import { IUpdateAdmissionParams, ITabData } from '../../../interfaces';
import React, { useState } from 'react';
import { renderStatus } from '../../../helpers';
import {
  TabContentContainer,
  HeaderWrapper,
  StatusWrapper,
  TextWrapper,
  StyledHeader,
} from '../styles';
import { TypeformModalWrapper } from '../../../../InstructorOnboarding/InstructorProfile/components/Resources/styles';
import { Body, Cap, Button } from '@careerfoundry/bubble';
import TheModal from '../../../../shared/components/TheModal';
import Typeform from '../../../../shared/components/Typeform';

interface IProps {
  tabData: ITabData;
  updateAdmission: (params: IUpdateAdmissionParams, tab: string) => void;
}

const ProfileSurvey: React.FC<IProps> = ({ tabData, updateAdmission }) => {
  const typeformId = tabData.surveyLink;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const toggleSurvey = (isOpen: boolean) => setModalIsOpen(isOpen);

  const handleSubmitSurvey = (event: any) => {
    updateAdmission(
      {
        profile_survey: {
          typeform_id: event.data.response_id,
        },
      },
      'profileSurvey'
    );
  };

  return (
    <TabContentContainer>
      <HeaderWrapper>
        <StyledHeader variant='h3' tag='h3'>
          Profile Survey
        </StyledHeader>
        <StatusWrapper>
          <Cap tag='p' variant='bold'>
            status:{' '}
          </Cap>
          {renderStatus(tabData.state, true)}
        </StatusWrapper>
      </HeaderWrapper>
      <TextWrapper>
        <Body tag='p' variant='large'>
          The profile survey helps us get to know you better—assessing your
          suitability for the course, and learning more about your motivations
          to apply! In order to complete the contract, we’ll also be asking you
          some basic personal questions (address, date of birth, phone number,
          etc.).
        </Body>
      </TextWrapper>
      <Button
        label='Take the survey'
        onClick={() => toggleSurvey(true)}
        size='standard'
        variant='primary'
        disabled={tabData.state === 'done'}
      />
      <TypeformModalWrapper>
        <TheModal
          isModalOpen={modalIsOpen}
          handleClose={() => toggleSurvey(false)}
        >
          <Typeform formId={typeformId} handleSubmit={handleSubmitSurvey} />
        </TheModal>
      </TypeformModalWrapper>
    </TabContentContainer>
  );
};

export default ProfileSurvey;
