import ConfirmationModal from '../ConfirmationModal';
import Form from '../../containers/Form';
import React from 'react';
import TheModal from '../../../../shared/components/TheModal';
import { Wrapper } from './styles';

interface ModalProps {
  handleModalClose: () => void;
  modalAction: string;
}

const Modal: React.FC<ModalProps> = ({ handleModalClose, modalAction }) => {
  return (
    <Wrapper modalAction={modalAction}>
      <TheModal handleClose={handleModalClose} isModalOpen={modalAction !== ''}>
        <>
          {
            {
              createConfirmation: (
                <ConfirmationModal
                  handleClose={handleModalClose}
                  icon='ds-icon__thumb-up-2'
                  heading='New invoice claim added!'
                  body='Your submission will be reviewed by the team and added to your invoice.
                        No further action is required at this time.'
                />
              ),
              deleteConfirmation: (
                <ConfirmationModal
                  handleClose={handleModalClose}
                  icon='ds-icon__trash'
                  heading='Claim deleted!'
                  body='Your claim was successfully deleted.'
                />
              ),
              updateConfirmation: (
                <ConfirmationModal
                  handleClose={handleModalClose}
                  icon='ds-icon__thumb-up-2'
                  heading='Claim updated!'
                  body='Your changes have been successfully saved.'
                />
              ),
              form: <Form />,
            }[modalAction]
          }
        </>
      </TheModal>
    </Wrapper>
  );
};

export default Modal;
