import styled from 'styled-components';

const HeaderStyles = styled.div`
  box-shadow: 5px 5px 10px 0 rgba(51, 51, 51, 0.1);
  background: var(--ds-color__white);
  padding: 30px;

  @media (max-width: 479px) {
    box-shadow: none;
    padding: 15px 8px 0;
  }
`;

HeaderStyles.displayName = 'HeaderStyles';

export default HeaderStyles;
