import styled from 'styled-components';

const StyledAccordion = styled.div`
  .accordion__button {
    padding: 16px 0 0;
    margin-top: 48px;
    display: flex;
    align-item: center;

    h5 {
        font-size: 32px !important;
    }
  }

  .accordion__button:after {
    display: inline-block;
    float: right;
    margin-top: 14px;
    margin-left: 17px;
    content: '';
    height: 14px;
    width: 14px;
    border-top: 4px solid #000;
    border-left: 4px solid #000;
    transform: rotate(225deg);
    transition: all .2s;
  }

  .accordion__button[aria-expanded='true']::after,
  .accordion__button[aria-selected='true']::after {
    transform: rotate(45deg);
  }

  .accordion__panel {
    p {
      margin-bottom: 10px;
    }

    p.break,
    ul.break {
      margin-bottom: 40px
    }

    ul {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 479px) {
    margin: 0 -15px;

    .accordion__button {
      margin-bottom 8px;
    }

    .accordion__panel {
      padding: 16px 16px 24px;

      p.break,
      ul.break {
        margin-bottom: 16px
      }

      ul {
        padding-left: 30px;
      }
    }
  }
`;

StyledAccordion.displayName = 'StyledAccordion';

export default StyledAccordion;
