import { Body, Button } from '@careerfoundry/bubble';
import { ButtonActions } from './styles';
import React from 'react';
import { buttonEnabled } from './helpers';
import { IFormValues } from '../../interfaces';
import LoadingSpinner from '../../../../shared/loadingSpinner';

interface ButtonsProps {
  handleModalClose: () => void;
  values: IFormValues;
  canDelete: boolean;
  handleDelete: () => void;
  submitting: boolean;
}

const Buttons: React.FC<ButtonsProps> = ({
  handleModalClose,
  values,
  canDelete,
  handleDelete,
  submitting,
}) => (
  <ButtonActions>
    <div>
      <Button
        label='Cancel'
        onClick={handleModalClose}
        size='standard'
        variant='secondary'
      />
      <Button
        label={submitting ? <LoadingSpinner /> : 'Submit'}
        size='standard'
        variant='primary'
        disabled={!buttonEnabled(values)}
      />
    </div>
    <div>
      {canDelete && (
        <Body tag='p' variant='medium-bold' onClick={handleDelete}>
          Delete this entry
        </Body>
      )}
    </div>
  </ButtonActions>
);

export default Buttons;
