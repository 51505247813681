import ButtonWithIcon from '../../../../shared/components/ButtonWithIcon';
import styled from 'styled-components';

const StyledStartButton = styled(ButtonWithIcon)`
  i {
    color: #fa6357;
  }
`;

StyledStartButton.displayName = 'StyledStartButton';

export default StyledStartButton;
