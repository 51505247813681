import AfaStudentsList from '../../../react/afa_students_list';
import React from 'react';
import { createRoot } from 'react-dom/client';

const afaStudentsListContainer = document.querySelector('.admin__afa_students');

if (afaStudentsListContainer) {
  const afaStudentsListContainerRoot = createRoot(afaStudentsListContainer);

  afaStudentsListContainerRoot.render(<AfaStudentsList />);
}
