import JobSeekingProfileShow from './ShowModal';
import PropTypes from 'prop-types';
import React from 'react';
import StyledModalWrapper from '../../../admin/EmployerConnector/shared/components/Modal/styles/StyledModalWrapper';
import TheModal from '../../../shared/components/TheModal';

const EmployerModal = ({
  handleCloseModal,
  modalIsOpen,
  modalAction,
}) => {
  return (
    modalIsOpen
      ? <StyledModalWrapper isLargeModal={true} >
        <TheModal
          handleClose={handleCloseModal}
          isModalOpen={modalIsOpen}
        >
          <>
            {
              {
                showProfileModal: <JobSeekingProfileShow />,
              }[modalAction]
            }
          </>
        </TheModal>
      </StyledModalWrapper>
      : null
  );
};

EmployerModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  modalAction: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

export default EmployerModal;
