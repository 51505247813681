import AppContainer from './containers/App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../../../shared/reduxStore';

const employerConnectorEmployersContainer = document.getElementById(
  'employerConnectorEmployers'
);

if (employerConnectorEmployersContainer) {
  const employerConnectorEmployersContainerRoot = createRoot(
    employerConnectorEmployersContainer
  );

  employerConnectorEmployersContainerRoot.render(
    <Provider store={store}>
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
    </Provider>
  );
}
