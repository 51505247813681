import EmployerConnectorCardContainer from '../../containers/EmployerConnectorCard';
import FreelancingTile from './components/FreelancingTile';
import JobSearchTipsTile from './components/JobSearchTipsTile';
import React from 'react';
import RecommendedJobTile from './components/RecommendedJobTile';
import RemoteWorkTile from './components/RemoteWorkTile';
import StyledFlexWrapper from './styles/StyledFlexWrapper';
import StyledJobSection from './styles/StyledJobSection';
import StyledSection from './styles/StyledSection';

const JobSearchSection = () => (
  <StyledJobSection>
    <StyledSection className='tour__employer-connector'>
      <EmployerConnectorCardContainer />
    </StyledSection>
    <StyledSection>
      <h5 className='ds-typography__h5 text-left'>Job search resources</h5>
      <StyledFlexWrapper className='ds-grid__row'>
        <RecommendedJobTile />
        <JobSearchTipsTile />
      </StyledFlexWrapper>
    </StyledSection>
    <StyledSection>
      <h5 className='ds-typography__h5 text-left'>
        Freelance and remote work resources
      </h5>
      <StyledFlexWrapper className='ds-grid__row'>
        <FreelancingTile />
        <RemoteWorkTile />
      </StyledFlexWrapper>
    </StyledSection>
  </StyledJobSection>
);

JobSearchSection.propTypes = {};

export default JobSearchSection;
