import { FilterWrapper } from '../../styles';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../../../shared/components/Select';

const FilterSelect = ({ options, filter, handleChange, label, name }) => (
  <FilterWrapper>
    <label className="ds-typography__body--bold">{label}:</label>
    <Select onChange={handleChange} value={filter} name={name}>
      <option value="" defaultValue="">
        All
      </option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  </FilterWrapper>
);

FilterSelect.propTypes = {
  filter: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default FilterSelect;
