import PropTypes from 'prop-types';
import React from 'react';
import StyledLinkEdit from '../../styles/StyledLinkEdit';

const LinkEdit = ({ link, onEdit, onDelete, className = '' }) => (
  link
    ? <StyledLinkEdit className={className} >
      <a
        href={link}
        className="ds-typography__anchor ds-typography__small"
        target="_blank"
        rel="noreferrer"
      >
        {link}
      </a>
      <div className="icon-wrap">
        <i className="fa fa-pencil" onClick={onEdit}></i>
        <i className="fa fa-times-circle" onClick={onDelete}></i>
      </div>
    </StyledLinkEdit>
    : null
);

LinkEdit.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export default LinkEdit;
