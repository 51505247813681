module.exports = (() => {
  var init = function() {
    cancellationTable();
  };

  var cancellationTable = function() {
    $('.cancellation_reasons_data_table').DataTable({
      searching: false,
      paging: false,
      info: false
    });
  };

  return {
    init: init
  };
})();
