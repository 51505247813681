import Button from '../../../EmployerConnector/modals/shared/Button';
import ModalHeaderContainer from '../../containers/ModalHeaderContainer';
import PropTypes from 'prop-types';
import React from 'react';
import StyledButtonWrapper from '../styles/StyledButtonWrapper';
import StyledModalWrapper from '../styles/StyledModalWrapper';

const ModalWrapper = ({
  children,
  onPrevClick,
  onNextClick,
  currentState,
  submitDisabled,
  submitLoading,
  leftButtons = false,
}) => {
  const getSecondaryLabel = () => {
    switch (currentState) {
      case 'callQuestion':
      case 'editNote':
      case 'callDidNotHappen':
        return 'Cancel';
      case 'registerCall':
        return 'Discard';
      default:
        return 'Back';
    }
  };

  const getPrimaryLabel = () => {
    switch (currentState) {
      case 'callNoteForm':
        return 'Finish';
      case 'callQuestion':
        return 'Next';
      case 'viewNote':
        return 'Close';
      case 'registerCall':
        return 'Go to call notes';
      default:
        return 'Submit';
    }
  };

  return (
    <StyledModalWrapper currentState={currentState}>
      <ModalHeaderContainer />
      {children}
      <StyledButtonWrapper leftButtons={leftButtons}>
        {typeof onPrevClick === 'function' && (
          <Button
            className='ds-button__secondary'
            label={getSecondaryLabel()}
            onClick={() => onPrevClick()}
          />
        )}
        <Button
          className='ds-button__primary'
          disabled={submitDisabled}
          label={getPrimaryLabel()}
          loading={submitLoading}
          onClick={() => onNextClick()}
        />
      </StyledButtonWrapper>
    </StyledModalWrapper>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  currentState: PropTypes.string.isRequired,
  leftButtons: PropTypes.bool,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func,
  submitDisabled: PropTypes.bool,
  submitLoading: PropTypes.bool,
};

export default ModalWrapper;
