import PropTypes from 'prop-types';
import React from 'react';

const MailIcon = ({ unReadMessageCount }) => {
  const displayedMsgCount = unReadMessageCount > 9 ? '9+' : unReadMessageCount;

  return (
    <>
      <svg
        className='cf-nav__bell'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 13.3975C11.3396 13.3954 10.6876 13.2553 10.0893 12.987L1.08179 8.9953V18.9876C1.08179 19.9524 1.89649 20.7345 2.90148 20.7345H21.0985C22.1034 20.7345 22.9182 19.9524 22.9182 18.9876V8.9953L13.9107 12.987C13.3123 13.2553 12.6604 13.3954 12 13.3975ZM21.0985 3.26544H2.90148H2.90148C1.89649 3.26544 1.08179 4.04756 1.08179 5.01235V7.0737L10.8536 11.441C11.5808 11.7641 12.4192 11.7641 13.1464 11.441L22.9182 7.0737V5.01235C22.9182 4.04756 22.1034 3.26544 21.0985 3.26544Z'
        />
      </svg>
      {!!unReadMessageCount && (
        <span className='cf-nav__counter'>{displayedMsgCount}</span>
      )}
    </>
  );
};

MailIcon.propTypes = {
  unReadMessageCount: PropTypes.number,
};

export default MailIcon;
