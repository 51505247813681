import EmptySection from '../EmptySection';
import Progress from '../../../UserProfile/components/CoursesTab/Progress';
import PropTypes from 'prop-types';
import React from 'react';
import { Wrapper } from './styles';
import { constants } from '../../constants';
import { getAchievements } from '../../helpers';

const Achievements = ({
  selectedCourseId,
  attendees,
  isSelectedCourseStarted,
}) => {
  if (!isSelectedCourseStarted || selectedCourseId === 0) {
    return <EmptySection message={constants.SUBMISSIONS_AFTER_COURSE_START} />;
  }

  const selectedCourseAchievements =
    getAchievements({
      attendees,
      courseId: selectedCourseId,
    }) || [];

  const selectedCourse =
    attendees.find((attendee) => attendee.courseId === selectedCourseId) || {};

  const isCourseOutOfTime =
    selectedCourse.state === constants.PASSED_COURSE_END_DATE_STATE;

  return (
    <>
      {selectedCourseAchievements.length > 0 ? (
        <Wrapper>
          <Progress
            achievements={selectedCourseAchievements}
            showTitle={false}
            showCurrentTask={false}
            expandLastAchievement={!isCourseOutOfTime}
          />
        </Wrapper>
      ) : (
        <EmptySection message={constants.NO_SUBMISSIONS_MESSAGE} />
      )}
    </>
  );
};

Achievements.propTypes = {
  attendees: PropTypes.arrayOf(
    PropTypes.shape({
      courseId: PropTypes.number,
      progress: Progress.propTypes.achievements,
    })
  ),
  isSelectedCourseStarted: PropTypes.bool,
  selectedCourseId: PropTypes.number,
};

export default Achievements;
