import {
  ImageUploaderDesc,
  ImageUploaderImage,
  StyledImageUploader,
  Uploader,
  Wrapper,
} from './styles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import profileImage from '../../assets/images/profile.svg';
import uploadIcon from '../../assets/images/upload.svg';

const ImageUploader = ({ onSelectImage, userImage }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const checkFileValidity = (file) => {
    const types = ['image/png', 'image/jpeg', 'image/jpg'];
    const size = 1048576;
    return (
      types.findIndex((type) => type === file.type) > -1 && file.size <= size
    );
  };

  const handleFileChange = (event) => {
    if (checkFileValidity(event.target.files[0])) {
      setSelectedFile(URL.createObjectURL(event.target.files[0]));
      onSelectImage(event.target.files[0]);
    } else {
      alert('Selected file type is not supported! Please upload JPG or PNG');
    }
  };

  return (
    <Wrapper>
      <StyledImageUploader>
        <ImageUploaderImage
          src={selectedFile || userImage || profileImage}
          role='mainImage'
        />
      </StyledImageUploader>
      <Uploader>
        <label htmlFor='upload' role='mainUploader'>
          <div
            className='ds-button__petrol ds-typography__body'
            role='uploadFileIcon'
          >
            <img src={uploadIcon} /> &nbsp; Choose File
          </div>
          <input
            type='file'
            onChange={handleFileChange}
            id='upload'
            style={{ display: 'none' }}
          />
        </label>
      </Uploader>
      <ImageUploaderDesc className='ds-typography__body'>
        JPG or PNG <br />
        Max file size: 1MB
      </ImageUploaderDesc>
    </Wrapper>
  );
};

ImageUploader.propTypes = {
  onSelectImage: PropTypes.func,
  userImage: PropTypes.string,
};

export default ImageUploader;
