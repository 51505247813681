import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  display: flex;
  margin: 0.5rem 1.5rem;
  color: var(--ds-color__white);
  cursor: pointer;
`;

const ArrowIconWrapper = styled.div`
  display: flex;
  align-items: start;
  margin-right: 2rem;

  svg {
    width: 1rem;
    height: 1rem;
    fill: var(--ds-color__white);
    transform: ${(props) =>
      props.shouldShowCourses ? 'none' : 'rotate(0.5turn)'};
  }
`;

export { ArrowIconWrapper, Wrapper, Name };
