import styled from 'styled-components';

const StyledPasswordChange = styled.div`
  h4 {
    font-size: 28px;
    font-weight: 500;
    line-height: 2;
    margin-bottom: 24px;
  }

  .button-section {
    margin-top: 92px;
    display: flex;
    justify-content: center;
    
    button {
      margin: 0;
      width: 152px;
    }
  }
`;

StyledPasswordChange.displayName = 'StyledPasswordChange';

export default StyledPasswordChange;
