import ActiveButtonsWrapper from '../styles/ActiveButtonsWrapper';
import CardText from '../shared/CardText';
import PropTypes from 'prop-types';
import React from 'react';
import Wrapper from '../styles/Wrapper';
import { employerConnectorTracking } from '../../../../shared/helpers/tracking';

const AvailableContent = ({ showECModal }) => {
  const startOptInFlow = () => {
    employerConnectorTracking('ec_start_opt_in');
    showECModal('optin');
  };

  return (
    <Wrapper>
      <CardText
        paragraph='Employer Connector connects applicants with CareerFoundry partners interested in hiring our graduates.'
        link={{
          onClick: () => showECModal('optinDetails'),
          text: 'I want to know more about the Employer connector',
        }}
      />
      <ActiveButtonsWrapper>
        <button
          className='ds-button__primary'
          onClick={() => startOptInFlow('optin')}
        >
          {' '}
          Join
        </button>
      </ActiveButtonsWrapper>
    </Wrapper>
  );
};

AvailableContent.propTypes = {
  showECModal: PropTypes.func.isRequired,
};

export default AvailableContent;
