import styled from 'styled-components';

const StyledBanner = styled.div`
  max-width: 493px;
  padding: 14px 32px;
  margin-top: 40px;
  border-radius: 0 4px 4px 0;
  background-color: rgba(92, 162, 178, 0.1);
  border-left: 3px solid var(--ds-color__petrol);
  float: right;

  &.top-margin {
    margin: 0 0 40px;
  }

  & + div > div {
    height: 80vh;
  }

  @media (min-width: 1600px) {
    & + div > div {
      height: 60vh;
    }
  }

  h5 {
    font-size: 20px;
  }

  p {
    display: inline-block;
    margin-bottom: 0;
  }

  & > div {
    display: inline-block;
    cursor: pointer;
  }

  .arrow-wrap {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: var(--ds-color__indigo);
    color: var(--ds-color__white);
    text-align: center;
    line-height: 22px;
    display: inline-block;
    margin-left: 8px;
    position: relative;
    top: 6px;

    i {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-45%, -50%);
    }
  }

  @media (max-width: 1022px) {
    max-width: 100%;
    float: none;
    margin-bottom: 30px;
  }

  @media (max-width: 478px) {
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -15px;
    max-width: initial;
    padding: 14px 13px;
  }
`;

StyledBanner.displayName = 'StyledBanner';

export default StyledBanner;
