import { Label, Section, SectionHeading } from '../styles';
import PropTypes from 'prop-types';
import React from 'react';

const Banktransfer = ({
  contactName,
  city,
  country,
  bankName,
  street,
  zip,
  bic,
  branch,
  iban,
}) => (
  <>
    <div className="ds-grid__row">
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">Account holder name</Label>
        <p className="ds-typography__body">{contactName}</p>
      </div>
    </div>
    <div className="ds-grid__row">
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">IBAN/Account number</Label>
        <p className="ds-typography__body">{iban}</p>
      </div>
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">BIC/Swift</Label>
        <p className="ds-typography__body">{bic}</p>
      </div>
    </div>
    <div className="ds-grid__row">
      {bankName && (
        <div className="ds-grid__col-xs-6">
          <Label className="ds-typography__body">Bank name</Label>
          <p className="ds-typography__body">{bankName}</p>
        </div>
      )}
      {branch && (
        <div className="ds-grid__col-xs-6">
          <Label className="ds-typography__body">Bank branch</Label>
          <p className="ds-typography__body">{branch}</p>
        </div>
      )}
    </div>
    <div className="ds-grid__row">
      {street && (
        <div className="ds-grid__col-xs-6">
          <Label className="ds-typography__body">Bank street</Label>
          <p className="ds-typography__body">{street}</p>
        </div>
      )}
      {city && (
        <div className="ds-grid__col-xs-6">
          <Label className="ds-typography__body">Bank city</Label>
          <p className="ds-typography__body">{city}</p>
        </div>
      )}
    </div>
    <div className="ds-grid__row">
      {zip && (
        <div className="ds-grid__col-xs-6">
          <Label className="ds-typography__body">Bank postcode</Label>
          <p className="ds-typography__body">{zip}</p>
        </div>
      )}
      {country && (
        <div className="ds-grid__col-xs-6">
          <Label className="ds-typography__body">Bank country</Label>
          <p className="ds-typography__body">{country}</p>
        </div>
      )}
    </div>
  </>
);

Banktransfer.propTypes = {
  bankName: PropTypes.string,
  bic: PropTypes.string,
  branch: PropTypes.string,
  city: PropTypes.string,
  contactName: PropTypes.string,
  country: PropTypes.string,
  iban: PropTypes.string,
  street: PropTypes.string,
  zip: PropTypes.string,
};

const Paypal = ({ payee }) => (
  <div className="ds-grid__row">
    <div className="ds-grid__col-xs-6">
      <Label className="ds-typography__body">Email</Label>
      <p className="ds-typography__body">{payee}</p>
    </div>
  </div>
);

Paypal.propTypes = {
  payee: PropTypes.string,
};

const Payoneer = ({ payee }) => (
  <div className="ds-grid__row">
    <div className="ds-grid__col-xs-6">
      <Label className="ds-typography__body">Token</Label>
      <p className="ds-typography__body">{payee}</p>
    </div>
  </div>
);

Payoneer.propTypes = {
  payee: PropTypes.string,
};

const PaymentFields = ({
  paymentMethod,
  city,
  country,
  contactName,
  bankName,
  street,
  zip,
  bic,
  branch,
  iban,
  payee,
}) => (
  <Section>
    <SectionHeading className="ds-typography__h5">
      Payment Method
    </SectionHeading>
    <p className="ds-typography__body--bold">{paymentMethod}</p>
    {
      {
        'Bank Transfer': (
          <Banktransfer
            city={city}
            country={country}
            bankName={bankName}
            street={street}
            zip={zip}
            bic={bic}
            branch={branch}
            contactName={contactName}
            iban={iban}
          />
        ),
        PayPal: <Paypal payee={payee} />,
        Payoneer: <Payoneer payee={payee} />,
      }[paymentMethod]
    }
  </Section>
);

PaymentFields.propTypes = {
  bankName: PropTypes.string,
  bic: PropTypes.string,
  branch: PropTypes.string,
  city: PropTypes.string,
  contactName: PropTypes.string,
  country: PropTypes.string,
  iban: PropTypes.string,
  payee: PropTypes.string,
  paymentMethod: PropTypes.string,
  street: PropTypes.string,
  zip: PropTypes.string,
};

export default PaymentFields;
