import React, { useState } from 'react';
import ButtonSection from './ButtonSection';
import PropTypes from 'prop-types';
import StyledConsentModal from './styles/StyledConsentModal';
import ToggleSection from './ToggleSection';

const ApplicationsConsentModal = ({
  createApplicationConsent,
  handleClose,
  userId,
}) => {
  const [checked, setChecked] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const handleToggle = (event) => {
    const { checked } = event.target;
    setChecked(checked);
  };

  const handleConfirm = () => {
    setSaving(true);
    createApplicationConsent(userId, checked);
  };

  return (
    <StyledConsentModal>
      <h3 className="ds-typography__h3">
        Would you like your past applications to be visible to your career
        specialist?
      </h3>
      <p className="ds-typography__body">
        All your future applications will become visible to your Career
        Specialist by default. For more information please refer to our{' '}
        <a
          href="https://careerfoundry.com/en/terms"
          className="ds-typography__anchor"
          target="_blank"
          rel="noreferrer"
        >
          Terms & Conditions
        </a>
        .
      </p>
      <ToggleSection
        isChecked={checked}
        onChange={handleToggle}
        label="I’d like to share my applications with my career specialist"
      />
      <ButtonSection
        onClickConfirm={handleConfirm}
        onClose={handleClose}
        isSaving={isSaving}
      />
    </StyledConsentModal>
  );
};

ApplicationsConsentModal.propTypes = {
  createApplicationConsent: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

export default ApplicationsConsentModal;
