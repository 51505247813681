import React from 'react';

export const VolumeOnIcon = () => (
  <svg width='58' height='54' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.23837 7.90155H5.23837C2.70504 7.90155 0.651367 9.93831 0.651367 12.4508V21.5492C0.651367 24.0617 2.70504 26.0985 5.23837 26.0985H9.29022L18.9994 33.6805V0.319497L9.29022 7.90155L5.23837 7.90155ZM31.2314 17V17.0008C31.2294 13.7904 29.9876 10.7027 27.7611 8.37224C27.1784 7.76506 26.2098 7.74129 25.5975 8.31916C24.9853 8.89703 24.9614 9.8577 25.544 10.4649C29.0583 14.125 29.0583 19.8762 25.544 23.5363C24.9445 24.1261 24.9405 25.0862 25.5352 25.6808C25.5381 25.6838 25.5411 25.6867 25.544 25.6896C25.8413 25.9658 26.2373 26.113 26.6444 26.0985C27.0659 26.1011 27.4697 25.931 27.7605 25.6284C29.9872 23.2981 31.2292 20.2105 31.2314 17V17ZM29.0908 0.622814V0.622814C28.4152 0.12032 27.4568 0.256104 26.9502 0.926096C26.4435 1.59609 26.5804 2.54658 27.256 3.04907V3.04907V3.04907C35.0249 8.82776 36.5993 19.7584 30.7727 27.4633C29.773 28.7852 28.5889 29.9596 27.256 30.951C26.5804 31.4535 26.4435 32.404 26.9502 33.074C27.2389 33.4558 27.6921 33.6806 28.1734 33.6806C28.5042 33.6806 28.8261 33.5741 29.0908 33.3773L29.0908 33.3773C38.2108 26.5936 40.0591 13.7621 33.2191 4.71712C32.0455 3.16526 30.6555 1.7867 29.0908 0.622814L29.0908 0.622814Z'
      fill='#1C7488'
    />
  </svg>
);

export const VolumeOffIcon = () => (
  <svg width='58' height='54' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.809175 14.4513V23.5461C0.809175 26.0575 2.84388 28.0934 5.3538 28.0934H9.36822L18.9877 35.6724V27.5174L2.46039 10.9802H2.46039C1.42423 11.8361 0.819704 13.1069 0.809175 14.4513V14.4513ZM33.1972 31.125C39.8796 22.0874 37.9963 9.34388 28.9858 2.62817C28.3165 2.12589 27.367 2.26161 26.865 2.93133C26.363 3.60105 26.4987 4.55115 27.168 5.05344V5.05344C34.6891 10.6398 36.4312 21.186 31.1067 28.8968L28.6829 26.4867H28.6829C32.3393 21.6055 31.9424 14.7967 27.7436 10.3739C27.1664 9.76693 26.2067 9.74317 25.6001 10.3208C24.9935 10.8984 24.9698 11.8587 25.5471 12.4656C28.6149 15.6914 29.0187 20.6215 26.5166 24.304L18.9877 16.8614V2.32502L10.8528 8.72165L3.39961 1.24881L3.39961 1.24881C2.8056 0.654431 1.8425 0.654431 1.24849 1.2488C0.654475 1.84318 0.654475 2.80685 1.24849 3.40123L34.5757 36.7486C35.1649 37.343 36.124 37.3469 36.7181 36.7574C36.721 36.7545 36.7239 36.7515 36.7268 36.7486C37.3209 36.1591 37.3248 35.1993 36.7356 34.605C36.7327 34.602 36.7298 34.5991 36.7268 34.5962L33.1972 31.125Z'
      fill='#1C7488'
    />
  </svg>
);

export const AudioReplayIcon = () => (
  <svg width='58' height='54' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_56_3111)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.0909 17.325L16.636 13.3251C16.1842 12.9937 15.5433 13.0832 15.2045 13.5251C15.0718 13.6982 15 13.9087 15 14.1251V22.125C15 22.6773 15.4578 23.125 16.0225 23.125C16.2437 23.125 16.459 23.0548 16.636 22.925L22.0909 18.925C22.5427 18.5936 22.6343 17.9669 22.2954 17.525C22.2373 17.4492 22.1684 17.3819 22.0909 17.325Z'
        fill='#1C7488'
      />
      <path
        d='M35.6224 16.5577C34.8875 7.46446 27.2148 0.421576 17.9277 0.31572C7.9971 0.431687 0.0400965 8.42831 0.150303 18.1818C0.0399994 27.9353 7.99702 35.932 17.9277 36.048C19.2977 36.048 20.4082 34.9573 20.4082 33.6116C20.4082 32.2662 19.2977 31.1755 17.9277 31.1755C10.7408 31.0509 5.0101 25.2409 5.11142 18.1818C5.01008 11.1229 10.7408 5.31291 17.9277 5.18835C24.4747 5.28989 29.92 10.1644 30.6282 16.5577H28.2635C27.8046 16.5633 27.3687 16.7559 27.0602 17.0894C26.446 17.7533 26.496 18.7805 27.172 19.3838L33.2246 24.6785L39.3102 19.3838C39.6582 19.0731 39.8551 18.6316 39.8517 18.1698C39.8448 17.2728 39.0989 16.551 38.1857 16.5577H35.6224Z'
        fill='#1C7488'
      />
    </g>
    <defs>
      <clipPath id='clip0_56_3111'>
        <rect width='40' height='36.3636' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const AudioReplayDisabledIcon = () => (
  <svg width='58' height='54' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_56_3158)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.0909 17.325L16.636 13.3251C16.1842 12.9937 15.5433 13.0832 15.2045 13.5251C15.0718 13.6982 15 13.9087 15 14.1251V22.125C15 22.6773 15.4578 23.125 16.0225 23.125C16.2437 23.125 16.459 23.0548 16.636 22.925L22.0909 18.925C22.5427 18.5936 22.6343 17.9669 22.2954 17.525C22.2373 17.4492 22.1684 17.3819 22.0909 17.325Z'
        fill='#223C50'
        fillOpacity='0.4'
      />
      <path
        d='M35.6224 16.5577C34.8875 7.46446 27.2148 0.421576 17.9277 0.31572C7.9971 0.431687 0.0400965 8.42831 0.150303 18.1818C0.0399994 27.9353 7.99702 35.932 17.9277 36.048C19.2977 36.048 20.4082 34.9573 20.4082 33.6116C20.4082 32.2662 19.2977 31.1755 17.9277 31.1755C10.7408 31.0509 5.0101 25.2409 5.11142 18.1818C5.01008 11.1229 10.7408 5.31291 17.9277 5.18835C24.4747 5.28989 29.92 10.1644 30.6282 16.5577H28.2635C27.8046 16.5633 27.3687 16.7559 27.0602 17.0894C26.446 17.7533 26.496 18.7805 27.172 19.3838L33.2246 24.6785L39.3102 19.3838C39.6582 19.0731 39.8551 18.6316 39.8517 18.1698C39.8448 17.2728 39.0989 16.551 38.1857 16.5577H35.6224Z'
        fill='#223C50'
        fillOpacity='0.4'
      />
    </g>
    <defs>
      <clipPath id='clip0_56_3158'>
        <rect width='40' height='36.3636' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
