import Banner from '../../containers/Banner';
import Card from '../../../InstructorOnboarding/InstructorProfile/styles/Card';
import PersonalInformation from '../../containers/PersonalInformation';
import React from 'react';
import StyledApp from '../../../InstructorOnboarding/InstructorProfile/components/App/styles';
import SubmissionModal from '../../containers/SubmissionModal';

const App = () => {
  return (
    <StyledApp>
      <div className='ds-grid__container'>
        <div className='ds-grid__row'>
          <div className='ds-grid__col-lg-offset-2 ds-grid__col-lg-8 ds-grid__col-xs-12'>
            <img
              src='https://images.careerfoundry.com/public/instructor_candidates/instructor_profile_header_image.webp'
              alt='desk'
            />
            <Banner />
            <Card>
              <PersonalInformation />
            </Card>
          </div>
        </div>
      </div>
      <SubmissionModal />
    </StyledApp>
  );
};

export default App;
