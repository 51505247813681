import Header from './styles/Header';
import { PropTypes } from 'prop-types';
import React from 'react';

const Banner = ({ dismissBanner }) => (
  <Header>
    <p className='ds-typography__body--bold ds-color__indigo'>
      <i className='ds-icon__info-line' /> We adjust your goal to ensure you can
      finish the course on time.
    </p>
    <button
      className='ds-button__tertiary ds-button__small'
      onClick={dismissBanner}
    >
      Dismiss
    </button>
  </Header>
);

Banner.propTypes = {
  dismissBanner: PropTypes.func.isRequired,
};

export default Banner;
