export const SET_SHOULD_SHOW_DATA_POLICY_AGREEMENT_MODAL =
  'sideNavList.SET_SHOULD_SHOW_DATA_POLICY_AGREEMENT_MODAL';
export const SET_IS_ACCEPTING_PRIVACY_POLICY =
  'sideNavList.SET_IS_ACCEPTING_PRIVACY_POLICY';
export const SET_REQUIRED_DATA = 'sideNavList.SET_REQUIRED_DATA';
export const SET_IS_VISIBLE = 'sideNavList.SET_IS_VISIBLE';
export const SET_AGREED_TO_DISCOURSE_PRIVACY_POLICY =
  'sideNavList.SET_AGREED_TO_DISCOURSE_PRIVACY_POLICY';
export const SET_SHOULD_SHOW_SUB_NAV = 'sideNavList.SET_SHOULD_SHOW_SUB_NAV';
export const MOBILE_VIEW_MAX_WIDTH = 768;
export const CAREER_SUPPORT_CENTER = 'Career Support Center';
export const INACTIVE_USER_TYPES = ['frozen', 'canceled', 'lead'];
