import SharedCourseCard from '../../../../shared/styles/CourseCard';
import styled from 'styled-components';

const CourseCard = styled(SharedCourseCard)`
  @media (max-width: 479px) {
    padding: 1.5rem;
  }
`;

export default CourseCard;
