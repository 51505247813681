import {
  handleCategoryChange,
  handleChange,
  handleCreate,
  handleUpdate,
} from './actions';
import MentorNoteForm from '../../../components/MentorNotesTab/MentorNoteForm';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  userProfile: {
    mentorNotesTab: { formAction, formData, selectedNote },
  },
}) => ({
  formAction,
  formData,
  selectedNote,
});

export const mapDispatchToProps = (dispatch) => ({
  handleCategoryChange: (event) => dispatch(handleCategoryChange(event)),
  handleChange: (event) => dispatch(handleChange(event)),
  handleCreate: (params) => dispatch(handleCreate(params)),
  handleUpdate: (params) => dispatch(handleUpdate(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MentorNoteForm);
