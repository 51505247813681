import React from 'react';
import { Button, Header } from '@careerfoundry/bubble';
import { ButtonWrapper, Section, SectionStep } from '../styles';
import { renderStatus } from '../../../../../AfaAdmissions/helpers';

interface TermsProps {
  data: {
    state: string;
    reason?: string;
  };
  handleModalAction: (action: string, attribute: string) => void;
}

const Terms: React.FC<TermsProps> = ({ data, handleModalAction }) => {
  return (
    <Section>
      <SectionStep>
        <div>
          <Header tag='h5' variant='h5'>
            Terms of Participation
          </Header>
          {renderStatus(data.state, true)}
        </div>
      </SectionStep>
      <SectionStep>
        <div>
          <div></div>
          <div>
            {data.state === 'pending' && (
              <ButtonWrapper>
                <Button
                  label='Reject'
                  onClick={() =>
                    handleModalAction('reject', 'terms_of_participation')
                  }
                  size='small'
                  variant='secondary'
                />
                <Button
                  label='Approve'
                  onClick={() =>
                    handleModalAction('approve', 'terms_of_participation')
                  }
                  size='small'
                  variant='primary'
                />
              </ButtonWrapper>
            )}
          </div>
        </div>
      </SectionStep>
    </Section>
  );
};

export default Terms;
