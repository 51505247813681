import styled from 'styled-components';

const ProfileSeparator = styled.div`
  margin: 12px 0 8px;
  opacity: 0.5;
  border: solid 1px #c5c8c9;

  @media (max-width: 768px) {
    margin: 12px 0 14px;
  }
`;

ProfileSeparator.displayName = 'ProfileSeparator';

export default ProfileSeparator;
