import { TextField } from '@careerfoundry/bubble';
import React, { ChangeEvent } from 'react';
import { BillingData } from './types/BillingForm';

interface Props {
  billingData: BillingData;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  userLocation: string;
}

const BillingForm = ({ billingData, handleChange, userLocation }: Props) => {
  console.log(userLocation);
  return (
    <>
      <TextField
        id='address'
        name='address'
        value={billingData.address}
        onChange={handleChange}
        label='Address'
        style={{ ...styles, marginBottom: 8 }}
        required={true}
      />
      <TextField
        id='city'
        name='city'
        value={billingData.city}
        onChange={handleChange}
        label='City'
        style={styles}
        required={true}
      />
      {userLocation === 'US' && (
        <TextField
          id='state'
          name='state'
          value={billingData.state}
          onChange={handleChange}
          label='State'
          style={styles}
          required={true}
        />
      )}
      <TextField
        id='zip'
        name='zip'
        value={billingData.zip}
        onChange={handleChange}
        label='Zip code'
        style={{ ...styles, marginBottom: -10 }}
        required={true}
      />
    </>
  );
};

const styles = {
  marginBottom: 12,
  fontSize: '16px',
};

export default BillingForm;
