import App from './containers/App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const studentHandoffContainer = document.getElementById('studentHandoff');

if (studentHandoffContainer) {
  const studentHandoffContainerRoot = createRoot(studentHandoffContainer);

  studentHandoffContainerRoot.render(
    <Provider store={store}>
      <App
        userId={studentHandoffContainer.dataset.userId}
        isTutor={studentHandoffContainer.dataset.tutor === 'true'}
        overdue={false}
      />
    </Provider>
  );
}
