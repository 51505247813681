import InputRow from '../../CareerSupportCenter/components/CareerSupportCenterModal/styles/InputRow';
import styled from 'styled-components';

const StyledFormField = styled(InputRow)`
  font-size: 16px;
  margin-bottom: 44px;

  &.sublabel {
    margin-bottom: 22px;
  }

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

StyledFormField.displayName = 'StyledFormField';

export default StyledFormField;
