import styled from 'styled-components';

const StyledFlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  ${({ isEdit }) =>
    isEdit &&
    `
    justify-content: space-between !important;
  `}
`;

StyledFlexWrapper.displayName = 'StyledFlexWrapper';

export default StyledFlexWrapper;
