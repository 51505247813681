import styled from 'styled-components';

const FormWrapper = styled.div`
  width: 518px;
  margin-bottom: 12px;

  h3,
  p {
    margin-bottom: 0;
  }

  p {
    color: rgba(34, 60, 80, 0.75);
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`;

FormWrapper.displayName = 'FormWrapper';

const FormSection = styled.div`
  border-bottom: ${(props) => !props.last && '1px solid #E8EBED'};
  margin-bottom: ${(props) => props.last && '8px'};
  padding-bottom: 16px;
  margin-top: 32px;
`;

FormSection.displayName = 'FormSection';

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative;

  input,
  select {
    width: 247px;
  }

  .upload-wrapper {
    position: relative;
  }

  .remove-tax-input {
    position: absolute;
    right: 0;
    top: 25%;
    font-size: 14px;
    color: #a7b1b9;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: block;

    input,
    select {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

InputWrapper.displayName = 'InputWrapper';

const SubmitButton = styled.button`
  width: 221px;

  .loading_spinner_wrapper {
    background: transparent;
    margin-top: 0;

    > .cf-spinner > div {
      background-color: #fff;
    }
  }

  i {
    margin-left: 16px;
  }
`;

SubmitButton.displayName = 'SubmitButton';

const CheckboxWrapper = styled.div`
  float: right;

  p {
    color: rgba(34, 60, 80, 0.75) !important;
  }

  input + div {
    width: 14.5px;
    height: 14.5px;
    border-radius: 3px;
    border: 1px solid rgba(34, 60, 80, 0.75);

    &:before {
      width: 5px;
      height: 8px;
      margin-left: 4px;
      margin-top: 1px;
    }
  }
`;

CheckboxWrapper.displayName = 'CheckboxWrapper';

export {
  FormWrapper,
  FormSection,
  InputWrapper,
  SubmitButton,
  CheckboxWrapper,
};
