import React, { useEffect } from 'react';
import App from '../../components/App';
import LoadingSpinner from '../../../shared/loadingSpinner';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getData } from '../../actions';

export const AppContainer = ({ getData, loading, userId }) => {
  useEffect(() => {
    getData(userId);
  }, []);

  return loading ? <LoadingSpinner /> : <App />;
};

AppContainer.propTypes = {
  getData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

export const mapStateToProps = ({ userProfile: { loading } }) => ({
  loading,
});

export const mapDispatchToProps = (dispatch) => ({
  getData: (userId) => dispatch(getData(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
