import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from '../../EmployerConnector/modals/shared/Button';
import PropTypes from 'prop-types';
import StyledFormField from './styles/StyledFormField';
import StyledInput from '../../shared/components/Input/styles';
import StyledPasswordChange from './styles/StyledPasswordChange';

const PasswordChange = ({ updatePassword, passwordError }) => {
  const [passwordState] = useState({
    password: '',
    password_confirmation: '',
  });
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (data) => {
    setLoading(true);
    if (data.password) {
      await updatePassword(data);
    }
  };

  useEffect(() => {
    if (passwordError) {
      setLoading(false);
    }
  }, [passwordError]);

  const validateInput = (values, role) => {
    const errors = {};
    if (values.password_confirmation.length > 0 && values.password.length < 1) {
      errors.password = 'password field cannot be empty';
      return errors;
    }

    if (values.password.length < 8) {
      errors.password = 'password length must be greater than 8';
      return errors;
    }

    if (values.password !== values.password_confirmation) {
      errors.password_confirmation = 'password not the same';
      return errors;
    }
    return errors;
  };

  return (
    <StyledPasswordChange>
      <h4 className="ds-typography__h4 text-left">Change password</h4>
      <Formik
        initialValues={passwordState}
        validate={values => validateInput(values)}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <StyledFormField labelText='New password'>
              <StyledInput error={touched.password && errors.password}>
                <Field
                  type="text"
                  name="password"
                  placeholder="Enter new password"
                />
                <ErrorMessage name="password">
                  {msg => <p className="ds-typography__small--bold error-text">{msg}</p>}
                </ErrorMessage>
              </StyledInput>
            </StyledFormField>
            <StyledFormField labelText='Confirm new password'>
              <StyledInput error={touched.password_confirmation && errors.password_confirmation}>
                <Field
                  type="text"
                  name="password_confirmation"
                  placeholder="Confirm new password"
                />
                <ErrorMessage name="password_confirmation">
                  {msg => <p className="ds-typography__small--bold error-text">{msg}</p>}
                </ErrorMessage>
                {passwordError && passwordError.password &&
                  <p className="ds-typography__small--bold error-text">{passwordError.password[0]}</p>
                }
              </StyledInput>
            </StyledFormField>
            <div className="button-section">
              <Button
                className="ds-button__primary"
                label='Update password'
                onClick={handleSubmit}
                loading={isLoading}
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </StyledPasswordChange>
  );
};

PasswordChange.propTypes = {
  passwordError: PropTypes.object,
  updatePassword: PropTypes.func.isRequired,
};

export default PasswordChange;
