import { Field } from 'formik';
import React from 'react';
import { StyledInput } from '../App/styles';

const TaxRateInputDefault = () => (
  <StyledInput>
    <Field
      type='text'
      name='taxRate'
      placeholder='Tax rate'
      required={true}
    ></Field>
  </StyledInput>
);

export default TaxRateInputDefault;
