import React, { useEffect } from 'react';
import CallReviewsTab from '../../components/CallReviewsTab';
import LoadingSpinner from '../../../shared/loadingSpinner';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCallReviewData } from '../../actions';
import { setActivePage } from './actions';

export const CallReviewsContainer = ({
  getCallReviewData,
  reviewsLoading,
  userId,
  ...rest
}) => {
  useEffect(() => {
    getCallReviewData(userId);
  }, []);

  return reviewsLoading ? <LoadingSpinner /> : <CallReviewsTab {...rest} />;
};

CallReviewsContainer.propTypes = {
  getCallReviewData: PropTypes.func.isRequired,
  reviewsLoading: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

export const mapStateToProps = ({
  userProfile: {
    callReviews,
    callReviewsActivePage,
    callReviewsLength,
    reviewsLoading,
    userId,
  },
}) => ({
  callReviews,
  callReviewsActivePage,
  callReviewsLength,
  reviewsLoading,
  userId,
});

export const mapDispatchToProps = (dispatch) => ({
  getCallReviewData: (userId) => dispatch(getCallReviewData(userId)),
  setActivePage: (activePage) => dispatch(setActivePage(activePage)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallReviewsContainer);
