import { FormWrapper, SubmitButton } from './styles';
import AddressInputs from '../../containers/PersonalInformation/AddressInputs';
import BillingInputs from '../../containers/PersonalInformation/BillingInputs';
import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PaymentInputs from '../../containers/PersonalInformation/PaymentInputs';
import PersonalInformationInputs from '../../containers/PersonalInformation/PersonalInformationInputs';
import PropTypes from 'prop-types';
import React from 'react';
import TaxInputs from '../../containers/PersonalInformation/TaxInputs';

const PersonalInformation = ({
  submitBillingProfile,
  loadingButton,
  handleSubmit,
}) => {
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit();
    submitBillingProfile();
  };

  return (
    <FormWrapper>
      <div>
        <h3 className="ds-typography__h3 text-left">Personal Information</h3>
        <p className="ds-typography__small text-center">* required field</p>
      </div>
      <form onSubmit={handleFormSubmit}>
        <PersonalInformationInputs />
        <AddressInputs />
        <BillingInputs />
        <PaymentInputs />
        <TaxInputs />
        <SubmitButton type="submit" className="ds-button__primary">
          {loadingButton ? <LoadingSpinner /> : 'Submit'}
          {!loadingButton && <i className="ds-icon__arrow-thin-right"></i>}
        </SubmitButton>
      </form>
    </FormWrapper>
  );
};

PersonalInformation.propTypes = {
  handleSubmit: PropTypes.func,
  loadingButton: PropTypes.bool,
  submitBillingProfile: PropTypes.func.isRequired,
};

export default PersonalInformation;
