import styled from 'styled-components';

const StyledBackgroundImage = styled.div`
  height: 528px;
  flex-basis: 52%;
  background-repeat: no-repeat;
  background-image: url('http://images.careerfoundry.com/public/images/congratulation.png');

  @media (max-width: 900px) {
    min-height: 336px;
    background-size: cover;
  }

  @media (max-width: 479px) {
    min-height: 280px;
  }
`;
StyledBackgroundImage.displayName = 'StyledBackgroundImage';
export default StyledBackgroundImage;
