import ApiService from '../../services/ApiService';
import { constants } from './constants';
import { getAuthToken } from '../../utils/getAuthToken';

export const getInitialData = () => async (dispatch) => {
  const apiService = new ApiService('/api/v1/line_items');

  try {
    const result = await apiService.makeGetCall();
    dispatch({
      data: result.data,
      type: constants.LOAD_INITIAL_DATA_SUCCESS,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setActiveTab = (tab) => ({
  tab,
  type: constants.SET_ACTIVE_TAB,
});

export const handleStatusChange = (id, status) => ({
  id,
  status,
  type: constants.HANDLE_STATUS_CHANGE,
});

export const handleModalClose = () => ({
  type: constants.HANDLE_MODAL_CLOSE,
});

export const handleSubmit = (id, status) => async (dispatch) => {
  const apiService = new ApiService(`/api/v1/line_items/${id}`);
  const params = {
    authenticity_token: getAuthToken(),
    status,
  };

  try {
    const result = await apiService.makePatchCall(params);
    dispatch({
      data: result.data,
      type: constants.UPDATE_CALL_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: constants.UPDATE_CALL_FAILURE,
    });
  }
};
