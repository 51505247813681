import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import FilterWrapper from '../shared/FilterWrapper';
import PropTypes from 'prop-types';
import { SPECIALIZATION } from '../../constants';
import StyledFilterWrapper from '../../styles/StyledFilterWrapper';
import { employerTracking } from '../../../shared/helpers/tracking';
import { programHeaders } from '../../../CareerSupportCenter/constants';

let timerId;

const JobProfileFilters = ({
  getAllIndustries,
  getJobSeekingProfiles,
  industries,
  setFilterParams,
  profileFilterParams,
}) => {
  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFilterParams({
      name,
      value,
    });
  };

  const handleInputChange = (event) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    const { name, value } = event.target;
    timerId = setTimeout(() => setFilterParams({ name, value }), 1000);
  };

  useEffect(() => {
    getAllIndustries();
  }, []);

  useEffect(() => {
    getJobSeekingProfiles(1, profileFilterParams);
  }, [profileFilterParams]);

  useEffect(() => {
    const data = [];
    for (const filter in profileFilterParams) {
      if (profileFilterParams[filter]) {
        data.push({ filter: filter, value: profileFilterParams[filter] });
      }
    }
    if (data.length > 0) {
      employerTracking('ec_student_profile_filter', data);
    }
  }, [profileFilterParams]);

  const filteredIndustries = industries
    ? industries.filter((industry) => industry.toLowerCase() !== 'general')
    : [];

  return (
    <StyledFilterWrapper>
      <Formik
        initialValues={{
          industry: '',
          location: '',
          specialization: '',
        }}
      >
        {() => (
          <Form>
            <FilterWrapper label="Industry" isVerticalLabel={true}>
              <Field
                as="select"
                name="industry"
                placeholder="Select Industry"
                onChange={handleSelectChange}
                value={profileFilterParams.industry}
              >
                <option value="">Select Industry</option>
                {filteredIndustries.map((industry) => (
                  <option value={industry} key={industry}>
                    {programHeaders[industry]}
                  </option>
                ))}
              </Field>
            </FilterWrapper>
            <FilterWrapper label="Specialization" isVerticalLabel={true}>
              <Field
                as="select"
                name="specialization"
                placeholder="specialization"
                onChange={handleSelectChange}
                value={profileFilterParams.specialization}
              >
                <option value="">Select specialization</option>
                {SPECIALIZATION.map((specialization) => {
                  const [name, slug] = specialization;
                  return (
                    <option value={slug} key={slug}>
                      {name}
                    </option>
                  );
                })}
              </Field>
            </FilterWrapper>
            <FilterWrapper label="Location" isVerticalLabel={true}>
              <Field
                type="text"
                name="location"
                placeholder="Type country, state or city"
                onKeyUp={handleInputChange}
              />
            </FilterWrapper>
          </Form>
        )}
      </Formik>
    </StyledFilterWrapper>
  );
};

JobProfileFilters.propTypes = {
  getAllIndustries: PropTypes.func.isRequired,
  getJobSeekingProfiles: PropTypes.func.isRequired,
  industries: PropTypes.array.isRequired,
  profileFilterParams: PropTypes.shape({
    industry: PropTypes.string,
    location: PropTypes.string,
    specialization: PropTypes.string,
  }),
  setFilterParams: PropTypes.func.isRequired,
};

export default JobProfileFilters;
