import EvaluationAudio from '../../../InterviewPrep/components/InterviewReview/EvaluationAudio';
import EvaluationContent from '../../../InterviewPrep/components/InterviewReview/styles/Content';
import EvaluationHeader from '../../../InterviewPrep/components/InterviewReview/styles/Header';
import EvaluationInfo from '../../../InterviewPrep/components/InterviewReview/styles/Info';
import EvaluationQuestionTips from '../../../InterviewPrep/components/InterviewReview/EvaluationQuestionTips';
import PropTypes from 'prop-types';
import React from 'react';
import StyledCard from './styles/StyledCard';

const Evaluation = ({ isModal, currentAnswer, isMentorPage }) => (
  <StyledCard isModal={isModal}>
    <EvaluationContent isModal={isModal} className='review-content'>
      <EvaluationHeader isModal={isModal} className='text-left'>
        <p className='ds-typography__body'>Question</p>
        <h4 className='ds-typography__h4'>{currentAnswer.question}</h4>
      </EvaluationHeader>
      <EvaluationInfo isModal={isModal}>
        <EvaluationQuestionTips currentAnswer={currentAnswer} />
        <EvaluationAudio
          isModal={isModal}
          currentAnswer={currentAnswer}
          isMentorPage={isMentorPage}
        />
      </EvaluationInfo>
    </EvaluationContent>
  </StyledCard>
);

Evaluation.propTypes = {
  currentAnswer: PropTypes.object,
  isMentorPage: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
};

export default Evaluation;
