import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`;

export const StyledImageUploader = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  overflow: hidden;
`;

export const ImageUploaderDesc = styled.div`
  margin: 0 auto;
  margin-top: 24px;
  width: 130px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--ds-color__indigo);
  padding-bottom: 32px;
`;

export const ImageUploaderImage = styled.img`
  text-align: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;

export const Uploader = styled.div`
  display: flex;
  justify-content: center;
  aling-items: center;
  padding-top: 32px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    position: absolute;
    width: 137px;
    height: 32px;
    background: var(--ds-color__petrol);
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: white;
  }
`;

export default Wrapper;
