import {
  SET_CURRENT_PAGE,
  SET_CURRICULUM_STRUCTURE,
  SET_SURVEY_QUESTIONS,
  SET_USER_ANSWERS,
  SET_USER_DATA,
  START_UI_LOADING,
  STOP_UI_LOADING,
} from './constants';

export const initialState = {
  currentPage: '',
  curriculumStructure: null,
  isLoading: false,
  questions: [],
  userAnswers: [],
  userData: {},
};

export default function (state = initialState, action) {
  return { ...state, ...reducer(state, action) };
}

const reducer = (state, action) =>
  ({
    [SET_CURRENT_PAGE]: {
      currentPage: action.currentPage,
    },
    [SET_CURRICULUM_STRUCTURE]: {
      curriculumStructure: action.structure,
    },
    [SET_SURVEY_QUESTIONS]: {
      questions: action.questions,
    },
    [SET_USER_ANSWERS]: {
      userAnswers: action.answers,
    },
    [SET_USER_DATA]: {
      userData: action.userData,
    },
    [START_UI_LOADING]: {
      isLoading: action.isLoading,
    },
    [STOP_UI_LOADING]: {
      isLoading: action.isLoading,
    },
  }[action.type]);
