import styled from 'styled-components';

const StyledButton = styled.button`
  height: 40px;
  padding: 8px 16px;
  margin-bottom: 40px;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(34, 60, 80, 0.16);
  white-space: nowrap;
  text-align: center;
`;

StyledButton.displayName = 'StyledButton';

export default StyledButton;
