import styled from 'styled-components';

const CallFeedbackBox = styled.div`
  margin-top: 15px;
  box-shadow: 5px 5px 10px 0 rgba(51, 51, 51, 0.1);
  background: #fff;
  padding: 30px;
`;

CallFeedbackBox.displayName = 'CallFeedbackBox';

export default CallFeedbackBox;
