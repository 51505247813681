export const constants = {
  CREATE_EMPLOYER_FAILURE: 'AdminEmployerConnector.CREATE_EMPLOYER_FAILURE',
  CREATE_EMPLOYER_SUCCESS: 'AdminEmployerConnector.CREATE_EMPLOYER_SUCCESS',
  DELETE_EMPLOYER_FAILURE: 'AdminEmployerConnector.DELETE_EMPLOYER_FAILURE',
  DELETE_EMPLOYER_SUCCESS: 'AdminEmployerConnector.DELETE_EMPLOYER_SUCCESS',
  FETCH_JOB_SEEKING_PROFILES_FAILURE: 'AdminEmployerConnector.FETCH_JOB_SEEKING_PROFILES_FAILURE',
  FETCH_JOB_SEEKING_PROFILES_SUCCESS: 'AdminEmployerConnector.FETCH_JOB_SEEKING_PROFILES_SUCCESS',
  GET_EMPLOYERS_FAILURE: 'AdminEmployerConnector.GET_EMPLOYERS_FAILURE',
  GET_EMPLOYERS_REQUEST: 'AdminEmployerConnector.GET_EMPLOYERS_REQUEST',
  GET_EMPLOYERS_SUCCESS: 'AdminEmployerConnector.GET_EMPLOYERS_SUCCESS',
  HANDLE_CLOSE_MODAL: 'AdminEmployerConnector.HANDLE_CLOSE_MODAL',
  HANDLE_NEW_EMPLOYER: 'AdminEmployerConnector.HANDLE_NEW_EMPLOYER',
  HANDLE_UPDATE_EMPLOYER: 'AdminEmployerConnector.HANDLE_UPDATE_EMPLOYER',
  SET_PROFILE_FILTER: 'AdminEmployerConnector.SET_PROFILE_FILTER',
  SET_PROFILE_LOADING: 'AdminEmployerConnector.SET_PROFILE_LOADING',
  SHOW_EMPLOYER_FAILURE: 'AdminEmployerConnector.SHOW_EMPLOYER_FAILURE',
  SHOW_EMPLOYER_SUCCESS: 'AdminEmployerConnector.SHOW_EMPLOYER_SUCCESS',
  SHOW_JOB_SEEKING_PROFILE_FAILURE: 'AdminEmployerConnector.SHOW_JOB_SEEKING_PROFILE_FAILURE',
  SHOW_JOB_SEEKING_PROFILE_SUCCESS: 'AdminEmployerConnector.SHOW_JOB_SEEKING_PROFILE_SUCCESS',
  UPDATE_EMPLOYER_FAILURE: 'AdminEmployerConnector.UPDATE_EMPLOYER_FAILURE',
  UPDATE_EMPLOYER_SUCCESS: 'AdminEmployerConnector.UPDATE_EMPLOYER_SUCCESS',
};
