module.exports = (() => {
  const init = () => bindFunctions();

  const bindFunctions = () => {
    document.addEventListener('keyup', toggleSubmitButton);
    document.addEventListener('change', toggleSubmitButton);
    datePicker();
  };

  const datePicker = function () {
    $('.datepicker').datepicker({
      dateFormat: 'dd MM, yy',
      onSelect: () => toggleSubmitButton(),
    });
  };

  const toggleSubmitButton = () => {
    const button = document.querySelector("input[type='submit']");
    button.disabled = !$('form').valid();
  };

  return {
    init: init,
  };
})();
