import { Body, Button } from '@careerfoundry/bubble';
import {
  ButtonWrapper,
  StyledForm,
  UploadWrapper,
} from '../../../../AfaAdmissions/components/Tabs/BSG/styles';
import { IFile, UploadProps } from './interfaces';
import React, { useState, useEffect } from 'react';
import UploadButton from '../../../../InstructorOnboarding/InstructorProfile/components/Documents/UploadButton';
import { getFileData } from '../../../../InstructorOnboarding/InstructorProfile/components/PersonalInformation/helpers';
import { isUrl } from '../../../../InstructorOnboarding/InstructorProfile/helpers';
import { filePreview } from '../../../../../utils/filePreview';

const Upload: React.FC<UploadProps> = ({ fileData, handleFormSubmit }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<IFile>({
    data: '',
    fileName: '',
    fileSize: 0,
  });

  useEffect(() => {
    setFile({
      data: fileData.data || '',
      fileName: fileData.fileName || '',
      fileSize: 0,
    });
  }, [fileData]);

  useEffect(() => {
    setLoading(false);
  }, [handleFormSubmit]);

  const handleDelete = () => {
    setFile({
      data: '',
      fileName: '',
      fileSize: 0,
    });
  };

  const handleFileUpload = (data: Promise<IFile | string>) => {
    data.then((res: any) => {
      const file = {
        data: res.data,
        fileName: res.fileName,
        fileSize: res.fileSize,
      };
      setFile(file);
    });
  };

  const handleSave = (file: IFile, ev: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    handleFormSubmit(file, ev);
  };

  return (
    <StyledForm>
      {(!file.data || isUrl(file.data)) && (
        <UploadButton
          required={true}
          handleChange={(ev) => handleFileUpload(getFileData(ev))}
        />
      )}
      <UploadWrapper>
        {file.data &&
          (isUrl(file.data) ? (
            <a
              href={file.data}
              target='_blank'
              className='ds-text__link'
              rel='noreferrer'
            >
              {file.fileName}
            </a>
          ) : (
            <Body
              tag='p'
              variant='medium'
              onClick={() => filePreview(file.data)}
            >
              {file.fileName}
            </Body>
          ))}
        {file.data && !isUrl(file.data) && (
          <ButtonWrapper>
            <Button
              label='Save'
              isLoading={loading}
              onClick={(ev: React.MouseEvent<HTMLButtonElement>) =>
                handleSave(file, ev)
              }
              size='small'
              variant='primary'
            />
            <i className='ds-icon__trash' onClick={handleDelete}></i>
          </ButtonWrapper>
        )}
      </UploadWrapper>
    </StyledForm>
  );
};

export default Upload;
