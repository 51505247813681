import {
  CloseButton,
  FrozenBody,
  ModalBackground,
} from '../TheModal/styles';
import React, { useEffect, useState } from 'react';
import { FullScreenModalBox } from './styles/FullScreenModal';
import PropTypes from 'prop-types';

const FullScreenModal = ({ children, isModalOpen = false, handleClose, modalClassName = '' }) => {
  const [isOpen, setIsOpen] = useState(isModalOpen);

  useEffect(() => setIsOpen(isModalOpen), [isModalOpen]);

  const handleClick = () => handleClose ? handleClose() : setIsOpen(false);

  return <>
    {isOpen && (
      <>
        <FrozenBody />
        <ModalBackground onClick={handleClick}>
          <FullScreenModalBox onClick={e => e.stopPropagation()} className={modalClassName}>
            <CloseButton
              className="ds-icon__close"
              onClick={handleClick}
            />
            {children}
          </FullScreenModalBox>
        </ModalBackground>
      </>)}
  </>;
};

FullScreenModal.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func,
  isModalOpen: PropTypes.bool,
  modalClassName: PropTypes.string,
};

export default FullScreenModal;
