import styled from 'styled-components';

const StyledError = styled.p`
  color: var(--ds-color_error);
  font-style: italic;
  font-weight: 400;
  margin-bottom: 0 !important;
`;

StyledError.displayName = 'StyledError';

export default StyledError;
