import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  margin-top: -18px;
  margin-bottom: 30px;

  p {
    line-height: 18px;
  }

  .TheModal {
    width: 440px;

    .ds-icon__close-thin {
      display: none;
    }
  }
`;

const ModalContent = styled.div`
  h3,
  p {
    color: var(--ds-color__indigo);
  }

  i.fa-university {
    color: var(--ds-color__indigo);
    font-size: 32px;
    margin-bottom: 32px;
    position: static;
    display: block;
    text-align: center;
  }

  h3 {
    margin-bottom: 16px !important;
  }

  p.ds-typography__body {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }

  button.ds-button__primary {
    width: 70px;
    height: 40px;
    margin: 32px auto 0;
    display: block;
  }
`;

export {
  CheckboxWrapper,
  ModalContent,
};
