import Border from './styles/Border';
import PropTypes from 'prop-types';
import React from 'react';
import Table from './styles/Table';

const ConfidenceSurveys = ({ confidenceSurveys }) => (
  <>
    <Border />
    <p className='ds-typography__lead--bold'>Confidence Survey</p>
    <Table>
      <thead>
        <tr>
          <th></th>
          <th>Resume / Portfolio</th>
          <th>Interview</th>
          <th>Finding a job</th>
          <th>Starting a new role</th>
          <th>Average</th>
        </tr>
      </thead>
      <tbody>
        {confidenceSurveys.map((survey) => (
          <tr key={survey[0]}>
            {survey.map((result, i) => (
              <td
                key={`${survey[0]}_${result}_${i}`}
                className='ds-typography__body--bold'
              >
                {result}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  </>
);

ConfidenceSurveys.propTypes = {
  confidenceSurveys: PropTypes.array.isRequired,
};

export default ConfidenceSurveys;
