import {
  acceptPrivacyPolicy,
  setIsAcceptingPrivacyPolicy,
  setShouldShowDataPolicyAgreementModal,
} from '../../actions';
import DataPolicyAgreementModal from '../../components/DataPolicyAgreementModal';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const {
    common,
    isAcceptingPrivacyPolicy,
    shouldShowDataPolicyAgreementModal,
  } = state.sideNavList;

  return {
    activeCourseSlug: common.userDefinedActiveCourse?.friendlyCourseId || '',
    isAcceptingPrivacyPolicy,
    shouldShowDataPolicyAgreementModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  acceptPrivacyPolicy: (redirectToForum) =>
    dispatch(acceptPrivacyPolicy(redirectToForum)),
  setIsAcceptingPrivacyPolicy: (isAcceptingPrivacyPolicy) =>
    dispatch(setIsAcceptingPrivacyPolicy(isAcceptingPrivacyPolicy)),
  setShouldShowDataPolicyAgreementModal: (shouldShowDataPolicyAgreementModal) =>
    dispatch(
      setShouldShowDataPolicyAgreementModal(shouldShowDataPolicyAgreementModal)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataPolicyAgreementModal);
