import React from 'react';
import DiversitySurveyCard from './components/Profile/DiversitySurveyCard';
import { createRoot } from 'react-dom/client';

const diversitySurveyCardContainers = Array.from(
  // there are two containers; for desktop and mobile
  document.getElementsByClassName('diversity-survey-card')
);

diversitySurveyCardContainers.map((diversitySurveyCardContainer) => {
  const diversitySurveyCardContainerRoot = createRoot(
    diversitySurveyCardContainer
  );

  diversitySurveyCardContainerRoot.render(<DiversitySurveyCard />);
});
