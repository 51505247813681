import ExerciseRating from '../../components/ExerciseRating';
import { connect } from 'react-redux';
import { submitRating } from '../../actions';

export default connect(
  ({ taskSubmission: { ratings, showTimeSurvey } }) => ({
    ratings,
    showTimeSurvey,
  }),
  (dispatch: any) => ({
    submitRating: (exerciseRating: number, surveyQuestionId: number) =>
      dispatch(submitRating(exerciseRating, surveyQuestionId)),
  })
)(ExerciseRating);
