import styled from 'styled-components';

const StyledCardText = styled.div`
  span {
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    p {
      margin-top: 16px;
    }  
  }
  @media only screen and (min-width: 768px) {
    p {
      margin-bottom: 0;
    }
  }
`;

StyledCardText.displayName = 'StyledCardText';

export default StyledCardText;
