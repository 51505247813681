import PropTypes from 'prop-types';
import React from 'react';
import StyledError from '../../styles/StyledError';

const ErrorText = ({ placeholder, error }) => (
  <StyledError className="ds-typography__small--bold error-text">{placeholder} {error}</StyledError>
);

ErrorText.propTypes = {
  error: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default ErrorText;
