import styled from 'styled-components';

const StyleAttachmentPreview = styled.div`
  text-align: center;
  font-size: 70%;
  flex-wrap: wrap;
  justify-content: space-around;
  display: inline-block;
  border: 1px solid $clr-cancel;
  padding: 7px;
  min-width: 80px;
  transition: border-color 300ms;
  -webkit-transition: border-color 300ms;
  &:hover {
    border-color: $clr-petrol;
    color: $clr-petrol;
  }
  .fa {
    font-size: 3em;
    margin: 5px;
    color: $clr-cancel;
    transition: color 300ms;
    -webkit-transition: color 300ms;
  }
  .fa {
    &:hover {
      color: $clr-petrol;
    }
  }
  position: relative;
  margin-bottom: 28px;
  margin-top: 14px;
  .fa.fa-times-circle {
    cursor: pointer;
    color: $clr-petrol;
    background-color: $clr-white;
    font-size: 1.5em;
    position: absolute;
    top: -13px;
    right: -12px;
  }
  &:hover {
    .fa.fa-times-circle {
      color: $clr-petrol;
    }
  }
`;

StyleAttachmentPreview.displayName = 'StyleAttachmentPreview';

export default StyleAttachmentPreview;
