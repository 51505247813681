import styled from 'styled-components';

const StyledLoading = styled.div`
  min-height: 610px;
  background: white;
  padding: 305px 0;
`;

StyledLoading.displayName = 'StyledLoading';

export default StyledLoading;
