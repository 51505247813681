import { setPasswordStrength } from '../../../utils/insertZXCVBNScript';

module.exports = (() => {
  const thisState = {};

  const togglePasswordVisibility = _ => {
    const passwordField = document.querySelector('#new-password');
    if (passwordField.type === 'text') {
      passwordField.type = 'password';
    } else {
      passwordField.type = 'text';
    }
  };

  const init = function () {
    thisState.form = new CF.Form('#register-form');
    const toggle = document.querySelector('#show_password_toggle');
    toggle.addEventListener('click', togglePasswordVisibility);
    window.insertZXCVBNScript();
    const passwordField = document.querySelector('#new-password');
    passwordField.addEventListener('input', setPasswordStrength);
  };

  return {
    init: init,
  };
})();
