import { createAttachment, handleUpload } from '../Uploader/actions';
import ApiService from '../../services/ApiService';
import { constants } from './constants';
import { getAuthToken } from '../../utils/getAuthToken';

export const handleBack = () => ({
  type: constants.HANDLE_BACK,
});

export const setShareWithMentor = (shared) => ({
  data: shared,
  type: constants.SET_SHARE_WITH_MENTOR,
});

export const setInterviewStage = (interviewStage) => ({
  data: interviewStage,
  type: constants.SET_INTERVIEW_STAGE,
});

export const setCurrentAnswer = (currentAnswer) => ({
  currentAnswer: { ...currentAnswer },
  type: constants.SET_CURRENT_ANSWER,
});

export const setReviewRating = (reviewRating) => ({
  reviewRating: { ...reviewRating },
  type: constants.SET_REVIEW_RATING,
});

export const setReviewNote = (reviewNote) => ({
  reviewNote: { ...reviewNote },
  type: constants.SET_REVIEW_NOTE,
});

export const setReviewNavigable = (isReviewNavigable) => ({
  isReviewNavigable,
  type: constants.SET_REVIEW_NAVIGABLE,
});

export const checkIfAttachmentFailed = (attachments) => {
  return !!(attachments && attachments.type !== 'danger');
};

export const checkIfUploadFailed = (response) => {
  return !!(response && response.url);
};

const handleUploadAudio = async (file, userId) => {
  const response = await handleUpload(file, 'interview_answer', userId);

  const uploadSuccessful = checkIfUploadFailed(response);
  if (!uploadSuccessful) {
    return null;
  }

  return response;
};

const handleCreateAttachment = async (response, id) => {
  const attachments = await createAttachment(
    response,
    'InterviewAnswer',
    null,
    id
  );

  const hasAttachment = checkIfAttachmentFailed(attachments);
  if (!hasAttachment) {
    return null;
  }

  return attachments;
};

export const getGuidedTourSteps = async () => {
  const params = {
    tour_area: 'InterviewPrepEvaluation',
  };

  const apiService = new ApiService('/api/v1/guided_tours');
  try {
    const result = await apiService.makeGetCall(params);
    return result.data.data;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const getInterviewAnswers = () => async (dispatch) => {
  const apiService = new ApiService('/api/v1/interview_answers');
  try {
    const result = await apiService.makeGetCall();
    dispatch({
      data: result.data.data,
      type: constants.GET_INTERVIEW_ANSWERS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error,
      type: constants.GET_INTERVIEW_ANSWERS_FAILURE,
    });
  }
};

export const getInterviewQuestions = (category) => async (dispatch) => {
  const params = {
    category,
  };

  const apiService = new ApiService('/api/v1/interview_questions');
  try {
    const result = await apiService.makeGetCall(params);
    dispatch({
      data: result.data.data,
      type: constants.GET_INTERVIEW_QUESTIONS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error,
      type: constants.GET_INTERVIEW_QUESTIONS_FAILURE,
    });
  }
};

export const answerQuestion =
  (interviewId, questionId, file, shared = false) =>
  async (dispatch) => {
    const params = {
      authenticity_token: getAuthToken(),
      interview_id: interviewId,
      interview_question_id: questionId,
      shared,
    };

    const apiService = new ApiService('/api/v1/interview_answers');
    try {
      const result = await apiService.makePostCall(params);
      const data = result.data.data;
      const response = await handleUploadAudio(file, data.userId);
      const attachments = await handleCreateAttachment(response, data.id);

      if (!attachments) {
        return null;
      }

      dispatch({
        data: { ...data, attachment: attachments.data },
        type: constants.ANSWER_QUESTION_SUCCESS,
      });

      return data;
    } catch (error) {
      console.log(`Something went wrong... ${error}`);
      return null;
    }
  };

export const deleteAnswer = (answerId) => async (dispatch) => {
  const params = {
    data: {
      authenticity_token: getAuthToken(),
    },
  };

  const apiService = new ApiService(`/api/v1/interview_answers/${answerId}`);
  try {
    const result = await apiService.makeDeleteCall(params);
    dispatch({
      data: result.data.data,
      type: constants.DELETE_ANSWER_SUCCESS,
    });
    return result.data.data;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const evaluateAnswer = (answerId, params) => async (dispatch) => {
  const formattedParams = {
    ...params,
    authenticity_token: getAuthToken(),
  };

  const apiService = new ApiService(`/api/v1/interview_answers/${answerId}`);
  try {
    const result = await apiService.makePutCall(formattedParams);
    dispatch({
      data: result.data.data,
      type: constants.EVALUATE_ANSWER_SUCCESS,
    });
    return result.data.data;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const handleShareAnswer = (shared, interviewId) => async (dispatch) => {
  const formattedParams = {
    authenticity_token: getAuthToken(),
    id: interviewId,
    shared,
  };

  const apiService = new ApiService('/api/v1/interviews/share_all_answers');
  try {
    dispatch(setShareWithMentor(!!shared));
    const result = await apiService.makePutCall(formattedParams);
    return result;
  } catch (error) {
    dispatch(setShareWithMentor(!shared));
    console.log(`Something went wrong... ${error}`);
  }
};

export const evaluatePreviousResponses = (answer) => ({
  data: answer,
  type: constants.EVALUATE_PREVIOUS_RESPONSES,
});
