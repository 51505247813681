import React from 'react';

const ActivationSuccess = () => {
  const goBack = () =>
    window.location.replace('/en/admin/instructors/candidates/');

  return (
    <>
      <i className="ds-icon__check-circle-line"></i>
      <h3 className="ds-typography__h3">Activated</h3>
      <p className="ds-typography__lead">
        This candidate has been activated and will soon receive an email
        notification regarding their status.
      </p>
      <button className="ds-button__primary" onClick={goBack}>
        Got it
      </button>
    </>
  );
};

export default ActivationSuccess;
