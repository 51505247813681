import App from './components/App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const mentorBillingProfileContainer = document.getElementById(
  'updateBillingProfile'
);

if (mentorBillingProfileContainer) {
  const mentorBillingProfileContainerRoot = createRoot(
    mentorBillingProfileContainer
  );

  mentorBillingProfileContainerRoot.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}
