import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledFeedbackButton from './styles/StyledFeedbackButton';
import TheModal from '../TheModal';
import Typeform from '../Typeform';

const FeedbackButton = ({
  formId,
  submitAction,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleSubmit = () => {
    setTimeout(() => {
      setModalIsOpen(false);
    }, 3000);
  };

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <StyledFeedbackButton>
      <button onClick={toggleModal} className="ds-button__secondary">Give us feedback!</button>
      <TheModal
        isModalOpen={modalIsOpen}
        handleClose={toggleModal}
      >
        <Typeform
          formId={formId}
          handleSubmit={submitAction || handleSubmit}
        />
      </TheModal>
    </StyledFeedbackButton>
  );
};

FeedbackButton.propTypes = {
  formId: PropTypes.string.isRequired,
  submitAction: PropTypes.func,
};

export default FeedbackButton;
