import DisabledTooltipContent from '../../DisabledTooltipContent';
import Icon from '../../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { MyMentorCallsIcon as SVGIcon } from '../../SideNavList/icons';
import { trackAndRedirect } from '../../../helpers';

const MyMentorCallsIcon = ({
  isDisabled = true,
  isVisible = false,
  areAllCoursesExpired = false,
  isStudent,
  currentPageName,
  onClickRedirectTo,
  ...rest
}) => (
  <Icon
    isDisabled={isDisabled}
    isSelected={currentPageName === 'MY_MENTOR_CALLS'}
    isVisible={isVisible}
    onClick={() =>
      !isDisabled &&
      trackAndRedirect({
        redirect: {
          url: onClickRedirectTo,
        },
        track: {
          itemName: 'mentorcalls',
          shouldTrack: isStudent,
        },
      })
    }
    renderSVGIcon={() => <SVGIcon />}
    nameForMobileView="My mentor calls"
    tooltipContent={
      isDisabled && !areAllCoursesExpired ? (
        <DisabledTooltipContent
          title="My mentor calls"
          description="Unlocked after course/program start"
        />
      ) : (
        'My mentor calls'
      )
    }
    {...rest}
  />
);

MyMentorCallsIcon.propTypes = {
  ...Icon.propTypes,
  areAllCoursesExpired: PropTypes.bool,
  currentPageName: PropTypes.string,
  isDisabled: PropTypes.bool,
  isStudent: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClickRedirectTo: PropTypes.string,
};

export default MyMentorCallsIcon;
