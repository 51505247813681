export const iconFromName = (name) => {
  switch (name.toLowerCase()) {
    case 'behance':
      return 'fa-behance';
    case 'dribbble':
      return 'fa-dribbble';
    case 'facebook':
      return 'fa-facebook-f';
    case 'github':
      return 'fa-github';
    case 'google':
      return 'fa-google-plus';
    case 'linkedin':
      return 'fa-linkedin';
    case 'pinterest':
      return 'fa-pinterest';
    case 'stackoverflow':
      return 'fa-stack-overflow';
    case 'twitter':
      return 'fa-twitter';
    case 'youcanbookme':
      return 'fa-calendar';
    case 'website':
      return 'fa-globe';
    case 'blog':
      return 'fa-globe';
    default:
      return 'fa-question';
  }
};

export const linkFromSocial = (name) => {
  switch (name.toLowerCase()) {
    case 'behance':
      return 'https://behance.net/';
    case 'dribbble':
      return 'https://dribbble.com/';
    case 'facebook':
      return 'https://facebook.com/';
    case 'github':
      return 'https://github.com/';
    case 'google':
      return 'https://google.com/';
    case 'linkedin':
      return 'https://linkedin.com/';
    case 'pinterest':
      return 'https://www.pinterest.com/';
    case 'stackoverflow':
      return 'https://stackoverflow.com/';
    case 'twitter':
      return 'https://twitter.com/';
  }
};
