import styled from 'styled-components';

const EvaluationHeader = styled.div`
  background-color: #e8ebed;
  padding: 20px;
  p {
    margin-bottom: 0;
  }
  h4 {
    margin: 0;
    line-height: 56px;
  }
  ${({ isModal }) => !isModal && `
    h4 {
      font-size: 28px;
      font-weight: 500;
      line-height: 1.4;
    }
  `}
  @media (max-width: 800px) {
    padding: 35px;
    h4 {
      line-height: 1.3;
    }
  }

  @media (max-width: 768px) {
    ${({ isModal }) => !isModal && `
      padding: 16px 61px 24px;

      h4 {
        font-size: 24px;
      }
    `}
  }
`;

EvaluationHeader.displayName = 'EvaluationHeader';

export default EvaluationHeader;
