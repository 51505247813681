import { CAREER_SUPPORT_CENTER } from '../../../constants';
import CareerSupportCenterIcon from '../../../components/icons/CareerSupportCenterIcon';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { courseNavigationData: courses, common, meData } = state.sideNavList;
  const roles = meData.roles || [];
  const isCareerSupportSpecialist = roles.includes('career_support_specialist');
  const careerSupportCenterCourse = courses.find(
    (course) => course.name === CAREER_SUPPORT_CENTER
  );

  return {
    isDisabled:
      !isCareerSupportSpecialist &&
      careerSupportCenterCourse?.status !== 'active',
    isStudent: common.isStudent,
    isVisible:
      common.isUserActive &&
      (!!careerSupportCenterCourse || isCareerSupportSpecialist),
  };
};

const CareerSupportCenterIconContainer = (props) => {
  return <CareerSupportCenterIcon {...props} />;
};

CareerSupportCenterIconContainer.propTypes = {
  ...CareerSupportCenterIcon.propTypes,
};

export default connect(mapStateToProps)(CareerSupportCenterIconContainer);
