import React, { useMemo } from 'react';
import AccountLinks from '../shared/AccountLinks';
import FileUploader from '../shared/FileUploader';
import FileUploaderView from '../showModal/styles/FileUploaderView';
import FormField from '../shared/FormField';
import PropTypes from 'prop-types';
import { updateSocialLinks } from '../../../CareerSupportCenter/actions';

const ApplicationPackage = ({
  updateECProfile,
  jobSeekingProfile = {},
  isEditView = true,
}) => {
  const socialsUrls = useMemo(
    () => (jobSeekingProfile.socials ? jobSeekingProfile.socials : []),
    [jobSeekingProfile.socials],
  );

  const handleAttachmentChange = (data) => {
    const { attachment } = data;
    updateECProfile({
      field: 'attachment',
      value: attachment,
    });
  };

  const linkedInUrl = useMemo(() => socialsUrls.find((social) => social.name.toLowerCase() === 'linkedin'), [socialsUrls]);
  const otherUrls = useMemo(() => socialsUrls.filter((social) => social.name.toLowerCase() !== 'linkedin'), [socialsUrls]);
  const textRequired = isEditView ? '*' : '';

  return (
    <>
      <FormField labelText={`CV${textRequired}`} sublabel=".pdf, less than 10MB">
        {
          !isEditView
            ? <FileUploaderView attachment={jobSeekingProfile.attachment || { fileIcon: 'empty' }}/>
            : <FileUploader
              subtype="cv"
              previousAttachment={jobSeekingProfile.attachment}
              attachableType="JobSeekingProfile"
              attachableId={jobSeekingProfile.id}
              fileType="pdf"
              onAttachmentChange={handleAttachmentChange}
              maxSize={10}
              allowedFormat={['application/pdf']}
              hardDelete={false}
            />
        }
      </FormField>
      <AccountLinks
        linkedInUrl={linkedInUrl}
        updateECProfile={updateECProfile}
        socialsUrls={socialsUrls}
        otherUrls={otherUrls}
        portfolio={jobSeekingProfile.portfolio}
        onDeleteSocial={updateSocialLinks}
        textRequired={textRequired}
        isEditView={isEditView}
      />
    </>
  );
};

ApplicationPackage.propTypes = {
  isEditView: PropTypes.bool,
  jobSeekingProfile: PropTypes.shape({
    attachment: PropTypes.shape({
      attachableId: PropTypes.number,
      attachableType: PropTypes.string,
      contentType: PropTypes.string,
      fileIcon: PropTypes.string,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      id: PropTypes.number,
      s3Url: PropTypes.string,
      subtype: PropTypes.string,
    }),
    country: PropTypes.string,
    graduated: PropTypes.bool,
    graduationDate: PropTypes.string,
    id: PropTypes.number.isRequired,
    motivationStatement: PropTypes.string,
    portfolio: PropTypes.string,
    program: PropTypes.string,
    progress: PropTypes.number,
    socials: PropTypes.array,
    specialization: PropTypes.string,
    state: PropTypes.string,
    status: PropTypes.string.isRequired,
    studentName: PropTypes.string,
    willingToWorkRemote: PropTypes.bool,
  }),
  updateECProfile: PropTypes.func.isRequired,
};

export default ApplicationPackage;
