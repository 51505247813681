import { DocumentLink, Label, Section, SectionHeading } from '../styles';
import PropTypes from 'prop-types';
import React from 'react';

const TaxFields = ({ taxId, vatId, taxDoc, vatDoc }) => (
  <Section>
    <SectionHeading className="ds-typography__h5">
      Tax Information
    </SectionHeading>
    <div className="ds-grid__row">
      <div className="ds-grid__col-xs-6">
        {taxId && (
          <>
            <Label className="ds-typography__body-larger">Tax number</Label>
            <p className="ds-typography__body">{taxId}</p>
            <DocumentLink
              href={taxDoc.data}
              target="_blank"
              rel="noreferrer"
              className="ds-typography__body"
            >
              {taxDoc.file_name}
            </DocumentLink>
          </>
        )}
      </div>
    </div>
    <div className="ds-grid__row">
      <div className="ds-grid__col-xs-6">
        {vatId && (
          <>
            <Label className="ds-typography__body-larger">VAT ID</Label>
            <p className="ds-typography__body">{vatId}</p>
            <DocumentLink
              href={vatDoc.data}
              target="_blank"
              rel="noreferrer"
              className="ds-typography__body"
            >
              {vatDoc.file_name}
            </DocumentLink>
          </>
        )}
      </div>
    </div>
  </Section>
);

TaxFields.propTypes = {
  taxDoc: PropTypes.shape({
    data: PropTypes.string,
    file_name: PropTypes.string,
  }),
  taxId: PropTypes.string,
  vatDoc: PropTypes.shape({
    data: PropTypes.string,
    file_name: PropTypes.string,
  }),
  vatId: PropTypes.string,
};

export default TaxFields;
