import styled from 'styled-components';

const ReviewerImage = styled.img`
  border-radius: 50%;
  display: inline-block;
  height: 35px;
  width: 35px;
`;

ReviewerImage.displayName = 'ReviewerImage';

export default ReviewerImage;
