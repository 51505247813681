import styled from 'styled-components';

const StyledDropdown = styled.div`
  cursor: pointer;
  .action-button {
    display: block;
    width: fit-content;
  }

  select {
    border: none;
    width: 90%;
    background: transparent;
    cursor: pointer;
    height: 40px;
  }

  height: 56px;
    min-width: 332px;
    margin-left: 56px;
    font-size: 16px;
    font-family: var(--ds-font__dinpro--cond)
    color: var(--ds-color__indigo);
    border: none;
    box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);
    background: rgba(92, 162, 178, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &:focus {
      border: none;
    }

    &:hover {
      border: none;
    }
    
    select:focus-visible {
      outline: none;
    }
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }

  @media (max-width: 400px) {
    min-width: 290px;
  }
`;

StyledDropdown.displayName = 'StyledDropdown';

export default StyledDropdown;
