import styled from 'styled-components';

const StyledCheckbox = styled.div`
  width: 25px;
  height: 25px;
  margin: 0 10px 0 0;
  background-color: var(--ds-color__white);
  display: flex;
  align-items: center;
  border: 2px solid #89a7da;
  justify-content: center;

  input {
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }
  p {
    margin: 4px 0 0 !important;
    font-size: 14px;
    line-height: 1 !important;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
  p {
    color: #5058AF !important;
  }
  `}
`;

StyledCheckbox.displayName = 'StyledCheckbox';

export default StyledCheckbox;
