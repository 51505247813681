import styled from 'styled-components';

const StyledRecorder = styled.div`
  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .counter {
    margin: 0 16px 52px;
    font-family: var(--ds-font__tradegothic);
    font-size: 40px;
    color: var(--ds-color__indigo);
    padding: 0;
    font-weight: normal;
  }

  .recorder-header {
    font-family: var(--ds-font__dinpro--cond);
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0;
  }

  h1,
  .audio-tips {
    text-align: center;
  }

  h2 {
    text-align: center;
    font-weight: normal;
    padding: 2rem 0;
  }

  .main-controls {
    padding: 0.5rem 0;
  }

  .audio-wrapper {
    width: 490px;
    height: 114px;
    margin: 32px auto 24px;
    padding: 30px 40px 22px;
    border-radius: 58px;
    background-color: var(--white);
  }

  .visualizer-section {
    margin-bottom: 0.5rem;
  }

  #buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #buttons a {
    width: calc(35% - 0.25rem);
    margin: 8px 12px 0 12px;
  }

  #buttons .ds-button__primary {
    border: 2px solid #223c50;
  }

  #buttons .ds-button__petrol {
    border: 2px solid var(--ds-color__petrol);
  }

  #buttons .ds-button__secondary {
    background: var(--ds-color__white) !important;
  }

  #buttons .disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  a {
    font-size: 1rem;
    text-align: center;
    transition: all 0.2s;
    padding: 0.5rem;
  }

  a:active {
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.5);
    transform: translateY(2px);
  }

  section,
  article {
    display: block;
  }

  .clip {
    padding-bottom: 1rem;
  }

  .clip p {
    display: inline-block;
    font-size: 1rem;
  }

  .clip button {
    font-size: 1rem;
    float: right;
  }

  label {
    font-size: 3rem;
    position: absolute;
    top: 2px;
    right: 3px;
    z-index: 5;
    cursor: pointer;
    background-color: black;
    border-radius: 10px;
  }

  aside {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    transition: 0.3s all ease-out;
    background-color: #efefef;
    padding: 1rem;
  }

  aside p {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }

  aside a {
    color: #666;
  }

  /* Toggled State of information box */

  input[type='checkbox']:checked ~ aside {
    transform: translateX(0);
  }

  /* Cursor when clip name is clicked over */

  .clip p {
    cursor: pointer;
  }

  /* Adjustments for wider screens */

  @media (max-width: 1050px) {
    #buttons a {
      width: calc(40% - 0.25rem);
    }
  }

  @media (max-width: 800px) {
    #buttons {
      flex-direction: column;
      align-items: center;
    }
    #buttons a {
      width: calc(95% - 0.25rem);
      max-width: unset;
    }
    .audio-wrapper {
      padding: 30px 9px 22px;
      border-radius: 21px;
      width: 95%;
    }
  }

  @media all and (min-width: 800px) {
    /* Don't take all the space as readability is lost when line length
        goes past a certain size */
    .wrapper {
      width: 90%;
      max-width: 1000px;
      margin: 0 auto;
    }
  }
`;

StyledRecorder.displayName = 'StyledRecorder';

export default StyledRecorder;
