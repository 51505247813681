import styled from 'styled-components';

const StyledSectionContent = styled.div`
@media only screen and (min-width: 768px) {
  a {
    display: block;
    width: fit-content;
  }
}
`;

StyledSectionContent.displayName = 'StyledSectionContent';

export default StyledSectionContent;
