import PropTypes from 'prop-types';
import React from 'react';
import { StyledModal } from '../styles';

const ModalContent = ({ handleDelete, handleClose }) => (
  <StyledModal>
    <i className="ds-icon__info-line"></i>
    <h3 className="ds-typography__h3">Comment deletion</h3>
    <p className="ds-typography__lead">
      Please confirm that you want to delete the comment.
    </p>
    <button className="ds-button__secondary" onClick={handleClose}>
      Discard
    </button>
    <button className="ds-button__primary" onClick={handleDelete}>
      Delete
    </button>
  </StyledModal>
);

ModalContent.propTypes = {
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default ModalContent;
