import styled from 'styled-components';

const EvaluationInfo = styled.div`
  padding: 24px 20px;
  .audio-wrapper {
    height: 114px;
    margin: 16px auto 24px;
    padding: 30px 40px 22px;
    border-radius: 58px;
    background-color: #f4f5f6;
  }
  .audio-wrapper div {
    background: #f4f5f6;
  }
  .audio-wrapper audio::-webkit-media-controls-panel {
    background-color: #f4f5f6;
  }

  h5 {
    line-height: 1.8;
    font-size: 20px;
  }

  h5 span {
    font-size: 14px;
    font-family: var(--ds-font__tradegothic);
  }

  @media(max-width: 800px) {
    .audio-wrapper {
      padding: 30px 9px 22px;
      border-radius: 21px;
    }
  }

  @media(max-width: 768px) {
    ${({ isModal }) => !isModal && `
      padding: 24px;

    `}
  }

`;

EvaluationInfo.displayName = 'EvaluationInfo';

export default EvaluationInfo;
