import styled from 'styled-components';

const StyledJobApplicationTracker = styled.div`
    background: #fff;
    min-height: 600px;
    padding-bottom: 20px;
    margin-bottom: -10px;

    a {
      min-height: 40px;
    }

    header {
      h1 {
        text-align: left;
      }

      p {
        margin-bottom: 28px;
      }
    }
    table {
      margin-bottom: 40px;
    }
    table > thead {
      background-color: var(--ds-color__indigo);
      color: $clr-white;
      th {
        text-align: center;
      }
    }

    table > tbody > tr > td {
      text-align: center;
    }

    table > tbody > tr:nth-child(even) > td,
    table > tbody > tr:nth-child(even) > th {
      background-color: #eef0f1;
    }
    .line {
      margin: 40px 0px;
      border: solid 2px #e8ebed
    }

    .dropdown > select {
      padding: 2px;
    }

    .margin-right {
      margin-right: 15px;
    }
  }
`;

StyledJobApplicationTracker.displayName = 'StyledJobApplicationTracker';

export default StyledJobApplicationTracker;
