import {
  handleFileUpload,
  handleTaxChange,
  removeFile,
  removeTaxInput,
} from './actions';
import TaxInputs from '../../../components/PersonalInformation/TaxInputs';
import { connect } from 'react-redux';
import { handleSave } from '../actions';

export const mapStateToProps = ({
  instructorProfile: {
    formErrors,
    personalData,
    region,
    taxDocumentsRequired,
    taxInputs,
  },
}) => ({
  formErrors,
  personalData,
  region,
  taxDocumentsRequired,
  taxInputs,
});

export const mapDispatchToProps = (dispatch) => ({
  handleFileUpload: (key, data) => dispatch(handleFileUpload(key, data)),
  handleSave: (name, value) => dispatch(handleSave(name, value)),
  handleTaxChange: (event) => dispatch(handleTaxChange(event)),
  removeFile: (key) => dispatch(removeFile(key)),
  removeTaxInput: (key) => dispatch(removeTaxInput(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaxInputs);
