import React, { useEffect, useState } from 'react';
import ButtonWithIcon from '../../../shared/components/ButtonWithIcon';
import Card from './styles/Card';
import Header from './styles/Header';
import InterviewForm from '../../containers/InterviewInstructions';
import InterviewQuestion from '../InterviewQuestion';
import InterviewReview from '../../containers/InterviewReview';
import InterviewTypeSelect from '../../containers/InterviewTypeSelect';
import Label from './styles/Label';
import PropTypes from 'prop-types';
import StyledInterviewTool from './styles/StyledInterviewTool';

const getNextQuestion = (
  questions,
  currentQuestion,
  setFinished,
  setCurrentQuestion
) => {
  if (questions.length === questions.indexOf(currentQuestion) + 1) {
    setFinished(true);
  } else {
    setCurrentQuestion(questions[questions.indexOf(currentQuestion) + 1]);
  }
};

const InterviewTool = ({
  answerQuestion,
  handleBack,
  interviewId,
  interviewStage,
  shareWithMentor,
  questions,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [errorsaving, setErrorSaving] = useState(false);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (questions) {
      setCurrentQuestion(questions[0]);
    }
  }, [questions]);

  const handleAnswer = async (questionId, file) => {
    answerQuestion(interviewId, questionId, file, shareWithMentor)
      .then((res) => {
        if (res && res.id) {
          getNextQuestion(
            questions,
            currentQuestion,
            setFinished,
            setCurrentQuestion
          );
        } else {
          setErrorSaving(true);
        }
      })
      .catch(() => {
        setErrorSaving(true);
      });
  };

  const handleCancelErrorSaving = () => {
    setErrorSaving(false);
  };

  const isReview = interviewStage === 'review';

  return (
    <StyledInterviewTool>
      <div className="ds-grid__container wrapper">
        <div className="ds-grid__row">
          <div className="ds-grid__col-xs-12">
            <Card isReview={isReview}>
              <Header isReview={isReview}>
                <ButtonWithIcon
                  onClick={() => handleBack()}
                  icon="arrow-left"
                  text="Back to Dashboard"
                />
                <Label className="training-label">
                  <h5 className="ds-typography__h5">Interview Training</h5>
                </Label>
              </Header>
              {
                {
                  practice: (
                    <InterviewQuestion
                      question={currentQuestion}
                      handleAnswer={handleAnswer}
                      questions={questions}
                      finished={finished}
                      errorsaving={errorsaving}
                      onCancelErrorSaving={handleCancelErrorSaving}
                    />
                  ),
                  review: (
                    <InterviewReview questions={questions} isModal={false} />
                  ),
                  selectInterviewType: <InterviewTypeSelect />,
                  start: <InterviewForm />,
                }[interviewStage]
              }
            </Card>
          </div>
        </div>
      </div>
    </StyledInterviewTool>
  );
};

InterviewTool.propTypes = {
  answerQuestion: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  interviewId: PropTypes.number,
  interviewStage: PropTypes.string.isRequired,
  questions: PropTypes.array,
  shareWithMentor: PropTypes.bool,
};

export default InterviewTool;
