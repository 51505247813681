const statusConstants = {
  RECORDING: 'recording',
  INACTIVE: 'inactive',
  PAUSED: 'paused',
  FINISHED: 'finished',
  TIME_ELAPSED: 'timeElapsed',
  SUBMITTING_RECORDING: 'submittingRecording',
  ERROR_SAVING: 'errorSaving',
};

export default statusConstants;
