import React, { useEffect } from "react";
import { EnrollType } from "../../../utils/enrollTypes";
import PreselectedPlanView from "./PreselectedPlanView";
import PropTypes from 'prop-types';
import SelectablePlanView from "./SelectablePlanView";
import { formatDropdownFromParams } from "../../../utils/formatDropdown";
import { keysToCamel } from "../../../utils/keysConverter";

const SelectPlanSlide = props => {
  props = keysToCamel(props);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentEnrollType = props.enrollType;
  const plans = props.courseParams.selectablePlans;

  const getMainText = () => {
    if (currentEnrollType === EnrollType.offer || currentEnrollType === EnrollType.normal) {
      return plans[0].title;
    } else if (currentEnrollType === EnrollType.bank) {
      return `Pay total ${props.productType} fee`;
    } else {
      return `Your ${props.productType} is paid by ${props.courseParams.customEnroll.payerName}`;
    }
  };

  const getSubText = () => {
    if (currentEnrollType === EnrollType.offer || currentEnrollType === EnrollType.normal) {
      return plans[0].text;
    } else if (currentEnrollType === EnrollType.bank) {
      return `You will pay the full ${props.productType} fee of ${plans[0].total} today.`;
    } else {
      return "";
    }
  };

  if (plans.length > 1) {
    return <SelectablePlanView {...props} />;
  } else {
    return <PreselectedPlanView mainText={getMainText()}
      subText={getSubText()}
      productType={props.productType}
      datesArray={formatDropdownFromParams(props.courseParams.startDates, "formatted", "value")}
      enrollType={props.enrollType}
      onStartChange={props.onStartChange}
      onSubmit={props.onNextClick} />;
  }
};

SelectPlanSlide.propTypes = {
  courseParams: PropTypes.shape({
    customEnroll: PropTypes.shape({
      payerName: PropTypes.string.isRequired,
    }),
    selectablePlans: PropTypes.array.isRequired,
    startDates: PropTypes.array.isRequired,
  }),
  enrollType: PropTypes.string.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onStartChange: PropTypes.func.isRequired,
  productType: PropTypes.string.isRequired,
};

export default SelectPlanSlide;
