import styled from 'styled-components';

const StyledModalHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  @media (max-width: 479px) {
    margin-top: 24px;
  }
  p {
    margin: 0;
  }
  .img-circle {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`;

StyledModalHeader.displayName = 'StyledModalHeader';

export default StyledModalHeader;
