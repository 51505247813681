import ProfessionalStatement from '../../modals/optInModal/ProfessionalStatement';
import { connect } from 'react-redux';
import { updateECProfile } from '../../actions';
import { updateJobSeekingProfile } from '../../../CareerSupportCenter/actions';

export const mapDispatchToProps = (dispatch) => ({
  updateECProfile: (data) => dispatch(updateECProfile(data)),
  updateJobSeekingProfile: (jobSeekingProfile) => dispatch(updateJobSeekingProfile(jobSeekingProfile)),
});

export const mapStateToProps = ({
  careerSupportCenter: { jobSeekingProfile },
}) => ({
  jobSeekingProfile,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalStatement);
