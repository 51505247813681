import styled from 'styled-components';

const StyledInterviewTypeSelect = styled.form`
  h4 {
    margin-bottom: 24px;
    font-weight: bold;
  }

  p {
    margin-left: 20%;
  }

  .categories {
    margin-bottom: 32px;
    margin-left: 20%;
  }

  input[type='radio'] {
    margin-right: 11px;
    height: 20px;
    border: 2px solid #a7b1b9;

    margin-top: 0;
    width: 20px;
  }

  button {
    width: 313px;
    margin-top: 32px;
    text-align: center;
  }

  .category-select {
    cursor: pointer;
    font-size: 16px;
    line-height: 1.67;
    margin-bottom: 12px;
    align-items: center;
    display: flex;
  }

  @media (max-width: 768px) {
    button {
      width: 100%;
    }
    p {
      margin-left: 0%;
    }
    .categories {
      margin-bottom: 0px;
      margin-left: 0%;
    }
  }
`;

StyledInterviewTypeSelect.displayName = 'StyledInterviewTypeSelect';

export default StyledInterviewTypeSelect;
