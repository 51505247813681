import styled from 'styled-components';

const StyledStepper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  overflow: hidden;
  width: 50%;
  
  ::before {
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    left: 0;
    Content: "";
    background-color: var(--ds-color__grey--light);;
    height: 4px;
  }
  
  .icon {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
  }

  .grey {
    color: var(--ds-color__grey--light);
  }

  .circle-text {
    padding-top: 5px;
  }

  ${({ isClickable }) => isClickable && `
    .icon {
      cursor: pointer;
    }
  `};
`;

StyledStepper.displayName = 'StyledStepper';

export default StyledStepper;
