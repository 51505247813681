import PropTypes from 'prop-types';
import React from 'react';
import arrow from '../assets/arrowLeft.svg';

const PrevButton = ({ buttonRef, hidden }) => {
  return (
    <span
      className={`ds-button__tertiary tourBackButton ${hidden && 'hidden'}`}
      ref={buttonRef}>
      <img src={arrow} />
    </span>
  );
};

PrevButton.propTypes = {
  buttonRef: PropTypes.object,
  hidden: PropTypes.bool,
};

export default PrevButton;
