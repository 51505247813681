import MentorNote from '../../containers/MentorNotes/MentorNote';
import MentorNotesStyles from './styles/MentorNotes';
import PaginationControls from '../../../shared/components/Pagination';
import PropTypes from 'prop-types';
import React from 'react';
import { paginationCollection } from '../../../../utils/paginationCollection';

const MentorNotes = ({
  activePage,
  handleNewNote,
  mentorNotes,
  setActivePage,
}) => {
  const ITEMS_COUNT_PER_PAGE = 5;

  return (
    <MentorNotesStyles>
      <button onClick={handleNewNote} className='ds-button__petrol'>
        Add new note
      </button>
      {paginationCollection(mentorNotes, ITEMS_COUNT_PER_PAGE).map(
        (page, i) =>
          i + 1 === activePage && (
            <div key={`page_${i}`}>
              {page.map((note) => (
                <MentorNote key={note.id} note={note} />
              ))}
            </div>
          )
      )}
      {mentorNotes && mentorNotes.length > ITEMS_COUNT_PER_PAGE && (
        <PaginationControls
          activePage={activePage}
          itemsCountPerPage={ITEMS_COUNT_PER_PAGE}
          totalItemsCount={mentorNotes.length}
          pageRangeDisplayed={10}
          onChange={(pageNumber) => setActivePage(pageNumber)}
        />
      )}
    </MentorNotesStyles>
  );
};

MentorNotes.propTypes = {
  activePage: PropTypes.number.isRequired,
  handleNewNote: PropTypes.func.isRequired,
  mentorNotes: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      creatorId: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      shared: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
      updated: PropTypes.string.isRequired,
    })
  ),
  setActivePage: PropTypes.func.isRequired,
};

export default MentorNotes;
