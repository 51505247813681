import AttachmentPreview from '../../Uploader/components/AttachmentPreview';
import styled from 'styled-components';

const StyledAttachmentPreview = styled(AttachmentPreview)`
  width: 77px;
  height: 82px;
  margin: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0;
  min-width: unset;
  border: solid 1px #d8d8d8;
  i {
    color: #d8d8d8;
    background: #fff;
  }
  .fa-times-circle {
    color: #a9a9a9;
    font-size: 15px !important;
  }
  p {
    font-size: 9px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: auto;
    color: var(--ds-color__indigo);
  }
`;

StyledAttachmentPreview.displayName = 'StyledAttachmentPreview';

export default StyledAttachmentPreview;
