import React, { useEffect } from 'react';
import ButtonWithIcon from '../../../shared/components/ButtonWithIcon';
import { JOB_SEARCH_CATEGORIES } from './constants';
import StyledJobSearchTips from './styles/StyledJobSearchTips';
import { getCategorizedData } from '../../../shared/helpers/jobSearch';
import { userEventTracking } from '../../../shared/helpers/tracking';
import JobsMansoryTiles from '../JobsMansoryTiles';
import StyledJobTipsContainer from './styles/StyledJobTipsContainer';

const JobSearchTips = () => {
  const categories = getCategorizedData(JOB_SEARCH_CATEGORIES, 3);

  useEffect(() => {
    window.scrollTo(0, 0);
    userEventTracking(window.location.href, 'job_search_tips_open');
  }, []);

  return (
    <StyledJobSearchTips>
      <div className="ds-grid__container">
        <ButtonWithIcon
          href="/en/career-support-center"
          icon="arrow-left"
          type="secondary"
          text="Back to career support center"
        />
        <h2 className="ds-typography__h2 text-left">Job Search Tips</h2>
        <StyledJobTipsContainer>
          <JobsMansoryTiles
            tilesCategories={categories}
            jobType="job_search_tips"
            linkEventName="job_search_tips_link_clicked"
          />
        </StyledJobTipsContainer>
      </div>
    </StyledJobSearchTips>
  );
};

export default JobSearchTips;
