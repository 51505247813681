import AccordionSection from '../../../../shared/components/AccordionSection';
import CallsTile from '../components/CallsTile';
import CommunityEventTile from '../components/CommunityEventTile';
import CommunityTile from '../components/CommunityTile';
import MobileAccordionWrapper from './MobileAccordionWrapper';
import PropTypes from 'prop-types';
import React from 'react';

const UsefulLinksMobileView = ({ isMobile }) => (
  <MobileAccordionWrapper>
    <AccordionSection
      heading="Calls"
      content={<CallsTile />}
    />
    <AccordionSection
      heading="Community"
      content={<CommunityTile />}
    />
    <AccordionSection
      heading="Community events calendar"
      content={<CommunityEventTile />}
    />
  </MobileAccordionWrapper>
);

UsefulLinksMobileView.propTypes = {
  isMobile: PropTypes.bool,
};

export default UsefulLinksMobileView;
