import styled from 'styled-components';

const StyledStartReview = styled.div`
  height: 432px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  button {
    margin: 0 6px;
  }

  @media (max-width: 479px) {
    button {
      width: 100%;
    }
  }
`;

StyledStartReview.displayName = 'StyledStartReview';

export default StyledStartReview;
