import axios from 'axios';
const banners = require('./global_banners');

module.exports = (() => {
  const fetchUserData = async () => {
    const res = await axios.get('/api/v1/me.json');
    return res.data.data;
  };

  let user;

  const init = async () => {
    user = await fetchUserData();
    banners.init();
    bindFunctions();
    initZendesk();
  };

  const bindFunctions = () => {
    $('#copy_to_clipboard--js').on('click', copyToClipboard);
    $(document).on('click', '.zendesk-contact--js', openZendesk);
  };

  const initZendesk = async () => {
    const isAStudent = user.user_type === 'student';
    window.zESettings = {
      webWidget: {
        contactForm: {
          ticketForms: [{ id: isAStudent ? 10579381999133 : 8420083205917 }],
          title: {
            '*': isAStudent ? 'Ask Your Advisor' : 'Need help?',
          },
        },
      },
    };
  };

  const openZendesk = (e) => {
    e.preventDefault();
    zE('webWidget', 'prefill', {
      email: {
        value: user.email,
      },
      name: {
        value: user.name,
      },
    });
    zE('webWidget', 'open');
  };

  const copyToClipboard = () => {
    let clipboardText = '';
    clipboardText = $('.copy_to_clipboard--link').val();
    _copyLink(clipboardText);
  };

  const _copyLink = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);

    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    _flashMessage('Link copied to clipboard', 'success');
  };

  const _flashMessage = (msg, type) => {
    const color = type === 'success' ? 'success' : 'error';
    const icon = type === 'success' ? 'fa-check' : 'fa-times';
    $('.flash_message__container span').html(msg);
    $('.flash_message__container i').addClass(icon);
    $('.flash_message__container').addClass(`${color} flash_message__fade`);
    setTimeout(() => {
      $('.flash_message__container').removeClass(
        `${color} flash_message__fade`
      );
      $('.flash_message__container i').removeClass(icon);
    }, 5000);
  };

  return {
    init,
  };
})();
