import ApiService from '../../services/ApiService';
import { getAuthToken } from '../../utils/getAuthToken';

export const getEncryptedUserId = async () => {
  const usersEncryptedIdApiService = new ApiService(
    `/api/v1/encrypted_user_id`
  );

  return await usersEncryptedIdApiService.makeGetCall();
};

export const setSurveyCompleted = async () => {
  const usersCompleteSurveyApiService = new ApiService(
    `/api/v1/diversity_survey/mark_as_complete`
  );

  return await usersCompleteSurveyApiService.makePostCall({
    authenticity_token: getAuthToken(),
  });
};
