import {
  SET_CURRENT_PAGE,
  SET_CURRICULUM_STRUCTURE,
  SET_SURVEY_QUESTIONS,
  SET_USER_ANSWERS,
  SET_USER_DATA,
  START_UI_LOADING,
  STOP_UI_LOADING,
} from './constants';
import {
  answerToQuestion,
  getStringFromUrlHash,
  goBack,
  gotoPage,
  modifySurveyQuestions,
  navigateUserToAppropriatePage,
} from './utils/helpers';
import ApiService from '../../services/ApiService';
import { getAuthToken } from '../../utils/getAuthToken';
import { getUserData as getUserApiData } from '../../services/getUserData';
import { handleUpload } from '../Uploader/actions';
import pages from './pages.json';
const navs = Object.keys(pages);

const BASE_URL = '/api/v1';

export const navigateTo = (nextLocation, event, currentScreen) => {
  const screenToSubmit =
    currentScreen === 'personalized-support'
      ? `questionaire_${event === 'next' ? '4' : '1'}`
      : currentScreen;
  if (nextLocation === 'back') {
    goBack();
    trackEvent('onboarding_flow', {
      event_action: 'back',
      onboarding_screen: screenToSubmit,
    });
  } else {
    gotoPage(nextLocation);
    trackEvent('onboarding_flow', {
      event_action: event,
      onboarding_screen: screenToSubmit,
    });
  }
};

export const getSurveyQuestions = () => async (dispatch) => {
  const surveyQuestionsService = new ApiService(
    `${BASE_URL}/onboarding/survey_questions.json`
  );
  const userAnswersService = new ApiService(
    `${BASE_URL}/onboarding/user_answers.json`
  );
  try {
    dispatch(startUiLoading());
    const userQuestions = await surveyQuestionsService.makeGetCall();
    const userAnswers = await userAnswersService.makeGetCall();

    const questions = userQuestions.data.data.surveyQuestions;
    const answers = userAnswers.data.data.answers;
    dispatch(setSurveyQuestions(modifySurveyQuestions(questions, answers)));
    dispatch(stopUiLoading());
  } catch (e) {
    console.log(e);
    dispatch(stopUiLoading());
  }
};

export const getUserAnswers = () => async (dispatch) => {
  const answersService = new ApiService(
    `${BASE_URL}/onboarding/user_answers.json`
  );
  try {
    dispatch(startUiLoading());
    const { data } = await answersService.makeGetCall();
    dispatch(setUserAnswers(data.data.answers));
    dispatch(stopUiLoading());
  } catch (e) {
    console.log(e);
    dispatch(stopUiLoading());
  }
};

export const getCurriculumStructure = () => async (dispatch) => {
  const curriculumService = new ApiService(
    `${BASE_URL}/onboarding/curriculum_structure.json`
  );
  try {
    dispatch(startUiLoading());
    const { data } = await curriculumService.makeGetCall();
    dispatch(setCurriculumStructure(data.data));
    dispatch(stopUiLoading());
  } catch (e) {
    console.log(e);
    dispatch(stopUiLoading());
  }
};

export const getUserData = () => async (dispatch) => {
  try {
    dispatch(startUiLoading());
    const { data } = await getUserApiData();
    const userData = data.data;
    dispatch(setUserData(userData));
    dispatch(stopUiLoading());
    if (data.data.onboardingStatus) {
      navigateUserToAppropriatePage(navs, userData.onboardingStatus);
    } else {
      gotoPage('#Welcome');
    }
  } catch (e) {
    console.log(e);
    dispatch(stopUiLoading());
  }
};

export const setCurrentPage = (page) => async (dispatch) => {
  const apiService = new ApiService(
    `${BASE_URL}/onboarding/update_onboarding_status.json`
  );
  if (page) {
    try {
      dispatch(startUiLoading());
      const postData = {
        authenticity_token: getAuthToken(),
        onboarding_status: getStringFromUrlHash(page),
      };
      await apiService.makePostCall(postData);
      dispatch(setPage(page));
      dispatch(stopUiLoading());
    } catch (e) {
      console.log(e);
      dispatch(stopUiLoading());
    }
  }
};

export const answerSurveyQuestion =
  (answer, questionIndex) => async (dispatch, store) => {
    const apiService = new ApiService(
      `${BASE_URL}/onboarding/answer_survey_question.json`
    );
    try {
      dispatch(startUiLoading());
      const postData = { authenticity_token: getAuthToken(), ...answer };
      const { data } = await apiService.makePostCall(postData);
      if (data.success) {
        const questions = store().onBoarding.questions;
        const newQuestions = answerToQuestion(questions, questionIndex, answer);
        dispatch(setSurveyQuestions(newQuestions));
        trackEvent('onboarding_flow', {
          answer: answer.answer,
          event_action: 'answer',
          onboarding_screen: `questionaire_${questionIndex + 1}`,
        });
      }
      dispatch(stopUiLoading());
    } catch (e) {
      console.log(e);
      dispatch(stopUiLoading());
    }
  };

export const submitProfileData = (file, bio) => async (dispatch, state) => {
  const apiService = new ApiService(
    `${BASE_URL}/onboarding/update_user_info.json`
  );
  try {
    dispatch(startUiLoading());
    const userId = state().onBoarding.userData.id;
    const imageUploadData =
      file && (await uploadFileAndTrackEvent(file, userId));
    const userProfileBioPostData = {
      authenticity_token: getAuthToken(),
      bio,
      s3_url: imageUploadData && imageUploadData.url,
    };
    await apiService.makePostCall(userProfileBioPostData);
    file &&
      bio &&
      trackEvent('onboarding_flow', {
        event_action: 'bio completed',
        onboarding_screen: 'profile',
      });
    trackEvent('onboarding_flow', {
      event_action: 'next',
      onboarding_screen: 'profile',
    });
    dispatch(getUserData());
    window.location.href = '/en/dashboards/main';
    dispatch(stopUiLoading());
  } catch (e) {
    console.log(e);
    dispatch(stopUiLoading());
  }
};

const uploadFileAndTrackEvent = async (file, userId) => {
  const prefix = 'users';
  const data = await handleUpload(file, prefix, userId);
  data &&
    data.url &&
    trackEvent('onboarding_flow', {
      event_action: 'photo uploaded',
      onboarding_screen: 'profile',
    });
  return data;
};

export const trackEvent = async (event, body) => {
  const apiService = new ApiService(`${BASE_URL}/analytics/track`);
  const postData = { authenticity_token: getAuthToken(), data: body, event };
  apiService.makePostCall(postData);
};

export const startUiLoading = () => ({
  isLoading: true,
  type: START_UI_LOADING,
});

export const stopUiLoading = () => ({
  isLoading: false,
  type: STOP_UI_LOADING,
});

export const setPage = (page) => ({
  currentPage: page,
  type: SET_CURRENT_PAGE,
});

export const setCurriculumStructure = (structure) => ({
  structure,
  type: SET_CURRICULUM_STRUCTURE,
});

export const setSurveyQuestions = (questions) => ({
  questions,
  type: SET_SURVEY_QUESTIONS,
});

export const setUserAnswers = (answers) => ({
  answers,
  type: SET_USER_ANSWERS,
});

export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  userData,
});
