import styled from 'styled-components';

const StyledReviewConsentBox = styled.div`
  .checkbox-wrapper {
    justify-content: center;
    label {
      margin-left: 6px;
      max-width: 315px;
    }
  }

  .toggle-section {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    i {
      font-size: 15px;
      transform: rotate(0);
      transition: all .4s;
      text-rendering: auto;
      margin-bottom: 5px;
      cursor: pointer;
    }

    h5 {
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
      font-weight: normal;
      cursor: pointer;
      a {
        font-size: 14px;
        line-height: 1.71;
        margin: 0;
        margin-right: 5px;
        font-weight: normal;
      }
    }
  }

  &.consent-form {
    margin-bottom: 23px;
    .checkbox-wrapper {
      display: none !important;
    }
  }

  @media (min-width: 800px) {
    ${({ isOpen }) =>
    isOpen &&
      `&.consent-form {
        .checkbox-wrapper {
          display: flex !important;
        }
        .toggle-section {
          i {
            font-size: 15px;
            transform: rotate(0);
            transition: all .4s;
            text-rendering: auto;
            transform: rotate(180deg);
            margin-bottom: 5px;
          }
        }
      }
    `}
  }

  @media (max-width: 768px) {
    &.consent-form {
      display: none !important;
    }
  }
`;

StyledReviewConsentBox.displayName = 'StyledReviewConsentBox';

export default StyledReviewConsentBox;
