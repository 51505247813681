import {
  ImageButton,
  ModalBody,
  ModalBox,
  ModalDate,
  ModalDesc,
  ModalFooter,
  ModalImage,
  ModalSubtitle,
  ModalTitle,
  TrackingDescription,
} from './styles';
import { MinusIcon, PlusIcon, WsgIcon } from './icons';
import Modal from '../../../../shared/components/TheModal';
import PropTypes from 'prop-types';
import React from 'react';

const WsgModal = ({
  attendeeTargetCompletion,
  closeWsgModal,
  date,
  decreaseGoal,
  decreaseDisabled,
  goal,
  increaseGoal,
  increaseDisabled,
  handleClick,
  updateModalEventTracking,
}) => {
  React.useEffect(() => {
    updateModalEventTracking('open');
  }, []);

  return (
    <Modal handleClose={closeWsgModal} isModalOpen={true}>
      <ModalBody>
        <ModalImage>
          <WsgIcon />
        </ModalImage>
        <ModalTitle className='ds-typography__h4 ds-color__indigo'>
          Set your weekly submission goal!
        </ModalTitle>
        <ModalSubtitle className='ds-typography__body ds-color__petrol'>
          Plan your study time by setting a weekly submission goal. Based on
          this goal, the expected end date of your course will be calculated.
          <br />
          Submitting at least <b>2 tasks</b> per week will keep you on track; if
          you set higher goals, you can finish the course sooner! Goals can be
          adjusted at any time.
          <br />
          <b>{attendeeTargetCompletion}</b> remains your course end date.
        </ModalSubtitle>
        <TrackingDescription className='ds-typography__body ds-color__indigo'>
          Your goal is tracked by calendar week Monday-Sunday
        </TrackingDescription>
        <ModalBox>
          <span className='ds-typography__lead'>I aim to submit</span>
          <ImageButton isDisabled={decreaseDisabled} onClick={decreaseGoal}>
            <MinusIcon />
          </ImageButton>
          <span className='ds-typography__h3'>{goal}</span>
          <ImageButton isDisabled={increaseDisabled} onClick={increaseGoal}>
            <PlusIcon />
          </ImageButton>
          <span className='ds-typography__lead'>tasks per week</span>
        </ModalBox>
        <ModalDesc className='ds-typography__body'>
          At this pace you will finish by:
        </ModalDesc>
        <ModalDate className='ds-typography__h4 ds-color__indigo'>
          {date}
        </ModalDate>
      </ModalBody>
      <ModalFooter>
        <button className='ds-button__secondary' onClick={closeWsgModal}>
          Maybe later
        </button>
        <button className='ds-button__primary' onClick={handleClick}>
          Set my goal
        </button>
      </ModalFooter>
    </Modal>
  );
};

WsgModal.propTypes = {
  attendeeTargetCompletion: PropTypes.string.isRequired,
  closeWsgModal: PropTypes.func.isRequired,
  date: PropTypes.string,
  decreaseDisabled: PropTypes.bool,
  decreaseGoal: PropTypes.func.isRequired,
  goal: PropTypes.number,
  handleClick: PropTypes.func.isRequired,
  increaseDisabled: PropTypes.bool,
  increaseGoal: PropTypes.func.isRequired,
  updateModalEventTracking: PropTypes.func.isRequired,
};

export default WsgModal;
