import { deleteMentorNote, handleCancel } from './actions';
import MentorNoteForm from '../MentorNoteForm';
import PropTypes from 'prop-types';
import React from 'react';
import WarningModal from '../../../../shared/components/WarningModal';
import { connect } from 'react-redux';

export const MentorNotesModalContainer = ({
  deleteMentorNote,
  modalAction,
  handleClose,
  handleCancel,
}) => {
  const cancelMessage = 'Are you sure you want to cancel?';
  const cancelSubmessage = 'All your progress will be lost';
  const deleteMessage = 'Are you sure you want to delete note?';

  return modalAction === 'form' ? (
    <MentorNoteForm handleClose={handleClose} />
  ) : (
    <WarningModal
      action={modalAction}
      cancelButtonAction={modalAction === 'cancel' ? handleCancel : handleClose}
      confirmButtonAction={
        modalAction === 'cancel' ? handleClose : deleteMentorNote
      }
      message={modalAction === 'cancel' ? cancelMessage : deleteMessage}
      subMessage={modalAction === 'cancel' && cancelSubmessage}
    />
  );
};

MentorNotesModalContainer.propTypes = {
  deleteMentorNote: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalAction: PropTypes.string.isRequired,
};

export const mapStateToProps = (
  {
    userProfile: {
      mentorNotesTab: { modalAction },
    },
  },
  ownProps
) => ({
  handleClose: ownProps.handleModalClose,
  modalAction,
});

export const mapDispatchToProps = (dispatch) => ({
  deleteMentorNote: () => dispatch(deleteMentorNote()),
  handleCancel: () => dispatch(handleCancel()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MentorNotesModalContainer);
