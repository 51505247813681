import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StyledQuestionInsight from './styles/StyledQuestionInsight';

const QuestionInsight = ({ insight }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => setOpen(false), [insight]);

  return (
    <StyledQuestionInsight open={open}>
      <div onClick={() => setOpen(!open)} className="toggle-section">
        <h5 className="ds-typography__lead--bold text-left">Tips for answering the question</h5>
        <i className="fa fa-chevron-up fa-md" rel="noopener noreferrer" />
      </div>
      <h5 className="ds-typography__body text-left insight-content">{insight}</h5>
    </StyledQuestionInsight>
  );
};

QuestionInsight.propTypes = {
  insight: PropTypes.string,
};

export default QuestionInsight;
