import { Label, Section, SectionHeading } from '../styles';
import PropTypes from 'prop-types';
import React from 'react';

const AddressFields = ({
  heading,
  addressLine1,
  addressLine2,
  city,
  zip,
  country,
}) => (
  <Section>
    <SectionHeading className="ds-typography__h5">{heading}</SectionHeading>
    <div className="ds-grid__row">
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">Address</Label>
        <p className="ds-typography__body">{addressLine1}</p>
      </div>
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">Apt., bldg., etc.</Label>
        <p className="ds-typography__body">{addressLine2}</p>
      </div>
    </div>
    <div className="ds-grid__row">
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">City</Label>
        <p className="ds-typography__body">{city}</p>
      </div>
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">Postcode</Label>
        <p className="ds-typography__body">{zip}</p>
      </div>
    </div>
    <div className="ds-grid__row">
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">Country</Label>
        <p className="ds-typography__body">{country}</p>
      </div>
    </div>
  </Section>
);

AddressFields.propTypes = {
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  heading: PropTypes.string,
  zip: PropTypes.string,
};

export default AddressFields;
