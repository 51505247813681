import styled from 'styled-components';

const InputRow = styled.div`
  width: 100%;
  display: flex;
`;

InputRow.displayName = 'InputRow';

export default InputRow;
