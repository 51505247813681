import styled from 'styled-components';

const StyledApp = styled.div`
  .TheModal {
    width: 584px;
  }

  .the-modal-bg {
    padding-bottom: 400px;
  }
`;

StyledApp.displayName = 'StyledApp';

export { StyledApp };
