module.exports = (() => {
  const thisState = {};

  const init = function () {
    _bindFunctions();
    thisState.modal = new CF.Modal('#mentor_billing__modal');
    thisState.rejection_modal = new CF.Modal('#mentor_billing_rejection_modal');
  };

  const _bindFunctions = () => {
    $(document).on('click', '.mentor-billing--js', _openDetailsModal);
    $(document).on('click', '.mentor-billing--rejection--js', _openrejectionModal);
    $(document).on('click', '.reasons_area p.reason_textarea_dummy', _toggleReasonTextArea);
    $(document).on('click', '.reasons_area p.reason', _toggleReason);
    $(document).on('click', '.cf-modal__close_button', _resetReasonTextArea);
    $(document).on('change keyup paste', '.reason--textarea--js', _toggleRejectionSubmitButton);
  };

  const _openDetailsModal = (e) => {
    e.preventDefault();
    const currentProfile = e.target.getAttribute('data-current');
    const pendingProfile = e.target.getAttribute('data-pending');
    $('.current-profile--js').html(currentProfile);
    $('.pending-profile--js').html(pendingProfile);
    $('.supplier-account-form--js').attr('action', e.target.getAttribute('data-approve'));
    $('.billing-profile-reject-form--js').attr('action', e.target.getAttribute('data-reject'));
    $('.supplier-account-field--js').val(e.target.getAttribute('data-current-number'));
    $('.next-number--js').html(`Next available number: ${e.target.getAttribute('data-next-number')}`);
    $('.next-number--js').html(`Next available number: ${e.target.getAttribute('data-next-number')}`);
    $('.billing-profile-id-field--js').val(e.target.getAttribute('data-billing-profile-id'));
    $('.updated-time-field--js').val(e.target.getAttribute('data-updated-time'));
    $('.payment-details--js').attr('href', e.target.getAttribute('data-details'));
    thisState.modal.toggleModal();
  };

  const _openrejectionModal = (e) => {
    e.preventDefault();
    thisState.rejection_modal.toggleModal();
  };

  const _toggleReasonTextArea = (event) => {
    if (event.detail === 2) {
      $('.reason--textarea--js').removeClass('hidden');
      $('.reason_textarea_dummy').addClass('hidden');
    }
  };

  const _toggleReason = (event) => {
    const element = event.target;
    if (/selected/i.test(element.className)) {
      element.classList.remove('selected');
      $(element).find('input').val('');
    } else {
      element.classList.add('selected');
      $(element).find('input').val($(element).text());
    }
    _toggleRejectionSubmitButton(event);
  };

  const _toggleRejectionSubmitButton = (event) => {
    const element = event.target;
    const selectedReasonCount = $(element).closest('main');

    if ($(selectedReasonCount).find('.reason.selected').length > 0 || $(selectedReasonCount).find('textarea.reason--textarea--js').val()) { $(selectedReasonCount).siblings('footer').find('.billing-profile-change--js').prop('disabled', false); } else { $(selectedReasonCount).siblings('footer').find('.billing-profile-change--js').prop('disabled', true); }
  };

  const _resetReasonTextArea = (event) => {
    $(event.target).closest('main').find('.reason.selected').removeClass('selected');
    $('.reason--textarea--js').val('');
    $('.reason--textarea--js').addClass('hidden');
    $('.reason_textarea_dummy').removeClass('hidden');
  };

  return {
    init: init,
  };
})();
