import App from './containers/App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const payoutInformationFormContainer = document.getElementById(
  'payoutInformationForm'
);

if (payoutInformationFormContainer) {
  const payoutInformationFormContainerRoot = createRoot(
    payoutInformationFormContainer
  );

  payoutInformationFormContainerRoot.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}
