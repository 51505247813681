import CountrySelect from '../../../../../shared/components/CountrySelect';
import { EU_COUNTRIES } from '../../../../../../constants';
import Input from '../../../../../shared/components/Input';
import { InputWrapper } from '../styles';
import React from 'react';
import { errorMessage } from '../helpers';

export const paymentFields = (
  personalData,
  handleSave,
  handleCountryChange,
  formErrors
) => {
  switch (personalData.paymentMethod) {
    case 'PayPal':
      return paypal(personalData, handleSave, formErrors);
    case 'Payoneer':
      return payoneer(personalData, handleSave, formErrors);
    case 'Bank Transfer':
      return bankTransfer(
        personalData,
        handleSave,
        handleCountryChange,
        formErrors
      );
    default:
      return null;
  }
};

const paypal = (personalData, handleSave, formErrors) => (
  <InputWrapper>
    <Input
      type="email"
      name="payee"
      placeholder="Email"
      required={true}
      value={personalData.payee}
      onChange={(value) => handleSave('payee', value)}
      error={errorMessage(formErrors, 'payee')}
    />
  </InputWrapper>
);

const payoneer = (personalData, handleSave, formErrors) => (
  <InputWrapper>
    <Input
      type="text"
      name="payee"
      placeholder="Payoneer token"
      required={true}
      value={personalData.payee}
      onChange={(value) => handleSave('payee', value)}
      error={errorMessage(formErrors, 'payee')}
      pattern="^(CareerFoundry)([0-9]{3,4})"
    />
  </InputWrapper>
);

const bankTransfer = (
  personalData,
  handleSave,
  handleCountryChange,
  formErrors
) => (
  <>
    <InputWrapper>
      <Input
        type="text"
        name="contactName"
        placeholder="Account holder name"
        required={true}
        value={personalData.contactName}
        onChange={(value) => handleSave('contactName', value)}
      />
    </InputWrapper>
    <InputWrapper>
      <Input
        type="text"
        name="iban"
        placeholder="IBAN/Account number"
        required={true}
        value={personalData.iban}
        onChange={(value) => handleSave('iban', value)}
        error={errorMessage(formErrors, 'iban')}
      />
      <Input
        type="text"
        name="bic"
        placeholder="BIC/Swift"
        required={true}
        value={personalData.bic}
        onChange={(value) => handleSave('bic', value)}
        error={errorMessage(formErrors, 'bic')}
      />
    </InputWrapper>
    {!EU_COUNTRIES.includes(personalData.country) && (
      <>
        <InputWrapper>
          <Input
            type="text"
            name="bankName"
            placeholder="Bank name"
            value={personalData.bankName}
            onChange={(value) => handleSave('bankName', value)}
            error={errorMessage(formErrors, 'bankName')}
            required={true}
          />
          <Input
            type="text"
            name="branch"
            placeholder="Branch"
            value={personalData.branch}
            onChange={(value) => handleSave('branch', value)}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            type="text"
            name="bankStreet"
            placeholder="Bank street"
            value={personalData.bankStreet}
            onChange={(value) => handleSave('bankStreet', value)}
          />
          <Input
            type="text"
            name="bankCity"
            placeholder="Bank city"
            value={personalData.bankCity}
            onChange={(value) => handleSave('bankCity', value)}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            type="text"
            name="bankZip"
            placeholder="Bank postcode"
            value={personalData.bankZip}
            onChange={(value) => handleSave('bankZip', value)}
          />
          <CountrySelect
            name="bankCountry"
            value={personalData.bankCountry}
            onChange={handleCountryChange}
            placeholder="Bank country"
          />
        </InputWrapper>
      </>
    )}
  </>
);
