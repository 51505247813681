import styled from 'styled-components';

const StyledFeedbackButton = styled.div`
  text-align: center;
  margin: 50px 0;

  & > div > div {
    height: 60vh;
  }
`;

StyledFeedbackButton.displayName = 'StyledFeedbackButton';

export default StyledFeedbackButton;
