import styled from 'styled-components';

const StyledMenu = styled.div`
  height: 48px;
  width: 223px;
  background-color: #fff;
  border-radius: 0px;
  margin: 0 0 6px auto;
  padding: 0 7px 0 17px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;
  }

  .microphone {
    path {
      fill: #223c50;
    }
  }

  ${({ isActive }) =>
    isActive &&
    `
    background-color: #223C50;

    svg {
      path {
        fill: #fff;
      }
    }

    .tracker {
      path:nth-child(1) {
        fill: #282828;
      }
    }

    .microphone {
      path {
        fill: #fff;
      }
    }
  `}
`;

StyledMenu.displayName = 'StyledMenu';
export default StyledMenu;
