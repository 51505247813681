import ApiService from '../../../services/ApiService';
import { constants } from './constants';
import { getAuthToken } from '../../../utils/getAuthToken';
import { keysToSnake } from '../../../utils/keysConverter';

export const getJobSeekingProfiles = (page, filterParams = {}) => async dispatch => {
  const params = {
    authenticity_token: getAuthToken(),
    page: page,
    ...filterParams,
  };

  const apiService = new ApiService('/api/v1/admin/job_seeking_profiles');
  try {
    dispatch(setLoadingProfile());
    const result = await apiService.makeGetCall(params);
    dispatch({
      data: result.data,
      type: constants.FETCH_JOB_SEEKING_PROFILES_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.FETCH_JOB_SEEKING_PROFILES_FAILURE,
    });
  }
};

export const handleCloseModal = () => dispatch => {
  dispatch({ type: constants.HANDLE_CLOSE_MODAL });
};

export const showJobSeekingProfile = id => async dispatch => {
  const params = {
    authenticity_token: getAuthToken(),
  };

  const apiService = new ApiService(`/api/v1/admin/job_seeking_profiles/${id}`);
  try {
    const result = await apiService.makeGetCall(params);
    dispatch({
      data: result.data,
      type: constants.SHOW_JOB_SEEKING_PROFILE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.SHOW_JOB_SEEKING_PROFILE_FAILURE,
    });
  };
};

export const showEmployer = id => async dispatch => {
  const params = {
    authenticity_token: getAuthToken(),
  };

  const apiService = new ApiService(`/api/v1/admin/employer_connector/employers/${id}`);
  try {
    const result = await apiService.makeGetCall(params);
    dispatch({
      data: result.data,
      type: constants.SHOW_EMPLOYER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.SHOW_EMPLOYER_FAILURE,
    });
  };
};

export const handleNewEmployer = () => dispatch => {
  dispatch({ type: constants.HANDLE_NEW_EMPLOYER });
};

export const handleUpdateEmployer = (id) => dispatch => {
  dispatch({ data: id, type: constants.HANDLE_UPDATE_EMPLOYER });
};

export const updateEmployer = (id, employer) => async dispatch => {
  const apiService = new ApiService(`/api/v1/admin/employer_connector/employers/${id}`);
  const params = {
    authenticity_token: getAuthToken(),
    employer: keysToSnake(employer),
    id: id,
  };
  try {
    const result = await apiService.makePutCall(params);
    dispatch({ data: result.data, type: constants.UPDATE_EMPLOYER_SUCCESS });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.UPDATE_EMPLOYER_FAILURE,
    });
  }
};

export const deleteEmployer = (id) => async dispatch => {
  const apiService = new ApiService(`/api/v1/admin/employer_connector/employers/${id}`);
  const params = {
    data: {
      authenticity_token: getAuthToken(),
    },
    id: id,
  };
  try {
    await apiService.makeDeleteCall(params);
    dispatch({ data: id, type: constants.DELETE_EMPLOYER_SUCCESS });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.DELETE_EMPLOYER_FAILURE,
    });
  }
};

export const createEmployer = (employerAttributes) => async dispatch => {
  const apiService = new ApiService('/api/v1/admin/employer_connector/employers');
  const params = {
    authenticity_token: getAuthToken(),
    employer: keysToSnake(employerAttributes),
  };
  try {
    const result = await apiService.makePostCall(params);
    dispatch({ data: result.data, type: constants.CREATE_EMPLOYER_SUCCESS });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.CREATE_EMPLOYER_FAILURE,
    });
  }
};

export const getEmployers = (page, orderType, orderBy) => async dispatch => {
  const params = {
    authenticity_token: getAuthToken(),
    order_by: orderBy,
    order_type: orderType,
    page: page,
    per_page: 20,
  };
  dispatch({ type: constants.GET_EMPLOYERS_REQUEST });

  const apiService = new ApiService('/api/v1/admin/employer_connector/employers');
  try {
    const result = await apiService.makeGetCall(params);
    dispatch({ data: result.data, type: constants.GET_EMPLOYERS_SUCCESS });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.GET_EMPLOYERS_FAILURE,
    });
  }
};

export const setLoadingProfile = () => ({ type: constants.SET_PROFILE_LOADING });
export const setFilterParams = (data) => ({ data, type: constants.SET_PROFILE_FILTER });
