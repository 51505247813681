import PropTypes from 'prop-types';
import React from 'react';

export const PlusIcon = ({ disabled }) => {
  const strokeColor = disabled ? '#A7B1B9' : '#223C50';
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 12H18.5'
        stroke={strokeColor}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M12.25 5.75L12.25 18.25'
        stroke={strokeColor}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <circle cx='12' cy='12' r='11' stroke={strokeColor} strokeWidth='2' />
    </svg>
  );
};

export const MinusIcon = ({ disabled }) => {
  const strokeColor = disabled ? '#A7B1B9' : '#223C50';
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 12H18.5'
        stroke={strokeColor}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <circle cx='12' cy='12' r='11' stroke={strokeColor} strokeWidth='2' />
    </svg>
  );
};

export const WsgIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='125'
    height='115'
    viewBox='0 0 125 115'
    fill='none'
  >
    <path
      d='M121.983 78.2356C124.957 71.858 125.315 63.4974 119.594 52.3499C113.872 41.2023 106.062 35.0379 98.0559 31.6116C85.034 26.0795 72.5082 28.4842 45.9754 35.6147C19.4427 42.7452 7.79012 46.8386 2.91391 57.18C-0.0667722 63.5599 -0.425387 71.9206 5.29612 83.0681C11.0176 94.2156 18.828 100.38 26.8409 103.806C39.7905 109.552 52.3839 106.936 78.9143 99.8055C105.447 92.6727 117.349 88.7067 121.983 78.2356Z'
      fill='#5CA2B2'
      fillOpacity='0.1'
    />
    <path
      d='M64.8087 115C63.496 115 62.4297 113.941 62.4297 112.633V8.30475H67.1876V112.635C67.1876 113.941 66.1234 115 64.8087 115Z'
      fill='#FFBC42'
    />
    <path
      d='M64.8086 10.0804C67.6067 10.0804 69.875 7.82384 69.875 5.04021C69.875 2.25658 67.6067 0 64.8086 0C62.0105 0 59.7422 2.25658 59.7422 5.04021C59.7422 7.82384 62.0105 10.0804 64.8086 10.0804Z'
      fill='#FFBC42'
    />
    <path
      d='M64.8089 0C64.5853 0 64.3658 0.016478 64.1484 0.0432548C66.6351 0.364576 68.5544 2.47788 68.5544 5.04021C68.5544 7.60048 66.6351 9.71378 64.1484 10.0372C64.3638 10.066 64.5853 10.0804 64.8089 10.0804C67.6061 10.0804 69.8753 7.82293 69.8753 5.04021C69.8753 2.25749 67.6082 0 64.8089 0Z'
      fill='#F7A623'
    />
    <path
      d='M82.7575 14.5562C83.0349 14.4243 82.9707 14.0144 82.6664 13.9712L36.9174 7.39441L26.5671 12.3399C26.3683 12.4346 26.329 12.7003 26.4926 12.8486L35.0125 20.5068L80.7636 27.0815C81.068 27.1248 81.244 26.7499 81.0162 26.546L73.1857 19.5099C73.0532 19.3925 73.0843 19.1782 73.2458 19.102L82.7575 14.5562Z'
      fill='#197EFF'
    />
    <path
      d='M82.6389 13.9776L81.9556 13.8808L72.6219 18.2701C72.4439 18.3546 72.4108 18.5873 72.5557 18.715L73.065 19.1641L82.7403 14.6141C83.0467 14.4719 82.9764 14.025 82.6389 13.9776Z'
      fill='#096BD6'
    />
    <path
      d='M80.1197 25.7387L35.3976 19.3123L27.269 12.0063L26.5671 12.3421C26.3683 12.4368 26.329 12.7025 26.4926 12.8508L35.0125 20.509L80.7636 27.0837C81.068 27.127 81.244 26.7521 81.0162 26.5482L80.1197 25.7387Z'
      fill='#096BD6'
    />
    <path
      d='M85.3034 43.6339C85.5436 43.4424 85.3883 43.0572 85.0819 43.084L39.0203 46.9584L30.0613 54.1016C29.8895 54.2396 29.9123 54.5074 30.1048 54.6145L40.1424 60.1593L86.2041 56.2849C86.5105 56.2581 86.5975 55.8544 86.3283 55.7061L77.1044 50.6103C76.9491 50.5238 76.9305 50.3096 77.0692 50.1984L85.3034 43.6339Z'
      fill='#197EFF'
    />
    <path
      d='M85.0571 43.0985L84.3697 43.1582L76.2721 49.5311C76.1189 49.6526 76.1376 49.8874 76.3094 49.9801L76.9078 50.3035L85.2994 43.6979C85.5664 43.4878 85.3967 43.0697 85.0571 43.0985Z'
      fill='#096BD6'
    />
    <path
      d='M85.2724 55.1211L40.246 58.909L30.668 53.6175L30.0613 54.1015C29.8895 54.2395 29.9123 54.5073 30.1048 54.6144L40.1424 60.1593L86.2041 56.2849C86.5105 56.2602 86.5975 55.8544 86.3283 55.7061L85.2724 55.1211Z'
      fill='#096BD6'
    />
    <path
      d='M45.3824 42.8882C45.1401 43.0757 45.2871 43.4629 45.5935 43.4423L91.7111 40.3012L100.784 33.3021C100.958 33.1683 100.941 32.8984 100.749 32.7893L90.8001 27.0858L44.6825 30.2269C44.3761 30.2475 44.2829 30.6512 44.548 30.8037L53.6891 36.0457C53.8423 36.1343 53.8568 36.3485 53.718 36.4577L45.3824 42.8882Z'
      fill='#81E1DA'
    />
    <path
      d='M45.621 43.4278L46.3084 43.3805L54.5074 37.1374C54.6627 37.0179 54.6482 36.7831 54.4784 36.6883L53.8863 36.3546L45.3891 42.8264C45.1199 43.0303 45.2814 43.4525 45.621 43.4278Z'
      fill='#71D1C6'
    />
    <path
      d='M45.5977 31.4031L90.6779 28.334L100.169 33.7759L100.784 33.3021C100.958 33.1683 100.941 32.8984 100.749 32.7893L90.8001 27.0858L44.6825 30.2269C44.3761 30.2475 44.2829 30.6512 44.548 30.8037L45.5977 31.4031Z'
      fill='#71D1C6'
    />
    <path
      d='M28.7462 113.208C42.9655 113.208 54.4925 101.741 54.4925 87.5951C54.4925 73.4494 42.9655 61.9821 28.7462 61.9821C14.527 61.9821 3 73.4494 3 87.5951C3 101.741 14.527 113.208 28.7462 113.208Z'
      fill='#81E1DA'
    />
    <path
      d='M28.7465 110.539C41.4838 110.539 51.8094 100.266 51.8094 87.5951C51.8094 74.9237 41.4838 64.6516 28.7465 64.6516C16.0092 64.6516 5.68359 74.9237 5.68359 87.5951C5.68359 100.266 16.0092 110.539 28.7465 110.539Z'
      fill='white'
    />
    <path
      d='M28.7469 108.728C40.4791 108.728 49.9899 99.2665 49.9899 87.595C49.9899 75.9236 40.4791 66.462 28.7469 66.462C17.0147 66.462 7.50391 75.9236 7.50391 87.595C7.50391 99.2665 17.0147 108.728 28.7469 108.728Z'
      fill='#81E1DA'
    />
    <path
      d='M28.7454 101.845C36.656 101.845 43.0688 95.4649 43.0688 87.5952C43.0688 79.7255 36.656 73.3458 28.7454 73.3458C20.8347 73.3458 14.4219 79.7255 14.4219 87.5952C14.4219 95.4649 20.8347 101.845 28.7454 101.845Z'
      fill='white'
    />
    <path
      d='M28.7445 100.149C35.714 100.149 41.364 94.5284 41.364 87.595C41.364 80.6615 35.714 75.0408 28.7445 75.0408C21.7749 75.0408 16.125 80.6615 16.125 87.595C16.125 94.5284 21.7749 100.149 28.7445 100.149Z'
      fill='#81E1DA'
    />
    <path
      d='M28.7471 93.6467C32.1067 93.6467 34.8301 90.9373 34.8301 87.5951C34.8301 84.2529 32.1067 81.5436 28.7471 81.5436C25.3875 81.5436 22.6641 84.2529 22.6641 87.5951C22.6641 90.9373 25.3875 93.6467 28.7471 93.6467Z'
      fill='white'
    />
    <path
      d='M28.7478 92.3904C31.4098 92.3904 33.5678 90.2435 33.5678 87.5952C33.5678 84.947 31.4098 82.8001 28.7478 82.8001C26.0857 82.8001 23.9277 84.947 23.9277 87.5952C23.9277 90.2435 26.0857 92.3904 28.7478 92.3904Z'
      fill='#FF62BA'
    />
    <path
      d='M56.2356 64.0067L53.2521 63.7369L52.8877 60.7791C52.8877 60.7791 49.1463 64.1344 48.0386 65.8111C46.9765 67.4197 47.5127 69.4074 47.5583 69.5701L47.5521 69.5763C47.5521 69.5763 47.5583 69.5784 47.5604 69.5784C47.5604 69.5804 47.5624 69.5866 47.5624 69.5866L47.5686 69.5804C47.7322 69.6216 49.7468 70.0912 51.3307 68.9852C52.9829 67.8338 56.2356 64.0067 56.2356 64.0067Z'
      fill='#197EFF'
    />
    <path
      d='M53.506 64.1079L52.916 63.521L29.85 86.4677L30.44 87.0546L53.506 64.1079Z'
      fill='#096BD6'
    />
    <path
      d='M76.231 101.383C78.759 98.0419 82.225 95.3456 86.1485 94.806C87.9622 94.5568 89.894 94.7483 91.5213 93.8317C92.48 93.2921 93.2357 92.4126 94.0867 91.6875C96.7493 89.4177 100.462 88.7256 103.658 89.8997C104.979 90.3858 106.195 91.185 107.228 92.2066C109.547 94.497 108.34 98.7133 105.255 99.2097C101.47 99.8194 97.4864 99.7473 94.0763 101.611C90.8422 103.379 88.5337 106.674 85.5356 108.889C82.343 111.249 78.5354 112.269 74.7961 113.002C73.8479 113.187 72.8934 113.391 71.9389 113.521C71.2826 113.609 70.5848 113.638 70.5413 112.742C70.5041 111.958 70.9347 111.13 71.2349 110.45C71.6677 109.469 72.1107 108.493 72.5787 107.531C73.6263 105.368 74.8023 103.27 76.231 101.383Z'
      fill='#76BEE6'
    />
    <path
      d='M72.3487 113.562C76.9017 110.649 80.1482 106.205 84.3243 102.839C88.3721 99.5763 93.0679 97.1087 98.0453 95.5803C100.863 94.7153 103.77 94.1509 106.71 93.912C107.106 93.879 107.11 93.2611 106.71 93.294C101.459 93.7183 96.3144 95.156 91.5937 97.4815C89.2354 98.6432 86.9869 100.027 84.8937 101.615C82.7528 103.238 80.8376 105.092 78.9514 106.998C76.7981 109.171 74.6304 111.37 72.0381 113.028C71.7007 113.243 72.0112 113.776 72.3487 113.562Z'
      fill='#E1FFFF'
    />
    <path
      d='M97.4837 95.3725C98.1524 93.5311 99.784 92.1119 101.693 91.6629C102.082 91.5723 101.917 90.9749 101.527 91.0676C99.3989 91.5681 97.6265 93.1686 96.8853 95.2098C96.7487 95.5826 97.3491 95.7453 97.4837 95.3725Z'
      fill='#E1FFFF'
    />
    <path
      d='M93.9294 97.8646C96.356 98.1344 98.8053 98.12 101.228 97.8234C101.619 97.776 101.623 97.1561 101.228 97.2055C98.8053 97.5021 96.3539 97.5165 93.9294 97.2467C93.5319 97.2034 93.536 97.8214 93.9294 97.8646Z'
      fill='#E1FFFF'
    />
    <path
      d='M85.444 101.471C85.0009 99.5949 86.061 97.5598 87.823 96.7895C88.1874 96.6288 87.8727 96.0974 87.5103 96.256C85.4916 97.1397 84.3446 99.5125 84.8456 101.636C84.9367 102.023 85.5351 101.859 85.444 101.471Z'
      fill='#E1FFFF'
    />
    <path
      d='M79.1808 106.31C78.5638 104.497 78.9282 102.411 80.1684 100.938C80.4231 100.635 79.9862 100.196 79.7295 100.501C78.3526 102.141 77.893 104.444 78.5824 106.474C78.7087 106.849 79.3092 106.689 79.1808 106.31Z'
      fill='#E1FFFF'
    />
    <path
      d='M78.1855 109.566C79.8792 109.125 81.4983 108.417 82.9683 107.469C83.3017 107.253 82.9911 106.72 82.6557 106.936C81.2271 107.857 79.668 108.543 78.022 108.971C77.6348 109.07 77.7984 109.667 78.1855 109.566Z'
      fill='#E1FFFF'
    />
    <path
      d='M76.8685 73.2655C79.0301 69.5601 82.5457 66.5878 86.6784 65.3808C90.811 64.1738 95.5234 64.8412 98.9707 67.4097C101.302 69.1481 103.072 72.143 102.178 74.901C101.29 77.6363 98.2378 79.0102 95.4261 79.6714C93.9312 80.0236 92.3639 80.2852 91.0864 81.1338C89.8089 81.9824 88.9041 83.6323 89.4673 85.0555C89.9352 86.2358 91.3038 87.0659 91.2872 88.3347C91.2748 89.3089 90.399 90.0731 89.4818 90.415C88.5646 90.7569 87.5624 90.8167 86.637 91.1339C84.2207 91.9619 82.732 94.4109 82.0198 96.8517C81.3076 99.2946 81.1689 101.88 80.3738 104.296C78.881 108.842 74.9719 112.524 70.33 113.758C69.8931 113.876 69.3879 113.96 69.0152 113.705C68.6612 113.462 68.5514 112.996 68.5017 112.572C68.011 108.271 70.9884 104.304 71.3238 99.9887C71.6302 96.0381 69.6736 92.2132 70.4231 88.2667C71.0049 85.1997 73.2783 83.0741 74.1665 80.1595C74.8933 77.7785 75.6014 75.4365 76.8685 73.2655Z'
      fill='#8CCAEC'
    />
    <path
      d='M95.5704 69.5106C92.5827 71.0452 89.9573 73.1729 87.4914 75.4242C86.245 76.5591 85.0193 77.7208 83.8184 78.901C82.5948 80.1019 81.4229 81.3707 80.4912 82.8146C78.8058 85.4284 77.9673 88.4665 77.3523 91.482C76.7271 94.5552 76.3088 97.6798 75.3916 100.687C74.373 104.022 72.7683 107.136 71.0333 110.15C70.82 110.52 70.6047 110.889 70.3873 111.258C70.0498 111.83 70.9443 112.349 71.2817 111.777C73.0437 108.782 74.7146 105.715 75.8968 102.442C76.9755 99.4553 77.4849 96.3286 78.0667 93.2204C78.6257 90.2359 79.2675 87.1957 80.7086 84.4891C82.2697 81.5561 84.8226 79.3254 87.2202 77.0802C89.6302 74.8227 92.1417 72.6394 95.0175 70.9834C95.3716 70.7794 95.7319 70.5838 96.0963 70.3984C96.6843 70.0956 96.1604 69.2058 95.5704 69.5106Z'
      fill='#E1FFFF'
    />
    <path
      d='M90.4985 74.5406C93.1735 75.9083 96.3951 75.9433 99.1033 74.6498C99.3559 74.5303 99.4221 74.1719 99.2896 73.9454C99.1385 73.69 98.8341 73.6405 98.5815 73.76C96.2233 74.8867 93.3474 74.8414 91.0223 73.6508C90.4301 73.3501 89.9063 74.2379 90.4985 74.5406Z'
      fill='#E1FFFF'
    />
    <path
      d='M83.8823 78.7939C83.1515 77.7702 82.8368 76.4705 83.1163 75.2347C83.373 74.1059 84.0707 73.1028 85.048 72.4746C85.605 72.1162 85.0873 71.2243 84.5263 71.5848C83.3316 72.3531 82.433 73.5704 82.1183 74.9587C81.7808 76.4499 82.0976 78.0647 82.9879 79.3109C83.1494 79.5375 83.4393 79.6446 83.696 79.4963C83.9217 79.3665 84.0459 79.0205 83.8823 78.7939Z'
      fill='#E1FFFF'
    />
    <path
      d='M79.2364 88.0093C82.1579 88.7776 85.1725 88.139 88.0504 87.4902C88.7005 87.344 88.4252 86.3512 87.775 86.4974C85.0834 87.103 82.2469 87.7353 79.5118 87.0165C78.8658 86.8455 78.5925 87.8404 79.2364 88.0093Z'
      fill='#E1FFFF'
    />
    <path
      d='M77.008 94.4296C75.2108 93.5624 73.9519 91.7416 73.8505 89.7457C73.8173 89.0866 72.7821 89.0825 72.8152 89.7457C72.9353 92.0856 74.3577 94.2916 76.4862 95.3194C76.7367 95.4409 77.0452 95.3874 77.1943 95.134C77.3268 94.9074 77.2606 94.5511 77.008 94.4296Z'
      fill='#E1FFFF'
    />
    <path
      d='M28.6654 88.2363L30.7362 84.6293L32.3441 86.2962L28.6654 88.2363Z'
      fill='#096BD6'
    />
  </svg>
);

PlusIcon.propTypes = {
  disabled: PropTypes.bool,
};

MinusIcon.propTypes = {
  disabled: PropTypes.bool,
};
