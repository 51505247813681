import styled from 'styled-components';

const StyledTile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
  background-color: #f6f7f8;
  text-align: center;
  position: relative;
  align-items: center;

  i {
    font-size: 59px;
    margin-bottom: 32px;
  }

  h5 {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .tile-icon {
    height: 36px;
    width: 36px;
    margin: 20px auto 28px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .link-row {
    width: 100%;
    display: inline-block;
    margin-bottom: 8px;
    overflow: visible;
    line-height: 1.5;
  }

  .row-button {
    width: 85%;
    display: inline-block;
    margin-bottom: 8px;
    overflow: visible;
    position: absolute;
    bottom: 5px;
    line-height: 1.5;
  }

  .description {
    margin: 0 0 16px;
  }

  @media (min-width: 1336px) {
    margin: 0;
    width: 31%;
    min-height: 289px;
    height: unset;
  }

  @media (min-width: 768px) and (max-width: 1335px) {
    margin: 0;
    height: 360px;
    width: 31%;
  }

  @media (max-width: 1170px) {
    width: 100%;
    margin-bottom: 11px;
    height: unset;

    .row-button {
      border-bottom: 2px solid !important;
      text-decoration: none;
      transition: 0.4s;
      width: 100%;
      color: var(--ds-color__indigo) !important;
      position: relative;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;

    h5,
    .tile-icon {
      display: none;
    }

    .description {
      text-align: left;
      margin: 0 0 16px !important;
    }
  }
`;

StyledTile.displayName = 'StyledTile';

export default StyledTile;
