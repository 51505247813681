import styled from 'styled-components';

const WarningStyles = styled.div`
  height: 432px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  button {
    margin: 0 6px;
  }

  @media (max-width: 479px) {
    button {
      width: 40%;
    }
  }
`;

WarningStyles.displayName = 'WarningStyles';

export default WarningStyles;
