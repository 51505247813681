import styled from 'styled-components';

const StyledText = styled.div`
  margin-bottom: 32px;
  font-size: 16px !important;
  p {
    font-size: 16px !important;
  }
`;

StyledText.displayName = 'StyledText';

export default StyledText;
