import {
  IconCheckFilled,
  IconCheckOpen,
  IconLocked,
} from '../SideNavList/icons';
import { IconWrapper, Wrapper } from './styles';
import { isCourseAccessible, trackAndRedirect } from '../../helpers';
import { CAREER_SUPPORT_CENTER } from '../../constants';
import PropTypes from 'prop-types';
import React from 'react';

const renderStatusIcon = ({ status = 'canceled' }) => {
  switch (status) {
    case 'canceled':
    case 'blocked':
      return <IconLocked />;
    case 'completed':
    case 'graduated':
      return <IconCheckFilled />;
    case 'active':
    case 'read_only':
    case 'waiting':
      return <IconCheckOpen />;
    default:
      return <IconCheckOpen />;
  }
};

const Course = ({ link = '', name = '', status = '', isStudent }) => {
  const isAccessible = isCourseAccessible({ status });
  const isSelected = link === window.location.pathname;
  const hiddenCoursesNames = [CAREER_SUPPORT_CENTER];

  if (hiddenCoursesNames.includes(name)) {
    return null;
  }

  return (
    <Wrapper
      isAccessible={isAccessible}
      onClick={() =>
        isAccessible &&
        trackAndRedirect({
          redirect: {
            url: link,
          },
          track: {
            itemName: `mycourses.${name}`,
            shouldTrack: isStudent,
          },
        })
      }
      isSelected={isSelected}
    >
      <IconWrapper isSelected={isSelected}>
        {renderStatusIcon({ status })}
      </IconWrapper>
      <div className="ds-typography__body--bold">{name}</div>
    </Wrapper>
  );
};

Course.propTypes = {
  isStudent: PropTypes.bool,
  link: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
};

export default Course;
