import PropTypes from 'prop-types';
import React from 'react';
import StyledModalBody from '../../styles/StyledModalBody';

const ModalBody = ({ children, header }) => (
  <StyledModalBody>
    <h4 className="ds-typography__h4 text-left">{ header }</h4>
    { children }
  </StyledModalBody>
);

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
};

export default ModalBody;
