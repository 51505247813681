import styled from 'styled-components';

const StyledCheckboxWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  label {
    font-weight: 700;
    font-size: 14px !important;
  }
  input {
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed !important;
    pointer-events: none;
  `};
`;

StyledCheckboxWrapper.displayName = 'StyledCheckboxWrapper';

export default StyledCheckboxWrapper;
