import { AIResponseWrapper, Icon } from './styles';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-regular-svg-icons';
import {
  faThumbsUp as faSolidThumbsUp,
  faThumbsDown as faSolidThumbsDown,
} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { rateResponse } from './services';
import CustomMarkdownRender from './CustomMarkdownRender';

interface Props {
  text?: string;
  messageId: string;
  requestId: string;
}

const ResponseMessage: React.FC<Props> = ({ text, requestId, messageId }) => {
  const [rated, setRated] = useState<boolean>(false);
  const [helpful, setHelpful] = useState<boolean>(false);

  const handleRating = async (helpful: boolean) => {
    rateResponse({ helpful, requestId, messageId }).then(() => {
      setRated(!rated);
      setHelpful(helpful);
    });
  };

  return (
    <AIResponseWrapper>
      <header>
        <p className='ds-text__body--small'>Getting started on your task</p>
        <div>
          <Icon
            icon={rated && helpful ? faSolidThumbsUp : faThumbsUp}
            onClick={() => handleRating(true)}
          />
          <Icon
            icon={rated && !helpful ? faSolidThumbsDown : faThumbsDown}
            onClick={() => handleRating(false)}
          />
        </div>
      </header>
      <main>
        <CustomMarkdownRender text={text} />
      </main>
    </AIResponseWrapper>
  );
};

export default ResponseMessage;
