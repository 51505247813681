import SharedAccordion from '../../../../shared/styles/Accordion';
import styled from 'styled-components';

const Accordion = styled(SharedAccordion)`
  margin-bottom: 1.5rem;

  > p:first-child {
    color: var(--ds-color__indigo);
  }

  .accordion__panel {
    background-color: var(--ds-color__grey--light_300);
    padding: 1.5rem;

    div:first-child ~ div {
      margin-top: 1rem;
    }

    div:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .accordion__button:before {
    margin: 0.5rem;
    height: 1rem;
    width: 1rem;
    border-top: 2px solid var(--ds-color__indigo);
    border-left: 2px solid var(--ds-color__indigo);
  }
`;

export default Accordion;
