import React from 'react';
import AccordionSection from '../../../../shared/components/AccordionSection';
import JobsMansoryTiles from '../../JobsMansoryTiles';
import { getCategorizedData } from '../../../../shared/helpers/jobSearch';
import { NORTH_AMERICA_JOBS_CATEGORIES } from '../constants/northAmericaJobs';

const SECTION_NAME = "North America";

const NorthAmericaLocationBoard = () => {
  const categories = getCategorizedData(NORTH_AMERICA_JOBS_CATEGORIES, 3);

  return (
    <AccordionSection
      heading={SECTION_NAME}
      content={
        <JobsMansoryTiles
          tilesCategories={categories}
          jobType="north_america_jobs"
          sectionName={SECTION_NAME}
          linkEventName="recommended_job_link_clicked"
        />
      }
    />
  );
};

export default NorthAmericaLocationBoard;
