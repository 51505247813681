import InlineIllustration from '../shared/InlineIllustration';
import PropTypes from 'prop-types';
import React from 'react';
import StyledImageText from '../../styles/StyledImageText';

const ImageText = ({ imageUrl, alt, paragraph, className = '' }) => (
  <StyledImageText className={className}>
    <InlineIllustration
      imageUrl={ imageUrl }
      alt={ alt }
      className='text-image'
    />
    <p className="ds-typography__body">{ paragraph }</p>
  </StyledImageText>
);

ImageText.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
};

export default ImageText;
