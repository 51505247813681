import axios from 'axios';
import { getAuthToken } from '../../../../../utils/getAuthToken';
import { keysToCamel } from '../../../../../utils/keysConverter';

export const GET_CANDIDATE_NOTES =
  'InstructorsCandidatesPage.GET_CANDIDATE_NOTES';
export const GET_CURRENT_USER_ID =
  'InstructorsCandidatesPage.GET_CURRENT_USER_ID';
export const CREATE_CANDIDATE_NOTE_SUCCESS =
  'InstructorsCandidatesPage.CREATE_CANDIDATE_NOTE_SUCCESS';
export const DELETE_CANDIDATE_NOTE_SUCCESS =
  'InstructorsCandidatesPage.DELETE_CANDIDATE_NOTE_SUCCESS';

export const getCandidateNotes = (id) => async (dispatch) => {
  try {
    const candidateNotes = await axios.get(
      `/api/v1/admin/instructors/candidate_notes?instructor_candidate_id=${id}`
    );
    dispatch({
      candidateNotes: keysToCamel(candidateNotes.data.data),
      type: GET_CANDIDATE_NOTES,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCurrentUserId = () => async (dispatch) => {
  try {
    const currentUserId = await axios.get('/api/v1/me');
    dispatch({
      currentUserId: currentUserId.data.data.id,
      type: GET_CURRENT_USER_ID,
    });
  } catch (e) {
    console.log(e);
  }
};

export const createNote = (authorId, note, id) => async (dispatch) => {
  const params = {
    authenticity_token: getAuthToken(),
    candidate_note: {
      author_id: authorId,
      note,
    },
  };

  try {
    const res = await axios.post(
      `/api/v1/admin/instructors/candidate_notes?instructor_candidate_id=${id}`,
      params
    );
    dispatch({
      note: keysToCamel(res.data),
      type: CREATE_CANDIDATE_NOTE_SUCCESS,
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteNote = (id) => async (dispatch) => {
  const params = {
    data: {
      authenticity_token: getAuthToken(),
    },
  };

  try {
    await axios.delete(
      `/api/v1/admin/instructors/candidate_notes/${id}`,
      params
    );
    dispatch({
      id,
      type: DELETE_CANDIDATE_NOTE_SUCCESS,
    });
  } catch (e) {
    console.log(e);
  }
};
