import PasswordChange from '../../components/PasswordChange';
import { connect } from 'react-redux';
import { updatePassword } from '../../actions';

export const mapStateToProps = ({
  employerDashboard: {
    passwordError,
  },
}) => ({
  passwordError,
});

export const mapDispatchToProps = (dispatch) => ({
  updatePassword: data => dispatch(updatePassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
