import styled from 'styled-components';

const StyledContentWrapper = styled.div`
  flex-basis: 48%;
  h3,
  p {
    margin-bottom: 16px;
    text-align: center;
  }
  margin-bottom: 40px;
`;

StyledContentWrapper.displayName = 'StyledContentWrapper';

export default StyledContentWrapper;
