import React, { useEffect } from 'react';
import Dashboard from '../../containers/Dashboard';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import StyledLoading from './styles/StyledLoading';
import { connect } from 'react-redux';
import { getInterviewAnswers } from '../../actions';

export const AppContainer = ({
  getInterviewAnswers,
  loading,
}) => {
  useEffect(() => {
    getInterviewAnswers();
  }, []);

  return loading
    ? <StyledLoading>
      <LoadingSpinner />
    </StyledLoading>
    : <Dashboard />;
};

AppContainer.propTypes = {
  getInterviewAnswers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export const mapStateToProps = ({
  interviewPrep: {
    loading,
  },
}) => ({
  loading,
});

export const mapDispatchToProps = dispatch => ({
  getInterviewAnswers: () => dispatch(getInterviewAnswers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
