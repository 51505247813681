import PropTypes from 'prop-types';
import React from 'react';

const ActivationFailure = ({ errorMessage, handleClose }) => (
  <>
    <i className="ds-icon__check-circle-line"></i>
    <h3 className="ds-typography__h3">Something went wrong..</h3>
    <p className="ds-typography__body">
      There was an error: {errorMessage?.message}
    </p>
    <button className="ds-button__primary" onClick={handleClose}>
      Close
    </button>
  </>
);

ActivationFailure.propTypes = {
  errorMessage: PropTypes.shape({
    message: PropTypes.string,
  }),
  handleClose: PropTypes.func,
};

export default ActivationFailure;
