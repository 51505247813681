export const ProductTypes = Object.freeze({
  course: 'course',
  program: 'program',
});

export const productType = (name: string) => {
  if (name.toLowerCase().includes(ProductTypes.program)) {
    return ProductTypes.program;
  } else {
    return ProductTypes.course;
  }
};
