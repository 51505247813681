module.exports = (() => {
  var init = function() {
    mentorCapacitiesTable();
    regionalCapacity();
    fillRate();
    avgStudentPerTutor();
  };

  var mentorCapacitiesTable = function() {
    $('.mentor_capacities_table').DataTable({
      "paging": false,
      "info": false,
      "searching": false
    });
  };

  // Calculates current tutor capacity per region
  var regionalCapacity = function() {
    var region = [ 'west','east', 'europe', 'asia' ]
    $.each(region, function(i, l){
      var regionalCapacity = 0
        $("." + l ).each(function() {

          var value = $(this).text();
          // add only if the value is number
          if(!isNaN(value) && value.length != 0) {
              regionalCapacity += parseFloat(value);
          }
        });
        $('.capacity-' + l).text(regionalCapacity)
    });
  };

  var mentees = 0;
  var capacity = 0;
  // Calculate the Fill Rate of all Mentors
  var fillRate = function() {
    $(".tutor_mentees").each(function() {

      var value = $(this).text();
      // add only if the value is number
      if(!isNaN(value) && value.length != 0) {
          mentees += parseFloat(value);
      }
    });

    $(".tutor_capacity").each(function() {

      var value = $(this).text();
      // add only if the value is number
      if(!isNaN(value) && value.length != 0) {
          capacity += parseFloat(value);
      }
    });
    var fillRate = ( (mentees / capacity) * 100 );
    $('.fill-rate').text(fillRate.toFixed(2) + '%');
  };

  // Calculate the Average # of students per tutor
  var avgStudentPerTutor = function() {
    var avg = (mentees / $('.tutor_mentees').length);
    $('.students-per-tutor').text(avg.toFixed(2));
  };

  return {
    init: init
  };
})();
