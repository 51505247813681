import NextStep from '../../components/NextStep';
import { connect } from 'react-redux';
import { openModal } from '../../actions';

export default connect(
  ({
    taskSubmission: {
      nextStepUrl,
      showSurvey,
      attendeeId,
      wsgZone,
      course,
      afa,
    },
  }) => ({
    afa,
    attendeeId,
    course,
    nextStepUrl,
    showSurvey,
    wsgZone,
  }),
  (dispatch) => ({
    openModal: (modalIsOpen) => dispatch(openModal(modalIsOpen)),
  })
)(NextStep);
