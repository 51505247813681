import React, { useEffect, useState } from 'react';
import FloatingInput from './FloatingInput';
import PropTypes from 'prop-types';
import StyledAddIcon from '../../styles/StyledAddIcon';

const AddLinkWithInput = ({ onInputChange, type, isOpen = false, initialValue, onCloseInput }) => {
  const [showInput, setShowInput] = useState(false);
  useEffect(() => {
    setShowInput(isOpen);
  }, []);

  const closeInput = () => {
    if (typeof onCloseInput === 'function') {
      onCloseInput();
    }
    setShowInput(!showInput);
  };

  return (
    <StyledAddIcon onClick={() => setShowInput(!showInput)}>
      <i className="fa fa-plus-circle"></i>
      <p>Add link</p>
      {showInput && (
        <FloatingInput
          initialValue={initialValue}
          onInputChange={onInputChange}
          type={type}
          onCloseInput={closeInput}
        />
      )}
    </StyledAddIcon>
  );
};

AddLinkWithInput.propTypes = {
  initialValue: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseInput: PropTypes.func,
  onInputChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default AddLinkWithInput;
