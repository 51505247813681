import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SortOrders } from '../../services/AdminListViewService';
import { isBottomOfDiv } from '../../utils/isBottomOfDiv';
import { loadingSpinnerHtml } from '../../utils/loadingSpinnerHtml';

const API_FETCH_LIMIT_MILLISECONDS = 1000;
let recentlyCalled = false;
const sortOrders = SortOrders.getSortOrders();

/**
 * build the table with infinite scroll and sortable columns
 * @param props - {
 *    headerColumns: [],
 *    rows: [[]],
 *    loading: Boolean,
 *    visibleRowCount: Int,
 *    totalRowCount: Int,
 *    fetchNewTableEntries: Function,
 *    onColumnClick: Function
 *  }
 */

const TableView = (props) => {
  if (!props.headerColumns || !props.rows) return null;
  if (props.headerColumns.length === 0) return null;

  const [state, setState] = useState({
    sortOrder: null,
    sortedColumn: null,
  });

  useEffect(() => {
    props.onColumnClick(state.sortedColumn, state.sortOrder);
  }, [state.sortOrder, state.sortedColumn]);

  document.addEventListener('scroll', () => bindScrollListener(props));

  const toggleRecentlyCalled = () => {
    recentlyCalled = !recentlyCalled;
  };

  const handleHeaderColumnClick = (item) => {
    const notSortable = ['target_completion', 'job_prep_progress'];
    if (!notSortable.includes(item)) {
      sortColumns(item);
    }
  };

  const sortColumns = (item) => {
    if (item === state.sortedColumn) {
      setState({
        sortOrder:
          state.sortOrder === sortOrders.ASC ? sortOrders.DSC : sortOrders.ASC,
        sortedColumn: item,
      });
    } else {
      setState({
        sortOrder: sortOrders.ASC,
        sortedColumn: item,
      });
    }
  };

  const renderHeaderRow = (columns) => {
    return (
      <tr>
        {columns.map((item, i) => (
          <th
            key={i}
            onClick={() => handleHeaderColumnClick(item.name)}
            className="header-column-labels"
          >
            {item.elem}{' '}
            {state.sortedColumn !== null && state.sortedColumn === item.name ? (
              <span
                className={`sorted-column-arrow--${
                  state.sortOrder === sortOrders.ASC ? 'asc' : 'dsc'
                }`}
              >
                ➤
              </span>
            ) : (
              ''
            )}
          </th>
        ))}
      </tr>
    );
  };

  const renderRow = (row, key) => {
    return (
      <tr key={key}>
        {row.map((item, j) => (
          <td key={j}>{item}</td>
        ))}
      </tr>
    );
  };

  const renderTable = (headerColumns, rows, loading) => {
    return (
      <table className="cf-admin-table" id="admin_table_wrapper_id">
        <thead>{renderHeaderRow(headerColumns)}</thead>
        <tbody>
          {rows.length > 0 ? (
            rows.map((row, i) => renderRow(row, i))
          ) : loading ? null : (
            <tr>
              <td>
                <span>No results</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const bindScrollListener = (props) => {
    const tableElement = document.getElementById('admin_table_wrapper_id');
    // we also check the scrollHeight as this gets triggered on pageload (probably with the loading div's addition)
    if (
      isBottomOfDiv(tableElement) &&
      !recentlyCalled &&
      tableElement.scrollHeight > 800
    ) {
      toggleRecentlyCalled();
      props.fetchNewTableEntries();
      setTimeout(() => {
        toggleRecentlyCalled();
      }, API_FETCH_LIMIT_MILLISECONDS);
    }
  };

  const renderLoaderSpinner = (isActive) => (
    <div
      className="loading_spinner_wrapper"
      dangerouslySetInnerHTML={{
        __html: isActive ? loadingSpinnerHtml() : '<div></div>',
      }}
    />
  );

  return (
    <div>
      <div>
        <p className="cf-small--bold">
          <span>{props.rows.length}</span>
          <span>&nbsp;of&nbsp;</span>
          <span>{props.totalRowCount}</span>
          <span>&nbsp;Results</span>

          <span className="table_header_download_btn">
            Download {props.totalRowCount} Results{' '}
            <a href={props.csvDownloadLink} download>
              <i className="fa fa-download" aria-hidden="true"></i>
            </a>
          </span>
        </p>
      </div>
      {renderTable(props.headerColumns, props.rows, props.loading)}
      {renderLoaderSpinner(props.loading)}
    </div>
  );
};

TableView.propTypes = {
  csvDownloadLink: PropTypes.string,
  fetchNewTableEntries: PropTypes.func,
  headerColumns: PropTypes.array,
  loading: PropTypes.bool,
  onColumnClick: PropTypes.func,
  rows: PropTypes.array,
  totalRowCount: PropTypes.number,
};

export default TableView;
