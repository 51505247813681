import ButtonWithIcon from '../../shared/components/ButtonWithIcon';
import MentorModalsContainer from '../../MentorCallNotes/containers/MentorModalsContainer';
import NotesHistoryTableContainer from '../containers/NotesHistoryTableContainer';
import React from 'react';
import StyledCallNotesHistory from './styles/StyledCallNotesHistory';
import StyledDashboardHeader from '../../CareerSupportCenter/components/DashboardWrapper/styles/StyledDashboardHeader';

const CallNotesHistory = () => (
  <StyledCallNotesHistory>
    <div className="ds-grid__container">
      <ButtonWithIcon
        href="/en/course/job-preparation/mentor_dashboard"
        icon="arrow-left"
        type="secondary"
        text="Back to Dashboard"
      />
      <StyledDashboardHeader className="ds-typography__h2">
        Note history
      </StyledDashboardHeader>
      <NotesHistoryTableContainer />
      <MentorModalsContainer />
    </div>
  </StyledCallNotesHistory>
);

export default CallNotesHistory;
