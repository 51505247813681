import styled from 'styled-components';

const StyledHeaderWrapper = styled.div`
  padding: 18px 58px 6px 33px;
  display: flex;
  justify-content: space-between;
  h5 {
    font-size: 28px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    padding: 18px 50px 6px 0px;
    display: flex;
    flex-direction: column;
    h5 {
      margin-bottom: 24px;
      margin-left: 12px;
    }
  }
`;

StyledHeaderWrapper.displayName = 'StyledHeaderWrapper';

export default StyledHeaderWrapper;
