import CallNotesListsContainer from '../../containers/CallNotesListsContainer';
import MentorModalsContainer from '../../containers/MentorModalsContainer';
import React from 'react';

const CallNotesCard = () => {
  return (
    <>
      <CallNotesListsContainer />
      <MentorModalsContainer />
    </>
  );
};

export default CallNotesCard;
