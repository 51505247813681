import { AppContainer, Card } from './styles';
import BSG from '../../containers/Tabs/BSG';
import TermsTab from '../../containers/Tabs/TermsOfParticipation';
import ProfileSurvey from '../../containers/Tabs/ProfileSurvey';
import EnglishTest from '../../containers/Tabs/EnglishTest';
import AdmissionTest from '../../containers/Tabs/AdmissionTest';
import React from 'react';
import { useState } from 'react';
import Sidebar from '../../containers/Sidebar';
import CompletionModal from '../CompletionModal';
import { IProgress } from '../../interfaces';

interface AppProps {
  selectedTab: string;
  progress: IProgress;
}

const App: React.FC<AppProps> = ({ selectedTab, progress }) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const handleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <AppContainer>
      <div className='ds-grid__container'>
        <div className='ds-grid__row'>
          <div className='ds-grid__col-lg-4 ds-grid__col-xs-12'>
            <Sidebar />
          </div>
          <div className='ds-grid__col-lg-8 ds-grid__col-xs-12'>
            <img
              src='https://images.careerfoundry.com/public/instructor_candidates/instructor_profile_header_image.webp'
              alt='desk'
            />
            <Card>
              {
                {
                  afaVoucher: <BSG />,
                  termsOfParticipation: <TermsTab />,
                  profileSurvey: <ProfileSurvey />,
                  englishTest: <EnglishTest />,
                  admissionTest: <AdmissionTest />,
                }[selectedTab]
              }
            </Card>
          </div>
        </div>
      </div>
      {progress.state === 'ready_for_contract' && (
        <CompletionModal modalIsOpen={modalIsOpen} handleClose={handleModal} />
      )}
    </AppContainer>
  );
};

export default App;
