import PrivacyPolicy from '../../components/onboarding/PrivacyPolicy';
import { connect } from 'react-redux';
import { updatePolicy } from '../../actions';

export const mapStateToProps = ({
  employerDashboard: {
    employer,
  },
}) => ({
  employer,
});

export const mapDispatchToProps = (dispatch) => ({
  updatePolicy: (employerId, data) => dispatch(updatePolicy(employerId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
