import './form';

module.exports = (() => {
  const init = function () {
    bindFunctions();
  };

  const bindFunctions = () => {
    $('#prorated_refund_cancelation_date').on('change', calculateRefund);
    $('#reset_calculation').on('click', resetCalculation);
  };

  const duration = parseInt($('#prorated_refund_duration').val());
  const totalPayment = parseInt(
    $('#prorated_refund_total_payment_amount').val()
  );

  const calculateRefund = (e) => {
    const date = e.target.value;

    if (isAbleToRefund(date)) {
      refundMessage(date);
    } else {
      noRefundMessage();
    }
  };

  const resetCalculation = () => {
    $('#prorated_refund_cancelation_date').val('');
    $('#reset_calculation').addClass('hidden');
    $('#refund_form_amount').val('');
    $('#refund_form_category').val('');
    $('#refund_feedback').html('');
    $('#refund_form_refund_on_payment_provider_and_generate_credit').prop(
      'checked',
      false
    );
  };

  const isAbleToRefund = (date) => daysUsed(date) / duration <= 0.6;

  const daysUsed = (date) => {
    const cancelDate = new Date(date);
    const startDate = new Date($('#prorated_refund_start_date').val());
    const diffTime = Math.abs(cancelDate - startDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const refundAmount = (date) => (1 - daysUsed(date) / duration) * totalPayment;

  const refundMessage = (date) => {
    const amount = refundAmount(date).toFixed(2);
    $('#reset_calculation').removeClass('hidden');
    $('#refund_feedback').html(`Refund amount: ${amount}`);
    $('#refund_form_amount').val(amount);
    $('#refund_form_category').val('course_cancelled');
    $('#refund_form_refund_on_payment_provider_and_generate_credit').prop(
      'checked',
      true
    );
  };

  const noRefundMessage = () =>
    $('#refund_feedback').html('No refund possible');

  return {
    init,
  };
})();
