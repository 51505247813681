import styled from 'styled-components';

const StyledHeader = styled.div`
  header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;
  }

  img {
    width: 78px;
    height: 78px;
    margin-right: 12px;
  }

  h2 {
    margin-bottom: 4px;
  }

  span {
    font-weight: 900;
  }

  h2,
  p {
    color: var(--ds-color__indigo);
  }
`;

StyledHeader.displayName = 'StyledHeader';

export { StyledHeader };
