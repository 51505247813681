import styled from 'styled-components';

export const WTopImages = styled.div`
  margin-top: 55px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .ob-image-item {
    padding: 0px 5px;
  }
`;

export const WTopImageItem = styled.div`
  padding: 0px 5px;
`;

export const WHeader = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--ds-color__indigo);
`;

export const WSubtitle = styled.div`
  margin-top: 16px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--ds-color__petrol);
`;

export const WBold = styled.div`
  font-weight: 700;
`;

export const WQuestions = styled.div`
  display: flex;
  width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const WQuestionItem = styled.div`
  padding: 8px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
`;

export const WQuestionItemNumber = styled.div`
  color: var(--ds-color__petrol);
  border: 2px solid var(--ds-color__petrol);
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 5px 7px;
  line-height: 16px;
`;

export const WQuestionItemText = styled.div`
  margin-left: 16px;
  word-break: break-word;
`;

export const WSubmit = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 32px;
    background: var(--ds-color__indigo);
    border-radius: 4px;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    width: 171px;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  @media only screen and (max-width: 768px) {
    padding: 0px 40px;
  }
`;
