import styled from 'styled-components';

const StyledInterviewQuestion = styled.div`
  position: relative;
  .content {
    background-color: #e8ebed;
    min-height: 374px;
    padding: 40px 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .header-section {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;

    h4 {
      margin: 12px 0 16px;
    }

    .parag-wrapper {
      display: flex;
      align-item: flex-start;
      padding: 24px 0 32px;
      background: rgba(34, 60, 80, 0.02);

      .svg-wrapper {
        margin-top: 8px;
      }

      svg {
        cursor: pointer;
      }

      p {
        line-height: 48px;
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    .header-section {
      p {
        margin: 0 0 12px;
        font-size: 32px;
        line-height: 1.25;
      }
      h4 {
        margin: 0;
      }
    }
  }
  .header {
    text-align: left;
  }
`;

StyledInterviewQuestion.displayName = 'StyledInterviewQuestion';

export default StyledInterviewQuestion;
