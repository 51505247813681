import PropTypes from 'prop-types';
import React from 'react';

const Multiple = ({
  onChange,
  property,
  currentValue,
  title,
  defaultValue,
}) => {
  const isPresent =
    currentValue.filter((id) => {
      return id === defaultValue;
    }).length > 0;
  return (
    <div className="cf-form__checkbox--open cf-multiple">
      <label htmlFor={`${property}-${defaultValue}`}>{title}</label>
      <input
        name={`${property}[]`}
        id={`${property}-${defaultValue}`}
        checked={isPresent}
        defaultValue={defaultValue}
        onChange={(ev) => {
          onChange(ev, 'multiple');
        }}
        type="checkbox"
      />
    </div>
  );
};

Multiple.propTypes = {
  currentValue: PropTypes.array,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  title: PropTypes.string,
};

const Select = ({
  onChange,
  property,
  title,
  options,
  required,
  currentValue,
}) => {
  return (
    <div className="ds-form__input__wrapper">
      <select
        name={property}
        id={property}
        value={currentValue}
        required={required}
        className="ds-form__input"
        onChange={(ev) => onChange(ev, 'select')}
      >
        {required && <option value=""></option>}
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {' '}
            {opt.title}
          </option>
        ))}
      </select>
      <i className="fa fa-angle-down"></i>
      <label htmlFor={property} className="ds-form__input__label">{`${title}${
        required ? '*' : ''
      }`}</label>
    </div>
  );
};

Select.propTypes = {
  currentValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  property: PropTypes.string.isRequired,
  required: PropTypes.bool,
  title: PropTypes.string,
};

const Bool = ({ onChange, property, currentValue, title }) => {
  return (
    <div
      key={`candidate-attribute-${property}`}
      className="cf-form__checkbox--open"
    >
      <label htmlFor={property}>{title}</label>
      <input
        name={property}
        id={property}
        checked={currentValue}
        onChange={(ev) => {
          onChange(ev, 'boolean');
        }}
        type="checkbox"
      />
    </div>
  );
};

Bool.propTypes = {
  currentValue: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  title: PropTypes.string,
};

const Text = ({
  onChange,
  property,
  currentValue = '',
  title,
  required,
  type,
  role,
}) => {
  const csRole =
    role === 'certified_career_specialist' || role === 'career_specialist';
  const hidden =
    (property === 'personal_email' || property === 'cf_email_password') &&
    !csRole;

  return (
    <div
      key={`candidate-attribute-${property}`}
      className={`ds-form__input__wrapper ${hidden ? 'hidden' : ''}`}
    >
      <label htmlFor={property} className="ds-form__input__label">{`${
        property === 'email' && csRole ? 'CF ' : ''
      }${title}${required || csRole ? '*' : ''}`}</label>
      <input
        name={property}
        id={property}
        defaultValue={currentValue}
        onChange={(ev) => {
          onChange(ev, 'text');
        }}
        required={required || csRole}
        type={type}
        className="ds-form__input"
      />
    </div>
  );
};

Text.propTypes = {
  currentValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  required: PropTypes.bool,
  role: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
};

const Builder = (type, props, onChange, currentValue, role) => {
  switch (type) {
    case 'multiple':
      return (
        <Multiple
          onChange={onChange}
          currentValue={currentValue}
          {...props}
          defaultValue={props.default_value}
          key={`from-builer-${props.property}-${props.default_value}`}
        />
      );
    case 'select':
      return (
        <Select
          onChange={onChange}
          currentValue={currentValue}
          {...props}
          key={`from-builer-${props.property}`}
        />
      );
    case 'boolean':
      return (
        <Bool
          onChange={onChange}
          currentValue={currentValue}
          {...props}
          key={`from-builer-${props.property}`}
        />
      );
    case 'text':
    case 'email':
    default:
      return (
        <Text
          onChange={onChange}
          type={type}
          role={role}
          currentValue={currentValue}
          {...props}
          required={props.required}
          key={`from-builer-${props.property}`}
        />
      );
  }
};

Builder.propTypes = {
  currentValue: PropTypes.any,
  onChange: PropTypes.func,
  props: PropTypes.shape({
    property: PropTypes.string,
    required: PropTypes.string,
  }),
  type: PropTypes.string.isRequired,
};

export default {
  Boolean: Bool,
  Builder,
  Multiple,
  Select,
  Text,
};
