import styled from 'styled-components';

const DataPolicyAgreementModalContent = styled.div`
  text-align: center;

  h1 {
    margin-bottom: 1rem;
    color: var(--ds-color__indigo);
  }

  a {
    font-weight: 700;
    text-decoration: underline;
  }

  p {
    color: var(--ds-color__indigo);
  }

  p:nth-of-type(2) {
    margin-bottom: 1.5rem;
  }

  button:nth-of-type(1) {
    margin-right: 1rem;
  }
`;

DataPolicyAgreementModalContent.displayName = 'DataPolicyAgreementModalContent';

export { DataPolicyAgreementModalContent };
