import PropTypes from 'prop-types';
import React from 'react';
import { Wrapper } from './styles';

const DisabledTooltipContent = ({ title = '', description = '' }) => {
  return (
    <Wrapper>
      <div className="ds-typography__body--bold">{title}</div>
      <div className="ds-typography_lead">{description}</div>
    </Wrapper>
  );
};

DisabledTooltipContent.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

export default DisabledTooltipContent;
