import React, { Component } from 'react'; 

import SignupForm from './SignupForm'
import SigninForm from './SigninForm'

class SigninSlide extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const SigninDetails = () => {
      return(
        <div className="enroll__signin__details enroll__form enroll__slide">
          <h2>You Are Logged In</h2>
          <p className="cf-small--gray">Name</p>
          <p>{this.props.user.name}</p>
          <p className="cf-small--gray">Email</p>
          <p>{this.props.user.email}</p>
          <footer>
            <button onClick={this.props.onNextClick} className="button--large button--primary-petrol">Next</button>
            <p className="cf-small">Not you? <a onClick={this.props.userSignout}>Sign Out</a></p>
          </footer>
        </div>
      )
    }

    const renderSlide = () => {
      const displayErrorMessage = (this.props.formError != "") ? this.props.formError : ""

      if(this.props.signedIn){
        return SigninDetails()
      }else if(this.props.showSignin){
        return(<SigninForm onSignupClick={this.props.onSignupClick}
                           userSignin={this.props.userSignin}
                           formError={this.props.formError}
                           displayErrorMessage={displayErrorMessage}
                           loadingState={this.props.loadingState}
              />)
      }else{
        return(<SignupForm onLoginClick={this.props.onLoginClick}
                           userSignup={this.props.userSignup}
                           formError={this.props.formError}
                           errorType={this.props.errorType}
                           displayErrorMessage={displayErrorMessage}
                           courseParams={this.props.courseParams}
                           onTermsClick={this.props.onTermsClick}
                           agreedToTerms={this.props.agreedToTerms}
                           loadingState={this.props.loadingState}
               />)
      }
    }

    return(
      <div className="enroll__signin_slide cf-content">
        {renderSlide()}
      </div>
    )
  }
}

export default SigninSlide
