import PropTypes from 'prop-types';
import React from 'react';
import StyledToggle from './styles';

const ToggleSwitch = ({ onChange = () => {}, checked, name, readOnly = false }) => {
  return (
    <StyledToggle readOnly={readOnly}>
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          name={name}
          readOnly={readOnly}
        />
        <span className="slider round"></span>
      </label>
    </StyledToggle>
  );
};

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default ToggleSwitch;
