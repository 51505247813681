import styled from 'styled-components';

const StyledPagination = styled.div`
  text-align: center;
  padding-top: 5px;
  width: 100%;
  margin: 10px 0px;
  i {
    padding: 0px 10px;
  }

  .grey {
    color: var(--ds-color__grey--light);
  }
  .clickable {
    cursor: pointer;
  }
`;

StyledPagination.displayName = 'StyledPagination';

export default StyledPagination;
