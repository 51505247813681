import styled from 'styled-components';

const StyledOffPlatformForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  min-height: 400px;
`;

StyledOffPlatformForm.displayName = 'StyledOffPlatformForm';

export default StyledOffPlatformForm;
