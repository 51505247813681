import styled from 'styled-components';

const Tab = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  height: 2rem;
  cursor: pointer;

  > div:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  > div:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

const TabOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.isSelected ? 'var(--ds-color__white)' : 'var(--ds-color__indigo)'};
  padding: 0 1rem;
  background-color: ${(props) =>
    props.isSelected ? 'var(--ds-color__indigo)' : 'var(--ds-color__white)'};
  border: ${(props) =>
    !props.isSelected ? '1px solid var(--ds-color__indigo)' : 'none'};
`;

export { Tab, TabOption };
