class TableFilter {
  constructor (inputBox, unfilteredTable) {
    this.inputBox = document.querySelector(inputBox);
    this.unfilteredTable = unfilteredTable;
  };

  init () {
    const obj = this;

    obj.inputBox.addEventListener('change', function () {
      obj.filt();
    });
  }

  filt () {
    const obj = this;
    const filt = obj.inputBox.value.toUpperCase();
    const trs = document.querySelectorAll(obj.unfilteredTable + ' tbody tr');
    let present;

    trs.forEach((tr) => {
      present = [...tr.children].find(function (x) {
        return x.innerHTML.toUpperCase().includes(filt);
      });

      tr.style.display = present ? '' : 'none';
    });
  }
}

export default TableFilter;
