module.exports = (() => {
  const init = () => {
    bindFunctions();
    adminSubmissionsTable();
    $('.datepicker').datepicker({
      dateFormat: 'yy-mm-dd',
    });
  };

  const bindFunctions = function() {
    $('.sample_submission--js').on('click', sampleSubmission);
    $('.admin_approved_sample--js').on('click', adminApprovedSample);
    $(document).on('change', '#admin_submissions_course_id', loadSteps);
  };

  const adminSubmissionsTable = () => $('.admin_submissions_data_table').DataTable();

  const loadSteps = function() {
    $.ajax({
      url: `/en/admin/submissions/load_steps/`,
      data: {
        course_id: $('#admin_submissions_course_id').val(),
      },
      method: 'POST',
      dataType: 'script'
    });
  };

  const sampleSubmission = function(){
    let submission_id = $(this).data('id');
    $.ajax({
      url: `/en/admin/submissions/${submission_id}/make_sample/`,
      data: {
        make_sample: $(this).prop('checked')
      },
      method: 'POST',
      dataType: 'script'
    });
  };

  const adminApprovedSample = function(){
    let submission_id = $(this).data('id');
    $.ajax({
      url: `/en/admin/submissions/${submission_id}/set_admin_approved.json`,
      data: JSON.stringify({
        admin_approved: $(this).prop('checked')
      }),
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json'
    });
  };

  return {
    init: init
  };
})();
