import styled from 'styled-components';

const TabStyles = styled.div`
  margin-top: 40px;

  ul {
    padding-left: 0;
    margin-bottom: 30px;
    position: relative;
  }

  .ds-tabs {
    overflow-x: visible;
  }

  .tabs-arrow {
    display: none;
  }

  .content .fa-chevron-right {
    display: block;
  }

  @media (max-width: 479px) {
    padding: 0 8px;

    .ds-tabs {
      overflow-x: scroll;

      li {
        padding: 0 15px;
      }
    }
  }

  @media (max-width: 450px) {
    .tabs-arrow {
      display: block;
      position: absolute;
      right: 10px;
    }
  }
`;

TabStyles.displayName = 'TabStyles';

export default TabStyles;
