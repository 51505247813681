import AudioSection from '../AudioRecorder/AudioSection';
import PropTypes from 'prop-types';
import React from 'react';
import { audioRecorderTracking } from '../../../shared/helpers/tracking';
import { formatReviewDate } from '../../helpers';

const EvaluationAudio = ({ isModal, currentAnswer, isMentorPage }) => {
  const trackPlayButton = (question) => {
    audioRecorderTracking(question, 'ip_evaluation_play_recording_click');
  };

  const pretext = isMentorPage ? 'Student Answer' : 'Your Answer';
  const date = formatReviewDate(currentAnswer.date);
  return (
    <div className="section text-left">
      <h5 className="ds-typography__h5">
        {pretext}
        {isModal && (
          <span>{` on ${date}`}</span>
        )}
      </h5>
      <div className="audio-wrapper">
        <AudioSection
          audio={currentAnswer.attachment.s3Url}
          trackPlayButton={() => trackPlayButton(currentAnswer.question)}
        />
      </div>
    </div>
  );
};

EvaluationAudio.propTypes = {
  currentAnswer: PropTypes.object,
  isMentorPage: PropTypes.bool,
  isModal: PropTypes.bool.isRequired,
};

export default EvaluationAudio;
