import React from 'react';
import resources from './constants/resources';

const AdditionalResources = () => (
  <>
    <ul>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/ux-design/how-to-become-a-remote-ux-designer/'
            )
          }
        >
          How to Become a Remote UX Designer (CareerFoundry)
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/web-development/become-a-remote-developer/'
            )
          }
        >
          How to Become a Remote Web Developer (CareerFoundry)
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/data-analytics/remote-data-analyst/'
            )
          }
        >
          Can Data Analysts Work Remotely (CareerFoundry)
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/ui-design/work-as-a-remote-ui-designer/'
            )
          }
        >
          Is It Possible to Work as a Remote User Interface (UI) Designer?
          (CareerFoundry)
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/ux-design/whats-it-like-to-be-a-remote-ux-designer-a-day-in-the-life/'
            )
          }
        >
          A Day in the Life of a Remote UX Designer (CareerFoundry)
        </a>
      </li>
    </ul>
    <h5 className='ds-typography__h5'>Remote collaboration tools</h5>
    {Object.entries(resources).map(([header, links]) => (
      <>
        <div className='ds-typography__lead'>{header}</div>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <a onClick={() => window.open(link.link)}>{link.text}</a>
            </li>
          ))}
        </ul>
      </>
    ))}
  </>
);

export default AdditionalResources;
