import styled from 'styled-components';

const StyledAudioLoader = styled.div`
  text-align: center;
  margin-bottom: 60px;
  i {
    margin-bottom: 26px;
  }
`;

StyledAudioLoader.displayName = 'StyledAudioLoader';

export default StyledAudioLoader;
