import styled from 'styled-components';

const StyledRowWrapper = styled.div`
  textarea {
    width: 100%;
    height: 211px;
    margin: 0;
    padding: 8px 8px 0;
    border-radius: 4px;
    border: solid 2px var(--ds-color__grey--400);
    background-color: var(--ds-color__white);
    font-size: 16px;
    font-weight: 300;
    color: var(--ds-color__indigo);
  }
`;

StyledRowWrapper.displayName = 'StyledRowWrapper';

export default StyledRowWrapper;
