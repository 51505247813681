module.exports = (() => {
  var init = function() {
    courseMentorsTable();
  };

  var courseMentorsTable = function() {
    $('.course_mentors_table').DataTable({
      "paging": false,
      "info": false,
      "searching": false
    });
  };

  return {
    init: init
  };
})();
