import ApiService from '../../../../../services/ApiService';

export const handleSubmit = () => async (dispatch) => {
  dispatch({
    type: 'InstructorProfile.SUBMIT_RESOURCES',
  });

  const apiService = new ApiService('/api/v1/instructors/onboarding_status');

  try {
    const result = await apiService.makeGetCall();
    dispatch({
      data: result.data,
      type: 'InstructorProfile.SUBMIT_RESOURCES_SUCCESS',
    });
  } catch (error) {
    console.log(error);
  }
};
