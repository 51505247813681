export const EUROPE_JOBS_CATEGORIES = [
  {
    category_name: 'united_kingdom',
    contents: [
      {
        link: 'https://app.otta.com/companies?covid-19',
        text: 'Tech Companies (London)',
      },
      {
        link: 'https://snap.hr/sign-up/talent/',
        text: 'Snap',
      },
      {
        link: 'https://jobs.theguardian.com/',
        text: 'Guardian Jobs',
      },
      {
        link: 'https://codebar.io/jobs',
        text: 'Codebar',
      },
      {
        link: 'https://hackajob.co/p/discover?utm_source=slack&utm_campaign=channels&utm_content=spr',
        text: 'Hackajob',
      },
      {
        link: 'https://www.reed.co.uk/',
        text: 'Reed',
      },
      {
        link: 'https://otta.com/rocketlist/',
        text: '​Otta',
      },
      {
        link: 'https://workinstartups.com/',
        text: 'WorkInStartups',
      },
      {
        link: 'https://www.thedrum.com//jobs/search/search',
        text: 'The Drum',
      },
      {
        link: 'https://www.stopgap.co.uk/jobs',
        text: 'STOPGAP',
      },
      {
        link: 'https://www.totaljobs.com/JobSearch/locations',
        text: 'Totaljobs',
      },
      {
        link: 'https://www.cv-library.co.uk/',
        text: 'CVLibrary',
      },
      {
        link: 'https://www.technojobs.co.uk/',
        text: 'technojobs',
      },
      {
        link: 'https://www.charityjob.co.uk/jobs',
        text: 'CharityJobs',
      },
      {
        link: 'https://www.cwjobs.co.uk/',
        text: 'CW Jobs (Web Development \u0026 Data Jobs)',
      },
    ],
    title: 'United Kingdom',
  },
  {
    category_name: 'germany',
    contents: [
      {
        link: 'https://jobboerse.arbeitsagentur.de/prod/vamJB/startseite.html?aa=1&m=1&kgr=as&vorschlagsfunktionaktiv=true',
        text: 'Jobbörse (Federal Employment Agency Job Board)',
      },
      {
        link: 'https://gesinesjobtipps.de/',
        text: 'Gesines Jobtipps (Non-Profit Jobs)',
      },
      {
        link: 'http://www.sticks-and-stones.com/',
        text: 'Sticks and Stones',
      },
      {
        link: 'https://www.tech4germany.org/',
        text: 'Tech 4 Germany',
      },
      {
        link: 'https://dasauge.de/',
        text: 'Dasauge.de',
      },
      {
        link: 'https://www.meinestadt.de/',
        text: 'Meinestadt.de',
      },
      {
        link: 'https://www.designmadeingermany.de/',
        text: '​Designmadeingermany.de',
      },
      {
        link: 'https://www.stepstone.de/',
        text: 'Stepstone.de',
      },
      {
        link: 'https://germanystartupjobs.com/',
        text: 'Germany Startup Jobs',
      },
      {
        link: 'https://www.jobs.de/',
        text: 'Jobs.DE',
      },
      {
        link: 'https://berlinstartupjobs.com/',
        text: 'Berlinstartupjobs.com',
      },
      {
        link: 'https://www.ferchau.com/de/de',
        text: 'Ferchau.com',
      },
      {
        link: 'https://germantechjobs.de/en',
        text: 'GermanTechJobs',
      },
      {
        link: 'https://www.seedtable.com/startups-berlin',
        text: 'Seedtable (Berlin)',
      },
      {
        link: 'https://www.creative-city-berlin.de/en/jobs/all/',
        text: 'Creative City Berlin',
      },
      {
        link: 'https://www.consortia.com/de-en/?fh=1',
        text: 'Consortia',
      },
      {
        link: 'https://www.monster.de/',
        text: 'Monster',
      },
      {
        link: 'https://startup.jobs/',
        text: 'STARTUP.JOBS',
      },
      {
        link: 'https://www.jobsinberlin.eu/',
        text: 'Jobs In Berlin',
      },
      {
        link: 'https://www.jobsinmunich.com/',
        text: 'Jobs In Munich',
      },
      {
        link: 'https://www.karriere.at/',
        text: 'karriere.at ',
      },
      {
        link: 'https://startupsucht.com/',
        text: 'STARTUP SUCHT',
      },
      {
        link: 'https://www.itjobs.rocks/',
        text: 'ITJobs.Rocks',
      },
      {
        link: 'https://www.stellenanzeigen.de/jobs/',
        text: 'Stellenanzeigen.de',
      },
      {
        link: 'https://www.harnham.com/',
        text: 'harnham (Data Jobs)',
      },
      {
        link: 'https://goodjobs.eu/',
        text: 'GOODJOBS',
      },
      {
        link: 'https://www.tbd.community/en/jobs',
        text: 'tbd*',
      },
      {
        link: 'https://www.heyjobs.co/de-de/jobs',
        text: 'HeyJobs',
      },
      {
        link: 'https://www.get-in-it.de/jobsuche',
        text: 'Get in (IT)',
      },
      {
        link: 'https://www.workwise.io/',
        text: 'Workwise',
      },
    ],
    title: 'Germany',
  },
  {
    category_name: 'other_countries',
    contents: [
      {
        link: 'https://www.moberries.com/',
        text: 'MoBerries',
      },
      {
        link: 'https://www.honeypot.io/lp/join?utm_source=slck-rubdev&utm_medium=community&utm_term=slck-rubdev-no_en-dev-12-09-17&utm_campaign=dev-no',
        text: 'HoneyPot (Developers)',
      },
      {
        link: 'https://www.talent.io/',
        text: 'Talent.io (Germany, France, UK, remote)',
      },
      {
        link: 'https://www.creativeset.net/',
        text: 'Creativeset.net (Germany, Austria and Switzerland)',
      },
      {
        link: 'https://www.designerdock.com/',
        text: 'Designerdock (Germany, Austria and Switzerland)',
      },
      {
        link: 'https://www.hyperisland.com/jobs',
        text: 'HyperIsland (Northern Europe - Scandinavia, UK, Netherlands)',
      },
      {
        link: 'https://digitaltalentservices.com/',
        text: '​Di​​gital Talent (UK and Ireland)',
      },
      {
        link: 'https://tq.co/jobs',
        text: 'TQ (Amsterdam StartUp Jobs)',
      },
      {
        link: 'https://www.jobindex.dk/',
        text: 'Job Index (Denmark)',
      },
      {
        link: 'http://www.greatercph.com/careers/jobs#?cludoquery=*&cludopage=1',
        text: 'Greater CHP (Denmark)',
      },
      {
        link: 'https://www.cphux.com/jobboard/',
        text: 'CPHUX (Denmark)',
      },
      {
        link: 'https://www.hays.de/',
        text: 'hays.de (Germany & EU)',
      },
      {
        link: 'https://www.zuehlke.com/en/careers/user-experience',
        text: 'Zühlke',
      },
      {
        link: 'https://eu.experteer.com/',
        text: 'Expert GmbH',
      },
      {
        link: 'https://www.jobteaser.com/en',
        text: 'JobTeaser',
      },
      {
        link: 'https://www.irishjobs.ie/',
        text: 'IRISHJOBS.IE (Ireland)',
      },
      {
        link: 'https://www.nijobs.com/',
        text: 'NIJOBS.COM (Northern Ireland)',
      },
      {
        link: 'https://gamedevelopers.ie/category/jobs-and-internships/',
        text: 'GameDevelopers.ie (Ireland)',
      },
      {
        link: 'https://remotejobsireland.com/',
        text: 'Remote Work Ireland',
      },
      {
        link: 'https://www.prosperity.ie/',
        text: 'Prosperity (Ireland, Spain, France)',
      },
      {
        link: 'https://www.welovesalt.com/',
        text: 'Salt (UK and Europe)',
      },
      {
        link: 'https://www.finn.no/job/browse.html',
        text: 'FINN (Norway)',
      },
      {
        link: 'https://unicornhunt.io/',
        text: 'Unicorn Hunt',
      },
    ],
    title: 'Other Countries',
  },
  {
    category_name: 'italy',
    contents: [
      {
        link: 'https://www.monster.it/',
        text: 'Monster',
      },
      {
        link: 'https://it.recruit.net/?&locale=it',
        text: 'Recruit',
      },
      {
        link: 'https://it.indeed.com/',
        text: 'Indeed',
      },
      {
        link: 'https://www.jobs.com/it-it/',
        text: 'Jobs.com',
      },
      {
        link: 'https://www.jobatus.it/',
        text: 'JOBATUS',
      },
      {
        link: 'https://it.jobsora.com/',
        text: 'Jobs Ora',
      },
      {
        link: 'https://www.adzuna.it/t',
        text: 'Adzuna',
      },
      {
        link: 'https://lavoro.trovit.it/',
        text: 'Trovit',
      },
      {
        link: 'https://www.careerjet.it/',
        text: 'Careerjet',
      },
    ],
    title: 'Italy',
  },
];
