import React, { useEffect, useState } from 'react';
import { createMessageChannel, handleCloseModal } from '../../actions';
import MessagingButton from '../../components/MessagingButton';
import PropTypes from 'prop-types';
import { chatClient } from '../../../Messaging';
import { connect } from 'react-redux';
import { openMessagingWindow } from '../../../Messaging/MessagingNotification/containers/ChannelButton/actions';
import { setMessageChannel } from '../../../Messaging/actions';

export const MessagingButtonContainer = ({
  messagingLoading,
  handleCloseModal,
  createMessageChannel,
  setMessageChannel,
  jobSeekingProfile,
  sort,
  employerUUID,
}) => {
  const filters = {
    members: { $eq: [employerUUID, jobSeekingProfile.uuid] },
    type: 'messaging',
  };

  const [creatingChannel, setCreatingChannel] = useState(false);
  const timer = ms => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    if (messagingLoading === false) {
      let channels = [];
      setCreatingChannel(true);
      (async () => {
        try {
          let attempts = 0;
          while (channels.length <= 0) {
            await timer(3000);
            channels = await chatClient.queryChannels(filters, sort);
            attempts += 1;
            setMessageChannel(channels[0]);
            if (attempts === 5) {
              break;
            }
          }
          setCreatingChannel(false);
          handleCloseModal();
        } catch (error) {
          console.log('uh oh', error);
        }
      })();
    }
  }, [messagingLoading, jobSeekingProfile]);

  return (
    <MessagingButton
      jobSeekingProfile={jobSeekingProfile}
      createMessageChannel={createMessageChannel}
      loading={messagingLoading || creatingChannel}
    />
  );
};

export const mapStateToProps = ({
  employerDashboard: {
    jobSeekingProfile,
    messagingLoading,
  },
  messaging: {
    isMessagingPreviewOpen,
    sort,
    me: { uuid },
  },
}) => ({
  employerUUID: uuid,
  isMessagingPreviewOpen,
  jobSeekingProfile,
  messagingLoading,
  sort,
});

export const mapDispatchToProps = dispatch => ({
  createMessageChannel: (studentId) => dispatch(createMessageChannel(studentId)),
  handleCloseModal: () => dispatch(handleCloseModal()),
  openMessagingWindow: (channelId) => dispatch(openMessagingWindow(channelId)),
  setMessageChannel: (channelId) => dispatch(setMessageChannel(channelId)),
});

MessagingButtonContainer.propTypes = {
  createMessageChannel: PropTypes.func,
  employerUUID: PropTypes.string,
  handleCloseModal: PropTypes.func,
  jobSeekingProfile: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
  }),
  messagingLoading: PropTypes.bool,
  setMessageChannel: PropTypes.func,
  sort: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagingButtonContainer);
