
import React, { useEffect, useState } from 'react';
import ButtonSection from './styles/InterviewReview__ButtonSection';
import EvaluationEditButton from './EvaluationEditButton';
import EvaluationPage from './EvaluationPage';
import PropTypes from 'prop-types';
import ReviewConsentBox from './ReviewConsentBox.js';
import StyledInterviewReview from './styles/StyledInterviewReview';

const InterviewReview = ({
  answersForReview,
  closeEvaluation,
  evaluateAnswer,
  finishInterview,
  isModal,
  currentAnswer,
  setCurrentAnswer,
  setReviewNavigable,
  reviewNote,
  reviewRating,
  isMentorPage,
  shareWithMentor,
}) => {
  useEffect(() => {
    setCurrentAnswer(answersForReview[0]);
  }, []);
  const [currentNavIndex, setNavIndex] = useState(0);

  const toggleNav = (direction) => {
    const nextIndex = answersForReview.findIndex(answer => answer.id === currentAnswer.id) + direction;
    setNavIndex(nextIndex);
    setCurrentAnswer(answersForReview[nextIndex]);
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  const buttonDisabled = () => {
    return answersForReview.map((answer) => answer.rating).includes(0);
  };

  const updateReview = (answerId, data) => {
    if (!answerId) {
      closeEvaluation();
      return;
    }
    evaluateAnswer(answerId, data)
      .then((res) => {
        setCurrentAnswer(res);
        setReviewNavigable(true);
        closeEvaluation();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <StyledInterviewReview isModal={isModal}>
      <EvaluationPage
        isMentorPage={isMentorPage}
        isModal={isModal}
        answersForReview={answersForReview}
        currentAnswer={currentAnswer}
        currentNavIndex={currentNavIndex}
        toggleNav={toggleNav}
      />
      {isModal
        ? (
          <EvaluationEditButton
            isModal={isModal}
            reviewRating={reviewRating}
            reviewNote={reviewNote}
            updateReview={updateReview}
            setReviewNavigable={setReviewNavigable}
            closeEvaluation={closeEvaluation}
            isMentorPage={isMentorPage}
          />
        )
        : (
          <>
            <ReviewConsentBox shareWithMentor={shareWithMentor}/>
            <ButtonSection isModal={isModal}>
              {
                <button
                  disabled={buttonDisabled()}
                  onClick={() => finishInterview()}
                  className="ds-button__primary"
                >
                Finish Evaluation
                </button>
              }
            </ButtonSection>
          </>
        )}
    </StyledInterviewReview>
  );
};

InterviewReview.propTypes = {
  answersForReview: PropTypes.array.isRequired,
  closeEvaluation: PropTypes.func.isRequired,
  currentAnswer: PropTypes.object,
  evaluateAnswer: PropTypes.func,
  finishInterview: PropTypes.func,
  isMentorPage: PropTypes.bool,
  isModal: PropTypes.bool.isRequired,
  reviewNote: PropTypes.object,
  reviewRating: PropTypes.object,
  setCurrentAnswer: PropTypes.func.isRequired,
  setReviewNavigable: PropTypes.func,
  shareWithMentor: PropTypes.bool.isRequired,
};

export default InterviewReview;
