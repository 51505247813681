import ModalContent from '../ModalContent';
import ModalHeader from '../ModalHeader';
import ModalWrapper from './styles';
import PropTypes from 'prop-types';
import React from 'react';
import TheModal from '../../../../shared/components/TheModal';
import Typeform from '../../../../shared/components/Typeform';

const CallFeedbackModal = ({
  formId,
  handleClose,
  handleSubmit,
  loading,
  modalIsOpen,
  selectedCall,
}) =>
  !loading && (
    <ModalWrapper callStatus={selectedCall.state}>
      <TheModal handleClose={handleClose} isModalOpen={modalIsOpen}>
        {selectedCall.state !== 'open' ? (
          <ModalContent
            callStatus={selectedCall.state}
            handleClose={handleClose}
          />
        ) : (
          selectedCall && (
            <>
              <ModalHeader
                mentor={selectedCall.mentor}
                date={selectedCall.date}
              />
              <Typeform
                handleSubmit={handleSubmit}
                formId={formId}
                params={{ call_id: selectedCall.callId }}
              />
            </>
          )
        )}
      </TheModal>
    </ModalWrapper>
  );

CallFeedbackModal.propTypes = {
  formId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  modalIsOpen: PropTypes.bool.isRequired,
  selectedCall: PropTypes.shape({
    callId: PropTypes.number,
    date: PropTypes.string,
    mentor: PropTypes.string,
    state: PropTypes.string,
  }),
};

export default CallFeedbackModal;
