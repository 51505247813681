import 'react-h5-audio-player/src/styles.scss';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { PlayButton, PlayerStyleWrapper } from './styles';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const AudioReader = ({
  audioSrc,
  descriptionText,
  handleTrackingEvent = () => {},
}) => {
  const audioPlayer = useRef(null);

  return (
    audioSrc && (
      <PlayerStyleWrapper>
        <AudioPlayer
          ref={audioPlayer}
          customAdditionalControls={[]}
          customVolumeControls={[]}
          layout={'stacked-reverse'}
          customProgressBarSection={[
            RHAP_UI.CURRENT_TIME,
            RHAP_UI.PROGRESS_BAR,
            RHAP_UI.DURATION,
          ]}
          customControlsSection={[
            <p className='ds-typography__body--bold ' key='custom-control-1'>
              {descriptionText}
            </p>,
            RHAP_UI.MAIN_CONTROLS,
          ]}
          showJumpControls={false}
          src={audioSrc}
          customIcons={{
            pause: <PlayButton className='ds-icon__player-pause' />,
            play: <PlayButton className='ds-icon__player-play-circle' />,
          }}
          onListen={handleTrackingEvent}
        />
      </PlayerStyleWrapper>
    )
  );
};

AudioReader.propTypes = {
  audioSrc: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  handleTrackingEvent: PropTypes.func,
};

export default AudioReader;
