import styled from 'styled-components';

const RevisionTitle = styled.h6`
  background-color: #fff;
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
  margin: 0 -16px;
  padding: 10px 16px;
`;

RevisionTitle.displayName = 'RevisionTitle';

export default RevisionTitle;
