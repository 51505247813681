import styled from 'styled-components';

const StyledVisualizer = styled.div`
  @media (max-width: 800px) {
    canvas {
      width: 99%;
      padding: 20px;
    }
  }
`;

StyledVisualizer.displayName = 'StyledVisualizer';

export default StyledVisualizer;
