import styled from 'styled-components';

const StyledMediaLibrary = styled.div`
  margin-bottom: 60px;
  padding-right: 60px;

  h2 {
    text-align: left;
    margin-bottom: 60px;
  }

  hr {
    border: solid 1px #e8ebed;
    margin-bottom: 24px;
  }

  & > hr:last-of-type {
    display: none;
  }

  @media (max-width: 1022px) {
    & > div {
      margin-top: 0;
    }
  }

  @media (max-width: 767px) {
    padding-right: 8px;

    hr {
      margin: 0 -8px;
    }

    & > hr:last-of-type {
      display: block;
    }
  }
`;

StyledMediaLibrary.displayName = 'StyledMediaLibrary';

export default StyledMediaLibrary;
