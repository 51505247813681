import Capacity from '../../containers/Capacity';
import Card from '../../styles/Card';
import Documents from '../../containers/Documents';
import PersonalInformation from '../../containers/PersonalInformation';
import PropTypes from 'prop-types';
import React from 'react';
import Resources from '../../containers/Resources';
import Sidebar from '../../containers/Sidebar';
import StyledApp from './styles';
import SubmissionModal from '../../containers/SubmissionModal';

const App = ({ selectedTab }) => (
  <StyledApp>
    <div className="ds-grid__container">
      <div className="ds-grid__row">
        <div className="ds-grid__col-lg-4 ds-grid__col-xs-12">
          <Sidebar />
        </div>
        <div className="ds-grid__col-lg-8 ds-grid__col-xs-12">
          <img
            src="https://images.careerfoundry.com/public/instructor_candidates/instructor_profile_header_image.webp"
            alt="desk"
          />
          <Card>
            {
              {
                capacity: <Capacity />,
                documents: <Documents />,
                personal_information: <PersonalInformation />,
                resources_surveys: <Resources />,
              }[selectedTab]
            }
          </Card>
        </div>
      </div>
    </div>
    <SubmissionModal />
  </StyledApp>
);

App.propTypes = {
  selectedTab: PropTypes.string.isRequired,
};

export default App;
