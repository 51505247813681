import styled from 'styled-components';

const StyledBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  height: 64px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3);

  div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 14px;
  }

  span {
    position: absolute;
    top: 10px;
    right: 8px;
  }

  button {
    margin-left: 24px;
  }

  & + div > div {
    height: 60vh;
  }

  @media (max-width: 767px) {
    height: auto;

    div {
      margin-bottom: 20px;
      flex-direction: column;
    }

    button {
      margin-left: 0;
    }

    p {
      margin-right: 20px;
    }
  }
`;

StyledBanner.displayName = 'StyledBanner';

export default StyledBanner;
