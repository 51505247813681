import { Label, Wrapper } from './styles';
import { BookIcon } from '../../icons';
import PropTypes from 'prop-types';
import React from 'react';
import { redirectTo } from '../../../../utils/redirectTo.ts';

const CurrentExerciseCta = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }
  const getPathnameForCurrentExercisePage = (locale = 'en') =>
    `/api/v1/current_exercise?locale=${locale}`;

  return (
    <Wrapper
      onClick={() => redirectTo({ url: getPathnameForCurrentExercisePage() })}
    >
      <BookIcon />
      <Label className="ds-typography__body--bold">
        Go to current Exercise
      </Label>
    </Wrapper>
  );
};

CurrentExerciseCta.propTypes = {
  isVisible: PropTypes.bool,
};

export default CurrentExerciseCta;
