import React, { useEffect, useState } from 'react';
import CallsTile from '../MainDashboard/components/CallsTile';
import CommunityEventTile from '../MainDashboard/components/CommunityEventTile';
import CommunityTile from '../MainDashboard/components/CommunityTile';
import PropTypes from 'prop-types';
import StyledFlexWrapper from '../MainDashboard/styles/StyledFlexWrapper';
import UsefulLinksMobileView from '../MainDashboard/mobileViews/UsefulLinksMobileView';
import { getCallsLeft } from '../../containers/CallsCalendarCard/actions';

const UsefulLinks = ({ attendeeId, isMobile }) => {
  const [callsLeft, setCallsLeft] = useState(null);

  useEffect(() => {
    getCallsLeft(attendeeId).then((res) => {
      setCallsLeft(res.callsLeft);
    });
  }, []);

  return (
    <div>
      {isMobile ? (
        <UsefulLinksMobileView />
      ) : (
        <StyledFlexWrapper>
          {!!callsLeft && <CallsTile />}
          <CommunityTile />
          <CommunityEventTile />
        </StyledFlexWrapper>
      )}
    </div>
  );
};

UsefulLinks.propTypes = {
  attendeeId: PropTypes.number,
  isMobile: PropTypes.bool,
};

export default UsefulLinks;
