import { ModalActions } from '../../../../CallsEdit/components/ConfirmationModal/styles';
import PropTypes from 'prop-types';
import React from 'react';

const DeletedSuccessContent = ({ handleModalClose }) => (
  <>
    <i className="ds-icon__check-circle-fill" />
    <h3 className="ds-typography__h3">Canceled</h3>
    <p className="ds-typography__body">
      You have canceled your time off. You will receive a confirmation email
      shortly. No further actions are required.
    </p>
    <ModalActions>
      <button className="ds-button__primary" onClick={handleModalClose}>
        Got it
      </button>
    </ModalActions>
  </>
);

DeletedSuccessContent.propTypes = {
  handleModalClose: PropTypes.func,
};

export default DeletedSuccessContent;
