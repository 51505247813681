import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import StyledBackgroundImage from './styles/StyledBackgroundImage';
import StyledContentSection from './styles/StyledContentSection';
import StyledInterviewPrepCard from './styles/StyledInterviewPrepCard';
import StyledSectionHeader from '../MainDashboard/styles/StyledSectionHeader';

const MobileInterviewPreview = ({
  openInterviewGuidelines,
  startInterview,
}) => (
  <StyledInterviewPrepCard>
    <StyledContentSection mobile={true}>
      <StyledSectionHeader>
        <h4 className="ds-typography__h4 text-left">Practice Interview</h4>
      </StyledSectionHeader>
      <StyledBackgroundImage mobile={true} />
      <ul>
        <li className="ds-typography__body">
          Train yourself with common interview questions
        </li>
        <li className="ds-typography__body">
          Record your answers and review them afterwards
        </li>
      </ul>
      <span
        className="ds-typography__body ds-typography__anchor guidelines"
        onClick={() => openInterviewGuidelines()}
      >
        View the guidelines
      </span>
    </StyledContentSection>
    <Link
      to="/en/interview-training"
      onClick={startInterview}
      className="ds-button__primary"
    >
      Start a new practice
    </Link>
    <a
      href="/en/interview-training/"
      className="ds-button__secondary"
      target="_blank"
    >
      View history
    </a>
  </StyledInterviewPrepCard>
);

MobileInterviewPreview.propTypes = {
  openInterviewGuidelines: PropTypes.func.isRequired,
  startInterview: PropTypes.func.isRequired,
};

export default MobileInterviewPreview;
