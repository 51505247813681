import { startCase, truncate } from 'lodash';
import EmptyState from './EmptyState';
import PropTypes from 'prop-types';
import React from 'react';
import StyledJobApplicationTableWrapper from './styles/StyledJobApplicationTableWrapper';

const JobApplicationsTable = ({
  headers,
  jobApplications,
  handleUpdateJobApplication,
  handleViewJobApplication,
}) => {
  return (
    <>
      <>
        <StyledJobApplicationTableWrapper>
          <table className='ds-table'>
            <thead>
              <tr className='ds-table__tr'>
                {headers.map((column, i) => {
                  if (typeof column === 'object') {
                    return (
                      <th className='ds-table__th' key={i}>
                        {startCase(column.name)}
                      </th>
                    );
                  }
                  return (
                    <th className='ds-table__th' key={i}>
                      {startCase(column)}
                    </th>
                  );
                })}
                <th className='action-column ds-table__th'>Edit</th>
              </tr>
            </thead>
            <tbody>
              {jobApplications.length >= 1 &&
                jobApplications.map((application) => (
                  <tr className='ds-table__tr' key={application.id}>
                    {headers.map((column) => {
                      if (typeof column === 'object') {
                        return (
                          <td
                            key={`${column.name}_${application.id}`}
                            className='ds-typography__body--bold ds-table__td'
                          >
                            {column.type === 'viewLink' ? (
                              <a
                                className='ds-typography__anchor link'
                                onClick={() =>
                                  handleViewJobApplication(application.id)
                                }
                              >
                                {application[column.name]}
                              </a>
                            ) : (
                              <a
                                className='ds-typography__anchor link lowercase'
                                href={application[column.name]}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {truncate(application[column.name], {
                                  length: 60,
                                })}
                              </a>
                            )}
                          </td>
                        );
                      }
                      return (
                        <td
                          key={`${column}_${application.id}`}
                          className='ds-typography__body--bold ds-table__td'
                        >
                          {application[column]}
                        </td>
                      );
                    })}
                    <td className='action-column ds-table__td'>
                      <a
                        className='fa fa-pencil'
                        onClick={() =>
                          handleUpdateJobApplication(application.id)
                        }
                      ></a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </StyledJobApplicationTableWrapper>
        {!jobApplications.length && <EmptyState />}
      </>
    </>
  );
};

JobApplicationsTable.propTypes = {
  handleUpdateJobApplication: PropTypes.func.isRequired,
  handleViewJobApplication: PropTypes.func.isRequired,
  headers: PropTypes.array.isRequired,
  jobApplications: PropTypes.array.isRequired,
};

export default JobApplicationsTable;
