import styled from 'styled-components';

const CurrentIndicator = styled.div`
  background-color: var(--ds-color__petrol);
  border-radius: 50%;
  display: inline-block;
  height: 15px;
  justify-content: space-between;
  margin-right: 0.5rem;
  min-width: 15px;
  position: relative;

  i {
    color: #fff;
    display: block;
    position: relative;
    transform: translate(25%, 50%);
  }

  &::before {
    animation: exercisePulse 3s infinite;
    border: 15.5px solid #5ca2b2;
    border-radius: 50%;
    content: '';
    display: block;
    left: -8px;
    position: absolute;
    top: -8px;

    @keyframes exercisePulse {
      0% {
        opacity: 0;
        transform: scale(0);
      }

      25% {
        opacity: 0.1;
        transform: scale(0);
      }

      50% {
        opacity: 0.3;
        transform: scale(0.1);
      }

      75% {
        opacity: 0.5;
        transform: scale(0.5);
      }

      100% {
        opacity: 0;
        transform: scale(1);
      }
    }
  }
`;

CurrentIndicator.displayName = 'CurrentIndicator';

export default CurrentIndicator;
