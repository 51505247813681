import AdminEmployerConnectorModal from '../../components/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { handleCloseModal } from '../../../actions';

const AdminEmployerConnectorModalContainer = ({
  employer,
  handleCloseModal,
  modalAction,
  modalIsOpen,
}) => {
  return (
    <AdminEmployerConnectorModal
      employer={employer}
      handleCloseModal={handleCloseModal}
      modalIsOpen={modalIsOpen}
      modalAction={modalAction} />
  );
};

export const mapStateToProps = ({
  adminEmployerConnector: {
    employer,
    modalIsOpen,
    modalAction,
  },
}) => ({
  employer,
  modalAction,
  modalIsOpen,
});

export const mapDispatchToProps = dispatch => ({
  handleCloseModal: () => dispatch(handleCloseModal()),
});

AdminEmployerConnectorModalContainer.propTypes = {
  employer: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  handleCloseModal: PropTypes.func.isRequired,
  modalAction: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmployerConnectorModalContainer);
