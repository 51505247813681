import { init as _init, toggleSubmitButton } from '../new';
import axios from 'axios';

module.exports = (() => {
  const init = () => bindFunctions();

  const bindFunctions = () => {
    $(document).on('change', '.product-list-js', updateStartDates);
    $(document).on(
      'change',
      '.product-start-date-list-js',
      updateRelevantDates
    );
    $('.payer-list-js').select2();
    datePicker();
    _init();
  };

  const datePicker = function () {
    $('.datepicker').datepicker({
      dateFormat: 'dd.mm.yy',
      onSelect: () => toggleSubmitButton(),
    });
  };

  const updateStartDates = async () => {
    const res = await axios.get(
      '/api/v1/admin/products/' +
        $('.product-list-js').val() +
        '/start_dates.json'
    );
    $('.product-start-date-list-js').empty();
    $('.product-list-js').data('length', res.data.product_length);
    const trialEndDate = new Date(res.data.trial_end_date);
    $('.enrollment-trial-end-date-js').datepicker('setDate', trialEndDate);
    const endDate = new Date(res.data.end_date);
    $('.enrollment-end-date-js').datepicker('setDate', endDate);
    $.each(res.data.data, function (_i, v) {
      $('.product-start-date-list-js').append(
        $('<option></option>')
          .attr('value', v.attributes.start)
          .text(v.attributes.formatted_start)
      );
    });
  };

  const updateRelevantDates = () => {
    const startDate = $('.product-start-date-list-js').val();
    const trialDate = new Date(startDate);
    const endDate = new Date(startDate);
    trialDate.setDate(trialDate.getDate() + 14);
    $('.enrollment-trial-end-date-js').datepicker('setDate', trialDate);
    endDate.setMonth(endDate.getMonth() + $('.product-list-js').data('length'));
    $('.enrollment-end-date-js').datepicker('setDate', endDate);
  };

  return {
    init,
  };
})();
