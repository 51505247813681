import { Body } from '@careerfoundry/bubble';
import React from 'react';
import { ErrorMessageWrapper } from './styles';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <ErrorMessageWrapper>
      <Body tag='p' variant='small-bold'>
        {message}
      </Body>
    </ErrorMessageWrapper>
  );
};

export default ErrorMessage;
