module.exports = (() => {
  const thisState = {
    element: null,
    extention: {
      actionName: '',
      allowed_days: 0,
      targetId: '',
    },
  };

  const init = () => {
    datePicker();
    select2();
    bindFunctions();
    initializeValidator();
    thisState.form = new CF.Form('#extend_attendee_modal');
  };

  const getAuthToken = () =>
    document.querySelector('meta[name="csrf-token"]').content;

  const bindFunctions = () => {
    $(document).on('click', '.openCancelModal--js', openCancelModal);
    $('.extendStudents--js').on('click', setExtentionState);
    $('.submitExtention--js').on('click', extendStudents);
    $('.bufferCourses--js').on('click', setBufferModalData);
    $('.submitbufferCourses--js').on('click', bufferCourse);
    $('.number_of_buffer_days .buffer-time-form__input').on(
      'keyup',
      bufferCourseInputKeyup
    );
    $('.buffer_attendee_modal-actions .button--back').on(
      'click',
      resetBufferModalData
    );
    $('.cancel-trial-course--js').on('click', handleTrialCourseCancellation);
    $('.createConversation--js').on('click', createConversation);
    $('.createAIAssistant--js').on('click', createAIAssistant);
  };

  const createConversation = async function (e) {
    e.preventDefault();
    const attendeeId = $(this).data('attendee-id');
    const response = await fetch(
      `/en/admin/attendees/${attendeeId}/create_conversation`
    );
    const data = await response.json();

    if (data.message || data.error) {
      alert('There was an issue with your request, please try again.');
    } else {
      alert('Conversation created.');
    }
  };

  const createAIAssistant = async function (e) {
    e.preventDefault();
    const userId = $(this).data('user-id');
    const response = await fetch(
      `/api/v1/admin/users/${userId}/create_ai_assistant`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getAuthToken(),
        },
        method: 'POST',
      }
    );
    const data = await response.json();

    if (data.message || data.error) {
      alert('There was an issue with your request, please try again.');
    } else {
      alert('Conversation created.');
    }
  };

  const setExtentionState = function () {
    thisState.extention.actionName = $(this).data('action-name');
    thisState.extention.targetId = $(this).data('target-id');
  };

  const extendStudents = function () {
    let successCallback;
    if (thisState.extention.actionName === 'extend_single_attendee') {
      successCallback = _singleAttendeeSuccess;
    } else if (
      thisState.extention.actionName === 'extend_all_students_of_user'
    ) {
      successCallback = _allAttendeesOfUserSuccess;
    }

    if ($('#number_of_days').val().length > 0) {
      $(this).replaceWith(Template.loading_spinner);
      $('.button--back').hide();
      $.ajax({
        data: {
          days: $('#number_of_days').val(),
          only_afa: !!$('#only_afa').is(':checked'),
        },
        error: function (response) {
          alert('Something went wrong.. ' + response.responseText);
        },
        success: successCallback,
        type: 'POST',
        url: _getActionRoute(),
      });
    } else {
      alert('please enter a number');
    }
  };

  const setBufferModalData = function () {
    thisState.extention.targetId = $(this).data('target-id');
    thisState.extention.allowed_days = $(this).data('remaining-buffer');
    thisState.element = $(this);
    $('.remaining-number-of-buffer-days').text(
      $(this).data('remaining-buffer')
    );
  };

  const bufferCourse = function () {
    if ($.isNumeric($('#number_of_buffer_days').val())) {
      if (
        $('#number_of_buffer_days').val() <= thisState.extention.allowed_days
      ) {
        $(
          '.number_of_buffer_days_label label, .buffer-time-form__input'
        ).removeClass('error');
        $('.buffer-time-error').hide();
        $('.buffer_attendee_modal-actions').hide();
        $('.spinner-area').html(Template.loading_spinner);
        $.ajax({
          data: {
            days: $('#number_of_buffer_days').val(),
          },
          error: _bufferTimeErrorCallback,
          success: _bufferTimeSuccessCallback,
          type: 'POST',
          url: `/en/admin/attendees/${thisState.extention.targetId}/buffer_course/`,
        });
      } else {
        $('.buffer-time-error').text(
          'Please enter ' + thisState.extention.allowed_days + ' or fewer days '
        );
        $(
          '.number_of_buffer_days_label label, .buffer-time-form__input'
        ).addClass('error');
        $('.buffer-time-error').show();
      }
    } else {
      $('.buffer-time-error').text('please enter a number');
    }
  };

  const bufferCourseInputKeyup = function () {
    if ($(this).val().length > 0) {
      $('.submitbufferCourses--js').prop('disabled', false);
    } else {
      $('.submitbufferCourses--js').prop('disabled', true);
    }
  };

  const _bufferTimeSuccessCallback = function (response) {
    $('.confirmation-feedback .buffer-time-target-completion').text(
      response.attendee_target_completion
    );
    if (response.job_prep_target_completion) {
      $('.confirmation-feedback .buffer-time-job-prep-target-completion').text(
        response.job_prep_target_completion
      );
      $('.confirmation-feedback .buffer-time-job-prep').show();
      $(thisState.element)
        .closest('.attendees_table-js')
        .find('.job_prep_target_completion--js')
        .text(response.job_prep_target_completion);
      $(thisState.element)
        .closest('.attendees_table-js')
        .find('.job_prep_end_date--js')
        .text(response.job_prep_end_date);
    } else {
      $('.confirmation-feedback .buffer-time-job-prep').hide();
    }

    $('#buffer_attendee_modal').modal('hide');
    $('#buffer_attendee_result_modal').modal('show');
    $(thisState.element)
      .closest('tr')
      .find('.attendee_target_completion--js')
      .text(response.attendee_target_completion);
    $(thisState.element).data(
      'remaining-buffer',
      thisState.extention.allowed_days - $('#number_of_buffer_days').val()
    );
    _renderNote(response.note);
    if (response.program_attendee_target_completion) {
      $(thisState.element)
        .closest('#admin-user-profile')
        .find('.program_attendees_table .program_target_completion')
        .text(response.program_attendee_target_completion);
    }

    _hideSpinner();
  };

  const _bufferTimeErrorCallback = function (response) {
    $('.buffer-time-error').text(response.responseText);
    $('.number_of_buffer_days_label label, .buffer-time-form__input').addClass(
      'error'
    );
    $('.buffer-time-error').show();
    _hideSpinner();
  };

  const _renderNote = function (bufferTimeNote) {
    $('.notes-heading').removeClass('hidden');
    $('.user-notes').prepend(
      "<div class='note-area' id='user_note_id_" +
        bufferTimeNote.id +
        "' >" +
        '<p>' +
        bufferTimeNote.note +
        '</p>' +
        "<div class='help-block'>" +
        bufferTimeNote.name +
        ' on ' +
        bufferTimeNote.updated_at +
        '</div>' +
        "<a data-remote='true' rel='nofollow' data-method='delete' href='/en/user_notes/" +
        bufferTimeNote.id +
        "'><i class='glyphicon glyphicon-trash'></i></a></div>"
    );
  };

  const _hideSpinner = function () {
    $('.spinner-area').html('');
    $('.buffer_attendee_modal-actions').show();
  };

  const resetBufferModalData = function () {
    $('#number_of_buffer_days').val('');
  };

  const _singleAttendeeSuccess = function (response) {
    $('#extend_attendee_modal').modal('hide');
    $('#attendees_table').load(' #attendees_table');
    alert('Updated! New end date:' + response.end_date);
  };

  const _allAttendeesOfUserSuccess = function (response) {
    $('#extend_attendee_modal').modal('hide');
    alert(
      response.success + ' attendees updated \n' + response.error.join('\n')
    );
  };

  const _getActionRoute = function () {
    const id = thisState.extention.targetId;
    if (thisState.extention.actionName === 'extend_single_attendee') {
      return `/en/admin/attendees/${id}/extend_attendee/`;
    } else {
      return `/en/admin/users/${id}/extend_students_of_user/`;
    }
  };

  const datePicker = function () {
    $('.datepicker').datepicker({
      dateFormat: 'yy-mm-dd',
    });
  };

  const select2 = () => $('#attendee_course_id').select2();

  const openCancelModal = function () {
    const attendeeId = $(this).data('attendee-id');
    $('#cancel_attendee_modal_' + attendeeId).modal('show');
  };

  const initializeValidator = () => {
    $('.cancellation_reason_form').validate({
      messages: {
        'attendee[cancellation_reason_id]': {
          required: 'Please enter cancellation reason',
        },
      },
      rules: {
        'attendee[cancellation_reason_id]': {
          required: true,
        },
      },
    });
  };

  const cancelTrialCourse = (attendeeId) => {
    const params = {
      attendee: {
        cancellation_notes: 'TP failed',
      },
      cancel_now: true,
      trial_period_cancellation: true,
    };

    $.ajax({
      data: params,
      error: function (response) {
        alert(`There was an error: ${error.response.data.errors}`);
      },
      success: function (response) {
        alert('Course cancelled!');
        location.reload();
      },
      type: 'GET',
      url: `/admin/attendees/${attendeeId}/cancel_attendee`,
    });
  };

  const handleTrialCourseCancellation = function () {
    window.confirm('Are you sure you want to cancel this course?') &&
      cancelTrialCourse($(this).data('attendee-id'));
  };

  return {
    init: init,
  };
})();
