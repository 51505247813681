import PropTypes from 'prop-types';
import React from 'react';

const LastStepButton = ({ title = 'Got it' }) => {
  return (
    <span className="ds-button__tertiary tourNextButton">{title}</span>
  );
};

LastStepButton.propTypes = {
  title: PropTypes.string,
};

export default LastStepButton;
