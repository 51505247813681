import { cancelECModal, deleteJobApplication, handleCancelModal, handleCloseModal, showECModal } from '../../actions';
import CareerSupportCenterModal from '../../components/CareerSupportCenterModal/index';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const CareerSupportCenterModalContainer = ({
  cancelECModal,
  deleteJobApplication,
  ecModalType,
  formData,
  handleCancelModal,
  handleCloseModal,
  loading,
  modalIsOpen,
  modalAction,
  optinModalStep,
  showECModal,
  userId,
}) => {
  const handleCancel = () => {
    handleCancelModal(formData);
  };

  const confirmDelete = () => {
    deleteJobApplication(formData.id);
  };

  return (
    !loading &&
    <CareerSupportCenterModal
      cancelECModal={cancelECModal}
      ecModalType={ecModalType}
      formData={formData}
      confirmDelete={confirmDelete}
      handleCancel={handleCancel}
      handleCloseModal={handleCloseModal}
      modalIsOpen={modalIsOpen}
      modalAction={modalAction}
      optinModalStep={optinModalStep}
      showECModal={showECModal}
      userId={userId} />
  );
};

export const mapStateToProps = ({
  careerSupportCenter: {
    ecModalType,
    formData,
    loading,
    modalAction,
    modalIsOpen,
    optinModalStep,
  },
}) => ({
  ecModalType,
  formData,
  loading,
  modalAction,
  modalIsOpen,
  optinModalStep,
});

export const mapDispatchToProps = dispatch => ({
  cancelECModal: () => dispatch(cancelECModal()),
  deleteJobApplication: tempData => dispatch(deleteJobApplication(tempData)),
  handleCancelModal: tempData => dispatch(handleCancelModal(tempData)),
  handleCloseModal: () => dispatch(handleCloseModal()),
  showECModal: () => dispatch(showECModal()),
});

CareerSupportCenterModalContainer.propTypes = {
  cancelECModal: PropTypes.func.isRequired,
  deleteJobApplication: PropTypes.func.isRequired,
  ecModalType: PropTypes.string.isRequired,
  formData: PropTypes.object,
  handleCancelModal: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  modalAction: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  optinModalStep: PropTypes.string,
  showECModal: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CareerSupportCenterModalContainer);
