import App from './containers/App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const afaAdmissionsContainer = document.getElementById('afaAdmissions');

if (afaAdmissionsContainer) {
  const afaAdmissionsContainerRoot = createRoot(afaAdmissionsContainer);

  afaAdmissionsContainerRoot.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}
