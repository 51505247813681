import styled from 'styled-components';

const ButtonWrapper = styled.div`
  button {
    margin: 0 7px 32px;
  }

  @media (max-width: 768px) {
    button {
      width: 145px;
    }
  }
  
`;

ButtonWrapper.displayName = 'ButtonWrapper';

export default ButtonWrapper;
