import React, { useEffect } from 'react';
import App from '../../components/App';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInitialData } from '../../containers/App/action';

const AppContainer = ({ getInitialData }) => {
  useEffect(() => {
    getInitialData();
  }, []);
  return <App />;
};

AppContainer.propTypes = {
  getInitialData: PropTypes.func.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  getInitialData: () => dispatch(getInitialData()),
});

export default connect(null, mapDispatchToProps)(AppContainer);
