import InteractiveModalButton from '../../../../JobApplicationTracker/components/InteractiveMessages/InteractiveModalButton';
import PropTypes from 'prop-types';
import React from 'react';
import StyledConfidenceReviewModal from '../styles/StyledConfidenceReviewModal';
import StyledModalParagraph from '../../../../JobApplicationTracker/components/InteractiveMessages/styles/StyledModalParagraph';

const ConfidenceReviewModal = ({ onCloseModal }) => {
  return (
    <StyledConfidenceReviewModal>
      <h5>About your low confidence survey rating</h5>
      <StyledModalParagraph>
        <p>
            Hi! We noticed that you have rated your confidence very low on the
            Confidence Survey. It is very normal to feel this way at some point
            of your career change. Our recommendation? Email Career Services at &nbsp;
          <a href='mailto:careerservices@careerfoundry.com'>careerservices@careerfoundry.com</a>.
        </p>
        <p>Some ways Career Services may be able to help you:</p>
        <ul>
          <li>
              Extra guidance and support from our veteran and senior career
              coaches at CareerFoundry
          </li>
          <li>
              Facilitate an introduction to a successful graduate who found
              success in your area, field, or situation
          </li>
          <li>
              Possibility to support a one-time call with a career specialist
              who with a special focus on your issue or background
          </li>
        </ul>
        <p>
            We can’t promise that we will solve all your problems, but we will
            do our best. Even if you think we can’t help you - it can’t hurt to
            send a quick email, can it? :){' '}
        </p>
        <p>
            Haven’t gotten in touch with your career specialist yet? We also
            recommend that you do so. Our data shows that student confidence
            goes up dramatically once students finish job prep!
        </p>
      </StyledModalParagraph>
      <InteractiveModalButton onCloseModal={onCloseModal} />
    </StyledConfidenceReviewModal>
  );
};

ConfidenceReviewModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

export default ConfidenceReviewModal;
