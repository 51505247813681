import { Banner } from '../../styles';
import checkIcon from '../../../../../../assets/images/static_pages/enrollment_page/check.svg';
import React from 'react';
import moment from 'moment';

interface Props {
  startDate: string;
  courseName: string;
}

const StartDateCourseBanner: React.FC<Props> = ({ courseName, startDate }) => {
  return (
    <Banner tag='p' variant='large-bold'>
      <img className='check-icon' src={checkIcon} />
      You’re now enrolled in the {courseName}! Your course starts on{' '}
      {moment(startDate).format('MMMM D, YYYY')}.
    </Banner>
  );
};

export default StartDateCourseBanner;
