import {
  SET_AGREED_TO_DISCOURSE_PRIVACY_POLICY,
  SET_IS_ACCEPTING_PRIVACY_POLICY,
  SET_IS_VISIBLE,
  SET_REQUIRED_DATA,
  SET_SHOULD_SHOW_DATA_POLICY_AGREEMENT_MODAL,
  SET_SHOULD_SHOW_SUB_NAV,
} from './constants';
import { get, noop } from 'lodash';
import ApiService from '../../services/ApiService';
import { getAuthToken } from '../../utils/getAuthToken';

export const setShouldShowDataPolicyAgreementModal = (
  shouldShowDataPolicyAgreementModal
) => ({
  shouldShowDataPolicyAgreementModal,
  type: SET_SHOULD_SHOW_DATA_POLICY_AGREEMENT_MODAL,
});

export const setShouldShowSubNav = (shouldShowSubNav) => ({
  shouldShowSubNav,
  type: SET_SHOULD_SHOW_SUB_NAV,
});

export const setIsVisible = (isVisible) => ({
  isVisible,
  type: SET_IS_VISIBLE,
});

export const setIsAcceptingPrivacyPolicy = (isAcceptingPrivacyPolicy) => ({
  isAcceptingPrivacyPolicy,
  type: SET_IS_ACCEPTING_PRIVACY_POLICY,
});

export const setAgreedToDiscoursePrivacyPolicy = (
  agreedToDiscoursePrivacyPolicy
) => ({
  agreedToDiscoursePrivacyPolicy,
  type: SET_AGREED_TO_DISCOURSE_PRIVACY_POLICY,
});

export const setRequiredData = (requiredData) => ({
  requiredData,
  type: SET_REQUIRED_DATA,
});

export const acceptPrivacyPolicy =
  (redirectToForum = noop) =>
  async (dispatch) => {
    const discussionsDiscoursePrivacyPolicyApi = new ApiService(
      '/api/v1/discussions/discourse_privacy_policy.json'
    );

    try {
      await discussionsDiscoursePrivacyPolicyApi.makePutCall({
        authenticity_token: getAuthToken(),
      });

      dispatch(setIsAcceptingPrivacyPolicy(false));
      dispatch(setAgreedToDiscoursePrivacyPolicy(true));
      dispatch(setShouldShowDataPolicyAgreementModal(false));
      redirectToForum();
    } catch (error) {
      // TODO: Report to Rollbar
      dispatch(setIsAcceptingPrivacyPolicy(false));
    }
  };

export const fetchRequiredData = () => async (dispatch) => {
  const courseNavigationApi = new ApiService('/api/v1/course_navigation');
  const attendeesApi = new ApiService('/api/v1/attendees');
  const meApi = new ApiService('/api/v1/me.json');
  const discussionsUserApi = new ApiService('/api/v1/discussions/user.json');

  try {
    const courseNavigationApiResponse = await courseNavigationApi.makeGetCall();
    const attendeesApiResponse = await attendeesApi.makeGetCall();
    const meApiResponse = await meApi.makeGetCall();
    const discussionsUserApiResponse = await discussionsUserApi.makeGetCall();

    dispatch(
      setRequiredData({
        attendeesData: get(attendeesApiResponse, 'data.data.attendees', {}),
        courseNavigationData: get(courseNavigationApiResponse, 'data.data', []),
        discussionsUserData: discussionsUserApiResponse?.data || {},
        meData: get(meApiResponse, 'data.data', {}),
      })
    );
  } catch (error) {
    // TODO: Report to Rollbar
  }
};
