import axios from 'axios';
import { closeWsgModal } from '../../actions';
import { getAuthToken } from '../../../../../utils/getAuthToken';

export const getWsgModalData = async (attendeeId) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `/api/v1/attendee/${attendeeId}/weekly_goal_estimates`,
      {
        authenticity_token: getAuthToken(),
      }
    );

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const submitAdjustedWsgGoal = (goal) => async (dispatch, getState) => {
  dispatch({ type: 'wsg.SUBMIT_ADJUSTED_WSG_GOAL' });
  const {
    wsg: { attendeeId, wsgId },
  } = getState();

  try {
    const {
      data: {
        data: { weekly_submission_goal: data },
      },
    } = await axios.patch(
      `/api/v1/attendee/${attendeeId}/weekly_submission_goals/${wsgId}/update_variant`,
      {
        authenticity_token: getAuthToken(),
        weekly_goal: {
          submission_goal: goal,
        },
      }
    );

    dispatch({ data, type: 'wsg.SUBMIT_ADJUSTED_WSG_GOAL_SUCCESS' });
    modalEventTracking('finish', attendeeId);
  } catch (err) {
    dispatch({ type: 'wsg.SUBMIT_ADJUSTED_WSG_GOAL_FAIL' });
    console.log(`Something went wrong... ${err}`);
  } finally {
    dispatch(closeWsgModal());
  }
};

export const updateModalEventTracking = (type) => async (_, getState) => {
  const {
    wsg: { attendeeId },
  } = getState();
  modalEventTracking(type, attendeeId);
};

export const modalEventTracking = (type, id) =>
  axios.post('/api/v1/analytics/track', {
    authenticity_token: getAuthToken(),
    data: {
      attendee_id: id,
      event_action: type,
    },
    event: 'wsg_interaction',
  });
