import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 20px;
  table-layout: fixed;
  overflow-x: auto;
  white-space: nowrap;

  thead > tr > th:not(:last-of-type) {
    padding-right: 15px;
  }

  thead > tr > th:nth-of-type(2) {
    padding-left: 15px;
  }

  tbody > tr > td:not(:first-of-type),
  thead > tr > th {
    text-align: center;
  }

  @media (max-width: 1022px) {
    display: block;
  }
`;

Table.displayName = 'Table';

export default Table;
