import styled from 'styled-components';

const MentorWrapper = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: fit-content;
  }

  @media (max-width: 490px) {
    width: 100%;
  }
`;

MentorWrapper.displayName = 'MentorWrapper';

export default MentorWrapper;
