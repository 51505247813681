import CelebrationMessage from '../../components/CelebrationMessage';
import PropTypes from 'prop-types';
import React from 'react';
import SubmittedMessage from '../../components/SubmittedMessage';
import { connect } from 'react-redux';

export const Message = ({ hasMetWsg, name }) =>
  hasMetWsg ? <CelebrationMessage /> : <SubmittedMessage name={name} />;

Message.propTypes = {
  hasMetWsg: PropTypes.bool,
  name: PropTypes.string,
};

export default connect(({ taskSubmission: { hasMetWsg, name } }) => ({
  hasMetWsg,
  name,
}))(Message);
