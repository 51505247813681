import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { clearTimeouts } from "../../utils/clearTimeouts";

const Input = props => {
  const [ value, setValue ] = useState(props.value ? props.value : '');
  const [ inputWrapperClass, setInputWrapperClass ] = useState('');
  const [ timeoutVariable, setTimeoutVariable ] = useState(0);

  useEffect(() => {
    setValue(props.value ? props.value : "");
    setFrozen();
  }, [props.value]);

  useEffect(() => {
    // throttle callbacks to not overwhelm the parent with each keystroke
    clearTimeouts(timeoutVariable);
    setTimeoutVariable(window.setTimeout(() => {
      props.onChange(value, props.name);
      setFrozen();
    }, 500));
  }, [value]);

  const setFrozen = isFrozen => {
    if(value.length > 0) {
      setInputWrapperClass('cf-form__group--frozen');
    } else {
      setInputWrapperClass('');
    }
  };

  const handleInputChange = value => {
    setValue(value);
  };

  return (
    <div className={`cf-form__group--floating ${props.classes ? props.classes : ''} ${inputWrapperClass} ${props.isValid ? 'cf-form--validated' : ''}`}>
      <input
        className="cf-form__input"
        value={value}
        name={props.name}
        type={props.type}
        required={props.required}
        onChange={e => handleInputChange(e.target.value)}
        disabled={props.disabled}
        maxLength={props.maxLength}
      />
      <label className="cf-form__label--floating">{props.labelText}</label>
    </div>
  )
};

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  isValid: PropTypes.bool,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.string
};

export default Input;
