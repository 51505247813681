import styled from 'styled-components';

const ModalWrapper = styled.div`
  .TheModal {
    width: 524px;
  }
`;

const ModalContent = styled.div`
  text-align: center;

  h4.ds-text__h4 {
    margin-top: 0;
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    font-family: 'DINPRO-COND';
    margin-bottom: 32px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`;

export { ModalWrapper, ModalContent, ButtonWrapper };
