import styled from 'styled-components';

const StyledLink = styled.div`
  margin-bottom: 32px;

  a,
  p {
    display: inline;
    margin-right: 40px;
  }

  a {
    color: var(--ds-color__indigo);
  }

  p,
  i {
    cursor: pointer;
  }

  i {
    color: rgba(34, 60, 80, 0.75);
  }

  .error-text {
    display: block;
    color: var(--ds-color_error);
  }
`;

StyledLink.displayName = 'UploadLink';

export default StyledLink;
