import Icon from '../../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { QuickTourIcon as SVGIcon } from '../../SideNavList/icons';
import { Wrapper } from './styles';

const QuickTourIcon = ({
  isVisible = false,
  onToggleTooltip,
  currentCoachmark,
  ...rest
}) => {
  return (
    <Wrapper>
      <Icon
        id="quick-start-instruction"
        isVisible={isVisible}
        onClick={() => onToggleTooltip(currentCoachmark)}
        renderSVGIcon={() => <SVGIcon />}
        nameForMobileView="Quick start instruction"
        tooltipContent="Quick start instruction"
        {...rest}
      />
    </Wrapper>
  );
};

QuickTourIcon.propTypes = {
  ...Icon.propTypes,
  currentCoachmark: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isVisible: PropTypes.bool,
  onToggleTooltip: PropTypes.func,
};

export default QuickTourIcon;
