import styled from 'styled-components';

const StyledForm = styled.div`
  margin-top: 40px;

  label {
    font-size: 14px;
    margin-bottom: 0;
    display: block;
  }

  input,
  select {
    display: inline-block;
    width: 200px;
    height: 32px;
    border: 1px solid var(--ds-color__grey--400);
    border-radius: 4px;
    margin-bottom: 16px;
    font-size: 16px;
    color: var(--ds-color__indigo);
    font-family: TradeGothic;

    &::placeholder {
      color: var(--ds-color__indigo);
    }
    &:-ms-input-placeholder {
      color: var(--ds-color__indigo);
    }
    &::-ms-input-placeholder {
      color: var(--ds-color__indigo);
    }

    &:focus {
      outline-color: var(--ds-color__petrol--medium);
    }

    &.error {
      border: 1px solid var(--ds-color_error);
    }
  }

  .error-text {
    margin-top: -12px;
    color: var(--ds-color_error);
  }

  input {
    padding: 7px 8px 4px;
  }

  select {
    padding: 7px 5px 4px;
    appearance: none;
  }

  select + span {
    &:after {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      top: 9px;
      right: 10px;
      border-right: 2px solid var(--ds-color__indigo);
      border-bottom: 2px solid var(--ds-color__indigo);
      transform: rotate(45deg);
      pointer-events: none;
    }
  }
  .form-actions {
    margin-top: 32px;

    button:first-of-type {
      margin-right: 16px;
    }
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: black;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

SelectWrapper.displayName = 'SelectWrapper';
StyledForm.displayName = 'StyledForm';

export { SelectWrapper, StyledForm };
