export const getCategorizedData = (data, count) => {
  let lastIndex = 0;
  const categories = data.reduce((acc, category) => {
    if (lastIndex > count - 1) {
      lastIndex = 0;
    }
    if (!acc[lastIndex]) {
      acc[lastIndex] = [];
    }
    acc[lastIndex] = [...acc[lastIndex], category];
    lastIndex++;
    return acc;
  }, []);
  return categories;
};
