import styled from 'styled-components';

const ProfileTab = styled.div`
  padding: 30px;

  @media (max-width: 479px) {
    padding: 0;
  }
`;

ProfileTab.displayName = 'ProfileTab';

export default ProfileTab;
