export const IMAGE_MESSAGES = [
  'OneJobApplication',
  'TenJobApplications',
  'TwentyFiveJobApplications',
  'FourtyJobApplications',
  'SixtyJobApplications',
  'EightyJobApplications',
  'FiveJobInterviews',
  'TenJobInterviews',
  'FifteenJobInterviews',
  'TwentyJobInterviews',
  'TwentyFiveJobInterviews',
  'ThirtyJobInterviews',
  'ThirtyFiveJobInterviews',
  'FortyJobInterviews',
  'OneWeekConsistency',
  'FourWeeksConsistency',
  'EightWeeksConsistency',
  'TwelveWeeksConsistency',
  'SixteenWeeksConsistency',
  'FirstPhoneScreen',
  'FirstAssignment',
  'FirstJobInterview',
  'FirstSecondInterview',
  'AcceptedOffer',
];

export const TEXT_MESSAGES = [
  'TenRejections',
  'TwentyFiveRejections',
  'FourtyRejections',
  'SixtyRejections',
  'EightyRejections',
];
