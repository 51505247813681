import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const sideNavListContainer = document.getElementById('SideNavList');
const dataPolicyAgreementModalContainer = document.getElementById(
  'DataPolicyAgreementModal'
);
const subNavContainer = document.getElementById('SubNav');

if (sideNavListContainer) {
  const SideNavList = lazy(() => import('./containers/SideNavList'));
  const sideNavListContainerRoot = createRoot(sideNavListContainer);

  sideNavListContainerRoot.render(
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <SideNavList userID={sideNavListContainer.dataset.user_id} />
      </Suspense>
    </Provider>
  );
}

if (dataPolicyAgreementModalContainer) {
  const DataPolicyAgreementModal = lazy(() =>
    import('./containers/DataPolicyAgreementModal')
  );
  const dataPolicyAgreementModalContainerRoot = createRoot(
    dataPolicyAgreementModalContainer
  );

  dataPolicyAgreementModalContainerRoot.render(
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <DataPolicyAgreementModal />
      </Suspense>
    </Provider>
  );
}

if (subNavContainer) {
  const SubNav = lazy(() => import('./containers/SubNav'));
  const subNavContainerRoot = createRoot(subNavContainer);

  subNavContainerRoot.render(
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <SubNav />
      </Suspense>
    </Provider>
  );
}
