/**
 * if a givenId param is passed, window.clearTimeout that, or clear all timeouts
 * @param givenId
 */
export const clearTimeouts = givenId => {
  if(givenId) {
    window.clearTimeout(givenId);
  }
  else {
    // clear all
    let id = window.setTimeout(() => {}, 0);
    while (id--) {
      window.clearTimeout(id); // will do nothing if no timeout with id is present
    }
  }
};
