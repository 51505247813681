import styled from 'styled-components';

const ModalWrapper = styled.div`
  background-color: white;
  width: 562px;
  height: 586px;
  margin: 0 auto;
  padding: 6px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 17px;
`;

const ModalTitle = styled.div`
  text-align: center;
  margin-bottom: 16px;
`;

const ModalSubtitle = styled.div`
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (min-width: var(--md-min)) {
    padding: 0px 66px;
  }
`;

const ModalBox = styled.div`
  border: 1px solid var(--ds-color__petrol);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 21px 20px 14px 20px;
  margin-bottom: 11px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 71px;
  .current-wsg {
  }
`;

const ModalDate = styled.div`
  width: 100%;
  text-align: left;
`;

const ModalDesc = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 2px;
`;

const ModalImage = styled.div`
  margin: 9px 0px 8px 0px;
`;

const ModalFooter = styled.div`
  text-align: center;
  margin-bottom: 34px;
  button {
    margin: 0px 4px;
  }
`;

const ImageButton = styled.span`
  cursor: pointer;
  padding: 11px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
`;

const TrackingDescription = styled.p`
  width: 100%;
`;

ModalWrapper.displayName = 'ModalWrapper';
ModalHeader.displayName = 'ModalHeader';
ModalTitle.displayName = 'ModalTitle';
ModalSubtitle.displayName = 'ModalSubtitle';
ModalBody.displayName = 'ModalBody';
ModalBox.displayName = 'ModalBox';
ModalDate.displayName = 'ModalDate';
ModalDesc.displayName = 'ModalDesc';
ModalImage.displayName = 'ModalImage';
ModalFooter.displayName = 'ModalFooter';
ImageButton.displayName = 'ImageButton';
TrackingDescription.displayName = 'TrackingDescription';

export {
  ModalWrapper,
  ModalHeader,
  ModalTitle,
  ModalSubtitle,
  ModalBody,
  ModalBox,
  ModalDate,
  ModalDesc,
  ModalImage,
  ModalFooter,
  ImageButton,
  TrackingDescription,
};
