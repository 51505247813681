import { handleModalClose, handleSubmit } from '../../actions';
import ConfirmationModal from '../../components/ConfirmationModal';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  callEdits: { modalAction, selectedCall },
}) => ({
  isOpen: modalAction !== null,
  modalAction,
  selectedCall,
});

export const mapDispatchToProps = (dispatch) => ({
  handleModalClose: () => dispatch(handleModalClose()),
  handleSubmit: (id, status) => dispatch(handleSubmit(id, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
