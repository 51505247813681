import styled from 'styled-components';

const Status = styled.div`
  display: flex;
  align-items: center;
  padding: var(--ds-spacing__xs);
  border-radius: 8px;
  background: #fcfcfc;
  border: 1px solid #e8e8e8;
  margin: var(--ds-spacing__m) 0;

  i {
    align-self: flex-start;
    margin-right: var(--ds-spacing__xs);
  }
`;

export { Status };
