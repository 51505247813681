import App from '../StudentHandoff/containers/App';
import React from 'react';
import { createRoot } from 'react-dom/client';

const overdueRequestsContainer = document.getElementById('overdueRequests');

if (overdueRequestsContainer) {
  const overdueRequestsContainerRoot = createRoot(overdueRequestsContainer);

  overdueRequestsContainerRoot.render(
    <App
      userId={overdueRequestsContainer.dataset.userId}
      isTutor={overdueRequestsContainer.dataset.tutor === 'true'}
      overdue={true}
    />
  );
}
