import React, { useEffect, useState } from 'react';
import MediaResource from '../../../MediaLibrary/components/MediaResource';
import MediaResourceCarousel from './MediaResourceCarousel';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const PreviewSection = ({ mediaResources }) => {
  const [displayCarousel, setDisplayCarousel] = useState(
    window.innerWidth <= 1022
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setDisplayCarousel(window.innerWidth <= 1022);
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', debounce(handleResize, 500));
    return () => {
      window.removeEventListener('resize', debounce(handleResize, 500));
    };
  }, []);

  return (
    <>
      <div className="ds-grid__row">
        <div className="ds-grid__col-sm-12">
          <div className="ds-grid__row">
            {displayCarousel ? (
              <MediaResourceCarousel
                mediaResources={mediaResources}
                isMobile={isMobile}
              />
            ) : (
              mediaResources.map((resource) => (
                <MediaResource
                  resource={resource}
                  key={`${resource.title}`}
                  preview
                />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

PreviewSection.propTypes = {
  mediaResources: PropTypes.array.isRequired,
};

export default PreviewSection;
