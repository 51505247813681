import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import ExerciseWrapper from './styles/ExerciseWrapper';
import InfoWrapper from './styles/InfoWrapper';
import ProgressStyles from './styles/Progress';
import PropTypes from 'prop-types';
import React from 'react';
import StyledAccordionAchievement from './styles/StyledAccordionAchievement';
import { redirectTo } from '../../../../utils/redirectTo';
import { setClasses } from './helpers';

const Progress = ({
  achievements,
  nextExercise,
  showTitle = true,
  showCurrentTask = true,
  expandLastAchievement = true,
}) => (
  <>
    {showTitle && <p className='ds-typography__lead--bold'>Progress</p>}
    {showCurrentTask && (
      <InfoWrapper>
        <p className='ds-typography__body--bold'>Current task:</p>
        <p className='ds-typography__body'>{nextExercise}</p>
      </InfoWrapper>
    )}

    <ProgressStyles>
      {achievements.map((achievement, i, arr) => (
        <StyledAccordionAchievement key={achievement.name}>
          <p className='ds-typography__lead--bold'>
            Achievement {achievement.position}
          </p>
          <Accordion
            allowZeroExpanded
            preExpanded={[expandLastAchievement ? 'lastAchievement' : '']}
          >
            <AccordionItem
              uuid={
                arr.length - 1 === i ? 'lastAchievement' : `achievement${i}`
              }
            >
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div>
                    <img src={`/assets/${achievement.imageUrl}`} />
                    <h5 className='ds-typography__h5'>{achievement.name}</h5>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {achievement.exercises.map((exercise, i, arr) => (
                  <ExerciseWrapper
                    className={setClasses(arr, i, exercise.status)}
                    key={exercise.name}
                  >
                    <div>
                      <p className='ds-typography__body--bold'>
                        {exercise.name}
                      </p>
                      <p className='ds-typography__body'>
                        {exercise.status ? exercise.status : 'Current Task'}
                      </p>
                    </div>
                    <button
                      className='ds-button__primary'
                      onClick={() =>
                        redirectTo({
                          openInNewTab: true,
                          url: exercise.link,
                        })
                      }
                      disabled={!exercise.link}
                    >
                      View Submission
                    </button>
                  </ExerciseWrapper>
                ))}
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </StyledAccordionAchievement>
      ))}
    </ProgressStyles>
  </>
);

Progress.propTypes = {
  achievements: PropTypes.arrayOf(
    PropTypes.shape({
      exercises: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string,
          name: PropTypes.string.isRequired,
          status: PropTypes.string,
        })
      ),
      image_url: PropTypes.string,
      name: PropTypes.string.isRequired,
      position: PropTypes.number,
    })
  ),
  expandLastAchievement: PropTypes.bool,
  nextExercise: PropTypes.string,
  showCurrentTask: PropTypes.bool,
  showTitle: PropTypes.bool,
};

export default Progress;
