import styled from 'styled-components';

const StyledProfileBody = styled.div`
  .small {
    margin: 40px 0 0 !important;
  }

  @media (max-width: 768px) {
    .small {
      margin: 16px 0 0 !important;
    }
  }
`;

StyledProfileBody.displayName = 'StyledProfileBody';

export default StyledProfileBody;
