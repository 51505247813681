import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

const getWindowDimensions = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
});

export default () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());

    window.addEventListener('resize', debounce(handleResize, 200));
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
