import Checkbox from '../../../shared/components/Checkbox';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { StyledInput } from '../App/styles';

const TaxSmallBusinessInput = ({ country, smallCompany }) => {
  return (
    <>
      <StyledInput>
        <Field
          defaultChecked={smallCompany}
          as={Checkbox}
          type='checkbox'
          name='smallCompany'
          required={true}
          labelText='Small Company'
          onClick={() => {}}
          disabled={country !== 'DE'}
        ></Field>
      </StyledInput>
    </>
  );
};

TaxSmallBusinessInput.defaultProps = {
  smallCompany: false,
};

TaxSmallBusinessInput.propTypes = {
  country: PropTypes.string,
  smallCompany: PropTypes.bool,
};

export default TaxSmallBusinessInput;
