import AudioSection from './AudioSection';
import PropTypes from 'prop-types';
import React from 'react';
import Visualizer from './AudioVisualizer';
import { audioRecorderTracking } from '../../../shared/helpers/tracking';
import statusConstants from '../../statusConstant';

const AudioControls = ({ showAnswer, audio, mediaStream, question, recordingState }) => {
  const drawVisuals = recordingState !== statusConstants.PAUSED;
  const trackPlayButton = () => {
    audioRecorderTracking(question.question, 'ip_play_recording_click');
  };

  return showAnswer
    ? (
      <div className="audio-wrapper">
        <div className="audio-section">
          <AudioSection audio={audio} trackPlayButton={trackPlayButton} />
        </div>
      </div>
    )
    : (
      <div className="main-controls">
        <div className="visualizer-section">
          <Visualizer stream={mediaStream} drawVisuals={drawVisuals} />
        </div>
      </div>
    );
};

AudioControls.propTypes = {
  audio: PropTypes.string,
  mediaStream: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  recordingState: PropTypes.string,
  showAnswer: PropTypes.bool.isRequired,
};

export default AudioControls;
