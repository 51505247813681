import React, { Component } from 'react'; 


class FooterButtons extends Component {
  constructor(props){
    super(props);
  }

  render(){
    var prevButtonClass = this.props.prevButtonDisabled ? "button--primary-disabled" : "button--back";
    var nextButtonClass = this.props.nextButtonDisabled ? "button--primary-disabled" : "button--primary-petrol";

    var renderNextButton = () => {
      if(this.props.modalLoading){
        return <a className={`button--large ${nextButtonClass} quiz__button`} dangerouslySetInnerHTML={{__html: CF.html.loadingSpinner}}></a>
      }else{
        return <a onClick={this.props.onNextClick} className={`button--large ${nextButtonClass} quiz__button`}>{this.props.nextButtonLabel}</a>
      }
    };

    return (
      <footer className="quiz__buttons">
        <a onClick={this.props.onPrevClick} className={`button--large ${prevButtonClass}`}>{this.props.prevButtonLabel}</a>
        {renderNextButton()}
      </footer>
    );
  }
}

export default FooterButtons;
