import styled from 'styled-components';

const EvaluationCard = styled.div`
  display: inline-block;
  position: relative;
  .evaluation-title {
    margin-left: 10%;
    margin-right: 10%;
    font-weight: bold;
  }


  ${({ isModal }) => !isModal && `
    .evaluation-title {
      font-size: 40px;
      font-weight: 500;
      line-height: 1.4;
      margin-bottom: 16px;
    }
     .fa-stack {
      width: 29px;
      height: 29px;
     }
    .fa-stack-2x {
      font-size: 29px;
    }

    .circle-text {
      font-size: 14px;
    }
  `}

  h4 {
    font-weight: 600;
    margin: 10px 0px;
  }
  .grey {
    color: var(--ds-color__grey--light);
  }
  .clickable {
    cursor: pointer;
  }
  .italic {
    font-style: italic;
  }
  .right-arrow,
  .left-arrow {
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    object-fit: contain;
    z-index: 2;
  }

  .right-arrow {
    right: ${(props) => (props.isModal ? '-3rem' : '1rem')};
  }
  .left-arrow {
    left: ${(props) => (props.isModal ? '-3rem' : '1rem')};
  }

  @media (max-width: 768px) {
    .right-arrow,
    .left-arrow {
      top: 14%;
      font-size: 1.5em
    }

    .right-arrow {
      right: 22px;
    }

    .left-arrow {
      left: 22px;
    }

    ${({ isModal }) => !isModal && `
      .evaluation-title {
        margin: 0 0 8px;
        padding: 0 24px;
        font-size: 32px;
      }
    `}
  }
`;

EvaluationCard.displayName = 'EvaluationCard';

export default EvaluationCard;
