import FeedbackBanner from '../../../shared/components/FeedbackBanner';
import React from 'react';

export const BannerContainer = () => {
  const FORM_ID = 'e2SMGU1i';
  const hasDismissedCookie = !!document.cookie.match(new RegExp('dismissedFeedbackBanner', 'g'));
  const createDismissedBannerCookie = () => {
    CF.createCookie('dismissedFeedbackBanner', 'true', 7);
  };

  return (
    <FeedbackBanner
      bannerIsVisible={!hasDismissedCookie}
      buttonText="Give Feedback"
      closeAction={createDismissedBannerCookie}
      formId={FORM_ID}
      text="Would you like to give us some feedback about the Media Library?"
      submitAction={createDismissedBannerCookie}
    />
  );
};
