import styled from 'styled-components';

const StyledConsentModal = styled.div`
  min-height: 320px;
  display: flex;
  flex-grow: 1;
  text-align: center;
  padding: 32px 16px 0;
  flex-direction: column;

  h3 {
    font-size: 34px;
    margin-bottom: 18px;
  }

  a {
      font-size: 14px !important;
  }

  p {
      font-size: 14px;
      margin-bottom: 34px;
  }

  @media (max-width: 479px) {
    padding: 46px 8px 0;
  }
`;

StyledConsentModal.displayName = 'StyledConsentModal';
export default StyledConsentModal;
