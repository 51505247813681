import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import PropTypes from 'prop-types';
import React from 'react';
import Separator from '../styles/Separator';

const AccordionSection = ({
  content,
  heading,
  uuid,
}) => (
  <AccordionItem uuid={uuid}>
    <AccordionItemHeading>
      <AccordionItemButton>
        <h5 className="ds-typography__lead--bold">{heading}</h5>
      </AccordionItemButton>
    </AccordionItemHeading>
    <Separator />
    <AccordionItemPanel className="accordion__panel ds-implicit">
      {content}
    </AccordionItemPanel>
  </AccordionItem>
);

AccordionSection.propTypes = {
  content: PropTypes.object,
  heading: PropTypes.string,
  uuid: PropTypes.string,
};

export default AccordionSection;
