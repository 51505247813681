import React, { useEffect, useState } from 'react';
import { getLinkType, isValidLink } from '../../helpers';
import PropTypes from 'prop-types';
import StyledFloatingInput from '../../styles/StyledFloatingInput';

const FloatingInput = ({ onInputChange, type, onCloseInput, initialValue = '' }) => {
  const [error, setError] = useState(null);
  const [link, setLink] = useState(null);

  useEffect(() => {
    setLink(initialValue);
  }, []);

  const handleChange = (event) => {
    if (error) {
      setError(null);
    }
    const { value } = event.target;
    setLink(value);
  };

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      const { value } = event.target;
      const urlType = type || getLinkType(value);
      const isValid = urlType === 'portfolio' || isValidLink(value, urlType);

      if (!isValid) {
        setError('Link not valid');
        return;
      }

      if (typeof onInputChange === 'function') {
        onInputChange({ link, urlType });
      }

      if (typeof onCloseInput === 'function') {
        onCloseInput();
      }
    }
  };

  return (
    <StyledFloatingInput isError={error} onClick={handleClick}>
      <div className='wrapper'>
        <input
          type='text'
          name={type}
          value={link || ''}
          onChange={ handleChange }
          onKeyPress={handleKeyPress}
          onBlur={onCloseInput}
        />
        <i className="fa fa-paperclip" aria-hidden="true"></i>
      </div>
      <p className='ds-typography__body ds-typography__small'>{ error || 'Insert link and press Enter' }</p>
    </StyledFloatingInput>
  );
};

FloatingInput.propTypes = {
  initialValue: PropTypes.string,
  onCloseInput: PropTypes.func,
  onInputChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default FloatingInput;
