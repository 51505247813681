import React from 'react';
import SideNav from '../../components/MainDashboard/SideNav';
import { connect } from 'react-redux';

const SideNavContainer = (props) => {
  return <SideNav {...props} />;
};

export const mapStateToProps = ({
  careerSupportCenter: {
    user,
  },
}) => ({
  user,
});

export default connect(mapStateToProps, null)(SideNavContainer);
