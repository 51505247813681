import styled from 'styled-components';

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0 rgba(51, 51, 51, 0.1);
  padding: 30px 24px;

  .header-paragraph {
    margin-bottom: 25px;
  }

  a {
    margin: 24px 31px 0 0;
  }

  @media (max-width: 768px) {
    margin-top: 0px;
    hr {
      display: block;
      margin: 15px -16px;
      border: solid 1px #e8ebed;
    }
    padding: 8px 4px;
    border-radius: 0;
    box-shadow: none;

    a {
      width: 100%;
      text-align: center;
      display: block;

      &:nth-of-type(2) {
        margin-top: 16px;
      }
    }
  }
  @media (min-width: 768px) {
    hr {
        display: none;
    }
    margin-top: 15px;
  }
`;

Card.displayName = 'Card';

export default Card;
