import AddLinkWithInput from './AddLinkWithInput';
import PropTypes from 'prop-types';
import React from 'react';
import StyledIconsWrapper from '../../styles/StyledIconsWrapper';
import StyledPreviewSmall from '../../styles/StyledPreview__small';

const SingleAddInput = ({
  attachment,
  deleteAttachment,
  type,
  onInputChange,
  isEditView,
}) => {
  return (
    <StyledIconsWrapper hasMargin={true}>
      {attachment
        ? (
          <StyledPreviewSmall
            attachment={attachment}
            deleteAttachment={!isEditView ? null : () => deleteAttachment()}
            isViewOnly={!isEditView}
          />
        )
        : (
          isEditView && <AddLinkWithInput type={type} onInputChange={onInputChange} />
        )}
    </StyledIconsWrapper>
  );
};

SingleAddInput.propTypes = {
  attachment: PropTypes.object,
  deleteAttachment: PropTypes.func.isRequired,
  isEditView: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default SingleAddInput;
