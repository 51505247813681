const handleIndexControls = (currentIndex, records, setIndexControls, displayLength = 1) => {
  if (records) {
    const lastIndex = records.length - displayLength;
    if (currentIndex === 0 && records.length <= 1) {
      setIndexControls({ nextRecord: false, previousRecord: false });
    } else if (currentIndex === 0 && records.length > 1) {
      setIndexControls({ nextRecord: false, previousRecord: true });
    } else if (currentIndex === lastIndex) {
      setIndexControls({ nextRecord: true, previousRecord: false });
    } else {
      setIndexControls({ nextRecord: true, previousRecord: true });
    }
  }
};

export default handleIndexControls;
