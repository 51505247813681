import App from '../../components/App.js';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { handleNewEmployer } from '../../../actions.js';

const AppContainer = ({ handleNewEmployer, modalIsOpen }) => {
  return (
    <App modalIsOpen={modalIsOpen} handleNewEmployer={handleNewEmployer} />
  );
};

AppContainer.propTypes = {
  handleNewEmployer: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

export const mapStateToProps = ({
  adminEmployerConnector: {
    modalIsOpen,
  },
}) => ({
  modalIsOpen,
});

export const mapDispatchToProps = dispatch => ({
  handleNewEmployer: () => dispatch(handleNewEmployer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
