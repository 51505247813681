import { Accordion } from 'react-accessible-accordion';
import AccordionSection from '../AccordionSection';
import AdditionalResources from './AdditionalResources';
import AudioReader from '../../../../shared/components/AudioReader';
import BusinessPlanning from './BusinessPlanning';
import ButtonWithIcon from '../../../../shared/components/ButtonWithIcon';
import EducationalResources from './EducationalResources';
import Introduction from './Introduction';
import React from 'react';
import SpecialConsideration from './SpecialConsideration';
import StyledAccordion from '../styles/StyledAccordion';
import StyledBannerImage from '../styles/StyledBannerImage';
import StyledPageContents from '../styles/StyledPageContents';
import StyledRemoteWork from '../styles/StyledRemoteWork';
import WhereToFindClients from './WhereToFindClients';
const ACCORDION_EVENT = 'accordion_heading_clicked';

const Freelancing = () => {
  return (
    <StyledRemoteWork>
      <StyledBannerImage backgroundImage='https://coach-courses-us.s3.amazonaws.com/public/career-support-center/freelance.jpg'>
        <ButtonWithIcon
          href='/en/career-support-center'
          icon='arrow-left'
          type='secondary'
          text='Back to career support center'
        />
        <AudioReader
          audioSrc='https://speech-content.s3.amazonaws.com/freelancing.mp3'
          descriptionText='Listen to this article'
        />
      </StyledBannerImage>
      <StyledPageContents>
        <h1 className='ds-typography__h1'>Introduction to freelancing</h1>
        <Introduction />
        <StyledAccordion>
          <Accordion allowZeroExpanded>
            <AccordionSection
              heading='Business planning'
              content={<BusinessPlanning />}
              eventType={ACCORDION_EVENT}
            />
            <AccordionSection
              heading='Where to find clients'
              content={<WhereToFindClients />}
              eventType={ACCORDION_EVENT}
            />
            <AccordionSection
              heading='Educational resources'
              content={<EducationalResources />}
              eventType={ACCORDION_EVENT}
            />
            <AccordionSection
              heading='Special consideration'
              content={<SpecialConsideration />}
              eventType={ACCORDION_EVENT}
            />
            <AccordionSection
              heading='Additional resources'
              content={<AdditionalResources />}
              eventType={ACCORDION_EVENT}
            />
          </Accordion>
        </StyledAccordion>
      </StyledPageContents>
    </StyledRemoteWork>
  );
};

export default Freelancing;
