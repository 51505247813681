const textEditor = require('../../../shared/text_editor');
const inputField = document.getElementById('student_job_opening_content');

module.exports = (() => {
  const init = () => {
    textEditor.init(inputField);
    bindFunctions();
    initializeElements();
    datePicker();
  };

  const initializeElements = () => {
    $('#location_country').select2({
      placeholder: 'Country',
    });
  };

  const datePicker = () => {
    $('.datepicker').datepicker({
      dateFormat: 'yy-mm-dd',
    });
  };

  const bindFunctions = () => {
    $('#location_country').on('change', countrySelect);
  };

  const replaceDropdownArrows = () => {
    $('b[role="presentation"]').hide();
    if ($('.select2-selection__arrow .fa-angle-down').length) {
      $('.fa-angle-down').remove();
    };
    $('.select2-selection__arrow').append('<i class="fa fa-angle-down"></i>');
  };

  const countrySelect = () => {
    const selected = $('#location_country').val();
    $('.dropdown_cities--js').html('');
    $.ajax({
      data: { country_code: selected },
      dataType: 'script',
      method: 'POST',
      success: function(data) {
        $('#student_job_opening_city').select2({
        });
        replaceDropdownArrows();
      },
      url: '/en/careerhub/cities/',
    });
  };

  return {
    init: init,
  };
})();
