import Border from './styles/Border';
import InfoWrapper from './styles/InfoWrapper';
import ProgressLine from '../../../shared/components/ProgressLine';
import ProgressLineLabel from './ProgressLineLabel';
import ProgressLineTime from '../../../shared/components/ProgressLineTime';
import ProgressLineWrapper from './styles/ProgressLineWrapper';
import PropTypes from 'prop-types';
import React from 'react';

const Activity = ({ activity, graduationDate, end, start }) => {
  const bluePrint = Array.from({ length: activity.exercisesTotal }, (v, k) =>
    k < activity.exercisesCompleted ? 'complete' : 'tick'
  );

  return (
    <>
      <p className='ds-typography__lead--bold'>Activity</p>
      <InfoWrapper>
        {activity.lastActivity && (
          <>
            <p className='ds-typography__body--bold'>Last activity</p>
            <p className='ds-typography__body'>{activity.lastActivity}</p>
          </>
        )}
      </InfoWrapper>
      <ProgressLineWrapper>
        <ProgressLineTime
          progress={activity}
          graduationDate={graduationDate}
          end={end}
          start={start}
        />
      </ProgressLineWrapper>
      <ProgressLineWrapper>
        <ProgressLineLabel
          complete={activity.exercisesCompleted}
          total={activity.exercisesTotal}
          label='Approved Tasks'
        />
        <ProgressLine bluePrint={bluePrint} />
      </ProgressLineWrapper>
      <Border />
    </>
  );
};

Activity.propTypes = {
  activity: PropTypes.shape({
    completed: PropTypes.bool,
    exercisesCompleted: PropTypes.number,
    exercisesTotal: PropTypes.number,
    lastActivity: PropTypes.string,
    lockedOut: PropTypes.bool,
    started: PropTypes.bool,
    totalWeeks: PropTypes.number,
    weeksCompleted: PropTypes.number,
    weeksOverTargetCompletion: PropTypes.number,
  }),
  end: PropTypes.string,
  graduationDate: PropTypes.string,
  start: PropTypes.string,
};

export default Activity;
