import {
  answerQuestion,
  handleBack,
} from '../../actions';
import InterviewTool from '../../components/InterviewTool';
import { connect } from 'react-redux';

export const mapDispatchToProps = dispatch => ({
  answerQuestion: (interviewId, questionId, file, shared) => dispatch(answerQuestion(interviewId, questionId, file, shared)),
  handleBack: () => dispatch(handleBack()),
});

export const mapStateToProps = ({
  interviewPrep: {
    interviewId,
    interviewStage,
    questions,
    shareWithMentor,
  },
}) => ({
  interviewId,
  interviewStage,
  questions,
  shareWithMentor,
});

export default connect(mapStateToProps, mapDispatchToProps)(InterviewTool);
