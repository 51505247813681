import PropTypes from 'prop-types';
import React from 'react';

const Sidebar = ({ amount }) => (
  <div className="SideBar">
    <p className="cf-bold">One-time payment</p>
    <div className="price__wrapper">
      <p className="price">€{amount}</p>
    </div>
    <div className="details__wrapper">
      <ul>
        <li className="cf-small">This is a one-time payment, not a recurring subscription</li>
        <li className="cf-small">It may take up to 6 working days for the payment to be processed. You will receive a notification of confirmation alongside your new end date.</li>
      </ul>
    </div>
  </div>
);

Sidebar.propTypes = {
  amount: PropTypes.number,
};

export default Sidebar;
