import React, { useEffect } from 'react';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import MediaLibrary from '../../components/MediaLibrary';
import PropTypes from 'prop-types';
import StyledMediaLibrary from '../../styles/StyledMediaLibrary';
import { connect } from 'react-redux';
import { getMediaResources } from '../../actions';
import { getUserIndustry } from '../../../CareerSupportCenter/actions';

export const MediaLibraryContainer = ({
  attendeeId,
  getMediaResources,
  getUserIndustry,
  loading,
  resources,
  userIndustry,
}) => {
  useEffect(() => {
    getUserIndustry().then((industry) => {
      getMediaResources(industry);
    });
  }, [userIndustry]);

  return (
    <StyledMediaLibrary className='ds-grid__container'>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <MediaLibrary resources={resources} attendeeId={attendeeId} />
      )}
    </StyledMediaLibrary>
  );
};

MediaLibraryContainer.propTypes = {
  attendeeId: PropTypes.string,
  getMediaResources: PropTypes.func.isRequired,
  getUserIndustry: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  resources: PropTypes.object.isRequired,
  userIndustry: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  getMediaResources: (industry) => dispatch(getMediaResources(industry)),
  getUserIndustry: () => getUserIndustry(),
});

const mapStateToProps = ({
  mediaLibrary: { loading, resources },
  careerSupportCenter: { userIndustry },
}) => ({
  loading,
  resources,
  userIndustry,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaLibraryContainer);
