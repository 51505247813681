module.exports = (() => {
  const hubspotFormIds = {
    49: '9b464a32-3cd5-4627-8190-b3c16db7d495',
    52: '9d327c45-7e1a-43d0-9812-bd24979133df',
    58: 'f60d79ce-2743-4e52-94ca-ea3ab8dd4abf',
  };

  const thisState = {
    course_slug: $('[data-course-slug]').data('course-slug'),
  };

  const init = () => {
    thisState.forms = {
      connect_advisor: new CF.Form('#connect_advisor_form'),
      short_course_form_course_plan: new CF.Form('#short_course_form_course_plan'),
    };

    affixSideMenu();
    smoothScroll();
    _initContactForm();
    _renderCalendarIframe();
    _setFormId();
    bindFunctions();
  };

  const bindFunctions = () => {
    $('.achievement-menu ul li a').on('click', scrollToSection);
    $('.form-opt-call--js').on('click', _showCallForm);
    $('.form-opt-email--js').on('click', _showMailForm);
    $('#short_course_form_course_plan').on('submit', _validateAndSubmitShortCourse);
    $('#connect_advisor_form').on('submit', _validateAndSubmit);
    $('.afa-signup-modal--js').on('click', closeAfaModal);
  };

  const closeAfaModal = () => {
    const modal = document.getElementsByClassName('afa-signup-modal')[0];
    modal.classList.remove('in');
    document.cookie = 'afaModal=; Path=/; Max-Age=0';
  };

  const scrollToSection = event => {
    $('.achievement-menu ul li.active').removeClass('active');
    $(event.target).parent().addClass('active');
  };

  const affixSideMenu = () => {
    const top_height = $('#top').outerHeight(true);
    const bottom_height = $('.cf-footer__container').outerHeight(true);

    $('.achievement-menu.affixed').affix({
      offset: {
        top: top_height * 1.14,
        bottom: bottom_height * 1.5,
      },
    });
  };

  const _validateAndSubmit = e => {
    e.preventDefault();

    thisState.forms.connect_advisor.validateAndSubmit()
      .then(result => {
        thisState.forms.connect_advisor.setGTMdata();
        $('.advisor__form__wrapper--email').hide();
        $('.advisor__form__wrapper--email--thankyou').show();
      })
      .catch(error => console.log(error));
  };

  const _validateAndSubmitShortCourse = e => {
    e.preventDefault();
    thisState.forms.short_course_form_course_plan.validateAndSubmit()
      .then(result => {
        thisState.forms.short_course_form_course_plan.setGTMdata();
        $('.achievement-menu--variant-form--js').hide();
        $('.achievement-menu--variant-submitted--js').show();
      })
      .catch(error => console.log(error));
  };

  const _initContactForm = () => {
    CF.ClientLocator.getLocationObject(locationObject => {
      if (CF.ClientLocator.getWhitelist().includes(locationObject.location_country_code)) {
        // bind reset modal click event if in PRIO country
        thisState.findOutMoreModal = new CF.Modal('#findOutMoreModal', _resetModal);
        _bindResetModal();
      } else {
        // Don't display calendar for users outside the PRIO countries
        thisState.findOutMoreModal = new CF.Modal('#findOutMoreModal');
        $('.cf-modal__back_button').addClass('hidden--non_prio');
        _showMailForm();
      };
    });
  };

  const _renderCalendarIframe = () => {
    CF.ClientLocator.getLocationObject(locationObject => {
      const currencyLocation = locationObject.location_currency;
      const iframeContainer = document.querySelector('.advisor__form__wrapper--js');
      const usCalendarUrl = iframeContainer.dataset.urlUs;
      const euCalendarUrl = iframeContainer.dataset.urlEu;

      function createIframe (url) {
        const iframe = Object.assign(document.createElement('iframe'), {
          frameBorder: '0',
          height: '100%',
          src: url,
          width: '100%',
        });
        iframeContainer.appendChild(iframe);
      };

      (currencyLocation === 'EU') ? createIframe(euCalendarUrl) : createIframe(usCalendarUrl);
    });
  };

  const _showCallForm = () => {
    $('.contact-intro').hide();
    $('.advisor__form__wrapper--call').show();
    $('.cf-modal__back_button').toggleClass('hidden');
  };

  const _showMailForm = () => {
    $('.contact-intro').hide();
    $('.advisor__form__wrapper--email').show();
    $('.cf-modal__back_button').toggleClass('hidden');
  };

  const _setFormId = () => {
    const $formInput = $('input[data-course]');
    const courseId = $formInput.data('course');
    const formId = hubspotFormIds[courseId];
    formId && $formInput.val(formId);
  };

  const _resetModal = () => {
    setTimeout(function () {
      $('.contact-intro').show();
      $('.advisor__form__wrapper--call').hide();
      $('.advisor__form__wrapper--email').hide();
      $('.cf-modal__back_button').addClass('hidden');
    }, 400);
  };

  const _bindResetModal = () => {
    $('.contact__modal--variant .cf-modal__exit').on('click', _resetModal);
    $('.cf-modal__back_button').on('click', _resetModal);
    $('.cf-modal__close_button').on('click', _resetModal);
  };

  return {
    init: init,
  };
})();
