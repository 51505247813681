import { handleNewNote, setActivePage } from './actions';
import MentorNotes from '../../../components/MentorNotesTab/MentorNotes';
import { connect } from 'react-redux';

export const mapStateToProps = ({ userProfile: { mentorNotesTab } }) => ({
  activePage: mentorNotesTab.activePage,
  mentorNotes: mentorNotesTab.mentorNotes,
});

export const mapDispatchToProps = (dispatch) => ({
  handleNewNote: () => dispatch(handleNewNote()),
  setActivePage: (activePage) => dispatch(setActivePage(activePage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MentorNotes);
