module.exports = (() => {
  const trackEvent = function(event, data = {}){
    $.ajax({
      url: '/api/v1/analytics/track',
      method: 'POST',
      data: {
        event: event,
        data: data
      }
    })
  };

  const dismissBannerApi = function(bannerName){
    $.ajax({
      url: '/api/v1/dashboards/dismiss_banner',
      method: 'POST',
      data: {
        banner_name: bannerName
      }
    })
  };

  return {
    trackEvent: trackEvent,
    dismissBannerApi: dismissBannerApi,
  };
})();
