import { Accordion } from 'react-accessible-accordion';
import AccordionSection from './AccordionSection';
import ActivateDeactivate from './subsections/ActivateDeactivate';
import React from 'react';
import StyledAccordion from './styles/StyledAccordion';
import ViewAndModify from './subsections/ViewAndModify';
import WhatHappensNext from './subsections/WhatHappensNext';

const FAQ = () => {
  return (
    <div className="ds-grid__container small-container">
      <div className="ds-grid__row">
        <div className="ds-grid__col-sm-12">
          <h3 className="ds-typography__h3 text-left">FAQs</h3>
          <StyledAccordion>
            <Accordion
              allowZeroExpanded
              allowMultipleExpanded
            >
              <AccordionSection
                uuid='whatHappensNext'
                heading="What happens next?"
                content={<WhatHappensNext />}
              />
              <AccordionSection
                uuid='viewAndModify'
                heading="How do I view/modify my information?"
                content={<ViewAndModify />}
              />
              <AccordionSection
                uuid='activateDeactivate'
                heading="How do I activate/deactivate my account?"
                content={<ActivateDeactivate />}
              />
            </Accordion>
          </StyledAccordion>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
