import styled from 'styled-components';

const CardWrapper = styled.div`
  margin-bottom: 40px;
`;

const TypeformModalWrapper = styled.div`
  .TheModal {
    width: 770px !important;
    height: 80vh;
  }
`;

CardWrapper.displayName = 'CardWrapper';

export { CardWrapper, TypeformModalWrapper };
