import {
  filterInstructorList,
  orderedInstructorList,
} from '../../helpers';
import {
  filterInstructors,
  setInstructorsTab,
  setTimeZoneTab,
  toggleInstructorAssignment,
  toggleOrderByCurrentStudents,
} from './actions';
import Instructors from '../../components/Instructors';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  assignmentsPage: {
    activeInstructors,
    activeRegion,
    assigningStudentId,
    assigningMentorId,
    assigningTutorId,
    csvDownloadURL,
    instructorsListLoaded,
    instructors,
    instructorTags,
    orderByCurrentStudents,
    tagsToHide,
    tagsToShow,
  },
}) => {
  if (instructorsListLoaded) {
    const {
      certified,
      mentors_count: mentorsCount,
      mentors: mentorsList,
      mentors_total_free_capacity: mentorFreeCapacity,
      tutors: tutorsList,
      tutors_count: tutorsCount,
      tutors_total_free_capacity: tutorFreeCapacity,
    } = instructors;

    let totalFreeCapacity;
    let instructorCount;
    let instructorList;
    let assigningInstructorId;

    if (activeInstructors === 'tutors') {
      instructorCount = tutorsCount;
      instructorList = tutorsList;
      assigningInstructorId = assigningTutorId;
      totalFreeCapacity = tutorFreeCapacity;
    } else {
      instructorCount = mentorsCount;
      instructorList = mentorsList;
      assigningInstructorId = assigningMentorId;
      totalFreeCapacity = mentorFreeCapacity;
    }

    const instructorLists = {
      all: instructorList.filter(list => !list.certified),
      america: instructorList.filter(list => list.timezone_area === 'america'),
      apac: instructorList.filter(list => list.timezone_area === 'apac'),
      certified: instructorList.filter(list => list.certified),
      europe: instructorList.filter(list => list.timezone_area === 'europe' && !list.certified),
    };

    const filteredInstructorList = () => {
      const hasFiltersApplied = tagsToShow.length || tagsToHide.length;
      const list = instructorLists[activeRegion];
      return hasFiltersApplied ? filterInstructorList(list, tagsToShow, tagsToHide) : list;
    };

    return {
      activeInstructors,
      activeRegion,
      assigningInstructorId,
      assigningStudentId,
      certified,
      csvDownloadURL,
      instructorCount,
      instructorList: orderedInstructorList(filteredInstructorList(), orderByCurrentStudents),
      instructorTags,
      mentorsCount,
      mentorsList,
      orderByCurrentStudents,
      totalFreeCapacity,
      tutorsCount,
      tutorsList,
    };
  }
  return {
    activeInstructors,
    activeRegion,
    instructorsListLoaded,
    ...loadingStateData,
  };
};

export const mapDispatchToProps = dispatch => ({
  filterInstructors: (tagsToShow, tagsToHide) =>
    dispatch(filterInstructors(tagsToShow, tagsToHide)),
  setAssigningInstructorId: instructorIds =>
    dispatch(setAssigningInstructorId(instructorIds)),
  setInstructorsTab: tabTitle => dispatch(setInstructorsTab(tabTitle)),
  setTimeZoneTab: tabTitle => dispatch(setTimeZoneTab(tabTitle)),
  toggleInstructorAssignment: instructorId =>
    dispatch(toggleInstructorAssignment(instructorId)),
  toggleOrderByCurrentStudents: () =>
    dispatch(toggleOrderByCurrentStudents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Instructors);

const loadingStateData = {
  assigningInstructorId: null,
  csvDownloadURL: '',
  instructorCount: {
    all: '😮',
    america: '😮',
    apac: '😮',
    certified: '😮',
    europe: '😮',
  },
  instructorList: [{
    currentStudents: 4,
    id: 1,
    name: 'Victor Odutokun',
    newStudents: 0,
    profileUrl: '',
    studentsEndingSoon: 1,
    timezone: 'PST (-08:00)',
    totalCapacity: 40,
  }],
  totalFreeCapacity: 178,
};
