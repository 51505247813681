import React, { useEffect, useState } from 'react';
import CallNoteForm from '../shared/CallNoteForm';
import ModalWrapper from '../shared/ModalWrapper';
import PropTypes from 'prop-types';
import { REQUIRED_NOTE_FIELD } from '../../constants';
import { getScreenshot } from './helpers';

const CallNoteFormModal = ({
  canSubmitNote,
  createOffPlatformCall,
  modalType,
  currentCalendarEntry,
  errorMessage,
  setModalType,
  isOffPlatformCall,
  currentCallId,
  mentorNoteForm,
  onSubmit,
  submitLoading,
}) => {
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  const handleSubmit = async () => {
    if (
      !mentorNoteForm.sharable ||
      mentorNoteForm.sharable_message ===
        'Would you like to inform career services regarding some specifics of this call?'
    ) {
      delete mentorNoteForm.sharable_message;
    }

    if (typeof onSubmit === 'function') {
      if (isOffPlatformCall) {
        const screenshot = getScreenshot();
        await createOffPlatformCall(
          { ...currentCalendarEntry, callRequestScreenshot: screenshot },
          mentorNoteForm
        );
      } else {
        await onSubmit(currentCallId, mentorNoteForm);
      }
    }
  };

  useEffect(() => {
    for (const field of REQUIRED_NOTE_FIELD) {
      if (!mentorNoteForm[field]) return setSubmitDisabled(true);
    }

    return setSubmitDisabled(false);
  }, [mentorNoteForm]);

  return (
    <ModalWrapper
      currentState={modalType}
      onNextClick={handleSubmit}
      onPrevClick={() => setModalType('callQuestion')}
      submitDisabled={isSubmitDisabled || canSubmitNote === false}
      submitLoading={submitLoading}
    >
      <CallNoteForm isMessageSharable={mentorNoteForm.sharable} />
      {errorMessage && (
        <p className='ds-typography_body--bold error'>{errorMessage}</p>
      )}
    </ModalWrapper>
  );
};

CallNoteFormModal.propTypes = {
  canSubmitNote: PropTypes.bool.isRequired,
  createOffPlatformCall: PropTypes.func.isRequired,
  currentCalendarEntry: PropTypes.object.isRequired,
  currentCallId: PropTypes.number.isRequired,
  errorMessage: PropTypes.string,
  isOffPlatformCall: PropTypes.bool.isRequired,
  mentorNoteForm: PropTypes.shape({
    sharable: PropTypes.bool,
    sharable_message: PropTypes.string,
  }),
  modalType: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setModalType: PropTypes.func.isRequired,
  submitLoading: PropTypes.bool,
};

export default CallNoteFormModal;
