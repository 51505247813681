import React from 'react';

const CelebrationMessage = () => (
  <header className='cf-text-center'>
    <h3 className='ds-typography__h3'>🥳</h3>
    <h3 className='ds-typography__h3 ds-color__indigo'>
      You achieved your goal!
    </h3>
    <p className='ds-typography__body' style={{ marginBottom: '32px' }}>
      {textGenerator(exclamationText)}! You’ll get feedback soon.{' '}
      {textGenerator(rewardText)}. Before you go, please share your thoughts on
      how to improve this exercise. Thank you!
    </p>
  </header>
);

export default CelebrationMessage;

const textGenerator = (textOptions) =>
  textOptions[Math.floor(Math.random() * Math.floor(textOptions.length))];

const exclamationText = [
  'Nice',
  'Excellent',
  'Good work',
  'Fantastic',
  'Look at you go',
  'Fantastic',
  'That’s awesome',
];

const rewardText = [
  'Take a walk and find something beautiful!',
  'Treat yourself to that little thing you’ve been wanting.',
  'Have a cup o’ tea and breathe.',
  'Take a nap!',
  'Go read a book that’s got nothing to do with work.',
  'Spend some quality time with someone you care about.',
  'Stretch it out with some yoga.',
  'Unwind in the tub.',
  'Unplug for a while.',
  'Catch up on that show you’ve been watching.',
];
