import PropTypes from 'prop-types';
import React from 'react';
import StarIcon from './StarIcon';

export function StaticRatingIcon ({ index, rating }) {
  const fill = rating >= index ? 'var(--ds-color__indigo)' : 'none';

  return (
    <div>
      <StarIcon fill={fill} />
    </div>
  );
}

StaticRatingIcon.propTypes = {
  index: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
};

export default StaticRatingIcon;
