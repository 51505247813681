import styled from 'styled-components';

const Wrapper = styled.div`
  h2 {
    text-align: left;
  }

  .cf-form select + i {
    top: 18px;
  }

  .submit-button {
    width: 100%;
    display: block;
    margin: 50px 0;
    border-radius: 2px !important;
  }

  .linkButton {
    margin: 10px 0;
    display: block;
  }

  .ds-form__input__wrapper.multiple {
    .fa {
      display: none;
    }
    height: auto;

    select.ds-form__input[multiple] {
      display: block;
      margin: 0;
      padding: 0;
      height: 100vh;
      position: relative;
      top: 52px;
      scroll: auto;
      option {
        height: 52px;
        font-size: 16px;
        background: white;
        vertical-align: middle;
        margin-bottom: 16px;
        border-radius: 3px;
      }
    }
  }

  .TheModal {
    width: 450px;

    & > span {
      display: none;
    }
  }
`;

Wrapper.displayName = 'Wrapper';

export { Wrapper };
