import React, { Component } from 'react'; 

import QuestionSlide from './QuestionSlide';
import ResultSlide from './ResultSlide';
import DetailsSlide from './DetailsSlide';

class QuizModal extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.onQuizLoaded();
  }

  render(){
    var attempt = (this.props.tempAttempt) ? this.props.tempAttempt : this.props.attempt

    var questionsArray = Object.values(attempt.questions);
    var questionId = Object.keys(attempt.questions)[attempt.progress_index];
    var correctAnswersCount = questionsArray.filter(question => question.chosen_answer_status == 'correct').length;

    var currentProgress = attempt.progress_index;

    var renderSlides = () => {
      if (currentProgress < questionsArray.length && this.props.detailsProgress < 1){
        // User has not finished the quiz yet
        var currentQuestion = questionsArray[currentProgress];

        return (<QuestionSlide quizLabel={this.props.attempt.quiz_label}
                               modalLoading={this.props.modalLoading}
                               progress={currentProgress + 1}
                               numberOfQuestions={questionsArray.length}
                               questionId={questionId}
                               onAnswerClick={this.props.onAnswerClick}
                               onPrevClick={this.props.onQuestionPrevClick}
                               onNextClick={this.props.onQuestionNextClick}
                               {...currentQuestion}
        />)
      } else if ((this.props.detailsProgress > 0) && (this.props.detailsProgress < questionsArray.length + 1)){
        // User has completed the quiz and started looking at the details.
        var currentDetailsQuestion = questionsArray[this.props.detailsProgress - 1];

        return(<DetailsSlide quizLabel={this.props.attempt.quiz_label}
                             modalLoading={this.props.modalLoading}
                             numberOfQuestions={questionsArray.length}
                             numberOfCorrectAnswers={correctAnswersCount}
                             questions_status_list={this.props.questions_status_list}
                             onDetailsNextClick={this.props.onDetailsNextClick}
                             onDetailsPrevClick={this.props.onDetailsPrevClick}
                             detailsProgress={this.props.detailsProgress}
                             questionId={questionId}
                             {...currentDetailsQuestion}
        />)
      } else {
        // 1. Has completed and not yet started looking at details
        // 2. Has completed the quiz AND completed looking at the details
        return (<ResultSlide quizLabel={this.props.attempt.quiz_label}
                             modalLoading={this.props.modalLoading}
                             numberOfQuestions={questionsArray.length}
                             numberOfCorrectAnswers={correctAnswersCount}
                             questions_status_list={this.props.questions_status_list}
                             onTakeAgainClick={this.props.onTakeAgainClick}
                             detailsProgress={this.props.detailsProgress}
                             onNextClick={this.props.onResultNextClick}
        />)
      }
    }
    return (
      <div className="cf-modal quiz__modal" id="quiz__modal">
        <div className="cf-modal__box">
          {renderSlides()}
        </div>
      </div>
    );
  }
}

export default QuizModal;
