import React from 'react';
import { Locale } from '../../../../shared/enums';
import { ChevronRight } from '../../../shared/icons/ChevronRight';
import ExerciseNavigator from '../ExerciseNavigator';
import { constants } from './constants';
import { Nav } from './styles';

interface BreadcrumbNavProps {
  courseInfo: {
    courseName: string;
    courseSlug: string;
  };
  exerciseInfo: {
    exerciseStep: string;
    nextExerciseName: string;
    nextExerciseSlug: string;
    previousExerciseName: string;
    previousExerciseSlug: string;
  };
}

const BreadcrumbNav: React.FC<BreadcrumbNavProps> = ({
  courseInfo,
  exerciseInfo,
}) => {
  const { courseName, courseSlug } = courseInfo;
  const {
    exerciseStep,
    previousExerciseName,
    previousExerciseSlug,
    nextExerciseName,
    nextExerciseSlug,
  } = exerciseInfo;

  const navItems = [
    {
      label: constants.dashboardNavItemLabel,
      url: `/${Locale.en}`,
    },
    {
      label: courseName,
      url: `/${Locale.en}/dashboards/${courseSlug}/course-home`,
    },
  ];

  return (
    <Nav isNextNavItemPresent={!!nextExerciseSlug}>
      <div className='page-navigator'>
        {navItems.map((navItem, index) => (
          <React.Fragment key={`${navItem.label}-${index}`}>
            <a href={navItem.url} className='ds-text__link--medium'>
              {navItem.label}
            </a>
            <ChevronRight className='chevron-right-icon' />
          </React.Fragment>
        ))}
        <span className='current-exercise-info ds-text__body--medium ds-text--bold'>
          {`Exercise ${exerciseStep}`}
        </span>
      </div>
      <div className='exercise-navigator'>
        <ExerciseNavigator
          courseSlug={courseSlug}
          shouldShowTooltip={true}
          previousNavItemLabel={constants.previousNavItemLabel}
          nextNavItemLabel={constants.nextNavItemLabel}
          previousExerciseName={previousExerciseName}
          previousExerciseSlug={previousExerciseSlug}
          nextExerciseName={nextExerciseName}
          nextExerciseSlug={nextExerciseSlug}
        />
      </div>
    </Nav>
  );
};

export default BreadcrumbNav;
