import styled from 'styled-components';

export const StyledDropDown = styled.div``;

export const DropDownHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const DropDownList = styled.div`
  margin-top: 10px;
  position: absolute;
  width: 165px;
  padding: 14px;
  background: white;
`;

export const DropDownListItem = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: var(--ds-color__indigo);
  padding-left: 6px;
  cursor: pointer;
`;
