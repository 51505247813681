import { FormSection, InputWrapper } from '../styles';
import Input from '../../../../../shared/components/Input';
import PropTypes from 'prop-types';
import React from 'react';
import SectionHeader from '../../SectionHeader';
import { errorMessage } from '../helpers';

const PersonalInformationInputs = ({
  formErrors,
  handleSave,
  personalData,
}) => {
  return (
    <FormSection>
      <SectionHeader
        headline="Legal Name"
        tooltipText="Please write your name exactly as it appears in your government issued ID. We’ll use this to create your contract."
      />
      <InputWrapper>
        <Input
          type="text"
          name="personalLegalFirstName"
          placeholder="First name"
          required={true}
          value={personalData.personalLegalFirstName}
          onChange={(value) => handleSave('personalLegalFirstName', value)}
          error={errorMessage(formErrors, 'personalLegalFirstName')}
        />
        <Input
          type="text"
          name="personalLegalMiddleName"
          placeholder="Middle name"
          value={personalData.personalLegalMiddleName}
          onChange={(value) => handleSave('personalLegalMiddleName', value)}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          type="text"
          name="personalLegalLastName"
          placeholder="Last name"
          required={true}
          value={personalData.personalLegalLastName}
          onChange={(value) => handleSave('personalLegalLastName', value)}
          error={errorMessage(formErrors, 'personalLegalLastName')}
        />
        <Input
          type="text"
          name="businessName"
          placeholder="Business name (if applicable)"
          value={personalData.businessName}
          onChange={(value) => handleSave('businessName', value)}
        />
      </InputWrapper>
    </FormSection>
  );
};

PersonalInformationInputs.propTypes = {
  formErrors: PropTypes.object,
  handleSave: PropTypes.func,
  personalData: PropTypes.object,
};

export default PersonalInformationInputs;
