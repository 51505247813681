import PropTypes from 'prop-types';
import React from 'react';
import WarningStyles from '../../../UserProfile/components/MentorNotesTab/styles/WarningStyles';

const WarningModal = ({
  action,
  cancelButtonAction,
  confirmButtonAction,
  message,
  subMessage,
}) => (
  <WarningStyles>
    <div></div>
    <div>
      <h3 className="ds-typography__h3">{message}</h3>
      <p className="ds-typography__body">{subMessage}</p>
    </div>
    <div>
      <button className="ds-button ds-button__primary--inverse" onClick={cancelButtonAction}>No, don't {action}</button>
      <button className="ds-button__primary" onClick={confirmButtonAction}>Yes, {action}</button>
    </div>
  </WarningStyles>
);

WarningModal.propTypes = {
  action: PropTypes.string,
  cancelButtonAction: PropTypes.func,
  confirmButtonAction: PropTypes.func,
  message: PropTypes.string.isRequired,
  subMessage: PropTypes.string,
};

export default WarningModal;
