import PropTypes from 'prop-types';
import React from 'react';
import StyledHeading from './styles/HeadingSection';
import StyledProfileSection from './styles/StyledProfileSection';

const ProfileSection = ({ header, childComponent, headerClassName = '' }) => {
  return (
    <StyledProfileSection>
      <StyledHeading className={`ds-typography__h5 text-left section-header ${headerClassName}`}>{header}</StyledHeading>
      {childComponent}
    </StyledProfileSection>
  );
};

ProfileSection.propTypes = {
  childComponent: PropTypes.element.isRequired,
  header: PropTypes.string.isRequired,
  headerClassName: PropTypes.string,
};

export default ProfileSection;
