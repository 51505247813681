import React from 'react';
import StyledImageSection from '../styles/StyledImageSection';
import StyledSuccessImage from '../styles/StyledSuccessImage';
import { PaymentIntent } from '../types/PaymentIntent';

interface SuccessPageProps {
  paymentIntent: PaymentIntent;
}

const SuccessPage = (props: SuccessPageProps) => {
  const { paymentIntent } = props;
  const goToDashboard = () => window.location.replace('/en/dashboards/main');

  return (
    <>
      <h3 className='ds-text__h3'>Thank you!</h3>
      <StyledImageSection>
        <StyledSuccessImage />
      </StyledImageSection>
      <div className='message__body'>
        <p className='ds-typography__h5'>Payment {paymentIntent.status}</p>
        <span>
          <p className='ds-typography__body'>
            Reference Number: {paymentIntent.id}
          </p>
        </span>
        <p className='ds-typography__body'>
          We are excited to have you on board! Your account will be set up
          shortly. You will receive your receipt and an email with more
          information about your enrolment soon.
        </p>
        <button className='ds-button__primary' onClick={goToDashboard}>
          Go to dashboard
        </button>
      </div>
    </>
  );
};

export default SuccessPage;
