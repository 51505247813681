import PropTypes from 'prop-types';
import React from 'react';

const ContractSignedSuccess = ({ handleClose }) => (
  <>
    <i className="ds-icon__check-circle-line"></i>
    <h3 className="ds-typography__h3">Thank you!</h3>
    <p className="ds-typography__lead">
      You've confirmed that the contract has been signed by both parties. The
      candidate status has been updated.
    </p>
    <button className="ds-button__primary" onClick={handleClose}>
      Got it
    </button>
  </>
);

ContractSignedSuccess.propTypes = {
  handleClose: PropTypes.func,
};

export default ContractSignedSuccess;
