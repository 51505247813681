import styled from 'styled-components';

const StyledQuestion = styled.div`
  padding: 16px 16px;
  border: solid 1px #c0c6cb;
  justify-content: space-between;
  p {
    margin-bottom: 0 !important;
  }
  a {
    font-size: 14px;
  }

  .question {
    width: 33%;
    max-width: unset;
    flex-basis: unset;
  }

  .answers {
    width: 66%;
    max-width: unset;
    flex-basis: unset;
  }
  .ds-typography__anchor:hover {
    font-size: 14px !important;
  }

  @media (max-width: 800px) {
    a {
      text-align: left;
      width: fit-content;
      margin-top: 0;
      margin-bottom: 32px !important;
      display: block;
    }
    .question {
      width: 100%;
    }

    .answers {
      width: 100%;
    }
  }

  @media (max-width: 479px) {
    border-top: 0;
  }
`;

StyledQuestion.displayName = 'StyledQuestion';

export default StyledQuestion;
