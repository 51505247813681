import PropTypes from 'prop-types';
import React from 'react';
import StyledSelect from './styles';

const Select = ({
  children,
  name,
  value,
  onChange,
  required,
}) => (
  <StyledSelect>
    <select
      name={name}
      value={value}
      onChange={onChange}
      required={required}
    >{children}</select>
    <span></span>
  </StyledSelect>
);

Select.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
};

export default Select;
