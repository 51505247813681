module.exports = (() => {
  const init = () => {
    bindFunctions();
  };

  const initializeS3uploader = () => {
  };

  const bindFunctions = () => {
    $("#termsS3uploader").bind('s3_uploads_start', uploadStarted);
    $("#termsS3uploader").bind("s3_uploads_complete", uploadStop);
    $("#privacyS3uploader").bind('s3_uploads_start', uploadStarted);
    $("#privacyS3uploader").bind("s3_uploads_complete", uploadStop);
  };

  const uploadStarted = () => {
    $('.btn-file').addClass('button--primary-disabled')
    $('.terms-uploads--js').html(Template.loading_spinner)
  };

  const uploadStop = () => location.reload();

  return {
    init: init
  };
})();
