import ApprovalModal from './approval_modal';
import ApprovalModalBSG from './approval_modal_bsg';
import React from 'react';
import RejectionModal from './rejection_modal';
import TheModal from '../../../../shared/components/TheModal';

interface ModalProps {
  modalAction: {
    action: string;
    attribute: string;
  };
  handleModalClose: () => void;
  handleUpdateAdmission: (params: any) => void;
}

const Modal: React.FC<ModalProps> = ({
  modalAction,
  handleModalClose,
  handleUpdateAdmission,
}) => {
  return (
    <TheModal
      isModalOpen={!!modalAction.action.length}
      handleClose={handleModalClose}
    >
      <>
        {
          {
            approve: (
              <ApprovalModal
                modalAction={modalAction}
                handleUpdateAdmission={handleUpdateAdmission}
              />
            ),
            approveBSG: (
              <ApprovalModalBSG
                handleUpdateAdmission={handleUpdateAdmission}
                handleClose={handleModalClose}
              />
            ),
            reject: (
              <RejectionModal
                modalAction={modalAction}
                handleUpdateAdmission={handleUpdateAdmission}
              />
            ),
          }[modalAction.action]
        }
      </>
    </TheModal>
  );
};

export default Modal;
