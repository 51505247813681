import styled from 'styled-components';

const StyledButtonWrapper = styled.div`
  //margin-right: 25px;
  button {
    margin: 0;
    margin: 0;
    margin-left: 16px;
    min-width: 125px;
    width: unset;
  }

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;

    button {
      width: 100%;

      &.ds-button__secondary {
        margin-top: 12px;
      }
    }
  }
`;

StyledButtonWrapper.displayName = 'StyledButtonWrapper';

export default StyledButtonWrapper;
