import PropTypes from 'prop-types';
import React from 'react';
import StudentHandoffCard from '../StudentHandoffCard/index';
import StyledStudentHandoff from './styles/StyledStudentHandoff';

const PendingRequests = ({ handleClick, pendingHandoffs, overdueHandoffs }) => (
  <StyledStudentHandoff>
    <div className="ds-grid__container">
      <div className="flex header">
        <h4 className="intro_graf bold">Handoff Requests</h4>
        <a href="/en/overdue-handoffs">Overdue requests ({overdueHandoffs.length})</a>
      </div>
      <p>Students from this list are starting to prepare for job search. We kindly ask you to help our Career Specialists by filling in a short 3-5 min survey about your experience of working with these students.</p>
      {pendingHandoffs && pendingHandoffs.length > 0
        ? pendingHandoffs.map(handoff => <StudentHandoffCard handleClick={handleClick} key={handoff.id} handoff={handoff} />)
        : <p className="bold center">No handoff requests at the moment</p>
      }
    </div>
  </StyledStudentHandoff>
);
;

PendingRequests.propTypes = {
  handleClick: PropTypes.func.isRequired,
  overdueHandoffs: PropTypes.array.isRequired,
  pendingHandoffs: PropTypes.array.isRequired,
  selectedHandoff: PropTypes.shape({
    createdAt: PropTypes.string,
    student: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      photo: PropTypes.object,
      slug: PropTypes.string,
    }),
  }),
};

export default PendingRequests;
