import React, { useState } from 'react';
import CallFeedbackBox from './styles';
import CallFeedbackModal from '../CallFeedbackModal';
import CallFeedbackWrapper from '../CallFeedbackWrapper';
import PropTypes from 'prop-types';
import { postData } from '../../actions';

const TYPEFORM_ID = 'CbpmUenm';

const CallFeedbackContainer = ({
  attendeeId,
  calls,
  loading,
  modal,
  selectedCall,
  setCalls,
  setSelectedCall,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(modal);

  const handleClick = (call) => {
    setSelectedCall(call);
    setModalIsOpen(true);
  };

  const handleClose = () => setModalIsOpen(false);

  const handleSubmit = (event) => {
    postData({
      attendeeId,
      callId: selectedCall.callId,
      formId: TYPEFORM_ID,
      responseId: event.data.responseId,
    });

    setTimeout(() => {
      const updatedCalls = calls.filter(
        (el) => el.callId !== selectedCall.callId
      );
      setCalls(updatedCalls);
      handleClose();
    }, 2000);
  };

  return (
    <>
      {!!calls.length && (
        <CallFeedbackBox>
          <h4 className='ds-typography__h4 ds-color__indigo'>Call Review</h4>
          {calls.map((call, i, arr) => (
            <CallFeedbackWrapper
              key={call.callId}
              border={i + 1 !== arr.length}
              call={call}
              handleClick={handleClick}
            />
          ))}
        </CallFeedbackBox>
      )}
      <CallFeedbackModal
        selectedCall={selectedCall}
        formId={TYPEFORM_ID}
        modalIsOpen={modalIsOpen}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
};

CallFeedbackContainer.propTypes = {
  attendeeId: PropTypes.number,
  calls: PropTypes.arrayOf(
    PropTypes.shape({
      callId: PropTypes.number,
      date: PropTypes.string,
      expires: PropTypes.string,
      imageUrl: PropTypes.string,
      mentor: PropTypes.string,
    }).isRequired
  ),
  loading: PropTypes.bool,
  modal: PropTypes.bool,
  selectedCall: PropTypes.object,
  setCalls: PropTypes.func.isRequired,
  setSelectedCall: PropTypes.func,
};

export default CallFeedbackContainer;
