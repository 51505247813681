import styled from 'styled-components';

const StepperSection = styled.div`
  a {
    margin: 10px;
  }
  padding: 20px 0px;
  display: flex;
  justify-content: center;

  ${({ isModal }) =>
    !isModal &&
    `
    padding: 32px 0 48px;
      div {
        width: 34%;
      }

      div:before {
        height: 2px;
      }
      .fa-stack {
        width: 29px;
        height: 29px;
      }
      i {
        font-size: 29px;
      }

      .circle-text {
        font-size: 14px;
      }

      .toggle-section {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        h5 {
          font-size: 16px;
          line-height: 1.5;
          margin: 0;
           a {
            font-size: 14px;
            line-height: 1.71;
            text-decoration: underline;
            margin: 0;
            margin-right: 5px;
           }
        }
      }

    @media (max-width: 768px) {
      padding-bottom: 0;
      div {
        width: 85%;
      }
    }
  `}
`;

StepperSection.displayName = 'StepperSection';

export default StepperSection;
