import styled from 'styled-components';

const StyledAddIcon = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  position: relative;

  i {
    color: var(--ds-color__grey--400);
    font-size: 22px;
    margin-right: 6px;
  }

  p {
    font-size: 12px;
    line-height: 1.67;
    color: var(--ds-color__grey--400);
    margin-bottom: 0;
    font-weight: 600;
  }
`;

StyledAddIcon.displayName = 'StyledAddIcon';

export default StyledAddIcon;
