import { getJobSeekingProfiles, setFilterParams } from '../../../actions';
import JobProfileFilters from '../../components/JobProfileFilters';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  adminEmployerConnector: {
    profileFilterParams,
  },
}) => ({
  profileFilterParams,
});

export const mapDispatchToProps = dispatch => ({
  getJobSeekingProfiles: (page, params) => dispatch(getJobSeekingProfiles(page, params)),
  setFilterParams: (params) => dispatch(setFilterParams(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobProfileFilters);
