import {
  handleCancelModal,
  handleCloseModal,
  setModalAction,
} from '../../action';
import MentorModals from '../../components/Modals';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  mentorCallNotes: { isOffPlatformCall, modalAction, modalIsOpen },
}) => ({
  isOffPlatformCall,
  modalAction,
  modalIsOpen,
});

export const mapDispatchToProps = (dispatch) => ({
  handleCancel: () => dispatch(handleCancelModal()),
  handleCloseModal: () => dispatch(handleCloseModal()),
  setModalAction: (action) => dispatch(setModalAction(action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MentorModals);
