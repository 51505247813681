import styled from 'styled-components';

const Wrapper = styled.div`
  h4 {
    display: inline-block;
    font-size: 24px;
    font-weight: 400 !important;
  }

  p {
    line-height: 30px;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 0;
  }
  span {
    cursor: pointer;
  }
  
  @media only screen and (max-width: 768px) {
    h4 {
      margin-bottom: 0px;
      display: none;
    }
    padding-bottom: 8px;
  }

  @media only screen and (min-width: 768px) {
    h4 {
      margin-bottom: 32px;
    }
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
