export const renderPrettyDate = dateStr => {
  if(dateStr === null || dateStr === '' || dateStr === undefined) return '-';

  const dateObj = new Date(dateStr);
  let date = dateObj.getDate();
  let month = (dateObj.getMonth() + 1);
  let year = dateObj.getFullYear().toString().slice(2,4);
  if (date < 10)
    date = '0' + date;
  if (month < 10)
    month = '0' + month;
  return `${date}.${month}.${year}`;
};

export const formattedDate = date => {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return `${monthNames[monthIndex]}, ${day} ${year}`;
};

export const lastDateInWeek = (date = new Date()) => {
  const lastday = date.getDate() - (date.getDay() - 1) + 6;
  return date.setDate(lastday);
};
