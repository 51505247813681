import styled from 'styled-components';

const Wrapper = styled.ul`
  border: 1px solid #eee;
  list-style-type: none;
  padding: 0;
  max-height: calc(100vh - 315px);
  min-height: 150px;
  overflow-y: scroll;

  li:nth-of-type(even) {
    background-color: #223C5010;
  }
  
  p {
    line-height: initial;
  }
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  transition: 300ms box-shadow, 300ms opacity;

  button {
    margin-right: 5px;
  }
`;
const ColumnRight = styled.div`
  p {
    text-align: right;
  }
`;

ColumnRight.displayName = 'ColumnRight';
ListItem.displayName = 'ListItem';
Wrapper.displayName = 'Wrapper';

export {
  ColumnRight,
  ListItem,
  Wrapper,
};
