import styled from 'styled-components';

const ButtonSection = styled.div`
  padding-top: 10px;

  @media (max-width: 768px) {
    padding-top: 0;
    a {
      margin: 16px 31px 0 0;
    }
  }
`;

ButtonSection.displayName = 'ButtonSection';

export default ButtonSection;
