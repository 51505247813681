import { constants } from './constants';
import { mapJobSeekingProfileResponse } from './Students/helpers';
import { replaceUpdatedEmployer } from './helpers';

export const initialState = {
  activePage: 1,
  employer: {},
  employers: [],
  employersPage: 1,
  errors: [],
  jobSeekingProfile: {},
  jobSeekingProfiles: [],
  loading: false,
  modalAction: null,
  modalIsOpen: false,
  perPage: 20,
  profileFilterParams: { city: '', country: '', enrolled_end_date: '', enrolled_start_date: '', graduated_status: '', program: '', specialization: '', status: '' },
  totalEntries: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
  case constants.HANDLE_CLOSE_MODAL: {
    return {
      ...state,
      modalIsOpen: false,
    };
  }
  case constants.HANDLE_NEW_EMPLOYER: {
    return {
      ...state,
      employer: {},
      modalAction: 'newEmployer',
      modalIsOpen: true,
    };
  }
  case constants.HANDLE_UPDATE_EMPLOYER: {
    return {
      ...state,
      employer: state.employers.find(e => e.id === action.data),
      modalAction: 'updateEmployer',
      modalIsOpen: true,
    };
  }
  case constants.GET_EMPLOYERS_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }

  case constants.GET_EMPLOYERS_SUCCESS: {
    return {
      ...state,
      activePage: action.data.pagination.currentPage,
      employers: action.data.employers,
      loading: false,
      perPage: action.data.pagination.perPage,
      totalEntries: action.data.pagination.totalEntries,
    };
  }

  case constants.UPDATE_EMPLOYER_SUCCESS: {
    const updatedEmployers = replaceUpdatedEmployer(action.data, [...state.employers]);
    return {
      ...state,
      employers: updatedEmployers,
      loading: false,
      modalIsOpen: false,
    };
  }

  case constants.CREATE_EMPLOYER_SUCCESS: {
    return {
      ...state,
      employers: [action.data, ...state.employers],
      errors: [],
      loading: false,
      modalIsOpen: false,
    };
  }

  case constants.CREATE_EMPLOYER_FAILURE:
  case constants.UPDATE_EMPLOYER_FAILURE: {
    return {
      ...state,
      errors: [...state.errors, action.error],
      loading: false,
    };
  }

  case constants.SHOW_JOB_SEEKING_PROFILE_SUCCESS: {
    return {
      ...state,
      jobSeekingProfile: action.data,
      modalAction: 'showJobSeekingProfile',
      modalIsOpen: true,
    };
  }

  case constants.SHOW_EMPLOYER_SUCCESS: {
    return {
      ...state,
      employer: action.data,
      modalAction: 'showEmployer',
      modalIsOpen: true,
    };
  }

  case constants.DELETE_EMPLOYER_SUCCESS: {
    return {
      ...state,
      employers: state.employers.filter(employer => employer.id !== action.data),
      loading: false,
      modalIsOpen: false,
    };
  }

  case constants.FETCH_JOB_SEEKING_PROFILES_SUCCESS: {
    const { data } = action;
    return {
      ...state,
      activePage: data.currentPage,
      jobSeekingProfiles: mapJobSeekingProfileResponse(data.data),
      loading: false,
      perPage: data.perPage,
      totalEntries: data.totalEntries,
    };
  }

  case constants.SET_PROFILE_FILTER: {
    const { name, value } = action.data;
    return {
      ...state,
      profileFilterParams: {
        ...state.profileFilterParams,
        [name]: value,
      },
    };
  }

  case constants.SET_PROFILE_LOADING: {
    return {
      ...state,
      loading: true,
    };
  }

  default:
    return state;
  };
};
