export const formatErrorMessage = error => {
  if (_.isObject(error)) {
    if (_.isObject(error.message)) {
      return Object.keys(error.message)
        .map(key => `${key.replace('_', ' ')} ${error.message[key]}`)
        .join(', ')
        .toLowerCase();
    } else {
      if (error.error && _.isObject(error.error.message)) {
        return error.error.message.message;
      } else {
        if (error.key === 'checkout') {
          return error.message.replace(/ *\([^)]*\) */g, "").replace("..", ".")
        } else {
          return error.message || error.error;
        }
      }
    }
  }
  return error;
}
