import React, { useEffect, useState } from 'react';
import CompletionWrapper from './styles/CompletionWrapper';
import LoadingSpinner from '../loadingSpinner';
import SuccessPage from './Completion/SuccessPage';
import ErrorPage from './Completion/ErrorPage';
import { PaymentIntent } from './types/PaymentIntent';
import { updateCheckoutTransaction } from './actions';

interface CompletionProps {
  stripePromise: any;
}

interface ResponseError {
  message: string;
}

function Completion(props: CompletionProps) {
  const [transactionId, setTransactionId] = useState<number>(0);
  const [messageBody, setMessageBody] = useState(<LoadingSpinner />);
  const { stripePromise } = props;

  const handleStripeResponse = (
    error: ResponseError,
    paymentIntent: PaymentIntent,
    transactionId: number
  ) => {
    if (error) {
      setMessageBody(<p className='ds-typography__body'>{error.message}</p>);
    }

    if (paymentIntent) {
      setMessageBody(
        paymentIntent.status === 'succeeded' ? (
          <SuccessPage paymentIntent={paymentIntent} />
        ) : (
          <ErrorPage />
        )
      );
      updateCheckoutTransaction(transactionId);
    }
  };

  useEffect(() => {
    if (!stripePromise) return;

    stripePromise.then(async (stripe: any) => {
      // @ts-ignore
      const url = new URL(window.location);
      const clientSecret = url.searchParams.get('payment_intent_client_secret');
      const transactionId = url.searchParams.get('transaction_id');
      setTransactionId(Number(transactionId));
      const { error, paymentIntent } =
        await stripe.retrievePaymentIntent(clientSecret);

      handleStripeResponse(error, paymentIntent, Number(transactionId));
    });
  }, [stripePromise]);

  return (
    <CompletionWrapper>
      <div
        id='messages'
        role='alert'
        style={messageBody ? { display: 'block' } : {}}
      >
        {messageBody}
      </div>
    </CompletionWrapper>
  );
}

export default Completion;
