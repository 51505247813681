import PropTypes from 'prop-types';
import React from 'react';
import StyledCard from './styles';

const Card = ({
  actionFn,
  actionLink,
  actionText,
  heading,
  imageUrl,
  subHeading,
  type,
  warning,
}) => (
  <StyledCard className="ds-shadow__4" type={type}>
    <div>
      <img src={imageUrl} role="presentation" />
    </div>
    <div>
      <h4 className="ds-typography__h4">{heading}</h4>
      <p className="ds-typography__body">{subHeading}</p>
      <div>
        <a
          href={actionLink}
          onClick={actionFn}
          className="ds-button__secondary"
          target="_blank"
          rel="noreferrer"
        >
          {actionText}
        </a>
        <div>
          {warning && (
            <>
              <i className="ds-icon__warning"></i>
              <span className="ds-typography__small--bold">{warning}</span>
            </>
          )}
        </div>
      </div>
    </div>
  </StyledCard>
);

Card.propTypes = {
  actionFn: PropTypes.func,
  actionLink: PropTypes.string,
  actionText: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  warning: PropTypes.string,
};

export default Card;
