import { Accordion } from 'react-accessible-accordion';
import AccordionSection from '../AccordionSection';
import AdditionalResources from './AdditionalResources';
import AudioReader from '../../../../shared/components/AudioReader';
import ButtonWithIcon from '../../../../shared/components/ButtonWithIcon';
import HowToWorkFromHome from './HowToWorkFromHome';
import Introduction from './Introduction';
import LocationAndSalaryExpectations from './LocationAndSalaryExpectations';
import ProsAndCons from './ProsAndCons';
import React from 'react';
import StyledAccordion from '../styles/StyledAccordion';
import StyledBannerImage from '../styles/StyledBannerImage';
import StyledPageContents from '../styles/StyledPageContents';
import StyledRemoteWork from '../styles/StyledRemoteWork';
import WhatIsRemoteWork from './WhatIsRemoteWork';
import WhatToConsider from './WhatToConsider';
import WhereToFindRemoteWork from './WhereToFindRemoteWork';
const ACCORDION_EVENT = 'accordion_heading_clicked';

const RemoteWork = () => {
  return (
    <StyledRemoteWork>
      <StyledBannerImage backgroundImage='https://coach-courses-us.s3.amazonaws.com/public/career-support-center/remote-work.jpg'>
        <ButtonWithIcon
          href='/en/career-support-center'
          icon='arrow-left'
          type='secondary'
          text='Back to career support center'
        />
        <AudioReader
          audioSrc='https://hanzluo.s3-us-west-1.amazonaws.com/music/wuyuwuqing.mp3'
          descriptionText='Listen to this article'
        />
      </StyledBannerImage>
      <StyledPageContents>
        <h1 className='ds-typography__h1'>Getting started in remote work</h1>
        <Introduction />
        <StyledAccordion>
          <Accordion allowZeroExpanded>
            <AccordionSection
              heading='What is remote work'
              content={<WhatIsRemoteWork />}
              eventType={ACCORDION_EVENT}
            />
            <AccordionSection
              heading='What to consider before working remotely'
              content={<WhatToConsider />}
              eventType={ACCORDION_EVENT}
            />
            <AccordionSection
              heading='Remote work location and salary expectations'
              content={<LocationAndSalaryExpectations />}
              eventType={ACCORDION_EVENT}
            />
            <AccordionSection
              heading='Pros and cons of remote working'
              content={<ProsAndCons />}
              eventType={ACCORDION_EVENT}
            />
            <AccordionSection
              heading='Where to find remote work'
              content={<WhereToFindRemoteWork />}
              eventType={ACCORDION_EVENT}
            />
            <AccordionSection
              heading='How to work from home'
              content={<HowToWorkFromHome />}
              eventType={ACCORDION_EVENT}
            />
            <AccordionSection
              heading='Additional resources'
              content={<AdditionalResources />}
              eventType={ACCORDION_EVENT}
            />
          </Accordion>
        </StyledAccordion>
      </StyledPageContents>
    </StyledRemoteWork>
  );
};

export default RemoteWork;
