import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import StyledText from '../CallNotesLists/styles/StyledText';
import StyledTextArea from '../styles/StyledTextArea';
import textEditor from '../../../../shared/text_editor';

const TextAreaInput = ({
  id,
  selector,
  text = '',
  onInputChange,
  showInput = true,
  disabled,
  viewType,
}) => {
  const [loading, setLoading] = useState(true);
  const handleChange = (value) => {
    if (typeof onInputChange === 'function') {
      onInputChange({ data: value, id });
    }
  };

  useEffect(() => {
    if (showInput) {
      const inputField = document.getElementById(id);
      textEditor.init(inputField, selector, handleChange);
      setLoading(false);
    }

    return () => textEditor.closeEditor(selector);
  }, [showInput]);

  return (
    showInput &&
    (viewType === 'edit' ? (
      <StyledTextArea disabled={disabled} loading={loading}>
        <div id={selector}>
          <input id={id} defaultValue={text} disabled={disabled} />
        </div>
        <LoadingSpinner />
      </StyledTextArea>
    ) : (
      <StyledText
        className="ds-typography__body"
        dangerouslySetInnerHTML={{ __html: text }}
      ></StyledText>
    ))
  );
};

TextAreaInput.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  selector: PropTypes.string.isRequired,
  showInput: PropTypes.bool,
  text: PropTypes.string,
  viewType: PropTypes.string,
};

export default TextAreaInput;
