import styled from 'styled-components';

const StyledCareerSupportCenter = styled.div`
  background: #eef5f7;
  padding: 75px 132px 24px 56px;

  .csc-grid {
    width: 98%;
  }

  h2 {
    clear: both;
    font-size: 48px;
    line-height: 1.17;
    margin-bottom: 26px;
  }

  hr {
    display: none;
  }

  h4 {
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
  }

  @media (min-width: 1361px) {
    padding: 75px 56px 24px 56px;
    max-width: 1500px;
    margin: auto;
  }

  @media (max-width: 1360px) {
    padding: 75px 72px 24px 56px;
  }

  @media (max-width: 1300px) {
    padding: 75px 24px 24px 24px;
  }

  @media (max-width: 767px) {
    background: #fff;
    padding: 16px 0;

    h2 {
      padding: 0;
      margin-bottom: 0;
      font-size: 42px;
    }
  }
`;

StyledCareerSupportCenter.displayName = 'StyledCareerSupportCenter';

export default StyledCareerSupportCenter;
