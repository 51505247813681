import styled from 'styled-components';

const StyledOnboardingWrapper = styled.div`
  padding: 56px 42px 58px;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 16px 16px 16px;
    margin-top: 0;
    padding: 36px 42px 16px;
    height: 100vh;
  }
`;

StyledOnboardingWrapper.displayName = 'StyledOnboardingWrapper';

export default StyledOnboardingWrapper;
