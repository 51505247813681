export const copyLink = text => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);

  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);

  return {
    message: 'Copied to clipboard',
    status: 'success',
  };
};
