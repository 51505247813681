import AccountSetupModal from '../../container/AccountSetupModal';
import OptinFAQ from '../../container/OptinFAQModal';
import PersonalDetailsModal from './PersonalDetails';
import ProfessionalStatement from '../../container/ProfessionalStatementModal';
import PropTypes from 'prop-types';
import React from 'react';
import WelcomeModal from './WelcomeModal';

const OptinModal = ({ optinModalStep, setOptinModalStep }) => {
  return (
    <div>
      {
        {
          accountSetup: (
            <AccountSetupModal
              onNextClick={() => setOptinModalStep('faq')}
              onPrevClick={() => setOptinModalStep('professionalStatement')}
            />
          ),
          faq: <OptinFAQ />,
          personalDetails: (
            <PersonalDetailsModal
              onNextClick={() => setOptinModalStep('professionalStatement')}
              onPrevClick={() => setOptinModalStep('welcome')}
            />
          ),
          professionalStatement: (
            <ProfessionalStatement
              onNextClick={() => setOptinModalStep('accountSetup')}
              onPrevClick={() => setOptinModalStep('personalDetails')}
            />
          ),
          welcome: <WelcomeModal onClick={() => setOptinModalStep('personalDetails')} />,
        }[optinModalStep]
      }
    </div>
  );
};

OptinModal.propTypes = {
  optinModalStep: PropTypes.string.isRequired,
  setOptinModalStep: PropTypes.func.isRequired,
};

export default OptinModal;
