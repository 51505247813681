import styled from 'styled-components';

const StyledBoardContainer = styled.div`
  padding: 40px 40px 56px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);
  background-color: var(--ds-color__white);
`;

StyledBoardContainer.displayName = 'StyledBoardContainer';

export default StyledBoardContainer;
