import styled from 'styled-components';

const StyledCard = styled.div`
  border: 1px solid rgba(34, 60, 80, 0.1);
  border-left: 8px solid
    ${(props) =>
      props.type === 'survey' ? '#A5DBE5' : 'var(--ds-color__teal)'};
  border-radius: 4px;
  padding: 30px;
  height: 244px;
  display: flex;
  margin-top: ${(props) => (props.type === 'survey' ? '32px' : '16px')};

  & > div:first-of-type {
    margin-right: 16px;

    img {
      max-width: initial;
      max-height: initial;
    }
  }

  & > div:last-of-type {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    & > div:first-of-type {
      a {
        margin-right: 32px;
      }

      display: flex;
      align-items: center;

      & > div {
        padding-top: 8px;
        display: flex;
      }
    }

    i {
      color: var(--ds-color__petrol--medium);
      margin-right: 10px;
    }
  }

  @media (max-width: 1278px) {
    height: auto;
  }

  @media (max-width: 1061px) and (min-width: 1023px) {
    & > div:last-of-type {
      & > div:last-of-type {
        display: block;

        & > div {
          margin-top: 12px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    & > div:first-of-type {
      display: none;
    }

    & > div:last-of-type {
      & > div:last-of-type {
        display: block;

        & > div {
          margin-top: 12px;
        }
      }
    }
  }
`;

StyledCard.displayName = 'Card';

export default StyledCard;
