import InteractiveMessagesModal from '../../../JobApplicationTracker/components/InteractiveMessages';
import { connect } from 'react-redux';
import {
  handleCloseInteractiveMessage,
} from '../../actions';

export const mapStateToProps = ({
  careerSupportCenter: {
    modalAction,
    modalIsOpen,
    interactiveMessage,
  },
}) => ({
  interactiveMessage,
  modalAction,
  modalIsOpen,
});

export const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(handleCloseInteractiveMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InteractiveMessagesModal);
