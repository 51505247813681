import {
  CheckboxContainer,
  HiddenCheckbox,
  LabelText,
  StyledCheckbox,
} from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const Checkbox = ({
  defaultChecked,
  onClick,
  disabled = false,
  name,
  className = null,
  labelText,
}) => (
  <CheckboxContainer className={className}>
    <>
      <HiddenCheckbox
        defaultChecked={defaultChecked}
        onClick={onClick}
        disabled={disabled}
        name={name}
      />
      <StyledCheckbox
        checked={defaultChecked}
        disabled={disabled}
      />
    </>
    {labelText && <LabelText className="ds-typography__label">{labelText}</LabelText>}
  </CheckboxContainer>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  defaultChecked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  labelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default Checkbox;
