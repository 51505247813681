import { DateWrapper, IconWrapper, Wrapper } from './styles';
import { displayState, isCourseAccessible } from '../../CoursesTab/helpers';
import { GraduationCap } from '../../../../shared/icons/GraduationCap';
import PropTypes from 'prop-types';
import React from 'react';

const CourseInfo = ({
  state = '',
  date = '',
  isUnderProgram = false,
  isSelected = false,
}) => {
  const isAccessible = isCourseAccessible(state);

  return (
    <Wrapper
      isUnderProgram={isUnderProgram}
      isSelected={isSelected}
      isAccessible={isAccessible}
    >
      <p className='ds-typography__body'>{displayState(state)}</p>
      {date && (
        <DateWrapper isSelected={isSelected} isAccessible={isAccessible}>
          <IconWrapper>
            <GraduationCap
              isInaccessible={!isAccessible}
              isHighlight={isSelected}
            />
          </IconWrapper>
          <span className='ds-typography__body'>{date}</span>
        </DateWrapper>
      )}
    </Wrapper>
  );
};

CourseInfo.propTypes = {
  date: PropTypes.string,
  isSelected: PropTypes.bool,
  isUnderProgram: PropTypes.bool,
  state: PropTypes.string,
};

export default CourseInfo;
