import PropTypes from 'prop-types';
import React from 'react';
import StyledInlineError from './styles/InlineError';

const InlineError = ({ message, dismissError }) => {
  return (
    <StyledInlineError>
      <span onClick={() => dismissError()}>
        <i className="fa fa-times danger "></i>
      </span>
      <p>{message}</p>
    </StyledInlineError>
  );
};

InlineError.propTypes = {
  dismissError: PropTypes.func,
  message: PropTypes.string,
};

export default InlineError;
