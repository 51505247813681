import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import PropTypes from 'prop-types';
import Question from '../Question';
import React from 'react';

const Category = ({
  category,
  openQuestionInsights,
}) => {
  return (
    <AccordionItem uuid={category.category.replace(/ /g, '')}>
      <AccordionItemHeading>
        <AccordionItemButton>
          <h5 className="ds-typography__h5">
            {category.category}
          </h5>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {category.questions.map((question, index) => (
          <Question
            key={`${question.question + index}`}
            openQuestionInsights={openQuestionInsights}
            question={question}
          />
        ))}
      </AccordionItemPanel>
    </AccordionItem>
  );
};

Category.propTypes = {
  category: PropTypes.shape({
    category: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        answers: PropTypes.arrayOf(
          PropTypes.shape({
            answer: PropTypes.shape({
              attachment: PropTypes.shape({
                fileIcon: PropTypes.string,
                fileName: PropTypes.string,
                id: PropTypes.number,
                s3Url: PropTypes.string,
              }),
              category: PropTypes.string,
              date: PropTypes.string,
              id: PropTypes.number,
              industry: PropTypes.string,
              notes: PropTypes.string,
              question: PropTypes.string,
              questionInsights: PropTypes.string,
              rating: PropTypes.number,
              shared: PropTypes.bool,
            }),
          }),
        ),
        question: PropTypes.string,
        questionInsights: PropTypes.string,
      }),
    ),
  }),
  openQuestionInsights: PropTypes.func,
};

export default Category;
