import Table from '../../../../shared/styles/EmployerConnectorTableStyles';
import styled from 'styled-components';

const StyledEmployerTable = styled(Table)`
  i {
    color: var(--ds-color__indigo);
  }
`;

StyledEmployerTable.displayName = 'StyledEmployerTable';

export default StyledEmployerTable;
