import styled from 'styled-components';

const JobApplicationDetailsStyles = styled.div`
  height: 500px;
  overflow-y: scroll;
`;

JobApplicationDetailsStyles.displayName = 'JobApplicationDetailsStyles';

export default JobApplicationDetailsStyles;
