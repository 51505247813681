import React, { useEffect } from 'react';
import { getDocuments, uploadDocuments } from '../../actions';
import Documents from '../../components/Documents';
import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeDocument } from './actions';

const DocumentsContainer = ({
  documents,
  formErrors,
  getDocuments,
  loadingButton,
  loadingTab,
  removeDocument,
  uploadDocuments,
}) => {
  useEffect(() => {
    getDocuments();
  }, []);

  return loadingTab ? (
    <LoadingSpinner />
  ) : (
    <Documents
      data={documents}
      formErrors={formErrors}
      loadingButton={loadingButton}
      handleSubmit={uploadDocuments}
      removeDocument={removeDocument}
    />
  );
};

DocumentsContainer.propTypes = {
  documents: PropTypes.objectOf(
    PropTypes.shape({
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              data: PropTypes.string,
              fileName: PropTypes.string,
              fileSize: PropTypes.number,
            }),
          ]),
          key: PropTypes.string,
          label: PropTypes.string,
          required: PropTypes.bool,
        })
      ),
      name: PropTypes.string,
      tooltip: PropTypes.string,
    })
  ),
  formErrors: PropTypes.object,
  getDocuments: PropTypes.func,
  loadingButton: PropTypes.bool,
  loadingTab: PropTypes.bool,
  removeDocument: PropTypes.func,
  uploadDocuments: PropTypes.func,
};

export const mapStateToProps = ({
  instructorProfile: { documents, formErrors, loadingButton, loadingTab },
}) => ({
  documents,
  formErrors,
  loadingButton,
  loadingTab,
});

export const mapDispatchToProps = (dispatch) => ({
  getDocuments: () => dispatch(getDocuments()),
  removeDocument: (key) => dispatch(removeDocument(key)),
  uploadDocuments: (payload) => dispatch(uploadDocuments(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsContainer);
