import React from 'react';
import App from './components';
import { createRoot } from 'react-dom/client';

const DiversitySurveyInfoContainer = document.getElementById(
  'diversity_survey_info'
);

if (DiversitySurveyInfoContainer) {
  const DiversitySurveyInfoContainerRoot = createRoot(
    DiversitySurveyInfoContainer
  );

  DiversitySurveyInfoContainerRoot.render(
    <App
      startDate={DiversitySurveyInfoContainer.dataset.startDate!}
      courseName={DiversitySurveyInfoContainer.dataset.courseName!}
    />
  );
}
