import CourseInfo from './CourseInfo';
import CourseTileWrapper from './styles/CourseTileWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { isCourseAccessible } from '../CoursesTab/helpers';

const CourseTile = ({
  state,
  courseId,
  date,
  name,
  selectedCourse,
  setSelectedCourse,
  isCourseUnderProgram = false,
}) => {
  const isCourseSelected = selectedCourse === courseId;
  const isAccessible = isCourseAccessible(state);

  return (
    <CourseTileWrapper
      isCourseSelected={isCourseSelected}
      isAccessible={isAccessible}
      key={name}
      onClick={() => isAccessible && setSelectedCourse(courseId, state)}
      isCourseUnderProgram={isCourseUnderProgram}
    >
      <h4 className='ds-typography__h4'>{name}</h4>
      {isAccessible && (
        <CourseInfo
          state={state}
          date={date}
          isUnderProgram={isCourseUnderProgram}
          isSelected={isCourseSelected}
        />
      )}
    </CourseTileWrapper>
  );
};

CourseTile.propTypes = {
  courseId: PropTypes.number,
  date: PropTypes.string,
  isCourseUnderProgram: PropTypes.bool,
  name: PropTypes.string.isRequired,
  selectedCourse: PropTypes.number,
  setSelectedCourse: PropTypes.func,
  state: PropTypes.string,
};

export default CourseTile;
