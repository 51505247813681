import { ModalActions } from '../../../../CallsEdit/components/ConfirmationModal/styles';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteConfirmationContent = ({
  handleModalClose,
  handleDelete,
  selectedRequest,
}) => (
  <>
    <i className="ds-icon__info-fill"></i>
    <h3 className="ds-typography__h3">Are you sure?</h3>
    <p className="ds-typography__body">
      If you cancel this action then change your mind, you'll need to submit new
      time off again.
    </p>
    <ModalActions>
      <button className="ds-button__secondary" onClick={handleModalClose}>
        Discard
      </button>
      <button
        className="ds-button__primary"
        onClick={() => handleDelete(selectedRequest)}
      >
        Cancel time off
      </button>
    </ModalActions>
  </>
);

DeleteConfirmationContent.propTypes = {
  handleDelete: PropTypes.func,
  handleModalClose: PropTypes.func,
  selectedRequest: PropTypes.string,
};

export default DeleteConfirmationContent;
