import PropTypes from 'prop-types';
import React from 'react';

const studentListTabOrder = [
  { key: 'regular', value: 'Regular' },
  { key: 'stateSponsored', value: 'State Sponsored' },
  { key: 'assigned', value: 'Assigned' },
];

const Tabs = ({
  activeStudents,
  setStudentsTab,
  studentsCount,
}) => (
  <div className="ds-tabs">
    {studentListTabOrder.map(({ key, value }) =>
      <div
        key={key}
        className={`ds-typography__body--bold ds-tabs__item ${activeStudents === key ? 'active' : ''}`}
        onClick={() => setStudentsTab(key)}
      >
        {value} ({studentsCount[key]})
      </div>,
    )}
  </div>
);

Tabs.propTypes = {
  activeStudents: PropTypes.string,
  setStudentsTab: PropTypes.func.isRequired,
  studentsCount: PropTypes.shape({
    assigned: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    regular: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stateSponsored: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default Tabs;
