import ButtonWithIcon from '../../../shared/components/ButtonWithIcon';
import PropTypes from 'prop-types';
import React from 'react';
import StyledStartButton from './styles/StyledStartButton';
import statusConstants from '../../statusConstant';

const AudioButtons = ({
  mediaRecorder,
  recordingState,
  resetRecording,
  setRecordingState,
  submitRecording,
  onResubmitRecording,
  onDisableAudioIcon,
}) => {
  const stopRecording = () => {
    mediaRecorder.stop();
  };

  const startRecording = () => {
    mediaRecorder.start();
    setRecordingState(statusConstants.RECORDING);
    onDisableAudioIcon();
  };

  const pauseRecording = () => {
    setRecordingState(statusConstants.PAUSED);
    mediaRecorder.pause();
  };

  const resumeRecording = () => {
    setRecordingState(statusConstants.RECORDING);
    mediaRecorder.resume();
  };

  return (
    <div id='buttons'>
      {
        {
          [statusConstants.FINISHED]: (
            <ButtonWithIcon
              onClick={() => resetRecording()}
              icon='repeat'
              type='secondary'
              text='Try again'
            />
          ),
          [statusConstants.INACTIVE]: (
            <StyledStartButton
              onClick={() => startRecording()}
              icon='circle'
              type='primary'
              text='Start Recording'
            />
          ),
          [statusConstants.PAUSED]: (
            <ButtonWithIcon
              onClick={() => resumeRecording()}
              icon='microphone'
              type='secondary'
              text='Resume Recording'
            />
          ),
          [statusConstants.RECORDING]: (
            <ButtonWithIcon
              onClick={() => pauseRecording()}
              icon='pause'
              type='secondary'
              text='Pause'
            />
          ),
          [statusConstants.TIME_ELAPSED]: (
            <ButtonWithIcon
              onClick={() => pauseRecording()}
              icon='pause'
              type='secondary'
              text='Pause'
              className='disabled'
            />
          ),
          [statusConstants.SUBMITTING_RECORDING]: (
            <ButtonWithIcon
              icon='repeat'
              type='secondary'
              text='Try again'
              className='disabled'
            />
          ),
          [statusConstants.ERROR_SAVING]: (
            <ButtonWithIcon
              onClick={() => onResubmitRecording()}
              icon='repeat'
              type='primary'
              text='Try again'
            />
          ),
        }[recordingState]
      }
      {
        {
          [statusConstants.FINISHED]: (
            <ButtonWithIcon
              onClick={() => submitRecording()}
              icon='check'
              type='primary'
              text='Submit and to next question'
            />
          ),
          [statusConstants.PAUSED]: (
            <ButtonWithIcon
              onClick={() => stopRecording()}
              icon='stop'
              type='primary'
              text='Finish Recording'
            />
          ),
          [statusConstants.RECORDING]: (
            <ButtonWithIcon
              onClick={() => stopRecording()}
              icon='stop'
              type='primary'
              text='Finish Recording'
            />
          ),
          [statusConstants.TIME_ELAPSED]: (
            <ButtonWithIcon
              onClick={() => stopRecording()}
              icon='stop'
              type='primary'
              text='Finish Recording'
              className='disabled'
            />
          ),
          [statusConstants.SUBMITTING_RECORDING]: (
            <ButtonWithIcon
              icon='check'
              type='primary'
              text='Submit Recording'
              className='disabled'
            />
          ),
        }[recordingState]
      }
    </div>
  );
};

AudioButtons.propTypes = {
  mediaRecorder: PropTypes.object.isRequired,
  onDisableAudioIcon: PropTypes.func.isRequired,
  onResubmitRecording: PropTypes.func.isRequired,
  recordingState: PropTypes.string.isRequired,
  resetRecording: PropTypes.func.isRequired,
  setRecordingState: PropTypes.func.isRequired,
  submitRecording: PropTypes.func.isRequired,
};

export default AudioButtons;
