import PropTypes from 'prop-types';
import React from 'react';

const Step = ({ stepState, stepCount, onClick }) => {
  return (<>{{
    isActive: <span className="fa-stack icon" onClick={onClick}>
      <i className="fa fa-circle-thin fa-stack-2x"></i>
      <strong className="fa-stack-1x circle-text">{stepCount}</strong>
    </span>,
    isComplete: <i className='fa fa-check-circle fa-2x icon' onClick={onClick}/>,
    isNext: <span className="fa-stack icon" onClick={onClick}>
      <i className="fa fa-circle-thin fa-stack-2x grey"></i>
      <strong className="fa-stack-1x circle-text grey">{stepCount}</strong>
    </span>,
  }[stepState]}
  </>);
};

Step.propTypes = {
  onClick: PropTypes.func,
  stepCount: PropTypes.number.isRequired,
  stepState: PropTypes.string.isRequired,
};

export default Step;
