export const initialState = {
  channelsHaveLoaded: false,
  chatHasLoaded: false,
  filters: null,
  isMessagingPreviewOpen: false,
  isMessagingWindowOpen: false,
  memberChannelId: '',
  sort: { last_message_at: -1 },
  unReadMessageCount: 0,
};

export default function (state = initialState, action) {
  return { ...state, ...reducer(state, action) };
}

const reducer = (state, action) =>
  ({
    'Messaging.CLOSE_MESSAGE_PREVIEW': {
      isMessagingPreviewOpen: false,
    },
    'Messaging.CLOSE_MESSAGING_WINDOW': {
      activeChannel: null,
      isMessagingWindowOpen: false,
    },
    'Messaging.LOADED_CHAT_SUCCESS': {
      chatHasLoaded: true,
      me: action.me,
      unReadMessageCount: action.unReadMessageCount,
    },
    'Messaging.OPEN_MESSAGING_WINDOW': {
      activeChannel: action.cid,
      isMessagingWindowOpen: true,
    },
    'Messaging.QUERIED_CHANNELS_SUCCESS': {
      channelsHaveLoaded: true,
      memberChannelId: action.memberChannelId,
    },
    'Messaging.SET_ACTIVE_CHANNEL': {
      activeChannel: action.cid,
      isMessagingWindowOpen: false,
    },
    'Messaging.TOGGLE_MESSAGE_PREVIEW': {
      isMessagingPreviewOpen: !state.isMessagingPreviewOpen,
    },
    'Messaging.UPDATE_UNREAD_MESSAGE': {
      unReadMessageCount: action.unReadMessageCount,
    },
  })[action.type];
