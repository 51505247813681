import {
  LoadMoreCta,
  SearchInput,
  SearchInputWrapper,
  Wrapper,
} from './styles';
import React, { useCallback, useEffect, useState } from 'react';
import EmptySection from '../EmptySection';
import Loading from '../../../shared/components/Loading';
import Note from '../../../UserProfile/components/Note';
import NotesWrapper from '../../../UserProfile/components/StudentNotesTab/styles/NotesWrapper';
import PropTypes from 'prop-types';
import { constants } from '../../constants';
import { debounce } from 'lodash';
import moment from 'moment';

const Notes = ({
  allNotesCount,
  clearNotesAction,
  fetchNotesAction,
  isFetchingNotes,
  isSelectedCourseStarted,
  notes,
  selectedCourseId,
  totalNotesToLoadCount,
  userId,
}) => {
  if (!isSelectedCourseStarted || selectedCourseId === 0) {
    return <EmptySection message={constants.NOTES_AFTER_COURSE_START} />;
  }

  const [pageNumber, setPageNumber] = useState(1);
  const [isResetNotesList, setIsResetNotesList] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const shouldLoadMoreNotes = totalNotesToLoadCount > notes.length;
  const notesPerPage = 10;

  useEffect(() => {
    clearNotesAction({ shouldResetAllNotesCount: true });

    return () => clearNotesAction({ shouldResetAllNotesCount: true });
  }, [selectedCourseId]);

  useEffect(() => {
    clearNotesAction({ shouldResetAllNotesCount: false });
    fetchNotesAction({
      courseId: selectedCourseId,
      isFetchingAllNotes: true,
      isFetchingMoreNotes: false,
      notesPerPage,
      pageNumber: 1,
      userId,
    });

    setPageNumber(1);
    setSearchQuery('');
  }, [selectedCourseId, isResetNotesList]);

  useEffect(() => {
    if (pageNumber > 1) {
      fetchNotesAction({
        courseId: selectedCourseId,
        isFetchingMoreNotes: true,
        notesPerPage,
        pageNumber,
        searchQuery: searchQuery || undefined,
        userId,
      });
    }
  }, [pageNumber]);

  const fetchNotesForSearchQuery = useCallback(
    debounce((searchQuery) => {
      if (!searchQuery) {
        setIsResetNotesList(!isResetNotesList);
      } else {
        clearNotesAction({ shouldResetAllNotesCount: false });
        fetchNotesAction({
          courseId: selectedCourseId,
          isFetchingMoreNotes: false,
          notesPerPage,
          pageNumber: 1,
          searchQuery,
          userId,
        });

        setPageNumber(1);
      }
    }, 500),
    [selectedCourseId, isResetNotesList]
  );

  const handleOnChange = (event) => {
    const searchQuery = event.currentTarget.value;

    setSearchQuery(searchQuery);
    fetchNotesForSearchQuery(searchQuery);
  };

  const renderEmptySection = () => {
    if (searchQuery && totalNotesToLoadCount === 0) {
      return <EmptySection message={constants.NO_NOTES_FOUND} />;
    } else if (allNotesCount === 0) {
      return <EmptySection message={constants.NO_NOTES} />;
    }
  };

  return (
    <Wrapper>
      {allNotesCount > 0 && (
        <SearchInputWrapper>
          <i className="fa fa-search"></i>
          <SearchInput
            type="text"
            value={searchQuery}
            onChange={handleOnChange}
          />
        </SearchInputWrapper>
      )}
      <NotesWrapper>
        {notes.map((note) => (
          <Note
            key={note.id}
            id={note.id}
            title={note.exerciseTitle}
            createdAt={`About ${moment(note.createdAt).fromNow()}`}
            quote={note.quote}
            text={note.text}
          />
        ))}
      </NotesWrapper>
      <Loading isLoading={isFetchingNotes}>
        {shouldLoadMoreNotes && (
          <LoadMoreCta
            className="ds-typography__body--bold"
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            Load more
            <span>
              <i className="fa fa-angle-down"></i>
            </span>
          </LoadMoreCta>
        )}
        {renderEmptySection()}
      </Loading>
    </Wrapper>
  );
};

Notes.propTypes = {
  allNotesCount: PropTypes.number,
  clearNotesAction: PropTypes.func,
  fetchNotesAction: PropTypes.func,
  isFetchingNotes: PropTypes.bool,
  isSelectedCourseStarted: PropTypes.bool,
  notes: PropTypes.array,
  selectedCourseId: PropTypes.number,
  totalNotesToLoadCount: PropTypes.number,
  userId: PropTypes.number,
};

export default Notes;
