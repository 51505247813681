export const loadedChatSuccess = (me, unReadMessageCount) => ({
  me,
  type: 'Messaging.LOADED_CHAT_SUCCESS',
  unReadMessageCount,
});

export const updateUnReadMessage = (unReadMessageCount) => ({
  type: 'Messaging.UPDATE_UNREAD_MESSAGE',
  unReadMessageCount,
});
