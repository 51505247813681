import { MOBILE_VIEW_MAX_WIDTH } from '../../../SideNavList/constants';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 11;
  background-color: var(--ds-color__petrol);
  bottom: 0;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    fill: white;
    margin-right: 1rem;
  }

  @media (min-width: ${MOBILE_VIEW_MAX_WIDTH + 1}px) {
    display: none;
  }
`;

const Label = styled.div`
  color: white;
`;

export { Wrapper, Label };
