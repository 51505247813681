import { getAllIndustries, getCurrentUser, getIndustry, updateInstructorIndustry } from '../../actions';
import IndustryDropdown from '../../components/CSCHeader/Dropdown';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  careerSupportCenter: {
    user,
    industries,
    userIndustry,
  },
}) => ({
  industries,
  user,
  userIndustry,
});

export const mapDispatchToProps = dispatch => ({
  getAllIndustries: () => dispatch(getAllIndustries()),
  getCurrentUser: () => dispatch(getCurrentUser()),
  getIndustry: () => dispatch(getIndustry()),
  updateInstructorIndustry: (instructorId, industry) => dispatch(updateInstructorIndustry(instructorId, industry)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryDropdown);
