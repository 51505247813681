import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  margin: 1.25rem 2rem;

  svg {
    margin: 0 0.5rem;
  }

  a {
    display: flex;
    align-items: center;
    color: var(--ds-color__indigo);
  }

  .previous-nav-item {
    margin-right: auto;
  }

  .next-nav-item {
    margin-left: auto;
  }
`;

export { Wrapper };
