import styled from 'styled-components';

const UsersList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .profile-photo {
    display: none;
  }

  .chevron-right-icon-container {
    display: none;
  }

  @media (max-width: 960px) {
    justify-content: center;

    .profile-photo-name-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }

    .chevron-right-icon-container {
      position: absolute;
      left: 0;
      border-radius: 2px;
      padding: var(--ds-spacing__s) 0;
      background-color: var(--ds-bg-color__static-secondary-neutral);
      display: inline-block;
      box-shadow: 0px 4px 6px 0px rgba(34, 60, 80, 0.16);

      svg {
        display: flex;
        width: 24px;
        height: 24px;
      }
    }

    .profile-photo {
      width: 32px;
      display: inline-block;
      margin-right: var(--ds-spacing__s);
      border-radius: 50%;
    }
  }

  @media only screen and (max-width: 768px) {
    position: fixed !important;
    top: 40px;
    left: 0;
    right: 0;
    border-radius: 0 !important;
    background-color: var(--ds-bg-color__static-primary) !important;
  }
`;

UsersList.displayName = 'UsersList';

export { UsersList };
