import styled from 'styled-components';

const StyledButton = styled.div`
  position: relative;
  width: 247px;
  margin-bottom: 16px;

  button {
    width: 150px;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
      min-height: 100%;
      opacity: 0;
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
    }

    p {
      display: inline;
      margin: 0 6px;
    }
  }

  .help-text {
    bottom: -24px;
    position: absolute;
  }
`;

StyledButton.displayName = 'UploadButton';

export default StyledButton;
