import PropTypes from 'prop-types';
import React from 'react';

const TermsCheckbox = (props) => {
  const termsRequired =
    props.termsRequired == null ? true : props.termsRequired;

  if (termsRequired) {
    return (
      <div className="cf-form__checkbox--open">
        <input
          type="checkbox"
          className="cf-form__input"
          name={props.name}
          id={props.id}
          onChange={props.onChange}
          defaultChecked={props.agreedToTerms}
          required={props.required}
        />
        <label htmlFor={props.id}>
          I have read and agree to the{' '}
          <a href="/en/terms" target="_blank">
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a href="/en/privacy" target="_blank">
            Privacy Policy
          </a>
        </label>
      </div>
    );
  } else {
    return null;
  }
};

TermsCheckbox.propTypes = {
  agreedToTerms: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  termsRequired: PropTypes.bool,
};

export default TermsCheckbox;
