import { Body, Button, Header } from '@careerfoundry/bubble';
import ModalContent from '../../../InstructorOnboarding/InstructorProfile/components/SubmissonModal/styles';
import React from 'react';
import TheModal from '../../../shared/components/TheModal';

interface SubmissionModalProps {
  handleClose: () => void;
  modalIsOpen: boolean;
}

const SubmissionModal: React.FC<SubmissionModalProps> = ({
  handleClose,
  modalIsOpen,
}) => (
  <TheModal handleClose={handleClose} isModalOpen={modalIsOpen}>
    <ModalContent>
      <Header tag='h1' variant='h1'>
        <i className='ds-icon__thumb-up-2'></i>
      </Header>
      <Header tag='h3' variant='h3'>
        Submitted
      </Header>
      <Body tag='p' variant='large'>
        Thank you for updating your billing and tax details. The changes need to
        be checked by our finance team. You will receive an email confirmation
        once your billing details have been approved.
      </Body>
      <button className='ds-btn__primary' onClick={handleClose}>
        Done
      </button>
    </ModalContent>
  </TheModal>
);

export default SubmissionModal;
