import ApiService from "./ApiService";

class PayersService {

  getPayers() {
    const apiService = new ApiService("/api/v1/admin/organizations/users.json");

    return apiService.makeGetCall().then(result => {
      return result.data.data;
    })
    .catch(error => {
      throw error;
    })
  };
};

export default PayersService;
