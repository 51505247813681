import EmptySection from './EmptySection';
import FeatureDescription from './FeatureDescription';
import PreviewSection from './PreviewSection';
import PropTypes from 'prop-types';
import React from 'react';
import StyledMediaResourcePreview from './styles/StyledMediaResourcePreview';

const MediaResourcePreview = ({ mediaResources }) => (
  <StyledMediaResourcePreview className="tour__media-library">
    <FeatureDescription />
    {!mediaResources || mediaResources.length < 1 ? (
      <EmptySection />
    ) : (
      <PreviewSection mediaResources={mediaResources} />
    )}
  </StyledMediaResourcePreview>
);

MediaResourcePreview.propTypes = {
  mediaResources: PropTypes.array.isRequired,
};

export default MediaResourcePreview;
