import styled from 'styled-components';

const StyledQuestionInsight = styled.div`
  .toggle-section {
    display: flex;
    align-items: center;
    cursor: pointer;

    h5 {
      margin: 0;
      line-height: 1.8;
    }
  }

  i {
    margin-left: 15px;
    margin-bottom: 5px;
    transform: rotate(180deg);
    transform: ${props => (props.open ? 'rotate(0)' : '')};
    transition: all .4s;
  }

  .insight-content {
    overflow: hidden;
    display: ${props => props.open ? 'block' : 'none'};
    -webkit-transition: max-height 0.4s;
    -moz-transition: max-height 0.4s;
    transition: max-height 0.4s;
    margin: 0 0 24px;
    padding: 16px 11px 15px 16px;
    background-color: #f4f5f6;
  }

  @media (max-width: 768px) {
    .toggle-section {
      margin-bottom: 16px;
    }
  }
`;

StyledQuestionInsight.displayName = 'StyledQuestionInsight';

export default StyledQuestionInsight;
