module.exports = (() => {
  const init = () => {
    initSelect2();
    bindFunctions();
  };

  const bindFunctions = () => {
    $('.tooltip-container').on('mouseenter', showToolTip);
  };

  const showToolTip = function () {
    $(this).tooltip({
      container: 'body',
      placement: 'top',
      title: $('.tooltip-text').html(),
    });
    $(this).tooltip('show');
  };

  const initSelect2 = () => $('.select2').select2();

  return {
    init,
  };
})();
