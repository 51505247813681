import {
  PersonalInformationContainer,
  mapStateToProps,
} from '../../../InstructorOnboarding/InstructorProfile/containers/PersonalInformation';
import {
  getPersonalData,
  handleSubmit,
} from '../../../InstructorOnboarding/InstructorProfile/actions';
import { connect } from 'react-redux';
import { updateBillingProfile } from '../../actions';

export const mapDispatchToProps = (dispatch) => ({
  getPersonalData: () => dispatch(getPersonalData()),
  handleSubmit: () => dispatch(handleSubmit()),
  submitBillingProfile: () => dispatch(updateBillingProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInformationContainer);
