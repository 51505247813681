import { constants } from '../../constants';

export const closeEvaluation = () => ({
  type: constants.CLOSE_EVALUATION,
});

export const finishInterview = () => ({
  type: constants.FINISH_INTERVIEW,
});

export const handleCloseModal = () => ({
  type: constants.HANDLE_CLOSE_MODAL,
});

export const startReview = () => ({
  type: constants.START_REVIEW,
});
