import PropTypes from 'prop-types';
import React from 'react';

const ProgressLineTime = ({
  end,
  graduationDate,
  progress,
  start,
}) => {
  const numberOfWeeks = Array.from({ length: progress.totalWeeks }, (_, i) => i);
  const numberOfCompletedWeeks = Array.from({ length: progress.weeksCompleted }, (_, i) => i);

  return (
    <div className="course_progress_field">
      <p className="course_progress_field__label ds-typography__small">{progress.weeksCompleted} / {progress.totalWeeks} Weeks on Course</p>

      <div className="course_progress__wrapper">
        <i className={`fa fa-flag-checkered ${progress.started && 'started'}`} aria-hidden="true"></i>
        {progress.completed
          ? progress.weeksOverTargetCompletion <= 0
            ? <i className="fa fa-check completed" aria-hidden="true" style={{ right: '-8px' }}></i>
            : <>
              <i className="fa fa-check completed" aria-hidden="true" style={{ right: '-8px' }}></i>
              <i className="fa fa-check target_end_date--js" aria-hidden="true" style={{ right: `calc(${progress.weeksOverWhenCompletedPercentage}% - 10px)` }}></i>
            </>
          : <i className="fa fa-check target_end_date--js" aria-hidden="true" style={{ right: `calc(${progress.weeksOverTargetCompletionPercentage}% - 10px)` }}></i>
        }

        <div className="course_progress__tick_marks">
          {progress.completed || (!progress.completed && progress.weeksOverTargetCompletion > 0)
            ? numberOfCompletedWeeks.map((_, i) => <div key={`tick${i}`} className="course_progress__tick_div"></div>)
            : numberOfWeeks.map((_, i) => <div key={`tick${i}`} className="course_progress__tick_div"></div>)}
        </div>

        <div className="course_progress__color_bar">
          <div className={`progress-bar course_progress__color_bar--${progress.lockedOut ? 'canceled-red' : 'time'}`} role="progressbar" aria-valuenow={progress.weeksCompletionPercent} aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress.weeksCompletionPercent}%` }}></div>
        </div>
        {progress.weeksOverTargetCompletion > 0 && !progress.completed &&
            <div className={`course_progress__color_bar--passed ${progress.lockedOut && 'passed-program'}`}>
              <div className="progress-bar" role="progressbar" aria-valuenow={progress.weeksCompletionPercent} aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress.weeksOverTargetCompletionPercentage}%` }}></div>
            </div>}
      </div>

      <div className="course_progress__course-dates-field">
        <div className="course_dates--start_date">{start}</div>
        {progress.completed
          ? progress.weeksOverTargetCompletion === 0
            ? <div className="course_dates--end_date">{graduationDate}</div>
            : <>
              <div className={`course_dates--end_date over_target_completion ${progress.totalWeeks >= 5 && 'hidden'}`} style={{ right: `calc(${progress.weeksOverWhenCompletedPercentage}% - 10px)` }}>{end}</div>
              <div className="course_dates--end_date">{graduationDate}</div>
            </>
          : progress.weeksOverTargetCompletion > 0
            ? <>
              <div className={`course_dates--end_date over_target_completion ${progress.weeksOverTargetCompletionPercentage > 70 && 'hidden'}`} style={{ right: `calc(${progress.weeksOverTargetCompletionPercentage}% - 10px)` }}>{end}</div>
              <div className="course_dates--date-today <%= progress.part_of_program ? '' : 'hidden' %>">Today</div>
            </>
            : <div className="course_dates--end_date">{end}</div>
        }
      </div>
    </div>
  );
};

ProgressLineTime.propTypes = {
  end: PropTypes.string,
  graduationDate: PropTypes.string,
  progress: PropTypes.shape({
    completed: PropTypes.bool,
    exercisesCompleted: PropTypes.number,
    exercisesTotal: PropTypes.number,
    lastActivity: PropTypes.string,
    lockedOut: PropTypes.bool,
    started: PropTypes.bool,
    totalWeeks: PropTypes.number,
    weeksCompleted: PropTypes.number,
    weeksCompletionPercent: PropTypes.number,
    weeksOverTargetCompletion: PropTypes.number,
    weeksOverTargetCompletionPercentage: PropTypes.number,
    weeksOverWhenCompletedPercentage: PropTypes.number,
  }),
  start: PropTypes.string,
};

export default ProgressLineTime;
