import React, { useEffect, useState } from 'react';
import { getSurveyData, getSurveyFormId, postSurveyData } from './actions';
import ConfidenceReviewModal from '../../../CareerSupportCenter/components/MainDashboard/components/ConfidenceReviewModal';
import PropTypes from 'prop-types';
import StyledBanner from './styles/StyledBanner';
import TheModal from '../TheModal';
import Typeform from '../Typeform';

const ConfidenceSurveyBanner = ({ attendeeId, className = '' }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState('form');
  const [formId, setFormId] = useState(null);

  useEffect(() => {
    getSurveyFormId(attendeeId).then((res) => {
      setFormId(res);
      setShowBanner(!!res);
    });
  }, []);

  const handleSubmit = (event) => {
    postSurveyData({
      attendeeId,
      formId,
      responseId: event.data.responseId,
    })
      .then(() => {
        getSurveyData({ formId, responseId: event.data.responseId }).then(
          (data) => {
            const lowConfidenceResponses = data[0].answers.filter(
              (answer) => answer.number <= 2
            );
            if (lowConfidenceResponses.length > 0) {
              setModalType('review');
            } else {
              setTimeout(() => {
                setShowBanner(false);
              }, 1500);
            }
          }
        );
      })
      .catch(() => {
        handleCloseModal();
      });
  };

  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => {
    setModalType('form');
    setModalIsOpen(false);
  };

  const handleCloseReviewModal = () => {
    handleCloseModal();
    setShowBanner(false);
  };

  return (
    showBanner && (
      <>
        <StyledBanner className={className}>
          <h5 className="ds-typography__h5">
            How confident about your job search are you feeling at the moment?
          </h5>
          <div onClick={handleOpenModal}>
            <p className="ds-typography__body">
              Please fill in our short survey
            </p>
            <div className="arrow-wrap">
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </StyledBanner>
        <TheModal isModalOpen={modalIsOpen} handleClose={handleCloseModal}>
          <>
            {
              {
                form: <Typeform formId={formId} handleSubmit={handleSubmit} />,
                review: (
                  <ConfidenceReviewModal
                    onCloseModal={handleCloseReviewModal}
                  />
                ),
              }[modalType]
            }
          </>
        </TheModal>
      </>
    )
  );
};

ConfidenceSurveyBanner.propTypes = {
  attendeeId: PropTypes.number,
  className: PropTypes.string,
};

export default ConfidenceSurveyBanner;
