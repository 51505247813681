import PropTypes from 'prop-types';
import React from 'react';
import StyledCardText from '../styles/StyledCardText';

const CardText = ({ paragraph, link }) => (
  <StyledCardText>
    <p className="ds-typography__body text-left">{ paragraph }&nbsp;
      <span onClick={() => link.onClick()} className="ds-typography__body ds-typography__anchor">{ link.text }</span>
    </p>
  </StyledCardText>
);

CardText.propTypes = {
  link: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
  paragraph: PropTypes.string.isRequired,
};

export default CardText;
