import PropTypes from 'prop-types';
import React from 'react';
import { Wrapper } from './styles';
import { redirectTo } from '../../../../utils/redirectTo';

const Note = ({ id, title, createdAt, quote, text }) => (
  <Wrapper>
    <p className='ds-typography__lead--bold'>{title}</p>
    <p>{createdAt}</p>
    <div>
      <p className='ds-typography__body--bold'>Quote:</p>
      <mark
        className='ds-typography__body'
        onClick={() => redirectTo({ url: `/en/notes/${id}` })}
      >
        {quote}
      </mark>
    </div>
    <div>
      <p className='ds-typography__body--bold'>Your note:</p>
      <p className='ds-typography__body'>{text}</p>
    </div>
  </Wrapper>
);

Note.propTypes = {
  createdAt: PropTypes.string,
  id: PropTypes.number,
  quote: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default Note;
