import React from 'react';
import { StatusContainer, SectionLink, TabName } from './styles';
import { renderStatus } from '../../helpers';

export interface TabProps {
  selectedSubTab: string;
  setSelectedSubTab: (tab: string) => void;
  subTabKey: string;
  subTabValue: {
    name: string;
    status: string;
    active: boolean;
  };
  isUnLocked: boolean;
}

const SubSection: React.FC<TabProps> = ({
  selectedSubTab,
  setSelectedSubTab,
  subTabKey,
  subTabValue,
  isUnLocked,
}) => {
  return (
    <SectionLink
      active={false}
      className='steps-section-link'
      isUnLocked={isUnLocked}
      onClick={() => {
        subTabValue.active === true ? setSelectedSubTab(subTabKey) : null;
      }}
    >
      <li>
        <TabName
          active={selectedSubTab === subTabKey}
          isUnLocked={isUnLocked}
          className='ds-text__body--medium'
        >
          Step {subTabValue.name}
        </TabName>
      </li>
      <StatusContainer>
        {renderStatus(subTabValue.status, subTabValue.active)}
      </StatusContainer>
    </SectionLink>
  );
};

export default SubSection;
