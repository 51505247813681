import React from 'react';
import { Service, Services } from '../../interfaces';
import { TableWrapper } from './styles';
import moment from 'moment';
import { snakeCaseToHumanReadable } from '../../../../../utils/snakeCaseToHumanReadable';
import { stringTruncate } from '../../../../../utils/stringTruncate';
import EmptyState from '../../../../shared/components/EmptyTableState';

interface TableProps {
  handleEdit: (id: string) => void;
  services: Services;
}

const Table: React.FC<TableProps> = ({ services, handleEdit }) => {
  const proofLink = (service: Service) => {
    const link = service.proofFile.data
      ? service.proofFile.fileName
      : service.proofLink;
    return stringTruncate(link, 25);
  };

  return (
    <TableWrapper>
      <table className='ds-table'>
        <thead>
          <tr className='ds-table__tr'>
            <th className='ds-table__th fixed-width'>Student</th>
            <th className='ds-table__th fixed-width'>Service</th>
            <th className='ds-table__th'>Fee</th>
            <th className='ds-table__th'>Date</th>
            <th className='ds-table__th'>Proof</th>
            <th className='ds-table__th'>Comment</th>
            <th className='ds-table__th'>Status</th>
            <th className='ds-table__th'></th>
          </tr>
        </thead>
        <tbody>
          {services.map((service: Service) => {
            return (
              <tr className='ds-table__tr' key={service.id}>
                <td className='ds-table__td'>{service.studentName}</td>
                <td className='ds-table__td'>
                  {snakeCaseToHumanReadable(service.serviceType)}
                </td>
                <td className='ds-table__td'>{service.fee}</td>
                <td className='ds-table__td'>
                  {moment(service.serviceDate).format('DD.MM.YYYY')}
                </td>
                <td className='ds-table__td proof-wrapper'>
                  <i className='ds-icon__link'></i>
                  <a
                    href={
                      service.proofFile.data
                        ? service.proofFile.data
                        : service.proofLink
                    }
                    className='proof-link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {proofLink(service)}
                  </a>
                </td>
                <td className='ds-table__td'>{service.comment}</td>
                <td
                  className={`ds-table__td state ${service.state}`}
                  onClick={() =>
                    service.state === 'rejected' && handleEdit(service.id)
                  }
                >
                  {service.state}
                </td>
                <td className='ds-table__td'>
                  {service.state !== 'approved' && (
                    <i
                      className='ds-icon__edit edit-icon'
                      onClick={() => handleEdit(service.id)}
                    ></i>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {!services.length && (
        <EmptyState hasButton={false} heading='No services yet' />
      )}
    </TableWrapper>
  );
};

export default Table;
