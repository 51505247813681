import { getInterviewAnswers, openQuestionInsights } from '../../actions';
import InterviewAnswersTab from '../../components/InterviewAnswersTab';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  userProfile: { userId, interviewAnswers, isMentorPage, isInterviewLoading },
}) => ({
  interviewAnswers,
  isInterviewLoading,
  isMentorPage,
  userId,
});

export const mapDispatchToProps = (dispatch) => ({
  getInterviewAnswers: (studentId) => dispatch(getInterviewAnswers(studentId)),
  openQuestionInsights: (question, insights) =>
    dispatch(openQuestionInsights(question, insights)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewAnswersTab);
