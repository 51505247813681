import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
`;

export const TeamsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const TeamsItem = styled.div`
  width: 290px;
  height: 472px;
  background: white;
  margin: 0px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const ItemContainer = styled.div`
  padding: 24px 24px 0px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const ItemImage = styled.div`
  width: 80px;
  height: 80px;
  img {
    width: 80px;
    height: 80px;
  }
`;

export const ItemTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: var(--ds-color__indigo);
  margin-top: 8px;
`;

export const ItemDescription = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--ds-color__indigo);
  margin-top: 8px;
`;

export const ItemBulletpoints = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--ds-color__indigo);
  margin-top: 2px;
  padding: 0px 0px 0px 20px;
  ul {
    padding: 0;
    margin: 0;
    li {
      margin: 4px;
    }
  }
`;

export const ItemBottom = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${(props) => props.backgroundColor};
`;
