import styled from 'styled-components';

const StyledProfileImage = styled.div`
  .img-circle {
    width: 64px;
    height: 64px;
    margin-right: 10px;
  }
`;

StyledProfileImage.displayName = 'StyledProfileImage';

export default StyledProfileImage;
