/* eslint-disable sort-keys */
const resources = {
  'Communication (writing)': [
    { link: 'https://grammarly.com/', text: 'Grammarly' },
    { link: 'http://www.hemingwayapp.com/', text: 'Hemmingway App' },
  ],
  'Communication (calls or chat': [
    { link: 'https://www.nextiva.com/', text: 'Nextiva' },
    { link: 'https://slack.com/', text: 'Slack' },
  ],
  'Project management': [
    { link: 'https://asana.com/', text: 'Asana' },
    { link: 'https://www.notion.com/', text: 'Notion' },
    { link: 'https://trello.com/', text: 'Trello' },
    { link: 'https://monday.com/', text: 'Monday' },
    { link: 'https://miro.com/', text: 'Miro' },
    { link: 'https://hive.com/', text: 'Hive' },
  ],
  Meeting: [
    { link: 'https://slack.com/', text: 'Slack' },
    { link: 'https://teams.microsoft.com/', text: 'Microsoft Teams' },
    { link: 'https://meet.google.com/', text: 'Google Meet' },
    { link: 'https://zoom.us/', text: 'Zoom' },
    { link: 'https://www.skype.com/', text: 'Skype' },
  ],
  'Productivity tools': [
    { link: 'https://www.invisionapp.com/', text: 'InVision' },
    { link: 'https://github.com/', text: 'GitHub' },
    { link: 'https://workspace.google.com/', text: 'Google Workspace' },
    { link: 'https://paperform.co/', text: 'Paperform' },
  ],
};

export default resources;
