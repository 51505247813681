import { customBuilder, defaultBuilder, submittedInPastWeek } from './helpers';

export default function (state = {}, action) {
  switch (action.type) {
    case 'WeeklySubmissionGoal.FETCH_DATA': {
      const {
        attendeeId,
        data: {
          afa,
          current,
          custom,
          current: {
            begin_of_week: rawWeekStart,
            max_goal: maxGoal,
            index,
            source,
            submissions,
            weeks_left: weeksLeft,
            wsg_id: wsgId,
          },
        },
        exercises,
      } = action;

      const beginOfWeek = new Date(rawWeekStart);
      const startDate = new Date(current.start_date);
      const targetEndDate = new Date(current.target_end_date);

      const exercisesRemaining = Object.keys(exercises).filter(
        (k) => !submittedInPastWeek(exercises[k].submission_date, beginOfWeek)
      ).length;
      const goal = Math.min(
        action.data[source][index].goal,
        exercisesRemaining
      );

      const { present, past } =
        weeksLeft <= 1
          ? defaultBuilder(exercises, 1, beginOfWeek)
          : source === 'default'
          ? defaultBuilder(exercises, weeksLeft, beginOfWeek)
          : customBuilder(exercises, goal, beginOfWeek);

      return {
        afa,
        attendeeId,
        beginOfWeek,
        custom,
        dataLoaded: true,
        default: action.data.default,
        exercises,
        goal,
        maxGoal,
        order: present,
        past,
        source,
        startDate,
        submissions,
        targetEndDate,
        weeksLeft,
        wsgId,
      };
    }

    case 'WeeklySubmissionGoal.DECREASE_GOAL_SUCCESS':
    case 'WeeklySubmissionGoal.INCREASE_GOAL_SUCCESS':
      return {
        ...state,
        goal: action.goal,
        modalIsOpen: state.default
          ? action.goal < state.default[0].goal
          : false,
        order: customBuilder(state.exercises, action.goal, state.beginOfWeek)
          .present,
        source: 'custom',
      };

    case 'WeeklySubmissionGoal.CLOSE_DEFAULT_GOAL_REMINDER_MODAL':
      return {
        ...state,
        modalIsOpen: false,
      };

    default:
      return state;
  }
}
