import PropTypes from 'prop-types';
import React from 'react';
import { redirectTo } from '../../../../../utils/redirectTo';
import { stringTruncate } from '../../../../../utils/stringTruncate';

const ChannelButton = ({
  channel,
  displayImage,
  displayTitle,
  latestMessage,
  openMessagingWindow,
  setActiveChannel,
  toggleMsgPreview,
}) => {
  const handleClick = () => {
    if (channel.data.chatbot) {
      redirectTo({ url: `/en/messaging?id=${channel.id}` });
    } else {
      setActiveChannel(channel);
      openMessagingWindow(channel);
      toggleMsgPreview();
    }
  };
  return (
    <div
      onClick={handleClick}
      className={`str-chat__channel-preview ${
        channel.countUnread() && 'str-chat__channel-preview__unread'
      }`}
    >
      <button data-testid='channel-preview-button'>
        <div
          data-testid='avatar'
          className='str-chat__avatar str-chat__avatar--circle'
        >
          <img
            data-testid='avatar-img'
            src={displayImage}
            alt=''
            className='str-chat__avatar-image str-chat__avatar-image--loaded'
          />
        </div>
        <div className='str-chat__channel-preview-info'>
          <span className='str-chat__channel-preview-title'>
            {displayTitle}
          </span>
          <span className='str-chat__channel-preview-last-message'>
            {latestMessage.props &&
              latestMessage.props.source &&
              stringTruncate(latestMessage.props.source, 25)}
          </span>
        </div>
      </button>
    </div>
  );
};

ChannelButton.propTypes = {
  channel: PropTypes.object,
  displayImage: PropTypes.string,
  displayTitle: PropTypes.string,
  latestMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  openMessagingWindow: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  setActiveChannel: PropTypes.func,
  toggleMsgPreview: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default ChannelButton;
