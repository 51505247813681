import React, { useEffect, useState } from 'react';
import confetti from 'canvas-confetti';
import { getCelebrationMessage } from './helpers';
import { get } from 'lodash';
import { Modal } from './style';
import { Body, Header } from '@careerfoundry/bubble';

/**
 * ==================================================
 * Enums
 * ==================================================
 */
enum Page {
  courseDashboard = 'courseDashboard',
  exercisePage = 'exercisePage',
}

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */
interface Props {
  page: Page;
}

/**
 * ==================================================
 * <CelebrationModal />
 * ==================================================
 */
const CelebrationModal: React.FC<Props> = ({ page }) => {
  const [message, setMessage] = useState<string | null>(null);
  const pagePathname = window.location.pathname;

  const getCourseId = () => {
    if (page === Page.courseDashboard) {
      return (pagePathname.match(/dashboards\/(.*)\/course-home/) || [])[1];
    }

    return (pagePathname.match(/course\/(.*)\/exercise/) || [])[1];
  };

  const fetchMessage = async () => {
    const celebrationMessageData = await getCelebrationMessage(getCourseId());

    setMessage(get(celebrationMessageData, 'data.data.message', ''));
  };

  useEffect(() => {
    try {
      fetchMessage();
    } catch (error) {
      /**
       * TODO: Handle it in a better way (once we have a way to handle
       * API errors; like having platform level notification popups)
       */
    }
  }, []);

  if (message) {
    confetti({
      particleCount: 300,
      origin: { x: 0.5, y: 0.5 },
      ticks: 1000,
      angle: 90,
      spread: 90,
      startVelocity: 100,
    });
  }

  return (
    <Modal isModalOpen={!!message} handleClose={() => setMessage(null)}>
      <i className='fa-kit fa-icon-thumbup-extralarge'></i>
      <Header tag='h4' variant='h4'>
        Congratulations!
      </Header>
      <Body tag='p' variant='medium'>
        {message}
      </Body>
    </Modal>
  );
};

/**
 * ==================================================
 * Exports
 * ==================================================
 */
export default CelebrationModal;

export { Page };
