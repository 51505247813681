import styled from 'styled-components';

const StyledAttachmentsSection = styled.div`
  width: 100%;
  padding-left: 25%;
`;

StyledAttachmentsSection.displayName = 'StyledAttachmentsSection';

export default StyledAttachmentsSection;
