import {
  HeadDescription,
  HeadNumber,
  HeadSubtitle,
  HeadText,
  HeadTitle,
  StyledHead,
} from './styles.js';
import PropTypes from 'prop-types';
import React from 'react';

const Head = ({ data }) => (
  <StyledHead>
    <HeadSubtitle className='ds-typography__body' role='headSubtitle'>
      {data.title}
    </HeadSubtitle>
    <HeadTitle>
      <HeadNumber role='headPageIndex'>{data.pageIndex}</HeadNumber>
      <HeadText className='ds-typography__uber' role='headMain'>
        {data.main}
      </HeadText>
    </HeadTitle>
    <HeadDescription className='ds-typography__body' role='headDescription'>
      {data.description}
    </HeadDescription>
  </StyledHead>
);

Head.propTypes = {
  data: PropTypes.object,
};

export default Head;
