import {
  MainWrapper,
  PNav,
  PNavItem,
  PersonalizedBox,
  PersonalizedBoxFooter,
} from './styles';
import {
  NavButton,
  NavButtonsWrapper,
} from '../../components/NavButtons/styles';
import React, { useEffect, useState } from 'react';
import {
  answerOnQuestion,
  onClickBack,
  onClickNext,
  resetActiveIndex,
} from '../../utils/helpers';
import Head from '../../components/Head';
import PropTypes from 'prop-types';
import Question from '../../components/Question';

const PersonalizedSupportPage = ({
  data,
  navigateTo,
  onAnswerQuestion,
  questions,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [userAnswerIndex, setUserAnswerIndex] = useState(null);
  const nextButtonEnabled =
    (questions[activeIndex] && questions[activeIndex].answered) ||
    typeof userAnswerIndex === 'number';
  useEffect(() => {
    questions.length && resetActiveIndex(questions, setActiveIndex);
  }, []);

  const onClickNavButton = (type) => {
    type === 'next'
      ? onClickNext(activeIndex, questions, navigateTo, setActiveIndex, () => {
          questions[activeIndex].answers[userAnswerIndex] &&
            answerOnQuestion(
              questions,
              activeIndex,
              userAnswerIndex,
              onAnswerQuestion
            );
          setUserAnswerIndex(null);
        })
      : onClickBack(activeIndex, navigateTo, setActiveIndex);
  };

  return (
    <MainWrapper>
      <Head data={data.head} />
      <PersonalizedBox className='ds-shadow__4'>
        {questions[activeIndex] && (
          <Question
            data={questions[activeIndex]}
            onClickAnswer={setUserAnswerIndex}
            userAnswerIndex={userAnswerIndex}
          />
        )}
        <PersonalizedBoxFooter>
          <PNav>
            {questions.map((question, index) => (
              <PNavItem
                key={question.id}
                role='navItem'
                active={index === activeIndex}
              ></PNavItem>
            ))}
          </PNav>
          <NavButtonsWrapper>
            <NavButton
              className='ds-button__secondary'
              onClick={() => onClickNavButton('back')}
            >
              Back
            </NavButton>
            <NavButton
              className='ds-button__primary'
              disabled={!nextButtonEnabled}
              onClick={() =>
                nextButtonEnabled ? onClickNavButton('next') : null
              }
            >
              Next
            </NavButton>
          </NavButtonsWrapper>
        </PersonalizedBoxFooter>
      </PersonalizedBox>
    </MainWrapper>
  );
};

PersonalizedSupportPage.propTypes = {
  data: PropTypes.object,
  navigateTo: PropTypes.func,
  onAnswerQuestion: PropTypes.func,
  questions: PropTypes.array,
};

export default PersonalizedSupportPage;
