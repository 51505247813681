import { SectionHeading, SurveyLink } from '../styles';
import PropTypes from 'prop-types';
import React from 'react';

const Survey = ({ score, link }) => (
  <>
    <SectionHeading className='ds-typography__h5'>Survey</SectionHeading>
    {score ? (
      <p className='ds-typography__body'>Survey score: {score}</p>
    ) : (
      <SurveyLink
        href={link}
        target='_blank'
        rel='noreferrer'
        className='ds-button__secondary'
      >
        Check results
      </SurveyLink>
    )}
  </>
);

Survey.propTypes = {
  link: PropTypes.string,
  score: PropTypes.number,
};

export default Survey;
