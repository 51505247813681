import { DEFAULT_MODAL_STATE, FORM_DEFAULT, constants } from './constants';
import { formatNoteData } from './helper';

export const DEFAULT_PAGINATION_STATE = {
  currentPage: 1,
  perPage: 0,
  totalEntries: 0,
  totalPages: 0,
};

export const initialState = {
  calendarEntries: [],
  calendarEntriesPagination: { ...DEFAULT_PAGINATION_STATE },
  callNotes: [],
  callNotesPagination: { ...DEFAULT_PAGINATION_STATE },
  currentCalendarEntry: {},
  currentCallId: null,
  currentEditNote: {},
  currentEditNoteId: null,
  errorMessage: null,
  filterParams: {},
  isFetchingNote: false,
  isOffPlatformCall: false,
  mentees: [],
  mentorNoteForm: FORM_DEFAULT,
  modalAction: '',
  modalIsOpen: false,
  modalType: 'callQuestion',
  prevModalType: '',
  reloadCalendarEntries: false,
  showFlash: false,
  submitLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.SHOW_ADD_NOTES_MODAL: {
      const { callId, canSubmitNote } = action.data;
      return {
        ...state,
        canSubmitNote,
        currentCalendarEntry:
          state.calendarEntries.find((entry) => entry.id === callId) || {},
        currentCallId: callId,
        modalAction: 'addNote',
        modalIsOpen: true,
        showFlash: false,
      };
    }

    case constants.SHOW_CALL_CANCELED_MODAL: {
      return {
        ...state,
        modalAction: 'callCanceled',
        modalIsOpen: true,
        reloadCalendarEntries: true,
        showFlash: false,
      };
    }

    case constants.HANDLE_CLOSE_MODAL: {
      if (state.modalAction === 'cancel') {
        return {
          ...state,
          ...DEFAULT_MODAL_STATE,
        };
      } else if (state.currentCallId !== null) {
        return {
          ...state,
          modalAction: 'cancel',
          prevModalType: state.modalAction,
        };
      } else {
        return {
          ...state,
          ...DEFAULT_MODAL_STATE,
        };
      }
    }

    case constants.HANDLE_CANCEL_MODAL: {
      return {
        ...state,
        modalAction: state.prevModalType,
        prevModalType: '',
      };
    }

    case constants.CREATE_OFF_PLATFORM_CALL_SUCCESS: {
      return {
        ...state,
        currentCalendarEntry: {},
        errorMessage: null,
        flashMessage: 'Off-platform call added',
        isOffPlatformCall: false,
        modalAction: 'offPlatformSuccess',
        showFlash: true,
        submitLoading: false,
      };
    }

    case constants.CREATE_OFF_PLATFORM_CALL_FAILURE: {
      return {
        ...state,
        errorMessage: action.error,
        submitLoading: false,
      };
    }

    case constants.STORE_OFF_PLATFORM_CALL: {
      return {
        ...state,
        canSubmitNote: true,
        currentCalendarEntry: action.data,
        modalAction: 'addNote',
        modalType: 'callQuestion',
      };
    }

    case constants.SET_MODAL_TYPE: {
      const { modalType } = action;
      return {
        ...state,
        modalType,
      };
    }

    case constants.SET_MODAL_ACTION: {
      const { modalAction } = action;
      return {
        ...state,
        modalAction,
        prevModalType: state.modalAction,
      };
    }

    case constants.OPEN_OFF_PLATFORM_CALL_MODAL: {
      return {
        ...state,
        isOffPlatformCall: true,
        modalAction: 'offPlatformCall',
        modalIsOpen: true,
        modalType: 'registerCall',
      };
    }

    case constants.GET_MENTEES_SUCCESS: {
      return {
        ...state,
        mentees: action.data,
      };
    }

    case constants.SET_NOTE_FORM_FIELDS: {
      const { id, data } = action.data;
      return {
        ...state,
        mentorNoteForm: {
          ...state.mentorNoteForm,
          [id]: data,
        },
      };
    }

    case constants.ADD_NOTES_LOADING: {
      const { loading } = action;
      return {
        ...state,
        submitLoading: loading,
      };
    }

    case constants.UPDATE_CALENDAR_ENTRY_SUCCESS: {
      return {
        ...state,
        ...DEFAULT_MODAL_STATE,
        flashMessage: 'Call information updated successfully',
        showFlash: true,
      };
    }

    case constants.ADD_NOTES_SUCCESS:
    case constants.EDIT_NOTES_SUCCESS: {
      const { message } = action;
      return {
        ...state,
        ...DEFAULT_MODAL_STATE,
        flashMessage: message || 'Note Added Successfully',
        showFlash: true,
      };
    }

    case constants.GET_CALENDAR_ENTRIES_SUCCESS: {
      const { calendarEntries, pagination } = action.data;
      return {
        ...state,
        calendarEntries,
        calendarEntriesPagination: pagination,
        reloadCalendarEntries: false,
      };
    }

    case constants.GET_CALENDAR_ENTRIES_FAILURE: {
      return {
        ...state,
        calendarEntries: [],
      };
    }

    case constants.GET_NOTES_SUCCESS: {
      const { callNotes, pagination } = action.data;
      const newCallNotes = callNotes.map((callNote) => {
        const studentProfile = callNote?.student;
        return {
          ...callNote,
          name: studentProfile?.name,
          profileUrl: `/en/users/${studentProfile?.slug}/profile`,
          status: studentProfile?.statusLabel,
        };
      });

      return {
        ...state,
        callNotes: newCallNotes,
        callNotesPagination: pagination,
      };
    }

    case constants.FETCHING_NOTES: {
      const { isLoading, noteId, type } = action.data;
      if (type === 'edit') {
        return {
          ...state,
          currentCallId: noteId,
          currentViewId: null,
          isFetchingNote: isLoading,
        };
      } else {
        return {
          ...state,
          currentCallId: null,
          currentViewId: noteId,
          isFetchingNote: isLoading,
        };
      }
    }

    case constants.SHOW_EDIT_NOTES_MODAL: {
      const { noteId, note } = action.data;
      const formattedNote = formatNoteData(note);
      return {
        ...state,
        currentCalendarEntry: { ...formattedNote },
        currentCallId: noteId,
        currentViewId: null,
        mentorNoteForm: { ...formattedNote },
        modalAction: 'editNotes',
        modalIsOpen: true,
        modalType: '',
        showFlash: false,
      };
    }

    case constants.SHOW_VIEW_NOTES_MODAL: {
      const { noteId, note } = action.data;
      const formattedNote = formatNoteData(note);
      return {
        ...state,
        currentCalendarEntry: { ...formattedNote },
        currentCallId: null,
        currentViewId: noteId,
        mentorNoteForm: { ...formattedNote },
        modalAction: 'viewNotes',
        modalIsOpen: true,
        modalType: '',
        showFlash: false,
      };
    }

    case constants.SET_NOTES_FILTER: {
      const { name, value } = action.data;
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          [name]: value,
        },
      };
    }

    default:
      return state;
  }
}
