import PropTypes from 'prop-types';
import React from 'react';
import TheModal from '../../../../shared/components/TheModal';
import { Wrapper } from './styles';

const DefaultGoalReminderModal = ({
  closeModal,
  defaultGoal,
  modalIsOpen = false,
}) => (
  <TheModal handleClose={closeModal} isModalOpen={modalIsOpen}>
    <Wrapper>
      <h3 className='ds-typography__h3 ds-color__indigo'>
        <i className='ds-icon__flag' />
      </h3>
      <h3 className='ds-typography__h3 ds-color__indigo'>Remember...</h3>
      <p className='ds-typography__body ds-color__indigo'>
        You should aim to submit {defaultGoal} tasks per week on average in
        order to meet your course target end date.
      </p>
      <button onClick={closeModal} className='ds-button__primary'>
        Got it!
      </button>
    </Wrapper>
  </TheModal>
);

DefaultGoalReminderModal.propTypes = {
  closeModal: PropTypes.func,
  defaultGoal: PropTypes.number,
  modalIsOpen: PropTypes.bool,
};

export default DefaultGoalReminderModal;
