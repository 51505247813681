import React from 'react';
import { useHistory } from 'react-router-dom';

const DeletionSuccess = () => {
  const history = useHistory();
  const goBack = () => history.push('/en/admin/instructors/candidates/');

  return (
    <>
      <i className="ds-icon__check-circle-line"></i>
      <h3 className="ds-typography__h3">Deleted</h3>
      <p className="ds-typography__lead">You have deleted the instructor.</p>
      <button className="ds-button__primary" onClick={goBack}>
        Got it
      </button>
    </>
  );
};

export default DeletionSuccess;
