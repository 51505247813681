import styled from 'styled-components';

const TooltipWrapper = styled.div`
  display: inline-block;

  i {
    margin-left: 13px;

    &:hover {
      & + div {
        visibility: visible;
        opacity: 1;
        transition: visibility 0.3s linear, opacity 0.3s linear;
      }
    }
  }
`;

TooltipWrapper.displayName = 'TooltipWrapper';

const Tooltip = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  right: -210px;
  background-color: var(--ds-color__indigo);
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bolder;
  width: ${(props) => (props.country === 'DE' ? '329px' : '192px;')};
  word-break: break-word;
  z-index: 10;
`;

Tooltip.displayName = 'Tooltip';

const Header = styled.header`
  position: relative;
  margin-bottom: 16px;

  h5 {
    display: inline-block;
  }

  i {
    color: var(--ds-color__petrol--medium);
  }

  .cf-form__checkbox--open {
    display: inline-block;
    float: right;
    padding-top: 5px;

    label {
      color: rgba(34, 60, 80, 0.75);
    }

    input {
      width: 14.5px;
      height: 14.5px;
      border-radius: 3px;
      border: 1px solid rgba(34, 60, 80, 0.75);
      margin: 4px 8px 0 0;

      &:checked:before {
        width: 5px;
        height: 9px;
        margin-left: 3px;
        margin-top: 1px;
      }
    }
  }
`;

Header.displayName = 'SectionHeader';

export { Header, Tooltip, TooltipWrapper };
