import { Body, Button, Header } from '@careerfoundry/bubble';
import { ApprovalModalContent, ButtonWrapper } from './styles';
import React, { useState } from 'react';

interface ApprovalModalBSGProps {
  handleUpdateAdmission: (params: any) => void;
  handleClose: () => void;
}

const ApprovalModalBSG: React.FC<ApprovalModalBSGProps> = ({
  handleUpdateAdmission,
  handleClose,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const params = {
    afa_voucher: {
      state: 'done',
      type: selectedOption,
    },
  };

  return (
    <ApprovalModalContent>
      <Header tag='h4' variant='h4'>
        Please choose an option
      </Header>
      <div className='ds-radio'>
        <input
          type='radio'
          id='radio-paper'
          name='radio'
          onClick={() => setSelectedOption('physical')}
        />
        <label
          className='radio-label ds-typography__body'
          htmlFor='radio-paper'
        >
          Paper Bildungsgutschein submitted
        </label>
        <input
          type='radio'
          id='radio-digital'
          name='radio'
          onClick={() => setSelectedOption('digital')}
        />
        <label
          className='radio-label ds-typography__body'
          htmlFor='radio-digital'
        >
          Digital Bildungsgutschein submitted
        </label>
      </div>
      <ButtonWrapper>
        <Button
          label='Cancel'
          onClick={handleClose}
          size='medium'
          variant='secondary'
        />
        <Button
          label='Save'
          onClick={() => handleUpdateAdmission(params)}
          size='medium'
          variant='primary'
        />
      </ButtonWrapper>
    </ApprovalModalContent>
  );
};

export default ApprovalModalBSG;
