import styled from 'styled-components';

interface NavProps {
  isNextNavItemPresent: boolean;
}

const Nav = styled.nav<NavProps>`
  height: 4rem;
  width: calc(100% - 5rem); // compensate for the side nav bar
  z-index: 10; // top nav bar is 11 and we need to show dropdown over this component
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ds-color__grey--light_300);
  position: fixed;

  .page-navigator {
    display: flex;
    align-items: center;
    margin: 1.25rem 2rem;

    svg {
      margin: 0 0.5rem;
    }

    a {
      border: none;
      color: var(--ds-color__indigo);

      :hover {
        font-family: var(--ds-font__tradegothic--bold);
      }
    }
  }

  .exercise-navigator {
    margin-right: 2rem;

    .previous-nav-item {
      margin-right: ${(props) => (props.isNextNavItemPresent ? '2.5rem' : 0)};
    }
  }

  @media (max-width: 1025px) {
    position: static;
    width: 100%;
  }

  @media (max-width: 1024px) {
    .exercise-navigator {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin-top: 2.5rem; // compensate for the top nav bar
    background-color: initial;

    .page-navigator {
      margin-left: 1rem;

      a:first-of-type,
      svg:last-of-type,
      .current-exercise-info {
        display: none;
      }

      > .chevron-right-icon {
        transform: rotate(180deg);
        transition: transform 0.5s;
      }
    }
  }
`;

export { Nav };
