import { InputWrapper, StyledInput, StyledSelect } from '../App/styles';
import React, { useEffect, useState } from 'react';
import BankTransfer from './BankTransfer';
import { Field } from 'formik';
import Paypal from './Paypal';
import PropTypes from 'prop-types';

const paymentOptions = [
  {
    name: 'Bank Transfer',
    value: 'bank_transfer',
  },
  {
    name: 'PayPal',
    value: 'paypal',
  },
];

const PayoutFields = ({ country, methodType }) => {
  const [paymentMethodState, setPaymentMethodState] = useState(methodType);
  useEffect(() => {
    setPaymentMethodState(methodType);
  }, [methodType]);

  return (
    <>
      <h4 className='ds-typography__h4'>Payout Information</h4>
      <InputWrapper>
        <StyledInput>
          <Field
            type='email'
            name='payeeEmail'
            placeholder='Payee email'
            required={true}
          />
        </StyledInput>
        <StyledSelect>
          <Field
            as='select'
            name='methodType'
            value={paymentMethodState || 'Select your payment method'}
            required={true}
            onChange={(event) => setPaymentMethodState(event.target.value)}
          >
            <option value=''>Payment method*</option>
            {paymentOptions.map((paymentOption) => (
              <option key={paymentOption.value} value={paymentOption.value}>
                {paymentOption.name}
              </option>
            ))}
          </Field>
          <span></span>
        </StyledSelect>
      </InputWrapper>
      {methodType === 'paypal' && <Paypal />}
      {methodType === 'bank_transfer' && <BankTransfer country={country} />}
    </>
  );
};

PayoutFields.propTypes = {
  country: PropTypes.string,
  methodType: PropTypes.string,
};

export default PayoutFields;
