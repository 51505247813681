import styled from 'styled-components';

const Form = styled.div`
  display: none;

  @media (max-width: calc(1023px - 1px)) {
    padding: 30px 30px 0px;
    display: block;
    width: 100%;

    & > div {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 479px) {
    padding: 0;
  }
`;

Form.displayName = 'Form';

export default Form;
