import styled from 'styled-components';

const StyledFAQWrapper = styled.div`
  padding: 32px 33px;
  h4 {
    font-size: 28px;
    font-weight: 500;
    line-height: 2;
  }
  .mb-52 {
    margin-bottom: 52px;
  }
  p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 6px;
    line-height: 1.5;
    color: #223c50;
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    margin-top: 48px;
    padding: 0;

    h4, p {
      padding: 0 18px;
    }

    .small-container {
      padding: 0 18px 0 32px;
    }

    .scroll-body {
      height: 72vh;
      overflow: auto;
    }

    .button-section {
      button {
        margin: 0 0 32px;
        width: 90%;
      }
    }

    .accordion__panel p {
      padding: 0;
    }

    hr {
      border: solid 1px #c5c8c9;
    }
  }
  
`;

StyledFAQWrapper.displayName = 'StyledFAQWrapper';

export default StyledFAQWrapper;
