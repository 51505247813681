import styled from 'styled-components';

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export default StyledHeaderContainer;

StyledHeaderContainer.displayName = 'StyledHeaderContainer';
