import React, { useEffect } from 'react';
import AnswerHistoryCard from '../../../InterviewPrep/components/AnswerHistoryCard';
import InterviewEvaluationModal from '../../containers/InterviewEvaluationModal';
import LoadingSpinner from '../../../shared/loadingSpinner';
import PropTypes from 'prop-types';

const InterviewAnswersTab = ({
  getInterviewAnswers,
  userId,
  interviewAnswers,
  isInterviewLoading,
  isMentorPage,
  openQuestionInsights,
}) => {
  useEffect(() => {
    getInterviewAnswers(userId);
  }, []);
  return isInterviewLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <AnswerHistoryCard
        selectedCategory={''}
        interviewAnswers={interviewAnswers}
        isMentorPage={isMentorPage}
        openQuestionInsights={openQuestionInsights}
      />
      <InterviewEvaluationModal />
    </>
  );
};

InterviewAnswersTab.propTypes = {
  getInterviewAnswers: PropTypes.func.isRequired,
  interviewAnswers: PropTypes.array,
  isInterviewLoading: PropTypes.bool.isRequired,
  isMentorPage: PropTypes.bool.isRequired,
  openQuestionInsights: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

export default InterviewAnswersTab;
