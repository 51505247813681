import React, { useEffect, useState } from 'react';
import { getEmployers, handleUpdateEmployer, showEmployer, updateEmployer } from '../../../actions';
import EmployerTable from '../../components/EmployerTable/index.js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const EmployerTableContainer = (props) => {
  const { getEmployers, employersPage } = props;
  const [orderBy, setOrderBy] = useState('last_login');
  const [orderType, setOrderType] = useState({
    company: 'DESC',
    contact_name: 'DESC',
    last_login: 'DESC',
  });

  useEffect(() => {
    getEmployers(employersPage, orderType[orderBy], orderBy);
  }, [orderType, orderBy]);

  const handlePageChange = (pageNumber) => getEmployers(pageNumber, orderType[orderBy], orderBy);

  return (
    <EmployerTable {...props} handlePageChange={handlePageChange} setOrderBy={setOrderBy} orderType={orderType} setOrderType={setOrderType} />
  );
};

EmployerTableContainer.propTypes = {
  activePage: PropTypes.number.isRequired,
  employers: PropTypes.array.isRequired,
  employersPage: PropTypes.number.isRequired,
  getEmployers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  perPage: PropTypes.number.isRequired,
  totalEntries: PropTypes.number.isRequired,
  updateEmployer: PropTypes.func.isRequired,
};

export const mapStateToProps = ({
  adminEmployerConnector: {
    employersPage,
    employers,
    loading,
    activePage,
    perPage,
    totalEntries,
  },
}) => ({
  activePage,
  employers,
  employersPage,
  loading,
  perPage,
  totalEntries,
});

export const mapDispatchToProps = dispatch => ({
  getEmployers: (page, orderBy, orderType) => dispatch(getEmployers(page, orderBy, orderType)),
  handleUpdateEmployer: (id) => dispatch(handleUpdateEmployer(id)),
  showEmployer: (id) => dispatch(showEmployer(id)),
  updateEmployer: (id, payload) => dispatch(updateEmployer(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployerTableContainer);
