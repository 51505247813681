import { ModalActions } from '../../../../CallsEdit/components/ConfirmationModal/styles';
import PropTypes from 'prop-types';
import React from 'react';

const CreatedSuccessContent = ({ handleModalClose }) => (
  <>
    <i className="ds-icon__time"></i>
    <h3 className="ds-typography__h3">Time off notice sent</h3>
    <p className="ds-typography__body">
      The time off notice has been submitted and acknowledged. You will receive
      a confirmation email shortly.{' '}
      <span className="ds-typography__body--bold">
        No further actions are required
      </span>
      .
    </p>
    <ModalActions>
      <button className="ds-button__primary" onClick={handleModalClose}>
        Got it
      </button>
    </ModalActions>
  </>
);

CreatedSuccessContent.propTypes = {
  handleModalClose: PropTypes.func,
};

export default CreatedSuccessContent;
