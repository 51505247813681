import ApiService from '../../../../services/ApiService';
import { getAuthToken } from '../../../../utils/getAuthToken';

export const postData = (data) => {
  const params = {
    authenticity_token: getAuthToken(),
    feedbackable_id: data.stepId,
    feedbackable_type: 'Step',
    form_id: data.formId,
    owner_id: data.attendeeId,
    owner_type: 'Attendee',
    response_id: data.responseId,
  };

  const apiService = new ApiService('/api/v1/feedbacks.json');

  return apiService.makePostCall(params).catch((error) => {
    throw error;
  });
};

export const getSurveryData = (data) => {
  const params = {
    attendee_id: data.attendeeId,
    authenticity_token: getAuthToken(),
    feedbackable_id: data.stepId,
  };

  const apiService = new ApiService(
    '/api/v1/feedbacks/course_content_survey.json'
  );

  return apiService
    .makeGetCall(params)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
};
