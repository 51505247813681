import { SET_GUIDED_STEPS, SET_USER_DATA, TOUR_AREA } from './constants';
import {
  getCourseId,
  isCourseReadOnly,
} from '../../shared/helpers/courseHelpers';
import ApiService from '../../../services/ApiService';
import { getAuthToken } from '../../../utils/getAuthToken';
import { getUserData } from '../../../services/getUserData';
import { openWsgModal } from '../actions';
import { shouldShowTour } from '../../shared/helpers/guidedTourHelpers';
import tourStepsFull from './tourStepsFull';

export const getCurrentUserdData = () => async (dispatch) => {
  const getToursAndDispatch = async () => {
    const steps = await getGuidedTourSteps();
    dispatch(setGuidedSteps(steps));
  };
  try {
    const attendees = await getAttendees();
    if (!isCourseReadOnly(attendees, getCourseId())) {
      const { data } = await getUserData();
      dispatch(setUserData(data.data));
      shouldShowTour(
        data.data.completedGuidedTours,
        data.data.onboardingStatus,
        TOUR_AREA
      ) && (await getToursAndDispatch());
    }
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const getGuidedTourSteps = () => tourStepsFull;

export const trackEvent = async (event, data) => {
  const params = {
    authenticity_token: getAuthToken(),
    data,
    event,
  };
  try {
    const apiService = new ApiService('/api/v1/analytics/track');
    await apiService.makePostCall(params);
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const getAttendees = async () => {
  try {
    const apiService = new ApiService('/api/v1/attendees');
    const {
      data: {
        data: { attendees },
      },
    } = await apiService.makeGetCall();
    return attendees.attendees;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const finishTour = (stepName) => async (dispatch) => {
  try {
    const params = {
      authenticity_token: getAuthToken(),
    };
    if (stepName) {
      trackEvent('onboarding_tooltip', {
        event_action: 'skip',
        tooltip: stepName,
      });
    }
    const apiService = new ApiService(
      `/api/v1/guided_tours/${TOUR_AREA}/complete_tour`
    );
    await apiService.makePostCall(params);
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  } finally {
    dispatch(setGuidedSteps([]));
    dispatch(openWsgModal());
  }
};

export const toggleTourStatus = (status) => async (dispatch, getState) => {
  try {
    const state = getState();
    const dispatchAndTrack = async () => {
      const steps = await getGuidedTourSteps();
      dispatch(setGuidedSteps(steps));
      trackEvent('onboarding_quick_start', {
        event_action: 'reruntooltip',
        tooltip_sequence: 'course_dashboard',
      });
    };
    status && !state.guidedTour.steps.length
      ? await dispatchAndTrack()
      : dispatch(setGuidedSteps([]));
  } catch (e) {
    console.log(e);
  }
};

export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  userData,
});

export const setGuidedSteps = (steps) => ({
  steps,
  type: SET_GUIDED_STEPS,
});
