import { BackButton } from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ startDate, title }) => (
  <div className="ds-grid__col-xs-12">
    <BackButton href="/en/admin/cohorts/">
      <i className="ds-icon__arrow-thin-left"></i>
      Back to cohorts list
    </BackButton>
    <h2 className="ds-typography__h2">{ title }: { startDate }</h2>
  </div>
);

Header.propTypes = {
  startDate: PropTypes.string,
  title: PropTypes.string,
};

export default Header;
