import { Provider } from 'react-redux';
import React from 'react';
import StudentActivity from './containers/StudentActivity';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const studentActivityContainer = document.getElementById('StudentActivity');

if (studentActivityContainer) {
  const studentActivityContainerRoot = createRoot(studentActivityContainer);

  studentActivityContainerRoot.render(
    <Provider store={store}>
      <StudentActivity />
    </Provider>
  );
}
