import PropTypes from 'prop-types';
import React from 'react';

const StudentModal = (props) => (
  <div>
    <h4>Notes: {props.attributes.notes}</h4>
    <h4>Reference Code: {props.attributes.referenceCode}</h4>
  </div>
);

StudentModal.propTypes = {
  attributes: PropTypes.object,
};

export default StudentModal;
