import React, { useEffect, useState } from 'react';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';
import { ChevronRight } from '../../../../shared/icons/ChevronRight';
import PropTypes from 'prop-types';
import { UsersList } from './styles';
import { stringTruncate } from '../../../../../utils/stringTruncate';

const CustomChannelHeader = ({
  userId,
  shouldShowChannelList,
  setShouldShowChannelList,
}) => {
  const [chatMembers, setChatMembers] = useState([]);
  const openMobileNav = useChatContext().openMobileNav;
  const { channel } = useChannelStateContext();
  const members = channel?.state?.members || {};

  // owner is the recipient of a message
  const owner = Object.values(members).find(({ role }) => role === 'owner');

  useEffect(() => {
    const getChannelMembers = async () => {
      try {
        const membersResponse = await channel.queryMembers({
          user_id: { $nin: userId },
        });
        const membersList = membersResponse.members.map(
          (member) => member.user.name
        );
        if (membersList && membersList.length) {
          setChatMembers(membersList);
        }
      } catch (err) {
        console.log({ err });
      }
    };

    getChannelMembers();
  }, []);

  useEffect(() => {
    if (shouldShowChannelList) {
      openMobileNav();
    }

    // to not open the channel list when user switches to another conversation
    setShouldShowChannelList(false);
  }, []);

  return (
    <UsersList className='str-chat__header-livestream'>
      <div className='chevron-right-icon-container'>
        <ChevronRight onClick={openMobileNav} />
      </div>
      <div className='profile-photo-name-container'>
        <img className='profile-photo' src={owner?.user?.image} />
        {stringTruncate(chatMembers.join(', '), 50)}
      </div>
    </UsersList>
  );
};

CustomChannelHeader.propTypes = {
  setShouldShowChannelList: PropTypes.func,
  shouldShowChannelList: PropTypes.bool,
  userId: PropTypes.array,
};

export default CustomChannelHeader;
