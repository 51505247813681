import PropTypes from 'prop-types';
import React from 'react';
import StyledLink from './styles/StyledLink';

const RouterLinkWithIcon = ({
  to,
  icon,
  text,
  type = 'secondary',
  className = '',
}) => (
  <StyledLink to={to} className={`ds-button__${type} ${className}`}>
    <i className={`fa fa-${icon}`}></i>
    <span>{text}</span>
  </StyledLink>
);

RouterLinkWithIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default RouterLinkWithIcon;
