import InterviewPreview from './InterviewPreview';
import MobileInterviewPreview from './MobileInterviewPreview';
import PropTypes from 'prop-types';
import React from 'react';
import { userEventTracking } from '../../../shared/helpers/tracking';

const InterviewPrepPreview = ({
  startInterview,
  isMobile,
  openInterviewGuidelines,
}) => {
  const handleStartInterview = () => {
    userEventTracking(window.location.href, 'ip_start_interview_from_csc');
    startInterview('careerSupportCenter');
  };

  const handleInterviewGuidelines = () => {
    userEventTracking(window.location.href, 'ip_open_interview_guidelines_csc');
    openInterviewGuidelines();
  };

  return (
    <>
      {isMobile ? (
        <MobileInterviewPreview
          startInterview={handleStartInterview}
          openInterviewGuidelines={handleInterviewGuidelines}
        />
      ) : (
        <InterviewPreview
          startInterview={handleStartInterview}
          openInterviewGuidelines={handleInterviewGuidelines}
        />
      )}
    </>
  );
};

InterviewPrepPreview.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  openInterviewGuidelines: PropTypes.func.isRequired,
  startInterview: PropTypes.func.isRequired,
};

export default InterviewPrepPreview;
