import React, { Component } from 'react';
import { getKeyByValue } from '../../../utils/getKeyByValue';
import { EnrollType } from '../../../utils/enrollTypes';
import { formattedDate } from '../../../utils/datetime';
import { EU_WITHOUT_DE } from '../../../globals/constants';
import Checkbox from '../components/Checkbox';
import UserProfileForm from './UserProfileForm';
import TermsCheckbox from '../../shared/termsCheckbox';
import Form from '../../shared/form';
import LoadingSpinner from '../loadingSpinner';
import CardFields from './CardFields';
import SepaCheckbox from './SepaCheckbox';
import SepaFields from './SepaFields';
import { addScriptToHead } from './helpers/dynamicallyAddScript';
import { getPaypalScriptUrl } from './helpers/getPaypalScriptUrl';

class PaymentDetailsSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      buttonLabel: 'Show All Payments',
      billingName: '',
      businessName: '',
      vat: '',
      cardElement: null,
      nameOnCard: '',
      cardNumber: '',
      expMonth: '',
      expYear: '',
      expDate: '',
      cvc: '',
      street: '',
      city: '',
      state: '',
      country: '',
      countryCode: '',
      validCountryForVat: true,
      zip: '',
      changeCardToggle: null,
      agreedToTerms: false,
      agreedToSepa: false,
      redirectPath: '',
    };

    this.content = this.props.extensionsPage
      ? this.props.courseContent.payment
      : this.props.courseParams.content.payment;
    this.paypalButtons = null;
    this.paypalButtonsRendered = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getFromSessionStorage();
    this.props.extensionsPage
      ? this.populateData()
      : this.props.handlePaymentSelect();
    addScriptToHead(
      getPaypalScriptUrl(
        this.props.extensionsPage ? 'upfront' : this.props.selectedOption,
        this.props.courseParams.currency_code
      )
    );
  }

  componentDidUpdate(prevProps) {
    const paypalButtonsContainerExists = !!document.querySelector(
      '#paypal-button-container'
    );
    const {
      extensionsPage,
      paymentMethod,
      sepaAvailable,
      onPaymentMethodClick,
      showSepaWarning,
    } = this.props;

    if (
      paymentMethod === 'paypal' &&
      paypalButtonsContainerExists &&
      (prevProps.paymentMethod !== paymentMethod ||
        (extensionsPage && !this.paypalButtonsRendered))
    ) {
      this.initializePaypalButtons('#paypal-button-container');
    }

    paymentMethod === 'sepa' &&
      (!sepaAvailable || showSepaWarning) &&
      onPaymentMethodClick('card');
  }

  cardElementForStripeCheckout = (cardElement) =>
    this.setState({ cardElement });

  populateData = () => {
    let { billingData } = this.props;
    if (!billingData.student_paying) return;

    this.setState({
      billingName: billingData.billingProfile.billingName,
      vat: billingData.billingProfile.vat,
      businessName: billingData.billingProfile.businessName,
      street: billingData.billingProfile.street,
      city: billingData.billingProfile.city,
      country: CF.countries.listWithCodes[billingData.billingProfile.country],
      countryCode: billingData.billingProfile.country,
      zip: billingData.billingProfile.zip,
      state: billingData.billingProfile.state,
      changeCardToggle: false,
    });
  };

  handleChange = (event) => {
    const state = this.state;
    state[event.target.name] = event.target.value;
    this.setState(state);
    if (event.target.name !== 'cvc') {
      sessionStorage.setItem(event.target.name, event.target.value);
    }
  };

  handleCheckboxClick = (event) => {
    if (event.target.name === 'b2b__checkbox') {
      this.props.onB2BClick(event.target.checked);
    } else {
      this.setState({ [event.target.name]: event.target.checked });
    }
  };

  handleCountrySelect = (event) => {
    let country = event.target[event.target.selectedIndex].text;
    let countryCode = getKeyByValue(CF.countries.listWithCodes, country);
    let validForVat = EU_WITHOUT_DE.indexOf(countryCode) > -1;

    this.setState({
      country,
      countryCode,
      validCountryForVat: validForVat,
    });
  };

  toggleInstallments = () => {
    if (this.state.expanded) {
      this.setState({
        expanded: false,
        buttonLabel: 'Show All Payments',
      });
    } else {
      this.setState({
        expanded: true,
        buttonLabel: 'Show Less',
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    sessionStorage.clear();
    this.checkout();
  };

  checkout = (isSubscription) => {
    return this.props.onCheckout({
      ...this.state,
      stripe: this.props.stripe,
      email: this.props.email,
      isSubscription,
    });
  };

  getFromSessionStorage = () => {
    const state = this.state;
    Object.keys(sessionStorage).forEach((k) => {
      if (Object.keys(state).includes(k)) {
        state[k] = sessionStorage.getItem(k);
        this.setState(state);
      }
    });
  };

  initializePaypalButtons(paypalButtonsWrapper) {
    if (!window.paypal) {
      setTimeout(() => {
        this.initializePaypalButtons(paypalButtonsWrapper);
      }, 200);
      return;
    }

    if (
      this.paypalButtons &&
      this.paypalButtons.close &&
      this.paypalButtonsRendered
    ) {
      this.paypalButtons.close();
      this.paypalButtonsRendered = false;
    }

    const paypalAction =
      this.props.extensionsPage || this.props.selectedOption === 'upfront'
        ? 'createOrder'
        : 'createSubscription';
    const isSubscription =
      this.props.selectedOption === 'upfront_with_installments';
    const checkoutFormElement = document.querySelector(
      '#enroll__payment__form'
    );

    this.paypalButtons = paypal
      .Buttons({
        [paypalAction]: async () => {
          return this.checkout(isSubscription).then((res) => {
            if (res?.data) {
              this.setState({
                redirectPath: res.data.redirect_path,
              });
              return res.data.id;
            }
            return Promise.reject(
              new Error('Paypal object creation failed, please try again')
            );
          });
        },
        onApprove: () => {
          window.location.href = this.state.redirectPath;
        },
        onInit: (data, actions) => {
          const enablePaypalButton = () => {
            if (checkoutFormElement.checkValidity()) {
              actions.enable();
            }
          };

          actions.disable();
          enablePaypalButton();
          checkoutFormElement.addEventListener('change', enablePaypalButton);
        },
        onClick: () => {
          if (!checkoutFormElement.checkValidity()) {
            checkoutFormElement.reportValidity();
          }
        },
      })
      .render(paypalButtonsWrapper)
      .then(() => {
        this.paypalButtonsRendered = true;
      })
      .catch((err) => {
        let selector = document.querySelector(paypalButtonsWrapper);
        if (selector && selector.children.length > 0) {
          throw new Error(err);
        }
        return;
      });
  }

  totalProductFee = () =>
    `Total ${
      this.props.extensionsPage ? 'Extension' : this.props.productType
    } Fee`;

  paymentBreakdown = () => {
    const hasInstallments =
      this.props.paymentDetails && this.props.paymentDetails.length > 1;
    return (
      <div>
        <p className='cf-small cf-bold sub-heading'>
          {hasInstallments
            ? this.content.subheadingInstallments
            : this.totalProductFee()}
        </p>
        <p className='cf-small'>
          {hasInstallments
            ? `Upfront deposit followed by ${
                this.props.paymentDetails.length - 1
              } monthly payments`
            : 'One-time payment'}
        </p>
        <div className='installment__breakdown'>
          {this.props.paymentDetails.map((payment, i) => {
            return (
              <div
                key={i}
                className={`installment ${
                  !this.state.expanded && i > 1 ? 'hidden' : ''
                }`}
              >
                <p className={`cf-small ${i > 0 ? 'cf-small--gray' : ''}`}>
                  {payment[0]} {i === 0 ? '(today)' : ''}
                </p>
                <p className={`cf-small ${i > 0 ? 'cf-small--gray' : ''}`}>
                  {payment[1]}
                </p>
              </div>
            );
          })}
          <p
            onClick={this.toggleInstallments}
            className={`cf-small cf-bold installment__toggle ${
              hasInstallments ? '' : 'hidden'
            }`}
          >
            {this.state.buttonLabel}
          </p>
        </div>
      </div>
    );
  };

  paymentBreakdownBank = () => {
    const todaysFormattedDate = formattedDate(new Date());
    return (
      <div>
        <p className='cf-small cf-bold sub-heading'>{this.totalProductFee()}</p>
        <p className='cf-small'>One-time payment</p>
        <div className='installment'>
          <p className='cf-small'>{todaysFormattedDate} (today)</p>
          <p className='cf-small'>
            {this.props.courseParams.selectable_plans[0]['total']}
          </p>
        </div>
      </div>
    );
  };

  paymentDetailsHeader = () => (
    <header>
      <h2>{this.content.heading}</h2>
      {this.props.loadingState ? (
        <LoadingSpinner />
      ) : this.props.enrollType === EnrollType.bank ? (
        this.paymentBreakdownBank()
      ) : (
        this.paymentBreakdown()
      )}
    </header>
  );

  paymentMethods = () => (
    <div
      className={`enroll__payment__providers ${
        this.props.sepaAvailable ? 'all_providers' : ''
      }`}
    >
      <div
        onClick={() => this.props.onPaymentMethodClick('card')}
        className={this.props.paymentMethod === 'card' ? 'active' : ''}
      >
        <i className='fa fa-credit-card'></i>
        <p className='cf-allcaps cf-bold'>{this.content.card}</p>
      </div>
      {this.paypalButton()}
      {this.props.sepaAvailable && this.sepaButton()}
    </div>
  );

  sepaButton = () =>
    this.props.showSepaWarning ? (
      <div className='disabled'>
        <i className='fa fa-university'></i>
        <p className='cf-allcaps cf-bold'>{this.content.sepa}</p>
        <div className='cf-tooltip sepa-tooltip cf-text-center'>
          <p className='cf-small--bold'>
            To pay with SEPA, please select a start date more than 14 days from
            today
          </p>
        </div>
      </div>
    ) : (
      <div
        onClick={() => this.props.onPaymentMethodClick('sepa')}
        className={this.props.paymentMethod === 'sepa' ? 'active' : ''}
      >
        <i className='fa fa-university'></i>
        <p className='cf-allcaps cf-bold'>{this.content.sepa}</p>
      </div>
    );

  paypalButton = () => {
    if (this.props.paypalAvailable) {
      return (
        <div
          onClick={() => this.props.onPaymentMethodClick('paypal')}
          className={this.props.paymentMethod === 'paypal' ? 'active' : ''}
        >
          <i className='fa fa-paypal'></i>
          <p className='cf-allcaps cf-bold'>{this.content.paypal}</p>
        </div>
      );
    } else {
      return (
        <div className='disabled'>
          <i className='fa fa-paypal'></i>
          <p className='cf-allcaps cf-bold'>{this.content.paypal}</p>
          <div className='cf-tooltip cf-text-center'>
            <p className='cf-small--bold'>
              Select a start date within 60 days to pay with PayPal
            </p>
          </div>
        </div>
      );
    }
  };

  b2bCheckbox = () => (
    <Checkbox
      onClick={this.handleCheckboxClick}
      defaultChecked={this.props.isB2B}
      name='b2b__checkbox'
      labelText={`My company is paying for this ${
        this.props.extensionsPage ? 'extension' : this.props.productType
      }`}
      className='b2b-checkbox'
    />
  );

  b2bFormFields = () => (
    <div>
      <div
        className={`cf-form__group--floating ${
          this.state.businessName && this.state.businessName.length > 0
            ? 'cf-form__group--frozen'
            : ''
        }`}
      >
        <input
          onChange={this.handleChange}
          value={this.state.businessName}
          type='text'
          name='businessName'
          required='required'
          className='cf-form__input'
        />
        <label className='cf-form__label--floating'>Company Name</label>
        <div className='cf-form__validation'></div>
      </div>
      <div
        className={`cf-form__group--floating ${
          this.state.validCountryForVat ? '' : 'hidden'
        } ${
          this.state.vat && this.state.vat.length > 0
            ? 'cf-form__group--frozen'
            : ''
        } ${this.props.errorType === 'vat' ? 'with__errors' : ''}`}
      >
        <input
          onChange={this.handleChange}
          value={this.state.vat}
          type='text'
          name='vat'
          className='cf-form__input'
        />
        <label className='cf-form__label--floating'>VAT ID (optional)</label>
        <div className='cf-form__validation'></div>
      </div>
    </div>
  );

  renderCardFields = () => {
    if (this.props.paymentMethod === 'card') {
      return (
        <CardFields
          nameOnCard={this.state.nameOnCard}
          handleChange={this.handleChange}
          stripe={this.props.stripe}
          cardElementForStripeCheckout={this.cardElementForStripeCheckout}
        />
      );
    }
  };

  sepaFields = () => (
    <SepaFields
      name={this.state.nameOnCard}
      stripe={this.props.stripe}
      handleChange={this.handleChange}
      cardElementForStripeCheckout={this.cardElementForStripeCheckout}
      countryCode={this.props.countryCode}
    />
  );

  addressFields = () => (
    <div>
      <div
        className={`cf-form__group--floating ${
          this.state.billingName && this.state.billingName.length > 0
            ? 'cf-form__group--frozen'
            : ''
        }`}
      >
        <input
          onChange={this.handleChange}
          value={this.state.billingName}
          type='text'
          name='billingName'
          required='required'
          className='cf-form__input'
        />
        <label className='cf-form__label--floating'>Billing Name</label>
        <div className='cf-form__validation'></div>
      </div>
      <div className='split__fields'>
        <div
          className={`cf-form__group--floating ${
            this.state.street && this.state.street.length > 0
              ? 'cf-form__group--frozen'
              : ''
          }`}
        >
          <input
            onChange={this.handleChange}
            value={this.state.street}
            type='text'
            name='street'
            required='required'
            className='cf-form__input'
          />
          <label className='cf-form__label--floating'>Street Address</label>
          <div className='cf-form__validation'></div>
        </div>
        <div
          className={`cf-form__group--floating ${
            this.state.city && this.state.city.length > 0
              ? 'cf-form__group--frozen'
              : ''
          }`}
        >
          <input
            onChange={this.handleChange}
            value={this.state.city}
            type='text'
            name='city'
            required='required'
            className='cf-form__input'
          />
          <label className='cf-form__label--floating'>City</label>
          <div className='cf-form__validation'></div>
        </div>
      </div>
      <div className='split__fields'>
        <div
          className={`cf-form__group--floating ${
            this.state.zip && this.state.zip.length > 0
              ? 'cf-form__group--frozen'
              : ''
          }`}
        >
          <input
            onChange={this.handleChange}
            value={this.state.zip}
            type='text'
            name='zip'
            required='required'
            className='cf-form__input'
          />
          <label className='cf-form__label--floating'>Zip Code</label>
          <div className='cf-form__validation'></div>
        </div>
        {this.props.countryCode === 'US' ? (
          <div
            className={`cf-form__group--floating ${
              this.state.state && this.state.state.length > 0
                ? 'cf-form__group--frozen'
                : ''
            }`}
          >
            <input
              onChange={this.handleChange}
              value={this.state.state}
              type='text'
              name='state'
              required='required'
              className='cf-form__input'
            />
            <label className='cf-form__label--floating'>State</label>
            <div className='cf-form__validation'></div>
          </div>
        ) : (
          this.renderCountrySelect()
        )}
      </div>
      {this.props.countryCode === 'US' && this.renderCountrySelect()}
    </div>
  );

  renderCountrySelect = () => (
    <div
      className={`cf-form__group--floating ${
        this.state.country && this.state.country.length > 0
          ? 'cf-form__group--frozen'
          : ''
      }`}
    >
      <select
        onChange={this.handleCountrySelect}
        value={this.state.country}
        name='country'
        required='required'
        className='cf-form__input'
      >
        {Object.keys(CF.countries.listWithCodes).map((code, i) => {
          return (
            <option key={i} value={CF.countries.listWithCodes[code]}>
              {CF.countries.listWithCodes[code]}
            </option>
          );
        })}
      </select>
      <i className='fa fa-angle-down'></i>
      <label className='cf-form__label--floating'>Country</label>
      <div className='cf-form__validation'></div>
    </div>
  );

  renderCardLogos = () => (
    <div className='enroll__card__options cf-text-center'>
      {this.content.cards.map((card, i) => (
        <img key={i} src={card} />
      ))}
      <p className='cf-small--gray'>{this.content.card_warning}</p>
    </div>
  );

  companyFields = () => (this.props.isB2B ? this.b2bFormFields() : '');

  renderTermsCheckbox = () => (
    <TermsCheckbox
      name='agreedToTerms'
      id='terms__checkbox'
      onChange={this.handleCheckboxClick}
      termsRequired={
        this.props.extensionsPage || this.props.courseParams.terms_required
      }
    />
  );

  renderEnrollButton = () => {
    const isEnabled =
      this.props.paymentMethod === 'sepa'
        ? this.state.agreedToTerms && this.state.agreedToSepa
        : this.state.agreedToTerms;
    let enrollExtendSwitch = this.props.extensionsPage
      ? 'Confirm & Extend'
      : 'Confirm & Enroll';
    let enrollButtonLabel;

    switch (this.props.paymentMethod) {
      case 'card':
        enrollButtonLabel = enrollExtendSwitch;
        break;
      case 'paypal':
        enrollButtonLabel = 'Complete Order via PayPal Website';
        break;
      case 'sepa':
        enrollButtonLabel = enrollExtendSwitch;
        break;
      default:
        throw new Error('Invalid provider');
    }

    if (
      this.props.buttonLoadingState &&
      !(this.props.paymentMethod == 'paypal')
    ) {
      return (
        <button
          className='button--large button--primary-petrol'
          disabled={true}
          dangerouslySetInnerHTML={{ __html: CF.html.loadingSpinner }}
        ></button>
      );
    }

    return this.props.paymentMethod !== 'paypal' ? (
      <button
        type='submit'
        disabled={!this.state.agreedToTerms}
        className={`button--primary-${isEnabled ? 'petrol' : 'disabled'}`}
      >
        {enrollButtonLabel}
      </button>
    ) : (
      <div id='paypal-button-container'></div>
    );
  };

  handleChangeCardToggle = (e) => {
    if (e.target.checked) {
      this.setState({
        nameOnCard: '',
        cardNumber: '',
        expDate: '',
        cvc: '',
        changeCardToggle: true,
      });
    } else {
      this.populateData();
    }
    this.props.handleCardChangeToggle(e.target.checked);
  };

  renderCardImageName = () => {
    let { cardLastFourDigits, cardType } = this.props.billingData.cardDetails;
    let { cards } = this.content;
    let cardImageUrl = null; // set default?

    switch (cardType[0]) {
      case 'V':
        cardImageUrl = cards[0];
        break;
      case 'A':
        cardImageUrl = cards[1];
        break;
      case 'M':
        cardImageUrl = cards[2];
        break;
      default:
        throw new Error('Invalid card type given');
    }

    return (
      <span>
        <img className='extension-page__saved-card-image' src={cardImageUrl} />{' '}
        {cardType} ending in {cardLastFourDigits}
      </span>
    );
  };

  renderChangeCardButton = () => {
    return this.props.extensionsPage && this.props.billingData.cardPresent ? (
      <div>
        <p
          className={
            this.state.changeCardToggle
              ? 'change-card-toggle--fade cf-small'
              : 'cf-small'
          }
        >
          {this.renderCardImageName()}
        </p>
        <div className='extensions-page__change-card cf-form__slider'>
          <label>
            <input
              onChange={this.handleChangeCardToggle}
              type='checkbox'
              id='change_card'
            />
            <span></span>
          </label>
        </div>
        &nbsp;
        <label htmlFor='change_card'>Change card</label>
      </div>
    ) : null;
  };

  renderBillingProfileForm = () => (
    <div className='PaymentDetailsSlide enroll__slide'>
      {this.paymentDetailsHeader()}
      {this.b2bCheckbox()}
      <Form onSubmit={this.handleSubmit} id='enroll__payment__form'>
        {this.companyFields()}
        {this.addressFields()}
        {this.renderTermsCheckbox()}
        <div className='error__container'>
          <p className='cf-small'>{this.props.formError}</p>
        </div>
        {this.renderEnrollButton()}
      </Form>
    </div>
  );

  renderNormalEnrollForm = () => (
    <div className='PaymentDetailsSlide enroll__slide'>
      {this.paymentDetailsHeader()}
      {this.props.extensionsPage &&
      (this.props.billingData.cardPresent ||
        this.props.billingData.paypalPresent)
        ? null
        : this.paymentMethods()}
      {this.b2bCheckbox()}
      {this.renderChangeCardButton()}
      <Form onSubmit={this.handleSubmit} id='enroll__payment__form'>
        {this.companyFields()}
        {this.props.paymentMethod === 'sepa' && this.sepaFields()}
        {this.props.extensionsPage &&
        (this.props.billingData.cardPresent ||
          this.props.billingData.paypalPresent) &&
        !this.state.changeCardToggle
          ? null
          : this.renderCardFields()}
        {this.addressFields()}
        {this.props.paymentMethod === 'card' && this.renderCardLogos()}
        {this.renderTermsCheckbox()}
        {this.props.paymentMethod === 'sepa' && (
          <SepaCheckbox
            onChange={this.handleCheckboxClick}
            agreedToSepa={this.state.agreedToSepa}
          />
        )}
        <div className='error__container'>
          <p className='cf-small'>{this.props.formError}</p>
        </div>
        {this.renderEnrollButton()}
      </Form>
    </div>
  );

  renderOrganizationForm = () => (
    <UserProfileForm
      onCheckout={this.props.onCheckout}
      buttonLoadingState={this.props.buttonLoadingState}
      formError={this.props.formError}
    />
  );

  render() {
    switch (this.props.enrollType) {
      case EnrollType.bank:
        return this.renderBillingProfileForm();
      case EnrollType.organization:
        return this.renderOrganizationForm();
      case EnrollType.normal:
      case EnrollType.offer:
      default:
        return this.renderNormalEnrollForm();
    }
  }
}

export default PaymentDetailsSlide;
