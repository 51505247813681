import AccountSetup from '../../modals/optInModal/AccountSetup';
import React from 'react';
import { connect } from 'react-redux';
import { updateECProfile } from '../../actions';
import { updateJobSeekingProfile } from '../../../CareerSupportCenter/actions';

const AccountSetupModal = (props) => {
  return (
    <AccountSetup {...props} />
  );
};

export const mapDispatchToProps = (dispatch) => ({
  updateECProfile: (data) => dispatch(updateECProfile(data)),
  updateJobSeekingProfile: (jobSeekingProfile) => dispatch(updateJobSeekingProfile(jobSeekingProfile)),
});

export const mapStateToProps = ({
  careerSupportCenter: { jobSeekingProfile },
}) => ({
  jobSeekingProfile,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetupModal);
