import styled from 'styled-components';

const SidebarStyles = styled.div`
  padding: 1rem;
  border-right: solid 1px #c0c6cb;

  .accordion__button {
    padding: 24px;
  }

  .StandaloneCourseTiles {
    > div {
      border-top: none;
    }

    > div:first-child {
      border-top: ${({ totalProgramAttendees }) =>
        totalProgramAttendees > 0 ? 'solid 1px #c0c6cb' : 'none'};
    }

    > div:last-child {
      border-bottom: none;
    }
  }

  @media (max-width: calc(1023px - 1px)) {
    display: none;
  }
`;

SidebarStyles.displayName = 'Sidebar';

export default SidebarStyles;
