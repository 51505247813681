import PropTypes from 'prop-types';
import React from 'react';
import StyledParagraphWithLabel from '../../styles/StyledParagraphWithLabel';

const ParagraphWithLabel = ({ label, paragraph }) => (
  <StyledParagraphWithLabel>
    <h5 className="ds-typography__body">{ label }</h5>
    <p className="ds-typography__body">{ paragraph }</p>
  </StyledParagraphWithLabel>
);

ParagraphWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
};

export default ParagraphWithLabel;
