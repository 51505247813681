import styled from 'styled-components';

const ModalContent = styled.div`
  text-align: center;

  h1 {
    color: var(--ds-color__petrol--medium);
  }

  p {
    margin-bottom: 32px;
  }

  img {
    max-width: initial;
    max-height: initial;
    margin: -40px;
    margin-bottom: 32px;
  }

  button {
    margin: 0 auto;
  }
`;

ModalContent.displayName = 'ModalContent';

export default ModalContent;
