import axios from 'axios';
import { Dispatch } from 'redux';
import { constants } from '../../shared/StripePayments/constants';

export const getUserLocation = () => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get('/api/v1/geolocator/location');
    dispatch({
      type: constants.GET_USER_LOCATION,
      payload: response.data.data,
    });
  } catch (error) {
    console.error(error);
  }
};
