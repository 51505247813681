import React, { useMemo, useState } from 'react';
import BodyWrapper from '../../styles/BodyWrapper';
import FormToggleSection from '../../styles/FormToggleSection';
import ModalWrapper from '../shared/ModalWrapper';
import PropTypes from 'prop-types';
import StatementTextArea from '../shared/StatementTextArea';

const MIN_WORD_COUNT = 90;

const ProfessionalStatement = ({
  onNextClick,
  onPrevClick,
  updateECProfile,
  jobSeekingProfile,
  updateJobSeekingProfile,
}) => {
  const [errors, setError] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleToggleChange = (event) => {
    const { name, checked } = event.target;
    updateECProfile({ field: name, value: checked });
  };

  const handleSaveChange = async () => {
    if (!jobSeekingProfile.motivationStatement || jobSeekingProfile.motivationStatement.length < MIN_WORD_COUNT) {
      setError([...errors, 'isRequired']);
    } else {
      setLoading(true);
      await updateJobSeekingProfile(jobSeekingProfile);
      setLoading(false);
      onNextClick();
    }
  };

  const wordCount = useMemo(() => {
    const count = jobSeekingProfile && jobSeekingProfile.motivationStatement ? jobSeekingProfile.motivationStatement.split(' ').length : 0;
    return count;
  }, [jobSeekingProfile.motivationStatement]);

  return (
    <ModalWrapper
      header="Work preferences and professional statement"
      submitDisabled={wordCount < MIN_WORD_COUNT}
      submitLoading={loading}
      currentState="professionalStatement"
      onNextClick={handleSaveChange}
      onPrevClick={onPrevClick}
    >
      <BodyWrapper>
        <FormToggleSection
          label="I’d be willing to relocate"
          isChecked={!!jobSeekingProfile.willingToRelocate}
          name="willingToRelocate"
          onChange={handleToggleChange}
        />
        <FormToggleSection
          label="I’d be willing to work remotely"
          isChecked={!!jobSeekingProfile.willingToWorkRemote}
          name="willingToWorkRemote"
          onChange={handleToggleChange}
        />
        <StatementTextArea
          errors={errors}
          setError={setError}
          motivationStatement={jobSeekingProfile.motivationStatement}
          updateECProfile={updateECProfile}
        />
      </BodyWrapper>
    </ModalWrapper>
  );
};

ProfessionalStatement.propTypes = {
  jobSeekingProfile: PropTypes.object.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  updateECProfile: PropTypes.func.isRequired,
  updateJobSeekingProfile: PropTypes.func.isRequired,
};

export default ProfessionalStatement;
