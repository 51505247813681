import PaginationControls from '../Pagination';
import PropTypes from 'prop-types';
import React from 'react';
import Table from './styles';

const PaginatedTable = ({
  activePage,
  collection,
  collectionLength,
  columns,
  itemsCountPerPage,
  setActivePage,
  showApplicationModal,
}) => {
  const pagination = () => collection.slice((activePage - 1) * itemsCountPerPage, activePage * itemsCountPerPage);
  const handlePageChange = (pageNumber) => setActivePage(pageNumber);
  const getColumnData = (row, item, index) => {
    if (typeof item === 'object') {
      const name = item.name;
      const link = item.url && row[item.url.toLowerCase()];
      const nameLC = name ? row[name.toLowerCase()] : '';

      return (
        <td key={`${name}_${index}`}>
          {item.type === 'link' && link
            ? (
              <a
                className="ds-typography__anchor"
                href={link}
                rel="noreferrer"
                target="_blank"
              >
                {nameLC}
              </a>
            )
            : (
              <span>{nameLC}</span>
            )}
        </td>
      );
    }
    return <td key={`${item}_${index}`}>{row[item.toLowerCase()]}</td>;
  };

  const handleViewJobApplication = (application) => {
    showApplicationModal(application.id);
  };

  return (
    <>
      <Table className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              {columns.map((column) => {
                if (typeof column === 'object') {
                  return <th key={column.name}>{column.name}</th>;
                }
                return <th key={column}>{column}</th>;
              })}
              <th>View Application Info</th>
            </tr>
          </thead>
          <tbody>
            {pagination().map((row, i) => (
              <tr key={`row_${i}`}>
                {columns.map((item, index) => {
                  return getColumnData(row, item, index);
                })}
                <td>
                  <a onClick={() => handleViewJobApplication(row)}>View Info</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
      {collectionLength > itemsCountPerPage && (
        <PaginationControls
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={collectionLength}
          pageRangeDisplayed={10}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};

PaginatedTable.propTypes = {
  activePage: PropTypes.number.isRequired,
  collection: PropTypes.array.isRequired,
  collectionLength: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  itemsCountPerPage: PropTypes.number,
  setActivePage: PropTypes.func.isRequired,
  showApplicationModal: PropTypes.func.isRequired,
};

export default PaginatedTable;
