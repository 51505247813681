import App from './components/App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const jobApplicationTrackerContainer = document.getElementById(
  'jobApplicationTracker'
);

if (jobApplicationTrackerContainer) {
  const jobApplicationTrackerContainerRoot = createRoot(
    jobApplicationTrackerContainer
  );

  jobApplicationTrackerContainerRoot.render(
    <Provider store={store}>
      <App
        attendeeId={
          jobApplicationTrackerContainer.dataset.attendeeId
            ? Number(jobApplicationTrackerContainer.dataset.attendeeId)
            : ''
        }
      />
    </Provider>
  );
}
