import styled from 'styled-components';

const StyledSmallModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;

  span {
    display: inline;
  }

  button {
    margin: 4px;
  }

  input {
    margin: 0px;
  }
`;

StyledSmallModal.displayName = 'StyledSmallModal';

export default StyledSmallModal;
