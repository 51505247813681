import ProfileDetailsView from '../../../../../EmployerConnector/modals/shared/ProfileDetailsView';
import React from 'react';
import { connect } from 'react-redux';

const AdminProfileDetailsViewContainer = (props) => {
  return (
    <ProfileDetailsView {...props} />
  );
};

export const mapStateToProps = ({
  adminEmployerConnector: { jobSeekingProfile },
}) => ({
  jobSeekingProfile,
});

export default connect(mapStateToProps, null)(AdminProfileDetailsViewContainer);
