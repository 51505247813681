import { InputWrapper, StyledInput } from '../App/styles';
import CountrySelect from '../../../shared/components/CountrySelect';
import { EU_COUNTRIES } from '../../../../constants';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const BankTransfer = ({ country }) => {
  return (
    <>
      <InputWrapper>
        <StyledInput id='iban'>
          <Field
            type='text'
            name='accountHolder'
            placeholder='Account holder name'
            required={true}
          />
        </StyledInput>
        <StyledInput id='iban'>
          <Field
            type='text'
            name='ibanNumber'
            placeholder='IBAN/Account number'
            required={true}
          />
        </StyledInput>
      </InputWrapper>
      <InputWrapper>
        <StyledInput>
          <Field
            type='text'
            name='bicNumber'
            placeholder='BIC/Swift'
            required={true}
          />
        </StyledInput>
      </InputWrapper>
      {!EU_COUNTRIES.includes(country) && (
        <>
          <InputWrapper>
            <StyledInput>
              <Field
                type='text'
                name='branch'
                placeholder='Branch'
                required={true}
              />
            </StyledInput>
            <StyledInput>
              <Field
                type='text'
                name='bankName'
                placeholder='Bank name'
                required={true}
              />
            </StyledInput>
          </InputWrapper>
          <InputWrapper>
            <StyledInput>
              <Field
                type='text'
                name='bankAddress'
                placeholder='Bank street'
                required={true}
              />
            </StyledInput>
            <StyledInput>
              <Field
                type='text'
                name='bankCity'
                placeholder='City'
                required={true}
              />
            </StyledInput>
          </InputWrapper>
          <InputWrapper>
            <StyledInput>
              <Field as={CountrySelect} name='bankCountry' required={true} />
            </StyledInput>
          </InputWrapper>
        </>
      )}
    </>
  );
};

BankTransfer.propTypes = {
  country: PropTypes.string,
};

export default BankTransfer;
