import { Body, Button, Header } from '@careerfoundry/bubble';
import { ConfirmationContainer, CenteredContainer } from './styles';
import React from 'react';

interface ConfirmationProps {
  handleClose: () => void;
  icon: string;
  heading: string;
  body?: string;
}

const ConfirmationModal: React.FC<ConfirmationProps> = ({
  icon,
  heading,
  body,
  handleClose,
}) => {
  return (
    <ConfirmationContainer>
      <CenteredContainer>
        <i className={icon}></i>
      </CenteredContainer>
      <Header tag='h4' variant='h4'>
        {heading}
      </Header>
      <Body tag='p' variant='medium'>
        {body}
      </Body>
      <CenteredContainer>
        <Button label='Got it!' onClick={handleClose}></Button>
      </CenteredContainer>
    </ConfirmationContainer>
  );
};

export default ConfirmationModal;
