import ApiService from '../../../../../services/ApiService';
import { getAuthToken } from '../../../../../utils/getAuthToken';

export const handleChange = (data) => ({
  data,
  type: 'UserProfile.HANDLE_MENTOR_NOTE_FORM_CHANGE',
});

export const handleCategoryChange = (categories) => ({
  categories,
  type: 'UserProfile.HANDLE_MENTOR_NOTE_CATEGORY_CHANGE',
});

export const handleCreate = (params) => async (dispatch, getState) => {
  const userId = getState().userProfile.userId;
  const apiService = new ApiService(
    `/api/v1/users/${userId}/mentor_notes.json`
  );
  const formattedParams = {
    authenticity_token: getAuthToken(),
    categories: params.categories,
    content: params.content,
    shared: params.shared,
    title: params.title,
    user_id: userId,
  };

  try {
    const result = await apiService.makePostCall(formattedParams);
    dispatch({
      mentorNote: result.data,
      type: 'UserProfile.MENTOR_NOTE_CREATED',
    });
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const handleUpdate = (params) => async (dispatch, getState) => {
  const userId = getState().userProfile.userId;
  const noteId = getState().userProfile.mentorNotesTab.selectedNote.id;
  const apiService = new ApiService(
    `/api/v1/users/${userId}/mentor_notes/${noteId}.json`
  );

  const formattedParams = {
    authenticity_token: getAuthToken(),
    categories: params.categories,
    content: params.content,
    shared: params.shared,
    title: params.title,
  };

  try {
    const result = await apiService.makePutCall(formattedParams);
    dispatch({
      mentorNote: result.data,
      type: 'UserProfile.MENTOR_NOTE_UPDATED',
    });
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};
