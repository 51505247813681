export const constants = {
  FETCH_JOB_SEEKING_PROFILES_FAILURE:
    'employerDashboard.FETCH_JOB_SEEKING_PROFILES_FAILURE',
  FETCH_JOB_SEEKING_PROFILES_SUCCESS:
    'employerDashboard.FETCH_JOB_SEEKING_PROFILES_SUCCESS',
  FETCH_ONBOARDING_STATUS_FAILURE:
    'employerDashboard.FETCH_ONBOARDING_STATUS_FAILURE',
  FETCH_ONBOARDING_STATUS_SUCCESS:
    'employerDashboard.FETCH_ONBOARDING_STATUS_SUCCESS',
  HANDLE_CLOSE_MODAL: 'employerDashboard.HANDLE_CLOSE_MODAL',
  PASSWORD_UPDATE_FAILURE: 'employerDashboard.PASSWORD_UPDATE_FAILURE',
  PASSWORD_UPDATE_SUCCESS: 'employerDashboard.PASSWORD_UPDATE_SUCCESS',
  POLICY_UPDATE_SUCCESS: 'employerDashboard.POLICY_UPDATE_SUCCESS',
  SET_CREATING_CHANNEL: 'employerDashboard.SET_CREATING_CHANNEL',
  SET_CURRENT_USER: 'employerDashboard.SET_CURRENT_USER',
  SET_MESSAGING_LOADING: 'employerDashboard.SET_MESSAGING_LOADING',
  SET_PROFILE_FILTER: 'employerDashboard.SET_PROFILE_FILTER',
  SET_PROFILE_LOADING: 'employerDashboard.SET_PROFILE_LOADING',
  SHOW_JOB_SEEKING_PROFILE_FAILURE:
    'employerDashboard.SHOW_JOB_SEEKING_PROFILE_FAILURE',
  SHOW_JOB_SEEKING_PROFILE_SUCCESS:
    'employerDashboard.SHOW_JOB_SEEKING_PROFILE_SUCCESS',
  SHOW_PASSWORD_CHANGE_MODAL: 'employerDashboard.SHOW_PASSWORD_CHANGE_MODAL',
};

export const PROGRAMS = [
  { name: 'UX Design Plus Program', slug: 'ux-design-plus' },
  { name: 'Advanced UX Design Program', slug: 'advanced-ux-design' },
  { name: 'UI Design Program', slug: 'ui-design-program' },
  {
    name: 'Full-Stack Web Development Program ',
    slug: 'full-stack-web-development',
  },
  { name: 'Data Analytics Program (AfA)', slug: 'data-analytics-afa' },
  { name: 'Data Analytics Program', slug: 'data-analytics' },
  { name: 'Digital Marketing Program', slug: 'digital-marketing' },
  {
    name: 'Full-Stack Web Development Program',
    slug: 'full-stack-web-development-2',
  },
  { name: 'UX Design Program (AfA)', slug: 'ux-design-afa' },
  {
    name: 'Full-Stack Web Development Program (Afa)',
    slug: 'full-stack-web-development-afa',
  },
  { name: 'UX Design Program (GCU)', slug: 'ux-design-gcu' },
  {
    name: 'Full-Stack Web Development Program (GCU)',
    slug: 'full-stack-web-development-gcu',
  },
  {
    name: 'Full-Stack Web Development Program',
    slug: 'become-a-full-stack-web-developer',
  },
  { name: 'UX Design Program', slug: 'ux-design' },
];

export const SPECIALIZATION = [
  ['UI for UX Designers', 'ui-for-ux-designers'],
  [
    'Voice User Interface Design',
    'voice-user-interface-design-with-amazon-alexa',
  ],
  ['Frontend Development for Designers', 'frontend-development-for-designers'],
  ['Animation for UI Designers', 'ui-animation'],
  ['Python for Web Developers', 'python-for-web-developers'],
];

export const STATUSES = ['available', 'active'];
