import PropTypes from 'prop-types';
import React from 'react';
import StyledNumberCheckInput from '../styles/StyledNumberCheckInput';

const NumberCheckInput = ({ label, isSelected, onClick, viewType }) => (
  <StyledNumberCheckInput
    isEdit={viewType === 'edit'}
    isSelected={isSelected}
    onClick={() => (viewType === 'view' ? null : onClick(label))}
  >
    <p className="ds-typography__small">{label}</p>
  </StyledNumberCheckInput>
);

NumberCheckInput.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  viewType: PropTypes.string,
};

export default NumberCheckInput;
