export const clickEventTracking = (payload) => {
  if (location.protocol !== 'https:') {
    return false;
  }

  const blob = new Blob([JSON.stringify(payload)], {
    type: 'application/json',
  });
  navigator.sendBeacon('/api/v1/analytics/track', blob);
};

export const userEventTracking = (url, event) => {
  const payload = {
    data: {
      u: url,
    },
    event,
  };

  clickEventTracking(payload);
};

export const employerConnectorTracking = (event, step) => {
  const payload = {
    data: {
      ec_step: step,
    },
    event,
  };

  clickEventTracking(payload);
};

export const employerTracking = (event, data) => {
  const payload = {
    data,
    event,
  };

  clickEventTracking(payload);
};

export const exitInterviewTracking = (question, progress, event) => {
  const payload = {
    data: {
      p: progress,
      q: question,
    },
    event,
  };

  clickEventTracking(payload);
};

export const audioRecorderTracking = (question, event) => {
  const payload = {
    data: {
      q: question,
    },
    event,
  };

  clickEventTracking(payload);
};

export const startInterviewTracking = (category, event) => {
  const payload = {
    data: {
      c: category,
    },
    event,
  };

  clickEventTracking(payload);
};

export const resourceTracking = (category, url, event, section = null) => {
  const payload = {
    data: {
      c: category,
      s: section,
      u: url,
    },
    event,
  };
  clickEventTracking(payload);
};

export const motivationalMessageTracking = (id, url, numberOfLinks, event) => {
  const payload = {
    data: {
      id,
      l: numberOfLinks,
      u: url,
    },
    event,
  };
  clickEventTracking(payload);
};

export const accordionClickTracking = (heading, event) => {
  const payload = {
    data: {
      h: heading,
    },
    event,
  };
  clickEventTracking(payload);
};

export const interviewQuestionTracking = (question, event) => {
  const payload = {
    data: {
      q: question,
    },
    event,
  };

  clickEventTracking(payload);
};

export const interviewAnswerTracking = (answer, event) => {
  const payload = {
    data: {
      a: answer,
    },
    event,
  };

  clickEventTracking(payload);
};

export const cscNavTracking = (event, userId, menuItem, itemOrder) => {
  const payload = {
    data: {
      datetime: new Date(),
      item_order: itemOrder,
      menu_item: menuItem,
      user_id: userId,
    },
    event,
  };

  clickEventTracking(payload);
};
