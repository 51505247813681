export const datepicker = () => {
  $('.datepicker').datepicker({
    dateFormat: 'yy-mm-dd',
  });

  $('.initial-date').datepicker({
    dateFormat: 'yy-mm-dd',
    maxDate: 0
  });
};
