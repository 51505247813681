import styled from 'styled-components';

const StyledInteractiveTextMessage = styled.div`
display: flex;
align-items: center;
  .text-wrapper {
    flex-basis: 100%;
  }

  @media (max-width: 900px) {
    align-items: unset;
  }
`;
StyledInteractiveTextMessage.displayName = 'StyledInteractiveTextMessage';
export default StyledInteractiveTextMessage;
