import React, { useEffect } from 'react';
import ActiveContentContainer from '../../containers/EmployerConnectorCard/states/ActiveContentContainer';
import AvailableContentContainer from '../../containers/EmployerConnectorCard/states/AvailableContentContainer';
import InactiveContentContainer from '../../containers/EmployerConnectorCard/states/InactiveContentContainer';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import UnavailableContent from './states/UnavailableContent';
import Wrapper from './styles/Wrapper';

const EmployerConnectorCard = ({
  jobSeekingProfile,
  getJobSeekingProfile,
  user,
}) => {
  useEffect(() => {
    if (user && user.userType === 'instructor') {
      return;
    }
    getJobSeekingProfile();
  }, [user]);
  return (
    <Wrapper>
      {user && jobSeekingProfile && jobSeekingProfile.status ? (
        <div className='ds-grid__row'>
          <div className='ds-grid__col-xs-12'>
            <h5 className='ds-typography__h5 text-left'>Employer connector</h5>
            <>
              {
                {
                  active: <ActiveContentContainer />,
                  available: <AvailableContentContainer />,
                  inactive: <InactiveContentContainer />,
                  unavailable: <UnavailableContent />,
                }[jobSeekingProfile.status]
              }
            </>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </Wrapper>
  );
};

EmployerConnectorCard.propTypes = {
  getJobSeekingProfile: PropTypes.func.isRequired,
  jobSeekingProfile: PropTypes.shape({
    attachment: PropTypes.shape({
      attachableId: PropTypes.number,
      attachableType: PropTypes.string,
      contentType: PropTypes.string,
      fileIcon: PropTypes.string,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      id: PropTypes.number,
      s3Url: PropTypes.string,
      subtype: PropTypes.string,
    }),
    country: PropTypes.string,
    graduated: PropTypes.bool,
    graduation_date: PropTypes.string,
    id: PropTypes.number,
    motivation_statement: PropTypes.string,
    portfolio: PropTypes.string,
    program: PropTypes.string,
    progress: PropTypes.number,
    socials: PropTypes.array,
    specialization: PropTypes.string,
    state: PropTypes.string,
    status: PropTypes.string,
    student_name: PropTypes.string,
    willing_to_work_remote: PropTypes.bool,
  }),
  user: PropTypes.shape({
    userType: PropTypes.string,
  }),
};

export default EmployerConnectorCard;
