import styled from 'styled-components';

export const StyledCourseProgress = styled.div`
  margin: 60px 0px;
  text-align: left;
  width: ${(props) => (props.width ? props.width + 'px' : '100%')};
`;

export const ProgressBar = styled.div`
  height: 8px;
  background: var(--ds-color__aqua);
  border-radius: 100px;
`;

export const ProgressBarItem = styled.div`
  position: absolute;
  margin-top: -40px;
  width: auto;
  max-width: 150px;
  margin-left: ${(props) => (props.marginLeft ? `${props.marginLeft}px` : 0)};
`;

export const ProgressBarItemTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
  color: var(--ds-color__indigo);
  background-color: #eef5f7;
`;

export const ProgressBarItemCheck = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--ds-color__petrol);
  background-image: url('data:image/svg+xml,%3Csvg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M2.49565 4.80363C2.07138 4.45006 1.44081 4.50739 1.08725 4.93166C0.733683 5.35594 0.791008 5.9865 1.21528 6.34007L2.49565 4.80363ZM6.14118 9.14327L5.501 9.9115C5.91241 10.2543 6.52088 10.2122 6.88112 9.81595L6.14118 9.14327ZM14.024 1.95881C14.3955 1.55015 14.3654 0.9177 13.9567 0.546193C13.5481 0.174686 12.9156 0.204802 12.5441 0.61346L14.024 1.95881ZM1.21528 6.34007L5.501 9.9115L6.78137 8.37505L2.49565 4.80363L1.21528 6.34007ZM6.88112 9.81595L14.024 1.95881L12.5441 0.61346L5.40124 8.4706L6.88112 9.81595Z" fill="white"/%3E%3C/svg%3E%0A');
  background-repeat: no-repeat;
  background-position: 5px 8px;
`;

export const ProgressBarItemDate = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
  color: var(--ds-color__indigo);
  background-color: #eef5f7;
`;
