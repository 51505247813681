import AttachmentPreview from '../../../Uploader/components/AttachmentPreview';
import CallNoteForm from '../../../MentorCallNotes/components/shared/CallNoteForm';
import ModalWrapper from '../../../MentorCallNotes/components/shared/ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { keysToCamel } from '../../../../utils/keysConverter';

const ViewNoteModal = ({ handleCloseModal, currentCalendarEntry }) => {
  const attachment = keysToCamel(currentCalendarEntry.screenshot)
  return (
    <ModalWrapper currentState={'viewNote'} onNextClick={handleCloseModal}>
      <CallNoteForm
        isMessageSharable={currentCalendarEntry.sharable}
        showCSSection={currentCalendarEntry.sharable}
        isCSSectionEditable={false}
        viewType='view'
      />
      {attachment.s3Url && (
        <>
          <p className="ds-typography__body--bold">
            Off platform call screenshot
          </p>
          <AttachmentPreview
            attachment={attachment}
            className=''
          />
        </>
      )}
    </ModalWrapper>
  )
};
ViewNoteModal.propTypes = {
  currentCalendarEntry: PropTypes.shape({
    screenshot: PropTypes.object,
    sharable: PropTypes.bool,
    sharable_message: PropTypes.string,
  }),
  handleCloseModal: PropTypes.func.isRequired,
};

export default ViewNoteModal;
