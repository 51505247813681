import styled from 'styled-components';

const MentorNoteButtonStyles = styled.div`
  button:first-of-type {
    margin-right: 17px;
  }
`;

MentorNoteButtonStyles.displayName = 'MentorNoteButtonStyles';

export default MentorNoteButtonStyles;
