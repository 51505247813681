import InteractiveModalButton from './InteractiveModalButton';
import MarkdownText from './MarkdownText';
import PropTypes from 'prop-types';
import React from 'react';
import StyledBackgroundImage from './styles/StyledBackgroundImage';
import StyledContentWrapper from './styles/StyledContentWrapper';
import StyledModalParagraph from './styles/StyledModalParagraph';

const InteractiveImageMessage = ({
  header,
  subheader,
  description,
  messageType,
  onCloseModal,
}) => {
  return (
    <>
      <StyledBackgroundImage />
      <StyledContentWrapper>
        <h3 className="ds-typography__h3">{header}</h3>
        {subheader && <h5 className="ds-typography__h5">{subheader}</h5>}
        <StyledModalParagraph>
          {description && (
            <MarkdownText text={ description } />
          )}
          {messageType === 'AcceptedOffer' && (
            <span className="ds-typography__body--bold">
              Your CareerFoundry Team
            </span>
          )}
        </StyledModalParagraph>
        <InteractiveModalButton onCloseModal={onCloseModal} />
      </StyledContentWrapper>
    </>
  );
};

InteractiveImageMessage.propTypes = {
  description: PropTypes.string,
  header: PropTypes.string.isRequired,
  messageType: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  subheader: PropTypes.string,
};

export default InteractiveImageMessage;
