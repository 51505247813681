module.exports = (() => {
  const init = function () {
    bindFunctions();
    initializeElements();
  };

  const bindFunctions = () => {
    $(document).on('change', '#payment_refund_course_id', loadPayments)
  };

  const loadPayments = function() {
    $.ajax({
      url: '/en/admin/payment_refunds/load_payments/',
      data: {
        user_id: $('#payment_refund_user_id').val(),
        course_id: $('#payment_refund_course_id').val()
      },
      method: 'POST',
      dataType: 'script'
    });
  };

  return {
    init: init
  };
})();
