import styled from 'styled-components';

const StyledMediaResourceImage = styled.div`
  img {
    width: 100%;
    height: auto;
  }

  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 8px 0 0px;

  p {
    position: absolute;
    bottom: 8px;
    right: 8px;
    padding: 2px 9px 0;
    background-color: #fff;
    margin: 0;
  }

  & > p:first-of-type {
    align-self: center;
    margin: 0;
  }
`;

StyledMediaResourceImage.displayName = 'StyledMediaResourceImage';

export default StyledMediaResourceImage;
