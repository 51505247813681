import styled from 'styled-components';

const StyledJobSection = styled.div`
  h4 {
    margin-bottom: 32px;
  }
`;

StyledJobSection.displayName = 'StyledJobSection';

export default StyledJobSection;
