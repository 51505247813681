import React from 'react';

export const CheckIcon = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8997 1.17862V1.17862C19.9897 0.385854 18.6374 0.514433 17.8792 1.4658C17.8781 1.46724 17.8769 1.46868 17.8758 1.47012L8.38594 13.3766L3.5606 10.0132C2.54101 9.383 1.22585 9.73629 0.623088 10.8023C0.0670968 11.7856 0.306975 13.0488 1.1801 13.7354L9.22234 19.3411L21.1785 4.34024V4.34024C21.9367 3.38887 21.8137 1.97497 20.9038 1.18221C20.9024 1.18101 20.9011 1.17982 20.8997 1.17862L20.8997 1.17862Z"
      fill="#223C50"
    />
  </svg>
);

export const MicroPhoneIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.4591C11.68 16.4591 13.8525 14.4223 13.8525 11.9098V4.63106C13.8525 2.11859 11.68 0.0818176 9 0.0818176C6.32003 0.0818176 4.14748 2.11859 4.14748 4.63106V11.9098C4.14748 14.4223 6.32003 16.4591 9 16.4591ZM16.764 7.36061C16.2281 7.36061 15.7935 7.76796 15.7935 8.27045V11.9098C15.7935 15.4273 12.752 18.2788 9 18.2788C5.24804 18.2788 2.20647 15.4273 2.20647 11.9098V8.27045C2.20647 7.76796 1.77196 7.36061 1.23596 7.36061C0.699967 7.36061 0.265457 7.76796 0.265457 8.27045V11.9098V11.91C0.267628 16.0788 3.61032 19.5808 8.02962 20.0439V21.9182H9.97063V20.0439C14.3899 19.5806 17.7324 16.0787 17.7346 11.9099V8.27046C17.7346 7.76796 17.3 7.36061 16.764 7.36061Z"
      fill="#223C50"
    />
  </svg>
);

export const PlantIcon = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 12 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.56909 1.96764C7.78776 2.14074 6.66509 3.05334 6.06243 3.90166C5.68843 3.39063 5.61843 2.81406 5.78576 2.26548C6.27909 0.644566 8.86109 -0.849702 11.5744 0.565653C10.3499 0.728955 10.0245 1.6505 9.70357 2.55908C9.49424 3.1518 9.28686 3.739 8.83309 4.10659C8.25109 4.57625 7.57576 4.68889 6.96843 4.4967C6.74537 4.83383 6.64312 5.5406 6.63596 6.15999L6.64047 6.17329C6.64047 6.17329 6.62262 7.24943 6.62294 8.45774C8.51698 8.55184 12 9.0562 12 10.4822C12 10.7506 9.8298 22.4616 9.7812 22.7235C9.0996 23.5747 7.5408 24 5.9802 24C4.4208 24 2.8608 23.5747 2.1798 22.7235L2.09269 22.2431L2.09263 22.2428C1.68513 19.9975 0 10.7129 0 10.479C0 9.27343 2.87202 8.47688 5.79924 8.44189C5.59581 7.93989 5.35608 7.39686 5.26081 7.18303L5.26069 7.18308L5.25953 7.18016L5.21872 7.08896L5.21819 7.08138C5.08375 6.77472 4.88271 6.44503 4.69858 6.32522C4.43169 6.55137 4.06599 6.64045 3.67214 6.53183C3.36478 6.44645 3.13576 6.19884 2.9046 5.94891C2.55024 5.56578 2.19084 5.17719 1.5364 5.35909C2.61117 4.07175 4.23398 4.26038 4.82609 4.96087C5.02667 5.19787 5.113 5.4998 5.03159 5.83445C4.5473 5.54186 3.78617 5.32904 3.35404 5.41082C4.39507 5.61583 5.17383 6.193 5.60192 7.03824L5.60182 7.03828C5.68561 7.17694 5.77842 7.3537 5.87245 7.54563C5.89352 7.08479 5.92307 6.67135 5.96414 6.3639H5.96309C6.07909 4.61507 6.97243 3.06925 8.56909 1.96764ZM5.82541 9.61713C3.39402 9.64247 1.4556 10.1159 1.4556 10.6968C1.4556 11.0358 2.11595 11.3382 3.14925 11.5363C3.69281 11.24 4.67908 11.0331 5.82631 11.0036C5.8236 10.6398 5.82189 10.1505 5.82541 9.61713ZM9.02671 11.5135C9.99358 11.3154 10.605 11.023 10.605 10.6968C10.605 10.1477 8.87329 9.69457 6.63 9.62525C6.6368 10.1464 6.64924 10.6355 6.6703 11.0143C7.66908 11.066 8.52132 11.2532 9.02671 11.5135Z"
      fill="#223C50"
    />
  </svg>
);

export const InfoIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0.0818176C4.97005 0.0818176 0.0818176 4.97005 0.0818176 11C0.0818176 17.0299 4.97005 21.9182 11 21.9182C17.0299 21.9182 21.9182 17.0299 21.9182 11C21.9182 4.97005 17.0299 0.0818176 11 0.0818176ZM11.9099 16.4591C11.9099 16.9616 11.5025 17.3689 11 17.3689C10.4975 17.3689 10.0902 16.9616 10.0902 16.4591V10.0902C10.0902 9.58766 10.4975 9.1803 11 9.1803C11.5025 9.1803 11.9099 9.58766 11.9099 10.0902V16.4591ZM11 7.36061C10.2463 7.36061 9.63523 6.74958 9.63523 5.99583C9.63523 5.24209 10.2463 4.63106 11 4.63106C11.7537 4.63106 12.3648 5.24209 12.3648 5.99583C12.3648 6.74958 11.7537 7.36061 11 7.36061Z"
      fill="#223C50"
    />
  </svg>
);
