import React from 'react';
import StyledSpinner from './style/StyledSpinner';

const Spinner = () => (
  <StyledSpinner className="cf-spinner">
    <div className="cf-spinner__circle--1"></div>
    <div className="cf-spinner__circle--2"></div>
    <div className="cf-spinner__circle--3"></div>
  </StyledSpinner>
);

export default Spinner;
