import Banner from './Banner';
import CalIcon from './styles/CalIcon';
import Card from './styles/Card';
import CardBody from './styles/CardBody';
import Flex from './styles/Flex';
import { PropTypes } from 'prop-types';
import React from 'react';
import SubmissionProgressChart from './SubmissionProgressChart.js';
import WsgModal from '../../containers/WsgModal';

const WsgCard = ({
  date,
  dismissBanner,
  displayBanner,
  goal,
  openWsgModal,
  submissions,
}) => (
  <>
    <WsgModal />
    <Card>
      {displayBanner && <Banner dismissBanner={dismissBanner} />}
      <CardBody>
        <div className='ds-grid__row'>
          <div className='ds-grid__col-xs-8'>
            <h4
              id='wsgCardTitle'
              className='ds-typography__h4 ds-color__indigo'
            >
              Weekly Submission Goal
            </h4>
          </div>
          <div className='ds-grid__col-xs-4 ds-grid__end-xs'>
            <button className='ds-button__secondary' onClick={openWsgModal}>
              Adjust
            </button>
          </div>
        </div>
        <div className='ds-grid__row'>
          <div className='ds-grid__col-xs-5 ds-grid__col-sm-3'>
            <SubmissionProgressChart goal={goal} submissions={submissions} />
          </div>
          <Flex className='ds-grid__col-xs-6 ds-grid__col-sm-8 ds-grid__col-xs-offset-1'>
            <p className='ds-typography__body ds-typography__body--bold ds-color__indigo'>
              At this pace you'll finish by:
            </p>
            <p className='ds-typography__body ds-color__indigo'>
              <CalIcon className='ds-icon__calendar' />
              <span>{date}</span>
            </p>
          </Flex>
        </div>
      </CardBody>
    </Card>
  </>
);

WsgCard.propTypes = {
  date: PropTypes.string,
  dismissBanner: PropTypes.func.isRequired,
  displayBanner: PropTypes.bool,
  goal: PropTypes.number,
  openWsgModal: PropTypes.func.isRequired,
  submissions: PropTypes.number,
};

export default WsgCard;
