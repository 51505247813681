import PropTypes from 'prop-types';
import React from 'react';
import Step from './Step';
import StyledStepper from './styles/StyledStepper';

const Stepper = ({ steps, onClick = () => {} }) => {
  return (
    <StyledStepper isClickable={typeof onClick === 'function'}>
      {steps.map((step, index) => {
        return (
          <Step key={step.stepCount} stepCount={step.stepCount} stepState={step.stepState} onClick={() => onClick(index)}/>
        );
      })}
    </StyledStepper>
  );
};

Stepper.propTypes = {
  onClick: PropTypes.func,
  steps: PropTypes.array.isRequired,
};

export default Stepper;
