import React from 'react';

const BookSearch = () => (
  <svg
    width="62"
    height="53"
    viewBox="0 0 62 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.4987 37.2184H9.51433C7.76993 37.2184 6.35681 35.8052 6.35681 34.0609V3.15752C6.35681 1.41312 7.76993 0 9.51433 0H58.4987C60.2431 0 61.6562 1.41312 61.6562 3.15752V34.0609C61.6562 35.8029 60.2431 37.2184 58.4987 37.2184Z"
      fill="#A5DBE5"
    />
    <path
      d="M27.1759 43.2762H1.09111C0.488801 43.2762 0 42.7874 0 42.1851V7.88335C0 7.28104 0.488801 6.79224 1.09111 6.79224H23.1706C25.9806 6.79224 28.2671 9.07871 28.2671 11.8887V42.1851C28.2671 42.7874 27.7759 43.2762 27.1759 43.2762ZM1.85327 41.423H26.4138V11.8887C26.4138 10.1003 24.959 8.64551 23.1706 8.64551H1.85327V41.423Z"
      fill="#223C50"
    />
    <path
      d="M53.4648 43.2762H27.6279C26.9584 43.2762 26.414 42.7318 26.414 42.0624V11.8887C26.414 9.07871 28.7005 6.79224 31.5105 6.79224H53.4672C54.1367 6.79224 54.6811 7.33664 54.6811 8.00613V42.06C54.6811 42.7318 54.1343 43.2762 53.4648 43.2762ZM28.2673 41.423H52.8278V8.64551H31.5105C29.7221 8.64551 28.2673 10.1003 28.2673 11.8887V41.423Z"
      fill="#223C50"
    />
    <path
      d="M14.1338 36.1388H6.18789C5.11994 36.1388 4.2489 35.2701 4.2489 34.1998V30.9821C4.2489 29.9118 5.11762 29.0431 6.18789 29.0431H14.1338C15.2017 29.0431 16.0728 29.9118 16.0728 30.9821V34.1998C16.0728 35.2701 15.2017 36.1388 14.1338 36.1388ZM6.18789 30.4354C5.88442 30.4354 5.63886 30.6809 5.63886 30.9844V34.2021C5.63886 34.5056 5.88442 34.7512 6.18789 34.7512H14.1338C14.4373 34.7512 14.6828 34.5056 14.6828 34.2021V30.9844C14.6828 30.6809 14.4373 30.4354 14.1338 30.4354H6.18789Z"
      fill="#223C50"
    />
    <path
      d="M9.49136 15.0162H5.26358C4.87903 15.0162 4.5686 14.7058 4.5686 14.3212C4.5686 13.9366 4.87903 13.6262 5.26358 13.6262H9.49368C9.87823 13.6262 10.1887 13.9366 10.1887 14.3212C10.1887 14.7058 9.87592 15.0162 9.49136 15.0162Z"
      fill="#223C50"
    />
    <path
      d="M49.2325 14.9652H45.1599C44.7753 14.9652 44.4649 14.6547 44.4649 14.2702C44.4649 13.8856 44.7753 13.5752 45.1599 13.5752H49.2325C49.617 13.5752 49.9274 13.8856 49.9274 14.2702C49.9274 14.6547 49.617 14.9652 49.2325 14.9652Z"
      fill="#223C50"
    />
    <path
      d="M22.3553 31.3065H18.0465C17.6619 31.3065 17.3515 30.996 17.3515 30.6115C17.3515 30.2269 17.6619 29.9165 18.0465 29.9165H22.3553C22.7399 29.9165 23.0503 30.2269 23.0503 30.6115C23.0503 30.996 22.7399 31.3065 22.3553 31.3065Z"
      fill="#223C50"
    />
    <path
      d="M22.3553 35.3141H18.0465C17.6619 35.3141 17.3515 35.0037 17.3515 34.6192C17.3515 34.2346 17.6619 33.9242 18.0465 33.9242H22.3553C22.7399 33.9242 23.0503 34.2346 23.0503 34.6192C23.0503 35.0037 22.7399 35.3141 22.3553 35.3141Z"
      fill="#223C50"
    />
    <path
      d="M22.4874 15.0162H11.6364C11.2519 15.0162 10.9415 14.7058 10.9415 14.3212C10.9415 13.9366 11.2519 13.6262 11.6364 13.6262H22.4874C22.8719 13.6262 23.1823 13.9366 23.1823 14.3212C23.1823 14.7058 22.8719 15.0162 22.4874 15.0162Z"
      fill="#223C50"
    />
    <path
      d="M43.149 14.9652H32.7012C32.3166 14.9652 32.0062 14.6547 32.0062 14.2702C32.0062 13.8856 32.3166 13.5752 32.7012 13.5752H43.149C43.5336 13.5752 43.844 13.8856 43.844 14.2702C43.844 14.6547 43.5336 14.9652 43.149 14.9652Z"
      fill="#223C50"
    />
    <path
      d="M22.4227 20.342H5.19888C4.81433 20.342 4.50391 20.0316 4.50391 19.647C4.50391 19.2624 4.81433 18.952 5.19888 18.952H22.4227C22.8073 18.952 23.1177 19.2624 23.1177 19.647C23.1177 20.0316 22.8073 20.342 22.4227 20.342Z"
      fill="#223C50"
    />
    <path
      d="M22.4874 25.6099H5.26358C4.87903 25.6099 4.5686 25.2995 4.5686 24.9149C4.5686 24.5304 4.87903 24.22 5.26358 24.22H22.4874C22.872 24.22 23.1824 24.5304 23.1824 24.9149C23.1824 25.2995 22.872 25.6099 22.4874 25.6099Z"
      fill="#223C50"
    />
    <path
      d="M49.3226 19.9992H32.7381C32.3535 19.9992 32.0431 19.6888 32.0431 19.3042C32.0431 18.9197 32.3535 18.6093 32.7381 18.6093H49.3226C49.7071 18.6093 50.0175 18.9197 50.0175 19.3042C50.0175 19.6888 49.7071 19.9992 49.3226 19.9992Z"
      fill="#223C50"
    />
    <path
      d="M49.3852 24.9798H32.8007C32.4161 24.9798 32.1057 24.6694 32.1057 24.2848C32.1057 23.9003 32.4161 23.5898 32.8007 23.5898H49.3852C49.7697 23.5898 50.0802 23.9003 50.0802 24.2848C50.0802 24.6694 49.7674 24.9798 49.3852 24.9798Z"
      fill="#223C50"
    />
    <path
      d="M49.2603 29.9605H32.6758C32.2913 29.9605 31.9808 29.6501 31.9808 29.2655C31.9808 28.881 32.2913 28.5706 32.6758 28.5706H49.2603C49.6448 28.5706 49.9553 28.881 49.9553 29.2655C49.9553 29.6501 49.6448 29.9605 49.2603 29.9605Z"
      fill="#223C50"
    />
    <path
      d="M49.3852 35.1544H32.8007C32.4161 35.1544 32.1057 34.8439 32.1057 34.4594C32.1057 34.0748 32.4161 33.7644 32.8007 33.7644H49.3852C49.7697 33.7644 50.0802 34.0748 50.0802 34.4594C50.0802 34.8439 49.7674 35.1544 49.3852 35.1544Z"
      fill="#223C50"
    />
    <path
      d="M40.8786 38.3952C39.6902 38.8933 38.6083 39.4632 37.4593 39.806C30.6462 41.8423 23.4462 37.2277 22.4385 30.22C21.5003 23.7127 25.7119 17.8563 32.1728 16.6841C38.6292 15.5119 44.9674 20.0849 45.9195 26.5922C46.3434 29.4856 45.7944 32.1729 44.2701 34.6609C44.1126 34.918 44.1102 35.0732 44.3442 35.2609C44.5203 35.3999 44.6709 35.5713 44.8261 35.7358C45.2871 36.2246 45.7087 36.6949 46.5172 36.63C47.1427 36.5813 47.6917 36.9613 48.1666 37.3898C51.3635 40.2717 54.5673 43.1442 57.7619 46.0284C59.0916 47.2307 59.1287 49.1048 57.8638 50.3859C57.2986 50.9581 56.731 51.528 56.1565 52.0932C54.894 53.3349 53.0175 53.3025 51.8291 51.989C48.9427 48.7967 46.0701 45.5906 43.1882 42.396C42.6114 41.7566 42.3334 41.0153 42.3566 40.1558C42.3589 40.04 42.3427 39.8848 42.2686 39.8084C41.789 39.2964 41.2863 38.8053 40.8786 38.3952ZM23.8401 28.369C23.8401 34.0864 28.4408 38.6802 34.186 38.6918C39.8477 38.7033 44.467 34.0887 44.4763 28.4084C44.4855 22.6795 39.8801 18.0602 34.1628 18.0602C28.464 18.0625 23.8401 22.6795 23.8401 28.369ZM43.172 36.3682C42.8083 36.7203 42.4701 37.0516 42.1875 37.325C42.4817 37.6308 42.8083 37.9713 43.1141 38.2887C43.4361 37.9667 43.772 37.6331 44.0848 37.3226C43.7651 36.9914 43.4384 36.6485 43.172 36.3682Z"
      fill="#223C50"
    />
    <g opacity="0.96">
      <path
        opacity="0.96"
        d="M34.0886 38.701C39.8242 38.701 44.4739 34.0513 44.4739 28.3157C44.4739 22.5801 39.8242 17.9304 34.0886 17.9304C28.353 17.9304 23.7033 22.5801 23.7033 28.3157C23.7033 34.0513 28.353 38.701 34.0886 38.701Z"
        fill="white"
      />
    </g>
    <path
      d="M29.7384 25.2022C29.7384 24.6462 29.9168 24.0833 30.2735 23.5134C30.6303 22.9435 31.1515 22.4709 31.8349 22.0956C32.5183 21.7204 33.3175 21.535 34.2303 21.535C35.0781 21.535 35.8264 21.6926 36.4774 22.0053C37.126 22.318 37.6287 22.7443 37.9832 23.2817C38.3376 23.8192 38.5137 24.4053 38.5137 25.0377C38.5137 25.5358 38.4117 25.9713 38.2102 26.3443C38.0086 26.7196 37.7677 27.0416 37.4897 27.3126C37.2117 27.5837 36.7137 28.0423 35.9932 28.6864C35.794 28.867 35.6341 29.0269 35.516 29.1636C35.3955 29.3003 35.3075 29.4277 35.2496 29.5412C35.1917 29.6547 35.1453 29.7682 35.1129 29.884C35.0805 29.9975 35.0318 30.1991 34.9693 30.484C34.8581 31.0933 34.5106 31.3968 33.9245 31.3968C33.621 31.3968 33.3639 31.2972 33.1577 31.0979C32.9515 30.8987 32.8473 30.6045 32.8473 30.2107C32.8473 29.7196 32.9237 29.2933 33.0766 28.9342C33.2295 28.5752 33.4311 28.2578 33.6812 27.9867C33.9314 27.7157 34.272 27.3914 34.6982 27.0161C35.0735 26.6895 35.3422 26.4416 35.509 26.2748C35.6758 26.108 35.8171 25.9227 35.9307 25.7165C36.0442 25.5126 36.1021 25.2902 36.1021 25.0493C36.1021 24.5814 35.9283 24.1875 35.5808 23.8655C35.2334 23.5435 34.7839 23.3837 34.2349 23.3837C33.5909 23.3837 33.1183 23.5458 32.8125 23.8702C32.509 24.1945 32.2519 24.6717 32.0411 25.3041C31.8419 25.9644 31.4643 26.2956 30.9083 26.2956C30.5793 26.2956 30.3037 26.1798 30.0789 25.9481C29.8496 25.7234 29.7384 25.4732 29.7384 25.2022ZM34.0195 34.8207C33.6627 34.8207 33.35 34.7049 33.0859 34.4732C32.8195 34.2415 32.6874 33.9195 32.6874 33.5025C32.6874 33.1342 32.8172 32.8238 33.0743 32.5736C33.3314 32.3234 33.6465 32.196 34.0218 32.196C34.3901 32.196 34.7005 32.3211 34.9507 32.5736C35.2009 32.8261 35.3283 33.1342 35.3283 33.5025C35.3283 33.9126 35.1963 34.2346 34.9322 34.4686C34.6704 34.7025 34.3646 34.8207 34.0195 34.8207Z"
      fill="#223C50"
    />
  </svg>
);

export { BookSearch };
