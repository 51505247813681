import React from 'react';

const WhatToConsider = () => (
  <>
    <p className='ds-typography__body break'>
      While each company will establish a policy that works for them there are
      some things to consider when negotiating for or accepting a remote or
      hybrid role:
    </p>
    <ul>
      <li>
        Do you and the team have access to digital tools that make remote work
        possible?
      </li>
      <li>Are there clear guidelines on what equipment is provided/used?</li>
      <li>How effective is the team at collaborating or even communicating?</li>
      <li>Is the employer open/willing to make reasonable accommodations?</li>
      <li>
        Do you and the team have access to digital tools that make remote work
        possible?
      </li>
    </ul>
    <p className='ds-typography__body break'>
      This can be especially useful to gauge for{' '}
      <strong>neurodiverse professionals</strong> who may need specific things
      like one clear way for tasks to be assigned, or a consistent work
      environment. Some things you can work on now to prepare for a remote or a
      hybrid work environment is to practice:
    </p>
    <ul>
      <li>Communicating your needs</li>
      <li>Asking questions to gain clarity</li>
      <li>Speaking up to contribute to discussions</li>
    </ul>
    <p className='ds-typography__body break'>
      For more tips on how to request a more flexible schedule check out:{' '}
      <a
        onClick={() =>
          window.open(
            'https://www.forbes.com/sites/forbeshumanresourcescouncil/2021/11/04/eight-tips-for-employees-to-request-more-flexible-schedules/?sh=52d25b2676c3'
          )
        }
      >
        Eight Tips For Employees To Request More Flexible Schedules.
      </a>
    </p>
    <p className='ds-typography__body break'>
      To further assess your Remote Work Readiness check out this{' '}
      <a
        onClick={() =>
          window.open(
            'https://www.kudos.com/blog/communication-and-remote-work-in-2021-assess-your-skills'
          )
        }
      >
        Communication Skills Assessment
      </a>{' '}
      and this{' '}
      <a
        onClick={() =>
          window.open(
            'https://snaphunt.com/resources/sourcing-and-assessing-talent/how-to-determine-if-a-candidate-could-be-a-good-remote-worker'
          )
        }
      >
        Candidate Assessment
      </a>{' '}
      that shows what employers look for in a remote worker.
    </p>
  </>
);

export default WhatToConsider;
