import ApiService from "./ApiService";

class EnrollmentLinkService {
  expireLink(id, authToken) {
    let params = {
      authenticity_token: authToken,
      expireLink: true
    };
    const apiService = new ApiService(`/api/v1/admin/enrollment_links/${id}`);
    return apiService.makePutCall(params).then(result => {
      return result;
    })
    .catch(error => {
      throw error;
    })
  };

  updateLink(id, state, authToken){
    let params = {
      authenticity_token: authToken,
      state: state
    };
    const apiService = new ApiService(`/api/v1/admin/enrollment_links/${id}`);
    return apiService.makePutCall(params).then(result => {
      return result;
    })
    .catch(error => {
      throw error;
    })
  };

};

export default EnrollmentLinkService;
