import BSG from './BSG';
import EnglishTest from './EnglishTest';
import { Header } from '@careerfoundry/bubble';
import React from 'react';
import Terms from './Terms';
import { Content } from '../../styles';
import { StyledDocuments } from './styles';
import { DocumentProps } from '../../interfaces';

const Documents: React.FC<DocumentProps> = ({
  admission,
  handleModalAction,
  handleUpdateAdmission,
}) => {
  return (
    <StyledDocuments>
      <Content>
        <Header tag='h4' variant='h4'>
          Documents
        </Header>
        <BSG
          data={admission['afaVoucher']}
          handleModalAction={handleModalAction}
          handleUpdateAdmission={handleUpdateAdmission}
        />
        <Terms
          data={admission['termsOfParticipation']}
          handleModalAction={handleModalAction}
        />
        <EnglishTest
          data={admission['englishTest']}
          handleModalAction={handleModalAction}
          handleUpdateAdmission={handleUpdateAdmission}
        />
      </Content>
    </StyledDocuments>
  );
};

export default Documents;
