import { dismissBanner, getWsgData } from './actions';
import PropTypes from 'prop-types';
import React from 'react';
import WsgCard from '../../components/WsgCard';
import { connect } from 'react-redux';
import moment from 'moment';
import { openWsgModal } from '../../../actions';

const WsgCardContainer = ({
  attendeeId,
  attendeeTargetCompletion,
  getWsgData,
  ...rest
}) => {
  React.useEffect(() => {
    (async () => {
      getWsgData(attendeeId, attendeeTargetCompletion);
    })();
  }, []);

  return <WsgCard {...rest} />;
};

WsgCardContainer.propTypes = {
  attendeeId: PropTypes.number.isRequired,
  attendeeTargetCompletion: PropTypes.string.isRequired,
  getWsgData: PropTypes.func.isRequired,
};

export const mapState = (
  { wsg: { displayBanner, estimatedCompletion, submissionGoal, submissions } },
  { attendeeId, attendeeTargetCompletion }
) => ({
  attendeeId,
  attendeeTargetCompletion: moment(new Date(attendeeTargetCompletion)).format(
    'MMM D, YYYY'
  ),
  date: estimatedCompletion,
  displayBanner,
  goal: submissionGoal,
  submissions,
});

export const mapDispatch = (dispatch) => ({
  dismissBanner: () => dispatch(dismissBanner()),
  getWsgData: (attendeeId, atc) => dispatch(getWsgData(attendeeId, atc)),
  openWsgModal: () => dispatch(openWsgModal()),
});

export default connect(mapState, mapDispatch)(WsgCardContainer);
