import QuickTourIcon from '../../../components/icons/QuickTourIcon';
import React from 'react';
import { connect } from 'react-redux';
import { getCurrentCoachmark } from '../../../helpers';
import { toggleTourStatus as toggleCoarseTourStatus } from '../../../../CourseDashboard/OnboardingTour/actions';
import { toggleTourStatus as toggleExcerciseTour } from '../../../../ExercisePage/OnboardingTour/actions';
import { toggleTourStatus } from '../../../../DashboardMainPage/actions';

export const pageNameToActionMap = {
  'course-home': toggleCoarseTourStatus,
  'exercise-page': toggleExcerciseTour,
  main: toggleTourStatus,
};

const mapStateToProps = (state) => {
  const { attendeesData, common } = state.sideNavList;
  const currentCoachmark = getCurrentCoachmark(
    window.location.pathname,
    attendeesData.attendees || []
  );
  const isVisible = !!(common.isStudent && currentCoachmark);

  return {
    currentCoachmark,
    isVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onToggleTooltip: (variant) => dispatch(pageNameToActionMap[variant](true)),
});

const QuickTourIconContainer = (props) => {
  return <QuickTourIcon {...props} />;
};

QuickTourIconContainer.propTypes = {
  ...QuickTourIcon.propTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickTourIconContainer);
