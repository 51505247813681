import { constants } from '../../constants';

const diffDays = (to, from) => {
  const diffTime = Math.abs(to - from);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const validateInput = (values) => {
  const errors = {};
  const today = new Date();
  const to = new Date(values.to);
  const from = new Date(values.from);
  const future = from > today;

  if (from && (!future || diffDays(today, from) <= 2)) {
    errors.from = constants.FORM_ERROR_TOO_EARLY;
  }

  if (to && from && from > to) {
    errors.to = constants.FORM_ERROR_END_BEFORE_START;
  }

  return errors;
};
