import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

const useBarWidth = () => {
  const calculateWidth = () => {
    return window.innerWidth > 580 ? 550 : window.innerWidth * 0.8;
  };
  const [barWidth, setBarWidth] = useState(calculateWidth());
  useEffect(() => {
    const handleResize = () => {
      setBarWidth(calculateWidth());
    };
    window.addEventListener('resize', debounce(handleResize, 500));
    return () => {
      window.removeEventListener('resize', debounce(handleResize, 500));
    };
  }, []);
  return barWidth;
};

export default useBarWidth;
