import styled from 'styled-components';

const StyledContentSection = styled.div`
  .action-button {
    display: block;
    margin-bottom: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 313px;
    margin: 0 0 16px;
    height: 48px;
  }

  @media (max-width: 767px) {
    .action-button {
      width: 100% !important;
    }
  }

  padding-left: ${(props) => !props.mobile && '5%'};

  ul {
    padding: 0 0 0 20px;
    margin-bottom: 45px;
    li {
      font-size: 18px;
    }
  }

  @media (max-width: 767px) {
    ul {
      margin-bottom: 32px;
      padding: 0 0 0 16px;

      li {
        font-size: 16px !important;
      }
    }
  }

  li {
    margin-bottom: 2%;
  }

  margin-bottom: ${(props) => props.mobile && '5%'};
`;

export default StyledContentSection;
