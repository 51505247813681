import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tour from 'reactour';

const GuidedTour = ({
  cookieName,
  getGuidedTourSteps,
  isMobile,
}) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [arrowClass, setArrowClass] = useState('top');
  const hasDismissedCookie = !!document.cookie.match(new RegExp(cookieName, 'g'));
  const shouldShowTour = !isMobile && !hasDismissedCookie;

  useEffect(() => {
    shouldShowTour && getGuidedTourSteps().then(res => {
      setSteps(res);
      setIsTourOpen(true);
    });
  }, []);

  const updateCurrentStep = curr => {
    setCurrentStep(curr);
    setArrowClass(steps[curr].arrowPosition);
  };

  const onClose = () => {
    CF.createCookie(cookieName, 'true');
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  };

  const nextButton = (
    <span className="ds-button__tertiary tourNextButton">
      OK <i className="fa fa-arrow-right"></i>
    </span>
  );
  const prevButton = (
    <span className={`ds-button__tertiary tourBackButton ${currentStep === 0 && 'hidden'}`}>
      <i className="fa fa-arrow-left"></i>
    </span>
  );
  const lastStepNextButton = <span className="ds-button__tertiary tourNextButton">Finish</span>;

  return (
    shouldShowTour && <Tour
      className={`maskWrapper maskWrapper__${arrowClass}`}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => setIsTourOpen(false)}
      showNavigation={false}
      showNumber={false}
      nextButton={nextButton}
      prevButton={prevButton}
      lastStepNextButton={lastStepNextButton}
      maskClassName="guidedMask"
      onBeforeClose={onClose}
      getCurrentStep={curr => updateCurrentStep(curr)}
      rounded={4}
    />
  );
};

GuidedTour.propTypes = {
  cookieName: PropTypes.string.isRequired,
  getGuidedTourSteps: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default GuidedTour;
