import styled from 'styled-components';

export const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0 rgba(51, 51, 51, 0.1);
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 40px 32px;
`;

const InfoWrapper = styled.div`
  margin-bottom: 32px;
  ${({ borderRight }) =>
    borderRight &&
    `
    border-right: 1px solid #E8EBED;
    margin-top: -40px;
    padding-top: 40px;
    margin-bottom: -40px;
    padding-bottom: 40px;
  `}

  ${({ borderBottom }) =>
    borderBottom &&
    `
    border-bottom: 1px solid #E8EBED;
    margin-left: -32px;
    padding-left: 32px;
    margin-right: -32px;
    padding-right: 32px;
  `}

  ${({ paddingLeft }) =>
    paddingLeft &&
    `
    padding-left: 32px;
  `}

  i:hover {
    cursor: pointer;
  }
`;

InfoWrapper.displayName = 'InfoWrapper';

const InfoSection = styled(Card)``;

InfoSection.displayName = 'InfoSection';

const SurveyLink = styled.a`
  display: inline-block;
  margin-bottom: 32px;
`;

SurveyLink.displayName = 'SurveyLink';

const CategoryHeading = styled.h4`
  margin-bottom: 16px;
`;

CategoryHeading.displayName = 'CategoryHeading';

const Section = styled.div`
  margin-bottom: 32px;
`;

Section.displayName = 'Section';

const SectionHeading = styled.h5`
  margin-bottom: 16px;
`;

SectionHeading.displayName = 'SectionHeading';

const Label = styled.p`
  font-size: 18px;
  margin-bottom: 8px;
  color: var(--ds-color__petrol--dark);
`;

Label.displayName = 'Label';

const DocumentLink = styled.a`
  color: var(--ds-color__indigo) !important;
  text-decoration: underline !important;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 32px;
`;

DocumentLink.displayName = 'DocumentLink';

export {
  InfoWrapper,
  InfoSection,
  SurveyLink,
  CategoryHeading,
  Section,
  SectionHeading,
  Label,
  DocumentLink,
};
