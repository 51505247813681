import ParagraphWithLabel from '../shared/ParagraphWithLabel';
import PropTypes from 'prop-types';
import React from 'react';
import StyledParagraphWithLink from '../../../JobApplicationTracker/components/styles/StyledParagraphWithLink';

const ProfileDetailsView = ({ jobSeekingProfile = {}, studentViewing = false }) => {
  const city = jobSeekingProfile.city ? jobSeekingProfile.city : jobSeekingProfile.state;
  const location = [city, jobSeekingProfile.country];

  return (
    <>
      <ParagraphWithLabel label="Name" paragraph={jobSeekingProfile.studentName} />
      <ParagraphWithLabel label="Program" paragraph={jobSeekingProfile.program} />
      <ParagraphWithLabel label="Specialization" paragraph={jobSeekingProfile.specialization} />
      <ParagraphWithLabel label="Location " paragraph={location.filter(Boolean).join(', ')} />
      {studentViewing && <StyledParagraphWithLink className="ds-typography__small text-left small">
          If this data isn’t correct, please update it in{' '}
        <a
          href={`/en/users/${jobSeekingProfile.slug}/settings`}
          className="ds-typography__anchor ds-typography__small"
          target="_blank"
          rel="noreferrer"
        >
              your student profile.
        </a>
      </StyledParagraphWithLink>
      }
    </>
  );
};

ProfileDetailsView.propTypes = {
  jobSeekingProfile: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    cvUrl: PropTypes.string,
    graduated: PropTypes.bool,
    graduationDate: PropTypes.string,
    id: PropTypes.number.isRequired,
    motivationStatement: PropTypes.string,
    portfolio: PropTypes.string,
    program: PropTypes.string,
    progress: PropTypes.number,
    slug: PropTypes.string.isRequired,
    socials: PropTypes.array,
    specialization: PropTypes.string,
    state: PropTypes.string,
    status: PropTypes.string.isRequired,
    studentName: PropTypes.string,
    willingToWorkRemote: PropTypes.bool,
  }),
  studentViewing: PropTypes.bool.isRequired,
};

export default ProfileDetailsView;
