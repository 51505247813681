import styled from 'styled-components';

const StyledScrollPanel = styled.div`
  width: 100%;
  height: 371px;
  overflow: auto;
  padding-right: 14px;
`;

StyledScrollPanel.displayName = 'StyledScrollPanel';

export default StyledScrollPanel;
