import styled from 'styled-components';

const StyledWebinarEventsSection = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 6px 0px #223c5029;
  border-radius: 4px;
`;

export default StyledWebinarEventsSection;

StyledWebinarEventsSection.displayName = 'StyledWebinarEventsSection';
