import styled from 'styled-components';

const StyledCardSubheader = styled.h4`
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 8px;
  }
`;

StyledCardSubheader.displayName = 'StyledCardSubheader';

export default StyledCardSubheader;
