import styled from 'styled-components';

const AppContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 50px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

AppContainer.displayName = 'AppContainer';

const Card = styled.div`
  background-color: var(--ds-color__int-content-primary);
  box-shadow: 0px 2px 3px rgba(34, 60, 80, 0.16);
`;

Card.displayName = 'Card';

export { AppContainer, Card };
