import React from 'react';
import WorkPreferences from '../../../EmployerConnector/modals/showModal/WorkPreferences';
import { connect } from 'react-redux';

const WorkPreferencesContainer = (props) => {
  return (
    <WorkPreferences {...props} />
  );
};

export const mapStateToProps = ({
  employerDashboard: { jobSeekingProfile },
}) => ({
  isEditView: false,
  jobSeekingProfile,
  studentViewing: false,
});

export default connect(mapStateToProps, null)(WorkPreferencesContainer);
