import { FormSection, Header, SectionWrapper, Title } from './styles';
import React, { useEffect, useState } from 'react';
import { documentByKey, errorMessage } from './helpers';
import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import SectionHeader from '../SectionHeader';
import { SubmitButton } from '../PersonalInformation/styles';
import UploadButton from './UploadButton';
import UploadLink from '../UploadLink';
import { getFileData } from '../PersonalInformation/helpers';
import { isEmpty } from 'lodash';

const Documents = ({
  data,
  formErrors,
  handleSubmit,
  loadingButton,
  removeDocument,
}) => {
  const formattedData = Object.values(data);
  const [state, setState] = useState(
    formattedData.flatMap((el) => el.documents)
  );
  const updateState = (key, file) =>
    setState([
      ...state.map((obj) => (obj.key === key ? { ...obj, data: file } : obj)),
    ]);

  useEffect(() => {
    if (!isEmpty(formErrors)) {
      window.scrollTo({ behavior: 'smooth', top: 0 });
    }
  }, [formErrors]);

  const handleFileUpload = (data, key) => {
    data.then((res) => {
      const file = {
        data: res.data,
        fileName: res.fileName,
        fileSize: res.fileSize,
      };
      updateState(key, file);
    });
  };

  const handleRemoveFile = (key) => {
    removeDocument(key);
    updateState(key, '');
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(state);
  };

  return (
    <SectionWrapper>
      <Header>
        <div>
          <h3 className="ds-typography__h3 text-left">Documents</h3>
          <p className="ds-typography__small text-center">* required field</p>
        </div>
        <p className="ds-typography__body">
          Your academic experience is valuable to us—and to our students! Please
          upload documentation of your academic and professional qualifications
          so that we can provide evidence of your credentials to our students
          and certifying institutions. Please be aware that the document limit
          is 5 MB per file. Formats jpg, png and pdf are accepted.
        </p>
      </Header>
      <form onSubmit={handleFormSubmit}>
        {formattedData.map((section, i) => (
          <FormSection key={section.name} last={formattedData.length === i + 1}>
            <SectionHeader
              headline={section.name}
              tooltipText={section.tooltip}
            />
            {section.documents.map((doc) => (
              <div key={doc.key}>
                <Title className="ds-typography__body">
                  {doc.label}
                  {doc.required && '*'}
                </Title>
                {documentByKey(state, doc.key) ? (
                  <UploadLink
                    data={documentByKey(state, doc.key)}
                    removeFile={() => handleRemoveFile(doc.key)}
                    error={errorMessage(formErrors, doc.key)}
                  />
                ) : (
                  <UploadButton
                    required={doc.required}
                    handleChange={(ev) =>
                      handleFileUpload(getFileData(ev), doc.key)
                    }
                  />
                )}
              </div>
            ))}
          </FormSection>
        ))}
        <SubmitButton type="submit" className="ds-button__primary">
          {loadingButton ? (
            <LoadingSpinner />
          ) : (
            <>
              Save and continue<i className="ds-icon__arrow-thin-right"></i>
            </>
          )}
        </SubmitButton>
      </form>
    </SectionWrapper>
  );
};

Documents.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.shape({
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              data: PropTypes.string,
              fileName: PropTypes.string,
              fileSize: PropTypes.number,
            }),
          ]),
          key: PropTypes.string,
          label: PropTypes.string,
          required: PropTypes.bool,
        })
      ),
      name: PropTypes.string,
      tooltip: PropTypes.string,
    })
  ),
  formErrors: PropTypes.object,
  handleSubmit: PropTypes.func,
  loadingButton: PropTypes.bool,
  removeDocument: PropTypes.func,
};

export default Documents;
