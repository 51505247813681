import {
  Backdrop,
  DropDownHead,
  DropDownList,
  DropDownListItem,
  StyledDropDown,
} from './styles';
import React, { useState } from 'react';
import { ArrowDown } from '../../Icons';
import PropTypes from 'prop-types';
const Dropdown = ({ options, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClickItem = (fn) => {
    fn && fn();
    setIsOpen(false);
  };

  return (
    <StyledDropDown>
      <DropDownHead onClick={() => setIsOpen(!isOpen)} role='dropDownHead'>
        <div>{title}</div>
        <ArrowDown />
      </DropDownHead>
      {isOpen && (
        <>
          <Backdrop onClick={() => setIsOpen(false)}></Backdrop>
          <DropDownList className='ds-shadow__4'>
            {options.map((option) => (
              <DropDownListItem
                key={option.value}
                className='ds-typography__body'
                role='dropDownListItem'
                onClick={() => onClickItem(option.onClick)}
              >
                {option.text}
              </DropDownListItem>
            ))}
          </DropDownList>
        </>
      )}
    </StyledDropDown>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array,
  title: PropTypes.object,
};

export default Dropdown;
