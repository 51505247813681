import React, { useEffect } from 'react';
import Loading from '../../../../shared/components/Loading';
import OverviewPage from '../../components/OverviewPage';
import { connect } from 'react-redux';
import { getServices, handleModalClose, setModalAction } from '../../actions';

interface OverviewPageContainerProps {
  loading: boolean;
  getServices: () => void;
  modalAction: string;
  handleModalClose: () => void;
  setModalAction: (modalAction: string) => void;
}

interface IState {
  offPlatformServices: {
    loading: boolean;
    modalAction: string;
  };
}

export const OverviewPageContainer: React.FC<OverviewPageContainerProps> = ({
  getServices,
  loading,
  handleModalClose,
  modalAction,
  setModalAction,
}) => {
  useEffect(() => {
    getServices();
  }, []);

  return (
    <Loading isLoading={loading}>
      <OverviewPage
        handleModalClose={handleModalClose}
        modalAction={modalAction}
        setModalAction={setModalAction}
      />
    </Loading>
  );
};

export const mapStateToProps = ({
  offPlatformServices: { loading, modalAction },
}: IState) => ({
  loading,
  modalAction,
});

export const mapDispatchToProps = (dispatch: any) => ({
  getServices: () => dispatch(getServices()),
  handleModalClose: () => dispatch(handleModalClose()),
  setModalAction: (modalAction: string) =>
    dispatch(setModalAction(modalAction)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewPageContainer);
