import styled from 'styled-components';

const StyledSection = styled.div`
  margin-bottom: 24px;

  h5 {
    margin-bottom: 24px;
  }
`;

StyledSection.displayName = 'StyledSection';

export default StyledSection;
