export const taskTimeRange = ({ min, max }) => {
  const minInHours = Math.floor(min / 60);
  const maxInHours = Math.floor(max / 60);
  return `
    ${
      maxInHours <= 1
        ? '< 1 hour'
        : minInHours >= 10
        ? '10+ hours'
        : `${minInHours} - ${maxInHours} hours`
    }
  `;
};

export const readTimeHumanized = (readTime) => {
  const hours = Math.floor(readTime / 60);
  const minutes = readTime % 60;

  return `
    ${hours ? `${hours} hour${hours === 1 ? '' : 's'}` : ''}
    ${hours && minutes ? ' and ' : ''}
    ${minutes ? `${minutes} minute${minutes === 1 ? '' : 's'}` : ''}
`;
};
