import { Link } from '@careerfoundry/bubble';
import styled, { css } from 'styled-components';

type LinkProps = {
  className: string;
};

const StyledLink = styled(Link)<LinkProps>`
  color: ${(props) =>
    props.className === 'disabled-link'
      ? 'var(--ds-color__int-content-secondary-disabled) !important;'
      : ''};
  pointer-events: ${(props) =>
    props.className === 'disabled-link' ? 'none' : 'pointer'};
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clippath: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
`;

const checkedStyles = css`
  &:before {
    content: '';
    display: block;
    width: 5px;
    height: 9px;
    border: solid #223c50;
    border-width: 0 2px 2px 0;
    margin-left: 3px;
    margin-top: 0px;
    transform: rotate(45deg);
  }
`;

type Props = {
  checked: boolean;
};

const StyledCheckbox = styled.div<Props>`
  border: 1.5px solid var(--ds-color__indigo);
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  transition: all 150ms;
  cursor: pointer;
  margin-top: 3px;
  margin-right: 6px;

  ${(props) => props.checked && checkedStyles};
`;

const TermsContainer = styled.div`
  padding: 0px 30px 30px 30px;

  .MessageWrapper {
    margin-bottom: 24px;
  }

  p {
    color: var(--ds-color__static-content-primary);
  }
`;

const CheckboxContainer = styled.label`
  display: flex;
  margin-top: 10px;
`;

HiddenCheckbox.displayName = 'HiddenCheckbox';
StyledCheckbox.displayName = 'StyledCheckbox';
CheckboxContainer.displayName = 'CheckboxContainer';
TermsContainer.displayName = 'TermsContainer';

export {
  StyledLink,
  StyledCheckbox,
  HiddenCheckbox,
  CheckboxContainer,
  TermsContainer,
};
