import styled from 'styled-components';

const MenuWrapper = styled.div`
  margin-bottom: 34px;
  cursor: pointer;

  h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    margin: 0 0 10px auto;
    font-weight: 700;
    font-stretch: unset;
    color: #515559;
    width: 223px;
  }
`;

MenuWrapper.displayName = 'MenuWrapper';
export default MenuWrapper;
