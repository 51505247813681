const careerfoundryCoach = require('../../../../../assets/javascripts/site/careerfoundry_coach.js');

module.exports = (() => {
  const init = function() {
    initializeDatePicker();
    bindFunctions();
    initializeValidation();
  }

  const bindFunctions = () => {
    $('#refundModal').on('show.bs.modal', addData)
    $('#refundModal').on('hide.bs.modal', clearData)
    $('.refundForm--js').on('submit', submitForm)
  }

  const addData = function(e) {
    let $link = $(e.relatedTarget);
    let name = $link.data('name');
    let paymentId = $link.data('payment-id');
    let $modal = $(this);

    $modal.find('.modalTitle--js').text('Create a refund for ' + name);
    $modal.find('.hiddenPaymentId--js').val(paymentId);
  }

  const clearData = () => $('.refundForm--js').trigger("reset");

  const submitForm = function(e) {
    e.preventDefault();

    let $form = $(this);
    let data = $form.serialize();

    if($form.valid()){
      $.ajax({
        url: '/en/admin/refunds/',
        method: 'POST',
        data: data,
        beforeSend: _disableSubmit(),
      })
      .done(_handleSuccess)
      .fail(_handleFailure)
    }
  }

  const _handleSuccess = r => window.location.href = `/en/admin/payments/${r.payment_id}/`;

  const _handleFailure = r => {
    _hideModal();
    _enableSubmit();
    var resp = $.parseJSON(r.responseText);
    CareerFoundyCoach.flashMessage(resp.message, resp.type);
  }

  const _hideModal = () => $('#refundModal').modal('hide');

  const _enableSubmit = () => $('.refundSubmit--js').attr('disabled', false);

  const _disableSubmit = () => {
    $('.refundSubmit--js').attr('disabled', true)
  };

  const initializeValidation = () => {
    $('.refundForm--js').validate({
      rules: {
        'refund[amount]': 'required'
      },
      messages: {
        'refund[amount]': 'Please fill out the amount field.'
      }
    })
  };

  const initializeDatePicker = () => {
    $(".datepicker").datepicker({
      dateFormat: 'yy-mm-dd'
    });
  };

  return {
    init: init
  };
})();
