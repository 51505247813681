import styled from 'styled-components';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

ModalContent.displayName = 'ModalContent';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  button:first-of-type {
    margin-right: 8px;
  }
`;

ButtonWrapper.displayName = 'ButtonWrapper';

export { ModalContent, ButtonWrapper };
