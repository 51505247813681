import { CAREER_SUPPORT_CENTER, MOBILE_VIEW_MAX_WIDTH } from './constants';
import {
  getCourseId,
  isCourseReadOnly,
  isExerciseReadOnly,
} from '../shared/helpers/courseHelpers';
import ApiService from '../../services/ApiService';
import { getAuthToken } from '../../utils/getAuthToken';
import { getUrlLastSegment } from '../../utils/getUrlLastSegment';
import { invert } from 'lodash';
import { redirectTo } from '../../utils/redirectTo';

const getPathnamesPageNamesMapping = (locale = 'en') => {
  const pathnamesToPageNames = {
    [`/${locale}/home`]: 'ROOT',
    [`/${locale}/career`]: 'MY_MENTOR_CALLS',
    [`/${locale}/career-support-center`]: 'CAREER_SUPPORT_CENTER',
    [`/${locale}/dashboards/main`]: 'DASHBOARD',
    [`/${locale}/communications`]: 'FAQ',
    [`/${locale}/signout`]: 'SIGNOUT',
    [`/${locale}/cf-plus`]: 'MY_CF_PLUS',
  };

  const pageNamesToPathnames = invert(pathnamesToPageNames);

  /**
   * We are using window.location.pathname to get the page name from the
   * pathnamesToPageNames map, and as the window.location.pathname does not
   * include query and hash parameters of the URL, we are adding the rest
   * of the actual URL for the FAQ page in the pageNamesToPathnames map.
   */
  pageNamesToPathnames.FAQ = `${pageNamesToPathnames.FAQ}?faq=true#sidebartabs`;

  pageNamesToPathnames.getMyActivityPathname = (isStudent, userNameSlug) => {
    if (isStudent) {
      return `/${locale}/activity`;
    } else {
      return `/${locale}/users/${userNameSlug}/reviews`;
    }
  };

  pageNamesToPathnames.getSettingsPathname = (userNameSlug) =>
    `/${locale}/users/${userNameSlug}/settings`;

  return {
    pageNamesToPathnames,
    pathnamesToPageNames,
  };
};

const getPageNameFromPathname = (pathname = '') => {
  const { pathnamesToPageNames } = getPathnamesPageNamesMapping();

  const pathnameParts = pathname.split('/');
  const lastPathnamePart = pathnameParts[pathnameParts.length - 1];

  let currentPageName = pathnamesToPageNames[pathname];

  if (lastPathnamePart === 'reviews' || lastPathnamePart === 'profile') {
    currentPageName = 'MY_ACTIVITIES';
  } else if (lastPathnamePart === 'mentor_dashboard') {
    currentPageName = 'DASHBOARD';
  }

  return currentPageName;
};

const getDiscourseCategoryUrl = (category = '') => {
  const domain = 'https://forum.careerfoundry.com';

  return category ? `${domain}/c/${category}` : domain;
};

const getCurrentCoachmark = (currentPagePathname = '', attendees = []) => {
  const urlLastSegment = getUrlLastSegment();
  const courseOrMainPage =
    ['main', 'course-home'].find((el) => el === urlLastSegment) ||
    (currentPagePathname.includes('/exercise/') && 'exercise-page');

  switch (courseOrMainPage) {
    case 'main':
      return courseOrMainPage;
    case 'course-home':
      return !isCourseReadOnly(attendees, getCourseId()) && courseOrMainPage;
    case 'exercise-page':
      return (
        !isExerciseReadOnly(attendees, currentPagePathname.split('/')[3]) &&
        courseOrMainPage
      );
    default:
      return false;
  }
};

const isCourseAccessible = ({ status = '' }) => {
  const inaccessibleStatuses = ['canceled', 'blocked'];

  return !inaccessibleStatuses.includes(status);
};

const isProgram = ({ course = {} }) => Array.isArray(course.courses);

const isCourseLinkEqualToCurrentPathname = ({
  course = {},
  currentPathname = '',
}) => {
  /**
   * The pathname of the Career Support Center is the same as the link
   * mentioned in its course data, and we do not want it to be considered
   * a course page.
   */
  if (course.name === CAREER_SUPPORT_CENTER) {
    return false;
  } else {
    return course.link === currentPathname;
  }
};

const isUserOnCoursePage = ({ courses = [], currentPathname = '' }) =>
  !!courses.find((course) => {
    if (isProgram({ course })) {
      return !!course.courses.find((course) =>
        isCourseLinkEqualToCurrentPathname({ course, currentPathname })
      );
    } else {
      return isCourseLinkEqualToCurrentPathname({ course, currentPathname });
    }
  });

const isMobileView = ({ windowWidth }) => windowWidth <= MOBILE_VIEW_MAX_WIDTH;

const trackNavigation = ({ itemName = '' }) => {
  const apiService = new ApiService('/api/v1/analytics/track');

  try {
    apiService.makePostCall({
      authenticity_token: getAuthToken(),
      data: {
        nav_icon: `Navigation.${itemName}`,
      },
      event: 'navbar_click',
    });
  } catch (error) {
    // TODO: Report to Rollbar
  }
};

const trackAndRedirect = ({
  track: { shouldTrack = true, itemName = '' },
  redirect: { shouldRedirect = true, url = '' },
}) => {
  if (shouldTrack) {
    trackNavigation({ itemName });
  }

  if (shouldRedirect) {
    redirectTo({ url });
  }
};

export {
  getCurrentCoachmark,
  getPathnamesPageNamesMapping,
  getPageNameFromPathname,
  getDiscourseCategoryUrl,
  isCourseAccessible,
  isProgram,
  isUserOnCoursePage,
  isMobileView,
  trackNavigation,
  trackAndRedirect,
};
