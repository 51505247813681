import React from 'react';

export function getCookie(name) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function getScreenshot() {
  const base64 = localStorage.getItem('callRequestScreenshot');
  const base64Parts = base64.split(',');
  const fileContent = base64Parts[1];

  const fileName = localStorage.getItem('callRequestScreenshotName'); // retrieve the file name
  const typedArray = new Uint8Array(
    atob(fileContent)
      .split('')
      .map(function (c) {
        return c.charCodeAt(0);
      })
  );
  const file = new File([typedArray], fileName, { type: 'image/jpeg' });
  return file;
}

export function setScreenshot(file, setFile) {
  const reader = new FileReader();
  reader.onload = function (event) {
    const base64 = event.target.result;
    localStorage.setItem('callRequestScreenshot', base64);
    localStorage.setItem('callRequestScreenshotName', file.name);
  };
  const a = (
    <a href={URL.createObjectURL(file)} download={file.name}>
      {file.name}
    </a>
  );
  setFile(a);
  reader.readAsDataURL(file);
}
