import PropTypes from 'prop-types';
import React from 'react';
import StyledSmallModal from './styles/StyledSmallModal';

function QuitFlowModal({
  handleConfirm,
  handleCancel,
  header,
  description,
  confirmButtonText = 'quit',
  cancelButtonText = 'continue',
}) {
  return (
    <StyledSmallModal>
      <i className='ds-typography__h4 ds-color__indigo ds-icon__info-fill'></i>
      <h4 className='ds-typography__h4 ds-color__indigo'>{header}</h4>
      <p>{description}</p>
      <span>
        <button className='ds-button__secondary' onClick={handleConfirm}>
          {confirmButtonText}
        </button>
        <button className='ds-button__primary' onClick={handleCancel}>
          {cancelButtonText}
        </button>
      </span>
    </StyledSmallModal>
  );
}

QuitFlowModal.propTypes = {
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  description: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
};

export default QuitFlowModal;
