import PropTypes from 'prop-types';
import React from 'react';
import StyledHighlight from './styles/StyledHighlight';
import StyledJobSearchTile from './styles/StyledJobSearchTile';
import { userEventTracking } from '../../../shared/helpers/tracking';

const JobSearchTile = ({
  description,
  icon,
  title,
  secondaryButton,
  links,
  className = '',
}) => {
  const handleOnClick = (link) => {
    if (link.event) {
      userEventTracking(link.link, link.event);
    }
  };

  return (
    <StyledJobSearchTile className='ds-grid__col-xl-4 ds-grid__col-lg-6 ds-grid__col-md-6 ds-grid__col-sm-6 ds-grid__col-xs-12'>
      <div className='inner'>
        <h5 className='ds-typography__h5 text-center'>{title}</h5>
        <div className='tile-icon'>{icon}</div>
        <p className='ds-typography__body description'>{description}</p>
        {secondaryButton && (
          <a
            href={secondaryButton.link}
            onClick={() => handleOnClick(secondaryButton)}
            className='ds-button__secondary link-row row-button'
            rel='noreferrer'
            target={secondaryButton.target || '_blank'}
          >
            {secondaryButton.text}
          </a>
        )}
        {links &&
          links.map((link) => (
            <a
              className='link-row'
              key={link.text}
              onClick={() => handleOnClick(link)}
              href={link.link}
              rel='noreferrer'
              target='_blank'
            >
              {link.text}
            </a>
          ))}
        <StyledHighlight />
      </div>
    </StyledJobSearchTile>
  );
};

JobSearchTile.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  links: PropTypes.array,
  secondaryButton: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default JobSearchTile;
