import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
  h5,
  p,
  i {
    display: inline-block;
  }

  i {
    margin-left: 16px;
    color: var(--ds-color__int-content);
    line-height: 0;
  }
`;

HeaderWrapper.displayName = 'HeaderWrapper';

const StyledTooltipContent = styled.div`
  max-width: 550px;

  .__react_component_tooltip.show {
    opacity: 1 !important;
  }

  p {
    color: var(--content__inv-static-primary) !important;
  }
`;

StyledTooltipContent.displayName = 'StyledTooltipContent';

interface statusProps {
  state?: string;
}

const StatusWrapper = styled.div<statusProps>`
  p {
    margin: 0;
    color: var(--ds-color__static-content-secondary);
    display: inline;
    text-transform: uppercase;
  }
  i {
    font-size: ${(props) =>
      props.state === 'done' ? '12px !important;' : 'auto;'};
  }
`;

StatusWrapper.displayName = 'StatusWrapper';

const StyledAccordionItemButton = styled(AccordionItemButton)<{
  locked: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: ${(props) => (props.locked === 'true' ? 'none' : 'auto')};

  i {
    color: var(--ds-color__int-content-primary-disabled);
    font-size: 29px;
    line-height: 0;
  }

  h3 {
    margin: 0;
    color: ${(props) =>
      props.locked === 'true'
        ? 'var(--ds-color__int-content-primary-disabled)'
        : 'var(--ds-bg-color__inv-static-primary)'};
  }
`;

StyledAccordionItemButton.displayName = 'StyledAccordionItemButton';

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 24px;

  i {
    color: var(--ds-bg-color__inv-static-primary);
    align-self: center;
    line-height: 0;
    font-size: 22px;
    margin-left: 8px;

    &:hover {
      color: var(--ds-bg-color__int-primary-hover);
      cursor: pointer;
    }
  }
`;

ButtonWrapper.displayName = 'ButtonWrapper';

const UploadWrapper = styled.div`
  display: flex;
  align-items: baseline;

  a,
  p {
    cursor: pointer;
  }

  p {
    margin: 0;
  }
`;

UploadWrapper.displayName = 'UploadWrapper';

const StyledAccordionItem = styled(AccordionItem)<{
  uuid: string;
}>`
  padding: 32px;
  border-bottom: ${(props) =>
    props.uuid === '1'
      ? '1px solid var(--ds-color__int-content-primary-disabled)'
      : 'none'};
`;

StyledAccordionItem.displayName = 'StyledAccordionItem';

const StyledForm = styled.form`
  & > div {
    margin-bottom: 8px;
  }
`;

StyledForm.displayName = 'StyledForm';

const AccordionContent = styled(AccordionItemPanel)`
  h5,
  p {
    color: var(--ds-bg-color__inv-static-primary);
  }

  button p {
    color: var(--content__inv-static-primary);
  }
  margin-top: 24px;
`;

AccordionContent.displayName = 'AccordionContent';

const TextWrapper = styled.div`
  max-width: 664px;
`;

TextWrapper.displayName = 'TextWrapper';

const StyledAccordion = styled(Accordion)`
  .TheModal {
    width: 440px;
  }
`;

StyledAccordion.displayName = 'StyledAccordion';

const List = styled.ul`
  margin-left: 10px;
  padding: 0px 0px 0px 10px;
`;

List.displayName = 'List';

export {
  HeaderWrapper,
  StyledTooltipContent,
  StyledAccordionItemButton,
  ButtonWrapper,
  UploadWrapper,
  StyledAccordionItem,
  StyledForm,
  StatusWrapper,
  AccordionContent,
  TextWrapper,
  StyledAccordion,
  List,
};
