import CallNoteFormModalContainer from '../../containers/CallNoteFormModalContainer';
import CallQuestionModalContainer from '../../containers/CallQuestionModalContainer';
import PropTypes from 'prop-types';
import React from 'react';

const AddNotesModal = ({ modalType }) => {
  return (
    <>
      {
        {
          callNoteForm: <CallNoteFormModalContainer />,
          callQuestion: <CallQuestionModalContainer />,
        }[modalType]
      }
    </>
  );
};

AddNotesModal.propTypes = {
  modalType: PropTypes.string.isRequired,
};

export default AddNotesModal;
