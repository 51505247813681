import { handleCountryChange, handleSave } from '../actions';
import AddressInputs from '../../../components/PersonalInformation/AddressInputs';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  instructorProfile: { formErrors, personalData },
}) => ({
  formErrors,
  personalData,
});

export const mapDispatchToProps = (dispatch) => ({
  handleCountryChange: (event) => dispatch(handleCountryChange(event)),
  handleSave: (name, value) => dispatch(handleSave(name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressInputs);
