const handleIndexControls = (currentPage, totalPages, setIndexControls) => {
  if (currentPage === 1 && totalPages === 1) {
    setIndexControls({ nextPage: false, previousPage: false });
  } else if (currentPage === 1 && totalPages > 1) {
    setIndexControls({ nextPage: true, previousPage: false });
  } else if (currentPage === totalPages) {
    setIndexControls({ nextPage: false, previousPage: true });
  } else {
    setIndexControls({ nextPage: true, previousPage: true });
  }
};

export default handleIndexControls;
