export const constants = {
  ADD_NOTES_LOADING: 'MentorCallNotes.ADD_NOTES_LOADING',
  ADD_NOTES_SUCCESS: 'MentorCallNotes.ADD_NOTES_SUCCESS',
  CREATE_OFF_PLATFORM_CALL_FAILURE:
    'MentorCallNotes.CREATE_OFF_PLATFORM_CALL_FAILURE',
  CREATE_OFF_PLATFORM_CALL_SUCCESS:
    'MentorCallNotes.CREATE_OFF_PLATFORM_CALL_SUCCESS',
  EDIT_NOTES_SUCCESS: 'MentorCallNotes.EDIT_NOTES_SUCCESS',
  FETCHING_NOTES: 'MentorCallNotes.FETCHING_NOTES',
  GET_CALENDAR_ENTRIES_FAILURE: 'MentorCallNotes.GET_CALENDAR_ENTRIES_FAILURE',
  GET_CALENDAR_ENTRIES_SUCCESS: 'MentorCallNotes.GET_CALENDAR_ENTRIES_SUCCESS',
  GET_MENTEES_SUCCESS: 'MentorCallNotes.GET_MENTEES_SUCCESS',
  GET_NOTES_FAILURE: 'MentorCallNotes.GET_NOTES_FAILURE',
  GET_NOTES_SUCCESS: 'MentorCallNotes.GET_NOTES_SUCCESS',
  HANDLE_CANCEL_MODAL: 'MentorCallNotes.HANDLE_CANCEL_MODAL',
  HANDLE_CLOSE_MODAL: 'MentorCallNotes.HANDLE_CLOSE_MODAL',
  OPEN_OFF_PLATFORM_CALL_MODAL: 'MentorCallNotes.OPEN_OFF_PLATFORM_CALL_MODAL',
  SET_MODAL_ACTION: 'MentorCallNotes.SET_MODAL_ACTION',
  SET_MODAL_TYPE: 'MentorCallNotes.SET_MODAL_TYPE',
  SET_NOTES_FILTER: 'MentorCallNotes.SET_NOTES_FILTER',
  SET_NOTE_FORM_FIELDS: 'MentorCallNotes.SET_NOTE_FORM_FIELDS',
  SHOW_ADD_NOTES_MODAL: 'MentorCallNotes.SHOW_ADD_NOTES_MODAL',
  SHOW_CALL_CANCELED_MODAL: 'MentorCallNotes.SHOW_CALL_CANCELED_MODAL',
  SHOW_EDIT_NOTES_MODAL: 'MentorCallNotes.SHOW_EDIT_NOTES_MODAL',
  SHOW_VIEW_NOTES_MODAL: 'MentorCallNotes.SHOW_VIEW_NOTES_MODAL',
  STORE_OFF_PLATFORM_CALL: 'MentorCallNotes.STORE_OFF_PLATFORM_CALL',
  UPDATE_CALENDAR_ENTRY_FAILURE:
    'MentorCallNotes.UPDATE_CALENDAR_ENTRY_FAILURE',
  UPDATE_CALENDAR_ENTRY_SUCCESS:
    'MentorCallNotes.UPDATE_CALENDAR_ENTRY_SUCCESS',
};

export const FORM_DEFAULT = {
  share_message:
    'Would you like to inform career services regarding some specifics of this call?',
  share_with_career_services: false,
};
export const DEFAULT_MODAL_STATE = {
  canSubmitNote: false,
  currentCalendarEntry: {},
  currentCallId: null,
  currentViewId: null,
  isOffPlatformCall: false,
  mentorNoteForm: FORM_DEFAULT,
  modalAction: '',
  modalIsOpen: false,
  modalType: 'callQuestion',
  prevModalType: '',
};

export const REQUIRED_NOTE_FIELD = [
  'action_points',
  'text',
  'student_motivation',
  'student_open_to_suggestion',
  'student_confidence',
  'tag_list',
];
