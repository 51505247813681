import React, { useEffect } from 'react';
import {
  Error,
  ICreateService,
  IUpdateService,
  Student,
  Service,
  ServiceType,
} from '../../interfaces';
import Form from '../../components/Form';
import { connect } from 'react-redux';
import {
  getStudentsAndServices,
  handleModalClose,
  handleSubmit,
  handleDelete,
  handleUpdate,
} from '../../actions';

interface FormContainerProps {
  error: Error;
  getStudentsAndServices: () => void;
  students: Student[];
  serviceTypes: ServiceType[];
  handleModalClose: () => void;
  handleSubmit: (params: ICreateService) => void;
  selectedService?: Service;
  handleDelete: (id: string) => void;
  handleUpdate: (params: IUpdateService, id: string) => void;
}

interface IState {
  offPlatformServices: {
    error: Error;
    students: Student[];
    serviceTypes: ServiceType[];
    selectedService: Service;
  };
}

export const FormContainer: React.FC<FormContainerProps> = ({
  error,
  getStudentsAndServices,
  students,
  serviceTypes,
  handleModalClose,
  handleSubmit,
  selectedService,
  handleDelete,
  handleUpdate,
}) => {
  useEffect(() => {
    getStudentsAndServices();
  }, []);

  return (
    <Form
      students={students}
      serviceTypes={serviceTypes}
      handleModalClose={handleModalClose}
      handleSubmit={handleSubmit}
      selectedService={selectedService}
      handleDelete={handleDelete}
      handleUpdate={handleUpdate}
      error={error}
    />
  );
};

export const mapStateToProps = ({
  offPlatformServices: { error, students, serviceTypes, selectedService },
}: IState) => ({
  error,
  students,
  serviceTypes,
  selectedService,
});

export const mapDispatchToProps = (dispatch: any) => ({
  getStudentsAndServices: () => dispatch(getStudentsAndServices()),
  handleModalClose: () => dispatch(handleModalClose()),
  handleSubmit: (params: ICreateService) => dispatch(handleSubmit(params)),
  handleDelete: (id: string) => dispatch(handleDelete(id)),
  handleUpdate: (params: IUpdateService, id: string) =>
    dispatch(handleUpdate(params, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
