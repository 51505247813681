module.exports = (() => {
  var init = function() {
    datePicker();
  };

  var datePicker = function() {
    $( ".datepicker" ).datepicker({
      dateFormat: 'yy-mm-dd'
    });
  };

  return {
    init: init
  }
})();
