import { Field, Form, Formik } from 'formik';
import { SelectWrapper, StyledForm } from './styles';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { formatOptionForSelect } from '../../../../../utils/formatOptionForSelect';
import { validateInput } from './helpers';

const FormComponent = ({ createTimeOffRequest, reasons }) => {
  const handleSumit = (values, setErrors, setSubmitting) => {
    createTimeOffRequest(values).then((result) => {
      if (result.errors) {
        setErrors({
          from: ' ',
          to: result.errors.message,
        });
      }
    });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        from: '',
        to: '',
        type: '',
      }}
      validate={(values) => validateInput(values)}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        handleSumit(values, setErrors, setSubmitting);
      }}
    >
      {({ isSubmitting, isValid, values, errors, setFieldValue }) => (
        <Form>
          <StyledForm>
            <label className="ds-typography__body--bold">From</label>
            <DatePicker
              selected={values.from}
              dateFormat="dd MMM. yyyy"
              name="from"
              onChange={(date) => setFieldValue('from', date)}
              className={errors.from ? 'error' : ''}
            />
            {errors.from && (
              <p className="ds-typography__body error-text">{errors.from}</p>
            )}
            <label className="ds-typography__body--bold">To</label>
            <DatePicker
              selected={values.to}
              dateFormat="dd MMM. yyyy"
              name="to"
              onChange={(date) => setFieldValue('to', date)}
              className={errors.to ? 'error' : ''}
            />
            {errors.to && (
              <p className="ds-typography__body error-text">{errors.to}</p>
            )}
            <label className="ds-typography__body--bold">Time off type</label>
            <SelectWrapper>
              <Field as="select" name="type">
                <option value="">Choose</option>
                {reasons.map((reason) => (
                  <option key={reason} value={reason}>
                    {formatOptionForSelect(reason)}
                  </option>
                ))}
              </Field>
              <span></span>
            </SelectWrapper>
            <div className="form-actions">
              <Link to="/en/time-off">
                <button className="ds-button__secondary">Discard</button>
              </Link>
              <button
                disabled={
                  isSubmitting ||
                  Object.values(values).every((v) => !v.length) ||
                  !isValid
                }
                type="submit"
                className="ds-button__primary"
              >
                Submit time off
              </button>
            </div>
          </StyledForm>
        </Form>
      )}
    </Formik>
  );
};

FormComponent.propTypes = {
  createTimeOffRequest: PropTypes.func,
  reasons: PropTypes.arrayOf(PropTypes.string),
};

export default FormComponent;
