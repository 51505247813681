import { InputWrapper, StyledInput } from '../App/styles';
import { TaxRateInput, TaxSmallBusinessInput } from './index';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const TaxFields = ({ country, smallCompany }) => {
  return (
    <>
      <h4 className='ds-typography__h4'>Tax Information</h4>
      <InputWrapper>
        <StyledInput>
          <Field type='text' name='taxNumber' placeholder='Tax/VAT number' />
        </StyledInput>
        <TaxRateInput country={country} />
      </InputWrapper>
      <InputWrapper>
        <TaxSmallBusinessInput country={country} smallCompany={smallCompany} />
      </InputWrapper>
    </>
  );
};

TaxFields.propTypes = {
  country: PropTypes.string,
  smallCompany: PropTypes.bool,
};

export default TaxFields;
