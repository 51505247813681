import styled from 'styled-components';

const CardBody = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

CardBody.displayName = 'CardBody';

export default CardBody;
