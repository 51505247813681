import React from 'react';

const ProsAndCons = () => (
  <>
    <p className='ds-typography__body break'>
      As you weigh your options we wanted to offer up some things you may want
      to consider as you decide what is right for you.
    </p>
    <h5 className='ds-typography__h5'>Remote work challenges</h5>
    <p className='ds-typography__body break'>
      Some of the things that could impact your success in a remote role can
      include:
    </p>
    <p className='ds-typography__body break'>
      Here's an unordered list in HTML:
    </p>
    <ul>
      <li>Feeling isolated or lonely</li>
      <li>Less work/life balance</li>
      <li>Burnout from excessive working hours</li>
      <li>
        Increased distractions from family, deliveries, or even too many
        meetings
      </li>
      <li>
        Under or over compensating for your lack of physical presence in an
        office
      </li>
      <li>Lack of clear communication or support</li>
      <li>Response delays</li>
    </ul>
    <p className='ds-typography__body break'>
      See the <strong>How to work from home section</strong> for tips on ways
      you can address this.
    </p>
    <p className='ds-typography__body break'>
      PRO TIP: Because the old model of promotions might rely on who is visible,
      you can authentically increase your exposure and engagement by seeing it
      less like bragging or drawing attention to your efforts and more like
      offering to help. If others do not know what you can do, they do not know
      that they can come to you.
    </p>

    <h5 className='ds-typography__h5'>Remote work opportunities</h5>
    <p className='ds-typography__body break'>
      At the same time as there are pitfalls, there may also be some benefits
      you were not already aware of like:
    </p>
    <ul>
      <li>Increased schedule flexibility</li>
      <li>Time saved by not commuting</li>
      <li>Increased productivity</li>
      <li>Less absenteeism</li>
      <li>Better for the environment</li>
      <li>Expanded talent pool / job opportunities</li>
      <li>More accommodations for differently abled teams</li>
    </ul>
  </>
);

export default ProsAndCons;
