import React, { useState } from 'react';
import StyledBanner from './styles/StyledBanner';
import TheModal from '../TheModal';
import Typeform from '../Typeform';

interface FeedbackBannerProps {
  bannerIsVisible: boolean;
  buttonText: string;
  closeAction: () => void;
  formId: string;
  submitAction: () => void;
  text: string;
}

const FeedbackBanner: React.FC<FeedbackBannerProps> = ({
  bannerIsVisible,
  buttonText,
  closeAction,
  formId,
  submitAction,
  text,
}) => {
  const [showBanner, setShowBanner] = useState(bannerIsVisible);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleDismiss = () => {
    closeAction && closeAction();
    setShowBanner(false);
  };

  const handleSubmit = () => {
    submitAction();
    setTimeout(() => {
      setShowBanner(false);
    }, 1500);
  };

  const handleOpenModal = () => setModalIsOpen(true);

  return showBanner ? (
    <>
      <StyledBanner>
        <div className='ds-grid__container'>
          <p className='ds-typography__body'>{text}</p>
          <button className='ds-button__primary' onClick={handleOpenModal}>
            {buttonText}
          </button>
          <span className='ds-icon__close' onClick={handleDismiss}></span>
        </div>
      </StyledBanner>
      <TheModal isModalOpen={modalIsOpen} handleClose={null}>
        <Typeform formId={formId} handleSubmit={handleSubmit} />
      </TheModal>
    </>
  ) : null;
};

export default FeedbackBanner;
