import React, { useEffect, useState } from 'react';
import Answer from '../../../containers/AnswerHistoryCard/Answer/index';
import AnswerNavigation from './AnswerNavigation';
import PropTypes from 'prop-types';
import StyledAnswerCarousel from './styles/StyledAnswerCarousel';
import { debounce } from 'lodash';
import handleIndexControls from '../../../../shared/helpers/handleIndexControls';

const AnswerCarousel = ({
  answers,
}) => {
  const [displayLength, setDisplayLength] = useState(3);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [indexControls, setIndexControls] = useState({});

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    handleIndexControls(0, answers, setIndexControls);
  }, [answers]);

  useEffect(() => {
    window.addEventListener('resize', debounce(handleResize, 100));
  }, []);

  const handleResize = () => {
    const itemSize = window.innerWidth <= 1026 ? 1 : window.innerWidth <= 1200 ? 2 : 3;
    setDisplayLength(itemSize);
  };

  return (
    <StyledAnswerCarousel className="ds-grid__row">
      <div className="answer">
        <div>
          {answers.slice(currentIndex, currentIndex + displayLength).map(answer =>
            <Answer answer={answer} key={answer.id} />,
          )}
          <AnswerNavigation currentIndex={currentIndex}
            indexControls={indexControls}
            setCurrentIndex={setCurrentIndex}
            displayLength={displayLength}
            setIndexControls={setIndexControls}
            answers={answers} />
        </div>
      </div>
    </StyledAnswerCarousel>
  );
};

AnswerCarousel.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.shape({
        attachment: PropTypes.shape({
          fileIcon: PropTypes.string,
          fileName: PropTypes.string,
          id: PropTypes.number,
          s3Url: PropTypes.string,
        }),
        category: PropTypes.string,
        date: PropTypes.string,
        id: PropTypes.number,
        industry: PropTypes.string,
        notes: PropTypes.string,
        question: PropTypes.string,
        questionInsights: PropTypes.string,
        rating: PropTypes.number,
        shared: PropTypes.bool,
      }),
    }),
  ),
};

export default AnswerCarousel;
