import styled from 'styled-components';

const PlayButton = styled.i`
  font-size: 20px;
`;

const PlayerStyleWrapper = styled.div`
  padding: 0 0.5em;

  .rhap_container {
    background-color: inherit;
    box-shadow: none;
    padding: 0;
  }

  .rhap_progress-section {
    flex: 25 1 auto;
  }

  .rhap_additional-controls,
  .rhap_volume-controls {
    display: none;
  }

  .rhap_main-controls-button {
    align-items: center;
    display: flex;
  }

  .rhap_time {
    color: var(--ds-color__indigo);
    font-size: 12px;
  }

  .rhap_progress-filled {
    background-color: var(--ds-color__aqua);
  }

  .rhap_progress-indicator {
    background-color: var(--ds-color__indigo);
    transform: scale(0.7);
  }

  .rhap_play-pause-button {
    justify-content: center;
  }
`;

PlayButton.displayName = 'PlayButton';
PlayerStyleWrapper.displayName = 'PlayerStyleWrapper';

export { PlayButton, PlayerStyleWrapper };
