import CreateNewProjectCard from '../CreateNewProjectCard';
import ExploreJobsSection from './ExploreJobsSection';
import InterviewPrepPreviewContainer from '../../containers/InterviewPrepPreviewContainer';
import JobApplicationsContainer from '../../containers/JobApplications';
import JobSearchSection from './JobSearchSection';
import PropTypes from 'prop-types';
import React from 'react';
import SectionCards from './SectionCards';
import UsefulLinks from '../UsefulLinks';

const CSCSections = ({
  attendeeId,
  isMobile,
  sectionRefs,
  showProjectCard,
}) => {
  return (
    <>
      <SectionCards
        isMobile={isMobile}
        className='tour__application-tracker'
        id='application-tracker'
        ref={sectionRefs['application-tracker'].ref}
      >
        <JobApplicationsContainer isMobile={isMobile} attendeeId={attendeeId} />
      </SectionCards>
      <SectionCards
        header='Search jobs'
        id='job-search'
        ref={sectionRefs['job-search'].ref}
      >
        <JobSearchSection />
      </SectionCards>
      <SectionCards
        isMobile={isMobile}
        className='tour__interview-prep'
        id='interview-prep'
        ref={sectionRefs['interview-prep'].ref}
      >
        <InterviewPrepPreviewContainer isMobile={isMobile} />
      </SectionCards>
      {showProjectCard && (
        <SectionCards
          className='tour__create-new-project'
          id='portfolio-project'
          ref={sectionRefs['portfolio-project'].ref}
        >
          <CreateNewProjectCard isMobile={isMobile} />
        </SectionCards>
      )}
      <SectionCards
        isMobile={isMobile}
        header='Explore job-related topics'
        id='explore-job'
        ref={sectionRefs['explore-job'].ref}
      >
        <ExploreJobsSection isMobile={isMobile} />
      </SectionCards>
      <SectionCards
        className='tour__useful-links'
        isMobile={isMobile}
        header='Get support from the CF community'
        id='support-community'
        ref={sectionRefs['support-community'].ref}
      >
        <UsefulLinks attendeeId={attendeeId} isMobile={isMobile} />
      </SectionCards>
    </>
  );
};

CSCSections.propTypes = {
  attendeeId: PropTypes.number,
  isMobile: PropTypes.bool,
  sectionRefs: PropTypes.object,
  showProjectCard: PropTypes.bool,
};

export default CSCSections;
