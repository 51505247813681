import { orderBy } from 'lodash';

export const orderedInstructorList = (list, order) => {
  return order ? orderBy(list, item => item.current_students, ['asc']) : list;
};

export const filterInstructorList = (list, toShow, toHide) => {
  const listToShow = list.filter(
    instructor => toShow.every(
      filter => instructor.tags.map(
        t => t.name,
      ).includes(filter.value),
    ),
  );

  return listToShow.filter(
    instructor => toHide.every(
      filter => !instructor.tags.map(
        t => t.name,
      ).includes(filter.value),
    ),
  );
};
