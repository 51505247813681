import { handleCancelModal, handleCloseModal } from '../../action';
import AddNotesModal from '../../components/Modals/AddNotesModal';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  mentorCallNotes: {
    modalType,
  },
}) => ({
  modalType,
});

export const mapDispatchToProps = dispatch => ({
  handleCancel: () => dispatch(handleCancelModal()),
  handleCloseModal: () => dispatch(handleCloseModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNotesModal);
