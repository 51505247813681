import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledConsentForm from './styles/StyledConsentForm';

const ConsentForm = ({
  onShareAnswer,
  subText,
  shareWithMentor,
  interviewId,
}) => {
  const [shareOption, setShareOption] = useState(shareWithMentor);
  const handleChange = () => {
    onShareAnswer(!shareOption, interviewId);
    setShareOption(!shareOption)
  }

  return (
    <StyledConsentForm onClick={handleChange}>
      <div className="cf-form__checkbox--open checkbox-wrapper">
        <input type="checkbox" checked={shareOption} />
        <label>
          Share my answers with my <strong>career specialist *</strong>
          <span className="ds-typography__label">{subText}</span>
        </label>
      </div>
    </StyledConsentForm>
  );
};

ConsentForm.propTypes = {
  interviewId: PropTypes.number,
  onShareAnswer: PropTypes.func.isRequired,
  shareWithMentor: PropTypes.bool.isRequired,
  subText: PropTypes.string.isRequired,
};

export default ConsentForm;
