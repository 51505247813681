import FormField from '../../../../../../EmployerConnector/modals/shared/FormField';
import styled from 'styled-components';

const StyledFormField = styled(FormField)`
  margin-bottom: 28px;
  .large-label {
    width: 40%;
  }

  input, select {
    height: 40px;
    padding: 5px 4px 4px 10px;
    border-radius: 4px;
    border: solid 2px #a7b1b9;
    background-color: #fff;
  }
`;

StyledFormField.displayName = 'StyledFormField';

export default StyledFormField;
