import {
  fetchCallNote,
  getCallNotesHistory,
} from '../../../MentorCallNotes/action';
import NotesHistoryTable from '../../components/NotesHistoryTable';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  mentorCallNotes: {
    callNotes,
    callNotesPagination,
    currentCallId,
    currentViewId,
    isFetchingNote,
    filterParams,
  },
}) => ({
  callNotes,
  callNotesPagination,
  currentCallId,
  currentViewId,
  filterParams,
  isFetchingNote,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchCallNote: (noteId, type) => dispatch(fetchCallNote(noteId, type)),
  getCallNotesHistory: (currentPage, filterParams) =>
    dispatch(getCallNotesHistory(currentPage, filterParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesHistoryTable);
