import React from 'react';
import TaskSubmissionButton from '../../components/TaskSubmissionButton';
import { connect } from 'react-redux';
import {
  submitTask,
  submitTaskForAiEvaluation,
  handleAiEvaluationWithoutConsent,
  toggleConsentModal,
} from '../../actions';
import { Dispatch } from 'redux';

interface TaskSubmissionButtonContainerProps {
  isLoadingModal: boolean;
  submitTask: () => void;
  submitTaskForAiEvaluation: () => void;
  isAiEvaluationUnderProgress: boolean;
  consentModalIsOpen: boolean;
  handleAiEvaluationWithoutConsent: () => void;
  toggleConsentModal: (modalIsOpen: boolean) => void;
  aiTutorInfo: {
    isConsentProvidedByUser: boolean;
    isEnabled: boolean;
    isEvaluationUnderReview: boolean;
  };
}

interface State {
  taskSubmission: {
    isLoadingModal: boolean;
    consentModalIsOpen: boolean;
    aiEvaluationState: {
      inProgress: boolean;
    };
  };
}

const TaskSubmissionButtonContainer = ({
  isLoadingModal,
  submitTask,
  submitTaskForAiEvaluation,
  aiTutorInfo,
  isAiEvaluationUnderProgress,
  consentModalIsOpen,
  handleAiEvaluationWithoutConsent,
  toggleConsentModal,
}: TaskSubmissionButtonContainerProps) => {
  return (
    <TaskSubmissionButton
      isLoadingModal={isLoadingModal}
      submitTask={submitTask}
      submitTaskForAiEvaluation={submitTaskForAiEvaluation}
      isAiEvaluationUnderProgress={
        isAiEvaluationUnderProgress || aiTutorInfo.isEvaluationUnderReview
      }
      isAiTutorEnabled={aiTutorInfo.isEnabled}
      aiTutorConsent={aiTutorInfo.isConsentProvidedByUser}
      consentModalIsOpen={consentModalIsOpen}
      handleAiEvaluationWithoutConsent={handleAiEvaluationWithoutConsent}
      toggleConsentModal={toggleConsentModal}
    />
  );
};

export const mapStateToProps = ({
  taskSubmission: {
    isLoadingModal,
    consentModalIsOpen,
    aiEvaluationState: { inProgress },
  },
}: State) => ({
  isLoadingModal,
  consentModalIsOpen,
  isAiEvaluationUnderProgress: inProgress,
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleAiEvaluationWithoutConsent: () =>
    handleAiEvaluationWithoutConsent()(dispatch),
  submitTask: () => submitTask()(dispatch),
  submitTaskForAiEvaluation: () => submitTaskForAiEvaluation()(dispatch),
  toggleConsentModal: (modalIsOpen: boolean) =>
    dispatch(toggleConsentModal(modalIsOpen)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskSubmissionButtonContainer);
