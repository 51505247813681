import Header from '../../containers/Header';
import Instructors from '../../containers/Instructors';
import React from 'react';
import Students from '../../containers/Students';
import { Wrapper } from './styles';

const App = () => (
  <Wrapper>
    <div className="container-fluid">
      <div className="ds-grid__row">
        <Header />
        <Students />
        <Instructors/>
      </div>
    </div>
  </Wrapper>
);

export default App;
