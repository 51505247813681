import styled from 'styled-components';

const CallReviewStyles = styled.div`
  box-shadow: 5px 5px 10px 0 rgba(51, 51, 51, 0.1);
  background: var(--ds-color__white);
  padding: 30px;

  @media (max-width: 479px) {
    box-shadow: none;
    padding: 15px 8px 0;
  }

  table {
    margin-bottom: 40px;
  }

  table > thead {
    background-color: var(--ds-color__indigo);
    color: var(--ds-color__white);
    th {
      text-align: center;
    }
  }

  table > tbody > tr > td {
    text-align: center;
  }

  table > tbody > tr:nth-child(even) > td,
  table > tbody > tr:nth-child(even) > th {
    background-color: #eef0f1;
  }
`;

CallReviewStyles.displayName = 'CallReviewStyles';

export default CallReviewStyles;
