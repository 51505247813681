import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Survey from '../../components/Candidate/Survey';
import { connect } from 'react-redux';
import { updateSurveyResult } from '../../actions';

export const SurveyContainer = ({
  candidateId,
  candidateRole,
  formId,
  responseId,
  updateSurveyResult,
  score,
}) => {
  useEffect(() => {
    if (formId && responseId) {
      updateSurveyResult(formId, responseId, candidateId, score);
    }
  }, [candidateId]);

  const link =
    candidateRole === 'Certified career specialist' ||
    candidateRole === 'Career specialist'
      ? 'https://admin.typeform.com/form/PLegF5WF/results#responses'
      : 'https://admin.typeform.com/login#responses';

  return <Survey score={score} link={link} />;
};

SurveyContainer.propTypes = {
  candidateId: PropTypes.number,
  candidateRole: PropTypes.string,
  formId: PropTypes.string,
  responseId: PropTypes.string,
  score: PropTypes.number,
  updateSurveyResult: PropTypes.func.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  updateSurveyResult: (formId, responseId, candidateId, score) =>
    dispatch(updateSurveyResult(formId, responseId, candidateId, score)),
});

export const mapStateToProps = ({
  instructorsCandidatesPage: { candidate },
}) => ({
  candidateId: candidate.id,
  candidateRole: candidate.candidate_role,
  formId: candidate.survey_form_id,
  responseId: candidate.survey_response_id,
  score: candidate.survey_score,
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyContainer);
