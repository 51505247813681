import FormToggleSection from '../../styles/FormToggleSection';
import PropTypes from 'prop-types';
import React from 'react';

const WorkPreferences = ({ jobSeekingProfile, isEditView, updateECProfile = () => {} }) => {
  const handleToggleChange = (event) => {
    const { name, checked } = event.target;
    updateECProfile({ field: name, value: checked });
  };
  return (
    <>
      <FormToggleSection
        readOnly={!isEditView}
        label="I’d be willing to relocate"
        isChecked={jobSeekingProfile.willingToRelocate}
        name="willingToRelocate"
        onChange={isEditView ? handleToggleChange : null}
      />
      <FormToggleSection
        readOnly={!isEditView}
        label="I’d be willing to work remotely"
        isChecked={jobSeekingProfile.willingToWorkRemote}
        name="willingToWorkRemote"
        onChange={isEditView ? handleToggleChange : null}
      />
    </>
  );
};

WorkPreferences.propTypes = {
  isEditView: PropTypes.bool,
  jobSeekingProfile: PropTypes.shape({
    attachment: PropTypes.shape({
      attachableId: PropTypes.number,
      attachableType: PropTypes.string,
      contentType: PropTypes.string,
      fileIcon: PropTypes.string,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      id: PropTypes.number,
      s3Url: PropTypes.string,
      subtype: PropTypes.string,
    }),
    country: PropTypes.string,
    graduated: PropTypes.bool,
    graduation_date: PropTypes.string,
    id: PropTypes.number.isRequired,
    motivationStatement: PropTypes.string,
    portfolio: PropTypes.string,
    program: PropTypes.string,
    progress: PropTypes.number,
    slug: PropTypes.string,
    socials: PropTypes.array,
    specialization: PropTypes.string,
    state: PropTypes.string,
    status: PropTypes.string.isRequired,
    studentName: PropTypes.string,
    willingToRelocate: PropTypes.bool,
    willingToWorkRemote: PropTypes.bool,
  }),
  updateECProfile: PropTypes.func,
};

export default WorkPreferences;
