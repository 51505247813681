import PropTypes from 'prop-types';
import React from 'react';

const FlashMessageContainer = props => (
  <div className={`flash_message__container flash_message__fade ${props.status}`}>
    <i className={`fa ${props.status === "success" ? 'fa-check' : 'fa-times'}`}></i>
    <span className="cf-small">{props.message}</span>
  </div>
);

FlashMessageContainer.propTypes = {
  message: PropTypes.string,
  status: PropTypes.string,
};

export default FlashMessageContainer;
