import Form from '../../../shared/form.jsx';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import SepaCheckbox from '../../../shared/PaymentDetailsSlide/SepaCheckbox';
import SepaFields from '../../../shared/PaymentDetailsSlide/SepaFields';
import TermsCheckbox from '../../../shared/termsCheckbox.jsx';
import Wrapper from './styles';

const BillingForm = ({
  nameOnCard,
  setNameOncard,
  buttonLoading,
  handleCheckboxClick,
  handleSubmit,
  checkboxes,
  stripe,
  handleCardElement,
  formError,
}) => {
  const isEnabled = checkboxes.agreedToSepa && checkboxes.agreedToTerms && nameOnCard.length;

  return (
    <Wrapper>
      <br />
      <Form onSubmit={handleSubmit} id="enroll__payment__form">
        <SepaFields
          name={nameOnCard}
          stripe={stripe}
          handleChange={e => setNameOncard(e.target.value)}
          cardElementForStripeCheckout={handleCardElement}
        />
        <TermsCheckbox
          name="agreedToTerms"
          id="terms__checkbox"
          onChange={handleCheckboxClick}
          termsRequired
          defaultChecked={checkboxes.agreedToTerms}
        />
        <SepaCheckbox
          onChange={handleCheckboxClick}
          agreedToSepa={checkboxes.agreedToSepa}
        />
        <div className="error__container">
          <p className="cf-small">{formError}</p>
        </div>
        <button
          type="submit"
          disabled={!isEnabled || buttonLoading}
          className={`button--primary-${isEnabled ? 'petrol' : 'disabled'}`}
        >{buttonLoading ? <LoadingSpinner /> : 'Confirm & pay'}
        </button>
      </Form>
    </Wrapper>
  );
};

BillingForm.propTypes = {
  buttonLoading: PropTypes.bool,
  checkboxes: PropTypes.shape({
    agreedToSepa: PropTypes.bool,
    agreedToTerms: PropTypes.bool,
  }),
  formError: PropTypes.string,
  handleCardElement: PropTypes.func,
  handleCheckboxClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  nameOnCard: PropTypes.string,
  setNameOncard: PropTypes.func,
  stripe: PropTypes.object,
};

export default BillingForm;
