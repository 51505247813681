import App from './containers/App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const userProfileContainer = document.getElementById('userProfile');

if (userProfileContainer) {
  const userProfileContainerRoot = createRoot(userProfileContainer);

  userProfileContainerRoot.render(
    <Provider store={store}>
      <App userId={+userProfileContainer.dataset.userId} />
    </Provider>
  );
}
