import JobApplicationTrackerHeader from '../containers/JobApplicationTrackerHeader';
import JobApplicationTrackerTable from '../containers/JobApplicationTrackerTable';
import PropTypes from 'prop-types';
import React from 'react';
import StyledJobApplicationTracker from './styles/StyledJobApplicationTracker';

const JobApplicationTracker = ({ userId }) => (
  <StyledJobApplicationTracker>
    <JobApplicationTrackerHeader userId={userId} />
    <JobApplicationTrackerTable />
  </StyledJobApplicationTracker>
);

JobApplicationTracker.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default JobApplicationTracker;
