export const toggleButtons = (state, refs) => {
  if (state) {
    refs.forEach(ref => ref.current.classList.remove('hidden'));
  } else {
    refs.forEach(ref => ref.current.classList.add('hidden'));
  };
};

export const isStepPositive = (curr, stepsLength, showBackButtonAtLastStep) => {
  return curr > 0 && curr < (stepsLength - !showBackButtonAtLastStep);
};

export const trackEvent = (curr, currentRef, steps, fn) => {
  fn('onboarding_tooltip', {
    event_action: curr > currentRef.current ? 'next' : 'back',
    tooltip: steps[currentRef.current].name,
  });
};

export const isFirstOrLastStep = (curr, steps, currentRef) => {
  return (curr === (steps.length - 1) && curr === currentRef.current);
};

export const getSkipTourAction = (onSkipClose, onClose, skipTour) => {
  return onSkipClose ? onClose : skipTour;
};

export const getSkipTourText = (steps, curr, tot) => {
  return `${steps.length === curr ? '' : 'Skip tour · '}${curr} of ${tot}`;
};
