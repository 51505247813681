import React, { Component } from 'react';
import ProgressBar from '../../react/enroll/ProgressBar';
import Sidebar from '../shared/Sidebar';
import LoadingSpinner from '../shared/loadingSpinner';
import SigninSlide from '../../react/enroll/SigninSlide';
import SelectPlanSlide from '../../react/enroll/SelectPlanSlide';
import AccountsService from '../../services/AccountsService';
import UserService from '../../services/UserService';
import PaymentDetailsService from '../../services/PaymentDetailsService';
import CheckoutService from '../../services/CheckoutService';
import CourseDetailsService from '../../services/CourseDetailsService';
import { loadStripeLib } from '../../services/StripeHelpers';
import ValidationService from '../../services/ValidationService';
import { getAuthToken } from '../../utils/getAuthToken';
import { getUrlParams } from '../../utils/getUrlParams';
import { productType } from '../../utils/productType';
import _ from 'lodash';
import { daysUntilDate } from '../../utils/daysUntilDate';
import { keysToCamel } from '../../utils/keysConverter';
import { getEnrollType, EnrollType } from '../../utils/enrollTypes';
import { formatErrorMessage } from '../../utils/formatErrorMessages';
import { getUserLocation } from './actions';
import { sepaCountries } from './helpers';
import PaymentPlanWrapper from './PaymentPlanWrapper';

class EnrollWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      paypalAvailable: true,
      sepaAvailable: false,
      showSepaWarning: false,
      slideIndex: 0,
      completedIndex: 0,
      completedSignin: false,
      completedPlanSelection: false,
      signedIn: false,
      showSignin: false,
      formError: '',
      errorType: '',
      user: null,
      selectedPrice: {
        total: '',
        total_undiscounted: '',
      },
      selectedOption: null,
      selectedStart: null,
      discount_type: null,
      loadingState: false,
      buttonLoadingState: false,
      payments: {},
      paymentMethod: 'card',
      agreedToTerms: false,
      isB2B: false,
      enrollType: null,
      courseParams: null,
      authToken: getAuthToken(),
      productType: '',
      countryCode: '',
      paymentPlan: {},
    };

    this.accountsService = new AccountsService(this.state.authToken);
    this.PaymentDetailsService = new PaymentDetailsService(
      this.state.authToken
    );
    this.CheckoutService = new CheckoutService(this.state.authToken, 'enroll');
    this.CourseDetailsService = new CourseDetailsService(this.state.authToken);
    this.ValidationService = new ValidationService();
  }

  componentDidMount() {
    this.getUserData();
    this.getCourseData();
    this.getLocaiton();
    loadStripeLib(window.$).then((stripe) => {
      this.setState({ stripe });
    });
  }

  getUserData = () => {
    const userService = new UserService();

    userService.getCurrentUser().then((response) => {
      if (!_.isEmpty(response)) {
        this.setState({
          user: response,
          signedIn: true,
          showSignin: false,
        });
      }
    });
  };

  getCourseData = () => {
    const params = getUrlParams(window.location.search);

    this.CourseDetailsService.fetchCourseDetails(params).then((response) => {
      this.setState({
        courseParams: response,
        enrollType: getEnrollType(keysToCamel(response)),
        selectedStart: response.start_dates[0].value,
        agreedToTerms: !response.terms_required,
        productType: productType(response.course_name),
      });

      let preSelectedPlan =
        response.selectable_plans.find((item) => item.plan === 'upfront') ||
        response.selectable_plans[0];

      preSelectedPlan ? this.setPlan(preSelectedPlan) : '';
    });
  };

  getLocaiton = () => {
    getUserLocation().then((res) => {
      this.setState({
        countryCode: res.data.data.countryCode,
      });
    });
  };

  setPlan = (preSelectedPlan) => {
    this.setState({
      selectedOption: preSelectedPlan.plan,
      discount_type: preSelectedPlan.discount_type,
      paymentPlan: preSelectedPlan,
      selectedPrice: {
        total: preSelectedPlan.total,
        total_undiscounted: preSelectedPlan.total_undiscounted,
      },
    });
  };

  handleUserSignin = (email, password, rememberMe) => {
    this.setState({ loadingState: true });

    return this.accountsService
      .signin(email, password, rememberMe)
      .then((response) => {
        if (response.error != null) {
          this.setState({
            signedIn: false,
            formError: response.error.error,
            loadingState: false,
            user: null,
          });
        } else {
          this.getUserData();
          this.setState({
            formError: '',
            errorType: '',
            slideIndex: this.state.slideIndex + 1,
            completedIndex: this.state.completedIndex + 1,
            loadingState: false,
          });
          this.getCourseData();
        }
      });
  };

  handleUserSignout = () => {
    return this.accountsService
      .signout()
      .then((response) => {
        window.location.reload();
      })
      .catch((response) => {
        this.setState({ signedIn: true });
      });
  };

  handleUserSignup = (...args) => {
    this.setState({ loadingState: true });
    return this.accountsService.signup(...args).then((response) => {
      if (response.error != null) {
        this.setState({
          signedIn: false,
          formError: response.error.errors[0]['message'],
          errorType: this.setErrorType(response.error.errors[0]['key']),
          loadingState: false,
          user: null,
        });
      } else {
        this.setState({
          formError: '',
          errorType: '',
          slideIndex: this.state.slideIndex + 1,
          completedIndex: this.state.completedIndex + 1,
          completedSignin: true,
          loadingState: false,
        });
        this.getUserData();
      }
    });
  };

  paypalAvailable(paymentPlan, daysUntilPaymentStart) {
    return paymentPlan === 'upfront' || daysUntilPaymentStart <= 89;
  }

  sepaAvailable(paymentPlan) {
    return (
      paymentPlan === 'upfront' &&
      sepaCountries.includes(this.state.countryCode)
    );
  }

  showSepaWarning(date) {
    const startDate = new Date(date);
    const currentTime = new Date();
    return startDate < currentTime.setDate(currentTime.getDate() + 14);
  }

  daysUntilPaymentStart(courseStartDateStr) {
    let startDate = new Date(Date.parse(courseStartDateStr));
    let paymentStartDate = new Date(
      startDate.setDate(startDate.getDate() + 30)
    );
    return daysUntilDate(new Date(), paymentStartDate);
  }

  handlePaymentSelect = () => {
    if (this.state.enrollType === EnrollType.bank) {
      this.setState({ loadingState: false });
      return;
    }

    let daysBetween = this.daysUntilPaymentStart(this.state.selectedStart);
    let paypalAvailable = this.paypalAvailable(
      this.state.selectedOption,
      daysBetween
    );
    let sepaAvailable = this.sepaAvailable(this.state.selectedOption);
    let showSepaWarning = this.showSepaWarning(this.state.selectedStart);

    this.setState({
      paypalAvailable: paypalAvailable,
      showSepaWarning: showSepaWarning,
      sepaAvailable: sepaAvailable,
    });

    if (!this.state.paypalAvailable) {
      this.setState({ paymentMethod: 'card' });
    }

    return this.PaymentDetailsService.postPaymentDetails(
      this.state.selectedOption,
      this.state.selectedStart,
      this.state.courseParams.offer_code,
      this.state.courseParams.discount_code,
      this.state.courseParams.product_sku
    ).then((response) => {
      if (response != null) {
        this.setState({
          loadingState: false,
          payments: response.data,
        });
      } else {
        alert(
          'Whoops, something went wrong. Please try again later, or send an email to hello@careerfoundry.com'
        );
      }
    });
  };

  handleBackClick = () => {
    if (this.state.slideIndex > 0) {
      this.setState({
        slideIndex: this.state.slideIndex - 1,
        formError: '',
        errorType: '',
      });
    }
  };

  handleProgressClick = (slideNumber) => {
    if (this.state.slideIndex == 1) {
      this.setState({
        loadingState: true,
        formError: '',
        errorType: '',
      });
    }
    if (this.state.completedIndex >= slideNumber) {
      this.setState({
        slideIndex: slideNumber,
        formError: '',
        errorType: '',
      });
    }
  };

  handleNextClick = () => {
    if (this.state.slideIndex == 1) {
      this.setState({
        loadingState: true,
        slideIndex: this.state.slideIndex + 1,
        completedIndex: this.state.completedPlanSelection
          ? this.state.completedIndex
          : this.state.completedIndex + 1,
        completedPlanSelection: true,
        formError: '',
        errorType: '',
      });
    } else {
      this.setState({
        slideIndex: this.state.slideIndex + 1,
        completedIndex: this.state.completedSignin
          ? this.state.completedIndex
          : this.state.completedIndex + 1,
        completedSignin: true,
        formError: '',
        errorType: '',
      });
    }
  };

  handleLoginClick = () => {
    this.setState({
      showSignin: true,
      formError: '',
    });
  };

  handleSignupClick = () => {
    this.setState({
      signedIn: false,
      showSignin: false,
      formError: '',
    });
  };

  handlePlanSelect = (option) => {
    this.setState({
      paymentPlan: option,
      selectedOption: option.plan,
      selectedPrice: {
        total: option.total,
        total_undiscounted: option.totalUndiscounted,
      },
    });
  };

  handleStartSelect = (date) => this.setState({ selectedStart: date });

  handlePaymentMethodClick = (method) =>
    this.setState({ paymentMethod: method });

  handleB2BClick = (value) => this.setState({ isB2B: value });

  handleTermsClick = (value) => this.setState({ agreedToTerms: value });

  handleCheckout = (params) => {
    const {
      selectedStart,
      courseParams,
      selectedOption,
      isB2B,
      paymentMethod,
    } = this.state;
    const discountCode = courseParams.discount_code;
    const offerCode = courseParams.offer_code;
    const productSku = courseParams.product_sku;
    const provider = this.setProvider();

    this.setState({
      buttonLoadingState: true,
      formError: '',
    });

    return this.CheckoutService.checkout(
      params,
      selectedStart,
      discountCode,
      offerCode,
      selectedOption,
      provider,
      isB2B,
      productSku,
      paymentMethod
    )
      .then((response) => {
        if (provider === 'paypal') {
          return response;
        }
        this.setState({
          formError: '',
          errorType: '',
        });
        window.location.replace(response.data.redirect_url);
      })
      .catch((error) => {
        this.setState({
          formError: formatErrorMessage(error.error),
          errorType: this.setErrorType(error.errorType),
          buttonLoadingState: false,
        });
      });
  };

  setProvider = () => {
    if (
      this.state.enrollType === EnrollType.normal ||
      this.state.enrollType === EnrollType.offer
    ) {
      return this.state.paymentMethod === 'paypal' ? 'paypal' : 'stripe';
    } else {
      return '';
    }
  };

  setErrorType = (error) => this.ValidationService.getErrorType(error);

  render() {
    const renderBackButton = () => {
      if (this.state.slideIndex > 0) {
        return (
          <img
            src='https://images.careerfoundry.com/public/framework/back-arrow.svg'
            onClick={() => {
              this.handleBackClick();
            }}
          />
        );
      }
    };

    const renderSlides = () => {
      if (this.state.slideIndex == 0) {
        return (
          <SigninSlide
            signedIn={this.state.signedIn}
            showSignin={this.state.showSignin}
            onNextClick={this.handleNextClick}
            initializeForms={this.initializeForms}
            userSignin={this.handleUserSignin}
            formError={this.state.formError}
            errorType={this.state.errorType}
            userSignout={this.handleUserSignout}
            userSignup={this.handleUserSignup}
            user={this.state.user}
            onLoginClick={this.handleLoginClick}
            onSignupClick={this.handleSignupClick}
            courseParams={this.state.courseParams}
            onTermsClick={this.handleTermsClick}
            agreedToTerms={this.state.agreedToTerms}
            loadingState={this.state.loadingState}
          />
        );
      } else if (this.state.slideIndex == 1) {
        return (
          <SelectPlanSlide
            courseParams={this.state.courseParams}
            selectedOption={this.state.selectedOption}
            selectedStart={this.state.selectedStart}
            loadingState={this.state.loadingState}
            onPlanClick={this.handlePlanSelect}
            onStartChange={this.handleStartSelect}
            onNextClick={this.handleNextClick}
            enrollType={this.state.enrollType}
            productType={this.state.productType}
          />
        );
      } else {
        return (
          <PaymentPlanWrapper
            paymentPlan={this.state.paymentPlan}
            courseParams={this.state.courseParams}
            paymentMethod={this.state.paymentMethod}
            selectedOption={this.state.selectedOption}
            paypalAvailable={this.state.paypalAvailable}
            sepaAvailable={this.state.sepaAvailable}
            showSepaWarning={this.state.showSepaWarning}
            isB2B={this.state.isB2B}
            onPaymentMethodClick={this.handlePaymentMethodClick}
            onB2BClick={this.handleB2BClick}
            paymentDetails={this.state.payments}
            onCheckout={this.handleCheckout}
            stripe={this.state.stripe}
            loadingState={this.state.loadingState}
            buttonLoadingState={this.state.buttonLoadingState}
            formError={this.state.formError}
            errorType={this.state.errorType}
            handlePaymentSelect={this.handlePaymentSelect}
            enrollType={this.state.enrollType}
            productType={this.state.productType}
            countryCode={this.state.countryCode}
            email={this.state.user.email}
            startDate={this.state.selectedStart}
          />
        );
      }
    };

    if (!this.state.courseParams) {
      return <LoadingSpinner />;
    } else {
      return (
        <div className='EnrollWrapper cf-content'>
          <header>
            <p className='cf-bold cf-text-center'>
              Enroll in the {this.state.courseParams.course_name}
            </p>
            {renderBackButton()}
            <ProgressBar
              slideIndex={this.state.slideIndex}
              completedIndex={this.state.completedIndex}
              onProgressClick={this.handleProgressClick}
            />
          </header>
          <main className={this.state.slideIndex == 2 ? 'paymentSlide' : ''}>
            {renderSlides()}
            <Sidebar
              courseParams={this.state.courseParams}
              slideIndex={this.state.slideIndex}
              price={this.state.selectedPrice}
              discount_type={this.state.discount_type}
            />
          </main>
        </div>
      );
    }
  }
}

export default EnrollWrapper;
