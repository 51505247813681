module.exports = (() => {
  var init = function() {
    enableDatePickers();
  };

  var enableDatePickers = function() {
    $('.datepicker').datepicker({
      dateFormat: 'yy-mm-dd',
    });
  };

  return {
    init: init
  };
})();
