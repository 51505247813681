import AttachmentPreview from '../../../Uploader/components/AttachmentPreview';
import InfoWrapper from './styles/InfoWrapper';
import JobApplicationDetailsStyles from './styles/JobApplicationDetailsStyles';
import Line from './styles/Line';
import PropTypes from 'prop-types';
import React from 'react';
import { stringTruncate } from '../../../../utils/stringTruncate';

const JobApplicationsDetails = ({ jobApplication, isMentor = false }) => {
  const {
    date, role, company, source, link, location, notes, contact, followUpDate,
    status, salary, attachments,
  } = jobApplication;

  const screenshotAttachments = attachments.filter(attachment => attachment.subtype === 'screenshot');
  const coverLetterAttachments = attachments.filter(attachment => attachment.subtype === 'cover_letter');
  const requiredInfo = [{ data: date, text: 'Date applied:' }, { data: role, text: 'Role:' }, { data: company, text: 'Company:' }, { data: source, text: 'Source:' }];
  const mentorViewInfo = [
    { data: location, text: 'Location:' },
    { data: followUpDate, text: 'Follow up date:' },
    { data: status, text: 'Status:' },
  ];
  const optionalInfo = isMentor
    ? mentorViewInfo
    : [
      ...mentorViewInfo,
      { data: notes, text: 'Notes:' },
      { data: contact, text: 'Contact info:' },
      { data: salary, text: 'Salary:' },
    ];

  return (
    <JobApplicationDetailsStyles>
      <h3 className="ds-typography__h3">View Application</h3>
      {requiredInfo.map((info) => {
        return (<InfoWrapper key={info.text}>
          <p className="ds-typography__body">{info.text}</p>
          <p className="ds-typography__body">{info.data}</p>
        </InfoWrapper>);
      })}
      <Line />
      <h5 className="ds-typography__lead--bold">Optional information</h5>

      <InfoWrapper>
        <p className="ds-typography__body">Link:</p>
        <p className="ds-typography__body">
          <a href={link} rel="noreferrer" target="_blank">
            {link && stringTruncate(link, 45)}
          </a>
        </p>
      </InfoWrapper>
      {optionalInfo.map((info) => {
        return (<InfoWrapper key={info.text}>
          <p className="ds-typography__body">{info.text}</p>
          <p className="ds-typography__body">{info.data}</p>
        </InfoWrapper>);
      })}
      <InfoWrapper>
        <p className="ds-typography__body">Screenshot of job ad:</p>
        {screenshotAttachments.map(attachment => <AttachmentPreview attachment={attachment} key={attachment.id} />)}
      </InfoWrapper>
      <InfoWrapper>
        <p className="ds-typography__body">Cover letter:</p>
        {coverLetterAttachments.map(attachment => <AttachmentPreview attachment={attachment} key={attachment.id} />)}
      </InfoWrapper>
    </JobApplicationDetailsStyles>
  );
};

JobApplicationsDetails.propTypes = {
  isMentor: PropTypes.bool.isRequired,
  jobApplication: PropTypes.shape({
    attachments: PropTypes.array.isRequired,
    company: PropTypes.string,
    contact: PropTypes.string,
    date: PropTypes.string,
    followUpDate: PropTypes.string,
    id: PropTypes.number,
    link: PropTypes.string,
    location: PropTypes.string,
    notes: PropTypes.string,
    role: PropTypes.string,
    salary: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default JobApplicationsDetails;
