import HeadsUpMessageStyles from './styles/HeadsUpMessageStyles';
import PropTypes from 'prop-types';
import React from 'react';

const HeadsUpMessage = ({ futureStepPostscript }) => (
  <HeadsUpMessageStyles className='future_step_notice no-margin'>
    <p className='ds-typography__body no-margin'>
      <b>{futureStepPostscript.title}</b> Regarding Task{' '}
      {futureStepPostscript.step_display_position},{' '}
      {futureStepPostscript.content}{' '}
    </p>
    <img src={futureStepPostscript.icon_url} />
  </HeadsUpMessageStyles>
);

HeadsUpMessage.propTypes = {
  futureStepPostscript: PropTypes.shape({
    content: PropTypes.string,
    icon_url: PropTypes.string,
    step_display_position: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default HeadsUpMessage;
