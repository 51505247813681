import PropTypes from 'prop-types';
import React from 'react';
import Tile from './tile';

const JobsMansoryTiles = ({ tilesCategories, jobType, linkEventName, sectionName }) => {
  return (
    <div className='ds-grid__row'>
      {tilesCategories.map((categories, index) => (
        <div
          className="ds-grid__col-lg-4 ds-grid__col-xs-12"
          key={`${jobType}_board_${index}`}
        >
          <div className="ds-grid__row">
            {categories.map((category) => (
              <div className="ds-grid__col-xs-12" key={category.category_name}>
                <Tile
                  contents={category.contents}
                  title={category.title}
                  categoryName={category.category_name}
                  linkEventName={linkEventName}
                  sectionName={sectionName}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

JobsMansoryTiles.propTypes = {
  tilesCategories: PropTypes.array.isRequired,
  jobType: PropTypes.string.isRequired,
  linkEventName: PropTypes.string,
  sectionName: PropTypes.string,
};

export default JobsMansoryTiles;
