import React, { Component } from 'react';
import FooterButtons from './FooterButtons.jsx';
import PropTypes from 'prop-types';

class QuestionSlide extends Component {
  render() {
    const renderProgress = () => {
      return (
        <div className='quiz__progress'>
          {[...Array(this.props.numberOfQuestions)].map((_, i) => {
            return (
              <div
                key={i}
                className={`quiz__progress__item ${
                  i < this.props.progress ? 'completed' : ''
                }`}
              ></div>
            );
          })}
        </div>
      );
    };

    const answerButtons = () => {
      return (
        <div className='quiz__answer__buttons'>
          {this.props.answers.map((answer, i) => {
            const activeClass =
              this.props.chosen_answer_id === answer.id ? 'selected' : '';
            return (
              <div
                key={i}
                className={`card--white quiz__answer__button cf-text-center ${activeClass}`}
                onClick={() => {
                  this.props.onAnswerClick(answer.id);
                }}
              >
                {answer.text}
              </div>
            );
          })}
        </div>
      );
    };

    const prevButtonDisabled = this.props.progress <= 1;
    const nextButtonDisabled = this.props.chosen_answer_id == null;

    return (
      <div className='quiz__slide quiz__question__slide'>
        <header>
          <p className='cf-small--gray cf-bold'>Quiz: {this.props.quizLabel}</p>
          <div className='quiz__slide--progress'>
            {renderProgress()}
            <p className='cf-small--gray cf-bold'>
              {this.props.progress}/{this.props.numberOfQuestions}
            </p>
          </div>
        </header>
        <p className='cf-bold cf-text-left'>
          {this.props.progress}. {this.props.question}
        </p>
        {answerButtons()}
        <FooterButtons
          prevButtonDisabled={prevButtonDisabled}
          prevButtonLabel='Previous'
          onPrevClick={this.props.onPrevClick}
          nextButtonDisabled={nextButtonDisabled}
          nextButtonLabel='Submit'
          onNextClick={this.props.onNextClick}
          modalLoading={this.props.modalLoading}
        />
      </div>
    );
  }
}

QuestionSlide.propTypes = {
  answers: PropTypes.array,
  chosen_answer_id: PropTypes.number,
  modalLoading: PropTypes.bool,
  numberOfQuestions: PropTypes.number,
  onAnswerClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  progress: PropTypes.number,
  question: PropTypes.string,
  quizLabel: PropTypes.string,
};

export default QuestionSlide;
