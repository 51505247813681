import { BannerContainer } from '../containers/Banner/index';
import ButtonWithIcon from '../../shared/components/ButtonWithIcon';
import ConfidenceSurvey from '../../shared/components/ConfidenceSurveyBanner';
import IndustryDropdown from '../../CareerSupportCenter/containers/IndustryDropdown';
import MediaCategory from '../components/MediaCategory';
import PropTypes from 'prop-types';
import React from 'react';
import StyledHeader from '../../CareerSupportCenter/components/CSCHeader/styles/StyledHeader';

const MediaLibrary = ({ attendeeId, resources }) => (
  <>
    <ButtonWithIcon
      href='/en/career-support-center'
      icon='arrow-left'
      type='secondary'
      text='Back to career support center'
    />
    <ConfidenceSurvey attendeeId={Number(attendeeId)} />
    <StyledHeader>
      <h2 className='ds-typography__h2 text-left tour__heading'>
        Media Library
      </h2>
      <IndustryDropdown />
    </StyledHeader>
    {Object.keys(resources).map((category) => (
      <MediaCategory
        category={category}
        resources={resources[category]}
        key={category}
      />
    ))}
    <hr />
    <BannerContainer />
  </>
);

MediaLibrary.propTypes = {
  attendeeId: PropTypes.string,
  resources: PropTypes.object.isRequired,
};

export default MediaLibrary;
