import PropTypes from 'prop-types';
import React from 'react';
import StyledIllustration from '../../styles/StyledIllustration';

const InlineIllustration = ({ imageUrl, alt, className = '' }) => (
  <StyledIllustration className={className}>
    <img src={imageUrl} alt={alt} />
  </StyledIllustration>
);

InlineIllustration.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
};

export default InlineIllustration;
