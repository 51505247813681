import PropTypes from 'prop-types';
import React from 'react';
import StyledModalHeader from '../styles/StyledModalHeader';
import moment from 'moment';

const ModalHeader = ({ modalType, profile }) => {
  const startTimeMoment = moment(profile?.startTime);

  return (
    modalType !== 'registerCall' && (
      <StyledModalHeader>
        <img
          src='https://images.careerfoundry.com/public/framework/head.svg'
          alt='profile-picture'
          className='profile-image img-circle'
        />
        <p className='ds-typography__small'>
          Call with {profile?.attendeeName}, at{' '}
          <span>{startTimeMoment.format('HH:mm')}</span>, (timezone:{' '}
          <span>{startTimeMoment.format('Z')}</span>) on{' '}
          <span>{startTimeMoment.format('ddd, D MMM YYYY')}</span>
        </p>
      </StyledModalHeader>
    )
  );
};

ModalHeader.propTypes = {
  modalType: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    attendeeName: PropTypes.string.isRequired,
    startTime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }),
};

export default ModalHeader;
