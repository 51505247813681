import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 558px;
  margin: 24px auto;
  @media only screen and (max-width: 628px) {
    max-width: 558px;
    width: auto;
    margin: 40px auto;
    padding: 0px 16px;
  }
`;
export const PersonalizedBox = styled.div`
  min-height: 521px;
  padding-bottom: 29px;
  background: white;
  border-radius: 4px;
`;

export const PersonalizedBoxFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
`;

export const PNavItem = styled.div`
  margin: 0px 8px;
  display: block;
  width: 15px;
  height: 15px;
  background: ${(props) => (props.active ? '#a7b1b9' : '#e8ebed')};
  border-radius: 50%;
`;
