import styled from 'styled-components';

export const InstructorEnrollCard = styled.section`
  min-height: calc(100vh - 123px);
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    background-color: white;
    padding: 40px 65px;
    width: 536px;
    max-width: 100%;
    min-height: 300px;

    h3 {
      margin-bottom: 24px;
    }

    .cf-form__checkbox--open {
      margin: 24px 0 32px;
    }

    .ds-form__input__wrapper {
      margin: 24px 0;
    }

    form > p {
      margin-bottom: 0;

      & + .ds-form__input__wrapper {
        margin-top: 8px;
        position: relative;
      }
    }

    i {
      position: absolute;
      top: 17px;
      right: 17px;
    }

    .disabled {
      color: #a7b1b9;
    }

    .helper-text {
      color: rgba(34, 60, 80, 0.75);
      text-align: right;
    }

    input[type='checkbox'] + label {
      font-weight: 400;
    }

    input[type='submit'] {
      border-radius: 4px;
    }
  }

  .error-text {
    color: red;
  }

  .loading_spinner_wrapper {
    margin-top: 0px;
  }
`;
