import ApiService from '../../services/ApiService';
import { getAuthToken } from '../../utils/getAuthToken';

export const getStudentHandoffs = async () => {
  const apiService = new ApiService('/api/v1/student_handoffs');
  try {
    const result = await apiService.makeGetCall();
    return result.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const postTypeformData = async (data) => {
  const params = {
    authenticity_token: getAuthToken(),
    feedbackable_id: data.handoff.id,
    feedbackable_type: 'StudentHandoff',
    form_id: data.formId,
    owner_id: data.userId,
    owner_type: 'User',
    response_id: data.responseId,
  };

  const apiService = new ApiService('/api/v1/feedbacks.json');

  try {
    await completeStudentHandoff(data.handoff.id);
    const result = await apiService.makePostCall(params);
    return result;
  } catch (error) {
    console.log(error);
  }
};

const completeStudentHandoff = async (id) => {
  const params = {
    authenticity_token: getAuthToken(),
    student_handoff: {
      status: 'complete',
    },
  };
  const apiService = new ApiService(`/api/v1/student_handoffs/${id}`);
  try {
    const result = await apiService.makePutCall(params);
    return result.data.data;
  } catch (error) {
    console.log(error);
  }
};
