import { Note, NotesWrapper, StyledCandidateNotes } from './styles';
import React, { useState } from 'react';
import Loading from '../../../../../shared/components/Loading';
import ModalContent from './ModalContent';
import PropTypes from 'prop-types';
import { SectionHeading } from '../styles';
import TheModal from '../../../../../shared/components/TheModal';
import { useParams } from 'react-router-dom';

const CandidateNotes = ({
  candidateNotes,
  createNote,
  currentUserId,
  deleteNote,
  loading,
}) => {
  const { candidateId } = useParams();
  const [noteContent, setNoteContent] = useState('');
  const [isModalOpen, handleIsModalOpen] = useState(false);
  const [noteId, setNoteId] = useState(0);

  const handleClick = (e) => setNoteContent(e.target.value);
  const toggleModal = () => handleIsModalOpen(!isModalOpen);

  const handleConfirmationModal = (id) => {
    setNoteId(id);
    toggleModal();
  };

  const handleDelete = () => {
    deleteNote(noteId);
    setNoteId(0);
    toggleModal();
  };

  const handleSumit = (e) => {
    if (noteContent === '') {
      return false;
    }
    createNote(currentUserId, noteContent, candidateId);
    setNoteContent('');
    e.target.blur();
  };

  return (
    <Loading isLoading={loading}>
      <StyledCandidateNotes>
        <SectionHeading className="ds-typography__h5">Comments</SectionHeading>
        <form>
          <textarea
            value={noteContent}
            placeholder="Write a comment here..."
            onChange={(e) => handleClick(e)}
            onKeyPress={(e) => e.key === 'Enter' && handleSumit(e)}
          ></textarea>
        </form>
        <NotesWrapper>
          {candidateNotes.map((note) => (
            <Note key={note.id}>
              <p className="ds-typography__label">
                created by {note.author} {note.date}
              </p>
              <p className="ds-typography__body">{note.note}</p>
              {note.authorId === currentUserId && (
                <i
                  className="ds-icon__trash"
                  onClick={() => handleConfirmationModal(note.id)}
                ></i>
              )}
            </Note>
          ))}
        </NotesWrapper>
      </StyledCandidateNotes>
      <TheModal handleClose={toggleModal} isModalOpen={isModalOpen}>
        <ModalContent handleClose={toggleModal} handleDelete={handleDelete} />
      </TheModal>
    </Loading>
  );
};

CandidateNotes.propTypes = {
  candidateNotes: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      authorId: PropTypes.number,
      date: PropTypes.string,
      id: PropTypes.number,
      note: PropTypes.string,
    })
  ),
  createNote: PropTypes.func,
  currentUserId: PropTypes.number,
  deleteNote: PropTypes.func,
  loading: PropTypes.bool,
};

export default CandidateNotes;
