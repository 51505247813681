import Form from './styles/Form';
import PropTypes from 'prop-types';
import React from 'react';
import { uniqBy } from 'lodash';

const DropDown = ({
  attendees,
  programAttendees,
  selectedCourse,
  setSelectedCourse,
}) => {
  const paAttendees = [];
  programAttendees &&
    programAttendees.forEach((el) => {
      el.attendees.forEach((attendee) => paAttendees.push(attendee));
    });
  const filteredAttendees = paAttendees.filter((el) => el.courseId);
  const allAttendees = attendees && attendees.concat(filteredAttendees);
  const uniqAttendees = uniqBy(allAttendees, 'courseId');

  return (
    <Form className='cf-form'>
      <div className='cf-form__group--floating cf-form__group--frozen'>
        <select
          className='cf-form__input'
          value={selectedCourse}
          onChange={() => setSelectedCourse(parseInt(event.target.value), '')}
        >
          {uniqAttendees.map((attendee) => (
            <option value={attendee.courseId} key={attendee.courseId}>
              {attendee.name}
            </option>
          ))}
        </select>
        <i className='fa fa-angle-down' />
        <label className='cf-form__label--floating'>Select Course</label>
      </div>
    </Form>
  );
};

DropDown.propTypes = {
  attendees: PropTypes.array,
  programAttendees: PropTypes.array,
  selectedCourse: PropTypes.number,
  setSelectedCourse: PropTypes.func.isRequired,
};

export default DropDown;
