import { getJobSeekingProfiles, showJobSeekingProfile } from '../../../actions';
import PropTypes from 'prop-types';
import React from 'react';
import StudentTable from '../../components/StudentTable/index.js';
import { connect } from 'react-redux';

const StudentTableContainer = (props) => {
  return (
    <StudentTable {...props} />
  );
};

StudentTableContainer.propTypes = {
  activePage: PropTypes.number.isRequired,
  getJobSeekingProfiles: PropTypes.func.isRequired,
  jobSeekingProfile: PropTypes.shape({
    attachment: PropTypes.shape({
      attachableId: PropTypes.number,
      attachableType: PropTypes.string,
      contentType: PropTypes.string,
      fileIcon: PropTypes.string,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      id: PropTypes.number,
      s3Url: PropTypes.string,
      subtype: PropTypes.string,
    }),
    country: PropTypes.string,
    graduated: PropTypes.bool,
    graduation_date: PropTypes.string,
    id: PropTypes.number,
    motivation_statement: PropTypes.string,
    portfolio: PropTypes.string,
    program: PropTypes.string,
    progress: PropTypes.number,
    socials: PropTypes.array,
    specialization: PropTypes.string,
    state: PropTypes.string,
    status: PropTypes.string,
    student_name: PropTypes.string,
    willing_to_work_remote: PropTypes.bool,
  }),
  jobSeekingProfiles: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  perPage: PropTypes.number.isRequired,
  profileFilterParams: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    program: PropTypes.string,
    specialization: PropTypes.string,
  }),
  showJobSeekingProfile: PropTypes.func.isRequired,
  totalEntries: PropTypes.number.isRequired,
};

export const mapStateToProps = ({
  adminEmployerConnector: {
    jobSeekingProfile, jobSeekingProfiles,
    loading,
    activePage,
    perPage,
    totalEntries,
    profileFilterParams,
  },
}) => ({
  activePage,
  jobSeekingProfile,
  jobSeekingProfiles,
  loading,
  perPage,
  profileFilterParams,
  totalEntries,
});

export const mapDispatchToProps = dispatch => ({
  getJobSeekingProfiles: (page, params) => dispatch(getJobSeekingProfiles(page, params)),
  showJobSeekingProfile: (id) => dispatch(showJobSeekingProfile(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentTableContainer);
