import React, { useEffect, useState } from 'react';
import CallNoteForm from '../../../MentorCallNotes/components/shared/CallNoteForm';
import FlashMessageContainer from '../../../shared/flashMessageContainer.jsx';
import ModalWrapper from '../../../MentorCallNotes/components/shared/ModalWrapper';
import PropTypes from 'prop-types';
import { REQUIRED_NOTE_FIELD } from '../../../MentorCallNotes/constants';

const EditNoteModal = ({
  currentCalendarEntry,
  handleCloseModal,
  currentCallId,
  mentorNoteForm,
  onSubmit,
  submitLoading,
  flashMessage,
  showFlash,
}) => {
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  const handleFieldCheck = () => {
    const result = [];
    for (const field of [...REQUIRED_NOTE_FIELD, 'action_points']) {
      if (field === 'tag_list') {
        if (
          mentorNoteForm[field].toLowerCase() !==
          currentCalendarEntry[field].toLowerCase()
        ) {
          result.push(field);
        }
      } else {
        if (mentorNoteForm[field] !== currentCalendarEntry[field]) {
          result.push(field);
        }
      }
    }
    return result;
  };

  const handleSubmit = async () => {
    const data = {};
    const changedFields = handleFieldCheck();
    for (const field of changedFields) {
      data[field] = mentorNoteForm[field];
    }

    if (typeof onSubmit === 'function') {
      await onSubmit(currentCallId, data);
    }
  };

  useEffect(() => {
    for (const field of REQUIRED_NOTE_FIELD) {
      if (!mentorNoteForm[field]) return setSubmitDisabled(true);
    }

    if (handleFieldCheck().length > 0) {
      return setSubmitDisabled(false);
    }

    setSubmitDisabled(true);
  }, [mentorNoteForm]);

  return (
    <>
      <ModalWrapper
        currentState={'editNote'}
        onNextClick={handleSubmit}
        onPrevClick={handleCloseModal}
        submitDisabled={isSubmitDisabled}
        submitLoading={submitLoading}
      >
        <CallNoteForm
          isMessageSharable={mentorNoteForm.sharable}
          showCSSection={mentorNoteForm.sharable}
          isCSSectionEditable={false}
        />
      </ModalWrapper>
      {showFlash && (
        <FlashMessageContainer status="success" message={flashMessage} />
      )}
    </>
  );
};

EditNoteModal.propTypes = {
  currentCalendarEntry: PropTypes.object.isRequired,
  currentCallId: PropTypes.number.isRequired,
  flashMessage: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
  mentorNoteForm: PropTypes.shape({
    sharable: PropTypes.bool,
    sharable_message: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  showFlash: PropTypes.bool,
  submitLoading: PropTypes.bool,
};

export default EditNoteModal;
