import React, { useState } from 'react';
import AddLinkWithInput from './AddLinkWithInput';
import LinkEdit from './LinkEdit';
import PropTypes from 'prop-types';
import StyledIconsWrapper from '../../styles/StyledIconsWrapper';

const EditLinkInput = ({ link, onDelete, onInputChange, type }) => {
  const [showInput, setShowInput] = useState(false);
  return (
    <StyledIconsWrapper hasMargin={true}>
      {showInput || !link
        ? (
          <AddLinkWithInput
            type={type}
            onInputChange={onInputChange}
            isOpen={showInput}
            initialValue={link}
            onCloseInput={() => setShowInput(false)}
          />
        )
        : (
          <LinkEdit link={link} onEdit={() => setShowInput(true)} onDelete={onDelete} />
        )}
    </StyledIconsWrapper>
  );
};

EditLinkInput.propTypes = {
  link: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default EditLinkInput;
