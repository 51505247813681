import styled from 'styled-components';

const TypeSelector = styled.div`
  margin-bottom: 34px;

  & > div {
    padding: 0 30px;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    margin-right: 6px;
  }
`;

TypeSelector.displayName = 'TypeSelector';

export {
  TypeSelector,
  TabsWrapper,
};
