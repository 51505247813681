import ApiService from '../../../services/ApiService';
import { constants } from './constants';
import { getAuthToken } from '../../../utils/getAuthToken';
import moment from 'moment';

export const getInitialData = () => async (dispatch) => {
  const apiService = new ApiService('/api/v1/instructors/leaves');

  try {
    const result = await apiService.makeGetCall();
    dispatch({
      data: result.data,
      type: constants.LOAD_INITIAL_DATA_SUCCESS,
    });
  } catch (e) {
    console.log(e);
  }
};

export const createTimeOffRequest = (values) => async (dispatch) => {
  const apiService = new ApiService('/api/v1/instructors/leaves');

  const params = {
    authenticity_token: getAuthToken(),
    instructor_leave_form: {
      end_date: moment(values.to).format('DD/MM/YYYY'),
      reason: values.type,
      start_date: moment(values.from).format('DD/MM/YYYY'),
    },
  };

  try {
    const result = await apiService.makePostCall(params);
    dispatch({
      data: result.data,
      type: constants.CREATE_TIME_OFF_SUCCESS,
    });
  } catch (errors) {
    return { errors };
  }
};

export const handleModalClose = () => ({
  type: constants.HANDLE_MODAL_CLOSE,
});

export const handleDeleteConfirmation = (slug) => ({
  slug,
  type: constants.HANDLE_DELETE_CONFIRMATION,
});

export const handleDelete = (slug) => async (dispatch) => {
  const apiService = new ApiService(`/api/v1/instructors/leaves/${slug}`);

  const params = {
    data: {
      authenticity_token: getAuthToken(),
    },
  };

  try {
    const result = await apiService.makeDeleteCall(params);
    dispatch({
      data: result.data,
      type: constants.DELETE_TIME_OFF_REQUEST,
    });
  } catch (e) {
    console.log(e);
  }
};
