import React from 'react';

const LocationAndSalaryExpectations = () => (
  <>
    <p className='ds-typography__body break'>
      While it could seem that ‘Work From Home’ could translate to ‘Work From
      Anywhere’ for the same pay rate, this may not be the case. Employers are
      listing job opportunities as ‘remote’ but that might be temporary, as they
      might require the role to occasionally be onsite.
    </p>
    <h5 className='ds-typography__h5'>Location expectations</h5>
    <p className='ds-typography__body break'>
      As you are networking or interviewing you might find that some companies
      may require that you work in the same or certain countries,
      states/provinces, or cities for legal, administrative, or tax reasons.
      Additionally, companies could require the same or similar time zones for
      team meetings or even team-building retreats. There may even be some
      parameters like ‘you cannot work outside the designated area or country
      for more than three weeks.’
    </p>
    <p className='ds-typography__body break'>
      We know, we’ve seen it happen. This is why we are telling you now so you
      can ask key questions like:
    </p>
    <ul>
      <li>
        What types of team-building activities are planned that include remote
        workers?
      </li>
      <li>
        How has this company overcome the challenges of having a distributed
        team?
      </li>
      <li>
        The key to having a conversation about this is to ask an open ended
        question (start with How or What) and see what you find out! Also, be on
        the lookout for signs that there is a commitment to having remote
        workers, such as money to set up a home office or clear remote work
        policies.
      </li>
    </ul>
    <h5 className='ds-typography__h5'>Salary expectations</h5>
    <p className='ds-typography__body break'>
      As you are doing your research you might notice that
      salaries are also changing. With this new way of working, some companies
      may opt to base salaries and compensation on where the employee lives or
      the national average instead of where the corporate headquarters is
      located. This means it could help to look at both your location and the
      national average when preparing for job interviews and salary
      negotiations.
    </p>
    <p className='ds-typography__body break'>
      For additional information on salary negotiation, check our webinar{' '}
      <a
        onClick={() =>
          window.open(
            'https://careerfoundry.com/en/events/how-to-negotiate-your-salary/'
          )
        }
      >
        How to Negotiate Your Salary
      </a>
      .
    </p>
  </>
);

export default LocationAndSalaryExpectations;
