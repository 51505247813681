import { constants } from './constants';

export const initialState = {
  activeTab: '',
  callData: [],
  calls: [],
  loading: true,
  modalAction: null,
  options: [],
  selectedCall: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.LOAD_INITIAL_DATA_SUCCESS: {
      const activeTab = Object.keys(action.data.months[0]).toString();

      return {
        ...state,
        activeTab,
        callData: action.data.months,
        calls: action.data.months.find((el) => el[activeTab])[activeTab]
          .lineItems,
        loading: false,
        options: action.data.options,
      };
    }
    case constants.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab,
        calls: state.callData.find((el) => el[action.tab])[action.tab]
          .lineItems,
      };
    case constants.HANDLE_STATUS_CHANGE:
      return {
        ...state,
        modalAction: 'confirm',
        selectedCall: {
          id: action.id,
          status: action.status,
        },
      };
    case constants.HANDLE_MODAL_CLOSE:
      return {
        ...state,
        modalAction: null,
        selectedCall: {},
      };
    case constants.UPDATE_CALL_SUCCESS: {
      const { activeTab, calls, callData } = state;
      const newLineItems = calls.map((call) =>
        call.id === action.data.id ? action.data : call
      );
      const newCallObj = callData.map((el) =>
        Object.keys(el).toString() === activeTab
          ? { [activeTab]: { ...el[activeTab], lineItems: newLineItems } }
          : el
      );

      return {
        ...state,
        callData: newCallObj,
        calls: newLineItems,
        modalAction: 'success',
        selectedCall: {},
      };
    }
    case constants.UPDATE_CALL_FAILURE:
      return {
        ...state,
        modalAction: 'error',
        selectedCall: {},
      };
    default:
      return state;
  }
}
