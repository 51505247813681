import PropTypes from 'prop-types';
import React from 'react';
import WarningWrapper from '../../styles/WarningWrapper';
import { addDays } from '../../../../../EmployerConnector/helpers';

const ActionRequired = ({ updatedAt }) => {
  const jobSeekingProfileTotalDays = 74;
  const expiryDate = addDays(updatedAt, jobSeekingProfileTotalDays);
  return (
    <WarningWrapper>
      <p className='ds-typography__body--bold text-left sub-header'>
        Action Required!
      </p>
      <p className='ds-typography__body text-left'>
        You’ve been inactive for the past 2 months. We’ll deactivate your
        account by{' '}
        <span className='bold'>{expiryDate.toLocaleDateString()}</span>. Please
        conﬁrm if you’d like to stay active in the Employer connector pool.
      </p>
    </WarningWrapper>
  );
};

ActionRequired.propTypes = {
  updatedAt: PropTypes.string,
};

export default ActionRequired;
