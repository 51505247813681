import DefaultGoalReminderModal from '../../components/DefaultGoalReminderModal';
import { closeModal } from './actions';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  weeklySubmissionGoal: { default: wsgDefault, modalIsOpen },
}) => ({
  defaultGoal: wsgDefault[0].goal,
  modalIsOpen,
});

export const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultGoalReminderModal);
