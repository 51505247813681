import PropTypes from 'prop-types';
import React from 'react';
import StyledLink from './styles';
import { filePreview } from '../../../../../utils/filePreview';
import { isUrl } from '../../helpers';

const UploadLink = ({ data, error, removeFile }) => (
  <StyledLink>
    {isUrl(data.data) ? (
      <a
        href={data.data}
        target='_blank'
        className='ds-text__body--medium'
        rel='noreferrer'
      >
        {data.fileName}
      </a>
    ) : (
      <p
        className='ds-text__body--medium'
        onClick={() => filePreview(data.data)}
      >
        {data.fileName}
      </p>
    )}
    <i className='ds-icon__trash' onClick={removeFile}></i>
    {error && (
      <p className='ds-text__body--small ds-text--bold error-text'>
        File {error}
      </p>
    )}
  </StyledLink>
);

UploadLink.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.string,
    fileName: PropTypes.string,
    fileSize: PropTypes.number,
  }),
  error: PropTypes.string,
  removeFile: PropTypes.func,
};

export default UploadLink;
