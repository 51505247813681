import ViewNoteModal from '../../components/Modal/ViewNoteModal';
import { connect } from 'react-redux';
import { handleCloseModal } from '../../../MentorCallNotes/action';

export const mapStateToProps = ({
  mentorCallNotes: { currentCalendarEntry },
}) => ({
  currentCalendarEntry,
});

export const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => dispatch(handleCloseModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewNoteModal);
