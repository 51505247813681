import styled from 'styled-components';

const StyledExploreJobs = styled.div`
  h4 {
    margin-bottom: 32px;
  }
`;

StyledExploreJobs.displayName = 'StyledExploreJobs';

export default StyledExploreJobs;
