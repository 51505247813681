import styled from 'styled-components';

const StyledFAQ = styled.div`
  background-color: #fff;
  padding: 30px;

  h3 {
    margin-bottom: 24px;
  }
`;

StyledFAQ.displayName = 'StyledFAQ';

export default StyledFAQ;
