module.exports = (() => {
  const init = function () {
    bindFunctions();
    addErrorToUserDropdown();
  };

  const bindFunctions = function () {
    $('.invoiceForm--js').on('click', '.add_fields', addFieldsToForm);
    $('.invoiceForm--js').on('click', '.remove_fields', removeFieldsFromForm);
    $('.service-types--js').on('change', toggleRequired);
  };

  const toggleRequired = function () {
    const element = $(this).parent().next().children('input');

    if ($(this).val() === 'Other') {
      element.prop('required', true);
    } else {
      element.prop('required', false);
    }
  };

  const checkInput = e => e.target.value === '' ? clearDropdown(e.target.nextElementSibling) : null;

  const bindAutocompleteEvent = () => {
    $('.user_select--js').bind('railsAutocomplete.select', (e, data) => {
      const element = e.target.nextElementSibling;
      const userId = data.item.id;

      $('.ui-autocomplete-input').on('keydown', checkInput);

      fetch(`/api/v1/admin/users/${userId}`)
        .then(response => response.json())
        .then(json => buildDropdown(json.data.attendees, element));
    });
  };

  const buildDropdown = (attendees, element) => {
    clearDropdown(element);

    if (attendees.length > 0) {
      attendees.forEach(attendee => {
        element.append(new Option(attendee.course, attendee.id));
      });
    } else {
      element.append(new Option('This user has no attendees', null));
    }
  };

  const clearDropdown = dropdown => {
    dropdown.innerHTML = '';
    dropdown.append(new Option('Select attendee based on user', null));
  };

  const addFieldsToForm = function (e) {
    var time = new Date().getTime();
    var regex = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regex, time));
    e.preventDefault();
    bindAutocompleteEvent();
    $('.service-types--js').on('change', toggleRequired);
  };

  const removeFieldsFromForm = function (e) {
    var a = $(this);
    if (a.data('id') === null) {
      a.closest('fieldset').slideUp(600, function () {
        $(this).remove();
      });
    };
  };

  const addErrorToUserDropdown = function () {
    if ($('.help-block a').hasClass('no_course_mentor--js')) {
      $('.user-dropdown').addClass('field_with_errors');
    };
  };

  return {
    init: init,
  };
})();
