import styled from 'styled-components';

const ButtonSection = styled.div`
  padding 0 !important;
  text-align: center;
  margin-bottom: 48px;
  
  a {
    width: 20%;
    margin: 0 !important;
  }

  @media (max-width: 1150px) {
    a {
      width: 40%;
    }
  }

  @media (max-width: 600px) {
    a {
      width: 100%;
    }
  }
`;

ButtonSection.displayName = 'ButtonSection';

export default ButtonSection;
