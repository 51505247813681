module.exports = (() => {
  const init = function() {
    $(document).on('change', '#restart_subscription_form_cycles', _updateProjectedMonthlyAmount)
    _updateProjectedMonthlyAmount()
  }

  const _updateProjectedMonthlyAmount = (_) => {
    const currentCycles = $('#restart_subscription_form_cycles').val()
    const remainingPaymentsAmount = $('#restart_subscription_form_remaining_payments_amount').val()
    const monthlyAmount = (remainingPaymentsAmount / currentCycles).toFixed(2)

    $('#restart_subscription_form_amount_monthly').val(monthlyAmount)
  }

  return {
    init: init
  };
})();
