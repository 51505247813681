import styled from 'styled-components';

const StyledPageContents = styled.div`
  padding: 32px;

  h1 {
    color: var(--ds-color__indigo) !important;
    margin-bottom: 24px;
  }

  p {
    font-size: 18px;
    color: var(--ds-color__indigo) !important;
  }
`;

StyledPageContents.displayName = 'StyledPageContents';

export default StyledPageContents;
