import React, { ChangeEvent, useState } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import LoadingSpinner from '../loadingSpinner';
import CheckoutFormProps from './types/CheckOutFormProps';
import BillingForm from './BillingForm';
import { updateBillingDetails } from './actions';
import { BillingData } from './types/BillingForm';

export default function CheckoutForm(props: CheckoutFormProps) {
  const {
    stripe,
    elements,
    transactionId,
    startDate,
    productSku,
    userLocation,
  } = props;
  const [billingData, setBillingData] = useState<BillingData>({
    address: '',
    city: '',
    zip: '',
  });
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBillingData({ ...billingData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    await updateBillingDetails(transactionId, startDate, billingData);

    const { error } = await stripe.confirmPayment({
      confirmParams: {
        return_url: `${window.location.origin}/enroll/completion?transaction_id=${transactionId}&product_sku=${productSku}`,
      },
      elements,
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message || '');
    } else {
      setMessage('An unexpected error occured.');
    }

    setIsLoading(false);
  };

  return (
    <form data-testid='payment-form' id='payment-form' onSubmit={handleSubmit}>
      <BillingForm
        billingData={billingData}
        handleChange={handleChange}
        userLocation={userLocation}
      />
      <PaymentElement id='payment-element' />
      <button
        data-testid='submit'
        className='ds-button__primary'
        disabled={isLoading || !stripe || !elements}
        id='submit'
      >
        <span id='button-text'>
          {isLoading ? (
            <LoadingSpinner data-testid='loading-spinner' />
          ) : (
            'Pay now'
          )}
        </span>
      </button>
      {message && (
        <span data-testid='error-message' className='ds-text__body--small'>
          {message}
        </span>
      )}
    </form>
  );
}
