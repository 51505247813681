import MediaResourcePreviewContainer from '../MediaResourcePreview';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  careerSupportCenter: {
    userIndustry,
  },
}) => ({
  userIndustry,
});

export default connect(mapStateToProps, null)(MediaResourcePreviewContainer);
