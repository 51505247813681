const referralBanner = require('./banners/referral_banner');

module.exports = (() => {
  const thisState = {
    bannerHeight: 0,
  };

  const init = () => {
    checkWarningBanners();
    checkHolidayBanner();
    checkJobPrepBanner();
    bindFunctions();
    referralBanner.init();
  };

  const onDashboard = $('.prototype_dashboard').length > 0;
  const onCourseHome = $('.prototype_course_home').length > 0;
  const holidayBanner = $('.holiday_banner').length > 0;
  const jobPrepBanner = $('.job_prep_banner').length > 0;

  // Init Function
  const checkWarningBanners = () => {
    if ($('.locked_out_program').length > 0) {
      _showWarningBanner();
    } else if (
      $('.behind_program').length > 0 &&
      !$.cookie('dismissedBehindBanner')
    ) {
      _showWarningBanner();
    }
  };

  const checkHolidayBanner = () => {
    if ($('.holiday_banner').length > 0) {
      $('.holiday_banner').css({ top: `${90 + thisState.bannerHeight}px` });
      _addBannerHeight($('.holiday_banner'));
      _setContainerStyle();
    }
  };

  const checkJobPrepBanner = () => {
    if (jobPrepBanner && !$.cookie('dismissedJobPrepBanner')) {
      $('.job_prep_banner').show();
    }
  };

  // Bind functions
  const bindFunctions = () => {
    $(document).on(
      'click',
      '.dismiss-support-banner--js',
      dismissSupportBanner
    );
    $(document).on(
      'click',
      '.dismiss-holiday-banner--js',
      dismissHolidayBanner
    );
    $(document).on('click', '.dismiss-course-update-banner--js', () =>
      dismissBanner('dismissedCourseUpdateBanner', 'course_update_banner')
    );
    $(document).on('click', '.dismiss-job-prep-banner--js', () =>
      dismissBanner('dismissedJobPrepBanner', 'job_prep_banner')
    );
  };

  const dismissBanner = (cookieName, className) => {
    $.cookie(cookieName, 'true', { expires: 90 });
    $(`.${className}`).hide();
  };

  const dismissSupportBanner = function (e) {
    e.preventDefault();
    _removeBannerHeight($(this).closest('.support_has_ended'));
    $(this).closest('.support_has_ended').hide();
    _removeContainerStyle();

    if (holidayBanner) {
      $('.holiday_banner').css({ top: `${45}px` });
    }

    if ($(this).data('banner') === 'behind') {
      $.cookie('dismissedBehindBanner', 'true', { expires: 90 });
    }
  };

  const dismissHolidayBanner = (e) => {
    e.preventDefault();
    $.cookie('dismissedHolidayBanner', 'true', { expires: 90 });
    _removeBannerHeight($('.holiday_banner'));
    $('.holiday_banner').remove();
    _removeContainerStyle();
  };

  // Private Functions
  const _showWarningBanner = () => {
    $('.support_has_ended').show();
    _addBannerHeight($('.support_has_ended'));
    _setContainerStyle();
  };

  const _addBannerHeight = (banner) =>
    (thisState.bannerHeight += banner.innerHeight());
  const _removeBannerHeight = (banner) =>
    (thisState.bannerHeight -= banner.innerHeight());

  const _setContainerStyle = () => {
    if (onDashboard) {
      $('.prototype_dashboard').css({
        'margin-top': `${thisState.bannerHeight}px`,
      });
    } else if (onCourseHome) {
      $('.prototype_course_home').css({
        'margin-top': `${thisState.bannerHeight}px`,
      });
    }
  };

  const _removeContainerStyle = () => {
    if (onDashboard) {
      $('.prototype_dashboard').css({
        'margin-top': `${thisState.bannerHeight}px`,
      });
    } else if (onCourseHome) {
      $('.prototype_course_home').css({
        'margin-top': `${thisState.bannerHeight}px`,
      });
    }
  };

  return {
    init: init,
  };
})();
