export const handleSave = (name, value) => ({
  name,
  type: 'InstructorProfile.HANDLE_SAVE',
  value,
});

export const handleCountryChange = (event) => ({
  name: event.target.name,
  type: 'InstructorProfile.HANDLE_COUNTRY_CHANGE',
  value: event.target.value,
});

export const handlePaymentMethodChange = (paymentMethod) => ({
  paymentMethod,
  type: 'InstructorProfile.HANDLE_PAYMENT_METHOD_CHANGE',
});
