import React from 'react';
import moment from 'moment';
import { DateSeparator, DateSeparatorProps } from 'stream-chat-react';

const formatDate = (date: Date) => moment(date).calendar();

const CustomDateSeparator = (props: DateSeparatorProps) => {
  const { date } = props;

  return (
    <DateSeparator formatDate={formatDate} date={date} position='center' />
  );
};

export default CustomDateSeparator;
