import Pagination from 'react-js-pagination';
import PropTypes from 'prop-types';
import React from 'react';
import StyledPagination from './styles';

const PaginationControls = ({
  activePage,
  itemsCountPerPage,
  onChange,
  pageRangeDisplayed,
  totalItemsCount,
}) => (
  <StyledPagination>
    <Pagination
      activePage={activePage}
      itemsCountPerPage={itemsCountPerPage}
      totalItemsCount={totalItemsCount}
      pageRangeDisplayed={pageRangeDisplayed}
      onChange={onChange}
    />
  </StyledPagination>
);

PaginationControls.propTypes = {
  activePage: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageRangeDisplayed: PropTypes.number.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
};

export default PaginationControls;
