import ApiService from '../../../../../services/ApiService';
import { getAuthToken } from '../../../../../utils/getAuthToken';

export const handleCancel = () => ({
  type: 'UserProfile.MENTOR_NOTE_CANCEL_MODAL',
});

export const deleteMentorNote = () => async (dispatch, getState) => {
  const userId = getState().userProfile.userId;
  const noteId = getState().userProfile.mentorNotesTab.selectedNote.id;
  const apiService = new ApiService(
    `/api/v1/users/${userId}/mentor_notes/${noteId}.json`
  );
  const params = {
    data: {
      authenticity_token: getAuthToken(),
    },
  };

  try {
    await apiService.makeDeleteCall(params);
    dispatch({
      id: noteId,
      type: 'UserProfile.MENTOR_NOTE_DELETED',
    });
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};
