import React, { useEffect, useState } from 'react';
import {
  getMotivationalMessages,
  getUserIndustry,
  rateMessage,
} from '../../actions';
import MotivationalMessage from '../../components/MotivationalMessage';
import PropTypes from 'prop-types';
import handleIndexControls from '../../../../react/shared/helpers/handleIndexControls';

let TOTAL_MESSAGES;

const MotivationalMessages = ({ userIndustry }) => {
  const [motivationalMessages, setMotivationalMessages] = useState(null);
  const [currentMessage, setCurrentMessage] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [indexControls, setIndexControls] = useState({});

  useEffect(() => {
    getUserIndustry().then((industry) => {
      getMotivationalMessages(industry).then((res) => {
        const messages = res.messages;
        setMotivationalMessages(messages);
        if (messages && messages.length > 0) {
          TOTAL_MESSAGES = res.totalCount;
          setCurrentMessage(messages[0]);
        }
      });
    });
  }, [userIndustry]);

  useEffect(() => {
    handleIndexControls(0, motivationalMessages, setIndexControls);
    setPageIndex(motivationalMessages ? motivationalMessages.length : 0);
  }, [motivationalMessages]);

  const handleRating = (messageId, rating) => {
    rateMessage(messageId, rating).then((res) => {
      motivationalMessages.splice(
        motivationalMessages.indexOf(currentMessage),
        1,
        res
      );
      setCurrentMessage(res);
    });
  };

  const getCurrentIndex = (direction, jumpTo) => {
    if (jumpTo && direction === 1) {
      return motivationalMessages.length - 1;
    } else if (jumpTo && direction === -1) {
      return 0;
    } else {
      return motivationalMessages.indexOf(currentMessage) + direction;
    }
  };

  const toggleMessage = (direction, jumpTo) => {
    const currentIndex = getCurrentIndex(direction, jumpTo);
    setPageIndex(motivationalMessages.length - currentIndex);
    setCurrentMessage(motivationalMessages[currentIndex]);
    handleIndexControls(currentIndex, motivationalMessages, setIndexControls);
  };

  return (
    motivationalMessages &&
    motivationalMessages.length > 0 && (
      <MotivationalMessage
        motivationalMessage={currentMessage}
        handleRating={handleRating}
        toggleMessage={toggleMessage}
        indexControls={indexControls}
        pageIndex={pageIndex}
        totalCount={TOTAL_MESSAGES}
      />
    )
  );
};

MotivationalMessages.propTypes = {
  userIndustry: PropTypes.string,
};

export default MotivationalMessages;
