import { MAX_GOAL_INC_AMOUNT, MIN_GOAL_DEC_AMOUNT } from '../constants';
import axios from 'axios';
import { getAuthToken } from '../../../../../../utils/getAuthToken';

export const increaseGoal = () => async (dispatch, getState) => {
  const { attendeeId, wsgId, goal } = getState().weeklySubmissionGoal;

  dispatch({ type: 'WeeklySubmissionGoal.INCREASE_GOAL' });

  try {
    const goalIndex = Math.min(MAX_GOAL_INC_AMOUNT, goal + 1);

    await goalUpdateRequest(attendeeId, goalIndex, wsgId);

    dispatch({
      goal: goalIndex,
      type: 'WeeklySubmissionGoal.INCREASE_GOAL_SUCCESS',
    });
  } catch (err) {
    console.log('Error', err);
  }
};

export const decreaseGoal = () => async (dispatch, getState) => {
  const { attendeeId, wsgId, goal } = getState().weeklySubmissionGoal;

  dispatch({ type: 'WeeklySubmissionGoal.DECREASE_GOAL' });

  try {
    const goalIndex = Math.max(MIN_GOAL_DEC_AMOUNT, goal - 1);

    await goalUpdateRequest(attendeeId, goalIndex, wsgId);

    dispatch({
      goal: goalIndex,
      type: 'WeeklySubmissionGoal.DECREASE_GOAL_SUCCESS',
    });
  } catch (err) {
    console.log('Error', err);
  }
};

const goalUpdateRequest = (attendeeId, goalIndex, wsgId) => {
  axios.patch(
    `/api/v1/attendee/${attendeeId}/weekly_submission_goals/${wsgId}`,
    {
      authenticity_token: getAuthToken(),
      weekly_goal: {
        custom_source: true,
        submission_goal: goalIndex,
      },
    }
  );
};
