import styled from 'styled-components';

const StyleButtonSection = styled.div`
  position: absolute;
  bottom: 0;
  background: white;
  width: 100%;
  left: 0;
  border-top: 2px solid #E8EBED;
`;

StyleButtonSection.displayName = 'StyleButtonSection';

export default StyleButtonSection;
