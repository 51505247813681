import { Body } from '@careerfoundry/bubble';
import styled from 'styled-components';

interface StepProps {
  locked: string;
}

const StyledDocuments = styled.div`
  border-right: 1px solid var(--ds-palette__grey-light);
`;

StyledDocuments.displayName = 'StyledDocuments';

const Section = styled.div`
  margin-bottom: 8px;
`;

Section.displayName = 'Section';

const SectionStep = styled.div`
  margin-bottom: 24px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    height: 40px;
  }
`;

SectionStep.displayName = 'SectionStep';

const Step = styled(Body)<StepProps>`
  color: ${(props) =>
    props.locked === 'true'
      ? 'var(--ds-color__int-content-primary-disabled)'
      : 'var(--ds-color__feedback-content-neutral)'};
`;

Step.displayName = 'Step';

const ButtonWrapper = styled.div`
  display: flex;

  button:first-of-type {
    margin-right: 8px;
  }
`;

ButtonWrapper.displayName = 'ButtonWrapper';

export { Section, SectionStep, Step, StyledDocuments, ButtonWrapper };
