import React, { useState } from 'react';
import ConsentForm from '../../containers/ConsentForm';
import PropTypes from 'prop-types';
import StyledReviewConsentBox from './styles/StyledReviewConsentBox';

const ReviewConsentBox = ({ shareWithMentor }) => {
  const [isOpen, setOpen] = useState(false);

  const toggleBox = () => {
    setOpen(!isOpen);
  };

  return (
    <StyledReviewConsentBox
      className="ds-grid__col-sm-12 ds-grid__col-md-12 consent-form"
      isOpen={isOpen}
    >
      <div onClick={toggleBox} className="toggle-section">
        <h5 className="ds-typography__lead--bold text-left">
          {`Your answers will ${!shareWithMentor ? 'not ' : ''}be visible to your career specialist.`}&nbsp;
          <a className="ds-typography__anchor">Change</a>
        </h5>
        <i className="fa fa-chevron-down fa-md" />
      </div>
      <ConsentForm subText="Your career specialist will be able to see your answers, but they will only give you feedback if you ask for it. It may take some time for your answers to become visible to your career specialist after sharing them." />
    </StyledReviewConsentBox>
  );
};

ReviewConsentBox.propTypes = {
  shareWithMentor: PropTypes.bool.isRequired,
};

export default ReviewConsentBox;
