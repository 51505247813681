import { decreaseGoal, increaseGoal } from './actions';
import Controls from '../../components/Controls';
import { MAX_GOAL_INC_AMOUNT } from './constants';
import { connect } from 'react-redux';
import { submittedInPastWeek } from '../../helpers';

export const mapStateToProps = ({
  weeklySubmissionGoal: {
    afa,
    beginOfWeek,
    custom,
    default: defaultWsg,
    exercises,
    goal,
  },
}) => {
  const exercisesRemaining = Object.keys(exercises).filter(
    (k) => !submittedInPastWeek(exercises[k].submission_date, beginOfWeek)
  ).length;

  return {
    disableDecreaseToggle: !goal || (afa && goal <= defaultWsg[0].goal),
    disableIncreaseToggle:
      goal >= MAX_GOAL_INC_AMOUNT || goal >= exercisesRemaining,
    goal,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  decreaseGoal: () => dispatch(decreaseGoal()),
  increaseGoal: () => dispatch(increaseGoal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
