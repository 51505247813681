import EnrollWrapper from '../../react/enroll/EnrollWrapper.jsx';
import React from 'react';
import { createRoot } from 'react-dom/client';

const enrollContainer = document.querySelector('.enroll__wrapper--js');

if (enrollContainer) {
  const enrollContainerRoot = createRoot(enrollContainer);

  enrollContainerRoot.render(<EnrollWrapper />);
}
