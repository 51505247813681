import styled from 'styled-components';

const StyleInputWrapper = styled.div`
  display: flex;
  label {
    margin-right: 16px;
  }
`;

StyleInputWrapper.displayName = 'StyleInputWrapper';

export default StyleInputWrapper;
