class UserPaymentsView {
    constructor() {
        this.initCardBehaviour()
        this.initShowMorePayments()
    }

    initCardBehaviour() {
        $('.user_settings__payments_card header').click((ev) => {
            let currentCard = $(ev.currentTarget).parent()
            let activeCard = $('.user_settings__payments_card.active')

            if (currentCard.hasClass('active')) {
                currentCard.find('i.fa').toggleClass('fa-angle-down', 'fa-angle-up')
                currentCard.removeClass('active')
                return
            }

            activeCard.find('i.fa').toggleClass('fa-angle-down', 'fa-angle-up')
            activeCard.removeClass('active')

            currentCard.find('i.fa').toggleClass('fa-angle-up', 'fa-angle-down')
            currentCard.addClass('active')
        });
    }

    initShowMorePayments() {
        let view = this;
        $('.user_settings__payments_card .show_more_payments').click((ev) => {
            let card = $(ev.currentTarget);
            let contractUrl = card.find('.btn').data('payment-contract-url')

            view.loadPaymentTable(card, contractUrl)

        })
    }

    loadPaymentTable = (card, paymentUrl) => {
        let table = $('.user_settings__payments_card.active .user_settings__payment_details')
        $.get(paymentUrl, (data) => {
            table.html(data)
            card.fadeOut()
        })
    }
}

const PaymentsViewSingleton = new UserPaymentsView()
export default PaymentsViewSingleton