import PropTypes from 'prop-types';
import React from 'react';

const EvaluationNavigation = ({ indexControls, toggleMessage, navigable }) => {
  const { nextRecord, previousRecord } = indexControls;
  return (
    <>
      <i onClick={nextRecord && navigable ? () => toggleMessage(-1) : null} className={`fa ${nextRecord ? 'clickable' : 'grey'} fa-chevron-circle-left fa-lg left-arrow fa-2x tour__interview_prep_arrows`} rel="noopener noreferrer" />
      <i onClick={previousRecord && navigable ? () => toggleMessage(1) : null} className={`fa ${previousRecord ? 'clickable' : 'grey'} fa-chevron-circle-right fa-lg right-arrow fa-2x`} rel="noopener noreferrer" />
    </>
  );
};

EvaluationNavigation.propTypes = {
  indexControls: PropTypes.object.isRequired,
  navigable: PropTypes.bool.isRequired,
  toggleMessage: PropTypes.func.isRequired,
};

export default EvaluationNavigation;
