import styled from 'styled-components';

const ModalContent = styled.div`
  text-align: center;

  i {
    font-size: 36px;
    color: var(--ds-color__petrol--dark);
  }

  ol {
    text-align: left;
  }

  li {
    margin-bottom: 4px;
  }

  h3 + p {
    margin-bottom: 32px;
  }

  button {
    min-width: 88px;

    &:first-of-type {
      margin-right: 4px;
    }

    &:last-of-type {
      margin-left: 4px;
    }
  }

  .delete-button {
    background-color: var(--ds-color_error);
  }

  .loading_spinner_wrapper {
    background: transparent;
    margin-top: 0;

    > .cf-spinner > div {
      background-color: #fff;
    }
  }
`;

ModalContent.displayName = 'ModalContent';

export default ModalContent;
