import React, { useEffect, useState } from 'react';
import { getInterviewQuestions, setShareWithMentor } from '../../actions';
import InterviewTypeSelect from '../../components/InterviewTypeSelect/index';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInterviewQuestionCategories } from '../../components/InterviewTypeSelect/actions';

export const mapDispatchToProps = (dispatch) => ({
  getInterviewQuestionCategories: () =>
    dispatch(getInterviewQuestionCategories),
  getInterviewQuestions: (category) =>
    dispatch(getInterviewQuestions(category)),
  setShareWithMentor: (shared) => dispatch(setShareWithMentor(shared)),
});

export const mapStateToProps = ({ interviewPrep: { shareWithMentor } }) => ({
  shareWithMentor,
});

export const InterviewTypeSelectContainer = ({
  getInterviewQuestionCategories,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getInterviewQuestionCategories()
      .then((res) => {
        setCategories(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <InterviewTypeSelect {...props} categories={categories} />
  );
};

InterviewTypeSelectContainer.propTypes = {
  getInterviewQuestionCategories: PropTypes.func.isRequired,
  getInterviewQuestions: PropTypes.func.isRequired,
  setShareWithMentor: PropTypes.func.isRequired,
  shareWithMentor: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewTypeSelectContainer);
