import { LOAD_RESOURCES_SUCCESS } from './constants';

export const initialState = {
  loading: true,
  resources: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
  case LOAD_RESOURCES_SUCCESS: {
    const formatResources = resources => {
      const categories = getCategories(resources);
      const obj = Object.create({});
      obj.New = resources.slice(0, 10);
      categories.forEach(category => (
        obj[category] = resources.filter(r => r.categories[0] === category)
      ));

      return obj;
    };

    const getCategories = resources => (
      resources.map(r => r.categories[0])
        .filter((el, i, self) => i === self.indexOf(el))
    );

    return {
      ...state,
      loading: false,
      resources: formatResources(action.resources),
    };
  }
  default:
    return state;
  }
}
