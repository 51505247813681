import styled from 'styled-components';

const MentorNoteCategoryStyles = styled.div`
  background-color: var(--ds-color__indigo);
  color: white;
  width: 20%;
  margin-right: 10px;
  font-size: 11px;
  padding: 5px;
  justify-content: center !important;
  vertical-align: top;
`;

MentorNoteCategoryStyles.displayName = 'MentorNoteCategoryStyles';

export default MentorNoteCategoryStyles;
