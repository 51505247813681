import { MOBILE_VIEW_MAX_WIDTH } from '../../../SideNavList/constants';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 11;
  display: flex;
  justify-content: space-between;
  height: 2.5rem;
  background-color: var(--ds-bg-color__static-primary);
  box-shadow: 0px 4px 6px rgba(34, 60, 80, 0.16);

  svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--ds-color__indigo);
  }

  @media (min-width: ${MOBILE_VIEW_MAX_WIDTH + 1}px) {
    display: none;
  }

  @media (max-width: ${MOBILE_VIEW_MAX_WIDTH}px) {
    box-shadow: none;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
`;

const RightSideIcons = styled.div`
  display: flex;
`;

export { Wrapper, IconWrapper, RightSideIcons };
