import styled from 'styled-components';

const StyledJobApplicationTrackerHeader = styled.div`
  margin: 40px 0px;

  .header-text {
    line-height: 1.6;
    margin-bottom: 0;
    font-size: 20px;
  }

  h2 {
    clear: both;
    margin-bottom: 36px;
  }
`;

StyledJobApplicationTrackerHeader.displayName =
  'StyledJobApplicationTrackerHeader';

export default StyledJobApplicationTrackerHeader;
