import SubmissionModal from '../../components/SubmissonModal';
import { connect } from 'react-redux';
import { handleClose } from '../../actions';

export const mapStateToProps = ({
  instructorProfile: { modalAction, modalIsOpen, data },
}) => ({
  modalAction,
  modalIsOpen,
  status: data.status,
});

export const mapDispatchToProps = (dispatch) => ({
  handleClose: () => dispatch(handleClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionModal);
