import { handleModalClose, setModalAction } from '../../actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DashboardCard from '../../components/DashboardCard';

interface IState {
  offPlatformServices: {
    modalAction: string;
  };
}

export const mapStateToProps = ({
  offPlatformServices: { modalAction },
}: IState) => ({
  modalAction,
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModalAction: (modalAction: string) =>
    dispatch(setModalAction(modalAction)),
  handleModalClose: () => dispatch(handleModalClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);
