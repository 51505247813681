import LoadingSpinner from '../../loadingSpinner.jsx';
import PropTypes from 'prop-types';
import React from 'react';

const Loading = ({ children, isLoading }) => isLoading ? <LoadingSpinner /> : children;

Loading.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
};

export default Loading;
