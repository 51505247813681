import {
  WHeader,
  WQuestionItem,
  WQuestionItemNumber,
  WQuestionItemText,
  WQuestions,
  WSubmit,
  WSubtitle,
  WTopImageItem,
  WTopImages,
  Wrapper,
} from './styles';
import { PotsIcon } from '../../Icons';
import PropTypes from 'prop-types';
import React from 'react';

const WelcomePage = ({ data, navigateTo, userData }) => (
  <Wrapper>
    <WTopImages role='topImages'>
      <WTopImageItem>
        <PotsIcon />
      </WTopImageItem>
    </WTopImages>
    <WHeader className='ds-typography__uber' role='title'>
      Hi, {userData.firstName}, we’re happy you’re here!
    </WHeader>
    <WSubtitle className='ds-typography__body'>{data.subtitle}</WSubtitle>
    <WQuestions>
      {data.steps.map((step, index) => (
        <WQuestionItem className='ds-typography__body' key={step}>
          <WQuestionItemNumber>{index + 1}</WQuestionItemNumber>
          <WQuestionItemText>{step}</WQuestionItemText>
        </WQuestionItem>
      ))}
    </WQuestions>
    <WSubmit>
      <button
        onClick={() => navigateTo('#personalized-support', 'next')}
        className='ds-button__primary'
      >
        Let's go!
      </button>
    </WSubmit>
  </Wrapper>
);

WelcomePage.propTypes = {
  data: PropTypes.object,
  navigateTo: PropTypes.func,
  userData: PropTypes.object,
};

export default WelcomePage;
