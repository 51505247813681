import React, { useMemo, useState } from 'react';
import ApplicationPackage from '../shared/ApplicationPackage';
import ModalWrapper from '../shared/ModalWrapper';
import PropTypes from 'prop-types';
import { employerConnectorTracking } from '../../../shared/helpers/tracking';
import { formatSocialPayload } from '../../helpers';
import { updateSocialLinks } from '../../../CareerSupportCenter/actions';

const AccountSetup = ({
  onNextClick,
  onPrevClick,
  updateECProfile,
  jobSeekingProfile,
  updateJobSeekingProfile,
}) => {
  const [loading, setLoading] = useState(false);
  const socialsUrls = useMemo(
    () => (jobSeekingProfile.socials ? jobSeekingProfile.socials : []),
    [jobSeekingProfile.socials],
  );

  const linkedInUrl = useMemo(() => socialsUrls.find((social) => social.name.toLowerCase() === 'linkedin'), [socialsUrls]);

  const handleSaveChange = async () => {
    setLoading(true);
    const socials = formatSocialPayload(jobSeekingProfile.socials);

    if (socials.length > 0) {
      await updateSocialLinks(socials);
    }
    await updateJobSeekingProfile({ ...jobSeekingProfile, status: 'active' });
    employerConnectorTracking('ec_successful_opt_in');
    setLoading(false);
    onNextClick();
  };

  return (
    <ModalWrapper
      header="Application package and social media accounts"
      currentState="accountSetup"
      onNextClick={handleSaveChange}
      onPrevClick={onPrevClick}
      submitDisabled={!linkedInUrl || !jobSeekingProfile?.attachment?.s3Url}
      submitLoading={loading}
    >
      <ApplicationPackage
        updateECProfile={updateECProfile}
        jobSeekingProfile={jobSeekingProfile}
      />
    </ModalWrapper>
  );
};

AccountSetup.propTypes = {
  jobSeekingProfile: PropTypes.shape({
    attachment: PropTypes.shape({
      attachableId: PropTypes.number,
      attachableType: PropTypes.string,
      contentType: PropTypes.string,
      fileIcon: PropTypes.string,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      id: PropTypes.number,
      s3Url: PropTypes.string,
      subtype: PropTypes.string,
    }),
    country: PropTypes.string,
    graduated: PropTypes.bool,
    graduationDate: PropTypes.string,
    id: PropTypes.number.isRequired,
    motivationStatement: PropTypes.string,
    portfolio: PropTypes.string,
    program: PropTypes.string,
    progress: PropTypes.number,
    socials: PropTypes.array,
    specialization: PropTypes.string,
    state: PropTypes.string,
    status: PropTypes.string.isRequired,
    studentName: PropTypes.string,
    willingToWorkRemote: PropTypes.bool,
  }),
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  updateECProfile: PropTypes.func.isRequired,
  updateJobSeekingProfile: PropTypes.func.isRequired,
};

export default AccountSetup;
