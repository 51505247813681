import styled from 'styled-components';

const StyledInterviewReview = styled.div`
  overflow: auto;
  height: 100%;

  @media (max-width: 600px) {
    .review-button {
      flex-direction: column;
      a {
        width: 90%;
        margin: auto auto 8px;
      }
    }
  }
`;

StyledInterviewReview.displayName = 'StyledInterviewReview';

export default StyledInterviewReview;
