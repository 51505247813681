import { Route, Switch } from 'react-router-dom';
import ConfirmationModal from '../../containers/ConfirmationModal';
import Form from '../../containers/Form';
import Header from '../../components/Header';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '../../containers/Table';
import { emails } from '../../constants';

const App = ({ role }) => {
  return (
    <Switch>
      <Route exact path="/en/time-off">
        <>
          <Header
            heading="Time off"
            iconPath="https://images.careerfoundry.com/public/icons/icon_time_off_table.svg"
            text={
              <>
                You will find all of your current and upcoming time off. For
                vacation time off please try to provide us with two weeks
                notice. For sick leave and other types of time off please submit
                your dates as soon as possible, so we can ensure your students
                are supported while you are unable to work.
              </>
            }
          />
          <Table />
          <ConfirmationModal />
        </>
      </Route>
      <Route exact path="/en/time-off/new">
        <>
          <Header
            heading="New time off"
            iconPath="https://images.careerfoundry.com/public/icons/icon_new_time_off.svg"
            text={
              <>
                Fill out this form to submit new time off. Here you can only
                register time off 2 days or more before it starts. For time off
                starting today or tomorrow, please email{' '}
                <span>{emails[role]}</span>.
              </>
            }
          />
          <Form />
          <ConfirmationModal />
        </>
      </Route>
    </Switch>
  );
};

App.propTypes = {
  role: PropTypes.string,
};

export default App;
