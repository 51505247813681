import RouterLinkWithIcon from '../../../../shared/components/RouterLinkWithIcon';
import styled from 'styled-components';

const StyledBackButton = styled(RouterLinkWithIcon)`
  margin: 0 0 40px 4px;
  width: 279px;
  padding: 9px 19px 7px;
  background: var(--ds-color__white) !important;

  @media (max-width: 768px) {
    margin: 0 0 24px 4px;
  }
`;

StyledBackButton.displayName = 'StyledBackButton';

export default StyledBackButton;
