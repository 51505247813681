import { loadStripeLib } from '../../services/StripeHelpers';
const adminUsersEdit = require('../admin/users/edit');

module.exports = (() => {
  const thisState = {};

  const init = () => {
    bindFunctions();
    loadPanelsForStripe();
    adminUsersEdit.init();
    initCardBehaviour();
    initShowMorePayments();
    thisState.profileForm = new CF.Form('.user-settings-profile--js');
    thisState.accountForm = new CF.Form('.user-settings-account--js');
    thisState.emailForm = new CF.Form('.user_settings__referrals_email_form');
    _fillEmailForm();
    loadStripeLib(window.$);
  };

  const bindFunctions = () => {
    $('#s3-profile-uploader').bind('s3_uploads_start', s3UploadStart);
    $('#s3-profile-uploader').bind("ajax:error", s3UploadError);
    $('#s3-profile-uploader').bind("ajax:success", s3UploadSuccess);
    $('#user_drop_down').on('change', adminUsersEdit.setUserFlags);
    $('.form-submit--js').on('click', _submitAndValidate);
    $('.nav-item--js').on('click', _toggleMobileTabs);
    $('.user_settings__accordion__heading').on('click', _toggleAccordionHeadings);
    $('#cc-b2b__checkbox').on('change', _toggleB2BFields);
    $('#cc-terms__checkbox').on('change', _toggleSubmitButton);
  };

  const _fillEmailForm = function () {
    let $emailInput = $('.user_settings__referrals_email_form textarea');
    $emailInput.val('Hi there! If you’re interested in studying with CareerFoundry, you can use my referral link for a 5% discount on one of the courses.');
    $emailInput.parent().addClass('cf-form__group--frozen');
  };

  const loadPanelsForStripe = function () {
    // load AJAX panels for Stripe payments

    // set name field value
    $('.edit_user').submit(function (e) {
      first_name = $('#user_first_name').val();
      last_name = $('#user_last_name').val();
      $('#user_name').val(first_name + ' ' + last_name);
    });
  };

  const s3UploadStart = function (e) {
    let loadingGif = 'https://images.careerfoundry.com/public/images/ajax-loader-lg.gif';
    $('.help-block#ajax-error').hide();
    $('.js-progress-bars').css('display', 'block');
    $('.profile_image').css('background-color', '#fff');
    $('img.profile_image').attr('src', loadingGif);
  };

  const s3UploadError = function (e) {
    let profile = $('img.profile_image');
    let url = '#{@user.photo.url(:medium)}' || 'https://images.careerfoundry.com/public/icons/photos/medium/missing.png';
    profile.attr('src', url);
    profile.css('background-color', 'rgb(241, 241, 241)');
    $(this).find('.help-block').append('<div class="help-block" id="ajax-error" style="color:tomato;">Error: File must be a JPEG or PNG under 10MB</div>');
  };

  const s3UploadSuccess = function (e) {
    $('.help-block#ajax-error').hide();
  };

  // private functions
  const _submitAndValidate = function (e) {
    let $formData = $(this).data('form');

    if ($formData == 'profile-form') {
      _buildUserServices(e);
    } else if ($formData == 'account-form') {
      thisState.accountForm.validate();
    } else if ($formData == 'email-form') {
      e.preventDefault();
      _sendEmails(_emailsSentResponse);
    }
  };

  const _sendEmails = function (callbackFn) {
    if (thisState.emailForm.validate()) {
      let $form = $('.referral-emails--js');
      let data = $form.serialize();
      $form.find('.form-submit--js').html(CF.html.loadingSpinner);

      $.ajax({
        url: '/api/v1/user_referrals/send_referral_email/',
        method: 'POST',
        data: data
      }).done(callbackFn)
    }
  };

  const _emailsSentResponse = function (res) {
    let $form = $('.referral-emails--js');
    $form.removeClass('cf-form--validated');
    $form.find('.form-submit--js').html('Send');
    if (res.status == 'ok') {
      _flashMessage(res.message, 'success')
      $('input[name="emails"]').val('').parent().removeClass('cf-form__group--frozen');
    } else {
      _flashMessage(res.message, 'error');
    }
  };

  const _buildUserServices = function (e) {
    e.preventDefault();
    $.ajax({
      url: '/api/v1/user_services/create_or_update/',
      method: 'POST',
      data: {
        services: Array.from($('.user_service')).map(serviceElm => {
          return {
            service: $(serviceElm).data('service'),
            value: $(serviceElm).val()
          }
        })
      }
    }).done(function (response) {
      thisState.profileForm.validate($('.user-settings-profile--js').submit());
    })
  };

  const _toggleB2BFields = () => $('.cc-b2b--js').toggleClass('hidden');

  const _toggleSubmitButton = function () {
    let btn = $('.form-submit--js');
    if ($(this).is(':checked')) {
      btn.removeClass('button--primary-disabled');
      btn.prop('disabled', false);
      btn.addClass('button--primary-petrol');
    } else {
      btn.addClass('button--primary-disabled');
      btn.prop('disabled', true);
    }
  };

  const _toggleMobileTabs = function () {
    $(this).parent('ul').toggleClass('open');
  };

  const _toggleAccordionHeadings = function () {
    $(this).find('i').toggleClass('cf-rotate');
  };

  const _flashMessage = (msg, type) => {
    let color = (type == 'success') ? 'success' : 'error';
    let icon = (type == 'success') ? 'fa-check' : 'fa-times';
    $('.flash_message__container span').html(msg);
    $('.flash_message__container i').addClass(icon);
    $('.flash_message__container').addClass(`${color} flash_message__fade`);
    setTimeout(() => {
      $('.flash_message__container').removeClass(`${color} flash_message__fade`);
      $('.flash_message__container i').removeClass(icon);
    }, 5000)
  };

  const initCardBehaviour = function () {
    $('.user_settings__payments_card header').click((ev) => {
      const currentCard = $(ev.currentTarget).parent();
      const activeCard = $('.user_settings__payments_card.active');

      if (currentCard.hasClass('active')) {
        currentCard.find('i.fa').toggleClass('fa-angle-down fa-angle-up');
        currentCard.removeClass('active');
        return;
      }

      activeCard.find('i.fa').toggleClass('fa-angle-down fa-angle-up');
      activeCard.removeClass('active');

      currentCard.find('i.fa').toggleClass('fa-angle-up fa-angle-down');
      currentCard.addClass('active');
    });
  };

  const initShowMorePayments = function () {
    $('.user_settings__payments_card .show_more_payments').click((ev) => {
      const card = $(ev.currentTarget);
      const contractUrl = card.find('.btn').data('payment-contract-url');
      loadPaymentTable(card, contractUrl);
    });
  };

  const loadPaymentTable = function (card, paymentUrl) {
    const table = $('.user_settings__payments_card.active .user_settings__payment_details');
    $.get(paymentUrl, (data) => {
      table.html(data);
      card.fadeOut();
    });
  };

  return {
    init: init
  };
})();
