import { setInitialTab, setSelectedSubTab } from './helpers';
import { constants, initialState } from './constants';
import { Action } from './types';
import { State } from './interfaces';

export default function (state = initialState, action: Action): State {
  switch (action.type) {
    case constants.GET_INITIAL_DATA_SUCCESS: {
      const selectedTab = setInitialTab(action.payload.progress);
      const tabData = action.payload.admission[selectedTab];

      return {
        ...state,
        loading: false,
        tabLoading: false,
        progress: action.payload.progress,
        admission: action.payload.admission,
        selectedTab,
        selectedSubTab: setSelectedSubTab(selectedTab, tabData),
        tabData,
      };
    }
    case constants.SET_SELECTED_TAB: {
      const tabData = state.admission[action.payload];

      return {
        ...state,
        tabData,
        selectedTab: action.payload,
        selectedSubTab: setSelectedSubTab(action.payload, tabData),
      };
    }
    case constants.SET_SELECTED_SUB_TAB:
      return {
        ...state,
        tabData: state.admission['afaVoucher'],
        selectedTab: 'afaVoucher',
        selectedSubTab: action.payload,
      };
    case constants.UPDATE_ADMISSION_SUCCESS:
      return {
        ...state,
        tabLoading: false,
        admission: action.payload.admission,
        progress: action.payload.progress,
        tabData: action.payload.admission[action.payload.tab],
      };
    case constants.SET_TAB_IS_LOADING:
      return {
        ...state,
        tabLoading: true,
      };
    default:
      return state;
  }
}
