module.exports = (() => {
  const init = () => bindFunctions();

  const bindFunctions = () => {
    document.addEventListener('keyup', toggleSubmitButton);
    document.addEventListener('change', toggleSubmitButton);
    $('.payer-list-js').on('select2:select', toggleSubmitButton);
  };

  const toggleSubmitButton = () => {
    const button = document.querySelector("input[type='submit']");
    button.disabled = !$('form').valid();
  };

  return {
    init: init,
    toggleSubmitButton: toggleSubmitButton,
  };
})();
