import { Accordion } from 'react-accessible-accordion';
import AllLocationsBoard from './AllLocationsBoard';
import EuropeLocationBoard from './EuropeLocationBoard';
import NorthAmericaLocationBoard from './NorthAmericaLocation';
import PropTypes from 'prop-types';
import React from 'react';
import StyledAccordion from '../styles/StyledAccordion';

const RecommendedJobAccordions = ({ isMobile }) => {
  return (
    <StyledAccordion>
      <Accordion
        allowZeroExpanded
        allowMultipleExpanded
        preExpanded={[`${isMobile ? '' : 'WorldwideLocations'}`]}
      >
        <AllLocationsBoard />
        <NorthAmericaLocationBoard />
        <EuropeLocationBoard />
      </Accordion>
    </StyledAccordion>
  );
};

RecommendedJobAccordions.propTypes = {
  isMobile: PropTypes.bool,
};

export default RecommendedJobAccordions;
