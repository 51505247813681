import ApplicationsConsentModal from '../../../JobApplicationTracker/containers/JobApplicationsConsent';
import EmployerConnectorModal from '../../../EmployerConnector/container/EmployerConnectorModal';
import InteractiveMessagesModal from '../../containers/InteractiveMessagesModal';
import InterviewGuidelines from '../../../InterviewPrep/components/InterviewGuidelines';
import JobApplicationDetails from '../../containers/JobApplicationDetails';
import JobApplicationForm from '../../containers/JobApplicationForm';
import PropTypes from 'prop-types';
import React from 'react';
import StyledModalWrapper from './styles/StyledModalWrapper';
import TheModal from '../../../shared/components/TheModal';
import WarningModal from '../../../shared/components/WarningModal';
import { employerConnectorTracking } from '../../../shared/helpers/tracking';

const CareerSupportCenterModal = ({
  cancelECModal,
  confirmDelete,
  ecModalType,
  handleCancel,
  handleCloseModal,
  modalIsOpen,
  modalAction,
  optinModalStep,
  showECModal,
  userId,
}) => {
  const isEmployerConnectorOptin = modalAction === 'employerConnectorModal' && ecModalType === 'optin';

  const handleCloseECModal = () => {
    employerConnectorTracking('ec_quit_opt_in', optinModalStep);
    handleCloseModal();
  };

  const isLargeModal = modalAction === 'employerConnectorModal' && ecModalType === 'show';

  return (
    <StyledModalWrapper
      isInteractiveModal={modalAction === 'interactiveMessage'}
      isEmployerConnector={modalAction === 'employerConnectorModal'}
      isLargeModal={isLargeModal}
    >
      <TheModal
        handleClose={() =>
          isEmployerConnectorOptin && optinModalStep !== 'faq'
            ? cancelECModal()
            : handleCancel()
        }
        isModalOpen={modalIsOpen}
      >
        <>
          {
            {
              cancel: (
                <WarningModal
                  action={modalAction}
                  cancelButtonAction={handleCancel}
                  confirmButtonAction={handleCloseModal}
                  message="Are you sure you want to cancel?"
                  subMessage="All your progress will be lost"
                />
              ),
              closeEmployerConnectorModal: (
                <WarningModal
                  action='quit'
                  cancelButtonAction={showECModal}
                  confirmButtonAction={handleCloseECModal}
                  message="You haven’t completed the opt-in procedure yet. Are you sure you want to quit?"
                  subMessage="Your progress will be saved.You’ll start from the last save point when you return"
                />
              ),
              delete: (
                <WarningModal
                  action={modalAction}
                  cancelButtonAction={handleCancel}
                  confirmButtonAction={confirmDelete}
                  message="Are you sure you want to delete job application?"
                />
              ),
              employerConnectorModal: <EmployerConnectorModal />,
              form: <JobApplicationForm handleCancel={handleCancel} />,
              interactiveMessage: <InteractiveMessagesModal />,
              interviewGuidelines: <InterviewGuidelines buttonAction={handleCloseModal} />,
              shareJobApplication: <ApplicationsConsentModal userId={userId}/>,
              view: <JobApplicationDetails />,
            }[modalAction]
          }
        </>
      </TheModal>
    </StyledModalWrapper>
  );
};

CareerSupportCenterModal.propTypes = {
  cancelECModal: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  ecModalType: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  modalAction: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  optinModalStep: PropTypes.string,
  showECModal: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

export default CareerSupportCenterModal;
