import { FormWrapper, StyledContainer, SubmitButton } from './styles';
import AddressFields from '../AddressFields';
import { Form } from 'formik';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import NameFields from '../NameFields';
import PayoutFields from '../PayoutFields';
import PropTypes from 'prop-types';
import React from 'react';
import { TaxFields } from '../TaxFields';
import { renderMessages } from '../../helpers';

const App = ({
  formStatus,
  handleFormChange,
  handleFormSubmit,
  isLoading,
  personalData,
}) => {
  return (
    <StyledContainer className='container ds-grid__container'>
      <div>
        <h2 className='ds-typography__h2 text-left'>Personal Information</h2>
        <p className='ds-typography__small text-left'>* required field</p>
      </div>
      <FormWrapper
        initialValues={personalData}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        <Form onChange={handleFormChange}>
          <NameFields />
          <AddressFields />
          <PayoutFields
            country={personalData.country}
            methodType={personalData.methodType}
          />
          <TaxFields
            country={personalData.country}
            smallCompany={personalData.smallCompany}
          />
          {!!formStatus.length && renderMessages(formStatus)}
          <SubmitButton type='submit' className='ds-button__primary'>
            {isLoading ? <LoadingSpinner /> : 'Save'}
            {!isLoading ? <i className='ds-icon__arrow-thin-right'></i> : ''}
          </SubmitButton>
        </Form>
      </FormWrapper>
    </StyledContainer>
  );
};

App.propTypes = {
  formStatus: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string,
    })
  ),
  handleFormChange: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  personalData: PropTypes.object,
};

export default App;
