import MentorService from '../../services/MentorService'

module.exports = (() => {
  const metaTag = authenticityToken();
  const mentorService = new MentorService(metaTag.content);

  function authenticityToken () {
    return (document.head.querySelector('meta[name="csrf-token"]') || window.gon.form_authenticity_token)
  }

  function initializeTodos () {
    if (window.$('#mentor-my-todos').length) {
      window.$('#mentor-my-todos').append(ajaxSpinner('loading-todos'))
      getTodosOfCourseId(window.gon.current_course_id)
    }
  };

  function getTodosOfCourseId (courseId) {
    mentorService.todos(courseId).then(response => {
      window.$('.loading-todos').remove();
      window.$('#mentor-my-todos')
        .hide()
        .append(response.data)
        .fadeIn()

      todosInfiniteScroll();
    }).catch(error => {
      console.log(error);
    });
  };

  function todosInfiniteScroll () {
    if (window.$('#todos-pagination-controls').size() > 0) {
      window.$('#mentor-my-todos').on('scroll', function () {
        var morePostsUrl = window.$('#mentor-my-todos .pagination .next_page').attr('href')
        if (morePostsUrl && window.$('#mentor-my-todos').scrollTop() > (window.$('#mentor-my-todos').height() - 400)) {
          window.$('#mentor-my-todos .pagination').html(ajaxSpinner('loading-todos-pagination'))
          window.$.getScript(morePostsUrl)
        }
      })
    }
  };

  function bindFunctions () {
    window.$('.call_buttons').on('click', '.update-call-status--js', updateCallStatus);
    window.$('#mentor-my-students').on('click', '.message__student--js', openStudentConversation);
    window.$('#feedback-modal').find('form').on('submit', submitFeedback);
    $('#job_prep_status_filter').on('change', toggleStudentCards);
    $('#filter_filter').on('change', toggleFilter);
  };

  function toggleStudentCards () {
    const jobPrep = $('.JobPreparationCourse');
    const jobSearch = $('.JobSearch');

    switch ($(this).val()) {
      case 'All':
        jobPrep.removeClass('hidden');
        jobSearch.removeClass('hidden');
        break;
      case 'Job Preparation Course':
        jobSearch.addClass('hidden');
        jobPrep.removeClass('hidden');
        break;
      case 'Job Search':
        jobPrep.addClass('hidden');
        jobSearch.removeClass('hidden');
        break;
    }
    toggleFilter();
  };

  function toggleFilter () {
    const filterMapping = {
      'Last Online': {
        option: 'updated_at',
        order: 'DESC'
      },
      'Name (A-Z)': {
        option: 'name',
        order: 'ASC'
      },
      'Name (Z-A)': {
        option: 'name',
        order: 'DESC'
      },
      'Course Progress (least)': {
        option: 'progress_percent',
        order: 'ASC'
      },
      'Course Progress (most)': {
        option: 'progress_percent',
        order: 'DESC'
      }
    }

    const jobPrepStatus = $('#job_prep_status_filter').val()
    const sorting = filterMapping[$('#filter_filter').val()];
    getMyStudentsOfCourseId(window.gon.current_course_id, sorting.option, sorting.order, jobPrepStatus);
  };

  function ajaxSpinner (loaderClass) {
    return '<img src="https://images.careerfoundry.com/public/images/ajax-loader-md.gif" alt="Loading..." title="Loading..." class="' + loaderClass + ' feature-ajax-spinner" />'
  };

  function openStudentConversation () {
    window.communicationSidebarWrapper.openConversation([window.$(this).data('student-id'), window.gon.current_user.id])
  };

  function getMyStudentsOfCourseId (courseId, sortingOption = 'updated_at', sortingOrder = 'DESC', jobPrepStatus = 'All') {
    mentorService.students(courseId, sortingOption, sortingOrder, jobPrepStatus).then(response => {
      window.$('.loading-students').remove();
      window.$('#mentor-my-students')
        .hide()
        .empty()
        .append(response.data)
        .fadeIn();
    }).catch((error) => {
      console.log(error)
    });
  };

  function slackEventBanner () {
    window.$('.close-nag-button').click(function () {
      window.$('.nag').fadeTo('fast', 0.0).slideUp('slow')
      window.$.cookie('happyHolidaysBannerRemoved', 'true', { expires: 90 })
    })

    if (window.$.cookie('happyHolidaysBannerRemoved') !== 'true') {
      window.$(this).find('.nag').fadeIn().removeClass('hidden')
    }
  };

  function initializeStudents () {
    if (window.$('#mentor-my-students').length) {
      window.$('#mentor-my-students').append(ajaxSpinner('loading-students'))
      getMyStudentsOfCourseId(window.gon.current_course_id)
    }
  };

  function submitFeedback () {
    window.$('#feedback-modal').modal('hide')
  };

  function updateCallStatus () {
    let userSure;

    if(window.$(this).data('status') === 'canceled'){
      userSure = confirm('Are you sure you want to cancel this call?');
    }else{
      userSure = true;
    }

    if(userSure){
      window.$(this).html('<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>');
      window.$(this).addClass('disabled');
      var status = window.$(this).data('status');
      var id = window.$(this).data('id');

      window.$.ajax({
        url: `/en/career/${id}/update_status`,
        type: 'PATCH',
        dataType: 'script',
        data: {
          status: status
        },
        success: function(){
        },
        error: function(xhr){
          var errors = window.$.parseJSON(xhr.responseText).errors
          if(window.displayCalendarError) {
            window.displayCalendarError(errors[0]);
          }
        }
      })
    };
  };

  function highlightActiveTabOnLoad () {
    window.$('.top_bar_container').find('li.' + window.gon.current_course_id).addClass('active')
  };

  function init () {
    initializeTodos();
    initializeStudents();
    highlightActiveTabOnLoad();
    slackEventBanner();
    bindFunctions();
  }

  return {
    init: init,
    todosInfiniteScroll: todosInfiniteScroll
  }
})()
