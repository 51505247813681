export const initialState = {
  displayBanner: false,
  isModalOpen: false,
  options: [],
};

export default function (state = initialState, action) {
  return { ...state, ...reducer(null, action) };
}

const reducer = (_, action) => {
  switch (action.type) {
    case 'wsg.CLOSE_WSG_MODAL': {
      return { isModalOpen: false };
    }
    case 'wsg.DISMISS_BANNER': {
      return { displayBanner: false };
    }
    case 'wsg.GET_WSG_DATA_SUCCESS': {
      const {
        estimated_completion: estimatedCompletion,
        submission_goal: submissionGoal,
        wsg_id: wsgId,
        ...rest
      } = action.data.weekly_submission_goal;
      return {
        attendeeId: action.attendeeId,
        attendeeTargetCompletion: action.attendeeTargetCompletion,
        displayBanner: action.data.display_banner,
        estimatedCompletion,
        options: action.data.options,
        submissionGoal,
        wsgId,
        ...rest,
      };
    }
    case 'wsg.OPEN_WSG_MODAL': {
      return {
        isModalOpen: true,
      };
    }
    case 'wsg.SUBMIT_ADJUSTED_WSG_GOAL_SUCCESS': {
      return {
        estimatedCompletion: action.data.estimated_completion,
        submissionGoal: action.data.submission_goal,
        submissions: action.data.submissions,
        wsgId: action.data.wsg_id,
      };
    }
  }
};
