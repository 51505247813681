/**
 * The component is built to add message drafting functionality.
 *
 * Reference 1: https://bit.ly/41yLySP
 * Reference 2: https://bit.ly/3UHwiAU
 *
 * TODO: We should stop using this component once drafting of messages is
 * officially supported by Stream (which would be somewhere in 2023).
 *
 * More info: https://getstream.io/
 */
import React, { useCallback, useEffect, useState } from 'react';
import {
  ChatAutoComplete,
  EmojiPicker,
  useMessageInputContext,
  useTranslationContext,
  useChannelStateContext,
  QuotedMessagePreview,
  FileUploadIcon,
  EmojiIconLarge,
  CooldownTimer,
  SendButton,
  UploadsPreview,
  Tooltip,
  useChatContext,
} from 'stream-chat-react';
import { ImageDropzone, FileUploadButton } from 'react-file-utils';
import clsx from 'clsx';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { debounce } from 'lodash';

const CustomMessageInput = <
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>() => {
  const { t } = useTranslationContext('MessageInputFlat');
  const {
    closeEmojiPicker,
    cooldownRemaining,
    emojiPickerIsOpen,
    handleSubmit,
    handleChange,
    isUploadEnabled,
    maxFilesLeft,
    numberOfUploads,
    openEmojiPicker,
    setCooldownRemaining,
    uploadNewFiles,
    setText,
  } = useMessageInputContext<StreamChatGenerics>('MessageInputFlat');
  const { channel, acceptedFiles, multipleUploads, quotedMessage } =
    useChannelStateContext<StreamChatGenerics>('MessageInputFlat');
  const {
    client: { userID: userId },
  } = useChatContext();

  const channelId = channel.id || '';

  const draftedMessages = JSON.parse(
    localStorage.getItem('draftedMessages') || '{}'
  );

  const draftedMessage = draftedMessages[userId!]
    ? draftedMessages[userId!][channelId]
    : null;

  const [message, setMessage] = useState<string>(draftedMessage || '');

  useEffect(() => {
    setText(message); // sets message (drafted if exists) in the input field
  }, []);

  const saveMessage = useCallback(
    debounce((message) => {
      if (draftedMessages[userId!]) {
        draftedMessages[userId!][channelId] = message;
      } else {
        draftedMessages[userId!] = {
          [channelId]: message,
        };
      }

      localStorage.setItem('draftedMessages', JSON.stringify(draftedMessages));
    }, 500),
    []
  );

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    try {
      const message = event.currentTarget?.value.trim();

      /**
       * Note: A tag will not be saved if the message needs ends with one.
       *
       * Example 1: Message = "Hey @CF" / Draft = "Hey @"
       * Example 2: Message = "Hey @CF, hello." / Draft = "Hey @CF, hello."
       *
       * Additionally, if a message contains a tag and it is sent then and there
       * (without it getting saved as draft to be sent later) then the tag would
       * get highlighted in the chat. But if a message contains a tag and it gets
       * drafted first and then sent to the recipient later (for example, in a
       * new session) then the tag will not get highlighted.
       */
      if (message) {
        setMessage(message);
        saveMessage(message);
      }
    } catch (error) {
      Rollbar.error(
        "Messaging: Failed saving a message as draft in user's browser",
        error,
        {
          userId,
        }
      );
    }

    handleChange(event);
  };

  const handleOnSubmit = (event: React.BaseSyntheticEvent) => {
    setMessage('');
    saveMessage('');
    handleSubmit(event);
  };

  return (
    <div
      className={clsx('str-chat__input-flat', 'str-chat__message-input', {
        'str-chat__input-flat--send-button-active': !!SendButton,
        'str-chat__input-flat-has-attachments': numberOfUploads,
        'str-chat__input-flat-quoted':
          quotedMessage && !quotedMessage.parent_id,
      })}
    >
      <ImageDropzone
        accept={acceptedFiles}
        disabled={!isUploadEnabled || maxFilesLeft === 0 || !!cooldownRemaining}
        handleFiles={uploadNewFiles}
        maxNumberOfFiles={maxFilesLeft}
        multiple={multipleUploads}
      >
        {quotedMessage && !quotedMessage.parent_id && (
          <QuotedMessagePreview quotedMessage={quotedMessage} />
        )}
        <div className='str-chat__input-flat-wrapper'>
          {isUploadEnabled && <UploadsPreview />}
          <div className='str-chat__input-flat--textarea-wrapper'>
            <div className='str-chat__emojiselect-wrapper'>
              <Tooltip>
                {emojiPickerIsOpen
                  ? t<string>('Close emoji picker')
                  : t<string>('Open emoji picker')}
              </Tooltip>
              <button
                aria-label='Emoji picker'
                className='str-chat__input-flat-emojiselect'
                onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
              >
                {cooldownRemaining ? (
                  <div className='str-chat__input-flat-cooldown'>
                    <CooldownTimer
                      cooldownInterval={cooldownRemaining}
                      setCooldownRemaining={setCooldownRemaining}
                    />
                  </div>
                ) : (
                  <EmojiIconLarge />
                )}
              </button>
            </div>
            <EmojiPicker />
            <ChatAutoComplete
              onChange={handleOnChange}
              handleSubmit={handleOnSubmit}
            />
            {isUploadEnabled && !cooldownRemaining && (
              <div
                className='str-chat__fileupload-wrapper'
                data-testid='fileinput'
              >
                <Tooltip>
                  {maxFilesLeft
                    ? t<string>('Attach files')
                    : t<string>("You've reached the maximum number of files")}
                </Tooltip>
                <FileUploadButton
                  accepts={acceptedFiles}
                  disabled={maxFilesLeft === 0}
                  handleFiles={uploadNewFiles}
                  multiple={multipleUploads}
                >
                  <span className='str-chat__input-flat-fileupload'>
                    <FileUploadIcon />
                  </span>
                </FileUploadButton>
              </div>
            )}
          </div>
          {!cooldownRemaining && <SendButton sendMessage={handleSubmit} />}
        </div>
      </ImageDropzone>
    </div>
  );
};

export default CustomMessageInput;
