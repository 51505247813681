import styled from 'styled-components';

const CardBody = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(34, 60, 80, 0.16);
  padding: 30px;

  #wsgCardTitle {
    width: fit-content;
  }
`;

CardBody.displayName = 'CardBody';

export default CardBody;
