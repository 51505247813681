import styled from 'styled-components';

const Header = styled.div`
  margin-bottom: 20px;

  h3 {
    display: inline-block;
    text-align: left;
  }

  span {
    float: right;
    text-transform: capitalize;
  }
`;

Header.displayName = 'Header';

export default Header;
