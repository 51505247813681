import QuestionInsights from '../../components/QuestionInsights';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  interviewPrep: {
    selectedInsight,
  },
}, ownProps) => ({
  buttonAction: ownProps.buttonAction,
  insights: selectedInsight.insights,
  question: selectedInsight.question,
});

export default connect(mapStateToProps, null)(QuestionInsights);
