import styled from 'styled-components';

const Wrapper = styled.div`
  .typeform-modal {
    height: 90%;
    width: 90%;
    max-width: none;

    & > div {
      height: 100%;

      @media (max-width: 481px) {
        height: 90%;
      }
    }
  }

  .confirmation-modal {
    max-width: 440px;

    h4 {
      text-align: center;
      margin-bottom: var(--ds-spacing__m);
      margin-top: 0;
    }

    & > p {
      text-align: center;
      margin-bottom: var(--ds-spacing__l);

      span {
        display: inline-block;
        margin-top: var(--ds-spacing__xs);
      }
    }

    & > .footer {
      display: flex;
      justify-content: center;

      button:nth-of-type(2) {
        margin-left: var(--ds-spacing__xs);
      }
    }
  }
`;

export { Wrapper };
