import styled from 'styled-components';

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;

  h2 {
    margin-bottom: 0 !important;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3px;
    h2 {
      margin-bottom: 0 !important;
    }
  }
`;

StyledHeader.displayName = 'StyledHeader';

export default StyledHeader;
