import PropTypes from 'prop-types';
import React from 'react';
import StyledButtonSection from './styles/StyledButtonSection';

const ButtonSection = ({ employerId, handleSubmit, isEditView, handleCloseModal, onDelete }) => (
  <StyledButtonSection>
    <a onClick={handleSubmit} className="ds-button__primary">Submit</a>
    <a onClick={handleCloseModal} className="ds-button__secondary">Cancel</a>
    {isEditView && <a onClick={() => onDelete(employerId)} className="ds-button__primary delete-button">Delete</a>}
  </StyledButtonSection>
);

ButtonSection.propTypes = {
  employerId: PropTypes.number,
  handleCloseModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  isEditView: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
};

export default ButtonSection;
