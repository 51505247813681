import styled from 'styled-components';

const ExerciseTitle = styled.p`
  color: ${({ isNotFuture }) =>
    isNotFuture ? 'var(--ds-color__petrol)' : 'var(--ds-color__grey)'};
  margin: 0;
  padding-top: 3px;
`;

ExerciseTitle.displayName = 'ExerciseTitle';

export default ExerciseTitle;
