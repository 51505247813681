import styled from 'styled-components';

const StyledLabel = styled.div`
    width: 20%;
    label {
      display: inline;
    }
`;

StyledLabel.displayName = 'StyledLabel';

export default StyledLabel;
