import styled from 'styled-components';

const EmptyTabStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;

  p {
    margin-bottom: 0;
  }
`;

EmptyTabStyles.displayName = 'EmptyTab';

export default EmptyTabStyles;
