import ApiService from '../../services/ApiService';
import { getAuthToken } from '../../utils/getAuthToken';

interface TrackEventParams {
  data?: {
    [key: string]: any;
  };
  event: string;
}

export const trackEvent = ({ data, event }: TrackEventParams) => {
  const trackApi = new ApiService('/api/v1/analytics/track');

  try {
    trackApi.makePostCall({
      authenticity_token: getAuthToken(),
      data,
      event,
    });
  } catch (error) {
    // TODO: Report to Rollbar
  }
};
