import axios from 'axios';

export const PRESIGNED_URL_REQ_URL =
  'https://c23tj3p0gb.execute-api.us-east-1.amazonaws.com/staging/upload-file-staging';
export const S3_FILE_UPLOADS_URL =
  'https://getstream-file-uploads.s3.amazonaws.com';

export const uploadFile = async (file: any): Promise<any> => {
  if (file.size > 52428800) {
    throw new Error(
      'File size exceeds the 50MB limit, please reduce the size or upload another file.'
    );
  }
  const {
    data: { body },
  } = await axios(`${PRESIGNED_URL_REQ_URL}?fileKey=${file.name}`);
  const { fields, url } = JSON.parse(body).data;
  const formData = new FormData();

  Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
  formData.append('file', file);

  await axios.post(url, formData);

  return {
    file: `${S3_FILE_UPLOADS_URL}/${fields.key}`,
  };
};
