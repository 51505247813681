import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RatingIcon from './RatingIcon';
import StaticRatingIcon from './StaticRatingIcon';
import StyledStarRating from './styles/StyledStarRating';
import StyledStaticStarRating from './styles/StyledStaticStarRating';

const StarRating = ({ handleRating, modelId, rating, isModal }) => {
  const [localRating, setLocalRating] = useState(rating || 0);
  const [hoverRating, setHoverRating] = useState(0);

  const onMouseEnter = (index) => {
    setHoverRating(index);
  };

  const onMouseLeave = () => {
    setHoverRating(0);
  };

  const onSaveRating = (index) => {
    handleRating(modelId, index);
    setLocalRating(index);
  };

  return (
    handleRating
      ? <StyledStarRating isModal={isModal}>
        {[1, 2, 3, 4, 5].map((index) => {
          return (
            <RatingIcon
              key={index}
              index={index}
              rating={localRating}
              hoverRating={hoverRating}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onSaveRating={onSaveRating} />
          );
        })}
      </StyledStarRating>
      : <StyledStaticStarRating>
        {[1, 2, 3, 4, 5].map((index) => {
          return (
            <StaticRatingIcon
              key={index}
              index={index}
              rating={rating} />
          );
        })}
      </StyledStaticStarRating>
  );
};

StarRating.propTypes = {
  handleRating: PropTypes.func,
  isModal: PropTypes.bool,
  modelId: PropTypes.number.isRequired,
  rating: PropTypes.number,
};

export default StarRating;
