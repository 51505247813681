import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import StudentHandoffTable from '../../components/StudentHandoffTable';
import { connect } from 'react-redux';
import { getHandoffResponses } from './actions';

export const StudentHandoffTableContainer = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [responses, setResponses] = useState(null);

  useEffect(() => {
    getHandoffResponses(userId).then((resp) => {
      setResponses(resp);
      setLoading(false);
    });
  }, []);

  StudentHandoffTableContainer.propTypes = {
    userId: PropTypes.number.isRequired,
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <StudentHandoffTable responses={responses} />
  );
};

export const mapStateToProps = ({ userProfile: { userId } }) => ({
  userId,
});

export default connect(mapStateToProps, null)(StudentHandoffTableContainer);
