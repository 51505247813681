import ModalWrapper from './styles';
import PropTypes from 'prop-types';
import React from 'react';
import TheModal from '../../../shared/components/TheModal';
import Typeform from '../../../shared/components/Typeform';

const StudentHandoffModal = ({
  formId,
  handleClose,
  handleSubmit,
  loading,
  modalIsOpen,
}) => (
  !loading && <ModalWrapper>
    <TheModal
      handleClose={handleClose}
      isModalOpen={modalIsOpen}
    >
      <Typeform
        handleSubmit={handleSubmit}
        formId={formId}
      />
    </TheModal>
  </ModalWrapper>
);

StudentHandoffModal.propTypes = {
  formId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  modalIsOpen: PropTypes.bool.isRequired,
};

export default StudentHandoffModal;
