import { finishInterview, handleCloseModal, startReview } from './actions';
import InterviewModal from '../../components/InterviewModal';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  interviewPrep: {
    answersForReview,
    modalAction,
    modalIsOpen,
    questions,
    entryPoint,
  },
}) => ({
  answersForReview,
  entryPoint,
  modalAction,
  modalIsOpen,
  questions,
});

export const mapDispatchToProps = (dispatch) => ({
  finishInterview: () => dispatch(finishInterview()),
  handleCloseModal: () => dispatch(handleCloseModal()),
  startReview: () => dispatch(startReview()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterviewModal);
