import styled from 'styled-components';

const Border = styled.div`
  border: 1px solid #c0c6cb;
  margin: 22px 0 30px;
`;

Border.displayName = 'Border';

export default Border;
