import Border from '../../../../shared/styles/Border';
import CallFeedbackWrapperStyles from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const CallFeedbackWrapper = ({ border, call, handleClick }) => (
  <>
    <CallFeedbackWrapperStyles>
      <h3 className='ds-typography__body--bold'>Past Call</h3>
      <header>
        <p className='ds-typography__body'>with your mentor {call.mentor}</p>
        <img src={call.imageUrl} alt='mentor image' />
      </header>
      <p className='ds-typography__small'>{call.date}</p>
      <footer>
        <button className='ds-button__petrol' onClick={() => handleClick(call)}>
          Rate Call
        </button>
        <p className='ds-typography__small'>{call.expires}</p>
      </footer>
    </CallFeedbackWrapperStyles>
    {border && <Border />}
  </>
);

CallFeedbackWrapper.propTypes = {
  border: PropTypes.bool.isRequired,
  call: PropTypes.shape({
    callId: PropTypes.number,
    date: PropTypes.string,
    expires: PropTypes.string,
    imageUrl: PropTypes.string,
    mentor: PropTypes.string,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default CallFeedbackWrapper;
