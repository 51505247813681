import HeaderInfographic from '../../../JobApplicationTracker/components/HeaderInfographic';
import PropTypes from 'prop-types';
import React from 'react';
import StyledJobApplicationsPreviewHeader from './styles/StyledJobApplicationsPreviewHeader';

const JobApplicationPreviewHeader = ({ onClick, retrievingConsent }) => (
  <>
    <HeaderInfographic
      buttonLoading={retrievingConsent}
      onButtonClick={onClick}
    />
    <StyledJobApplicationsPreviewHeader>
      <p className="ds-typography__body--bold text-left">Recent Applications</p>
    </StyledJobApplicationsPreviewHeader>
  </>
);

JobApplicationPreviewHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  retrievingConsent: PropTypes.bool.isRequired,
};

export default JobApplicationPreviewHeader;
