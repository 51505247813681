import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import { SignupCard } from './SignupCard';
import TermsCheckbox from '../../../shared/termsCheckbox.jsx';
import { createCookie } from '../../../../utils/createCookie.js';
import { getPasswordScore } from '../../../../utils/insertZXCVBNScript';
import { handleFormSubmit } from '../../actions';

let FORM_ELEMENT = null;

const AfaSignupForm = ({ email }) => {
  const defaultStrength = {
    score: 0,
    textLabel: 'At least 8 characters',
  };
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordStrengthScore, setPasswordStrengthScore] =
    useState(defaultStrength);

  useEffect(() => {
    FORM_ELEMENT = new CF.Form('#instructor-enrollment-form');
    window.insertZXCVBNScript();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      FORM_ELEMENT._initializeFrozenLabels();
    }, 0);
  }, []);

  const togglePassword = () => setPasswordVisible(!passwordVisible);

  const handlePasswordChange = (value) => {
    const { score, textLabel } = getPasswordScore(value);
    setPasswordStrengthScore({
      score: score,
      textLabel: textLabel,
    });
    setPassword(value);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const termsCheckbox = document.querySelector('#terms__checkbox').checked;
    const uuid = location.pathname.split('/').pop();
    const params = {
      user_signup_form: {
        agreed_to_terms: termsCheckbox,
        password,
      },
    };

    handleFormSubmit(params, uuid)
      .then((res) => {
        createCookie('afaModal', true);
        window.location.href = res.data.redirectUrl;
      })
      .catch((errors) => {
        setErrors(errors?.errors || 'Something went wrong, please try again');
        setLoading(false);
      });
  };

  return (
    <SignupCard>
      <div>
        <h3 className="ds-typography__h3">Sign up to CareerFoundry</h3>
        <p className="ds-typography__body">
          Welcome! Congratulations on joining the CareerFoundry community. We
          are so happy to have you with us!
        </p>
        <form
          onSubmit={handleSubmit}
          className="cf-form"
          id="instructor-enrollment-form"
        >
          <p className="ds-typography__small helper-text">
            All fields are required
          </p>
          <div className="ds-form__input__wrapper">
            <label
              htmlFor="preselected-email"
              className="ds-form__input__label"
            >
              Email
            </label>
            <input
              id="preselected-email"
              value={email}
              disabled
              type="email"
              className="ds-form__input disabled"
            />
          </div>
          <p className="ds-typography__small">Password (min. 8 characters)</p>
          <div className="ds-form__input__wrapper">
            <label htmlFor="password" className="ds-form__input__label">
              Password
            </label>
            <input
              id="password"
              type={passwordVisible ? 'text' : 'password'}
              className="ds-form__input"
              onChange={(e) => handlePasswordChange(e.target.value)}
              value={password}
              minLength="8"
              required
            />
            <i
              className={`fa fa-${passwordVisible ? 'eye-slash' : 'eye'}`}
              onClick={() => togglePassword()}
            ></i>
          </div>
          <div className="password-strength-indicator__wrapper">
            <progress
              className="strength-meter"
              max="4"
              value={passwordStrengthScore.score}
            ></progress>
            <div className="static-label">
              Password
              <br />
              Strength
            </div>
            <span className="label-text">
              {passwordStrengthScore.textLabel}
            </span>
          </div>
          <TermsCheckbox
            name="terms__checkbox"
            id="terms__checkbox"
            required={true}
            termsRequired={true}
          />
          {errors && (
            <p className="ds-typography__body--bold ds-color__error">
              {errors}
            </p>
          )}
          <div className="text-center">
            <button type="submit" className="ds-button ds-button__primary">
              {loading ? <LoadingSpinner /> : 'Sign up'}
            </button>
          </div>
        </form>
      </div>
    </SignupCard>
  );
};

AfaSignupForm.propTypes = {
  email: PropTypes.string,
};

export default AfaSignupForm;
