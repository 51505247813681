import React, { useEffect } from 'react';
import CurriculumPage from '../../components/CurriculumPage';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurriculumStructure } from '../../actions';

const CurriculumPageComponent = ({
  curriculumStructure,
  onGetCurriculumStructure,
  ...props
}) => {
  useEffect(() => {
    onGetCurriculumStructure();
  }, []);

  return (
    curriculumStructure && (
      <CurriculumPage curriculumStructure={curriculumStructure} {...props} />
    )
  );
};

CurriculumPageComponent.propTypes = {
  curriculumStructure: PropTypes.shape({
    curriculumStructure: PropTypes.object,
    timeline: PropTypes.array,
    type: PropTypes.string,
  }),
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  navigateTo: PropTypes.func,
  onGetCurriculumStructure: PropTypes.func,
};

export const mapStateToProps = (state) => {
  return {
    curriculumStructure: state.onBoarding.curriculumStructure,
    isLoading: state.onBoarding.isLoading,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onGetCurriculumStructure: () => dispatch(getCurriculumStructure()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurriculumPageComponent);
