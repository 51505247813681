import PropTypes from 'prop-types';
import React from 'react';

const HandoffDisclaimer = ({ disclaimerType }) => (
  <>
    <p>-*</p>
    <br />
    <p className='ds-typography__small'>
      *(this question is only addressed to the {disclaimerType})
    </p>
  </>
);

HandoffDisclaimer.propTypes = {
  disclaimerType: PropTypes.string,
};

export default HandoffDisclaimer;
