import styled from 'styled-components';

const ControlButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: inherit;
  margin-left: 16px;
`;

ControlButtons.displayName = 'ControlButtons';

export default ControlButtons;
