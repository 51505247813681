import React from 'react';

const Introduction = () => (
  <>
    <p className="ds-typography__body break">As you embark on your new career path, it  is important that you continue to refine your industry skills. This will increase your chances of getting your dream job. At the same time, you'll want to stand out from other applicants. This is why we recommend that you work on an additional project to enhance your portfolio. Throughout this guide, we’ll help you plan and execute your next portfolio project, breaking it down into stages and helping you set achievable goals every step of the way.</p>
    <p className="ds-typography__body break">Remember: Your portfolio is a showcase of projects intended to show employers what you can do and help you get you the job you want at your current career stage. As you add to your portfolio, be sure to document every step of your project so that employers get a clear, well-rounded view of your process.</p>
    <p className="ds-typography__body--bold">Helpful resources:</p>
    <ul>
      <li><a href="https://www.invisionapp.com/inside-design/sample-projects-ux-portfolio" target="_blank" rel="noreferrer">How to come up with sample UX design projects</a></li>
      <li><a href="https://miro.com/templates/ux-research-plan" target="_blank" rel="noreferrer">UX Research Plan (Miro Template)</a></li>
      <li><a href="https://uxstudioteam.com/ux-blog/product-management-tools/" target="_blank" rel="noreferrer">Best Product Management Tools</a></li>
      <li><a href="https://uxdesign.cc/how-to-start-any-ux-project-f6bf1b8e1f6e" target="_blank" rel="noreferrer">How to Start Any UX Project</a></li>
      <li><a href="https://learnui.design/blog/how-create-ux-ui-portfolio-without-much-experience.html" target="_blank" rel="noreferrer">How to Create a UX/UI Design Portfolio Without Much Experience</a></li>
      <li><a href="https://www.bigmarker.com/careerfoundry/How-To-Create-a-Great-UX-UI-Portfolio" target="_blank" rel="noreferrer">How To Create a Great UX/UI Design Portfolio</a></li>
      <li><a href="https://lnkd.in/d_6s9rH" target="_blank" rel="noreferrer">Slack Channel of upcoming project briefs for juniors</a></li>
    </ul>
  </>
);

export default Introduction;
