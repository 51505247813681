import React, { useEffect, useState } from 'react';
import { getCurrentUser, getOnboardingStatus, showPasswordChangeModal } from '../../actions';
import EmployerOnboarding from '../../components/onboarding';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
const EmployerOnboardingContainer = ({
  employer,
  getCurrentUser,
  getOnboardingStatus,
  onboardingStatus,
  showPasswordChangeModal,
}) => {
  const [step, setStep] = useState('introduction');

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (employer && employer.id) {
      getOnboardingStatus(employer.id);
    }
  }, [employer]);

  useEffect(() => {
    const termsSigned = onboardingStatus && onboardingStatus.agreedToTerms && onboardingStatus.agreedToPrivacy;
    if (termsSigned && !onboardingStatus.passwordUpdatedAt) {
      showPasswordChangeModal();
    }
  }, [onboardingStatus]);

  useEffect(() => {
    if (onboardingStatus && onboardingStatus.agreedToTerms && !onboardingStatus.agreedToPrivacy) {
      setStep('privacyPolicy');
    }
  }, [onboardingStatus]);
  return (
    <EmployerOnboarding setStep={setStep} step={step} />
  );
};

EmployerOnboardingContainer.propTypes = {
  employer: PropTypes.shape({
    id: PropTypes.number,
  }),
  getCurrentUser: PropTypes.func.isRequired,
  getOnboardingStatus: PropTypes.func.isRequired,
  onboardingStatus: PropTypes.shape({
    agreedToPrivacy: PropTypes.bool,
    agreedToTerms: PropTypes.bool,
    passwordUpdatedAt: PropTypes.string,
  }),
  showPasswordChangeModal: PropTypes.func.isRequired,
};

export const mapStateToProps = ({
  employerDashboard: {
    employer,
    onboardingStatus,
  },
}) => ({
  employer,
  onboardingStatus,
});

export const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => dispatch(getCurrentUser()),
  getOnboardingStatus: (employerId) => dispatch(getOnboardingStatus(employerId)),
  showPasswordChangeModal: () => dispatch(showPasswordChangeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployerOnboardingContainer);
