import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;

  @media (min-width: 1480px) {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
