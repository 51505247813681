import { IModalAction } from '../../interfaces';

export const formatRejectionParams = (
  modalAction: IModalAction,
  reason: string
) => {
  const sectionKey =
    modalAction.attribute === 'physicalVoucher'
      ? 'afa_voucher'
      : modalAction.attribute;
  const reasonKey =
    modalAction.attribute === 'physicalVoucher'
      ? 'physical_voucher_reason'
      : 'reason';
  return {
    [sectionKey]: {
      state: 'rejected',
      [reasonKey]: reason,
    },
  };
};

export const formatApprovalParams = (modalAction: IModalAction) => {
  const sectionKey =
    modalAction.attribute === 'physicalVoucher'
      ? 'afa_voucher'
      : modalAction.attribute;
  const stateKey =
    modalAction.attribute === 'physicalVoucher'
      ? 'physical_voucher_state'
      : 'state';
  return {
    [sectionKey]: {
      [stateKey]: 'done',
    },
  };
};
