import Button from './styles/SendButton';
import PropTypes from 'prop-types';
import React from 'react';

const SendButton = ({ mentorId, userId }) => {
  return (
    mentorId &&
    userId &&
    mentorId !== userId && (
      <Button
        target='_blank'
        href='/en/messaging'
        className='ds-typography__small'
      >
        Send message
      </Button>
    )
  );
};

SendButton.propTypes = {
  mentorId: PropTypes.number,
  userId: PropTypes.number,
};

export default SendButton;
