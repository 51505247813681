import { Body, Button } from '@careerfoundry/bubble';
import React from 'react';
import Tooltip from '../../../shared/components/Tooltip';
import { Wrapper, StyledTooltip } from './styles';
import AiConsentModal from '../AiConsentModal';

interface TaskSubmissionButtonProps {
  isLoadingModal: boolean;
  isAiEvaluationUnderProgress: boolean;
  submitTask: () => void;
  submitTaskForAiEvaluation: () => void;
  isAiTutorEnabled: boolean;
  aiTutorConsent: boolean;
  consentModalIsOpen: boolean;
  handleAiEvaluationWithoutConsent: () => void;
  toggleConsentModal: (modalIsOpen: boolean) => void;
}

const TaskSubmissionButton = ({
  isLoadingModal,
  submitTask,
  submitTaskForAiEvaluation,
  isAiEvaluationUnderProgress,
  isAiTutorEnabled,
  aiTutorConsent,
  consentModalIsOpen,
  handleAiEvaluationWithoutConsent,
  toggleConsentModal,
}: TaskSubmissionButtonProps) => {
  const TooltipContent = () => (
    <Body tag='p' variant='medium'>
      <Body tag='span' variant='medium-bold'>
        AI Evaluate
      </Body>{' '}
      can check your submission instantly, give you valuable feedback, and
      ensure you’re on the right path. Once you’re happy with the evaluation,
      submit your task to your instructor for approval.
    </Body>
  );

  return (
    <Wrapper>
      {isAiTutorEnabled && (
        <Tooltip
          content={<TooltipContent />}
          customStyledComponentWrapper={StyledTooltip}
          place='top'
          backgroundColor='var(--ds-palette__eggshell-50)'
        >
          <Button
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();

              if (aiTutorConsent) {
                submitTaskForAiEvaluation();
              } else {
                handleAiEvaluationWithoutConsent();
              }
            }}
            label='AI evaluation'
            size='standard'
            variant='secondary'
            disabled={isAiEvaluationUnderProgress}
          />
        </Tooltip>
      )}
      <Button
        label='Submit to instructor'
        variant='primary'
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          submitTask();
        }}
        isLoading={isLoadingModal}
        disabled={isAiEvaluationUnderProgress}
      />
      <AiConsentModal
        modalIsOpen={consentModalIsOpen}
        handleClose={() => toggleConsentModal(false)}
        submitTaskForAiEvaluation={submitTaskForAiEvaluation}
      />
    </Wrapper>
  );
};

export default TaskSubmissionButton;
