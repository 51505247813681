import { chain } from 'lodash';

export const groupInterviewAnswers = arr => {
  return groupByCategory(arr);
};

const groupByCategory = (arr) => {
  const previouslyAnsweredCategories = formatPreviouslyAnsweredCategories(arr);
  return previouslyAnsweredCategories.map(category => {
    const categoryAnswers = arr.filter(answer => answer.categories.includes(category));
    const questions = groupByQuestion(categoryAnswers);
    return {
      category: category,
      questions: questions,
    };
  });
};

const groupByQuestion = (answers) => {
  return chain(answers).groupBy('question').map((vv, question) => {
    return {
      answers: vv,
      question: question,
      questionInsights: vv[0].questionInsights,
    };
  }).value();
};

const formatPreviouslyAnsweredCategories = arr => {
  const all = arr.map(question => question.categories);
  return [...new Set([].concat.apply([], all))];
};

export const appendNewInterviewAnswer = (answer, previousAnswersState) => {
  const existingCategories = previousAnswersState.map(question => question.category);
  let newAnswersState = [...previousAnswersState];
  answer.categories.forEach((category) => {
    if (existingCategories.includes(category)) {
      newAnswersState = findMatchingCategory(answer, previousAnswersState, category);
    } else {
      newAnswersState = newCategory(answer, newAnswersState, category);
    }
  });
  return newAnswersState;
};

const newCategory = (answer, previousAnswersState, category) => {
  const obj = {
    category: category,
    questions: [
      {
        answers: [answer],
        question: answer.question,
        questionInsights: answer.questionInsights,
      },
    ],
  };
  return [...previousAnswersState, obj];
};

const findMatchingCategory = (answer, arr, category) => {
  arr.forEach(categoryObject => {
    if (categoryObject.category === category) {
      handleSameCategory(categoryObject, answer);
    }
  });
  return arr;
};

const handleSameCategory = (categoryObject, answer) => {
  const existingQuestions = categoryObject.questions.map(question => question.question);

  if (existingQuestions.includes(answer.question)) {
    const question = categoryObject.questions.filter(question => question.question === answer.question)[0];
    question.answers.unshift(answer);
  } else {
    const newQuestionObject = {
      answers: [answer],
      question: answer.question,
      questionInsights: answer.questionInsights,
    };
    categoryObject.questions.unshift(newQuestionObject);
  };
};

export const replaceEvaluatedResponse = (answer, arr) => {
  return findAndModify(answer, arr, replaceResponse);
};

export const replaceAnswersForReview = (answer, arr) => {
  const index = arr.findIndex(o => o.id === answer.id);
  arr[index] = answer;
  return arr;
};

export const deleteAnswer = (answer, arr) => {
  return findAndModify(answer, arr, deleteResponse);
};

const findAndModify = (answer, arr, modifyFunction) => {
  const newArr = arr.map(categoryObject => {
    answer.categories.forEach(answerCategory => {
      if (categoryObject.category === answerCategory) {
        handleModification(categoryObject, answer, modifyFunction);
      }
    });
    return categoryObject;
  });

  return newArr;
};

const handleModification = (categoryObject, answer, modifyFunction) => {
  categoryObject.questions.forEach(questionObject => {
    if (questionObject.question === answer.question) {
      const index = questionObject.answers.findIndex(a => a.id === answer.id);
      modifyFunction(questionObject, index, answer);
    }
  });
};

const deleteResponse = (questionObject, index, answer) => {
  questionObject.answers.splice(index, 1);
};

const replaceResponse = (questionObject, index, answer) => {
  questionObject.answers[index] = answer;
};

export const formatReviewDate = (dateString) => {
  const date = dateString.split(',')[0];
  const dateArr = date.split(',')[0].split(' ');
  const [day, monthString, year] = dateArr;

  const month = getMonth(monthString.substring(0, 3));

  return `${day}.${month}.${year}`;
};

export const getMonth = (monthString) => {
  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  let month = '' + (months.indexOf(monthString.toLowerCase()) + 1);
  if (month.length < 2) {
    month = '0' + month;
  }
  return month;
};
