import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import AdminWrapper from '../../../../shared/adminWrapper.jsx';
import Candidate from '../../containers/Candidate';
import CandidateForm from '../../containers/CandidateForm';
import CandidateList from '../CandidatesList';
import PropTypes from 'prop-types';
import React from 'react';
import { Wrapper } from './styles';

const App = ({
  candidatesList,
  candidatesListLoaded,
  candidateStates,
  interviewers,
  sendWelcomeEmail,
}) => {
  const location = useLocation();

  return (
    <Wrapper>
      <Switch location={location}>
        <Route exact path='/:lang/admin/instructors/candidates/'>
          <AdminWrapper headerText='Instructor Candidates'>
            <CandidateList
              candidatesList={candidatesList}
              candidatesListLoaded={candidatesListLoaded}
              candidateStates={candidateStates}
              interviewers={interviewers}
              sendWelcomeEmail={sendWelcomeEmail}
            />
          </AdminWrapper>
        </Route>
        <Route path='/:lang/admin/instructors/candidates/new'>
          <AdminWrapper headerText='Instructor Candidates'>
            <CandidateForm />
          </AdminWrapper>
        </Route>
        <Route path='/:lang/admin/instructors/candidates/:candidateId/edit'>
          <AdminWrapper headerText='Instructor Candidates'>
            <CandidateForm />
          </AdminWrapper>
        </Route>
        <Route path='/:lang/admin/instructors/candidates/:candidateId'>
          <Candidate fetchParams={useParams} />
        </Route>
      </Switch>
    </Wrapper>
  );
};

App.propTypes = {
  candidateStates: PropTypes.array.isRequired,
  candidatesList: PropTypes.array.isRequired,
  candidatesListLoaded: PropTypes.bool.isRequired,
  interviewers: PropTypes.array.isRequired,
  sendWelcomeEmail: PropTypes.func.isRequired,
};

export default App;
