import { openInterviewGuidelines, startInterview } from './actions';
import InterviewToolCard from '../../components/InterviewToolCard/index';
import { connect } from 'react-redux';

export const mapDispatchToProps = (dispatch) => ({
  openInterviewGuidelines: () => dispatch(openInterviewGuidelines()),
  startInterview: (entryPoint) => dispatch(startInterview(entryPoint)),
});

export default connect(null, mapDispatchToProps)(InterviewToolCard);
