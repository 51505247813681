import { CloseButton, FrozenBody, ModalBackground, ModalBox } from './styles';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const TheModal = ({
  children,
  closeButton = true,
  className = '',
  isModalOpen = false,
  handleClose,
  style = {},
}) => {
  const [isOpen, setIsOpen] = useState(isModalOpen);

  useEffect(() => setIsOpen(isModalOpen), [isModalOpen]);

  const handleClick = () => (handleClose ? handleClose() : setIsOpen(false));

  return (
    <>
      {isOpen && (
        <>
          <FrozenBody />
          <ModalBackground onClick={handleClick} className='the-modal-bg'>
            <ModalBox
              onClick={(e) => e.stopPropagation()}
              className={className || 'TheModal'}
              style={style}
            >
              {closeButton && (
                <CloseButton
                  className='ds-icon__close-thin'
                  onClick={handleClick}
                />
              )}
              {children}
            </ModalBox>
          </ModalBackground>
        </>
      )}
    </>
  );
};

TheModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeButton: PropTypes.bool,
  handleClose: PropTypes.func,
  isModalOpen: PropTypes.bool,
  style: PropTypes.object,
};

export default TheModal;
