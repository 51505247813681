import React from 'react';
import { BSGProps } from './interfaces';
import FirstTab from './FirstTab';
import SecondTab from './SecondTab';
import { StyledAccordion } from './styles';

const BSG: React.FC<BSGProps> = ({
  tabData,
  selectedSubTab,
  setSelectedSubTab,
  updateAdmission,
}) => {
  return (
    <StyledAccordion onChange={(e) => setSelectedSubTab(e.join())}>
      <FirstTab
        selectedSubTab={selectedSubTab}
        tabData={tabData}
        updateAdmission={updateAdmission}
      />
      <SecondTab
        selectedSubTab={selectedSubTab}
        tabData={tabData}
        updateAdmission={updateAdmission}
      />
    </StyledAccordion>
  );
};

export default BSG;
