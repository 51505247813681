import App from './containers/App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../../shared/reduxStore';

const timeOffContainer = document.getElementById('timeOff');

if (timeOffContainer) {
  const timeOffContainerRoot = createRoot(timeOffContainer);

  timeOffContainerRoot.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}
