import AdminUserRoles from '../../../react/admin_user';
import React from 'react';
import { createRoot } from 'react-dom/client';

const adminUserRolesContainer = document.querySelector('.admin_user__roles');

if (adminUserRolesContainer) {
  const adminUserRolesContainerRoot = createRoot(adminUserRolesContainer);

  adminUserRolesContainerRoot.render(<AdminUserRoles />);
}
