import { handleDeleteJobApplication, handleUpdateJobApplication, handleViewJobApplication } from '../../../CareerSupportCenter/actions';
import JobApplicationsTable from '../../../shared/components/JobApplicationsTable';
import { connect } from 'react-redux';

const headers = [
  'date',
  { name: 'role', type: 'viewLink' },
  'status',
  'company',
  { name: 'link', type: 'link' },
  'followUpDate',
];
export const mapStateToProps = ({
  careerSupportCenter: {
    jobApplications,
  },
}) => ({
  headers,
  jobApplications,
});

export const mapDispatchToProps = dispatch => ({
  handleDeleteJobApplication: id => dispatch(handleDeleteJobApplication(id)),
  handleUpdateJobApplication: id => dispatch(handleUpdateJobApplication(id)),
  handleViewJobApplication: id => dispatch(handleViewJobApplication(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobApplicationsTable);
