import React, { useEffect, useState } from 'react';
import { InstructorEnrollCard } from './StyledInstructorEnrollCard';
import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import TermsCheckbox from '../../../../shared/termsCheckbox.jsx';
import { getPasswordScore } from '../../../../../utils/insertZXCVBNScript';

let FORM_ELEMENT = null;

const InstructorSignupForm = ({
  errorMessage,
  handleFormSubmit,
  instructorDetails,
  loading,
}) => {
  const defaultStrength = {
    score: 0,
    textLabel: 'At least 8 characters',
  };
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordStrengthScore, setPasswordStrengthScore] =
    useState(defaultStrength);

  useEffect(() => {
    FORM_ELEMENT = new CF.Form('#instructor-enrollment-form');
    window.insertZXCVBNScript();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      FORM_ELEMENT._initializeFrozenLabels();
    }, 0);
  }, [instructorDetails]);

  const togglePassword = () => setPasswordVisible(!passwordVisible);

  const handlePasswordChange = (value) => {
    const { score, textLabel } = getPasswordScore(value);
    setPasswordStrengthScore({
      score,
      textLabel,
    });
    setPassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const termsCheckbox = document.querySelector('#terms__checkbox').checked;

    handleFormSubmit({
      firstName: instructorDetails.firstName,
      lastName: instructorDetails.lastName,
      password,
      termsCheckbox,
    });
  };

  return (
    <InstructorEnrollCard>
      <div>
        <h3 className="ds-typography__h3">Sign-Up</h3>
        <p className="ds-typography__body">
          Welcome! Congratulations on joining the CareerFoundry community. We
          are so happy to have you with us!
        </p>
        <form
          onSubmit={handleSubmit}
          className="cf-form"
          id="instructor-enrollment-form"
        >
          <p className="ds-typography__small helper-text">* required field</p>
          <div className="ds-form__input__wrapper">
            <label
              htmlFor="preselected-email"
              className="ds-form__input__label"
            >
              Email
            </label>
            <input
              id="preselected-email"
              value={instructorDetails.email}
              disabled
              type="email"
              className="ds-form__input disabled"
            />
          </div>
          <p className="ds-typography__small">
            Please create a password for your profile
          </p>
          <div className="ds-form__input__wrapper">
            <label htmlFor="password" className="ds-form__input__label">
              Password<sup>*</sup>
            </label>
            <input
              id="password"
              type={passwordVisible ? 'text' : 'password'}
              className="ds-form__input"
              onChange={(e) => handlePasswordChange(e.target.value)}
              value={password}
              minLength="8"
              required
            />
            <i
              className={`fa fa-${passwordVisible ? 'eye-slash' : 'eye'}`}
              onClick={() => togglePassword()}
            ></i>
          </div>
          <div className="password-strength-indicator__wrapper">
            <progress
              className="strength-meter"
              max="4"
              value={passwordStrengthScore.score}
            ></progress>
            <div className="static-label">
              Password
              <br />
              Strength
            </div>
            <span className="label-text">
              {passwordStrengthScore.textLabel}
            </span>
          </div>
          <TermsCheckbox
            name="terms__checkbox"
            id="terms__checkbox"
            required={true}
            termsRequired={true}
          />
          <p className="ds-typography__body--bold ds-color__error">
            {errorMessage}
          </p>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="text-center">
              <input
                type="submit"
                value="Sign up"
                className="ds-button ds-button__primary"
              />
            </div>
          )}
        </form>
      </div>
    </InstructorEnrollCard>
  );
};

InstructorSignupForm.propTypes = {
  errorMessage: PropTypes.string,
  handleFormSubmit: PropTypes.func,
  instructorDetails: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

export default InstructorSignupForm;
