import React, { Component } from 'react';

class SigninForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      password: "",
      rememberMe: 1,
    };
  };

  componentDidMount(){
    const form = new CF.Form('#enroll__user_login');
  };

  handleSubmit = event => {
    event.preventDefault();
    let { email, password, rememberMe } = this.state;
    this.props.userSignin(email, password, rememberMe);
  }

  onRememberMeChange = event => {
    this.setState({
      rememberMe: +event.target.checked
    });
  }

  onChange = event => {
    const state = this.state;
    state[event.target.name] = event.target.value;
    this.setState(state);
  };

  render() {
    let isDisabled = this.state.email.length < 1 || this.state.password.length < 1;
    let toggleButtonClass = isDisabled ? "button--primary-disabled" : "button--primary-petrol";

    const renderButton = () => {
      if(this.props.loadingState) {
        return(<button disabled={true} className="button--large button--primary-petrol" dangerouslySetInnerHTML={{__html: CF.html.loadingSpinner}}></button>);
      }else{
        return(<button type="submit" disabled={isDisabled} className={toggleButtonClass}>Login</button>);
      }
    };

    return(
      <div className="enroll__signin__form enroll__form enroll__slide">
        <h2>Log In to Your CareerFoundry Account</h2>
        <form className={`cf-form ${(this.props.formError != "") ? "with__errors" : ""}`} id="enroll__user_login" onSubmit={this.handleSubmit}>
          <div className="cf-form__group--floating">
            <input type="email" name="email" value={this.state.email} onChange={this.onChange} required="required" className="cf-form__input"/>
            <label className="cf-form__label--floating">Email Address</label>
            <div className="cf-form__validation"></div>
          </div>
          <div className="cf-form__group--floating">
            <input type="password" name="password" value={this.state.password} onChange={this.onChange} required="required" className="cf-form__input"/>
            <label className="cf-form__label--floating">Password</label>
            <div className="cf-form__validation"></div>
            <div className="error__container">
              <p className="cf-small cf-text-right">{this.props.displayErrorMessage}</p>
            </div>
          </div>
          <div className="cf-form__checkbox--open cf-form__group--frozen remember-me-checkbox">
            <div>
              <input id="rememberMe" name="rememberMe" checked={this.state.rememberMe} type="checkbox" onChange={this.onRememberMeChange}/>
              <label htmlFor="rememberMe">Remember me</label>
            </div>
            <div>
              <a className="cf-small" href="/users/password/new">Forgot your password?</a>
            </div>
          </div>
          {renderButton()}
        </form>
        <p className="cf-small cf-text-center">New User? <a onClick={this.props.onSignupClick}>Register</a></p>
      </div>
    )
  }
};

export default SigninForm;
