/**
 *
 * Helper functions to customize api return data in camelCase and convert nulls to empty strings

 * Function params legend
 * o => object
 * s => string
 * a => array
 */

const toCamel = s => {
  return s.replace(/([-_][a-z0-9])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const toSnake = string => {
  const upperChars = string.match(/([A-Z0-9])/g);
  if(!upperChars) {
    return string;
  }

  let str = string.toString();
  for(let i = 0, n = upperChars.length; i < n; i++) {
    str = str.replace(new RegExp(upperChars[i]), '_' + upperChars[i].toLowerCase());
  }

  if(str.slice(0, 1) === '_') {
    str = str.slice(1);
  }

  return str;
};

const isArray = a => {
  return Array.isArray(a);
};

const isObject = o => {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const driver = (o, fn) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[fn(k)] = driver(o[k], fn);
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return driver(i, fn);
    });
  }

  return o !== null && o !== undefined ? o : '';
};

export const keysToCamel = obj => {
  return driver(obj, toCamel);
};

export const keysToSnake = obj => {
  return driver(obj, toSnake);
};
