import { Dispatch } from 'redux';
import SubmissionModal from '../../components/SubmissionModal';
import { connect } from 'react-redux';
import { handleClose } from '../../actions';

interface IProps {
  instructorProfile: {
    modalIsOpen: boolean;
  };
}

export const mapStateToProps = ({
  instructorProfile: { modalIsOpen },
}: IProps) => ({
  modalIsOpen,
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleClose: () => dispatch(handleClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionModal);
