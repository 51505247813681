import { Body, Button, Header } from '@careerfoundry/bubble';
import { Card } from './styles';
import Modal from '../Modal';
import React from 'react';
import { redirectTo } from '../../../../../utils/redirectTo';

interface DashboardCardProps {
  modalAction: string;
  setModalAction: (modalAction: string) => void;
  handleModalClose: () => void;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  modalAction,
  setModalAction,
  handleModalClose,
}) => {
  return (
    <Card>
      <Header tag='h4' variant='h3'>
        Off-Platform Services
      </Header>
      <Body tag='p' variant='medium'>
        Providing student support off-platform? Send all external invoice claims
        to us here.
      </Body>
      <div>
        <Button
          label='Add claim'
          onClick={() => setModalAction('form')}
          size='standard'
          variant='secondary'
        />
        <Button
          label='View claims'
          onClick={() => redirectTo({ url: '/en/off-platform-services' })}
          size='standard'
          variant='primary'
        />
      </div>
      <Modal handleModalClose={handleModalClose} modalAction={modalAction} />
    </Card>
  );
};

export default DashboardCard;
