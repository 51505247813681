import styled from 'styled-components';

const StyledLinkEdit = styled.div`
  overflow: hidden;
  display: flex;
  text-overflow: ellipsis;
  color: var(--ds-color__petrol);
  i {
    margin-left: 8px;
    color: #a9a9a9 !important;
    font-size: 15px !important;
    cursor: pointer;
  }

  a {
    overflow: hidden !important;
    width: 190px;
    max-height: 24px;
    overflow: auto;
    text-overflow: ellipsis;
  }

  .icon-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: self-start;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    i {
      font-size: 22px !important;
      margin-top: 15px;
    }
  }
`;

StyledLinkEdit.displayName = 'StyledLinkEdit';

export default StyledLinkEdit;
