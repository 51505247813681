/**
 * supplied two dates, it returns number of days between the two dates
 * @param startDate
 * @param endDate
 * @returns {number}
 */

export const daysUntilDate = (startDate, endDate) =>
  Math.abs(daysUntilDateRaw(startDate, endDate));

export const daysUntilDateRaw = (startDate, endDate) => {
  if (!startDate || !endDate) throw 'startdate and enddate needs to be supplied';

  const msInDay = 1000 * 60 * 60 * 24;
  const differenceInMs = startDate.getTime() - endDate.getTime();
  return Math.round(differenceInMs / msInDay);
};
