import React from 'react';
import StyledLink from './styles/StyledLink';
import { noop } from 'lodash';

interface ButtonWithIconProps {
  className: string;
  href: string;
  icon: string;
  onClick?: () => void;
  text: string;
  type: string;
}

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  onClick = noop,
  href,
  icon,
  text,
  type = 'secondary',
  className = '',
}) => (
  <StyledLink
    href={href}
    onClick={onClick}
    className={`ds-button__${type} ${className}`}
  >
    <i className={`fa fa-${icon}`}></i>
    <span>{text}</span>
  </StyledLink>
);

export default ButtonWithIcon;
