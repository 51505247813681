import App from '../../components/App';
import { closeMessagingWindow } from './actions';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  messaging: { activeChannel, isMessagingWindowOpen },
}) => ({
  activeChannel,
  isMessagingWindowOpen,
});

export const mapDispatchToProps = (dispatch) => ({
  closeMessagingWindow: () => dispatch(closeMessagingWindow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
