import { BellIcon, HamburgerIcon, MailIcon } from '../../icons';
import { IconWrapper, RightSideIcons, Wrapper } from './styles';
import React from 'react';
import { getPathnameFromPageName, PageNames } from '../../helpers';
import { noop } from 'lodash';
import { redirectTo } from '../../../../utils/redirectTo';

interface Props {
  setIsSideNavListVisible: (status: boolean) => void;
}

const TopNavBar: React.FC<Props> = ({ setIsSideNavListVisible = noop }) => (
  <Wrapper>
    <IconWrapper onClick={() => setIsSideNavListVisible(true)}>
      <HamburgerIcon />
    </IconWrapper>
    <RightSideIcons>
      <IconWrapper
        onClick={() =>
          redirectTo({
            url: getPathnameFromPageName({ pageName: PageNames.NOTIFICATIONS }),
          })
        }
      >
        <BellIcon />
      </IconWrapper>
      <IconWrapper
        onClick={() =>
          redirectTo({
            url: getPathnameFromPageName({ pageName: PageNames.MESSAGING }),
          })
        }
      >
        <MailIcon />
      </IconWrapper>
    </RightSideIcons>
  </Wrapper>
);

export default TopNavBar;
