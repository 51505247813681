import {
  HelpfulCheckedIcon,
  HelpfulIcon,
  HelpfulInTransition2Icon,
  HelpfulInTransitionIcon,
  InfoIcon,
} from './icons';
import React, { useEffect, useRef, useState } from 'react';
import HelpfulSection from './styles/HelpfulSection';
import MotivationalMessageCard from './styles/Card';
import PropTypes from 'prop-types';
import StyledBackgroundImage from './styles/StyledBackgroundImage';
import StyledContentSection from './styles/StyledContentSection';
import StyledFooter from './styles/StyledFooter';
import StyledImageSection from './styles/StyledImageSection';
import TextBox from './styles/TextBox';
import Tooltip from '../../../shared/components/Tooltip';
import { consumeMessage } from '../../actions';
import { motivationalMessageTracking } from '../../../shared/helpers/tracking';
import useOnScreen from '../../../shared/hooks/useOnScreen';

const MotivationalMessage = ({
  handleRating,
  indexControls,
  motivationalMessage,
  toggleMessage,
  pageIndex,
  totalCount,
}) => {
  const { nextRecord, previousRecord } = indexControls;
  const { id, userHasLiked, message } = motivationalMessage;
  const messageContainer = useRef();
  const isVisible = useOnScreen(messageContainer);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [ratingTransition, setRatingTransition] = useState(false);

  useEffect(() => {
    const links = messageContainer.current.querySelectorAll('a');
    if (links.length) {
      links.forEach((link) => {
        link.addEventListener('click', () => {
          motivationalMessageTracking(
            id,
            link.href,
            links.length,
            'motivational_message_link_clicked'
          );
        });
      });
    }
  }, [toggleMessage]);

  useEffect(() => {
    if (isVisible && !motivationalMessage.userHasSeen) {
      consumeMessage(motivationalMessage.id);
    }
  }, [isVisible]);

  const handleRatingClick = () => {
    setRatingLoading(!userHasLiked);
    setTimeout(() => {
      setRatingTransition(!userHasLiked);
      handleRating(id, userHasLiked ? 0 : 1);
    }, 1000);
  };

  const clearRatingClick = () => {
    setRatingLoading(false);
    setRatingTransition(false);
  };

  const renderArrowIcon = (showIcon, arrowDirection, isJumpTo, iconName) =>
    showIcon ? (
      <i
        onClick={() => {
          clearRatingClick();
          toggleMessage(arrowDirection, isJumpTo);
        }}
        className={`fa ${showIcon ? 'clickable' : 'grey'} ${iconName}`}
        rel='noopener noreferrer'
      />
    ) : null;

  return (
    <MotivationalMessageCard className='tour__motivational-messages large-tile'>
      <StyledImageSection className='ds-grid__col-sm-12 ds-grid__col-md-4'>
        <StyledBackgroundImage />
      </StyledImageSection>
      <StyledContentSection className='ds-grid__col-sm-12 ds-grid__col-md-8'>
        <div className='header-section'>
          <h4 className='ds-typography__h4'>Your tip of the day</h4>
          <Tooltip content='See a new inspiring tip every day!'>
            <div>
              <InfoIcon />
            </div>
          </Tooltip>
        </div>
        <TextBox>
          <div
            dangerouslySetInnerHTML={{ __html: message }}
            ref={messageContainer}
          />
        </TextBox>
        <StyledFooter>
          <div className='navigation-section'>
            {renderArrowIcon(
              previousRecord,
              1,
              true,
              'fa-angle-double-left fa-lg'
            )}
            {renderArrowIcon(previousRecord, 1, false, 'fa-angle-left fa-lg')}

            <p className='ds-typography__body'>
              <span> {pageIndex} </span> of <span> {totalCount} </span>
            </p>
            {renderArrowIcon(nextRecord, -1, false, 'fa-angle-right fa-lg')}
            {renderArrowIcon(
              nextRecord,
              -1,
              true,
              'fa-angle-double-right fa-lg'
            )}
          </div>
          <HelpfulSection
            onClick={handleRatingClick}
            userHasLiked={userHasLiked}
            ratingLoading={ratingLoading}
            ratingTransition={ratingTransition}
          >
            {userHasLiked && <HelpfulCheckedIcon />}
            {!userHasLiked && (
              <>
                <HelpfulIcon />
                <HelpfulInTransitionIcon />
                <HelpfulInTransition2Icon />
              </>
            )}
            <p className='ds-typography__body'>Helpful</p>
          </HelpfulSection>
        </StyledFooter>
      </StyledContentSection>
    </MotivationalMessageCard>
  );
};

MotivationalMessage.propTypes = {
  handleRating: PropTypes.func.isRequired,
  indexControls: PropTypes.object.isRequired,
  motivationalMessage: PropTypes.object.isRequired,
  pageIndex: PropTypes.number.isRequired,
  toggleMessage: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
};

export default MotivationalMessage;
