import styled from 'styled-components';

const StyledModalWrapper = styled.div`
  .TheModal {
    width: 763px;
    padding: 0;
    border-radius: 4px;
    min-height: 621px;
  }

  ${({ isPasswordChange }) =>
    isPasswordChange &&
    `.TheModal {
      width: 619px;
      min-height: 424px;
      padding: 48px 72px 32px 48px;
    }
    .the-modal-bg {
      background-color: rgb(244, 245, 246);
    }

    .ds-icon__close-thin {
      display: none;
    }
    `
}
`;

StyledModalWrapper.displayName = 'StyledModalWrapper';

export default StyledModalWrapper;
