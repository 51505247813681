import { constants } from './constants';
import { isEmpty } from 'lodash';

export const initialState = {
  ecModalType: '',
  formData: {
    attachments: [],
  },
  industries: [],
  initialJobProfile: {},
  jobApplicationAction: null,
  jobApplications: [],
  jobSeekingProfile: {},
  loading: true,
  modalAction: 'form',
  modalIsOpen: false,
  modalType: null,
  optinModalStep: 'welcome',
  reload: true,
  retrievingConsent: false,
  shareJobApplication: false,
  submitting: false,
  totalPages: null,
  user: null,
  userIndustry: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
  case constants.HANDLE_NEW_JOB_APPLICATION:
    return {
      ...state,
      formAction: 'Create',
      jobApplicationAction: null,
      modalAction: 'form',
      modalIsOpen: true,
      modalType: 'jobApplication',
    };
  case constants.HANDLE_CANCEL_MODAL: {
    if (state.modalAction === 'cancel' && state.modalType === 'jobApplication') {
      return {
        ...state,
        modalAction: 'form',
      };
    } else if (!isEmpty(action.data) && !['delete', 'view'].includes(state.modalAction) && state.modalType === 'jobApplication') {
      return {
        ...state,
        formData: action.data,
        modalAction: 'cancel',
      };
    } else {
      return {
        ...state,
        formData: {
          attachments: [],
        },
        jobSeekingProfile: state.initialJobProfile,
        modalIsOpen: false,
        modalType: null,
      };
    }
  }
  case constants.HANDLE_CLOSE_MODAL: {
    return {
      ...state,
      formData: {
        attachments: [],
      },
      modalAction: 'form',
      modalIsOpen: false,
      modalType: null,
      optinModalStep: 'welcome',
    };
  }

  case constants.HANDLE_UPDATE_JOB_APPLICATION: {
    return {
      jobApplicationAction: null,
      ...state,
      formAction: 'Update',
      formData: state.jobApplications.find(x => x.id === action.id),
      modalAction: 'form',
      modalIsOpen: true,
      modalType: 'jobApplication',
    };
  }

  case constants.HANDLE_DELETE_JOB_APPLICATION: {
    return {
      ...state,
      formData: state.jobApplications.find(x => x.id === action.id),
      jobApplicationAction: null,
      modalAction: 'delete',
      modalIsOpen: true,
      modalType: 'jobApplication',
    };
  }

  case constants.HANDLE_ATTACHMENT_UPDATE: {
    const updatedJobApplications = state.jobApplications.map(obj => obj.id === action.data.id ? action.data : obj);

    return {
      ...state,
      formData: action.data,
      jobApplications: updatedJobApplications,
    };
  }

  case constants.HANDLE_VIEW_JOB_APPLICATION: {
    return {
      ...state,
      formData: state.jobApplications.find(x => x.id === action.id),
      modalAction: 'view',
      modalIsOpen: true,
      modalType: 'jobApplication',
    };
  }

  case constants.UPDATE_JOB_APPLICATION_SUCCESS: {
    return {
      ...state,
      formData: {
        attachments: [],
      },
      jobApplicationAction: 'updated',
      loading: false,
      modalIsOpen: false,
      modalType: null,
      reload: true,
      submitting: false,
    };
  }
  case constants.CREATE_JOB_APPLICATION_SUCCESS: {
    return {
      ...state,
      formData: {
        attachments: [],
      },
      jobApplicationAction: 'created',
      loading: false,
      modalIsOpen: false,
      modalType: null,
      reload: true,
      submitting: false,
    };
  }

  case constants.DELETE_JOB_APPLICATION_SUCCESS: {
    return {
      ...state,
      jobApplicationAction: 'deleted',
      loading: false,
      modalIsOpen: false,
      modalType: null,
      reload: true,
    };
  }

  case constants.OPEN_INTERVIEW_GUIDELINES: {
    return {
      ...state,
      modalAction: 'interviewGuidelines',
      modalIsOpen: true,
    };
  }

  case constants.GET_JOB_APPLICATIONS_SUCCESS:
    return {
      ...state,
      jobApplications: action.data.data,
      loading: false,
      reload: false,
      totalPages: action.data.totalPages,
    };
  case constants.GET_JOB_SEEKING_PROFILE_SUCCESS:
  case constants.UPDATE_JOB_SEEKING_PROFILE_SUCCESS:
    return {
      ...state,
      initialJobProfile: action.data,
      jobSeekingProfile: action.data,
    };
  case constants.SHOW_INTERACTIVE_MESSAGES: {
    const { interactiveMessage } = action;
    return {
      ...state,
      interactiveMessage,
      loading: false,
      modalAction: 'interactiveMessage',
      modalIsOpen: true,
      reload: false,
    };
  }

  case constants.CREATE_JOB_APPLICATION_REQUEST:
  case constants.UPDATE_JOB_APPLICATION_REQUEST: {
    return {
      ...state,
      submitting: true,
    };
  }
  case constants.CREATE_JOB_APPLICATION_FAILURE:
  case constants.UPDATE_JOB_APPLICATION_FAILURE: {
    return {
      ...state,
      submitting: false,
    };
  }
  case constants.CLOSE_INTERACTIVE_MESSAGES: {
    return {
      ...state,
      interactiveMessage: null,
      modalAction: 'form',
      modalIsOpen: false,
      modalType: null,
    };
  }
  case constants.GET_CURRENT_USER_SUCCESS: {
    const { data } = action;
    return {
      ...state,
      user: data,
    };
  }

  case constants.SET_USER_INDUSTRY: {
    const { data } = action;
    return {
      ...state,
      userIndustry: data,
    };
  }

  case constants.SET_ALL_INDUSTRIES: {
    const { data } = action;
    return {
      ...state,
      industries: data,
    };
  }

  case constants.SHOW_APPLICATION_CONSENT: {
    return {
      ...state,
      modalAction: 'shareJobApplication',
      modalIsOpen: true,
    };
  }

  case constants.UPDATE_APPLICATION_CONSENT_FAILURE: {
    return {
      ...state,
      modalAction: 'form',
      modalIsOpen: true,
      retrievingConsent: false,
      shareJobApplication: false,
    };
  }

  case constants.UPDATE_LOADING_CONSENT: {
    const { data } = action;
    return {
      ...state,
      retrievingConsent: data,
    };
  }

  case constants.UPDATE_SHARE_JOB_APPLICATION: {
    const { data } = action;
    return {
      ...state,
      shareJobApplication: data,
    };
  }

  case constants.SHOW_EMPLOYER_CONNECTOR_MODAL: {
    const { status } = action;
    return {
      ...state,
      ecModalType: status,
      modalAction: 'employerConnectorModal',
      modalIsOpen: true,
    };
  }

  case constants.CLOSE_EMPLOYER_CONNECTOR_MODAL: {
    return {
      ...state,
      modalAction: 'closeEmployerConnectorModal',
      modalIsOpen: true,
    };
  }

  case constants.UPDATE_EC_PROFILE: {
    const { data } = action;
    return {
      ...state,
      jobSeekingProfile: {
        ...state.jobSeekingProfile,
        [data.field]: data.value,
      },
    };
  }

  case constants.SET_OPTIN_MODAL_STEP: {
    const { step } = action;
    return {
      ...state,
      optinModalStep: step,
    };
  }

  case constants.SET_FORM_DATA: {
    return {
      ...state,
      formData: action.data,
    };
  }

  case constants.REVERSE_PROFILE_UPDATE: {
    return {
      ...state,
      jobSeekingProfile: state.initialJobProfile,
    };
  }

  default:
    return state;
  };
};
