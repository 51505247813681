import styled from 'styled-components';

const MobileWrapper = styled.div`
  h4 {
    font-weight: 500 !important;
    font-size: 24px;
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    ul {
      margin-bottom: 32px;
      padding: 0 0 0 16px;

      li {
        font-size: 16px !important;
      }
    }
  }
`;

MobileWrapper.displayName = 'MobileWrapper';
export default MobileWrapper;
