import EmptyTabStyles from './styles/EmptyTab';
import PropTypes from 'prop-types';
import React from 'react';

const EmptyTab = ({ type }) => (
  <EmptyTabStyles className='ds-grid__col-xs-12'>
    <p className='ds-typography__body--bold'>{`This user doesn't have any ${type} yet.`}</p>
  </EmptyTabStyles>
);

EmptyTab.propTypes = {
  type: PropTypes.string.isRequired,
};

export default EmptyTab;
