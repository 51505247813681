import IndustryDropdown from '../../containers/IndustryDropdown';
import PropTypes from 'prop-types';
import React from 'react';
import StyledBackButton from './styles/StyledBackButton';
import StyledDashboardHeader from './styles/StyledDashboardHeader';
import StyledDashboardWrapper from './styles/StyledDashboardWrapper';
import StyledGrid from './styles/StyledGrid';
import StyledHeaderWrapper from './styles/StyledHeaderWrapper';

const DashboardWrapper = ({
  showButton = true,
  children,
  header,
  showHeader = true,
  isInterviewMode,
  isReview,
}) => (
  <StyledDashboardWrapper>
    <StyledGrid
      className="ds-grid__container"
      isInterviewMode={isInterviewMode}
      isReview={isReview}
    >
      {showButton && (
        <StyledBackButton
          to="/en/career-support-center"
          icon="arrow-left"
          type="secondary"
          text="Back to career support center"
        />
      )}
      {showHeader && (
        <StyledHeaderWrapper>
          <StyledDashboardHeader className="ds-typography__h2">
            {header}
          </StyledDashboardHeader>
          <IndustryDropdown />
          <hr />
        </StyledHeaderWrapper>
      )}
      {children}
    </StyledGrid>
  </StyledDashboardWrapper>
);

DashboardWrapper.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
  isInterviewMode: PropTypes.bool,
  isReview: PropTypes.bool,
  showButton: PropTypes.bool,
  showHeader: PropTypes.bool,
};

export default DashboardWrapper;
