import styled from 'styled-components';

const StyledFlexWrapper = styled.div`
  display: flex;
  justify-content: space-around-center;
  .large-tile {
    width: 65%;
  }

  .small-tile {
    width: 32%;
  }

  @media (max-width: 1170px) {
    flex-direction: column;

    .large-tile,
    .small-tile {
      width: 100%;
    }

    .large-tile {
      margin-bottom: 11px;
    }
  }
`;

StyledFlexWrapper.displayName = 'StyledFlexWrapper';

export default StyledFlexWrapper;
