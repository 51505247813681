import CurriculumPage from '../CurriculumPage';
import PersonalizedSupportPage from '../PersonalizedSupportPage';
import ProfilePage from '../ProfilePage';
import PropTypes from 'prop-types';
import React from 'react';
import TeamPage from '../TeamPage';
import WelcomePage from '../WelcomePage';
import { connect } from 'react-redux';
import pages from '../../pages.json';

const PageComponents = {
  '#curriculum-structure': CurriculumPage,
  '#personalized-support': PersonalizedSupportPage,
  '#profile': ProfilePage,
  '#team': TeamPage,
  '#welcome': WelcomePage,
};

const Page = ({ currentPage, navigateTo, ...props }) => {
  const PageComponent = currentPage && PageComponents[currentPage];
  return (
    PageComponent && (
      <PageComponent
        data={pages[currentPage]}
        {...props}
        navigateTo={(event, data) =>
          navigateTo(event, data, currentPage.split('#')[1])
        }
      />
    )
  );
};

export const mapStateToProps = (state) => {
  return {
    currentPage: state.onBoarding.currentPage,
  };
};

Page.propTypes = {
  currentPage: PropTypes.string,
  navigateTo: PropTypes.func,
};

export default connect(mapStateToProps)(Page);
