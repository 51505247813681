import PropTypes from 'prop-types';
import React from 'react';
import { Wrapper } from './styles';

const CustomChannelPreview = ({
  result,
  uuid,
  selectResult,
  onOpenChannel,
}) => {
  let secondUserId = null;

  if (result.data.member_count === 2) {
    secondUserId = Object.keys(result.state.members).find(
      (memberId) => memberId !== uuid
    );
  }

  const title =
    result.data.member_count === 2
      ? secondUserId && result.state.members[secondUserId].user.name
      : result.name;
  const lastMessage =
    result.state.messages &&
    result.state.messages[result.state.messages.length - 1] &&
    result.state.messages[result.state.messages.length - 1].text;

  return (
    <Wrapper
      className='str-chat__channel-preview-messenger'
      onClick={() => {
        selectResult(result);
        onOpenChannel && onOpenChannel(result);
      }}
      data-testid='channel-preview-button'
    >
      <div className='str-chat__channel-preview-messenger--left'>
        <div
          className='str-chat__avatar str-chat__avatar--circle'
          data-testid='avatar'
          title={title}
        >
          {secondUserId ? (
            <img
              alt={title && title[0]}
              className='str-chat__avatar-image str-chat__avatar-image--loaded'
              data-testid='avatar-img'
              src={result.state.members[secondUserId].user.image}
            />
          ) : (
            <div
              className='str-chat__avatar-fallback'
              data-testid='avatar-fallback'
            >
              {title && title[0]}
            </div>
          )}
        </div>
      </div>
      <div className='str-chat__channel-preview-messenger--right'>
        <div className='str-chat__channel-preview-messenger--name'>
          <span>{title}</span>
        </div>
        <div className='str-chat__channel-preview-messenger--last-message'>
          <p>{lastMessage}</p>
        </div>
      </div>
    </Wrapper>
  );
};

CustomChannelPreview.propTypes = {
  onOpenChannel: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  result: PropTypes.object,
  selectResult: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  uuid: PropTypes.string,
};

export default CustomChannelPreview;
