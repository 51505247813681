import styled from 'styled-components';

const StyledModalButton = styled.div`
  position: absolute;
  bottom: 30px;

  @media (max-width: 900px) {
    bottom: 18px;
  }
`;

StyledModalButton.displayName = 'StyledModalButton';

export default StyledModalButton;
