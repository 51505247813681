import styled from 'styled-components';

const Section = styled.div`
  margin: 0 auto;
  position: relative;

  button {
    margin: 0 2px;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;

      @media (max-width: 700px) {
        margin-top: 8px;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 40px;
`;

const Valid = styled.span`
  position: absolute;
  right: 0;
  top: 50px;
`;

Section.displayName = 'Section';
Valid.displayName = 'Valid';

export { Section, Valid, ButtonWrapper };
