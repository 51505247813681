import styled from 'styled-components';

const ClearFilterButton = styled.div`
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
  cursor: pointer;
  margin-bottom: 6px;

  &:hover {
    i, p {
      color: rgba(34, 60, 80, 0.75);
    }
  }

  i {
    line-height: 1;
    margin-right: 4px;
  }

  p {
    display: inline-block;
    margin-bottom: 0;
  }
`;

const Label = styled.label``;

const SelectWrapper = styled.div`
  display: inline-block;
  width: 350px;
  margin-right: 24px;

  select {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  margin: 0 0 32px;
  display: flex;
  justify-content: space-between;

  button {
    padding: 8px 16px 9px;

    i {
      font-size: 14px;
      margin-left: 6px;
    }
  }
`;

ClearFilterButton.displayName = 'ClearFilterButton';
Label.displayName = 'Label';
SelectWrapper.displayName = 'SelectWrapper';
Wrapper.displayName = 'Wrapper';

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    '&:hover': {
      border: '1px solid #aaa',
    },
    border: '1px solid #aaa',
    borderColor: state.isFocused ? '#aaa' : '#aaa',
    boxShadow: 'none',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '12px',
    lineHeight: '1.25',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--ds-color__indigo)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
};

export {
  ClearFilterButton,
  customSelectStyles,
  Label,
  SelectWrapper,
  Wrapper,
};
