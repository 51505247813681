const paypalClientIdMetaElem = document.querySelector('meta[name="paypal-client-id"]');
const PAYPAL_CLIENT_ID = (paypalClientIdMetaElem ? paypalClientIdMetaElem.getAttribute('content') : null);
const disabledPaymentOptions = [
  'bancontact',
  'blik',
  'eps',
  'giropay',
  'ideal',
  'mercadopago',
  'mybank',
  'p24',
  'sepa',
  'sofort',
  'venmo',
  'card',
];

export const getPaypalScriptUrl = (paymentOption, currencyCode = 'USD') => {
  let paypalIntent = '';
  let vault = '';

  switch (paymentOption) {
  case 'upfront':
    paypalIntent = 'capture';
    vault = 'false';
    break;
  case 'upfront_with_installments':
    paypalIntent = 'subscription';
    vault = 'true';
    break;
  }
  return `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&vault=${vault}&intent=${paypalIntent}&currency=${currencyCode}&disable-funding=${disabledPaymentOptions.join(',')}`;
};
