import React from 'react';
import StyledVideoContainer from '../styles/StyledVideoContainer';

const WhatIsRemoteWork = () => (
  <>
    <p className='ds-typography__body break'>
      Just like a design system or data processes can speed up your work by
      making you more efficient, we recommend you start by building out your
      system for getting things done because it will help you have a structure
      and a focus once you get your first client. Also, please note that you can
      build your business network, support, and ask questions and share
      resources in their{' '}
      <a
        onClick={() =>
          window.open('https://careerfoundry.slack.com/archives/C03M1ADJX')
        }
      >
        Freelancing Slack Channel
      </a>
    </p>
    <h5 className='ds-typography__h5'>Setting your expectations</h5>
    <p className='ds-typography__body break'>
      So much has and can be said about freelancing, so it is important to come
      up with your own reasons for starting this business. This can be a lot of
      work because:
    </p>
    <ul>
      <li>
        <strong>There is no one way to do anything</strong> so this will be an
        iterative process of testing and learning as you go. This is also why
        some entrepreneurs refer to this as building a plane while you are
        flying it. There can be excitement in it and frustration so self-care is
        key.
      </li>
      <li>
        <strong>There is a lot of admin work. </strong> You are the front
        office, back office, tech support, investor, marketing, data, design,
        and product guru de jour. You can ask for help but this entire operation
        begins and ends with you.
      </li>
      <li>
        <strong>This is a full-time job. </strong> On top of what is already
        listed, freelancing can also feel like a never-ending job search which
        is why some may go into contract work
      </li>
      <li>
        <strong>This is a part-time job. </strong> Once you are set up (as a
        hobby or as a 'side hustle'), you can supplement your full-time work
        with freelance work on the side. This can help you build experience,
        learn new skills, and increase your income.
      </li>
    </ul>
    <p className='ds-typography__body break'>
      There are a million reasons why not to do this but there can also be a
      million reasons why you can. The bottom line is that you get to decide
      what is right for you. Once you make your decision, don't forget to write
      it down so that your Future Self can easily remember your motivation as
      things may not always go according to plan.
    </p>
    <ul>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/career-change/myths-freelance-lifestyle/'
            )
          }
        >
          10 Myths of Freelance Lifestyle (CareerFoundry)
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/career-change/freelancing-disadvantages/'
            )
          }
        >
          5 Disadvantages of Going Freelance & How to Offset Them
          (CareerFoundry)
        </a>
      </li>
    </ul>
    <h5 className='ds-typography__h5'>Defining your niche</h5>
    <p className='ds-typography__body break'>
      Now that you have decided you are going to give freelancing a try, it is
      important to define your niche. Why specialize when you are looking to
      generate as much income you can as fast as possible? Chances are you have
      not come all this way to be mediocre, so by establishing your niche, you
      have a filter for deciding what is important so you can be efficient with
      your time and resources. SPOILER ALERT: Your niche will give you focus
      more than it will limit you because, in the end, people will come to you
      when they realize you can deliver what they need.
    </p>
    <ul>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://bettermarketing.pub/finding-your-niche-questions-to-consider-d513c54a437e'
            )
          }
        >
          Finding Your Niche
        </a>
      </li>
    </ul>
    <h5 className='ds-typography__h5'>Branding yourself as a freelancer</h5>
    <p className='ds-typography__body break'>
      In addition to picking your business specialization, you can identify what
      you do and what sets you apart. Here are a list of things to do to get
      clear about what you offer before you start talking to potential
      customers:
    </p>
    <ul>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://medium.com/the-mission/the-key-ingredients-to-complete-your-personal-mission-statement-d5000d899e23'
            )
          }
        >
          Freelance Mission Statement
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/career-change/personal-branding/'
            )
          }
        >
          Business Brand
        </a>
      </li>
      <li>
        <a onClick={() => window.open('https://youtu.be/bj8pEnMveYE')}>
          Competitor Analysis
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://businessnamegenerator.com/freelance-business-name-generator-guide-ideas/'
            )
          }
        >
          Freelance Business Name Generator
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/course/job-preparation/exercise/defining-elevator-pitch'
            )
          }
        >
          Freelance Elevator Pitch (CF Job Prep Task)
        </a>
      </li>
    </ul>
    <p className='ds-typography__body break'>
      Now, as you might expect, there are some deliverables you will need in
      order to help prospective customers understand the value you offer. This
      includes a{' '}
      <a
        onClick={() =>
          window.open('https://careerfoundry.slack.com/archives/C03M1ADJX')
        }
      >
        Client Case Study
      </a>{' '}
      where you can spell out how you have already helped others. This can also
      outline your process for gathering project requirements, aligning with
      customer specifications, and the difference this will make.
    </p>
    <p className='ds-typography__body break'>
      For a step-by-step guide to writing a case study, review{' '}
      <a
        onClick={() =>
          window.open(
            'https://careerfoundry.com/en/course/job-preparation/exercise/creating-a-case-study'
          )
        }
      >
        Exercise 1.4 Creating a Case Study
      </a>{' '}
      from the Job Preparation course. This exercise will also help you
      acknowledge how you already have experience delivering business value
      (take that impostor syndrome!). Because nothing you do is wasted, your
      case study can also be used in your client proposals and pitches.
    </p>
    <p className='ds-typography__body break'>
      In addition to the case study you can take a more targeted approach to
      your{' '}
      <a
        onClick={() =>
          window.open(
            'https://www.indeed.com/career-advice/resumes-cover-letters/freelancing-resume'
          )
        }
      >
        Freelance-focused Resume
      </a>{' '}
      by including transferable skills like time management, project details,
      and a link to your portfolio. Speaking of your Freelancer Portfolio, be
      sure to create a case study for your client projects and update the skills
      you showcase. For a Freelance-focused LinkedIn Profile, you want to
      include rich media that promotes your work and create a company page that
      you can connect to. Additionally, consider how you plan to include your
      client projects. If you list every project individually, over time, it
      could look like you are a 'job hopper' or feel unwieldy. Here is a video
      walkthrough to give you options on how to feature your new work experience
      and a document with{' '}
      <a
        onClick={() =>
          window.open(
            'https://docs.google.com/document/d/1Wl4EY5EI8XRTwu1YAESPlP-1R7YH-GzKDpje89qhzT0/edit?usp=sharing'
          )
        }
      >
        two layout examples
      </a>{' '}
      that you can use for inspiration.
    </p>
    <StyledVideoContainer>
      <iframe
        src='https://www.loom.com/embed/2118d1e493124ceead2f3bede0cad0db'
        frameBorder='0'
        webkitAllowFullScreen
        mozAllowFullScreen
        allowFullScreen
      ></iframe>
    </StyledVideoContainer>
    <h5 className='ds-typography__h5'>Business operations</h5>
    <p className='ds-typography__body break'>
      Freelancing is a job search with less structure and direction. You decide
      where to find clients and what process you get to build in order to
      consider and deliver on the promises you make. Skills used include:
      networking, business development, branding, project management, and
      leadership. HINT: These can go on your Freelance Resume.
    </p>
    <p className='ds-typography__body break'>
      To help guide you as you build your operation, here are some key
      resources:
    </p>
    <div className='ds-typography__lead'>
      <strong>Business structure</strong>
    </div>
    <p className='ds-typography__body break'>
      Part of defining what your business will be includes implementing ways to
      protect it. While it is understandable to consider stepping out to try a
      bit of freelancing and then deciding at a later date whether you will
      formalize it, we thought it important to share some resources on what the
      business types are so you can make a more informed decision about your
      next steps:
    </p>
    <ul>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://en.wikipedia.org/wiki/List_of_legal_entity_types_by_country'
            )
          }
        >
          Legal Business Structures by Country
        </a>
      </li>
    </ul>
    <div className='ds-typography__lead'>
      <strong>Contracts & service agreements</strong>
    </div>
    <p className='ds-typography__body break'>
      This seems like a good time to mention that it is important to write down
      what you plan on delivering to a client and when, so that they know what
      to expect (project scope). By definition a contract may be a more formal
      outline with a lot of legal terms while a service agreement can be a list
      of commitments made between two parties.
    </p>
    <p className='ds-typography__body break'>
      Having the client sign this agreement will help you set boundaries. If the
      client then wants to change anything about the project and it is not
      covered in your original contract then you can work with them to decide if
      it is important enough to change the arrangement (amendment) or add to the
      original project scope and fee (addendum).
    </p>
    <p className='ds-typography__body break'>
      Examples of things that you can add to your agreements include but are not
      limited to:
    </p>
    <ul>
      <li>
        <strong>What you will not be responsible for</strong> like copyediting,
        stock photos, securing the rights to assets they provide, or the results
        generated by the final product especially if they alter anything.
      </li>
      <li>
        <strong>Ability to own/share your work</strong> on your portfolio. This
        may need to be negotiated, but it is important to realize that just
        because you deliver the goods, doesn’t mean you have the right to share
        it. Rather than find that out later, it is best to be clear about it up
        front.
      </li>
    </ul>
    <p className='ds-typography__body break'>
      Here are some sample contracts and proposals:
    </p>
    <ul>
      <li>
        <a
          onClick={() =>
            window.open('https://www.hellobonsai.com/best-freelance-tools')
          }
        >
          Best Freelance Tools
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open('https://www.freelancersunion.org/resources/')
          }
        >
          Freelance Resources
        </a>
      </li>
    </ul>
    <div className='ds-typography__lead'>Finances</div>
    <p className='ds-typography__body break'>
      Especially if you are working directly with clients (which can include
      billing and invoicing them) or you are on a contract where you are
      expected to pay your own taxes, it is crucial that you keep that money
      separate from everything else you take in so that you can more easily
      track it and report it. Ideally every bit of revenue and each expense
      would be moving through this one account that is created specifically for
      your business.
    </p>
    <p className='ds-typography__body break'>
      There are applications that specialize in supporting freelancers and may
      even say they pay your taxes for you. It is important that you read the
      reviews carefully. Key things to think about when you set up your banking
      system:
    </p>
    <ul>
      <li>How easy is it to access your money?</li>
      <li>Is there a bank transfer or debit card option?</li>
      <li>How will you accept credit card payments from customers?</li>
      <li>What are the fees involved?</li>
    </ul>
    <p className='ds-typography__body break'>
      If you are able to have multiple accounts at the same bank you can use
      this as a way to keep yourself organized. This could mean having accounts
      like the following:
    </p>
    <ul>
      <li>
        Checking Account to receive deposits from your payment systems and
        client transfers
      </li>
      <li>Checking Account to pay your expenses</li>
      <li>Savings Account to pay taxes so you can keep them separate</li>
      <li>Savings Account for emergencies like seasons of low or no work</li>
      <li>
        Savings Account for software/equipment or anything else you need to
        finance
      </li>
    </ul>
    <p className='ds-typography__body break'>
      Some banks also have special business accounts to streamline these
      processes, so it is a good idea to check and see what offers are available
      locally.
    </p>
    <p className='ds-typography__body break'>
      PRO TIP: Build 3 months of emergency savings, then 6, then 12. If you do
      have to dip into it for…well…an emergency, then create a plan to also put
      that money back so you will have it next time you need it.
    </p>
    <h5 className='ds-typography__h5'>Business systems</h5>
    <p className='ds-typography__body break'>
      Beyond protecting your investment and how you plan to structure your
      business, is how you plan on getting your work done. These are the tools
      and processes that will help you to both consistently do what you intend
      but also scale your work so that you can do more projects at once to a
      consistent level of quality. These include:
    </p>
    <ul>
      <li>
        <strong>Automation Tools</strong> that help with tasks like emails,
        scheduling, marketing like{' '}
        <a onClick={() => window.open('https://ifttt.com/')}>IFTTT</a> and{' '}
        <a onClick={() => window.open('https://www.followupthen.com/')}>
          Follow Up Then
        </a>
      </li>
      <li>
        <strong>Emails</strong> that represent your brand or at least give a
        professional appearance. Here are{' '}
        <a
          onClick={() =>
            window.open(
              'https://www.awai.com/2019/02/choosing-a-professional-email-address'
            )
          }
        >
          some tips on what to consider
        </a>
      </li>
      <li>
        <strong>Calendaring System</strong>, tied to your freelance email, is a
        great way to get organized, keep track of meetings, and also schedule
        breaks
      </li>
      <li>
        <strong>Scheduling Tools</strong> to streamline the process of booking
        meetings use software like{' '}
        <a onClick={() => window.open('https://calendly.com/')}>Calendly</a>
      </li>
      <li>
        <strong>Cloud Storage / Drives</strong>for both collaboration,
        transferring large files, and backup your data like{' '}
        <a onClick={() => window.open('https://dropbox.com/')}>DropBox</a>
      </li>
      <li>
        <strong>Accounting / Bookkeeping</strong> services or software to track
        spending, tax payments, income, and invoicing. Check reviews for{' '}
        <a onClick={() => window.open('https://www.zoho.com/books')}>
          Zoho Books
        </a>{' '}
        and{' '}
        <a
          onClick={() =>
            window.open('https://quickbooks.intuit.com/self-employed/')
          }
        >
          QuickBooks Self Employed
        </a>
      </li>
      <li>
        <strong>Meeting Tools</strong> to aid in remote and onsite communication
        including{' '}
        <a onClick={() => window.open('https://meet.google.com/')}>
          Google Meet
        </a>{' '}
        and <a onClick={() => window.open('https://zoom.us/')}>Zoom</a>
      </li>
      <li>
        <strong>Project & Customer Tracking</strong>tools that help you to know
        what to do, when to do it, and who to deliver it to. This could be a
        spreadsheet like{' '}
        <a onClick={() => window.open('https://www.airtable.com/templates')}>
          Airtable
        </a>{' '}
        or a platform like{' '}
        <a onClick={() => window.open('https://trello.com')}>Trello</a> to
        start. As a business, collecting and protecting client information is
        key.
      </li>
      <li>
        <strong>Security Tools</strong> that safeguard your passwords and
        customer information like{' '}
        <a onClick={() => window.open('https://bitwarden.com/')}>Bitwarden</a>{' '}
        and{' '}
        <a onClick={() => window.open('https://www.lastpass.com/')}>LastPass</a>
      </li>
      <li>
        <strong>Collaboration Tools</strong>to connect you with other
        freelancers, clients, stakeholders, including Slack.
      </li>
    </ul>
    <p className='ds-typography__body break'>
      PRO TIP:{' '}
      <a onClick={() => window.open('https://appsumo.com/')}>AppSumo</a> can
      offer a variety of software for a one-time fee.
    </p>
    <p className='ds-typography__body break'>
      While some of this feels like something you can decide as you go, imagine
      how you have gotten your work done while at CareerFoundry.
    </p>
    <ul>
      <li>What are the tools and services that you were thankful to have?</li>
      <li>What do you wish was in place?</li>
    </ul>
    <p className='ds-typography__body break'>
      Create a list of what tools and services you want to install that will
      help you get your client work done right now and what you would like to
      have once you grow to a portfolio of 10+ clients. This could inform how
      you approach your tracking process or how you adjust it.
    </p>
    <p className='ds-typography__body break'>
      PRO TIP: The first time you do anything, it may take a long time. Pad your
      proposed project schedule to accommodate this. Also, keep track of your
      workflows and communications in case you can convert them into templates
      for faster response times in the future.
    </p>
    <h5 className='ds-typography__h5'>Business setup</h5>
    <p className='ds-typography__body break'>
      While CareerFoundry is nurturing a community of budding and established
      entrepreneurs, there are also local government agencies or corporate
      guides that offer checklists and resources you can use to ask questions or
      to lean on for accountability.
    </p>
    <h5 className='ds-typography__h5'>Set your rate</h5>
    <p className='ds-typography__body break'>
      Before getting your first client it can be helpful to set your rate. This
      way you can be more thoughtful and deliberate about it rather than
      impulsive. This is important because a freelance rate is different than a
      salary or hourly rate due to three important factors:
    </p>
    <ol>
      <li>
        <strong>Taxes</strong>: Depending on where you live a % of the revenue
        you generate is taxable income, and it is up to you to both track that
        and pay that on-time.
      </li>
      <li>
        <strong>Overhead</strong>: You are responsible for the software, tools,
        equipment, services, and fees associated with running your business.
      </li>
      <li>
        <strong>Administration</strong>: Consider including time for non-project
        work including marketing, vacation, sick leave. See also 'non-billable
        hours' in the Track Your Time section.
      </li>
    </ol>
    <p className='ds-typography__body break'>
      Also check out{' '}
      <a
        onClick={() =>
          window.open(
            'https://careerfoundry.com/en/blog/career-change/pricing-freelancer/'
          )
        }
      >
        how to price yourself as a freelancer.
      </a>
    </p>
    <p className='ds-typography__body break'>
      Save time by not calculating your price every time you talk to a client.
      Instead, determine your hourly rate as a freelancer and create a bundle or
      package of services where the deliverables (including number of revisions)
      are spelled out and the pricing is set. This makes it easier for the
      client, and helps you make the sale. Find out more at{' '}
      <a
        onClick={() =>
          window.open(
            'https://careerfoundry.com/en/blog/career-change/get-paid-freelancer/'
          )
        }
      >
        How to Get Paid What You're Worth (CareerFoundry).
      </a>
    </p>
    <div className='ds-typography__lead'>Estimating project timeline</div>
    <p className='ds-typography__body break'>
      One key question that has come up is how to estimate your time for a
      project proposal. One way to do this is to track your time as you move
      through each aspect of a project. You can use tools like Clockify.me or
      Toggl Track. See also Most Freelancers Start Off With An Hourly Rate
      section of{' '}
      <a
        onClick={() =>
          window.open(
            'https://careerfoundry.com/en/blog/career-change/pricing-freelancer/'
          )
        }
      >
        Pricing 101: How To Price Yourself As A Freelancer.
      </a>
    </p>
    <div className='ds-typography__lead'>Tracking your time</div>
    <p className='ds-typography__body break'>
      Tracking your time is important. You want to be able to look back and see
      how much time you spent actually doing your client's project (billable
      hours) and how much time you spent fighting with your printer
      (non-billable hours) so that you can tell what is worth your time to focus
      on and what is a distraction (or necessary stress relief). Tracking your
      time will also help you to identify when it is time to outsource
      administrative tasks to other professionals.
    </p>
    <div className='ds-typography__lead'>Payments and fees</div>
    <p className='ds-typography__body break'>
      To set yourself up for success there are a few things you can do around
      your pricing and billing:
    </p>
    <ul>
      <li>
        <strong>Require a Deposit</strong>: It can be too easy to be excited
        about a project and get started before the client is fully committed. To
        set that boundary with yourself and them, you can require the full
        payment up front or a partial payment as a deposit to get started.
      </li>
      <li>
        <strong>Milestone Payments</strong>: The client might be concerned about
        getting their money's worth and you might be worried about staying
        motivated so you can create milestones in your project that correspond
        with payments.
      </li>
      <li>
        <strong>Set Late Fees</strong>: You can create incentive for your
        clients to pay on time by having a late fee (and due date) on your
        invoices and in your contracts.
      </li>
      <li>
        <strong>Expedited Service Fee</strong>: If you have a client in a hurry
        that stress can often be passed on to you. You don't have to take it,
        and if you do you can be compensated for the rush.
      </li>
      <li>
        <strong>Special Pricing</strong>: There are a few scenarios where you
        may be tempted to alter your fees so we will list some of those reasons
        here so you can decide how you want to handle them:
        <ul>
          <li>
            <strong>Volunteer or Barter Services</strong> to support a cause
            near to your heart. NOTE: if you donate/volunteer your services,
            consider asking for a recommendation, referral, endorsement, or to
            use the materials in a case study for your portfolio.
          </li>
          <li>
            <strong>Professional Development / Learning Opportunity</strong> is
            when you take on a project in order to learn a new tool or practice
            a skill. Some alumni offer these services at a discounted rate or
            pad the deadline time. Whatever you choose to do, be sure to set the
            client and your expectations from the beginning.
          </li>
        </ul>
      </li>
    </ul>
    <p className='ds-typography__body break'>
      The bottom line is that in order for you to feel valued for your work you
      must get something from the arrangement and in order for a client to value
      your work they must pay. This is a win-win proposition! As a reminder, if
      you make an exception to your pricing, be sure and document your reasons
      why. Your Future Self will thank you later!
    </p>
    <div className='ds-typography__lead'>Taxes</div>
    <p className='ds-typography__body break'>
      It is important to do your homework when it comes to taxes in your area
      because this will dictate how you handle your finances, structure your
      banking, and even what you charge because you don't want to get to the end
      of the year and realize you didn't charge enough. As a starting point here
      are a few resources to reference:
    </p>
    <ul>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://www.adp.com/resources/articles-and-insights/articles/i/independent-contractor-taxes.aspx'
            )
          }
        >
          US Independent Contractor Taxes
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://www.welcome-center-germany.com/post/self-employed-tax-guide-in-germany'
            )
          }
        >
          Germany Self-Employed Tax Guide
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://www.european-microfinance.org/sites/default/files/document/file/exploring-self-employment-in-the-european-union.pdf'
            )
          }
        >
          Exploring Self-Employment in the EU
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open('https://www.gov.uk/topic/business-tax/self-employed')
          }
        >
          UK Self Employed Business Tax
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://www.hrotoday.com/market-intelligence/research/growth-freelancers-best-practices-attract/'
            )
          }
        >
          APAC Freelancer Best Practices
        </a>
      </li>
    </ul>
    <div className='ds-typography__lead'>Contract work</div>
    <p className='ds-typography__body break'>
      Contract work can be considered freelancing because some companies
      approach this like a business-to-business (B2B) engagement. This could be
      for a set term (3 months), as a probationary period (contract-to-hire) and
      for part-time and full-time opportunities. If your contract work requires
      self-employment, this can impact your tax responsibilities.
    </p>
    <p className='ds-typography__body break'>
      In the US, for example, if you work as a 1099 Independent Contractor you
      are not considered an employee and must pay all of your own taxes and
      benefits like health and life insurance. If you are enrolled in a W2
      contract, then you are considered an employee of the company you signed
      with (like a Staffing Firm or Consulting Agency) and they will then pay
      your taxes and even provide access to or the opportunity for health
      benefits. Every company is different so it is important to review all
      paperwork.
    </p>
    <p className='ds-typography__body break'>
      PRO TIP: You can have extra money taken out for taxes (withholding) to
      help cover the amount of freelancing you do.
    </p>
  </>
);

export default WhatIsRemoteWork;
