import EmployerModalContainer from '../containers/EmployerModalContainer';
import EmployerOnboardingContainer from '../containers/EmployerOnboardingContainer';
import React from 'react';

const App = () => {
  return (
    <>
      <EmployerOnboardingContainer />
      <EmployerModalContainer />
    </>
  );
};

export default App;
