import { constants } from './constants';

const initialState = {
  allNotesCount: 0,
  attendees: [],
  courseCards: {
    attendees: [],
    programAttendees: [],
  },
  isFetchingNotes: true,
  isLoading: true,
  isSelectedCourseStarted: false,
  notes: [],
  selectedCourseId: 0,
  totalNotesToLoadCount: 0,
  userId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_REQUIRED_DATA: {
      const {
        attendees,
        courseCards,
        isSelectedCourseStarted,
        selectedCourseId,
        userId,
      } = action.payload;

      return {
        ...state,
        attendees,
        courseCards,
        isLoading: false,
        isSelectedCourseStarted,
        selectedCourseId,
        userId,
      };
    }
    case constants.SET_SELECTED_COURSE_ID:
      return {
        ...state,
        selectedCourseId: action.payload.courseId,
      };
    case constants.SET_NOTES_DATA: {
      const {
        notes,
        totalNotesToLoadCount,
        isFetchingMoreNotes,
        isFetchingAllNotes,
        shouldResetAllNotesCount,
      } = action.payload;
      const newState = {
        ...state,
        isFetchingNotes: false,
        totalNotesToLoadCount,
      };

      if (isFetchingMoreNotes) {
        return {
          ...newState,
          notes: [...newState.notes, ...notes],
        };
      } else {
        return {
          ...newState,
          notes,
          ...(isFetchingAllNotes && { allNotesCount: notes.length }),
          ...(shouldResetAllNotesCount && { allNotesCount: 0 }),
        };
      }
    }
    case constants.SET_NOTES_LOADER:
      return {
        ...state,
        isFetchingNotes: action.payload.isLoading,
      };
    default:
      return state;
  }
};

export default reducer;

export { initialState };
