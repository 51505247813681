import styled from 'styled-components';

const StyledJobApplicationsTracker = styled.div`
  h4 {
    display: inline-block;
    margin-bottom: 49px;
  }

  p {
    margin-top: 16px;
  }
`;

export default StyledJobApplicationsTracker;
