import AvailableContent from '../../../components/EmployerConnectorCard/states/AvailableContent';
import { connect } from 'react-redux';
import { showECModal } from '../../../actions';

export const mapDispatchToProps = (dispatch) => ({
  showECModal: status => dispatch(showECModal(status)),
});

export const mapStateToProps = ({
  careerSupportCenter: { jobSeekingProfile },
}) => ({
  jobSeekingProfile,
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailableContent);
