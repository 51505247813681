import React, { useEffect } from 'react';
import CoursesTab from '../../components/CoursesTab';
import LoadingSpinner from '../../../shared/loadingSpinner';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCourseData } from '../../actions';

export const mapStateToProps = ({
  userProfile: {
    courses,
    coursesLoading,
    currentUserId,
    selectedCourse,
    studentIsViewing,
    userId,
  },
}) => ({
  attendees: courses?.attendees?.attendees,
  coursesLoading,
  currentUserId,
  hasAttendees: !!courses?.attendees?.attendees,
  selectedCourse,
  studentIsViewing,
  userId,
});

const CoursesTabContainer = ({
  attendees,
  getCourseData,
  coursesLoading,
  userId,
  ...rest
}) => {
  useEffect(() => {
    if (!attendees) {
      getCourseData(userId);
    }
  }, []);

  return coursesLoading ? (
    <LoadingSpinner />
  ) : (
    <CoursesTab attendees={attendees} {...rest} />
  );
};

CoursesTabContainer.propTypes = {
  attendees: PropTypes.array,
  coursesLoading: PropTypes.bool.isRequired,
  getCourseData: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  getCourseData: (userId) => dispatch(getCourseData(userId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoursesTabContainer);
