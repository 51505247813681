import HeaderStyles from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const ModalHeader = ({ date, mentor }) => (
  <HeaderStyles>
    <h3 className='ds-typography__body--bold cf-text-left'>Call Details</h3>
    <p className='ds-typography__small'>
      with your mentor{' '}
      <span className='ds-typography__small--bold'>{mentor}</span>
    </p>
    <p className='ds-typography__small--bold'>{date}</p>
  </HeaderStyles>
);

ModalHeader.propTypes = {
  date: PropTypes.string,
  mentor: PropTypes.string,
};

export default ModalHeader;
