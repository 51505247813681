import React, { useEffect, useState } from 'react';
import { getSurveryData, postData } from './actions';
import ConfidenceSurvey from '../ConfidenceSurvey';
import PropTypes from 'prop-types';
import { getSurveyData } from '../../../shared/components/ConfidenceSurveyBanner/actions';

const ConfidenceSurveyWrapper = ({ attendeeId, formId, linkText, stepId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [renderSurvey, setRenderSurvey] = useState(false);
  const [modalType, setModalType] = useState('form');

  useEffect(() => {
    getSurveryData({ attendeeId, stepId }).then((res) =>
      setRenderSurvey(res.showSurvey)
    );
  }, []);

  const handleClick = () => setModalIsOpen(true);

  const handleSubmit = (event) => {
    postData({
      attendeeId,
      formId,
      responseId: event.data.responseId,
      stepId,
    })
      .then(() => {
        getSurveyData({ formId, responseId: event.data.responseId }).then(
          (data) => {
            const lowConfidenceResponses = data[0].answers.filter(
              (answer) => answer.number <= 2
            );
            if (lowConfidenceResponses.length > 0) {
              setModalType('review');
            } else {
              setTimeout(() => {
                setRenderSurvey(false);
                handleCloseModal();
              }, 1500);
            }
          }
        );
      })
      .catch(() => {
        handleCloseModal();
      });
  };

  const handleCloseModal = () => {
    setModalType('form');
    setModalIsOpen(false);
  };

  const handleCloseReviewModal = () => {
    handleCloseModal();
    setRenderSurvey(false);
  };

  return (
    <ConfidenceSurvey
      attendeeId={attendeeId}
      formId={formId}
      handleClick={handleClick}
      handleClose={handleCloseModal}
      handleSubmit={handleSubmit}
      isModalOpen={modalIsOpen}
      linkText={linkText}
      renderSurvey={renderSurvey}
      modalType={modalType}
      handleCloseReviewModal={handleCloseReviewModal}
    />
  );
};

ConfidenceSurveyWrapper.propTypes = {
  attendeeId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  stepId: PropTypes.string.isRequired,
};

export default ConfidenceSurveyWrapper;
