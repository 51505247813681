import Completion from '../../react/shared/StripePayments/Completion';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { loadStripe } from '@stripe/stripe-js';

const enrollCompletionContainer = document.querySelector('.enroll__completion');

if (enrollCompletionContainer) {
  const stripeKey = document
    .querySelector('meta[name="stripe-key"]')
    ?.getAttribute('content');

  const stripePromise = loadStripe(stripeKey || '');

  const enrollCompletionContainerRoot = createRoot(enrollCompletionContainer);

  enrollCompletionContainerRoot.render(
    <Completion stripePromise={stripePromise} />
  );
}
