import MessagingButtonContainer from '../../containers/MessagingButtonContainer';
import PersonalData from './PersonalData';
import ProfessionalStatementContainer from '../../containers/ProfessionalStatementContainer';
import ProfileSection from '../../../EmployerConnector/modals/showModal/ProfileSection';
import ProfileSeparator from '../../../EmployerConnector/modals/showModal/styles/ProfileSeparator';
import React from 'react';
import SocialsContainer from '../../containers/SocialsContainer';
import StyledHeaderWrapper from '../../../EmployerConnector/modals/showModal/styles/StyledHeaderWrapper';
import StyledShowModal from '../../../EmployerConnector/modals/showModal/styles/StyledShowModal';
import WorkPreferenceContainer from '../../containers/WorkPreferenceContainer';

const JobSeekingProfileShow = () => {
  return (
    <StyledShowModal>
      <div className="scroll">
        <div className="ds-grid__row">
          <div className="ds-grid__col-sm-12">
            <StyledHeaderWrapper>
              <h5 className="ds-typography__h5 text-left">Student Application Package</h5>
              <MessagingButtonContainer />
            </StyledHeaderWrapper>
            <ProfileSeparator />
            <div className="ds-grid__row">
              <div className="ds-grid__col-xs-6 row-lg">
                <ProfileSection
                  header="Personal Data"
                  childComponent={<PersonalData />}
                />
                <ProfileSeparator />
                <ProfileSection
                  header='Professional Statement'
                  childComponent={<ProfessionalStatementContainer />
                  }
                />
              </div>
              <div className="ds-grid__col-xs-6 row-md">
                <hr />
                <ProfileSection
                  header="Work Preferences"
                  headerClassName="mr-33"
                  childComponent={
                    <WorkPreferenceContainer />
                  }
                />
                <ProfileSeparator />
                <ProfileSection
                  header="Application package and social media accounts"
                  headerClassName="mr-33 mb-25"
                  childComponent={
                    <SocialsContainer />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledShowModal>
  );
};

export default JobSeekingProfileShow;
