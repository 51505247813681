import styled from 'styled-components';

const JobApplicationFormStyles = styled.form`
  overflow-y: scroll;
  height: 60vh;
  padding: 20px 20px 85px;

  @media (max-width: 768px) {
    height: 100vh;
  }

  input, textarea, select {
    border: solid 2px #a7b1b9;
    border-radius: 4px;
    margin-bottom: 22px;
    font-size: 16px;
    padding: 10px;
  }

  input[type=text] {
    width: 75%;
    margin-left: 5%;
    height: fit-content;
  }

  textarea {
    width: 75%;
    margin-left: 5%;
    border: solid 2px #a7b1b9;
    border-radius: 4px;
  }

  input[type=date] {
    width: 75%;
    margin-left: 5%;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  input[type="date"]::-webkit-clear-button {
    z-index: 1;
  }

  input[type="date"] {
    position: relative;
    height: fit-content;
  }

  label {
    width: 20%
  }

  select {
    width: 75%;
    margin-left: 5%;
  }

  h3, h5 {
    padding-bottom: 20px;
  }

  button {
    margin: 20px;
  }
`;

JobApplicationFormStyles.displayName = 'JobApplicationFormStyles';

export default JobApplicationFormStyles;
