import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

const padding = css`
  padding: 10px 10px 8px;
`;

const AIResponseWrapper = styled.div`
  header {
    padding: 16px 16px 12px;
    background: linear-gradient(
      90deg,
      rgba(223, 237, 253, 1) 50%,
      rgba(239, 232, 253, 1) 100%
    );
    border-radius: 16px 16px 0px 0px;

    display: flex;
    justify-content: space-between;
    margin: -4px -8px 0;
    border-bottom: 1px solid #e5dfd8;

    div {
      margin-left: 50px;
      display: flex;
      align-items: flex-start;
    }
  }

  main {
    padding: 16px 8px 12px;
    margin-left: 0;

    p {
      margin: 0;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: var(--ds-color__int-border-icon);
  font-size: 16px;
  cursor: pointer;

  &:first-of-type {
    margin-right: 8px;
  }
`;

const Wrapper = styled.div`
  ${padding}
  p, li, strong, ul, ol {
    color: var(--ds-bg-color__int-primary);
  }

  ul,
  ol {
    padding-left: 24px;
  }
`;

export { AIResponseWrapper, Icon, Wrapper };
