import styled from 'styled-components';

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  h2 {
    clear: both;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    h2 {
      margin-bottom: 12px !important;
    }

    hr {
      display: block;
      margin: 14px 0px;
      border: solid 1px #e8ebed;
      width: 100%;
      opacity: 0.5;
    }
  }
`;

StyledHeaderWrapper.displayName = 'StyledHeaderWrapper';

export default StyledHeaderWrapper;
