import { getDiscourseCategoryUrl, trackNavigation } from '../../../helpers';
import DisabledTooltipContent from '../../DisabledTooltipContent';
import Icon from '../../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { ForumIcon as SVGIcon } from '../../SideNavList/icons';
import { noop } from 'lodash';
import { redirectTo } from '../../../../../utils/redirectTo';

const ForumIcon = ({
  activeCourseSlug = '',
  isDisabled = true,
  isVisible = false,
  isStudent,
  isAgreedToDiscoursePrivacyPolicy,
  setShouldShowDataPolicyAgreementModal = noop,
  ...rest
}) => {
  const onClickHandler = () => {
    if (!isDisabled) {
      if (isStudent) {
        trackNavigation({ itemName: 'forum' });
      }

      if (!isAgreedToDiscoursePrivacyPolicy) {
        setShouldShowDataPolicyAgreementModal(true);
      } else {
        redirectTo({
          openInNewTab: true,
          url: getDiscourseCategoryUrl(activeCourseSlug),
        });
      }
    }
  };

  return (
    <Icon
      isDisabled={isDisabled}
      isVisible={isVisible}
      onClick={onClickHandler}
      renderSVGIcon={() => <SVGIcon />}
      nameForMobileView="Forum"
      tooltipContent={
        isDisabled ? (
          <DisabledTooltipContent
            title="Forum"
            description="Unlocked after course/program start"
          />
        ) : (
          'Forum'
        )
      }
      {...rest}
    />
  );
};

ForumIcon.propTypes = {
  ...Icon.propTypes,
  activeCourseSlug: PropTypes.string,
  isAgreedToDiscoursePrivacyPolicy: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isStudent: PropTypes.bool,
  isVisible: PropTypes.bool,
  setShouldShowDataPolicyAgreementModal: PropTypes.func,
};

export default ForumIcon;
