// eslint-disable-next-line import/no-unresolved
import LoadingSpinner from '../../../../../shared/loadingSpinner';
import PaginationControls from '../../../../../shared/components/Pagination';
import PropTypes from 'prop-types';
import React from 'react';
import Table from './styles/StudentTableStyles';
import moment from 'moment';

const StudentTable = ({
  showJobSeekingProfile, jobSeekingProfiles, getJobSeekingProfiles, loading,
  activePage,
  perPage,
  totalEntries,
  profileFilterParams,
}) => {
  const columns = [
    'Student Name',
    'Email',
    'Program',
    'Specialization',
    'Progress',
    'Enrollment Date',
    'Graduation Date',
    'Status',
    'Location',
    'Last Login',
    'CV Update Date',
  ];

  const handlePageChange = (pageNumber) => getJobSeekingProfiles(pageNumber, profileFilterParams);

  return (
    <>
      {loading
        ? <LoadingSpinner />
        : <>
          <Table className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  {columns.map(column => <th key={column}>{column}</th>)}
                </tr>
              </thead>
              <tbody>
                {jobSeekingProfiles.length
                  ? jobSeekingProfiles.map((row, i) => (
                    <tr key={`row_${i}`}>
                      <td><a className='ds-typography__anchor' onClick={() => showJobSeekingProfile(row.id)}>{row.studentName}</a></td>
                      <td>{row.email}</td>
                      <td>{row.program}</td>
                      <td>{row.specialization || 'Not selected'}</td>
                      <td>{row.progress}%</td>
                      <td>{row.courseEnrolledAt ? moment(row.courseEnrolledAt).format('Do MMM, YYYY') : ''}</td>
                      <td>{row.graduationDate ? moment(row.graduationDate).format('Do MMM, YYYY') : ''}</td>
                      <td>{row.status}</td>
                      <td>{row.location}</td>
                      <td>{row.lastLogin ? moment(row.lastLogin).format('Do MMM, YYYY') : ''}</td>
                      <td>{row.cvLastUpdate ? moment(row.cvLastUpdate).format('Do MMM, YYYY') : ''}</td>
                    </tr>
                  ))
                  : <tr>
                    <td colSpan={columns.length}>There are no job seeking profiles yet.</td>
                  </tr>
                }
              </tbody>
            </table>
          </Table>
          <PaginationControls
            activePage={activePage}
            itemsCountPerPage={perPage}
            totalItemsCount={totalEntries}
            pageRangeDisplayed={perPage}
            onChange={handlePageChange}
          />
        </>
      }
    </>
  );
};

StudentTable.propTypes = {
  activePage: PropTypes.number.isRequired,
  getJobSeekingProfiles: PropTypes.func.isRequired,
  jobSeekingProfiles: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  perPage: PropTypes.number.isRequired,
  profileFilterParams: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    program: PropTypes.string,
    specialization: PropTypes.string,
  }),
  showJobSeekingProfile: PropTypes.func.isRequired,
  totalEntries: PropTypes.number.isRequired,
};

export default StudentTable;
