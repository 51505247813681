import ApiService from '../../services/ApiService';
import { getAuthToken } from '../../utils/getAuthToken';

const formatData = (response, file) => {
  const formData = new FormData();
  const {
    key,
    successActionStatus,
    policy,
    xAmzCredential,
    xAmzAlgorithm,
    xAmzDate,
    xAmzSignature,
    Expires,
  } = response.data.fields;
  formData.append('key', key);
  formData.append('acl', 'public-read');
  formData.append('Content-Type', file.type);
  formData.append('Expires', Expires);
  formData.append('success_action_Status', successActionStatus);
  formData.append('policy', policy);
  formData.append('x-amz-credential', xAmzCredential);
  formData.append('x-amz-algorithm', xAmzAlgorithm);
  formData.append('x-amz-date', xAmzDate);
  formData.append('x-amz-signature', xAmzSignature);
  formData.append('file', file, file.name);

  return formData;
};

export const handleUpload = async (file, prefix, userId) => {
  const { name, type, size } = file;
  const params = {
    authenticity_token: getAuthToken(),
    file_name: name,
    file_path: `${prefix}/${userId}/`,
    file_size: size,
    file_type: type,
    prefix: `${prefix}/`,
  };
  const apiService = new ApiService('/api/v1/uploads');
  try {
    const response = await apiService.makePostCall(params);
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const data = formatData(response, file);
    const s3Service = new ApiService(response.data.url);
    await s3Service.makePostCall(data, config);
    return response.data.fileData;
  } catch (error) {
    return error;
  }
};

export const createAttachment = async (
  fileData,
  attachableType,
  subtype,
  attachableId = null
) => {
  const { name, url, size, type } = fileData;
  const params = {
    attachment: {
      attachable_id: attachableId,
      attachable_type: attachableType,
      content_type: type,
      file_name: name,
      file_size: size,
      s3_url: url,
      subtype: subtype,
    },
    authenticity_token: getAuthToken(),
  };
  const apiService = new ApiService('/api/v1/attachments');
  try {
    const result = await apiService.makePostCall(params);
    return result;
  } catch (error) {
    return error;
  }
};

export const deleteAttachment = async (id) => {
  const params = {
    data: {
      authenticity_token: getAuthToken(),
    },
  };

  const apiService = new ApiService(`/api/v1/attachments/${id}`);
  try {
    const result = await apiService.makeDeleteCall(params);
    return result;
  } catch (error) {
    return error;
  }
};
