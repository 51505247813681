import styled from 'styled-components';

const StlyedCreateNewProject = styled.div`
  background-color: #fff;
  padding-bottom: 10px;

  h3 {
    margin-bottom: 24px;
  }
`;

StlyedCreateNewProject.displayName = 'StlyedCreateNewProject';

export default StlyedCreateNewProject;
