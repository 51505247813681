import styled from 'styled-components';

const StyledClearFiltersText = styled.p`
  text-decoration: underline !important;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export { StyledClearFiltersText };
