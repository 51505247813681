import React, { Component } from 'react'; 


class StepQuizEntry extends Component {
  constructor(props){
    super(props);
  }

  notStarted(){
    return this.props.questions_status_list.every(value => value == 'open');
  }

  isCompleted(){
    return this.props.questions_status_list.every(value => value != 'open');
  }

  render(){
    var renderText = () => {
      if(this.notStarted()){
        return "Take the quiz to test your knowledge on this Exercise.";
      }else if(this.isCompleted()){
        var correctAnswersCount = this.props.questions_status_list.filter(question => question == 'correct').length;
        return `You scored ${correctAnswersCount}/${this.props.questions_status_list.length} on this Exercise last time.`;
      }else{
        var answeredQuestionsCount = this.props.questions_status_list.filter(question => question != 'open').length;
        return `You've answered ${answeredQuestionsCount}/${this.props.questions_status_list.length} questions so far. Do you want to continue?`;
      }
    };

    var renderButtons = () => {
      if(this.notStarted()){
        return (<a className="quiz__button button--large button--primary-petrol" onClick={this.props.onStartNewQuiz}>Take Quiz</a>);
      }else if(this.isCompleted()){
        return (
          <div className="quiz__buttons">
            <a className="quiz__button button--primary-petrol" onClick={this.props.onTakeAgainClick}>Take Quiz Again</a>
            <a className="quiz__button button--back" onClick={this.props.onHandleSeeDetails}>See Details</a>
          </div>
        );
      }else{
        return (<a className="button--primary-petrol" onClick={this.props.onStartNewQuiz}>Continue Quiz</a>);
      }
    };

    var renderProgress = () => {
      var progressClass = this.isCompleted() ? ' quiz__completed' : '';

      return (<div className={`quiz__progress ${progressClass}`}>
        {this.props.questions_status_list.map((question, i) => {
          return (<div key={i} className={`quiz__progress__item ${question}`}></div>)
        })}
      </div>)
    };

    return (
      <div className="quiz__entry text-center">
        <p>{renderText()}</p>
        {renderProgress()}
        {renderButtons()}
      </div>
    );
  }
}

export default StepQuizEntry;
