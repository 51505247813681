import {
  CANDIDATE_ACTIVATION_FAILURE,
  CANDIDATE_ACTIVATION_SUCCESS,
  CLEAR_CANDIDATE,
  CLOSE_MODAL,
  CONTRACT_CHECKBOX,
  CREATE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE_FAILURE,
  DELETE_CANDIDATE_SUCCESS,
  GET_CANDIDATES_SUCCESS,
  GET_INITIAL_DATA_SUCCESS,
  INIT_CANDIDATE_FORM_SUCCESS,
  LOADING_STATE,
  OPEN_MODAL,
  SHOW_CANDIDATE_SUCCESS,
  UPDATE_CANDIDATE_SUCCESS,
  UPDATE_CONTRACT_STATUS_SUCCESS,
  WELCOME_EMAIL_SENT,
} from './actions';
import {
  CREATE_CANDIDATE_NOTE_SUCCESS,
  DELETE_CANDIDATE_NOTE_SUCCESS,
  GET_CANDIDATE_NOTES,
  GET_CURRENT_USER_ID,
} from './containers/CandidateNotes/actions';

export const initialState = {
  candidate: {},
  candidateForm: {
    courses: [],
    form_attributes: [],
    loading: true,
  },
  candidateLoaded: false,
  candidateNotes: [],
  candidateNotesLoading: true,
  candidateStates: [],
  candidatesList: [],
  candidatesListLoaded: false,
  currentUserId: 0,
  interviewers: [],
  loadingButton: false,
  modalAction: '',
  modalIsOpen: false,
};

const reduceGetCandidates = (state, action) => {
  return {
    ...state,
    candidatesList: action.candidates,
    candidatesListLoaded: true,
  };
};
const reduceInitialData = (state, action) => {
  return {
    ...state,
    candidateStates: action.candidateStates,
    interviewers: action.interviewers,
  };
};
const reduceShowCandidateSuccess = (state, action) => {
  return {
    ...state,
    candidate: action.data,
    candidateLoaded: true,
    modalIsOpen: false,
  };
};
const reduceUpdateCandidateSuccess = (state, action) => {
  return {
    ...state,
    candidate: action.data,
    candidateLoaded: true,
  };
};
const reduceCreateCandidateSuccess = (state, action) => {
  return {
    ...state,
    candidate: {},
    candidatesList: [action.data, ...state.candidatesList],
    candidatesListLoaded: false,
  };
};
const reduceInitCandidateSuccess = (state, action) => {
  return {
    ...state,
    candidateForm: {
      loading: false,
      ...action.data,
    },
  };
};
const reduceWelcomeEmailSent = (state, action) => {
  return {
    ...state,
    candidatesList: state.candidatesList.map((candidate) =>
      candidate.id === action.data.id ? action.data : candidate
    ),
  };
};

const reduceOpenModal = (state, action) => {
  return {
    ...state,
    errorMessage: {},
    loadingButton: false,
    modalAction: action.modalAction,
    modalIsOpen: true,
  };
};

const reduceCloseModal = (state) => {
  return {
    ...state,
    errorMessage: {},
    loadingButton: false,
    modalAction: '',
    modalIsOpen: false,
  };
};

const reduceCandidateActivationSuccess = (state) => {
  return {
    ...state,
    errorMessage: {},
    loadingButton: false,
    modalAction: 'activationSuccess',
  };
};

const reduceCandidateActivationFailure = (state, action) => {
  return {
    ...state,
    errorMessage: action.error,
    loadingButton: false,
    modalAction: 'activationFailure',
  };
};

const reduceLoadingState = (state) => {
  return {
    ...state,
    loadingButton: true,
  };
};

const reduceCurrentUserId = (state, action) => {
  return {
    ...state,
    currentUserId: action.currentUserId,
  };
};

const reduceCandidateNotes = (state, action) => {
  return {
    ...state,
    candidateNotes: action.candidateNotes,
    candidateNotesLoading: false,
  };
};

const reduceCreateCandidateNoteSuccess = (state, action) => {
  return {
    ...state,
    candidateNotes: [action.note, ...state.candidateNotes],
  };
};

const reduceDeleteCandidateNoteSuccess = (state, action) => {
  return {
    ...state,
    candidateNotes: state.candidateNotes.filter(
      (note) => note.id !== action.id
    ),
  };
};

const reduceContractCheckbox = (state, action) => {
  return {
    ...state,
    modalAction: action.modalAction,
    modalIsOpen: true,
  };
};

const reduceUpdateContractStatus = (state, action) => {
  return {
    ...state,
    candidate: action.data,
    modalAction:
      state.modalAction === 'hello_sign'
        ? 'contractSignedSuccess'
        : 'contractSentSuccess',
  };
};

const reduceClearCandidate = (state) => {
  return {
    ...state,
    candidate: {},
  };
};

const reduceDeleteCandidateSuccess = (state, action) => {
  return {
    ...state,
    candidate: {},
    candidatesList: state.candidatesList.filter(
      (candidate) => candidate.id !== parseInt(action.id)
    ),
    loadingButton: false,
    modalAction: 'deletionSuccess',
  };
};

const reduceDeleteCandidateFailure = (state, action) => {
  return {
    ...state,
    errorMessage: action.error,
    loadingButton: false,
    modalAction: 'deletionFailure',
  };
};

const CandidatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CANDIDATES_SUCCESS:
      return reduceGetCandidates(state, action);
    case GET_INITIAL_DATA_SUCCESS:
      return reduceInitialData(state, action);
    case SHOW_CANDIDATE_SUCCESS:
      return reduceShowCandidateSuccess(state, action);
    case UPDATE_CANDIDATE_SUCCESS:
      return reduceUpdateCandidateSuccess(state, action);
    case CREATE_CANDIDATE_SUCCESS:
      return reduceCreateCandidateSuccess(state, action);
    case INIT_CANDIDATE_FORM_SUCCESS:
      return reduceInitCandidateSuccess(state, action);
    case WELCOME_EMAIL_SENT:
      return reduceWelcomeEmailSent(state, action);
    case OPEN_MODAL:
      return reduceOpenModal(state, action);
    case CLOSE_MODAL:
      return reduceCloseModal(state);
    case CANDIDATE_ACTIVATION_SUCCESS:
      return reduceCandidateActivationSuccess(state);
    case CANDIDATE_ACTIVATION_FAILURE:
      return reduceCandidateActivationFailure(state, action);
    case LOADING_STATE:
      return reduceLoadingState(state);
    case GET_CURRENT_USER_ID:
      return reduceCurrentUserId(state, action);
    case GET_CANDIDATE_NOTES:
      return reduceCandidateNotes(state, action);
    case CREATE_CANDIDATE_NOTE_SUCCESS:
      return reduceCreateCandidateNoteSuccess(state, action);
    case DELETE_CANDIDATE_NOTE_SUCCESS:
      return reduceDeleteCandidateNoteSuccess(state, action);
    case CONTRACT_CHECKBOX:
      return reduceContractCheckbox(state, action);
    case UPDATE_CONTRACT_STATUS_SUCCESS:
      return reduceUpdateContractStatus(state, action);
    case CLEAR_CANDIDATE:
      return reduceClearCandidate(state);
    case DELETE_CANDIDATE_SUCCESS:
      return reduceDeleteCandidateSuccess(state, action);
    case DELETE_CANDIDATE_FAILURE:
      return reduceDeleteCandidateFailure(state, action);
    default:
      return state;
  }
};

export default CandidatesReducer;
