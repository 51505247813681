import React, { Component } from 'react';
import FooterButtons from './FooterButtons.jsx';
import PropTypes from 'prop-types';
import ResultBar from './ResultBar.jsx';

class DetailsSlide extends Component {
  render() {
    const renderChosenAnswerLabel = (answer) => {
      if (answer.value) {
        return 'correct_answer card--white';
      } else if (answer.id === this.props.chosen_answer_id) {
        return 'chosen_answer';
      }
    };

    const renderChosenIcon = (answer) => {
      if (answer.value) {
        return 'fa fa-check';
      } else if (answer.id === this.props.chosen_answer_id) {
        return 'fa fa-times';
      }
    };

    const renderAnswers = () => {
      return this.props.answers.map((answer, i) => {
        return (
          <div key={i} className={renderChosenAnswerLabel(answer)}>
            <span>
              <i className={renderChosenIcon(answer)}></i>
              <p>{answer.text}</p>
            </span>
          </div>
        );
      });
    };

    const prevButtonDisabled = this.props.detailsProgress === 1;

    return (
      <div className='quiz__slide quiz__details__slide'>
        <header>
          <p className='cf-small--gray cf-bold'>Quiz: {this.props.quizLabel}</p>
        </header>
        <div className='quiz__results'>
          <p>{`You scored ${this.props.numberOfCorrectAnswers}/${this.props.numberOfQuestions} on this quiz!`}</p>
          <ResultBar
            questions_status_list={this.props.questions_status_list}
            detailsView={true}
            detailsProgress={this.props.detailsProgress}
          />
          <p className='cf-bold'>{this.props.question}</p>
          <div className='details__answers'>{renderAnswers()}</div>
        </div>
        <p className='cf-small'>{this.props.explanation}</p>
        <FooterButtons
          prevButtonLabel='Previous'
          nextButtonLabel='Next'
          onNextClick={this.props.onDetailsNextClick}
          onPrevClick={this.props.onDetailsPrevClick}
          prevButtonDisabled={prevButtonDisabled}
          nextButtonDisabled={false}
          modalLoading={this.props.modalLoading}
        />
      </div>
    );
  }
}

DetailsSlide.propTypes = {
  answers: PropTypes.array,
  chosen_answer_id: PropTypes.number,
  detailsProgress: PropTypes.number,
  explanation: PropTypes.string,
  modalLoading: PropTypes.bool,
  numberOfCorrectAnswers: PropTypes.number,
  numberOfQuestions: PropTypes.number,
  onDetailsNextClick: PropTypes.func,
  onDetailsPrevClick: PropTypes.func,
  question: PropTypes.string,
  questions_status_list: PropTypes.array,
  quizLabel: PropTypes.string,
};

export default DetailsSlide;
