import Checkbox from '../../../../shared/components/Checkbox';
import { CheckboxWrapper } from './styles';
import InstructorFilters from './InstructorFilters';
import InstructorList from './InstructorList';
import InstructorRegionSelector from './InstructorRegionSelector';
import InstructorTypeSelector from './InstructorTypeSelector';
import PropTypes from 'prop-types';
import React from 'react';

const Instructors = ({
  activeInstructors,
  activeRegion,
  assigningInstructorId,
  assigningStudentId,
  certified,
  csvDownloadURL,
  filterInstructors,
  instructorCount,
  instructorList,
  instructorTags,
  orderByCurrentStudents,
  setInstructorsTab,
  setTimeZoneTab,
  toggleInstructorAssignment,
  toggleOrderByCurrentStudents,
  totalFreeCapacity,
}) => (
  <section className="ds-grid__col-xs-8">
    <InstructorTypeSelector
      activeInstructors={activeInstructors}
      setInstructorsTab={setInstructorsTab}
      totalFreeCapacity={totalFreeCapacity}
      csvDownloadURL={csvDownloadURL}
      toggleOrderByCurrentStudents={toggleOrderByCurrentStudents}
      orderByCurrentStudents={orderByCurrentStudents}
    />

    <InstructorRegionSelector
      activeRegion={activeRegion}
      setTimeZoneTab={setTimeZoneTab}
      instructorCount={instructorCount}
    />

    <CheckboxWrapper>
      <Checkbox
        labelText='Order by current students'
        defaultChecked={orderByCurrentStudents || false}
        onClick={toggleOrderByCurrentStudents}
      />
    </CheckboxWrapper>

    <InstructorFilters
      instructorTags={instructorTags}
      filterInstructors={filterInstructors}
    />

    <InstructorList
      assigningStudentId={assigningStudentId}
      certified={certified}
      instructorList={instructorList}
      toggleInstructorAssignment={toggleInstructorAssignment}
      assigningInstructorId={assigningInstructorId}
    />
  </section>
);

Instructors.propTypes = {
  activeInstructors: PropTypes.string.isRequired,
  activeRegion: PropTypes.string.isRequired,
  assigningInstructorId: PropTypes.number,
  assigningStudentId: PropTypes.number,
  certified: PropTypes.bool,
  csvDownloadURL: PropTypes.string,
  filterInstructors: PropTypes.func.isRequired,
  instructorCount: PropTypes.object.isRequired,
  instructorList: PropTypes.array.isRequired,
  instructorTags: PropTypes.array,
  orderByCurrentStudents: PropTypes.bool,
  setInstructorsTab: PropTypes.func.isRequired,
  setTimeZoneTab: PropTypes.func.isRequired,
  toggleInstructorAssignment: PropTypes.func.isRequired,
  toggleOrderByCurrentStudents: PropTypes.func.isRequired,
  totalFreeCapacity: PropTypes.number.isRequired,
};

export default Instructors;
