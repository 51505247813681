import PropTypes from 'prop-types';
import React from 'react';
import StyledButtonSection from './styles/StyledButtonSection';

const ButtonSection = ({ isSaving, onClickConfirm, onClose }) => (
  <StyledButtonSection>
    <a onClick={onClose} className="ds-button__secondary">
        Decide later
    </a>
    <a
      onClick={!isSaving ? onClickConfirm : null}
      className={`ds-button__primary ${isSaving ? 'disabled' : ''}`}
    >
        Confirm
    </a>
  </StyledButtonSection>
);

ButtonSection.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ButtonSection;
