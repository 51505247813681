export const errorMessage = (formErrors, key) =>
  formErrors[key] ? formErrors[key][0] : '';

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

export const getFileData = async (ev) => {
  const file = ev.target.files[0];
  const base64 = await toBase64(file);
  return {
    data: base64,
    fileName: file.name,
    fileSize: file.size,
  };
};
