import {
  addCallNotes,
  createOffPlatformCall,
  setModalType,
} from '../../action';
import CallNoteFormModal from '../../components/Modals/CallNoteFormModal';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  mentorCallNotes: {
    canSubmitNote,
    currentCallId,
    currentCalendarEntry,
    errorMessage,
    isOffPlatformCall,
    modalType,
    mentorNoteForm,
    submitLoading,
  },
}) => ({
  canSubmitNote,
  currentCalendarEntry,
  currentCallId,
  errorMessage,
  isOffPlatformCall,
  mentorNoteForm,
  modalType,
  submitLoading,
});

export const mapDispatchToProps = (dispatch) => ({
  createOffPlatformCall: (callInformation, noteData) =>
    dispatch(createOffPlatformCall(callInformation, noteData)),
  onSubmit: (callId, data) => dispatch(addCallNotes(callId, data)),
  setModalType: (modalType) => dispatch(setModalType(modalType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallNoteFormModal);
