import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import CSCMainDashboardContainer from '../../containers/CSCMainDashboardContainer';
import CreateNewProject from '../../components/CreateNewProject';
import Freelancing from '../Pages/Freelancing';
import InterviewPrepTool from '../../../InterviewPrep/containers/App/index';
import JobSearchTips from '../JobSearchTips';
import PropTypes from 'prop-types';
import RecommendedJobsBoard from '../RecommendedJobsBoard';
import RemoteWork from '../Pages/RemoteWork';

import { debounce } from 'lodash';

const App = ({ attendeeId }) => {
  const [isMobile, setMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', debounce(handleResize, 500));
    return () => {
      window.removeEventListener('resize', debounce(handleResize, 500));
    };
  }, []);

  return (
    <Router>
      <Switch>
        <Route path='/en/new-project'>
          <CreateNewProject isMobile={isMobile} />
        </Route>
        <Route path='/en/career-support-center'>
          <CSCMainDashboardContainer
            isMobile={isMobile}
            attendeeId={attendeeId}
          />
        </Route>
        <Route path='/en/interview-training'>
          <InterviewPrepTool />
        </Route>
        <Route path='/en/job-search-tips'>
          <JobSearchTips />
        </Route>
        <Route path='/en/freelancing'>
          <Freelancing />
        </Route>
        <Route path='/en/remote-work'>
          <RemoteWork />
        </Route>
        <Route path='/en/recommended-jobs'>
          <RecommendedJobsBoard isMobile={isMobile} />
        </Route>
      </Switch>
    </Router>
  );
};

App.propTypes = {
  attendeeId: PropTypes.number,
};

export default App;
