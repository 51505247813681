import ToggleSection from '../../JobApplicationTracker/components/JobApplicationsConsent/ToggleSection';
import styled from 'styled-components';

const FormToggleSection = styled(ToggleSection)`
  justify-content: flex-start;
  margin-bottom: 7px;

  @media (max-width: 768px) {
    align-items: center;

    p {
      font-size: 16px;
    }
  }
`;

FormToggleSection.displayName = 'FormToggleSection';

export default FormToggleSection;
