import App from '../../../../shared/components/ProgressLine';
import { connect } from 'react-redux';

export const mapStateToProps = (
  { weeklySubmissionGoal: { exercises, goal, submissions } },
  { complete, total, isTestVariant }
) => {
  const bluePrint = Array.from({ length: total }, (v, k) =>
    k < complete ? 'complete' : 'tick'
  );

  if (isTestVariant === 'true' && exercises) {
    const approvedThisWeek = Object.keys(exercises).filter(
      (k) => exercises[k].exercise_state === 'approved'
    ).length;

    bluePrint.splice(
      complete + goal - approvedThisWeek,
      0,
      goal <= submissions ? 'wsgMet' : 'wsg'
    );
  }

  return {
    bluePrint,
  };
};

export default connect(mapStateToProps)(App);
