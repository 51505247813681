import Accordion from './Accordion';
import styled from 'styled-components';

const StyledAccordionAchievement = styled(Accordion)`
  margin-bottom: 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--ds-color__border-secondary-disabled);
`;

export default StyledAccordionAchievement;
