import styled from 'styled-components';

const HeaderStyles = styled.div`
  border: 1px solid #c0c6cb;
  padding: 14px;

  h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0;
  }
`;

HeaderStyles.displayName = 'HeaderStyles';

export default HeaderStyles;
