import {
  HeaderDropDown,
  HeaderDropDownImg,
  HeaderDropDownName,
  HeaderLeft,
  HeaderRight,
  StyledHeader,
} from './styles';
import Dropdown from '../../components/Dropdown';
import PropTypes from 'prop-types';
import React from 'react';
import profile from '../../assets/images/profile.svg';

const Header = ({ userData }) => (
  <StyledHeader className='ds-typography__body'>
    <HeaderLeft role='logoPart'></HeaderLeft>
    <HeaderRight>
      <Dropdown
        title={
          <HeaderDropDown>
            <HeaderDropDownName
              className='ds-typography__body'
              role='headerUserName'
            >
              {userData.name}
            </HeaderDropDownName>
            <HeaderDropDownImg>
              <img src={userData.avatar ? userData.avatar : profile} />
            </HeaderDropDownImg>
          </HeaderDropDown>
        }
        options={[
          {
            onClick: () => {
              window.location.href = '/en/signout';
            },
            text: 'Log out',
            value: 'logout',
          },
        ]}
      />
    </HeaderRight>
  </StyledHeader>
);

Header.propTypes = {
  userData: PropTypes.object,
};

export default Header;
