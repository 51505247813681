import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

const DeletionConfirm = ({ handleClose, handleDelete, loadingButton }) => {
  const { candidateId } = useParams();

  return (
    <>
      <i className="ds-icon__info-line"></i>
      <h3 className="ds-typography__h3">Before you delete...</h3>
      <p className="ds-typography__lead">
        Are you sure you want to delete the instructor? This action cannot be
        undone and instructor data will be deleted or anonymised forever.
      </p>
      <button className="ds-button__secondary" onClick={handleClose}>
        Discard
      </button>
      <button
        className="ds-button__primary delete-button"
        onClick={() => handleDelete(candidateId)}
      >
        {loadingButton ? <LoadingSpinner /> : 'Delete'}
      </button>
    </>
  );
};

DeletionConfirm.propTypes = {
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  loadingButton: PropTypes.bool,
};

export default DeletionConfirm;
