import { Body, Button, Header } from '@careerfoundry/bubble';
import {
  DiversitySurveyBody,
  DiversitySurveyContainer,
  DiversitySurveyFootnote,
  DiversitySurveyTertiaryButton,
} from '../styles';
import {
  BodyText,
  HeaderContent,
  HeaderContentDescription,
  PrimaryButtonLabel,
  TertiaryButtonLabel,
} from '../constants';
import React, { useState } from 'react';
import StartDateCourseBanner from './StartDateCourseBanner';
import { redirectTo } from '../../../utils/redirectTo';
import TheModal from '../../shared/components/TheModal';

interface Props {
  startDate: string;
  courseName: string;
}

const App: React.FC<Props> = ({ courseName, startDate }) => {
  const [shouldShowExitConfirmationModal, setShouldShowExitConfirmationModal] =
    useState<boolean>(false);

  return (
    <>
      <StartDateCourseBanner courseName={courseName} startDate={startDate} />
      <DiversitySurveyContainer>
        <Header tag='h2' variant='h2'>
          {HeaderContent}
        </Header>
        <DiversitySurveyBody tag='p' variant='large'>
          {HeaderContentDescription}
        </DiversitySurveyBody>
        <img
          className='DiversitySurveyImageDesktop'
          src='https://images.careerfoundry.com/public/enroll/student%20diversity2-min.png'
          alt='student_diversity'
        />
        <img
          className='DiversitySurveyImageMobile'
          src='https://images.careerfoundry.com/public/enroll/Student_Diversity_mobile.png'
          alt='student_diversity'
        />
        <DiversitySurveyBody tag='p' variant='large'>
          {BodyText}
        </DiversitySurveyBody>
        <div className='DiversitySurveyButtonContainer'>
          <Button
            label={PrimaryButtonLabel}
            onClick={() => redirectTo({ url: '/en/diversity_survey/' })}
            size='standard'
            variant='primary'
          />
          <DiversitySurveyTertiaryButton
            label={TertiaryButtonLabel}
            onClick={() => setShouldShowExitConfirmationModal(true)}
            size='standard'
            variant='tertiary'
          />
        </div>
        <DiversitySurveyFootnote tag='p' variant='small'>
          *Any information you provide by answering the survey is treated and
          processed in accordance with the provisions of the EU General Data
          Protection Regulation (GDPR) and the German Federal Data Protection
          Act (BDSG). You may revoke your consent at any time after answering
          the survey by sending a message to{' '}
          <Body tag='span' variant='small-bold'>
            datenschutz@careerfoundry.com
          </Body>
          . Your data will then be deleted from our survey results. Your data
          will automatically be deleted after 3 years.
        </DiversitySurveyFootnote>
        {shouldShowExitConfirmationModal && (
          <TheModal
            isModalOpen={shouldShowExitConfirmationModal}
            handleClose={() => setShouldShowExitConfirmationModal(false)}
          >
            <Header tag='h4' variant='h4'>
              You’re about to leave the page
            </Header>
            <Body tag='p' variant='medium'>
              You can always tell us more about yourself later by completing the
              Diversity Survey in your student profile.
              <span>
                If you quit, you’ll be redirected to the Orientation Course.
              </span>
            </Body>
            <div className='footer'>
              <Button
                variant='secondary'
                label='Yes, quit'
                onClick={() => {
                  setShouldShowExitConfirmationModal(false);
                  redirectTo({
                    url: '/en/dashboards/orientation/course-home/',
                  });
                }}
              />
              <Button
                label='Cancel'
                onClick={() => {
                  setShouldShowExitConfirmationModal(false);
                }}
              />
            </div>
          </TheModal>
        )}
      </DiversitySurveyContainer>
    </>
  );
};

export default App;
