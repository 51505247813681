import { get } from 'lodash';
import { groupInterviewAnswers } from '../InterviewPrep/helpers';

export const initialState = {
  answers: [],
  answersForReview: [],
  callReviewsActivePage: 1,
  courses: {},
  coursesLoading: false,
  currentAnswer: null,
  interviewAnswers: [],
  isInterviewLoading: false,
  isMentorPage: false,
  jobApplication: {},
  jobApplicationsTab: {
    activePage: 1,
    applications: [],
    applicationsLength: 0,
    loading: true,
  },
  loading: true,
  mentorNotesTab: {
    activePage: 1,
    allowModalClose: false,
    formAction: '',
    formData: {
      categories: [],
      content: '',
      shared: true,
      title: '',
    },
    loading: true,
    mentorNotes: [],
    modalAction: 'form',
    modalIsOpen: false,
    selectedNote: null,
  },
  modalAction: '',
  modalIsOpen: false,
  reviewsLoading: true,
  selectedCourse: 0,
  selectedInsight: {},
  studentNotesActivePage: 1,
  tabIndex: 0,
  user: {},
  viewApplication: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'UserProfile.LOAD_DATA_SUCCESS':
      return {
        ...state,
        answers: action.answers,
        currentUserId: action.currentUserId,
        loading: false,
        studentIsViewing: action.user.id === action.currentUserId,
        user: action.user,
        userId: action.user.id,
      };
    case 'UserProfile.CALL_REVIEWS_LOADED':
      return {
        ...state,
        callReviews: action.callReviews,
        callReviewsLength: action.callReviews.length,
        reviewsLoading: false,
      };
    case 'UserProfile.SET_TAB_INDEX':
      return {
        ...state,
        tabIndex: action.tabIndex,
      };
    case 'UserProfile.SET_SELECTED_COURSE':
      return {
        ...state,
        selectedCourse:
          action.courseId && action.cardState !== 'blocked'
            ? action.courseId
            : state.selectedCourse,
      };
    case 'UserProfile.SET_ACTIVE_PAGE_FOR_STUDENT_NOTES':
      return {
        ...state,
        studentNotesActivePage: action.activePage,
      };
    case 'UserProfile.SET_ACTIVE_PAGE_FOR_CALL_REVIEWS':
      return {
        ...state,
        callReviewsActivePage: action.activePage,
      };
    case 'UserProfile.SET_ACTIVE_PAGE_FOR_MENTOR_NOTES':
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          activePage: action.activePage,
        },
      };
    case 'UserProfile.GET_COURSE_DATA_REQUEST':
      return {
        ...state,
        coursesLoading: true,
      };
    case 'UserProfile.GET_COURSE_DATA_SUCCESS': {
      const activeCourseId = get(action, 'data.attendees.activeCourse', 0);

      return {
        ...state,
        courses: action.data,
        coursesLoading: false,
        selectedCourse: activeCourseId,
      };
    }
    case 'UserProfile.GET_COURSE_DATA_FAILURE':
      return {
        ...state,
        coursesLoading: false,
      };
    case 'UserProfile.MENTOR_NOTES_LOADED':
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          loading: false,
          mentorNotes: action.mentorNotes,
        },
      };
    case 'UserProfile.HANDLE_NEW_NOTE':
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          allowModalClose: false,
          formAction: 'Add',
          formData: {
            categories: [],
            content: '',
            shared: true,
            title: '',
          },
          modalAction: 'form',
          modalIsOpen: true,
          selectedNote: null,
        },
      };
    case 'UserProfile.MENTOR_NOTE_CANCEL_MODAL':
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          allowModalClose: false,
          modalAction: 'form',
        },
      };
    case 'UserProfile.HANDLE_MODAL_CLOSE': {
      const allowClose = state.mentorNotesTab.allowModalClose;
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          allowModalClose: allowClose || true,
          modalAction: allowClose ? state.mentorNotesTab.modalAction : 'cancel',
          modalIsOpen: allowClose ? false : state.mentorNotesTab.modalIsOpen,
        },
      };
    }
    case 'UserProfile.HANDLE_MENTOR_NOTE_FORM_CHANGE':
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          formData: action.data,
        },
      };
    case 'UserProfile.HANDLE_MENTOR_NOTE_CATEGORY_CHANGE':
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          formData: {
            ...state.mentorNotesTab.formData,
            categories: action.categories,
          },
        },
      };
    case 'UserProfile.HANDLE_DELETE_NOTE':
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          allowModalClose: true,
          modalAction: 'delete',
          modalIsOpen: true,
          selectedNote: state.mentorNotesTab.mentorNotes.find(
            (x) => x.id === action.id
          ),
        },
      };
    case 'UserProfile.MENTOR_NOTE_DELETED':
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          allowModalClose: true,
          mentorNotes: state.mentorNotesTab.mentorNotes.filter(
            (x) => x.id !== action.id
          ),
          modalIsOpen: false,
        },
      };
    case 'UserProfile.MENTOR_NOTE_CREATED':
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          mentorNotes: [action.mentorNote, ...state.mentorNotesTab.mentorNotes],
          modalIsOpen: false,
        },
      };
    case 'UserProfile.HANDLE_EDIT_NOTE': {
      const selectedNote = state.mentorNotesTab.mentorNotes.find(
        (x) => x.id === action.id
      );
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          allowModalClose: false,
          formAction: 'Update',
          formData: {
            categories: selectedNote.categories,
            content: selectedNote.content,
            shared: selectedNote.shared,
            title: selectedNote.title,
          },
          modalAction: 'form',
          modalIsOpen: true,
          selectedNote: selectedNote,
        },
      };
    }
    case 'UserProfile.MENTOR_NOTE_UPDATED':
      return {
        ...state,
        mentorNotesTab: {
          ...state.mentorNotesTab,
          mentorNotes: state.mentorNotesTab.mentorNotes.map((note) =>
            note.id === action.mentorNote.id ? action.mentorNote : note
          ),
          modalIsOpen: false,
        },
      };
    case 'UserProfile.SET_MENTOR_PAGE':
      return {
        ...state,
        isMentorPage: true,
      };
    case 'UserProfile.GET_INTERVIEW_ANSWERS_SUCCESS':
      return {
        ...state,
        interviewAnswers: groupInterviewAnswers(action.data),
        isInterviewLoading: false,
        isMentorPage: true,
      };

    case 'UserProfile.GET_INTERVIEW_ANSWERS_FAILURE':
      return {
        ...state,
        interviewAnswers: [],
        isInterviewLoading: false,
        isMentorPage: true,
      };

    case 'UserProfile.EVALUATE_PREVIOUS_RESPONSES': {
      return {
        ...state,
        answersForReview: [action.data],
        modalAction: 'evaluation',
        modalIsOpen: true,
      };
    }

    case 'UserProfile.CLOSE_EVALUATION': {
      return {
        ...state,
        answersForReview: [],
        modalAction: '',
        modalIsOpen: false,
      };
    }

    case 'UserProfile.SET_CURRENT_ANSWER': {
      const { currentAnswer } = action;
      return {
        ...state,
        currentAnswer,
      };
    }

    case 'UserProfile.SET_LOADING_INTERVIEWS': {
      return {
        ...state,
        isInterviewLoading: true,
      };
    }

    case 'UserProfile.OPEN_QUESTION_INSIGHTS':
      return {
        ...state,
        modalAction: 'questionInsights',
        modalIsOpen: true,
        selectedInsight: {
          insights: action.insights,
          question: action.question,
        },
      };

    case 'UserProfile.JOB_APPLICATIONS_LOADED':
      return {
        ...state,
        jobApplicationsTab: {
          ...state.jobApplicationsTab,
          applications: action.applications,
          applicationsLength: action.applications.length,
          loading: false,
        },
      };
    case 'UserProfile.SET_ACTIVE_PAGE_FOR_JOB_APPLICATIONS':
      return {
        ...state,
        jobApplicationsTab: {
          ...state.jobApplicationsTab,
          activePage: action.activePage,
        },
      };

    case 'UserProfile.HANDLE_VIEW_JOB_APPLICATION': {
      const { applications } = state.jobApplicationsTab;
      const { jobId } = action;

      return {
        ...state,
        jobApplication: applications.find((x) => x.id === jobId),
        modalAction: 'viewApplication',
        viewApplication: true,
      };
    }

    case 'UserProfile.CLOSE_MODAL': {
      return {
        ...state,
        modalAction: '',
        viewApplication: false,
      };
    }
    default:
      return state;
  }
}
