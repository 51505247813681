import styled from 'styled-components';

const ButtonWrapper = styled.div`
  text-align: right;
  margin-bottom: 20px;
`;

ButtonWrapper.displayName = 'ButtonWrapper';

export default ButtonWrapper;
