import Activity from './Activity';
import Border from './styles/Border';
import CourseCard from './styles/CourseCard';
import DropDown from '../../containers/Dropdown';
import EmptyTab from '../EmptyTab';
import Header from './styles/Header';
import InfoWrapper from './styles/InfoWrapper';
import Mentors from './Mentors';
import Progress from './Progress';
import PropTypes from 'prop-types';
import React from 'react';
import Sidebar from '../../containers/Sidebar';
import Wrapper from './styles/Wrapper';
import { displayState } from './helpers';

const CoursesTab = ({
  attendees,
  currentUserId,
  hasAttendees,
  selectedCourse,
  studentIsViewing,
}) => (
  <Wrapper className='ds-grid__row'>
    <Sidebar />
    <DropDown />
    {hasAttendees ? (
      <CourseCard className='ds-grid__col-lg-9 ds-grid__col-xs-12'>
        {attendees.map(
          (attendee) =>
            attendee.courseId === selectedCourse && (
              <div key={attendee.name}>
                <Header>
                  <h3 className='ds-typography__h3'>{attendee.name}</h3>
                  <span className='ds-typography__body--bold'>
                    {displayState(attendee.state)}
                  </span>
                </Header>
                <div>
                  <InfoWrapper>
                    <p className='ds-typography__body--bold'>Start Date</p>
                    <p className='ds-typography__body'>{attendee.start}</p>
                  </InfoWrapper>
                  <InfoWrapper>
                    <p className='ds-typography__body--bold'>End Date</p>
                    <p className='ds-typography__body'>
                      {attendee.targetCompletion}
                    </p>
                  </InfoWrapper>
                </div>
                <Border />
                <Activity
                  activity={attendee.activity}
                  graduationDate={attendee.graduationDate}
                  start={attendee.start}
                  end={attendee.targetCompletion}
                />
                {currentUserId && attendee.mentors.length && (
                  <Mentors
                    currentUserId={currentUserId}
                    mentors={attendee.mentors}
                    studentIsViewing={studentIsViewing}
                  />
                )}
                <Progress
                  achievements={attendee.progress}
                  nextExercise={attendee.nextExercise}
                />
              </div>
            )
        )}
      </CourseCard>
    ) : (
      <EmptyTab type='courses' />
    )}
  </Wrapper>
);

CoursesTab.propTypes = {
  attendees: PropTypes.arrayOf(
    PropTypes.shape({
      courseId: PropTypes.number,
      graduationDate: PropTypes.string,
      mentors: PropTypes.array,
      name: PropTypes.string.isRequired,
      nextExercise: PropTypes.string,
      progress: PropTypes.array,
      start: PropTypes.string,
      state: PropTypes.string.isRequired,
      targetCompletion: PropTypes.string,
    })
  ),
  currentUserId: PropTypes.number,
  hasAttendees: PropTypes.bool.isRequired,
  selectedCourse: PropTypes.number.isRequired,
  studentIsViewing: PropTypes.bool,
};

export default CoursesTab;
