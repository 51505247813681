import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import StyledDropdown from './styles/StyledDropdown';
import { programHeaders } from '../../constants';

const IndustryDropdown = ({
  industries,
  getCurrentUser,
  updateInstructorIndustry,
  user,
  userIndustry,
  getAllIndustries,
  getIndustry,
}) => {
  useEffect(() => {
    getCurrentUser();
  }, []);

  const filteredIndustries = useMemo(() => {
    if (industries && industries.length > 0) {
      return industries.filter(industry => industry !== 'general');
    }
    return [];
  }, [industries]);

  useEffect(() => {
    getIndustry();
    getAllIndustries();
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    const { id } = user.instructorSetting;
    updateInstructorIndustry(id, value);
  };

  return user && user.instructorSetting && filteredIndustries.length > 0
    ? (
      <StyledDropdown>
        <select
          className="select_box"
          onChange={handleChange}
          name="industry"
          defaultValue={userIndustry}
        >
          {filteredIndustries.map((industry, i) => (
            <option value={industry} key={industry}>
              {programHeaders[industry]}
            </option>
          ))}
        </select>
      </StyledDropdown>
    )
    : null;
};

IndustryDropdown.propTypes = {
  getAllIndustries: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  getIndustry: PropTypes.func.isRequired,
  industries: PropTypes.array.isRequired,
  updateInstructorIndustry: PropTypes.func.isRequired,
  user: PropTypes.object,
  userIndustry: PropTypes.string.isRequired,
};

export default IndustryDropdown;
