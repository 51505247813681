import styled from 'styled-components';

const StyledOffPlatformSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;

  span {
    margin-bottom: 20px;
  }

  input {
    margin: 0px;
  }
`;

StyledOffPlatformSuccess.displayName = 'StyledOffPlatformSuccess';

export default StyledOffPlatformSuccess;
