import { Accordion } from 'react-accessible-accordion';
import PropTypes from 'prop-types';
import React from 'react';
import StyledAccordion from '../styles/StyledAccordion';

const MobileAccordionWrapper = ({ children }) => {
  return (
    <StyledAccordion>
      <Accordion
        allowZeroExpanded
        allowMultipleExpanded
      >
        { children }
      </Accordion>
    </StyledAccordion>
  );
};

MobileAccordionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MobileAccordionWrapper;
