import { Action } from './types';
import { Actions } from './constants';
import { Service, State } from './interfaces';

export const initialState = {
  error: {
    attribute: '',
    type: '',
    message: [],
  },
  loading: true,
  modalAction: '',
  students: [],
  serviceTypes: [],
  services: [],
  selectedService: undefined,
};

export default function (state = initialState, action: Action): State {
  switch (action.type) {
    case Actions.GET_STUDENTS_AND_SERVICES_SUCCESS:
      return {
        ...state,
        students: action.payload.students,
        serviceTypes: action.payload.serviceTypes,
      };
    case Actions.GET_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.payload,
      };
    case Actions.SET_MODAL_ACTION:
      return {
        ...state,
        modalAction: action.payload,
      };
    case Actions.HANDLE_MODAL_CLOSE:
      return {
        ...state,
        modalAction: '',
        selectedService: undefined,
      };
    case Actions.HANDLE_SUBMIT_SUCCESS:
      return {
        ...state,
        error: {
          attribute: '',
          type: '',
          message: [],
        },
        modalAction: 'createConfirmation',
        services: [...state.services, action.payload],
      };
    case Actions.HANDLE_SUBMIT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case Actions.HANDLE_EDIT:
      return {
        ...state,
        modalAction: 'form',
        selectedService: state.services.find(
          (service: Service) => service.id === action.payload
        ),
      };
    case Actions.HANDLE_DELETE_SUCCESS:
      return {
        ...state,
        modalAction: 'deleteConfirmation',
        selectedService: undefined,
        services: state.services.filter(
          (service: Service) => service.id !== action.payload
        ),
      };
    case Actions.HANDLE_UPDATE_SUCCESS:
      return {
        ...state,
        modalAction: 'updateConfirmation',
        selectedService: undefined,
        services: state.services.map((service: Service) =>
          service.id === action.payload.id ? action.payload : service
        ),
      };
    case Actions.HANDLE_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
