import JobSeekingProfileShow from '../../../../../EmployerConnector/modals/showModal';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  adminEmployerConnector: {
    jobSeekingProfile,
  },
}) => ({
  jobSeekingProfile: jobSeekingProfile,
  studentViewing: false,
});

export default connect(mapStateToProps, null)(JobSeekingProfileShow);
