import styled from 'styled-components';

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0 rgba(51, 51, 51, 0.1);
  padding: 32px 40px 24px;

  hr {
    display: none;
  }

  @media (max-width: 1200px) {
    width: 700px;
    margin: 0 0 72px auto;
  }

  @media (max-width: 1100px) {
    width: 635px;
    margin: 0 0 72px auto;
  }

  @media (max-width: 1023px) {
    margin: unset;
    width: 100%;
  }

  @media (max-width: 768px) {
    &.media-body {
      background: #f4f5f6;
      padding: 0 0 8px;

      p {
        margin-bottom: 16px;
      }

      a {
        color: white;
      }
    }
    h4 {
      font-size: 24px;
      font-weight: 400 !important;
      line-height: 40px;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 28px;
    }
    margin-top: 0px;
    hr {
      display: block !important;
      margin: 15px -16px;
      border: solid 1px #e8ebed;
    }
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
  @media (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 24px;
    &.mb-72 {
      margin-bottom: 72px;
    }
  }

  @media (max-width: 360px) {
    a.link-row,
    a.ds-button__primary,
    a.ds-button__secondary {
      font-size: var(--md-font) !important;
    }
  }
`;

Card.displayName = 'Card';

export default Card;
