import { GlobeIcon } from './icons';
import JobSearchTile from '../../UsefulLinks/JobSearchTile';
import React from 'react';

const FreelancingTile = () => (
  <JobSearchTile
    description='Everything a successful freelancer needs to know.'
    icon={<GlobeIcon />}
    secondaryButton={{
      event: 'freelancing_page_click',
      link: '/en/freelancing',
      text: 'Read about freelancing',
    }}
    title='Freelancing'
  />
);

export default FreelancingTile;
