import logo from '../../assets/images/logo.svg';
import logoSmall from '../../assets/images/logo_small.svg';
import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background: var(--ds-color__indigo);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  padding: 0px 72px;
  @media only screen and (max-width: 768px) {
    padding: 0px 21px;
    .ob-header {
      &__left {
        line-height: 13px;
        height: 100%;
        width: 27px;
        background: url(${logoSmall}) no-repeat 0px 0px;
      }
    }
  }
`;

export const HeaderLeft = styled.div`
  line-height: 13px;
  height: 100%;
  width: 150px;
  background: url(${logo}) no-repeat 0px 10px;
`;

export const HeaderRight = styled.div`
  line-height: 13px;
  color: white;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const HeaderDropDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderDropDownName = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: white;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const HeaderDropDownImg = styled.div`
  margin: 0px 16px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;
