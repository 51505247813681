module.exports = (() => {
  var init = function () {
    initializeCarousel();
    bindFunctions();
  };

  var bindFunctions = function () {
    $(window).on("load", slideToHash);
    $(window).on("load", showCorrectArrowState);
    $(window).on("load", addCheckMarksToMenu);
    $(window).on("scroll", createCookie);
    $(document).on("slid.bs.carousel", ".prep-carousel.carousel", toggleArrowDisable);
    $(document).on("slid.bs.carousel", ".prep-carousel.carousel", setLocation);
    $(document).on("click", ".disabled", preventDef);
    $(document).on("click", ".myCarousel-target", slideToMenuItem);
    $(document).on("click", ".bottom-controls a.left.enabled, .bottom-controls a.right.enabled", scrollToTop);
    $(document).on("click", ".toc a", scrollToSubheading);
  };

  var showCorrectArrowState = function () {
    if ($(".carousel-inner .item:first").hasClass("active")) {
      $(".prep-carousel.carousel").find(".card .top-controls a.left.enabled, .card .bottom-controls a.left.enabled").removeClass("enabled").addClass("disabled");
    } else {
      $(".prep-carousel.carousel").find(".card .top-controls a.left.enabled, .card .bottom-controls a.left.enabled").removeClass("disabled").addClass("enabled");
    }

    if ($(".carousel-inner .item:last").hasClass("active")) {
      $(".prep-carousel.carousel").find(".card .top-controls a.right.enabled, .card .bottom-controls a.right.enabled").removeClass("enabled").addClass("disabled");
    } else {
      $(".prep-carousel.carousel").find(".card .top-controls a.right.disabled, .card .bottom-controls a.right.disabled").removeClass("disabled").addClass("enabled");
    }
  };

  var toggleArrowDisable = function () {
    if ($(".carousel-inner .item:first").hasClass("active")) {
      $(this).find(".card .top-controls a.left.enabled, .card .bottom-controls a.left.enabled").removeClass("enabled").addClass("disabled");
    } else {
      $(this).find(".card .top-controls a.left.disabled, .card .bottom-controls a.left.disabled").removeClass("disabled").addClass("enabled");
    }

    if ($(".carousel-inner .item:last").hasClass("active")) {
      $(this).find(".card .top-controls a.right.enabled, .card .bottom-controls a.right.enabled").removeClass("enabled").addClass("disabled");
    } else {
      $(this).find(".card .top-controls a.right.disabled, .card .bottom-controls a.right.disabled").removeClass("disabled").addClass("enabled");
    }
  };

  var setLocation = function () {
    var to_slide;
    to_slide = $(".carousel-item.active").attr("data-slide-no");
    $(".myCarousel-target.active").removeClass("active");
    $(".carousel-indicators [data-slide-to=" + to_slide + "]").addClass("active");
    window.location.hash = to_slide;
  };

  var slideToHash = function () {
    var initial_slide = parseInt(window.location.hash.substr(1)) || false;
    if(initial_slide){
      $("#prep-carousel").carousel(initial_slide);
      $(".myCarousel-target.active").removeClass("active");
      $(".carousel-indicators [data-slide-to=" + initial_slide + "]").addClass("active");
    };
  };

  var slideToMenuItem = function (event) {
    $(".carousel").carousel(parseInt($(this).attr("data-slide-to")));
    $(".myCarousel-target.active").removeClass("active");
    $(this).addClass("active");
  }

  var preventDef = function (e) {
    e.preventDefault();
  };

  var scrollToTop = function () {
    setTimeout(function() {
      $('html,body').animate({
        scrollTop: 0
      }, 300);
    }, 500);
  };

  var scrollToSubheading = function () {
    var target = $(this.hash);
    $('html,body').animate({
          scrollTop: (target.offset().top - 40)
    }, 500);
  };

  // CHECK BOXES FEATURE:
  var addCheckMarksToMenu = function () {
    // check course_prep_menu and place check marks if cookies exist
    $.each($(".carousel-indicators li"), function(){
      var panelName = $(this).attr("id");
      if ( getCookie(panelName) === "true" ) {
        addCheck(panelName);
        $(this).children(".check-box").tooltip("destroy");
      }
    })
  };

  // create new cookie on scroll to bottom of panel
  var createCookie = function () {
    var panelName = $(".carousel-indicators li.active").attr("id");

    //check scroll position to see if reached bottom
    if($(window).scrollTop() + $(window).height() > $(document).height() - 400) {
      if ( getCookie(panelName) != "true" ) {
        setCookie(panelName);
        addCheck(panelName);
      }
    }
  };

  var setCookie = function (panelName) {
    $.cookie( panelName, "true", { expires : 21 } );
  };

  var getCookie = function (panelName) {
    return $.cookie( panelName );
  };

  // add check mark if no check mark
  var addCheck = function (panelName) {
    var listItem = $(".carousel-indicators li#" + panelName);

    if ( listItem.children("i").length === 0 ) {
      $(".carousel-indicators li#" + panelName).append('<i class="fa fa-check"></i>');
    }
  };

  // Public Methods
  var initializeCarousel = function () {
    $("#prep-carousel").carousel({
      interval: false,
      pause: true
    })
  };

  return {
    init: init
  };
})();
