import styled from 'styled-components';

const StyledMessageModal = styled.div`
  max-height: 528px;
  min-height: 320px;
  display: flex;
  flex-grow: 1;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  ${({ isTextModal }) => isTextModal && 'justify-content: center;'}
`;

StyledMessageModal.displayName = 'StyledMessageModal';
export default StyledMessageModal;
