import styled from 'styled-components';

const StyledErrorMessage = styled.div`
  text-align: center;
  i {
    font-size: 3em;
    padding-top: 1em;
  }
  h1 {
    margin: 24px 0 70px;
    font-size: 16px;
  }
`;

StyledErrorMessage.displayName = 'StyledErrorMessage';

export default StyledErrorMessage;
