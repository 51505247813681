import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledButton from './styles';
import { uniqueId } from 'lodash';

const UploadButton = ({ handleChange, required }) => {
  const [id] = useState(() => uniqueId());

  return (
    <StyledButton>
      <label htmlFor={id}>
        <i className="ds-icon__cloud-upload"></i>
        <span className="ds-typography__body--bold">Upload File</span>
      </label>
      <input type="file" id={id} onChange={handleChange} required={required} />
    </StyledButton>
  );
};

UploadButton.propTypes = {
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default UploadButton;
