import BSG from '../../../components/Tabs/BSG';
import { connect } from 'react-redux';
import { ITabData, IUpdateAdmissionParams } from '../../../interfaces';
import { setSelectedSubTab, updateAdmission } from '../../../actions';

interface IState {
  afaAdmissions: {
    selectedSubTab: string;
    tabData: ITabData;
  };
}

export const mapStateToProps = ({
  afaAdmissions: { selectedSubTab, tabData },
}: IState) => ({
  selectedSubTab,
  tabData,
});

export const mapDispatchToProps = (dispatch: any) => ({
  setSelectedSubTab: (tab: string) => dispatch(setSelectedSubTab(tab)),
  updateAdmission: (params: IUpdateAdmissionParams, tab: string) =>
    dispatch(updateAdmission(params, tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BSG);
