import { ReactTooltipWrapper as DefaultWrapper } from './styles';
import React from 'react';
import ReactTooltip, { Effect, Place } from 'react-tooltip';
import { uniqueId } from 'lodash';
import { StyledComponent } from 'styled-components';

interface TooltipProps {
  content: string | React.ReactNode;
  effect?: Effect;
  disable?: boolean;
  place?: Place;
  backgroundColor?: string;
  customStyledComponentWrapper?: StyledComponent<any, any>;
  children: React.ReactElement;
}

const Tooltip: React.FC<TooltipProps> = ({
  content = '',
  effect = 'solid',
  disable = false,
  place = 'right',
  backgroundColor = 'var(--ds-color__indigo)',
  customStyledComponentWrapper = null,
  children,
}) => {
  const uniqueTooltipId = uniqueId('tooltip-');
  const ReactTooltipWrapper = customStyledComponentWrapper ?? DefaultWrapper;

  return (
    <>
      {React.cloneElement(children, {
        'data-for': uniqueTooltipId,
        'data-tip': '',
      })}
      <ReactTooltipWrapper>
        <ReactTooltip
          id={uniqueTooltipId}
          effect={effect}
          place={place}
          disable={disable}
          backgroundColor={backgroundColor}
        >
          {typeof content === 'string' ? (
            <span className='ds-typography__body--bold'>{content}</span>
          ) : (
            content
          )}
        </ReactTooltip>
      </ReactTooltipWrapper>
    </>
  );
};

export default Tooltip;
