import styled from 'styled-components';

const ScrollView = styled.div`
  @media (max-width: 768px) {
    overflow: auto;
    height: 70vh;
  }
`;

ScrollView.displayName = 'ScrollView';

export default ScrollView;
