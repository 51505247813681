import { Body, Button, Header } from '@careerfoundry/bubble';
import { ButtonWrapper, ModalContent } from '../ConfirmationModal/styles';
import React from 'react';
import TheModal from '../../../shared/components/TheModal';

interface IProps {
  handleClose: () => void;
  modalIsOpen: boolean;
}

const CompletionModal: React.FC<IProps> = ({ handleClose, modalIsOpen }) => (
  <TheModal handleClose={handleClose} isModalOpen={modalIsOpen}>
    <ModalContent>
      <Header tag='h4' variant='h4'>
        Congrats!
      </Header>
      <Body tag='p' variant='medium'>
        You completed the admissions process—so what happens next?
      </Body>
      <Body tag='p' variant='medium'>
        Once we’ve received your blank “Bildungsgutschein” we’ll have everything
        we need. From there, we’ll fill it out, send it to the Agentur für
        Arbeit or Jobcenter, then email your contract for signing. We will send
        you an update after returning the signed contract to let you know you’re
        enrolled! The whole process can take between 10–14 business days, so
        please be patient.
      </Body>
      <Body tag='p' variant='medium'>
        Best wishes, <br />
        Your Bildungsgutschein Team
      </Body>
      <ButtonWrapper>
        <Button
          label='OK'
          variant='primary'
          size='standard'
          onClick={handleClose}
        />
      </ButtonWrapper>
    </ModalContent>
  </TheModal>
);

export default CompletionModal;
