import styled from 'styled-components';

export const StyledHead = styled.div`
  margin-top: 24px;
  @media only screen and (max-width: 768px) {
    .ob-head {
      &__description {
        max-width: 369;
        margin: 24px auto;
      }
    }
  }
`;

export const HeadTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeadNumber = styled.div`
  width: 28px;
  height: 28px;
  border: 2px solid var(--ds-color__petrol);
  box-sizing: border-box;
  border-radius: 50%;
  color: var(--ds-color__petrol);
  line-height: 18px;
  font-size: 17px;
  padding: 6px 7px;
  font-weight: 700;
`;

export const HeadText = styled.div`
  margin-left: 16px;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--ds-color__indigo);
`;

export const HeadDescription = styled.div`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--ds-color__indigo);
  margin: 10px 0px;
`;

export const HeadSubtitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--ds-color__indigo);
`;
