import styled from 'styled-components';

const StyledInterviewGuidelines = styled.div`
  .button-section {
      margin-top: 50px;
      text-align: center;
  }
`;

StyledInterviewGuidelines.displayName = 'StyledInterviewGuidelines';

export default StyledInterviewGuidelines;
