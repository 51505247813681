import AddNotesModalContainer from '../../containers/AddNotesModalContainer';
import CallCanceledModal from './CallCanceledModal';
import EditNoteModalContainer from '../../../MentorCallNotesHistory/containers/EditNoteModalContainer';
import OffPlatformCallModalContainer from '../../containers/OffPlatformCallModalContainer';
import OffPlatformSuccessModalContainer from '../../containers/OffPlatformSuccessModalContainer';
import PropTypes from 'prop-types';
import QuitFlowModal from '../../../shared/components/SmallModals/QuitFlowModal';
import React from 'react';
import StyledModalWrapper from '../../../CareerSupportCenter/components/CareerSupportCenterModal/styles/StyledModalWrapper';
import TheModal from '../../../shared/components/TheModal';
import ViewNoteModalContainer from '../../../MentorCallNotesHistory/containers/ViewNoteModalContainer';

const MentorModals = ({
  isOffPlatformCall,
  handleCancel,
  handleCloseModal,
  modalIsOpen,
  modalAction,
  setModalAction,
}) => {
  const closeOrCancel = () => {
    isOffPlatformCall ? setModalAction('cancel') : handleCloseModal();
  };

  const quitModalDescription = isOffPlatformCall
    ? 'If you cancel now, the off-platform call will not be added.'
    : 'All your progress will be lost';

  return (
    <StyledModalWrapper
      isCallNote={true}
      isSmallModal={['offPlatformSuccess', 'cancel'].includes(modalAction)}
    >
      <TheModal handleClose={closeOrCancel} isModalOpen={modalIsOpen}>
        <>
          {
            {
              addNote: <AddNotesModalContainer />,
              callCanceled: <CallCanceledModal />,
              cancel: (
                <QuitFlowModal
                  handleConfirm={handleCloseModal}
                  handleCancel={handleCancel}
                  header='Are you sure?'
                  description={quitModalDescription}
                  confirmButtonText='Cancel anyway'
                  cancelButtonText='Back to call notes'
                />
              ),
              editNotes: <EditNoteModalContainer />,
              offPlatformCall: <OffPlatformCallModalContainer />,
              offPlatformSuccess: <OffPlatformSuccessModalContainer />,
              viewNotes: <ViewNoteModalContainer />,
            }[modalAction]
          }
        </>
      </TheModal>
    </StyledModalWrapper>
  );
};

MentorModals.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isOffPlatformCall: PropTypes.bool.isRequired,
  modalAction: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  setModalAction: PropTypes.func.isRequired,
};

export default MentorModals;
