import NoteFilters from '../../components/shared/NoteFilters';
import { connect } from 'react-redux';
import { setFilterParams } from '../../../MentorCallNotes/action';

export const mapStateToProps = ({ mentorCallNotes: { filterParams } }) => ({
  filterParams,
});

export const mapDispatchToProps = (dispatch) => ({
  setFilterParams: (filters) => dispatch(setFilterParams(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteFilters);
