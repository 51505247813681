import ButtonSection from './styles/Evaluation__ButtonSection';
import EvaluationAudio from './EvaluationAudio';
import EvaluationInfo from './styles/Info';
import EvaluationNotes from './EvaluationNotes';
import EvaluationQuestionTips from './EvaluationQuestionTips';
import EvaluationRating from './EvaluationRating';
import PropTypes from 'prop-types';
import React from 'react';

const EvaluationInfoContent = ({
  setReviewNavigable,
  currentAnswer,
  setReviewNote,
  setReviewRating,
  isModal,
  deleteAnswer,
  evaluateAnswer,
  setCurrentAnswer,
}) => {
  const handleDelete = (answerId) => {
    deleteAnswer(answerId);
  };

  const updateReview = (answerId, data) => {
    evaluateAnswer(answerId, data).then((res) => {
      setCurrentAnswer(res);
      setReviewNavigable(true);
    });
  };

  const saveReviewData = (data, type) => {
    setReviewNavigable(false);
    if (type === 'rating') {
      setReviewRating(data);
    } else {
      setReviewNote(data);
    }
    setReviewNavigable(true);
  };

  return (
    <EvaluationInfo isModal={isModal}>
      <EvaluationQuestionTips currentAnswer={currentAnswer} />
      <EvaluationAudio isModal={isModal} currentAnswer={currentAnswer} />
      {isModal && (
        <ButtonSection>
          <a onClick={() => window.confirm('Are you sure to delete this record?') ? handleDelete(currentAnswer.id) : undefined }
            className="ds-button__secondary text-center"
          >
            Delete answer
          </a>
        </ButtonSection>
      )}
      <div className="evaluation-section">
        <EvaluationRating
          currentAnswer={currentAnswer}
          isModal={isModal}
          saveReviewData={saveReviewData}
          updateReview={updateReview}
        />
        <EvaluationNotes
          currentAnswer={currentAnswer}
          isModal={isModal}
          saveReviewData={saveReviewData}
          updateReview={updateReview}
        />
      </div>
    </EvaluationInfo>
  );
};

EvaluationInfoContent.propTypes = {
  currentAnswer: PropTypes.object,
  deleteAnswer: PropTypes.func.isRequired,
  evaluateAnswer: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  setCurrentAnswer: PropTypes.func.isRequired,
  setReviewNavigable: PropTypes.func.isRequired,
  setReviewNote: PropTypes.func.isRequired,
  setReviewRating: PropTypes.func.isRequired,
};

export default EvaluationInfoContent;
