import styled from 'styled-components';

const Content = styled.div`
  padding: 32px;

  .ds-btn__secondary-error {
    color: var(--ds-color__int-border-secondary-negative);
    width: 75px;
  }
`;

Content.displayName = 'Content';

export { Content };
