export default (() => {
  const init = () => {
    $(document).on('dblclick', 'td.text-heavy', expandCell);
    $(document).on('dblclick', 'td.text-expand', collapseCell);
  };

  const collapseCell = (e) => {
    const targetCell = e.target;
    targetCell.classList.add('text-heavy');
    targetCell.classList.remove('text-expand');
  };

  const expandCell = (e) => {
    const targetCell = e.target;
    targetCell.classList.remove('text-heavy');
    targetCell.classList.add('text-expand');
  };

  return {
    init,
  };
})();
