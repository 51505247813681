import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import TopNavBarContainer from './containers/TopNavBar';
import store from '../shared/reduxStore';

const topNavBarContainer = document.getElementById('top-nav-bar');

if (topNavBarContainer) {
  const topNavBarContainerRoot = createRoot(topNavBarContainer);

  topNavBarContainerRoot.render(
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <TopNavBarContainer />
      </Suspense>
    </Provider>
  );
}
