import styled from 'styled-components';
import Card from '../../../InstructorOnboarding/InstructorProfile/styles/Card';

type ActiveProps = {
  active: boolean;
  isUnLocked?: boolean;
};

const List = styled.ul`
  list-style-type: square !important;
  margin-left: 10px;
  margin-bottom: 0px;
  li {
    display: list item !important;
    color: var(--ds-color__int-content);
  }
`;

const StatusContainer = styled.div`
  float: right !important;
  margin-left: auto;
  margin-right: 0;

  p {
    color: var(--ds-color__static-content-secondary);
  }

  i {
    color: var(--ds-color__static-content-secondary);
  }
`;

const SectionLink = styled.div<ActiveProps>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--ds-bg-color__static-tertiary-neutral);
  margin: 0 -32px;
  padding: 0 30px;
  height: 57px;
  border-left: ${(props) =>
    props.active && '8px solid var(--ds-color__int-content)'};
  padding-left: ${(props) => props.active && '22px'};
  pointer-events: ${(props) => (props.isUnLocked ? 'auto' : 'none')};
  cursor: pointer;

  p {
    margin-bottom: 0;
    display: inline-block;
    line-height: 1;
  }

  .warning {
    text-transform: uppercase;
    margin-left: 12px;
    font-size: 10px;
  }

  .pending {
    color: var(--ds-color__int-content);
  }

  .changes-requested {
    color: var(--ds-color__feedback-content-negative);
  }

  .completed-section {
    font-size: 14px;
    color: var(--ds-color__int-content);
  }

  &.steps-section-link {
    background: var(--ds-bg-color__static-secondary-neutral) !important;
    border-bottom: 0;
    height: 45px !important;
    padding: 0px 30px 0px 0px !important;
    box-shadow: -50px 0px 0px 0px var(--ds-bg-color__static-secondary-neutral);
  }
`;

const TabName = styled.p<ActiveProps>`
  color: ${(props) =>
    props.isUnLocked === false
      ? 'var(--ds-color__int-content-primary-disabled)'
      : props.active
      ? 'var(--ds-color__int-content)'
      : 'var(--ds-color__int-content-secondary)'};
`;

const StyledSidebar = styled.div`
  position: sticky;
  top: 78px;
`;

const StyledCard = styled(Card)`
  padding-bottom: 0px;
`;

TabName.displayName = 'TabName';
List.displayName = 'List';
StatusContainer.displayName = 'StatusContainer';
SectionLink.displayName = 'SectionLink';
StyledSidebar.displayName = 'Sidebar';
StyledCard.displayName = 'StyledCard';

export {
  List,
  StatusContainer,
  SectionLink,
  TabName,
  StyledSidebar,
  StyledCard,
};
