import PropTypes from 'prop-types';
import React from 'react';
import StyledInterviewGuidelines from './styles/StyledInterviewGuidelines';

const InterviewGuidelines = ({ buttonAction }) => (
  <StyledInterviewGuidelines>
    <h3 className="ds-typography__h3 text-left">Instructions and setup</h3>
    <ul>
      <li>You will receive 5 questions and will record answers to them in audio format.</li>
      <li>You will have 3 minutes to answer each question.</li>
      <li>For each answer you will have the option to pause and resume your recording.</li>
      <li>After each question you can either submit your answer and move on to the next question or try answering the question again.</li>
      <li>At the end of the interview training there will be an evaluation where you can review your answers, make notes, and rate your answers based on the insights received for each question.</li>
    </ul>
    <div className="button-section">
      <a onClick={() => buttonAction()} className="ds-button__primary text-center">Close Guidelines</a>
    </div>
  </StyledInterviewGuidelines>
);

InterviewGuidelines.propTypes = {
  buttonAction: PropTypes.func.isRequired,
};

export default InterviewGuidelines;
