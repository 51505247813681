import React, { useEffect, useState } from 'react';
import { startCase, without } from 'lodash';
import Checkbox from '../../../shared/components/Checkbox';
import MentorNoteFormStyles from './styles/MentorNoteFormStyles';
import PropTypes from 'prop-types';
import useDebounce from '../../../shared/hooks/useDebounce';

const MentorNoteForm = ({
  formAction,
  formData,
  handleCategoryChange,
  handleChange,
  handleClose,
  handleCreate,
  handleUpdate,
}) => {
  const noteCategories = [
    'concerns',
    'student_profile',
    'action_items_for_mentors',
    'interview_practice',
    'call_notes',
    'student_goals',
    'action_items_for_students',
    'job_search_advice',
  ];
  const [tempData, setTempData] = useState(formData);
  const debouncedTempData = useDebounce(tempData, 500);

  useEffect(() => {
    if (formData.categories !== tempData.categories) {
      handleCategoryChange(tempData.categories);
    } else if (formData !== tempData) {
      handleChange(tempData);
    }
  }, [debouncedTempData]);

  const handleTempData = ({ target: { name, checked, value } }) => {
    setTempData({
      ...tempData,
      [name]: name === 'shared' ? checked : value,
    });
  };

  const handleTempCategory = ({ target: { name, checked } }) => {
    setTempData({
      ...tempData,
      categories:
        checked && !tempData.categories.includes(name)
          ? [...tempData.categories, name]
          : without([...tempData.categories], name),
    });
  };

  const mentorNoteFormSubmit = (e) => {
    e.preventDefault();

    const data = {
      ...formData,
      title: formData.title || 'Generic Note',
    };

    formAction === 'Update' ? handleUpdate(data) : handleCreate(data);
  };

  return (
    <MentorNoteFormStyles onSubmit={mentorNoteFormSubmit}>
      <input
        type='text'
        value={tempData.title}
        placeholder='Add topic (default: Generic Note)'
        onChange={handleTempData}
        name='title'
      />
      <textarea
        type='text'
        value={tempData.content}
        placeholder='Add note'
        onChange={handleTempData}
        name='content'
        required
      ></textarea>
      <div>
        <div className='ds-slider'>
          <label>
            <input
              id='share'
              type='checkbox'
              value={tempData.shared}
              checked={tempData.shared}
              onChange={handleTempData}
              name='shared'
            />
            <span></span>
          </label>
        </div>
        <label htmlFor='share'>
          visible to student's tutor, mentor and career specialist
        </label>
      </div>
      <div className='note-categories'>
        <p>Note Labels</p>
        {noteCategories.map((category) => (
          <div className='category-checkbox' key={category}>
            <Checkbox
              defaultChecked={tempData.categories.includes(category)}
              onClick={handleTempCategory}
              name={category}
              labelText={startCase(category)}
            />
          </div>
        ))}
      </div>
      <div>
        <button
          className='ds-button ds-button__primary--inverse'
          onClick={handleClose}
        >
          Cancel
        </button>
        <button className='ds-button__primary' type='submit'>
          {formAction} note
        </button>
      </div>
    </MentorNoteFormStyles>
  );
};

MentorNoteForm.propTypes = {
  formAction: PropTypes.string,
  formData: PropTypes.shape({
    categories: PropTypes.array,
    content: PropTypes.string,
    shared: PropTypes.bool,
    title: PropTypes.string,
  }),
  handleCategoryChange: PropTypes.func,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleCreate: PropTypes.func,
  handleUpdate: PropTypes.func,
};

export default MentorNoteForm;
