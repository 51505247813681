import Card from '../App/styles/Card';
import JobApplicationPreviewHeader from './header';
import JobApplicationsTable from '../../../shared/components/JobApplicationsTable';
import MobileHeaderInfographic from '../../../JobApplicationTracker/components/MobileHeaderInfographic';
import PropTypes from 'prop-types';
import React from 'react';
import StyledParagraphWithLink from './styles/StyledParagraphWithLink';

const JobApplicationPreview = ({
  jobApplications,
  isMobile,
  handleNewJobApplication,
  handleUpdateJobApplication,
  handleViewJobApplication,
  shareJobApplication,
  getApplicationConsent,
  retrievingConsent,
  userId,
}) => {
  const handleClick = () => {
    if (shareJobApplication) {
      return handleNewJobApplication();
    }
    const hasApplications = jobApplications.length > 0;
    return getApplicationConsent(userId, hasApplications);
  };

  return (
    <>
      {isMobile ? (
        <Card>
          <MobileHeaderInfographic
            buttonLoading={retrievingConsent}
            onButtonClick={handleClick}
          />
        </Card>
      ) : (
        <>
          <JobApplicationPreviewHeader
            onClick={handleClick}
            retrievingConsent={retrievingConsent}
          />
          <JobApplicationsTable
            headers={[
              'date',
              { name: 'role', type: 'viewLink' },
              'status',
              'company',
            ]}
            jobApplications={jobApplications}
            handleViewJobApplication={handleViewJobApplication}
            handleUpdateJobApplication={handleUpdateJobApplication}
          />
          <StyledParagraphWithLink className="ds-typography__small text-left">
            Please note that your applications will be visible to your Career
            Specialist. For more information, refer to our&nbsp;
            <a
              href="https://careerfoundry.com/en/terms"
              className="ds-typography__anchor ds-typography__small"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </StyledParagraphWithLink>
        </>
      )}
    </>
  );
};

JobApplicationPreview.propTypes = {
  getApplicationConsent: PropTypes.func.isRequired,
  handleNewJobApplication: PropTypes.func.isRequired,
  handleUpdateJobApplication: PropTypes.func.isRequired,
  handleViewJobApplication: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  jobApplications: PropTypes.array.isRequired,
  retrievingConsent: PropTypes.bool.isRequired,
  shareJobApplication: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

export default JobApplicationPreview;
