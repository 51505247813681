import Payment from './Payment';
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LoadingSpinner from '../loadingSpinner';

interface StripePaymentProps {
  clientSecret: string;
  transactionId: number;
  startDate: string;
  productSku: string;
  userLocation: string;
}

interface Appearance {
  theme: 'stripe' | 'night' | 'flat' | undefined;
  variables?: {
    colorPrimary: string;
    colorText: string;
    fontFamily: string;
    borderRadius: string;
  };
  rules?: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

const stripeKey = document
  .querySelector('meta[name="stripe-key"]')
  ?.getAttribute('content');

const stripePromise = loadStripe(stripeKey || '');

const stripePayments = ({
  clientSecret,
  transactionId,
  startDate,
  productSku,
  userLocation,
}: StripePaymentProps) => {
  const appearance: Appearance = {
    theme: 'stripe',

    variables: {
      colorPrimary: '#0e1633',
      colorText: '#0e1633',
      fontFamily: 'TradeGothic',
      borderRadius: '4px',
    },
    rules: {
      '.Input': {
        border: '1px solid #a7b1b9',
        boxShadow: 'none',
        marginBottom: '-2px',
        fontSize: '16px',
        padding: '6px 8px',
        height: '32px',
        lineHeight: '18.4px',
      },
      '.Input--selected': {
        borderColor: '#a7b1b9',
        outline: '#46b0a7',
        boxShadow: 'none',
      },
      '.Input:focus': {
        borderColor: '#46b0a7',
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px #46b0a7',
      },
      '.Label': {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'TradeGothic-bold',
        marginBottom: '5px',
        marginTop: '4px',
        lineHeight: '1.5',
      },
      '.Input::placeholder': {
        fontSize: '14px',
        fontFamily: 'TradeGothic',
      },
    },
  };

  const fonts = [
    {
      family: 'TradeGothic',
      src: 'url(https://styleguide.careerfoundry.com/5f66dde8eb1fcec20849edacfca54d0e.woff2)',
    },
    {
      family: 'TradeGothic-bold',
      src: 'url(https://styleguide.careerfoundry.com/5290f36f954ec79e0afc994495cae031.woff2)',
    },
  ];

  const options = {
    clientSecret,
    appearance,
    fonts,
  };

  return (
    <div className='PaymentDetailsSlide enroll__slide regular__spinner'>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <Payment
            transactionId={transactionId}
            startDate={startDate}
            productSku={productSku}
            userLocation={userLocation}
          />
        </Elements>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default stripePayments;
