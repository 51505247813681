import styled from 'styled-components';

const ControlButton = styled.button`
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 8px 0 hsla(0, 0%, 39%, 0.14),
    0 3px 1px -2px hsla(0, 0%, 39%, 0.12), 0 1px 5px 0 hsla(0, 0%, 39%, 0.2);
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  transition: background-color 300ms;

  i {
    align-self: center;
    display: flex;
  }

  &:disabled {
    pointer-events: none;

    i {
      opacity: 0.2;
    }
  }
`;

ControlButton.displayName = 'ControlButton';

export default ControlButton;
