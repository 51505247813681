import ProfileDetailsViewContainer from '../../containers/ProfileDetailsViewContainer';
import React from 'react';
import StyledRowWrapper from '../../../EmployerConnector/modals/showModal/styles/StyledRowWrapper';

const PersonalData = () => (
  <StyledRowWrapper>
    <ProfileDetailsViewContainer />
  </StyledRowWrapper>
);

export default PersonalData;
