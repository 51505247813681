import React from 'react';

const LocationAndSalaryExpectations = () => (
  <>
    <p className='ds-typography__body break'>
      If you are looking to continue your business-specific education, here are some resources:
    </p>
    <ul>
      <li>
        <a onClick={() => window.open('https://youtube.com/playlist?list=PLzKJi2GjpkEEb7tzw-vExC72h8nQ75oIZ')}>
          Business School for Creatives (YouTube Playlist)
        </a>
      </li>
      <li>
        <a onClick={() => window.open('https://www.udemy.com/course/charge-higher-freelance-fees-with-niche-service-packages-1/')}>
          Free Freelancing Tutorial
        </a>
      </li>
      <li>
        <a onClick={() => window.open('https://www.facebook.com/business/resource?ref=alias')}>
          Meta Business Hub (Resources)
        </a>
      </li>
      <li>
        <a onClick={() => window.open('https://www.skillshare.com/browse')}>
          Skillshare Classes
        </a>
      </li>
      <li>
        <a onClick={() => window.open('https://www.sba.gov/')}>
          U.S. Small Business Administration (Resources, Training)
        </a>
      </li>
      <li>
        <a onClick={() => window.open('https://www.sba.gov/local-assistance/find')}>
          Free US Business Counseling
        </a>
      </li>
    </ul>
  </>
);

export default LocationAndSalaryExpectations;
