import styled from 'styled-components';

const StyledContentSection = styled.div`
  p,
  a {
    margin-top: 32px;
    font-size: 18px;
    color: var(--ds-color__indigo) !important;
  }

  padding: 0 20px 0 20px;

  @media (min-width: 767px) {
    margin-bottom: 48px;

    .button__section {
      text-align: left;
    }
    h5 {
      margin-top: 48px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;

    .button__section {
      text-align: center;
    }
    h5 {
      margin-top: 40px;
    }
  }
`;

export default StyledContentSection;
