module.exports = (() => {
  var init = function() {
    adminStepRatingsTable();
  };

  var adminStepRatingsTable = function() {
    $('.admin_step_ratings_data_table').DataTable({
      "paging": false,
      "info": false
    });
  };

  return {
    init: init
  };
})();
