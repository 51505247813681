import styled from 'styled-components';

const StyledAnswerCarousel = styled.div`
  position: relative;
  justify-content: start;
  width: 100%;
  margin: auto;

  @media (max-width: 768px) {
    justify-content: space-around;
  }

  .invisible {
    display: none;
  }

  .answer {
    margin-left: 10%;
    margin-right: 10%;
    width: 100%;
    margin: auto 56px;
    display: flex;
    justify-content: flex-start;
  }

  .grey {
    color: var(--ds-color__grey--light);
  }
  .clickable {
    cursor: pointer;
  }

  .right-arrow,
  .left-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    object-fit: contain;
    z-index: 2;
  }
  
  .right-arrow {
    right: 0;
  }
  .left-arrow {
    left: 0;
  }

  @media (max-width: 800px) {
    .answer {
      justify-content: center;
      margin: auto;
    }

    .right-arrow {
      right: 0;
    }
    .left-arrow {
      left: 0;
    }
  }
`;

StyledAnswerCarousel.displayName = 'StyledAnswerCarousel';

export default StyledAnswerCarousel;
