import React from 'react';
import PropTypes from 'prop-types';

const Form = props => (
  <form onSubmit={props.onSubmit}
        className={`cf-form ${props.className}`}
        id={props.id} >
    {props.children}
  </form>
);

Form.propTypes = {
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string
};

export default Form;
