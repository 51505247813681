import axios from 'axios';
import { keysToCamel } from '../utils/keysConverter';

/**
 * ApiService - encapsulate all axios methods used by other services
 * @param: - url - backend endpoint
 */

class ApiService {
  constructor (url) {
    this.url = url;
  };

  makeGetCall (params) {
    return axios.get(this.url, { params })
      .then(response => {
        return ({ data: keysToCamel(response.data), error: null });
      })
      .catch(error => {
        throw error.response.data;
      });
  };

  makePostCall (params, config = null) {
    return axios.post(this.url, params, config)
      .then(response => {
        return ({ data: keysToCamel(response.data), error: null });
      })
      .catch(error => {
        throw error.response.data;
      });
  };

  makePatchCall (params, config = null) {
    return axios.patch(this.url, params, config)
      .then(response => {
        return({ data: keysToCamel(response.data), error: null })
      })
      .catch(error => {
        throw error.response.data;
      });
  };

  makePutCall (params) {
    return axios.put(this.url, params)
      .then(response => {
        return ({ data: keysToCamel(response.data), error: null });
      })
      .catch(error => {
        throw error.response.data;
      });
  };

  makeDeleteCall (params) {
    return axios.delete(this.url, params)
      .then(response => {
        return ({ data: keysToCamel(response.data), error: null });
      })
      .catch(error => {
        return ({ data: null, error: error.response.data });
      });
  };
};

export default ApiService;
