import { Locale } from '../../shared/enums';

enum PageNames {
  MESSAGING = 'MESSAGING',
  NOTIFICATIONS = 'NOTIFICATIONS',
}

interface Params {
  pageName: PageNames;
  locale?: Locale;
}

const getPathnameFromPageName = ({
  pageName,
  locale = Locale.en,
}: Params): string => {
  const pageNamesToPathnames = {
    MESSAGING: `/${locale}/messaging`,
    NOTIFICATIONS: `/${locale}/all_notifications`,
  };

  return pageNamesToPathnames[pageName];
};

export { getPathnameFromPageName, PageNames };
