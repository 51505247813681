import React from 'react';

const FinalStage = () => (
  <>
    <p className="ds-typography__body--bold">Celebrate your success</p>
    <p className="ds-typography__body">Oftentimes, we think that we only deserve a treat or some kind of recognition when we’ve achieved our main goal. However, it’s crucial that we celebrate our successes along the way! In fact, studies have shown that workers who practice affirmations and take breaks are ultimately more efficient and successful! Remember: It’s all too easy to get stuck into dwelling on the negative, which can decrease motivation, productivity, and satisfaction. With that in mind, consider the following:</p>
    <ul className="break">
      <li>What are my major milestones on this project?</li>
      <li>How will I celebrate these milestones?</li>
    </ul>
    <p className="ds-typography__body--bold">Reflection and improvement</p>
    <p className="ds-typography__body break">Once you've completed your project, it’s helpful to reflect on your work. What are the things you're most proud of and what would you do differently in the future? What are your key takeaways? You'll also want to think about how you want to improve your project in the future. If necessary, come up with concrete steps and a timeline with the improvements you want to make. Iterating your work will show that you're continuously striving to improve and develop—a great quality for designers to have!</p>
    <p className="ds-typography__body--bold">Finally: Show off your skills!</p>
    <p className="ds-typography__body">Once you're happy with your work, it's time to show the world what you've accomplished! If you want to add your project to your portfolio, remember to think about who might be interested in this project. How can you present this project as a marketing tool? How does it fill a previously-existing gap in your experience or the scope of your brand? Start thinking about how you’ll pitch this in design interviews, cover letters, and networking events now: this will help you organize your presentation for your portfolio.</p>
  </>
);

export default FinalStage;
