import styled from 'styled-components';

const StyledHeader = styled.div`
  h2 {
    margin-bottom: 32px;
  }
`;

StyledHeader.displayName = 'StyledHeader';

export { StyledHeader };
