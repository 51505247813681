import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 70px;
    position: relative;

    h4 {
      font-size: 24px;
      width: 100%;
      margin: 0 0 16px;
      padding: 0;
      line-height: 1.5;
    }

    hr {
      padding: 0;
      margin-top: 0;
      border: 0;
      border-bottom: solid 2px #c5c8c9;
    }

    .button-section {
      border-top: solid 2px #c5c8c9;
      button {
        margin: 24px 0px;
        width: 90%;
      }
    }
  }
`;

StyledWrapper.displayName = 'StyledWrapper';

export default StyledWrapper;
