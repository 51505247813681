import axios from 'axios'

class PaymentDetailsService {
  constructor(authToken) {
    this.authToken = authToken
  }

  postPaymentDetails(attendeePlan, startDate, offerCode, discountCode, productSku){
    let data = {
      authenticity_token: this.authToken,
      attendee_plan: attendeePlan,
      start_date: startDate,
      offer_code: offerCode,
      discount_code: discountCode,
      product_sku: productSku
    };

    return axios.post('/enroll/payment_plan.json', data).then((response) => {
      return({ data: response.data, error: null })
    }).catch((error) => {
      return({ data: null, error: error.response.data })
    })
  }
}
export default PaymentDetailsService
