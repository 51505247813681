import {
  ButtonsWrapper,
  CurriculumCourse,
  CurriculumCourseContent,
  CurriculumCourseTitle,
  CurriculumWrapper,
} from './styles';
import CourseProgress from '../../components/CourseProgress';
import Head from '../../components/Head';
import NavButtons from '../../components/NavButtons';
import PropTypes from 'prop-types';
import React from 'react';
import { generateTimelineItems } from '../../utils/helpers';
const CurriculumPage = ({ curriculumStructure, data, navigateTo }) => {
  let introCourse, coreCourse, immersionCourse, specializationCourse;

  curriculumStructure.curriculumStructure.courses &&
    curriculumStructure.curriculumStructure.courses.forEach((course) => {
      switch (course.courseCategory) {
        case 'Intro':
          introCourse = course;
          break;
        case 'Immersion':
          immersionCourse = course;
          break;
        case 'specialization':
          specializationCourse = course;
          break;
        case null:
          coreCourse = course;
          break;
      }
    });

  const headInfo = { ...data.head };
  headInfo.description =
    curriculumStructure.type !== 'course' &&
    curriculumStructure.curriculumStructure.curriculum;

  return (
    <CurriculumWrapper>
      <Head data={headInfo} />
      {
        <CourseProgress
          items={generateTimelineItems(curriculumStructure.timeline)}
        />
      }
      {curriculumStructure.type === 'course' && (
        <CurriculumCourseContent
          className='ds-typography__uber'
          role='pageTitle'
        >
          {curriculumStructure.curriculumStructure.curriculum}
        </CurriculumCourseContent>
      )}
      {introCourse && (
        <CurriculumCourse className='ds-typography__body' role='introCourse'>
          <CurriculumCourseTitle className='ds-typography__body'>
            {introCourse.name}
          </CurriculumCourseTitle>
          <CurriculumCourseContent className='ds-typography__body'>
            {introCourse.curriculum}
          </CurriculumCourseContent>
        </CurriculumCourse>
      )}
      {coreCourse && (
        <CurriculumCourse className='ds-typography__body' role='coreCourse'>
          <CurriculumCourseTitle className='ds-typography__body'>
            {coreCourse.name}
          </CurriculumCourseTitle>
          <CurriculumCourseContent className='ds-typography__body'>
            {coreCourse.curriculum}
          </CurriculumCourseContent>
        </CurriculumCourse>
      )}
      {immersionCourse && (
        <CurriculumCourse className='ds-typography__body' role='coreCourse'>
          <CurriculumCourseTitle className='ds-typography__body'>
            {immersionCourse.name}
          </CurriculumCourseTitle>
          <CurriculumCourseContent className='ds-typography__body'>
            {immersionCourse.curriculum}
          </CurriculumCourseContent>
        </CurriculumCourse>
      )}
      {specializationCourse && (
        <CurriculumCourse className='ds-typography__body' role='specialties'>
          <CurriculumCourseTitle className='ds-typography__body'>
            {specializationCourse.name}
          </CurriculumCourseTitle>
          <CurriculumCourseContent className='ds-typography__body'>
            {specializationCourse.curriculum}
          </CurriculumCourseContent>
        </CurriculumCourse>
      )}
      <ButtonsWrapper>
        <NavButtons
          onClickNext={() => navigateTo('#team', 'next')}
          onClickBack={() => navigateTo('back')}
          nextButtonText='Got it'
        />
      </ButtonsWrapper>
    </CurriculumWrapper>
  );
};

CurriculumPage.propTypes = {
  curriculumStructure: PropTypes.object,
  data: PropTypes.object,
  navigateTo: PropTypes.func,
};
export default CurriculumPage;
