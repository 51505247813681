import PropTypes from 'prop-types';
import React from 'react';
import StyledToggleSection from './styles/StyledToggleSection';
import ToggleSwitch from '../../../CareerSupportCenter/components/ToggleSwitch';

const ToggleSection = ({
  isChecked,
  onChange,
  readOnly = false,
  className = '',
  label = '',
  name = '',
}) => {
  return (
    <StyledToggleSection className={className}>
      <ToggleSwitch onChange={onChange} checked={isChecked || false} name={name} readOnly={readOnly}/>
      <p className="ds-typography__body">{label}</p>
    </StyledToggleSection>
  );
};

ToggleSection.propTypes = {
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default ToggleSection;
