import React, { Component } from 'react';
import { getAuthToken } from '../../utils/getAuthToken';
import EnrollmentLinkService from '../../services/EnrollmentLinkService';

class SentLinkOrManualEnrolled extends Component {
  constructor(props) {
    super(props);
    this.enrollmentLinkService = new EnrollmentLinkService();
  }

  redirectToManualEnrollUrl = () => {
    return `/en/admin/enrollment_links/${this.props.link.id}/manual_user_enrollments`;
  };

  markAsLinkSent = () => {
    this.enrollmentLinkService
      .updateLink(this.props.link.id, 'link_sent', getAuthToken())
      .then((res) => {
        location.reload();
      });
  };

  render() {
    return (
      <div>
        <a onClick={() => this.markAsLinkSent()} href="#">
          LinkSent
        </a>
        <br />
        <a
          href={`/en/admin/enrollment_links/${this.props.link.id}/manual_user_enrollments`}
        >
          ManualEnroll
        </a>
      </div>
    );
  }
}

export default SentLinkOrManualEnrolled;
