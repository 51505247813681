import {
  appendNewInterviewAnswer,
  deleteAnswer,
  groupInterviewAnswers,
  replaceAnswersForReview,
  replaceEvaluatedResponse,
} from './helpers';
import { constants } from './constants';

export const initialState = {
  answersForReview: [],
  currentAnswer: null,
  entryPoint: 'interviewPrepDashboard',
  interviewAnswers: [],
  interviewId: null,
  interviewStage: '',
  isReviewNavigable: true,
  loading: true,
  modalAction: 'quit',
  modalIsOpen: false,
  reviewNote: null,
  reviewRating: null,
  selectedCategory: null,
  shareWithMentor: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.GET_INTERVIEW_ANSWERS_SUCCESS:
      return {
        ...state,
        interviewAnswers: groupInterviewAnswers(action.data),
        loading: false,
      };

    case constants.SET_SHARE_WITH_MENTOR:
      return {
        ...state,
        shareWithMentor: action.data,
      };

    case constants.START_INTERVIEW:
      return {
        ...state,
        answersForReview: [],
        entryPoint: action.data,
        interviewStage: 'start',
        modalAction: 'quit',
      };
    case constants.SET_INTERVIEW_STAGE:
      return {
        ...state,
        answersForReview: [],
        interviewStage: action.data,
        modalAction: 'quit',
      };
    case constants.START_REVIEW:
      return {
        ...state,
        interviewStage: 'review',
        modalAction: 'quitEvaluation',
        modalIsOpen: false,
      };

    case constants.FINISH_INTERVIEW:
      return {
        ...state,
        interviewId: null,
        interviewStage: '',
        modalIsOpen: false,
        questions: [],
      };

    case constants.HANDLE_BACK:
      return {
        ...state,
        modalIsOpen: true,
      };

    case constants.OPEN_INTERVIEW_GUIDELINES:
      return {
        ...state,
        modalAction: 'interviewGuidelines',
        modalIsOpen: true,
      };

    case constants.OPEN_QUESTION_INSIGHTS:
      return {
        ...state,
        modalAction: 'questionInsights',
        modalIsOpen: true,
        selectedInsight: {
          insights: action.insights,
          question: action.question,
        },
      };

    case constants.HANDLE_CLOSE_MODAL:
      return {
        ...state,
        modalIsOpen: false,
      };

    case constants.GET_INTERVIEW_QUESTIONS_SUCCESS:
      return {
        ...state,
        interviewId: action.data.interview.id,
        interviewStage: 'practice',
        questions: action.data.questions,
        selectedCategory: action.data.category,
      };

    case constants.ANSWER_QUESTION_SUCCESS: {
      const lastQuestion =
        state.questions.length === state.answersForReview.length + 1;
      const newInterviewAnswersState = appendNewInterviewAnswer(
        action.data,
        state.interviewAnswers
      );
      return {
        ...state,
        answersForReview: [...state.answersForReview, action.data],
        interviewAnswers: newInterviewAnswersState,
        modalAction: lastQuestion ? 'startReview' : 'quit',
        modalIsOpen: lastQuestion,
      };
    }

    case constants.EVALUATE_ANSWER_SUCCESS: {
      const clonedArr = [...state.interviewAnswers];
      const newAnswersForReviewState = replaceAnswersForReview(action.data, [
        ...state.answersForReview,
      ]);
      const newInterviewAnswersState = replaceEvaluatedResponse(
        action.data,
        clonedArr
      );
      return {
        ...state,
        answersForReview: newAnswersForReviewState,
        interviewAnswers: newInterviewAnswersState,
        reviewNote: null,
        reviewRating: null,
      };
    }

    case constants.DELETE_ANSWER_SUCCESS: {
      const clonedArr = [...state.interviewAnswers];
      const newInterviewAnswersState = deleteAnswer(action.data, clonedArr);
      return {
        ...state,
        interviewAnswers: newInterviewAnswersState,
        modalIsOpen: false,
      };
    }

    case constants.EVALUATE_PREVIOUS_RESPONSES: {
      return {
        ...state,
        answersForReview: [action.data],
        modalAction: 'evaluation',
        modalIsOpen: true,
      };
    }

    case constants.CLOSE_EVALUATION: {
      return {
        ...state,
        answersForReview: [],
        modalIsOpen: false,
      };
    }

    case constants.SET_CURRENT_ANSWER: {
      const { currentAnswer } = action;
      return {
        ...state,
        currentAnswer,
      };
    }

    case constants.SET_REVIEW_NOTE: {
      const { reviewNote } = action;
      return {
        ...state,
        reviewNote,
      };
    }

    case constants.SET_REVIEW_RATING: {
      const { reviewRating } = action;
      return {
        ...state,
        reviewRating,
      };
    }

    case constants.SET_REVIEW_NAVIGABLE: {
      const { isReviewNavigable } = action;
      return {
        ...state,
        isReviewNavigable,
      };
    }

    default:
      return state;
  }
}
