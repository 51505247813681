import React from 'react';
import { TextField } from '@careerfoundry/bubble';
import { noop } from 'lodash';
import { constants } from './constants';

export interface SubmissionUrlFieldProps {
  name: string;
  placeholder: string;
  label: string;
  value: string;
  errors: {
    project_url?: string;
    source_url?: string;
  };
}

const SubmissionUrlField: React.FC<SubmissionUrlFieldProps> = ({
  name,
  placeholder,
  label,
  value,
  errors,
}) => {
  let errorMessage;
  switch (true) {
    case 'project_url' in errors:
      errorMessage = constants.projectURLError;
      break;
    case 'source_url' in errors:
      errorMessage = constants.sourceURLError;
  }

  return (
    <TextField
      id={name}
      name={name}
      placeholder={placeholder}
      label={label}
      errorMessage={errorMessage}
      defaultValue={value}
      onChange={noop}
    />
  );
};

export default SubmissionUrlField;
