import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const currentExerciseCtaContainer = document.getElementById(
  'current-exercise-cta'
);

if (currentExerciseCtaContainer) {
  const CurrentExerciseCta = lazy(() =>
    import('./containers/CurrentExerciseCta')
  );
  const currentExerciseCtaContainerRoot = createRoot(
    currentExerciseCtaContainer
  );

  currentExerciseCtaContainerRoot.render(
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <CurrentExerciseCta />
      </Suspense>
    </Provider>
  );
}
