import styled from 'styled-components';

const StyledUploader = styled.div`
  input {
    display: inline;
    width: 75%;
    margin-left: 5%;
    height: fit-content;
  }
  display: flex;
`;

StyledUploader.displayName = 'StyledUploader';

export default StyledUploader;
