import styled from 'styled-components';

const StyledSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  h4 {
    display: inline-block;
    font-weight: 400 !important;
    line-height: 40px;
  }

  a {
    width: 204px;
    justify-content: center;
    height: 48px;
    display: flex;
    align-items: center;
    line-height: unset;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
    h4 {
      font-size: 24px;
      font-weight: 400 !important;
      line-height: 40px;
      margin-bottom: 10px;
    }
  }

  @media only screen and (min-width: 768px) {
    h4 {
      margin-bottom: 0;
    }
  }

  .inline-button {
    float: right;
  }
`;

StyledSectionHeader.displayName = 'StyledSectionHeader';

export default StyledSectionHeader;
