import Sidebar from '../../components/Sidebar';
import { connect } from 'react-redux';
import { setSelectedCourse } from '../../actions';

export const mapStateToProps = ({
  userProfile: { courses, selectedCourse },
}) => ({
  attendees: courses?.courseCards?.attendees,
  programAttendees: courses?.courseCards?.programAttendees,
  selectedCourse,
});

export const mapDispatchToProps = (dispatch) => ({
  setSelectedCourse: (courseId, cardState) =>
    dispatch(setSelectedCourse(courseId, cardState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
