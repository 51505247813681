import axios from 'axios';
import { getAuthToken } from '../../../utils/getAuthToken';

const API_VERSION = 'v1';
const API_ADMIN_INSTRUCTORS_CANDIDATES_URL = `/api/${API_VERSION}/admin/instructors/candidates`;

export const GET_CANDIDATES_SUCCESS =
  'InstructorsCandidatesPage.GET_CANDIDATES_SUCCESS';
export const GET_INITIAL_DATA_SUCCESS =
  'InstructorsCandidatesPage.GET_INITIAL_DATA_SUCCESS';
export const SHOW_CANDIDATE_SUCCESS =
  'InstructorsCandidatesPage.SHOW_CANDIDATE_SUCCESS';
export const UPDATE_CANDIDATE_SUCCESS =
  'InstructorsCandidatesPage.UPDATE_CANDIDATE_SUCCESS';
export const CREATE_CANDIDATE_SUCCESS =
  'InstructorsCandidatesPage.CREATE_CANDIDATE_SUCCESS';
export const INIT_CANDIDATE_FORM_SUCCESS =
  'InstructorsCandidatesPage.INIT_CANDIDATE_FORM_SUCCESS';
export const WELCOME_EMAIL_SENT =
  'InstructorsCandidatesPage.WELCOME_EMAIL_SENT';
export const OPEN_MODAL = 'InstructorsCandidatesPage.OPEN_MODAL';
export const CLOSE_MODAL = 'InstructorsCandidatesPage.CLOSE_MODAL';
export const CANDIDATE_ACTIVATION_SUCCESS =
  'InstructorsCandidatesPage.CANDIDATE_ACTIVATION_SUCCESS';
export const CANDIDATE_ACTIVATION_FAILURE =
  'InstructorsCandidatesPage.CANDIDATE_ACTIVATION_FAILURE';
export const LOADING_STATE = 'InstructorsCandidatesPage.LOADING_STATE';
export const CONTRACT_CHECKBOX = 'InstructorsCandidatesPage.CONTRACT_CHECKBOX';
export const UPDATE_CONTRACT_STATUS_SUCCESS =
  'InstructorsCandidatesPage.UPDATE_CONTRACT_STATUS_SUCCESS';
export const CLEAR_CANDIDATE = 'InstructorsCandidatesPage.CLEAR_CANDIDATE';
export const DELETE_CANDIDATE_SUCCESS =
  'InstructorsCandidatesPage.DELETE_CANDIDATE_SUCCESS';
export const DELETE_CANDIDATE_FAILURE =
  'InstructorsCandidatesPage.DELETE_CANDIDATE_FAILURE';

export const getCandidates = () => async (dispatch) => {
  try {
    const candidates = await axios.get(API_ADMIN_INSTRUCTORS_CANDIDATES_URL);

    dispatch({
      candidates: candidates.data,
      type: GET_CANDIDATES_SUCCESS,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getInitialData = () => async (dispatch) => {
  try {
    const statesResponse = await axios.get(
      `${API_ADMIN_INSTRUCTORS_CANDIDATES_URL}/states`
    );
    const interviewersResponse = await axios.get(
      `${API_ADMIN_INSTRUCTORS_CANDIDATES_URL}/interviewers`
    );

    dispatch({
      candidateStates: statesResponse.data.all_states,
      interviewers: interviewersResponse.data.interviewers,
      type: GET_INITIAL_DATA_SUCCESS,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCandidate =
  ({ candidateId }) =>
  async (dispatch) => {
    dispatch({
      type: CLEAR_CANDIDATE,
    });

    try {
      const { data } = await axios.get(
        `${API_ADMIN_INSTRUCTORS_CANDIDATES_URL}/${candidateId}`,
        {
          authenticity_token: getAuthToken(),
        }
      );
      dispatch({ data, type: SHOW_CANDIDATE_SUCCESS });
    } catch (e) {
      console.log(e);
    }
  };

export const updateCandidate =
  ({ candidateId, payload }) =>
  async (dispatch) => {
    try {
      const patchData = {
        authenticity_token: getAuthToken(),
        instructor_candidate: payload,
      };
      const { data } = await axios.patch(
        `${API_ADMIN_INSTRUCTORS_CANDIDATES_URL}/${candidateId}`,
        patchData
      );
      dispatch({ data, type: UPDATE_CANDIDATE_SUCCESS });
      return data;
    } catch (e) {
      console.log(e);
    }
  };

export const updateContractStatus =
  (candidateId, payload) => async (dispatch) => {
    dispatch({
      type: LOADING_STATE,
    });

    try {
      const patchData = {
        authenticity_token: getAuthToken(),
        instructor_candidate: payload,
      };
      const { data } = await axios.patch(
        `${API_ADMIN_INSTRUCTORS_CANDIDATES_URL}/${candidateId}`,
        patchData
      );
      dispatch({
        data,
        type: UPDATE_CONTRACT_STATUS_SUCCESS,
      });
    } catch (e) {
      console.log(e);
    }
  };

export const createCandidate =
  ({ payload }) =>
  async (dispatch) => {
    try {
      const postData = {
        authenticity_token: getAuthToken(),
        instructor_candidate: payload,
      };
      const { data } = await axios.post(
        `${API_ADMIN_INSTRUCTORS_CANDIDATES_URL}/`,
        postData
      );
      dispatch({ data, type: CREATE_CANDIDATE_SUCCESS });
      return data;
    } catch (e) {
      console.log(e);
    }
  };

export const initCandidateForm = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${API_ADMIN_INSTRUCTORS_CANDIDATES_URL}/form`,
      {
        authenticity_token: getAuthToken(),
      }
    );
    dispatch({ data, type: INIT_CANDIDATE_FORM_SUCCESS });
  } catch (e) {
    console.log(e);
  }
};

export const sendWelcomeEmail = (id) => async (dispatch) => {
  try {
    const postData = { authenticity_token: getAuthToken() };
    const { data } = await axios.post(
      `${API_ADMIN_INSTRUCTORS_CANDIDATES_URL}/${id}/send_welcome_email`,
      postData
    );
    dispatch({
      data,
      type: WELCOME_EMAIL_SENT,
    });
  } catch (e) {
    console.log(e);
  }
};

export const handleOpen = (modalAction) => ({
  modalAction,
  type: OPEN_MODAL,
});

export const handleClose = () => ({
  type: CLOSE_MODAL,
});

export const handleContractCheckbox = (modalAction) => ({
  modalAction,
  type: CONTRACT_CHECKBOX,
});

export const handleActivate = (candidateId) => async (dispatch) => {
  dispatch({
    type: LOADING_STATE,
  });

  try {
    const postData = { authenticity_token: getAuthToken() };
    const { data } = await axios.post(
      `${API_ADMIN_INSTRUCTORS_CANDIDATES_URL}/${candidateId}/activate`,
      postData
    );
    dispatch({
      data,
      type: CANDIDATE_ACTIVATION_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error,
      type: CANDIDATE_ACTIVATION_FAILURE,
    });
  }
};

export const handleDelete = (candidateId) => async (dispatch) => {
  dispatch({
    type: LOADING_STATE,
  });

  try {
    const postData = { authenticity_token: getAuthToken() };
    await axios.post(
      `${API_ADMIN_INSTRUCTORS_CANDIDATES_URL}/${candidateId}/anonymize`,
      postData
    );
    dispatch({
      id: candidateId,
      type: DELETE_CANDIDATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: error.response.data,
      type: DELETE_CANDIDATE_FAILURE,
    });
  }
};

const fetchSurveyResult = async (formId, responseId) => {
  const response = await axios.get(
    `/api/v1/surveys/${formId}/responses/${responseId}`
  );
  return response.data.data.length && response.data.data[0].calculated.score;
};

export const updateSurveyResult =
  (formId, responseId, candidateId, currentScore) => async (dispatch) => {
    try {
      const score = await fetchSurveyResult(formId, responseId);

      if (score !== currentScore) {
        const patchData = {
          authenticity_token: getAuthToken(),
          instructor_candidate: {
            survey_score: score,
          },
        };
        const { data } = await axios.patch(
          `${API_ADMIN_INSTRUCTORS_CANDIDATES_URL}/${candidateId}`,
          patchData
        );
        dispatch({
          data,
          type: UPDATE_CANDIDATE_SUCCESS,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
