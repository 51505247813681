import React from 'react';
import StyledAudioLoader from './styles/StyledAudioLoader';

const AudioLoader = () => (
  <StyledAudioLoader>
    <i className="fa fa-spinner fa-3x"></i>
    <p className="ds-typography__body">Your recording is being saved…</p>
  </StyledAudioLoader>
);

export default AudioLoader;
