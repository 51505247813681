import { MOBILE_VIEW_MAX_WIDTH } from '../../constants';
import styled from 'styled-components';

const ArrowIconWidth = '1rem';
const ProfilePhotoWidth = '2rem';
const ProfilePhotoWrapperMarginLeft = '1rem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.25rem;
  padding: 0 1rem 1rem;

  @media (min-width: ${MOBILE_VIEW_MAX_WIDTH + 1}px) {
    display: none;
  }
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: start;
  cursor: pointer;
`;

const ArrowIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: ${ArrowIconWidth};
    fill: var(--ds-color__white);
    transform: ${(props) =>
      props.shouldShowOptions ? 'none' : 'rotate(0.5turn)'};
  }
`;

const ProfilePhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${ProfilePhotoWrapperMarginLeft};

  img {
    width: ${ProfilePhotoWidth};
    border-radius: 50%;
  }
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  color: var(--ds-color__white);
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    margin-top: 1.5rem;
  }

  > div:last-child {
    margin-bottom: 0;
  }
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  margin: 0.75rem 0 0.75rem ${ArrowIconWidth};
  color: var(--ds-color__white);
  cursor: pointer;
`;

const OptionIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${ProfilePhotoWrapperMarginLeft};
  width: ${ProfilePhotoWidth};

  svg {
    width: ${ArrowIconWidth};
    fill: var(--ds-color__white);
  }
`;

export {
  ArrowIconWrapper,
  Name,
  Wrapper,
  ProfilePhotoWrapper,
  Options,
  Option,
  UserInfo,
  OptionIconWrapper,
};
