import Form from '../../components/Form';
import { connect } from 'react-redux';
import { createTimeOffRequest } from '../../actions';

export const mapStateToProps = ({ timeOff: { reasons } }) => ({
  reasons,
});

export const mapDispatchToProps = (dispatch) => ({
  createTimeOffRequest: (values) => dispatch(createTimeOffRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
