import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import CourseInfo from './CourseInfo';
import CourseTile from './CourseTile';
import { ProgramCourses } from './styles/ProgramCourses';
import PropTypes from 'prop-types';
import React from 'react';
import SidebarStyles from './styles/Sidebar';
import StyledAccordion from './styles/Accordion';
import { isCourseAccessible } from '../CoursesTab/helpers';

const Sidebar = ({
  attendees,
  programAttendees = [],
  selectedCourse,
  setSelectedCourse,
}) => {
  const selectedProgramAttendeeIndex = programAttendees.findIndex(
    (programAttendee) =>
      programAttendee.attendees.find(
        (attendee) => attendee.courseId === selectedCourse
      )
  );

  return (
    <SidebarStyles
      className='ds-grid__col-lg-3'
      totalProgramAttendees={programAttendees.length}
    >
      {programAttendees &&
        programAttendees.map((programAttendee, index) => (
          <StyledAccordion
            key={programAttendee.name}
            isAccessible={isCourseAccessible(programAttendee.state)}
          >
            <Accordion
              allowZeroExpanded
              preExpanded={[
                selectedProgramAttendeeIndex === index
                  ? `programAttendee-${index}`
                  : '',
              ]}
            >
              <AccordionItem uuid={`programAttendee-${index}`}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h4 className='ds-typography__h4'>
                      {programAttendee.name}
                    </h4>
                    {isCourseAccessible(programAttendee.state) && (
                      <CourseInfo
                        state={programAttendee.state}
                        date={
                          programAttendee.graduationDate ||
                          programAttendee.targetCompletion
                        }
                      />
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                {isCourseAccessible(programAttendee.state) && (
                  <AccordionItemPanel>
                    <ProgramCourses>
                      {programAttendee.attendees.map((attendee) => (
                        <CourseTile
                          key={attendee.name}
                          courseId={attendee.courseId}
                          name={attendee.name}
                          isCourseUnderProgram={true}
                          setSelectedCourse={setSelectedCourse}
                          selectedCourse={selectedCourse}
                          state={attendee.state}
                        />
                      ))}
                    </ProgramCourses>
                  </AccordionItemPanel>
                )}
              </AccordionItem>
            </Accordion>
          </StyledAccordion>
        ))}
      <div className='StandaloneCourseTiles'>
        {attendees &&
          attendees.map((attendee) => (
            <CourseTile
              key={attendee.name}
              courseId={attendee.courseId}
              date={attendee.graduationDate || attendee.targetCompletion}
              name={attendee.name}
              setSelectedCourse={setSelectedCourse}
              selectedCourse={selectedCourse}
              state={attendee.state}
            />
          ))}
      </div>
    </SidebarStyles>
  );
};

Sidebar.propTypes = {
  attendees: PropTypes.arrayOf(
    PropTypes.shape({
      course_id: PropTypes.number,
      graduationDate: PropTypes.string,
      name: PropTypes.string.isRequired,
      state: PropTypes.string,
    })
  ),
  programAttendees: PropTypes.arrayOf(
    PropTypes.shape({
      attendees: PropTypes.arrayOf(
        PropTypes.shape({
          course_id: PropTypes.number,
          graduation_date: PropTypes.string,
          icon: PropTypes.string,
          id: PropTypes.number,
          mentorCallText: PropTypes.string,
          name: PropTypes.string,
          progress: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
          state: PropTypes.string,
          subtitle: PropTypes.string,
          targetCompletion: PropTypes.string,
          type: PropTypes.string,
          waitingDescription: PropTypes.string,
        })
      ),
      graduationDate: PropTypes.string,
      name: PropTypes.string.isRequired,
      state: PropTypes.string,
      targetCompletion: PropTypes.string,
    })
  ),
  selectedCourse: PropTypes.number,
  setSelectedCourse: PropTypes.func.isRequired,
};

export default Sidebar;
