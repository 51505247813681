import styled from 'styled-components';

const MentorNoteStyles = styled.div`
  display: flex;
  background-color: #eef0f1;
  padding: 24px 30px 32px 30px;
  margin-bottom: 40px;
  border-left: ${({ owner }) => owner && '5px solid var(--ds-color__petrol)'};

  & > div:first-of-type {
    text-align: center;
    padding-right: 56px;
    width: 160px;

    img {
      width: 64px;
      height: auto;
      margin-bottom: 10px;
    }

    p:first-of-type {
      margin-bottom: 8px;
    }

    p:last-of-type {
      margin-right: 0;
    }
  }

  & > div:last-of-type {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    & > p:nth-of-type(2) {
      flex-grow: 1;
    }

    .visible-label {
      position: absolute;
      right: 0;
      font-size: 11px;
      color: #757b81;
    }

    .edited-label {
      margin-left: 8px;
      color: var(--ds-color__indigo)
    }

    a {
      text-decoration: underline;
      margin-left: 10px;
    }

    div {
      display: flex;
      justify-content: space-between;

      p {
        align-self: flex-end;
        margin-bottom: 0;
        color: #757b81;
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 15px 15px 22px 15px;

    & > div:first-of-type {
      text-align: left;
      display: flex;
      background-color: #fff;
      margin: -30px -30px 0;
      padding: 15px 15px; 10px 15px;
      width: auto;

      img {
        margin-right: 9px;
      }
    }

    & > div:last-of-type {
      padding-top: 15px;

      .visible-label {
        position: static;
        text-align: right;
      }
      div {
        display: block;
        button {
          float: right;
        }
      }
    }
  }
`;

MentorNoteStyles.displayName = 'MentorNoteStyles';

export default MentorNoteStyles;
