import { each, forEach, startCase } from 'lodash';
import { keysToCamel } from '../../../utils/keysConverter';

module.exports = (() => {
  const thisState = {
    append: false,
    currentReferrer: '',
    limit: 25,
    loadedElements: 0,
    modal: '',
    readyToDownload: [],
    referrals: [],
    search: {
      name: '',
      period: {
        month: '',
        year: '',
      },
      status: '',
    },
    selectedReferrals: [],
    totalElements: 0,
  };

  const init = function () {
    thisState.modal = new CF.Modal('#user_referral__modal');
    thisState.form = new CF.Form('.admin_referrals__form');

    $(window).scroll(
      _.debounce(function () {
        thisState.loadedElements = $('.user_referral__row').length;
        const { loadedElements, limit, totalElements, loading } = thisState;
        const currentHight =
          $(window).scrollTop() >
          $(document).height() - $(window).height() - 250;
        if (
          loadedElements >= limit &&
          loadedElements < totalElements &&
          !loading &&
          currentHight
        ) {
          thisState.loading = true;
          _insertLoadingSpinner();
          _loadUserReferrals(_insertResponse);
        }
      }, 200)
    );

    _bindFunctions();
    _buildSerachParams();
  };

  const _bindFunctions = () => {
    $(document).ready(function () {
      $('.referral-date--js').val('');
      $('.referral-date--js').focus();
    });

    $('.referral-status--js').on('change', _buildSerachParams);
    $('.referral-name--js').on('keypress', _search);
    $('.referral-date--js').on('keypress', _search);

    $('#pay_checkbox').on('click', () => {
      _toggleSubmitBtn('.referral-submit--js');
    });
    $('#cancel_checkbox').on('click', () => {
      _toggleSubmitBtn('.referral-cancel--js');
    });

    $('#review_checkbox').on('click', () => {
      _toggleSubmitBtn('.referral-start-review--js');
    });

    $('.referral-submit--js').on('click', _submitPay);
    $('.referral-cancel--js').on('click', _submitCancel);
    $('.referral-review--js').on('click', _submitReview);

    $('.referral-start-review--js').on('click', _startReview);

    $('#bulk-download-payouts').on('click', _bulkDownloadPayouts);
    $('#bulk-create-payouts').on('click', _bulkCreatePayouts);

    $('#selected-download-payouts').on('click', _selectDownloadPayouts);

    $('.breakdown_earnings--js').on('change', _updateNetEarnings);

    $(document).on('click', '.pay-referral--js', _openPaymentModal);
    $(document).on('click', 'input.checkbox__download--js', _selectRow);

    _loadUserReferrals(_insertResponse);

    _form = new CF.Form('#admin_referrals_form--js');
  };

  const _search = (e) => {
    if (e.which === 13) {
      document.querySelector('#admin_referrals_form--js').reportValidity();
      _buildSerachParams();
      return false;
    }
  };

  const _bulkCreatePayouts = (e) => {
    e.preventDefault();
    $('.user_referral__modal main').hide();
    $('.user_referral__modal footer').hide();
    thisState.modal.toggleModal();
    _callBulkPayout(_insertPaidResponse);
  };

  const _selectRow = (e) => {
    const currentRow = e.target.getAttribute('value');
    const indexOfRow = thisState.selectedReferrals.indexOf(currentRow);
    const $downloadButton = $('#selected-download-payouts');

    if (indexOfRow === -1) {
      thisState.selectedReferrals.push(currentRow);
    } else {
      thisState.selectedReferrals.splice(indexOfRow, 1);
    }

    if (thisState.selectedReferrals.length > 0) {
      $downloadButton.removeClass('hidden');
    } else if (
      thisState.selectedReferrals.length <= 0 &&
      !$downloadButton.hasClass('hidden')
    ) {
      $downloadButton.addClass('hidden');
    }
  };

  const _buildSerachParams = () => {
    thisState.search.status = $('.referral-status--js').val();
    thisState.search.name = $('.referral-name--js').val();
    thisState.search.period.month =
      $('.referral-date--js').val().split('/')[0] || '';
    thisState.search.period.year =
      $('.referral-date--js').val().split('/')[1] || '';
    _filter();
  };

  const _filter = () => {
    _insertLoadingSpinner();
    thisState.totalElements = 0;
    thisState.loadedElements = 0;
    // thisState.readyToDownload = [];

    $('.user_referral__row').remove();
    _loadUserReferrals(_insertResponse);
  };

  const _openPaymentModal = (e) => {
    e.preventDefault();
    thisState.currentReferrer = e.target.getAttribute('data-id');
    thisState.modal.toggleModal();

    _disableActions();
    _getFinanceReferral(_insertReferrerData);
  };

  const _disableActions = () => {
    $('.review-payout-comment--js').val('');
    $('.admin-payout-review--js').hide();

    $('.cf-form_checkbox input').prop('checked', false);
    $('.referral-submit--js, .referral-start-review--js, .referral-cancel--js')
      .addClass('button--primary-disabled')
      .prop('disabled', true);
  };

  const _toggleSubmitBtn = (btnClass) =>
    $(btnClass)
      .toggleClass('button--primary-disabled')
      .prop('disabled', (_i, v) => !v);

  const _getFinanceReferral = (callbackFn) => {
    $.ajax({
      url: `/api/v1/admin/finance/referrals/${thisState.currentReferrer}`,
    }).done(callbackFn);
  };

  const _bulkDownloadPayouts = () => {
    _search({ which: 13 });

    const payload = encodeURIComponent(
      JSON.stringify({
        user_referral_ids: Object.keys(thisState.readyToDownload),
      })
    );
    window
      .open(
        `/api/v1/finance/transactions/download/zip_payout_information.zip?payload=${payload}`,
        '_blank'
      )
      .focus();
  };

  const _selectDownloadPayouts = () => {
    _search({ which: 13 });

    const payload = encodeURIComponent(
      JSON.stringify({
        user_referral_ids: thisState.selectedReferrals,
      })
    );
    window
      .open(
        `/api/v1/finance/transactions/download/zip_payout_information.zip?payload=${payload}`,
        '_blank'
      )
      .focus();
  };

  const _insertReferrerData = (response) => {
    const {
      payoutInformation,
      referrerId,
      referredBy,
      referredCustomer,
      earnings,
      earningsNet,
      earningsTaxAmount,
      courseTopic,
      earliestStartDate,
    } = keysToCamel(response);

    const { payoutMethod } = payoutInformation;

    $('.user_referral__modal header').hide();

    $('.user_referral__modal main').show();
    $('.user_referral__modal footer').show();

    $('.referral-referred--js').html(
      `<a href="/en/admin/users/${referrerId}" >${referredBy}</a>`
    );
    $('.referral-referrer--js').text(referredCustomer);
    $('.course-topic--js').text(courseTopic);
    $('.course-start-date--js').text(earliestStartDate);

    $('.breakdown_earnings--js').val(earnings);
    $('.breakdown-tax--js').text(earningsTaxAmount);
    $('.breakdown_earnings-net--js').text(earningsNet);

    _fillPayoutMethod(payoutMethod);
    _removeLoadingSpinner();
  };

  const _updateNetEarnings = () => {
    const earnings = $('.breakdown_earnings--js').val();
    $('.breakdown_earnings-net--js').text(earnings);
  };

  const _fillPayoutMethod = (payoutMethod) => {
    $('.payout-method-information--js').html(``);
    if (!payoutMethod) return;

    switch (payoutMethod.methodType) {
      case 'paypal':
        _fillPaypalDetails(payoutMethod);
        break;
      case 'bank_transfer':
        _fillBankDetails(payoutMethod);
        break;
    }
  };

  const _fillBankDetails = (payoutMethod) => {
    $('.payout-method-information--js').html(``);
    each(payoutMethod, (value, key) => {
      if (key === 'methodType' || key === 'uuid') return;
      $('.payout-method-information--js').append(`
        <div class='table__row'><strong>${startCase(
          key
        )}</strong><p>${value}</p></div>
      `);
    });
  };

  const _fillPaypalDetails = ({ paypalEmail }) => {
    $('.payout-method-information--js').html(`
      <div class='table__row'><strong>Paypal Email</strong><p>${paypalEmail}</p></tr>
    `);
  };

  const _submitPay = (e) => {
    e.preventDefault();
    _payPayout(_insertPaidResponse);
  };

  const _submitCancel = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    _cancelPayout(() => {
      thisState.modal.toggleModal();
      _filter();
    });
  };

  const _submitReview = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    _reviewPayout(() => {
      thisState.modal.toggleModal();
      _filter();
    });
  };

  const _payPayout = (callbackFn) => {
    const earningsAmount = $('.breakdown_earnings--js').val();
    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({
        payload: {
          earnings_amount: earningsAmount,
          user_referral_id: thisState.currentReferrer,
        },
        transaction: 'CREATE_PAYOUT',
      }),
      dataType: 'json',
      method: 'POST',
      url: '/api/v1/finance/transactions',
    })
      .success(callbackFn)
      .fail((error) => alert(error.message));
  };

  const _cancelPayout = (callbackFn) => {
    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({
        payload: {
          user_referral_id: thisState.currentReferrer,
        },
        transaction: 'CANCEL_PAYOUT',
      }),
      dataType: 'json',
      method: 'POST',
      url: '/api/v1/finance/transactions',
    })
      .success(callbackFn)
      .fail((error) => alert(error.message));
  };

  const _reviewPayout = (callbackFn) => {
    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({
        payload: {
          comments: $('.review-payout-comment--js').val(),
          user_referral_id: thisState.currentReferrer,
        },
        transaction: 'REVIEW_PAYOUT',
      }),
      dataType: 'json',
      method: 'POST',
      url: '/api/v1/finance/transactions',
    })
      .success(callbackFn)
      .fail((error) => alert(error.message));
  };

  const _getSelectedUserReferralIds = () => {
    return thisState.selectedReferrals.length === 0
      ? Object.keys(thisState.referrals)
      : thisState.selectedReferrals;
  };

  const _callBulkPayout = (callbackFn) => {
    const userReferralIds = _getSelectedUserReferralIds();
    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({
        payload: {
          user_referral_ids: userReferralIds,
        },
        transaction: 'BULK_CREATE_PAYOUT',
      }),
      dataType: 'json',
      method: 'POST',
      url: '/api/v1/finance/transactions',
    })
      .success(callbackFn)
      .fail((error) => alert(error.message));
  };

  const _startReview = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();

    $('.user_referral__modal header').hide();
    $('.user_referral__modal footer').hide();
    $('.admin-payout-review--js').show();
    $('.referral-review--js').removeAttr('disabled');
  };

  const _insertPaidResponse = (response) => {
    const { data } = keysToCamel(response);

    $('.user_referral__modal header').show();
    $('.user_referral__modal main').hide();
    $('.user_referral__modal footer').hide();
    $('.message-container--js').html(`
      <div class='table__row table__row--heading'>
        <p class='cf-label-tiny--gray'>referrer</p>
        <p class='cf-label-tiny--gray'>state</p>
        <p class='cf-label-tiny--gray'>note</p>
      </div>
    `);
    thisState.readyToDownload = [];
    $('#bulk-download-payouts').removeClass('hidden').removeAttr('disabled');
    forEach(data, _appendResponseDetails);
  };

  const _appendResponseDetails = ({ state, payout, userReferralId }) => {
    const { pdfUrl, identifier } = payout;
    const { referredCustomer } = thisState.referrals[userReferralId];
    if (state === 'valid') thisState.readyToDownload[userReferralId] = payout;
    $('.message-container--js').append(
      `<div class='table__row'><p>${referredCustomer}</p><p>${state}</p><p><a href='${pdfUrl}'>${identifier}.pdf</a></p></div>`
    );
  };

  const _loadUserReferrals = (callbackFn) => {
    $.ajax({
      data: {
        limit: thisState.limit,
        offset: thisState.loadedElements,
        search: thisState.search,
      },
      url: '/api/v1/admin/finance/referrals',
    }).done(callbackFn);
  };

  const _insertResponse = (response) => {
    const { result, partial, offset, count, resultCount } =
      keysToCamel(response);
    if (thisState.append !== true) {
      thisState.referrals = [];
    } // clean referrals before inserting new ones

    forEach(result, (referral) => {
      thisState.referrals[referral.id] = referral;
    });

    $('.admin__referrals__table').append(partial);
    $('.referral-display--js').html(resultCount + offset);
    $('.referral-count--js').html(count);
    thisState.totalElements = count;
    thisState.loadedElements = $('.user_referral__row').length;
    thisState.loading = false;
    thisState.append = false;
    _removeLoadingSpinner();
  };

  const _insertLoadingSpinner = () =>
    $('.loading_spinner--js').html(Template.loading_spinner);
  const _removeLoadingSpinner = () => $('.loading_spinner--js').html('');

  return {
    init,
  };
})();
