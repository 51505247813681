import styled from 'styled-components';

const Mentor = styled.div`
  background-color: #f6f7f8;
  display: flex;
  padding: 18px 20px 7px;

  &:first-of-type {
    margin-right: 30px;
  }

  & > div {
    display: flex;
    flex-direction: column;
  }

  & > div:first-of-type {
    align-items: center;
    margin-right: 20px;
  }

  & > div:last-of-type {
    p:first-of-type {
      margin: 0;
    }

    p:nth-of-type(2) {
      align-self: flex-start;
    }
  }

  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  img + p {
    margin-bottom: 0;
    margin-top: 8px;
  }

  @media (max-width: 1024px) {
    &:first-of-type {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;

Mentor.displayName = 'Mentor';

export default Mentor;
