import {
  ModalContent,
  ModalWrapper,
} from '../../../../CallsEdit/components/ConfirmationModal/styles';
import CreatedSuccessContent from './CreatedSuccessContent';
import DeleteConfirmationContent from './DeleteConfirmationContent';
import DeletedSuccessContent from './DeletedSuccessContent';
import PropTypes from 'prop-types';
import React from 'react';
import TheModal from '../../../../shared/components/TheModal';
import { useHistory } from 'react-router-dom';

const ConfirmationModal = ({
  handleDelete,
  handleModalClose,
  isOpen,
  modalAction,
  selectedRequest,
}) => {
  const history = useHistory();
  const closeModal = () => {
    handleModalClose();
    history.push('/en/time-off');
  };

  return (
    <ModalWrapper>
      <TheModal
        isModalOpen={isOpen}
        handleClose={
          modalAction === 'createdSuccess' ? closeModal : handleModalClose
        }
        closeButton={false}
      >
        <ModalContent>
          {
            {
              createdSuccess: (
                <CreatedSuccessContent handleModalClose={closeModal} />
              ),
              delete: (
                <DeleteConfirmationContent
                  handleModalClose={handleModalClose}
                  handleDelete={handleDelete}
                  selectedRequest={selectedRequest}
                />
              ),
              deletedSuccess: (
                <DeletedSuccessContent handleModalClose={closeModal} />
              ),
            }[modalAction]
          }
        </ModalContent>
      </TheModal>
    </ModalWrapper>
  );
};

ConfirmationModal.propTypes = {
  handleDelete: PropTypes.func,
  handleModalClose: PropTypes.func,
  isOpen: PropTypes.bool,
  modalAction: PropTypes.string,
  selectedRequest: PropTypes.string,
};

export default ConfirmationModal;
