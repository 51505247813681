import MentorNote from '../../../components/MentorNotesTab/MentorNote';
import { connect } from 'react-redux';

const MAX_LENGTH = 750;

export const mapStateToProps = (
  { userProfile: { currentUserId } },
  ownProps
) => ({
  edited: ownProps.note.created !== ownProps.note.updated,
  note: ownProps.note,
  owner: ownProps.note.creatorId === currentUserId,
  shouldTruncate: ownProps.note.content?.length > MAX_LENGTH,
});

export default connect(mapStateToProps)(MentorNote);
