import styled from 'styled-components';
import { Header } from '@careerfoundry/bubble';

const TabContentContainer = styled.div`
  padding: 0px 30px 30px 30px;

  #admission-btn {
    margin-top: 20px;
  }
`;

const TextWrapper = styled.div`
  max-width: 664px;

  p {
    color: var(--ds-color__static-content-primary);
  }

  #middle-paragraph {
    margin-bottom: 0px;
  }

  .MessageWrapper {
    margin-bottom: 24px;
  }
`;

const HeaderWrapper = styled.div`
  h5,
  p,
  i {
    display: inline-block;
  }
  i {
    color: var(--ds-color__int-content);
    line-height: 0;
  }
  display: flex;
  justify-content: space-between;
`;

const StatusWrapper = styled.div`
  margin-top: 30px;
  p {
    margin: 0;
    color: var(--ds-color__static-content-secondary);
    display: inline;
  }
`;

const SpinnerContainer = styled.div`
  margin-bottom: 40px;
  margin-top: -40px;
`;

const StyledHeader = styled(Header)`
  margin-top: 0px;
  padding: 32px 0px 0px 0px;
`;

TabContentContainer.displayName = 'TabContentContainer';
HeaderWrapper.displayName = 'HeaderWrapper';
StatusWrapper.displayName = 'StatusWrapper';
TextWrapper.displayName = 'TextWrapper';
SpinnerContainer.displayName = 'SpinnerContainer';
StyledHeader.displayName = 'StyledHeader';

export {
  TabContentContainer,
  HeaderWrapper,
  StatusWrapper,
  TextWrapper,
  SpinnerContainer,
  StyledHeader,
};
