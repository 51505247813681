import EmployerModalContainer from '../../containers/EmployerModalContainer';
import React from 'react';
import StudentPageContainer from '../../containers/StudentPageContainer';

const Dashboard = () => {
  return (
    <>
      <StudentPageContainer />
      <EmployerModalContainer />
    </>
  );
};

export default Dashboard;
