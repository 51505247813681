import PropTypes from 'prop-types';
import React from 'react';
import StyledTag from '../styles/StyledTag';

const Tag = ({ label, isActive, onClick, viewType }) => (
  <StyledTag
    isActive={isActive}
    isEdit={viewType === 'edit'}
    className="ds-typography__small"
    onClick={() => (viewType === 'view' ? null : onClick(label))}
  >
    {label}
  </StyledTag>
);

Tag.propTypes = {
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  viewType: PropTypes.string,
};

export default Tag;
