import styled from 'styled-components';

const MentorNoteCategoriesStyles = styled.div`
  justify-content: flex-start !important;
  color: white;
  width: 100%;
  padding-bottom: 20px;
`;

MentorNoteCategoriesStyles.displayName = 'MentorNoteCategoriesStyles';

export default MentorNoteCategoriesStyles;
