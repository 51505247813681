import React from 'react';

const PrivacyPolicyBody = () => (
  <>
    <p className='ds-typography__body'>
      Responsible party pursuant to data protection laws, in particular the EU
      General Data Protection Regulation (GDPR), is
    </p>
    <p className='ds-typography__body'>CareerFoundry GmbH,</p>
    <p className='ds-typography__body'>c/o THE GATE,</p>
    <p className='ds-typography__body'>Torstraße 177,</p>
    <p className='ds-typography__body'>10115 Berlin,</p>
    <p className='ds-typography__body'>Deutschland</p>
    <p className='ds-typography__body'>
      Registration: HRB 155933 B, AG Charlottenburg
    </p>
    <p className='ds-typography__body'>VAT number: DE294024931</p>
    <p className='ds-typography__body'>Telephone Sales: +49 30 4849 2016</p>
    <p className='ds-typography__body'>Email: hello@careerfoundry.com</p>
    <p className='ds-typography__body'>
      Web: <a href='https://careerfoundry.com'>https://careerfoundry.com</a>
    </p>
    <p className='ds-typography__body'>Managing Director: Martin Ramsin</p>
    <p className='ds-typography__body'>
      Furthermore, it is important to us that you know, at any point, when we
      store your personal data and how we use it.
    </p>
    <p className='ds-typography__body'>
      We will collect, process and use your personal data in compliance with
      applicable European and German data protection law.
    </p>
    <p className='ds-typography__body'>
      <strong>Consent settings</strong>
    </p>
    <p className='ds-typography__body'>
      <a href='https://www.honeypot.io/consent_options.html'>
        Click here to view and edit your cookie consent options
      </a>
    </p>
    <p className='ds-typography__body'>
      <strong>What is this Privacy Policy about?</strong>
    </p>
    <p className='ds-typography__body'>
      The details provided in this Privacy Policy shall provide you with
      information about when and how we collect personal data relating to you
      during your use of the websites and services operated by CareerFoundry
      under the domain ”CareerFoundry.com” (“Service”) and how we use such data.
      We inform you about the kind of personal data, the scope of personal data,
      and the purposes that such collection and use of personal data is
      performed for. We, therefore, ask you to read the following explanations
      carefully.
    </p>
    <p className='ds-typography__body'>
      This Privacy Policy is only valid for this website. The website might
      contain cross-references ("links") to other websites of third-party
      providers. Our Privacy Policy does not apply to websites of third parties.
      For this reason, users should carefully read privacy policies of third
      party websites collecting your personal data.{' '}
    </p>
    <p className='ds-typography__body'>
      By agreeing to our Privacy Policy you agree to the collection, processing
      and use of your personal data in compliance with the laws applicable to
      the protection of personal data and the following provisions.{' '}
    </p>
    <p className='ds-typography__body'>
      <strong>What is personal data?</strong>
    </p>
    <p className='ds-typography__body'>
      Personal data is any information relating to an identified or identifiable
      natural person. A person is identifiable, if the person can be identified,
      directly or indirectly, in particular by reference to an identification
      number or to one or more factors specific to his physical, physiological,
      mental, economic, cultural or social identity. This may include data such
      as e.g. name, email address, telephone number and mailing address. We
      process your data in the context of our service only when you have given
      us your consent to do so, the data is necessary for the performance of the
      contract or where it is permissible by law.
    </p>
    <p className='ds-typography__body'>
      You may withdraw your consent to the processing of personal data at any
      time. A revocation has a direct influence on the admissibility of the
      processing of your personal data as soon as directed to us.
    </p>
    <p className='ds-typography__body'>
      If the processing of your personal data is based on legitimate interests,
      you have the right to object to processing the personal data. If you
      exercise your right of objection, we kindly ask you to explain the reasons
      why we should not process your personal data. If your objection is
      justified, we will review the facts and adjust or modify the processing of
      data or inform you about imperative legitimate grounds for the processing.
    </p>
    <p className='ds-typography__body'>
      We will delete your personal data as soon as the data is no longer
      necessary for the respective purpose. It may occur that personal data is
      kept for the time during which possible claims may be asserted against us
      (for example statutory limitation period of three or in some cases, thirty
      years). Furthermore, we are required by law to store certain data due to
      the duties of proof and retention, inter alia from the German Commercial
      Code, the German Tax Code or the Money Laundering Act. The storage periods
      can be up to ten years.
    </p>
    <p className='ds-typography__body'>
      <strong>What kind of personal data does CareerFoundry collect?</strong>
    </p>
    <p className='ds-typography__body'>
      With every access of our Service data related to this event is being saved
      to a protocol file or database on the server of our hosting service
      provider. This also applies to data you enter in order to use the Service
      or contact us.
    </p>
    <p className='ds-typography__body'>We collect the following information:</p>
    <ul>
      <li className='ds-typography__body'>
        File name of the content retrieved (respectively, information submitted
        by you in the course of using our service);
      </li>
      <li className='ds-typography__body'>Date and time of the request;</li>
      <li className='ds-typography__body'>
        Amount of data transferred, access status (content transmitted, content
        not found, etc.);
      </li>
      <li className='ds-typography__body'>
        Operating system, browser and device type;
      </li>
      <li className='ds-typography__body'>
        Screen resolution, language and time zone settings;
      </li>
      <li className='ds-typography__body'>
        Referrer-URL, i.e. internet address of the website visited previous to
        accessing our offering;
      </li>
      <li className='ds-typography__body'>
        IP address and name of your internet service provider.
      </li>
    </ul>
    <p className='ds-typography__body'>
      The IP address is stored for the period of use only and will be deleted or
      anonymized afterwards immediately.
    </p>
    <p className='ds-typography__body'>
      We collect the aforementioned data as this is technically necessary for us
      to be able to display our website and to ensure the stability and security
      of the website. The legal basis of this processing is Art. 6 (1) sentence
      1 lit. f) GDPR.
    </p>
    <p className='ds-typography__body'>
      <strong>How does CareerFoundry use my personal data?</strong>
    </p>
    <p className='ds-typography__body'>
      CareerFoundry uses the data for the following purposes:
    </p>
    <ul>
      <li className='ds-typography__body'>
        Providing the services you requested, e.g. matching you with other
        members of our service.
      </li>
      <li className='ds-typography__body'>
        Making certain that you have the best possible user experience, e.g.
        edit your profile and manage your privacy settings.
      </li>
      <li className='ds-typography__body'>
        Honoring our contractual obligations to you, e.g. inform you about
        possible connections (Interview Invites) via the platform, email or
        phone.
      </li>
      <li className='ds-typography__body'>
        Enabling you to participate in our interactive service as far as you
        like.
      </li>
      <li className='ds-typography__body'>
        To inform you about changes to our service.
      </li>
    </ul>
    <p className='ds-typography__body'>
      On our website you can set up an account to use our services in form of an
      online job marketplace platform. In case you sign up as a candidate
      (“Talent”), you are required to enter your personal data such as name and
      e-mail,. In addition, you need to add languages and other skills,
      employment and education history as well as location to create an account.
      You can also sign up with your LinkedIn or Github account (for further
      information please see provisions about social media plug-ins below). In
      this case, further information may be required, such as name, photo,
      current position, etc. CareerFoundry can contact the Talent to determine
      further information required and update the profile accordingly. Signing
      up as an employer requires to enter the company name, hiring location,
      number of employees, contact person and contact e-mail. The information
      required for the implementation of the contract relationship will be
      processed for this purpose only. In most cases, companies access the
      profiles of Talents by themselves on the platform after searching for
      certain skills and contact the Talent directly. Furthermore, however,
      CareerFoundry can contact the Talent or the employer via phone, email or
      messaging service and suggest companies which fit the Talent´s profile or
      suggest Talents which fit the company's profile. The legal basis of this
      processing is Art. 6 (1) sentence 1 lit. b) GDPR. A storage of data can be
      revoked at any time.
    </p>
    <p className='ds-typography__body'>
      The specified data and your entire user account can always be deleted in
      the customer area. You agree to treat personal access data confidentially
      and not to make such data available to unauthorized third parties. We do
      not assume liability for misused passwords unless we are responsible for
      the abuse. You should notify us immediately if you are concerned about a
      third party having knowledge of your password, if the password is used
      unauthorized or this seems to be likely. You are responsible for ensuring
      that your information is accurate and complete and that you notify us of
      any changes to the information provided. You can review and update the
      information provided and your account settings section of our website.
      After completing communication with CareerFoundry you should log out and
      close the browser window, especially if you share the computer with other
      people.
    </p>
    <p className='ds-typography__body'>
      Due to commercial and tax regulations, we may be obliged to store part of
      the personal data transferred (e.g. payment data) for a period of 10
      years. After a period of [e.g. 2 years] we restrict the use of specific
      data and, as a consequence, the data may be used to comply with legal
      obligations only.
    </p>
    <p className='ds-typography__body'>
      <strong>Does CareerFoundry transfer data to third parties?</strong>
    </p>
    <p className='ds-typography__body'>
      We transmit your personal data to third parties only if you have consented
      to the transfer, if the transfer is necessary for the performance of the
      contract, or we are enforced to do so by law.
    </p>
    <p className='ds-typography__body'>
      Companies we cooperate with to mediate the Talent and try to match the
      right person with the right job have access to profiles of the Talents and
      therefore personal data of Talents are transmitted to the companies as a
      result. The companies are only allowed to process this personal data
      within the framework of the application process (especially save the data
      in their applicant tracking system) and to contact the Talent in case they
      are interested. The legal basis of this processing is Art. 6 (1) sentence
      1 lit. b) and f) GDPR.
    </p>
    <p className='ds-typography__body'>
      We sometimes use external service providers to process your personal data
      and to provide our services. They are carefully chosen and instructed by
      us. They are bound by our instructions and are regularly inspected. The
      data provided to our service providers may be used by them to fulfill
      their tasks only.
    </p>
    <p className='ds-typography__body'>
      In particular, CareerFoundry transfers personal data to service providers
      to be able to create and publish Talent profiles on the platform, to proof
      the Talent´s qualification, to invite Talents to online interviews with
      companies or with CareerFoundry, to ensure communication between Talents
      and companies and between Talents and CareerFoundry and to help Talents
      and companies with the Visa process.
    </p>
    <p className='ds-typography__body'>
      In order to improve the services of CareerFoundry and to assess whether a
      contract between Talent and Company will take place soon, CareerFoundry is
      able to access the communication between Talent and Company on the
      platform.
    </p>
    <p className='ds-typography__body'>
      If we transfer personal data outside the European Economic Area (EEA), we
      will do so only if the EU Commission has decided that the third country
      ensures an adequate level of data protection or other appropriate
      safeguards exist (e.g. EU standard contractual clauses or binding
      corporate rules) or a person has given its consent to do so.
    </p>
    <p className='ds-typography__body'>
      <strong>Does CareerFoundry use Cookies?</strong>
    </p>
    <p className='ds-typography__body'>
      In addition to the aforementioned usage data, cookies are stored on your
      computer when you use our website. These are small text files that are
      stored on your device and through which CareerFoundry receives certain
      information. However, cookies cannot run programs or transmit viruses to
      your computer. We use cookies to make a visit to our website more
      attractive and to enable the use of certain functions.
    </p>
    <p className='ds-typography__body'>
      We use transient and persistent cookies on our website. If you close your
      browser, transient cookies are automatically deleted. These cookies
      include, in particular, session cookies. This will allow us to recognize
      your computer when you return to our website. Session cookies are deleted
      as soon as you close the browser or log out.
    </p>
    <p className='ds-typography__body'>
      In contrast, persistent cookies are automatically deleted after a
      specified period of time. These cookies can be deleted at any time in the
      security settings of your browser.
    </p>
    <p className='ds-typography__body'>
      Generally, you can configure your browser settings in accordance with your
      personal demands and, for example, decline all cookies. However,
      non-acceptance of cookies may limit the functionality of our website.
    </p>
    <p className='ds-typography__body'>
      <strong>Does CareerFoundry use analytics tools?</strong>
    </p>
    <p className='ds-typography__body'>
      CareerFoundry uses Google Analytics, a web analytics service provided by
      Google Inc. ("Google"). Google Analytics uses so-called "cookies", text
      files that are saved on your computer and that allow an analysis of your
      use of our website. The information generated by the cookie about your use
      of our website is usually transmitted to and stored on a Google server in
      the USA. However, in the event of activation of IP anonymization on our
      website, your IP address will be truncated previously by Google within
      member states of the European Union or in other contracting states of the
      Agreement on the European Economic Area. Only in exceptional cases, the
      full IP address will be sent to a Google server in the US and truncated
      there. On behalf of CareerFoundry, Google will use this information to
      evaluate your use of our website, to compile reports on website activity,
      and to provide other services related to website activity and internet
      usage to CareerFoundry.
    </p>
    <p className='ds-typography__body'>
      The IP address transmitted by your browser as part of Google Analytics
      will not be combined with other Google data.
    </p>
    <p className='ds-typography__body'>
      You can prevent the storage of cookies by an appropriate setting of your
      browser software; however, please note that in this case, you may not be
      able to use the full range of all functions of our website. You may also
      prevent the collection of the data generated by the cookie and relating to
      your use of our website (including your IP address) as well as the
      processing of this data by Google by downloading and installing the
      browser plug-in available under the following link:{' '}
      <a href='http://tools.google.com/dlpage/gaoptout?hl=de'>
        http://tools.google.com/dlpage/gaoptout?hl=de.
      </a>
    </p>
    <p className='ds-typography__body'>
      CareerFoundry uses Google Analytics with the extension "_anonymizeIp ()".
      Accordingly, IP addresses are truncated and, thus, a reference to persons
      can be ruled out. If the data collected about you have a personal
      reference, this reference is immediately excluded and the personal data is
      deleted immediately.
    </p>
    <p className='ds-typography__body'>
      We use Google Analytics to analyze and regularly improve the use of our
      website. With the statistics we can improve our offer and make it more
      interesting for you as a user. For the exceptional cases in which personal
      data is transferred to the US, Google is part of the EU-US Privacy Shield,{' '}
      <a href='https://www.privacyshield.gov/EU-US-Framework'>
        https://www.privacyshield.gov/EU-US-Framework
      </a>
      . The legal basis of this processing is Art. 6 (1) sentence 1 lit. f)
      GDPR.
    </p>
    <p className='ds-typography__body'>
      Information of third-party provider: Google Dublin, Google Ireland Ltd.,
      Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.
      User conditions:{' '}
    </p>

    <p>
      <a href='http://www.google.com/analytics/terms/de.html'>
        http://www.google.com/analytics/terms/de.html
      </a>
      , Overview of data protection:{' '}
    </p>
    <p>
      <a href='http://www.google.com/intl/de/analytics/learn/privacy.html'>
        http://www.google.com/intl/de/analytics/learn/privacy.html
      </a>
      , and privacy policy:{' '}
    </p>
    <p>
      <a href='http://www.google.de/intl/de/policies/privacy'>
        http://www.google.de/intl/de/policies/privacy
      </a>
      .
    </p>
    <p className='ds-typography__body'>
      <strong>Does CareerFoundry use Google Adwords?</strong>
    </p>
    <p className='ds-typography__body'>
      CareerFoundry uses Google Adwords to draw attention to our offers by using
      advertising materials (so-called Google Adwords) on external websites. In
      relation to the data of the advertising campaigns, we can determine how
      successful the individual advertising measures are. We are interested in
      showing you advertisements that are of interest to you, to make our
      website more interesting to you and to achieve a fair calculation of
      advertising costs
    </p>
    <p className='ds-typography__body'>
      These advertising materials are supplied by Google via so-called "ad
      servers". Therefore, we use ad server cookies, which measure certain
      performance metrics such as ads or user clicks. If you access our website
      through a Google ad, Google Adwords will store a cookie on your PC. These
      cookies usually lose their validity after 30 days and are not intended to
      identify you personally. Typically, the unique cookie ID, number of ad
      impressions per placement (frequency), last impression (relevant to
      post-view conversions), and opt-out information (mark that the user does
      not like to be addressed) will be saved.
    </p>
    <p className='ds-typography__body'>
      These cookies allow Google to recognize your internet browser. If a user
      visits certain pages of an Adwords customer's website and the cookie
      stored on their computer has not expired, Google and the customer will be
      able to detect that the user clicked on the ad and was redirected to that
      page. Each Adwords customer is assigned a different cookie. Cookies cannot
      be tracked via the websites of Adwords customers. CareerFoundry does not
      collect and process any personal data in the aforementioned advertising
      measures. We receive only statistical evaluations by Google. On the basis
      of these evaluations, we can identify which of the advertising measures
      used are effective. We do not receive any further data from the use of the
      advertising material, in particular we cannot identify the users on the
      basis of this information.
    </p>
    <p className='ds-typography__body'>
      Due to the marketing tools used, your browser automatically establishes a
      direct connection to the Google server. We have no control over the extent
      and the further use of the data, which are raised by the appliance of this
      tool by Google. Therefore we inform you according to our knowledge level:
      By the incorporation of AdWords conversion Google receives the information
      that you entered the appropriate part of our internet appearance or click
      on an ad from us. If you are registered with a service provided by Google,
      Google may associate the visit with your account. Even if you are not
      registered with Google or have not logged in, there is a chance that the
      provider will find and store your IP address.
    </p>
    <p className='ds-typography__body'>
      You can prevent participation in this tracking process in several ways: a)
      by adjusting your browser software accordingly, in particular, suppression
      of third-party cookies will prevent you from receiving any third party
      advertisements; b) by deactivating the cookies for conversion tracking by
      setting your browser to block cookies from the domain
      "www.googleadservices.com" under{' '}
      <a href='https://www.google.de/settings/ads'>
        https://www.google.de/settings/ads
      </a>
      . However, this setting will be deleted when you delete your cookies; c)
      by deactivating the interest-based ads of providers that are part of the
      self-regulation "About Ads" campaign via the link http:{' '}
      <a href='http://www.aboutads.info/'>http://www.aboutads.info/</a> choices,
      however this setting will be deleted when you delete your cookies; d) by
      permanent deactivation in your browser (Firefox, Internet Explorer or
      Google Chrome) under the link{' '}
      <a href='http://www.google.com/settings/ads/plugin'>
        http://www.google.com/settings/ads/plugin
      </a>
      . We point out that in this case you may not be able to use all the
      features of our offer.
    </p>
    <p className='ds-typography__body'>
      The legal basis for the processing of your data is Art. 6 (1) sentence 1
      lit. f) GDPR. More information about privacy at Google can be found here:{' '}
      <a href='http://www.google.com/intl/de/policies/privacy'>
        http://www.google.com/intl/de/policies/privacy
      </a>{' '}
      and{' '}
      <a href='https://services.google.com/sitestats/de.html'>
        https://services.google.com/sitestats/de.html
      </a>
      . Alternatively you can visit the website of Network Advertising
      Initiative (NAI) under{' '}
      <a href='http://www.networkadvertising.org'>
        http://www.networkadvertising.org
      </a>
      . Google is part of the EU-US Privacy Shield,{' '}
      <a href='https://www.privacyshield.gov/EU-US-Framework'>
        https://www.privacyshield.gov/EU-US-Framework
      </a>
      .
    </p>
    <p className='ds-typography__body'>
      <strong>Does CareerFoundry use Social-Media-Plug-ins?</strong>
    </p>
    <p className='ds-typography__body'>
      CareerFoundry currently uses the following social media plug-ins on our
      website: Facebook, Twitter, LinkedIn, Github.
    </p>
    <p className='ds-typography__body'>
      CareerFoundry uses the so-called two-click solution. This means that, by
      visiting our website, no personal data is initially passed on to providers
      of such plug-ins.
    </p>
    <p className='ds-typography__body'>
      The provider of the plug-in can be identified on our website by the
      corresponding logo via the respective button. We provide the opportunity
      to communicate directly with the provider of the plug-in. The plug-in
      provider only receives the information that you have accessed its website
      via our online service, if you click on the designated field and activate
      it. Furthermore, the information according to access and cookies of this
      Privacy Policy will be transmitted.
    </p>
    <p className='ds-typography__body'>
      In Germany, according to the information of the respective provider when
      using Facebook plug-ins the IP address will be anonymized immediately
      after collection.
    </p>
    <p className='ds-typography__body'>
      Please note that through activation of the plug-in personal data will be
      transferred about you to the respective plug-in provider (in case of US
      providers to the USA). Data collection is mainly done by plug-in providers
      via cookies. So we recommend deleting all cookies on the security settings
      of your browser before clicking on the button.
    </p>
    <p className='ds-typography__body'>
      We have no influence on the collected data and the data processing
      operations. We are also not aware of the full scope of the data
      collection, the purpose of the processing and the storage periods. In
      addition, we have no information on the deletion of the data collected by
      the plug-in provider.
    </p>
    <p className='ds-typography__body'>
      The respective plug-in provider stores the data collected about you in
      profiles and uses them for marketing, market research and/or tailored
      design of its website. Such an evaluation is carried out in particular
      (also for non-logged-in users) for the presentation of tailor-made
      advertising and to inform other users of the social network about your
      activities on our website. You have a right to object against the creation
      of such usage profiles. To exercise, you need to contact the respective
      plug-in provider. The plug-ins allow us to interact with social networks
      and other users so that we can improve our offer and make it more
      interesting for you. The legal basis for the use of the plug-ins is Art. 6
      (1) sentence 1 lit. f) GDPR.
    </p>
    <p className='ds-typography__body'>
      A transfer of data takes place regardless of whether you have an account
      with the plug-in provider and are logged-in . However, if you are logged
      in to the plug-in provider, your data collected from us will be assigned
      directly to your user account. If you activate the social media button
      and, for example, link the page, the plug-in provider also stores this
      information in your user account and shares it publicly with your
      contacts. For this reason, we recommend logging out regularly after using
      a social network, but especially before activating the button, as this
      will prevent you from being assigned to your profile with the plug-in
      provider.
    </p>
    <p className='ds-typography__body'>
      For more information on the purpose and scope of the data collection and
      its processing by the plug-in provider, please refer to the privacy
      statements of the relevant provider, which are provided below. You will
      also get further information about your rights and settings options to
      protect your privacy.
    </p>
    <p className='ds-typography__body'>
      Addresses of respective plug-in provider und URL with privacy policies:
    </p>
    <p className='ds-typography__body'>
      Facebook Inc., 1601 S California Ave, Palo Alto, California 94304, USA;
    </p>
    <p>
      <a href='http://www.facebook.com/policy.php'>
        http://www.facebook.com/policy.php
      </a>
      ; further information to the collection of data:
      <a href='http://www.facebook.com/help/186325668085084'>
        http://www.facebook.com/help/186325668085084
      </a>
      ,{' '}
      <a href='http://www.facebook.com/about/privacy/your-info-on-other#applications'>
        http://www.facebook.com/about/privacy/your-info-on-other#applications
      </a>{' '}
      and{' '}
      <a href='http://www.facebook.com/about/privacy/your-info#everyoneinfo'>
        http://www.facebook.com/about/privacy/your-info#everyoneinfo
      </a>
      . Facebook is part of the EU-US-Privacy-Shield,{' '}
      <a href='https://www.privacyshield.gov/EU-US-Framework'>
        https://www.privacyshield.gov/EU-US-Framework
      </a>
      .
    </p>
    <p className='ds-typography__body'>
      Twitter, Inc., 1355 Market St, Suite 900, San Francisco, California 94103,
      USA;
    </p>
    <p>
      <a href='https://twitter.com/privacy'>https://twitter.com/privacy</a>.
      Twitter is part of EU-US-Privacy-Shield,{' '}
      <a href='https://www.privacyshield.gov/EU-US-Framework'>
        https://www.privacyshield.gov/EU-US-Framework
      </a>
      .
    </p>
    <p className='ds-typography__body'>
      LinkedIn Corporation, 2029 Stierlin Court, Mountain View, California
      94043, USA;
    </p>
    <p>
      <a href='http://www.linkedin.com/legal/privacy-policy'>
        http://www.linkedin.com/legal/privacy-policy
      </a>
      . LinkedIn is part of the EU-US-Privacy-Shield,{' '}
      <a href='https://www.privacyshield.gov/EU-US-Framework'>
        https://www.privacyshield.gov/EU-US-Framework
      </a>
      .
    </p>
    <p className='ds-typography__body'>
      Github Inc., 88 Colin P Kelly Jr St, San Francisco, CA 94107, USA;
    </p>
    <p>
      <a href='https://enterprise.github.com/privacy'>
        https://enterprise.github.com/privacy
      </a>
      . Github is part of the EU-US-Privacy-Shield,{' '}
      <a href='https://www.privacyshield.gov/EU-US-Framework'>
        https://www.privacyshield.gov/EU-US-Framework
      </a>
      .
    </p>
    <p className='ds-typography__body'>
      <strong>Does CareerFoundry use Retargeting-Services?</strong>
    </p>
    <p className='ds-typography__body'>
      CareerFoundry currently uses retargeting functions of Google, LinkedIn,
      Twitter and Facebook. This service makes it possible to reach users who
      previously visited or registered on our website to show targeted
      advertisements and offers of CareerFoundry based on the collected
      information. In the case of Facebook we are using the Facebook Pixel which
      is implemented on our website.
    </p>
    <p className='ds-typography__body'>
      Addresses and URL with privacy policies:
    </p>
    <p className='ds-typography__body'>Google:</p>
    <ul>
      <li className='ds-typography__body'>
        Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland
      </li>
      <li className='ds-typography__body'>
        Privacy Policy:{' '}
        <a href='https://adssettings.google.com/authenticated'>
          https://adssettings.google.com/authenticated
        </a>
      </li>
      <li className='ds-typography__body'>
        Privacy Shield:{' '}
        <a href='https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active'>
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
        </a>
      </li>
    </ul>
    <p className='ds-typography__body'>LinkedIn:</p>
    <ul>
      <li className='ds-typography__body'>
        LinkedIn Ireland Unlimited Company, Wilton Place,Dublin 2, Ireland
      </li>
      <li className='ds-typography__body'>
        Privacy Policy:{' '}
        <a href='http://www.linkedin.com/legal/privacy-policy'>
          http://www.linkedin.com/legal/privacy-policy
        </a>
      </li>
      <li className='ds-typography__body'>
        Privacy Shield:{' '}
        <a href='https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active'>
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
        </a>
      </li>
    </ul>
    <p className='ds-typography__body'>Facebook Pixel:</p>
    <ul>
      <li className='ds-typography__body'>
        Facebook (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
        Harbour, Dublin 2 Ireland)
      </li>
      <li className='ds-typography__body'>
        Privacy Policy:{' '}
        <a href='https://www.facebook.com/policy.php'>
          https://www.facebook.com/policy.php
        </a>
      </li>
    </ul>
    <p className='ds-typography__body'>Twitter:</p>
    <ul>
      <li className='ds-typography__body'>
        Twitter (Twitter International Company, One Cumberland Place, Fenian
        Street, Dublin 2, D02 AX07 Ireland)
      </li>
      <li className='ds-typography__body'>
        Privacy Policy:{' '}
        <a href='https://twitter.com/privacy'>https://twitter.com/privacy</a>
      </li>
    </ul>
    <p className='ds-typography__body'>
      <strong>
        Does CareerFoundry save my data in case of me making a request?
      </strong>
    </p>
    <p className='ds-typography__body'>
      In case you direct an inquiry to CareerFoundry, CareerFoundry saves the
      communication history with you as well as information and data submitted
      in the course of this communication (e.g. email address), in order to
      optimally answer your request and any further follow-ups. CareerFoundry
      will delete the relevant data after the storage is no longer required or
      restrict the processing if statutory retention periods exist.
    </p>
    <p className='ds-typography__body'>
      <strong>Is my data secure?</strong>
    </p>
    <p className='ds-typography__body'>
      CareerFoundry takes all appropriate technical and organizational security
      measures to protect your data against manipulation, loss, destruction or
      unauthorized access by third parties. Our systems are secured against
      unauthorized access.. However, we have to point out that the Internet does
      not allow for absolute data security despite all technical measures. We
      are not liable for actions of third parties.
    </p>
    <p className='ds-typography__body'>
      <strong>What are my rights in relation to CareerFoundry?</strong>
    </p>
    <p className='ds-typography__body'>
      You have the right of access, rectification or erasure, restriction of
      processing, to object and to data portability with respect to your
      personal data.
    </p>
    <p className='ds-typography__body'>
      CareerFoundry is available for corresponding requests or messages at the
      specified address below.
    </p>
    <p className='ds-typography__body'>
      Please note that the deletion of data is only possible if there are no
      precluding legal regulations. Information concerning your requests or
      messages is transmitted in electronic form (by e-mail). Should you use
      this option of providing information via e-mail, the specified e-mail
      address (both sender and recipient) will not be used for any purpose other
      than the provision of information and its documentation. You also have the
      right to lodge a complaint concerning the processing of your personal data
      by us with a supervisory authority.
    </p>
    <p className='ds-typography__body'>
      If we are legally required to disclose data to local, state, national or
      international authorities, we will comply with this obligation.
    </p>
    <p className='ds-typography__body'>
      We will also disclose information to third parties when required by
      applicable laws and regulations.
    </p>
    <p className='ds-typography__body'>
      In addition, we may disclose information to investigate, prevent, or
      initiate countermeasures against illegal activities or suspected fraud, or
      to apply or enforce any of our contracts and licensing rights.
    </p>
    <p className='ds-typography__body'>
      <strong>Is an amendment of the Privacy Policy possible?</strong>
    </p>
    <p className='ds-typography__body'>
      CareerFoundry reserves the right to amend this Privacy Policy at any time,
      but CareerFoundry will always observe the applicable statutes concerning
      data protection. An up-to-date version is available at our website.
      CareerFoundry recommends that users regularly review our Privacy Policy in
      order to be informed about our current Privacy Policy.
    </p>
    <p className='ds-typography__body'>
      <strong>
        How do I contact CareerFoundry in regard to data privacy aspects?
      </strong>
    </p>
    <p className='ds-typography__body'>
      We look forward to your contact in case you might have requests,
      questions, or suggestions relating to data privacy. Please direct written
      requests to:
    </p>
    <p className='ds-typography__body'>CareerFoundry GmbH</p>
    <p className='ds-typography__body'>c/o THE GATE,</p>
    <p className='ds-typography__body'>Torstraße 177,</p>
    <p className='ds-typography__body'>10115 Berlin,</p>
    <p className='ds-typography__body'>Deutschland</p>
  </>
);

export default PrivacyPolicyBody;
