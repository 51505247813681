import styled from 'styled-components';

export const Wrapper = styled.div`
  div.card-content {
    margin-bottom: 30px;
  }

  div.modal-content-middle-paragraph {
    margin: var(--ds-spacing__s) 0 var(--ds-spacing__l);
  }

  .agreement-modal {
    width: 440px;
    text-align: center;

    .modal-title {
      margin-bottom: var(--ds-spacing__m);
    }

    @media (max-height: 700px) {
      height: 100%;
      overflow-y: auto;
    }
  }

  button {
    margin-top: var(--ds-spacing__l) !important;
    margin-left: auto;
    margin-right: auto;
  }
`;
