import { constants } from '../../constants';

export const openInterviewGuidelines = () => ({
  type: constants.OPEN_INTERVIEW_GUIDELINES,
});

export const startInterview = (entryPoint) => ({
  data: entryPoint,
  type: constants.START_INTERVIEW,
});
