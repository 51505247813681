import React, { useState } from 'react';
import InputHeader from '../shared/InputHeader';
import LongSelectInput from '../shared/LongSelectInput';
import ModalWrapper from '../shared/ModalWrapper';
import PropTypes from 'prop-types';
import StyledCenterAlign from '../styles/StyledCenterAlign';
import { getScreenshot } from './helpers';

const CallQuestionModal = ({
  currentCalendarEntry,
  currentCallId,
  createOffPlatformCall,
  isOffPlatformCall,
  modalType,
  setModalAction,
  setModalType,
  handleCloseModal,
  updateCalendarEntry,
}) => {
  const [didCallHappened, setOption] = useState(null);

  const handleNextClick = async () => {
    didCallHappened ? setModalType('callNoteForm') : await handleDroppedCall();
  };

  const handleDroppedCall = async () => {
    if (isOffPlatformCall) {
      const screenshot = getScreenshot();
      await createOffPlatformCall(
        {
          ...currentCalendarEntry,
          callDropped: true,
          callRequestScreenshot: screenshot,
        },
        {}
      );
    } else {
      updateCalendarEntry(currentCallId, { callDropped: true });
    }
  };

  const handlePrevClick = () => {
    isOffPlatformCall ? setModalAction('cancel') : handleCloseModal();
  };

  return (
    <ModalWrapper
      currentState={didCallHappened === false ? 'callDidNotHappen' : modalType}
      onNextClick={() => handleNextClick()}
      onPrevClick={handlePrevClick}
      submitDisabled={didCallHappened === null}
    >
      <StyledCenterAlign>
        <InputHeader label='Did the call happen?*' prefix='1.' />
        <LongSelectInput
          label='Yes'
          prefix='Y'
          isSelected={didCallHappened}
          onClick={() => setOption(true)}
        />
        <LongSelectInput
          label='No'
          prefix='N'
          isSelected={didCallHappened === false}
          onClick={() => setOption(false)}
        />
      </StyledCenterAlign>
    </ModalWrapper>
  );
};

CallQuestionModal.propTypes = {
  createOffPlatformCall: PropTypes.func.isRequired,
  currentCalendarEntry: PropTypes.object.isRequired,
  currentCallId: PropTypes.number,
  handleCloseModal: PropTypes.func.isRequired,
  isOffPlatformCall: PropTypes.bool.isRequired,
  modalType: PropTypes.string.isRequired,
  setModalAction: PropTypes.func.isRequired,
  setModalType: PropTypes.func.isRequired,
  updateCalendarEntry: PropTypes.func.isRequired,
};

export default CallQuestionModal;
