import {
  BackgroundBlur,
  BottomItems,
  CFAndCancelIconWrapper,
  CFIconWrapper,
  CancelIconWrapper,
  Courses,
  DashboardIconMobileViewWrapper,
  ItemsWrapper,
  TopItems,
  Wrapper,
} from './styles';
import {
  CFIcon,
  CancelIcon,
  DashboardIcon,
  FaqIcon,
  LogoutIcon,
  SettingsIcon,
} from './icons';
import {
  getPageNameFromPathname,
  getPathnamesPageNamesMapping,
  isMobileView,
  isUserOnCoursePage,
  trackAndRedirect,
} from '../../helpers';
import CareerSupportCenterIcon from '../../containers/icons/CareerSupportCenterIcon';
import ForumIcon from '../../containers/icons/ForumIcon';
import Icon from '../Icon';
import MyActivitiesIcon from '../../containers/icons/MyActivitiesIcon';
import MyCFPlusIcon from '../../containers/icons/MyCFPlusIcon';
import MyMentorCallsIcon from '../../containers/icons/MyMentorCallsIcon';
import Program from '../Program';
import PropTypes from 'prop-types';
import QuickTourIcon from '../../containers/icons/QuickTourIcon';
import React from 'react';
import User from '../../containers/User';
import { noop } from 'lodash';
import { redirectTo } from '../../../../utils/redirectTo';
import { renderCourses } from '../SubNav';

const SideNavList = ({
  courses = [],
  isSubNavAccessible = false,
  shouldShowSubNav = false,
  userNameSlug = '',
  isVisible = true,
  isStudent,
  isUserTypeStudent = false,
  setIsVisible = noop,
  setShouldShowSubNav = noop,
}) => {
  const { pageNamesToPathnames } = getPathnamesPageNamesMapping();
  const currentPathname = window.location.pathname;
  const currentPageName = getPageNameFromPathname(currentPathname);
  const hideSubNavIfOpen = () => shouldShowSubNav && setShouldShowSubNav(false);

  return (
    <div>
      <Wrapper isVisible={isVisible}>
        <CFAndCancelIconWrapper>
          <CFIconWrapper
            onClick={() =>
              trackAndRedirect({
                redirect: {
                  url: pageNamesToPathnames.ROOT,
                },
                track: {
                  itemName: 'CF.icon',
                  shouldTrack: isStudent,
                },
              })
            }
          >
            <CFIcon />
          </CFIconWrapper>
          <CancelIconWrapper onClick={() => setIsVisible(false)}>
            <CancelIcon />
          </CancelIconWrapper>
        </CFAndCancelIconWrapper>
        <ItemsWrapper>
          <TopItems>
            {/* For mobile view only - START */}
            <User
              options={[
                {
                  name: 'Settings',
                  pathname:
                    pageNamesToPathnames.getSettingsPathname(userNameSlug),
                  svgIcon: <SettingsIcon />,
                },
                {
                  name: 'Logout',
                  pathname: pageNamesToPathnames.SIGNOUT,
                  svgIcon: <LogoutIcon />,
                },
              ]}
            />
            {/* STOP */}
            <DashboardIconMobileViewWrapper
              isSelected={isUserOnCoursePage({ courses, currentPathname })}
            >
              <Icon
                isVisible={true}
                isSelected={
                  currentPageName === 'DASHBOARD' ||
                  isUserOnCoursePage({ courses, currentPathname })
                }
                onClick={() => {
                  if (!isMobileView({ windowWidth: window.innerWidth })) {
                    redirectTo({ url: pageNamesToPathnames.ROOT });
                  }
                }}
                renderSVGIcon={() => <DashboardIcon />}
                onMouseEnter={() =>
                  isSubNavAccessible && setShouldShowSubNav(true)
                }
                nameForMobileView='My courses'
              />
            </DashboardIconMobileViewWrapper>
            {/* For mobile view only - START */}
            <Courses>{renderCourses({ courses, isStudent })}</Courses>
            {/* STOP */}
            <MyActivitiesIcon
              currentPageName={currentPageName}
              onMouseEnter={hideSubNavIfOpen}
              onClickRedirectTo={pageNamesToPathnames.getMyActivityPathname(
                isUserTypeStudent,
                userNameSlug
              )}
            />
            <MyMentorCallsIcon
              currentPageName={currentPageName}
              onClickRedirectTo={pageNamesToPathnames.MY_MENTOR_CALLS} // add redirect logic to direct to CF+ if subscribed and only active course
              onMouseEnter={hideSubNavIfOpen}
            />
            <ForumIcon onMouseEnter={hideSubNavIfOpen} />
            <CareerSupportCenterIcon
              currentPageName={currentPageName}
              onClickRedirectTo={pageNamesToPathnames.CAREER_SUPPORT_CENTER}
              onMouseEnter={hideSubNavIfOpen}
            />
            <MyCFPlusIcon
              currentPageName={currentPageName}
              onClickRedirectTo={pageNamesToPathnames.MY_CF_PLUS}
              onMouseEnter={hideSubNavIfOpen}
            />
          </TopItems>
          <BottomItems>
            <Icon
              isVisible={true}
              isSelected={currentPageName === 'FAQ'}
              onClick={() =>
                trackAndRedirect({
                  redirect: {
                    url: pageNamesToPathnames.FAQ,
                  },
                  track: {
                    itemName: 'help',
                    shouldTrack: isStudent,
                  },
                })
              }
              renderSVGIcon={() => <FaqIcon />}
              onMouseEnter={hideSubNavIfOpen}
              nameForMobileView='Support'
              tooltipContent='Get help'
            />
            <QuickTourIcon onMouseEnter={hideSubNavIfOpen} />
          </BottomItems>
        </ItemsWrapper>
      </Wrapper>
      <BackgroundBlur isVisible={isVisible} />
    </div>
  );
};

SideNavList.propTypes = {
  courses: PropTypes.arrayOf(Program.propTypes.course),
  isStudent: PropTypes.bool,
  isSubNavAccessible: PropTypes.bool,
  isUserTypeStudent: PropTypes.bool,
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  setShouldShowSubNav: PropTypes.func,
  shouldShowSubNav: PropTypes.bool,
  userNameSlug: PropTypes.string,
};

export default SideNavList;
