import { answerSurveyQuestion, getUserAnswers } from '../../actions';
import PersonalizedSupportPage from '../../components/PersonalizedSupportPage';
import { connect } from 'react-redux';

export const mapStateToProps = (state) => {
  return {
    questions: state.onBoarding.questions,
    userAnswers: state.onBoarding.userAnswers,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    onAnswerQuestion: (answer, questionIndex) =>
      dispatch(answerSurveyQuestion(answer, questionIndex)),
    onGetUserAnswers: () => dispatch(getUserAnswers()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalizedSupportPage);
