import OnboardingWrapper from './shared/OnboardingWrapper';
import PropTypes from 'prop-types';
import React from 'react';

const IntroductionPage = ({ onClickNext }) => {
  return (
    <OnboardingWrapper
      header="Welcome to the Employer Connector!"
      panelHeader="How to use Employer Connector"
      buttonLabel='Next'
      onClick={onClickNext}
    >
      <p className="ds-typography__body">Employer Connector connects applicants with CareerFoundry partners interested in hiring our graduates. As an Employer you will have access to the pool of our graduates, that have confirmed their wish to participate in this program. You will be able to filter the list of students by location, study program and specialization. You will also be able to view the application package of the students, which will contain CV, Professional Statement, links to their social accounts and other  relevant information. In case you find the profile interesting, you will be able to contact selected students via CareerFoundry platform messaging tool.</p>
      <p className="ds-typography__body">Before you start, we kindly ask you to read and accept out Terms & Conditions and Privacy Policy</p>
    </OnboardingWrapper>
  );
};

IntroductionPage.propTypes = {
  onClickNext: PropTypes.func.isRequired,
};

export default IntroductionPage;
