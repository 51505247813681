import ProfileButtons from '../../modals/showModal/ProfileButtons';
import React from 'react';
import { connect } from 'react-redux';
import { reverseProfileEdit } from '../../actions';
import { updateJobSeekingProfile } from '../../../CareerSupportCenter/actions';

const ProfileButtonsContainer = (props) => {
  return (
    <ProfileButtons {...props} />
  );
};

export const mapStateToProps = ({
  careerSupportCenter: { jobSeekingProfile },
}) => ({
  jobSeekingProfile,
});

export const mapDispatchToProps = (dispatch) => ({
  reverseProfileEdit: () => dispatch(reverseProfileEdit()),
  updateJobSeekingProfile: (jobSeekingProfile) => dispatch(updateJobSeekingProfile(jobSeekingProfile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileButtonsContainer);
