import Dropdown from '../../shared/dropdown';
import React from 'react';
import { formatDropdownFromParams } from '../../../utils/formatDropdown';
import { formattedDate } from '../../../utils/datetime';

const SelectablePlanView = (props) => {
  const plans = props.courseParams.selectablePlans;
  const content = props.courseParams.content.planSelect;

  const renderPaymentOptions = () =>
    plans.map((option, i) => {
      return (
        <div
          key={i}
          className={`payment__option ${
            option.plan === props.selectedOption ? 'active' : ''
          }`}
          onClick={() => {
            props.onPlanClick(option);
          }}
        >
          <div className='selector_circle'>
            <div className='selector_circle__outer'></div>
          </div>
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: option.title,
              }}
            />
            <p className='cf-small--gray' style={{ color: '#6A6F74' }}>
              {option.text}
            </p>
          </div>
        </div>
      );
    });

  const renderSubmitButton = () => (
    <button
      className='button--large button--primary-petrol'
      id='user-profile-submit'
      onClick={props.onNextClick}
    >
      Next
    </button>
  );

  const renderStartDates = () => (
    <Dropdown
      options={formatDropdownFromParams(
        props.courseParams.startDates,
        'formatted',
        'value'
      )}
      name='select-start-date'
      onChange={props.onStartChange}
      initialValue={props.selectedStart}
      initialText={formattedDate(new Date(props.selectedStart))}
      noBlankOption
    />
  );

  return (
    <div className='SelectPlanSlide enroll__slide cf-form'>
      <h2>{plans.length > 1 ? content.heading : content.heading_with_offer}</h2>
      {renderPaymentOptions()}
      <p className='cf-small cf-bold'>{content.start}</p>
      {renderStartDates()}
      {renderSubmitButton()}
    </div>
  );
};

export default SelectablePlanView;
