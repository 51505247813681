import CelebrationModal, { Page } from '../shared/components/CelebrationModal';
import AiTaskSubmissionStatus from './container/AiTaskSubmissionStatus';
import AudioReader from './components/AudioReader';
import BreadcrumbNav from './components/BreadcrumbNav/index.tsx';
import ConfidenceSurveyWrapper from './components/ConfidenceSurveyWrapper';
import ExerciseNavigator from './components/ExerciseNavigator/index.tsx';
import FileUploadError from './container/FileUploadError';
import GuidedTour from './OnboardingTour/container/OnboardingTour';
import { Provider } from 'react-redux';
import React from 'react';
import SubmissionUrlField from './container/SubmissionUrlFieldContainer';
import TaskSubmissionButton from './container/TaskSubmissionButton';
import TaskSubmissionModal from './container/TaskSubmissionModal';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const attendeeData = document.getElementById('attendee_data');
const taskSubmissionButtonContainer = document.getElementById(
  'taskSubmissionButton'
);
const guidedTourContainer = document.getElementById('excersizeGuidedTour');
const taskSubmissionModalContainer = document.getElementById(
  'taskSubmissionModal'
);
const audioReaderContainers = document.querySelectorAll('.audioReader');
const confidenceSurveyContainer = document.getElementById('confidence_survey');
const breadcrumbNavContainer = document.getElementById('breadcrumb-nav');
const exerciseNavigatorMobileContainer = document.getElementById(
  'exercise-navigator-mobile'
);
const celebrationModalExercisePageContainer = document.getElementById(
  'celebration-modal-exercise-page'
);
const fileUploadError = document.getElementById('fileUploadError');
const aiTaskSubmissionStatusContainer = document.getElementById(
  'aiTaskSubmissionStatus'
);
const submissionProjectUrl = document.getElementById('submission_project_url');
const submissionSourceUrl = document.getElementById('submission_source_url');

const submissionUrlFieldContainer = submissionProjectUrl || submissionSourceUrl;

if (submissionUrlFieldContainer) {
  const submissionUrlFieldContainerRoot = createRoot(
    submissionUrlFieldContainer
  );
  submissionUrlFieldContainerRoot.render(
    <Provider store={store}>
      <SubmissionUrlField
        name={submissionUrlFieldContainer.dataset.name}
        label={submissionUrlFieldContainer.dataset.label}
        value={submissionUrlFieldContainer.dataset.value}
        placeholder={submissionUrlFieldContainer.dataset.placeholder}
      />
    </Provider>
  );
}

if (taskSubmissionButtonContainer) {
  const taskSubmissionButtonContainerRoot = createRoot(
    taskSubmissionButtonContainer
  );

  taskSubmissionButtonContainerRoot.render(
    <Provider store={store}>
      <TaskSubmissionButton
        aiTutorInfo={{
          isConsentProvidedByUser:
            taskSubmissionButtonContainer.dataset.aiTutorConsentProvided ===
            'true',
          isEnabled:
            taskSubmissionButtonContainer.dataset.aiTutorEnabled === 'true',
          isEvaluationUnderReview:
            taskSubmissionButtonContainer.dataset.aiEvaluationUnderReview ===
            'true',
        }}
      />
    </Provider>
  );
}

if (guidedTourContainer) {
  const guidedTourContainerRoot = createRoot(guidedTourContainer);

  guidedTourContainerRoot.render(
    <Provider store={store}>
      <GuidedTour />
    </Provider>
  );
}

if (taskSubmissionModalContainer) {
  const taskSubmissionModalContainerRoot = createRoot(
    taskSubmissionModalContainer
  );

  store.dispatch({
    afa: taskSubmissionModalContainer.dataset.afa,
    attendeeId: +taskSubmissionModalContainer.dataset.id,
    course: taskSubmissionModalContainer.dataset.course,
    showSurvey: taskSubmissionModalContainer.dataset.motivationSurvey,
    showTimeSurvey: taskSubmissionModalContainer.dataset.timeSurvey,
    type: 'HYDRATE_WITH_DATA_ATTRIBUTES',
    wsgZone: taskSubmissionModalContainer.dataset.wsgZone,
  });

  taskSubmissionModalContainerRoot.render(
    <Provider store={store}>
      <TaskSubmissionModal />
    </Provider>
  );
}

if (audioReaderContainers.length) {
  audioReaderContainers.forEach((audioReaderContainer) => {
    const audioReaderContainerRoot = createRoot(audioReaderContainer);

    audioReaderContainerRoot.render(
      <AudioReader stepId={audioReaderContainer.dataset.step_id} />
    );
  });
}

if (confidenceSurveyContainer) {
  const confidenceSurveyContainerRoot = createRoot(confidenceSurveyContainer);

  const stepId = document.getElementById('content').dataset.stepId;

  confidenceSurveyContainerRoot.render(
    <ConfidenceSurveyWrapper
      attendeeId={attendeeData.dataset.id}
      formId={confidenceSurveyContainer.dataset.formId}
      linkText={confidenceSurveyContainer.dataset.text}
      stepId={stepId}
    />
  );
}

if (breadcrumbNavContainer) {
  const breadcrumbNavContainerRoot = createRoot(breadcrumbNavContainer);
  const {
    courseName,
    courseSlug,
    achievementPosition,
    stepPosition,
    previousExerciseName,
    previousExerciseSlug,
    nextExerciseName,
    nextExerciseSlug,
  } = breadcrumbNavContainer.dataset;

  breadcrumbNavContainerRoot.render(
    <BreadcrumbNav
      courseInfo={{
        courseName,
        courseSlug,
      }}
      exerciseInfo={{
        exerciseStep: `${achievementPosition}.${stepPosition}`,
        nextExerciseName,
        nextExerciseSlug,
        previousExerciseName,
        previousExerciseSlug,
      }}
    />
  );
}

if (exerciseNavigatorMobileContainer) {
  const exerciseNavigatorMobileContainerRoot = createRoot(
    exerciseNavigatorMobileContainer
  );
  const { courseSlug, previousExerciseSlug, nextExerciseSlug } =
    exerciseNavigatorMobileContainer.dataset;

  exerciseNavigatorMobileContainerRoot.render(
    <ExerciseNavigator
      courseSlug={courseSlug}
      previousNavItemLabel='Previous Exercise'
      nextNavItemLabel='Next Exercise'
      previousExerciseSlug={previousExerciseSlug}
      nextExerciseSlug={nextExerciseSlug}
    />
  );
}

if (celebrationModalExercisePageContainer) {
  const celebrationModalExercisePageContainerRoot = createRoot(
    celebrationModalExercisePageContainer
  );

  celebrationModalExercisePageContainerRoot.render(
    <CelebrationModal page={Page.exercisePage} />
  );
}

if (fileUploadError) {
  const fileUploadErrorRoot = createRoot(fileUploadError);

  fileUploadErrorRoot.render(
    <Provider store={store}>
      <FileUploadError />
    </Provider>
  );
}

if (aiTaskSubmissionStatusContainer) {
  const aiTaskSubmissionStatusContainerRoot = createRoot(
    aiTaskSubmissionStatusContainer
  );

  aiTaskSubmissionStatusContainerRoot.render(
    <Provider store={store}>
      <AiTaskSubmissionStatus
        aiTutorInfo={{
          evaluationId: aiTaskSubmissionStatusContainer.dataset.aiEvaluationId,
          isEvaluationUnderReview:
            aiTaskSubmissionStatusContainer.dataset.aiEvaluationUnderReview ===
            'true',
        }}
      />
    </Provider>
  );
}
