import { deleteLink, formatLink } from '../../helpers';
import EditLinkInput from './EditLinkInput';
import FormField from './FormField';
import LinkEditView from '../showModal/styles/LinkEditView';
import MultipleLinkAddInput from './MultipleLinkAddInput';
import PropTypes from 'prop-types';
import React from 'react';
import SingleAddInput from './SingleAddInput';

const AccountLinks = ({ updateECProfile, socialsUrls = [], otherUrls, portfolio, linkedInUrl, onDeleteSocial, textRequired, isEditView }) => {
  const handleUpdatePortofolio = (data) => {
    const { link, urlType } = data;
    updateECProfile({
      field: urlType,
      value: link,
    });
  };

  const updateSocials = (socialsUrls) => {
    updateECProfile({
      field: 'socials',
      value: socialsUrls,
    });
  };

  const handleLinkChange = (data) => {
    const { link, urlType } = data;

    const newUrl = { handle: link, name: urlType };
    const socials = socialsUrls.filter((social) => social.name.toLowerCase() !== urlType);
    updateSocials([...socials, newUrl]);
  };

  const handleMultipleLinkChange = (data) => {
    const newSocials = data || [];
    if (linkedInUrl) {
      newSocials.push(linkedInUrl);
    }
    updateSocials(newSocials);
  };

  return (
    <>
      <FormField labelText={`LinkedIn${textRequired}`}>
        <SingleAddInput
          attachment={formatLink(linkedInUrl)}
          deleteAttachment={() =>
            deleteLink('linkedin', socialsUrls, updateSocials, onDeleteSocial)
          }
          type="linkedin"
          onInputChange={handleLinkChange}
          isEditView={isEditView}
        />
      </FormField>
      <FormField labelText="Portfolio">
        {
          !isEditView
            ? <LinkEditView link={portfolio}/>
            : <EditLinkInput
              link={portfolio}
              onDelete={() =>
                handleUpdatePortofolio({ link: null, urlType: 'portfolio' })
              }
              type="portfolio"
              onInputChange={handleUpdatePortofolio}
            />
        }
      </FormField>
      <FormField
        labelText="Other social media accounts"
        sublabel="Behance, Github, Facebook, Stackoverflow, Dribble, Twitter"
      >
        <MultipleLinkAddInput
          links={otherUrls}
          onDeleteSocial={onDeleteSocial}
          onInputChange={handleMultipleLinkChange}
          isEditView={isEditView}
        />
      </FormField>
    </>
  );
};

AccountLinks.propTypes = {
  isEditView: PropTypes.bool,
  linkedInUrl: PropTypes.object,
  onDeleteSocial: PropTypes.func.isRequired,
  otherUrls: PropTypes.array,
  portfolio: PropTypes.string,
  socialsUrls: PropTypes.array,
  textRequired: PropTypes.string,
  updateECProfile: PropTypes.func.isRequired,
};

export default AccountLinks;
