import EmployerModal from '../../components/modal';
import { connect } from 'react-redux';
import { handleCloseModal } from '../../actions';

export const mapStateToProps = ({
  employerDashboard: {
    modalIsOpen,
    modalAction,
  },
}) => ({
  modalAction,
  modalIsOpen,
});

export const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => dispatch(handleCloseModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployerModal);
