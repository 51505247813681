import '../src/css/application.scss';
import '../src/react/App/index.ts';

require('es6-promise').polyfill();

// Get and load page specific JS
try {
  if (!gon.new_session_redirect) {
    const viewModule = require(`../src/views/${gon.controller}/${gon.action}`);
    viewModule && viewModule.init();
  }
} catch (error) {
  /* Don't throw an error message if the module isn't present */
}

// load global JS when user is signed in
if (gon.signed_in) {
  const globalModule = require('../src/globals/global');
  globalModule.init();
}
