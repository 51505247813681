import { Field, Form, Formik } from 'formik';
import FilterWrapper from '../../../Employer/components/shared/FilterWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import StyledFilterWrapper from '../../../Employer/styles/StyledFilterWrapper';

let timerId;

const NoteFilters = ({ setFilterParams }) => {
  const handleInputChange = (event) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    const { name, value } = event.target;
    timerId = setTimeout(() => setFilterParams({ name, value }), 1000);
  };

  return (
    <StyledFilterWrapper className="mt-44">
      <Formik
        initialValues={{
          search: '',
        }}
      >
        {() => (
          <Form>
            <FilterWrapper label="Student" isVerticalLabel={true}>
              <Field
                type="text"
                name="search"
                placeholder="Type student name"
                onKeyUp={handleInputChange}
              />
            </FilterWrapper>
          </Form>
        )}
      </Formik>
    </StyledFilterWrapper>
  );
};

NoteFilters.propTypes = {
  setFilterParams: PropTypes.func.isRequired,
};

export default NoteFilters;
