import styled from 'styled-components';

const StyledMediaResource = styled.div`
  position: relative;
  padding: 0px !important;
  box-shadow: 0px 2px 3px 0px #223c5029;
  @media (min-width: 768px) {
    max-width: 252px;
  }

  border-radius: 4px;
  background: white;

  a {
    position: absolute;
    width: calc(100% - 30px) !important;
    height: calc(100% - 30px) !important;
    top: 0;
    left: 0;
    text-decoration: none;
    z-index: 10;
  }
`;

StyledMediaResource.displayName = 'StyledMediaResource';

export default StyledMediaResource;
