import styled from 'styled-components';

const StyledEmployerForm = styled.div`
  overflow-y: scroll;
  padding: 20px 20px 85px;

  @media (max-width: 768px) {
    height: 100vh;
  }

  input,
  textarea,
  select {
    border: solid 2px #a7b1b9;
    border-radius: 4px;
    margin-bottom: 22px;
    font-size: 16px;
    padding: 10px;
  }

  input[type="text"] {
    width: 100%;
    height: fit-content;
  }

  textarea {
    width: 75%;
    margin-left: 5%;
    border: solid 2px #a7b1b9;
    border-radius: 4px;
  }

  label {
    width: 20%;
  }

  select {
    width: 75%;
  }

  h3,
  h5 {
    padding-bottom: 20px;
  }

  button {
    margin: 20px;
  }

  .error-text {
    margin-top: 5px;
    color: var(--ds-color_error);
    position: relative;
    width: 100%;
  }
`;

StyledEmployerForm.displayName = 'StyledEmployerForm';

export default StyledEmployerForm;
