import styled from 'styled-components';

const StyledAccordion = styled.div`
  margin-bottom: 16px;

  .accordion__button {
    &:focus {
      outline: none;
    }
  }

  .accordion__button:before {
    display: inline-block;
    float: right;
    margin-top: 8px;
    content: '';
    height: 10px;
    width: 10px;
    border-top: 2px solid #c0c6cb;
    border-left: 2px solid #c0c6cb;
    transform: rotate(225deg);
    transition: all .2s;
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }
`;

StyledAccordion.displayName = 'StyledAccordion';

export default StyledAccordion;
