import TabSection from '../../components/TabSection';
import { connect } from 'react-redux';
import { setTabIndex } from './actions';

export const mapStateToProps = ({
  userProfile: { currentUserId, studentIsViewing, tabIndex },
}) => ({
  currentUserId,
  studentIsViewing,
  tabIndex,
});

export const mapDispatchToProps = (dispatch) => ({
  setTabIndex: (tabIndex) => dispatch(setTabIndex(tabIndex)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabSection);
