import React from 'react';

export const IconVector = () => (
  <svg
    width="24"
    height="24"
    className="tracker"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
      fill="#223C50"
    />
    <path
      d="M18.3199 5.50708H6.22531C5.79989 5.50708 5.45511 5.84914 5.45511 6.27121V8.28465H19.0901V6.27121C19.0901 5.84914 18.7454 5.50708 18.3199 5.50708ZM6.85716 7.42877C6.56343 7.42877 6.3252 7.19242 6.3252 6.90101C6.3252 6.6096 6.56343 6.37324 6.85716 6.37324C7.15089 6.37324 7.38986 6.6096 7.38986 6.90101C7.38986 7.19242 7.15163 7.42877 6.85716 7.42877ZM8.61361 7.42877C8.31988 7.42877 8.08164 7.19242 8.08164 6.90101C8.08164 6.6096 8.31988 6.37324 8.61361 6.37324C8.90733 6.37324 9.14557 6.6096 9.14557 6.90101C9.14557 7.19242 8.90733 7.42877 8.61361 7.42877ZM10.3693 7.42877C10.0756 7.42877 9.83735 7.19242 9.83735 6.90101C9.83735 6.6096 10.0756 6.37324 10.3693 6.37324C10.663 6.37324 10.9013 6.6096 10.9013 6.90101C10.902 7.19242 10.6638 7.42877 10.3693 7.42877Z"
      fill="white"
    />
    <path d="M8.10003 13.2V11.7H10.6V13.2H8.10003Z" fill="white" />
    <path d="M11.1 13.2V11.7H13.6V13.2H11.1Z" fill="white" />
    <path d="M14.1 13.2V11.7H16.6V13.2H14.1Z" fill="white" />
    <path d="M14.1 13.7H16.6V15.2H14.1V13.7Z" fill="white" />
    <path d="M13.6 13.7V15.2H11.1V13.7H13.6Z" fill="white" />
    <path d="M10.6 13.7V15.2H8.10003V13.7H10.6Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 8.70001V17.4856C5.5 17.9238 5.8581 18.2798 6.30054 18.2798H18.3352C18.7769 18.2798 19.1358 17.9246 19.1358 17.4856V8.70001H5.5ZM8.10003 10.45C8.10003 10.3119 7.9881 10.2 7.85003 10.2C7.71196 10.2 7.60003 10.3119 7.60003 10.45V11.2H6.85003C6.71196 11.2 6.60003 11.3119 6.60003 11.45C6.60003 11.5881 6.71196 11.7 6.85003 11.7H7.60003V13.2H6.85003C6.71196 13.2 6.60003 13.3119 6.60003 13.45C6.60003 13.5881 6.71196 13.7 6.85003 13.7H7.60003V15.2H6.85003C6.71196 15.2 6.60003 15.3119 6.60003 15.45C6.60003 15.5881 6.71196 15.7 6.85003 15.7H7.60003V16.45C7.60003 16.5881 7.71196 16.7 7.85003 16.7C7.9881 16.7 8.10003 16.5881 8.10003 16.45V15.7H10.6V16.45C10.6 16.5881 10.712 16.7 10.85 16.7C10.9881 16.7 11.1 16.5881 11.1 16.45V15.7H13.6V16.45C13.6 16.5881 13.712 16.7 13.85 16.7C13.9881 16.7 14.1 16.5881 14.1 16.45V15.7H16.6V16.45C16.6 16.5881 16.712 16.7 16.85 16.7C16.9881 16.7 17.1 16.5881 17.1 16.45V15.7H17.85C17.9881 15.7 18.1 15.5881 18.1 15.45C18.1 15.3119 17.9881 15.2 17.85 15.2H17.1V13.7H17.85C17.9881 13.7 18.1 13.5881 18.1 13.45C18.1 13.3119 17.9881 13.2 17.85 13.2H17.1V11.7H17.85C17.9881 11.7 18.1 11.5881 18.1 11.45C18.1 11.3119 17.9881 11.2 17.85 11.2H17.1V10.45C17.1 10.3119 16.9881 10.2 16.85 10.2C16.712 10.2 16.6 10.3119 16.6 10.45V11.2H14.1V10.45C14.1 10.3119 13.9881 10.2 13.85 10.2C13.712 10.2 13.6 10.3119 13.6 10.45V11.2H11.1V10.45C11.1 10.3119 10.9881 10.2 10.85 10.2C10.712 10.2 10.6 10.3119 10.6 10.45V11.2H8.10003V10.45Z"
      fill="white"
    />
  </svg>
);

export const IconSubtract = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.56386 12.1783V0H15.3832V8.48335C18.6618 8.75437 21.2381 11.5012 21.2381 14.8497C21.2402 16.0518 20.8978 17.2292 20.2515 18.2427L23.6586 21.6569C24.1137 22.1884 24.1137 22.9722 23.6586 23.5037C23.1486 24.0992 22.2524 24.1686 21.6569 23.6586L18.2427 20.2515C17.2292 20.8978 16.0518 21.2402 14.8498 21.2381C11.5012 21.2381 8.75438 18.6618 8.48335 15.3832H2.56386C1.14788 15.3832 0 14.2353 0 12.8193V7.05062H1.28193V12.1783C1.28193 12.5323 1.5689 12.8193 1.9229 12.8193C2.27689 12.8193 2.56386 12.5323 2.56386 12.1783ZM9.38613 11.5374C9.14277 11.9379 8.94222 12.3674 8.79083 12.8193H5.76869C5.41469 12.8193 5.12772 12.5323 5.12772 12.1783C5.12772 11.8244 5.41469 11.5374 5.76869 11.5374H9.38613ZM12.3086 8.98677C11.6002 9.29424 10.9584 9.72636 10.4109 10.2554H5.76869C5.41469 10.2554 5.12772 9.96848 5.12772 9.61448C5.12772 9.26049 5.41469 8.97352 5.76869 8.97352H12.1783C12.223 8.97352 12.2666 8.97808 12.3086 8.98677ZM14.6135 18.3586C12.6572 18.24 11.1072 16.6158 11.1072 14.6297C11.1072 12.5664 12.7798 10.8938 14.8431 10.8938C16.9063 10.8938 18.5789 12.5664 18.5789 14.6297C18.5789 16.6103 17.0376 18.231 15.0889 18.3575C15.0142 18.3843 14.9337 18.3988 14.8498 18.3988C14.7669 18.3988 14.6874 18.3846 14.6135 18.3586ZM5.12772 7.05062H12.8193V2.56386H5.12772V7.05062Z"
      fill="#223C50"
    />
  </svg>
);

export const IconUnion = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_416_306)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2H15.7391V8.51742L10.9313 13.3349L9.3913 18.7826L14.8695 17.2426L15.7391 16.373V22H2V2ZM17.7391 14.373V22V24H15.7391H2H0V22V2V0H2H15.7391H17.7391V2V7.92353L20.9564 11.1558L17.7391 14.373ZM19.2764 4.98367C20.3576 3.904 22.109 3.904 23.1902 4.98367V4.97149C24.2699 6.05273 24.2699 7.80411 23.1902 8.88535L21.8146 10.261L17.9129 6.3593L19.2764 4.98367Z"
        fill="#223C50"
      />
    </g>
    <defs>
      <clipPath id="clip0_416_306">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IconMicrophone = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17.4591V17.4591C14.68 17.4591 16.8525 15.4223 16.8525 12.9098V5.63103V5.63103C16.8525 3.11856 14.68 1.08179 12 1.08179C9.32002 1.08179 7.14746 3.11856 7.14746 5.63103V12.9098V12.9098C7.14746 15.4223 9.32002 17.4591 12 17.4591C12 17.4591 12 17.4591 12 17.4591V17.4591ZM19.764 8.36058V8.36058C19.228 8.36058 18.7935 8.76793 18.7935 9.27042V9.27042V12.9098V12.9098C18.7935 16.4273 15.752 19.2788 12 19.2788C8.24803 19.2788 5.20645 16.4273 5.20645 12.9098V9.27042V9.27042C5.20645 8.76793 4.77194 8.36058 4.23595 8.36058C3.69995 8.36058 3.26544 8.76793 3.26544 9.27042V12.9098V12.9099C3.26761 17.0788 6.61031 20.5807 11.0296 21.0439V22.9182H12.9706V21.0439V21.0439C17.3898 20.5806 20.7324 17.0787 20.7345 12.9098V9.27043V9.27043C20.7345 8.76793 20.3 8.36058 19.764 8.36058V8.36058V8.36058Z"
      fill="#223C50"
    />
  </svg>
);

export const IconStudy = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_416_303)">
      <path
        d="M11.9597 5.44956L21.552 10.0178L12.037 14.0058L2.4464 9.88645L11.9597 5.44956Z"
        fill="#223C50"
        stroke="#223C50"
        strokeWidth="2"
      />
      <path
        d="M18.0002 11.5V15.8986C18.0002 16.2614 17.8037 16.5957 17.4867 16.7722L12.6151 19.4852C12.3187 19.6503 11.9588 19.6538 11.6592 19.4946L6.53079 16.7683C6.20426 16.5948 6.00018 16.2551 6.00018 15.8853V11.5"
        stroke="#223C50"
        strokeWidth="2"
      />
      <line
        x1="21.2502"
        y1="10.25"
        x2="21.2502"
        y2="15.75"
        stroke="#223C50"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_416_303">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IconProfile = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 14C6.49 14 2 16.91 2 20.49V24H22V20.49C22 16.91 17.51 14 12 14ZM12 12C15.3137 12 18 9.31371 18 6C18 2.68629 15.3137 0 12 0C8.68629 0 6 2.68629 6 6C6 9.31371 8.68629 12 12 12Z"
      fill="#223C50"
    />
  </svg>
);

export const IconProgressCircle = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#54D0C6" />
  </svg>
);
