import styled from 'styled-components';

const StyledRecommendedJobsBoard = styled.div`
  background: #F1F1F1;
  padding: 40px 30px 40px 30px;

  h2 {
    clear: both;
  }

  h4 {
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
  }

  @media (max-width: 767px) {
    background: var(--ds-color__white);
    padding: 16px 0;

    h2 {
      padding: 0 8px;
    }
  }
`;

StyledRecommendedJobsBoard.displayName = 'StyledRecommendedJobsBoard';

export default StyledRecommendedJobsBoard;
