import ExtensionWrapper from '../../react/course_extensions/CourseExtensionsWrapper';
import React from 'react';
import { createRoot } from 'react-dom/client';

const extensionContainer = document.querySelector('.extension__wrapper--js');

if (extensionContainer) {
  const extensionContainerRoot = createRoot(extensionContainer);

  extensionContainerRoot.render(<ExtensionWrapper />);
}
