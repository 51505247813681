import MentorNoteCategoriesStyles from './styles/MentorNoteCategoriesStyles';
import MentorNoteCategoryStyles from './styles/MentorNoteCategoryStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { startCase } from 'lodash';

const MentorNoteCategories = ({ categories }) => (
  <MentorNoteCategoriesStyles>
    {categories.map((category) => (
      <MentorNoteCategoryStyles key={category}>
        {startCase(category)}
      </MentorNoteCategoryStyles>
    ))}
    ;
  </MentorNoteCategoriesStyles>
);

MentorNoteCategories.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default MentorNoteCategories;
