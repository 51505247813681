import styled from 'styled-components';

const StyledButton = styled.button`
  margin: 0 0 40px 4px;
  width: fit-content;
  padding: 9px 19px 7px;
  background: var(--ds-color__white) !important;

  i {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    margin: 0 0 24px 4px;
  }
`;

StyledButton.displayName = 'StyledButton';

export default StyledButton;
