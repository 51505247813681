import PropTypes from 'prop-types';
import React from 'react';
import { RegionTabs } from './styles';

const instructorListTabTitles = [
  { key: 'all', value: 'All' },
  { key: 'america', value: 'Americas' },
  { key: 'europe', value: 'Europe' },
  { key: 'apac', value: 'APAC' },
  { key: 'certified', value: 'State Sponsored' },
];

const InstructorRegionSelector = ({
  activeRegion,
  instructorCount,
  setTimeZoneTab,
}) => {
  return (
    <RegionTabs className="ds-tabs">
      {instructorListTabTitles.map(({ key, value }) =>
        <div
          key={key}
          className={`ds-typography__body--bold ds-tabs__item ${activeRegion === key ? 'active' : ''}`}
          onClick={() => setTimeZoneTab(key)}
        >
          {value} ({instructorCount[key] ?? instructorCount.total})
        </div>,
      )}
    </RegionTabs>
  );
};

InstructorRegionSelector.propTypes = {
  activeRegion: PropTypes.string.isRequired,
  instructorCount: PropTypes.object.isRequired,
  setTimeZoneTab: PropTypes.func.isRequired,
};

export default InstructorRegionSelector;
