import styled from 'styled-components';

const StyledCallCard = styled.div`
  background-color: var(--ds-color__grey--light_300);
  padding: 15px 15px 15px 24px;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
    .button-section {
      padding-left: 70px;
    }
  }

  div:first-child {
    display: flex;
  }

  div:last-child {
    button {
      width: max-content;
      margin: 12px 0 0;
    }
  }
`;

StyledCallCard.displayName = 'StyledCallCard';

export default StyledCallCard;
