import styled from 'styled-components';

const Wrapper = styled.div`
  bottom: 0;
  position: fixed;
  right: 0;
  z-index: 999;
  max-width: 500px;

  .str-chat.str-chat-channel.messaging.light {
    background-color: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 8px 12px 0 #223c5028;
    .str-chat__main-panel {
      padding: 0;
    }
  }
  .str-chat__input-flat.str-chat__input-flat--send-button-active {
    padding: 10px;
  }
  .str-chat.str-chat-channel {
    height: auto;
  }

  .str-chat__header-livestream {
    min-height: 50px;
  }

  .str-chat__list {
    max-height: 300px;
    width: 500px;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .str-chat__container {
    .str-chat__input-flat {
      box-shadow: 0 -1px 3px 0 rgb(0 0 0 / 5%), 0 -1px 0 0 rgb(0 0 0 / 7%);
    }
    .str-chat__tooltip {
      left: auto;
      right: 0;
    }
    textarea {
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px 100px 20px 20px;
    }
    textarea:focus {
      box-shadow: 0 0 0 1px var(--primary-color);
    }
    .str-chat__input-flat-emojiselect {
      svg {
        display: none;
      }
      top: calc(100% - 44px);
      left: auto;
      right: 52px;
      width: 24px;
      height: 24px;
      background: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="12" cy="12" r="10" stroke="%23223C50" stroke-width="2"/%3E%3Cpath d="M17.5436 13.2961C17.2421 14.0241 16.8001 14.6855 16.2429 15.2427C15.6858 15.7998 15.0244 16.2418 14.2964 16.5433C13.5685 16.8448 12.7882 17 12.0003 17C11.2124 17 10.4322 16.8448 9.70421 16.5433C8.97625 16.2418 8.31482 15.7998 7.75767 15.2427C7.20052 14.6855 6.75856 14.0241 6.45703 13.2961" stroke="%23223C50" stroke-width="2" stroke-linecap="round"/%3E%3Cpath d="M9.03046 9.62763C8.62859 9.22576 8.08355 9 7.51523 9C6.94691 9 6.40186 9.22576 6 9.62763" stroke="%23223C50" stroke-width="2" stroke-linecap="round"/%3E%3Cpath d="M18.0305 9.62763C17.6286 9.22576 17.0835 9 16.5152 9C15.9469 9 15.4019 9.22576 15 9.62763" stroke="%23223C50" stroke-width="2" stroke-linecap="round"/%3E%3C/svg%3E%0A')
        no-repeat;
    }
    .str-chat__input-flat-fileupload {
      svg {
        display: none;
      }
      width: 24px;
      height: 24px;
      display: block;
      background: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="12" cy="12" r="10" stroke="%23223C50" stroke-width="2"/%3E%3Cline x1="7" y1="12" x2="17" y2="12" stroke="%23223C50" stroke-width="2" stroke-linecap="round"/%3E%3Cline x1="12" y1="7" x2="12" y2="17" stroke="%23223C50" stroke-width="2" stroke-linecap="round"/%3E%3C/svg%3E%0A')
        no-repeat;
    }
    .str-chat__input--emojipicker {
      left: auto;
      right: 0;
    }
    .str-chat__input-flat .rfu-file-upload-button {
      top: calc(100% - 44px);
    }
  }

  .str-chat__list.str-chat__list--thread {
    width: 100%;
  }

  .str-chat__message-simple__actions {
    min-width: 45px;
  }

  .str-chat-channel.messaging .str-chat__main-panel {
    padding: 5px 5px 0;
  }

  .str-chat__header-hamburger {
    display: none;
  }

  .str-chat__message--me .str-chat__message-inner,
  .str-chat__message-simple--me .str-chat__message-inner {
    max-width: 90%;
    margin-left: unset !important;
    word-break: break-all;
  }

  .str-chat__message-text-inner.str-chat__message-simple-text-inner {
    flex: 0 0 100% !important;
    overflow: auto;
  }

  @media (min-width: 600px) {
    right: 25px;
  }

  @media (min-width: 768px) {
    margin-right: 45px;
  }

  @media (max-width: 2500px) and (min-width: 1200px) {
    section {
      padding: inherit;
    }
  }
`;
const CustomHeaderWrapper = styled.div`
  background-color: white;
  padding: 5px;

  button {
    background-color: inherit;
    border: none;
    display: block;
    padding: 0 0 0 5px;
    position: absolute;
  }

  h6 {
    text-align: center;
    width: 100%;
  }
`;

CustomHeaderWrapper.displayName = 'CustomHeaderWrapper';
Wrapper.displayName = 'Wrapper';

export { CustomHeaderWrapper, Wrapper };
