import {
  ProgressBar,
  ProgressBarItem,
  ProgressBarItemCheck,
  ProgressBarItemDate,
  ProgressBarItemTitle,
  StyledCourseProgress,
} from './styles';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import useBarWidth from '../../hooks/useBarWidth';
const CourseProgress = ({ items }) => {
  const barWidth = useBarWidth();
  return (
    <StyledCourseProgress width={barWidth}>
      <ProgressBar></ProgressBar>
      {items.map((item) => (
        <ProgressBarItem
          key={item.endTimeStamp}
          role='progressBarItem'
          marginLeft={(barWidth / 100) * item.offset}
        >
          <ProgressBarItemTitle
            role='progressBarItemTitle'
            className='ds-typography__body'
          >
            {item.name}
          </ProgressBarItemTitle>
          <ProgressBarItemCheck></ProgressBarItemCheck>
          <ProgressBarItemDate className='ds-typography__body'>
            {moment(item.endDate).format('Do MMM, YYYY')}
          </ProgressBarItemDate>
        </ProgressBarItem>
      ))}
    </StyledCourseProgress>
  );
};

CourseProgress.propTypes = {
  items: PropTypes.array,
};

export default CourseProgress;
