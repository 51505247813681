module.exports = require('./course_plan')
import { trackEvent } from './../../shared/track_event';

const ADVISOR_FRONTPAGE_URL = 'https://careerfoundry.com/en/connect/all-contact-advisor-referral/';

document.addEventListener('DOMContentLoaded', () => {
  trackEvent('course_welcome_page_visit');
  document.querySelector('#contact-us-cta').addEventListener('click', contactUsClickHandler);
  document.querySelector('#modal-trigger-cta').addEventListener('click', modalTriggerTracker);
  modalCloseModalTextChange();
});

const contactUsClickHandler = () => {
  trackEvent('course_contact_us_click');
  window.location.href = ADVISOR_FRONTPAGE_URL;
}

const modalTriggerTracker = () => {
  trackEvent('course_welcome_page_modal_trigger');
};

const modalCloseModalTextChange = () => {
  if(screen.width < 768) {
    $('#dismiss-modal').text('Back')
  }
};
