import styled from 'styled-components';

const StyledCheckList = styled.ul`
  list-style: none;
  margin-left: -20px;

  input[type='radio'] {
    margin: 0px 10px 6px 0px;
  }
`;

StyledCheckList.displayName = 'StyledCheckList';

export default StyledCheckList;
