import EmployerFormContainer from '../../../Employers/containers/EmployerFormContainer';
import EmployerShow from '../../../Employers/components/EmployerShow';
import PropTypes from 'prop-types';
import React from 'react';
import ShowModalContainer from '../../containers/EmployerConnectorModal/show';
import StyledModalWrapper from './styles/StyledModalWrapper';
import TheModal from '../../../../../shared/components/TheModal';

const AdminEmployerConnectorModal = ({
  employer,
  handleCloseModal,
  modalIsOpen,
  modalAction,
}) => {
  return (
    <StyledModalWrapper isLargeModal={true}>
      <TheModal
        handleClose={handleCloseModal}
        isModalOpen={modalIsOpen}
      >
        <>
          {
            {
              newEmployer: <EmployerFormContainer />,
              showEmployer: <EmployerShow employer={employer} />,
              showJobSeekingProfile: <ShowModalContainer />,
              updateEmployer: <EmployerFormContainer />,
            }[modalAction]
          }
        </>
      </TheModal>
    </StyledModalWrapper>
  );
};

AdminEmployerConnectorModal.propTypes = {
  employer: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  handleCloseModal: PropTypes.func.isRequired,
  modalAction: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

export default AdminEmployerConnectorModal;
