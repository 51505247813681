import Icon from '../../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { MyActivitiesIcon as SVGIcon } from '../../SideNavList/icons';
import { trackAndRedirect } from '../../../helpers';

const MyActivitiesIcon = ({
  currentPageName = '',
  onClickRedirectTo = '',
  isStudent,
  isVisible = false,
  ...rest
}) => (
  <Icon
    isSelected={currentPageName === 'MY_ACTIVITIES'}
    isVisible={isVisible}
    onClick={() =>
      trackAndRedirect({
        redirect: {
          url: onClickRedirectTo,
        },
        track: {
          itemName: 'myactivity',
          shouldTrack: isStudent,
        },
      })
    }
    renderSVGIcon={() => <SVGIcon />}
    nameForMobileView="My activity"
    tooltipContent="My activity"
    {...rest}
  />
);

MyActivitiesIcon.propTypes = {
  ...Icon.propTypes,
  currentPageName: PropTypes.string,
  isStudent: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClickRedirectTo: PropTypes.string,
  slug: PropTypes.string,
};

export default MyActivitiesIcon;
