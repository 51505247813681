import styled from 'styled-components';

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0 rgba(51, 51, 51, 0.1);
  width: 800px !important;
  margin: auto;
  padding: 32px 41px;
  margin-top: 0;

  a {
    margin: 24px 31px 0 0;
  }

  @media (max-width: 1000px) {
    width: 100% !important;
  }

  @media (min-width: 768px) {
    hr {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin-top: 0px;
    hr {
      display: block !important;
      margin: 15px -16px;
      border: solid 1px #e8ebed;
    }
    padding: 8px 4px;
    border-radius: 0;
    box-shadow: none;

    a {
      width: 100%;
      text-align: center;
      display: block;

      &:nth-of-type(2) {
        margin-top: 16px;
      }
    }
    ${({ isReview }) =>
      !isReview &&
      `
      padding: 8px 0 10px;
    `}
  }
`;

Card.displayName = 'Card';

export default Card;
