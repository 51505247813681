import ModalContent from './styles';
import PropTypes from 'prop-types';
import React from 'react';
import TheModal from '../../../../shared/components/TheModal';

const FormContent = ({ handleClose }) => (
  <>
    <h1 className="ds-typography__h1 ds-icon__thumb-up-2"></h1>
    <h3 className="ds-typography__h3">Submitted</h3>
    <p className="ds-typography__body">
      Thanks for submitting your billing and tax details. You'll get an email
      confirmation once these are approved. In the meantime, our team is working
      on your contract, which you'll also receive via email.
      <br />
      While you're waiting, please finalize your onboarding!
    </p>
    <button className="ds-button__primary" onClick={handleClose}>
      Continue
    </button>
  </>
);

FormContent.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

const ResourceContent = ({ handleClose, status }) => {
  const formatMessage = (tabs) =>
    `${tabs.join(' & ')} ${tabs.length === 1 ? 'section' : 'sections'}`;

  return (
    <>
      {status.isCompleted ? (
        <>
          <img src="https://images.careerfoundry.com/public/instructor_candidates/frame_467.webp" />
          <h3 className="ds-typography__h3 ds-color__indigo">Great job!</h3>
          <p className="ds-typography__body">
            Thanks for finalizing your onboarding! We will review everything and
            get back to you via email.
          </p>
        </>
      ) : (
        <>
          <h3 className="ds-typography__h3 ds-color__indigo">Almost there!</h3>
          <p className="ds-typography__body">
            Please complete the {formatMessage(status.missingTabs)} before you
            continue.
          </p>
        </>
      )}
      <button className="ds-button__primary" onClick={handleClose}>
        Got it
      </button>
    </>
  );
};

ResourceContent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  status: PropTypes.shape({
    isCompleted: PropTypes.bool,
    missingTabs: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const SubmissionModal = ({ handleClose, modalAction, modalIsOpen, status }) => (
  <TheModal handleClose={handleClose} isModalOpen={modalIsOpen}>
    <ModalContent>
      {(() => {
        switch (modalAction) {
          case 'formSubmit':
            return <FormContent handleClose={handleClose} />;
          case 'resourcesSubmit':
            return (
              <ResourceContent handleClose={handleClose} status={status} />
            );
        }
      })()}
    </ModalContent>
  </TheModal>
);

SubmissionModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  modalAction: PropTypes.string,
  modalIsOpen: PropTypes.bool.isRequired,
  status: PropTypes.shape({
    isCompleted: PropTypes.bool,
    missingTabs: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default SubmissionModal;
