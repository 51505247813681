import styled from 'styled-components';

const ExerciseWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(34, 60, 80, 0.16);
  margin-top: 1px;

  & > a {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
  }
`;

ExerciseWrapper.displayName = 'ExerciseWrapper';

export default ExerciseWrapper;
