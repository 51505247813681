import { Progress, SectionLink, StyledSidebar, TabName } from './styles';
import Card from '../../styles/Card';
import PropTypes from 'prop-types';
import React from 'react';

const renderStatus = (status) => {
  switch (status) {
    case 'submitted':
      return (
        <p className="ds-typography__small--bold warning pending">pending</p>
      );
    case 'completed':
      return <i className="ds-icon__check-thin warning completed-section"></i>;
    case 'changes_requested':
      return (
        <p className="ds-typography__small--bold warning changes-requested">
          changes requested
        </p>
      );
    default:
      return '';
  }
};

const renderMessage = (candidateState) => {
  switch (candidateState) {
    case 'onboarding_in_progress':
      return "We're excited to have you on the team! Please finalize your onboarding to start working with students.";
    case 'onboarding_to_be_checked':
    case 'ready_for_contract':
    case 'contract_signature_pending':
    case 'awaiting_finance_approval':
      return "Thanks for providing your information. We're working on your contract and reviewing your billing details. Please check your email for updates!";
    case 'activation_awaiting':
      return "Stay tuned! We're reviewing all your information and we'll activate your profile soon. You'll get an email from us as soon as you're ready to start.";
    case 'rejected_by_finance':
      return 'Thanks for providing your information. Please update your billing or/and tax details according to the finance email you have received.';
    default:
      return '';
  }
};

const Sidebar = ({
  candidateState,
  name,
  selectedTab,
  setSelectedTab,
  tabs,
  status,
}) => {
  const isActive = (key) => key === selectedTab;

  return (
    <StyledSidebar>
      <Card>
        <header>
          <h4 className="ds-typography__h4">Hello {name}</h4>
          <p className="ds-typography__body">{renderMessage(candidateState)}</p>
        </header>
        <Progress>
          <h4 className="ds-typography__h4">Progress</h4>
          {tabs.map((tab) => (
            <SectionLink
              active={isActive(tab.key)}
              key={tab.key}
              onClick={() => setSelectedTab(tab.key)}
            >
              <TabName
                className="ds-typography__body"
                active={isActive(tab.key)}
              >
                {tab.name}
              </TabName>
              {renderStatus(tab.status)}
            </SectionLink>
          ))}
        </Progress>
        <footer>
          <p className="ds-typography__small text-center">
            <b>Questions about onboarding?</b> Email us at:
            talent.instructors@careerfoundry.com
          </p>
        </footer>
      </Card>
    </StyledSidebar>
  );
};

Sidebar.propTypes = {
  candidateState: PropTypes.string,
  name: PropTypes.string.isRequired,
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  status: PropTypes.shape({
    isCompleted: PropTypes.bool,
    missingTabs: PropTypes.arrayOf(PropTypes.string),
  }),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
    }).isRequired
  ).isRequired,
};

export default Sidebar;
