import OptinModal from '../../modals/optInModal';
import { connect } from 'react-redux';
import { setOptinModalStep } from '../../actions';

export const mapStateToProps = ({
  careerSupportCenter: { optinModalStep },
}) => ({
  optinModalStep,
});

export const mapDispatchToProps = (dispatch) => ({
  setOptinModalStep: (step) => dispatch(setOptinModalStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptinModal);
