import { Body, Button, Header } from '@careerfoundry/bubble';
import React, { useState } from 'react';
import { Wrapper } from './styles';
import TheModal from '../../../../shared/components/TheModal';
import { redirectTo } from '../../../../../utils/redirectTo';
import { sharedConstants } from '../../../../shared/constants';

const DiversitySurveyCard = () => {
  const [shouldShowAgreementModal, setShouldShowAgreementModal] =
    useState<boolean>(false);

  return (
    <Wrapper>
      {/* TODO: Set tag as h4 when legacy styles stop overwriting styles from the Styleguide */}
      <Header tag='div' variant='h4' className='card-content'>
        Diversity Survey
      </Header>
      {/* TODO: Set tag as p when legacy styles stop overwriting styles from the Styleguide */}
      <Body tag='div' variant='small' className='card-content'>
        To continue building a diverse and inclusive learning community, we
        invite you to participate in our short Diversity Survey. By answering a
        few questions about how you identify, you'll help us assess our overall
        student diversity and the equity of student outcomes.
      </Body>
      <a
        className='ds-text__link--small ds-text--bold'
        onClick={() => setShouldShowAgreementModal(true)}
      >
        Fill out the Diversity Survey
      </a>
      <TheModal
        className='agreement-modal'
        isModalOpen={shouldShowAgreementModal}
        handleClose={() => setShouldShowAgreementModal(false)}
      >
        {/* TODO: Set tag as h4 when legacy styles stop overwriting styles from the Styleguide */}
        <Header tag='div' variant='h4' className='modal-title'>
          Diversity Survey
        </Header>
        {/* TODO: Set tag as p when legacy styles stop overwriting styles from the Styleguide */}
        <Body tag='div' variant='medium'>
          To continue building a diverse and inclusive learning community, we
          invite you to participate in our short Diversity Survey. By answering
          a few questions about how you identify, you'll help us assess our
          overall student diversity and the equity of student outcomes.
          <div className='modal-content-middle-paragraph'>
            All questions include a “Prefer not to disclose” option, and your
            responses will be reviewed anonymously and kept confidential.* Your
            data won't be shared with any third parties.
          </div>
          *Any information you provide in the survey is treated and processed in
          accordance with the provisions of the EU General Data Protection
          Regulation (GDPR) and the German Federal Data Protection Act (BDSG).
          You may revoke your consent at any time after answering the survey by
          sending a message to{' '}
          <Body tag='span' variant='medium-bold'>
            datenschutz@careerfoundry.com
          </Body>
          . Your data will then be deleted from our survey results. Your data
          will automatically be deleted after 3 years.
        </Body>
        <Button
          label='I agree'
          onClick={() => {
            localStorage.setItem(
              sharedConstants.IS_DIVERSITY_SURVEY_FLOW_VIA_PROFILE,
              'true'
            );

            redirectTo({ url: '/diversity_survey' });
          }}
          size='standard'
          variant='primary'
        />
      </TheModal>
    </Wrapper>
  );
};

export default DiversitySurveyCard;
