module.exports = (() => {
  var init = function() {
    stripeDataTable();
  };

  var stripeDataTable = function() {
    $('.stripe_subscriptions_data_table').DataTable({
      "paging": true,
      "info": false,
      "searching": true
    });
  };

  return {
    init: init
  };
})();
