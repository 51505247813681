const { trackEvent } = require('./common');
const common = require('./common');

module.exports = (() => {
  const init = () => {
    setupBanner();
  };

  const bannerName = $('.dismiss-referral-banner--js').data('banner-name')

  const setupBanner = () => {
    const referralBanner = $('.referral-banner').length > 0;
    if (referralBanner) {
      $(".referral-banner").css({ 'top': $("nav.coach-nav").outerHeight() });
      $('.referral-banner').show();
    }
    $(document).on('click', '.dismiss-referral-banner--js', () => dismissBanner());
    $(document).on('click', '.referral-link-anchor--js', () => clickBanner());
  };

  const dismissBanner = () => {
    $('.referral-banner').hide();
    common.trackEvent('referral_banner_dismiss', { banner_name: bannerName })
    common.dismissBannerApi(bannerName);
  };

  const clickBanner = () => {
    $('.referral-banner').hide();
    common.trackEvent('referral_banner_click', { banner_name: bannerName })
  }

  return {
    init: init,
  };
})();
