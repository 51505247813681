export default (() => {
  const init = () => {
    $(document).on('change', '#icon_select', displayIcon);
    $(document).on('change', "#admin_postscripts_course_id", displayStepsCheckboxes)
  };
  
  const displayStepsCheckboxes = function() {
    const course_id = $("#admin_postscripts_course_id option:selected").val();
    $(`#step-selector .step-options:not([data-course-id=${course_id}])`).addClass("hidden");
    $(`#step-selector .step-options[data-course-id=${course_id}]`).removeClass("hidden");
  };

  const displayIcon = function() {
    const url = $("#icon_select option:selected").attr("data-icon_url")
    if (url){
      $("#selected_icon").removeClass('hidden')
      $("#selected_icon").attr("src", url);
    }else{
      $("#selected_icon").addClass('hidden')
    }
  }

  return {
    init: init
  };
})();
