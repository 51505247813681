export const OTHER_JOBS_CATEGORIES = [
  {
    category_name: 'remote_virtual',
    contents: [
      {
        link: 'https://pangian.com/job-travel-remote/',
        text: 'Pangian',
      },
      {
        link: 'https://remote.co/qa-leading-remote-companies/',
        text: 'Remote.co',
      },
      {
        link: 'https://justremote.co/remote-design-jobs',
        text: 'justremote.co',
      },
      {
        link: 'http://jobs.remotive.io/',
        text: 'Remotive',
      },
      {
        link: 'https://www.flexjobs.com/',
        text: 'Flexjobs',
      },
      {
        link: 'https://talent.hubstaff.com/',
        text: 'Hubstaff Talent',
      },
      {
        link: 'https://meerkad.com/',
        text: 'Meerkat',
      },
      {
        link: 'http://www.wphired.com/',
        text: 'WP Hired',
      },
      {
        link: 'https://upstack.co/',
        text: 'Upstack',
      },
      {
        link: 'https://remoteok.io/',
        text: 'Remote OK',
      },
      {
        link: 'http://www.skipthedrive.com/',
        text: 'Skip The Drive',
      },
      {
        link: 'https://www.virtualvocations.com/',
        text: 'Virtual Vocations',
      },
      {
        link: 'https://www.workingnomads.co/jobs',
        text: 'Working Nomads',
      },
      {
        link: 'https://github.com/jessicard/remote-jobs',
        text: 'Github’s List of Remote Friendly Companies',
      },
      {
        link: 'https://weworkremotely.com/',
        text: 'We Work Remotely',
      },
      { link: 'https://nodesk.co/remote-jobs/', text: 'NoDesk' },
      {
        link: 'https://smartworkershome.com/jobs/',
        text: 'Smarter Workers Home',
      },
      { link: 'https://dailyremote.com/', text: 'DailyRemote' },
      { link: 'https://euremotejobs.com/', text: 'EU REMOTE JOBS' },
      { link: 'https://remoters.net/jobs/', text: 'Remoters' },
      { link: 'https://jobspresso.co/remote-work/', text: 'Jobspresso' },
      { link: 'https://remotehabits.com/jobs/', text: 'Remote Habits' },
      { link: 'https://remotejobsireland.com/', text: 'Remote Work Ireland' },
      { link: 'https://workremote.us/jobs/', text: 'Work/Remote' },
      { link: 'https://hitmarker.net/jobs', text: 'Hitmarker' },
      { link: 'https://uncubed.com/remote', text: 'UNCUBED' },
      {
        link: 'https://remotewoman.com/remote-design-jobs/',
        text: 'Remote Women',
      },
      { link: 'https://jsremotely.com/', text: 'Remote JavaScript Jobs' },
      { link: 'https://designremotely.co/jobs/', text: 'DesignRemotely' },
      { link: 'https://www.remotedesignjobs.co/', text: 'Remote Design Jobs' },
      {
        link: 'https://www.workatastartup.com/jobs/r/designer',
        text: 'Work at a Startup',
      },
      {
        link: 'https://about.gitlab.com/company/culture/all-remote/jobs/',
        text: 'All-Remote and Remote-First Jobs and Remote Work Communities',
      },
      { link: 'https://www.themuse.com/', text: 'themuse' },
      {
        link: 'https://underdog.io/candidates/remote-startup-jobs',
        text: 'underdog.io',
      },
      { link: 'https://remote.work/', text: 'Remote.Work' },
      { link: 'https://read.cv/open-roles', text: 'Read.cv' },
    ],
    title: 'Remote / Virtual / Telework',
  },
  {
    category_name: 'freelancing_all',
    contents: [
      {
        link: 'https://expertmap.eu/search/?postType=listing&listing--cat=90&postsPerPage=8&orderby=newest',
        text: 'Expert Map (Create your profile on this platform built by a CF Alumni)',
      },
      {
        link: 'https://tispr.com/blog/',
        text: 'Tisper (Resources)',
      },
      {
        link: 'https://hired.com/freelance-jobs',
        text: 'Hired.com (United States)',
      },
      {
        link: 'https://workingnotworking.com/',
        text: 'Working Not Working (United States)',
      },
      {
        link: 'https://www.yunojuno.com/',
        text: 'YunoJuno (UK)',
      },
      {
        link: 'https://www.malt.com/',
        text: 'Malt (Germany, France, Spain)',
      },
      {
        link: 'https://99designs.com/',
        text: '99Designs',
      },
      {
        link: 'http://www.guru.com/',
        text: 'Guru',
      },
      {
        link: 'https://www.freelancer.com/',
        text: 'Freelancer',
      },
      {
        link: 'https://needhq.com/',
        text: 'Need',
      },
      {
        link: 'http://onsite.io/',
        text: 'Onsite',
      },
      {
        link: 'https://www.crowdspring.com/',
        text: 'CrowdSpring',
      },
      {
        link: 'https://www.designcrowd.com/',
        text: 'Design Crowd',
      },
      {
        link: 'https://www.peopleperhour.com/',
        text: 'People Per Hour',
      },
      {
        link: 'https://www.topcoder.com/',
        text: 'Top Coder',
      },
      {
        link: 'https://www.toptal.com/careers',
        text: 'Toptal',
      },
      {
        link: 'https://www.linkedin.com/profinder/pros?trk=google',
        text: 'LinkedIn ProFinder',
      },
      {
        link: 'https://contently.net/',
        text: 'Contently',
      },
      {
        link: 'https://www.upwork.com/',
        text: 'Upwork',
      },
      {
        link: 'https://www.freelancermap.de/',
        text: 'freelancermap (Germany)',
      },
    ],
    title: 'Freelancing',
  },
  {
    category_name: 'wit_all',
    contents: [
      {
        link: 'https://www.inhersight.com/?_n=83146657',
        text: 'In Her Sight',
      },
      {
        link: 'https://womenhack.com/events/',
        text: 'Women Hack',
      },
      {
        link: 'https://themomproject.com/',
        text: 'The Mom Project',
      },
      {
        link: 'http://www.pathforward.org/',
        text: 'Path Forward',
      },
      {
        link: 'https://www.powertofly.com/jobs/',
        text: 'Power to Fly',
      },
      {
        link: 'https://www.dccjobs.com/job-search',
        text: 'DCC Jobs',
      },
      {
        link: 'https://remotewoman.com/',
        text: 'RemoteWoman',
      },
      {
        link: 'https://www.hiretechladies.com/jobs',
        text: 'TECH LADIES',
      },
      {
        link: 'https://www.advancingwomenintech.org/',
        text: 'Advancing Women in Tech',
      },
      {
        link: 'https://womenpm.org/',
        text: 'Women in Product',
      },
    ],
    title: 'Women in Tech',
  },
];
