import React, { Component } from 'react'; 


class ResultBar extends Component {
  constructor(props) {
    super(props);
  }

  render(){
    var renderIcons = () => {
      return (
        <div>
          {this.props.questions_status_list.map((question, i) => {
            return (<i key={i} className={`fa fa-${question == "correct" ? "check" : "times"} ${this.props.detailsProgress -1 == i ? "active" : ""}`}></i>)
          })}
        </div>
      );
    };

    var renderBar = () => {
      return (
        <div className="quiz__result__bar">
          {this.props.questions_status_list.map((question, i) => {
            return (<div key={i} className={`quiz__result__item ${question} ${this.props.detailsProgress -1 == i ? "active" : ""}`}></div>)
          })}
        </div>
      );
    }

    return (
      <div className={`result__bar__wrapper ${(this.props.detailsView) ? "details__result__bar" : ""}`}>
        {renderIcons()}
        {renderBar()}
      </div>
    )
  }
}

export default ResultBar;
