import styled from 'styled-components';

const StyledAnchorLink = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 35px;
  right: 30px;
  cursor: pointer;

  a {
    margin-bottom: 0;
  }
`;

StyledAnchorLink.displayName = 'StyledAnchorLink';

export default StyledAnchorLink;
