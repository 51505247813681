import Button from '../../../EmployerConnector/modals/shared/Button';
import MobileWrapper from './styles/MobileWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import StyledButtonWrapper from '../../../JobApplicationTracker/components/styles/StyledButtonWrapper';
import StyledHeaderContent from './styles/StyledHeaderContent';
import StyledHeaderImage from './styles/StyledHeaderImage';

const MobileHeaderInfographic = ({ onClick }) => (
  <MobileWrapper>
    <h4 className="ds-typography__h4">Create a new portfolio project</h4>
    <StyledHeaderImage />
    <StyledHeaderContent>
      <ul>
        <li className="ds-typography__body">
          Add to your portfolio to increase your chances of finding a new job
        </li>
        <li className="ds-typography__body">
          Follow the suggested step-by-step plan
        </li>
      </ul>
      <StyledButtonWrapper>
        <Button
          className="ds-button__primary"
          label="Get started"
          loading={false}
          onClick={onClick}
        />
      </StyledButtonWrapper>
    </StyledHeaderContent>
  </MobileWrapper>
);

MobileHeaderInfographic.propTypes = {
  onClick: PropTypes.func,
};

export default MobileHeaderInfographic;
