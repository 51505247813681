import styled from 'styled-components';

const StyledStaticStarRating = styled.div`
  display: flex;
  h1 {
    font-size: 20px;
    margin: 0 0 1rem 0;
  }
  .w-6 {
    width: 1.5em;
  }
  .h-6 {
    height: 1.5em;
  }
`;

StyledStaticStarRating.displayName = 'StyledStaticStarRating';

export default StyledStaticStarRating;
