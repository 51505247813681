module.exports = (() => {
  const init = function () {
    studentRosterDataTable();
    showMenteeRoster();
    bindFunctions();
  };

  const bindFunctions = () => {
    $('.all-checkbox-wrapper').on('click', toggleAll);
    $('.toggle-modal--js').on('click', toggleModal);
    $('.checkbox--js, .all-checkbox-wrapper').on('click', toggleButton);
    $('.checkbox--js').on('click', toggleSelected);
    $('#radio-temporary').on('click', toggleTempSwitch);
    $('#radio-permanent').on('click', togglePermanentSwitch);
    $('.students').on('click', showStudentList);
    $('.close-student-list--js').on('click', closeStudentList);
  };

  const showStudentList = function () {
    $('.student-list').hide();
    $(this).parent().siblings('.student-list').toggle();
  };

  const closeStudentList = function () {
    $(this).closest('.student-list').toggle();
  };

  const toggleTempSwitch = () => {
    $('.start-date-field, .end-date-field').removeClass('hidden');
    $('.instructor').removeClass('hidden');
  };

  const togglePermanentSwitch = () => {
    $('.end-date-field').addClass('hidden');
    $('.start-date-field').removeClass('hidden');
    $('#end_date').val('');
    $('.instructor').removeClass('hidden');
  };

  const toggleModal = e => {
    e.preventDefault();
    setNumberOfStudents();
    $('#switch_instructor_modal').modal('toggle');
  };

  const setNumberOfStudents = () => {
    const studentsSelected = $('.checkbox--js:checked').length;
    $('.number-of-students--js').html(` ${studentsSelected} `);
  };

  const toggleButton = function () {
    const checked = $('.checkbox--js').is(':checked');
    $('.toggle-modal--js').attr('disabled', !checked);
  };

  const toggleSelected = function () {
    $(this).closest('tr').toggleClass('selected');
  };

  const toggleAll = function () {
    const checked = $(this).find('input').is(':checked');
    $(this).parent().parent().siblings().find('.checkbox--js').each(function () {
      $(this).prop('checked', checked);
      setSelectedClass(checked, $(this));
    });
  };

  const setSelectedClass = (checked, el) => {
    const tr = el.closest('tr');
    checked ? tr.addClass('selected') : tr.removeClass('selected');
  };

  const studentRosterDataTable = function () {
    $('.student_roster_data_table').DataTable({
      aoColumnDefs: [
        {
          aTargets: [0],
          bSortable: false,
        },
      ],
      info: false,
      paging: false,
      searching: false,
    });
  };

  const showMenteeRoster = function () {
    $('.mentee-roster').click(function () {
      $('.mentee-table').slideToggle('slow');
      if ($('i').hasClass('open')) {
        $('i').removeClass('open');
      } else {
        $('i').addClass('open');
      }
    });
  };

  return {
    init: init,
  };
})();
