import AdminEnrollmentLinks from '../../../react/admin_enrollment_links';
import React from 'react';
import { createRoot } from 'react-dom/client';

const adminEnrollmentLinksContainer = document.querySelector(
  '.admin__enrollment_links'
);

if (adminEnrollmentLinksContainer) {
  const adminEnrollmentLinksContainerRoot = createRoot(
    adminEnrollmentLinksContainer
  );

  adminEnrollmentLinksContainerRoot.render(<AdminEnrollmentLinks />);
}
