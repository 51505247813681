import InteractiveModalButton from './InteractiveModalButton';
import MarkdownText from './MarkdownText';
import PropTypes from 'prop-types';
import React from 'react';
import StyledContentWrapper from './styles/StyledContentWrapper';
import StyledInteractiveTextMessage from './styles/StyledInteractiveTextMessage';
import StyledModalParagraph from './styles/StyledModalParagraph';

const InteractiveTextMessage = ({ description = '', onCloseModal }) => {
  return (
    <StyledInteractiveTextMessage>
      <StyledContentWrapper className="text-wrapper">
        <StyledModalParagraph>
          <MarkdownText text={description} />
        </StyledModalParagraph>
        <InteractiveModalButton onCloseModal={onCloseModal} />
      </StyledContentWrapper>
    </StyledInteractiveTextMessage>
  );
};

InteractiveTextMessage.propTypes = {
  description: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
};

export default InteractiveTextMessage;
