import styled from 'styled-components';

const StyledFilterWrapper = styled.div`
  form {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &.mt-44 {
    margin-top: 48px;
  }
`;

StyledFilterWrapper.displayName = 'StyledFilterWrapper';

export default StyledFilterWrapper;
