import Banner from '../../components/Banner';
import { connect } from 'react-redux';

interface IState {
  instructorProfile: {
    hasBillingChangeRequest: boolean;
  };
}

export const mapStateToProps = ({
  instructorProfile: { hasBillingChangeRequest },
}: IState) => ({
  hasBillingChangeRequest,
});

export default connect(mapStateToProps, null)(Banner);
