import SharedAccordion from '../../../../shared/styles/Accordion';
import styled from 'styled-components';

const Accordion = styled(SharedAccordion)`
  margin-bottom: 0.5rem;

  h4 {
    word-break: break-word;
  }

  .accordion__button {
    cursor: ${({ isAccessible }) => (isAccessible ? 'pointer' : 'not-allowed')};

    h4 {
      color: ${({ isAccessible }) =>
        isAccessible ? 'var(--ds-color__indigo)' : 'rgba(34, 60, 80, 0.4)'};
    }
  }

  .accordion__button:before {
    margin: 0.5rem;
    height: 1rem;
    width: 1rem;
    border-top: 2px solid
      ${({ isAccessible }) =>
        isAccessible ? 'var(--ds-color__indigo)' : 'rgba(34, 60, 80, 0.4)'};
    border-left: 2px solid
      ${({ isAccessible }) =>
        isAccessible ? 'var(--ds-color__indigo)' : 'rgba(34, 60, 80, 0.4)'};
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: ${({ isAccessible }) =>
      isAccessible ? 'rotate(45deg)' : 'rotate(225deg)'};
  }
`;

export default Accordion;
