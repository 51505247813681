module.exports = (() => {
  var init = function() {
    addClassToLastCourse();
  };

  var addClassToLastCourse = function() {
    $('.attendee_course').last().addClass('last');
  };

  return {
    init: init
  };
})();
