import React, { Component } from 'react';
import ProductsService from '../../services/ProductsService';
import PayersService from '../../services/PayersService';
import StartDateService from '../../services/StartDateService';
import EnrollmentConfigurationService from '../../services/EnrollmentConfigurationService';
import LoadingSpinner from '../shared/loadingSpinner';
import AdminWrapper from '../shared/adminWrapper';
import Dropdown from '../shared/dropdown';
import Checkbox from '../shared/components/Checkbox';
import Input from '../shared/input';
import Form from '../shared/form';
import CFModal from '../shared/modal';
import FlashMessageContainer from '../shared/flashMessageContainer';
import { getAuthToken } from '../../utils/getAuthToken';
import { currencySign } from '../../utils/currencySign';
import { copyLink } from '../../utils/copyToClipboard';
import { formatDropdown } from '../../utils/formatDropdown';
import _ from 'lodash';

class AdminEnrollmentLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalOpen: false,
      showFlashMessage: false,
      errorMessages: [],
      flashMessage: {},
      linkGenerated: false,
      enrollLink: '',
      productsDropdown: [],
      payersDropdown: [],
      startDateDropdown: [],
      products: [],
      product: {},
      planType: '',
      paymentDue: '',
      startDateRequired: false,
      targetCompletionRequired: false,
      productId: null,
      payer: 0,
      bankTransfer: false,
      startDate: '',
      targetCompletionDate: '',
      trialEndDate: '',
      discount: 0,
      notes: '',
      preliminaryUsername: '',
      createUnpaidInvoice: false,
      authToken: getAuthToken(),
      payerIdentifier: '',
    };
  }

  componentDidMount() {
    this.getProducts();
    this.getPayers();
  }

  getProducts = () => {
    const productsService = new ProductsService();

    productsService
      .getProducts()
      .then((result) => {
        this.setState({
          products: result,
          productsDropdown: formatDropdown(result, false, 'name', 'id'),
        });
      })
      .catch((error) => this.handelRequestError(error));
  };

  getPayers = () => {
    const payersService = new PayersService();

    payersService
      .getPayers()
      .then((result) => {
        this.setState({
          payersDropdown: formatDropdown(result, false, 'name', 'id'),
          loading: false,
        });
      })
      .catch((error) => this.handelRequestError(error));
  };

  getStartDates = (id) => {
    const startDateService = new StartDateService();

    startDateService
      .getStartDates(id)
      .then((result) => {
        this.setState({
          startDateDropdown: formatDropdown(result, true, 'start', 'start'),
        });
      })
      .catch((error) => this.handelRequestError(error));
  };

  handelRequestError = (error) => {
    this.setState({
      errorMessages: error.message ? [error.message] : error,
      modalOpen: false,
      loading: false,
    });
  };

  handleInputChange = (value, name) => {
    if (name === 'discount' && value === '') {
      this.setState({ discount: 0 });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleModalClose = () => this.setState({ modalOpen: false });

  handleFinalModalClose = () => (window.location = document.referrer);

  resetDropdown = (name) => this.setState({ [name]: '' });

  handleSelectChange = (value, name) => {
    if (name === 'product') {
      this.handleProductChange(value);
    } else {
      this.setState({ [name]: value });
    }
  };

  handleProductChange = (value) => {
    this.setState({ productId: value });

    if (value !== '') {
      this.findProductById(value);
    } else {
      this.setState({
        product: {},
        startDateDropdown: [],
        startDateRequired: false,
        targetCompletionDate: '',
        trialEndDate: '',
        planType: '',
        paymentDue: '',
      });
    }
  };

  findProductById = (id) => {
    const productObj = this.state.products
      .filter((p) => p.id === parseInt(id))
      .shift();
    productObj.attributes.organizationSale ? '' : this.resetDropdown('payer');
    this.getStartDates(id);
    this.setState({
      product: productObj,
      bankTransfer: !productObj.attributes.organizationSale,
      startDateRequired: productObj.attributes.startDateRequired,
      targetCompletionRequired: productObj.attributes.targetCompletionRequired,
      targetCompletionDate: '',
      trialEndDate: '',
      planType: this.findPlanType(productObj),
      paymentDue: this.formatPaymentStr(productObj.attributes.paymentDue),
    });
  };

  findPlanType = (product) => {
    let paymentPlans = product.attributes.prices[0].paymentPlans;
    return paymentPlans.length > 0
      ? this.formatPaymentStr(paymentPlans[0]['planType'])
      : 'No payment plan defined';
  };

  formatPaymentStr = (str) =>
    str.charAt(0).toUpperCase() + str.split('_').join(' ').slice(1);

  getPrices = () => {
    let prices = this.state.product.attributes.prices;

    return prices.map(
      (price, i) =>
        `${currencySign(price['currency'])}${parseFloat(
          price['paymentPlans'][0].totalAmount
        ).toFixed(2)}${prices.length === i + 1 ? '' : ','} `
    );
  };

  getDiscountedPrices = () => {
    let prices = this.state.product.attributes.prices;

    return prices.map(
      (price, i) =>
        `${currencySign(price['currency'])}${this.calculateDiscount(
          parseFloat(price['paymentPlans'][0].totalAmount)
        )}${prices.length === i + 1 ? '' : ','} `
    );
  };

  calculateDiscount = (price) =>
    (price - (price * this.state.discount) / 100).toFixed(2);

  handleSubmit = () => {
    const manualEnrollmentSetupService = new EnrollmentConfigurationService();

    let {
      productId,
      startDate,
      payer,
      discount,
      bankTransfer,
      notes,
      authToken,
      planType,
      targetCompletionDate,
      trialEndDate,
      createUnpaidInvoice,
      preliminaryUsername,
      payerIdentifier,
    } = this.state;

    manualEnrollmentSetupService
      .createLink(
        productId,
        startDate,
        payer,
        discount,
        bankTransfer,
        notes,
        authToken,
        planType,
        targetCompletionDate,
        trialEndDate,
        createUnpaidInvoice,
        preliminaryUsername,
        payerIdentifier
      )
      .then((result) => {
        this.setState({
          linkGenerated: true,
          enrollLink: this.formatUrl(result.attributes.links),
        });
      })
      .catch((error) => {
        this.handelRequestError(error);
      });
  };

  formatUrl = (links) =>
    `https://careerfoundry.com/en/enroll/links/${links[0].url}`;

  getPayerName = () =>
    this.state.bankTransfer
      ? 'Bank Transfer'
      : this.state.payersDropdown.find(
          (x) => x.value === parseInt(this.state.payer)
        )['text'];

  handleForm = (event) => {
    event.preventDefault();
    if (this.validateDates()) {
      this.setState({
        modalOpen: true,
        errorMessages: [],
      });
    } else {
      this.setState({
        errorMessages: ['Target completion date must be after the start date.'],
      });
    }
  };

  validateDates = () =>
    this.state.targetCompletionDate
      ? this.state.targetCompletionDate > this.state.startDate
      : true;

  handleCopyToClipboard = () => {
    const messageObj = copyLink(this.state.enrollLink);

    this.setState({
      showFlashMessage: true,
      flashMessage: messageObj,
    });
  };

  handleCheckboxClick = (event) =>
    this.setState({ [event.target.name]: event.target.checked });

  renderFlashMessage = () => (
    <FlashMessageContainer
      status={this.state.flashMessage.status}
      message={this.state.flashMessage.message}
    />
  );

  renderDropdown = (
    label,
    name,
    options,
    disabled,
    required,
    resetDropdown
  ) => (
    <Dropdown
      labelText={label}
      name={name}
      options={options}
      onChange={this.handleSelectChange}
      disabled={disabled}
      required={required}
      resetDropdown={resetDropdown}
    />
  );

  renderCheckbox = (label, name, checked) => (
    <Checkbox
      labelText={label}
      name={name}
      onClick={this.handleCheckboxClick}
      defaultChecked={checked}
    />
  );

  renderInput = (label, name, type, required, disabled) => (
    <Input
      labelText={label}
      name={name}
      type={type}
      required={required}
      disabled={disabled}
      onChange={this.handleInputChange}
      classes=''
    />
  );

  renderBankTransferStr = () => (
    <p>
      Payer: <span className='cf-bold'>Bank Transfer</span>
    </p>
  );

  renderErrorMessages = () => {
    return this.state.errorMessages.map((error, i) => {
      return (
        <p className='cf-text-right error_text' key={i}>
          {error.title || error}
        </p>
      );
    });
  };

  renderModalContent = () =>
    this.state.linkGenerated
      ? this.renderFinalModal()
      : this.renderConfirmationModal();

  renderFinalModal = () => (
    <div className='cf-text-center admin__enrollment_links__final_modal'>
      <h2>Saved</h2>
      <p>{`Here is the custom enroll link. You'll be able to find it in the Custom Enrolls page on your Admin Dashboard`}</p>
      <input
        type='text'
        className='copy_to_clipboard--link'
        defaultValue={this.state.enrollLink}
        disabled={true}
      />
      <button
        onClick={this.handleCopyToClipboard}
        className='button--primary-darkblue'
      >
        Copy
      </button>
      <a
        onClick={() => this.handleFinalModalClose()}
        className='button--large button--primary-petrol'
      >
        Done
      </a>
    </div>
  );

  renderConfirmationModal = () => (
    <div>
      <h2>Review & Confirm</h2>
      <p className='cf-text-center'>
        Here are the details of the custom enroll you're about to create.
      </p>
      <main>
        <p>
          Product:{' '}
          <span className='cf-bold'>{this.state.product.attributes.name}</span>
        </p>
        <p>
          Payment Plan: <span className='cf-bold'>{this.state.planType}</span>
        </p>
        <p>
          Payer: <span className='cf-bold'>{this.getPayerName()}</span>
        </p>
        <p>
          Loan ID: <span className='cf-bold'>{this.state.payerIdentifier}</span>
        </p>
        <p>
          Start Date:{' '}
          <span className='cf-bold'>
            {this.state.startDate
              ? this.state.startDate
              : 'Student will select'}
          </span>
        </p>
        {this.state.targetCompletionDate && (
          <p>
            Target Completion Date:{' '}
            <span className='cf-bold'>{this.state.targetCompletionDate}</span>
          </p>
        )}
        {this.state.trialEndDate && (
          <p>
            Trial End Date:{' '}
            <span className='cf-bold'>{this.state.trialEndDate}</span>
          </p>
        )}
        <p>
          Payment Due: <span className='cf-bold'>{this.state.paymentDue}</span>
        </p>
        <p>
          Notes: <span className='cf-bold'>{this.state.notes}</span>
        </p>
        <p>
          Preliminary Username:{' '}
          <span className='cf-bold'>{this.state.preliminaryUsername}</span>
        </p>
        <p>
          Create Unpaid Invoice:{' '}
          <span className='cf-bold'>
            {this.state.createUnpaidInvoice ? 'Yes' : 'No'}
          </span>
        </p>
        <p>
          Total amount before discount:{' '}
          <span className='cf-bold'>{this.getPrices()}</span>
        </p>
        <p>
          Total amount after discount:
          <span className='cf-bold'> {this.getDiscountedPrices()}</span>
          <span className='cf-tiny'>
            {' '}
            {this.state.discount != 0
              ? `(Discount of ${this.state.discount}% is applied)`
              : ''}
          </span>
        </p>
      </main>
      <footer>
        <a
          onClick={() => this.handleModalClose()}
          className='button--large button--primary-disabled'
        >
          Cancel
        </a>
        <a
          onClick={() => this.handleSubmit()}
          className='button--large button--primary-petrol'
        >
          Done
        </a>
      </footer>
    </div>
  );

  renderProductField = () => (
    <div>
      {this.renderDropdown(
        'Products',
        'product',
        this.state.productsDropdown,
        false,
        true,
        () => this.resetDropdown('product')
      )}
      <p>
        First, select the product.{' '}
        <b>Please note that AfA and B2B customers have their own products</b>{' '}
        and cannot be enrolled in any of the standard products.
      </p>
    </div>
  );

  renderPayerField = () => (
    <div>
      {this.renderDropdown(
        'Payer',
        'payer',
        this.state.payersDropdown,
        false,
        true,
        () => this.resetDropdown('payer')
      )}
      <p>
        B2B and AfA products are not paid for by students but a separate
        organization. Please select the organization paying for this student’s
        product and make sure the organization matches the product (e.g. an AfA
        payer paying for AfA product etc)
      </p>
    </div>
  );

  renderPaymentIdentifierField = () => (
    <div>
      {this.renderInput('Loan ID', 'payerIdentifier', 'text', false, false)}
      <p>If this is a loan student, add the loan ID here.</p>
    </div>
  );

  renderStartDateField = () => (
    <div>
      {this.renderDropdown(
        'Start Date (Optional)',
        'startDate',
        this.state.startDateDropdown,
        false,
        this.state.startDateRequired,
        () => this.resetDropdown('startDate')
      )}
      <p>
        Leave this blank if you want the student to be able to pick a start date
        themselves. For bank transfer students, the earliest possible date
        displayed to them is at least 14 days in the future. AFA students must
        have a start date selected.
      </p>
    </div>
  );

  renderTargetCompletionField = () => (
    <>
      <div>
        {this.renderInput(
          'Target Completion Date',
          'targetCompletionDate',
          'date',
          true,
          false
        )}
        <p>AFA students must have the target completion date set.</p>
      </div>
      <div>
        {this.renderInput(
          'Trial End Date',
          'trialEndDate',
          'date',
          true,
          false
        )}
        <p>AFA students must have the trial end date set.</p>
      </div>
    </>
  );

  renderDiscountField = () => (
    <div>
      {this.renderInput(
        'Discount %',
        'discount',
        'number',
        false,
        !this.state.bankTransfer
      )}
      <p>
        Leave this blank unless you are absolutely certain the student qualifies
        for a discount. All upfront payment plans will have a 5% discount that
        will also be applied in addition to any discount added here so keep that
        in mind when applying discounts.
      </p>
    </div>
  );

  renderNoteField = () => (
    <div>
      {this.renderInput('Notes', 'notes', 'text', false, false)}
      <p>
        These notes are only visible to other CF admins. They will not be
        visible to the student. You can use the notes field to write the name of
        the student to whom you are sending the link for admin use.
      </p>
    </div>
  );

  renderPreliminaryUsernameField = () => (
    <div>
      {this.renderInput(
        'Preliminary Username',
        'preliminaryUsername',
        'text',
        ''
      )}
      <p>
        Add username here if you already have the users name who would use this
        enrollment link
      </p>
    </div>
  );

  renderCreateUnpaidInvoiceField = () => (
    <div>
      {this.renderCheckbox(
        'Create Unpaid Invoice',
        'createUnpaidInvoice',
        this.state.createUnpaidInvoice
      )}
      <p>
        Creates an invoice for unpaid payment for B2B bank transfer customers
        that need an invoice before paying. Invoice is not sent to the customer
        but is available in Quaderno
      </p>
    </div>
  );

  render() {
    return (
      <AdminWrapper headerText='Custom Enroll'>
        {this.state.loading ? (
          <LoadingSpinner />
        ) : (
          <Form
            onSubmit={this.handleForm}
            className='admin__enrollment_links__form'
          >
            {this.renderProductField()}
            {this.state.bankTransfer ? '' : this.renderPayerField()}
            {this.renderPaymentIdentifierField()}
            <div className='text_container'>
              {this.state.bankTransfer ? this.renderBankTransferStr() : ''}
              <p>
                Payment Plan:{' '}
                <span className='cf-bold'>{this.state.planType}</span>
              </p>
              <p>
                Total Amount:{' '}
                <span className='cf-bold'>
                  {_.isEmpty(this.state.product) ? '' : this.getPrices()}
                </span>
              </p>
              <p>
                Payment Due:{' '}
                <span className='cf-bold'>{this.state.paymentDue}</span>
              </p>
            </div>
            {this.renderStartDateField()}
            {this.state.targetCompletionRequired
              ? this.renderTargetCompletionField()
              : ''}
            {this.renderDiscountField()}
            <div className='text_container'>
              <p>
                Final Amount after Discount:{' '}
                <span className='cf-bold'>
                  {_.isEmpty(this.state.product)
                    ? ''
                    : this.getDiscountedPrices()}
                </span>
              </p>
            </div>
            {this.renderNoteField()}
            {this.renderPreliminaryUsernameField()}
            {this.renderCreateUnpaidInvoiceField()}
            {this.state.errorMessages ? this.renderErrorMessages() : ''}
            <input
              type='submit'
              className='button--large button--primary-petrol'
              value='Generate enroll link'
            />
            <CFModal
              appElement='.admin__enrollment_links'
              isOpen={this.state.modalOpen}
              onRequestClose={this.handleModalClose}
              className='admin__enrollment_links__modal'
            >
              {this.state.modalOpen && this.renderModalContent()}
            </CFModal>
          </Form>
        )}
        {this.state.showFlashMessage && this.renderFlashMessage()}
      </AdminWrapper>
    );
  }
}

export default AdminEnrollmentLinks;
