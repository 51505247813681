export const EnrollType = Object.freeze({
  normal: 'normal',
  bank: 'bank',
  organization: 'organization',
  offer: 'offer'
});

export const getEnrollType = response => {
  if(_.isEmpty(response.customEnroll)) {
    if(response.offerCode) {
      return EnrollType.offer
    } else {
      return EnrollType.normal
    }
  } else {
    if(response.customEnroll.payerName) {
      return EnrollType.organization
    } else {
      return EnrollType.bank
    }
  }
};
