import PaymentBehaviour from './payment_behaviour.js';
import TableFilter from './table_filter.js';

document.addEventListener('DOMContentLoaded', function () {
  const input = 'input#input-searchable';
  const table = 'table.table-searchable';
  const tableFilter = new TableFilter(input, table);

  tableFilter.init();

  PaymentBehaviour.init();
});
