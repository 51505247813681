import styled from 'styled-components';

const CompletionWrapper = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0 rgba(51, 51, 51, 0.1);
  padding: 32px 40px 24px;
  justify-content: center;

  .message__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      display: flex;
    }
  }
`;

CompletionWrapper.displayName = 'CompletionWrapper';

export default CompletionWrapper;
