import React, { useMemo, useState } from 'react';
import Button from '../shared/Button';
import PropTypes from 'prop-types';
import StyledButtonWrapper from './styles/StyledButtonWrapper';
import { formatSocialPayload } from '../../helpers';
import { updateSocialLinks } from '../../../CareerSupportCenter/actions';

const ProfileButtons = ({
  isEditView,
  handleToggle,
  jobSeekingProfile = {},
  updateJobSeekingProfile,
  setViewType,
  reverseProfileEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const wordCount = useMemo(() => {
    const count = jobSeekingProfile && jobSeekingProfile.motivationStatement ? jobSeekingProfile.motivationStatement.split(' ').length : 0;
    return count;
  }, [jobSeekingProfile.motivationStatement]);

  const linkedInUrl = useMemo(() => jobSeekingProfile.socials ? jobSeekingProfile.socials.find((social) => social.name.toLowerCase() === 'linkedin') : null, [jobSeekingProfile.socials]);
  const isButtonDisabled = wordCount < 90 || !jobSeekingProfile?.attachment?.s3Url || !linkedInUrl;

  const handleSaveChange = async () => {
    setLoading(true);
    const socials = formatSocialPayload(jobSeekingProfile.socials);

    if (socials.length > 0) {
      await updateSocialLinks(socials);
    }
    await updateJobSeekingProfile({ ...jobSeekingProfile });
    setLoading(false);
    if (typeof setViewType === 'function') {
      setViewType(false);
    }
  };

  const handleReverseEdit = () => {
    handleToggle();
    reverseProfileEdit();
  };

  return (
    <StyledButtonWrapper>
      {
        isEditView
          ? <>
            <Button
              className="ds-button__primary"
              label='Save changes'
              disabled={isButtonDisabled}
              loading={loading}
              onClick={ handleSaveChange }
            />
            <Button
              className="ds-button__secondary"
              label='Cancel'
              onClick={ handleReverseEdit }
            />
          </>
          : <>
            <Button
              className="ds-button__primary"
              label='Edit'
              onClick={ handleToggle }
            />
          </>
      }
    </StyledButtonWrapper>
  );
};

ProfileButtons.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  isEditView: PropTypes.bool.isRequired,
  jobSeekingProfile: PropTypes.shape({
    attachment: PropTypes.shape({
      attachableId: PropTypes.number,
      attachableType: PropTypes.string,
      contentType: PropTypes.string,
      fileIcon: PropTypes.string,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      id: PropTypes.number,
      s3Url: PropTypes.string,
      subtype: PropTypes.string,
    }),
    country: PropTypes.string,
    graduated: PropTypes.bool,
    graduation_date: PropTypes.string,
    id: PropTypes.number.isRequired,
    motivationStatement: PropTypes.string,
    portfolio: PropTypes.string,
    program: PropTypes.string,
    progress: PropTypes.number,
    slug: PropTypes.string,
    socials: PropTypes.array,
    specialization: PropTypes.string,
    state: PropTypes.string,
    status: PropTypes.string.isRequired,
    studentName: PropTypes.string,
    willingToRelocate: PropTypes.bool,
    willingToWorkRemote: PropTypes.bool,
  }),
  reverseProfileEdit: PropTypes.func.isRequired,
  setViewType: PropTypes.func.isRequired,
  updateJobSeekingProfile: PropTypes.func.isRequired,
};

export default ProfileButtons;
