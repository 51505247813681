import { Body, Button, Header } from '@careerfoundry/bubble';
import React, { useState } from 'react';
import { RejectionModalContent } from './styles';
import { formatRejectionParams } from './helpers';

interface RejectionModalProps {
  modalAction: {
    action: string;
    attribute: string;
  };
  handleUpdateAdmission: (params: any) => void;
}

const RejectionModal: React.FC<RejectionModalProps> = ({
  modalAction,
  handleUpdateAdmission,
}) => {
  const [reason, setReason] = useState<string>('');

  return (
    <RejectionModalContent>
      <Header tag='h4' variant='h4'>
        Please explain the issue
      </Header>
      <Body tag='p' variant='medium'>
        Tell the candidate what went wrong and describe what they need to do
        next. Try to make your explanation as short and simple as possible, to
        avoid follow up.
      </Body>
      <textarea
        onChange={(e) => setReason(e.target.value)}
        value={reason}
        name='rejectionReason'
        placeholder='Type your explanation here'
        aria-label='reason-input'
      />
      <Button
        label='Send'
        onClick={() =>
          handleUpdateAdmission(formatRejectionParams(modalAction, reason))
        }
        size='medium'
        variant='primary'
        disabled={!reason}
      />
    </RejectionModalContent>
  );
};

export default RejectionModal;
