import React, { useEffect, useState } from 'react';
import {
  getInstructorSignupDetails,
  submitInstructorSignupForm,
} from './actions';
import InstructorSignupForm from '../../components/InstructorSignupForm/index.js';

const InstructorSignupContainer = () => {
  const [instructorDetails, instructorDetailsSetter] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });
  const [loading, loadingSetter] = useState(false);
  const [errorMessage, errorMessageSetter] = useState('');

  useEffect(() => {
    loadingSetter(true);
    getInstructorSignupDetails()
      .then((data) => {
        const { email, firstName, lastName } = data.data.data;
        instructorDetailsSetter({
          email,
          firstName,
          lastName,
        });
      })
      .catch((error) => {
        errorMessageSetter(error || 'Something went wrong, please try again');
      })
      .finally(() => {
        loadingSetter(false);
      });
  }, []);

  const handleFormSubmit = ({ ...instructorDetails }) => {
    loadingSetter(true);
    submitInstructorSignupForm({ ...instructorDetails })
      .then(({ data: { redirectUrl } }) => {
        window.location.href = redirectUrl;
      })
      .catch(({ errors }) => {
        errorMessageSetter(
          errors.toString() || 'Something went wrong, please try again'
        );
      })
      .finally(() => {
        loadingSetter(false);
      });
  };

  return (
    <InstructorSignupForm
      instructorDetails={instructorDetails}
      handleFormSubmit={handleFormSubmit}
      loading={loading}
      errorMessage={errorMessage}
    />
  );
};

export default InstructorSignupContainer;
