import styled from 'styled-components';

const StyledTag = styled.div`
  min-width: 100px;
  width: max-content;
  padding: 5px;
  border: 1px solid #a9a9a9;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  margin-right: 10px;
  text-transform: capitalize;

  ${({ isActive }) =>
    isActive &&
    `
    background: #504e4e;
    color: white;
    `}

  ${({ isEdit }) =>
    isEdit &&
    `
    cursor: pointer;
    `}
`;

StyledTag.displayName = 'StyledTag';

export default StyledTag;
