import React, { useEffect, useState } from 'react';
import CFModal from '../shared/modal.jsx';
import UserService from '../../services/UserService';
import UserSpecializationService from '../../services/UserSpecializationService';

export const AdminUserSpecialization = () => {
  const [userId, setUserId] = useState(null);
  const [selectedSpecializationId, setSelectedSpecializationId] =
    useState(null);
  const [loading, setLoading] = useState(true);
  const [specialization, setSpecialization] = useState(null);
  const [availableSpecializations, setAvailableSpecializations] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const selectText = 'Select a specialization';

  useEffect(() => {
    const parsedId = parseInt(
      document.querySelector('.admin_user_profile__wrapper').dataset.id
    );
    const userService = new UserService(parsedId);

    userService.getUserData().then((result) => {
      setUserId(result.id);
      setSpecialization(result.attendeeIntention);
      setLoading(false);
    });
  }, []);

  const handleEditClick = () => {
    const userSpecializationService = new UserSpecializationService();

    userSpecializationService.getSpecializations(userId).then((result) => {
      setAvailableSpecializations(result);
      setModalIsOpen(true);
    });
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const handleSubmit = (e) => {
    const userSpecializationService = new UserSpecializationService();

    e.preventDefault();
    setLoading(true);
    if (selectedSpecializationId) {
      userSpecializationService
        .updateSpecialization(specialization.id, selectedSpecializationId)
        .then((result) => {
          setSpecialization(result);
        })
        .catch((error) => {
          setErrorMessage(`There was an error: ${error}`);
        });
    }
    setLoading(false);
    setModalIsOpen(false);
  };

  const renderModalContent = () => (
    <main>
      <h2 className="cf-text-left">Edit User Specialization</h2>
      <form className="cf-form" onSubmit={handleSubmit}>
        <select id="specialization-dropdown" onChange={(e) => handleSelect(e)}>
          <option>{selectText}</option>
          {availableSpecializations.map((specialization) => {
            return (
              <option
                value={specialization.id}
                key={specialization.id}
                onChange={(event) => handleClick(specialization.id, event)}
              >
                {specialization.name}
              </option>
            );
          })}
        </select>
        <button className="button--full button--primary-petrol">Done</button>
      </form>
      <p className="errorContainer cf-small cf-text-right">{errorMessage}</p>
    </main>
  );

  const handleSelect = (e) => {
    if (e.target.value !== selectText) {
      setSelectedSpecializationId(e.target.value);
    } else {
      setSelectedSpecializationId(specialization.course_id);
    }
  };

  const renderLoaderSpinner = () => (
    <div
      className="loading_spinner_wrapper"
      dangerouslySetInnerHTML={{ __html: CF.html.loadingSpinner }}
    />
  );

  return (
    specialization &&
    !specialization.has_started && (
      <div className="AdminUserSpecializations cf-content">
        <div>
          <i className="fa fa-user-circle"></i>
          <p className="cf-allcaps">User Specialization</p>
        </div>

        {loading ? (
          renderLoaderSpinner()
        ) : (
          <div>
            <label
              className="cf-course-label cf-role-label"
              key={specialization.id}
            >
              {specialization.name ? specialization.name : 'N/A'}
            </label>
            <a className="cf-small--bold" onClick={() => handleEditClick()}>
              Edit User Specialization
            </a>

            <CFModal
              appElement=".admin_user__specializations"
              isOpen={modalIsOpen}
              onRequestClose={handleModalClose}
              className="admin_user__specializations__modal"
            >
              {modalIsOpen ? renderModalContent() : ''}
              {loading ? renderLoaderSpinner() : ''}
            </CFModal>
          </div>
        )}
      </div>
    )
  );
};

export default AdminUserSpecialization;
