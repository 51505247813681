import styled from 'styled-components';

const FormWrapper = styled.div`
  width: 221px;
  position: relative;

  button {
    margin-top: 48px;
  }

  .error-text {
    position: absolute;
    top: 46px;
  }
`;

FormWrapper.displayName = 'FormWrapper';

export { FormWrapper };
