import styled from 'styled-components';

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0 rgba(51, 51, 51, 0.1);
  margin-bottom: 15px;
  margin-top: 15px;
`;

Card.displayName = 'Card';

export default Card;
