import { ChannelList, ChannelListMessenger, Chat } from 'stream-chat-react';
import { Header, MessagingPageLink, Wrapper } from './styles';
import ChannelButton from '../../containers/ChannelButton';
import CustomChannelPreview from '../../../MessagingPage/components/CustomChannelPreview';
import PropTypes from 'prop-types';
import React from 'react';
import { chatClient } from '../../../index';

const MessagingPreview = ({
  filters,
  sort,
  onOpenMessagingWindow,
  onToggleMsgPreview,
}) => {
  const onOpenChannel = (channel) => {
    onOpenMessagingWindow(channel);
    onToggleMsgPreview();
  };
  const customSearchResultItem = (props) => (
    <CustomChannelPreview
      {...props}
      uuid={filters.members.$in[0]}
      onOpenChannel={onOpenChannel}
    />
  );
  const additionalProps = {
    SearchResultItem: customSearchResultItem,
    searchForChannels: true,
    searchFunction: async (ChannelSearchFunctionParams, event) => {
      ChannelSearchFunctionParams.setQuery(event.target.value);
      const channels = await chatClient.queryChannels({
        ...filters,
        'member.user.name': { $autocomplete: event.target.value || 'z' },
      });
      ChannelSearchFunctionParams.setResults(channels);
    },
  };
  return (
    <Wrapper>
      <div>
        <Chat client={chatClient} theme={'messaging light'}>
          <Header>
            <span>Messaging</span>
            <MessagingPageLink href='/en/messaging'>
              View all messages
            </MessagingPageLink>
          </Header>
          <ChannelList
            filters={filters}
            sort={sort}
            showChannelSearch
            List={ChannelListMessenger}
            additionalChannelSearchProps={additionalProps}
            Preview={ChannelButton}
          />
        </Chat>
      </div>
    </Wrapper>
  );
};

MessagingPreview.propTypes = {
  filters: PropTypes.object,
  onOpenMessagingWindow: PropTypes.func,
  onToggleMsgPreview: PropTypes.func,
  sort: PropTypes.object,
};

export default MessagingPreview;
