import styled from 'styled-components';

const StyledCardParagraph = styled.p`
  margin-bottom: 25px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

StyledCardParagraph.displayName = 'StyledCardParagraph';

export default StyledCardParagraph;
