import React from 'react';

const PlanYourProject = () => (
  <>
    <p className="ds-typography__body break">Now you have a rough idea of what you’re doing and why, it’s time to get specific. This section is all about breaking your “big idea” down into realistic, manageable tasks with deadlines to make sure that you can actually accomplish what you set out to do.</p>
    <p className="ds-typography__body--bold">Scope your work</p>
    <ul className="break">
      <li>What is the focus of this project? Is it a complete end to end product or more of a UI or UX based project?</li>
      <li>How much time do I want to spend on this project?</li>
      <li>What is my vision of success (i.e. what does success look like within the context of this project?) and when will I know this project is complete?</li>
      <li>What are some anticipated barriers that I might encounter, and how will I overcome them?</li>
      <li>Is this something that will be completed from scratch, or is this a rework?</li>
      <li>Do I currently have all the files, software, and tools I need to complete this project?</li>
    </ul>
    <p className="ds-typography__body--bold">What do I need to do and when?</p>
    <ul className="break">
      <li>What are my key deliverables? (Wireframes, UI of homepage, etc.)</li>
      <li>What are the steps I need to take to complete this project and when?</li>
      <li>What backup plans will I have in place if I miss a deadline?</li>
    </ul>
    <p className="ds-typography__body--bold">What do I need to do and when?</p>
    <ul className="break">
      <li>Short description of what I want the final project result to look like</li>
    </ul>
    <p className="ds-typography__body--bold">Validate your idea</p>
    <p className="ds-typography__body break">It’s essential to document your work as you move through your project. This will save you quite a bit of time at a later point, especially if you intend to add this project to your portfolio.</p>
    <p className="ds-typography__body--bold">Barriers</p>
    <p className="ds-typography__body">If you encounter unanticipated problems along the way, don’t worry; that’s all part of the process. It’s how you handle them that matters!  Be sure to document them here, as well as any steps you take to  solve them. These will be useful to refer back to in your reflection.</p>
  </>
);

export default PlanYourProject;
