import Card from './styles/Card';
import CardBody from './styles/CardBody';
import CardInner from './styles/CardInner';
import Controls from '../../containers/Controls';
import DefaultGoalReminderModal from '../../containers/DefaultGoalReminderModal';
import List from '../../containers/List';
import PropTypes from 'prop-types';
import React from 'react';

const App = ({ goal, weekEstimate }) => (
  <>
    <Card style={{ backgroundColor: 'transparent' }}>
      <CardInner>
        <CardBody style={{ paddingTop: '16px' }}>
          <small className='weekDate ds-typography__small--bold ds-color__grey'>
            How many tasks will you submit this week? Use the +/- controls to
            adjust your goal.
          </small>
          <h5 className='ds-typography__h5 ds-color__indigo'>
            This Week: {goal} Task{goal !== 1 ? 's' : ''}
          </h5>
          <small className='weekEstimate ds-typography__small ds-color__grey'>
            Total Weekly Estimate {weekEstimate}
          </small>
        </CardBody>
        <Controls />
      </CardInner>
      <List />
      <DefaultGoalReminderModal />
    </Card>
  </>
);

App.propTypes = {
  goal: PropTypes.number.isRequired,
  weekEstimate: PropTypes.string,
};

export default App;
