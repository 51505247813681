import {
  ClearFilterButton,
  Label,
  SelectWrapper,
  Wrapper,
  customSelectStyles,
} from './styles';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const InstructorFilters = ({
  filterInstructors,
  instructorTags,
}) => {
  const [tags, setTags] = useState([]);
  const [tagsToHide, setTagsToHide] = useState([]);
  const [tagsToShow, setTagsToShow] = useState([]);

  useEffect(() => {
    instructorTags && setTags(formatTags(instructorTags));
  }, [instructorTags]);

  const formatTags = list => list.map(tag => ({ label: tag, value: tag }));
  const clearFilters = () => {
    filterInstructors([], []);
    setTagsToHide([]);
    setTagsToShow([]);
  };

  return (
    <Wrapper>
      <div>
        <SelectWrapper>
          <Label className="ds-typography_body--bold">Tags to hide</Label>
          <Select
            name="tagsToHide"
            value={tagsToHide}
            options={tags}
            onChange={selectedOption => setTagsToHide(selectedOption)}
            isMulti
            styles={customSelectStyles}
            placeholder="Choose tags"
            components={{ IndicatorSeparator: () => null }}
          />
        </SelectWrapper>
        <SelectWrapper>
          <Label className="ds-typography_body--bold">Tags to show</Label>
          <Select
            name="tagsToShow"
            value={tagsToShow}
            options={tags}
            onChange={selectedOption => setTagsToShow(selectedOption)}
            isMulti
            styles={customSelectStyles}
            placeholder="Choose tags"
            components={{ IndicatorSeparator: () => null }}
          />
        </SelectWrapper>
        <button
          className="ds-button__primary"
          onClick={() => filterInstructors(tagsToShow, tagsToHide)}
        >
          Filter
          <i className="ds-icon__filter"></i>
        </button>
      </div>
      <ClearFilterButton onClick={clearFilters}>
        <i className="ds-icon__close-circle-fill"></i>
        <p className="ds-typography__body--bold">Clear filters</p>
      </ClearFilterButton>
    </Wrapper>
  );
};

InstructorFilters.propTypes = {
  filterInstructors: PropTypes.func.isRequired,
  instructorTags: PropTypes.arrayOf(PropTypes.string),
};

export default InstructorFilters;
