import React from 'react';
import Modal from 'react-modal';

/**
 * CFModal view, wrapper for React-Modal
 * @param props { isOpen, style, onAfterOpen, onRequestClose, appElement }
 */

 const defaultStyles = {
   overlay: {
    backgroundColor: 'rgba(34,60,80,.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
   content: {
    width: '770px',
    minHeight: '400px',
    maxWidth: '90%',
    padding: '60px',
    backgroundColor: '#fff',
    boxShadow: '10px 10px 10px 0 rgba(51,51,51,.2)',
    outline: 'none'
  }
 }

const CFModal = props => {

  Modal.setAppElement(props.appElement);

  return (
    <Modal
      isOpen={props.isOpen}
      style={{content: {...defaultStyles.content, ...(props.style ? props.style.content : {})}, overlay: {...defaultStyles.overlay, ...(props.style ? props.style.overlay : {})}}}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      className={`${props.className} cf-content`}
    >
      <div>
        {props.children}
      </div>
    </Modal>
  )
};

export default CFModal;
