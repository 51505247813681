import Button from '../../EmployerConnector/modals/shared/Button';
import PropTypes from 'prop-types';
import React from 'react';
import StyledButtonWrapper from './styles/StyledButtonWrapper';
import StyledHeaderContent from './styles/StyledHeaderContent';
import StyledHeaderImage from './styles/StyledHeaderImage';
import StyledHeaderInfographic from './styles/StyledHeaderInfographic';

const HeaderInfographic = ({
  isPreview = true,
  buttonLoading,
  onButtonClick,
}) => {
  return (
    <StyledHeaderInfographic isPreview={isPreview}>
      <StyledHeaderImage isPreview={isPreview} />
      <StyledHeaderContent isPreview={isPreview}>
        {isPreview && (
          <h4 className="ds-typography__h4">Track your job applications</h4>
        )}
        <ul>
          <li className="ds-typography__body">
            Organize and track your applications
          </li>
          <li className="ds-typography__body">
            Stay on top of your job search
          </li>
        </ul>
        <StyledButtonWrapper>
          <Button
            className="ds-button__primary"
            label="Add new application"
            loading={buttonLoading}
            onClick={() => onButtonClick()}
          />
          {isPreview && (
            <a
              href="/en/job_applications/"
              className="ds-button__secondary"
              target="_blank"
            >
              Open Full View
            </a>
          )}
        </StyledButtonWrapper>
      </StyledHeaderContent>
    </StyledHeaderInfographic>
  );
};

HeaderInfographic.propTypes = {
  buttonLoading: PropTypes.bool,
  isPreview: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
};

export default HeaderInfographic;
