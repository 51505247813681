import FormField from '../../../../../EmployerConnector/modals/shared/FormField';
import styled from 'styled-components';

const StyledFormField = styled(FormField)`
  margin-bottom: 29px;
  position: relative;
  align-items: center;
  .large-label {
    width: 40%;
  }
  input,
  select,
  textarea {
    height: 40px;
    padding: 5px 4px 4px 10px;
    border-radius: 4px;
    border: solid 2px var(--ds-color__grey--400);
    ::placeholder {
      color: var(--ds-color__indigo);
    }
    :focus::placeholder {
      color: transparent;
    }
    :focus {
      outline: none;
      border-color: var(--ds-color__petrol);
    }
  }

  input,
  select {
    width: 232px;
  }

  #callRequestScreenshot {
    display: none;
  }

  .tooltip {
    display: inline-block;
  }

  .upload-button {
    display: block;
    margin-bottom: 5px;
    width: 123px;
  }

  textarea {
    min-height: 57px;
    width: 100%;
  }

  .requestFile {
    i {
      margin-left: 10px;
      &:hover {
        opacity: 0.8;
      }
      transform: rotate(90deg);
    }
    a {
      text-decoration: underline;
    }
  }

  label {
    white-space: nowrap;
  }

  i {
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${({ isSearch }) =>
    isSearch &&
    `
    margin-bottom: 0px;
  `}
`;

StyledFormField.displayName = 'StyledFormField';

export default StyledFormField;
