import { constants } from './constants';

export const initialState = {
  afa: null,
  loading: true,
  modalAction: '',
  reasons: [],
  requests: [],
  role: '',
  selectedRequest: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.LOAD_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        afa: action.data.afa,
        loading: false,
        reasons: action.data.listOfReasons,
        requests: action.data.requests,
        role: action.data.role,
      };
    case constants.CREATE_TIME_OFF_SUCCESS:
      return {
        ...state,
        modalAction: 'createdSuccess',
        requests: [action.data, ...state.requests],
      };
    case constants.HANDLE_MODAL_CLOSE:
      return {
        ...state,
        modalAction: '',
        selectedRequest: null,
      };
    case constants.HANDLE_DELETE_CONFIRMATION:
      return {
        ...state,
        modalAction: 'delete',
        selectedRequest: action.slug,
      };
    case constants.DELETE_TIME_OFF_REQUEST:
      return {
        ...state,
        modalAction: 'deletedSuccess',
        requests: state.requests.filter((el) => el.slug !== action.data.slug),
        selectedRequest: null,
      };
    default:
      return state;
  }
}
