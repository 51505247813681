import { Body } from '@careerfoundry/bubble';
import React from 'react';
import { Wrapper } from './styles';

interface FileUploadErrorProps {
  errorMessage: string;
}

const FileUploadError = ({ errorMessage }: FileUploadErrorProps) => (
  <Wrapper>
    <Body tag='p' variant='medium'>
      {errorMessage}
    </Body>
  </Wrapper>
);

export default FileUploadError;
