import { TabsWrapper, TypeSelector } from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const instructorTypeTitles = [
  { key: 'tutors', value: 'Cohort Tutors' },
  { key: 'mentors', value: 'Cohort Mentors' },
];

const InstructorTypeSelector = ({
  activeInstructors,
  setInstructorsTab,
  totalFreeCapacity,
  csvDownloadURL,
}) => {
  return (
    <TabsWrapper>
      <TypeSelector className="ds-tabs">
        {instructorTypeTitles.map(({ key, value }) =>
          <div
            key={key}
            className={`ds-typography__body-large ds-tabs__item ${activeInstructors === key ? 'active' : ''}`}
            onClick={() => setInstructorsTab(key)}
          >
            {value}
          </div>,
        )}
      </TypeSelector>
      <div>
        <span className="ds-typography__body--bold">Capacity: {totalFreeCapacity}</span>
        <a href={csvDownloadURL}><i className="ds-icon__transfer-download" title="Download CSV"></i></a>
      </div>
    </TabsWrapper>
  );
};

InstructorTypeSelector.propTypes = {
  activeInstructors: PropTypes.string.isRequired,
  csvDownloadURL: PropTypes.string,
  setInstructorsTab: PropTypes.func.isRequired,
  totalFreeCapacity: PropTypes.number.isRequired,
};

export default InstructorTypeSelector;
