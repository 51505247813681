import { FormColumn, FormWrapper } from './styles';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { chunk, omit } from 'lodash';
import { defaultState, populatedState } from './helpers';
import Elements from './specialization/';
import Loading from '../../../../shared/components/Loading';
import PropTypes from 'prop-types';

const attributeElements = (state, attributes, onChange) => {
  return attributes.map((attr) => {
    const currentValue = state[attr.property];
    return Elements.Builder(
      attr.type,
      attr,
      onChange,
      currentValue,
      state.candidate_role
    );
  });
};

const handleOnChange = (event, type, state, setState) => {
  const { name, value, checked } = event.target;
  const property = name.replace('[]', '');

  switch (type) {
    case 'multiple':
      if (checked) {
        state[property].push(parseInt(value));
        setState((prevState) => ({
          ...prevState,
          [property]: state[property],
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [property]: state[property].filter((id) => {
            return id !== parseInt(value);
          }),
        }));
      }
      break;
    case 'boolean':
      setState((prevState) => ({ ...prevState, [name]: checked }));
      break;
    case 'text':
    default:
      setState((prevState) => ({ ...prevState, [name]: value }));
      break;
  }
};

const CandidateForm = ({
  candidate,
  createCandidate,
  formAttributes,
  currentCourses,
  loading,
  updateCandidate,
}) => {
  const location = useLocation();
  const isEdit = location.pathname.includes('/edit');
  const [state, setState] = useState(
    isEdit ? populatedState(candidate) : defaultState
  );
  const onChange = (event, type) =>
    handleOnChange(event, type, state, setState);
  const initForm = () => new CF.Form('#candidate-form');
  const splitCourseList = Math.ceil(currentCourses.length / 2);

  useEffect(() => {
    if (!loading) {
      initForm();
    }
  }, [loading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm().then((_) =>
      window.location.replace('/en/admin/instructors/candidates/')
    );
  };

  const submitForm = () =>
    isEdit
      ? updateCandidate(candidate.id, omit(state, 'state'))
      : createCandidate(state);

  const isEnabled = () => {
    const isCS =
      state.candidate_role === 'career_specialist' ||
      state.candidate_role === 'certified_career_specialist';
    const requiredInputs = isCS
      ? omit(state, 'user_id')
      : omit(state, ['user_id', 'cf_email_password', 'personal_email']);
    return Object.values(requiredInputs).every((v) => v.toString().length);
  };

  return (
    <FormWrapper>
      <Link className="linkButton" to="/en/admin/instructors/candidates">
        <i className="ds-icon__arrow-thin-left"></i>return to candidates list
      </Link>
      <h3 className="ds-typography__h3 cf-text-left">
        {isEdit ? 'Edit' : 'New'} candidate
      </h3>
      <form onSubmit={handleSubmit} className="cf-form" id="candidate-form">
        <Loading isLoading={loading}>
          <div className="ds-grid__container">
            <div className="ds-grid__row">
              <FormColumn className="ds-grid__col-xs-4">
                {attributeElements(state, formAttributes, onChange)}
              </FormColumn>
              {chunk(currentCourses, splitCourseList).map((courses, i) => (
                <FormColumn className="ds-grid__col-xs-4" key={`courses_${i}`}>
                  {i === 0 && (
                    <p className="ds-typography__body--bold">Course(s)</p>
                  )}
                  {attributeElements(state, courses, onChange)}
                </FormColumn>
              ))}
            </div>
            <div className="ds-grid__row">
              <div className="ds-grid__col-xs-offset-4 ds-grid__col-xs-4">
                <input
                  type="submit"
                  value={isEdit ? 'Edit candidate' : 'Create new candidate'}
                  disabled={!isEnabled()}
                  className="ds-button__petrol submit-button"
                />
              </div>
            </div>
          </div>
        </Loading>
      </form>
    </FormWrapper>
  );
};

CandidateForm.propTypes = {
  candidate: PropTypes.object,
  createCandidate: PropTypes.func.isRequired,
  currentCourses: PropTypes.array.isRequired,
  formAttributes: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  updateCandidate: PropTypes.func.isRequired,
};

export default CandidateForm;
