import adminEmployerConnector from '../../admin/EmployerConnector/reducer';
import afaAdmissions from '../../AfaAdmissions/reducer';
import assignmentsPage from '../../admin/AssignmentsPage/reducer';
import callEdits from '../../CallsEdit/reducer';
import careerSupportCenter from '../../CareerSupportCenter/reducer';
import { combineReducers } from 'redux';
import currentExerciseCta from '../../CurrentExerciseCta/reducer';
import employerDashboard from '../../Employer/reducer';
import guidedTour from '../../DashboardMainPage/reducer';
import instructorProfile from '../../InstructorOnboarding/InstructorProfile/reducer';
import instructorsCandidatesPage from '../../admin/InstructorsCandidatesPage/reducer';
import interviewPrep from '../../InterviewPrep/reducer';
import klarnaCheckout from '../../enroll/Klarna/reducer';
import mediaLibrary from '../../MediaLibrary/reducer';
import mentorCallNotes from '../../MentorCallNotes/reducer';
import messaging from '../../Messaging/reducer';
import offPlatformServices from '../../MentorDashboard/OffPlatformServices/reducer';
import onBoarding from '../../Onboarding/reducer';
import referralBillingProfile from '../../ReferralBillingProfile/reducer';
import sideNavList from '../../SideNavList/reducer';
import studentActivity from '../../StudentActivity/reducer';
import taskSubmission from '../../ExercisePage/reducer';
import timeOff from '../../MentorDashboard/TimeOff/reducer';
import userProfile from '../../UserProfile/reducer';
import weeklySubmissionGoal from '../../CourseDashboard/WeeklySubmissionGoal/reducer';
import wsg from '../../CourseDashboard/WSG/reducer';

export default combineReducers({
  adminEmployerConnector,
  afaAdmissions,
  assignmentsPage,
  callEdits,
  careerSupportCenter,
  currentExerciseCta,
  employerDashboard,
  guidedTour,
  instructorProfile,
  instructorsCandidatesPage,
  interviewPrep,
  klarnaCheckout,
  mediaLibrary,
  mentorCallNotes,
  messaging,
  offPlatformServices,
  onBoarding,
  referralBillingProfile,
  sideNavList,
  studentActivity,
  taskSubmission,
  timeOff,
  userProfile,
  weeklySubmissionGoal,
  wsg,
});
