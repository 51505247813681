import styled from 'styled-components';

const TableWrapper = styled.div`
  margin-bottom: 32px;
  overflow-x: scroll;

  .ds-table .ds-table__tr .ds-table__th {
    background-color: var(--ds-bg-color__int-primary);
  }

  .ds-table__td {
    vertical-align: top;
  }

  td.state {
    text-transform: capitalize;
  }

  th.fixed-width {
    width: 173px;
  }

  td.rejected {
    color: var(--ds-color__int-border-input-negative);
    text-decoration: underline;
    cursor: pointer;
  }

  i {
    font-size: 14px;
    cursor: pointer;
  }

  .proof-wrapper {
    i {
      margin-right: 8px;
    }

    a {
      color: var(--ds-color__int-primary) !important;
      border-bottom: none !important;
      font-weight: 100 !important;
    }
  }
`;

export { TableWrapper };
