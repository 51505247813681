import ApiService from '../../../services/ApiService';
import { formatPayload } from './helpers';
import { getAuthToken } from '../../../utils/getAuthToken';
import { keysToSnake } from '../../../utils/keysConverter';

export const getSections = () => async (dispatch) => {
  const apiService = new ApiService('/api/v1/instructors/onboarding_status');

  try {
    const result = await apiService.makeGetCall();
    dispatch({
      data: result.data,
      type: 'InstructorProfile.LOAD_TABS_SUCCESS',
    });
  } catch (error) {
    console.log(error);
  }
};

export const getPersonalData = () => async (dispatch) => {
  const apiService = new ApiService(
    '/api/v1/instructors/personal_billing_information/'
  );

  try {
    const result = await apiService.makeGetCall();
    dispatch({
      personalData: result.data,
      type: 'InstructorProfile.LOAD_PERSONAL_DATA_SUCCESS',
    });
  } catch (error) {
    console.log(error);
  }
};

export const getDocuments = () => async (dispatch) => {
  const apiService = new ApiService('/api/v1/instructors/documents');

  try {
    const result = await apiService.makeGetCall();
    dispatch({
      documents: result.data.documents,
      type: 'InstructorProfile.LOAD_DOCUMENTS_SUCCESS',
    });
  } catch (error) {
    console.log(error);
  }
};

export const createBillingProfile = () => async (dispatch, getState) => {
  const apiService = new ApiService(
    '/api/v1/instructors/personal_billing_information/save_record'
  );
  const apiServiceOnboarding = new ApiService(
    '/api/v1/instructors/onboarding_status'
  );
  const params = getState().instructorProfile.personalData;

  const data = {
    authenticity_token: getAuthToken(),
    personal_billing_information: keysToSnake(params),
  };

  try {
    const result = await apiService.makePostCall(data);
    const onboardingStatus = await apiServiceOnboarding.makeGetCall();

    dispatch({
      data: onboardingStatus.data,
      personalData: result.data,
      tab: 'personal_information',
      type: 'InstructorProfile.SAVE_PERSONAL_DATA_SUCCESS',
    });
  } catch (error) {
    dispatch({
      errors: error.errors,
      tab: 'personal_information',
      type: 'InstructorProfile.SAVE_PERSONAL_DATA_FAILURE',
    });
  }
};

export const uploadDocuments = (payload) => async (dispatch) => {
  dispatch({
    type: 'InstructorProfile.SUBMIT_DATA',
  });

  const apiService = new ApiService(
    '/api/v1/instructors/documents/save_record'
  );
  const apiServiceOnboarding = new ApiService(
    '/api/v1/instructors/onboarding_status'
  );

  const data = {
    authenticity_token: getAuthToken(),
    documents: keysToSnake(formatPayload(payload)),
  };

  try {
    const result = await apiService.makePostCall(data);
    const onboardingStatus = await apiServiceOnboarding.makeGetCall();

    dispatch({
      data: onboardingStatus.data,
      documents: result.data.documents,
      tab: 'documents',
      type: 'InstructorProfile.SAVE_DOCUMENTS_SUCCESS',
    });
  } catch (error) {
    dispatch({
      errors: error.errors,
      type: 'InstructorProfile.SAVE_DOCUMENTS_FAILURE',
    });
  }
};

export const getCapacity = () => async (dispatch) => {
  const apiService = new ApiService('/api/v1/profiles');
  const result = await apiService.makeGetCall();

  try {
    dispatch({
      capacity: result.data.mentorSpots,
      type: 'InstructorProfile.GET_CAPACITY',
    });
  } catch (error) {
    console.log(error);
  }
};

export const setCapacity = (value) => async (dispatch) => {
  dispatch({
    type: 'InstructorProfile.SUBMIT_DATA',
  });

  const params = {
    authenticity_token: getAuthToken(),
    mentor_spots: parseInt(value),
  };
  const apiServiceOnboarding = new ApiService(
    '/api/v1/instructors/onboarding_status'
  );
  const apiService = new ApiService('/api/v1/profiles/capacity');

  try {
    const result = await apiService.makePutCall(params);
    const onboardingStatus = await apiServiceOnboarding.makeGetCall();

    dispatch({
      data: onboardingStatus.data,
      mentorSpots: result.data,
      type: 'InstructorProfile.SET_CAPACITY_SUCCESS',
    });
  } catch (error) {
    console.log(error);
  }
};

export const setSelectedTab = (tab) => ({
  tab,
  type: 'InstructorProfile.SET_SELECTED_TAB',
});

export const handleClose = () => ({
  type: 'InstructorProfile.CLOSE_MODAL',
});

export const handleSubmit = () => ({
  type: 'InstructorProfile.SUBMIT_FORM',
});
