import OffPlatformRegistrationContainer from '../../containers/OffPlatformRegistrationContainer';
import PropTypes from 'prop-types';
import React from 'react';

const OffPlatformCallModal = ({ modalType }) => {
  return (
    <>
      {
        {
          registerCall: <OffPlatformRegistrationContainer />,
        }[modalType]
      }
    </>
  );
};

OffPlatformCallModal.propTypes = {
  modalType: PropTypes.string.isRequired,
};

export default OffPlatformCallModal;
