import styled from 'styled-components';

const StyledGrid = styled.div`
  width: 100%;
  padding: 48px 20px 81px;

  hr {
    display: none;
  }

  @media (max-width: 768px) {
    padding: 16px;

    hr {
      display: block;
      margin: 14px -16px;
      border: solid 1px #e8ebed;
    }
  }

  ${({ isInterviewMode }) =>
    isInterviewMode &&
    `
    padding-top: 16px;

    @media (max-width: 768px) {
      padding-top: 0;
    }
  `}

  ${({ isReview }) =>
    isReview &&
    `
    @media (max-width: 768px) {
      padding: 16px 0;
      .ds-grid__col-xs-12 {
        padding: 0;
      }
    }
  `};
`;

StyledGrid.displayName = 'StyledGrid';

export default StyledGrid;
