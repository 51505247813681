export const WORLD_WIDE_JOBS_CATEGORIES = [
  [
    {
      category_name: 'all_job_categories_ww',
      contents: [
        {
          link: 'https://careerfoundry.com/en/careerhub',
          text: 'Career Foundry’s Career Hub (Alumni Job Board)',
        },
        {
          link: 'https://expertmap.eu/search/?orderby=newest&offset=8&postType=job',
          text: 'Expert Map (Platform Built by a CF Alumni)',
        },
        {
          link: 'https://betalist.com/regions',
          text: 'BetaList (Start-Ups by Country)',
        },
        {
          link: 'https://angel.co/',
          text: 'Angel List (Start-Ups)',
        },
        {
          link: 'http://startupjobs.asia/',
          text: 'StartUp Jobs (Asia)',
        },
        {
          link: 'http://www.bestjobsca.com/',
          text: 'Best Jobs (Philippines, South Africa, Kenya)',
        },
        {
          link: 'https://www.seek.com.au/',
          text: '​Seek (Australia)',
        },
        {
          link: 'https://www.glassdoor.com/',
          text: 'Glassdoor',
        },
        {
          link: 'https://www.themuse.com/jobs',
          text: 'The Muse',
        },
        {
          link: 'http://www.indeed.com/',
          text: 'Indeed',
        },
        {
          link: 'https://www.linkedin.com/jobs/',
          text: 'LinkedIn',
        },
        {
          link: 'http://www.idealist.org/',
          text: '​Idealist',
        },
        {
          link: 'https://designgigsforgood.squarespace.com/job-board',
          text: 'Design Gigs For Good',
        },
        {
          link: 'https://www.ziprecruiter.com',
          text: 'Zip Recruiter',
        },
        {
          link: 'https://www.monster.com/',
          text: 'Monster',
        },
        {
          link: 'https://www.dice.com/jobs',
          text: 'Dice',
        },
        {
          link: 'http://jobs.smashingmagazine.com/',
          text: 'Smashing Jobs',
        },
        {
          link: 'https://www.jobsinnetwork.com/?locations.countryCode[]=',
          text: 'Jobs In Network',
        },
        {
          link: 'https://www.f6s.com/jobs?sort=newest',
          text: 'F6S ',
        },
        {
          link: 'https://graduateships.com/graduate-jobs/',
          text: 'GraduateShips',
        },
        {
          link: 'https://cturtle.co/',
          text: 'Cturtle ',
        },
        {
          link: 'https://creativepool.com/jobs/',
          text: 'Creativepool',
        },
        {
          link: 'https://opmjobs.com/jobs/',
          text: 'opmjobs.com (Gaming)',
        },
        {
          link: 'https://jobs.workable.com/',
          text: 'Jobs By Workable',
        },
        {
          link: 'https://the-dots.com/jobs/search',
          text: 'The-Dots',
        },
        {
          link: 'https://se.talent.com/en/worldwide',
          text: 'Talent.com',
        },
        {
          link: 'https://whoishiring.io/',
          text: 'Who Is Hiring?',
        },
        {
          link: 'https://www.awwwards.com/jobs/offer/',
          text: 'awwwards.',
        },
        {
          link: 'https://www.dmcgglobal.com/jobs',
          text: 'DMCG (Asia, UK, Germany)',
        },
        {
          link: 'https://www.jobteaser.com/en/job-offers',
          text: 'Job Teaser',
        },
        { link: 'https://www.unjobnet.org/', text: 'UNjobnet' },
        {
          link: 'https://www.ventureloop.com/ventureloop/home.php',
          text: 'VentureLoop',
        },
        { link: 'https://www.startus.cc/jobs', text: 'StartUs' },
        {
          link: 'https://clutch.co/',
          text: 'Clutch',
        },
        {
          link: 'https://www.jobsinnetwork.com/',
          text: 'JobsInNetwork',
        },
        {
          link: 'https://powertofly.com/jobs/',
          text: 'Power to Fly',
        },
        {
          link: 'https://jobs.theguardian.com/',
          text: 'TheGuardianJobs',
        },
        {
          link: 'https://grabjobs.co/all-countries',
          text: 'GRABJOBS',
        },
        {
          link: 'https://www.jobserve.com/ca/en/changecountry.aspx',
          text: 'jobserve',
        },
        {
          link: 'https://www.producthunt.com/jobs?\u0026',
          text: 'Product Hunt ',
        },
        {
          link: 'https://forpurposejobs.com/',
          text: 'For Purpose Jobs',
        },
        {
          link: 'https://jobs.astrolabs.com/',
          text: 'AstroLabs (MENA)',
        },
        {
          link: 'https://womenatwork.ae/',
          text: 'Women@Work (Dubai, UAE)',
        },
        {
          link: 'https://www.edarabia.com/jobs/',
          text: 'Edarabia (Dubai, UAE)',
        },
        {
          link: 'https://www.hub71.com/',
          text: 'HUB71 (Dubai, UAE)',
        },
        {
          link: 'https://www.cultjobs.com/',
          text: 'Cultjobs (Singapore)',
        },
        {
          link: 'https://sg.wantedly.com/projects',
          text: 'Wantedly (Singapore)',
        },
        {
          link: 'https://9cv9.com/job-lists',
          text: '9cv9 (Southeast Asia)',
        },
        {
          link: 'https://read.cv/open-roles',
          text: 'Read.cv',
        },
      ],
      title: 'All Job Categories',
    },
  ],
  [
    {
      category_name: 'development_ww',
      contents: [
        {
          link: 'http://stackoverflow.com/jobs',
          text: 'Stack Overflow',
        },
        {
          link: 'https://hackerx.org/jobs/',
          text: 'HackerX',
        },
        {
          link: 'https://www.workshape.io/companies',
          text: 'Workshape',
        },
        {
          link: 'http://www.rubycommunity.org/jobs',
          text: 'Ruby-Community',
        },
        {
          link: 'https://justjoin.it/',
          text: 'Just Join It',
        },
        {
          link: 'https://relocateme.eu/jobs/',
          text: 'relocateme',
        },
        {
          link: 'https://reliefweb.int/jobs?',
          text: 'reliefweb',
        },
        {
          link: 'https://fullstackjob.com/',
          text: 'Fullstack Jobs',
        },
        {
          link: 'https://www.workatastartup.com/',
          text: 'Work at a Startup',
        },
        {
          link: 'https://www.efinancialcareers.com/sitemap/sector/19/titles',
          text: 'eFinancialCareers ',
        },
        {
          link: 'https://www.darwinrecruitment.com/search-jobs/',
          text: 'darwin',
        },
        {
          link: 'https://www.developersforhire.com/full-stack',
          text: 'DevelopersForHire.com',
        },
        {
          link: 'https://authenticjobs.com/',
          text: 'Authentic Jobs',
        },
        {
          link: 'https://www.startupers.com/',
          text: 'startupers',
        },
        {
          link: 'https://careers.revelo.com/',
          text: 'Revelo (Remote from Latin America)',
        },
      ],
      title: 'Development',
    },
    {
      category_name: 'data_jobs_ww',
      contents: [
        {
          link: 'https://www.statsjobs.com/',
          text: 'StatsJobs',
        },
        {
          link: 'https://www.kdnuggets.com/jobs/index.html',
          text: 'KDnuggets',
        },
        {
          link: 'https://icrunchdata.com/',
          text: 'Icrunchdata',
        },
        {
          link: 'https://datajobs.com/',
          text: 'DataJobs',
        },
        {
          link: 'https://www.analytictalent.datasciencecentral.com/jobs/',
          text: 'Analytic Talent',
        },
        {
          link: 'https://www.brightnetwork.co.uk/search/',
          text: 'BRIGHT Network (United Kingdom)',
        },
        {
          link: 'https://www.gradcracker.com/search/all-disciplines/engineering-jobs',
          text: 'GRADCRACKER (UK and Europe)',
        },
        {
          link: 'https://www.interviewquery.com/jobs',
          text: 'Interview Query (United States)',
        },
        {
          link: 'https://www.michaelpage.co.uk/jobs/data-analyst?utm_source=google\u0026utm_medium=cpc\u0026ds_c=UK_MP_Paid+Search_Do_Prospecting_B2C_BAU_Applications_Marketing,+Agency+%26+Digital_Search+-+Google_(Marketing-Types)\u0026gclid=Cj0KCQjwyN-DBhCDARIsAFOELTmX9dCZfFTpWHxzrwb2qOUCmab8rHrcfHPUFP3FDur0QknQAxrWnc0aAiZtEALw_wcB\u0026gclsrc=aw.ds',
          text: 'Michael Page (United Kingdom)',
        },
        {
          link: 'https://www.efinancialcareers.com/',
          text: 'eFinancialCareers ',
        },
        {
          link: 'https://www.henlowgroup.com/jobs-at-henlow/browse',
          text: 'Henlow Recruitment Group',
        },
      ],
      title: 'Data Jobs',
    },
  ],
  [
    {
      category_name: 'design_jobs_ww',
      contents: [
        {
          link: 'https://dribbble.com/jobs',
          text: 'Dribbble',
        },
        {
          link: 'http://justuxjobs.com/',
          text: 'justUXjobs',
        },
        {
          link: 'https://www.designed.org/jobs',
          text: 'Designed',
        },
        {
          link: 'https://designerjobs.co/',
          text: 'Designer Jobs',
        },
        {
          link: 'https://www.ideatelabs.co/job-board',
          text: 'Ideate Labs Junior Design Job Board (Majority: United States)',
        },
        {
          link: 'https://uxpa.org/job-bank',
          text: 'UXPA Job Bank',
        },
        {
          link: 'http://ixda.org/page/job-board',
          text: 'IXD',
        },
        {
          link: 'https://uiuxjobsboard.com/',
          text: 'UI UX Jobs Board',
        },
        {
          link: 'https://www.uxjobsboard.com/',
          text: 'UX Jobs Board',
        },
        {
          link: 'https://www.uxswitch.com/',
          text: 'UXswitch',
        },
        {
          link: 'https://www.uxresearchjobs.com/',
          text: 'UXResearchJobs',
        },
        {
          link: 'https://uxinsight.org/ux-research-jobs/',
          text: 'uxinsight',
        },
        {
          link: 'https://www.halodesigners.com/',
          text: 'Halo Designers (Asia Pacific)',
        },
        {
          link: 'https://www.bamboocrowd.com/find-jobs',
          text: 'Bamboo Crowd',
        },
        {
          link: 'https://www.behance.net/joblist',
          text: 'Behance',
        },
        {
          link: 'https://www.dexigner.com/directory/popular/Firms',
          text: 'Dexigner (Top Design Firms)',
        },
      ],
      title: 'Design Jobs',
    },
    {
      category_name: 'marketing_jobs_ww',
      contents: [
        {
          link: 'https://www.skipthedrive.com/job-category/remote-marketing-jobs/',
          text: 'SkipTheDrive',
        },
        {
          link: 'https://remoters.net/jobs/marketing/filters?jobs-location=anywhere',
          text: 'Remoters',
        },
      ],
      title: 'Marketing Jobs',
    },
    {
      category_name: 'product_management_ww',
      contents: [
        {
          link: 'https://jobs.mindtheproduct.com/',
          text: 'Mind the Product',
        },
      ],
      title: 'Product Management Jobs',
    },
  ],
];
