import Card from '../../App/styles/Card';
import styled from 'styled-components';

const StyledInterviewPrepCard = styled(Card)`
  @media (max-width: 768px) {
    a {
      width: 100%;
      text-align: center;
      display: block;
      height: 40px;

      &:nth-of-type(2) {
        margin-top: 16px;
      }
    }
  }
`;

StyledInterviewPrepCard.displayName = 'StyledInterviewPrepCard';

export default StyledInterviewPrepCard;
