import React, { Component } from 'react';
import CFModal from '../shared/modal';
import { getAuthToken } from '../../utils/getAuthToken';
import EnrollmentLinkService from '../../services/EnrollmentLinkService';

class ArchivedAction extends Component {
  constructor(props) {
    super(props);
    this.enrollmentLinkService = new EnrollmentLinkService();
  }

  state = {
    modalOpen: false,
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  markAsArchived = () => {
    this.enrollmentLinkService
      .updateLink(this.props.link.id, 'archived', getAuthToken())
      .then((res) => {
        this.handleModalClose();
        location.reload();
      });
  };

  render() {
    return (
      <div>
        <a onClick={() => this.openModal()} href="#">
          Archive
        </a>
        <br />
        <CFModal
          appElement=".admin__enrollment_links_index"
          isOpen={this.state.modalOpen}
          onRequestClose={this.handleModalClose}
          className="admin__enrollment_links__modal"
        >
          <div className="cf-text-center">
            <h4 className="cf-text-center">
              Are you sure this enrollment link should be archived and set to
              expired?
            </h4>
            <a
              onClick={() => this.handleModalClose()}
              className="button--medium button--primary-darkblue"
            >
              No
            </a>
            <a
              onClick={() => this.markAsArchived()}
              className="button--medium button--primary-petrol"
            >
              Yes
            </a>
          </div>
        </CFModal>
      </div>
    );
  }
}

export default ArchivedAction;
