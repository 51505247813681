export const JOB_SEARCH_CATEGORIES = [
  {
    title: 'Salary Research Websites',
    category_name: 'salary_research_websites',
    contents: [
      { text: 'Comparably', link: 'https://www.comparably.com/' },
      { text: 'Glassdoor', link: 'https://www.glassdoor.com/' },
      { text: 'Payscale', link: 'https://www.payscale.com/' },
      {
        text: 'Indeed Salaries (Search “indeed” and the name of your country to find the indeed page tailored to the salaries in your location',
        link: 'https://www.indeed.com/career/salaries',
      },
      {
        link: 'https://www.kununu.com/',
        text: 'Kununu (Germany)',
      },
      { text: 'Gehalt (Germany)', link: 'https://www.gehalt.de/' },
      {
        text: 'Salary.com(US)',
        link: 'https://www.salary.com/research/salary',
      },
    ],
  },
  {
    title: 'Salary Research Websites for Freelancers',
    category_name: 'salary_research_websites_for_freelancers',
    contents: [
      {
        text: 'How To Calculate Your Freelance Hourly Rate',
        link: 'https://jenniferbourn.com/calculate-freelance-hourly-rate/',
      },
      {
        text: 'Freelancers - What Hourly Rate Should You Charge?',
        link: 'https://kontist.com/en/posts/freelancer-hourly-rate-charge/',
      },
      {
        text: 'How much do freelancers make? A guide to the average freelancer salary',
        link: 'https://n26.com/en-eu/blog/how-much-do-freelancers-make',
      },
    ],
  },
  {
    title: 'Optimizing your applications',
    category_name: 'optimizing_your_applications',
    contents: [
      { text: 'Pick job boards from multiple categories' },
      {
        text: 'For fast and efficient job applications, create a document with your employment history (dates of employment, addresses, locations, salary, etc) and any other info commonly asked on job applications.',
      },
      {
        text: 'Whenever you are asked new information, add it to your document for reference. ',
      },
      { text: 'Copy and Paste from this document!' },
      {
        text: 'Customizing resumes and cover letters for each position is a great idea, just be sure to update all company/position specific information!!',
      },
    ],
  },
  {
    title: 'How the system works',
    category_name: 'how_the_system_works',
    contents: [
      { text: 'Put keywords in your resume and profile' },
      {
        text: 'You will be spammed!  Use a separate email address just for job searching and don’t get upset about emails that don’t apply to you - just ignore them. Be sure to check it regularly while you are actively looking, or set it up to forward to your usual email during your job search.',
      },
      {
        text: 'Refresh or update your profile every 30, 90, or 120 days because recruiters filter their searches by the length of time since the last candidate activity',
      },
    ],
  },
  {
    title: 'Be Proactive',
    category_name: 'be_proactive',
    contents: [
      {
        text: 'Many ask for references at the time of the interview. Prepare personal and professional references who are responsive, and keep them with your interview materials (not on your resume!)',
      },
      {
        text: 'Can you do a video interview tomorrow?  Make sure you have a working machine, webcam, working internet, and use a headset with built-in microphone for best sound quality!',
      },
      {
        text: 'Don’t just rely on job boards! Many positions are only posted on company sites, so always check the Careers Pages, and sign up for job alerts with the companies you are targeting.',
      },
    ],
  },
  {
    title: 'Be Contactable',
    category_name: 'be_contactable',
    contents: [
      {
        text: 'Your phone number is valid and you have a professional voicemail message, you always answer your phone professionally',
      },
      {
        text: 'You regularly check the email address AND SPAM FOLDER that you have used on your resume, public profiles, and job applications',
      },
    ],
  },
];
