import React, { useEffect, useState } from 'react';
import EvaluationCard from './styles/Card';
import EvaluationContent from './styles/Content';
import EvaluationHeader from './styles/Header';
import EvaluationInfoContent from '../../containers/EvaluationInfoContent';
import EvaluationNavigation from './EvaluationNavigation';
import GuidedTour from '../../../shared/components/GuidedTour';
import PropTypes from 'prop-types';
import { getGuidedTourSteps } from '../../actions';
import handleIndexControls from '../../../shared/helpers/handleIndexControls';

const Evaluation = ({ isModal, answersForReview, currentAnswer, isReviewNavigable, onToggleNav }) => {
  const cookieName = 'dismissedEvaluationTour';
  const [indexControls, setIndexControls] = useState({});

  const questionId = answersForReview.findIndex(answer => answer.id === currentAnswer.id) + 1;

  useEffect(() => {
    handleIndexControls(answersForReview.findIndex(answer => answer.id === currentAnswer.id), answersForReview, setIndexControls);
  }, [currentAnswer]);

  return (
    <EvaluationCard isModal={isModal}>
      {!isModal && <h3 className="ds-typography__h3 ds-color__indigo text-left evaluation-title">Evaluation</h3>}
      <EvaluationContent isModal={isModal} className="review-content">
        <EvaluationHeader isModal={isModal} className="text-left">
          <p className="ds-typography__body">Question {!isModal && questionId}</p>
          <h4 className="ds-typography__h4">{currentAnswer.question}</h4>
        </EvaluationHeader>
        <EvaluationInfoContent isModal={isModal} />
      </EvaluationContent>
      {!isModal && <EvaluationNavigation indexControls={indexControls} toggleMessage={onToggleNav} navigable={isReviewNavigable} />}
      <GuidedTour
        getGuidedTourSteps={getGuidedTourSteps}
        cookieName={cookieName}
        mobile={isModal}
      />
    </EvaluationCard>
  );
};

Evaluation.propTypes = {
  answersForReview: PropTypes.array.isRequired,
  currentAnswer: PropTypes.object,
  isModal: PropTypes.bool.isRequired,
  isReviewNavigable: PropTypes.bool,
  onToggleNav: PropTypes.func.isRequired,
};

export default Evaluation;
