import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import CallReviewsTab from '../../containers/CallReviewsTab';
import CoursesTab from '../../containers/CoursesTab';
import InterviewAnswersTab from '../../containers/InterviewAnswersTab';
import JobApplicationsTab from '../../containers/JobApplicationsTab';
import MentorNotesTab from '../../containers/MentorNotes/MentorNotesTab';
import ProfileTab from '../../containers/ProfileTab';
import PropTypes from 'prop-types';
import React from 'react';
import StudentHandoffTab from '../../containers/StudentHandoffTable';
import StudentNotesTab from '../../containers/StudentNotesTab';
import TabPanelStyles from './styles/TabPanel';
import TabStyles from './styles/Tabs';

const TabSection = ({ setTabIndex, studentIsViewing, tabIndex }) => {
  const studentTabs = ['Profile info', 'Courses', 'Student notes'];

  const mentorTabs = [
    'Profile info',
    'Courses',
    'Call reviews',
    'Student notes',
    'Mentor/Tutor handoff info',
    'Mentor notes',
    'Interview Answers',
    'Job Applications',
  ];

  const tabsForUser = () => (studentIsViewing ? studentTabs : mentorTabs);

  return (
    <TabStyles>
      <i className='fa fa-chevron-right tabs-arrow'></i>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => setTabIndex(tabIndex)}
      >
        <TabList className='ds-tabs'>
          {tabsForUser().map((tab, i) => (
            <Tab
              className={`ds-tabs__item ${tabIndex === i && 'active'}`}
              key={`t.${i}`}
            >
              {tab}
            </Tab>
          ))}
        </TabList>
        <TabPanel>
          <TabPanelStyles>
            <ProfileTab />
          </TabPanelStyles>
        </TabPanel>
        <TabPanel>
          <TabPanelStyles>
            <CoursesTab />
          </TabPanelStyles>
        </TabPanel>
        {!studentIsViewing && (
          <TabPanel>
            <TabPanelStyles>
              <CallReviewsTab />
            </TabPanelStyles>
          </TabPanel>
        )}
        <TabPanel>
          <TabPanelStyles>
            <StudentNotesTab />
          </TabPanelStyles>
        </TabPanel>
        {!studentIsViewing && (
          <>
            <TabPanel>
              <TabPanelStyles>
                <StudentHandoffTab />
              </TabPanelStyles>
            </TabPanel>
            <TabPanel>
              <TabPanelStyles>
                <MentorNotesTab />
              </TabPanelStyles>
            </TabPanel>
          </>
        )}
        {!studentIsViewing && (
          <>
            <TabPanel>
              <TabPanelStyles>
                <InterviewAnswersTab />
              </TabPanelStyles>
            </TabPanel>
            <TabPanel>
              <TabPanelStyles>
                <JobApplicationsTab />
              </TabPanelStyles>
            </TabPanel>
          </>
        )}
      </Tabs>
    </TabStyles>
  );
};

TabSection.propTypes = {
  setTabIndex: PropTypes.func.isRequired,
  studentIsViewing: PropTypes.bool.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default TabSection;
