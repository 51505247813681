import { chatClient } from './index';
import { getUrlParams } from '../../utils/getUrlParams';
import { useEffect } from 'react';

const getMemberChannelId = async () => {
  const { uuid } = getUrlParams(window.location.search);
  if (uuid) {
    const channels = await chatClient.queryChannels({
      members: { $in: [uuid] },
    });
    const memberChanel = channels.find((channel) =>
      channel.cid.includes('members')
    );
    return memberChanel ? memberChanel.id : '';
  }
};

export const useChannels = (
  shouldLoadChannels,
  filters,
  sort,
  queriedChannelsSuccess
) => {
  useEffect(() => {
    if (shouldLoadChannels) {
      (async () => {
        try {
          await chatClient.queryChannels(filters, sort);
          const memberChannelId = await getMemberChannelId();
          queriedChannelsSuccess(memberChannelId);
        } catch (error) {
          console.log('uh oh', error);
        }
      })();
    }
  }, [shouldLoadChannels]);
};
