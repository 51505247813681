import PropTypes from 'prop-types';
import React from 'react';
import StyledChevron from './styles/StyledChevron';
import handleIndexControls from '../../../../shared/helpers/handleIndexControls';

const AnswerNavigation = ({
  currentIndex,
  setCurrentIndex,
  indexControls,
  setIndexControls,
  answers,
  displayLength,
}) => {
  const toggleAnswer = (direction) => {
    const nextIndex = currentIndex + direction;
    setCurrentIndex(nextIndex);
    handleIndexControls(nextIndex, answers, setIndexControls, displayLength);
  };

  const { nextRecord, previousRecord } = indexControls;
  return (
    <>
      <StyledChevron onClick={nextRecord ? () => toggleAnswer(-1) : null} className={`fa ${nextRecord ? 'clickable' : 'grey'} fa-chevron-circle-left fa-lg left-arrow fa-2x`} answers={answers} displayLength={displayLength} rel="noopener noreferrer" />
      <StyledChevron onClick={previousRecord ? () => toggleAnswer(1) : null} className={`fa ${previousRecord ? 'clickable' : 'grey'} fa-chevron-circle-right fa-lg right-arrow fa-2x`} answers={answers} displayLength={displayLength} rel="noopener noreferrer" />
    </>
  );
};

AnswerNavigation.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.shape({
        attachment: PropTypes.shape({
          fileIcon: PropTypes.string,
          fileName: PropTypes.string,
          id: PropTypes.number,
          s3Url: PropTypes.string,
        }),
        category: PropTypes.string,
        date: PropTypes.string,
        id: PropTypes.number,
        industry: PropTypes.string,
        notes: PropTypes.string,
        question: PropTypes.string,
        questionInsights: PropTypes.string,
        rating: PropTypes.number,
        shared: PropTypes.bool,
      }),
    }),
  ),
  currentIndex: PropTypes.number.isRequired,
  displayLength: PropTypes.number.isRequired,
  indexControls: PropTypes.object.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  setIndexControls: PropTypes.func.isRequired,
};

export default AnswerNavigation;
