import { IMAGE_MESSAGES, TEXT_MESSAGES } from './constants';
import InteractiveImageMessage from './InteractiveImageMessage';
import InteractiveTextMessage from './InteractiveTextMessage';
import PropTypes from 'prop-types';
import React from 'react';
import StyledMessageModal from './styles/StyledMessageModal';

const InteractiveMessagesModal = ({ interactiveMessage, handleClose }) => {
  const isTextMessage = TEXT_MESSAGES.includes(interactiveMessage.messageType);
  const isImageMessage = IMAGE_MESSAGES.includes(interactiveMessage.messageType);

  return (
    <StyledMessageModal isTextModal={isTextMessage}>
      { isTextMessage && <InteractiveTextMessage { ...interactiveMessage } onCloseModal={handleClose} /> }
      { isImageMessage && <InteractiveImageMessage { ...interactiveMessage } onCloseModal={handleClose}/> }
    </StyledMessageModal>
  );
};

InteractiveMessagesModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  interactiveMessage: PropTypes.object.isRequired,
};

export default InteractiveMessagesModal;
