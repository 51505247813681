module.exports = (() =>{
  thisState = {};

  var init = function(){
    thisState.form = new CF.Form('#forgot-password');
    bindFunctions();
  }

  var bindFunctions = function(){
    $('.form-submit--js').on('click', _validate);
  }

  var _validate = function(){
    thisState.form.validate();
  }

  return {
    init: init
  }
})();
