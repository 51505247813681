import styled from 'styled-components';

export const FullScreenModalBox = styled.div`
  background: #fff;
  text-align: center;
  box-shadow: 10px 10px 10px 0 rgb(34 60 80 / 40%);
  width: 80%;
  height: 80%;
  padding: 40px;
  margin-top: 2vh;
  margin-bottom: 2vh;
  z-index: 51;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.interview-modal {
    padding: 0 !important;
    width: 60%;
  }

  &.interview-modal .review-content {
    margin: 0 !important;
  }

  &.interview-modal .ds-icon__close {
    z-index: 99;
    font-size: 12px;
  }

  @media (max-width: 479px) {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    text-align: center;
    margin: 0;
    max-width: 100%;
    padding: 16px;
    width: 100%;

    &.interview-modal {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
`;

FullScreenModalBox.displayName = 'FullScreenModalBox';
