import AttachmentPreview from './AttachmentPreview';
import InlineError from '../../shared/InlineError';
import PropTypes from 'prop-types';
import React from 'react';
import StyledAttachmentsSection from './styles/StyledAttachmentsSection';
import StyledLabel from './styles/StyledLabel';
import StyledUploader from './styles/StyledUploader';

const Uploader = ({
  subtype,
  deleteAttachment,
  dismissError,
  label,
  error,
  handleAttachment,
  sublabel,
  attachments,
}) => {
  return (
    <>
      <StyledUploader>
        <StyledLabel>
          <label className='ds-typography__body--bold'>{label}</label>
          {sublabel && <p className='ds-typography__tiny'>{sublabel}</p>}
        </StyledLabel>
        <input
          type='file'
          name='file'
          onChange={(event) => handleAttachment(event.target.files[0], subtype)}
        />
      </StyledUploader>
      {error && error.subtype === subtype && (
        <InlineError message={error.message} dismissError={dismissError} />
      )}
      <StyledAttachmentsSection>
        {attachments.map((attachment) => (
          <AttachmentPreview
            key={attachment.id}
            attachment={attachment}
            deleteAttachment={deleteAttachment}
          />
        ))}
      </StyledAttachmentsSection>
    </>
  );
};

Uploader.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      fileIcon: PropTypes.string,
      fileName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      s3Url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  dismissError: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
    subtype: PropTypes.string,
  }),
  handleAttachment: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  sublabel: PropTypes.string,
  subtype: PropTypes.string.isRequired,
};

export default Uploader;
