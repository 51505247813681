import ApiService from '../../../services/ApiService';
import { keysToCamel } from '../../../utils/keysConverter';
import { getAuthToken } from '../../../utils/getAuthToken';
import { UpdateParams } from './interfaces';

export const getAdmission = async (id: string) => {
  const apiService = new ApiService(`/api/v1/admin/afa_admissions/${id}`);
  const response = await apiService.makeGetCall();
  return keysToCamel(response.data);
};

export const updateAdmission = async (id: string, params: UpdateParams) => {
  const apiService = new ApiService(`/api/v1/admin/afa_admissions/${id}`);
  const formattedParams = {
    authenticity_token: getAuthToken(),
    ...params,
  };
  const response = await apiService.makePutCall(formattedParams);
  return keysToCamel(response.data);
};
