import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const pageContainer = document.getElementById('mainDashboard');

if (pageContainer) {
  const OnboardingTour = lazy(() => import('./containers/OnboardingTour'));
  const pageContainerRoot = createRoot(pageContainer);

  pageContainerRoot.render(
    <Provider store={store}>
      <Suspense
        fallback={
          <i className='ds-icon__mail-fill ds-typography__h5 ds-color__grey' />
        }
      >
        <OnboardingTour />
      </Suspense>
    </Provider>
  );
}
