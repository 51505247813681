import ExerciseRating from '../../container/ExerciseRating';
import Message from '../../container/Message';
import { ModalWrapper } from './styles';
import NextStep from '../../container/NextStep';
import PropTypes from 'prop-types';
import React from 'react';
import TheModal from '../../../shared/components/TheModal';
import TimeTaken from '../../container/TimeTaken';
import { celebrationAnimation } from './utils';

const TaskSubmissionModal = ({
  hasMetWsg,
  modalIsOpen = false,
  openModal,
  showTimeSurvey,
}) => {
  if (modalIsOpen && hasMetWsg) celebrationAnimation();

  const handleClose = () => {
    openModal(false);
    redirectTo.goToId('#step_submission_history');
  };

  return (
    <ModalWrapper>
      <TheModal handleClose={handleClose} isModalOpen={modalIsOpen}>
        <Message />
        {showTimeSurvey && <TimeTaken />}
        <ExerciseRating />
        <NextStep />
      </TheModal>
    </ModalWrapper>
  );
};

TaskSubmissionModal.propTypes = {
  hasMetWsg: PropTypes.bool,
  modalIsOpen: PropTypes.bool,
  openModal: PropTypes.func,
  showTimeSurvey: PropTypes.bool,
};

export default TaskSubmissionModal;
