import {
  saveInstructorAssignment,
  setAssigningStudentId,
  setStudentsTab,
  unsetAssignmentIds,
} from './actions';
import Students from '../../components/Students';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  assignmentsPage: {
    activeStudents,
    assigningStudentId,
    assigningStudentInfo,
    students,
    studentsListLoaded,
  },
}) => {
  if (studentsListLoaded) {
    const studentList = {
      assigned: students.filter(item => item.assigned),
      regular: students.filter(item => !item.state_sponsored && !item.assigned),
      stateSponsored: students.filter(item => item.state_sponsored),
    };

    return {
      activeStudents,
      assigningStudentId,
      assigningStudentInfo,
      studentList: studentList[activeStudents],
      studentsCount: {
        assigned: studentList.assigned.length,
        regular: studentList.regular.length,
        stateSponsored: studentList.stateSponsored.length,
      },
    };
  }
  return loadingStateData(activeStudents);
};

export const mapDispatchToProps = dispatch => ({
  saveInstructorAssignment: () => dispatch(saveInstructorAssignment()),
  setAssigningStudentId: id => dispatch(setAssigningStudentId(id)),
  setStudentsTab: tabTitle => dispatch(setStudentsTab(tabTitle)),
  unsetAssignmentIds: () => dispatch(unsetAssignmentIds()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Students);

const loadingStateData = activeStudents => ({
  activeStudents,
  assigningStudentId: null,
  studentList: null,
  studentsCount: {
    assigned: '😱',
    regular: '😱',
    stateSponsored: '😱',
  },
});
