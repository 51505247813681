import styled from 'styled-components';

const TableWrapper = styled.div`
  select {
    width: 100%;
  }

  p.calls-count {
    margin-top: 16px;
  }
`;

TableWrapper.displayName = 'TableWrapper';

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 0;
  box-shadow: 0px 4px 6px rgba(34, 60, 80, 0.16);

  img {
    margin-top: 32px;
    margin-bottom: 48px;
    width: 115px;
  }
`;

EmptyStateWrapper.displayName = 'EmptyStateWrapper';

export { EmptyStateWrapper, TableWrapper };
