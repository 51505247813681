import { Button, Header, Lead } from '@careerfoundry/bubble';
import { LeadWrapper, Wrapper } from './styles';
import Card from '../../../../shared/styles/Card';
import Modal from '../../components/Modal';
import React from 'react';
import Tooltip from '../../../../shared/components/Tooltip';
import Table from '../../containers/Table';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

interface OverviewPageProps {
  handleModalClose: () => void;
  setModalAction: (modalAction: string) => void;
  modalAction: string;
}

const OverviewPage: React.FC<OverviewPageProps> = ({
  handleModalClose,
  modalAction,
  setModalAction,
}) => {
  return (
    <div className='ds-grid__container'>
      <Card>
        <Wrapper>
          <Header tag='h1' variant='h1'>
            Off-Platform Services Overview
          </Header>
          <LeadWrapper>
            <Lead tag='p'>
              Here’s the overview of your submitted claims and their current
              status. Approved claims can be found in your invoice.
            </Lead>
            <Tooltip
              place='bottom'
              content='Claims will be included in the invoice for the month when they occurred. You have until the 6th of each month to add claims for the previous month. Any claims submitted after the deadline will be added to the earliest open invoice.'
            >
              <i className='ds-icon__help-line'></i>
            </Tooltip>
          </LeadWrapper>
          <Table />
          <Modal
            handleModalClose={handleModalClose}
            modalAction={modalAction}
          />
          <Button
            hasIcon={true}
            icon={faPlus}
            iconPosition='left'
            label='Add Service'
            onClick={() => setModalAction('form')}
            size='standard'
            variant='primary'
          />
        </Wrapper>
      </Card>
    </div>
  );
};

export default OverviewPage;
