export const INSTRUCTORS_TABS_CHANGE = 'AssignmentsPage.INSTRUCTORS_TABS_CHANGE';
export const TIMEZONE_TABS_CHANGE = 'AssignmentsPage.TIMEZONE_TABS_CHANGE';
export const TOGGLE_INSTRUCTOR_ASSIGNMENT = 'AssignmentsPage.TOGGLE_INSTRUCTOR_ASSIGNMENT';
export const TOGGLE_ORDER_BY_ACTIVE_STUDENTS = 'AssignmentsPage.TOGGLE_ORDER_BY_ACTIVE_STUDENTS';
export const FILTER_INSTRUCTORS = 'AssignmentsPage.FILTER_INSTRUCTORS';

export const setInstructorsTab = tabTitle => {
  return {
    tabTitle,
    type: INSTRUCTORS_TABS_CHANGE,
  };
};

export const setTimeZoneTab = tabTitle => {
  return {
    tabTitle,
    type: TIMEZONE_TABS_CHANGE,
  };
};

export const toggleInstructorAssignment = instructorId => {
  return {
    instructorId,
    type: TOGGLE_INSTRUCTOR_ASSIGNMENT,
  };
};

export const toggleOrderByCurrentStudents = () => ({
  type: TOGGLE_ORDER_BY_ACTIVE_STUDENTS,
});

export const filterInstructors = (tagsToShow, tagsToHide) => ({
  tagsToHide,
  tagsToShow,
  type: FILTER_INSTRUCTORS,
});
