import PropTypes from 'prop-types';
import React from 'react';
import StyledTile from './styles/StyledTile';
import { resourceTracking } from '../../../shared/helpers/tracking';

const Tile = ({ contents = [], title, categoryName, linkEventName, sectionName }) => {
  return (
    <StyledTile>
      <h5 className="ds-typography__h5 text-left">{title}</h5>
      <div className="tile-wrapper">
        <ul>
          {contents.map((content, index) => {
            return (
              <li key={`${categoryName}_content_${index}`}>
                {content && content.link
                  ? (
                    <a
                      className="ds-typography__body ds-typography__anchor"
                      onClick={() =>
                        linkEventName
                          ? resourceTracking(
                            title,
                            content.link,
                            linkEventName,
                            sectionName,
                          )
                          : {}
                      }
                      href={content.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {content.text}
                    </a>
                  )
                  : (
                    <p className="ds-typography__body">{content.text}</p>
                  )}
              </li>
            );
          })}
        </ul>
      </div>
    </StyledTile>
  );
};

Tile.propTypes = {
  categoryName: PropTypes.string.isRequired,
  contents: PropTypes.array.isRequired,
  linkEventName: PropTypes.string,
  sectionName: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Tile;
