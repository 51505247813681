module.exports = (() => {
  const init = () => {
    bindFunctions();
    autosize($('.job_form_input'));
    initializeElements();
    replaceDropdownArrows();
    replaceDefaultMessage();
    validateJobForm();
    datePicker();
  };

  const bindFunctions = () => {
    $('#job_offer_modal').on('hidden.bs.modal', redirectToCareerhubIndex);
    $('.link_preview--js').on('click', openJobPostingPreview);
    $('.dropdown_cities--js').on('click','.select2', replaceDefaultMessage);
    $('.toggle-date-field--js [type="checkbox"]').on('change', toggleDateInput);
    $('.student_job_opening_form_submit--js').on('click', submitForm);
    $('.validation-trigger--js').on('change', triggerFieldValidation);
    $(document).on('select2:open', ".original-select2-dropdown", toggleTheArrow);
    $(document).on('select2:close', ".original-select2-dropdown", toggleTheArrow);
    $("#location_country").on('change', countrySelect);
  };

  const countrySelect = function() {
    let selected = $('#location_country').val();
    $(".dropdown_cities--js").html('');
    $.ajax({
      url: '/en/careerhub/cities/',
      dataType: 'script',
      data: { country_code: selected},
      method: 'POST',
      success: function(data)
      {
        $("#student_job_opening_city").select2({
        });
        replaceDropdownArrows();
      }
    });
  };

  const datePicker = () => {
    $('.datepicker').datepicker({
      dateFormat: 'yy-mm-dd'
    });
  };

  const triggerFieldValidation = function(){
    $("#student_job_opening_form").validate().element(this);
  };

  const toggleTheArrow = function() {
    $(this).next().find('.fa-angle-down').toggleClass('open');
    // Hide error message after selecting a location
    $(this).parents('.form-group').find('.error').hide();
  };

  const redirectToCareerhubIndex = function() {
    window.location.replace('/en/careerhub/');
  };

  const toggleDateInput = function() {
    let $text_field = $(this).parent().siblings('[type="text"]');

    if($(this).is(':checked')){
      $text_field.hide();
      $text_field.val($(this).val());
    }else{
      $text_field.show();
      $text_field.val("");
    }

    // validate text field after checkbox click
    $("#student_job_opening_form").validate().element($text_field);
  };

  const submitForm = () => $('#student_job_opening_form').submit();

  const openJobPostingPreview = function() {
    window.open('/en/careerhub/preview_job_offer' + "?" + $("#student_job_opening_form").serialize(), '_blank');
  };

  // Initialize localization gem form country/city dropdown
  const initializeElements = function(){
    $("#location_country").select2({
      placeholder: "Country",
      width: '100%'
    });
    $("#location_city").select2({
      placeholder: "City",
      minimumResultsForSearch: -1,
      width: '100%'
    });
    $("#location_city").addClass('location_city_dropdown');
    $("#location_country").addClass('location_country_dropdown');
  };

  // Adding the class and id .form-group#13 ensures that these arrows are changed ONLY ON THE SEND JOB OFFER PAGE,
  // and not elsewhere throughout our app.
  const replaceDropdownArrows = function () {
    $('.form-group#13 b[role="presentation"]').hide();
    $('.form-group#13 .select2-selection__arrow').append('<i class="fa fa-angle-down"></i>');
  };

  const replaceDefaultMessage = function() {
    let defaultMessage = "No results found"
    if ($(".select2-results__message").text() == defaultMessage) {
      $(".select2-results__message").html('Please choose a country first.');
    }
  };
  // ENDS

  $.validator.addMethod("greaterThan", function(value, element, params) {
    if (!/Invalid|NaN/.test(new Date(value))) {
      return new Date(value) > new Date($(params).val());
    }
    return isNaN(value) && isNaN($(params).val()) || (Number(value) > Number($(params).val()));
  },'End date must be after the start date.');

  // FORM VALIDATIONS
  const validateJobForm = function () {
    $('#student_job_opening_form').validate({
      rules: {
        'student_job_opening[about_company]': {
          maxlength: 300
        },
        'student_job_opening[job_description]': {
          maxlength: 500
        },
        'student_job_opening[required_skills]': {
          maxlength: 500
        },
        'student_job_opening[categories][]': {
          required: true
        },
        'student_job_opening[job_type]': {
          required: true
        },
        'student_job_opening[skill_level]': {
          required: true
        },
        'student_job_opening[remote_allowed]': {
          required: true
        },
        'student_job_opening[end_date]': {
          greaterThan: "#student_job_opening_start_date"
        },
        'privacy_agreement': {
          required: true
        },
        'authorization_agreement': {
          required: true
        }
      },
      messages: {
        'student_job_opening[contact_person]': 'Please enter a contact person.',
        'student_job_opening[about_company]': 'Max 300 characters please.',
        'student_job_opening[job_description]': 'Max 500 characters please.',
        'student_job_opening[required_skills]': 'Max 500 characters please.',
        'student_job_opening[categories][]': 'Please choose at least one option.',
        'student_job_opening[job_type]': 'Please choose one option.',
        'student_job_opening[skill_level]': 'Please choose one option.',
        'student_job_opening[remote_allowed]': 'Please choose one option.',
        'location_city': 'Please choose a location.',
        'privacy_agreement': 'Please confirm the privacy agreement.',
        'authorization_agreement': 'Please confirm the authorization agreement.'
      },
      errorContainer: ".default_error_message, .error_icon",
      highlight: function(element) {
        $(element).closest('.form_element_container').children('.icon').removeClass('valid_icon').addClass('error_icon');
        $(element).closest('.form_element_container').children('.job_form_input').removeClass('valid').addClass('error');
      },
      unhighlight: function(element) {
        $(element).closest('.form_element_container').children('.icon').removeClass('error_icon').addClass('valid_icon');
        $(element).closest('.form_element_container').children('.job_form_input').removeClass('error').addClass('valid');
      },
      invalidHandler: function(event, validator) {
        var errors = validator.numberOfInvalids();
        var firstError = $(validator.errorList[0].element);
        if (errors) {
          var message = errors == 1 ? 'You missed 1 field. It has been highlighted' : 'You missed ' + errors + ' fields. They have been highlighted';
          $(".job_form_error_summary span.errors").html(message);
          $(".job_form_error_summary").show();
        } else {
          $(".job_form_error_summary").hide();
        }
        $('html, body').animate({
          scrollTop: (firstError.offset().top - 150)
        }, 2000);
        firstError.focus();
      },
      onclick: false,
      onkeyup: false,
      focusInvalid: false
    });
  };
  // VALIDATIONS ENDS

  return {
    init: init
  };
})();
