import { Formik } from 'formik';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative;

  input,
  select {
    width: 247px;
  }

  @media (max-width: 768px) {
    display: block;

    input,
    select {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

InputWrapper.displayName = 'InputWrapper';

export const StyledInput = styled.div`
  display: flex;
  flex-direction: column;

  input {
    padding: 14px 16px 12px;
    border-radius: 4px;
    font-family: TradeGothic;
    font-size: 16px;
    color: var(--ds-color__indigo);
    border: 1px solid
      ${(props) =>
        props.error ? 'var(--ds-color_error)' : 'var(--ds-color__grey--400)'};
    outline: none;

    &::placeholder {
      color: var(--ds-color__indigo);
    }
    &:-ms-input-placeholder {
      color: var(--ds-color__indigo);
    }
    &::-ms-input-placeholder {
      color: var(--ds-color__indigo);
    }

    &:focus {
      border: 1px solid
        ${(props) =>
          props.error ? 'var(--ds-color_error)' : 'var(--ds-color__indigo)'};
    }

    &:hover {
      border: 1px solid
        ${(props) =>
          props.error ? 'var(--ds-color_error)' : 'var(--ds-color__petrol)'};
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  .error-text {
    margin-top: 5px;
    color: var(--ds-color_error);
  }
`;

StyledInput.displayName = 'StyledInput';

export const StyledSelect = styled.div`
  position: relative;

  select {
    padding: 14px 16px 12px;
    border: 1px solid
      ${(props) =>
        props.error ? 'var(--ds-color_error)' : 'var(--ds-color__grey--400)'};
    border-radius: 4px;
    font-family: TradeGothic;
    font-size: 16px;
    color: var(--ds-color__indigo);
    appearance: none;
    outline: none;

    &:focus {
      border: 1px solid
        ${(props) =>
          props.error ? 'var(--ds-color_error)' : 'var(--ds-color__indigo)'};
    }

    &:hover {
      border: 1px solid
        ${(props) =>
          props.error ? 'var(--ds-color_error)' : 'var(--ds-color__petrol)'};
    }
  }

  & > span {
    &:after {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      top: 14px;
      right: 16px;
      border-right: 1px solid var(--ds-color__indigo);
      border-bottom: 1px solid var(--ds-color__indigo);
      transform: rotate(45deg);
      pointer-events: none;
    }
  }
`;

StyledSelect.displayName = 'StyledSelect';

export const FormWrapper = styled(Formik)`
  width: 518px;
  margin-bottom: 12px;

  h3,
  p {
    margin-bottom: 0;
  }

  p {
    color: rgba(34, 60, 80, 0.75);
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`;

FormWrapper.displayName = 'FormWrapper';

export const SubmitButton = styled.button`
  width: 221px;

  .loading_spinner_wrapper {
    background: transparent;
    margin-top: 0;

    > .cf-spinner > div {
      background-color: var(--ds-color__white);
    }
  }

  i {
    margin-left: 16px;
  }
`;

SubmitButton.displayName = 'SubmitButton';

export const StyledContainer = styled.div`
  background-color: var(--ds-color__white);
  max-width: 590px;
  margin: 30px auto;
  padding: 30px;

  .form-messages {
    padding: 0;
    margin-bottom: 20px;
    list-style-type: none;

    .success-message {
      color: var(--ds-color__feedback-content-positive);
    }

    .error-message {
      color: var(--ds-color__feedback-content-negative);
    }
  }
`;

StyledContainer.displayName = 'StyledContainer';
