import React, { useEffect } from 'react';
import App from '../../components/App';
import Card from '../../../../shared/styles/Card';
import { Content } from '../../../../shared/styles/Content';
import Loading from '../../../../shared/components/Loading';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInitialData } from '../../actions';

const AppContainer = ({ getInitialData, loading, role }) => {
  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div className='ds-grid__container'>
      <Card>
        <Content>
          <Loading isLoading={loading}>
            <App role={role} />
          </Loading>
        </Content>
      </Card>
    </div>
  );
};

AppContainer.propTypes = {
  getInitialData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
};

export const mapStateToProps = ({ timeOff: { loading, role } }) => ({
  loading,
  role,
});

export const mapDispatchToProps = (dispatch) => ({
  getInitialData: () => dispatch(getInitialData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
