import styled from 'styled-components';

const StyledModalWrapper = styled.div`
    .TheModal {
      width: 763px;
      padding: 0;
      border-radius: 4px;
      min-height: 621px;
    }

    ${({ isLargeModal }) =>
    isLargeModal &&
    `
    hr {
      display: none;
    }
    .TheModal {
      width: 1040px;
      max-width: 1040px;
      height: 662px;
      padding: 0;
      border-radius: 4px;
      min-height: 621px;
    }
    @media (max-width: 768px) {
      hr {
        display: block;
      }
      .TheModal {
        height: 100%;
      }
    }
    
    `
}
`;

StyledModalWrapper.displayName = 'StyledModalWrapper';

export default StyledModalWrapper;
