import styled from 'styled-components';

interface InputWrapperProps {
  isError: boolean;
}

const HeaderContainer = styled.header`
  h2 {
    text-align: left;
    margin-bottom: 40px;
  }
`;

const ToolTipWrapper = styled.div`
  position: relative;

  i {
    position: absolute;
    top: 4px;
    right: 0;
  }

  span > div.__react_component_tooltip {
    max-width: 332px;
  }
`;

const ButtonActions = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;

    button {
      width: 83px;
    }

    button:first-of-type {
      margin-right: 8px;
    }

    .loading_spinner_wrapper {
      margin-top: 0;
      background: transparent;
    }
  }

  & > div:last-of-type {
    align-items: center;
    width: 83px;

    p {
      margin-bottom: 0;
      cursor: pointer;
      color: var(--ds-bg-color__inv-feedback-negative);
    }
  }
`;

const InputWrapper = styled.div<InputWrapperProps>`
  margin-bottom: 40px;

  .description-text {
    margin-bottom: 8px;
    color: var(--ds-color__static-content-primary);
  }

  .upload-wrapper {
    margin-top: 30px;
  }

  label {
    display: block;
  }

  input,
  select {
    width: 100%;
    padding: var(--ds-spacing__xxs) var(--ds-spacing__xs);
    font-family: var(--ds-font__tradegothic);
    font-size: 16px;
    height: var(--ds-spacing__l);
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid
      ${({ isError }) =>
        isError
          ? 'var(--ds-color__int-border-input-negative)'
          : 'var(--ds-color__int-border-input)'};

    &:focus {
      outline-color: var(--ds-color__int-border-focus);
    }

    ::placeholder {
      color: var(--ds-color__static-content-primary);
    }
  }
`;

const RejectionWrapper = styled.div`
  padding: 24px;
  border: 1px solid var(--ds-color__int-border-input-negative);
  margin-bottom: 40px;

  p {
    margin-bottom: 0;
  }
`;

const ErrorMessageWrapper = styled.div`
  p {
    margin-bottom: 0;
    margin-top: 10px;
    color: var(--ds-color__int-border-input-negative);
  }
`;

export {
  HeaderContainer,
  ToolTipWrapper,
  ButtonActions,
  InputWrapper,
  RejectionWrapper,
  ErrorMessageWrapper,
};
