import { Route, Switch, useLocation } from 'react-router-dom';
import AdminEmployerConnectorModalContainer from '../../shared/containers/EmployerConnectorModal/index';
import AppWrapper from '../../shared/components/AppWrapper';
import ButtonWrapper from './styles/ButtonWrapper';
import EmployerTableContainer from '../containers/EmployerTable';
import PropTypes from 'prop-types';
import React from 'react';

const App = ({ handleNewEmployer, modalIsOpen }) => {
  const location = useLocation();
  return (
    <>
      <Switch location={location}>
        <Route exact path="/:lang/admin/employer_connector/employers">
          <AppWrapper header='Employer Connector - Employer List'>
            <ButtonWrapper>
              <button className="ds-button ds-button__primary--inverse" onClick={handleNewEmployer}>Add new employer</button>
            </ButtonWrapper>
            <EmployerTableContainer />
          </AppWrapper>
        </Route>
      </Switch>
      {modalIsOpen && <AdminEmployerConnectorModalContainer /> }
    </>
  );
};

App.propTypes = {
  handleNewEmployer: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

export default App;
