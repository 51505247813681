import React from 'react';
import Wrapper from '../styles/Wrapper';

const UnavailableContent = () => (
  <Wrapper>
    <p className="ds-typography__body text-left">This feature is no longer available to you</p>
  </Wrapper>
);

export default UnavailableContent;
