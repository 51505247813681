import axios from 'axios';
import { getAuthToken } from '../../utils/getAuthToken';

export const openModal = (modalIsOpen) => ({
  modalIsOpen,
  type: 'TOGGLE_TASK_SUBMISSION_MODAL',
});

export const toggleConsentModal = (modalIsOpen) => ({
  modalIsOpen,
  type: 'TOGGLE_AI_CONSENT_MODAL',
});

export const submitTask = () => async (dispatch) => {
  const formData = $('#new_submission--js').serialize();

  if ($('#modal_question_editbox_text').val().length < 1) {
    $('.submission__error_container').html(
      'Please include a message with your submission!'
    );
  } else {
    try {
      dispatch({ isLoading: true, type: 'LOADING_TASK_MODAL' });

      const { data: responseData } = await axios({
        data: formData,
        headers: {
          'X-CSRF-TOKEN': getAuthToken(),
          'X-Requested-With': 'XMLHttpRequest',
        },
        method: gon.submission_id ? 'PATCH' : 'POST',
        url: gon.submission_id
          ? `/submissions/${gon.submission_id}`
          : '/submissions/',
      });
      dispatch({ isLoading: false, type: 'LOADING_TASK_MODAL' });
      if (responseData.success) {
        if (!gon.submission_id) {
          gon.submission_id = responseData.submission_id;
        }
        dispatch({
          data: {
            errors: {},
            hasMetWsg: responseData.has_met_wsg,
            name: responseData.name,
            nextStepUrl: responseData.next_step_url,
            submissionId: responseData.submission_id,
          },
          type: 'TASK_SUBMISSION_SAVED',
        });
        dispatch(openModal(true));
        $('#modal_question_editbox_text').val('');
      } else {
        dispatch({
          data: {
            errors: responseData.errors,
          },
          type: 'TASK_SUBMISSION_FAILURE',
        });
      }
    } catch (err) {
      console.log(`Something went wrong... ${err}`);
    }
  }
};

export const setAiEvaluationState = ({ inProgress, status, isError, id }) => ({
  id,
  inProgress,
  isError,
  status,
  type: 'SET_AI_EVALUATION_STATE',
});

export const setFileUploadError = ({ errorMessage }) => ({
  errorMessage,
  type: 'SET_FILE_UPLOAD_ERROR',
});

export const handleAiEvaluationWithoutConsent = () => async (dispatch) => {
  const formData = new FormData(document.getElementById('new_submission--js'));
  const uploadedFiles = formData.getAll(
    'comment[downloaded_deliverable_ids][]'
  );

  if (uploadedFiles.length === 0) {
    dispatch(
      setFileUploadError({ errorMessage: 'Please upload at least 1 file' })
    );
  } else {
    dispatch(setFileUploadError({ errorMessage: null }));
    dispatch(toggleConsentModal(true));
  }
};

export const submitTaskForAiEvaluation = () => async (dispatch) => {
  const formData = new FormData(document.getElementById('new_submission--js'));
  const uploadedFiles = formData.getAll(
    'comment[downloaded_deliverable_ids][]'
  );
  const apiFormData = new FormData();

  apiFormData.append('uploaded_file_ids[]', uploadedFiles);
  apiFormData.append('step_id', formData.get('submission[step_id]'));
  apiFormData.append('attendee_id', formData.get('submission[attendee_id]'));
  apiFormData.append('project_url', formData.get('submission[source_url]'));
  apiFormData.append('submission_text', formData.get('comment[body]'));

  try {
    dispatch(setAiEvaluationState({ inProgress: true, status: null }));

    const { data: responseData } = await axios({
      data: apiFormData,
      headers: {
        'X-CSRF-TOKEN': getAuthToken(),
        'X-Requested-With': 'XMLHttpRequest',
      },
      method: 'POST',
      url: '/api/v1/ai_tutor_submissions',
    });

    if (responseData.success) {
      dispatch(
        setAiEvaluationState({
          id: responseData.data.id,
          inProgress: true,
          status: responseData.data.state,
        })
      );
    } else {
      throw new Error();
    }
  } catch (error) {
    dispatch(
      setAiEvaluationState({
        inProgress: false,
        isError: true,
        status: null,
      })
    );
  }
};

export const estimationOptionSelected =
  (option) => async (dispatch, getState) => {
    const { submissionId } = getState().taskSubmission;

    try {
      await axios({
        data: { time_spent_on_task: option },
        headers: {
          'X-CSRF-TOKEN': getAuthToken(),
          'X-Requested-With': 'XMLHttpRequest',
        },
        method: 'POST',
        url: `/en/submissions/${submissionId}/add_task_estimation/`,
      });

      dispatch({ type: 'TASK_TIME_ESTIMATION_SAVED' });
    } catch (err) {
      console.log(`Something went wrong... ${err}`);
    }
  };

export const fetchRatings = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `/api/v1/exercises/${gon.step_id}/ratings`
    );
    dispatch({ ratings: data.data.ratings, type: 'FETCH_RATINGS_SUCCESS' });
  } catch (e) {
    console.log(e);
  }
};

export const submitRating =
  (exerciseRating, surveyQuestionId) => async (dispatch) => {
    try {
      await axios({
        data: {
          exercise_rating: exerciseRating,
          survey_question_id: surveyQuestionId,
        },
        headers: {
          'X-CSRF-TOKEN': getAuthToken(),
          'X-Requested-With': 'XMLHttpRequest',
        },
        method: 'POST',
        url: `/api/v1/exercises/${gon.step_id}/rate`,
      });
      dispatch({
        payload: {
          exerciseRating,
          surveyQuestionId,
        },
        type: 'SUBMIT_RATING_SUCCESS',
      });
    } catch (e) {
      console.log(e);
    }
  };

export const setAiTutorConsent = async () => {
  try {
    await axios({
      headers: {
        'X-CSRF-TOKEN': getAuthToken(),
      },
      method: 'POST',
      url: '/api/v1/consent_for_ai_evaluation',
    });
  } catch (e) {
    console.log(e);
  }
};
