import { EmptyStateWrapper } from './styles';
import React from 'react';
import { redirectTo } from '../../../../utils/redirectTo';

interface EmptyStateProps {
  buttonStyle?: string;
  heading: string;
  hasButton?: boolean;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  buttonStyle = 'primary',
  hasButton = true,
  heading,
}) => (
  <EmptyStateWrapper>
    <h4 className='ds-typography__h4'>{heading}</h4>
    <img
      src='https://images.careerfoundry.com/public/icons/binoculars.svg'
      alt='binoculars'
    />
    {hasButton && (
      <button
        className={`ds-button__${buttonStyle}`}
        onClick={() => redirectTo({ url: '/en' })}
      >
        Back to dashboard
      </button>
    )}
  </EmptyStateWrapper>
);

export default EmptyState;
