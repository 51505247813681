import MediaLibraryContainer from './containers/MediaLibrary/index';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const mediaLibraryContainer = document.getElementById('mediaLibrary');

if (mediaLibraryContainer) {
  const mediaLibraryContainerRoot = createRoot(mediaLibraryContainer);

  mediaLibraryContainerRoot.render(
    <Provider store={store}>
      <MediaLibraryContainer
        attendeeId={mediaLibraryContainer.dataset.attendeeId}
      />
      ,
    </Provider>
  );
}
