import { submitAdjustedWsgGoal, updateModalEventTracking } from './actions';
import PropTypes from 'prop-types';
import React from 'react';
import WsgModal from '../../components/WsgModal';
import { closeWsgModal } from '../../actions';
import { connect } from 'react-redux';

const buttonType = (type) =>
  ({
    decrease: -1,
    increase: 1,
  }[type]);

const WsgModalContainer = ({
  attendeeTargetCompletion,
  closeWsgModal,
  index,
  isModalOpen,
  maxGoal,
  minGoal,
  options,
  submitAdjustedWsgGoal,
  updateModalEventTracking,
}) => {
  React.useEffect(() => {
    if (options.length) {
      setWsgState({
        decreaseDisabled: options[index].goal <= minGoal,
        increaseDisabled: options[index].goal >= maxGoal,
        index,
        ...options[index],
      });
    }
  }, [index, options]);

  const updateGoal = (incDec) => () =>
    setWsgState((prev) => {
      const newIndex = prev.index + incDec;

      updateModalEventTracking(incDec > 0 ? 'plus' : 'minus');

      return {
        index: newIndex,
        ...options[newIndex],
        decreaseDisabled: options[newIndex].goal <= minGoal,
        increaseDisabled: options[newIndex].goal >= maxGoal,
      };
    });

  const [wsgState, setWsgState] = React.useState({ date: '', goal: null });

  const handleClick = () => submitAdjustedWsgGoal(wsgState.goal);
  const closeWsgModalWithStateReset = () => {
    setWsgState({
      decreaseDisabled: options[index].goal <= minGoal,
      increaseDisabled: options[index].goal >= maxGoal,
      index,
      ...options[index],
    });
    closeWsgModal();
    updateModalEventTracking('later');
  };

  return (
    isModalOpen && (
      <WsgModal
        attendeeTargetCompletion={attendeeTargetCompletion}
        closeWsgModal={closeWsgModalWithStateReset}
        date={wsgState.date}
        decreaseGoal={updateGoal(buttonType('decrease'))}
        decreaseDisabled={wsgState.decreaseDisabled}
        goal={wsgState.goal}
        increaseGoal={updateGoal(buttonType('increase'))}
        increaseDisabled={wsgState.increaseDisabled}
        handleClick={handleClick}
        maxGoal={maxGoal}
        minGoal={minGoal}
        updateModalEventTracking={updateModalEventTracking}
      />
    )
  );
};

WsgModalContainer.propTypes = {
  attendeeTargetCompletion: PropTypes.string,
  closeWsgModal: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool,
  maxGoal: PropTypes.number.isRequired,
  minGoal: PropTypes.number.isRequired,
  options: PropTypes.array,
  submitAdjustedWsgGoal: PropTypes.func.isRequired,
  updateModalEventTracking: PropTypes.func.isRequired,
};

export const mapState = ({
  wsg: { attendeeTargetCompletion, isModalOpen, options, submissionGoal },
}) => {
  return {
    attendeeTargetCompletion,
    index: options.findIndex((o) => o.goal === submissionGoal),
    isModalOpen,
    maxGoal: Math.max.apply(
      Math,
      options.map(function (o) {
        return o.goal;
      })
    ),
    minGoal: Math.min.apply(
      Math,
      options.map(function (o) {
        return o.goal;
      })
    ),
    options,
  };
};

export const mapDispatch = (dispatch) => ({
  closeWsgModal: () => dispatch(closeWsgModal()),
  submitAdjustedWsgGoal: (goal) => dispatch(submitAdjustedWsgGoal(goal)),
  updateModalEventTracking: (type) => dispatch(updateModalEventTracking(type)),
});

export default connect(mapState, mapDispatch)(WsgModalContainer);
