import React, { useState } from 'react';
import Achievements from '../../containers/Achievements';
import DropDown from '../../../UserProfile/components/Dropdown';
import Loading from '../../../shared/components/Loading';
import Notes from '../../containers/Notes';
import PropTypes from 'prop-types';
import Sidebar from '../../../UserProfile/components/Sidebar';
import TabMenu from '../TabMenu';
import { Wrapper } from './styles';
import { constants } from '../../constants';
import { noop } from 'lodash';

const StudentActivity = ({
  selectedCourseId,
  isLoading,
  isReadOnlyStudent,
  courseCards,
  setSelectedCourseIdAction = noop,
}) => {
  const [currentTab, setCurrentTab] = useState(constants.SUBMISSIONS_TAB);

  return (
    <Wrapper>
      <h2 className="ds-typography__h2">{constants.TITLE}</h2>
      <Loading isLoading={isLoading}>
        <div className="ds-grid__container">
          <TabMenu
            options={[constants.SUBMISSIONS_TAB, constants.NOTES_TAB]}
            selectedOption={currentTab}
            onClick={(option) => setCurrentTab(option)}
          />
          <div className="ds-grid__row">
            <Sidebar
              attendees={courseCards.attendees}
              programAttendees={courseCards.programAttendees}
              selectedCourse={selectedCourseId}
              setSelectedCourse={(courseId) =>
                setSelectedCourseIdAction({ courseId })
              }
            />
            <div className="ds-grid__col-lg-9 ds-grid__col-xs-12">
              <DropDown
                attendees={courseCards.attendees}
                programAttendees={courseCards.programAttendees}
                selectedCourse={selectedCourseId}
                setSelectedCourse={(courseId) =>
                  setSelectedCourseIdAction({ courseId })
                }
              />
              {!isReadOnlyStudent &&
                {
                  [constants.SUBMISSIONS_TAB]: <Achievements />,
                  [constants.NOTES_TAB]: <Notes />,
                }[currentTab]}
            </div>
          </div>
        </div>
      </Loading>
    </Wrapper>
  );
};

StudentActivity.propTypes = {
  courseCards: PropTypes.shape({
    attendees: PropTypes.array,
    programAttendees: PropTypes.array,
  }),
  isLoading: PropTypes.bool,
  isReadOnlyStudent: PropTypes.bool,
  selectedCourseId: PropTypes.number,
  setSelectedCourseIdAction: PropTypes.func,
};

export default StudentActivity;
