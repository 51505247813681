import styled from 'styled-components';

const StyledModalWrapper = styled.div`
  overflow-y: ${(props) =>
    props.currentState === 'registerCall' ? 'visible' : 'auto'};
  overflow-x: ${(props) =>
    props.currentState === 'registerCall' ? 'visible' : 'hidden'};

  .error {
    color: var(--ds-color_error);
  }
`;

StyledModalWrapper.displayName = 'StyledModalWrapper';

export default StyledModalWrapper;
