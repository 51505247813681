import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StatementParagraph from '../shared/StatementParagraph';
import StatementTextArea from '../shared/StatementTextArea';
import StyledRowWrapper from './styles/StyledRowWrapper';

const ProfessionalStatement = ({ jobSeekingProfile, isEditView, updateECProfile = () => {}, studentViewing = false }) => {
  const [errors, setError] = useState([]);
  return (
    <StyledRowWrapper>
      {studentViewing && <StatementParagraph />}
      {
        isEditView
          ? <StatementTextArea
            errors={errors}
            setError={setError}
            motivationStatement={jobSeekingProfile.motivationStatement}
            updateECProfile={updateECProfile}
            isProfile={true}
          />
          : <p className="ds-typography__body">{jobSeekingProfile.motivationStatement}</p>
      }
    </StyledRowWrapper>
  );
};

ProfessionalStatement.propTypes = {
  isEditView: PropTypes.bool,
  jobSeekingProfile: PropTypes.shape({ motivationStatement: PropTypes.string }),
  studentViewing: PropTypes.bool.isRequired,
  updateECProfile: PropTypes.func,
};

export default ProfessionalStatement;
