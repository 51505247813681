import styled from 'styled-components';

const StyledUploader = styled.div`
  position: relative;
  padding-left: 48px;
  input {
    color: transparent;
    width: 118px;
  }
  input::-webkit-file-upload-button {
    visibility: hidden;
  }
  input::before {
    content: 'Upload a file';
    color: black;
    display: inline-block;
    background-color: #fff;
    outline: none;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 12px;
    line-height: 2;
    color: var(--ds-color__grey--400);
    width: 118px;
    height: 32px;
    margin: 0;
    padding: 0 11px 0;
    text-align: right;
    border-radius: 4px;
    border: solid 2px var(--ds-color__grey--400);
  }
  
  i {
    position: absolute;
    top: 8px;
    font-size: 13px;
    left: 60px;
    color: var(--ds-color__grey--400);
  }
  input:active {
    outline: 0;
  }
  input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }

  .cf-spinner div {
    background-color: #dcdcdc !important;
  }
`;

StyledUploader.displayName = 'StyledUploader';

export default StyledUploader;
