import PaginationControls from '../../../shared/components/Pagination';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '../../../shared/components/PaginatedTable/styles';
import moment from 'moment';

const PaginatedTable = ({
  activePage,
  collection,
  columns,
  emptyText,
  itemsCountPerPage,
  onPageChange,
  totalItemsCount,
  loadingActions,
}) => {
  const getColumnData = (row, item, index) => {
    if (typeof item === 'object') {
      const name = item.name;
      const link = item.url && row[item.url];
      const cellData = row[item.key];

      return (
        <td key={`${name}_${index}`}>
          {item.type === 'link' && link && (
            <a
              className="ds-typography__anchor"
              href={link}
              rel="noreferrer"
              target="_blank"
            >
              {cellData}
            </a>
          )}
          {item.type === 'action' && item.actionType === 'icon' ? (
            <i
              className={`fa ${item.iconName}`}
              onClick={() => item.onClick(row.id)}
            />
          ) : (
            <span onClick={() => item.onClick(row.id)}>{item.actionName}</span>
          )}

          {item.type === 'date' && (
            <span>{moment(cellData).format('YYYY-MM-DD')}</span>
          )}

          {(!item.type || item.type === 'text') && <span>{cellData}</span>}
        </td>
      );
    }
    return <td key={`${item}_${index}`}>{row[item.toLowerCase()]}</td>;
  };

  return collection.length < 1 ? (
    <p className="ds-typography__body">{emptyText || 'No data yet'}</p>
  ) : (
    <>
      <Table className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              {columns.map((column) => {
                if (typeof column === 'object') {
                  return <th key={column.name}>{column.name}</th>;
                }
                return <th key={column}>{column}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {collection.map((row, i) => (
              <tr key={`row_${i}`}>
                {columns.map((item, index) => {
                  const isLoading =
                    loadingActions[item.key]?.currentId === row.id &&
                    loadingActions[item.key]?.isLoading;
                  return isLoading ? (
                    <td key={`${item.name}_${index}`}>
                      {' '}
                      <i className="fa fa-spinner fa-spin"></i>
                    </td>
                  ) : (
                    getColumnData(row, item, index)
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
      <PaginationControls
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={itemsCountPerPage}
        onChange={onPageChange}
      />
    </>
  );
};

PaginatedTable.propTypes = {
  activePage: PropTypes.number.isRequired,
  collection: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  emptyText: PropTypes.string,
  itemsCountPerPage: PropTypes.number,
  loadingActions: PropTypes.object,
  onPageChange: PropTypes.func.isRequired,
  totalItemsCount: PropTypes.number,
};

export default PaginatedTable;
