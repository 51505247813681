import styled from 'styled-components';
import TheModal from '../TheModal';

export const Modal = styled(TheModal)`
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;

  i {
    font-size: 40px;
    color: var(--ds-color__int-border-icon);
  }

  h4 {
    margin: var(--ds-spacing__m) 0;
  }

  p {
    margin: 0;
    text-align: center;
  }
`;
