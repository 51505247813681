import { SET_GUIDED_STEPS, SET_USER_DATA, TOGGLE_TOUR } from './constants';
import ApiService from '../../services/ApiService';
import { getAuthToken } from '../../utils/getAuthToken';
import { getUserData } from '../../services/getUserData';

const TOUR_AREA = 'pre_course_start';

export const getCurrentUserdData = () => async (dispatch) => {
  try {
    const { data } = await getUserData();
    dispatch(setUserData(data.data));
    dispatch(getGuidedTourSteps());
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const getGuidedTourSteps = () => async (dispatch) => {
  const params = {
    tour_area: 'pre_course_start',
  };

  const apiService = new ApiService('/api/v1/guided_tours');
  try {
    const { data } = await apiService.makeGetCall(params);
    dispatch(setGuidedSteps(data.data));
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const trackEvent = async (event, data) => {
  const params = {
    authenticity_token: getAuthToken(),
    data,
    event,
  };
  try {
    const apiService = new ApiService('/api/v1/analytics/track');
    await apiService.makePostCall(params);
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const finishTour = (stepName) => async (dispatch, getState) => {
  try {
    const params = {
      authenticity_token: getAuthToken(),
    };
    if (!stepName) {
      const apiService = new ApiService(
        `/api/v1/guided_tours/${TOUR_AREA}/complete_tour`
      );
      await apiService.makePostCall(params);
    } else {
      trackEvent('onboarding_tooltip', {
        event_action: 'skip',
        tooltip: stepName,
      });
    }
    const userData = { ...getState().guidedTour.userData };
    userData.completedGuidedTours = ['pre_course_start'];
    dispatch(setUserData(userData));
    dispatch(setTourActive(false));
  } catch (error) {
    dispatch(setTourActive(false));
    console.log(`Something went wrong... ${error}`);
  }
};

export const toggleTourStatus = (status) => async (dispatch, state) => {
  try {
    status &&
      !state().guidedTour.active &&
      trackEvent('onboarding_quick_start', {
        event_action: 'rerun tooltip',
      });

    dispatch(setTourActive(status));
  } catch (e) {
    console.log(e);
  }
};

export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  userData: userData,
});

export const setTourActive = (active) => ({
  active,
  type: TOGGLE_TOUR,
});

export const setGuidedSteps = (steps) => ({
  steps,
  type: SET_GUIDED_STEPS,
});
