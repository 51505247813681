import { getApplicationConsent, handleNewJobApplication } from '../../../CareerSupportCenter/actions';
import JobApplicationTrackerHeader from '../../components/header';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  careerSupportCenter: {
    shareJobApplication,
    retrievingConsent,
    jobApplications,
  },
}) => ({
  jobApplications,
  retrievingConsent,
  shareJobApplication,
});

export const mapDispatchToProps = dispatch => ({
  getApplicationConsent: (userId, hasApplications) => dispatch(getApplicationConsent(userId, hasApplications)),
  handleNewJobApplication: () => dispatch(handleNewJobApplication()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobApplicationTrackerHeader);
