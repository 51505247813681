import { deleteLink, formatAllLink } from '../../helpers';
import AddLinkWithInput from './AddLinkWithInput';
import PropTypes from 'prop-types';
import React from 'react';
import StyledIconsWrapper from '../../styles/StyledIconsWrapper';
import StyledPreviewSmall from '../../styles/StyledPreview__small';

const MultipleLinkAddInput = ({ links = [], onInputChange, onDeleteSocial, isEditView }) => {
  const handleChange = (data) => {
    const { link, urlType } = data;
    const newUrl = { handle: link, name: urlType };

    const socials = links.filter((social) => social.name.toLowerCase() !== urlType);
    if (typeof onInputChange === 'function') {
      onInputChange([...socials, newUrl]);
    }
  };

  const attachments = formatAllLink(links);
  const isViewOnly = !isEditView;
  return (
    <>
      <StyledIconsWrapper hasMargin={true}>
        {attachments && attachments.length > 0 && attachments.map((attachment, i) => (
          <StyledPreviewSmall
            key={i}
            attachment={attachment}
            isViewOnly={isViewOnly}
            deleteAttachment={isViewOnly
              ? null
              : () =>
                deleteLink(
                  attachment.type,
                  links,
                  onInputChange,
                  onDeleteSocial,
                )
            }
          />
        ))}
        {isEditView && <AddLinkWithInput onInputChange={handleChange} /> }
      </StyledIconsWrapper>
    </>
  );
};

MultipleLinkAddInput.propTypes = {
  isEditView: PropTypes.bool,
  links: PropTypes.array.isRequired,
  onDeleteSocial: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default MultipleLinkAddInput;
