import {
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import Dashboard from './dashboard';
import React from 'react';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <Dashboard />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
