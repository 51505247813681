import styled from 'styled-components';

interface CardProps {
  modalAction: string;
}

const Wrapper = styled.div<CardProps>`
  .TheModal {
    height: ${({ modalAction }) => (modalAction === 'form' ? '80vh' : 'auto')};
    width: ${({ modalAction }) => (modalAction === 'form' ? 'auto' : '440px')};
    overflow: auto;
  }
`;

export { Wrapper };
