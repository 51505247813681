export default (() => {
  const init = () => {
    let input_fields = document.querySelectorAll('#master-perk-form input:not([type=hidden])');    
    let text_area_fields = document.querySelectorAll('#master-perk-form textarea');
    [...input_fields, ...text_area_fields].forEach(element => {
      element.addEventListener('change', update_preview)
    });
    $('#admin_perks_course_selector').select2();
    $(document).on('click', '#delete-master-perk', deleteMasterperk);
  };
  
  const update_preview = (e) => {
    let field_name = e.target.id.split('master_perk_')[1];
    if(field_name==='logo'){
      let img_url = URL.createObjectURL(e.target.files[0]);
      $('#perk-logo').attr('src', img_url);
    }else{
      let selector = preview_selectors[field_name];
      update_field(selector, e.target.value);
    }
  }

  const deleteMasterperk = function(e){
    e.preventDefault();
    if (confirm('Do you want to remove this perk ?')){
      $.ajax({
        url: `/en/admin/master_perks/${$(this).data('id')}`,
        method: 'DELETE',
        dataType: 'script'
      });
      alert('Perk has been deleted. You will be redirected to the perks list page');
      window.location.replace(document.referrer);
    }
  };

  const preview_selectors = {
    'name': '.perk-name',
    'discount_text': '.discount-text',
    'description': '.description',
    'how_to_redeem': '.redeem-text',
    'redeem_url': '.redeem-url',
  }

  const update_field = (selector, value) => {
    console.log("updating" + selector + " with value " + value);
    $(selector).html(value);
  }

  return {
    init: init
  };
})();
