import PropTypes from 'prop-types';
import React from 'react';
import StyledHeader from './styles';
import { formattedDate } from '../../../../utils/datetime';

const Header = ({ amount }) => (
  <StyledHeader>
    <h2>Payment details</h2>
    <p className="cf-small cf-bold sub-heading">Total One-time payment Fee</p>
    <div className="installment">
      <p className="cf-small">{formattedDate(new Date())} (today)</p>
      <p className="cf-small">€{amount}</p>
    </div>
    <div className="enroll__payment__providers">
      <div className="disabled">
        <i className="fa fa-credit-card"></i>
        <p className="cf-allcaps cf-bold">debit / credit card</p>
      </div>
      <div className="disabled">
        <i className="fa fa-paypal"></i>
        <p className="cf-allcaps cf-bold">paypal</p>
      </div>
      <div className="active">
        <i className="fa fa-university"></i>
        <p className="cf-allcaps cf-bold">sepa direct debit</p>
      </div>
    </div>
  </StyledHeader>
);

Header.propTypes = {
  amount: PropTypes.number,
};

export default Header;
