import ButtonWrapper from '../../../EmployerConnector/styles/ButtonWrapper';
import styled from 'styled-components';

const StyledButtonWrapper = styled(ButtonWrapper)`
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.leftButtons ? 'flex-start' : 'flex-end'};
  padding-top: ${(props) => (props.leftButtons ? '0px' : '50px')};
  padding-right: ${(props) => (props.leftButtons ? '0px' : '16px')};
  background: var(--ds-color__white);
  align-items: center;
  z-index: 2;

  button {
    white-space: nowrap;
    text-align: center;
    padding: 8px 16px;
    margin-bottom: 16px;
    width: 141px;
    margin: 0 7px 32px 0;
  }
`;

StyledButtonWrapper.displayName = 'StyledButtonWrapper';

export default StyledButtonWrapper;
