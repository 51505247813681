import React, { useEffect, useState } from 'react';
import {
  createAttachment,
  deleteAttachment,
  handleUpload,
} from '../../../Uploader/actions';
import ErrorText from './ErrorText';
import PropTypes from 'prop-types';
import Spinner from '../../../CareerSupportCenter/components/Loader';
import StyledAttachmentPreview from '../../styles/StyledAttachmentPreview';
import StyledFileAttachment from '../../styles/StyledFileAttachment';
import StyledUploader from '../../styles/StyledUploader';
import { getCurrentUserId } from '../../../UserProfile/actions';

const FileUploader = ({
  fileType = '',
  attachableId,
  attachableType = '',
  hardDelete = true,
  previousAttachment,
  isRequiredError = false,
  maxSize,
  allowedFormat = [],
  subtype = '',
  onAttachmentChange,
}) => {
  const [attachment, setAttachment] = useState(previousAttachment);
  const [oldAttachment] = useState(previousAttachment);
  const [uploadError, setUploadError] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {
    if (typeof onAttachmentChange === 'function') {
      onAttachmentChange({ attachment, type: subtype });
    }
  }, [attachment]);

  useEffect(() => {
    const error = isRequiredError ? 'This field is required' : null;
    setUploadError(error);
  }, [attachment]);

  const dismissError = () => {
    setUploadError(null);
  };

  const replacePreviousAttachment = async () => {
    if (oldAttachment) {
      await deleteAttachment(oldAttachment.id);
    }
  };

  const handleAttachment = async (file, subtype) => {
    dismissError();
    if (file) {
      const size = Math.floor(file.size / (1024 * 1000));
      if (size > maxSize) {
        setUploadError('File size should be less than 10MB');
        return;
      }

      if (!allowedFormat.includes(file.type)) {
        setUploadError('PDF file format required');
        return;
      }
      setUploadLoading(true);
      await replacePreviousAttachment();
      getCurrentUserId().then((result) => {
        handleUpload(file, fileType, result).then((response) => {
          createAttachment(response, attachableType, subtype, attachableId).then(
            (response) => {
              if (response.type === 'danger') {
                setUploadError('Couldn’t upload file, please try again');
              } else {
                setAttachment(response.data);
                dismissError();
              }
              setUploadLoading(false);
            },
          );
        });
      });
    }
  };

  const handleDelete = (id) => {
    if (hardDelete) {
      deleteAttachment(id).then((response) => {
        setAttachment(null);
      });
    } else {
      setAttachment(null);
    }
  };

  return (
    <>
      {attachment
        ? (
          <StyledFileAttachment>
            <StyledAttachmentPreview
              key={attachment.id}
              attachment={attachment}
              deleteAttachment={handleDelete}
            />
          </StyledFileAttachment>
        )
        : (
          <>
            <StyledUploader>
              {
                uploadLoading
                  ? <Spinner />
                  : <>
                    <input
                      type="file"
                      name="file"
                      onChange={(event) =>
                        handleAttachment(event.target.files[0], subtype)
                      }
                    />
                    <i className="fa fa-upload" aria-hidden="true"></i></>
              }

              {uploadError && <ErrorText error={uploadError} />}
            </StyledUploader>
          </>
        )}
    </>
  );
};

FileUploader.propTypes = {
  allowedFormat: PropTypes.array.isRequired,
  attachableId: PropTypes.number.isRequired,
  attachableType: PropTypes.string,
  fileType: PropTypes.string.isRequired,
  hardDelete: PropTypes.bool,
  isRequiredError: PropTypes.bool,
  maxSize: PropTypes.number.isRequired,
  onAttachmentChange: PropTypes.func.isRequired,
  previousAttachment: PropTypes.shape({
    attachableId: PropTypes.number,
    attachableType: PropTypes.string,
    contentType: PropTypes.string,
    fileIcon: PropTypes.string,
    fileName: PropTypes.string,
    fileSize: PropTypes.number,
    id: PropTypes.number,
    s3Url: PropTypes.string,
    subtype: PropTypes.string,
  }),
  subtype: PropTypes.string.isRequired,
};

export default FileUploader;
