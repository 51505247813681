import styled from 'styled-components';

const MentorNotesStyles = styled.div`
  padding: 32px;

  & > button {
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    & > button {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 479px) {
    padding: 0;
  }
`;

MentorNotesStyles.displayName = 'MentorNotesStyles';

export default MentorNotesStyles;
