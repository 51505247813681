import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Header, SectionWrapper } from '../Documents/styles';
import { getInstructorCapacity, validateInput } from './helpers';
import { FormWrapper } from './styles';
import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import StyledInput from '../../../../shared/components/Input/styles';
import { SubmitButton } from '../PersonalInformation/styles';

const Capacity = ({ candidateRole, capacity, handleSubmit, loadingButton }) => (
  <SectionWrapper>
    <Header>
      <div>
        <h3 className="ds-typography__h3 text-left">Capacity</h3>
        <p className="ds-typography__small text-center">* required field</p>
      </div>
      <p className="ds-typography__body">{`Please enter the ideal number of students you would like to work with. We suggest no fewer than 10 and no more than ${getInstructorCapacity(
        candidateRole
      )} students. When entering this number, be mindful of your time and availability. Keep in mind that as student numbers fluctuate, we cannot always guarantee that you will work with your ideal number of students.`}</p>
    </Header>
    <FormWrapper>
      <Formik
        initialValues={{ capacity }}
        validate={(values) => validateInput(values, candidateRole)}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values.capacity);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <StyledInput error={touched.capacity && errors.capacity}>
              <Field
                type="number"
                name="capacity"
                placeholder="Ideal number of students*"
              />
              <ErrorMessage name="capacity">
                {(msg) => (
                  <p className="ds-typography__small--bold error-text">{msg}</p>
                )}
              </ErrorMessage>
            </StyledInput>
            <SubmitButton
              type="submit"
              disabled={isSubmitting}
              className="ds-button__primary"
            >
              {loadingButton ? (
                <LoadingSpinner />
              ) : (
                <>
                  Save and continue<i className="ds-icon__arrow-thin-right"></i>
                </>
              )}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  </SectionWrapper>
);

Capacity.propTypes = {
  candidateRole: PropTypes.string,
  capacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSubmit: PropTypes.func,
  loadingButton: PropTypes.bool,
};

export default Capacity;
