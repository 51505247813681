import styled from 'styled-components';

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    width: 313px;
    margin: 0 0 16px;
    height: 48px;
  }

  a {
    width: 313px !important;
    text-align: center;
    height: 48px;
  }

  @media (max-width: 767px) {
    button,
    a {
      width: 100% !important;
    }
  }
`;

StyledButtonWrapper.displayName = 'StyledButtonWrapper';
export default StyledButtonWrapper;
