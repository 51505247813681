import styled from 'styled-components';

const StyledJobSearchTile = styled.div`
  margin-bottom: 16px;
  max-width: 100%;

  .inner {
    padding-right: 20px;
    display: flex;
    height: 100%;
    position: relative;
    flex-direction: column;
    padding: 18px;
    background: #ffffff;
    text-align: center;
    position: relative;
    align-items: center;
    p {
      color: var(--ds-color__indigo);
    }

    border: 1px solid #e8ebed;

    box-shadow: 0px 4px 6px rgba(34, 60, 80, 0.16);
    border-radius: 4px;

    a {
      margin-top: auto;
      margin-bottom: 22px;

      :hover {
        color: var(--ds-color__indigo) !important;
        background-color: white !important;
      }

      :visited {
        background-color: white !important;
      }
    }
  }
`;

StyledJobSearchTile.displayName = 'StyledJobSearchTile';

export default StyledJobSearchTile;
