module.exports = (() => {
  var thisState = {};

  var init = function(){
    thisState.form = new CF.Form('#register-form');
  }

  return {
    init: init
  }
})();
