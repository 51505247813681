import { createEmployer, deleteEmployer, handleCloseModal, updateEmployer } from '../../../actions';
import EmployerForm from '../../components/EmployerForm';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const EmployerTableContainer = (props) => {
  return (
    <EmployerForm {...props} />
  );
};

EmployerTableContainer.propTypes = {
  createEmployer: PropTypes.func,
  employer: PropTypes.shape({
    companyName: PropTypes.string,
    contactName: PropTypes.string,
    email: PropTypes.string,
    frozen: PropTypes.bool,
    id: PropTypes.number,
    lastLogin: PropTypes.string,
    sizeRange: PropTypes.string,
  }),
  updateEmployer: PropTypes.func,
};

export const mapStateToProps = ({
  adminEmployerConnector: {
    employer,
    errors,
    modalAction,
  },
}) => ({
  employer,
  errors,
  modalAction,
});

export const mapDispatchToProps = dispatch => ({
  createEmployer: (payload) => dispatch(createEmployer(payload)),
  deleteEmployer: (id) => dispatch(deleteEmployer(id)),
  handleCloseModal: () => dispatch(handleCloseModal()),
  updateEmployer: (id, payload) => dispatch(updateEmployer(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployerTableContainer);
