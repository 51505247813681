import { optionForTax, taxInformation } from './helpers';
import { FormSection } from '../styles';
import PropTypes from 'prop-types';
import React from 'react';
import SectionHeader from '../../SectionHeader';

const TaxInputs = ({
  formErrors,
  handleFileUpload,
  handleSave,
  handleTaxChange,
  personalData,
  region,
  removeFile,
  removeTaxInput,
  taxInputs,
  taxDocumentsRequired,
}) => (
  <FormSection last={true}>
    <SectionHeader
      headline='Tax Information'
      tooltipText={taxInformation(region)}
      country={personalData.country}
    />
    {optionForTax(
      region,
      personalData,
      handleSave,
      handleTaxChange,
      taxInputs,
      removeTaxInput,
      formErrors,
      handleFileUpload,
      removeFile,
      taxDocumentsRequired
    )}
  </FormSection>
);

TaxInputs.propTypes = {
  formErrors: PropTypes.object,
  handleFileUpload: PropTypes.func,
  handleSave: PropTypes.func,
  handleTaxChange: PropTypes.func,
  personalData: PropTypes.object,
  region: PropTypes.string,
  removeFile: PropTypes.func,
  removeTaxInput: PropTypes.func,
  taxDocumentsRequired: PropTypes.bool,
  taxInputs: PropTypes.array,
};

export default TaxInputs;
