import ApiService from '../../../../services/ApiService';

export const getJobApplications = (userId) => async (dispatch) => {
  const apiService = new ApiService(
    `/api/v1/job_applications?user_id=${userId}`
  );
  try {
    const result = await apiService.makeGetCall();
    dispatch({
      applications: result.data.data,
      type: 'UserProfile.JOB_APPLICATIONS_LOADED',
    });
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const setActivePage = (activePage) => ({
  activePage,
  type: 'UserProfile.SET_ACTIVE_PAGE_FOR_JOB_APPLICATIONS',
});

export const handleCloseModal = () => ({
  type: 'UserProfile.CLOSE_MODAL',
});

export const showApplicationModal = (jobId) => ({
  jobId,
  type: 'UserProfile.HANDLE_VIEW_JOB_APPLICATION',
});
