import styled from 'styled-components';

const StyledQuestionGuidelines = styled.div`
  .button-section {
      margin-top: 50px;
      text-align: center;
  }
`;

StyledQuestionGuidelines.displayName = 'StyledQuestionGuidelines';

export default StyledQuestionGuidelines;
