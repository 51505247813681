import { readTimeHumanized, taskTimeRange } from './helpers';
import CurrentIndicator from './styles/CurrentIndicator';
import ExerciseTitle from './styles/ExerciseTitle';
import ExerciseWrapper from './styles/ExerciseWrapper';
import HeadsUpMessage from './HeadsUpMessage';
import Notice from './Notice';
import PropTypes from 'prop-types';
import React from 'react';
import TitleWrapper from './styles/TitleWrapper';

const Exercise = ({ ex, next }) => (
  <ExerciseWrapper>
    <a href={ex.link}>
      <TitleWrapper>
        {next && <CurrentIndicator status={!!ex.exercise_state} />}
        <div>
          <ExerciseTitle
            className='ds-typography__body--bold'
            isNotFuture={!!ex.exercise_state || next}
          >
            {ex.exercise_title}
          </ExerciseTitle>
          <p className='ds-typography__small ds-color__grey no-margin'>
            Read time: {readTimeHumanized(ex.read_time)} / Task time:{' '}
            {taskTimeRange({ max: ex.max_task_time, min: ex.min_task_time })}
          </p>
        </div>
      </TitleWrapper>
      <Notice
        exerciseState={ex.exercise_state}
        next={next}
        reviewerName={ex.reviewer_name}
        reviewerImg={ex.reviewer_img}
        studentAvatar={ex.student_avatar}
        postscript={ex.postscript}
      />
    </a>
    {ex.future_step_postscript && (
      <HeadsUpMessage futureStepPostscript={ex.future_step_postscript} />
    )}
  </ExerciseWrapper>
);

Exercise.propTypes = {
  ex: PropTypes.shape({
    exercise_state: PropTypes.string,
    exercise_title: PropTypes.string.isRequired,
    future_step_notice: PropTypes.object,
    future_step_postscript: PropTypes.object,
    link: PropTypes.string.isRequired,
    max_task_time: PropTypes.number.isRequired,
    min_task_time: PropTypes.number.isRequired,
    postscript: Notice.propTypes.postscript,
    read_time: PropTypes.number.isRequired,
    reviewer_img: PropTypes.string,
    reviewer_name: PropTypes.string,
    student_avatar: PropTypes.string,
    tags: PropTypes.array,
  }),
  next: PropTypes.bool,
};

export default Exercise;
