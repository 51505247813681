import styled from 'styled-components';

const StyledHeaderImage = styled.div`
  height: 300px;
  flex-basis: 45%;
  background-repeat: no-repeat;
  background-image: url('https://coach-courses-us.s3.amazonaws.com/public/images/create_new_project.png');
  background-position: center;

  @media (min-width: 1480px) {
    flex-basis: 410px;
  }

  @media (max-width: 767px) {
    background-size: contain;
    height: 168px;
    margin-bottom: 24px;
    flex-basis: unset;
  }
`;
StyledHeaderImage.displayName = 'StyledHeaderImage';
export default StyledHeaderImage;
