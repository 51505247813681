import React from 'react';
import Survey from './components/Survey';
import { createRoot } from 'react-dom/client';

const diversitySurveyContainer = document.getElementById(
  'diversity_survey_form'
);

if (diversitySurveyContainer) {
  const diversitySurveyContainerRoot = createRoot(diversitySurveyContainer);

  diversitySurveyContainerRoot.render(<Survey />);
}
