import ApiService from '../../../../services/ApiService';

export const getCallsLeft = async attendeeId => {
  const apiService = new ApiService(`/api/v1/attendees/${attendeeId}`);

  try {
    const result = await apiService.makeGetCall();
    return result.data.data;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};
