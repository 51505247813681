import { handleCancelModal, handleCloseModal } from '../../action';
import OffPlatformCallModal from '../../components/Modals/OffPlatformCallModal';
import { connect } from 'react-redux';

export const mapStateToProps = ({ mentorCallNotes: { modalType } }) => ({
  modalType,
});

export const mapDispatchToProps = (dispatch) => ({
  handleCancel: () => dispatch(handleCancelModal()),
  handleCloseModal: () => dispatch(handleCloseModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OffPlatformCallModal);
