import { MOBILE_VIEW_MAX_WIDTH } from '../../constants';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  position: fixed;
  width: 16.5rem;
  left: 5rem;
  top: 5rem;
  z-index: 12;
  background-color: #526675;
  overflow-y: auto;
  padding-bottom: 5rem;

  @media (max-width: ${MOBILE_VIEW_MAX_WIDTH}px) {
    display: none;
  }
`;

const Title = styled.div`
  margin: 1.5rem 1.5rem 0.5rem;
  color: var(--ds-color__white);
`;

export { Wrapper, Title };
