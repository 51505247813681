import styled from 'styled-components';

const RatingWrapper = styled.div`
  .heading {
    margin-bottom: 0;
  }

  i {
    color: var(--ds-color__int-border-icon-disabled);
    cursor: pointer;

    &.filled {
      color: var(--ds-bg-color__int-primary);
    }
  }
`;

const Question = styled.p`
  margin-bottom: 4px;
  width: 70%;

  @media (max-width: 768px) {
    width: auto;
  }
`;

const QuestionWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

const StarWrapper = styled.div`
  display: flex;
`;

export { RatingWrapper, StarWrapper, Question, QuestionWrapper };
