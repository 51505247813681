import styled from 'styled-components';

const StyledButton = styled.div`
  margin-bottom: 24px;
  position: relative;

  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    opacity: 0;
    cursor: pointer;
    z-index: -1;
  }

  label,
  i {
    font-size: 16px;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: var(--ds-color__petrol--dark);
    }
  }

  i {
    margin-right: 6px;
  }
`;

StyledButton.displayName = 'Button';

export default StyledButton;
