import styled from 'styled-components';

const StyledAccordion = styled.div`
  .accordion__button {
    width: 100%;
    height: 45px;
    background: #f4f5f6;
    padding: 0 14px 0;
    margin-bottom: 11px;

    h5 {
      font-size: 20px;
      margin: 0;
      line-height: 40px;
    }
  }

  .accordion__button:before {
    display: inline-block;
    float: right;
    margin-top: 15px;
    content: '';
    height: 12px;
    width: 12px;
    border-top: 4px solid #000;
    border-left: 4px solid #000;
    transform: rotate(225deg);
    transition: all 0.2s;
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }

  .accordion__panel {
    padding: 16px 16px 0;
    border-top: 0;
    margin-top: -11px;
    margin-bottom: 11px;
    background: #f4f5f6;

    p {
      margin-bottom: 10px;
    }

    p.break,
    ul.break {
      margin-bottom: 40px;
    }

    ul {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

StyledAccordion.displayName = 'StyledAccordion';

export default StyledAccordion;
