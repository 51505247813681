import Tabs from '../../components/Tabs';
import { connect } from 'react-redux';
import { setActiveTab } from '../../actions';

export const mapStateToProps = ({ callEdits: { activeTab, callData } }) => ({
  activeTab,
  tabs: callData,
});

export const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
