import ApiService from '../../../services/ApiService';
import { getAuthToken } from '../../../utils/getAuthToken';

export const getData = async (attendeeId) => {
  const apiService = new ApiService(
    `/api/v1/attendee/${attendeeId}/calendar_entries.json`
  );

  try {
    const result = await apiService.makeGetCall();
    return result.data.data;
  } catch (error) {
    return error;
  }
};

export const postData = (data) => {
  const params = {
    authenticity_token: getAuthToken(),
    feedbackable_id: data.callId,
    feedbackable_type: 'CalendarEntry',
    form_id: data.formId,
    owner_id: data.attendeeId,
    owner_type: 'Attendee',
    response_id: data.responseId,
  };

  const apiService = new ApiService('/api/v1/feedbacks.json');

  return apiService.makePostCall(params).catch((error) => {
    throw error;
  });
};
