import { IProgress, IProgressSection, ITabData } from './interfaces';
import { Cap } from '@careerfoundry/bubble';
import React from 'react';

export const renderStatus = (status: string, active: boolean) => {
  if (active) {
    switch (status) {
      case 'pending':
        return (
          <Cap
            tag='p'
            variant='bold'
            style={{ color: 'var(--ds-color__feedback-content-neutral)' }}
          >
            waiting for review
          </Cap>
        );
      case 'done':
        return (
          <Cap tag='p' variant='bold'>
            <i
              className='ds-icon__check-thin completed-section'
              style={{ color: 'var(--ds-color__feedback-content-positive)' }}
            ></i>
          </Cap>
        );
      case 'todo':
        return (
          <Cap
            tag='p'
            variant='bold'
            style={{ color: 'var(--ds-color__feedback-content-neutral)' }}
          >
            to do
          </Cap>
        );
      case 'action_required':
        return (
          <Cap
            tag='p'
            variant='bold'
            style={{ color: 'var(--ds-color__feedback-content-negative)' }}
          >
            action required
          </Cap>
        );
      default:
        return '';
    }
  } else {
    return (
      <i
        className='ds-icon__lock-line'
        style={{ color: 'var(--ds-color__int-content-primary-disabled)' }}
      />
    );
  }
};

export const setSubTab = (tabData: ITabData) =>
  tabData.state === 'done' ? '2' : '1';

export const setInitialTab = (progress: IProgress) => {
  const sidebarEntries = Object.entries(progress.sidebar);
  const section = sidebarEntries.find(([_, element]) => {
    return element.status === 'action_required' || element.status === 'todo';
  });
  return section ? section[0] : 'afaVoucher';
};

export const setSelectedSubTab = (tab: string, tabData: ITabData) => {
  return tab === 'afaVoucher' ? setSubTab(tabData) : '';
};
