import App from '../../components/App';
import PropTypes from 'prop-types';
import React from 'react';

export const AppContainer = ({
  attendeeId,
}) => <App attendeeId={attendeeId} />;

AppContainer.propTypes = {
  attendeeId: PropTypes.number.isRequired,
};

export default AppContainer;
