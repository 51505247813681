import { Header, Tooltip, TooltipWrapper } from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const SectionHeader = ({ children, country, headline, tooltipText }) => (
  <Header>
    <h5 className="ds-typography__h5">{headline}</h5>
    <TooltipWrapper>
      <i className="ds-icon__help-line"></i>
      <Tooltip country={country}>{tooltipText}</Tooltip>
    </TooltipWrapper>
    {children}
  </Header>
);

SectionHeader.propTypes = {
  children: PropTypes.node,
  country: PropTypes.string,
  headline: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
};

export default SectionHeader;
