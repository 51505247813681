import { DataPolicyAgreementModalContent } from './styles';
import PropTypes from 'prop-types';
import React from 'react';
import TheModal from '../../../shared/components/TheModal';
import { getDiscourseCategoryUrl } from '../../helpers';
import { noop } from 'lodash';
import { redirectTo } from '../../../../utils/redirectTo';

const DataPolicyAgreementModal = ({
  activeCourseSlug = '',
  shouldShowDataPolicyAgreementModal = false,
  isAcceptingPrivacyPolicy = false,
  setIsAcceptingPrivacyPolicy = noop,
  setShouldShowDataPolicyAgreementModal = noop,
  acceptPrivacyPolicy = noop,
}) => (
  <TheModal
    isModalOpen={shouldShowDataPolicyAgreementModal}
    handleClose={() => setShouldShowDataPolicyAgreementModal(false)}
  >
    <DataPolicyAgreementModalContent>
      <h1 className="ds-typography__h4">Data policy agreement</h1>
      <p className="ds-typography__body">
        You’re being directed to CareerFoundry’s Forum, hosted on Discourse.
        <br />
        Please read and accept{' '}
        <a
          rel="noreferrer"
          href="https://www.discourse.org/privacy"
          target="_blank"
        >
          Discourse’s data policy
        </a>{' '}
        to access the Forum.
      </p>
      <div className="button-container">
        <button
          className="ds-button__secondary"
          onClick={() => setShouldShowDataPolicyAgreementModal(false)}
        >
          Close
        </button>
        <button
          className="ds-button__primary"
          onClick={() => {
            setIsAcceptingPrivacyPolicy(true);
            acceptPrivacyPolicy(() =>
              redirectTo({
                openInNewTab: true,
                url: getDiscourseCategoryUrl(activeCourseSlug),
              })
            );
          }}
          disabled={isAcceptingPrivacyPolicy}
        >
          I accept
        </button>
      </div>
    </DataPolicyAgreementModalContent>
  </TheModal>
);

DataPolicyAgreementModal.propTypes = {
  acceptPrivacyPolicy: PropTypes.func,
  activeCourseSlug: PropTypes.string,
  isAcceptingPrivacyPolicy: PropTypes.bool,
  setIsAcceptingPrivacyPolicy: PropTypes.func,
  setShouldShowDataPolicyAgreementModal: PropTypes.func,
  shouldShowDataPolicyAgreementModal: PropTypes.bool,
};

export default DataPolicyAgreementModal;
