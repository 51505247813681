import styled from 'styled-components';

const ModalWrapper = styled.div`
  & > div > div {
    height: 80vh;
  }

  header {
    border: 1px solid #c0c6cb;
    padding: 14px;

    h3 {
      margin-top: 0;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 0;
    }
  }

  @media (max-width: 479px) {
    & > div > div {
      height: 100vh;
    }
  }
`;

ModalWrapper.displayName = 'ModalWrapper';

export default ModalWrapper;
