import ErrorText from './ErrorText';
import PropTypes from 'prop-types';
import React from 'react';
import StatementParagraph from './StatementParagraph';

const StatementTextArea = ({ errors = [], setError, motivationStatement, updateECProfile, isProfile = false }) => {
  const errorTexts = { isRequired: 'This field is required', maxLength: 'This field is limited to 200 words.' };

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    const count = value.split(' ').length;
    if (count > 200 && !errors.includes('maxLength')) {
      setError(['maxLength']);
    } else {
      setError([]);
    }
    updateECProfile({ field: name, value });
  };

  return (
    <>
      {!isProfile &&
      <>
        <h5 className="ds-typography__body text-left">Professional statement*</h5>
        <StatementParagraph />
      </>
      }

      {errors.map((error) => (
        <ErrorText key={error} error={errorTexts[error]} />
      ))}
      <textarea
        className="ds-typography__body"
        name="motivationStatement"
        onChange={handleTextChange}
        value={motivationStatement}
      />
    </>
  );
};

StatementTextArea.propTypes = {
  errors: PropTypes.array.isRequired,
  isProfile: PropTypes.bool,
  motivationStatement: PropTypes.string,
  setError: PropTypes.func.isRequired,
  updateECProfile: PropTypes.func.isRequired,
};

export default StatementTextArea;
