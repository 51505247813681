import LabelSection from './styles/LabelSection';
import ParagraphWithLabel from '../../../../../EmployerConnector/modals/shared/ParagraphWithLabel';
import ProfileSeparator from '../../../../../EmployerConnector/modals/showModal/styles/ProfileSeparator';
import PropTypes from 'prop-types';
import React from 'react';
import StyledHeaderWrapper from '../../../../../EmployerConnector/modals/showModal/styles/StyledHeaderWrapper';
import StyledShowModal from '../../../../../EmployerConnector/modals/showModal/styles/StyledShowModal';

const EmployerShow = ({ employer }) => {
  return (
    <StyledShowModal>
      <div className="ds-grid__row">
        <div className="ds-grid__col-sm-12">
          <StyledHeaderWrapper>
            <h5 className="ds-typography__h5 text-left">Employer info</h5>
          </StyledHeaderWrapper>
          <ProfileSeparator />
          <LabelSection>
            <h5 className="ds-typography__h5 text-left">Personal data</h5>
            <ParagraphWithLabel label="Name" paragraph={employer.contactName} />
            <ParagraphWithLabel label="Company name" paragraph={employer.companyName} />
            <ParagraphWithLabel label="Email" paragraph={employer.email} />
            <ParagraphWithLabel label="Status" paragraph={employer.frozen ? 'Frozen' : 'Active'} />
            <ParagraphWithLabel label="Last login" paragraph={employer.lastLogin} />
            <ParagraphWithLabel label="Company size" paragraph={employer.sizeRange} />
            <ParagraphWithLabel label="Temporary password" paragraph={employer.password || 'Password has been updated'} />
          </LabelSection>
        </div>
      </div>
    </StyledShowModal>
  );
};

EmployerShow.propTypes = {
  employer: PropTypes.shape({
    companyName: PropTypes.string,
    contactName: PropTypes.string,
    email: PropTypes.string,
    frozen: PropTypes.bool,
    id: PropTypes.number,
    lastLogin: PropTypes.string,
    password: PropTypes.string,
    sizeRange: PropTypes.string,
  }),
};

export default EmployerShow;
