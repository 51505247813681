import ApiService from "./ApiService";

class StartDateService {

  getStartDates(id) {
    const apiService = new ApiService(`/api/v1/admin/products/${id}/start_dates.json`);

    return apiService.makeGetCall().then(result => {
      return result.data.data;
    })
    .catch(error => {
      throw error;
    })
  };
};

export default StartDateService;
