import ButtonSection from './styles/InterviewToolCard__ButtonSection';
import PropTypes from 'prop-types';
import React from 'react';
import StyledCardParagraph from '../Dashboard/styles/StyledCardParagraph';
import StyledCardSubheader from '../Dashboard/styles/StyledCardSubheader';
import StyledInterviewToolCard from './styles/StyledInterviewToolCard';

const InterviewToolCard = ({ openInterviewGuidelines, startInterview }) => (
  <StyledInterviewToolCard>
    <StyledCardSubheader className="ds-typography__h4">
      New Practice
    </StyledCardSubheader>
    <StyledCardParagraph className="ds-typography__body">
      Practice answering 5 questions from a category of your choice.
    </StyledCardParagraph>
    <ButtonSection>
      <a
        onClick={() => startInterview('interviewPrepDashboard')}
        className="ds-button__primary"
      >
        Start interview practice
      </a>
      <a
        onClick={() => openInterviewGuidelines()}
        className="ds-button__secondary"
      >
        Check interview tool guidelines
      </a>
    </ButtonSection>
    <hr />
  </StyledInterviewToolCard>
);

InterviewToolCard.propTypes = {
  openInterviewGuidelines: PropTypes.func.isRequired,
  startInterview: PropTypes.func.isRequired,
};

export default InterviewToolCard;
