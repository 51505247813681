import styled from 'styled-components';

const EvaluationContent = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  max-height: ${props => props.isModal && '75vh'};
  border: ${props => !props.isModal && 'solid 1px #c0c6cb;'};

  padding: 0 0 72px;

  .insight-body {
    padding: 16px;
    background-color: #f4f5f6;
    margin-bottom: 32px;

    p {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .section {
    padding 0; 
    margin-top: 0;
  }


  .evaluation-section {
    padding-top: 0;

    .evaluation-header {
      margin-bottom: 16px;
    }

    .evaluation-subheader {
      font-size: 12px;
      font-family: var(--ds-font__tradegothic);
      margin-bottom: 9px;
    }

    .w-6, .h-6 {
      height: 15px;
      width: 15px;
      margin-right: 9px;
    }
  }

  .evaluation-note {
    margin-top: 32px;
    span {
      margin-bottom: 8px;
    }
    textarea, span {
      font-size: 12px;
    }
  }

  ${({ isModal }) => !isModal && `
    padding: 0;
    .insight-body {
      margin-bottom: 24px;
    }

    .audio-wrapper {
      margin: 0 auto 15px !important;
    }

    .evaluation-section {
      .evaluation-header {
        margin-bottom: 9px;
      }
      p {
        font-size: 9px;
        line-height: 2.22;
        color: #223c50;
        margin-bottom: 0;
      }

      .mb-24 {
        margin-bottom: 24px;
      }
      .evaluation-subheader {
        margin: 24px 0 0;
      }
    }
  `}

  @media (max-width: 768px) {
    ${({ isModal }) => !isModal && `
      margin: 0;
      border: 0;
      border-bottom: solid 2px #eef0f1;
    `}

    .insight-body {
      padding: 8px;
  
      p {
        font-size: 12px;
        line-height: 1.67;

        a {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .evaluation-section {
      .w-6, .h-6 {
        height: 29px;
        width: 29px;
        margin-right: 11px;
      }
    }
  }

  @media (max-width: 479px) {
    .insight-body {  
      p {
        a {
          font-size: 12px;
        }
      }
    }
  }
  
`;

EvaluationContent.displayName = 'EvaluationContent';

export default EvaluationContent;
