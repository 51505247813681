import PropTypes from 'prop-types';
import React from 'react';
import StyledButton from './styles';

const UploadButton = ({ handleChange, required }) => (
  <StyledButton>
    <button className="ds-button__secondary">
      <i className="ds-icon__cloud-upload"></i>
      <p className="ds-typography__body--bold">Upload File</p>
      <input
        type="file"
        onChange={handleChange}
        required={required}
      />
    </button>
    <p className="ds-typography__small help-text">5 MB limit, file types allowed: jpg, png, pdf</p>
  </StyledButton>
);

UploadButton.propTypes = {
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default UploadButton;
