import React, { useState } from 'react';
import App from '../../components/App';
import { LoadingChannels } from 'stream-chat-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getQueryVariable } from '../../../../../utils/getUrlQueryParams';
import { queriedChannelsSuccess } from '../../../actions';
import { useChannels } from '../../../hooks';

export const AppContainer = ({
  channelsHaveLoaded,
  chatHasLoaded,
  sort,
  uuid,
  queriedChannelsSuccess,
  memberChannelId,
}) => {
  const filters = {
    members: { $in: [uuid] },
    type: 'messaging',
  };
  useChannels(chatHasLoaded, filters, sort, queriedChannelsSuccess);
  const [activeChannelId] = useState(getQueryVariable('id') || memberChannelId);

  return chatHasLoaded && channelsHaveLoaded ? (
    <App filters={filters} sort={sort} memberChannelId={activeChannelId} />
  ) : (
    <LoadingChannels />
  );
};

AppContainer.propTypes = {
  channelsHaveLoaded: PropTypes.bool,
  chatHasLoaded: PropTypes.bool,
  memberChannelId: PropTypes.string,
  queriedChannelsSuccess: PropTypes.func,
  sort: PropTypes.object,
  uuid: PropTypes.string,
};

export const mapStateToProps = ({
  messaging: { channelsHaveLoaded, chatHasLoaded, me, sort, memberChannelId },
}) => ({
  channelsHaveLoaded,
  chatHasLoaded,
  memberChannelId,
  sort,
  uuid: me?.uuid,
});

export const mapDispatchToProps = (dispatch) => ({
  queriedChannelsSuccess: (channelId) =>
    dispatch(queriedChannelsSuccess(channelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
