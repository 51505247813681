import {
  AssignmentButtonWrapper,
  TableWrapper,
} from './styles';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../../../../../shared/components/Tooltip';

const ListRow = ({
  assigningInstructorId,
  assigningStudentId,
  listItem,
  toggleInstructorAssignment,
}) => {
  return (
    <tr>
      <td>
        <Tooltip
          content={listItem.leave}
          place="top"
        >
          <div>
            {listItem.tags && listItem.tags.map(tag => (
              <div className="instructor-tag" key={tag.name} style={{ color: tag.color }}>
                <span className="ds-typography__small--bold">{tag.name}</span>
              </div>
            ))}
          </div>
        </Tooltip>
      </td>
      <td>
        <a href={`/en/admin/users/${listItem.slug}`}>{listItem.name}</a>
        <br/>
        <span className="ds-typography__body--bold">Current Students:</span> {listItem.current_students}
      </td>
      <td>
        <span className="ds-typography__body--bold">New students:</span> {listItem.new_students}
      </td>
      <td>
        <span className="ds-typography__body--bold">Total capacity:</span> {listItem.total_capacity}
      </td>
      <td>
        <span className="ds-typography__body--bold">Students &gt; 85%:</span> {listItem.students_ending_soon}
      </td>
      <td>
        <span className="ds-typography__body--bold">Free Capacity:</span> {listItem.total_capacity - listItem.current_students}
        <br/>
        <span className="ds-typography__body--bold">Timezone:</span> {listItem.timezone}
        {listItem.certified && <div className="ds-typography__body--bold">*Certified</div>}
      </td>
      {assigningStudentId && (
        <AssignmentButtonWrapper>
          <button
            className="ds-button__primary"
            onClick={() => toggleInstructorAssignment(listItem.user_id)}
          >
            Assign {assigningInstructorId === listItem.user_id ? '✅' : '✋'}
          </button>
        </AssignmentButtonWrapper>
      )}
    </tr>
  );
};

ListRow.propTypes = {
  assigningInstructorId: PropTypes.number,
  assigningStudentId: PropTypes.number,
  listItem: PropTypes.object.isRequired,
  toggleInstructorAssignment: PropTypes.func.isRequired,
};

const InstructorList = ({
  assigningInstructorId,
  assigningStudentId,
  instructorList,
  toggleInstructorAssignment,
}) => {
  return (
    <TableWrapper>
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {instructorList.map(listItem =>
            <ListRow
              key={listItem.id}
              assigningStudentId={assigningStudentId}
              listItem={listItem}
              toggleInstructorAssignment={toggleInstructorAssignment}
              assigningInstructorId={assigningInstructorId}
            />,
          )}
        </tbody>
      </table>
    </TableWrapper>
  );
};

InstructorList.propTypes = {
  assigningInstructorId: PropTypes.number,
  assigningStudentId: PropTypes.number,
  instructorList: PropTypes.array.isRequired,
  toggleInstructorAssignment: PropTypes.func.isRequired,
};

export default InstructorList;
