import { Field, Form, Formik } from 'formik';
import {
  Error,
  ICreateService,
  Student,
  Service,
  ServiceType,
  IUpdateService,
  IValues,
} from '../../interfaces';
import { ToolTipWrapper, InputWrapper } from './styles';
import { Body } from '@careerfoundry/bubble';
import Buttons from './buttons';
import DatePicker from 'react-datepicker';
import Heading from './heading';
import React, { useEffect, useState } from 'react';
import Tooltip from '../../../../shared/components/Tooltip';
import Upload from '../Upload';
import ErrorMessage from './error_message';

interface ServiceFormProps {
  error: Error;
  students: Student[];
  serviceTypes: ServiceType[];
  handleModalClose: () => void;
  handleSubmit: (params: ICreateService) => void;
  selectedService?: Service;
  handleDelete: (id: string) => void;
  handleUpdate: (params: IUpdateService, id: string) => void;
}

const ServiceForm: React.FC<ServiceFormProps> = ({
  error,
  students,
  serviceTypes,
  handleModalClose,
  handleSubmit,
  selectedService,
  handleDelete,
  handleUpdate,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(
    error.attribute === 'proof_file'
  );

  useEffect(() => {
    setUploadError(error.attribute === 'proof_file');
    setSubmitting(false);
  }, [error]);

  const setDate = () =>
    selectedService?.serviceDate
      ? new Date(selectedService.serviceDate)
      : new Date();

  const initialValues = {
    studentId: selectedService?.studentId || '',
    serviceDate: setDate(),
    serviceType: selectedService?.serviceType || '',
    proofLink: selectedService?.proofLink || '',
    comment: selectedService?.comment || '',
    proofFile: selectedService?.proofFile || {
      data: '',
      fileName: '',
      fileSize: 0,
    },
  };

  const canDelete =
    selectedService !== undefined && selectedService.state !== 'approved';

  const handleFormSubmit = (values: IValues) =>
    selectedService
      ? handleUpdate(values, selectedService.id)
      : handleSubmit(values);

  return (
    <>
      <Heading reason={selectedService?.reason} />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          setSubmitting(true);
          handleFormSubmit(values);
        }}
      >
        {({ isValid, values, setFieldValue }) => (
          <Form>
            <InputWrapper isError={!isValid}>
              <label htmlFor='studentId'>
                <Body tag='div' variant='small-bold'>
                  Student name*
                </Body>
              </label>
              <Field
                name='studentId'
                placeholder='Select name'
                as='select'
                required={true}
                value={values.studentId}
              >
                <option value=''>Type name</option>
                {students.map((student) => (
                  <option key={student.id} value={student.id}>
                    {student.name}
                  </option>
                ))}
              </Field>
            </InputWrapper>
            <InputWrapper isError={!isValid}>
              <Body tag='p' variant='large' className='description-text'>
                When did you provide the service?
              </Body>
              <label htmlFor='serviceDate'>
                <Body tag='div' variant='small-bold'>
                  Date*
                </Body>
              </label>
              <DatePicker
                selected={values.serviceDate}
                dateFormat='dd MMM. yyyy'
                name='serviceDate'
                onChange={(date: Date) => setFieldValue('serviceDate', date)}
                required={true}
                placeholderText='Select date'
              />
            </InputWrapper>
            <InputWrapper isError={!isValid}>
              <Body tag='p' variant='large' className='description-text'>
                Please choose the type of service you’re making a claim for
              </Body>
              <label htmlFor='serviceType'>
                <Body tag='div' variant='small-bold'>
                  Off-platform service*
                </Body>
              </label>
              <Field
                name='serviceType'
                placeholder='Type name'
                as='select'
                required={true}
              >
                <option value=''>Choose service</option>
                {serviceTypes.length &&
                  serviceTypes.map((serviceType) => {
                    const key = Object.keys(serviceType).toString();
                    return (
                      <option key={key} value={key}>
                        {Object.values(serviceType)}
                      </option>
                    );
                  })}
              </Field>
            </InputWrapper>
            <InputWrapper isError={!isValid}>
              <ToolTipWrapper>
                <Body tag='p' variant='large' className='description-text'>
                  Please provide proof that the service was necessary with a
                  link or a file upload.
                </Body>
                <Tooltip
                  place='bottom'
                  content='Proof of service: For a call or email, a HubSpot link or screenshot is sufficient. If you’re unsure what may be used as proof of service in your claim, please contact the Career Services Team at: careerservices@careerfoundry.com.'
                >
                  <i className='ds-icon__help-line'></i>
                </Tooltip>
              </ToolTipWrapper>
              <label htmlFor='proofLink'>
                <Body tag='div' variant='small-bold'>
                  Proof of service need*
                </Body>
              </label>
              <Field
                name='proofLink'
                placeholder='Insert link'
                label='Proof of service need*'
                required={values.proofFile.data === ''}
              />
              <Upload
                file={values.proofFile}
                proof={values.proofLink}
                setFieldValue={setFieldValue}
                name='proofFile'
                setUploadError={setUploadError}
              />
              {uploadError && (
                <ErrorMessage message={error.message[0]}></ErrorMessage>
              )}
            </InputWrapper>
            <InputWrapper isError={!isValid}>
              <Body tag='p' variant='large' className='description-text'>
                Are we missing the full story? Please add anything that will
                help make for a quick claim approval.
              </Body>
              <label htmlFor='comment'>
                <Body tag='div' variant='small-bold'>
                  Additional details (optional)
                </Body>
              </label>
              <Field
                name='comment'
                placeholder='What should the Career Services Team know?'
                label='Additional details (optional)'
              />
            </InputWrapper>
            <Buttons
              handleModalClose={handleModalClose}
              values={values}
              canDelete={canDelete}
              handleDelete={() => canDelete && handleDelete(selectedService.id)}
              submitting={submitting}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ServiceForm;
