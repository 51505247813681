const getMe = async () => {
  const userDataApiResponseData = await fetch('/api/v1/me.json');

  if (userDataApiResponseData.ok) {
    const userDataApiResponse = await userDataApiResponseData.json();
    const userData = userDataApiResponse?.data;

    if (!userData) {
      Rollbar.error('Messaging: No user data received', error);
    } else {
      return userData;
    }
  }
};

const getUserToken = async (me) => {
  const { id, slug, uuid, name, email } = me;
  const userData = {
    email,
    id,
    name,
    slug,
    uuid,
  };

  const userTokenApiResponseData = await fetch(
    '/api/v1/messaging/chats/user_token'
  );

  if (userTokenApiResponseData.ok) {
    const userTokenApiResponse = await userTokenApiResponseData.json();
    const userToken = userTokenApiResponse?.user_token;

    if (!userToken) {
      Rollbar.error('Messaging: No user token received', error, userData);
    } else {
      return userToken;
    }
  }
};

export const init = async () => {
  try {
    const me = await getMe();
    const userToken = await getUserToken(me);

    return {
      me,
      userToken,
    };
  } catch (error) {
    /**
     * TODO: Handle it in a better way (once we have a way to handle
     * API errors; like having platform level notification popups)
     */
  }
};
