import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../shared/loadingSpinner.jsx';
import NoteFiltersContainer from '../containers/NoteFiltersContainer/index.js';
import PaginatedTable from './shared/PaginatedTable.js';
import PropTypes from 'prop-types';

const NotesHistoryTable = ({
  callNotes,
  callNotesPagination: { currentPage, perPage, totalEntries, totalPages },
  currentCallId,
  currentViewId,
  fetchCallNote,
  getCallNotesHistory,
  isFetchingNote,
  filterParams,
}) => {
  const [fetchingNotes, setFetchingNotes] = useState(false);

  useEffect(() => {
    fetchNotes(1, filterParams);
  }, [filterParams]);

  const fetchNotes = async (currentPage, params) => {
    setFetchingNotes(true);
    await getCallNotesHistory(currentPage, params);
    setFetchingNotes(false);
  };

  const handleEditNote = async (noteId) => {
    fetchCallNote(noteId, 'edit');
  };

  const handleViewNote = async (noteId) => {
    fetchCallNote(noteId, 'view');
  };

  const columns = [
    { key: 'datetimeOfCall', name: 'Call date', type: 'date' },
    { key: 'name', name: 'Name', type: 'link', url: 'profileUrl' },
    { key: 'status', name: 'Status' },
    {
      actionType: 'icon',
      iconName: 'fa-eye',
      key: 'ViewNotes',
      name: 'View notes',
      onClick: handleViewNote,
      type: 'action',
    },
    {
      actionType: 'icon',
      iconName: 'fa-pencil',
      key: 'Edit',
      name: 'Edit',
      onClick: handleEditNote,
      type: 'action',
    },
  ];

  return (
    <>
      <NoteFiltersContainer />
      {fetchingNotes ? (
        <LoadingSpinner />
      ) : (
        <>
          <PaginatedTable
            activePage={currentPage}
            collection={callNotes}
            emptyText="You have not created any call notes yet. Please check the relevant section of your dashboard for pending request."
            totalPages={totalPages}
            columns={columns}
            itemsCountPerPage={perPage}
            onPageChange={(page) => getCallNotesHistory(page, filterParams)}
            totalItemsCount={totalEntries}
            loadingActions={{
              Edit: { currentId: currentCallId, isLoading: isFetchingNote },
              ViewNotes: {
                currentId: currentViewId,
                isLoading: isFetchingNote,
              },
            }}
          />
        </>
      )}
    </>
  );
};

NotesHistoryTable.propTypes = {
  callNotes: PropTypes.array,
  callNotesPagination: PropTypes.shape({
    currentPage: PropTypes.number,
    perPage: PropTypes.number,
    totalEntries: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  currentCallId: PropTypes.number,
  currentViewId: PropTypes.number,
  fetchCallNote: PropTypes.func.isRequired,
  filterParams: PropTypes.shape({
    search: PropTypes.string,
  }),
  getCallNotesHistory: PropTypes.func.isRequired,
  isFetchingNote: PropTypes.bool,
};

export default NotesHistoryTable;
