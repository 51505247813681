import Button from '../../EmployerConnector/modals/shared/Button';
import PropTypes from 'prop-types';
import React from 'react';
import StyledButtonWrapper from './styles/StyledButtonWrapper';
import StyledHeaderImage from './styles/StyledHeaderImage';
import StyledMobilePreview from '../../CareerSupportCenter/components/JobApplicationPreview/styles/StyledMobilePreview';

const MobileHeaderInfographic = ({
  isPreview = true,
  buttonLoading,
  onButtonClick,
}) => {
  return (
    <StyledMobilePreview>
      {isPreview && (
        <h4 className="ds-typography__h4">Track your job applications</h4>
      )}
      <StyledHeaderImage isPreview={true} />
      <ul>
        <li className="ds-typography__body">
          Organize and track your applications
        </li>
        <li className="ds-typography__body">Stay on top of your job search</li>
      </ul>
      <StyledButtonWrapper>
        <Button
          className="ds-button__primary"
          label="Add new application"
          loading={buttonLoading}
          onClick={() => onButtonClick()}
        />
        {isPreview && (
          <a
            href="/en/job_applications/"
            className="ds-button__secondary"
            target="_blank"
          >
            Open Full View
          </a>
        )}
      </StyledButtonWrapper>
    </StyledMobilePreview>
  );
};

MobileHeaderInfographic.propTypes = {
  buttonLoading: PropTypes.bool,
  isPreview: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
};

export default MobileHeaderInfographic;
