import React, { useEffect, useState } from 'react';
import { getMediaResourcesByIndustry, getUserIndustry } from '../../actions';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import MediaResourcePreview from '../../components/MediaResourcePreview';
import PropTypes from 'prop-types';

const MediaResourcePreviewContainer = ({ isMobile = false, userIndustry }) => {
  const [loading, setLoading] = useState(true);
  const [mediaResources, setMediaResources] = useState([]);

  useEffect(() => {
    getUserIndustry().then((industry) => {
      getMediaResourcesByIndustry(industry, true).then((res) => {
        setMediaResources(res);
        setLoading(false);
      });
    });
  }, [userIndustry]);

  return (
    <>
      { loading ? (
        <LoadingSpinner />
      ) : (
        <MediaResourcePreview mediaResources={mediaResources.slice(0, 3)} />
      )}
    </>
  );
};

MediaResourcePreviewContainer.propTypes = {
  isMobile: PropTypes.bool,
  userIndustry: PropTypes.string,
};

export default MediaResourcePreviewContainer;
