import axios from 'axios';

class AccountsService {
  constructor (authToken) {
    this.authToken = authToken;
  }

  signin (email, password, rememberMe) {
    return axios.post('/users/sign_in.json', {
      user: {
        email: email,
        password: password,
        remember_me: rememberMe,
      },
    }).then((response) => {
      localStorage.setItem('authentication-keys', JSON.stringify({
        accessToken: response.headers['access-token'],
        client: response.headers.client,
        uid: response.headers.uid,
      }));
      return ({ data: response.data, error: null });
    }).catch((error) => {
      return ({ data: null, error: error.response.data });
    });
  }

  signout () {
    return axios.get('/users/sign_out.json').then((response) => {
      localStorage.removeItem('authentication-keys');
      return ({ data: response.data, error: null });
    }).catch((error) => {
      return ({ data: null, error: error.response.data });
    });
  }

  signup (firstName, lastName, email, password, productSku, agreedToTerms, utms) {
    return axios.post('/users.json', {
      authenticity_token: this.authToken,
      user_signup_form: {
        active_course_id: productSku,
        agreed_to_terms: agreedToTerms,
        email: email,
        first_name: firstName,
        last_name: lastName,
        password: password,
      },
      ...utms,
    }).then((response) => {
      return ({ data: response.data, error: null });
    }).catch((error) => {
      return ({ data: null, error: error.response.data });
    });
  }
}
export default AccountsService;
