import ProfessionalStatement from '../../../EmployerConnector/modals/showModal/ProfessionalStatement';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  employerDashboard: { jobSeekingProfile },
}) => ({
  isEditView: false,
  jobSeekingProfile,
  studentViewing: false,
});

export default connect(mapStateToProps, null)(ProfessionalStatement);
