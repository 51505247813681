import ApiService from '../../../../services/ApiService';
import { getAuthToken } from '../../../../utils/getAuthToken';

export const getSurveyFormId = async (attendeeId) => {
  const apiService = new ApiService('/api/v1/feedbacks/csc_survey');
  const params = {
    attendee_id: attendeeId,
  };

  try {
    const result = await apiService.makeGetCall(params);
    return result.data.surveyId;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const postSurveyData = async (data) => {
  const params = {
    authenticity_token: getAuthToken(),
    form_id: data.formId,
    owner_id: data.attendeeId,
    owner_type: 'Attendee',
    response_id: data.responseId,
  };

  const apiService = new ApiService('/api/v1/feedbacks.json');

  return apiService.makePostCall(params).catch(error => {
    throw error;
  });
};

export const getSurveyData = async (data) => {
  const apiService = new ApiService(`/api/v1/surveys/${data.formId}/responses/${data.responseId}`);

  try {
    const result = await apiService.makeGetCall();
    return result.data.data;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};
