import styled from 'styled-components';

const NotesForm = styled.form`
  height: 120px;
  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: solid 2px #a7b1b9;
    border-radius: 4px;
    resize: none;
  }
`;

NotesForm.displayName = 'NotesForm';

export default NotesForm;
