import axios from 'axios'
import { keysToCamel } from '../utils/keysConverter'

const COURSE_EXTENSION_DETAILS = `${window.location.href}/extension_details`;

class CourseExtensionsService {

  getExtensionDetails() {
    return axios.get(COURSE_EXTENSION_DETAILS)
      .then(response => {
        return({ data: keysToCamel(response.data), error: null })
      })
      .catch(error => {
        return({ data: null, error: error.response.data })
      })
  }
}

export default CourseExtensionsService;
