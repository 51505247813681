import styled from 'styled-components';

const borderSpacing = '1.5rem';

const Wrapper = styled.div`
  border-bottom: 1px solid var(--ds-color__grey--400);
  margin-bottom: ${borderSpacing};

  p {
    color: var(--ds-color__indigo);
  }

  p:nth-of-type(1) {
    margin-bottom: 0.25rem;
  }

  > p:nth-of-type(2) {
    margin-bottom: 0.5rem;
    font-size: 14px; // TODO: Use CSS class from design system when available
  }

  > div:nth-of-type(1) {
    margin-bottom: 0.5rem;
  }

  > div:nth-of-type(2) {
    > p:nth-of-type(2) {
      margin-bottom: ${borderSpacing};
    }
  }

  mark {
    cursor: pointer;
    color: var(--ds-color__indigo);
    background-color: rgba(255, 188, 66, 0.15);
  }
`;

export { Wrapper };
