import HeaderStyles from './styles/Header';
import ImageWrapper from './styles/ImageWrapper';
import InfoWrapper from './styles/InfoWrapper';
import Label from './styles/Label';
import PropTypes from 'prop-types';
import React from 'react';
import { iconFromName } from './helpers';

const Header = ({ user = {} }) => (
  <HeaderStyles>
    <div className='ds-grid__row'>
      <div className='ds-grid__col-xs-12'>
        <ImageWrapper>
          <img src={user.image} alt='user headshot' />
          <div>
            <h3 className='ds-typography__h3'>{user.nameWithPronouns}</h3>
            {user.afa && <Label>AfA Student</Label>}
            {user.userServices.map((service, i) => (
              <a href={service.handle} key={`s.${i}`}>
                <i className={`fa ${iconFromName(service.name)}`}></i>
              </a>
            ))}
          </div>
        </ImageWrapper>
      </div>
    </div>
    <div className='ds-grid__row'>
      <div className='ds-grid__col-xs-12'>
        <InfoWrapper>
          <p className='ds-typography__body--bold'>Last Seen</p>
          <p className='ds-typography__body'>{user.lastSeen}</p>
        </InfoWrapper>
        <InfoWrapper>
          <p className='ds-typography__body--bold'>Local Time</p>
          <p className='ds-typography__body'>{user.timezone}</p>
        </InfoWrapper>
        <InfoWrapper>
          <p className='ds-typography__body--bold'>Location</p>
          <p className='ds-typography__body'>{user.location}</p>
        </InfoWrapper>
      </div>
    </div>
  </HeaderStyles>
);

Header.propTypes = {
  user: PropTypes.shape({
    afa: PropTypes.bool,
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    lastSeen: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    name: PropTypes.string,
    nameWithPronouns: PropTypes.string.isRequired,
    skype: PropTypes.string.isRequired,
    survey: PropTypes.object.isRequired,
    timezone: PropTypes.string.isRequired,
    userServices: PropTypes.array.isRequired,
  }).isRequired,
};

export default Header;
