import React, { useEffect, useRef } from 'react';
import { Accordion } from 'react-accessible-accordion';
import AccordionSection from '../../../shared/components/AccordionSection';
import FeedbackButton from '../../../shared/components/FeedbackButton';
import FinalStage from './Content/FinalStage';
import Ideation from './Content/Ideation';
import Introduction from './Content/Introduction';
import PlanYourProject from './Content/PlanYourProject';
import PropTypes from 'prop-types';
import RouterLinkWithIcon from '../../../shared/components/RouterLinkWithIcon';
import StlyedCreateNewProject from './styles/StlyedCreateNewProject';
import StyledAccordion from './styles/StyledAccordion';
import UIDesign from './Content/UIDesign';
import UXDesign from './Content/UXDesign';
import UXResearch from './Content/UXResearch';
import { userEventTracking } from '../../../shared/helpers/tracking';

const ACCORDION_EVENT = 'accordion_heading_clicked';

const CreateNewProject = ({ isMobile }) => {
  const contentContainer = useRef();

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '10px' });

    const links = contentContainer.current.querySelectorAll('a');
    if (links.length) {
      links.forEach((link) => {
        link.addEventListener('click', () => {
          userEventTracking(link.href, 'new_project_link_clicked');
        });
      });
    }
    userEventTracking(window.location.href, 'New Project');
  }, []);

  return (
    <StlyedCreateNewProject>
      <div className="ds-grid__container">
        <div className="ds-grid__row">
          <div className="ds-grid__col-sm-12 ds-grid__col-md-offset-2 ds-grid__col-md-8">
            <RouterLinkWithIcon
              to="/en/career-support-center"
              text="Back to Dashboard"
              icon="arrow-left"
            />
            <h3 className="ds-typography__h3 text-left">
              Create a new project for your portfolio
            </h3>
            <StyledAccordion ref={contentContainer}>
              <Accordion
                allowZeroExpanded
                allowMultipleExpanded
                preExpanded={[`${isMobile ? '' : 'Introduction'}`]}
              >
                <AccordionSection
                  heading="Introduction"
                  content={<Introduction />}
                  eventType={ACCORDION_EVENT}
                />
                <AccordionSection
                  heading="Getting started: Ideation"
                  content={<Ideation />}
                  eventType={ACCORDION_EVENT}
                />
                <AccordionSection
                  heading="Plan your project"
                  content={<PlanYourProject />}
                  eventType={ACCORDION_EVENT}
                />
                <AccordionSection
                  heading="Design process. UX research: Understand the user, empathize, and define a focus"
                  content={<UXResearch />}
                  eventType={ACCORDION_EVENT}
                />
                <AccordionSection
                  heading="Design process. UX design: Ideate, prototype, and test"
                  content={<UXDesign />}
                  eventType={ACCORDION_EVENT}
                />
                <AccordionSection
                  heading="Design process. UI design: Implementation"
                  content={<UIDesign />}
                  eventType={ACCORDION_EVENT}
                />
                <AccordionSection
                  heading="Final stage"
                  content={<FinalStage />}
                  eventType={ACCORDION_EVENT}
                />
              </Accordion>
            </StyledAccordion>
            <FeedbackButton formId="qvmXcoVr" />
          </div>
        </div>
      </div>
    </StlyedCreateNewProject>
  );
};

CreateNewProject.propTypes = {
  isMobile: PropTypes.bool,
};

export default CreateNewProject;
