import InterviewInstructions from '../../components/InterviewInstructions/index';
import { connect } from 'react-redux';
import { setInterviewStage } from '../../actions';

export const mapDispatchToProps = (dispatch) => ({
  setInterviewStage: (stage) => dispatch(setInterviewStage(stage)),
});

export const mapStateToProps = ({ interviewPrep: { shareWithMentor } }) => ({
  shareWithMentor,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewInstructions);
