import App from './containers/App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../../shared/reduxStore';

const instructorsCandidatesPageContainer = document.getElementById(
  'InstructorsCandidatesPage'
);

if (instructorsCandidatesPageContainer) {
  const instructorsCandidatesPageContainerRoot = createRoot(
    instructorsCandidatesPageContainer
  );

  instructorsCandidatesPageContainerRoot.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}
