import React from 'react';

const LoadingSpinner = () => (
  <div className="loading_spinner_wrapper">
    <div className="cf-spinner">
      <div className="cf-spinner__circle--1"></div>
      <div className="cf-spinner__circle--2"></div>
      <div className="cf-spinner__circle--3"></div>
    </div>
  </div>
);

export default LoadingSpinner;
