import EmployerConnectorModal from '../../modals';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  careerSupportCenter: { ecModalType },
}) => ({
  ecModalType,
});

export default connect(mapStateToProps, null)(EmployerConnectorModal);
