import App from './containers/App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';

import store from '../shared/reduxStore';

const careerSupportCenterContainer = document.getElementById(
  'careerSupportCenter'
);

if (careerSupportCenterContainer) {
  const careerSupportCenterContainerRoot = createRoot(
    careerSupportCenterContainer
  );

  careerSupportCenterContainerRoot.render(
    <Provider store={store}>
      <App attendeeId={+careerSupportCenterContainer.dataset.attendeeId} />
    </Provider>
  );
}
