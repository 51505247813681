import styled from 'styled-components';

const StyledNumberCheckInput = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 2px;
  margin: 0 2px 0 0;
  background-color: white;
  display: flex;
  align-items: center;
  border: 1px solid #7a9cd5;
  background-color: #e5ecf7;
  justify-content: center;

  p {
    margin: 4px 0 0 !important;
    font-size: 14px;
    line-height: 1 !important;
    color: #4575c5 !important;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: #003280;
    p {
      color: var(--ds-color__white) !important;
    }
    `}

  ${({ isEdit }) =>
    isEdit &&
    `
    cursor: pointer;
    `}
`;

StyledNumberCheckInput.displayName = 'StyledNumberCheckInput';

export default StyledNumberCheckInput;
