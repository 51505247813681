import styled from 'styled-components';

const StyledErrorImage = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 300px;
  width: 100%;
  background-image: url('http://images.careerfoundry.com/public/images/practiceInterview.png');
`;
StyledErrorImage.displayName = 'StyledErrorImage';
export default StyledErrorImage;
