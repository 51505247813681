import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

type Props = {
  transactionId: number;
  startDate: string;
  productSku: string;
  userLocation: string;
};

const App: React.FC<Props> = ({
  transactionId,
  startDate,
  productSku,
  userLocation,
}) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          transactionId={transactionId}
          startDate={startDate}
          productSku={productSku}
          userLocation={userLocation}
        />
      )}
    </ElementsConsumer>
  );
};

export default App;
