import styled from 'styled-components';

const StyledHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding-left: 45px;

  h4 {
    margin-bottom: 36px !important;
    font-weight: 500 !important;
    font-size: 32px !important;
  }

  ul {
    padding: 0 0 0 20px;
    margin-bottom: 45px;
    max-width: 313px;

    li {
      font-size: 18px;
    }
  }

  @media (min-width: 1480px) {
    flex-basis: 40%;
  }

  @media (max-width: 767px) {
    padding-left: 0;
  }
`;

StyledHeaderContent.displayName = 'StyledHeaderContent';
export default StyledHeaderContent;
