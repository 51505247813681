import React, { useEffect } from 'react';
import {
  createBillingProfile,
  getPersonalData,
  handleSubmit,
} from '../../actions';
import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PersonalInformation from '../../components/PersonalInformation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const PersonalInformationContainer = ({
  submitBillingProfile,
  formErrors,
  getPersonalData,
  loadingButton,
  loadingForm,
  handleSubmit,
}) => {
  useEffect(() => {
    getPersonalData();
  }, []);

  return loadingForm ? (
    <LoadingSpinner />
  ) : (
    <PersonalInformation
      submitBillingProfile={submitBillingProfile}
      formErrors={formErrors}
      handleSubmit={handleSubmit}
      loadingButton={loadingButton}
    />
  );
};

PersonalInformationContainer.propTypes = {
  formErrors: PropTypes.object,
  getPersonalData: PropTypes.func,
  handleSubmit: PropTypes.func,
  loadingButton: PropTypes.bool,
  loadingForm: PropTypes.bool,
  submitBillingProfile: PropTypes.func,
};

export const mapStateToProps = ({
  instructorProfile: { formErrors, loadingButton, loadingForm },
}) => ({
  formErrors,
  loadingButton,
  loadingForm,
});

export const mapDispatchToProps = (dispatch) => ({
  getPersonalData: () => dispatch(getPersonalData()),
  handleSubmit: () => dispatch(handleSubmit()),
  submitBillingProfile: () => dispatch(createBillingProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInformationContainer);
