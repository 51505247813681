import AdminProfileDetailsViewContainer from '../../../admin/EmployerConnector/Students/containers/ProfileDetails';
import ProfileDetailsViewContainer from '../../container/ProfileDetailsViewContainer';
import PropTypes from 'prop-types';
import React from 'react';
import StyledRowWrapper from './styles/StyledRowWrapper';

const PersonalData = ({ studentViewing }) => (
  <StyledRowWrapper>
    {studentViewing
      ? <ProfileDetailsViewContainer studentViewing={studentViewing} />
      : <AdminProfileDetailsViewContainer studentViewing={studentViewing} />
    }
  </StyledRowWrapper>
);

PersonalData.propTypes = {
  studentViewing: PropTypes.bool.isRequired,
};

export default PersonalData;
