import Button from '../../../EmployerConnector/modals/shared/Button';
import MobileHeaderInfographic from './MobileHeaderInfographic';
import PropTypes from 'prop-types';
import React from 'react';
import StyledButtonWrapper from '../../../JobApplicationTracker/components/styles/StyledButtonWrapper';
import StyledHeaderContent from './styles/StyledHeaderContent';
import StyledHeaderImage from './styles/StyledHeaderImage';
import Wrapper from './styles/Wrapper';

const CreateNewProjectCard = ({ isMobile }) => {
  const openNewProject = () => {
    window.location.href = '/en/new-project';
  };

  return isMobile ? (
    <MobileHeaderInfographic onClick={openNewProject} />
  ) : (
    <Wrapper>
      <StyledHeaderImage />
      <StyledHeaderContent>
        <h4 className="ds-typography__h4">Create a new portfolio project</h4>
        <ul>
          <li className="ds-typography__body">
            Add to your portfolio to increase your chances of finding a new job
          </li>
          <li className="ds-typography__body">
            Follow the suggested step-by-step plan
          </li>
        </ul>
        <StyledButtonWrapper>
          <Button
            className="ds-button__primary"
            label="Get started"
            loading={false}
            onClick={openNewProject}
          />
        </StyledButtonWrapper>
      </StyledHeaderContent>
    </Wrapper>
  );
};

CreateNewProjectCard.propTypes = {
  isMobile: PropTypes.bool,
};

export default CreateNewProjectCard;
