import PropTypes from 'prop-types';
import React from 'react';
import StyledCourseLabel from './styles';
import { startCase } from 'lodash';

const CourseLabel = ({ course }) => {
  const getColor = () => {
    switch (course) {
    case 'ux_design': return 'var(--ds-color__turqi--700)';
    case 'ui_design': return 'var(--ds-color__lavender)';
    case 'web_development': return 'var(--ds-color__teal)';
    case 'data_analytics': return 'var(--ds-color__purple)';
    case 'general': return 'var(--ds-color__indigo)';
    case 'all': return 'var(--ds-color__indigo)';
    }
  };

  const getDisplayName = () => {
    switch (course) {
    case 'ux_design': return 'UX Design';
    case 'ui_design': return 'UI Design';
    default: return startCase(course);
    }
  };

  return (
    <StyledCourseLabel color={getColor()}>
      <p className="ds-typography__small--bold">{getDisplayName(course)}</p>
    </StyledCourseLabel>
  );
};

CourseLabel.propTypes = {
  course: PropTypes.string.isRequired,
};

export default CourseLabel;
