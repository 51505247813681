import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const mentorCallNotesHistoryContainer =
  document.getElementById('callNotesHistory');

const CallNotesHistory = lazy(() => import('./components/CallNotesHistory'));

if (mentorCallNotesHistoryContainer) {
  const mentorCallNotesHistoryContainerRoot = createRoot(
    mentorCallNotesHistoryContainer
  );

  mentorCallNotesHistoryContainerRoot.render(
    <Suspense fallback={<div></div>}>
      <Provider store={store}>
        <CallNotesHistory />
      </Provider>
      ,
    </Suspense>
  );
}
