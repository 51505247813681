export const heading = (action) => {
  return action === 'expired' ? 'Link Expired' : 'Already Reviewed';
};

export const text = (action) => {
  const expiredText =
    'Your call review link was active for 7 days. Unfortunately, this one has expired.';
  const reviewedText = 'You have already reviewed this call';
  return action === 'expired' ? expiredText : reviewedText;
};
