module.exports = (() => {
  const init = function () {
    bindFunctions();
  };

  const bindFunctions = function () {
    $('[data-payment-id]').click(_postLoadPaymentInfo);
    $('.subscription_form--js').on('change', _postSubscriptionStatusChange);
  };

  const bindInTemplate = function () {
    $('.stripe_charge_id--js').off();
    $('.stripe_charge_id--js').on('change', _prefillPaymentForm);
    $('.reload_table--js').on('click', _reloadTable);
  };

  const _insertAndBindTemplates = function (response) {
    const $paymentsView = $('.payments-view');
    const $paymentPlaceholder = $('.payment_action_placeholders');
    $paymentPlaceholder.html('').html(response);
    $paymentsView.hide();

    bindInTemplate();
  };

  const _reloadTable = function () {
    const $paymentsView = $('.payments-view');
    const $paymentPlaceholder = $('.payment_action_placeholders');

    $paymentsView.show();
    $paymentPlaceholder.html('');
  };

  const _postLoadPaymentInfo = function () {
    const paymentId = $(this).data('paymentId');
    const userId = $(this).data('userId');
    const postURL =
      '/en/admin/user_payments/' + userId + '/payments/' + paymentId;
    $.post(
      postURL,
      {
        payment: $(this).data(),
      },
      function (response) {
        _insertAndBindTemplates(response);
        $('.payment_action_placeholders')
          .find('.will-load')
          .removeClass('loading--bars');
      }
    ).fail(function (error) {
      const data = error.responseJSON;
      alert(data.message);
    });
  };

  const _postSubscriptionStatusChange = function () {
    $('.payment_action_placeholders')
      .find('.will-load')
      .addClass('loading--bars');
    $.post(
      '/en/admin/user_payments/edit_subscription',
      {
        id: $(this).data('id'),
        status: $(this).val(),
      },
      function (response) {
        alert(response.message);
        $('.subscription_form--js')[0].value = response.status;
      }
    ).fail(function (error) {
      const data = error.responseJSON;
      alert(data.message);
      $('.subscription_form--js')[0].value = data.status;
    });
  };

  const _prefillPaymentForm = function () {
    if ($(this).val().length < 1) return;
    $('.loading-stripe-charge--js').removeClass('hidden');
    const $parent = $(this).closest('form');

    $.get(
      '/en/admin/user_payments/stripe_charge',
      {
        stripe_charge_id: $(this).val(),
      },
      function (response) {
        $('.loading-stripe-charge--js').addClass('hidden');
        if (response.error == null) {
          $parent.find('[name="payment_date"]').val(response.payment_date);
          $parent.find('[name="state"]').val(response.state);
        } else {
          alert(response.error);
        }
      }
    );
  };

  return {
    init,
  };
})();
