import { ModalActions, ModalContent, ModalWrapper } from './styles';
import PropTypes from 'prop-types';
import React from 'react';
import TheModal from '../../../shared/components/TheModal';
import { formatOptionForSelect } from '../../../../utils/formatOptionForSelect';

const ConfrimContent = ({ handleModalClose, handleSubmit, selectedCall }) => (
  <>
    <i className="ds-icon__info-fill"></i>
    <h3 className="ds-typography__h3">Unsaved update</h3>
    <p className="ds-typography__lead">
      Do you want to update the call status to <br />"
      {formatOptionForSelect(selectedCall.status)}"
    </p>
    <ModalActions>
      <button className="ds-button__secondary" onClick={handleModalClose}>
        Discard
      </button>
      <button
        className="ds-button__primary"
        onClick={() => handleSubmit(selectedCall.id, selectedCall.status)}
      >
        Update
      </button>
    </ModalActions>
  </>
);

ConfrimContent.propTypes = {
  handleModalClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  selectedCall: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
  }),
};

const SuccessContent = ({ handleModalClose }) => (
  <>
    <i className="ds-icon__thumb-up-2"></i>
    <h3 className="ds-typography__h3">Update saved</h3>
    <p className="ds-typography__lead">
      Thank you for editing your call status. It was successfully updated.
    </p>
    <ModalActions>
      <button className="ds-button__primary" onClick={handleModalClose}>
        Got it
      </button>
    </ModalActions>
  </>
);

SuccessContent.propTypes = {
  handleModalClose: PropTypes.func,
};

const ErrorContent = ({ handleModalClose }) => (
  <>
    <i className="ds-icon__info-fill"></i>
    <h3 className="ds-typography__h3">Error</h3>
    <p className="ds-typography__lead">
      There was an error updating the call, please try again later.
    </p>
    <ModalActions>
      <button className="ds-button__primary" onClick={handleModalClose}>
        Got it
      </button>
    </ModalActions>
  </>
);

ErrorContent.propTypes = {
  handleModalClose: PropTypes.func,
};

const ConfirmationModal = ({
  handleModalClose,
  handleSubmit,
  isOpen,
  selectedCall,
  modalAction,
}) => (
  <ModalWrapper>
    <TheModal
      isModalOpen={isOpen}
      handleClose={handleModalClose}
      closeButton={false}
    >
      <ModalContent>
        {
          {
            confirm: (
              <ConfrimContent
                handleModalClose={handleModalClose}
                handleSubmit={handleSubmit}
                selectedCall={selectedCall}
              />
            ),
            error: <ErrorContent handleModalClose={handleModalClose} />,
            success: <SuccessContent handleModalClose={handleModalClose} />,
          }[modalAction]
        }
      </ModalContent>
    </TheModal>
  </ModalWrapper>
);

ConfirmationModal.propTypes = {
  handleModalClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  modalAction: PropTypes.string,
  selectedCall: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
  }),
};

export default ConfirmationModal;
