export const gotoPage = (hash) => {
  if (hash) {
    window.location.hash = hash;
  }
};
export const goBack = () => {
  window.history.back();
};

export const modifySurveyQuestions = (questions, answers) => {
  return questions.map((question) => {
    question.answers.map((answer) => {
      const userAnswer = answers.find(
        (answer) => question.id === answer.surveyQuestionId
      );
      if (userAnswer && answer.answer === userAnswer.answer) {
        answer.selected = true;
        question.answered = true;
      }
      return answer;
    });
    return question;
  });
};

export const answerToQuestion = (questions, questionIndex, answer) => {
  const newQuestions = JSON.parse(JSON.stringify(questions));
  const question = newQuestions[questionIndex];
  question.answered = true;
  question.answers.map((qa) => {
    qa.selected = false;
    if (qa.answer === answer.answer) {
      qa.selected = true;
    }
    return qa;
  });
  return newQuestions;
};

export const getStringFromUrlHash = (page) => {
  return page.split('#')[1];
};

export const navigateUserToAppropriatePage = (navs, onboardingStatus) => {
  const currentNavIndex = navs.findIndex(
    (nav) => nav === `#${onboardingStatus}`
  );
  if (currentNavIndex > 0) {
    navs.forEach((nav, index) => {
      if (index < currentNavIndex) {
        history.pushState(null, '', nav);
      }
    });
  }
  const pageToNavigate =
    '#' + (onboardingStatus === 'signedup' ? 'welcome' : onboardingStatus);
  gotoPage(pageToNavigate);
};

export const answerOnQuestion = (
  questions,
  activeIndex,
  userAnswerIndex,
  onAnswerQuestion
) => {
  const answer = {
    answer: questions[activeIndex].answers[userAnswerIndex].answer,
    survey_question_id: questions[activeIndex].id,
  };
  onAnswerQuestion(answer, activeIndex);
};

export const resetActiveIndex = (questions, setActiveIndex) => {
  const currentActiveIndex = questions.findIndex(
    (question) => !question.answered
  );
  currentActiveIndex > -1
    ? setActiveIndex(currentActiveIndex)
    : setActiveIndex(questions.length - 1);
};

export const onClickBack = (activeIndex, navigateTo, setActiveIndex) => {
  activeIndex === 0 ? navigateTo('back') : setActiveIndex(activeIndex - 1);
};

export const onClickNext = (
  activeIndex,
  questions,
  navigateTo,
  setActiveIndex,
  cb
) => {
  const incrementedIndex = activeIndex + 1;
  incrementedIndex === questions.length
    ? navigateTo('#curriculum-structure', 'next')
    : setActiveIndex(incrementedIndex);
  cb && cb();
};

export const generateTimelineItems = (timeline) => {
  let programStartDate = 0;
  let programEndDate = 0;

  const modifiedTimelineItems = timeline.map((item) => {
    const endTimeStamp = new Date(item.endDate).getTime();
    const startTimeStamp = new Date(item.startDate).getTime();
    if (programEndDate === 0 || programEndDate < endTimeStamp) {
      programEndDate = endTimeStamp;
    }
    if (programStartDate === 0 || programStartDate > startTimeStamp) {
      programStartDate = startTimeStamp;
    }
    return {
      ...item,
      endTimeStamp,
      startTimeStamp,
    };
  });

  const ratio = programEndDate - programStartDate;
  return modifiedTimelineItems
    .map((item) => {
      return {
        ...item,
        offset: Math.round(
          ((item.endTimeStamp - programStartDate) * 100) / ratio
        ),
      };
    })
    .sort((a, b) => a.endTimeStamp - b.endTimeStamp);
};
