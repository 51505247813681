import styled from 'styled-components';

const StyledStudentHandoff = styled.div`
  background: white;
  overflow:hidden;
  word-wrap: break-word;
  padding: 2em 1.4em 2em 1.4em;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  margin-bottom: 1em;
  min-height: 240px;

  .header {
    justify-content: space-between;
  }

  @media only screen and (max-width: 767px) {
    .card {
      padding: 1.2em 1.2em 1.5em 1.2em;
    }
  }
  a {
    text-decoration: underline;
  }
`;

StyledStudentHandoff.displayName = 'StyledStudentHandoff';

export default StyledStudentHandoff;
