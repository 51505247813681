import styled from 'styled-components';

const StyledShowAgain = styled.div`
  text-align: center;
  margin-top: 150px;
  .checkbox-wrapper {
    color: var(--ds-color__indigo);

    input {
      margin-top: 0;
    }

    label {
      margin-left: 6px;
      max-width: 400px;
      line-height: 1.67;
      color: rgba(34, 60, 80, 0.7);
      font-size: 12px;
    }

    span {
      display: block;
      margin-top: 0;
      font-size: 8px;
      line-height: 1.5;
    }
  }

  input[type='checkbox'] {
    border: 2px solid #a7b1b9;
  }

  @media (max-width: 768px) {
    .checkbox-wrapper {
      label {
        width: 90%;
      }
    }
  }
`;

StyledShowAgain.displayName = 'StyledShowAgain';

export default StyledShowAgain;
