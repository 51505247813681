import { Label, Section, SectionHeading } from '../styles';
import PropTypes from 'prop-types';
import React from 'react';

const NameFields = ({ firstName, middleName, lastName, businessName }) => (
  <Section>
    <SectionHeading className="ds-typography__h5">Legal Name</SectionHeading>
    <div className="ds-grid__row">
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">First name</Label>
        <p className="ds-typography__body">{firstName}</p>
      </div>
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">Middle name</Label>
        <p className="ds-typography__body">{middleName}</p>
      </div>
    </div>
    <div className="ds-grid__row">
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">Last name</Label>
        <p className="ds-typography__body">{lastName}</p>
      </div>
      <div className="ds-grid__col-xs-6">
        <Label className="ds-typography__body">
          Business name (if applicable)
        </Label>
        <p className="ds-typography__body">{businessName}</p>
      </div>
    </div>
  </Section>
);

NameFields.propTypes = {
  businessName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
};

export default NameFields;
