import IndustryDropdown from '../../containers/IndustryDropdown';
import React from 'react';
import StyledHeader from './styles/StyledHeader';

const CSCHeader = () => (
  <StyledHeader>
    <h2 className="ds-typography__h2 text-left tour__heading">
      Career Support Center
    </h2>
    <IndustryDropdown />
  </StyledHeader>
);

export default CSCHeader;
