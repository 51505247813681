import styled from 'styled-components';

export const AnswerList = styled.div`
  width: 100%;
  margin-top: -60px;
  background-color: white;
  padding: 20px 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    padding: 0;
    padding-top: 30px;
    box-shadow: none !important;
  }
`;

export const AnswerItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  height: auto;
  min-height: 40px;
  padding: 7px 16px;
  border: ${(props) =>
    props.isActive
      ? '1px solid var(--ds-color__aqua)'
      : '1px solid rgba(34, 60, 80, 0.4)'};
  box-sizing: border-box;
  border-radius: 4px;
  margin: 4px 0px;
`;

export const AnswerItemLetter = styled.div`
  background: white;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  border: ${(props) =>
    props.isActive
      ? '1px solid var(--ds-color__aqua)'
      : '1px solid rgba(34, 60, 80, 0.4)'};
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #a7b1b9;
`;

export const AnswerItemText = styled.div`
  margin-left: 16px;
  line-height: 24px;
`;
