import styled from 'styled-components';

const SurveyStyleWrapper = styled.div`
  display: inline;

  & > div > div {
    height: 80vh;
  }
`;

SurveyStyleWrapper.displayName = 'SurveyStyleWrapper';

export default SurveyStyleWrapper;
