import styled from 'styled-components';

const StyledBackgroundImage = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 330px;
  width: 100%;
  background-image: url('https://coach-courses-us.s3.amazonaws.com/public/images/tip_of_the_day.png');

  @media (max-width: 768px) {
    height: 200px;
  }
`;
StyledBackgroundImage.displayName = 'StyledBackgroundImage';
export default StyledBackgroundImage;
