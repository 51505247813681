import React from 'react';

const WhereToFindRemoteWork = () => (
  <>
    <p className='ds-typography__body break'>
      While we have a section of{' '}
      <a
        onClick={() =>
          window.open('https://careerfoundry.com/en/recommended-jobs')
        }
      >
        recommended job boards
      </a>{' '}
      organized by freelancing and remote work, you can use keywords to search
      mainstream job boards as well. These keywords can include:
    </p>
    <ul>
      <li>Remote Work</li>
      <li>Virtual Work</li>
      <li>Work From Home (WFH)</li>
      <li>Telecommuting</li>
      <li>Telecommute</li>
      <li>Telework</li>
      <li>Flexible Work</li>
    </ul>
    <h5 className='ds-typography__h5'>How to target remote work</h5>
    <p className='ds-typography__body break'>
      With the shift to more distributed teams, the expectations and demands of
      the roles have also changed. In addition to emphasizing that you have
      worked entirely remotely while completing your CareerFoundry program (if
      you have), you can focus your application materials and interview answers
      on outcomes, collaboration, and communication.
    </p>
    <h5 className='ds-typography__h5'>
      How to identify a job scam when looking for remote work
    </h5>
    <p className='ds-typography__body break'>
      As great as remote work sounds, unfortunately, some students have reported
      job scams where con artists pose as recruiters in order to get their
      personal information. Here are some signs to be aware of:
    </p>
    <div className='ds-typography__lead small-margin'>Warning signs</div>
    <ul>
      <li>
        The role offers a flexible schedule, high salary, and NO face-to-face
        interview.
      </li>
      <li>You are asked to pay for an introduction or even the job offer.</li>
      <li>
        Before meeting with the company you are asked for sensitive personal
        information.
      </li>
    </ul>
    <p className='ds-typography__body break'>
      For more information on this, check out the{' '}
      <a
        onClick={() =>
          window.open(
            'https://www.topresume.com/career-advice/job-scam-warning-signs'
          )
        }
      >
        11 Job Scam Warning Signs to Look Out For
      </a>{' '}
      and{' '}
      <a
        onClick={() =>
          window.open(
            'https://alexwright.net/blog/web-design/avoid-web-design-scams/'
          )
        }
      >
        Avoid Web Design Scams.
      </a>
    </p>
    <div className='ds-typography__lead'>What to do</div>
    <p className='ds-typography__body break'>
      To protect yourself we recommend the following:
    </p>
    <ul>
      <li>
        Make sure the address on the job description matches the company's
        LinkedIn page and company website.
      </li>
      <li>Check if the job is posted on the company's job board page.</li>
      <li>Verify the listing by calling or emailing the company directly.</li>
      <li>You are also encouraged to report it to the proper authorities.</li>
    </ul>
    <div className='ds-typography__lead'>Where to report a scam</div>
    <ul>
      <li>
        UNITED STATES: Contact the{' '}
        <a onClick={() => window.open('https://reportfraud.ftc.gov/#/?pid=B')}>
          FEDERAL TRADE COMMISSION (FTC)
        </a>{' '}
        or call 1-877-FTC-HELP (1-877-382-4357). Search the{' '}
        <a onClick={() => window.open('https://www.naag.org/')}>
          National Association of Attorneys General
        </a>{' '}
        for the Attorney General’s office in your state or the state where the
        company is located. The office can tell you if you’re protected by a
        state law that regulates work-at-home programs. Report the company to{' '}
        <a onClick={() => window.open('https://www.bbb.org/')}>
          The Better Business Bureau
        </a>
        . You can also search for scam reports using their{' '}
        <a onClick={() => window.open('http://www.bbb.org/scamtracker/us')}>
          Scam Tracker
        </a>
        . Additional resources can be found at{' '}
        <a onClick={() => window.open('https://www.usa.gov/stop-scams-frauds')}>
          Report Scams and Frauds
        </a>
      </li>
      <li>
        CANADA: Contact{' '}
        <a
          onClick={() =>
            window.open('https://antifraudcentre-centreantifraude.ca/')
          }
        >
          Canadian Anti-Fraud Centre
        </a>{' '}
        or call 1-888-495-8501.
      </li>
      <li>
        UNITED KINGDOM: Contact{' '}
        <a onClick={() => window.open('https://www.actionfraud.police.uk/')}>
          Action Fraud
        </a>{' '}
        or call 0300-123-2040. Be sure to also visit{' '}
        <a
          onClick={() =>
            window.open(
              'https://www.citizensadvice.org.uk/consumer/scams/reporting-a-scam/'
            )
          }
        >
          citizensadvice.org
        </a>{' '}
        for additional contacts
      </li>
      <li>
        EUROPE: Submit an{' '}
        <a
          onClick={() =>
            window.open(
              'https://anti-fraud.ec.europa.eu/contacts/report-fraud_en'
            )
          }
        >
          online form
        </a>{' '}
        to the European Anti-Fraud Office (OLAF). Additional ways to file can
        also be found on their{' '}
        <a
          onClick={() =>
            window.open(
              'https://anti-fraud.ec.europa.eu/olaf-and-you/report-fraud_en'
            )
          }
        >
          website
        </a>
      </li>
      <li>
        AUSTRALIA: Submit an{' '}
        <a
          onClick={() =>
            window.open('https://www.scamwatch.gov.au/report-a-scam')
          }
        >
          online form
        </a>{' '}
        with Australian Competition & Consumer Commission (ACCC). You can also
        check out their{' '}
        <a
          onClick={() =>
            window.open(
              'https://www.scamwatch.gov.au/types-of-scams/jobs-employment/jobs-employment-scams'
            )
          }
        >
          website
        </a>{' '}
        and{' '}
        <a
          onClick={() =>
            window.open('https://www.seek.com.au/safe-job-searching/')
          }
        >
          SEEK
        </a>{' '}
        for additional information on scams and how to protect yourself
      </li>
    </ul>
    <p className='ds-typography__body break'>
      Because we take these matters seriously, if you are suspicious something
      is fishy, please reach out to{' '}
      <a href='mailto:careerservices@careerfoundry.com'>
        careerservices@careerfoundry.com
      </a>
      . We are here to support you.
    </p>
  </>
);

export default WhereToFindRemoteWork;
