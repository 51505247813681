import styled from 'styled-components';

const StyledAccordion = styled.div`
  .accordion__button {
    margin-top: 32px;
    margin-bottom: 10px;
    color: var(--ds-color__indigo) !important;
  }
  
  a {
    font-weight: 400 !important;
  }

  .accordion__item {
    margin-top: 30px;
    border-bottom: 1px solid var(--ds-color__grey--light);

    h5 {
      margin-bottom: 16px;
      margin-top: 32px;
    }

    .ds-typography__lead {
      margin-top: 24px !important;
    }
  }

  .accordion__button:before {
    display: inline-block;
    float: right;
    margin-top: 8px;
    content: '';
    height: 12px;
    width: 12px;
    border-top: 4px solid var(--ds-color__petrol);
    border-left: 4px solid var(--ds-color__petrol);
    transform: rotate(225deg);
    transition: all .2s;
  }

  .accordion__panel:last-child {
    margin-bottom: 48px;
  }

  .accordion__button:hover::before {
    opacity: 0.75;
  }


  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }

  .accordion__panel {
    ul {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 479px) {
    margin: 0 -15px;

    .accordion__button {
      margin-bottom 8px;
    }

    .accordion__panel {
      ul {
        padding-left: 30px;
      }
    }
  }
`;

StyledAccordion.displayName = 'StyledAccordion';

export default StyledAccordion;
