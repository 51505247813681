import PropTypes from 'prop-types';
import React from 'react';
import StyledStudentHandoffCard from './styles/StyledStudentHandoffCard';

const StudentHandoffCard = ({ handoff, handleClick }) => {
  return (
    <StyledStudentHandoffCard className="container">
      <div className="flex">
        <div>
          {handoff.student.photo
            ? <img src={handoff.student.photo.url} alt="profile-picture" className="profile-image img-circle" />
            : <img src='https://images.careerfoundry.com/public/framework/head.svg' alt="profile-picture" className="profile-image img-circle" />
          }
        </div>
        <div>
          <a href={`/en/users/${handoff.student.slug}/profile`}>{handoff.student.name}</a>
          <p className='ds-typography__small'>Request has been added on {new Date(handoff.createdAt).toDateString()}</p>
        </div>
      </div>
      <div>
        <button onClick={() => handleClick(handoff)} className="btn btn-send ds-button__small">Fill-in-handoff info</button>
      </div>
    </StyledStudentHandoffCard>
  );
};

StudentHandoffCard.propTypes = {
  handleClick: PropTypes.func,
  handoff: PropTypes.shape({
    createdAt: PropTypes.string,
    student: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      photo: PropTypes.object,
      slug: PropTypes.string,
    }),
  }),
};

export default StudentHandoffCard;
