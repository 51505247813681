import { camelCase, omit } from 'lodash';
import { findRegion } from './components/PersonalInformation/TaxInputs/helpers';
import { keysToCamel } from '../../../utils/keysConverter';
import { redirectTo } from '../../../utils/redirectTo';

export const defaultFormState = {
  addressLine1: '',
  addressLine2: '',
  bankCity: '',
  bankCountry: '',
  bankName: '',
  bankStreet: '',
  bankZip: '',
  bic: '',
  billingEqualPersonalAddress: false,
  branch: '',
  businessName: '',
  city: '',
  contactName: '',
  country: '',
  iban: '',
  payee: '',
  paymentMethod: '',
  personalAddressLine1: '',
  personalAddressLine2: '',
  personalCity: '',
  personalCountry: '',
  personalLegalFirstName: '',
  personalLegalLastName: '',
  personalLegalMiddleName: '',
  personalPostCode: '',
  postCode: '',
  taxDocument: {},
  taxId: '',
  vatDocument: {},
  vatId: '',
};

export const initialState = {
  copyAddress: false,
  data: {},
  formErrors: {},
  loading: true,
  loadingButton: false,
  loadingForm: true,
  loadingTab: true,
  modalAction: '',
  modalIsOpen: false,
  personalData: {},
  region: '',
  selectedTab: '',
  taxInputs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'InstructorProfile.LOAD_TABS_SUCCESS': {
      const tabs = action.data.tabs;
      const selectedTab =
        tabs.filter((tab) => tab.status === 'pending')[0]?.key || tabs[0].key;

      return {
        ...state,
        data: action.data,
        loading: false,
        selectedTab,
      };
    }
    case 'InstructorProfile.LOAD_PERSONAL_DATA_SUCCESS': {
      const personalData = { ...defaultFormState, ...action.personalData };
      return {
        ...state,
        copyAddress: personalData.billingEqualPersonalAddress,
        hasBillingChangeRequest: personalData.hasBillingChangeRequest,
        loadingForm: false,
        personalData,
        region: findRegion(personalData.country),
        taxDocumentsRequired: action.personalData.taxDocumentsRequired,
        taxInputs: [
          personalData.taxId.length > 0 && 'taxId',
          personalData.vatId.length > 0 && 'vatId',
        ].filter((el) => el),
      };
    }
    case 'InstructorProfile.SAVE_PERSONAL_DATA_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          tabs: state.data.tabs.map((obj) =>
            obj.key === action.tab ? { ...obj, status: 'submitted' } : obj
          ),
        },
        formErrors: {},
        loadingButton: false,
        modalAction: 'formSubmit',
        modalIsOpen: true,
      };
    case 'InstructorProfile.SAVE_PERSONAL_DATA_FAILURE':
      return {
        ...state,
        formErrors: keysToCamel(action.errors),
        loadingButton: false,
      };
    case 'InstructorProfile.UPDATE_PERSONAL_INFORMATION_SUCCESS':
      return {
        ...state,
        loadingButton: false,
        modalIsOpen: true,
        redirectLink: action.data.redirectLink,
      };
    case 'InstructorProfile.HANDLE_MODAL_CLOSE_AFTER_UPDATE':
      redirectTo({ url: state.redirectLink });
      break;
    case 'InstructorProfile.SET_SELECTED_TAB':
      return {
        ...state,
        loadingTab: true,
        selectedTab: action.tab,
      };
    case 'InstructorProfile.CLOSE_MODAL': {
      const tabs = state.data.tabs;
      const currentIndex = tabs.findIndex((el) => el.key === state.selectedTab);
      const isCompleted = state.data.status.isCompleted;

      return {
        ...state,
        modalAction: '',
        modalIsOpen: false,
        selectedTab: isCompleted
          ? state.selectedTab
          : tabs[
              currentIndex + 1 >= tabs.length ? currentIndex : currentIndex + 1
            ].key,
      };
    }
    case 'InstructorProfile.SUBMIT_FORM': {
      const { personalData: pd, copyAddress } = state;

      return {
        ...state,
        loadingButton: true,
        personalData: {
          ...pd,
          addressLine1: copyAddress ? pd.personalAddressLine1 : pd.addressLine1,
          addressLine2: copyAddress ? pd.personalAddressLine2 : pd.addressLine2,
          city: copyAddress ? pd.personalCity : pd.city,
          country: copyAddress ? pd.personalCountry : pd.country,
          postCode: copyAddress ? pd.personalPostCode : pd.postCode,
        },
      };
    }
    case 'InstructorProfile.HANDLE_SAVE':
      return {
        ...state,
        personalData: {
          ...state.personalData,
          [action.name]: action.value,
        },
      };
    case 'InstructorProfile.HANDLE_COUNTRY_CHANGE': {
      const clearInputs =
        (action.name === 'personalCountry' && state.copyAddress) ||
        action.name === 'country';

      return {
        ...state,
        personalData: {
          ...state.personalData,
          bankCity: clearInputs ? '' : state.personalData.bankCity,
          bankCountry: clearInputs ? '' : state.personalData.bankCountry,
          bankName: clearInputs ? '' : state.personalData.bankName,
          bankStreet: clearInputs ? '' : state.personalData.bankStreet,
          bankZip: clearInputs ? '' : state.personalData.bankZip,
          branch: clearInputs ? '' : state.personalData.branch,
          contactName: clearInputs ? '' : state.personalData.contactName,
          country:
            action.name === 'personalCountry' && state.copyAddress
              ? action.value
              : state.personalData.country,
          [action.name]: action.value,
          taxDocument: clearInputs ? {} : state.personalData.taxDocument,
          taxId: clearInputs ? '' : state.personalData.taxId,
          vatDocument: clearInputs ? {} : state.personalData.vatDocument,
          vatId: clearInputs ? '' : state.personalData.vatId,
        },
        region: findRegion(
          clearInputs ? action.value : state.personalData.country
        ),
      };
    }
    case 'InstructorProfile.HANDLE_PAYMENT_METHOD_CHANGE':
      return {
        ...state,
        personalData: {
          ...state.personalData,
          bankCity: '',
          bankCountry: '',
          bankName: '',
          bankStreet: '',
          bankZip: '',
          bic: '',
          branch: '',
          contactName: '',
          iban: '',
          payee: '',
          paymentMethod: action.paymentMethod,
        },
      };
    case 'InstructorProfile.HANDLE_COPY_ADDRESS':
      return {
        ...state,
        copyAddress: action.checked,
        personalData: {
          ...state.personalData,
          country: state.personalData.personalCountry,
        },
        region: findRegion(
          action.checked
            ? state.personalData.personalCountry
            : state.personalData.country
        ),
      };
    case 'InstructorProfile.HANDLE_TAX_CHANGE':
      return {
        ...state,
        taxInputs: [...state.taxInputs, action.value].filter((el) => el),
      };
    case 'InstructorProfile.REMOVE_TAX_INPUT':
      return {
        ...state,
        formErrors: omit(state.formErrors, [
          `${action.key}Id`,
          `${action.key}Document`,
        ]),
        personalData: {
          ...state.personalData,
          [`${action.key}Id`]: '',
          [`${action.key}Document`]: {},
        },
        taxInputs: state.taxInputs.filter((item) => item !== `${action.key}Id`),
      };
    case 'InstructorProfile.REMOVE_FILE':
      return {
        ...state,
        formErrors: omit(state.formErrors, [action.key]),
        personalData: {
          ...state.personalData,
          [action.key]: {},
        },
      };
    case 'InstructorProfile.HANDLE_FILE_UPLOAD':
      return {
        ...state,
        personalData: {
          ...state.personalData,
          [action.key]: action.res,
        },
      };
    case 'InstructorProfile.SUBMIT_RESOURCES':
      return {
        ...state,
        loadingButton: true,
      };
    case 'InstructorProfile.SUBMIT_RESOURCES_SUCCESS':
      return {
        ...state,
        data: action.data,
        loadingButton: false,
        modalAction: 'resourcesSubmit',
        modalIsOpen: true,
      };
    case 'InstructorProfile.LOAD_DOCUMENTS_SUCCESS':
      return {
        ...state,
        documents: action.documents,
        loadingTab: false,
      };
    case 'InstructorProfile.SUBMIT_DATA':
      return {
        ...state,
        formErrors: {},
        loadingButton: true,
      };
    case 'InstructorProfile.SAVE_DOCUMENTS_SUCCESS': {
      const onboardingCompleted = action.data.status.isCompleted;

      return {
        ...state,
        data: action.data,
        documents: action.documents,
        formErrors: {},
        loadingButton: false,
        modalAction: 'resourcesSubmit',
        modalIsOpen: onboardingCompleted,
        selectedTab: onboardingCompleted ? action.tab : 'capacity',
      };
    }
    case 'InstructorProfile.SAVE_DOCUMENTS_FAILURE':
      return {
        ...state,
        formErrors: keysToCamel({ ...action.errors.documents }[0]),
        loadingButton: false,
      };
    case 'InstructorProfile.REMOVE_DOCUMENT':
      return {
        ...state,
        formErrors: omit(state.formErrors, [camelCase(action.key)]),
      };
    case 'InstructorProfile.GET_CAPACITY':
      return {
        ...state,
        capacity: action.capacity,
        loadingTab: false,
      };
    case 'InstructorProfile.SET_CAPACITY_SUCCESS': {
      const onboardingCompleted = action.data.status.isCompleted;

      return {
        ...state,
        capacity: action.mentorSpots,
        data: action.data,
        loadingButton: false,
        modalAction: 'resourcesSubmit',
        modalIsOpen: onboardingCompleted,
        selectedTab: onboardingCompleted ? action.tab : 'resources_surveys',
      };
    }
    default:
      return state;
  }
}
