module.exports = (() => {
  const init = () => {
    $(document).ready(() => {
      bindFunctions();
      toggleTruncatedText();
    });
  };

  const bindFunctions = () => {
    $(document).on('click', '.toggle-collapse', toggleAccordionArrow);
    $(document).on('change', '#start-rating-form', submitRating);
    $(document).on('click', '#ai-tutor-slider', toggleSlider);
  };

  const toggleSlider = function (event) {
    const checked = event.target.checked;
    const aiSubmissions = $('.ai_tutor_submission_item');
    checked ? aiSubmissions.show() : aiSubmissions.hide();
  };

  const toggleAccordionArrow = function () {
    $(this).find('[class*="angle"]').toggleClass('fa-angle-up fa-angle-down');
  };

  const toggleTruncatedText = function () {
    $('.truncated-text').each(function () {
      const $element = $(this);
      const maxLength = $element.data('max-length') || 500;
      const fullHtml = $element.html();
      const textContent = $element.text();

      if (textContent.length > maxLength) {
        $element.data('full-html', fullHtml);
        const truncatedText = textContent.substr(0, maxLength) + '...';
        $element.html(truncatedText);

        $element.next('.toggle-text').click(function (event) {
          event.stopPropagation();
          const isTruncated = $element.html().endsWith('...');
          if (isTruncated) {
            $element.html($element.data('full-html'));
            $(this).text('Show Less');
          } else {
            $element.html(truncatedText);
            $(this).text('Show More');
          }
        });
      } else {
        $element.next('.toggle-text').hide();
      }
    });
  };

  const submitRating = function () {
    const rating = $(this).val();
    const question = $(this).attr('data-question');
    const responseId = $(this).attr('data-response-id');

    $.post(`/api/v1/ai_tutor_submissions/${responseId}/submit_rating`, {
      rating,
      rating_criteria: question,
    });
  };

  return {
    init,
  };
})();
