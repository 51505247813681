import {
  Channel,
  ChannelList,
  ChannelListMessenger,
  Chat,
  Thread,
  Window,
} from 'stream-chat-react';
import CustomChannel from '../CustomChannel';
import CustomChannelPreview from '../CustomChannelPreview';
import CustomDateSeparator from '../../../components/CustomDateSeparator';
import PropTypes from 'prop-types';
import React from 'react';
import { Wrapper } from './styles';
import { chatClient } from '../../../index';

const App = ({ filters, sort, memberChannelId }) => {
  const customSearchResultItem = (props) => {
    return <CustomChannelPreview {...props} uuid={filters.members.$in[0]} />;
  };
  const additionalProps = {
    SearchResultItem: customSearchResultItem,
    searchForChannels: true,
    searchFunction: async (ChannelSearchFunctionParams, event) => {
      ChannelSearchFunctionParams.setQuery(event.target.value);
      const channels = await chatClient.queryChannels({
        ...filters,
        'member.user.name': { $autocomplete: event.target.value || 'z' },
      });
      ChannelSearchFunctionParams.setResults(channels);
    },
  };

  return (
    <Wrapper>
      <Chat client={chatClient} theme={'messaging light'}>
        <ChannelList
          filters={filters}
          sort={sort}
          showChannelSearch
          additionalChannelSearchProps={additionalProps}
          List={ChannelListMessenger}
          customActiveChannel={memberChannelId}
        />
        <Channel DateSeparator={CustomDateSeparator}>
          <Window>
            <CustomChannel filters={filters} />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    </Wrapper>
  );
};

App.propTypes = {
  filters: PropTypes.object,
  memberChannelId: PropTypes.string,
  sort: PropTypes.object,
};

export default App;
