import styled from 'styled-components';

const StyledBodyPanel = styled.div`
  margin-top: 40px;
  width: 900px;
  height: 556px;
  padding: 32px 45px 25px 33px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);
  background-color: #fff;
  h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #223c50;
    text-align: left;
    margin-bottom: 16px;
  }

  .button-section {
    margin-top: 31px;
    display: flex;
    justify-content: flex-end;

    button {
      margin: 0;
      width: max-content;
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: normal;
    color: #223c50;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 16px 16px 16px;
    margin-top: 0;
  }
`;

StyledBodyPanel.displayName = 'StyledBodyPanel';

export default StyledBodyPanel;
