import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import StarIcon from './StarIcon';

export function RatingIcon ({ index, rating, hoverRating, onMouseEnter, onMouseLeave, onSaveRating }) {
  const fill = useMemo(() => {
    if (hoverRating >= index) {
      return 'var(--ds-color__indigo)';
    } else if (!hoverRating && rating >= index) {
      return 'var(--ds-color__indigo)';
    }
    return 'none';
  }, [rating, hoverRating, index]);
  return (
    <div
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={() => onMouseLeave()}
      onClick={() => onSaveRating(index)}>
      <StarIcon fill={fill} />
    </div>
  );
}

RatingIcon.propTypes = {
  hoverRating: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onSaveRating: PropTypes.func.isRequired,
  rating: PropTypes.number.isRequired,
};

export default RatingIcon;
