import React, { useEffect } from 'react';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import TeamPage from '../../components/TeamPage';
import { connect } from 'react-redux';
import { getCurriculumStructure } from '../../actions';

const TeamPageContainer = ({
  data = {},
  curriculumStructure = {},
  isLoading = false,
  onGetCurriculumStructure = noop,
  userData = {},
  ...props
}) => {
  const curriculumStructureType = curriculumStructure?.type || 'course';
  const isMentorOnly = get(
    curriculumStructure,
    'curriculumStructure.mentorOnly',
    true
  );
  const shouldHideTutorCard = !!(
    curriculumStructureType === 'course' && isMentorOnly
  );

  const shouldHideMember = (title) => {
    if (
      (title === 'Career Specialist' && !userData.hasProductWithJobPrep) ||
      (title === 'Tutor' && shouldHideTutorCard)
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!curriculumStructure) {
      onGetCurriculumStructure();
    }
  }, []);

  return (
    !isLoading && (
      <TeamPage
        head={data?.head}
        members={data?.members}
        shouldHideMember={shouldHideMember}
        {...props}
      />
    )
  );
};

TeamPageContainer.propTypes = {
  curriculumStructure: PropTypes.shape({
    curriculumStructure: PropTypes.shape({
      courseCategory: PropTypes.string,
      curriculum: PropTypes.string,
      mentorOnly: PropTypes.bool,
      name: PropTypes.string,
    }),
    timeline: PropTypes.array,
    type: PropTypes.string,
  }),
  data: PropTypes.shape({
    head: PropTypes.shape({
      description: PropTypes.string,
      main: PropTypes.string,
      pageIndex: PropTypes.number,
      title: PropTypes.string,
    }),
    members: PropTypes.arrayOf(
      PropTypes.shape({
        bulletpoints: PropTypes.arrayOf(PropTypes.string),
        color: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    title: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  onGetCurriculumStructure: PropTypes.func,
  userData: PropTypes.shape({
    hasProductWithJobPrep: PropTypes.bool,
  }),
};

export const mapStateToProps = (state) => {
  return {
    curriculumStructure: get(state, 'onBoarding.curriculumStructure', {}),
    isLoading: get(state, 'onBoarding.isLoading', false),
    userData: get(state, 'onBoarding.userData', {}),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onGetCurriculumStructure: () => dispatch(getCurriculumStructure()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamPageContainer);
