import styled from 'styled-components';

const ButtonSection = styled.div`
  a {
    margin: 10px;
  }
  padding: 72px 0px 20px;
  display: flex;
  justify-content: center;

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  ${({ isModal }) =>
    !isModal &&
    `
      padding: 43px 0 20px;
    `}

    @media (max-width: 768px) {
      padding: 40px 0px 20px;

      ${({ isModal }) =>
    !isModal &&
      `
        padding: 26px 0 20px;
      `}
    }
`;

ButtonSection.displayName = 'ButtonSection';

export default ButtonSection;
