import styled from 'styled-components';

const CourseCard = styled.div`
  padding: 30px;

  @media (max-width: 479px) {
    padding: 0;
  }
`;

CourseCard.displayName = 'CourseCard';

export default CourseCard;
