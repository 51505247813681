import PropTypes from 'prop-types';
import React from 'react';
import StarRating from '../../../shared/components/StarRating';

const EvaluationRating = ({ currentAnswer, isModal, saveReviewData, updateReview }) => {
  const handleRating = (answerId, rating) => {
    if (isModal) {
      saveReviewData({ answerId, rating }, 'rating');
      return;
    }
    updateReview(answerId, { rating: rating });
  };

  return (<div className="section text-left">
    <h5 className="ds-typography__h5 tour__interview_prep_evaluation evaluation-header">
      Your self-evaluation
    </h5>
    {!isModal && (
      <p className="ds-typography__body mb-24">
        You can evaluate your answer based on the question insights. A question
        evaluation is considered done after you’ve rated your answer. You also
        have the option to leave notes on what you’d like to improve in the
        future.
      </p>
    )}
    <span className="ds-typography__body--bold tour__interview_prep_rating evaluation-subheader">
      Self-evaluation rating
    </span>
    {!isModal && (
      <p className="ds-typography__body">Your answer saves automatically</p>
    )}
    <StarRating
      key={currentAnswer.id}
      rating={currentAnswer.rating}
      modelId={currentAnswer.id}
      handleRating={handleRating}
      isModal={isModal}
    />
  </div>
  );
};

EvaluationRating.propTypes = {
  currentAnswer: PropTypes.object,
  isModal: PropTypes.bool.isRequired,
  saveReviewData: PropTypes.func.isRequired,
  updateReview: PropTypes.func.isRequired,
};

export default EvaluationRating;
