module.exports = (() => {
  const init = function () {
    $(document).on('change', '#course_forum_enabled', displayForumFields);
    $('#course_forum_color').on('change', updateColorPicker);
  };

  const displayForumFields = function () {
    if (this.checked) {
      $('#forum-fields').removeClass('hide');
    } else {
      $('#forum-fields').addClass('hide');
    }
  };

  const updateColorPicker = function () {
    $('#forum-color-picker').val($('#course_forum_color').val());
  };

  return {
    init: init,
  };
})();
