import React, { useEffect, useState } from 'react';
import Checkbox from '../../../shared/components/Checkbox';
import PropTypes from 'prop-types';
import StyledOffPlatformSuccess from './OffPlatformRegistrationModal/styles/StyledOffPlatformSuccess';
import { getCookie } from './helpers';

function OffPlatformCallSuccessModal({ handleCloseModal }) {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (getCookie('dontShowAgainOffPlatform')) {
      handleCloseModal();
    }
  }, []);

  const handleDontShowAgainChange = (event) => {
    if (event.target.checked) {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      document.cookie = `dontShowAgainOffPlatform=true; expires=${date.toUTCString()}`;
      setChecked(true);
    } else {
      document.cookie =
        'dontShowAgainOffPlatform=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
      setChecked(false);
    }
  };

  return (
    <StyledOffPlatformSuccess>
      <i className='ds-typography__h4 ds-color__indigo ds-icon__thumb-up-2'></i>
      <h4 className='ds-typography__h4 ds-color__indigo'>
        Off-platform call added
      </h4>
      <p>
        The off-platform call and notes were successfully added. No further
        action is required.
      </p>
      <span>
        <Checkbox
          defaultChecked={checked}
          onClick={handleDontShowAgainChange}
          labelText="Don't show this message again"
        />
      </span>
      <button className='ds-button__primary' onClick={handleCloseModal}>
        Got it
      </button>
    </StyledOffPlatformSuccess>
  );
}

OffPlatformCallSuccessModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

export default OffPlatformCallSuccessModal;
