import React from 'react';

const Ideation = () => (
  <>
    <p className="ds-typography__body">As you start thinking about what you want to do for your portfolio project, look far and wide for inspiration and opportunities. Here are some suggestions for project ideas:</p>
    <ul>
      <li>Think of problems you or a friend have recently encountered or have always wanted to solve, which might have tech solutions.</li>
      <li>Redesign your favorite product, thinking about how you can optimize it.</li>
      <li>Turn design challenge ideas into your project. Have a look at relevant websites like <a href="https://uxchallenge.co" target="_blank" rel="noreferrer">uxchallenge.co</a> for inspiration.</li>
      <li>Look around and support the local community. Help a local restaurant with their website, turn shops into webshops…the possibilities are endless!</li>
    </ul>
    <p className="ds-typography__body break">At this stage, you may not be crystal clear on what your ultimate goal is, or of the specific values and audiences you want to cater to, and that’s absolutely fine. You can often refine this after validating your idea and collecting data through research—which we’ll explore in the next section.</p>
    <p className="ds-typography__body--bold">Validate your idea</p>
    <p className="ds-typography__body">Once you have an idea in mind, it’s important to validate your idea in order to make sure that this is something worth spending time and energy on. You can validate your idea in the following ways:</p>
    <ul>
      <li>Doing competitor research</li>
      <li>Sending out an online survey to get feedback</li>
    </ul>
    <p className="ds-typography__body">After the idea validation, you’ll be in a much better position to figure out the specific values and goals of your product, and its target audience. Write them down, and further define them in the following design process.</p>
  </>
);

export default Ideation;
