import styled from 'styled-components';

const AssignmentButtonWrapper = styled.td`
  min-width: 130px;
`;

const TableWrapper = styled.div`
  max-height: calc(100vh - 315px);
  min-height: 150px;
  overflow-y: scroll;

  table {
    width: 100%;

    tbody {
      tr {
        border: 1px solid #E8EBED;

        &:nth-child(odd) {
          background-color: #223C5010;
        }

        td {
          padding: 1rem;
        }
      }
    }
  }

  thead {
    padding-bottom: 4px;

    th {
      padding: 0 1rem;

      &:hover {
        span, i {
          cursor: pointer;
          color: #4e6373;
        }
      }
    }

    i {
      vertical-align: sub;
      display: inline-block;
      margin-left: 4px;
      color: var(--ds-color__indigo);
    }
  }
`;

TableWrapper.displayName = 'InstructorsTable';
AssignmentButtonWrapper.displayName = 'AssignmentButtonWrapper';

export {
  AssignmentButtonWrapper,
  TableWrapper,
};
