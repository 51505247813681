import Tile from '../tile';
import styled from 'styled-components';

const StyledLargeTile = styled(Tile)`
  height: 388px;

  @media (max-width: 1170px) {
    height: unset;
    margin-bottom: 11px;

    .row-button {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    height: unset;
    padding: 0;
    margin-bottom: 11px;

    .link-row {
      height: fit-content;
      width: fit-content;
      text-align: center;
    }
    .row-button {
      width: 100%;
    }
  }
`;

StyledLargeTile.displayName = 'StyledLargeTile';

export default StyledLargeTile;
