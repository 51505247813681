import styled from 'styled-components';

const StyledContentWrapper = styled.div`
  flex-basis: 48%;
  padding: 40px;
  h3,
  h5
  {
    margin-bottom: 16px;
    text-align: left;
  }
  h3,
  h5 {
    text-transform: capitalize;
  }

  &.text-wrapper {
    display: flex;
    align-items: center;
    padding: 76px 40px 40px;
  }

  &.text-wrapper p {
    margin-bottom: 80px;
  }
  
  @media (max-width: 900px) {
    h5, p, span {
      display: none;
    }
    padding: 16px;
    min-height: 200px;

    &.text-wrapper p {
      display: block;
      margin-bottom: 16px;
    }
    &.text-wrapper {
      padding: 0 23px 0;
    }
  }

  @media (max-width: 479px) {
    min-height: unset;
    &.text-wrapper {
      padding-top: 0;
    }
  }
`;

StyledContentWrapper.displayName = 'StyledContentWrapper';

export default StyledContentWrapper;
