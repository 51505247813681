import styled from 'styled-components';

const StyledBackgroundImage = styled.div`
  height: 170px;
  background-size: 142px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('http://images.careerfoundry.com/public/images/emptyjobapplications.png');
`;
StyledBackgroundImage.displayName = 'StyledBackgroundImage';
export default StyledBackgroundImage;
