import ApiService from '../../services/ApiService';
import { constants } from './constants';
import { getAuthToken } from '../../utils/getAuthToken';

export const getJobSeekingProfiles = (page, filterParams = {}) => async dispatch => {
  const params = {
    authenticity_token: getAuthToken(),
    page: page,
    per_page: 20,
    ...filterParams,
  };

  const apiService = new ApiService('/api/v1/job_seeking_profiles');
  try {
    dispatch(setLoadingProfile());
    const result = await apiService.makeGetCall(params);
    dispatch({
      data: result.data,
      type: constants.FETCH_JOB_SEEKING_PROFILES_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.FETCH_JOB_SEEKING_PROFILES_FAILURE,
    });
  }
};

export const setLoadingProfile = () => ({ type: constants.SET_PROFILE_LOADING });
export const handleCloseModal = () => ({ type: constants.HANDLE_CLOSE_MODAL });
export const setCreatingChannel = () => ({ type: constants.SET_CREATING_CHANNEL });

export const setFilterParams = (data) => ({ data, type: constants.SET_PROFILE_FILTER });
export const setMessagingLoading = (data) => ({ data, type: constants.SET_MESSAGING_LOADING });

export const showJobSeekingProfile = id => async dispatch => {
  const params = {
    authenticity_token: getAuthToken(),
  };

  const apiService = new ApiService(`/api/v1/job_seeking_profiles/${id}`);
  try {
    const result = await apiService.makeGetCall(params);
    dispatch({
      data: result.data,
      type: constants.SHOW_JOB_SEEKING_PROFILE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.SHOW_JOB_SEEKING_PROFILE_FAILURE,
    });
  };
};

export const createMessageChannel = (studentId) => async dispatch => {
  dispatch(setMessagingLoading(true));
  const params = {
    authenticity_token: getAuthToken(),
    student_id: studentId,
  };

  const apiService = new ApiService('/api/v1/messaging/employers/create_channel');
  try {
    await apiService.makePostCall(params);
    dispatch(setMessagingLoading(false));
  } catch (error) {
    console.log('error', error);
  };
};
