import MentorNotes from '../../containers/MentorNotes/MentorNotes';
import MentorNotesModal from '../../containers/MentorNotes/MentorNotesModal';
import PropTypes from 'prop-types';
import React from 'react';
import TheModal from '../../../shared/components/TheModal';

const MentorNotesTab = ({ handleModalClose, modalIsOpen }) => (
  <>
    <MentorNotes />
    <TheModal handleClose={handleModalClose} isModalOpen={modalIsOpen}>
      <MentorNotesModal handleModalClose={handleModalClose} />
    </TheModal>
  </>
);

MentorNotesTab.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool,
};

export default MentorNotesTab;
