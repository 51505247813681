import { Body, Button, Header } from '@careerfoundry/bubble';
import { ButtonWrapper, ModalContent } from './styles';
import React from 'react';
import TheModal from '../../../shared/components/TheModal';

interface IProps {
  handleClose: () => void;
  modalIsOpen: boolean;
  handleConfirm: () => void;
}

const SubmissionModal: React.FC<IProps> = ({
  handleClose,
  modalIsOpen,
  handleConfirm,
}) => (
  <TheModal handleClose={handleClose} isModalOpen={modalIsOpen}>
    <ModalContent>
      <Header tag='h4' variant='h4'>
        Already sent your voucher?
      </Header>
      <Body tag='p' variant='medium'>
        We’ll keep an eye out at headquarters! When it arrives this step will be
        marked as complete.
      </Body>
      <ButtonWrapper>
        <Button
          label='Cancel'
          variant='secondary'
          size='standard'
          onClick={handleClose}
        />
        <Button
          label="Yes, it's in the mail"
          variant='primary'
          size='standard'
          onClick={handleConfirm}
        />
      </ButtonWrapper>
    </ModalContent>
  </TheModal>
);

export default SubmissionModal;
