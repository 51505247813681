import React from 'react';
import WorkPreferences from '../../modals/showModal/WorkPreferences';
import { connect } from 'react-redux';
import { updateECProfile } from '../../actions';

const WorkPreferencesViewContainer = (props) => {
  return (
    <WorkPreferences {...props} />
  );
};

export const mapStateToProps = ({
  careerSupportCenter: { jobSeekingProfile },
}) => ({
  jobSeekingProfile,
});

export const mapDispatchToProps = (dispatch) => ({
  updateECProfile: (data) => dispatch(updateECProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkPreferencesViewContainer);
