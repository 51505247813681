import styled from 'styled-components';

const Marker = styled.i`
  background-color: ${({ hasMetWsg }) => hasMetWsg ? 'var(--ds-color__aqua)' : '#f1f1f1'};
  background-color: ${({ active }) => active ? 'var(--ds-color__aqua)' : '#f1f1f1'};
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  margin-left: 2px;
  padding: 4px;
`;

const Tick = styled.div`
  background-color: ${({ isComplete }) => isComplete ? 'var(--ds-color__aqua)' : '#f1f1f1'};
  flex-grow: 1;
  height: 10px;
  margin-left: 2px;

  &:first-of-type {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    margin-left: 0;
  }

  &:last-of-type {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  &::before {
    color: transparent;
    content: ".";
  }
`;

const TickWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const Title = styled.small`
  bottom: -20px;
  position: absolute;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

Marker.displayName = 'Marker';
Tick.displayName = 'Tick';
TickWrapper.displayName = 'TickWrapper';
Title.displayName = 'Title';
Wrapper.displayName = 'Wrapper';

export {
  Marker,
  Tick,
  TickWrapper,
  Title,
  Wrapper,
};
