import FormField from '../../../EmployerConnector/modals/shared/FormField';
import styled from 'styled-components';

const StyledFormField = styled(FormField)`
  margin-bottom: 28px;
  align-items: center;
  .large-label {
    width: 40%;
  }

  input, select {
    width: 200px;
    height: 40px;
    padding: 5px 4px 4px 10px;
    border-radius: 4px;
    border: solid 2px #a7b1b9;
    background-color: #fff;
  }
  

  ${({ isFilter }) => isFilter && `
    margin-right: 32px;
    width: unset;
    .large-label {
      width: unset;
    }

    input {
      width: unset;
      min-width: 200px;
    }
  `}

  ${({ isVerticalLabel }) => isVerticalLabel && `
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`;

StyledFormField.displayName = 'StyledFormField';

export default StyledFormField;
