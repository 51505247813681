import { setSelectedSubTab, setSelectedTab } from '../../actions';
import Sidebar from '../../components/Sidebar';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IAdmission, IProgress } from '../../interfaces';

interface IState {
  afaAdmissions: {
    selectedTab: string;
    selectedSubTab: string;
    progress: IProgress;
    admission: IAdmission;
  };
}

export const mapStateToProps = ({
  afaAdmissions: { selectedTab, selectedSubTab, progress, admission },
}: IState) => ({
  selectedTab,
  selectedSubTab,
  progress,
  firstName: admission.firstName,
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSelectedTab: (tab: string) => dispatch(setSelectedTab(tab)),
  setSelectedSubTab: (tab: string) => dispatch(setSelectedSubTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
