import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  display: inline-block;
  margin: 40px 0 50px;
  i {
    margin-right: 10px;
  }
`;

StyledLink.displayName = 'StyledLink';

export default StyledLink;
