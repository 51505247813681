import PropTypes from 'prop-types';
import React from 'react';
import StyledPagination from './styles';

const PaginationControls = ({
  totalPages,
  currentPage,
  setCurrentPage,
  indexControls,
}) => {
  const { previousPage, nextPage } = indexControls;
  const getRequestedPage = (direction, jumpTo) => {
    if (jumpTo && direction === 1) {
      return totalPages;
    } else if (jumpTo && direction === -1) {
      return 1;
    } else {
      return currentPage + direction;
    }
  };

  const togglePage = (direction, jumpTo) => {
    const requestedPage = getRequestedPage(direction, jumpTo);
    setCurrentPage(requestedPage);
  };
  return (
    <StyledPagination>
      {<i onClick={previousPage ? () => togglePage(-1, true) : null} className={`fa ${previousPage ? 'clickable' : 'grey'} fa-angle-double-left fa-lg`} rel="noopener noreferrer" />}
      {<i onClick={previousPage ? () => togglePage(-1, false) : null} className={`fa ${previousPage ? 'clickable' : 'grey'} fa-angle-left fa-lg`} rel="noopener noreferrer" />}
      {<i onClick={nextPage ? () => togglePage(1, false) : null} className={`fa ${nextPage ? 'clickable' : 'grey'} fa-angle-right fa-lg`} rel="noopener noreferrer" />}
      {<i onClick={nextPage ? () => togglePage(1, true) : null} className={`fa ${nextPage ? 'clickable' : 'grey'} fa-angle-double-right fa-lg`} rel="noopener noreferrer" />}
    </StyledPagination>
  );
};

PaginationControls.propTypes = {
  currentPage: PropTypes.number.isRequired,
  indexControls: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default PaginationControls;
