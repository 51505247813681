import styled from 'styled-components';

const ModalWrapper = styled.div`
  .TheModal {
    width: 440px;
  }
`;

const ModalContent = styled.div`
  i {
    font-size: 40px;
  }

  h3,
  p,
  i {
    color: var(--ds-color__indigo);
  }

  text-align: center;
`;

const ModalActions = styled.div`
  margin-top: 32px;

  button {
    margin: 0 5px;
  }
`;

ModalActions.displayName = 'ModalActions';
ModalContent.displayName = 'ModalContent';
ModalWrapper.displayName = 'ModalWrapper';

export { ModalActions, ModalContent, ModalWrapper };
