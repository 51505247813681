import axios from 'axios'

class MentorService {
  constructor (authToken) {
    this.authToken = authToken
  }

  todos (courseId) {
    return axios.get(`/mentor-api/my-todos?course_id=${courseId}`, {
      authenticity_token: this.authToken
    }).then((response) => {
      return ({ data: response.data, error: null })
    }).catch((error) => {
      return ({ data: null, error: error.response.data })
    })
  }

  students (courseId, sortingOption, sortingOrder, jobPrepStatus) {
    return axios.get(`/mentor-api/my-students?course_id=`, {
      authenticity_token: this.authToken,
      params: {
        course_id: courseId,
        sorting_option: sortingOption,
        sorting_order: sortingOrder,
        job_prep_status: jobPrepStatus
      }
    }).then((response) => {
      return ({ data: response.data, error: null })
    }).catch((error) => {
      return ({ data: null, error: error.response.data })
    })
  }
}

export default MentorService
