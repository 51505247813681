import Checkbox from '../shared/components/Checkbox';
import React, { Component } from 'react';
import AdminWrapper from '../shared/adminWrapper';
import Input from '../shared/input';
import TableView from '../shared/table';
import AdminListViewService, {
  SortOrders,
} from '../../services/AdminListViewService';
import { formatRows } from './formatRows';
import CFModal from '../shared/modal';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { headerColumns } from './helpers';
import { StyledClearFiltersText } from './styles';

const AFA_STUDENT_DETAILS_URL = '/en/admin/afa_attendees.json';

class AfaStudentList extends Component {
  constructor(props) {
    super(props);

    this.sortOrder = SortOrders.getSortOrders();

    this.state = {
      courseOptions: [],
      labelText: 'Search by student name',
      startDates: { start: null, end: null },
      endDates: { start: null, end: null },
      filterByTrialDate: false,
      hideCompletedCourses: true,
      hideJobPrep: true,
      filterByText: '',
      filterByCourse: '',
      offset: 1,
      limit: 25,
      sortByColumn: 'start',
      sortOrder: this.sortOrder.DSC,
      loading: true,
      currentRowCount: 0,
      totalRowCount: 0,
      rows: [],
      rowsRawData: [],
      headerColumns: headerColumns,
      csvDownloadLink: '',
      modalOpen: false,
      currentUserNotes: [],
    };

    this.adminListViewService = new AdminListViewService(
      AFA_STUDENT_DETAILS_URL,
      this.state.limit
    );
  }

  componentDidMount() {
    this.setApiServiceParamsFromState();
  }

  clearApiServiceParamsInState = () => {
    this.setState(
      {
        offset: 1,
        filterByCourse: '',
        filterByText: '',
        startDates: { start: null, end: null },
        endDates: { start: null, end: null },
        sortByColumn: 'start',
        sortOrder: this.sortOrder.DSC,
        filterByTrialDate: false,
        hideCompletedCourses: true,
        hideJobPrep: true,
        loading: true,
      },
      () => {
        this.setApiServiceParamsFromState();
      }
    );
  };

  setApiServiceParamsFromState = (isAppend) => {
    this.adminListViewService.setOffset(this.state.offset);
    this.adminListViewService.setSearchQuery(this.state.filterByText);
    this.adminListViewService.setSortByColumn(this.state.sortByColumn);
    this.adminListViewService.setSortOrder(this.state.sortOrder);
    this.adminListViewService.setSortByCourse(this.state.filterByCourse);
    this.adminListViewService.setStartDates(this.state.startDates);
    this.adminListViewService.setEndDates(this.state.endDates);
    this.adminListViewService.setTrialDate(this.state.filterByTrialDate);
    this.adminListViewService.setHideCompletedCourses(
      this.state.hideCompletedCourses
    );
    this.adminListViewService.setHideJobPrep(this.state.hideJobPrep);
    this.getLatestResults(isAppend);
  };

  getLatestResults = (isAppend = false) => {
    this.adminListViewService
      .getLatestResults()
      .then((res) => {
        if (res.error) {
          console.log(res.error);
          this.clearApiServiceParamsInState();
        } else {
          const apiRes = res.data;
          let rowsRawData = isAppend
            ? [...this.state.rowsRawData, ...apiRes.data]
            : apiRes.data;
          rowsRawData = _.uniqBy(rowsRawData, 'id');
          const rows = formatRows(rowsRawData, this.adminNotesClickHandler);
          this.setState({
            rows,
            rowsRawData,
            courseOptions: ['All', ...apiRes.courses],
            totalRowCount: apiRes.pagination.totalEntries,
            currentRowCount: rows.length,
            loading: false,
            csvDownloadLink: apiRes.actions.export,
          });
        }
      })
      .catch((err) => {
        // do something with the error?
        console.log(err);
      });
  };

  adminNotesClickHandler = (id) => {
    const userObj = _.find(this.state.rowsRawData, {
      user: { id: parseInt(id) },
    });
    this.setState({
      currentUserNotes: userObj.user.notes,
      modalOpen: true,
    });
  };

  // filter/search change handler
  onFilterTextChange = (value) => {
    if (value === '') {
      if (this.state.filterByText !== '') {
        this.clearApiServiceParamsInState();
      }
      return;
    }

    this.setState(
      {
        rows: [],
        rowsRawData: [],
        sortOrder: '',
        sortByColumn: '',
        offset: 1,
        filterByText: value,
        loading: true,
      },
      () => {
        this.setApiServiceParamsFromState();
      }
    );
  };

  // append new results for infinite search thingy
  fetchNewTableEntries = () => {
    this.setState(
      {
        loading: true,
        offset: this.state.offset + 1,
      },
      () => {
        this.setApiServiceParamsFromState(true);
      }
    );
  };

  onColumnClick = (columnName, sortOrder) => {
    if (!columnName || !sortOrder) return null;
    this.setState(
      {
        sortOrder,
        sortByColumn: columnName,
        rows: [],
        rowsRawData: [],
        loading: true,
        offset: 1,
      },
      () => {
        this.setApiServiceParamsFromState();
      }
    );
  };

  onRequestCloseModal = () => {
    this.setState({
      currentUserNotes: [],
      modalOpen: false,
    });
  };

  onCourseFilter = (ev) => {
    if (ev.target.value === 'All') {
      if (this.state.filterByCourse !== '') {
        this.clearApiServiceParamsInState();
      }
      return;
    }

    this.setState(
      {
        filterByCourse: ev.target.value,
        loading: true,
      },
      () => {
        this.setApiServiceParamsFromState();
      }
    );
  };

  onFilterDate = (dates, type) => {
    const [start, end] = dates;
    if (start === null) {
      if (this.state[type].start !== null || this.state[type].end !== null) {
        this.clearApiServiceParamsInState();
      }
      return;
    }

    this.setState(
      {
        [type]: { start: start, end: end },
        loading: true,
      },
      () => {
        if (start && end) {
          this.setApiServiceParamsFromState();
        }
      }
    );
  };

  toggleCheckbox = (ev) => {
    const { name, checked } = ev.target;

    this.setState(
      {
        [name]: checked,
        loading: true,
      },
      () => {
        this.setApiServiceParamsFromState();
      }
    );
  };

  onEndDate = (dates) => this.onFilterDate(dates, 'endDates');
  onStartDate = (dates) => this.onFilterDate(dates, 'startDates');

  render() {
    const {
      courseOptions,
      filterByCourse,
      startDates,
      endDates,
      filterByTrialDate,
      hideCompletedCourses,
      hideJobPrep,
    } = this.state;

    return (
      <AdminWrapper headerText={`Current AFA Students`}>
        <p className='cf-small--bold'>Search</p>
        <div>
          <Input
            value={this.state.filterByText}
            onChange={this.onFilterTextChange}
            labelText={this.state.labelText}
            disabled={false}
            classes='admin_page__input--no-top-margin'
          />
          <p className='cf-small--bold'>Course name</p>
          <select
            name='course-name'
            id='course-name'
            class='cf-form__input'
            onChange={(ev) => {
              this.onCourseFilter(ev, 'select');
            }}
            value={filterByCourse == '' ? 'All' : filterByCourse}
          >
            {courseOptions.map((opt) => {
              return (
                <option className='search-option-afa' key={opt} value={opt}>
                  {' '}
                  {opt}
                </option>
              );
            })}
          </select>
        </div>
        <>
          <p className='cf-small--bold'>Start date</p>
          <DatePicker
            selected={startDates.start}
            onChange={this.onStartDate}
            startDate={startDates.start}
            endDate={startDates.end}
            selectsRange
            className='cf-form__input'
            placeholderText='Select range for start date'
          />
          <p className='cf-small--bold'>End date</p>
          <DatePicker
            selected={endDates.start}
            onChange={this.onEndDate}
            startDate={endDates.start}
            endDate={endDates.end}
            selectsRange
            className='cf-form__input'
            placeholderText='Select range for end date'
          />
        </>

        <Checkbox
          name='filterByTrialDate'
          labelText='In trial period'
          onClick={this.toggleCheckbox}
          defaultChecked={filterByTrialDate}
        />

        <Checkbox
          name='hideCompletedCourses'
          labelText='Hide completed courses'
          onClick={this.toggleCheckbox}
          defaultChecked={hideCompletedCourses}
        />

        <Checkbox
          name='hideJobPrep'
          labelText='Exclude job prep course'
          onClick={this.toggleCheckbox}
          defaultChecked={hideJobPrep}
        />

        <br />

        <StyledClearFiltersText
          className='cf-medium--bold'
          onClick={this.clearApiServiceParamsInState}
        >
          Clear Filters
        </StyledClearFiltersText>

        <br />
        <br />

        <TableView
          headerColumns={this.state.headerColumns}
          rows={this.state.rows}
          loading={this.state.loading}
          totalRowCount={this.state.totalRowCount}
          fetchNewTableEntries={this.fetchNewTableEntries}
          onColumnClick={this.onColumnClick}
          csvDownloadLink={this.state.csvDownloadLink}
        />
        <CFModal
          isOpen={this.state.modalOpen}
          onAfterOpen={this.onAfterOpenModal}
          onRequestClose={this.onRequestCloseModal}
          appElement={'.admin__afa_students'}
        >
          <div>
            <h3>Admin Notes</h3>
            {this.state.currentUserNotes.length !== 0 ? (
              <ul>
                {this.state.currentUserNotes.map((item) => {
                  return <li key={item.id}>{item.note}</li>;
                })}
              </ul>
            ) : (
              <p>No notes available for user</p>
            )}
          </div>
        </CFModal>
      </AdminWrapper>
    );
  }
}

export default AfaStudentList;
