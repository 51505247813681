import MyCFPlusIcon from '../../../components/icons/MyCFPlusIcon';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { meData } = state.sideNavList;
  const isDisabled = false;

  return {
    isDisabled,
    isVisible: meData.canAccessCfPlus,
  };
};

const MyCFPlusIconIconContainer = (props) => {
  return <MyCFPlusIcon {...props} />;
};

MyCFPlusIconIconContainer.propTypes = {
  ...MyCFPlusIcon.propTypes,
};

export default connect(mapStateToProps, null)(MyCFPlusIconIconContainer);
