import { createApplicationConsent, handleCloseModal } from '../../../CareerSupportCenter/actions';
import ApplicationsConsentModal from '../../components/JobApplicationsConsent';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  careerSupportCenter: {
    shareJobApplication,
  },
}) => ({
  shareJobApplication,
});

export const mapDispatchToProps = dispatch => ({
  createApplicationConsent: (userId, shared) => dispatch(createApplicationConsent(userId, shared)),
  handleClose: () => dispatch(handleCloseModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsConsentModal);
