import PaginationControls from '../../../shared/components/Pagination';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '../../../shared/components/PaginatedTable/styles';

const PaginatedTable = ({
  activePage,
  collection,
  collectionLength,
  columns,
  itemsCountPerPage,
  showApplicationModal,
  getJobSeekingProfiles,
  profileFilterParams,
}) => {
  const handlePageChange = (pageNumber) => getJobSeekingProfiles(pageNumber, profileFilterParams);
  const getColumnData = (row, item, index) => {
    if (typeof item === 'object') {
      const name = item.name;
      const link = item.url && row[item.url.toLowerCase()];
      const nameLC = row[item.key];

      return (
        <td key={`${name}_${index}`}>
          {item.type === 'link' && link
            ? (
              <a
                className="ds-typography__anchor"
                href={link}
                rel="noreferrer"
                target="_blank"
              >
                {nameLC}
              </a>
            )
            : (
              <span>{nameLC}</span>
            )}
        </td>
      );
    }
    return <td key={`${item}_${index}`}>{row[item.toLowerCase()]}</td>;
  };

  return (
    <>
      <Table className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              {columns.map((column) => {
                if (typeof column === 'object') {
                  return <th key={column.name}>{column.name}</th>;
                }
                return <th key={column}>{column}</th>;
              })}
              <th>Application Package</th>
            </tr>
          </thead>
          <tbody>
            {collection.map((row, i) => (
              <tr key={`row_${i}`}>
                {columns.map((item, index) => {
                  return getColumnData(row, item, index);
                })}
                <td><a className='ds-typography__anchor' onClick={() => showApplicationModal(row.id)}>View</a></td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
      {collectionLength > itemsCountPerPage && (
        <PaginationControls
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={collectionLength}
          pageRangeDisplayed={itemsCountPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};

PaginatedTable.propTypes = {
  activePage: PropTypes.number.isRequired,
  collection: PropTypes.array.isRequired,
  collectionLength: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  getJobSeekingProfiles: PropTypes.func.isRequired,
  itemsCountPerPage: PropTypes.number,
  profileFilterParams: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    program: PropTypes.string,
    specialization: PropTypes.string,
  }),
  showApplicationModal: PropTypes.func.isRequired,
};

export default PaginatedTable;
