import styled from 'styled-components';

const ReactTooltipWrapper = styled.span`
  div.__react_component_tooltip {
    border-radius: 0.25rem;
    padding: 1rem;
    max-width: 12.25rem;
    opacity: 1;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export { ReactTooltipWrapper };
