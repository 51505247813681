export const initialState = {
  afaVoucher: {
    fileName: '',
    data: '',
    type: '',
    state: '',
    reason: '',
    physicalVoucherState: '',
    physicalVoucherReason: '',
    physicalVoucherSentAt: '',
  },
  englishTest: {
    fileName: '',
    data: '',
    state: '',
    reason: '',
  },
  profileSurvey: {
    typeformId: '',
    state: '',
    reason: '',
  },
  termsOfParticipation: {
    state: '',
    reason: '',
  },
  contract: {
    state: '',
    reason: '',
  },
  admissionTest: {
    typeformId: '',
    score: 0,
    state: '',
    reason: '',
  },
  uuid: '',
  bsg: '',
  firstName: '',
  lastName: '',
  userId: '',
  slug: '',
  status: '',
  statusDate: '',
  stateDate: '',
  email: '',
  createdAt: '',
  productName: '',
  id: '',
};
