import styled from 'styled-components';

const ImageWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  img {
    width: 80px;
    height: 80px;
    margin-right: 30px;
  }

  h3 {
    text-align: left;
    margin-bottom: 5px;
  }

  i {
    color: #000;
    margin: 0 6px;
  }

  @media (max-width: 768px) {
    img {
      margin-right: 10px;
    }
`;

ImageWrapper.displayName = 'ImageWrapper';

export default ImageWrapper;
