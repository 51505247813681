import styled from 'styled-components';

const StyledDashboardWrapper = styled.div`
${({ nonStudentView }) =>
    nonStudentView &&
    `
    background-color: rgb(244, 245, 246);
    .grid-wrapper {
      padding: 48px 48px 81px;
    }

    table {
      text-align: center;
      th {
        vertical-align: top !important;
      }
    }
    table > tbody > tr:nth-child(odd) > td,
    table > tbody > tr:nth-child(odd) > th {
      background-color: #fff;
    }

    table > tbody > tr:nth-child(even) > td,
    table > tbody > tr:nth-child(even) > th {
      background-color: #eef0f1;
    }

    .table-bordered > thead > tr > th {
      text-align: center !important;
    }

    @media (max-width: 768px) {
      .grid-wrapper {
        padding: 16px;
      }
    }
  `}
`;

StyledDashboardWrapper.displayName = 'StyledDashboardWrapper';

export default StyledDashboardWrapper;
