import styled from 'styled-components';

const FilterWrapper = styled.div`
  label {
    margin-bottom: 0;
    margin-right: 10px;
    color: rgba(34, 60, 80, 0.75);
  }

  select:first-of-type {
    width: 218px;
  }

  select:last-of-type {
    width: 240px;
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 28px;

  & > div {
    display: inline-block;
    margin-right: 64px;
  }

  input {
    width: 230px;
  }

  button {
    width: 172px;
    padding: 15px 16px 13px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const TableHeader = styled.div`
  a {
    display: inline-block;
    color: var(--ds-color__indigo) !important;
    margin-bottom: 40px;
    padding: 8px 14px 5px;
  }
`;

TableHeader.displayName = 'TableHeader';

const TableWrapper = styled.div`
  min-height: 150px;
  background: white;
  padding-bottom: 50px;

  table {
    width: 100%;

    tbody tr:nth-child(odd) {
      background-color: #223c5010;
    }
    tr {
      border: 1px solid #e8ebed;
      td {
        cursor: pointer;
      }
      td,
      th {
        font-size: 12px;
        line-height: 1.2em;
        padding: 10px 5px;
      }
    }
  }

  .actions {
    a {
      font-size: 16px;
      &:hover {
        color: #65a0ae;
      }
    }
  }

  .empty-state {
    margin-top: 20px;
  }

  .flash-message-container {
    display: none;

    &.flash {
      display: block;
    }
  }
`;

TableWrapper.displayName = 'InstructorsTable';

const BreakLine = styled.div`
  display: block;
  clear: both;
  width: 100%;
  height: 1px;
`;

export { FilterWrapper, SearchWrapper, TableWrapper, TableHeader, BreakLine };
