import React from 'react';
import SubmissionUrlField, {
  SubmissionUrlFieldProps,
} from '../../components/SubmissionUrlField';
import { connect } from 'react-redux';
import { EmptyObject } from 'redux';

interface IState {
  taskSubmission: {
    errors: {
      project_url?: string;
      source_url?: string;
    };
  };
}

export const mapStateToProps = ({ taskSubmission: { errors } }: IState) => ({
  errors,
});

const SubmissionUrlFieldContainer = (props: SubmissionUrlFieldProps) => {
  return <SubmissionUrlField {...props} />;
};

export default connect(mapStateToProps)(SubmissionUrlFieldContainer);
