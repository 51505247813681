import styled from 'styled-components';

const Table = styled.div`
  table {
    margin-bottom: 40px;
  }

  table > thead {
    background-color: var(--ds-color__indigo);
    color: var(--ds-color__white);
  }

  table > tbody > tr:nth-child(even) > td,
  table > tbody > tr:nth-child(even) > th {
    background-color: #eef0f1;
  }
  td a, td span {
    text-transform: capitalize;
  }
`;

Table.displayName = 'Table';

export default Table;
