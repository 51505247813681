import React, { Component } from 'react';


class ProgressBar extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const renderBar = () => {
      const steps = ["Create Account", "Your Plan", "Add Your Details"]
      return(
        <div className="step-circles">
          {steps.map((step, i) => {
            return(
              <div key={i} onClick={() => this.props.onProgressClick(i)} className={`step ${i + 1 <= this.props.completedIndex ? "complete" : ""} ${i == this.props.slideIndex ? "active" : ""}`}>
                <div className="step-circle">
                  {i + 1}
                  <p className="cf-allcaps">{step}</p>
                </div>
                <div className={`circle-separator ${i == steps.length -1 ? "hidden" : ""}`}></div>
              </div>
            )
          })}
        </div>
      )
    }

    return(
      <div className={`ProgressBar cf-text-center ${this.props.slideIndex == 3 ? "hidden" : ""}`}>
        {renderBar()}
      </div>
    )
  }
}

export default ProgressBar
