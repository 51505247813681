import ApiService from '../../services/ApiService';
import { constants } from './constants';
import { getAuthToken } from '../../utils/getAuthToken';
import { getUserData } from '../../services/getUserData';
import { keysToSnake } from '../../utils/keysConverter';

export * from '../EmployerConnector/actions';

export const getGuidedTourSteps = async () => {
  const params = {
    tour_area: 'CareerSupportCenter',
  };

  const apiService = new ApiService('/api/v1/guided_tours');
  try {
    const result = await apiService.makeGetCall(params);
    return result.data.data;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const getMediaResourcesByIndustry = async (industry, preview = null) => {
  const params = {
    industry: industry,
    preview: preview,
  };

  const apiService = new ApiService('/api/v1/media_resources');
  try {
    const result = await apiService.makeGetCall(params);
    return result.data.data;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const handleNewJobApplication = () => (dispatch) => {
  dispatch({ type: constants.HANDLE_NEW_JOB_APPLICATION });
};

export const handleViewJobApplication = (id) => (dispatch) => {
  dispatch({ id: id, type: constants.HANDLE_VIEW_JOB_APPLICATION });
};

export const handleCancelModal = (tempData) => (dispatch) => {
  dispatch({
    data: tempData,
    type: constants.HANDLE_CANCEL_MODAL,
  });
};

export const handleCloseModal = () => (dispatch) => {
  dispatch({ type: constants.HANDLE_CLOSE_MODAL });
};

export const handleUpdateJobApplication = (id) => (dispatch) => {
  dispatch({ id: id, type: constants.HANDLE_UPDATE_JOB_APPLICATION });
};

export const handleDeleteJobApplication = (id) => (dispatch) => {
  dispatch({ id: id, type: constants.HANDLE_DELETE_JOB_APPLICATION });
};

export const handleAttachmentUpdate = (tempData) => (dispatch) => {
  dispatch({ data: tempData, type: constants.HANDLE_ATTACHMENT_UPDATE });
};

export const handleCloseInteractiveMessage = () => (dispatch) => {
  dispatch({ type: constants.CLOSE_INTERACTIVE_MESSAGES });
};

export const getJobApplications = (page, limit) => async (dispatch) => {
  const params = {
    authenticity_token: getAuthToken(),
    limit: limit,
    page: page,
  };

  const apiService = new ApiService('/api/v1/job_applications');
  try {
    const result = await apiService.makeGetCall(params);
    dispatch({
      data: result.data,
      type: constants.GET_JOB_APPLICATIONS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.GET_JOB_APPLICATIONS_FAILURE,
    });
  }
};

export const updateJobApplication = (jobApplication) => async (dispatch) => {
  const { id, ...data } = jobApplication;
  const params = {
    authenticity_token: getAuthToken(),
    job_application: keysToSnake(data),
  };
  dispatch({ type: constants.UPDATE_JOB_APPLICATION_REQUEST });
  const apiService = new ApiService(`/api/v1/job_applications/${id}`);
  try {
    const result = await apiService.makePutCall(params);
    dispatch({
      data: result.data,
      type: constants.UPDATE_JOB_APPLICATION_SUCCESS,
    });

    if (result.data.interactiveMessage) {
      dispatch({
        interactiveMessage: result.data.interactiveMessage,
        type: constants.SHOW_INTERACTIVE_MESSAGES,
      });
    }
  } catch (error) {
    dispatch({
      error: error,
      type: constants.UPDATE_JOB_APPLICATION_FAILURE,
    });
  }
};

export const deleteJobApplication = (id) => async (dispatch) => {
  const params = {
    data: {
      authenticity_token: getAuthToken(),
    },
  };
  const apiService = new ApiService(`/api/v1/job_applications/${id}`);
  try {
    await apiService.makeDeleteCall(params);
    dispatch({
      data: id,
      type: constants.DELETE_JOB_APPLICATION_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.DELETE_JOB_APPLICATION_FAILURE,
    });
  }
};

export const createJobApplication = (jobApplication) => async (dispatch) => {
  const { attachments, ...rest } = jobApplication;
  const attachmentIds = attachments.map((attachment) => attachment.id);
  const params = {
    attachment_ids: attachmentIds,
    authenticity_token: getAuthToken(),
    job_application: keysToSnake(rest),
  };
  dispatch({ type: constants.CREATE_JOB_APPLICATION_REQUEST });

  const apiService = new ApiService('/api/v1/job_applications');
  try {
    const result = await apiService.makePostCall(params);
    dispatch({
      data: result.data,
      type: constants.CREATE_JOB_APPLICATION_SUCCESS,
    });

    if (result.data.interactiveMessage) {
      dispatch({
        interactiveMessage: result.data.interactiveMessage,
        type: constants.SHOW_INTERACTIVE_MESSAGES,
      });
    }
  } catch (error) {
    dispatch({
      error: error,
      type: constants.CREATE_JOB_APPLICATION_FAILURE,
    });
  }
};

export const getMotivationalMessages = async (industry) => {
  const params = {
    industry: industry,
  };

  const apiService = new ApiService('/api/v1/motivational_messages');
  try {
    const result = await apiService.makeGetCall(params);
    return { messages: result.data.data, totalCount: result.data.totalCount };
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const consumeMessage = async (id) => {
  const params = {
    authenticity_token: getAuthToken(),
    id: id,
  };

  const apiService = new ApiService(
    `/api/v1/motivational_messages/${id}/consume_message`
  );
  try {
    const result = await apiService.makePostCall(params);
    return result.data.data;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const rateMessage = async (messageId, rating) => {
  const params = {
    authenticity_token: getAuthToken(),
    id: messageId,
    rating: rating,
  };

  const apiService = new ApiService(
    `/api/v1/motivational_messages/${messageId}/rate_message`
  );
  try {
    const result = await apiService.makePostCall(params);
    return result.data.data;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const updateJobApplicationAttachments = (attachments) => (dispatch) => {
  dispatch({
    data: attachments,
    type: constants.UPDATE_JOB_APPLICATION_ATTACHMENTS,
  });
};

export const getAllIndustries = () => async (dispatch) => {
  const apiService = new ApiService('/api/v1/industries');

  try {
    const result = await apiService.makeGetCall();
    dispatch(setAllIndustry(result.data));
    return result.data;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const getUserIndustry = async () => {
  const apiService = new ApiService('/api/v1/users/industry');

  try {
    const result = await apiService.makeGetCall();
    return result.data.industry;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const getIndustry = () => async (dispatch) => {
  try {
    const industry = await getUserIndustry();
    dispatch(setUserIndustry(industry));
    return industry;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const getApplicationConsent =
  (userId, hasApplications) => async (dispatch) => {
    const apiService = new ApiService(
      '/api/v1/job_applications/past_job_applications_prompt'
    );

    try {
      dispatch(updateLoadingConsent(true));
      const result = await apiService.makeGetCall();
      const showPrompt = !!(
        result &&
        result.data.data.pastJobApplicationsPrompt &&
        hasApplications
      );

      if (showPrompt) {
        dispatch(showApplicationConsent());
      } else {
        saveConsentToLocal(userId);
        dispatch(updateShareApplication(true));
        dispatch(handleNewJobApplication());
      }

      dispatch(updateLoadingConsent(false));
      const isShared = !showPrompt;
      return isShared;
    } catch (error) {
      console.log(`Something went wrong... ${error}`);
      dispatch({
        error: error,
        type: constants.UPDATE_APPLICATION_CONSENT_FAILURE,
      });
    }
  };

export const createApplicationConsent =
  (userId, shared) => async (dispatch) => {
    const params = {
      authenticity_token: getAuthToken(),
      shared,
    };
    const apiService = new ApiService(
      '/api/v1/job_applications/share_past_job_applications'
    );
    try {
      await apiService.makePutCall(params);
      dispatch(updateShareApplication(true));
      dispatch(handleNewJobApplication());

      saveConsentToLocal(userId);
    } catch (error) {
      dispatch({
        error: error,
        type: constants.UPDATE_APPLICATION_CONSENT_FAILURE,
      });
    }
  };

export const newProjectAccess = async () => {
  const apiService = new ApiService('/api/v1/new_projects');

  try {
    const result = await apiService.makeGetCall();
    return result.data.access;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const openInterviewGuidelines = () => ({
  type: constants.OPEN_INTERVIEW_GUIDELINES,
});

export const getCurrentUser = () => async (dispatch) => {
  try {
    const { data } = await getUserData();
    dispatch(setUserData(data.data));
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const updateInstructorIndustry =
  (instructorId, industry) => async (dispatch) => {
    const apiService = new ApiService(
      `/api/v1/instructor_settings/${instructorId}`
    );
    const params = {
      authenticity_token: getAuthToken(),
      industry,
    };

    try {
      const result = await apiService.makePutCall(params);
      dispatch(setUserIndustry(result.data.industry));
      return result.data.industry;
    } catch (error) {
      console.log(`Something went wrong... ${error}`);
    }
  };

export const setUserData = (data) => ({
  data,
  type: constants.GET_CURRENT_USER_SUCCESS,
});

export const setUserIndustry = (data) => ({
  data,
  type: constants.SET_USER_INDUSTRY,
});

export const setAllIndustry = (data) => ({
  data,
  type: constants.SET_ALL_INDUSTRIES,
});

export const showApplicationConsent = () => ({
  type: constants.SHOW_APPLICATION_CONSENT,
});

export const updateLoadingConsent = (loading) => ({
  data: loading,
  type: constants.UPDATE_LOADING_CONSENT,
});
export const saveConsentToLocal = (userId) => {
  localStorage.setItem(
    'jobApplicationConsent',
    JSON.stringify({ shared: true, userId })
  );
};

export const updateShareApplication = (shared) => ({
  data: shared,
  type: constants.UPDATE_SHARE_JOB_APPLICATION,
});

export const getJobSeekingProfile = () => async (dispatch) => {
  const apiService = new ApiService('/api/v1/job_seeking_profile');

  try {
    const result = await apiService.makeGetCall();
    dispatch({
      data: result.data,
      type: constants.GET_JOB_SEEKING_PROFILE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.GET_JOB_SEEKING_PROFILE_FAILURE,
    });
  }
};

export const updateJobSeekingProfile =
  (jobSeekingProfile) => async (dispatch) => {
    const { id, ...data } = jobSeekingProfile;

    const params = {
      authenticity_token: getAuthToken(),
      job_seeking_profile: keysToSnake(data),
    };
    const apiService = new ApiService(`/api/v1/job_seeking_profiles/${id}`);

    try {
      const result = await apiService.makePutCall(params);
      dispatch({
        data: result.data,
        type: constants.UPDATE_JOB_SEEKING_PROFILE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        error: error,
        type: constants.UPDATE_JOB_SEEKING_PROFILE_FAILURE,
      });
    }
  };

export const updateSocialLinks = async (socials) => {
  const apiService = new ApiService('/api/v1/user_services/create_or_update/');
  let index = 0;
  const services = {};
  socials.forEach((social) => {
    services[index] = social;
    index += 1;
  });
  const params = {
    authenticity_token: getAuthToken(),
    services,
  };

  try {
    const result = await apiService.makePostCall(params);
    return result.data.services;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const setFormData = (data) => ({
  data,
  type: constants.SET_FORM_DATA,
});
