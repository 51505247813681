export const NORTH_AMERICA_JOBS_CATEGORIES = [
  {
    category_name: 'all_job_categories_na',
    contents: [
      {
        link: 'https://www.linkup.com/',
        text: 'Link Up',
      },
      {
        link: 'https://www.careerbuilder.com/',
        text: 'CareerBuilder',
      },
      {
        link: 'https://www.theladders.com/careers/search',
        text: 'The Ladders',
      },
      {
        link: 'https://www.jobisjob.com',
        text: 'JobIsJob',
      },
      {
        link: 'http://www.simplyhired.com',
        text: 'Simply Hired',
      },
      {
        link: 'https://www.politicaljobhunt.com/',
        text: 'Political Jobs',
      },
      {
        link: 'https://www.upwardlyglobal.org/programs/new-comer/',
        text: '​Upwardly Global (for New Immigrants and Refugees)',
      },
      {
        link: 'https://authenticjobs.com/',
        text: 'Authentic Jobs',
      },
      {
        link: 'https://www.wowjobs.us/',
        text: 'WOWjobs',
      },
      {
        link: 'https://lensa.com/',
        text: 'LENSA',
      },
    ],
    title: 'All Job Categories',
  },
  {
    category_name: 'tech_job_categories_na',
    contents: [
      {
        link: 'https://www.jrdevjobs.com/',
        text: 'Jr. Dev Jobs',
      },
      {
        link: 'https://jobs.rubynow.com/',
        text: 'Ruby Now',
      },
      {
        link: 'http://cofolios.com/jobs',
        text: 'Cofolios (Design Internships)',
      },
      {
        link: 'http://www.coroflot.com/jobs',
        text: 'Coroflot (Design)',
      },
      {
        link: 'http://designjobs.aiga.org/',
        text: 'AIGA Design Jobs',
      },
      {
        link: 'https://www.krop.com/creative-jobs/',
        text: 'Krop (Creative Jobs)',
      },
      {
        link: 'https://icrunchdata.com/',
        text: 'Icrunchdata (Data)',
      },
      {
        link: 'https://datajobs.com/',
        text: 'DataJobs',
      },
      {
        link: 'https://www.analytictalent.datasciencecentral.com/jobs/',
        text: 'Analytic Talent (Data)',
      },
      {
        link: 'https://itcareers.apexsystems.com/?_ga=2.119637834.376371240.1645625646-1168608239.1645625646 ',
        text: 'Apex Systems (CF Partner)',
      },
      {
        link: 'https://www.welovesalt.com/usa/',
        text: 'Salt',
      },
      {
        link: 'https://www.eluta.ca/',
        text: 'eluta.ca (Canada)',
      },
      {
        link: 'https://www.krop.com/creative-jobs/',
        text: 'Krop',
      },
      {
        link: 'https://interns.design/?fbclid=IwAR3ycV1s9iEz6ju5MNL57Yco6diD0PX57r83_LW11Sw9ASRKQqg-U6Oppek',
        text: 'interns.design',
      },
      {
        link: 'https://www.creativecircle.com/',
        text: 'Creative Circle (US and Canada)',
      },
      {
        link: 'https://www.themuse.com/',
        text: 'themuse',
      },
      {
        link: 'https://underdog.io/candidates',
        text: 'underdog.io',
      },
      {
        link: 'https://constellationagency.com/careers',
        text: 'Constellation ',
      },
      {
        link: 'https://www.pocitjobs.com/signup/talent',
        text: 'POCIT (People of Color in Tech)',
      },
      {
        link: 'https://producthired.com/',
        text: 'Product Hired',
      },
      {
        link: 'https://www.productmanagercrossing.com/',
        text: 'Product Manager Crossings',
      },
      {
        link: 'https://productmanagerjobboard.com/',
        text: 'Product Manager Job Board',
      },
      {
        link: 'https://www.producthunt.com/jobs?&',
        text: 'Product Hunt',
      },
    ],
    title: 'Tech Job Categories',
  },
  {
    category_name: 'canada_na',
    contents: [
      {
        link: 'https://www.workopolis.com/en/',
        text: 'Workopolis',
      },
      {
        link: 'http://www.eluta.ca/',
        text: 'Eluta',
      },
      {
        link: 'http://www.jobs.ca/',
        text: 'Jobs.ca',
      },
      {
        link: 'http://www.allstarjobs.ca/',
        text: 'AllStarJobs.ca',
      },
      {
        link: 'http://www.indeed.ca/jobs?indpubnum=6644733348841692',
        text: 'Indeed.ca',
      },
      {
        link: 'http://www.monster.ca/',
        text: 'Monster.ca',
      },
      {
        link: 'http://www.wowjobs.ca/',
        text: 'Wow Jobs',
      },
      {
        link: 'http://www.canadajobs.com/',
        text: 'Canada Jobs',
      },
      {
        link: 'http://www.itjobs.ca/en/',
        text: 'IT Jobs',
      },
      {
        link: 'https://github.com/TechnologyMasters/jobs/issues',
        text: 'Tech Masters Github Job Board',
      },
    ],
    title: 'Canada',
  },
  {
    category_name: 'city_specific_na',
    contents: [
      {
        link: 'http://portlandtech.org/',
        text: 'Portland Tech Jobs',
      },
      {
        link: 'https://www.newyorkjobs.com',
        text: 'New York Jobs',
      },
      {
        link: 'https://www.builtinnyc.com/',
        text: 'Built in New York City',
      },
      {
        link: 'https://www.builtinaustin.com/jobs',
        text: 'Built in Austin',
      },
      {
        link: 'https://www.builtinla.com/jobs',
        text: 'Built In LA',
      },
      {
        link: 'https://www.builtinboston.com/',
        text: 'Built in Boston',
      },
      {
        link: 'http://tycheproject.crelate.com/portal',
        text: 'Tyche Project (Web Development)',
      },
      {
        link: 'http://www.builtinchicago.org/jobs#/jobs',
        text: 'Built In Chicago',
      },
      {
        link: 'https://www.builtincolorado.com/',
        text: 'Built in Colorado',
      },
      {
        link: 'https://www.builtinseattle.com/',
        text: 'Built in Seattle',
      },
      {
        link: 'https://www.builtinsf.com/',
        text: 'Built in San Francisco',
      },
    ],
    title: 'City Specific ',
  },
];
