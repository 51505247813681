import styled from 'styled-components';

const ProgressLineWrapper = styled.div`
  margin-bottom: 25px;
  position: relative;

  & > p {
    margin-bottom: 8px;
  }
`;

ProgressLineWrapper.displayName = 'ProgressLineWrapper';

export default ProgressLineWrapper;
