import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styles } from '../styles';

const SepaFields = ({
  cardElementForStripeCheckout,
  countryCode,
  handleChange,
  name,
  stripe,
}) => {
  useEffect(() => {
    const elements = stripe.elements();
    const options = {
      placeholderCountry: countryCode,
      style: styles,
      supportedCountries: ['SEPA'],
    };
    const stripeElement = elements.create('iban', options);
    cardElementForStripeCheckout(stripeElement);
    stripeElement.mount('#stripe-element');
  }, []);

  return (
    <div>
      <div className={`cf-form__group--floating ${(name && name.length > 0) ? 'cf-form__group--frozen' : ''}`}>
        <input
          name="nameOnCard"
          onChange={handleChange}
          value={name}
          type="text"
          id="accountholder-name"
          required="required"
          className="cf-form__input"
        />
        <label className="cf-form__label--floating">Name</label>
        <div className="cf-form__validation"></div>
      </div>
      <div className="cf-form__group--floating">
        <div id="stripe-element"></div>
      </div>
    </div>
  );
};

SepaFields.propTypes = {
  cardElementForStripeCheckout: PropTypes.func.isRequired,
  countryCode: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  stripe: PropTypes.object.isRequired,
};

export default SepaFields;
