import { Footer } from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const NextStep = ({
  openModal,
  nextStepUrl,
  showSurvey,
  attendeeId,
  wsgZone,
  course,
  afa,
}) => (
  <Footer>
    {showSurvey && (
      <a
        className='ds-button__tertiary'
        href={`https://careerfoundryteam.typeform.com/to/dMl65r?attendee_id=${attendeeId}&wsg_zone=${wsgZone}&course=${course}&afa=${afa}`}
        rel='noopener noreferrer'
        target='_blank'
      >
        Take our survey about this task
      </a>
    )}
    {!nextStepUrl ? (
      <button className='ds-btn__primary' onClick={() => openModal(false)}>
        Submit feedback
      </button>
    ) : (
      <a className='ds-btn__primary' href={nextStepUrl}>
        Go to next Exercise
      </a>
    )}
  </Footer>
);

NextStep.propTypes = {
  afa: PropTypes.bool,
  attendeeId: PropTypes.number,
  course: PropTypes.string,
  nextStepUrl: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  showSurvey: PropTypes.bool,
  wsgZone: PropTypes.string,
};

export default NextStep;
