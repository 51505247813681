import React, { Component } from 'react';
import CFModal from '../shared/modal';
import ApiService from '../../services/ApiService';
import UserService from '../../services/UserService';
import UserRoleService from '../../services/UserRoleService';
import { getAuthToken } from '../../utils/getAuthToken';

class AdminUserRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user: null,
      roles: null,
      modalOpen: false,
      authToken: getAuthToken(),
      modalSubmit: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    let userId = parseInt(
      document.querySelector('.admin_user_profile__wrapper').dataset.id
    );

    const userService = new UserService(userId);

    userService.getUserData().then((result) => {
      this.setState({
        user: result,
        loading: false,
      });
    });
  }

  handleEditClick = () => {
    const userRoleService = new UserRoleService();

    userRoleService.getAllUserRoles().then((result) => {
      this.setState({
        roles: result,
        modalOpen: true,
      });
    });
  };

  handleModalClose = () => this.setState({ modalOpen: false });

  renderUsersRoles = () => (
    <div>
      {this.state.user.roles.map((role) => {
        return (
          <label className="cf-course-label cf-role-label" key={role.id}>
            {role.name}
          </label>
        );
      })}
      <a className="cf-small--bold" onClick={() => this.handleEditClick()}>
        Edit User Roles
      </a>
    </div>
  );

  renderModalContent = () => (
    <main>
      <h2 className="cf-text-left">Edit User Roles</h2>
      <form className="cf-form">
        <div className="cf-form__group--floating">
          {this.renderCheckBoxes()}
        </div>
      </form>
      <p className="errorContainer cf-small cf-text-right">
        {this.state.errorMessage}
      </p>
      <a
        onClick={() => this.handleModalClose()}
        className="button--full button--primary-petrol"
      >
        Done
      </a>
    </main>
  );

  renderCheckBoxes = () =>
    this.state.roles.map((role) => {
      return (
        <div className="cf-form__checkbox" key={role.id}>
          <input
            type="checkbox"
            onChange={(event) => this.handleClick(role.id, event)}
            defaultChecked={
              this.getRolesOfUser(this.state.user.roles).includes(role.id)
                ? true
                : false
            }
            val={role.id}
            id={`checkbox_${role.id}`}
            disabled={this.state.modalSubmit ? 'disabled' : false}
          />
          <label htmlFor={`checkbox_${role.id}`}>{role.name}</label>
        </div>
      );
    });

  getRolesOfUser = (roles) => {
    let arr = [];
    roles.forEach((role) => arr.push(role.id));
    return arr;
  };

  updateUserState = (result) => {
    let newUserState = { ...this.state.user };
    newUserState.roles = result;
    return newUserState;
  };

  handleClick = (roleId, event) => {
    this.setState({ modalSubmit: true });

    if (event.target.checked) {
      this.makeCreateRequest(roleId);
    } else {
      this.makeDeleteRequest(roleId);
    }
  };

  makeCreateRequest = (roleId) => {
    const userRoleService = new UserRoleService();

    userRoleService
      .assignUserRole(this.state.user.id, roleId, this.state.authToken)
      .then((result) => {
        this.setState({
          user: this.updateUserState(result),
          modalSubmit: false,
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: `There was an error: ${error}`,
          modalSubmit: false,
        });
      });
  };

  makeDeleteRequest = (roleId) => {
    const userRoleService = new UserRoleService();

    userRoleService
      .deleteUserRole(this.state.user.id, roleId, this.state.authToken)
      .then((result) => {
        this.setState({
          user: this.updateUserState(result),
          modalSubmit: false,
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: `There was an error: ${error}`,
          modalSubmit: false,
        });
      });
  };

  renderLoaderSpinner = () => (
    <div
      className="loading_spinner_wrapper"
      dangerouslySetInnerHTML={{ __html: CF.html.loadingSpinner }}
    />
  );

  render() {
    return (
      <div className="AdminUserRoles cf-content">
        <div>
          <i className="fa fa-user-circle"></i>
          <p className="cf-allcaps">User Roles</p>
        </div>

        {this.state.loading ? (
          this.renderLoaderSpinner()
        ) : (
          <div>
            {this.renderUsersRoles()}

            <CFModal
              appElement=".admin_user__roles"
              isOpen={this.state.modalOpen}
              onRequestClose={this.handleModalClose}
              className="admin_user__roles__modal"
            >
              {this.state.modalOpen ? this.renderModalContent() : ''}
              {this.state.modalSubmit ? this.renderLoaderSpinner() : ''}
            </CFModal>
          </div>
        )}
      </div>
    );
  }
}

export default AdminUserRoles;
