import MediaResourcePreviewContainer from '../../../containers/MediaResourcePreviewContainer';
import MobileAccordionWrapper from './MobileAccordionWrapper';
import MotivationalMessagesContainer from '../../../containers/MotivationalMessagesContainer';
import React from 'react';
import WebinarEventsSection from '../components/WebinarEventsSection';

const ExploreJobsMobileView = () => (
  <MobileAccordionWrapper>
    <MotivationalMessagesContainer />
    <MediaResourcePreviewContainer />
    <WebinarEventsSection isMobile={true} />
  </MobileAccordionWrapper>
);

export default ExploreJobsMobileView;
