import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 0.5rem;

  p:only-of-type {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .accordion__button {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    > div:first-child {
      margin-top: 0;
    }
  }
`;

export { Wrapper };
