import {
  FILTER_INSTRUCTORS,
  INSTRUCTORS_TABS_CHANGE,
  TIMEZONE_TABS_CHANGE,
  TOGGLE_INSTRUCTOR_ASSIGNMENT,
  TOGGLE_ORDER_BY_ACTIVE_STUDENTS,
} from './containers/Instructors/actions';
import {
  SAVE_INSTRUCTOR_ASSIGNMENT_SUCCESS,
  SAVING_INSTRUCTOR_ASSIGNMENT,
  SET_ASSIGNING_STUDENT_ID,
  STUDENTS_TABS_CHANGE,
  UNSET_ASSIGNMENT_IDS,
} from './containers/Students/actions';
import { GET_INITIAL_DATA_SUCCESS } from './containers/App/action';

export const initialState = {
  activeInstructors: 'tutors',
  activeRegion: 'all',
  activeStudents: 'regular',
  assigningMentorId: null,
  assigningStudentId: null,
  assigningStudentInfo: null,
  assigningTutorId: null,
  instructorTags: [],
  instructorsListLoaded: false,
  orderByCurrentStudents: false,
  studentsListLoaded: false,
  tagsToHide: [],
  tagsToShow: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
  case GET_INITIAL_DATA_SUCCESS:
    return {
      ...state,
      ...action.studentList,
      csvDownloadURL: `/en/admin/cohorts/cohort_instructors.csv?cohort[course_id]=${action.studentList.course.id}&cohort[date]=${action.studentList.course.start}`,
      instructorTags: action.instructorList.instructor_tags,
      instructors: action.instructorList,
      instructorsListLoaded: true,
      studentsListLoaded: true,
    };
  case STUDENTS_TABS_CHANGE:
    return {
      ...state,
      activeStudents: action.tabTitle,
    };
  case SET_ASSIGNING_STUDENT_ID:
    return {
      ...state,
      assigningStudentId: action.id,
      assigningStudentInfo: state.students.find(s => s.id === action.id),
    };
  case INSTRUCTORS_TABS_CHANGE:
    return {
      ...state,
      activeInstructors: action.tabTitle,
    };
  case TIMEZONE_TABS_CHANGE:
    return {
      ...state,
      activeRegion: action.tabTitle,
    };
  case UNSET_ASSIGNMENT_IDS:
  case SAVE_INSTRUCTOR_ASSIGNMENT_SUCCESS:
    return {
      ...state,
      assigningMentorId: null,
      assigningStudentId: null,
      assigningStudentInfo: null,
      assigningTutorId: null,
    };
  case SAVING_INSTRUCTOR_ASSIGNMENT:
    return {
      ...state,
      instructorsListLoaded: false,
      studentsListLoaded: false,
    };
  case TOGGLE_INSTRUCTOR_ASSIGNMENT: {
    if (state.activeInstructors === 'tutors') {
      return {
        ...state,
        assigningStudentInfo: {
          ...state.assigningStudentInfo,
          tutor: state.assigningTutorId !== action.instructorId
            ? state.instructors.tutors.find(t => t.user_id === action.instructorId)
            : state.students.find(s => s.id === state.assigningStudentId).tutor,
        },
        assigningTutorId: state.assigningTutorId !== action.instructorId ? action.instructorId : null,
      };
    }
    return {
      ...state,
      assigningMentorId: state.assigningMentorId !== action.instructorId ? action.instructorId : null,
      assigningStudentInfo: {
        ...state.assigningStudentInfo,
        mentor: state.assigningMentorId !== action.instructorId
          ? state.instructors.mentors.find(t => t.user_id === action.instructorId)
          : state.students.find(s => s.id === state.assigningStudentId).mentor,
      },
    };
  }
  case TOGGLE_ORDER_BY_ACTIVE_STUDENTS:
    return {
      ...state,
      orderByCurrentStudents: !state.orderByCurrentStudents,
    };
  case FILTER_INSTRUCTORS:
    return {
      ...state,
      tagsToHide: action.tagsToHide,
      tagsToShow: action.tagsToShow,
    };
  default:
    return state;
  }
}
