import {
  CategoryHeading,
  DocumentLink,
  Label,
  Section,
  SectionHeading,
} from '../styles';
import LoadingSpinner from '../../../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import React from 'react';

const Documents = ({ documents }) =>
  !documents ? (
    <LoadingSpinner />
  ) : (
    <>
      <CategoryHeading className="ds-typography__h4">Documents</CategoryHeading>
      {Object.values(documents.documents).map((section) => (
        <Section key={section.name}>
          <SectionHeading className="ds-typography__h5">
            {section.name}
          </SectionHeading>
          {section.documents.map((document) => (
            <div key={document.key}>
              <Label className="ds-typography__body">{document.label}</Label>
              {document.data && (
                <DocumentLink
                  href={document.data.data}
                  target="_blank"
                  rel="noreferrer"
                  className="ds-typography__body"
                >
                  {document.data.fileName}
                </DocumentLink>
              )}
            </div>
          ))}
        </Section>
      ))}
    </>
  );

Documents.propTypes = {
  documents: PropTypes.shape({
    documents: PropTypes.shape({
      documents: PropTypes.shape({
        data: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            data: PropTypes.string,
            fileName: PropTypes.string,
            fileSize: PropTypes.number,
          }),
        ]),
        key: PropTypes.string,
        label: PropTypes.string,
        required: PropTypes.bool,
      }),
      name: PropTypes.string,
      tooltip: PropTypes.string,
    }),
    id: PropTypes.number,
    state: PropTypes.string,
  }),
};

export default Documents;
