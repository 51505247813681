import React from 'react';

const WhatIsRemoteWork = () => (
  <>
    <p className='ds-typography__body break'>
      <strong>Remote work, </strong>also referred to as “virtual,”
      “telecommuting,” and “work from home (WFH)” is the ability to work from
      outside a static corporate office. Whether or not this is a full-time
      arrangement where you can spend 100% of your time outside the office or
      the employer requires an occasional in-person gathering varies by company.
    </p>
    <p className='ds-typography__body break'>
      Alternatively, a <strong>hybrid</strong> role requires at least one day
      per week be spent in the corporate office getting your work done while the
      remainder of the week can be remote. This allows for more{' '}
      <strong>flexible work schedules</strong> which can be helpful when needing
      to adapt to family and pet care situations. For more information, check
      out CareerFoundry's article{' '}
      <a
        onClick={() =>
          window.open(
            'https://careerfoundry.com/en/blog/career-change/what-does-remote-work-mean/'
          )
        }
      >
        What does Remote Work Mean (vs WFH vs Hybrid Work)?
      </a>
    </p>
  </>
);

export default WhatIsRemoteWork;
