import Button from '../../../EmployerConnector/modals/shared/Button';
import PropTypes from 'prop-types';
import React from 'react';
import StyledCallCard from './styles/StyledCallCard';
import StyledProfileImage from './styles/StyledProfileImage';
import StyledTextWrapper from './styles/StyledTextWrapper';
import moment from 'moment';

const CallCard = ({ calendarEntry, onClick }) => {
  const startTimeMoment = moment(calendarEntry.startTime);
  const timeUtcOffset = startTimeMoment.format('Z');
  let currentZoneTime = moment().utcOffset(timeUtcOffset);

  const isPastCallTime = currentZoneTime > startTimeMoment;
  const daysDiff = currentZoneTime.diff(startTimeMoment, 'days');

  const profileUrl = calendarEntry.student?.links[0]?.href;

  const handleClick = () => {
    currentZoneTime = moment().utcOffset(timeUtcOffset);
    const minutesDiff = currentZoneTime.diff(startTimeMoment, 'minutes');
    const canSubmitNote = minutesDiff >= 0;

    onClick(calendarEntry.id, canSubmitNote);
  };

  return (
    <StyledCallCard>
      <div>
        <StyledProfileImage>
          <img
            src='https://images.careerfoundry.com/public/framework/head.svg'
            alt='profile-picture'
            className='profile-image img-circle'
          />
        </StyledProfileImage>
        <StyledTextWrapper>
          <a
            className='ds-typography__body ds-typography__anchor'
            href={profileUrl || ''}
          >
            {calendarEntry.attendeeName}
          </a>
          <p
            className={`ds-typography__small ${
              daysDiff >= 2 ? 'red-text' : ''
            }`}
          >
            {isPastCallTime ? 'Call ended at' : 'Call is scheduled for'}{' '}
            <span>{startTimeMoment.format('HH:mm')}</span>, (timezone:{' '}
            <span>{timeUtcOffset}</span>) on{' '}
            <span>{startTimeMoment.format('ddd, D MMM YYYY')}</span>
          </p>
        </StyledTextWrapper>
      </div>
      <div className='button-section'>
        <Button
          className='ds-button__primary'
          disabled={false}
          label='Add your call notes'
          loading={false}
          onClick={handleClick}
        />
      </div>
    </StyledCallCard>
  );
};

CallCard.propTypes = {
  calendarEntry: PropTypes.shape({
    attendeeName: PropTypes.string,
    id: PropTypes.number,
    startTime: PropTypes.string,
    student: PropTypes.shape({
      links: PropTypes.array,
    }),
  }),
  onClick: PropTypes.func.isRequired,
};

export default CallCard;
