import Answer from '../../../components/AnswerHistoryCard/Answer/index';
import { connect } from 'react-redux';
import { evaluatePreviousResponses } from '../../../actions';
import { showStudentResponse } from '../../../../UserProfile/actions';

export const mapStateToProps = ({
  userProfile: {
    isMentorPage,
  },
}) => ({
  isMentorPage,
});

export const mapDispatchToProps = dispatch => ({
  evaluatePreviousResponses: (answer) => dispatch(evaluatePreviousResponses(answer)),
  showStudentResponse: (answer) => dispatch(showStudentResponse(answer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Answer);
