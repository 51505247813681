import styled from 'styled-components';

const StyledVideoContainer = styled.div`
  text-align: center;
  @media (max-width: 1071px) {
    iframe {
      width: 500px;
      height: 400px;
    }
  }
  @media (min-width: 1071px) {
    iframe {
      width: 720px;
      height: 480px;
    }
  }
`;

StyledVideoContainer.displayName = 'StyledVideoContainer';

export default StyledVideoContainer;
