import React from 'react';
import { StatusContainer, SectionLink, TabName, List } from './styles';
import { renderStatus } from '../../helpers';
import SubSection from './subSection';
import { ISection } from '../../interfaces';

export interface TabProps {
  selectedTab: string;
  selectedSubTab: string;
  setSelectedTab: (tab: string) => void;
  setSelectedSubTab: (tab: string) => void;
  tabKey: string;
  tabValue: ISection;
  isUnLocked: boolean;
}

const Section: React.FC<TabProps> = ({
  selectedTab,
  selectedSubTab,
  setSelectedTab,
  setSelectedSubTab,
  tabKey,
  tabValue,
  isUnLocked,
}) => {
  return (
    <div>
      <SectionLink
        key={tabKey}
        active={selectedTab === tabKey}
        isUnLocked={isUnLocked}
        onClick={() =>
          tabValue.active && tabKey !== 'signedUp'
            ? setSelectedTab(tabKey)
            : null
        }
      >
        <TabName
          isUnLocked={isUnLocked}
          active={selectedTab === tabKey}
          className='ds-text__body--large'
        >
          {tabValue.name}
        </TabName>
        <StatusContainer>
          {renderStatus(tabValue.status, tabValue.active)}
        </StatusContainer>
      </SectionLink>
      {tabValue.steps && (
        <List>
          {Object.entries(tabValue.steps).map((tab: any) => {
            const subTabKey = tab[0];
            const subTabValue = tab[1];
            return (
              <SubSection
                key={subTabKey}
                setSelectedSubTab={setSelectedSubTab}
                selectedSubTab={selectedSubTab}
                subTabKey={subTabKey}
                subTabValue={subTabValue}
                isUnLocked={subTabValue.active}
              />
            );
          })}
        </List>
      )}
    </div>
  );
};

export default Section;
