import styled from 'styled-components';

const MentorNoteFormStyles = styled.form`
  input:first-of-type,
  textarea {
    width: 100%;
    border: solid 2px #a7b1b9;
    border-radius: 4px;
    margin-bottom: 22px;
    font-size: 16px;
    padding: 10px;
  }

  input:first-of-type {
    height: 40px;
  }

  textarea {
    height: 160px;
  }

  .ds-slider {
    margin-bottom: 28px;

    & + label {
      margin-left: 10px;
      font-size: 16px;
    }
  }

  .note-categories {
    text-align: justify;
    -ms-text-justify: distribute-all-lines;
    text-justify: distribute-all-lines;
    width: 100%;
  }

  .category-checkbox {
    width: 25%;
    vertical-align: top;
    display: inline-block;
  }

  .ds-slider input:checked + span {
    background-color: var(--ds-color__indigo);
  }

  & > div:last-of-type {
    text-align: right;
    button {
      margin-left: 24px;
    }
  }

  @media (max-width: 767px) {
    .ds-slider + label {
      font-size: 12px;
    }
  }

  @media (max-width: 810px) {
    .category-checkbox {
      width: 50%;
      vertical-align: top;
      display: inline-block;
      *display: inline;
    }
  }

  @media (max-width: 479px) {
    textarea {
      height: 332px;
    }

    & > div:first-of-type {
      display: flex;

      & > label {
        flex-basis: 70%;
      }
    }

    & > div:last-of-type {
      text-align: center;

      button {
        width: 40%;
      }
    }
  }
`;

MentorNoteFormStyles.displayName = 'MentorNoteFormStyles';

export default MentorNoteFormStyles;
