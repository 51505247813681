import { Title, Wrapper } from './styles';
import Course from '../Course';
import Program from '../Program';
import PropTypes from 'prop-types';
import React from 'react';
import { isProgram } from '../../helpers';
import { noop } from 'lodash';

export const renderCourses = ({
  courses = [],
  shouldExpandProgram = true,
  isStudent,
}) =>
  courses.map((course, index) => (
    <div key={course.id || index}>
      {isProgram({ course }) ? (
        <Program
          course={course}
          defaultShouldShowCourses={shouldExpandProgram}
          isStudent={isStudent}
        />
      ) : (
        <Course {...course} isStudent={isStudent} />
      )}
    </div>
  ));

const SubNav = ({
  courses = [],
  setShouldShowSubNav = noop,
  shouldShowSubNav = false,
  isStudent,
}) => {
  if (!shouldShowSubNav) {
    return null;
  }

  return (
    <Wrapper onMouseLeave={() => setShouldShowSubNav(false)}>
      <Title className="ds-typography__h4">My courses</Title>
      {renderCourses({ courses, isStudent })}
    </Wrapper>
  );
};

SubNav.propTypes = {
  courses: PropTypes.arrayOf(Program.propTypes.course),
  isStudent: PropTypes.bool,
  setShouldShowSubNav: PropTypes.func,
  shouldShowSubNav: PropTypes.bool,
};

export default SubNav;
