import { getJobSeekingProfiles, setFilterParams } from '../../actions';
import JobProfileFilters from '../../components/modal/JobProfileFilters';
import { connect } from 'react-redux';
import { getAllIndustries } from '../../../CareerSupportCenter/actions';

export const mapStateToProps = ({
  employerDashboard: {
    profileFilterParams,
  },
  careerSupportCenter: {
    industries,
  },
}) => ({
  industries,
  profileFilterParams,
});

export const mapDispatchToProps = dispatch => ({
  getAllIndustries: () => dispatch(getAllIndustries()),
  getJobSeekingProfiles: (page, params) => dispatch(getJobSeekingProfiles(page, params)),
  setFilterParams: (params) => dispatch(setFilterParams(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobProfileFilters);
