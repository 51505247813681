import { connect } from 'react-redux';
import ProfileSurvey from '../../../components/Tabs/ProfileSurvey';
import { IUpdateAdmissionParams, IAdmissionState } from '../../../interfaces';
import { updateAdmission } from '../../../actions';

export const mapStateToProps = ({
  afaAdmissions: { tabData, admission },
}: IAdmissionState) => ({
  tabData,
  admission,
});

export const mapDispatchToProps = (dispatch: any) => ({
  updateAdmission: (params: IUpdateAdmissionParams, tab: string) =>
    dispatch(updateAdmission(params, tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSurvey);
