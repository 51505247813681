class ValidationService {

  getErrorType(error){
    if(error == "exp_year" || error == "exp_month"){
      return "expDate"
    }else if(error == "number"){
      return "cardNumber"
    }else if(error == "cvc"){
      return "cvc"
    }else if(error == "invalid_vat"){
      return "vat"
    }else if(error == "invalid_password"){
      return "password"
    }else if(error == "invalid_email"){
      return "email"
    }else{
      return null
    }
  }
}

export default ValidationService
