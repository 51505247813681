import { editCallNotes, handleCloseModal } from '../../../MentorCallNotes/action';
import EditNoteModal from '../../components/Modal/EditNoteModal';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  mentorCallNotes: {
    currentCalendarEntry,
    currentCallId,
    flashMessage,
    mentorNoteForm,
    submitLoading,
    showFlash,
  },
}) => ({
  currentCalendarEntry,
  currentCallId,
  flashMessage,
  mentorNoteForm,
  showFlash,
  submitLoading,
});

export const mapDispatchToProps = dispatch => ({
  handleCloseModal: () => dispatch(handleCloseModal()),
  onSubmit: (callId, data) => dispatch(editCallNotes(callId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditNoteModal);
