import FAQ from '../../components/FAQ';
import React from 'react';
import StyledFAQ from '../../components/FAQ/styles/StyledFAQ';

const FAQModal = () => {
  return (
    <StyledFAQ>
      <FAQ />
    </StyledFAQ>
  );
};

export default FAQModal;
