import AttachmentPreview from '../../Uploader/components/AttachmentPreview';
import styled from 'styled-components';

const StyledPreviewSmall = styled(AttachmentPreview)`
  margin: 0;
  margin-right: 12px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0;
  min-width: unset;
  width: 44px;
  height: 37px;
  padding: 0;
  border: solid 1px #d8d8d8;
  i {
    color: var(--ds-color__indigo);
    background: #fff;
    font-size: 18px !important;
    margin: 0;
  }
  .fa-times-circle {
    color: #a9a9a9;
    font-size: 15px !important;
  }
  p {
    font-size: 9px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: auto;
    color: var(--ds-color__indigo);
  }

  ${({ isViewOnly }) => isViewOnly && `
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
  `}
`;

StyledPreviewSmall.displayName = 'StyledPreviewSmall';

export default StyledPreviewSmall;
