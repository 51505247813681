import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

TitleWrapper.displayName = 'TitleWrapper';

export default TitleWrapper;
