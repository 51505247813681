import styled from 'styled-components';

const StyledProgressCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e8ebed;
  margin: 0 12px;

  ${({ isActive }) =>
    isActive &&
    `background-color: var(--ds-color__grey--400);
     width: 12px;
     height: 12px;
    `
}
`;

StyledProgressCircle.displayName = 'StyledProgressCircle';

export default StyledProgressCircle;
