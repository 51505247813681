import Border from './styles/Border';
import Label from './styles/Label';
import Mentor from './styles/Mentor';
import MentorWrapper from './styles/MentorWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import SendButton from './SendButton';

const Mentors = ({ currentUserId, mentors, studentIsViewing }) => (
  <>
    <p className='ds-typography__lead--bold'>Mentors</p>
    <MentorWrapper>
      {mentors.map((mentor) => (
        <Mentor key={mentor.id}>
          <div>
            <img src={mentor.imageUrl} />
            <p className='ds-typography__small'>
              {mentor.callsLeft && `${mentor.callsLeft} calls left`}
            </p>
          </div>
          <div>
            <p className='ds-typography__body--bold'>{mentor.name}</p>
            <Label>{mentor.label}</Label>
            {currentUserId && studentIsViewing && (
              <SendButton mentorId={mentor.id} userId={currentUserId} />
            )}
          </div>
        </Mentor>
      ))}
    </MentorWrapper>
    <Border />
  </>
);

Mentors.propTypes = {
  currentUserId: PropTypes.number,
  mentors: PropTypes.arrayOf(
    PropTypes.shape({
      callsLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      id: PropTypes.number,
      imageUrl: PropTypes.string,
      label: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  studentIsViewing: PropTypes.bool,
};

export default Mentors;
