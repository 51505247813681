import CallReviewStyles from './styles/CallReviewStyles';
import PaginationControls from '../../../shared/components/Pagination';
import PropTypes from 'prop-types';
import React from 'react';

const CallReviewsTab = ({
  callReviewsActivePage: activePage,
  callReviews,
  callReviewsLength,
  setActivePage,
}) => {
  const ITEMS_COUNT_PER_PAGE = 10;
  const handlePageChange = (pageNumber) => setActivePage(pageNumber);
  const pagination = () =>
    callReviews.slice(
      (activePage - 1) * ITEMS_COUNT_PER_PAGE,
      activePage * ITEMS_COUNT_PER_PAGE
    );

  return (
    <CallReviewStyles>
      {callReviewsLength ? (
        <>
          <div className='table-responsive'>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>Call date</th>
                  <th>Name</th>
                  <th>Helpful</th>
                  <th>Encouraging</th>
                  <th>Average</th>
                  <th>Program / Course</th>
                </tr>
              </thead>
              <tbody>
                {pagination().map((review, i) => (
                  <tr key={`${review.date}_${i}`}>
                    <td>{review.date}</td>
                    <td>{review.mentor}</td>
                    <td>{review.helpful}</td>
                    <td>{review.encouraging}</td>
                    <td>{review.average}</td>
                    <td>{review.programName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {callReviews.length > ITEMS_COUNT_PER_PAGE && (
            <PaginationControls
              activePage={activePage}
              itemsCountPerPage={ITEMS_COUNT_PER_PAGE}
              totalItemsCount={callReviews.length}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          )}
        </>
      ) : (
        <p className='ds-typography__body text-center'>
          There are no call reviews here yet.
        </p>
      )}
    </CallReviewStyles>
  );
};

CallReviewsTab.propTypes = {
  callReviews: PropTypes.arrayOf(
    PropTypes.shape({
      average: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      encouraging: PropTypes.string.isRequired,
      helpful: PropTypes.string.isRequired,
      mentor: PropTypes.string.isRequired,
      programName: PropTypes.string.isRequired,
    })
  ).isRequired,
  callReviewsActivePage: PropTypes.number.isRequired,
  callReviewsLength: PropTypes.number.isRequired,
  setActivePage: PropTypes.func.isRequired,
};

export default CallReviewsTab;
