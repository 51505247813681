const StripeHelpers = {
  loadStripeLib: function (loader) {
    return new Promise((resolve, reject) => {
      if (loader) {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/';
        script.setAttribute('data-ot-ignore', '');
        script.onload = function () {
          const stripe = Stripe($('meta[name="stripe-key"]').attr('content'));
          resolve(stripe);
        };
        script.onerror = function () {
          reject(new Error('Error loading stripe'));
        };
        document.head.appendChild(script);
      } else {
        reject(new Error('Error loading stripe'));
      }
    });
  },
};

export default StripeHelpers;
