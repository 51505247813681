import IntroductionPage from './IntroductionPage';
import PrivacyPolicyContainer from '../../containers/PrivacyPolicyContainer';
import PropTypes from 'prop-types';
import React from 'react';
import TermsAndConditionPageContainer from '../../containers/TermsAndConditionPageContainer';

const EmployerOnboarding = ({
  step,
  setStep,
}) => {
  return (
    <>
      {
        {
          introduction: (
            <IntroductionPage onClickNext={() => setStep('termsAndCondition')} />
          ),
          privacyPolicy: <PrivacyPolicyContainer />,
          termsAndCondition: (
            <TermsAndConditionPageContainer onClickNext={() => setStep('privacyPolicy')} />
          ),
        }[step]
      }
    </>
  );
};

EmployerOnboarding.propTypes = {
  setStep: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
};

export default EmployerOnboarding;
