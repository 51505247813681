import styled from 'styled-components';

const Button = styled.button`
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;

  .cancelable {
    font-weight: 900;
    color: var(--ds-color_error);
    cursor: pointer;

    &:hover {
      color: rgba(250, 99, 87, 0.75) !important;
    }
  }

  .not-cancelable {
    font-weight: 900;
    color: var(--ds-palette__indigo-40);
  }

  .type-custom span {
    word-wrap: break-word;
  }
`;

TableWrapper.displayName = 'TableWrapper';
Button.displayName = 'Button';

export { Button, TableWrapper };
