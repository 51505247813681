import React, { useEffect, useRef, useState } from 'react';
import CSCHeader from '../CSCHeader';
import CSCSections from './CSCSections';
import ConfidenceSurveyBanner from '../../../shared/components/ConfidenceSurveyBanner';
import GuidedTour from '../../../shared/components/GuidedTour';
import PropTypes from 'prop-types';
import SideNavContainer from '../../containers/SideNavContainer';
import StyledCareerSupportCenter from '../App/styles/StyledCareerSupportCenter';
import StyledGrid from './styles/StyledGrid';
import { getGuidedTourSteps } from '../../actions';
import { sections } from '../../constants';

const CSCMainDashboard = ({ attendeeId, isMobile, showProjectCard, showProjectMenu }) => {
  const cookieName = 'dismissedCSCTour';
  const [activeNav, setActiveNav] = useState('application-tracker');
  const [activeSection, setActiveSection] = useState({ id: 'application-tracker', ratio: 0 });

  const scrollRef = useRef();

  const sectionRefs = sections.reduce((acc, value) => {
    acc[value] = {
      id: value,
      ratio: 0,
      ref: useRef(),
    };

    return acc;
  }, {});

  const callback = entries => {
    entries.forEach(
      entry =>
        (sectionRefs[entry.target.id].ratio =
          entry.intersectionRatio),
    );

    const activeRef = Object.values(sectionRefs).reduce(
      (acc, value) => (value.ratio > acc.ratio ? value : acc),
      activeSection,
    );

    if (activeRef.ratio > activeSection.ratio) {
      setActiveSection(activeRef);
    }
  };

  const observer = new IntersectionObserver(callback, {
    root: scrollRef.current,
    threshold: new Array(101).fill(0).map((v, i) => i * 0.01),
  });

  useEffect(() => {
    if (!isMobile) {
      Object.values(sectionRefs).forEach(value => {
        if (value.ref.current) {
          return observer.observe(value.ref.current);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setActiveNav(activeSection.id);
    }
  }, [activeSection]);

  const handleClick = (id) => {
    const element = document.getElementById(id);
    const position = element.getBoundingClientRect();
    const top = id === 'application-tracker' ? 0 : window.pageYOffset + position.top - 100;
    window.scroll({ behavior: 'auto', top });

    setActiveNav(id);
  };

  return (
    <StyledCareerSupportCenter>
      <StyledGrid className="ds-grid__container">
        <div className="ds-grid__row">
          <div className="ds-grid__col-lg-6 ds-grid__col-xs-12">
            <CSCHeader />
          </div>
          <div className="ds-grid__col-lg-6 ds-grid__col-xs-12">
            <ConfidenceSurveyBanner
              attendeeId={attendeeId}
              className="top-margin"
            />
          </div>
        </div>
        <div className="ds-grid__row">
          <div className="ds-grid__col-lg-3 ds-grid__col-xs-12 menu-div">
            <SideNavContainer
              showProjectMenu={showProjectMenu}
              onClickNav={handleClick}
              activeNav={activeNav}
            />
          </div>
          <div className="ds-grid__col-lg-9 ds-grid__col-xs-12 section-div" ref={scrollRef}>
            <CSCSections
              showProjectCard={showProjectCard}
              attendeeId={attendeeId}
              isMobile={isMobile}
              sectionRefs={sectionRefs}
            />
          </div>
        </div>
      </StyledGrid>
      <GuidedTour
        getGuidedTourSteps={getGuidedTourSteps}
        cookieName={cookieName}
        isMobile={isMobile}
      />
    </StyledCareerSupportCenter>
  );
};

CSCMainDashboard.propTypes = {
  attendeeId: PropTypes.number,
  isMobile: PropTypes.bool,
  showProjectCard: PropTypes.bool,
  showProjectMenu: PropTypes.bool,
};

export default CSCMainDashboard;
