import ApiService from "./ApiService";

class UserRoleService {

  getAllUserRoles() {
    const apiService = new ApiService("/api/v1/admin/roles.json");

    return apiService.makeGetCall().then(result => {
      return result.data.data;
    })
    .catch(error => {
      return error;
    })
  };

  assignUserRole(userId, roleId, authToken) {
    let params = {
      role_id: roleId,
      authenticity_token: authToken
    };

    const apiService = new ApiService(`/api/v1/admin/users/${userId}/roles.json`);

    return apiService.makePostCall(params).then(result => {
      return result.data.data.roles;
    })
    .catch(error => {
      return error;
    })
  };

  deleteUserRole(userId, roleId, authToken) {
    let params = {
      data: {
        authenticity_token: authToken
      }
    };

    const apiService = new ApiService(`/api/v1/admin/users/${userId}/roles/${roleId}.json`);

    return apiService.makeDeleteCall(params).then(result => {
      return result.data.data.roles;
    })
    .catch(error => {
      return error;
    })
  };
};

export default UserRoleService;
