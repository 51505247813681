import React, { useState } from 'react';
import AdminProfessionalStatementViewContainer from '../../../admin/EmployerConnector/Students/containers/ProfessionalStatementView';
import AdminSocialsViewContainer from '../../../admin/EmployerConnector/Students/containers/SocialsViewsContainer';
import AdminWorkPreferencesViewContainer from '../../../admin/EmployerConnector/Students/containers/WorkPreferencesViewContainer';
import PersonalData from './PersonalData';
import ProfessionalStatementViewContainer from '../../container/ProfessionalStatementViewContainer';
import ProfileButtonsContainer from '../../container/ProfileButtonsContainer';
import ProfileSection from './ProfileSection';
import ProfileSeparator from './styles/ProfileSeparator';
import PropTypes from 'prop-types';
import SocialsViewContainer from '../../container/SocialsViewContainer';
import StyledHeaderWrapper from './styles/StyledHeaderWrapper';
import StyledShowModal from './styles/StyledShowModal';
import WorkPreferencesViewContainer from '../../container/WorkPreferencesViewContainer';

const JobSeekingProfileShow = ({ studentViewing = false }) => {
  const [isEditView, setViewType] = useState(false);

  return (
    <StyledShowModal>
      <div className="scroll">
        <div className="ds-grid__row">
          <div className="ds-grid__col-sm-12">
            <StyledHeaderWrapper>
              <h5 className="ds-typography__h5 text-left">{studentViewing ? 'Your profile' : 'Student Application Package'}</h5>
              {studentViewing && <ProfileButtonsContainer
                isEditView={isEditView}
                handleToggle={() => setViewType(!isEditView)}
                setViewType={setViewType}
              />
              }
            </StyledHeaderWrapper>
            <ProfileSeparator />
            <div className="ds-grid__row">
              <div className="ds-grid__col-xs-6 row-lg">
                <ProfileSection
                  header="Personal Data"
                  childComponent={<PersonalData studentViewing={studentViewing}/>}
                />
                <ProfileSeparator />
                <ProfileSection
                  header={`Professional Statement${isEditView ? '*' : ''}`}
                  childComponent={
                    studentViewing
                      ? <ProfessionalStatementViewContainer
                        isEditView={isEditView}
                        studentViewing={studentViewing}
                      />
                      : <AdminProfessionalStatementViewContainer isEditView={false}/>
                  }
                />
              </div>
              <div className="ds-grid__col-xs-6 row-md">
                <hr />
                <ProfileSection
                  header="Work Preferences"
                  headerClassName="mr-33"
                  childComponent={
                    studentViewing
                      ? <WorkPreferencesViewContainer isEditView={isEditView} />
                      : <AdminWorkPreferencesViewContainer isEditView={false}/>
                  }
                />
                <ProfileSeparator />
                <ProfileSection
                  header="Application package and social media accounts"
                  headerClassName="mr-33 mb-25"
                  childComponent={
                    studentViewing
                      ? <SocialsViewContainer isEditView={isEditView} />
                      : <AdminSocialsViewContainer isEditView={false} />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledShowModal>
  );
};

JobSeekingProfileShow.propTypes = {
  studentViewing: PropTypes.bool.isRequired,
};

export default JobSeekingProfileShow;
