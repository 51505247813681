export enum Actions {
  HANDLE_DELETE_SUCCESS = 'offPlatformServices.HANDLE_DELETE_SUCCESS',
  HANDLE_EDIT = 'offPlatformServices.HANDLE_EDIT',
  HANDLE_MODAL_CLOSE = 'offPlatformServices.HANDLE_MODAL_CLOSE',
  HANDLE_SUBMIT_SUCCESS = 'offPlatformServices.HANDLE_SUBMIT_SUCCESS',
  HANDLE_SUBMIT_ERROR = 'offPlatformServices.HANDLE_SUBMIT_ERROR',
  HANDLE_UPDATE_SUCCESS = 'offPlatformServices.HANDLE_UPDATE_SUCCESS',
  HANDLE_UPDATE_ERROR = 'offPlatformServices.HANDLE_UPDATE_ERROR',
  GET_SERVICES_SUCCESS = 'offPlatformServices.GET_SERVICES_SUCCESS',
  GET_STUDENTS_AND_SERVICES_SUCCESS = 'offPlatformServices.GET_STUDENTS_AND_SERVICES_SUCCESS',
  SET_MODAL_ACTION = 'offPlatformServices.SET_MODAL_ACTION',
}
