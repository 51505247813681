import { errorMessage, getFileData } from '../helpers';
import { EU_COUNTRIES } from '../../../../../../constants';
import Input from '../../../../../shared/components/Input';
import { InputWrapper } from '../styles';
import React from 'react';
import Select from '../../../../../shared/components/Select';
import UploadButton from '../../../../../shared/components/UploadButton';
import UploadLink from '../../UploadLink';
import { isEmpty } from 'lodash';

export const taxInformation = (region) => {
  switch (region) {
    case 'DE':
      return 'German residents: Enter your tax number and/or VAT ID, then upload tax and/or VAT letter(s). This depends on your tax status in Germany. If you are uncertain, please discuss with your tax advisor. Tax number (‘Umsatzsteuer’ or ‘Steuernummer’) format: 2-3 digits / 3-4 digits / 4-5 digits. Example: 123/456/78900. VAT ID (‘Umsatzsteuer Identifikationsnummer’) format: DE123456789.';
    case 'GB':
      return 'Please provide your UTR number and/or VAT ID, and upload a UTR and/or VAT ID letter. UTR number format: 1234567890. VAT ID format: GB123456789.';
    case 'EU':
      return 'EU residents: Please provide your intra-community VAT number.';
    case 'other':
      return 'If you reside outside of the EU & the UK it is optional for you to provide your tax number. ';
    default:
      return 'Please first select a billing country.';
  }
};

export const findRegion = (country) => {
  switch (country) {
    case 'DE':
      return 'DE';
    case 'GB':
      return 'GB';
    case undefined:
      return '';
    case '':
      return '';
    default:
      return EU_COUNTRIES.includes(country) ? 'EU' : 'other';
  }
};

export const optionForTax = (
  region,
  personalData,
  handleSave,
  handleTaxChange,
  taxInputs,
  removeTaxInput,
  formErrors,
  handleFileUpload,
  removeFile,
  taxDocumentsRequired
) => {
  switch (region) {
    case 'DE':
      return selectOptions(
        personalData,
        handleSave,
        handleTaxChange,
        taxInputs,
        removeTaxInput,
        region,
        formErrors,
        handleFileUpload,
        removeFile,
        taxDocumentsRequired
      );
    case 'GB':
      return selectOptions(
        personalData,
        handleSave,
        handleTaxChange,
        taxInputs,
        removeTaxInput,
        region,
        formErrors,
        handleFileUpload,
        removeFile,
        taxDocumentsRequired
      );
    case 'EU':
      return EUTaxOptions(
        personalData,
        handleSave,
        formErrors,
        handleFileUpload,
        removeFile,
        taxDocumentsRequired
      );
    case 'other':
      return nonEUTaxOption(personalData, handleSave);
    default:
      return defaultTaxOptions();
  }
};

const defaultTaxOptions = () => (
  <InputWrapper>
    <Select>
      <option>Choose*</option>
      <option>Please first select your billing country.</option>
    </Select>
  </InputWrapper>
);

const EUTaxOptions = (
  personalData,
  handleSave,
  formErrors,
  handleFileUpload,
  removeFile,
  taxDocumentsRequired
) => (
  <>
    <InputWrapper>
      <Input
        type='text'
        name='vatId'
        placeholder='Intra-community VAT number'
        required={true}
        value={personalData.vatId}
        onChange={(value) => handleSave('vatId', value)}
        error={errorMessage(formErrors, 'vatId')}
        pattern='.{9,14}'
      />
      {isEmpty(personalData.vatDocument.data) && (
        <UploadButton
          handleChange={(ev) =>
            handleFileUpload(getFileData(ev), 'vatDocument')
          }
          required={taxDocumentsRequired}
        />
      )}
    </InputWrapper>
    {!isEmpty(personalData.vatDocument.data) && (
      <UploadLink
        data={personalData.vatDocument}
        removeFile={() => removeFile('vatDocument')}
        error={errorMessage(formErrors, 'vatDocument')}
      />
    )}
  </>
);

const taxId = (
  personalData,
  handleSave,
  formErrors,
  region,
  removeTaxInput,
  handleFileUpload,
  removeFile,
  taxDocumentsRequired
) => (
  <React.Fragment key='taxId'>
    <InputWrapper>
      <Input
        type='text'
        name='taxId'
        value={personalData.taxId}
        placeholder={`${region === 'DE' ? 'Tax' : 'UTR'} number`}
        required={true}
        onChange={(value) => handleSave('taxId', value)}
        error={errorMessage(formErrors, 'taxId')}
        pattern={region === 'DE' ? '^\\d{2,3}/\\d{3,4}/\\d{4,5}$' : '[0-9]{10}'}
      />
      <div className='upload-wrapper'>
        {isEmpty(personalData.taxDocument.data) && (
          <UploadButton
            handleChange={(ev) =>
              handleFileUpload(getFileData(ev), 'taxDocument')
            }
            required={taxDocumentsRequired}
          />
        )}
        <i
          className='ds-icon__close-thin remove-tax-input'
          onClick={() => removeTaxInput('tax')}
        ></i>
      </div>
    </InputWrapper>
    {!isEmpty(personalData.taxDocument.data) && (
      <UploadLink
        data={personalData.taxDocument}
        removeFile={() => removeFile('taxDocument')}
        error={errorMessage(formErrors, 'taxDocument')}
      />
    )}
  </React.Fragment>
);

const vatId = (
  personalData,
  handleSave,
  formErrors,
  region,
  removeTaxInput,
  handleFileUpload,
  removeFile,
  taxDocumentsRequired
) => (
  <React.Fragment key='vatId'>
    <InputWrapper>
      <Input
        type='text'
        name='vatId'
        value={personalData.vatId}
        placeholder={`VAT ${region === 'DE' ? 'ID' : 'number'}`}
        required={true}
        onChange={(value) => handleSave('vatId', value)}
        error={errorMessage(formErrors, 'vatId')}
        pattern={
          region === 'DE'
            ? '^(DE)[0-9]{9}$'
            : '^(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$'
        }
      />
      <div className='upload-wrapper'>
        {isEmpty(personalData.vatDocument.data) && (
          <UploadButton
            handleChange={(ev) =>
              handleFileUpload(getFileData(ev), 'vatDocument')
            }
            required={taxDocumentsRequired}
          />
        )}
        <i
          className='ds-icon__close-thin remove-tax-input'
          onClick={() => removeTaxInput('vat')}
        ></i>
      </div>
    </InputWrapper>
    {!isEmpty(personalData.vatDocument.data) && (
      <UploadLink
        data={personalData.vatDocument}
        removeFile={() => removeFile('vatDocument')}
        error={errorMessage(formErrors, 'vatDocument')}
      />
    )}
  </React.Fragment>
);

const renderTaxInput = (
  el,
  personalData,
  handleSave,
  formErrors,
  region,
  removeTaxInput,
  handleFileUpload,
  removeFile,
  taxDocumentsRequired
) => {
  if (el === 'taxId') {
    return taxId(
      personalData,
      handleSave,
      formErrors,
      region,
      removeTaxInput,
      handleFileUpload,
      removeFile,
      taxDocumentsRequired
    );
  } else {
    return vatId(
      personalData,
      handleSave,
      formErrors,
      region,
      removeTaxInput,
      handleFileUpload,
      removeFile,
      taxDocumentsRequired
    );
  }
};

const selectOptions = (
  personalData,
  handleSave,
  handleTaxChange,
  taxInputs,
  removeTaxInput,
  region,
  formErrors,
  handleFileUpload,
  removeFile,
  taxDocumentsRequired
) => {
  const required = taxInputs.length === 0;
  const allSelected = taxInputs.length === 2;

  return (
    <>
      {taxInputs.map((el) =>
        renderTaxInput(
          el,
          personalData,
          handleSave,
          formErrors,
          region,
          removeTaxInput,
          handleFileUpload,
          removeFile,
          taxDocumentsRequired
        )
      )}
      {!allSelected && (
        <InputWrapper>
          <Select onChange={handleTaxChange} value='' required={required}>
            <option value=''>Choose{required ? '*' : ''}</option>
            {!taxInputs.includes('taxId') && (
              <option value='taxId'>
                {region === 'DE' ? 'Tax' : 'UTR'} number
              </option>
            )}
            {!taxInputs.includes('vatId') && (
              <option value='vatId'>
                VAT {region === 'DE' ? 'ID' : 'number'}
              </option>
            )}
          </Select>
        </InputWrapper>
      )}
    </>
  );
};

const nonEUTaxOption = (personalData, handleSave) => (
  <InputWrapper>
    <Input
      type='text'
      name='taxId'
      placeholder='Tax number'
      value={personalData.taxId}
      onChange={(value) => handleSave('taxId', value)}
    />
  </InputWrapper>
);
