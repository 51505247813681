import InterviewPrepPreview from '../../components/InterviewPrepPreview';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { openInterviewGuidelines } from '../../actions';
import { startInterview } from '../../../InterviewPrep/containers/InterviewToolCard/actions';

const InterviewPrepPreviewContainer = ({ loading, ...rest }) => {
  return (
    <>{loading ? <LoadingSpinner /> : <InterviewPrepPreview {...rest} />}</>
  );
};

InterviewPrepPreviewContainer.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  openInterviewGuidelines: PropTypes.func.isRequired,
  startInterview: PropTypes.func.isRequired,
};

export const mapStateToProps = ({ careerSupportCenter: { loading } }) => ({
  loading,
});

export const mapDispatchToProps = (dispatch) => ({
  openInterviewGuidelines: () => dispatch(openInterviewGuidelines()),
  startInterview: (entryPoint) => dispatch(startInterview(entryPoint)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewPrepPreviewContainer);
