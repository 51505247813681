import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import FilterWrapper from './FilterWrapper';
import MenteeList from './MenteeList';
import PropTypes from 'prop-types';
import useDebounce from '../../../../shared/hooks/useDebounce';

const NameSearchForm = ({
  nameInput,
  mentees,
  setAttendee,
  attendee,
  handleNameSearch,
}) => {
  const [tempName, setTempName] = useState(nameInput);
  const [newSearch, setNewSearch] = useState(false);
  const debouncedName = useDebounce(tempName, 1000);
  const [isDropdownClicked, setIsDropdownClicked] = useState(false);

  useEffect(() => {
    if (nameInput !== tempName && !isDropdownClicked) {
      setNewSearch(true);
      handleNameSearch(tempName);
    }
  }, [debouncedName]);

  useEffect(() => {
    if (attendee) {
      setTempName(attendee.value);
    }
  }, [attendee]);

  const handleTempName = ({ target: { value } }) => {
    setTempName(value);
    setIsDropdownClicked(false);
  };

  const handleClick = (mentee) => {
    setAttendee(mentee);
    setIsDropdownClicked(true);
  };

  const inputPlaceholder = attendee ? '' : 'Type student name';

  return (
    <FilterWrapper label='Student name*' isSearch={true}>
      <Formik
        initialValues={{
          name: tempName,
        }}
      >
        <Form>
          <Field
            name='name'
            value={tempName}
            label='Student Name'
            placeholder={inputPlaceholder}
            onChange={handleTempName}
            autocomplete='off'
          />
        </Form>
      </Formik>
      <MenteeList
        mentees={mentees}
        newSearch={newSearch}
        handleClick={handleClick}
      />
    </FilterWrapper>
  );
};

NameSearchForm.propTypes = {
  attendee: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.string,
  }),
  handleNameSearch: PropTypes.func.isRequired,
  mentees: PropTypes.array,
  nameInput: PropTypes.string,
  setAttendee: PropTypes.func,
};

export default NameSearchForm;
