import ChannelButton from '../../components/ChannelButton';
import { connect } from 'react-redux';
import { openMessagingWindow } from './actions';
import { toggleMsgPreview } from '../../actions';

export const mapStateToProps = (state, ownProps) => ({ ...ownProps });

export const mapDispatchToProps = (dispatch) => ({
  openMessagingWindow: (cid) => dispatch(openMessagingWindow(cid)),
  toggleMsgPreview: () => dispatch(toggleMsgPreview()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelButton);
