import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const studentCallNotesContainer = document.getElementById('studentCallNotes');

const CallNotesCard = lazy(() => import('./components/CallNotes'));

if (studentCallNotesContainer) {
  const studentCallNotesContainerRoot = createRoot(studentCallNotesContainer);

  studentCallNotesContainerRoot.render(
    <Suspense fallback={<div></div>}>
      <Provider store={store}>
        <CallNotesCard />
      </Provider>
    </Suspense>
  );
}
