import MentorNoteButtonStyles from './styles/MentorNoteButtonStyles';
import PropTypes from 'prop-types';
import React from 'react';

const MentorNoteButtons = ({ handleDeleteNote, handleEditNote, id }) => (
  <MentorNoteButtonStyles>
    <button
      className='ds-button__petrol ds-button__small'
      onClick={() => handleDeleteNote(id)}
    >
      Delete
    </button>
    <button
      className='ds-button__petrol ds-button__small'
      onClick={() => handleEditNote(id)}
    >
      Edit
    </button>
  </MentorNoteButtonStyles>
);

MentorNoteButtons.propTypes = {
  handleDeleteNote: PropTypes.func,
  handleEditNote: PropTypes.func,
  id: PropTypes.number,
};

export default MentorNoteButtons;
