import DisabledTooltipContent from '../../DisabledTooltipContent';
import Icon from '../../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { CareerSupportCenterIcon as SVGIcon } from '../../SideNavList/icons';
import { trackAndRedirect } from '../../../helpers';

const CareerSupportCenterIcon = ({
  isDisabled = true,
  isVisible = false,
  isStudent,
  currentPageName = '',
  onClickRedirectTo = '',
  ...rest
}) => (
  <Icon
    isDisabled={isDisabled}
    isSelected={currentPageName === 'CAREER_SUPPORT_CENTER'}
    isVisible={isVisible}
    onClick={() =>
      !isDisabled &&
      trackAndRedirect({
        redirect: {
          url: onClickRedirectTo,
        },
        track: {
          itemName: 'CSC',
          shouldTrack: isStudent,
        },
      })
    }
    renderSVGIcon={() => <SVGIcon />}
    nameForMobileView="Career Support Center"
    tooltipContent={
      isDisabled ? (
        <DisabledTooltipContent
          title="Career support center"
          description="Unlocked halfway through your program"
        />
      ) : (
        'Career support center'
      )
    }
    {...rest}
  />
);

CareerSupportCenterIcon.propTypes = {
  ...Icon.propTypes,
  currentPageName: PropTypes.string,
  isDisabled: PropTypes.bool,
  isStudent: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClickRedirectTo: PropTypes.string,
};

export default CareerSupportCenterIcon;
