import TermsTab from '../../../components/Tabs/TermsOfParticipation';
import { connect } from 'react-redux';
import {
  IUpdateAdmissionParams,
  IAdmission,
  ITabData,
} from '../../../interfaces';
import { updateAdmission } from '../../../actions';

interface IState {
  afaAdmissions: {
    selectedSubTab: string;
    tabData: ITabData;
    admission: IAdmission;
  };
}

export const mapStateToProps = ({
  afaAdmissions: { selectedSubTab, tabData, admission },
}: IState) => ({
  selectedSubTab,
  tabData,
  admission,
});

export const mapDispatchToProps = (dispatch: any) => ({
  updateAdmission: (params: IUpdateAdmissionParams, tab: string) =>
    dispatch(updateAdmission(params, tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsTab);
