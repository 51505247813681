import React from 'react';
import StyledLargeTile from '../../UsefulLinks/styles/StyledLargeTile';

const CommunityEventTile = () => (
  <StyledLargeTile
    description="Subscribe to this calendar to ensure you don't miss any events that could be important for your job search."
    icon={
      <img
        src='https://coach-courses-us.s3.amazonaws.com/public/icons/csc-calendar.png'
        alt='icon'
      />
    }
    secondaryButton={{
      event: 'community_calendar_link_clicked',
      link: 'https://calendar.google.com/calendar/embed?src=alumni%40careerfoundry.com',
      text: 'View calendar',
    }}
    title='Community Event Calendar'
  />
);

export default CommunityEventTile;
