import aiTutor from './ai_tutor';
import simplifyText from './simplify_text';
require('./quiz');

module.exports = (() => {
  const init = () => {
    $(document).ready(() => {
      bindFunctions();
      applyGradientToAIContent('h4');
      applyGradientToAIContent('h5');
      applyGradientToAIContent('strong');
      applyIconToAIContent('div.course_menu_text');
    });
    insertTableWrapper();
    openAllLinksInNewTab();
    pageReadTracking();
    if (!gon.submission_id && !gon.ai_tutor_submission_id) {
      disableSubmissionHistoryTab();
    }
    simplifyText.init();
    aiTutor.init();
  };

  const bindFunctions = () => {
    $(document).on('click', '#feedback-open', openFeedbackModal);
    $(document).on('submit', '#feedback-modal form', closeFeedbackModal);
    $('#modal_question_editbox_text').on('keyup', clearErrorMessage);
    $('.rubric_nav--js a').on('click', showRubricsTab);
  };

  const applyGradientToAIContent = (
    element,
    text = /(Spotlight on AI|Bonus: Spotlight on AI)/g
  ) => {
    [].slice
      .call(document.querySelectorAll(element))
      .filter((el) => el.textContent.match(text))
      .forEach((el) => el.classList.add('spotlight-on-ai__text'));
  };

  const applyIconToAIContent = (element, text = /(Spotlight on AI)/g) => {
    const icon = `<div class="ai-icon-wrapper"><i class="fa-light fa-sparkle"></i></div>`;

    [].slice
      .call(document.querySelectorAll(element))
      .filter((el) => el.textContent.match(text))
      .forEach((el) => el.insertAdjacentHTML('afterend', icon));
  };

  const insertTableWrapper = () =>
    $('table').wrap("<div class='table__wrapper'></div>");

  const openAllLinksInNewTab = () => $('.task_text a').attr('target', '_blank');

  const disableSubmissionHistoryTab = () => {
    $('[href="#directions"]').tab('show');
    // Disable submission history if no submission exists
    $('.rubric_nav--js').on('hide.bs.tab', 'a', function (e) {
      e.preventDefault();
    });
    $('#step_submission_history_tab').parent().addClass('disabled');
  };

  const openFeedbackModal = () => $('#feedback-modal').modal('show');
  const closeFeedbackModal = () => $('#feedback-modal').modal('hide');

  const clearErrorMessage = () => $('.submission__error_container').html('');

  const showRubricsTab = function (e) {
    e.preventDefault();
    if (gon.submission_id || gon.ai_tutor_submission_id) {
      $(this).tab('show');
    }
  };

  const pageReadTracking = () => {
    if ('IntersectionObserver' in window) {
      const options = {
        rootMargin: '20px',
      };

      const observerCallback = (entries) => {
        const ad = _.find(
          entries,
          (el) => el.target.id === 'step-tracking-hook--js'
        );
        if (!ad || !ad.isIntersecting) return;
        stepScrolledObserver.unobserve(ad.target);
        sendTrackingEvent();
      };

      const stepScrolledObserver = new IntersectionObserver(
        observerCallback,
        options
      );
      stepScrolledObserver.observe(
        document.getElementById('step-tracking-hook--js')
      );
    }
  };

  const sendTrackingEvent = () => {
    const stepId = document.getElementById('content').dataset.stepId;

    $.post('/api/v1/analytics/track', {
      data: {
        step_id: stepId,
      },
      event: 'page_read',
    });
  };

  return {
    init: init,
  };
})();
