import React, { useState, useEffect } from 'react';
import UserService from '../../../services/UserService';
import {getAuthToken} from '../../../utils/getAuthToken';
import Input from '../input';
import Form from '../form';
import Dropdown from '../dropdown';

const UserProfileForm = props => {
  const [ city, setCity ] = useState(props.city ? props.city : '');
  const [ country, setCountry ] = useState(props.country ? props.country : '');
  const [ zipCode, setZipCode ] = useState(props.zipCode ? props.zipCode : '');

  useEffect(() => {
    populateFieldsIfUser();
  }, []);

  const populateFieldsIfUser = () => {
    const userService = new UserService();
    userService.getCurrentUser()
      .then(data => {
        if(Object.keys(data).length > 0) {
          setCity(data.profile.city);
          setCountry(data.profile.country);
          setZipCode(data.profile.zipCode);
        }
      })
  };

  const onChangeHandler = (val, name) => {
    switch(name) {
      case 'city': setCity(val); break;
      case 'country': setCountry(val); break;
      case 'zipcode': setZipCode(val); break;
      default: return;
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.onCheckout({country, city, zipCode});
  };

  const renderSubmitButton = () => {
    let formComplete = city && country && zipCode;
    let toggleButtonClass = formComplete ? "button--primary-petrol" : "button--primary-disabled";

    if(props.buttonLoadingState) {
      return (
        <button className="button--large button--primary-petrol"
                disabled={true}
                id="user-profile-submit"
                dangerouslySetInnerHTML={{__html: CF.html.loadingSpinner}}/>
      );
    }
    else {
      return (
        <button type="submit"
                disabled={!formComplete}
                id="user-profile-submit"
                className={toggleButtonClass}>
          Enroll
        </button>
      )
    }
  };

  return (
    <Form onSubmit={handleSubmit}
          className="enroll__slide user_profile_form">
      <h2>Add Your Details</h2>
      <Input type="text"
             name="city"
             value={city}
             onChange={onChangeHandler}
             required
             className="cf-form__input"
             labelText="City"/>
      <Input type="text"
             name="zipcode"
             value={zipCode}
             onChange={onChangeHandler}
             required
             className="cf-form__input"
             labelText="Zip Code"
             maxLength="10" />
      <Dropdown labelText="Country"
                name="country"
                initialText={CF.countries.listWithCodes[country]}
                initialValue={country}
                countrySelector
                onChange={onChangeHandler} />
      <footer>
        <div className="error__container">
          <p className="cf-small">{props.formError}</p>
        </div>
        {renderSubmitButton()}
      </footer>
    </Form>
  );
};

export default UserProfileForm;
