import EvaluationCard from '../../../../InterviewPrep/components/InterviewReview/styles/Card';
import styled from 'styled-components';

const StyledCard = styled(EvaluationCard)`
  width: 100%;
`;

StyledCard.displayName = 'StyledCard';

export default StyledCard;
