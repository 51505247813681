import styled from 'styled-components';

const StyledMarkdown = styled.div`
  a {
    text-decoration: underline;
  }
  p {
    font-family: var(--ds-font__tradegothic);
    font-size: 16px;
    margin-bottom: 16px;
    text-align: left;
    line-height: 24px;
    color: var(--ds-color__indigo);
  }

  @media (max-width: 900px) {
    p {
      display: none;
    }
  }
`;
StyledMarkdown.displayName = 'StyledMarkdown';
export default StyledMarkdown;
