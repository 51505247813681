import styled from 'styled-components';

const StyledCourseLabel = styled.div`
  padding: 2px 9px 0;
  display: inline-block;
  background-color: ${props => props.color};

  p {
    color: #fff;
    margin: 0;
  }
`;

StyledCourseLabel.displayName = 'StyledCourseLabel';

export default StyledCourseLabel;
