module.exports = (() => {
  var init = function() {
    bindFunctions();
    showTab($('.activity-tab.tab-pane.active'));
  }

  var bindFunctions = function() {
    $('.notes--js').one('click', 'p.truncated', expandNote);
    $('.nav-tabs a[data-toggle="tab"]').on('shown.bs.tab', initTabs)
  }

  // bind functions
  var expandNote = function(e) {
    e.preventDefault();
    $(this).css('display', 'none');
    $(this).siblings('p.expanded').css('display', 'inline');
  }

  var initTabs = function(e) {
    var tab = $($(e.target).attr('href'));
    showTab(tab);
  }

  // initialize functions
  var showTab = function(tab) {
    if (!tab.html()) {
      tab.html('<div style="margin-top:40px;margin-left:20%"><img src="https://images.careerfoundry.com/public/images/ajax-loader-md.gif" class="feature-ajax-spinner" /></div>');
      var href = tab.attr('id');
      var target = $('#'+href);
      var attendee_id = $('#content').data('attendee-id');
      var url = '/attendees/' + attendee_id + '/' + href;
      tab.load(url, function(){
        tab.tab('show');
        _hideExpanded();
      });
    }
  }

  // private functions
  var _hideExpanded = function() {
    $('p.expanded').css('display', 'none');
  }

  return {
    init: init
  }

})();
