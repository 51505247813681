import styled from 'styled-components';

const StyledBanner = styled.div`
  background-color: var(--ds-bg-color__int-progress_ind-warning);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;

  p {
    margin: 0;
  }

  i {
    line-height: 0;
  }
`;

StyledBanner.displayName = 'StyledBanner';

export { StyledBanner };
