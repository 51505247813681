import Achievements from '../../components/Achievements';
import { connect } from 'react-redux';

const mapStateToProps = ({
  studentActivity: { attendees, isSelectedCourseStarted, selectedCourseId },
}) => ({
  attendees,
  isSelectedCourseStarted,
  selectedCourseId,
});

export default connect(mapStateToProps, null)(Achievements);
