const HeaderContent = 'Welcome to the CareerFoundry family!';
const HeaderContentDescription =
  'You’re joining a very diverse global student community. We’re proud that our students come from 87 different countries, 67% identify as women, 40% identify as BIPOC, and 32% are caregivers to dependents. We believe this diversity enriches your experience as a CareerFoundry student.';
const BodyText =
  'To continue building a diverse and inclusive learning community, we invite you to participate in our short Diversity Survey. By answering a few questions about how you identify, you’ll help us assess the overall diversity of our students and the equity of student outcomes. All questions include a “Prefer not to disclose” option, and your responses will be reviewed anonymously and kept confidential.* Your data will not be shared with any third parties.';
const PrimaryButtonLabel = 'Tell us about yourself';
const TertiaryButtonLabel = 'I prefer to fill the survey out later';

export {
  BodyText,
  HeaderContent,
  HeaderContentDescription,
  PrimaryButtonLabel,
  TertiaryButtonLabel,
};
