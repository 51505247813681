import styled from 'styled-components';

const BodyWrapper = styled.div`
  h5 {
    font-size: 16px;
    margin: 32px 0 0;
    font-weight: 600;
  }
  p {
    margin-bottom: 10px;
  }

  textarea {
    width: 629px;
    height: 175px;
    margin: 0;
    padding: 8px 8px 0;
    border-radius: 4px;
    border: solid 2px var(--ds-color__grey--400);
    background-color: var(--ds-color__white);
    font-size: 16px;
    font-weight: 300;
    color: var(--ds-color__indigo);
  }

  @media (max-width: 768px) {
    margin: 28px 0 0;

    textarea {
      width: 100%;
      height: 362px;
      font-size: 16px;
      padding: 16px 16px 10px;
    }
  }
`;

BodyWrapper.displayName = 'BodyWrapper';

export default BodyWrapper;
