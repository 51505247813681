import PropTypes from 'prop-types';
import React from 'react';

const AudioHeader = ({ showAnswer, timeLeftString }) =>
  showAnswer
    ? (
      <>
        <h1 className="ds-typography__h2 recorder-header">Your Answer</h1>
        <p className="ds-typography__body audio-tips">
        (see the tips for answering the question above)
        </p>
      </>
    )
    : (
      <h1 className="ds-typography__h2 counter">{timeLeftString}</h1>
    );

AudioHeader.propTypes = {
  showAnswer: PropTypes.bool.isRequired,
  timeLeftString: PropTypes.string,
};

export default AudioHeader;
