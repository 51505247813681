import React, { useEffect } from 'react';
import {
  fetchRequiredData,
  setIsVisible,
  setShouldShowSubNav,
} from '../../actions';
import PropTypes from 'prop-types';
import SideNavList from '../../components/SideNavList';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

const SideNavListContainer = ({ fetchRequiredData, ...rest }) => {
  useEffect(() => {
    fetchRequiredData();
  }, []);

  return <SideNavList {...rest} />;
};

SideNavListContainer.propTypes = {
  fetchRequiredData: PropTypes.func,
};

const mapStateToProps = (state) => {
  const userTypesWithAccessToSubNav = ['student', 'instructor', 'admin'];
  const { courseNavigationData, meData, shouldShowSubNav, isVisible, common } =
    state.sideNavList;
  const isSubNavAccessible =
    userTypesWithAccessToSubNav.includes(meData.userType) &&
    !isEmpty(courseNavigationData);
  const isUserTypeStudent = meData.userType === 'student';

  return {
    courses: courseNavigationData,
    isStudent: common.isStudent,
    isSubNavAccessible,
    isUserTypeStudent,
    isVisible,
    shouldShowSubNav,
    userNameSlug: meData.slug,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchRequiredData: () => dispatch(fetchRequiredData()),
  setIsVisible: (isVisible) => dispatch(setIsVisible(isVisible)),
  setShouldShowSubNav: (shouldShowSubNav) =>
    dispatch(setShouldShowSubNav(shouldShowSubNav)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNavListContainer);
