import React, { useEffect } from 'react';
import { getCapacity, setCapacity } from '../../actions';
import Capacity from '../../components/Capacity';
import Loading from '../../../../shared/components/Loading';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const CapacityContainer = ({
  candidateRole,
  capacity,
  loadingButton,
  getCapacity,
  loadingTab,
  setCapacity,
}) => {
  useEffect(() => {
    getCapacity();
  }, []);

  return (
    <Loading isLoading={loadingTab}>
      <Capacity
        candidateRole={candidateRole}
        capacity={capacity}
        handleSubmit={setCapacity}
        loadingButton={loadingButton}
      />
    </Loading>
  );
};

CapacityContainer.propTypes = {
  candidateRole: PropTypes.string,
  capacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getCapacity: PropTypes.func,
  loadingButton: PropTypes.bool,
  loadingTab: PropTypes.bool,
  setCapacity: PropTypes.func,
};

export const mapStateToProps = ({
  instructorProfile: {
    capacity,
    data,
    loadingButton,
    loadingCapacity,
    loadingTab,
  },
}) => ({
  candidateRole: data.candidateRole,
  capacity,
  loadingButton,
  loadingCapacity,
  loadingTab,
});

export const mapDispatchToProps = (dispatch) => ({
  getCapacity: () => dispatch(getCapacity()),
  setCapacity: (value) => dispatch(setCapacity(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CapacityContainer);
