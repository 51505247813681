export const isCourseReadOnly = (attendees, courseId) => {
  return !attendees.some((attendee) => attendee.courseId === courseId);
};

export const isExerciseReadOnly = (attendees, courseSlug) => {
  return !attendees.some(
    (attendee) => attendee.friendlyCourseId === courseSlug
  );
};

export const getCourseId = () => {
  let courseId = location.pathname.split('/')[3];
  courseId = !isNaN(courseId) && +courseId;
  return courseId;
};

export const getCourseSlug = () => {
  return location.pathname.split('/')[3];
};

export const getCourseById = ({ courses = [], id = null }) => {
  return courses.find((course) => course.courseId === id);
};

export const getCourse = ({ courses = [], state = 'active' }) => {
  const currentDateTimeStamp = +new Date();

  if (state === 'active') {
    return courses.find(
      (course) =>
        +new Date(course.start) <= currentDateTimeStamp &&
        +new Date(course.endDate) > currentDateTimeStamp
    );
  } else if (state === 'started') {
    return courses.find(
      (course) => +new Date(course.start) <= currentDateTimeStamp
    );
  }
};

export const getExpiredCourses = ({ courses = [] }) => {
  const currentDateTimeStamp = +new Date();

  return courses.filter(
    (course) => +new Date(course.endDate) < currentDateTimeStamp
  );
};

export const isCourseStarted = ({ startDate }) => {
  const currentDateTimeStamp = +new Date();

  return +new Date(startDate) <= currentDateTimeStamp;
};
