import axios from 'axios';
import { getAuthToken } from '../../../../../../utils/getAuthToken';

export const getData = (attendeeId) => (dispatch) => {
  return Promise.all([
    getWsgData(attendeeId),
    getCourseStepData(attendeeId),
  ]).then(([data, exerciseData]) => {
    dispatch({
      attendeeId,
      data,
      exercises: exerciseData,
      type: 'WeeklySubmissionGoal.FETCH_DATA',
    });
  });
};

const getWsgData = async (attendeeId) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `/api/v1/attendee/${attendeeId}/weekly_submission_goals`,
      {
        authenticity_token: getAuthToken(),
      }
    );

    return data;
  } catch (err) {
    console.log('error', err);
  }
};

const getCourseStepData = async (attendeeId) => {
  try {
    const {
      data: { data: exerciseData },
    } = await axios.get(`/api/v1/attendee/${attendeeId}/course_steps`, {
      authenticity_token: getAuthToken(),
    });

    return exerciseData;
  } catch (err) {
    console.log('error', err);
  }
};
