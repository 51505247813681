import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: var(--ds-spacing__s);
  padding: var(--ds-spacing__m) var(--ds-spacing__l);
  background-color: var(--ds-bg-color__static-primary);
  box-shadow: 5px 5px 10px 0 rgba(51, 51, 51, 0.1);
  border-radius: 4px;

  h4 {
    margin: var(--ds-spacing__xs) 0 var(--ds-spacing__s);
  }

  p {
    margin-bottom: 0;
  }

  h4,
  p {
    color: var(--ds-color__static-content-primary);
  }

  div.link-container {
    margin-top: var(--ds-spacing__s);
  }

  @media (max-width: 1024px) {
    display: flex;
    padding: var(--ds-spacing__l);
    border-bottom: none;

    img {
      align-self: flex-start;
    }

    h4 {
      margin-top: 0;
    }

    div.content {
      margin-left: var(--ds-spacing__s);
    }
  }
`;
