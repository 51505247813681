import styled from 'styled-components';

const StyledConfidenceReviewModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  h5 {
    margin-top: 0 !important;
  }
`;
StyledConfidenceReviewModal.displayName = 'StyledConfidenceReviewModal';
export default StyledConfidenceReviewModal;
