import Dashboard from '../../components/Dashboard';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  interviewPrep: {
    interviewStage,
  },
}) => ({
  interviewStage,
});

export default connect(mapStateToProps, null)(Dashboard);
