import Resources from '../../components/Resources';
import { connect } from 'react-redux';
import { handleSubmit } from './actions';

export const mapStateToProps = ({
  instructorProfile: { data, loadingButton },
}) => ({
  id: data.id,
  loadingButton,
  role: data.candidateRole,
});

export const mapDispatchToProps = (dispatch) => ({
  handleSubmit: () => dispatch(handleSubmit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
