import 'react-h5-audio-player/src/styles.scss';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { PlayButton, PlayerStyleWrapper } from './styles';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getAuthToken } from '../../../../utils/getAuthToken';
import { getExerciseSlugFromPathname } from '../../helpers';
import { trackEvent } from '../../../../shared/apis/trackEvent';

const AudioReader = ({ stepId }) => {
  const [audioSrc, setAudioSrc] = useState(null);
  const audioPlayer = useRef(null);

  useEffect(() => {
    (async function () {
      try {
        const {
          data: {
            data: { audio_player: audioUrl },
          },
        } = await axios.get(`/api/v1/step_navigation/${stepId}`, {
          headers: {
            'X-CSRF-TOKEN': getAuthToken(),
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
        setAudioSrc(audioUrl);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const trackUsage = ({ target }, action) => {
    const duration = audioPlayer.current.audio.current.duration;
    const positionPercentage = Math.floor(
      (target.currentTime / duration) * 100
    );

    trackEvent({
      data: {
        action,
        progress_percentage: positionPercentage,
        step_slug: getExerciseSlugFromPathname(window.location.pathname),
      },
      event: 'audio_reader_click',
    });
  };

  return (
    audioSrc && (
      <PlayerStyleWrapper>
        <AudioPlayer
          ref={audioPlayer}
          customAdditionalControls={[]}
          customVolumeControls={[]}
          layout={'stacked-reverse'}
          customProgressBarSection={[
            RHAP_UI.CURRENT_TIME,
            RHAP_UI.PROGRESS_BAR,
            RHAP_UI.DURATION,
          ]}
          customControlsSection={[
            <small className='ds-text__body--medium' key='custom-control-1'>
              Listen to this exercise
            </small>,
            RHAP_UI.MAIN_CONTROLS,
          ]}
          showJumpControls={false}
          src={audioSrc}
          customIcons={{
            pause: (
              <PlayButton className='ds-icon__player-pause ds-color__indigo' />
            ),
            play: (
              <PlayButton className='ds-icon__player-play-circle ds-color__indigo' />
            ),
          }}
          onPause={(event) => trackUsage(event, 'pause')}
          onPlay={(event) => trackUsage(event, 'play')}
        />
      </PlayerStyleWrapper>
    )
  );
};

AudioReader.propTypes = {
  stepId: PropTypes.string.isRequired,
};

export default AudioReader;
