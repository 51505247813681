import styled from 'styled-components';

const HeadsUpMessageStyles = styled.div`
  display: flex;
  padding: 10px 16px;

  & > img {
    margin-left: 16px;
    height: 35px;
    width: 35px;
  }
`;

HeadsUpMessageStyles.displayName = 'HeadsUpMessageStyles';

export default HeadsUpMessageStyles;
