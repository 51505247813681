import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import ButtonSection from './ButtonSection';
import ProfileSeparator from '../../../../../EmployerConnector/modals/showModal/styles/ProfileSeparator';
import PropTypes from 'prop-types';
import StyledEmployerForm from './styles/StyledEmployerForm';
import StyledFormField from './styles/StyledFormField';
import StyledInput from './styles/StyledInput';

const EmployerForm = ({ createEmployer, deleteEmployer, errors, handleCloseModal, updateEmployer, employer, modalAction }) => {
  const [tempData, setTempData] = useState(employer);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTempData({ ...tempData, [name]: value });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setTempData({
      ...tempData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    isEditView ? updateEmployer(tempData.id, tempData) : createEmployer(tempData);
  };

  const RANGES = ['1-10', '11-50', '51-200', '201-500', '501-1000', '1001-5000', '>5000'];

  const isEditView = modalAction === 'updateEmployer';

  return (
    <StyledEmployerForm>
      <h5 className="ds-typography__h5 text-left">{isEditView ? 'Edit Employer' : 'New employer creation'}</h5>
      <ProfileSeparator />
      <Formik
        initialValues={{ companyName: '', contactName: '', email: '', sizeRange: '' }}
      >
        <Form>
          <StyledFormField labelText="Contact Name">
            <StyledInput>
              <Field
                id="contactName"
                type="text"
                name="contactName"
                onChange={handleInputChange}
                value={tempData.contactName}
              />
              {errors.map((error) => {
                return (
                  error.message && error.message.contactName && <p className="ds-typography__small--bold error-text">Contact Name {error.message.contactName.join('')}</p>
                );
              })}
            </StyledInput>
          </StyledFormField>
          <StyledFormField labelText="Company Name">
            <StyledInput>
              <Field
                id="companyName"
                type="text"
                name="companyName"
                onChange={handleInputChange}
                value={tempData.companyName}
              />
              {errors.map((error) => {
                return (
                  error.message && error.message.companyName && <p className="ds-typography__small--bold error-text">Company Name {error.message.companyName.join('')}</p>
                );
              })}
            </StyledInput>
          </StyledFormField>
          <StyledFormField labelText="Email">
            <StyledInput>
              <Field
                id="email"
                type="text"
                name="email"
                onChange={handleInputChange}
                value={tempData.email}
              />
              {errors.map((error) => {
                return (
                  error.message && error.message.email && <p className="ds-typography__small--bold error-text">Email {error.message.email.join('')}</p>
                );
              })}
            </StyledInput>
          </StyledFormField>
          <StyledFormField labelText="Company size">
            <StyledInput>
              <Field
                id="sizeRange"
                as="select"
                name="sizeRange"
                placeholder="Select company size"
                onChange={handleSelectChange}
                value={tempData.sizeRange}
              >
                <option value="">Select company size</option>
                {RANGES.map((range) => {
                  return (
                    <option value={range} key={range}>
                      {range}
                    </option>
                  );
                })}
              </Field>
            </StyledInput>
          </StyledFormField>
          <ButtonSection employerId={employer.id} handleSubmit={handleSubmit} onDelete={deleteEmployer} handleCloseModal={handleCloseModal} isEditView={isEditView} />
        </Form>
      </Formik>
    </StyledEmployerForm>
  );
};

EmployerForm.propTypes = {
  createEmployer: PropTypes.func,
  deleteEmployer: PropTypes.func,
  employer: PropTypes.shape({
    companyName: PropTypes.string,
    contactName: PropTypes.string,
    email: PropTypes.string,
    frozen: PropTypes.bool,
    id: PropTypes.number,
    lastLogin: PropTypes.string,
    sizeRange: PropTypes.string,
  }),
  errors: PropTypes.array,
  handleCloseModal: PropTypes.func,
  modalAction: PropTypes.string,
  updateEmployer: PropTypes.func,
};

export default EmployerForm;
