import { imagePreview } from './imagePreview';

module.exports = (() => {
  const init = function () {
    imagePreview();
  };

  return {
    init: init,
  };
})();
