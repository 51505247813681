import React, { Component } from 'react';
import _ from 'lodash';
import PaymentDetailsSlide from '../shared/PaymentDetailsSlide';
import Sidebar from '../shared/Sidebar';
import LoadingSpinner from '../shared/loadingSpinner';
import CheckoutService from '../../services/CheckoutService';
import CourseExtensionsService from '../../services/CourseExtensionsService';
import { getAuthToken } from '../../utils/getAuthToken';
import ValidationService from '../../services/ValidationService';
import UserService from '../../services/UserService';
import { loadStripeLib } from '../../services/StripeHelpers';
import { formatErrorMessage } from '../../utils/formatErrorMessages';
import { getUserLocation } from '../enroll/actions';
import { sepaCountries } from '../enroll/helpers';

class CourseExtensionsWrapper extends Component {
  constructor() {
    super();
    this.state = {
      stripe: null,
      paypalAvailable: true,
      sepaAvailable: false,
      formError: '',
      errorType: '',
      user: {
        name: '',
        email: '',
      },
      selectedPrice: {
        total: '',
      },
      selectedOption: null,
      selectedStart: null,
      loadingState: false,
      buttonLoadingState: false,
      payments: {},
      paymentMethod: 'card',
      isB2B: false,
      courseParams: null,
      cardChangeToggle: false,
    };

    let authToken = getAuthToken();
    this.CheckoutService = new CheckoutService(authToken, 'course_extensions');
    this.ValidationService = new ValidationService();
  }

  componentWillMount() {
    loadStripeLib(window.$).then((stripe) => {
      this.setState({ stripe });
    });
    this.getExtensionData();
    this.checkSepaAvailability();
    this.getUserData();
  }

  getExtensionData = () => {
    const courseExtensionsService = new CourseExtensionsService();

    courseExtensionsService
      .getExtensionDetails()
      .then((res) => {
        this.syncStateExtensionData(res.data);
      })
      .catch((res) => {
        throw new Error(
          'Error. Please reload the page or contact our support. ',
          res
        );
      });
  };

  getUserData = () => {
    const userService = new UserService();

    userService.getCurrentUser().then((response) => {
      this.setState({
        user: response,
      });
    });
  };

  checkSepaAvailability = () => {
    getUserLocation().then((res) => {
      this.setState({
        sepaAvailable: sepaCountries.includes(res.data.data.countryCode),
      });
    });
  };

  syncStateExtensionData = (data) => {
    let paymentMethod = null;
    if (data.billingData.cardPresent) {
      paymentMethod = 'card';
    } else if (data.billingData.paypalPresent) {
      paymentMethod = 'paypal';
    } else {
      paymentMethod = 'card'; // default
    }

    this.setState({
      paymentMethod,
      ...data,
    });
  };

  handlePaymentMethodClick = (method) =>
    this.setState({ paymentMethod: method });

  handleB2BClick = (value) => this.setState({ isB2B: value });

  setErrorType = (error) => this.ValidationService.getErrorType(error);

  handleCheckout = (data) => {
    this.setState({
      buttonLoadingState: true,
      formError: '',
    });

    const b2b = this.state.isB2B;
    const provider =
      this.state.paymentMethod === 'paypal' ? 'paypal' : 'stripe';
    const paymentMethod = this.state.paymentMethod;
    const isNewBillingProfile =
      (!this.state.billingData.cardPresent &&
        !this.state.billingData.paypalPresent) ||
      this.state.cardChangeToggle;
    return this.billingProfileDriver(
      isNewBillingProfile,
      data,
      null,
      null,
      null,
      null,
      provider,
      b2b,
      null,
      paymentMethod
    );
  };

  billingProfileDriver = (isNew, ...args) => {
    let caller = isNew
      ? this.CheckoutService.checkout
      : this.CheckoutService.existingBillingProfileCheckoutHandler;
    return caller(...args)
      .then((response) => {
        if (this.state.paymentMethod === 'paypal') {
          return response;
        }
        this.setState({
          formError: '',
          errorType: '',
        });
        window.location.replace(response.data.url);
      })
      .catch((error) => {
        this.setState({
          formError: formatErrorMessage(error),
          errorType: this.setErrorType(error.errorType),
          buttonLoadingState: false,
          loadingState: false,
        });
      });
  };

  handleBackClick = () => window.history.back();

  handleCardChangeToggle = (checked) =>
    this.setState({ cardChangeToggle: checked });

  renderBackButton = () => (
    <img
      className="extensions_page__back-button"
      src="https://images.careerfoundry.com/public/framework/back-arrow.svg"
      onClick={() => {
        this.handleBackClick();
      }}
    />
  );

  sideBar = () => (
    <Sidebar
      extensionsPage
      metadata={this.state.metadata}
      price={this.state.paymentDetails[0][1]}
    />
  );

  paymentDetails = () => (
    <PaymentDetailsSlide
      extensionsPage
      stripe={this.state.stripe}
      isB2B={this.state.isB2B}
      onB2BClick={this.handleB2BClick}
      paymentMethod={this.state.paymentMethod}
      onPaymentMethodClick={this.handlePaymentMethodClick}
      onCheckout={this.handleCheckout}
      courseContent={{ payment: this.state.metadata }}
      courseParams={{ currency_code: this.state.currencyCode }}
      paymentDetails={this.state.paymentDetails}
      billingData={this.state.billingData}
      formError={this.state.formError}
      paypalAvailable={this.state.paypalAvailable}
      handleCardChangeToggle={this.handleCardChangeToggle}
      buttonLoadingState={this.state.buttonLoadingState}
      loadingState={this.state.loadingState}
      sepaAvailable={this.state.sepaAvailable}
      email={this.state.user.email}
    />
  );

  render() {
    const { stripe, metadata, billingData, paymentDetails } = this.state;

    if (stripe && metadata && billingData && paymentDetails) {
      return (
        <div className="EnrollWrapper cf-content ExtensionPage">
          <div className="extensions-page__header-wrapper">
            <p className="cf-bold cf-text-center">Extend Your Course</p>
            {this.renderBackButton()}
          </div>
          <main className="paymentSlide--no-margin">
            {this.paymentDetails()}
            {this.sideBar()}
          </main>
        </div>
      );
    } else {
      return <LoadingSpinner />;
    }
  }
}

export default CourseExtensionsWrapper;
