import EmployerConnectorCard from '../../components/EmployerConnectorCard';
import { connect } from 'react-redux';
import { getJobSeekingProfile } from '../../actions';

export const mapDispatchToProps = (dispatch) => ({
  getJobSeekingProfile: () => dispatch(getJobSeekingProfile()),
});

export const mapStateToProps = ({
  careerSupportCenter: { jobSeekingProfile, user },
}) => ({
  jobSeekingProfile,
  user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerConnectorCard);
