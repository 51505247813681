import AdmissionTest from '../../../components/Tabs/AdmissionTest';
import { connect } from 'react-redux';
import {
  IUpdateAdmissionParams,
  IAdmission,
  ITabData,
} from '../../../interfaces';
import { updateAdmission, setTabIsLoading } from '../../../actions';

interface IState {
  afaAdmissions: {
    tabLoading: boolean;
    tabData: ITabData;
    admission: IAdmission;
  };
}

export const mapStateToProps = ({
  afaAdmissions: { tabData, admission, tabLoading },
}: IState) => ({
  tabData,
  admission,
  tabLoading,
});

export const mapDispatchToProps = (dispatch: any) => ({
  updateAdmission: (params: IUpdateAdmissionParams, tab: string) =>
    dispatch(updateAdmission(params, tab)),
  setTabIsLoading: (isLoading: boolean) => dispatch(setTabIsLoading(isLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionTest);
