import PropTypes from 'prop-types';
import React from 'react';
import StyledBackgroundImage from './styles/StyledBackgroundImage';
import StyledContentSection from './styles/StyledContentSection';
import StyledImageSection from './styles/StyledImageSection';
import StyledWebinarEventsSection from './styles/StyledWebinarEventsSection';

const WebinarEventsSection = ({ isMobile }) => {
  const handleOnClick = () => {
    userEventTracking(
      'https://careerfoundry.com/en/events/',
      'upcoming_events_link_clicked'
    );
  };

  return (
    <StyledWebinarEventsSection>
      {!isMobile && (
        <StyledImageSection className='ds-grid__col-md-4'>
          <StyledBackgroundImage />
        </StyledImageSection>
      )}
      <StyledContentSection className='ds-grid__col-sm-12 ds-grid__col-md-8'>
        <h5 className='ds-typography__h5'>
          Attend CareerFoundry online events
        </h5>
        <p className='ds-typography__body'>
          Join online events to grow your knowledge, ask questions and to meet
          like-minded people.
        </p>
        <div className='button__section'>
          <a
            href='https://careerfoundry.com/en/events/'
            onClick={handleOnClick}
            className='ds-button__secondary'
            rel='noreferrer'
            target='_blank'
          >
            See event schedule
          </a>
        </div>
      </StyledContentSection>
    </StyledWebinarEventsSection>
  );
};

WebinarEventsSection.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default WebinarEventsSection;
