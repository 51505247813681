import PropTypes from 'prop-types';
import React from 'react';
import StyledCheckboxWrapper from '../styles/StyledCheckboxWrapper';

const HeaderWithCheckbox = ({ isChecked, onChange, label, id, disabled }) => {
  return (
    <StyledCheckboxWrapper
      className='cf-form__checkbox--open'
      disabled={disabled}
    >
      <input
        type='checkbox'
        onChange={() => onChange({ data: !isChecked, id })}
        checked={!!isChecked}
        disabled={disabled}
      />
      <label className='ds-typography__body'>{label}</label>
    </StyledCheckboxWrapper>
  );
};

HeaderWithCheckbox.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default HeaderWithCheckbox;
