import DashboardCard from './containers/DashboardCard';
import OverviewPage from './containers/OverviewPage';
import React from 'react';
import { Provider } from 'react-redux';
import store from '../../shared/reduxStore';
import { createRoot } from 'react-dom/client';

const oPSContainer = document.getElementById('off-platform-services');
const oPSIndexContainer = document.getElementById(
  'off-platform-services-index'
);

if (oPSContainer) {
  const oPSContainerRoot = createRoot(oPSContainer);

  oPSContainerRoot.render(
    <Provider store={store}>
      <DashboardCard />
    </Provider>
  );
}

if (oPSIndexContainer) {
  const oPSIndexContainerRoot = createRoot(oPSIndexContainer);

  oPSIndexContainerRoot.render(
    <Provider store={store}>
      <OverviewPage />
    </Provider>
  );
}
