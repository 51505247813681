import styled from 'styled-components';

const LabelSection = styled.div`
  padding: 18px 58px 6px 33px;
  h5 {
    margin-bottom: 20px;
  }
`;

LabelSection.displayName = 'LabelSection';

export default LabelSection;
