import styled from 'styled-components';

const RegionTabs = styled.div`
  width: 700px;
`;

RegionTabs.displayName = 'RegionTabs';

export {
  RegionTabs,
};
