import { InputWrapper, StyledInput } from '../App/styles';
import CountrySelect from '../../../shared/components/CountrySelect';
import { Field } from 'formik';
import React from 'react';

const AddressFields = () => (
  <>
    <h4 className='ds-typography__h4'>Billing Address</h4>
    <div>
      <InputWrapper>
        <StyledInput>
          <Field
            type='text'
            name='addressLine'
            placeholder='Address'
            required={true}
          />
        </StyledInput>
        <StyledInput>
          <Field
            type='text'
            name='addressSecondLine'
            placeholder='Apt., bldg., etc.'
          />
        </StyledInput>
      </InputWrapper>
      <InputWrapper>
        <StyledInput>
          <Field type='text' name='city' placeholder='City' required={true} />
        </StyledInput>
        <StyledInput>
          <Field
            type='text'
            name='postalCode'
            placeholder='Postcode'
            required={true}
          />
        </StyledInput>
      </InputWrapper>
      <InputWrapper>
        <StyledInput>
          <Field type='text' name='state' placeholder='State' required={true} />
        </StyledInput>
        <StyledInput>
          <Field as={CountrySelect} name='country' required={true} />
        </StyledInput>
      </InputWrapper>
    </div>
  </>
);

export default AddressFields;
