import ApiService from '../../../../../services/ApiService';
import { IUpdateAdmissionParams, ITabData } from '../../../interfaces';
import React, { useState } from 'react';
import { renderStatus } from '../../../helpers';
import {
  TabContentContainer,
  HeaderWrapper,
  StatusWrapper,
  TextWrapper,
  SpinnerContainer,
  StyledHeader,
} from '../styles';
import { TypeformModalWrapper } from '../../../../InstructorOnboarding/InstructorProfile/components/Resources/styles';
import { Body, Cap, Button } from '@careerfoundry/bubble';
import TheModal from '../../../../shared/components/TheModal';
import Typeform from '../../../../shared/components/Typeform';
import { retry } from './helpers';
import LoadingSpinner from '../../../../shared/loadingSpinner';
import Message from '../../Message';

interface IProps {
  tabData: ITabData;
  updateAdmission: (params: IUpdateAdmissionParams, tab: string) => void;
  setTabIsLoading: (isLoading: boolean) => void;
  tabLoading: boolean;
}

const AdmissionTest: React.FC<IProps> = ({
  tabData,
  updateAdmission,
  setTabIsLoading,
  tabLoading,
}) => {
  const typeformId = tabData.admissionTestLink;

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const toggleSurvey = (isOpen: boolean) => {
    setModalIsOpen(isOpen);
  };

  const handleSubmitSurvey = (event: any) => {
    const responseId = event.data.responseId;
    setTabIsLoading(true);

    const survey = new ApiService(
      `/api/v1/surveys/${typeformId}/responses/${responseId}`
    );

    retry(() => survey.makeGetCall()).then((response: any) => {
      updateAdmission(
        {
          admission_test: {
            typeform_id: responseId,
            score: response.data.data[0].calculated.score,
          },
        },
        'admissionTest'
      );
    });
  };

  return (
    <TabContentContainer>
      <HeaderWrapper>
        <StyledHeader variant='h3' tag='h3'>
          Admission Test
        </StyledHeader>
        <StatusWrapper>
          <Cap tag='p' variant='bold'>
            status:{' '}
          </Cap>
          {renderStatus(tabData.state, true)}
        </StatusWrapper>
      </HeaderWrapper>
      <div>
        {tabLoading === true ? (
          <SpinnerContainer>
            <LoadingSpinner />
          </SpinnerContainer>
        ) : (
          <div>
            <TextWrapper>
              <Body tag='p' variant='large'>
                Please complete the Admission Test. This is to make sure that
                you have understood how the course works and that you’re ready
                to move forward with your application. You can find all the
                answers in the Terms of Participation.
              </Body>
            </TextWrapper>
            {tabData.reason && (
              <Message message={tabData.reason} messageType={'error'} />
            )}
          </div>
        )}
        <Button
          id='admission-btn'
          label='Take the test'
          onClick={() => toggleSurvey(true)}
          size='standard'
          variant='primary'
          disabled={tabData.state === 'done' || tabLoading}
        />
      </div>
      <TypeformModalWrapper>
        <TheModal
          isModalOpen={modalIsOpen}
          handleClose={() => toggleSurvey(false)}
        >
          <Typeform formId={typeformId} handleSubmit={handleSubmitSurvey} />
        </TheModal>
      </TypeformModalWrapper>
    </TabContentContainer>
  );
};

export default AdmissionTest;
