import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledShowAgain from './styles/StyledShowAgain';
import { createCookie } from '../../../../utils/createCookie';

const ShowAgain = ({ cookieName }) => {
  const [showAgain, setShowAgain] = useState(false);

  const handleChange = () => {
    if (showAgain) {
      setShowAgain(false);
      createCookie(cookieName, '', -1);
    } else {
      setShowAgain(true);
      createCookie(cookieName, '');
    }
  };

  return (
    <StyledShowAgain>
      <span
        onClick={handleChange}
        className="cf-form__checkbox--open checkbox-wrapper"
      >
        <input type="checkbox" checked={showAgain} />
        <label>Don't show this screen next time</label>
      </span>
    </StyledShowAgain>
  );
};

ShowAgain.propTypes = {
  cookieName: PropTypes.string.isRequired,
};

export default ShowAgain;
