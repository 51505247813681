import { formElement, formLabel, showElementsAndLabels, hideElementsAndLabels } from "./helpers";

var formId = "mentor_billing_profile_change_request_form";
const EUCOUNTRIES = ["AT", "BE", "BG", "CY", "CZ", "DK", "EE", "FI", "FR", "GR", "HU", "HR", "IE",
                     "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"];
const UKCOUNTRYCODE = "GB";
var thisState = {};


function setupBankTransferElements() {
  hideElementsAndLabels(formId, ["payee"]);

  let countryCode = formElement(formId, "country").val();

  if (countryCode === "DE" || EUCOUNTRIES.indexOf(countryCode) > -1) {
    hideElementsAndLabels(formId, ["branch", "bank_name", "bank_street", "bank_zip", "bank_city", "bank_country"]);
    showElementsAndLabels(formId, ["iban", "bic"]);
  } else {
    showElementsAndLabels(formId, ["iban", "bic", "branch", "bank_name", "bank_street", "bank_zip", "bank_city", "bank_country"]);
  }
}

function setupPayoneerAndPayPalElements() {
  let paymentMethodLabel = formElement(formId, "payment_method").val();
  let payeeLabel = paymentMethodLabel === "Payoneer" ? "Payoneer Token*" : "Email*";
  formLabel(formId, "payee").text(payeeLabel);
  showElementsAndLabels(formId, ["payee"]);
  hideElementsAndLabels(formId, ["iban", "bic", "branch", "bank_name", "bank_street", "bank_zip", "bank_city", "bank_country"]);
}

function setupPaymentMethodDetails() {
  let paymentMethodLabel = formElement(formId, "payment_method").val();

  if (paymentMethodLabel === "Bank Transfer") {
    setupBankTransferElements();
  } else if (["Payoneer", "PayPal"].indexOf(paymentMethodLabel) > -1) {
    setupPayoneerAndPayPalElements();
  }
}

function setupCountrySetting() {
  setupPaymentMethodDetails();
}

export function init() {
  thisState.form = new window.CF.Form(".mentor-billing-profile-form");

  formElement(formId, "payment_method").on("change", setupPaymentMethodDetails);
  formElement(formId, "country").on("change", setupCountrySetting);

  setupPaymentMethodDetails();
}
