import ApiService from './ApiService';
import cfApi from './cfApi';

class UserService {
  constructor (userId) {
    this.userId = userId;
  };

  getUserData () {
    const apiService = new ApiService(`/api/v1/admin/users/${this.userId}.json`);

    return apiService.makeGetCall().then(result => {
      return result.data.data;
    })
      .catch(error => {
        return error;
      });
  };

  getCurrentUser () {
    return cfApi.get('/api/v1/me.json').then(result => {
      return result.data.data;
    })
      .catch(error => {
        return error;
      });
  };
};

export default UserService;
