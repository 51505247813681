import { CheckIcon, InfoIcon, MicroPhoneIcon, PlantIcon } from './icons';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ShowAgain from '../ShowAgain';
import StyledInstruction from './styles/StyledInstruction';
import StyledInterviewInstructions from './styles/StyledInterviewInstructions';

const InterviewInstructions = ({ setInterviewStage }) => {
  const cookieName = 'showInterviewInstructions1';
  const hasShowAgainCookie = !!document.cookie.match(
    new RegExp(cookieName, 'g')
  );

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    if (hasShowAgainCookie) {
      setInterviewStage('selectInterviewType');
    }
  }, []);

  const interviewFormSubmit = (e) => {
    e.preventDefault();
    setInterviewStage('selectInterviewType');
  };

  const instructions = [
    {
      icon: <CheckIcon />,
      lead: 'Choose',
      text: ' the type of interview you want to prepare for',
    },
    {
      icon: <MicroPhoneIcon />,
      lead: 'Record',
      text: ' your answers in audio format',
    },
    {
      icon: <PlantIcon />,
      lead: 'Review',
      text: ' your answers to track your progress',
    },
    {
      icon: <InfoIcon />,
      lead: 'Get tips',
      text: ' on answering the questions',
    },
  ];

  return (
    <StyledInterviewInstructions onSubmit={interviewFormSubmit}>
      <h4 className="ds-typography__h4 text-center">Practice Interview</h4>
      <div className="instruction-section">
        {instructions.map((instruction, index) => {
          return (
            <StyledInstruction key={index}>
              {instruction.icon}
              <p>
                <strong>{instruction.lead}</strong>
                {instruction.text}
              </p>
            </StyledInstruction>
          );
        })}
      </div>
      <ShowAgain cookieName={cookieName} />
      <div className="text-center">
        <button type="submit" className="ds-button__primary" target="_blank">
          Get started
        </button>
      </div>
    </StyledInterviewInstructions>
  );
};

InterviewInstructions.propTypes = {
  setInterviewStage: PropTypes.func.isRequired,
};

export default InterviewInstructions;
