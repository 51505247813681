import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import StyledBackgroundImage from './styles/StyledBackgroundImage';
import StyledContentSection from './styles/StyledContentSection';
import StyledHeaderContainer from './styles/StyledHeaderContainer';
import StyledImageSection from './styles/StyledImageSection';
import StyledSectionHeader from '../MainDashboard/styles/StyledSectionHeader';

const InterviewPreview = ({ openInterviewGuidelines, startInterview }) => (
  <StyledHeaderContainer>
    <StyledImageSection className="ds-grid__col-xs-5">
      <StyledBackgroundImage mobile={false} />
      <span
        className="ds-typography__body ds-typography__anchor guidelines"
        onClick={() => openInterviewGuidelines()}
      >
        View the guidelines
      </span>
    </StyledImageSection>
    <StyledContentSection className="ds-grid__col-xs-7" mobile={false}>
      <StyledSectionHeader>
        <h4 className="ds-typography__h4 text-left">Practice Interview</h4>
      </StyledSectionHeader>
      <ul>
        <li className="ds-typography__body">
          Train yourself with common interview questions
        </li>
        <li className="ds-typography__body">
          Record your answers and review them afterwards
        </li>
      </ul>
      <Link
        className="ds-button__primary action-button"
        to="/en/interview-training"
        onClick={startInterview}
      >
        Start a new practice
      </Link>
      <Link
        className="ds-button__secondary action-button"
        to="/en/interview-training"
      >
        View history
      </Link>
    </StyledContentSection>
  </StyledHeaderContainer>
);

InterviewPreview.propTypes = {
  openInterviewGuidelines: PropTypes.func.isRequired,
  startInterview: PropTypes.func.isRequired,
};

export default InterviewPreview;
