import styled from 'styled-components';

const CallFeedbackWrapperStyles = styled.div`
  h3 {
    text-align: left;
    margin: 0;
  }

  header {
    display: flex;
    justify-content: space-between;

    p {
      margin-right: 20px;
    }

    img {
      border-radius: 50%;
      width: 36px;
      height: 36px;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
      align-self: center;
    }
  }
`;

CallFeedbackWrapperStyles.displayName = 'CallFeedbackWrapperStyles';

export default CallFeedbackWrapperStyles;
