export const styles = {
  base: {
    '::placeholder': {
      color: '#a7b1b9',
      fontFamily: 'TradeGothic,Helvetica,Arial,sans-serif',
      fontWeight: 300,
    },
    color: 'rgb(34, 60, 80)',
    fontFamily: 'TradeGothic,Helvetica,Arial,sans-serif',
    fontSize: '18px',
    fontWeight: 400,
    iconColor: '#a7b1b9',
  },
};
