import styled from 'styled-components';

const StyledHighlight = styled.div`
  height: 8px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: rgba(28, 116, 136, 0.75);
  border-radius: 0px 0px 4px 4px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;

StyledHighlight.displayName = 'StyledHighlight';

export default StyledHighlight;
