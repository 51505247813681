import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../../../CareerSupportCenter/components/Loader';
import StyledButton from '../../styles/StyledButton';

const Button = ({ label, onClick, className = '', disabled, loading, type }) => (
  <StyledButton className={className} onClick={ onClick } disabled={disabled} type={type || 'button'}>
    { loading ? <Spinner /> : label }
  </StyledButton>
);

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default Button;
