import {
  ArrowIconWrapper,
  Name,
  Option,
  OptionIconWrapper,
  Options,
  ProfilePhotoWrapper,
  UserInfo,
  Wrapper,
} from './styles';
import React, { useState } from 'react';
import { ArrowIcon } from '../SideNavList/icons';
import PropTypes from 'prop-types';
import { redirectTo } from '../../../../utils/redirectTo';

const User = ({ profilePhotoUrl = '', name = '', options = [] }) => {
  const [shouldShowOptions, setShouldShowOptions] = useState(false);

  return (
    <Wrapper>
      <UserInfo onClick={() => setShouldShowOptions(!shouldShowOptions)}>
        <ArrowIconWrapper shouldShowOptions={shouldShowOptions}>
          <ArrowIcon />
        </ArrowIconWrapper>
        <ProfilePhotoWrapper>
          <img src={profilePhotoUrl} alt="profile photo" />
        </ProfilePhotoWrapper>
        <Name className="ds-typography__body--bold">{name}</Name>
      </UserInfo>
      {shouldShowOptions && (
        <Options>
          {options.map((option, index) => (
            <Option
              key={index}
              onClick={() => redirectTo({ url: option.pathname })}
            >
              <OptionIconWrapper>{option.svgIcon}</OptionIconWrapper>
              <Name className="ds-typography__body--bold">{option.name}</Name>
            </Option>
          ))}
        </Options>
      )}
    </Wrapper>
  );
};

User.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      pathname: PropTypes.string,
      svgIcon: PropTypes.element,
    })
  ),
  profilePhotoUrl: PropTypes.string,
};

export default User;
