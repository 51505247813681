import HeaderInfographic from './HeaderInfographic';
import PropTypes from 'prop-types';
import React from 'react';
import StyledJobApplicationTrackerHeader from './styles/StyledJobApplicationTrackerHeader';
import StyledParagraphWithLink from './styles/StyledParagraphWithLink';

const JobApplicationTrackerHeader = ({
  handleNewJobApplication,
  shareJobApplication,
  getApplicationConsent,
  retrievingConsent,
  jobApplications,
  userId,
}) => (
  <StyledJobApplicationTrackerHeader>
    <h2 className="ds-typography__h2 text-left">Job Application Tracker</h2>
    <HeaderInfographic
      isPreview={false}
      buttonLoading={retrievingConsent}
      onButtonClick={() =>
        shareJobApplication
          ? handleNewJobApplication()
          : getApplicationConsent(userId, jobApplications.length > 0)
      }
    />
    <StyledParagraphWithLink className="ds-typography__small text-left">
      Please note that your applications will be visible to your Career
      Specialist. For more information, refer to our&nbsp;
      <a
        href="https://careerfoundry.com/en/terms"
        className="ds-typography__anchor ds-typography__small"
        target="_blank"
        rel="noreferrer"
      >
        Terms & Conditions
      </a>
    </StyledParagraphWithLink>
  </StyledJobApplicationTrackerHeader>
);

JobApplicationTrackerHeader.propTypes = {
  getApplicationConsent: PropTypes.func.isRequired,
  handleNewJobApplication: PropTypes.func.isRequired,
  jobApplications: PropTypes.array.isRequired,
  retrievingConsent: PropTypes.bool.isRequired,
  shareJobApplication: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

export default JobApplicationTrackerHeader;
