import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import StyledMarkdown from './styles/StyledMarkdown';

const MarkdownText = ({ text }) => (
  <StyledMarkdown>
    <ReactMarkdown>{text}</ReactMarkdown>
  </StyledMarkdown>
);

MarkdownText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default MarkdownText;
