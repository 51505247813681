import {
  DesktopMessage,
  MobileMessage,
  Notifications,
  Tooltip,
} from './styles';
import React, { useEffect, useState } from 'react';
import MailIcon from './MailIcon';
import MessagingPreview from '../../containers/MessagingPreview';
import PropTypes from 'prop-types';
import useOutsideClick from '../../../../shared/hooks/useOutsideClick';
import useWindowDimensions from '../../../../shared/hooks/useWindowDimensions';

const App = ({
  chatHasLoaded,
  isExistingUser,
  isMessagingPreviewOpen,
  openMessagingWindow,
  toggleMsgPreview,
  unReadMessageCount,
}) => {
  const readNewMsgFeatureTip =
    localStorage.getItem('readNewMsgFeatureTip') === 'true';
  const [shouldShowTooltip, setShouldShowTooltip] = useState(
    isExistingUser && !readNewMsgFeatureTip
  );
  const { ref, isOutsideClick } = useOutsideClick();
  const { width } = useWindowDimensions();

  useEffect(() => {
    isOutsideClick && isMessagingPreviewOpen && toggleMsgPreview();
  }, [isOutsideClick]);

  const handleTogglePreview = (e) => {
    chatHasLoaded && toggleMsgPreview();
    handleCloseTooltip();
  };

  const handleCloseTooltip = () => {
    if (shouldShowTooltip) {
      localStorage.setItem('readNewMsgFeatureTip', true);
      setShouldShowTooltip(false);
    }
  };

  return (
    <>
      <Notifications ref={ref}>
        <MobileMessage href='/en/messaging'>
          <MailIcon unReadMessageCount={unReadMessageCount} />
        </MobileMessage>
        <DesktopMessage onClick={handleTogglePreview}>
          <MailIcon unReadMessageCount={unReadMessageCount} />
        </DesktopMessage>
        {shouldShowTooltip && (
          <Tooltip className='ds-typography__body--bold'>
            Messages have moved. You can find them here.
            <button onClick={handleCloseTooltip}>
              <i className='ds-icon__close-thin ds-typography__text' />
            </button>
          </Tooltip>
        )}
        {isMessagingPreviewOpen && width > 768 && <MessagingPreview />}
      </Notifications>
    </>
  );
};

App.propTypes = {
  chatHasLoaded: PropTypes.bool,
  isExistingUser: PropTypes.bool,
  isMessagingPreviewOpen: PropTypes.bool,
  openMessagingWindow: PropTypes.func,
  toggleMsgPreview: PropTypes.func,
  unReadMessageCount: PropTypes.number,
};

export default App;
