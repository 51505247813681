import { MOBILE_VIEW_MAX_WIDTH } from '../../constants';
import styled from 'styled-components';

const commonStyles = [
  (props) => {
    if (props.isDisabled) {
      return 'rgba(255, 255, 255, 0.40)';
    } else if (props.isSelected) {
      return 'var(--ds-color__indigo)';
    } else {
      return 'var(--ds-color__white)';
    }
  },
];

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const IconAndNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isSelected ? 'var(--ds-color__white)' : 'inherit'};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0.25rem 0;

  div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    fill: ${commonStyles[0]};

    :hover {
      fill: ${(props) => {
        if (props.isDisabled) {
          return 'rgba(255, 255, 255, 0.40)';
        } else if (props.isSelected) {
          return 'rgba(34, 60, 80, 0.75)';
        } else {
          return 'var(--ds-color__grey--400)';
        }
      }};
    }
  }

  div:nth-child(2) {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    color: ${commonStyles[0]};

    @media (min-width: ${MOBILE_VIEW_MAX_WIDTH + 1}px) {
      display: none;
    }
  }

  @media (max-width: ${MOBILE_VIEW_MAX_WIDTH}px) {
    width: 100%;
    justify-content: start;
  }
`;

export { Wrapper, IconAndNameWrapper };
