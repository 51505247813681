import App from './containers/AppContainer';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const employerDashboardContainer = document.getElementById('employerDashboard');

if (employerDashboardContainer) {
  const employerDashboardContainerRoot = createRoot(employerDashboardContainer);

  employerDashboardContainerRoot.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}
