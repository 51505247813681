import ProfileTab from '../../components/ProfileTab';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  userProfile: { answers = [], user = {} },
}) => ({
  answers,
  user,
});

export default connect(mapStateToProps)(ProfileTab);
