import OnboardingWrapper from './shared/OnboardingWrapper';
import PrivacyPolicyBody from '../../../admin/EmployerConnector/Employers/components/PrivacyPolicyBody';
import PropTypes from 'prop-types';
import React from 'react';

const PrivacyPolicy = ({ updatePolicy, employer }) => {
  const handleClick = async () => {
    await updatePolicy(employer.id, { agreed_to_privacy: true });
  };

  return (
    <OnboardingWrapper
      header="Welcome to the Employer Connector!"
      panelHeader="Privacy Policy"
      buttonLabel="Accept"
      onClick={handleClick}
    >
      <PrivacyPolicyBody />
    </OnboardingWrapper>
  );
};

PrivacyPolicy.propTypes = {
  employer: PropTypes.shape({
    id: PropTypes.number,
  }),
  updatePolicy: PropTypes.func.isRequired,
};

export default PrivacyPolicy;
