import styled from 'styled-components';

const StyledInlineError = styled.div`
    width: 75%;
    margin-left: 25%;
    margin-bottom: 10px;
    border-color: lightcoral;
    border-width: 1px;
    border-style: solid;
    display: flex;
    span {
      padding: 10px 10px 0px;
      color: white;
      margin-right: 5%;
      background: lightcoral;
      cursor: pointer;
    }
    p {
        padding-left: 5px;
        position: relative;
        top: 5px;
    }
`;

StyledInlineError.displayName = 'StyledInlineError';

export default StyledInlineError;
