import React, { useEffect } from 'react';
import { fetchRatings, openModal } from '../../actions';
import TaskSubmissionModal from '../../components/TaskSubmissionModal';
import { connect } from 'react-redux';

interface AppContainerProps {
  openModal: (isOpen: boolean) => void;
  fetchRatings: () => void;
  hasMetWsg: boolean;
  modalIsOpen: boolean;
  showTimeSurvey: boolean;
}

interface StateProps {
  taskSubmission: {
    hasMetWsg: boolean;
    modalIsOpen: boolean;
    showTimeSurvey: boolean;
  };
}

const AppContainer: React.FC<AppContainerProps> = ({
  openModal,
  fetchRatings,
  hasMetWsg,
  modalIsOpen,
  showTimeSurvey,
}) => {
  useEffect(() => {
    fetchRatings();
  }, []);

  return (
    <TaskSubmissionModal
      openModal={openModal}
      hasMetWsg={hasMetWsg}
      modalIsOpen={modalIsOpen}
      showTimeSurvey={showTimeSurvey}
    />
  );
};

export const mapStateToProps = ({
  taskSubmission: { hasMetWsg, modalIsOpen, showTimeSurvey },
}: StateProps) => ({
  hasMetWsg,
  modalIsOpen,
  showTimeSurvey,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchRatings: () => dispatch(fetchRatings()),
  openModal: (isOpen: boolean) => dispatch(openModal(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
