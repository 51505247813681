import React, { useEffect } from 'react';
import AnswerHistoryCard from '../../containers/AnswerHistoryCard';
import DashboardWrapper from '../../../CareerSupportCenter/components/DashboardWrapper';
import InterviewModal from '../../containers/InterviewModal';
import InterviewTool from '../../containers/InterviewTool';
import InterviewToolCard from '../../containers/InterviewToolCard';
import PropTypes from 'prop-types';
import StyledInterviewPrep from './styles/StyledInterviewPrep';
import { userEventTracking } from '../../../shared/helpers/tracking';

const Dashboard = ({ interviewStage }) => {
  useEffect(() => {
    userEventTracking(window.location.href, 'ip_visit_dashboard');
  }, []);

  return (
    <StyledInterviewPrep>
      <DashboardWrapper
        showButton={!interviewStage}
        header="Interview Preparation Tool"
        showHeader={!interviewStage}
        isInterviewMode={!!interviewStage}
        isReview={interviewStage === 'review'}
      >
        <div className="ds-grid__row">
          <div className="ds-grid__col-xs-12">
            {interviewStage
              ? (
                <InterviewTool />
              )
              : (
                <>
                  <InterviewToolCard />
                  <AnswerHistoryCard isMentorPage={false} />
                </>
              )}
            <InterviewModal />
          </div>
        </div>
      </DashboardWrapper>
    </StyledInterviewPrep>
  );
};

Dashboard.propTypes = {
  interviewStage: PropTypes.string,
};

export default Dashboard;
