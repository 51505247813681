import React from 'react';
import Socials from '../../../../../EmployerConnector/modals/showModal/Socials';
import { connect } from 'react-redux';
import { updateECProfile } from '../../../../../EmployerConnector/actions';

const SocialsViewContainer = (props) => {
  return (
    <Socials {...props} />
  );
};

export const mapStateToProps = ({
  adminEmployerConnector: { jobSeekingProfile },
}) => ({
  jobSeekingProfile,
});

export const mapDispatchToProps = (dispatch) => ({
  updateECProfile: (data) => dispatch(updateECProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialsViewContainer);
