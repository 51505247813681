import styled from 'styled-components';

const StyledDashboardHeader = styled.h2`
  text-align: left;

  ${({ nonStudentView }) =>
    nonStudentView &&
    `
    margin-bottom: 56px !important;
  `}

  @media (max-width: 768px) {
    font-size: 32px;
    margin-bottom: 16px;
  }
`;

StyledDashboardHeader.displayName = 'StyledDashboardHeader';

export default StyledDashboardHeader;
