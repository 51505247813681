import FAQModal from './FAQ';
import OptinDetailsModal from '../container/OptinDetailsModalContainer';
import OptinModal from '../container/OptinModalContainer';
import PropTypes from 'prop-types';
import React from 'react';
import ShowModalContainer from '../container/ShowModalContainer';

const EmployerConnectorModal = ({ ecModalType }) => {
  return (
    <>
      {
        {
          faq: <FAQModal />,
          optin: <OptinModal />,
          optinDetails: <OptinDetailsModal />,
          show: <ShowModalContainer />,
        }[ecModalType]
      }
    </>
  );
};

EmployerConnectorModal.propTypes = {
  ecModalType: PropTypes.string.isRequired,
};

export default EmployerConnectorModal;
