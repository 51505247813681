import styled from 'styled-components';

const StyledTile = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 16px 16px 0;
  padding: 24px 32px 32px;
  background-color: #f6f7f8;
  text-align: justify;

  h5 {
    margin: 0 0 8px 0;
    color: var(--ds-color__indigo);
  }

  p {
    margin: 0;
    padding: 0 5px;
  }

  ul {
    padding-left: 30px;
    text-align: left;
  }

  a {
    border-bottom: 0 !important;
    font-weight: 500 !important;
  }

  .tile-wrapper {
    margin: 8px 0 0;
    font-size: var(--lg-font);
    color: var(--ds-color__petrol);
  }
`;

StyledTile.displayName = 'StyledTile';

export default StyledTile;
