import { Header } from '@careerfoundry/bubble';
import React from 'react';
import Survey from './survey';
import { Content } from '../../styles';
import { SurveysProps } from '../../interfaces';

const Surveys: React.FC<SurveysProps> = ({ admission }) => {
  return (
    <Content>
      <Header tag='h4' variant='h4'>
        Surveys
      </Header>
      <Survey
        header='Profile Survey'
        formId='jiPcBrh4'
        data={admission['profileSurvey']}
      />
      <Survey
        header='Admissions Test'
        formId='dbYPIkK2'
        data={admission['admissionTest']}
      />
    </Content>
  );
};

export default Surveys;
