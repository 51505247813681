import React from 'react';
import { Locale } from '../../../../shared/enums';
import Tooltip from '../../../shared/components/Tooltip';
import { ChevronLeft } from '../../../shared/icons/ChevronLeft';
import { ChevronRight } from '../../../shared/icons/ChevronRight';
import { Wrapper } from './styles';

interface ExerciseNavigatorProps {
  courseSlug: string;
  shouldShowTooltip?: boolean;
  previousNavItemLabel: string;
  nextNavItemLabel: string;
  previousExerciseName?: string;
  previousExerciseSlug?: string;
  nextExerciseName?: string;
  nextExerciseSlug?: string;
}

const ExerciseNavigator: React.FC<ExerciseNavigatorProps> = ({
  courseSlug,
  shouldShowTooltip = false,
  previousNavItemLabel,
  nextNavItemLabel,
  previousExerciseName = '',
  previousExerciseSlug = '',
  nextExerciseName = '',
  nextExerciseSlug = '',
}) => {
  const PreviousNavItem = (
    <a
      href={`/${Locale.en}/course/${courseSlug}/exercise/${previousExerciseSlug}`}
      className='previous-nav-item ds-text__body--medium ds-text--bold'
    >
      <ChevronLeft />
      {previousNavItemLabel}
    </a>
  );

  const NextNavItem = (
    <a
      href={`${nextExerciseSlug}`}
      className='next-nav-item ds-text__body--medium ds-text--bold'
    >
      {nextNavItemLabel}
      <ChevronRight />
    </a>
  );

  return (
    <Wrapper>
      {previousExerciseSlug &&
        (shouldShowTooltip ? (
          <Tooltip content={previousExerciseName} place='bottom'>
            {PreviousNavItem}
          </Tooltip>
        ) : (
          <>{PreviousNavItem}</>
        ))}
      {nextExerciseSlug &&
        (shouldShowTooltip ? (
          <Tooltip content={nextExerciseName} place='bottom'>
            {NextNavItem}
          </Tooltip>
        ) : (
          <>{NextNavItem}</>
        ))}
    </Wrapper>
  );
};

export default ExerciseNavigator;
