// eslint-disable-next-line import/no-unresolved
import LoadingSpinner from '../../../../../shared/loadingSpinner';
import PaginationControls from '../../../../../shared/components/Pagination';
import PropTypes from 'prop-types';
import React from 'react';
import StyledEmployerTable from './styles/EmployerTable';
import moment from 'moment';

const EmployerTable = ({
  employers,
  loading,
  getEmployers,
  handlePageChange,
  handleUpdateEmployer,
  showEmployer,
  updateEmployer,
  activePage,
  perPage,
  totalEntries,
  setOrderBy,
  setOrderType,
  orderType,
}) => {
  const columns = [
    'Company',
    'Contact name',
    'Email',
    'Company Size',
    'Last login',
    'Status',
    'Edit',
  ];

  const handleSorting = (columnName) => {
    const toggleOrderType = orderType[columnName] === 'DESC' ? 'ASC' : 'DESC';
    setOrderType({
      ...orderType,
      [columnName]: toggleOrderType,
    });
    setOrderBy(columnName);
  };

  const MAPPED_SORTING_COLUMNS = {
    Company: 'company',
    'Contact name': 'contact_name',
    'Last login': 'last_login',
  };

  return (
    <>
      <StyledEmployerTable className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              {columns.map(column => <th key={column}>
                {column}
                {Object.keys(MAPPED_SORTING_COLUMNS).includes(column) &&
                (<a onClick={() => handleSorting(MAPPED_SORTING_COLUMNS[column])}>
                  <i className={`fa fa-sort-${orderType[MAPPED_SORTING_COLUMNS[column]] === 'DESC' ? 'down' : 'up'}`}></i>
                </a>)
                }
              </th>)}
            </tr>
          </thead>
          <tbody>
            {employers.length
              ? employers.map((row, i) => (
                <tr key={`row_${i}`}>
                  <td>{row.companyName}</td>
                  <td><a onClick={() => showEmployer(row.id)}>{row.contactName}</a></td>
                  <td>{row.email}</td>
                  <td>{row.sizeRange}</td>
                  <td>{row.lastLogin ? moment(row.lastLogin).format('lll') : ''}</td>
                  <td><a onClick={() => updateEmployer(row.id, { frozen: !row.frozen })}>{row.frozen ? 'Activate' : 'Freeze'}</a></td>
                  <td><i className="fa fa-pencil" onClick={() => handleUpdateEmployer(row.id)}></i></td>
                </tr>
              ))
              : loading
                ? <tr>
                  <td colSpan={columns.length}>
                    <LoadingSpinner />
                  </td>
                </tr>
                : <tr>
                  <td colSpan={columns.length}>
                    <p className="ds-typography__body text-center">There are no employers yet.</p>
                  </td>
                </tr>
            }
          </tbody>
        </table>
      </StyledEmployerTable>
      <PaginationControls
        activePage={activePage}
        itemsCountPerPage={perPage}
        totalItemsCount={totalEntries}
        pageRangeDisplayed={perPage}
        onChange={handlePageChange}
      />
    </>
  );
};

EmployerTable.propTypes = {
  activePage: PropTypes.number.isRequired,
  employers: PropTypes.array.isRequired,
  getEmployers: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleUpdateEmployer: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  orderType: PropTypes.object.isRequired,
  perPage: PropTypes.number.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  setOrderType: PropTypes.func.isRequired,
  showEmployer: PropTypes.func.isRequired,
  totalEntries: PropTypes.number.isRequired,
  updateEmployer: PropTypes.func.isRequired,
};

export default EmployerTable;
