import { Content } from '../../../../shared/styles/Content';
import styled from 'styled-components';

const Wrapper = styled(Content)`
  h1 {
    text-align: left;
  }
`;

const LeadWrapper = styled.div`
  position: relative;

  i {
    height: 20px;
    display: inline;
    position: absolute;
    top: 0;
    right: 0;
  }

  p {
    max-width: 700px;
    display: inline-block;
  }

  span > div.__react_component_tooltip {
    max-width: 332px;
  }
`;

export { LeadWrapper, Wrapper };
