import React from 'react';

const TermsAndConditionsBody = () => (
  <>
    <p className='ds-typography__body'>
      <strong>1. Scope of the Terms</strong>
    </p>
    <p className='ds-typography__body'>
      1.1 CareerFoundry GmbH, c/o THE GATE, Torstraße 177 10115 Berlin,
      registered with the commercial register of the local court (Amtsgericht)
      of Charlottenburg under HRB 155933 B (“CareerFoundry”) provides online
      courses on its website careerfoundry.com (“Website”).
    </p>
    <p className='ds-typography__body'>
      1.2 These terms (“Terms”) apply to all services provided by CareerFoundry
      on its or through its Website (“Services”) to the person using the
      Services and who has accepted the Terms (hereafter referred to
      interchangeably as “User” or “Student”) unless otherwise noted in the
      Terms. The User may access the current version of the Terms at any time on
      the Website and may print, download and/or save it.
    </p>
    <p className='ds-typography__body'>
      1.3 These Terms shall apply exclusively. Differing or contrary terms of
      the User do not apply, except if expressly agreed upon in writing by
      CareerFoundry.
    </p>
    <p className='ds-typography__body'>
      <strong>2. Description of Services and Conclusion of Contract</strong>
    </p>
    <p className='ds-typography__body'>
      2.1 (General) CareerFoundry has established several online learning
      programs, which have been designed to impart skills and knowledge to its
      Users to accelerate a future or ongoing career in the technology industry
      (each a “Program”, together the “Programs”). Each Program is scheduled for
      a fixed period of time. Once the User has enrolled in a Program, the
      course material will be accessible through the Website. If the User
      participates in an instructor-supported Program, they may schedule an
      online video call with a CareerFoundry mentor (“Mentor”) to discuss and
      clarify any open questions related to the Program at dedicated intervals
      depending on their chosen Program. They may also make written contact via
      the learning portal with a CareerFoundry Tutor (“Tutor”) to discuss course
      content. Selected assignments will be corrected by the Mentor or Tutor.
      The User can find a detailed service description of each Program on the
      Website.
    </p>
    <p className='ds-typography__body'>
      2.2 (Booking of Programs) After the registration on the Website or logging
      into their account, the User may set up a meeting with a Career Specialist
      free of charge. If the User has decided to participate in a Program the
      booking can be made on the Website. The representation of the respective
      Programs on the Website represents a binding contract offer by
      CareerFoundry to its Users (“Offer”).
    </p>
    <p className='ds-typography__body'>
      2.3 The User accepts the Offer by clicking the corresponding order button
      provided on the website (“Acceptance”). CareerFoundry notifies the User
      immediately that it has received their booking. Upon receiving the
      booking, CareerFoundry enables the User to have access to the booked
      Program on the agreed upon Program Start Date through the User's account.
    </p>
    <p className='ds-typography__body'>
      2.4 Deviating from the provisions under Section 2.3 the following shall
      apply for the conclusion of contracts with Users, who have their place of
      residence in Germany: In order to conclude the contract, the User shall
      complete the contract form on the Website and print, sign and send the
      completed form via post to CareerFoundry GmbH, c/o THE GATE Görlitzer
      Park, Torstraße 177, 10115 Berlin (“Written Acceptance”). CareerFoundry
      will send an acknowledgement of receipt to the User's email address. In
      the event of inconsistencies the provisions of the contract form shall
      take precedence over the provisions of these terms.
    </p>
    <p className='ds-typography__body'>
      2.5 CareerFoundry's receipt of the Acceptance or the acknowledgement of
      receipt of the Written Acceptance, as the case may be, shall mark the
      point at which the contract between CareerFoundry and the User becomes
      binding.
    </p>
    <p className='ds-typography__body'>
      2.6 Bookings placed by minors or persons of legal incapacity require the
      consent of the respective legal guardian and/or conservatorship.
    </p>
    <p className='ds-typography__body'>
      <strong>2.7 Right of Refusal of Admission</strong>
    </p>
    <p className='ds-typography__body'>
      CareerFoundry reserves the right to refuse to issue a contract for sale to
      prospective customers for reasons including but not limited to the
      following:
    </p>
    <ul>
      <li className='ds-typography__body'>
        If the customer has given the Program Advisor reason to believe that
        they would not be able to pass any background checks associated with
        employment in the customer’s country/state of residence;
      </li>
      <li className='ds-typography__body'>
        Previous termination of an existing contract;
      </li>
      <li className='ds-typography__body'>
        An assessment by the Program Advisor that the course
        delivery/instruction model is unsuitable for the customer’s goals and/or
        learning needs
      </li>
    </ul>
    <p className='ds-typography__body'>
      If the Program Advisor or any other member of the CareerFoundry team makes
      the decision to refuse to enrol a customer, this decision will be issued
      in writing and will be final.
    </p>
    <p className='ds-typography__body'>
      <strong>3. Information on the Course Certificates</strong>
    </p>
    <p className='ds-typography__body'>
      The certificates issued by CareerFoundry after the completion of a Program
      are internal certificates and do not have the aim to prepare the User for
      government or public examinations. CareerFoundry’s certificates expressly
      do not represent or substitute any form of state or state recognized
      certificate.
    </p>
    <p className='ds-typography__body'>
      <strong>4. Registration</strong>
    </p>
    <p className='ds-typography__body'>
      4.1 In order to use the Services, the User must register for an account on
      the Website (“Account”). The registration is free of charge. The User must
      provide accurate and complete information and keep the Account information
      updated.
    </p>
    <p className='ds-typography__body'>
      4.2 The User is solely responsible for the activity that occurs on their
      Account. The User shall keep their login data (Username and password)
      confidential and prevent any unauthorized use by third parties. The User
      shall immediately inform CareerFoundry if there are indications that any
      third party is misusing his account.
    </p>
    <p className='ds-typography__body'>
      4.3 The User may not use names or other personal data for their Account
      that could infringe the rights of any third party. CareerFoundry may
      restrict, block, or delete an Account if the User has given false
      information during the registration or if the User violates applicable
      laws, regulation or these Terms. When choosing one of the aforementioned
      measures, CareerFoundry will consider the impact of the false information,
      the legitimate interests of the User and the degree of responsibility.
    </p>
    <p className='ds-typography__body'>
      4.4 The User may delete their Account at any time without providing a
      reason or notice, by sending an e-mail to datenschutz@careerfoundry.com.
      CareerFoundry advises the User that if they delete their Account, any
      unlocked course material will no longer be available to them through the
      Website.
    </p>
    <p className='ds-typography__body'>
      <strong>5. Booking and Cancellation of Mentor Calls</strong>
    </p>
    <p className='ds-typography__body'>
      If the User participates in a mentor-supported program or the Job
      Preparation Course and books a Mentor or Career Specialist call, the User
      shall book such call more than 48 hours in advance. If the User is no
      longer able to take such call, the User is required to cancel the booking
      no less than 24 hours prior to the booked call. If the booking is
      cancelled less than 24 hours in advance, the call shall be deemed as
      having taken place and will be deducted from the User’s allowance of
      calls.
    </p>
    <p className='ds-typography__body'>
      The number of calls a User is entitled to depends on their specific
      program or course.
    </p>
    <p className='ds-typography__body'>
      <strong>6. Payments and Billing Options</strong>
    </p>
    <p className='ds-typography__body'>
      6.1 The course fees arise from the respective current price list at the
      time of conclusion of the contract, which is accessible on the Website at
      all times. The obligation to pay the course fee shall apply irrespective
      of whether the User uses the provided Services or not (without limiting
      the User's warranty rights).
    </p>
    <p className='ds-typography__body'>
      6.2 CareerFoundry offers the possibility for cashless payment by way of
      the payment methods as indicated on the Website. CareerFoundry reserves
      the right to offer certain payment options to some Users and not to other
      Users.
    </p>
    <p className='ds-typography__body'>
      6.3 The course fees can be paid in installments. The first installment is
      due on enrollment date, the second installment is due one month after the
      Program start date, and the following installments are due monthly
      beginning from the second installment date. If the User chooses to pay via
      bank transfer, the total course fee shall be due immediately after receipt
      of the invoice. Failure of the User to pay installments owed will result
      in the User losing access to the Program content.
    </p>
    <p className='ds-typography__body'>
      6.4 Each Program allows access to mentoring for a fixed period of time.
      However, the User can extend the Program indefinitely (for as long as that
      Program is offered by CareerFoundry) by opting to continue to pay a
      monthly course extension fee towards the end of the chosen Program. The
      User can opt to continue paying the monthly course fees by purchasing the
      extensions themselves via the student platform, or reaching out to their
      Student Advisor. The User’s right to terminate the contract according to
      section 7 shall remain unaffected.
    </p>
    <p className='ds-typography__body'>
      6.5 Only one promotional discount code may be used per order. The discount
      code will be applied to the total price of your payment plan or upfront
      payment.
    </p>
    <p className='ds-typography__body'>
      6.6 CareerFoundry reserves the right to withdraw, amend, or cancel a
      discount offer at any time. This will not impact Users who have already
      enrolled with a discount code.
    </p>
    <p className='ds-typography__body'>
      <strong>7. Contract Term, Refund Policy and Termination</strong>
    </p>
    <p>7.1 The contract is entered into for the period booked by the User.</p>
    <p className='ds-typography__body'>
      7.2 All Programs can be extended indefinitely for as long as the Program
      continues to be offered by CareerFoundry by opting to continue to pay the
      monthly extension fee for the chosen Program; see Section 6.4.
    </p>
    <p className='ds-typography__body'>
      7.3 Beyond statutory requirements (see Section 8. of these Terms),
      CareerFoundry provides the User with a full refund if they withdraw within
      the first 14 days from the day of the Program start date (the “Refund
      Period”), i.e. CareerFoundry will not charge the User for services which
      have already been provided to him or her.
    </p>
    <p className='ds-typography__body'>
      <strong>7.3.1 Cancellations in order to re-enrol</strong>
    </p>
    <p className='ds-typography__body'>
      Under German law, Users must cancel their original enrolment in order to
      change courses, as a new invoice and payment record must be generated for
      the new sale.
    </p>
    <p className='ds-typography__body'>
      If a User cancels their enrolment in order to change to a new Program or
      Course, they may only do so once (no further enrolment changes will be
      permitted).
    </p>
    <p className='ds-typography__body'>
      7.4 The User may terminate the contract without reason at any time after
      the Program begins, except after completion of the Program. In the case of
      a termination of the contract any pending installments at the day of
      termination shall be canceled. This does not affect the right of the
      parties to issue a termination for good cause and it is irrespective of
      the right of withdrawal (see Section 8 of these Terms).
    </p>
    <p className='ds-typography__body'>
      7.5 The termination of this contract according to section 7.3 or 7.4 shall
      require a written notification (a message to the Student Advisors via the
      in-platform messaging tool is sufficient) and shall become effective on
      the date of the receipt of such notification by the respective other
      party.
    </p>
  </>
);

export default TermsAndConditionsBody;
