import styled from 'styled-components';

const StyledBannerImage = styled.div`
  a {
    margin-left: 32px;
    :hover {
      color: var(--ds-color__indigo) !important;
      background-color: transparent !important;
    }

    :visited {
      background-color: transparent !important;
    }
  }
  }
  position: relative;
  height: 385px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.backgroundImage});
`;

StyledBannerImage.displayName = 'StyledBannerImage';

export default StyledBannerImage;
