import React from 'react';

export const ChevronLeft: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6655 1.45476C11.7505 1.53932 11.8179 1.63983 11.8639 1.75052C11.9099 1.8612 11.9336 1.97989 11.9336 2.09976C11.9336 2.21963 11.9099 2.33832 11.8639 2.44901C11.8179 2.55969 11.7505 2.6602 11.6655 2.74476L6.55511 8.31476L11.6951 13.9248C11.7763 14.0132 11.8391 14.1168 11.88 14.2297C11.921 14.3425 11.9392 14.4623 11.9336 14.5822C11.928 14.7022 11.8988 14.8198 11.8475 14.9283C11.7963 15.0369 11.7241 15.1342 11.6351 15.2148C11.5467 15.2959 11.443 15.3588 11.3302 15.3997C11.2173 15.4406 11.0975 15.4588 10.9776 15.4532C10.8577 15.4477 10.7401 15.4184 10.6315 15.3672C10.523 15.316 10.4256 15.2438 10.3451 15.1548L4.04511 8.32476L10.3755 1.51476C10.4561 1.42575 10.5534 1.35355 10.662 1.30233C10.7705 1.2511 10.8881 1.22186 11.008 1.21628C11.1279 1.2107 11.2478 1.2289 11.3606 1.26983C11.4734 1.31076 11.5771 1.37361 11.6655 1.45476Z'
      fill='#223C50'
    />
  </svg>
);
