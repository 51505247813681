import { InputWrapper, StyledInput } from '../App/styles';
import { Field } from 'formik';
import React from 'react';
import Select from '../../../shared/components/Select';

const NameFields = () => (
  <>
    <h4 className='ds-typography__h4'>About you</h4>
    <div>
      <InputWrapper>
        <StyledInput>
          <Field
            type='text'
            name='contactName'
            placeholder='Legal full name'
            required={true}
          />
        </StyledInput>
        <StyledInput>
          <Field
            type='text'
            name='phoneNumber'
            placeholder='Phone number'
            required={true}
          />
        </StyledInput>
      </InputWrapper>
      <InputWrapper>
        <StyledInput>
          <Field
            as={Select}
            type='text'
            name='payoutType'
            placeholder='payout type'
            required={true}
          >
            <option value=''>I'am..</option>
            <option value='private_contributor'>
              I'm a private contributor
            </option>
            <option value='company'>I'm a company</option>
          </Field>
        </StyledInput>
        <StyledInput>
          <Field
            type='text'
            name='businessName'
            placeholder='Business name (if applicable)'
          />
        </StyledInput>
      </InputWrapper>
    </div>
  </>
);

export default NameFields;
