import TopNavBar from '../../components/TopNavBar';
import { connect } from 'react-redux';
import { setIsVisible as setIsSideNavListVisible } from '../../../SideNavList/actions';
import { Dispatch } from 'redux';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsSideNavListVisible: (isSideNavListVisible: boolean) =>
    dispatch(setIsSideNavListVisible(isSideNavListVisible)),
});

export default connect(null, mapDispatchToProps)(TopNavBar);
