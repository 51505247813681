import styled from 'styled-components';

const ExerciseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--ds-color__grey--400);

  div:first-of-type {
    display: inline-block;
  }

  p:first-of-type {
    margin-bottom: 0.5rem;
    color: var(--ds-color__indigo);
    font-size: 18px; // TODO: Use CSS class from design system when available
  }

  p:last-of-type {
    color: var(--ds-color__petrol);
    margin-bottom: 0;
  }

  button {
    align-self: flex-start;
  }

  @media (max-width: 1023px) {
    align-items: flex-start;
    flex-direction: column;

    button {
      margin-top: 1rem;
    }
  }
`;

ExerciseWrapper.displayName = 'ExerciseWrapper';

export default ExerciseWrapper;
