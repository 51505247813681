import ForumIcon from '../../../components/icons/ForumIcon';
import React from 'react';
import { connect } from 'react-redux';
import { getCourse } from '../../../../shared/helpers/courseHelpers';
import { setShouldShowDataPolicyAgreementModal } from '../../../actions';

const mapStateToProps = (state) => {
  const { common, discussionsUserData, attendeesData } = state.sideNavList;
  const {
    isStudent,
    isAnyCourseActive,
    userDefinedActiveCourse,
    isUserActive,
  } = common;
  const isAnyCourseStarted = !!getCourse({
    courses: attendeesData.attendees || [],
    state: 'started',
  });
  const isDisabled = isStudent && !isAnyCourseActive && !isAnyCourseStarted;
  const isVisible = isUserActive;

  return {
    activeCourseSlug: userDefinedActiveCourse?.friendlyCourseId || '',
    isAgreedToDiscoursePrivacyPolicy:
      discussionsUserData.agreedToDiscoursePrivacyPolicy || false,
    isDisabled,
    isStudent,
    isVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setShouldShowDataPolicyAgreementModal: (shouldShowDataPolicyAgreementModal) =>
    dispatch(
      setShouldShowDataPolicyAgreementModal(shouldShowDataPolicyAgreementModal)
    ),
});

const ForumIconContainer = (props) => {
  return <ForumIcon {...props} />;
};

ForumIconContainer.propTypes = {
  ...ForumIcon.propTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForumIconContainer);
