import React, { useEffect } from 'react';
import App from '../../components/App';
import Card from '../../../shared/styles/Card';
import { Content } from '../../../shared/styles/Content';
import Loading from '../../../shared/components/Loading';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInitialData } from '../../actions';

export const AppContainer = ({ getInitialData, loading }) => {
  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div className="ds-grid__container">
      <Card>
        <Content>
          <Loading isLoading={loading}>
            <App />
          </Loading>
        </Content>
      </Card>
    </div>
  );
};

AppContainer.propTypes = {
  getInitialData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export const mapStateToProps = ({ callEdits: { loading } }) => ({
  loading,
});

export const mapDispatchToProps = (dispatch) => ({
  getInitialData: () => dispatch(getInitialData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
