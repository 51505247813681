import {
  courseNameShortner,
  programNameShortner,
} from '../../utils/nameShortners';
import React from 'react';
import { renderPrettyDate } from '../../utils/datetime';
import { stringTruncate } from '../../utils/stringTruncate';

const renderNameWithLink = (user) => {
  if (!user) return '-';
  return <a href={user.url}>{user.name}</a>;
};

const renderNameEmailNotes = (user, notesCounter, adminNotesClickHander) => {
  return (
    <div className='name-email-notes-grid'>
      <div className='name-email-notes-grid-item'>
        <span
          className='fa-stack fa-1x admin-notes-modal-button'
          onClick={() => adminNotesClickHander(user.id)}
        >
          <i className='fa fa-comment-o fa-stack-2x'></i>
          <span className='fa fa-stack-1x'>{notesCounter}</span>
        </span>
      </div>
      <div className='name-email-notes-grid-item'>
        {renderNameWithLink(user)}
        <br />
        {stringTruncate(user.email, 25)}
      </div>
    </div>
  );
};

const renderCourseProgram = (course, program) => {
  course = courseNameShortner(course);
  return (
    <div className='txtcenter'>
      <span className='afa-index-program-name'>{program}</span>
      <br />
      {course}
    </div>
  );
};

const formatProgress = (percent, completed, total) => {
  return (
    <div>
      {percent}%
      <br />({completed}/{total})
    </div>
  );
};

export const formatRows = (crudeRows, adminNotesClickHander) => {
  return crudeRows.map((row) => {
    const nameEmailNotes = renderNameEmailNotes(
      row.user,
      row.notesCounter,
      adminNotesClickHander
    );

    const programName = row.program
      ? programNameShortner(row.program.name)
      : '';
    const courseProgram = renderCourseProgram(row.course.name, programName);

    const start = renderPrettyDate(row.course.startDate);
    const end = renderPrettyDate(row.course.endDate);
    const progressPercent = formatProgress(
      row.course.progressPercentage,
      row.course.completedExercises,
      row.course.totalExercises
    );
    const lastSubmission = renderPrettyDate(
      row.course.lastApprovedSubmissionAt
    );
    const lastActivity = renderPrettyDate(row.user.lastActivityAt);
    const wsg = row.weeklySubmissionGoal;
    const mentor = renderNameWithLink(row.mentor);
    const tutor = renderNameWithLink(row.tutor);
    const jobPrepProgress = formatProgress(
      row.jobPrep.progress,
      row.jobPrep.completedExercises,
      row.jobPrep.totalExercises
    );
    return [
      nameEmailNotes,
      courseProgram,
      start,
      end,
      progressPercent,
      jobPrepProgress,
      lastSubmission,
      lastActivity,
      wsg,
      mentor,
      tutor,
    ];
  });
};
