import App from './App';
import React from 'react';
import { createRoot } from 'react-dom/client';

const sepaOneTimePaymentsContainer = document.querySelector(
  '.sepa_one_time_payment__wrapper--js'
);

if (sepaOneTimePaymentsContainer) {
  const sepaOneTimePaymentsContainerRoot = createRoot(
    sepaOneTimePaymentsContainer
  );

  sepaOneTimePaymentsContainerRoot.render(<App />);
}
