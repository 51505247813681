import React from 'react';

export const ChevronRight: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.33448 15.0452C4.24952 14.9607 4.1821 14.8602 4.1361 14.7495C4.09009 14.6388 4.06641 14.5201 4.06641 14.4002C4.06641 14.2804 4.09009 14.1617 4.1361 14.051C4.1821 13.9403 4.24952 13.8398 4.33448 13.7552L9.44489 8.18524L4.30489 2.57524C4.22374 2.48679 4.16089 2.38316 4.11996 2.27032C4.07904 2.15748 4.06084 2.03766 4.06641 1.91775C4.07199 1.79785 4.10123 1.68023 4.15246 1.57168C4.20369 1.46312 4.27588 1.36577 4.36489 1.28524C4.45334 1.20409 4.55697 1.14124 4.66981 1.10031C4.78265 1.05938 4.90248 1.04118 5.02238 1.04676C5.14229 1.05234 5.2599 1.08158 5.36846 1.13281C5.47701 1.18403 5.57436 1.25623 5.65489 1.34524L11.9549 8.17524L5.62448 14.9852C5.54395 15.0742 5.4466 15.1464 5.33805 15.1977C5.22949 15.2489 5.11187 15.2781 4.99197 15.2837C4.87206 15.2893 4.75224 15.2711 4.6394 15.2302C4.52656 15.1892 4.42293 15.1264 4.33448 15.0452Z'
      fill='#223C50'
    />
  </svg>
);
