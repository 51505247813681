import styled from 'styled-components';

const Table = styled.div`
  table {
    margin-bottom: 40px;

    th, td {
      word-wrap: break-word;
      max-width: 150px;
    }

    thead {
      background-color: var(--ds-color__indigo);
      color: var(--ds-color__white);

      th {
          display: table-cell;
          vertical-align: middle;
          font-weight: bold;
        }
    }
  }

  i {
    margin-left: 10px;
    color: white;
  }


  table > tbody > tr:nth-child(even) > td,
  table > tbody > tr:nth-child(even) > th {
    background-color: #eef0f1;
  }
`;

Table.displayName = 'Table';

export default Table;
