const checkToDisplay = (toursCompleted, tourArea) => {
  return (toursCompleted.length) ? !toursCompleted.find(tour => tour === tourArea) : true;
};

export const shouldShowTour = (tours, onboardingStatus, tourArea) => {
  return (onboardingStatus && checkToDisplay(tours, tourArea));
};

export const tourCompleted = (tours, onboardingStatus, tourArea) => {
  return onboardingStatus && tours.find(tour => tour === tourArea);
};
