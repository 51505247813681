import Card from '../../Dashboard/styles/Card';
import styled from 'styled-components';

const StyledInterviewToolCard = styled(Card)`
  margin-top: 0 !important;
  margin-bottom: 27px;

  @media (max-width: 768px) {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
`;

StyledInterviewToolCard.displayName = 'StyledInterviewToolCard';

export default StyledInterviewToolCard;
