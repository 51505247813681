import React from 'react';
import StyledLargeTile from '../../UsefulLinks/styles/StyledLargeTile';

const CallsTile = () => (
  <StyledLargeTile
    className='tour__calendar'
    description='You have calls left with your career specialist. Please check their calendar to book them.'
    icon={
      <img
        src='https://coach-courses-us.s3.amazonaws.com/public/icons/csc-telephone.png'
        alt='icon'
      />
    }
    secondaryButton={{ link: '/en/career?course_id=55', text: 'Open calendar' }}
    title='Calls'
  />
);

export default CallsTile;
