import styled from 'styled-components';

const Separator = styled.div`
    height: 2px;
    margin: 11.1px 14px 23.9px 0px;
    transform: rotate(0.05deg);
    opacity: 0.5;
    border: solid 1px #d8d8d8;
    background-color: #d8d8d8;
`;

Separator.displayName = 'Separator';

export default Separator;
