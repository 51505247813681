import styled from 'styled-components';

const MotivationalMessageCard = styled.div`
  position: relative;
  padding: 0 48px 24px 22px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 14px -1px #223c5029;
  border-radius: 4px;

  .navigation-section {
    display: flex;
    align-items: center;
    padding-top: 5px;
    text-align: center;
    position: relative;
    bottom: 0;
    left: 0;
    margin: 10px 0px;
    i {
      padding: 0px 4px;
    }
    p {
      margin: 0;
      font-size: 24px;
      font-family: var(--ds-font__dinpro--cond);
      padding: 0px 8px;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 400 !important;
    line-height: 32px;
    margin-bottom: 0 !important;
  }

  h5 {
    font-weight: 600;
    margin: 10px 0px;
  }
  .header-section {
    display: inline-flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 32px;
  }

  .grey {
    color: var(--ds-color__grey--light);
  }
  .clickable {
    cursor: pointer;
  }
  .italic {
    font-style: italic;
  }

  @media (max-width: 768px) {
    padding: 0 0 16px;
    flex-direction: column;
  }
`;

MotivationalMessageCard.displayName = 'MotivationalMessageCard';

export default MotivationalMessageCard;
