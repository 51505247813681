export const draw = (
  canvas,
  canvasCtx,
  analyser,
  dataArray,
  bufferLength,
  drawVisuals,
) => {
  const WIDTH = canvas.width;
  const HEIGHT = canvas.height;

  requestAnimationFrame(() => draw(canvas, canvasCtx, analyser, dataArray, bufferLength, drawVisuals));

  analyser.getByteTimeDomainData(dataArray);

  canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

  canvasCtx.lineWidth = 2;
  canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

  canvasCtx.beginPath();

  if (drawVisuals) {
    calculateAudioLine(WIDTH, bufferLength, HEIGHT, dataArray, canvasCtx);
    canvasCtx.lineTo(canvas.width, canvas.height / 2);
  } else {
    canvasCtx.moveTo(5, HEIGHT / 2);
    canvasCtx.lineTo(WIDTH, HEIGHT / 2);
  }
  canvasCtx.stroke();
};

const calculateAudioLine = (
  WIDTH,
  bufferLength,
  HEIGHT,
  dataArray,
  canvasCtx,
) => {
  const sliceWidth = (WIDTH * 1.0) / bufferLength;
  let x = 0;

  for (let i = 0; i < bufferLength; i++) {
    const v = dataArray[i] / 128.0;
    const y = (v * HEIGHT) / 2;

    if (i === 0) {
      canvasCtx.moveTo(x, y);
    } else {
      canvasCtx.lineTo(x, y);
    }

    x += sliceWidth;
  }
};
