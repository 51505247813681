import styled from 'styled-components';

const Notifications = styled.div`
  background: inherit;
  border: none;
  cursor: pointer;
  display: flex;
  position: relative;

  .cf-nav__counter {
    position: absolute;
    top: -12px;
    left: 12px;
    background: tomato;
    color: white;
    border-radius: 13px;
    padding: 3px 7px 0;
    line-height: 1.8;
    font-size: 10px;
    border: 2px solid white;
  }
`;

const DesktopMessage = styled.div`
  @media (max-width: 767px) {
    display: none !important;
  }
`;

const MobileMessage = styled.a`
  @media (min-width: 768px) {
    display: none !important;
  }
`;

const Tooltip = styled.span`
  align-items: flex-start;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(51, 51, 51, 0.1);
  color: var(--ds-color__indigo);
  display: flex;
  font-weight: bold;
  min-width: 235px;
  padding: 16px;
  position: absolute;
  right: 9px;
  text-align: left;
  top: 35px;
  transition: visibility 0.2s;
  z-index: 1;

  @media (max-width: 768px) {
    display: none;
  }

  &::before {
    border-bottom: 8px solid transparent;
    border-right: 8px solid #f2f2f2;
    border-top: 8px solid transparent;
    content: '';
    display: block;
    height: 0;
    left: 91%;
    position: absolute;
    top: -12px;
    transform: rotate(90deg);
    width: 0;
  }

  button {
    background-color: inherit;
    border: none;
    display: block;
    padding: 0 0 0 5px;
  }
`;

Tooltip.displayName = 'Tooltip';
DesktopMessage.displayName = 'DesktopMessage';
MobileMessage.displayName = 'MobileMessage';
Notifications.displayName = 'Notifications';

export { DesktopMessage, MobileMessage, Notifications, Tooltip };
