import {
  StyledAccordionItem,
  AccordionContent,
  StyledAccordionItemButton,
  StatusWrapper,
  HeaderWrapper,
  StyledTooltipContent,
  TextWrapper,
  List,
} from '../styles';
import { Body, Button, Cap, Header } from '@careerfoundry/bubble';
import ConfirmationModal from '../../../ConfirmationModal';
import React, { useState } from 'react';
import Tooltip from '../../../../../shared/components/Tooltip';
import { AccordionItemHeading } from 'react-accessible-accordion';
import { TooltipContent1 } from '../helpers';
import { renderStatus } from '../../../../helpers';
import { ITabData, IUpdateAdmissionParams } from '../../../../interfaces';

const listItems = [
  'Leave all fields free for us to fill out',
  'Send via regular post and not registered post (i.e. do not “Einschreiben”)',
  'Only mail the “Bildungsgutschein” (send accompanying documents to the Agentur für Arbeit/Jobcenter)',
  'Students based in Berlin, please feel free to drop your Bildungsgutschein in our mailbox in the Hinterhof at Torstr. 177 in an envelope for CareerFoundry - Institutional Sales.',
];

interface IProps {
  selectedSubTab: string;
  tabData: ITabData;
  updateAdmission: (params: IUpdateAdmissionParams, tab: string) => void;
}

const SecondTab: React.FC<IProps> = ({
  selectedSubTab,
  tabData,
  updateAdmission,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const handleModal = () => setModalIsOpen(!modalIsOpen);

  const handleSubmit = () => {
    updateAdmission(
      {
        afa_voucher: {
          physical_voucher_sent: true,
        },
      },
      'afaVoucher'
    );
    handleModal();
  };

  return (
    <>
      <StyledAccordionItem
        uuid='2'
        dangerouslySetExpanded={selectedSubTab === '2'}
      >
        <AccordionItemHeading>
          <StyledAccordionItemButton
            locked={(tabData.state !== 'done').toString()}
          >
            <Header variant='h3' tag='h3'>
              Bildungsgutschein (Step 2 of 2)
            </Header>
            <StatusWrapper state={tabData.state}>
              <Cap tag='p' variant='bold'>
                {tabData.state === 'done' && 'status: '}
              </Cap>
              {tabData.physicalVoucherSentAt &&
              tabData.physicalVoucherState !== 'done' ? (
                <Cap tag='p' variant='bold'>
                  sent {tabData.physicalVoucherSentAt}
                </Cap>
              ) : (
                renderStatus(
                  tabData.physicalVoucherState || '',
                  tabData.state === 'done'
                )
              )}
            </StatusWrapper>
          </StyledAccordionItemButton>
        </AccordionItemHeading>
        <AccordionContent>
          {(tabData.physicalVoucherState === 'done' &&
            tabData.physicalVoucherSentAt) ||
          tabData.type === 'digital' ? (
            <Body tag='p' variant='large-bold'>
              Good news! We've received your Bildungsgutschein.
            </Body>
          ) : (
            <>
              <HeaderWrapper>
                <Body tag='p' variant='large-bold'>
                  Mail us the physical “Bildungsgutschein” training voucher by
                  post
                </Body>
                <Tooltip
                  content={<TooltipContent1 />}
                  customStyledComponentWrapper={StyledTooltipContent}
                >
                  <i className='ds-icon__help-line'></i>
                </Tooltip>
              </HeaderWrapper>
              <TextWrapper>
                <Body tag='p' variant='large'>
                  Before sending the physical copy of your blank voucher please:
                </Body>
                <List>
                  {listItems.map((item) => (
                    <li key={item} className='ds-text__body--large'>
                      {item}
                    </li>
                  ))}
                </List>
                <Body tag='p' variant='large'>
                  We look forward to receiving your “Bildungsgutschein” training
                  voucher mailed to:
                </Body>
                <Body tag='p' variant='large'>
                  CareerFoundry GmbH <br />
                  Institutional Sales <br />
                  c/o THE GATE <br />
                  Torstraße 177 10115 Berlin
                </Body>
              </TextWrapper>
              {!tabData.physicalVoucherSentAt && (
                <Button
                  label='Mark as Sent'
                  onClick={handleModal}
                  size='standard'
                  variant='primary'
                />
              )}
            </>
          )}
        </AccordionContent>
      </StyledAccordionItem>
      <ConfirmationModal
        modalIsOpen={modalIsOpen}
        handleClose={handleModal}
        handleConfirm={handleSubmit}
      />
    </>
  );
};

export default SecondTab;
