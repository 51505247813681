import React from 'react';
import SignupForm from './components/SignupForm';
import { createRoot } from 'react-dom/client';

const afaSignupContainer = document.getElementById('afaSignup');
const email = afaSignupContainer?.dataset.email;

if (afaSignupContainer && email) {
  const afaSignupContainerRoot = createRoot(afaSignupContainer);

  afaSignupContainerRoot.render(<SignupForm email={email} />);
}
