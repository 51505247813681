import React, { useEffect } from 'react';
import {
  createCandidate,
  initCandidateForm,
  updateCandidate,
} from '../../actions';
import CandidateForm from '../../components/CandidateForm';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const CandidateFormContainer = ({
  candidate,
  createCandidate,
  initCandidateForm,
  candidateForm,
  updateCandidate,
}) => {
  useEffect(() => {
    initCandidateForm();
  }, []);

  return (
    <CandidateForm
      candidate={candidate}
      loading={candidateForm.loading}
      createCandidate={createCandidate}
      updateCandidate={updateCandidate}
      formAttributes={candidateForm.form_attributes}
      currentCourses={candidateForm.courses}
    />
  );
};

CandidateFormContainer.propTypes = {
  candidate: PropTypes.object,
  candidateForm: PropTypes.object.isRequired,
  createCandidate: PropTypes.func.isRequired,
  initCandidateForm: PropTypes.func.isRequired,
  updateCandidate: PropTypes.func.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  createCandidate: (payload) => dispatch(createCandidate({ payload: payload })),
  initCandidateForm: () => dispatch(initCandidateForm()),
  updateCandidate: (id, payload) =>
    dispatch(updateCandidate({ candidateId: id, payload: payload })),
});

export const mapStateToProps = ({ instructorsCandidatesPage }) => ({
  candidate: instructorsCandidatesPage.candidate,
  candidateForm: instructorsCandidatesPage.candidateForm,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateFormContainer);
