import App from './containers/App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../../shared/reduxStore';

const assignmentsPageContainer = document.getElementById('AssignmentsPage');

if (assignmentsPageContainer) {
  const assignmentsPageContainerRoot = createRoot(assignmentsPageContainer);

  assignmentsPageContainerRoot.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}
