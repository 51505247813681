import { handleCountryChange, handleSave } from '../actions';
import BillingInputs from '../../../components/PersonalInformation/BillingInputs';
import { connect } from 'react-redux';
import { handleCopyAddress } from './actions';

export const mapStateToProps = ({
  instructorProfile: { copyAddress, formErrors, personalData },
}) => ({
  copyAddress,
  formErrors,
  personalData,
});

export const mapDispatchToProps = (dispatch) => ({
  handleCopyAddress: (checked) => dispatch(handleCopyAddress(checked)),
  handleCountryChange: (event) => dispatch(handleCountryChange(event)),
  handleSave: (name, value) => dispatch(handleSave(name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingInputs);
