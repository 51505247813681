import styled from 'styled-components';

const StyledAccordion = styled.div`
  .accordion__button {
    padding: 24px;
    background-color: var(--ds-color__grey--light_300);
    margin-bottom: 1px;
    border: solid 1px #c0c6cb;
    background-color: #e8ebed;
    height: 86.2px;
  }

  .accordion__button:before {
    display: inline-block;
    float: right;
    margin-top: 15px;
    content: '';
    height: 12px;
    width: 12px;
    border-top: 4px solid #000;
    border-left: 4px solid #000;
    transform: rotate(225deg);
    transition: all .2s;
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }

  .accordion__panel {
    padding: 0px 9px;

    p {
      margin-bottom: 10px;
    }

    p.break,
    ul.break {
      margin-bottom: 40px
    }

    ul {
      margin-bottom: 16px;
    }
  }

  .question:not(:first-child) {
    border-top: 0;
  }

  .question:first-child {
    margin-top: -1px;
  } 

  @media (max-width: 768px) {
    margin-top: 24px;
  }

  @media (max-width: 479px) {
    margin: 24px -21px 0;

    .accordion__button {
      margin-bottom 8px;
      border-top: 0;
    }

    .accordion__panel {
      padding: 0 8px 24px;
      border-top: 0;
      
      p.break,
      ul.break {
        margin-bottom: 16px
      }

      ul {
        padding-left: 30px;
      }
    }
  }
`;

StyledAccordion.displayName = 'StyledAccordion';

export default StyledAccordion;
