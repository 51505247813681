import styled from 'styled-components';

const StyledModalWrapper = styled.div`
  ${({ isInteractiveModal }) =>
    isInteractiveModal &&
    `.TheModal {
      width: 920px;
      padding: 0;
    }
    .TheModal .ds-icon__close-thin:before {
      font-size: 14px;
    }

    @media (max-width: 900px) {
      .TheModal {
        justify-content: unset;
      }
    }

    @media (max-width: 479px) {
      .the-modal-bg {
        padding: 48px 40px;
      }
    }`}
  ${({ isEmployerConnector }) =>
    isEmployerConnector &&
    `.TheModal {
      width: 763px;
      padding: 0;
      border-radius: 4px;
      min-height: 621px;
    }
    
    @media (max-width: 768px) {
      .TheModal {
        width: 100% !important;
      }
    }
    `}

${({ isCallNote }) =>
    isCallNote &&
    `.TheModal {
      position: relative;
      max-height: 95vh;
      height: auto;
      overflow-x: hidden;
      overflow-y: scroll;
      border-radius: 4px;
    }
    
    @media (max-width: 768px) {
      .TheModal {
        width: 100% !important;
      }
    }
    `}

${({ isSmallModal }) =>
    isSmallModal &&
    `.TheModal {
      width: 440px;
      max-height: 370px !important;
      min-height: 296px !important;
      overflow-x: hidden;
      overflow-x: hidden;
      padding: 0;
      border-radius: 4px;
      display: flex;
      justify-content: center;
    }
    @media (max-width: 768px) {
      .TheModal {
        height: 100%;
        width: 100% !important;
      }
    }
    
    `}
  
  ${({ isLargeModal }) =>
    isLargeModal &&
    `.TheModal {
    width: 1040px;
    max-width: 1040px;
    height: 662px;
    padding: 0;
    border-radius: 4px;
    min-height: 621px;
  }
  @media (max-width: 768px) {
    .TheModal {
      height: 100%;
      width: 100% !important;
    }
  }
  `}
`;

StyledModalWrapper.displayName = 'StyledModalWrapper';

export default StyledModalWrapper;
