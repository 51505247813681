import ApiService from './ApiService';
import { getAuthToken } from '../utils/getAuthToken';

class UserSpecializationService {
  getSpecializations (userId) {
    const params = {
      authenticity_token: getAuthToken(),
      user_id: userId,
    };
    const apiService = new ApiService('/api/v1/admin/attendee_intentions/available_courses');

    return apiService.makeGetCall(params).then(result => {
      return result.data.data;
    })
      .catch(error => {
        return error;
      });
  };

  updateSpecialization (specializationId, selectedCourseId) {
    const params = {
      authenticity_token: getAuthToken(),
      course_id: selectedCourseId,
    };

    const apiService = new ApiService(`/api/v1/admin/attendee_intentions/${specializationId}`);

    return apiService.makePutCall(params).then(result => {
      return result.data.data;
    })
      .catch(error => {
        return error;
      });
  };
};

export default UserSpecializationService;
