import AdminWrapper from '../shared/adminWrapper';
import CFModal from '../shared/modal';
import Checkbox from '../shared/components/Checkbox';
import Dropdown from '../shared/dropdown';
import EnrollmentConfigurationService from '../../services/EnrollmentConfigurationService';
import Input from '../shared/input';
import React from 'react';
import StudentModal from './studentModal';
import TableView from '../shared/table';
import enrollmentLinkAction from './enrollmentLinkAction.js';
import { objectArr2ArrArr } from '../../utils/objectArr2ArrArr';
import { copyLink } from '../../utils/copyToClipboard';
import _ from 'lodash';

export const CREATE_NEW_ENROLL_LINK = '/en/admin/enrollment_links/new';

class AdminEnrollmentLinksIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      rows: [],
      enrollmentLinkStates: [
        { status: 'created' },
        { status: 'link_sent' },
        { status: 'waiting_for_billing' },
        { status: 'waiting_for_payment' },
        { status: 'paid' },
      ],
      headerColumns: [
        { name: 'Student Name', elem: 'Student Name' },
        { name: 'Status', elem: 'Status' },
        { name: 'Payer', elem: 'Payer' },
        { name: 'Product Start Date', elem: 'Product Start Date' },
        { name: 'Product', elem: 'Product' },
        { name: 'Expired', elem: 'Expired' },
        { name: 'Link', elem: 'Link' },
        { name: 'Actions', elem: 'Actions' },
      ],
      searchValue: '',
      filterValue: '',
      excludeArchivedFilterValue: true,
      modalOpen: false,
    };

    this.manualEnrollmentService = new EnrollmentConfigurationService();
  }

  componentDidMount() {
    this.updateEnrollmentLinkRecords();
  }

  updateEnrollmentLinkRecords() {
    this.manualEnrollmentService
      .fetchAllRecords({
        state_filter: this.state.filterValue,
        exclude_archived_filter: this.state.excludeArchivedFilterValue,
        search_filter: this.state.searchValue,
      })
      .then((res) => {
        this.setState({ rows: res });
      });
  }

  selectOptionsFormatter = (rows) => {
    let tempArr = rows.map((row) => {
      return {
        text: row.status,
        value: row.status,
      };
    });
    return _.uniqBy(tempArr, (item) => item.text);
  };

  setStudentName = (attributes) => {
    if (attributes.user.name) {
      return (
        <div>
          <i
            onClick={() => this.openNotesModal(attributes)}
            className="fa fa-id-card"
          />
          <a href={attributes.user.url}>{attributes.user.name}</a>
        </div>
      );
    } else {
      return (
        <div>
          <i
            onClick={() => this.openNotesModal(attributes)}
            className="fa fa-id-card"
          />{' '}
          {`${attributes.preliminaryUsername} (Preliminary)`}{' '}
        </div>
      );
    }
  };

  createTableRows = (rows) => {
    let clonedRows = _.cloneDeep(rows);
    clonedRows = clonedRows.reverse();
    let resultsSet = clonedRows.map((row) => {
      let attributes = row.attributes;
      let studentName = this.setStudentName(attributes);
      let status = attributes.state ? attributes.state.toUpperCase() : '-';
      let startDate = attributes.startDate ? attributes.startDate : '-';
      let expiredAt = attributes.expiredAt ? attributes.expiredAt : '-';
      let productName = attributes.productName ? attributes.productName : '-';
      let payerName = attributes.payerName ? attributes.payerName : '-';
      let url = (
        <div>
          {attributes.url}{' '}
          <button
            onClick={() =>
              copyLink(`https://careerfoundry.com/en${attributes.url}`)
            }
          >
            Copy
          </button>
        </div>
      );
      let actions = enrollmentLinkAction(row);
      return {
        studentName,
        status,
        payerName,
        startDate,
        productName,
        expiredAt,
        url,
        actions,
      };
    });
    return objectArr2ArrArr(resultsSet);
  };

  handleFilterChange = (val) => {
    this.setState({ filterValue: val }, () =>
      this.updateEnrollmentLinkRecords()
    );
  };

  handleExcludeArchivedFilterChange = (event) => {
    this.setState({ excludeArchivedFilterValue: event.target.checked }, () =>
      this.updateEnrollmentLinkRecords()
    );
  };

  handleSearchChange = (val) =>
    this.setState({ searchValue: val }, () =>
      this.updateEnrollmentLinkRecords()
    );

  handleModalClose = () => this.setState({ modalOpen: false });

  openNotesModal = (attributes) =>
    this.setState({ modalOpen: true, attributes });

  modalContent = () => <StudentModal attributes={this.state.attributes} />;

  render() {
    return (
      <AdminWrapper headerText={`Manual Enrollments`}>
        <a className="button--primary-petrol" href={CREATE_NEW_ENROLL_LINK}>
          Create New Enroll Link
        </a>
        <form className="cf-form">
          <Dropdown
            options={this.selectOptionsFormatter(
              this.state.enrollmentLinkStates
            )}
            onChange={this.handleFilterChange}
            labelText="State"
            resetDropdown={() => this.handleFilterChange('')}
          />
          <Checkbox
            labelText="Exclude Archived"
            onClick={this.handleExcludeArchivedFilterChange}
            defaultChecked={this.state.excludeArchivedFilterValue}
          />
          <Input
            labelText="Student Name"
            onChange={this.handleSearchChange}
            value={this.state.searchValue}
          />
        </form>

        <TableView
          headerColumns={this.state.headerColumns}
          rows={this.createTableRows(this.state.rows)}
          loading={this.state.loading}
          visibleRowCount={0}
          fetchNewTableEntries={() => {}}
          onColumnClick={() => {}}
        />

        <CFModal
          appElement=".admin__enrollment_links_index"
          isOpen={this.state.modalOpen}
          onRequestClose={this.handleModalClose}
          className="admin__enrollment_links__modal"
        >
          {this.state.modalOpen ? this.modalContent() : null}
        </CFModal>
      </AdminWrapper>
    );
  }
}

export default AdminEnrollmentLinksIndex;
