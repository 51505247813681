import axios from 'axios';

export const GET_INITIAL_DATA_SUCCESS = 'AssignmentsPage.GET_INITIAL_DATA_SUCCESS,';

const ADMIN_COHORTS_URL = '/api/v1/admin/cohorts/courses/';
const params = new URLSearchParams(window.location.search);
const id = params.get('cohort[course_id]');
const start = params.get('cohort[date]');

export const getInitialData = () => async dispatch => {
  try {
    const { data: instructorList } = await axios.get(`${ADMIN_COHORTS_URL}${id}/instructors?start=${start}`);
    const { data: studentList } = await axios.get(`${ADMIN_COHORTS_URL}${id}?start=${start}`);

    dispatch({
      instructorList,
      studentList,
      type: GET_INITIAL_DATA_SUCCESS,
    });
  } catch (e) {
    console.log(e);
  }
};
