module.exports = (() => {
  const state = { prices: [] };
  const init = function () {
    bindFunctions();
  };

  const bindFunctions = function () {
    $('.base_price').on('change', basePriceChanged);
    $('#offer_form_amount_upfront').on('change', setUpfrontPrice);
    $('#offer_form_amount_monthly').on('change', setMonthlyPrice);
    $('#offer_form_cycles').on('change', updateOffer);
    $('.base_price').on('change', updateOffer);
    setUpfrontPrice();
    setMonthlyPrice();
    updateOffer();
    setPricing(null);
  };

  const basePriceChanged = function (e) {
    const changeElement = e.currentTarget;
    setPricing(changeElement);
  };

  const setPricing = function (changeElement) {
    if (changeElement) {
      if (changeElement.id === 'offer_form_program_id') {
        $('#offer_product_id').val('');
      }
      if (changeElement.id === 'offer_form_product_id') {
        $('#offer_form_program_id').val('');
      }
    }

    const programId = $('#offer_form_program_id').val();
    const productId = $('#offer_form_product_id').val();
    const currency = $('#offer_form_currency').val();

    $('.full_amount_course_price').empty();

    $.ajax({
      data: {
        currency,
        product_id: productId,
        program_id: programId,
      },
      type: 'GET',
      url: '/api/v1/finance/prices',
    })
      .done(function (response) {
        const prices = response.prices;
        state.prices = prices;
        processDomUpdatesForPrices();
      })
      .fail(function (response) {
        state.prices = [];
        processDomUpdatesForPrices();
      });
  };

  const processDomUpdateForPrice = function (price) {
    const { id, amount, currency, country_codes } = price;
    const discount = calculateDiscount(price.amount);
    const information = `<li>${currency} ${amount}, <b>Discount: ${discount}%</b></li>`;

    $('.full_amount_course_price').append(information);
    $('#offer_form_price_id').append(
      `<option value='${id}'>${currency} ${amount} - CountryCodes: ${country_codes}</option>`
    );
  };

  const processDomUpdatesForPrices = function () {
    $('.full_amount_course_price').empty();
    $('#offer_form_price_id').empty();
    const { prices } = state;

    prices.forEach(function (price) {
      processDomUpdateForPrice(price);
    });
  };

  const setUpfrontPrice = function () {
    const amountUpfront = $('#offer_form_amount_upfront').val();
    $('.amount_upfront').html(amountUpfront);
    updateOffer();
  };

  const setMonthlyPrice = function () {
    const amountMonthly = $('#offer_form_amount_monthly').val();
    $('.amount_monthly').html(amountMonthly);
    updateOffer();
  };

  const updateOffer = function () {
    processDomUpdatesForPrices();
    processDomUpdatesForOffer();
  };

  const processDomUpdatesForOffer = function () {
    const offer = calculateOffer();
    const currency = $('#offer_form_currency').val();
    $('.offer_price').html(`${currency} ${offer}`);
  };

  const calculateOffer = function () {
    const upfront = $('#offer_form_amount_upfront').val();
    const monthly = $('#offer_form_amount_monthly').val();
    const cycles = $('#offer_form_cycles').val();
    const offer = parseInt(upfront) + monthly * cycles;
    return offer;
  };

  const calculateDiscount = function (basePrice) {
    const offer = calculateOffer();
    const discount = (100 - (offer / basePrice) * 100).toFixed(2);
    return discount;
  };

  return {
    init,
  };
})();
