import styled from 'styled-components';

export const QHead = styled.div`
  height: 157px;
  background-color: var(--ds-color__petrol);
  display: flex;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-top: 48px;

  @media only screen and (max-width: 628px) {
    height: 103px;
    padding-top: 30px;
  }
`;

export const QHeadTitle = styled.div`
  margin: 0 auto;
  display:flex:
  justify-content: center;
  align-items: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: white;
  @media only screen and (max-width: 628px) {
    padding: 0px 23px;
  }
`;

export const QBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;

  @media only screen and (max-width: 628px) {
    padding: 0px 23px;
  }
`;

export const QBodyItem = styled.div`
  margin-top: -60px;
  width: 239px;
  height: 282px;
  padding: 24px 16px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding-bottom: 10px;
  ${(props) =>
    props.active &&
    `
      border: 1px solid var(--ds-color__aqua);
      box-sizing: border-box;
    `}
  @media only screen and (max-width: 628px) {
    margin-top: 32px;
    min-width: 130px;
    width: 48%;
    height: 350px;
  }
`;

export const QBodyItemTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: var(--ds-color__indigo);
`;

export const QBodyItemDescription = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--ds-color__indigo);
  word-break: break-word;
  margin-top: 8px;
`;

export const QBodyItemImage = styled.div`
  max-width: 165px;
  width: 100%;
  height: 125px;
  text-align: center;
  padding: 0px;
  margin: 8px 0px;
  img {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 628px) {
    img {
      width: 100%;
    }
  }
`;
