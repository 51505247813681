import FullScreenModalBox from '../../../shared/components/FullScreenModal';
import InterviewGuidelines from '../InterviewGuidelines';
import InterviewReview from '../../containers/InterviewReview';
import PropTypes from 'prop-types';
import QuestionInsights from '../../containers/QuestionInsights';
import React from 'react';
import StartReview from '../StartReview';
import TheModal from '../../../shared/components/TheModal';
import WarningModal from '../../../shared/components/WarningModal';
import { exitInterviewTracking } from '../../../shared/helpers/tracking';
import { useHistory } from 'react-router-dom';

const confirmFinishInterview = (
  questions,
  answersForReview,
  finishInterview,
  handleRedirect
) => {
  if (questions && questions.length) {
    const questionsAnswered = answersForReview.length;
    const currentQuesion = questions[questionsAnswered].question;
    const progress = `${answersForReview.length + 1} / ${questions.length}`;
    exitInterviewTracking(
      currentQuesion,
      progress,
      'ip_exit_interview_flow_click'
    );
  }
  handleRedirect();
  finishInterview();
};

const confirmFinishEvaluation = (answersForReview, finishInterview) => {
  const numberOfEvaluatedAnswers = answersForReview.filter(
    (answer) => answer.rating !== 0
  ).length;
  const progress = numberOfEvaluatedAnswers / answersForReview.length;
  const currentQuesion = answersForReview[numberOfEvaluatedAnswers];
  exitInterviewTracking(
    currentQuesion,
    progress,
    'ip_exit_evaluation_flow_click'
  );
  finishInterview();
};

const InterviewModal = ({
  answersForReview,
  entryPoint,
  finishInterview,
  handleCloseModal,
  modalIsOpen,
  modalAction,
  questions,
  startReview,
}) => {
  const history = useHistory();

  const handleRedirect = () => {
    switch (entryPoint) {
      case 'careerSupportCenter':
        history.push('/en/career-support-center');
        break;
      default:
        break;
    }
  };

  return (
    <>
      {modalAction !== 'evaluation' ? (
        <TheModal
          handleClose={
            modalAction === 'startReview' ? startReview : handleCloseModal
          }
          isModalOpen={modalIsOpen}
        >
          {
            {
              interviewGuidelines: (
                <InterviewGuidelines buttonAction={handleCloseModal} />
              ),
              questionInsights: (
                <QuestionInsights buttonAction={handleCloseModal} />
              ),
              quit: (
                <WarningModal
                  action={modalAction}
                  cancelButtonAction={handleCloseModal}
                  confirmButtonAction={() =>
                    confirmFinishInterview(
                      questions,
                      answersForReview,
                      finishInterview,
                      handleRedirect
                    )
                  }
                  message="You haven’t completed the interview training. Are you sure you want to quit?"
                  subMessage="All your answers that you have submitted so far will be saved within your answers history."
                />
              ),
              quitEvaluation: (
                <WarningModal
                  action="quit"
                  cancelButtonAction={handleCloseModal}
                  confirmButtonAction={() =>
                    confirmFinishEvaluation(answersForReview, finishInterview)
                  }
                  message="You haven’t completed the interview training evaluation yet. Are you sure you want to quit?"
                  subMessage="All your answers and evaluations that you have submitted so far will be saved within your answers history."
                />
              ),
              startReview: <StartReview buttonAction={startReview} />,
            }[modalAction]
          }
        </TheModal>
      ) : (
        <FullScreenModalBox
          handleClose={handleCloseModal}
          isModalOpen={modalIsOpen}
          modalClassName="interview-modal"
        >
          <InterviewReview isModal={true} />
        </FullScreenModalBox>
      )}
    </>
  );
};

InterviewModal.propTypes = {
  answersForReview: PropTypes.array,
  entryPoint: PropTypes.string.isRequired,
  finishInterview: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  modalAction: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  questions: PropTypes.array,
  startReview: PropTypes.func.isRequired,
};

export default InterviewModal;
