import axios from 'axios';
import { getAuthToken } from '../../../../../utils/getAuthToken';
import { getWsgModalData } from '../WsgModal/actions';

export const getWsgData =
  (attendeeId, attendeeTargetCompletion) => async (dispatch) => {
    dispatch({ type: 'wsg.GET_WSG_DATA' });
    try {
      const generalData = await getWsgGeneralData(attendeeId);
      const modalData = await getWsgModalData(attendeeId);

      dispatch({
        attendeeId,
        attendeeTargetCompletion,
        data: { ...generalData, ...modalData },
        type: 'wsg.GET_WSG_DATA_SUCCESS',
      });
    } catch (err) {
      dispatch({ type: 'wsg.GET_WSG_DATA_FAIL' });
      console.log(`Something went wrong... ${err}`);
    }
  };

export const getWsgGeneralData = async (attendeeId) => {
  try {
    const {
      data: { data },
    } = await axios.get(`/api/v1/attendee/${attendeeId}/weekly_goal`);

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const dismissBanner = () => async (dispatch, getState) => {
  try {
    const {
      wsg: { attendeeId, wsgId },
    } = getState();

    axios.patch(
      `/api/v1/attendee/${attendeeId}/weekly_submission_goals/${wsgId}/dismiss_banner`,
      {
        authenticity_token: getAuthToken(),
      }
    );

    dispatch({ type: 'wsg.DISMISS_BANNER' });
  } catch (e) {
    dispatch({ type: 'wsg.DISMISS_BANNER_FAIL' });
    console.log(e);
  }
};
