module.exports = (() => {
  const init = () => {
    initSelect2();
  };

  const initSelect2 = $('.tag-select').select2();

  return {
    init: init,
  };
})();
