import React from 'react';
import StyledCheckList from '../styles/StyledCheckList';

const WhereToFindClients = () => (
  <>
    <p className='ds-typography__body break'>
      Now that your business is set up or mapped out, this seems like a great
      time to test everything out with some customers. How you approach this can
      take many forms. Here are some different approaches other graduates have
      tried:
    </p>
    <ul>
      <li>
        Volunteer services or take on a volunteer project for a friend or
        colleague
      </li>
      <li>Take on projects from your family business</li>
      <li>
        Identify a need and put together a proposal for an organization like a
        housing community
      </li>
      <li>
        Pitch your idea to expand or improve the online presence of a
        neighborhood business
      </li>
    </ul>
    <p className='ds-typography__body break'>
      Build your network as a way to continue to learn, to share, or partner in
      projects, and to increase your reach into the market as an opportunity to
      serve others or to earn referrals. Some freelancers are also posting
      opportunities in the{' '}
      <a
        onClick={() =>
          window.open('https://careerfoundry.slack.com/archives/C34L7B00G')
        }
      >
        CareerFoundry Job Leads Slack Channel.
      </a>
    </p>
    <h5 className='ds-typography__h5'>Check job boards. Yes, job boards.</h5>
    <p className='ds-typography__body break'>
      Projects and contracts are posted on job boards because there is already
      an audience. In addition to checking out the Freelancing section of our{' '}
      <a
        onClick={() =>
          window.open('https://careerfoundry.com/en/recommended-jobs')
        }
      >
        Recommend Job Boards listing
      </a>{' '}
      You can also explore your favorite job boards for keywords like
      'contract'.
    </p>
    <p className='ds-typography__body break'>
      PRO TIP: An interview for a contract role or project can be different and
      at a faster-pace than that for a full-time role because the client is
      looking at you as a business rather than an employee.
    </p>
    <h5 className='ds-typography__h5'>Freelancing platforms</h5>
    <p className='ds-typography__body break'>
      There are also a growing number of platforms, also referred to as
      brokerage sites, are where you can be connected to projects including:
    </p>
    <ul>
      <li>
        <a onClick={() => window.open('https://www.upwork.com/')}>Upwork</a>
      </li>
      <li>
        <a onClick={() => window.open('https://www.fiverr.com/')}>Fiverr</a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open('https://www.linkedin.com/service-marketplace')
          }
        >
          LinkedIn Services Marketplace
        </a>
      </li>
      <li>
        <a onClick={() => window.open('https://www.leisurejobs.com/')}>
          Leisure Jobs
        </a>
      </li>
    </ul>
    <div className='ds-typography__lead'>Freelance brokerage sites</div>
    <p className='ds-typography__body break'>
      Many brokerage sites have low bids for work and fees are around 10%, so
      look at it more as a way to get started or work on the side
    </p>
    <p className='ds-typography__body break'>
      Brokerage websites like Upwork have become very popular marketplaces for
      freelance work. These sites have mixed reviews. Many of the offers are
      very low pay, so you will have to make the decision on whether you want to
      spend the time using them. You can find suggested brokerage sites in the
      Freelance section of our{' '}
      <a
        onClick={() =>
          window.open('https://careerfoundry.com/en/recommended-jobs')
        }
      >
        Recommended Job Boards.
      </a>
    </p>
    <p className='ds-typography__body break'>
      Here is a checklist of things to do to get started on these platforms:
    </p>
    <ul>
      <li>
        <strong>Create a Profile:</strong> Don't expect to receive work based on
        your profile, but it will be necessary to demonstrate your
        professionalism as a developer/designer.
        <ul>
          <li>
            Your <strong>Name</strong>
          </li>
          <li>
            Professional <strong>Photo</strong>
          </li>
          <li>
            <strong>Headline:</strong> A headline is a one-line summary of what
            you do for clients that clearly states the core service as well as
            the benefits of working with you. Example: “Web design with proven
            ability to increase sales.”
          </li>
          <li>
            <strong>Services Descriptions:</strong> Explain what you do and the
            benefits of that in detail. Instead of “I'm passionate” or “I'm a
            mother of two” include only what will bring value to the customer
            such as “I help businesses take care of their users.”
          </li>
          <li>
            <strong>Your Qualifications:</strong> Outline the projects, work
            history, and portfolio elements that demonstrate your ability to
            help.
          </li>
          <li>
            <strong>Testimonials:</strong> Include great quotes from clients or
            from your LinkedIn recommendations if you are just starting out.
          </li>
        </ul>
        <li>
          <strong>Incorporate Branding:</strong> Incorporate the personal
          branding that you have already developed into your presentation and
          marketing.
        </li>
        <li>
          <strong>Craft an Elevator Pitch:</strong> Use your elevator pitch to
          develop your value proposition and the unique perspective that you can
          offer to clients.
        </li>
      </li>
    </ul>
    <div className='ds-typography__lead'>Questions to ask the client</div>
    <p className='ds-typography__body break'>
      If you are responding to posts, companies and prospective clients have
      already decided on what they think project scope and cost should be.
      Because of this it can be helpful to create a list of questions to vet the
      project and the potential client. You can use{' '}
      <a
        onClick={() =>
          window.open(
            'https://careerfoundry.com/en/blog/ux-design/how-to-conduct-a-ux-redesign/'
          )
        }
      >
        How to Conduct a UX Redesign (CareerFoundry)
      </a>{' '}
      for inspiration. Some examples can include:
    </p>
    <ul>
      <li>What inspired this project?</li>
      <li>What are some examples of the work you want to see done?</li>
      <li>
        What has your previous experience working with a Freelancer been like?
      </li>
      <li>
        On a scale of 1-10 how important is this project to your operation?
      </li>
      <li>Do you have software or even a workflow you want me to be using?</li>
      <li>
        What are the business goals you intend to achieve with this project?
      </li>
      <li>What is your ideal type and channel for communicating?</li>
      <li>What are your key requirements or deadlines I should be aware of?</li>
      <li>What is your budget for this project?</li>
      <li>
        Who will hold the intellectual property rights when this project is
        complete?
      </li>
      <li>Who would be my primary point of contact?</li>
      <li>What parts of this project are already complete?</li>
      <li>What else should I know about this project?</li>
      <li>What would success look like when this project is complete?</li>
    </ul>
    <p className='ds-typography__body break'>
      Whichever questions you choose to ask, it is important to send a thank you
      to all meeting participants with a summary of what was discussed and next
      steps. This gives the prospective client a chance to correct your
      understanding of the project. This 'summary' of the project, scope, fees,
      and next steps can be sent in the form of a business proposal. Here is a
      resource with a{' '}
      <a
        onClick={() =>
          window.open(
            'https://blog.hubspot.com/sales/how-to-write-business-proposal'
          )
        }
      >
        template and examples.
      </a>
    </p>
    <div className='ds-typography__lead'>Passive income projects</div>
    <p className='ds-typography__body break'>
      Additionally you can create a 'passive income' stream (money earned with
      minimal effort as in 'while you sleep') by creating products like
      templates, funnels, and tutorials to be sold on-demand through eCommerce
      platforms. For example, you can create templates for websites or branding
      materials that customers tailor to their needs like{' '}
      <a
        onClick={() =>
          window.open(
            'https://www.etsy.com/shop/ThePistachioClub?ref=simple-shop-header-name&listing_id=767168379'
          )
        }
      >
        The Pistachio Club
      </a>{' '}
      or your artwork on products like{' '}
      <a
        onClick={() =>
          window.open(
            'https://www.etsy.com/shop/StudioInception?ref=simple-shop-header-name&listing_id=732920586'
          )
        }
      >
        Studio Inception
      </a>{' '}
      , both on Etsy.
    </p>
    <p className='ds-typography__body break'>
      Some platforms where you can market your products include:
    </p>
    <ul>
      <li>
        <a onClick={() => window.open('https://www.etsy.com/')}>Etsy</a>
      </li>
      <li>
        <a onClick={() => window.open('https://appsumo.com/')}>AppSumo</a>
      </li>
      <li>
        <a onClick={() => window.open('https://www.shopify.com/')}>Shopify</a>
      </li>
      <li>
        <a onClick={() => window.open('https://creativemarket.com/')}>
          Creative Market
        </a>
      </li>
    </ul>
    <h5 className='ds-typography__h5'>
      Case study: got your first client, now what?
    </h5>
    <p className='ds-typography__body break'>
      While we covered a great deal of content here, you can get another
      perspective by checking out the article:{' '}
      <a
        onClick={() =>
          window.open(
            'https://uxplanet.org/so-you-landed-your-first-ux-freelance-gig-46370775acc3'
          )
        }
      >
        So you landed your first UX Freelance Gig
      </a>{' '}
      and the sections on
    </p>
    <ul>
      <li>Manage Expectations</li>
      <li>Don't Be Afraid to Share Your Expertise</li>
      <li>There's Nothing Wrong with Asking for Help</li>
    </ul>
    <p className='ds-typography__body break'>
      For a checklist of what to do once you get your first client we recommend:
    </p>
    <StyledCheckList>
      <li>
        <input type='radio' id='scope' />
        Define the scope of work
      </li>
      <li>
        <input type='radio' id='contract' />
        Set up a contract [See Contracts &amp; Service Agreements]
      </li>
      <li>
        <input type='radio' id='payment' />
        Figure out how to get paid [See Finances]
      </li>
      <li>
        <input type='radio' id='requirements' />
        Get the Client Requirements [See Questions to Ask a Client]
      </li>
      <li>
        <input type='radio' id='timeline' />
        Set up a Timeline [See Estimating Project Timeline]
      </li>
      <li>
        <input type='radio' id='rate' />
        Calculate rate and give this to the client [See Set Your Rate]
      </li>
    </StyledCheckList>
    <h5 className='ds-typography__h5'>Managing projects</h5>
    <p className='ds-typography__body break'>
      By establishing your systems first instead of building them as you work
      you will have the opportunity to track your time as you progress through
      each stage of your project. This will help you to have a better idea of
      how many things you can take on later (capacity planning). With that said,
      here are some additional tips for managing your client projects:
    </p>
    <ul>
      <li>
        <strong>Time Management:</strong> Make sure you plan your time so that
        you can both work on the project and take time for self-care and fun!
      </li>
      <li>
        <strong>Systems Check:</strong> Schedule times each week, month, and
        year to make sure everything is working as you need it to or so you can
        change it to be more effective.
      </li>
      <li>
        <strong>Tech Stack:</strong> Keep a list of all the technology you have
        acquired and the relevant login information in a secure location for tax
        purposes as well as regular auditing.
      </li>
      <li>
        <strong>Review Pricing:</strong> Every quarter or year as you check in
        on your finances, evaluate your rates to be sure you are covering your
        expenses.
      </li>
      <li>
        <strong>Project Management:</strong> Carefully craft the first project
        update to your stakeholders because you can then convert that into a
        template for future status reports.
      </li>
      <li>
        <strong>Communication:</strong> You are there to help so be sure to ask
        questions to make sure everyone is clear on next steps, and explain your
        thought process because communication is key toward resolving or
        preventing misunderstandings.
      </li>
    </ul>
  </>
);

export default WhereToFindClients;
