export const isValidLink = (link, type) => {
  return link && type && link.toLowerCase().indexOf(type.toLowerCase()) !== -1;
};

const linkTypes = [
  'behance',
  'dribbble',
  'facebook',
  'github',
  'stackoverflow',
  'twitter',
];

export const getLinkType = (link) => {
  for (const linkType of linkTypes) {
    if (isValidLink(link, linkType)) {
      return linkType;
    }
  }

  return null;
};

export const formatLink = (link) => {
  if (link) {
    return { fileIcon: 'fa-linkedin', s3Url: link.handle };
  }
  return null;
};

const icons = {
  behance: 'fa-behance',
  dribbble: 'fa-dribbble',
  facebook: 'fa-facebook-square',
  github: 'fa-github',
  stackoverflow: 'fa-stack-overflow',
  twitter: 'fa-twitter',
};

export const formatAllLink = (links = []) => {
  return links.map((link) => ({ fileIcon: icons[link.name], s3Url: link.handle, type: link.name }));
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const formatSocialPayload = (socials) => {
  return socials ? socials.map(social => ({ service: social.name.toLowerCase(), value: social.handle })) : [];
};

export const deleteLink = (type, links, onInputChange, onDeleteSocial) => {
  const socials = links.filter((social) => social.name.toLowerCase() !== type);
  if (typeof onInputChange === 'function') {
    const removedUrl = links.find((social) => social.name.toLowerCase() === type);
    removedUrl.handle = '';
    onDeleteSocial(formatSocialPayload([removedUrl]));
    onInputChange([...socials]);
  }
};
