import App from './components/App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../../shared/reduxStore';

const afaAdmissionsContainer = document.getElementById('afaAdmissions');
const id = afaAdmissionsContainer?.dataset.id;

if (afaAdmissionsContainer && id) {
  const afaAdmissionsContainerRoot = createRoot(afaAdmissionsContainer);

  afaAdmissionsContainerRoot.render(
    <Provider store={store}>
      <App id={id} />
    </Provider>
  );
}
