import styled from 'styled-components';

const StyledJobApplicationTableWrapper = styled.div`
  margin-bottom: 25px;
  width: 100%;
  display: block;
  overflow-x: auto;

  thead {
    color: white;
    th {
      text-align: center;
      line-height: 1.5;
    }
  }

  tbody > tr > td {
    line-height: 1.5;
    max-width: 88px;
    overflow-wrap: break-word;
    text-transform: capitalize;
    font-weight: 400;
    background-color: initial !important;

    a {
      line-height: 1.5;
      font-weight: 400 !important;
      min-height: 0 !important;
      border-bottom: none !important;
    }
  }

  .action-column {
    max-width: 44px;
  }

  .link {
    text-decoration: underline !important;
    border-bottom: unset;
  }

  .lowercase {
    text-transform: lowercase !important;
  }

  thead > tr > th {
    max-width: 88px;
  }

  table {
    min-width: 600px;
  }
  td.title {
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
  }
`;

StyledJobApplicationTableWrapper.displayName =
  'StyledJobApplicationTableWrapper';

export default StyledJobApplicationTableWrapper;
