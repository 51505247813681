import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

const useDesktopOnly = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 767);
    };
    window.addEventListener('resize', debounce(handleResize, 500));
    return () => {
      window.removeEventListener('resize', debounce(handleResize, 500));
    };
  }, []);

  return isDesktop;
};

export default useDesktopOnly;
