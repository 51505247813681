import styled from 'styled-components';

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin-top: -8px;
  }

  &:last-of-type {
    padding-top: 36px;
  }

  .ds-typography__body--bold {
    font-weight: 700;
  }

  .cf-form__checkbox--open {
    border: 1px solid rgba(34, 60, 80, 0.0001);
    box-sizing: border-box;
    border-radius: 3px;
    margin: 16px auto;
  }

  .ds-form__input__wrapper {
    small.note {
      position: absolute;
      bottom: -18px;
      right: 0;
      font-size: 10px;
    }
  }

  .ds-form__input__wrapper,
  .cf-form__checkbox--open {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
    padding: 0 5px;

    label {
      font-size: 16px;
    }

    label,
    input {
      margin: 0;
      display: inline-block;
    }
  }
`;

const FormWrapper = styled.div`
  h3 {
    margin-bottom: 16px;
  }

  a > i {
    margin-right: 6px;
    vertical-align: middle;
  }
`;

export { FormColumn, FormWrapper };
