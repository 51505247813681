import List from '../../components/List';
import NoMoreExercises from '../../components/NoMoreExercises';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

export const ListContainer = ({ exercisesLeft, next, past, week }) =>
  exercisesLeft ? (
    <List next={next} past={past} week={week} />
  ) : (
    <NoMoreExercises />
  );

ListContainer.propTypes = {
  exercisesLeft: PropTypes.bool,
  next: PropTypes.string,
  past: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool,
  ]),
  week: PropTypes.array.isRequired,
};

export const mapStateToProps = ({
  weeklySubmissionGoal: { exercises, order, past },
}) => ({
  exercisesLeft: exercises && !!Object.keys(exercises).length,
  next: order.find((o) => !exercises[o].exercise_state) || null,
  past: past.length ? past.map((key) => exercises[key]) : null,
  week: order.map((key) => exercises[key]),
});

export default connect(mapStateToProps)(ListContainer);
