import styled from 'styled-components';

const MessageWrapper = styled.div<{
  messageType: string;
}>`
  padding: 24px;
  border: 1px solid
    ${(props) =>
      props.messageType === 'error'
        ? 'var(--ds-color__int-border-input-negative)'
        : 'var(--ds-color__feedback-positive)'};
  margin-top: 6px;
  margin-bottom: 7px;

  p {
    margin: 0;
    max-width: 664px;
  }
`;

MessageWrapper.displayName = 'MessageWrapper';

export { MessageWrapper };
