module.exports = (() => {
  const thisState = {
    search: {
      name: '',
      status: ''
    },
    totalElements: 0,
    loadedElements: 0,
    limit: 25,
    referrer: ''
  };

  const init = function() {
    _bindFunctions();
    _infinitScroll();
    _buildSerachParams();
    thisState.form = new CF.Form('.admin_referrals__form');
    thisState.modal = new CF.Modal('#user_referral__modal');
  };

  const _bindFunctions = () => {
    $('.referral-status--js').on('change', _buildSerachParams);
    $('.referral-name--js').on('keypress',  _searchUser);
    $(document).on('click', '.pay-referral--js', _openPaymentModal);
    $('#pay_checkbox').on('click', _toggleSubmitBtn);
    $('.referral-submit--js').on('click', _submitModalForm);
  };

  const _searchUser = e => {
    if(e.which == 13){
      _buildSerachParams();
      return false
    }
  };

  const _buildSerachParams = () => {
    thisState.search.status = $('.referral-status--js').val();
    thisState.search.name = $('.referral-name--js').val();
    _filter();
  };

  const _filter = () => {
    _insertLoadingSpinner();
    thisState.totalElements = 0;
    thisState.loadedElements = 0;
    $('.user_referral__row').remove();
    _loadUserReferrals(_insertResponse);
  };

  const _openPaymentModal = e => {
    e.preventDefault();
    thisState.referrer = e.target.getAttribute('data-id');
    thisState.modal.toggleModal();
    _getReferrer(_insertReferrerData);
  };

  const _toggleSubmitBtn = () => $('.referral-submit--js').toggleClass('button--primary-disabled')
    .prop('disabled', (i,v) => !v);

  const _getReferrer = callbackFn => {
    _insertLoadingSpinner();
    $.ajax({
      url: '/api/v1/user_referrals/get_referrer/',
      data: {
        id: thisState.referrer
      }
    }).success(callbackFn)
  };

  const _insertReferrerData = response => {
    $('.referrer-name--js').html(`<a href="/en/admin/users/${response.referrer_id}" >${response.name}</a>`);
    $('.referrer-payment--js').html(response.amount);
    $('.referrer-payment-method--js').html(response.payment_method);
    if(response.billing_profile){
      $('.referrer-billing-name--js').html(response.billing_profile.contact_name);
      $('.referrer-billing-address--js').html(response.billing_profile.address_line_1);
      $('.referrer-billing-city-zip--js').html(`${response.billing_profile.city} ${response.billing_profile.postal_code}`);
      $('.referrer-billing-country--js').html(response.billing_profile.country);
    };
    _removeLoadingSpinner();
  };

  const _submitModalForm = e => {
    e.preventDefault();
    _submitPaidForm(_insertPaidResponse);
  };

  const _submitPaidForm = callbackFn => {
    $.ajax({
      url: '/api/v1/user_referrals/mark_paid/',
      method: 'POST',
      data: {
        id: thisState.referrer
      }
    }).success(callbackFn)
      .fail(error => alert(error.message))
  };

  const _insertPaidResponse = response => {
    $('.user_referral__modal main').hide();
    $('.user_referral__modal form').hide();
    $('.message-container--js').html(response.message);
    setTimeout( () => location.reload(), 1000);
  };

  const _initReferrals = () => {
    _insertLoadingSpinner();
    _loadUserReferrals(_insertResponse);
  }

  const _infinitScroll = () => {
    $(window).scroll(function(){
      thisState.loadedElements = $('.user_referral__row').length;
      if(thisState.loadedElements < thisState.totalElements && !thisState.loading && $(window).scrollTop() > $(document).height() - $(window).height() - 250){
        thisState.loading = true
        _insertLoadingSpinner();
        _loadUserReferrals(_insertResponse);
        return
      }
    });
    return $(window).scroll();
  };

  const _loadUserReferrals = callbackFn => {
    $.ajax({
      url: '/api/v1/user_referrals/get_referrals',
      data: {
        search: thisState.search,
        limit: thisState.limit,
        offset: thisState.loadedElements,
      }
    }).done(callbackFn)
  };

  const _insertResponse = response => {
    $('.admin__referrals__table').append(response.partial);
    $('.referral-display--js').html(response.offset);
    $('.referral-count--js').html(response.count);
    thisState.totalElements = response.count;
    thisState.loadedElements = $('.user_referral__row').length;
    thisState.loading = false;
    _removeLoadingSpinner();
  };

  const _insertLoadingSpinner = () => $('.loading_spinner--js').html(Template.loading_spinner);
  const _removeLoadingSpinner = () => $('.loading_spinner--js').html('');

  return {
    init: init
  };
})();
