import DiscussedTopicsSectionContainer from '../../containers/DiscussedTopicsSectionContainer';
import GroupedNumberCheckInputContainer from '../../containers/GroupedNumberCheckInputContainer';
import HeaderWithCheckboxContainer from '../../containers/HeaderWithCheckboxContainer';
import InputHeader from '../shared/InputHeader';
import PropTypes from 'prop-types';
import React from 'react';
import TextAreaInputContainer from '../../containers/TextAreaInputContainer';

const CallNoteForm = ({
  isMessageSharable,
  showCSSection = true,
  isCSSectionEditable = true,
  viewType = 'edit',
}) => {
  const formatWithAsterick = (word) => {
    if (viewType === 'view') {
      return word;
    }

    return `${word} *`;
  };

  const isView = viewType === 'view';

  return (
    <>
      <InputHeader
        isView={isView}
        label={isView ? 'Call Summary' : 'Summarize your call *'}
      />
      <TextAreaInputContainer
        id="text"
        selector="callNotesInput"
        viewType={viewType}
      />
      <InputHeader
        isView={isView}
        label={formatWithAsterick(
          'Briefly list the next steps for the student'
        )}
      />
      <TextAreaInputContainer
        id="action_points"
        selector="nextStepInput"
        viewType={viewType}
      />
      <DiscussedTopicsSectionContainer
        id="tag_list"
        label={formatWithAsterick('Discussed topics')}
        viewType={viewType}
      />
      <GroupedNumberCheckInputContainer
        id="student_motivation"
        label={formatWithAsterick(
          'In your opinion, how motivated is the student in finding a job?'
        )}
        viewType={viewType}
      />
      <GroupedNumberCheckInputContainer
        id="student_open_to_suggestion"
        label={formatWithAsterick(
          'On a scale of 1 - 10, how open/responsive is the student to your suggestions?'
        )}
        viewType={viewType}
      />
      <GroupedNumberCheckInputContainer
        id="student_confidence"
        label={formatWithAsterick(
          "On a scale of 1 - 10, how confident are you in this student's ability to get a job?"
        )}
        viewType={viewType}
      />
      {showCSSection && (
        <>
          {isView ? (
            <InputHeader isView={isView} label="Shared with Career services" />
          ) : (
            <HeaderWithCheckboxContainer
              id="sharable"
              label="Click here to share if you want to alert Career Services about this call."
              disabled={isCSSectionEditable === false}
            />
          )}
          <TextAreaInputContainer
            id="sharable_message"
            selector="shareWithCSInput"
            showInput={isMessageSharable === true}
            disabled={isCSSectionEditable === false}
            viewType={viewType}
          />
        </>
      )}
    </>
  );
};

CallNoteForm.propTypes = {
  isCSSectionEditable: PropTypes.bool,
  isMessageSharable: PropTypes.bool,
  showCSSection: PropTypes.bool,
  viewType: PropTypes.string,
};

export default CallNoteForm;
