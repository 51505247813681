import styled from 'styled-components';

const StyledAudioSection = styled.div`
    flex: 1;
    overflow: auto;
    text-align: center;
    background: white;

    audio {
      width: 95%;
      display: block;
      margin: auto;
    }

    audio::-webkit-media-controls-panel {
      background-color: #FFF;
    }
`;

StyledAudioSection.displayName = 'StyledAudioSection';

export default StyledAudioSection;
