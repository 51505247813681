import {
  ColumnRight,
  ListItem,
  Wrapper
} from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const List = ({
  assigningStudentId,
  assigningStudentInfo: tempInfo,
  saveInstructorAssignment,
  setAssigningStudentId,
  studentList,
  unsetAssignmentIds,
}) => (
  <Wrapper key={assigningStudentId}>
    {studentList ? studentList.map(({
      id,
      mentor,
      name,
      program,
      slug,
      timezone,
      tutor,
    }) => {
      
      const isItemInAssignmentMode = assigningStudentId === id;
      const isAssignmentMode = !!assigningStudentId;

      const mentorName = isItemInAssignmentMode ? tempInfo.mentor?.name || 'unassigned' : mentor?.name || 'unassigned';
      const tutorName = isItemInAssignmentMode ? tempInfo.tutor?.name || 'unassigned' : tutor?.name || 'unassigned';
      
      return (
        <ListItem
          key={id}
          style={{
            boxShadow: isAssignmentMode && isItemInAssignmentMode ? '10px 5px 5px grey' : '0px 0px 0px grey',
            opacity: isAssignmentMode && !isItemInAssignmentMode ? '.5': '1',
            pointerEvents: isAssignmentMode && !isItemInAssignmentMode ? 'none' : 'auto'
          }}
        >
          <div className="ds-grid__col-xs-6">
            <p>
              <a
                className="ds-typography__lead--bold ds-color__petrol"
                href={`/en/admin/users/${slug}`}
              >{name}</a>
            </p>
            <p className="ds-typography__body ds-color__indigo">
              <b>Tutor:</b> {tutorName}
            </p>
            <p className="ds-typography__body ds-color__indigo">
              <b>Mentor:</b> {mentorName}
            </p>
            <div>
              {isItemInAssignmentMode
                ? (
                  <>
                    <button
                      className="ds-button__primary"
                      onClick={saveInstructorAssignment}
                    >Save</button>
                    <button
                      className="ds-button__secondary"
                      onClick={unsetAssignmentIds}
                    >Cancel</button>
                  </>
                )
                : (
                  <button
                    className="ds-button__primary"
                    onClick={() => setAssigningStudentId(id)}
                  >Assign</button>
                )
              }
            </div>
          </div>
          <ColumnRight>
            <p className="ds-typography__body ds-color__indigo">
              ({program?.name || 'Standalone course'})
            </p>
            <p className="ds-typography__body ds-color__indigo">
              <b>Time zone</b>: {timezone}
            </p>
          </ColumnRight>
        </ListItem>
      );
    }) : <ListItem>...loading</ListItem>}
  </Wrapper>
);

List.propTypes = {
  assigningStudentId: PropTypes.number,
  unsetAssignmentIds: PropTypes.func.isRequired,
  saveInstructorAssignment: PropTypes.func.isRequired,
  setAssigningStudentId: PropTypes.func.isRequired,
  studentList: PropTypes.array,
};

export default List;
