import ActiveContent from '../../../components/EmployerConnectorCard/states/ActiveContent';
import { connect } from 'react-redux';
import { showECModal } from '../../../../EmployerConnector/actions';
import { updateJobSeekingProfile } from '../../../actions';

export const mapDispatchToProps = (dispatch) => ({
  showECModal: (status) => dispatch(showECModal(status)),
  updateJobSeekingProfile: (jobSeekingProfile) => dispatch(updateJobSeekingProfile(jobSeekingProfile)),
});

export const mapStateToProps = ({
  careerSupportCenter: { jobSeekingProfile },
}) => ({
  jobSeekingProfile,
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveContent);
