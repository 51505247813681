import OnboardingWrapper from './shared/OnboardingWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import TermsAndConditionsBody from '../../../admin/EmployerConnector/Employers/components/TermsAndConditionsBody';

const TermsAndConditionPage = ({ onClickNext, updatePolicy, employer }) => {
  const handleClick = async () => {
    await updatePolicy(employer.id, { agreed_to_terms: true });
    onClickNext();
  };

  return (
    <OnboardingWrapper
      header="Welcome to the Employer Connector!"
      panelHeader="Terms & Conditions"
      buttonLabel="Accept"
      onClick={handleClick}
    >
      <TermsAndConditionsBody />
    </OnboardingWrapper>
  );
};

TermsAndConditionPage.propTypes = {
  employer: PropTypes.shape({
    id: PropTypes.number,
  }),
  onClickNext: PropTypes.func.isRequired,
  updatePolicy: PropTypes.func.isRequired,
};

export default TermsAndConditionPage;
