import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  margin-top: var(--ds-spacing__m);
  margin-bottom: var(--ds-spacing__m);
  justify-content: end;

  button:first-child {
    margin-right: var(--ds-spacing__s);
  }

  @media (max-width: 350px) {
    flex-direction: column;

    button:first-child {
      margin-right: 0;
      margin-bottom: var(--ds-spacing__s);
    }
  }
`;

const StyledTooltip = styled.div`
  .__react_component_tooltip {
    max-width: 561px;
    height: 100px;
    padding: var(--ds-spacing__s);
  }

  color: var(--ds-color__static-content-primary);

  p {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export { Wrapper, StyledTooltip };
