import styled from 'styled-components';

const ConfirmationContainer = styled.div`
  h4,
  p {
    text-align: center;
  }

  i {
    font-size: 60px;
    height: 60px;
    color: var(--ds-bg-color__int-primary);
  }

  padding-bottom: 20px;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { ConfirmationContainer, CenteredContainer };
