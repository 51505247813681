import {
  IAdmission,
  IUpdateAdmissionParams,
  ITabData,
} from '../../../interfaces';
import Checkbox from './checkbox';
import { Body, Cap } from '@careerfoundry/bubble';
import { StyledLink, TermsContainer } from './styles';
import {
  HeaderWrapper,
  StatusWrapper,
  TextWrapper,
  StyledHeader,
} from '../styles';
import Message from '../../Message';
import React from 'react';
import { getClassName } from './helpers';
import { renderStatus } from '../../../helpers';

interface IProps {
  tabData: ITabData;
  updateAdmission: (params: IUpdateAdmissionParams, tab: string) => void;
  admission: IAdmission;
}

const Terms: React.FC<IProps> = ({ tabData, updateAdmission, admission }) => {
  const toggleCheckbox = (e: any) => {
    updateAdmission(
      {
        terms_of_participation: {
          terms_of_participation_signed: e.target.checked,
        },
      },
      'termsOfParticipation'
    );
  };

  const clickable =
    tabData.state === 'todo' || tabData.state === 'action_required';

  return (
    <TermsContainer>
      <HeaderWrapper>
        <StyledHeader variant='h3' tag='h3'>
          Terms of Participation
        </StyledHeader>
        <StatusWrapper>
          <Cap tag='p' variant='bold'>
            status:{' '}
          </Cap>
          {renderStatus(tabData.state, true)}
        </StatusWrapper>
      </HeaderWrapper>
      <TextWrapper>
        <Body tag='p' variant='large'>
          Please read the terms carefully and sign them. They contain lots of
          useful information specifically for students taking the course with a
          Bildungsgutschein, and are separate to the CareerFoundry Terms and
          Conditions.
        </Body>
        {tabData.reason && (
          <Message message={tabData.reason} messageType='error' />
        )}
        <StyledLink
          target='_blank'
          href={tabData.termsLink}
          variant='large'
          className={`${getClassName(tabData.state)}`}
        >
          Click here to read the Terms of participation
        </StyledLink>
        <Checkbox
          labelText={
            'I have read, understood and signed the terms of participation'
          }
          onClick={(e) => {
            clickable && toggleCheckbox(e);
          }}
          defaultChecked={!clickable}
        />
      </TextWrapper>
    </TermsContainer>
  );
};

export default Terms;
