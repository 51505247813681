import React, { useEffect, useState } from 'react';
import ConsentForm from '../ConsentForm/index.js';
import PropTypes from 'prop-types';
import StyledInterviewTypeSelect from './styles/StyledInterviewTypeSelect';
import { startInterviewTracking } from '../../../shared/helpers/tracking';

const InterviewTypeSelect = ({
  categories,
  getInterviewQuestions,
  setShareWithMentor,
  shareWithMentor,
}) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, []);

  useEffect(() => {
    setSelectedCategory(categories[0]);
  }, [categories]);

  const interviewFormSubmit = (e) => {
    e.preventDefault();
    getInterviewQuestions(selectedCategory);
    startInterviewTracking(selectedCategory, 'ip_start_interview_click');
  };

  return (
    <StyledInterviewTypeSelect onSubmit={interviewFormSubmit}>
      <h4 className="ds-typography__h4 text-center">
        Practice interview setup
      </h4>
      <p className="ds-typography__body">Select interview type:</p>
      <div className="categories">
        {categories.map((category) => (
          <div
            className="category-select"
            key={category}
            onClick={() => setSelectedCategory(category)}
          >
            <input
              checked={category === selectedCategory}
              type="radio"
              value={category}
            />
            {category}
          </div>
        ))}
      </div>
      <ConsentForm
        subText="* Your career specialist will see your answers, but they’ll give you feedback only if you ask them for it."
        onShareAnswer={setShareWithMentor}
        shareWithMentor={shareWithMentor}
      />
      <div className="text-center">
        <button
          type="submit"
          className="ds-button__primary"
          disabled={!selectedCategory}
          target="_blank"
        >
          Start interview training
        </button>
      </div>
    </StyledInterviewTypeSelect>
  );
};

InterviewTypeSelect.propTypes = {
  categories: PropTypes.array.isRequired,
  getInterviewQuestions: PropTypes.func.isRequired,
  setShareWithMentor: PropTypes.func.isRequired,
  shareWithMentor: PropTypes.bool.isRequired,
};

export default InterviewTypeSelect;
