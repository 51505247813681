import ProgressBarWrapper from '../../styles/ProgressBarWrapper';
import ProgressCircle from './ProgressCircle';
import PropTypes from 'prop-types';
import React from 'react';

const ModalProgressBar = ({ currentState, progresses }) => (
  <ProgressBarWrapper>
    {
      progresses.map(progress => (
        <ProgressCircle key={progress} isActive={ currentState === progress }/>
      ))
    }
  </ProgressBarWrapper>
);

ModalProgressBar.propTypes = {
  currentState: PropTypes.string.isRequired,
  progresses: PropTypes.array.isRequired,
};

export default ModalProgressBar;
