import styled from 'styled-components';

const ActiveButtonsWrapper = styled.div`
  display: flex;
  .ds-button__secondary, .ds-button__primary {
    margin-right: 21px;
    margin-top: 16px;
  }

  @media (max-width: 768px) {
    display: block;
    .ds-button__secondary, .ds-button__primary  {
      width: 100%;
    }
    .ds-button__primary {
      background-color: var(--ds-color__indigo)
    }  
  }

  @media (min-width: 768px) {
    button {
      min-width: 126px;
    }
  }

  @media (max-width: 360px) {
  }
`;

ActiveButtonsWrapper.displayName = 'ActiveButtonsWrapper';

export default ActiveButtonsWrapper;
