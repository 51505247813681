import { Field, Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import FilterWrapper from './FilterWrapper';
import PropTypes from 'prop-types';
import Tooltip from '../../../../shared/components/Tooltip';
import moment from 'moment';
import { setScreenshot } from '../helpers';

const RegistrationForm = ({ callInformation, setCallInformation }) => {
  const [file, setFile] = useState(null);
  const fileInput = useRef();

  const clearPrevFile = () => {
    fileInput.current.value = null;
  };

  function shouldFilter(date) {
    const currentDate = moment();
    const earliestDate = moment().subtract(6, 'months').toDate();

    return date < currentDate && date > earliestDate;
  }

  return (
    <Formik
      initialValues={{
        callRequestScreenshot: '',
        mentorComment: '',
        startTime: '',
      }}
    >
      {() => (
        <Form>
          <FilterWrapper
            label='Date and time of call*'
            tooltip={
              <Tooltip
                className='tooltip'
                content='Please indicate the date and time of the call in your timezone.'
                place='right'
              >
                <i className='ds-icon__help-line ds-typography__text' />
              </Tooltip>
            }
          >
            <Field
              name='startTime'
              render={() => (
                <DatePicker
                  popperPlacement='bottom-start'
                  showPopperArrow={false}
                  selected={callInformation.startTime}
                  onChange={(val) => {
                    setCallInformation({ ...callInformation, startTime: val });
                  }}
                  placeholderText='Pick date and time'
                  filterDate={shouldFilter}
                  filterTime={shouldFilter}
                  dateFormat='MMMM d, yyyy h:mm aa'
                  showTimeSelect
                  required
                />
              )}
            />
          </FilterWrapper>
          <FilterWrapper
            label='Proof of call necessity*'
            tooltip={
              <Tooltip
                className='tooltip'
                content='Please upload a screenshot of your communication with the student that led to this off-platform call.'
                place='right'
              >
                <i className='ds-icon__help-line ds-typography__text' />
              </Tooltip>
            }
          >
            <Field
              name='callRequestScreenshot'
              render={() => (
                <div>
                  <label
                    htmlFor='callRequestScreenshot'
                    className='ds-button__secondary upload-button'
                  >
                    Upload proof
                  </label>
                  <input
                    id='callRequestScreenshot'
                    type='file'
                    ref={fileInput}
                    onClick={clearPrevFile}
                    onInput={(event) => {
                      setCallInformation({
                        ...callInformation,
                        callRequestScreenshot: event.currentTarget.files[0],
                      });
                      setScreenshot(event.currentTarget.files[0], setFile);
                    }}
                    required
                  />
                  {file ? (
                    <span className='requestFile'>
                      {file}
                      <i
                        className='ds-icon__close-thin'
                        onClick={() => {
                          setFile(null);
                          setCallInformation({
                            ...callInformation,
                            callRequestScreenshot: null,
                          });
                        }}
                      />
                    </span>
                  ) : (
                    <span>
                      {' '}
                      10 MB limit, file types allowed: jpg, png, jpeg{' '}
                    </span>
                  )}
                </div>
              )}
            />
          </FilterWrapper>
          <FilterWrapper label='Comments'>
            <Field
              name='mentorComment'
              render={() => (
                <textarea
                  placeholder='Write a comment about this call for the Career Services team (optional)'
                  onChange={(event) => {
                    setCallInformation({
                      ...callInformation,
                      mentorComment: event.currentTarget.value,
                    });
                  }}
                />
              )}
            />
          </FilterWrapper>
        </Form>
      )}
    </Formik>
  );
};

RegistrationForm.propTypes = {
  callInformation: PropTypes.shape({
    callRequestScreenshot: PropTypes.instanceOf(File),
    mentorComment: PropTypes.string,
    startTime: PropTypes.instanceOf(Date),
  }),
  setCallInformation: PropTypes.func.isRequired,
};

export default RegistrationForm;
