import styled from 'styled-components';

const StyledWelcomeModal = styled.div`
  padding: 32px 49px 0 32px;
  h4 {
    height: 56px;
    margin: 0 180px 33px 0;
    font-size: 28px;
    line-height: 2;
  }
  .button-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    margin-top: 64px;
    padding: 0 0 18px;
    position: relative;

    h4 {
      font-size: 24px;
      width: 100%;
      margin: 0;
      padding: 0 22px;
    }

    hr {
      padding: 0;
      margin-top: 0;
      border: 0;
      border-bottom: solid 2px #c5c8c9;
    }

    .button-section {
      border-top: solid 2px #c5c8c9;
      button {
        margin: 24px 0px;
        width: 90%;
      }
    }
  }
`;

StyledWelcomeModal.displayName = 'StyledWelcomeModal';

export default StyledWelcomeModal;
