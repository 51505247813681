import AttachmentPreview from '../../../../Uploader/components/AttachmentPreview';
import styled from 'styled-components';

const FileUploaderView = styled(AttachmentPreview)`
  width: 0;
  height: 0;
  margin: -15px 0 0 48px;
  justify-content: unset;
  align-items: unset;
  display: unset;
  padding: 0;
  min-width: unset;
  border: none;
  i {
    color: #d8d8d8 !important;
    background: #fff;
    margin-top: 0 !important;
    font-size: 33px;
  }
  p {
    font-size: 9px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: auto;
    color: #223c50;
    width: fit-content;
  }

  @media (max-width: 768px) {
    margin: -4px 0 0 48px;
  }
`;

FileUploaderView.displayName = 'FileUploaderView';

export default FileUploaderView;
