import JobApplicationModal from '../../components/JobApplicationsTab/modal/JobApplicationModal';
import { connect } from 'react-redux';
import { handleCloseModal } from '../JobApplicationsTab/actions';

export const mapStateToProps = ({
  userProfile: {
    modalAction,
    viewApplication,
    jobApplication,
    studentIsViewing,
  },
}) => ({
  isMentor: !studentIsViewing,
  jobApplication,
  modalAction,
  modalIsOpen: viewApplication,
});

export const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => dispatch(handleCloseModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobApplicationModal);
