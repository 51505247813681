import { Button, Body, Header } from '@careerfoundry/bubble';
import { Section, SectionStep } from '../Documents/styles';
import React from 'react';
import { renderStatus } from '../../../../AfaAdmissions/helpers';
import { redirectTo } from '../../../../../utils/redirectTo';

const MAX_SCORE = 17;

interface SurveyProps {
  data: {
    state: string;
    typeformId?: string;
    score?: number | null;
  };
  header: string;
  formId: string;
}

const Survey: React.FC<SurveyProps> = ({ data, header, formId }) => {
  return (
    <Section>
      <SectionStep>
        <div>
          <Header tag='h5' variant='h5'>
            {header}
          </Header>
          {renderStatus(data.state, true)}
        </div>
      </SectionStep>
      {data.score && (
        <Body tag='p' variant='small'>
          Score: {data.score}/{MAX_SCORE}
        </Body>
      )}
      {data.typeformId && (
        <Button
          label='View results'
          size='standard'
          variant='secondary'
          onClick={() =>
            redirectTo({
              url: `https://admin.typeform.com/form/${formId}/results#responses`,
              openInNewTab: true,
            })
          }
        />
      )}
    </Section>
  );
};

export default Survey;
