import ImageText from './ImageText';
import styled from 'styled-components';

const ReversedImageText = styled(ImageText)`
  flex-direction: row-reverse;
  margin-bottom: 0;
  
  p {
    padding: 16px 0 5px;
  }
`;

ReversedImageText.displayName = 'ReversedImageText';

export default ReversedImageText;
