import styled from 'styled-components';

const BackButton = styled.a`
  display: block;
  margin-bottom: 20px;

  i {
    margin-right: 6px;
    vertical-align: middle;
  }
`;

export {
  BackButton,
};
