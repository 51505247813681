import axios from 'axios';

class CourseDetailsService {
  constructor(authToken) {
    this.authToken = authToken;
  };

  fetchCourseDetails(params){
    return axios.get('/en/enroll.json', { params }).then(response => {
      return response.data.data;
    }).catch((error) => {
      return({ data: null, error: error.response.data });
    });
  };
};

export default CourseDetailsService;
