import ModalWrapper from '../shared/ModalWrapper';
import ProfileDetailsViewContainer from '../../container/ProfileDetailsViewContainer';
import PropTypes from 'prop-types';
import React from 'react';
import StyledProfileBody from '../../styles/StyledProfileBody';

const PersonalDetails = ({ onNextClick, onPrevClick }) => {
  return (
    <ModalWrapper
      header="Your personal data overview"
      currentState="personalDetails"
      onNextClick={onNextClick}
      onPrevClick={onPrevClick}
    >
      <StyledProfileBody>
        <ProfileDetailsViewContainer studentViewing={true} />
      </StyledProfileBody>
    </ModalWrapper>
  );
};

PersonalDetails.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
};

export default PersonalDetails;
