import CourseCard from './styles/CourseCard';
import DropDown from '../../containers/Dropdown';
import EmptyTab from '../EmptyTab';
import Note from '../Note';
import NotesWrapper from './styles/NotesWrapper';
import PaginationControls from '../../../shared/components/Pagination';
import PropTypes from 'prop-types';
import React from 'react';
import Sidebar from '../../containers/Sidebar';
import { paginationCollection } from '../../../../utils/paginationCollection';

const StudentNotesTab = ({
  activePage,
  attendees,
  hasAttendees,
  selectedCourse,
  setActivePage,
}) => {
  const ITEMS_COUNT_PER_PAGE = 5;

  const handlePageChange = (pageNumber) => setActivePage(pageNumber);

  return (
    <div className='ds-grid__row'>
      <Sidebar />
      <DropDown />
      {hasAttendees ? (
        <CourseCard className='ds-grid__col-md-9 ds-grid__col-xs-12'>
          {attendees.map(
            (attendee) =>
              attendee.courseId === selectedCourse && (
                <div key={attendee.courseId}>
                  <div>
                    {paginationCollection(
                      attendee.notes,
                      ITEMS_COUNT_PER_PAGE
                    ).map(
                      (page, i) =>
                        i + 1 === activePage && (
                          <NotesWrapper key={`page_${i}`}>
                            {page.map((note) => (
                              <Note
                                key={note.id}
                                id={note.id}
                                title={note.exerciseTitle}
                                createdAt={note.createdAt}
                                quote={note.quote}
                                text={note.text}
                              />
                            ))}
                          </NotesWrapper>
                        )
                    )}
                  </div>
                  {attendee.notes &&
                    attendee.notes.length > ITEMS_COUNT_PER_PAGE && (
                      <PaginationControls
                        activePage={activePage}
                        itemsCountPerPage={ITEMS_COUNT_PER_PAGE}
                        totalItemsCount={attendee.notes.length}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                      />
                    )}
                </div>
              )
          )}
        </CourseCard>
      ) : (
        <EmptyTab type='notes' />
      )}
    </div>
  );
};

StudentNotesTab.propTypes = {
  activePage: PropTypes.number.isRequired,
  attendees: PropTypes.arrayOf(
    PropTypes.shape({
      courseId: PropTypes.number,
      graduationDate: PropTypes.string,
      name: PropTypes.string.isRequired,
      nextExercise: PropTypes.string,
      progress: PropTypes.array,
      start: PropTypes.string,
      state: PropTypes.string.isRequired,
      targetCompletion: PropTypes.string,
    })
  ),
  hasAttendees: PropTypes.bool.isRequired,
  selectedCourse: PropTypes.number.isRequired,
  setActivePage: PropTypes.func.isRequired,
};

export default StudentNotesTab;
