import React from 'react';
import Card from './components/Card';
import { createRoot } from 'react-dom/client';

const studentSupportCardDesktopContainer = document.getElementById(
  'StudentSupportCardDesktop'
);
const studentSupportCardMobileContainer = document.getElementById(
  'StudentSupportCardMobile'
);

if (studentSupportCardDesktopContainer) {
  const studentSupportCardDesktopContainerRoot = createRoot(
    studentSupportCardDesktopContainer
  );

  studentSupportCardDesktopContainerRoot.render(<Card />);
}

/**
 * TODO: The grid layout used for course home page needs to be updated
 * in order to not have separate container to render card for its mobile view
 */
if (studentSupportCardMobileContainer) {
  const studentSupportCardMobileContainerRoot = createRoot(
    studentSupportCardMobileContainer
  );

  studentSupportCardMobileContainerRoot.render(<Card />);
}
