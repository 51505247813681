import styled from 'styled-components';

const StyledParagraphWithLink = styled.p`
  margin: 38px 0 0 !important;
  color: var(--ds-color__indigo);
  font-size: 14px !important;

  a {
    font-size: 14px !important;
    display: inline !important;
  }
`;

StyledParagraphWithLink.displayName = 'StyledParagraphWithLink';

export default StyledParagraphWithLink;
