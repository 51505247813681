import {
  deleteAnswer,
  evaluateAnswer,
  setCurrentAnswer,
  setReviewNavigable,
  setReviewNote,
  setReviewRating,
} from '../../actions';
import EvaluationInfoContent from '../../components/InterviewReview/EvaluationInfoContent';
import { connect } from 'react-redux';

export const mapDispatchToProps = (dispatch) => ({
  deleteAnswer: (answerId) => dispatch(deleteAnswer(answerId)),
  evaluateAnswer: (answerId, params) =>
    dispatch(evaluateAnswer(answerId, params)),
  setCurrentAnswer: (answer) => dispatch(setCurrentAnswer(answer)),
  setReviewNavigable: (isReviewNavigable) =>
    dispatch(setReviewNavigable(isReviewNavigable)),
  setReviewNote: (reviewNote) => dispatch(setReviewNote(reviewNote)),
  setReviewRating: (reviewRating) => dispatch(setReviewRating(reviewRating)),
});

export const mapStateToProps = ({
  interviewPrep: { answersForReview, currentAnswer },
}) => ({
  answersForReview,
  currentAnswer,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EvaluationInfoContent);
