import React, { useEffect, useState } from 'react';
import AudioButtons from './AudioButtons';
import AudioControls from './AudioControls';
import AudioHeader from './AudioHeader';
import AudioLoader from './AudioLoader';
import AudioStatusText from './AudioStatusText';
import ErrorMessage from './ErrorMessage';
import PropTypes from 'prop-types';
import StyledRecorder from './styles/StyledRecorder';
import { audioRecorderTracking } from '../../../shared/helpers/tracking';
import statusConstants from '../../statusConstant';

const ALLOCATED_TIME = 180;

const AudioRecorder = ({
  handleAnswer,
  question,
  errorsaving,
  onCancelErrorSaving,
  onDisableAudioIcon,
  onShowReplayIcon,
}) => {
  const [mediaStream, setMediaStream] = useState(undefined);
  const [recordingState, setRecordingState] = useState(
    statusConstants.INACTIVE
  );
  const [timeLeft, setTimeLeft] = useState(ALLOCATED_TIME);
  const [audio, setAudio] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [blob, setBlob] = useState(null);
  const [chunks, setChunks] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState({});

  const setupStream = () => {
    if (typeof MediaRecorder === 'undefined') {
      setErrorMessage(
        "MediaRecorder is not yet supported by your browser. Either enable it within your browser's settings or switch to a supported browser."
      );
    } else {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(setMediaStream)
        .catch((error) => {
          setErrorMessage(error.toString());
        });
    }
  };

  useEffect(setupStream, []);

  const initMediaRecorder = () => {
    mediaStream &&
      setMediaRecorder(
        Object.assign(new MediaRecorder(mediaStream), {
          ondataavailable: handleDataAvailable,
          onstop: handleStop,
        })
      );
    setRecordingState(statusConstants.INACTIVE);
  };

  const saveAudio = () => {
    if (chunks.length) {
      const blob = new Blob(chunks, { type: 'audio/mp4' });
      const audioURL = URL.createObjectURL(blob);
      setAudio(audioURL);
      setBlob(blob);
    }
  };

  useEffect(saveAudio, [chunks]);

  useEffect(() => {
    if (recordingState === statusConstants.RECORDING) {
      if (!timeLeft) {
        handleTimeElapsed();
      }

      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeLeft, recordingState]);

  useEffect(() => {
    let recordingStateTimer;
    if (recordingState === statusConstants.TIME_ELAPSED) {
      recordingStateTimer = setTimeout(() => {
        mediaRecorder.stop();
      }, 500);
    }

    return () => {
      recordingStateTimer && clearTimeout(recordingStateTimer);
    };
  }, [recordingState]);

  const handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      setChunks([data]);
    }
  };

  const handleTimeElapsed = () => {
    setRecordingState(statusConstants.TIME_ELAPSED);
  };

  const handleStop = () => {
    setRecordingState(statusConstants.FINISHED);
  };

  const handleSaveError = () => {
    if (errorsaving) {
      const status = errorsaving
        ? statusConstants.ERROR_SAVING
        : statusConstants.INACTIVE;
      setRecordingState(status);
    }
  };

  const resetState = () => {
    URL.revokeObjectURL(audio);
    setAudio(null);
    setBlob(null);
    setTimeLeft(ALLOCATED_TIME);
    initMediaRecorder();

    if (typeof onCancelErrorSaving === 'function') {
      onCancelErrorSaving();
    }
  };

  useEffect(resetState, [mediaStream, question]);

  useEffect(handleSaveError, [errorsaving]);

  const submitRecording = () => {
    setRecordingState(statusConstants.SUBMITTING_RECORDING);
    handleAttachment();
  };

  const resubmitRecording = () => {
    if (typeof onCancelErrorSaving === 'function') {
      onCancelErrorSaving();
    }
    submitRecording();
  };

  const resetRecording = () => {
    resetState();
    onShowReplayIcon();
    audioRecorderTracking(question.question, 'ip_restart_recording_click');
  };

  const handleAttachment = () => {
    const file = new File([blob], `${question.id}-${new Date().getTime()}.mp4`);
    handleAnswer(question.id, file);
  };

  const showAudio = recordingState !== statusConstants.INACTIVE;
  const showAnswer = !!(recordingState === statusConstants.FINISHED && audio);
  const timeLeftString = new Date(timeLeft * 1000).toISOString().substr(14, 8);

  return (
    <StyledRecorder>
      {mediaStream ? (
        <div className='wrapper'>
          {recordingState === statusConstants.SUBMITTING_RECORDING ? (
            <AudioLoader />
          ) : (
            <>
              {recordingState === statusConstants.ERROR_SAVING ? (
                <ErrorMessage errorMessage='An error occurred when saving your recording. Please try again!' />
              ) : (
                <>
                  <AudioStatusText
                    recordingState={recordingState}
                    showAnswer={showAnswer}
                  />
                  <AudioHeader
                    showAnswer={showAnswer}
                    timeLeftString={timeLeftString}
                  />
                  {showAudio && (
                    <AudioControls
                      recordingState={recordingState}
                      showAnswer={showAnswer}
                      audio={audio}
                      question={question}
                      mediaStream={mediaStream}
                    />
                  )}
                </>
              )}
            </>
          )}

          <AudioButtons
            mediaRecorder={mediaRecorder}
            question={question}
            recordingState={recordingState}
            resetRecording={resetRecording}
            setRecordingState={setRecordingState}
            submitRecording={submitRecording}
            onResubmitRecording={resubmitRecording}
            onDisableAudioIcon={onDisableAudioIcon}
          />
        </div>
      ) : (
        <ErrorMessage errorMessage={errorMessage} />
      )}
    </StyledRecorder>
  );
};

AudioRecorder.propTypes = {
  errorsaving: PropTypes.bool,
  handleAnswer: PropTypes.func.isRequired,
  onCancelErrorSaving: PropTypes.func.isRequired,
  onDisableAudioIcon: PropTypes.func.isRequired,
  onShowReplayIcon: PropTypes.func.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.shape({
          attachment: PropTypes.shape({
            fileIcon: PropTypes.string,
            fileName: PropTypes.string,
            id: PropTypes.number,
            s3Url: PropTypes.string,
          }),
          category: PropTypes.string,
          date: PropTypes.string,
          id: PropTypes.number,
          industry: PropTypes.string,
          notes: PropTypes.string,
          question: PropTypes.string,
          questionInsights: PropTypes.string,
          rating: PropTypes.number,
          shared: PropTypes.bool,
        }),
      })
    ),
    id: PropTypes.number,
    question: PropTypes.string,
    questionInsights: PropTypes.string,
  }),
};

export default AudioRecorder;
