import ApiService from '../../services/ApiService';
import { SET_REQUIRED_DATA } from './constants';
import { get } from 'lodash';

export const setRequiredData = (requiredData) => ({
  requiredData,
  type: SET_REQUIRED_DATA,
});

export const fetchRequiredData = () => async (dispatch) => {
  const attendeesApi = new ApiService('/api/v1/attendees');
  const meApi = new ApiService('/api/v1/me.json');

  try {
    const attendeesApiResponse = await attendeesApi.makeGetCall();
    const meApiResponse = await meApi.makeGetCall();

    dispatch(
      setRequiredData({
        attendeesData: get(attendeesApiResponse, 'data.data.attendees', {}),
        meData: get(meApiResponse, 'data.data', {}),
      })
    );
  } catch (error) {
    // TODO: Report to Rollbar
  }
};
