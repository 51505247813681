import React, { useEffect } from 'react';
import { loadedChatSuccess, updateUnReadMessage } from './actions';
import App from '../../components/App';
import PropTypes from 'prop-types';
import { chatClient } from '../../../index';
import { connect } from 'react-redux';
import { init } from './helpers';
import { openMessagingWindow } from '../ChannelButton/actions';
import { toggleMsgPreview } from '../../actions';

const title = document.title;

export const AppContainer = ({
  loadedChatSuccess,
  unReadMessageCount,
  updateUnReadMessage,
  ...rest
}) => {
  useEffect(() => {
    (async () => {
      try {
        const { me, userToken } = await init();

        const userDetails = {
          id: me.uuid,
          image: me.avatar,
          name: me.name,
        };
        const user = await chatClient.setUser(userDetails, userToken);

        loadedChatSuccess(me, user.me.total_unread_count);

        chatClient.on((e) => {
          if (e.total_unread_count !== undefined) {
            updateUnReadMessage(e.total_unread_count);
          }
        });
      } catch (error) {
        console.log('uh oh', error);
      }
    })();
  }, []);

  useEffect(() => {
    document.title = `${
      unReadMessageCount > 0 ? `(${unReadMessageCount}) ` : ''
    }${title}`;
  }, [unReadMessageCount]);

  return <App unReadMessageCount={unReadMessageCount} {...rest} />;
};

AppContainer.propTypes = {
  loadedChatSuccess: PropTypes.func,
  toggleMsgPreview: PropTypes.func,
  unReadMessageCount: PropTypes.number,
  updateUnReadMessage: PropTypes.func,
};

export const mapStateToProps = (
  { messaging: { chatHasLoaded, isMessagingPreviewOpen, unReadMessageCount } },
  { isExistingUser }
) => ({
  chatHasLoaded,
  isExistingUser,
  isMessagingPreviewOpen,
  unReadMessageCount,
});

export const mapDispatchToProps = (dispatch) => ({
  loadedChatSuccess: (me, unReadMessageCount) =>
    dispatch(loadedChatSuccess(me, unReadMessageCount)),
  openMessagingWindow: (channelId) => dispatch(openMessagingWindow(channelId)),
  toggleMsgPreview: () => dispatch(toggleMsgPreview()),
  updateUnReadMessage: (count) => dispatch(updateUnReadMessage(count)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
