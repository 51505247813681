import React from 'react';
import StyledIntroduction from '../styles/StyledIntroduction';

const Introduction = () => {
  return (
    <StyledIntroduction>
      <p className='ds-typography__body break'>
        Being able to work remotely has gone from a negotiated perk to more of
        an expectation. For those who are not already a{' '}
        <a
          onClick={() =>
            window.open(
              'https://www.careerfoundry.com/en/blog/career-change/how-to-become-a-digital-nomad/'
            )
          }
        >
          {' '}Digital Nomad
        </a>
        {' '}this section represents tips, tricks, and tools to help set you up for success in your first remote job.
      </p>
      <p className='ds-typography__body break'>
        It is important to note that for some, their CareerFoundry coursework is
        in fact the first remote work they have done. To this we say 'Huzzah!'
        so much learning and experimenting has already happened or is about to
        take place! This software training institute is not just about learning
        technical skills, it's also about learning how to learn and how to work
        in an increasingly digital world. You can use this section of the Career
        Support Center to reinforce and refine your approach.
      </p>
    </StyledIntroduction>
  );
};

export default Introduction;
