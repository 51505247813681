import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styles } from '../styles';

const CardFields = ({
  cardElementForStripeCheckout,
  handleChange,
  nameOnCard,
  stripe,
}) => {
  useEffect(() => {
    const elements = stripe.elements();
    const options = {
      hidePostalCode: true,
      style: styles,
    };
    const cardElement = elements.create('card', options);
    cardElementForStripeCheckout(cardElement);
    cardElement.mount('#card-element');
  }, []);

  return (
    <div>
      <div className={`cf-form__group--floating ${(nameOnCard && nameOnCard.length > 0) ? 'cf-form__group--frozen' : ''}`}>
        <input
          name="nameOnCard"
          onChange={handleChange}
          value={nameOnCard}
          type="text"
          id="cardholder-name"
          required="required"
          className="cf-form__input"
        />
        <label className="cf-form__label--floating">Cardholder Name</label>
        <div className="cf-form__validation"></div>
      </div>
      <div className="cf-form__group--floating">
        <div id="card-element"></div>
      </div>
    </div>
  );
}

CardFields.propTypes = {
  cardElementForStripeCheckout: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  nameOnCard: PropTypes.string.isRequired,
  stripe: PropTypes.object.isRequired,
}

export default CardFields;
