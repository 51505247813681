import { keysToSnake } from '../../utils/keysConverter';

export const formatNoteData = (data) => {
  if (!data) return {};
  const dataToSnakeCase = keysToSnake(data);

  return {
    ...dataToSnakeCase,
    attendeeName: dataToSnakeCase.student.name,
    startTime: dataToSnakeCase.datetime_of_call,
    tag_list: dataToSnakeCase.tag_list.join(','),
  };
};

export const splitString = (str) => {
  if (str) {
    return str.toLowerCase().split(',');
  }

  return [];
};
