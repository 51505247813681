import styled from 'styled-components';

const StyledSideNav = styled.div`
  margin-right: 24px;
  height: 500px;
  position: sticky;
  top: 120px;
  width: 260px;

  @media (min-width: 1024px) and (max-width: 1100px) {
    width: 250px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

StyledSideNav.displayName = 'StyledSideNav';

export default StyledSideNav;
