import styled from 'styled-components';

const StyledFeatureDescription = styled.div`
  .bullet-points,
  h5 {
    margin-bottom: 12px;
  }

  .bullet-points {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  li {
    font-size: 18px;
    margin-left: 20px;
    white-space: nowrap;
  }

  a {
    display: inline-block !important;
    margin-bottom: 40px !important;
  }
`;

StyledFeatureDescription.displayName = 'StyledFeatureDescription';

export default StyledFeatureDescription;
