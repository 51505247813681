import styled from 'styled-components';

const StyledApp = styled.div`
  h2,
  p {
    color: var(--ds-color__indigo);
  }
`;

StyledApp.displayName = 'StyledApp';

export { StyledApp };
