import axios from 'axios';

const cfOrigins = [
  'localhost:3333',
  'careerfoundry.com',
];

function getAuthDataFromLocalStorage () {
  return JSON.parse(localStorage.getItem('authentication-keys')) || {
    accessToken: null,
    client: null,
    uid: null,
  };
}

function getCfAxiosInstance () {
  const instance = axios.create({});

  instance.interceptors.response.use(
    (response) => {
      if (!cfOrigins.some(url => window.location.host.includes(url))) {
        return response;
      }
      const { headers } = response;
      const { client, uid } = headers;
      const accessToken = headers['access-token'];
      if (accessToken && client && uid) {
        localStorage.setItem('authentication-keys', JSON.stringify({ accessToken, client, uid }));
      }
      return response;
    },
    (error) => {
      return error;
    },
  );

  instance.interceptors.request.use(
    (request) => {
      if (!cfOrigins.some(url => window.location.host.includes(url))) {
        return request;
      }
      const { headers } = request;
      const { accessToken, client, uid } = getAuthDataFromLocalStorage();
      if (accessToken && client && uid) {
        headers['access-token'] = accessToken;
        headers.client = client;
        headers.uid = uid;
      }
      return request;
    },
    (error) => {
      return error;
    },
  );

  return instance;
}

const cfApi = getCfAxiosInstance();

export default cfApi;
