import PropTypes from 'prop-types';
import React from 'react';
import StyleAttachmentPreview from './styles/StyledAttachmentPreview';

const AttachmentPreview = ({
  attachment,
  deleteAttachment,
  className = '',
}) => {
  const { fileIcon, id, fileName, s3Url } = attachment;

  return (
    <StyleAttachmentPreview className={className}>
      <a href={s3Url} target='_blank' rel='noreferrer'>
        <i className={`fa ${fileIcon || 'fa-file-o'}`} />
        <p>{fileName ? fileName.substring(0, 12) : ''}</p>
      </a>
      {deleteAttachment && (
        <i
          className='fa fa-times-circle'
          onClick={() => deleteAttachment(id)}
        />
      )}
    </StyleAttachmentPreview>
  );
};

AttachmentPreview.propTypes = {
  attachment: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  deleteAttachment: PropTypes.func,
};

export default AttachmentPreview;
