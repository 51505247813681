import { ArrowIconWrapper, Name, Wrapper } from './styles';
import React, { useState } from 'react';
import { ArrowIcon } from '../SideNavList/icons';
import Course from '../Course';
import PropTypes from 'prop-types';

const Program = ({
  course = {},
  defaultShouldShowCourses = true,
  isStudent,
}) => {
  const [shouldShowCourses, setShouldShowCourses] = useState(
    defaultShouldShowCourses
  );

  return (
    <Wrapper>
      <Name
        className="ds-typography__body--bold"
        onClick={() => setShouldShowCourses(!shouldShowCourses)}
      >
        <ArrowIconWrapper shouldShowCourses={shouldShowCourses}>
          <ArrowIcon />
        </ArrowIconWrapper>
        {course.name}
      </Name>
      {shouldShowCourses &&
        course.courses.map((course, index) => (
          <Course key={course.id || index} {...course} isStudent={isStudent} />
        ))}
    </Wrapper>
  );
};

Program.propTypes = {
  course: PropTypes.shape({
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        ...Course.propTypes,
      })
    ),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ...Course.propTypes,
  }),
  defaultShouldShowCourses: PropTypes.bool,
  isStudent: PropTypes.bool,
};

export default Program;
