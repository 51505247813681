import ModalHeader from '../../components/shared/ModalHeader';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  mentorCallNotes: { currentCalendarEntry, modalType },
}) => ({
  modalType,
  profile: currentCalendarEntry,
});

export default connect(mapStateToProps, null)(ModalHeader);
