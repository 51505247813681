import PropTypes from 'prop-types';
import React from 'react';
import TextAreaInput from '../../components/shared/TextAreaInput';
import { connect } from 'react-redux';
import { setFormFields } from '../../action';

const TextAreaInputContainer = ({
  onInputChange,
  mentorNoteForm,
  id,
  ...rest
}) => {
  return <TextAreaInput id={id} onInputChange={onInputChange} text={mentorNoteForm[id]} {...rest} />;
};

export const mapStateToProps = ({
  mentorCallNotes: {
    mentorNoteForm,
  },
}) => ({
  mentorNoteForm,
});

export const mapDispatchToProps = dispatch => ({
  onInputChange: data => dispatch(setFormFields(data)),
});

TextAreaInputContainer.propTypes = {
  id: PropTypes.string.isRequired,
  mentorNoteForm: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextAreaInputContainer);
