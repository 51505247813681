import styled from 'styled-components';

const StyledImageText = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;

  p {
    flex-basis: 70%;
    margin-bottom: 0;
    padding: 27px 0 33px;
  }


  @media (max-width: 768px) {
    p {
      flex-basis: 100%;
      padding: 0 22px !important;
      text-align: justify;
    }

    .text-image {
      display: none;
    }
  }
`;

StyledImageText.displayName = 'StyledImageText';

export default StyledImageText;
