import { CheckboxContainer, HiddenCheckbox, StyledCheckbox } from './styles';
import { Body } from '@careerfoundry/bubble';
import React from 'react';

interface CheckboxProps {
  defaultChecked: any;
  onClick: (e: any) => void;
  name?: string;
  labelText: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  defaultChecked,
  onClick,
  name,
  labelText,
}) => {
  return (
    <CheckboxContainer>
      <>
        <HiddenCheckbox
          defaultChecked={defaultChecked}
          onClick={onClick}
          name={name}
        />
        <StyledCheckbox checked={defaultChecked} />
      </>
      {labelText && (
        <Body tag='p' variant='medium'>
          {labelText}
        </Body>
      )}
    </CheckboxContainer>
  );
};

export default Checkbox;
