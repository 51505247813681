import { PProfileTextarea, PWrapper } from './styles';
import React, { useEffect, useState } from 'react';
import Head from '../../components/Head';
import ImageUploader from '../ImageUploader';
import NavButtons from '../../components/NavButtons';
import PropTypes from 'prop-types';

const ProfilePage = ({ data, navigateTo, onSubmitProfileData, userData }) => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [profileBio, setProfileBio] = useState(
    userData.profile ? userData.profile.bio : ''
  );

  const onClickSubmit = () => {
    onSubmitProfileData(profilePicture, profileBio);
  };

  useEffect(() => {
    if (userData.profile && userData.profile.bio) {
      setProfileBio(userData.profile.bio);
    }
  }, [userData]);

  return (
    <PWrapper className='ob-profile'>
      <Head data={data.head} />
      <ImageUploader
        onSelectImage={setProfilePicture}
        userImage={userData.avatar}
      />
      <PProfileTextarea>
        <textarea
          placeholder='Tell us about yourself...'
          value={profileBio || ''}
          onChange={(event) => setProfileBio(event.target.value)}
        />
      </PProfileTextarea>
      <NavButtons
        onClickNext={onClickSubmit}
        onClickBack={() => navigateTo('back')}
        nextButtonText='All done!'
      />
    </PWrapper>
  );
};

ProfilePage.propTypes = {
  data: PropTypes.object,
  navigateTo: PropTypes.func,
  onSubmitProfileData: PropTypes.func,
  userData: PropTypes.object,
};

export default ProfilePage;
