import { handleStatusChange, handleSubmit } from '../../actions';
import Table from '../../components/Table';
import { connect } from 'react-redux';

export const mapStateToProps = ({ callEdits: { calls, options } }) => ({
  calls,
  options,
});

export const mapDispatchToProps = (dispatch) => ({
  handleStatusChange: (id, status) => dispatch(handleStatusChange(id, status)),
  handleSubmit: (id, status) => dispatch(handleSubmit(id, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
