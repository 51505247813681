import { Card } from '../styles';
import styled from 'styled-components';

const NotesWrapper = styled.div`
  max-height: 110px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(34, 60, 80, 0.4);
  }
`;

const Note = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 3px;

  p:first-of-type {
    text-transform: uppercase;
    margin-right: 16px;
    color: rgba(34, 60, 80, 0.75);
    font-weight: bold;
    min-width: 220px;
  }

  p:last-of-type {
    flex: 1 1 0px;
  }

  p {
    margin-bottom: 0;
  }

  i {
    color: #a7b1b9;
    padding-right: 16px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const StyledCandidateNotes = styled(Card)`
  textarea {
    width: 100%;
    height: 57px;
    border: 1px solid #e8ebed;
    border-radius: 4px;
    resize: none;
    padding: 8px 16px;
    font-size: 14px;
    font-family: TradeGothic, Helvetica, Arial, sans-serif;
    color: var(--ds-color__indigo);

    &::placeholder {
      font-family: TradeGothic, Helvetica, Arial, sans-serif;
      font-weight: bold;
      color: rgba(34, 60, 80, 0.4);
    }

    &:focus-visible {
      outline: none;
    }
  }
`;

const StyledModal = styled.div`
  text-align: center;

  i {
    font-size: 36px;
    color: var(--ds-color__petrol--dark);
  }

  p {
    margin-bottom: 32px;
  }

  button {
    width: 88px;

    &:first-of-type {
      margin-right: 4px;
    }

    &:last-of-type {
      margin-left: 4px;
    }
  }
`;

export { Note, NotesWrapper, StyledCandidateNotes, StyledModal };
