import React, { forwardRef } from 'react';
import Card from '../App/styles/Card';
import PropTypes from 'prop-types';
import StyledSectionContent from './styles/StyledSectionContent';
import StyledSectionHeader from './styles/StyledSectionHeader';

// eslint-disable-next-line react/display-name
const SectionCards = forwardRef((props, ref) => {
  const {
    className = '',
    header,
    secondaryLink,
    children,
    isMobile,
    id = '',
  } = props;
  return (
    <Card className={`mb-72 section-card ${className}`} id={id} ref={ref}>
      <hr />
      {header && (
        <StyledSectionHeader>
          <h4 className="ds-typography__h4 text-left">{header}</h4>
          {secondaryLink && !isMobile && (
            <a
              className="ds-button__secondary inline-button"
              href={secondaryLink}
            >
              Open full view
            </a>
          )}
        </StyledSectionHeader>
      )}

      <StyledSectionContent>{children}</StyledSectionContent>
    </Card>
  );
});

SectionCards.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  header: PropTypes.string,
  id: PropTypes.string,
  isMobile: PropTypes.bool,
  secondaryLink: PropTypes.string,
};

export default SectionCards;
