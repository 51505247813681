import Button from './Button';
import ButtonWrapper from '../../styles/ButtonWrapper';
import ModalBody from './ModalBody';
import ModalProgressBar from './ModalProgressBar';
import PropTypes from 'prop-types';
import React from 'react';
import StyledBackground from '../../styles/StyledBackground';
import StyledWrapper from '../../styles/StyledWrapper';

const ModalWrapper = ({
  children,
  submitDisabled,
  submitLoading,
  header,
  currentState,
  onNextClick,
  onPrevClick,
}) => {
  const progresses = [
    'personalDetails',
    'professionalStatement',
    'accountSetup',
  ];
  return (
    <StyledWrapper>
      <StyledBackground />
      <ModalBody header={header}>{children}</ModalBody>
      <ModalProgressBar currentState={currentState} progresses={progresses} />
      <ButtonWrapper>
        <Button
          className="ds-button__secondary"
          label="Back"
          onClick={() => onPrevClick()}
        />
        <Button
          className="ds-button__primary"
          disabled={submitDisabled}
          label={currentState === 'accountSetup' ? 'Finish' : 'Next'}
          loading={submitLoading}
          onClick={() => onNextClick()}
        />
      </ButtonWrapper>
    </StyledWrapper>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  currentState: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  submitLoading: PropTypes.bool,
};

export default ModalWrapper;
