import AdminUserSpecializations from '../../../react/admin_user/specialization';
import React from 'react';
import { createRoot } from 'react-dom/client';

const adminUserSpecializationsContainer = document.querySelector(
  '.admin_user__specializations'
);

if (adminUserSpecializationsContainer) {
  const adminUserSpecializationsContainerRoot = createRoot(
    adminUserSpecializationsContainer
  );

  adminUserSpecializationsContainerRoot.render(<AdminUserSpecializations />);
}
