import { setPasswordStrength } from '../../../utils/insertZXCVBNScript';

module.exports = (() => {
  const thisState = {};

  const init = function () {
    thisState.form = new CF.Form('#new_user');
    window.insertZXCVBNScript();
    const passwordField = document.querySelector('#user_password');
    passwordField.addEventListener('input', setPasswordStrength);
  };

  return {
    init: init,
  };
})();
