import { SET_REQUIRED_DATA } from './constants';

export const initialState = {
  attendeesData: {},
  meData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_REQUIRED_DATA: {
      return {
        ...state,
        attendeesData: action.requiredData.attendeesData,
        meData: action.requiredData.meData,
      };
    }
    default:
      return state;
  }
}
