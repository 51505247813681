import React, { useEffect, useState } from 'react';
import { SearchWrapper, TableHeader, TableWrapper } from '../../styles'; // shared styles
import FilterSelect from './FilterSelect';
import { Link } from 'react-router-dom';
import ListRow from './ListRow';
import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import StyledInput from '../../../../shared/components/Input/styles';
import { filteredList } from '../../helpers';

const CandidatesList = ({
  candidatesList,
  candidatesListLoaded,
  candidateStates,
  interviewers,
  sendWelcomeEmail,
}) => {
  const [interviewer, setInterviewer] = useState('');
  const [candidateState, setCandidateState] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredCandidatesList, setFilteredCandidatesList] = useState([]);
  const showEmptyState =
    candidatesListLoaded && filteredCandidatesList.length === 0;

  useEffect(() => {
    setFilteredCandidatesList(candidatesList);
  }, [candidatesList]);

  const filterCandidates = () => {
    const newList = filteredList(
      interviewer,
      candidateState,
      searchText,
      candidatesList
    );
    setFilteredCandidatesList(newList);
  };

  return (
    <>
      <TableHeader>
        <Link
          className="ds-button__secondary"
          to="/en/admin/instructors/candidates/new"
        >
          Create new candidate
        </Link>
        <SearchWrapper>
          <StyledInput>
            <input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              type="text"
              placeholder="Search by name"
            />
          </StyledInput>
          <FilterSelect
            label="Interviewer"
            filter={interviewer}
            options={interviewers}
            handleChange={(e) => setInterviewer(e.target.value)}
            name="interviewer"
          />
          <FilterSelect
            label="Candidate status"
            filter={candidateState}
            options={candidateStates}
            handleChange={(e) => setCandidateState(e.target.value)}
            name="state"
          />
          <button className="ds-button__primary" onClick={filterCandidates}>
            Search
          </button>
        </SearchWrapper>
      </TableHeader>
      <TableWrapper>
        <table>
          <thead>
            <tr>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>ROLE</th>
              <th>STATUS</th>
              <th>SIGN-UP LINK</th>
              <th>WELCOME EMAIL</th>
              <th>VIEW</th>
            </tr>
          </thead>
          <tbody>
            {candidatesListLoaded &&
              filteredCandidatesList.map((candidate) => (
                <ListRow
                  key={candidate.id}
                  candidate={candidate}
                  sendWelcomeEmail={sendWelcomeEmail}
                />
              ))}
          </tbody>
        </table>
        {!candidatesListLoaded && <LoadingSpinner />}
        {showEmptyState && (
          <p className="empty-state">
            Nothing to see here.. Try a different search.
          </p>
        )}
      </TableWrapper>
    </>
  );
};

CandidatesList.propTypes = {
  candidateStates: PropTypes.array.isRequired,
  candidatesList: PropTypes.array.isRequired,
  candidatesListLoaded: PropTypes.bool.isRequired,
  interviewers: PropTypes.array.isRequired,
  sendWelcomeEmail: PropTypes.func.isRequired,
};

export default CandidatesList;
