export const constants = {
  ANSWER_QUESTION_SUCCESS: 'InterviewPrep.ANSWER_QUESTION_SUCCESS',
  CLOSE_EVALUATION: 'InterviewPrep.CLOSE_EVALUATION',
  DELETE_ANSWER_SUCCESS: 'InterviewPrep.DELETE_ANSWER_SUCCESS',
  EVALUATE_ANSWER_SUCCESS: 'InterviewPrep.EVALUATE_ANSWER_SUCCESS',
  EVALUATE_PREVIOUS_RESPONSES: 'InterviewPrep.EVALUATE_PREVIOUS_RESPONSES',
  FINISH_INTERVIEW: 'InterviewPrep.FINISH_INTERVIEW',
  GET_INTERVIEW_ANSWERS_FAILURE: 'InterviewPrep.GET_INTERVIEW_ANSWERS_FAILURE',
  GET_INTERVIEW_ANSWERS_SUCCESS: 'InterviewPrep.GET_INTERVIEW_ANSWERS_SUCCESS',
  GET_INTERVIEW_QUESTIONS_FAILURE:
    'InterviewPrep.GET_INTERVIEW_QUESTIONS_FAILURE',
  GET_INTERVIEW_QUESTIONS_SUCCESS:
    'InterviewPrep.GET_INTERVIEW_QUESTIONS_SUCCESS',
  HANDLE_BACK: 'InterviewPrep.HANDLE_BACK',
  HANDLE_CLOSE_MODAL: 'InterviewPrep.HANDLE_CLOSE_MODAL',
  OPEN_INTERVIEW_GUIDELINES: 'InterviewPrep.OPEN_INTERVIEW_GUIDELINES',
  OPEN_QUESTION_INSIGHTS: 'InterviewPrep.OPEN_QUESTION_INSIGHTS',
  SET_CURRENT_ANSWER: 'InterviewPrep.SET_CURRENT_ANSWER',
  SET_INTERVIEW_STAGE: 'InterviewPrep.SET_INTERVIEW_STAGE',
  SET_REVIEW_NAVIGABLE: 'InterviewPrep.SET_REVIEW_NAVIGABLE',
  SET_REVIEW_NOTE: 'InterviewPrep.SET_REVIEW_NOTE',
  SET_REVIEW_RATING: 'InterviewPrep.SET_REVIEW_RATING',
  SET_SHARE_WITH_MENTOR: 'InterviewPrep.SET_SHARE_WITH_MENTOR',
  START_INTERVIEW: 'InterviewPrep.START_INTERVIEW',
  START_REVIEW: 'InterviewPrep.START_REVIEW',
};
