import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StudentPage from '../../components/students';
import { connect } from 'react-redux';
import { openMessagingWindow } from '../../../Messaging/MessagingNotification/containers/ChannelButton/actions';

const StudentPageContainer = ({
  activeChannel,
  openMessagingWindow,
}) => {
  useEffect(() => {
    if (activeChannel) {
      openMessagingWindow(activeChannel);
    }
  }, [activeChannel]);

  return (
    <StudentPage />
  );
};

export const mapStateToProps = ({
  messaging: {
    activeChannel,
  },
}) => ({
  activeChannel,
});

export const mapDispatchToProps = dispatch => ({
  openMessagingWindow: (channelId) => dispatch(openMessagingWindow(channelId)),
});

StudentPageContainer.propTypes = {
  activeChannel: PropTypes.any,
  openMessagingWindow: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentPageContainer);
