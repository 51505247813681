import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 2rem;

  > h2 {
    margin-bottom: 1.75rem;

    @media (max-width: 768px) {
      margin-top: 2.5rem;
    }
  }

  .ds-grid__row {
    > div {
      min-height: 55rem;
      background-color: var(--ds-color__white);

      @media (max-width: 768px) {
        min-height: 21rem;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      > div:nth-child(1) {
        @media (max-width: 479px) {
          padding: 1rem;
        }
      }
    }
  }

  > div:nth-child(2) {
    background-color: ${({ isLoading }) => (isLoading ? '#eef5f7' : 'inherit')};
  }
`;

export { Wrapper };
