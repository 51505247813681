import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StyledList from './styles/StyledList';

const MenteeList = ({ mentees, newSearch, handleClick }) => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    if (newSearch) {
      setIsHidden(false);
    }
  }, [mentees]);

  const handleMenteeClick = (mentee) => {
    handleClick(mentee);
    setIsHidden(true);
  };

  return (
    <StyledList>
      {!isHidden &&
        mentees.slice(0, 5).map((mentee) => (
          <li key={mentee.id} onClick={() => handleMenteeClick(mentee)}>
            {mentee.value}
          </li>
        ))}
    </StyledList>
  );
};

MenteeList.propTypes = {
  handleClick: PropTypes.func.isRequired,
  mentees: PropTypes.arrayOf(PropTypes.object).isRequired,
  newSearch: PropTypes.bool.isRequired,
};

export default MenteeList;
