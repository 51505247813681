import styled from 'styled-components';

const StyledMediaResourceDescription = styled.div`
  padding: 0px 8px;
  height: ${(props) => (props.preview ? '190px' : '300px')};
  .type {
    margin: 9px 0;
    font-size: 9px;
    text-transform: uppercase;
    color: var(--ds-color__indigo);
  }

  div {
    display: flex;
    justify-content: space-between;
    margin: 8px 0 8px;

    & > p:first-of-type {
      align-self: center;
      margin: 0;
    }
  }
`;

StyledMediaResourceDescription.displayName = 'StyledMediaResourceDescription';

export default StyledMediaResourceDescription;
