import ControlButton from './styles/ControlButton';
import ControlButtons from './styles/ControlButtons';
import Layout from './styles/Layout';
import PropTypes from 'prop-types';
import React from 'react';

const Controls = ({
  decreaseGoal,
  disableDecreaseToggle,
  disableIncreaseToggle,
  increaseGoal,
}) => {
  return (
    <Layout>
      <ControlButtons>
        <ControlButton disabled={disableIncreaseToggle} onClick={increaseGoal}>
          <i className='ds-icon__math-sign-plus ds-typography__h5' />
        </ControlButton>
        <ControlButton disabled={disableDecreaseToggle} onClick={decreaseGoal}>
          <i className='ds-icon__math-sign-minus ds-typography__h5' />
        </ControlButton>
      </ControlButtons>
    </Layout>
  );
};

Controls.propTypes = {
  decreaseGoal: PropTypes.func.isRequired,
  disableDecreaseToggle: PropTypes.bool,
  disableIncreaseToggle: PropTypes.bool,
  increaseGoal: PropTypes.func.isRequired,
};

export default Controls;
