import {
  getCourseById,
  isCourseStarted,
} from '../shared/helpers/courseHelpers';
import ApiService from '../../services/ApiService';
import { constants } from './constants';
import { get } from 'lodash';

const setRequiredData = ({
  attendees,
  courseCards,
  isSelectedCourseStarted,
  selectedCourseId,
  userId,
}) => ({
  payload: {
    attendees,
    courseCards,
    isSelectedCourseStarted,
    selectedCourseId,
    userId,
  },
  type: constants.SET_REQUIRED_DATA,
});

const setSelectedCourseId = ({ courseId = null }) => ({
  payload: {
    courseId,
  },
  type: constants.SET_SELECTED_COURSE_ID,
});

const setNotesLoader = ({ isLoading = true }) => ({
  payload: {
    isLoading,
  },
  type: constants.SET_NOTES_LOADER,
});

const setNotesData = ({
  isFetchingMoreNotes,
  isFetchingAllNotes,
  notes = [],
  shouldResetAllNotesCount = false,
  totalNotesToLoadCount = 0,
}) => ({
  payload: {
    isFetchingAllNotes,
    isFetchingMoreNotes,
    notes,
    shouldResetAllNotesCount,
    totalNotesToLoadCount,
  },
  type: constants.SET_NOTES_DATA,
});

const getRequiredData = () => async (dispatch) => {
  const meApi = new ApiService('/api/v1/me.json');
  const userAttendeesApi = (userId) =>
    new ApiService(`/api/v1/user_attendees/${userId}.json`);

  try {
    const meApiResponse = await meApi.makeGetCall();

    const userId = get(meApiResponse, 'data.data.id', null);

    const userAttendeesApiResponse = await userAttendeesApi(
      userId
    ).makeGetCall();

    const userAttendeesApiResponseData = get(
      userAttendeesApiResponse,
      'data.data',
      {}
    );

    const {
      attendees: courseCardsAttendees = [],
      programAttendees: courseCardsProgramAttendees = [],
    } = userAttendeesApiResponseData.courseCards;

    const { attendees = [] } = userAttendeesApiResponseData.attendees;

    const { activeCourse: activeCourseId = 0 } =
      userAttendeesApiResponseData.attendees;

    const activeCourse =
      getCourseById({ courses: attendees, id: activeCourseId }) || {};

    dispatch(
      setRequiredData({
        attendees,
        courseCards: {
          attendees: courseCardsAttendees,
          programAttendees: courseCardsProgramAttendees,
        },
        isSelectedCourseStarted: isCourseStarted({
          startDate: activeCourse.start,
        }),
        selectedCourseId: activeCourseId,
        userId,
      })
    );
  } catch (error) {
    // TODO: Report to Rollbar
  }
};

const fetchNotes =
  ({
    courseId,
    isFetchingAllNotes,
    isFetchingMoreNotes,
    notesPerPage,
    pageNumber,
    searchQuery,
    userId,
  }) =>
  async (dispatch) => {
    const notesApi = new ApiService('/api/v1/notes');

    try {
      dispatch(setNotesLoader({ isLoading: true }));

      const notesApiResponse = await notesApi.makeGetCall({
        course_id: courseId,
        page: pageNumber,
        per_page: notesPerPage,
        text_query: searchQuery,
        user_id: userId,
      });

      const notes = get(notesApiResponse, 'data.data.items', []);

      dispatch(
        setNotesData({
          isFetchingAllNotes,
          isFetchingMoreNotes,
          notes,
          totalNotesToLoadCount: get(
            notesApiResponse,
            'data.data.pagination.totalEntries'
          ),
        })
      );
    } catch (error) {
      // TODO: Report to Rollbar
    }
  };

export {
  setRequiredData,
  setNotesData,
  getRequiredData,
  setSelectedCourseId,
  fetchNotes,
  setNotesLoader,
};
