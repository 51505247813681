import { Body } from '@careerfoundry/bubble';
import React from 'react';
import UploadButton from '../../../../InstructorOnboarding/InstructorProfile/components/Documents/UploadButton';
import { UploadWrapper } from './styles';
import { getFileData } from '../../../../InstructorOnboarding/InstructorProfile/components/PersonalInformation/helpers';
import { isUrl } from '../../../../InstructorOnboarding/InstructorProfile/helpers';
import { filePreview } from '../../../../../utils/filePreview';

export interface IFile {
  data: string;
  fileName: string;
  fileSize: number;
}

interface IProps {
  file: IFile;
  proof: string;
  setFieldValue: (name: string, file: IFile) => void;
  name: string;
  setUploadError: (setUploadError: boolean) => void;
}

const Upload: React.FC<IProps> = ({
  file,
  proof,
  setFieldValue,
  name,
  setUploadError,
}) => {
  const handleFileUpload = (
    data: Promise<IFile | string>,
    setFieldValue: (name: string, file: IFile) => void
  ) => {
    data.then((res: any) => {
      const file = {
        data: res.data,
        fileName: res.fileName,
        fileSize: res.fileSize,
      };
      setFieldValue(name, file);
      setUploadError(false);
    });
  };

  return (
    <UploadWrapper className='upload-wrapper'>
      {file && file.data ? (
        <>
          {isUrl(file.data) ? (
            <a
              href={file.data}
              target='_blank'
              className='ds-text__link'
              rel='noreferrer'
            >
              {file.fileName}
            </a>
          ) : (
            <Body
              tag='p'
              variant='medium'
              onClick={() => filePreview(file.data)}
            >
              {file.fileName}
            </Body>
          )}
          <i
            className='ds-icon__trash'
            onClick={() =>
              setFieldValue(name, {
                data: '',
                fileName: '',
                fileSize: 0,
              })
            }
          ></i>
        </>
      ) : (
        <UploadButton
          required={proof === ''}
          handleChange={(ev) =>
            handleFileUpload(getFileData(ev), setFieldValue)
          }
        />
      )}
    </UploadWrapper>
  );
};

export default Upload;
