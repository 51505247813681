import ActivationConfirm from './ActivationConfirm';
import ActivationFailure from './ActivationFailure';
import ActivationSuccess from './ActivationSuccess';
import ContractConfirm from './ContractConfirm';
import ContractSentSuccess from './ContractSentSuccess';
import ContractSignedSuccess from './ContractSignedSuccess';
import DeletionConfirm from './DeletionConfirm';
import DeletionFailure from './DeletionFailure';
import DeletionSuccess from './DeletionSuccess';
import ModalContent from './styles';
import PropTypes from 'prop-types';
import React from 'react';
import TheModal from '../../../../shared/components/TheModal';

const ConfirmationModal = ({
  errorMessage,
  handleActivate,
  handleClose,
  handleDelete,
  loadingButton,
  modalAction,
  modalIsOpen,
  updateContractStatus,
}) => (
  <TheModal isModalOpen={modalIsOpen} handleClose={handleClose}>
    <ModalContent>
      {(() => {
        switch (modalAction) {
          case 'confirmActivation':
            return (
              <ActivationConfirm
                handleClose={handleClose}
                handleActivate={handleActivate}
                loadingButton={loadingButton}
              />
            );
          case 'activationFailure':
            return (
              <ActivationFailure
                handleClose={handleClose}
                errorMessage={errorMessage}
              />
            );
          case 'activationSuccess':
            return <ActivationSuccess />;
          case 'contractSignedSuccess':
            return <ContractSignedSuccess handleClose={handleClose} />;
          case 'contractSentSuccess':
            return <ContractSentSuccess handleClose={handleClose} />;
          case 'confirmDeletion':
            return (
              <DeletionConfirm
                handleClose={handleClose}
                loadingButton={loadingButton}
                handleDelete={handleDelete}
              />
            );
          case 'deletionFailure':
            return (
              <DeletionFailure
                handleClose={handleClose}
                errorMessage={errorMessage}
              />
            );
          case 'deletionSuccess':
            return <DeletionSuccess />;
          case 'hello_sign':
          case 'contract_sent':
            return (
              <ContractConfirm
                modalAction={modalAction}
                handleClose={handleClose}
                updateContractStatus={updateContractStatus}
                loadingButton={loadingButton}
              />
            );
        }
      })()}
    </ModalContent>
  </TheModal>
);

ConfirmationModal.propTypes = {
  errorMessage: PropTypes.shape({
    message: PropTypes.string,
  }),
  handleActivate: PropTypes.func,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  loadingButton: PropTypes.bool,
  modalAction: PropTypes.string,
  modalIsOpen: PropTypes.bool,
  updateContractStatus: PropTypes.func,
};

export default ConfirmationModal;
