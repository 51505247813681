import styled from 'styled-components';

const StyledGrid = styled.div`
  width: 100%;
  padding: 0;

  @media (min-width: 1800px) {
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

StyledGrid.displayName = 'StyledGrid';

export default StyledGrid;
