import WelcomePage from '../../components/WelcomePage';
import { connect } from 'react-redux';

export const mapStateToProps = (state) => {
  return {
    userData: state.onBoarding.userData,
  };
};

export default connect(mapStateToProps)(WelcomePage);
