import { showECModal, updateJobSeekingProfile } from '../../../actions';
import InactiveContent from '../../../components/EmployerConnectorCard/states/InactiveContent';
import { connect } from 'react-redux';

export const mapDispatchToProps = (dispatch) => ({
  showECModal: (status) => dispatch(showECModal(status)),
  updateJobSeekingProfile: (jobSeekingProfile) => dispatch(updateJobSeekingProfile(jobSeekingProfile)),
});

export const mapStateToProps = ({
  careerSupportCenter: { jobSeekingProfile },
}) => ({
  jobSeekingProfile,
});

export default connect(mapStateToProps, mapDispatchToProps)(InactiveContent);
