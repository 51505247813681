import GroupedNumberCheckInput from '../../components/shared/GroupedNumberCheckInput';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setFormFields } from '../../action';

const GroupedNumberCheckInputContainer = ({
  onInputChange,
  mentorNoteForm,
  id,
  ...rest
}) => {
  return (
    <GroupedNumberCheckInput
      id={id}
      onInputChange={onInputChange}
      prevSelected={mentorNoteForm[id]}
      {...rest}
    />
  );
};

export const mapStateToProps = ({ mentorCallNotes: { mentorNoteForm } }) => ({
  mentorNoteForm,
});

export const mapDispatchToProps = (dispatch) => ({
  onInputChange: (data) => dispatch(setFormFields(data)),
});

GroupedNumberCheckInputContainer.propTypes = {
  id: PropTypes.string.isRequired,
  mentorNoteForm: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupedNumberCheckInputContainer);
