import SubNav from '../../components/SubNav';
import { connect } from 'react-redux';
import { setShouldShowSubNav } from '../../actions';

const mapStateToProps = (state) => {
  const { courseNavigationData, shouldShowSubNav, common } = state.sideNavList;

  return {
    courses: courseNavigationData,
    isStudent: common.isStudent,
    shouldShowSubNav,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setShouldShowSubNav: (shouldShowSubNav) =>
    dispatch(setShouldShowSubNav(shouldShowSubNav)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubNav);
