import ArchivedAction from './ArchivedAction';
import BilledAction from './BilledAction';
import PayAction from './PayAction';
import React from 'react';
import SentLinkOrManualEnrolled from './SentLinkOrManualEnrolled';

const enrollmentLinkAction = (link) => {
  const linkState = link.attributes.state;

  switch (linkState) {
    case 'created':
      return (
        <div>
          <SentLinkOrManualEnrolled link={link} />
          <ArchivedAction link={link} />
        </div>
      );
    case 'link_sent':
      return <ArchivedAction link={link} />;
    case 'waiting_for_billing':
      return <BilledAction link={link} />;
    case 'waiting_for_payment':
      return <PayAction link={link} />;
    case 'paid':
      return <ArchivedAction link={link} />;
    default:
      return '-';
  }
};

export default enrollmentLinkAction;
