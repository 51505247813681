import Evaluation from '../../components/InterviewReview/Evaluation';
import { connect } from 'react-redux';
import { setCurrentAnswer } from '../../actions';

export const mapDispatchToProps = (dispatch) => ({
  setCurrentAnswer: (answer) => dispatch(setCurrentAnswer(answer)),
});

export const mapStateToProps = ({
  interviewPrep: { answersForReview, currentAnswer, isReviewNavigable },
}) => ({
  answersForReview,
  currentAnswer,
  isReviewNavigable,
});

export default connect(mapStateToProps, mapDispatchToProps)(Evaluation);
