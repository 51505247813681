import { camelCase } from 'lodash';

export const errorMessage = (formErrors, key) => {
  const formattedKey = camelCase(key);
  return formErrors[formattedKey]
    ? formErrors[formattedKey].attachment.slice(-1).toString()
    : '';
};

export const documentByKey = (state, key) =>
  state.find((o) => o.key === key).data;
