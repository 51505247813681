import PropTypes from 'prop-types';
import React from 'react';
import StyledStatusText from './styles/StyledStatusText';
import statusConstants from '../../statusConstant';

const AudioStatusText = ({ recordingState, showAnswer }) => {
  const getText = () => {
    switch (recordingState) {
    case statusConstants.RECORDING:
      return 'Recording';
    case statusConstants.TIME_ELAPSED:
      return 'Time is up!';
    default:
      return '';
    }
  };

  const text = getText();

  return (
    (recordingState !== 'inactive' && !showAnswer) && (
      <StyledStatusText>
        {text && (
          <>
            {recordingState === statusConstants.RECORDING && (
              <i className="fa fa-circle" />
            )}
            <span className="ds-typography__body">{text}</span>
          </>
        )}
      </StyledStatusText>
    )
  );
};

AudioStatusText.propTypes = {
  recordingState: PropTypes.string,
  showAnswer: PropTypes.bool,
};

export default AudioStatusText;
