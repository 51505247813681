import {
  handleCountryChange,
  handlePaymentMethodChange,
  handleSave,
} from '../actions';
import PaymentInputs from '../../../components/PersonalInformation/PaymentInputs';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  instructorProfile: { formErrors, personalData },
}) => ({
  formErrors,
  personalData,
});

export const mapDispatchToProps = (dispatch) => ({
  handleCountryChange: (event) => dispatch(handleCountryChange(event)),
  handlePaymentMethodChange: (paymentMethod) =>
    dispatch(handlePaymentMethodChange(paymentMethod)),
  handleSave: (name, value) => dispatch(handleSave(name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInputs);
