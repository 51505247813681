import styled from 'styled-components';

const StyledLongSelectInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  border: 2px solid #7a9cd5;
  background-color: #e5ecf7;
  width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  p {
    margin: 0;
    color: #5058AF;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: #003280;
    p {
      color: var(--ds-color__white);
    }
    `}
`;

StyledLongSelectInput.displayName = 'StyledLongSelectInput';

export default StyledLongSelectInput;
