import {
  INACTIVE_USER_TYPES,
  SET_AGREED_TO_DISCOURSE_PRIVACY_POLICY,
  SET_IS_ACCEPTING_PRIVACY_POLICY,
  SET_IS_VISIBLE,
  SET_REQUIRED_DATA,
  SET_SHOULD_SHOW_DATA_POLICY_AGREEMENT_MODAL,
  SET_SHOULD_SHOW_SUB_NAV,
} from './constants';
import { getCourse, getCourseById } from '../shared/helpers/courseHelpers';

export const initialState = {
  attendeesData: {},
  common: {},
  courseNavigationData: [],
  discussionsUserData: {},
  isAcceptingPrivacyPolicy: false,
  isVisible: false,
  meData: {},
  shouldShowDataPolicyAgreementModal: false,
  shouldShowSubNav: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SHOULD_SHOW_DATA_POLICY_AGREEMENT_MODAL: {
      return {
        ...state,
        shouldShowDataPolicyAgreementModal:
          action.shouldShowDataPolicyAgreementModal,
      };
    }
    case SET_AGREED_TO_DISCOURSE_PRIVACY_POLICY: {
      return {
        ...state,
        discussionsUserData: {
          ...state.discussionsUserData,
          agreedToDiscoursePrivacyPolicy: action.agreedToDiscoursePrivacyPolicy,
        },
      };
    }
    case SET_SHOULD_SHOW_SUB_NAV: {
      return {
        ...state,
        shouldShowSubNav: action.shouldShowSubNav,
      };
    }
    case SET_IS_VISIBLE: {
      return {
        ...state,
        isVisible: action.isVisible,
      };
    }
    case SET_IS_ACCEPTING_PRIVACY_POLICY: {
      return {
        ...state,
        isAcceptingPrivacyPolicy: action.isAcceptingPrivacyPolicy,
      };
    }
    case SET_REQUIRED_DATA: {
      const { attendeesData, meData } = action.requiredData;
      const userDefinedActiveCourse =
        getCourseById({
          courses: attendeesData.attendees || [],
          id: attendeesData.activeCourse,
        }) || null;
      const isAnyCourseActive = !!getCourse({
        courses: attendeesData.attendees || [],
        state: 'active',
      });
      const roles = meData.roles || [];
      const isStudent = roles.includes('student');
      const isUserActive = !INACTIVE_USER_TYPES.includes(meData.userType);

      return {
        ...state,
        attendeesData: action.requiredData.attendeesData,
        common: {
          isAnyCourseActive,
          isStudent,
          isUserActive,
          userDefinedActiveCourse,
        },
        courseNavigationData: action.requiredData.courseNavigationData,
        discussionsUserData: action.requiredData.discussionsUserData,
        meData: action.requiredData.meData,
      };
    }
    default:
      return state;
  }
}
