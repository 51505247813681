import MyActivitiesIcon from '../../../components/icons/MyActivitiesIcon';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { common } = state.sideNavList;
  const { isStudent, isUserActive } = common;
  const isVisible = isUserActive;

  return {
    isStudent,
    isVisible,
  };
};

const MyActivitiesIconContainer = (props) => {
  return <MyActivitiesIcon {...props} />;
};

MyActivitiesIconContainer.propTypes = {
  ...MyActivitiesIcon.propTypes,
};

export default connect(mapStateToProps, null)(MyActivitiesIconContainer);
