import Button from '../../../../EmployerConnector/modals/shared/Button';
import PropTypes from 'prop-types';
import React from 'react';
import StyledBodyPanel from '../../styles/StyledBodyPanel';
import StyledScrollPanel from '../../styles/StyledScrollPanel';

const OnboardingPanel = ({ header, children, buttonLabel, onClick }) => {
  return (
    <StyledBodyPanel>
      <h3 className="ds-typography__h3">{header}</h3>
      <StyledScrollPanel>
        {children}
      </StyledScrollPanel>
      <div className="button-section">
        <Button
          className="ds-button__primary"
          label={buttonLabel}
          onClick={onClick}
        />
      </div>
    </StyledBodyPanel>
  );
};

OnboardingPanel.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  children: PropTypes.node,
  header: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default OnboardingPanel;
