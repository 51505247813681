import React, { Component } from 'react';

import FooterButtons from './FooterButtons.jsx';
import PropTypes from 'prop-types';
import ResultBar from './ResultBar.jsx';

class ResultSlide extends Component {
  constructor(props) {
    super(props);

    this.isFinalSlide = this.isFinalSlide.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
  }

  isFinalSlide() {
    return this.props.detailsProgress >= this.props.numberOfQuestions;
  }

  handleNextClick() {
    this.props.onNextClick(this.isFinalSlide());
  }

  render() {
    const nextButtonLabel = this.isFinalSlide() ? 'Close' : 'See details';

    return (
      <div className='quiz__slide quiz__result__slide'>
        <div className='quiz__results'>
          <p className='cf-small--gray cf-bold'>Quiz: {this.props.quizLabel}</p>
          <h2>{`You scored ${this.props.numberOfCorrectAnswers}/${this.props.numberOfQuestions} on this quiz`}</h2>
          <ResultBar questions_status_list={this.props.questions_status_list} />
        </div>
        <FooterButtons
          nextButtonLabel={nextButtonLabel}
          prevButtonLabel='Take again'
          onPrevClick={this.props.onTakeAgainClick}
          onNextClick={this.handleNextClick}
          modalLoading={this.props.modalLoading}
        />
      </div>
    );
  }
}

ResultSlide.propTypes = {
  detailsProgress: PropTypes.number,
  modalLoading: PropTypes.bool,
  numberOfCorrectAnswers: PropTypes.number,
  numberOfQuestions: PropTypes.number,
  onNextClick: PropTypes.func,
  onTakeAgainClick: PropTypes.func,
  questions_status_list: PropTypes.array,
  quizLabel: PropTypes.string,
};

export default ResultSlide;
