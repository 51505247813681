import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0 15px;

  @media (max-width: 768px) {
    padding: 0;
  }

  @media (min-width: 768px) {
    margin-right: 45px;
  }

  @media (max-width: 768px) {
    .str-chat-channel-list--open.messaging {
      width: 80%;
    }
  }

  @media (max-width: 2500px) and (min-width: 1200px) {
    section {
      padding: inherit;
    }
  }
  .str-chat.messaging,
  .str-chat.commerce {
    background-color: #f4f5f6;
    height: calc(100vh - 85px);
  }
  .str-chat__channel {
    &-list-messenger {
      position: relative;
      background-color: #f4f5f6;
      height: calc(100% - 70px);
      &__main {
        height: 100%;
        overflow-y: scroll;
      }
    }
    &-search {
      background-color: #f4f5f6;
      padding: 23px 16px 0px 16px;
      &-input {
        height: 40px;
        border-radius: unset;
        border: 1px solid #a7b1b9;
        border-radius: 8px;
        background-color: #ffffff;

        font-family: 'Trade Gothic Next W01';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        color: rgba(34, 60, 80, 0.75);
        display: flex;
        align-items: center;
        background: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M14.8893 13.0328L11.7292 9.86613L11.7292 9.86613C12.3287 8.92616 12.6463 7.83407 12.6443 6.7192C12.6443 3.44681 9.99154 0.794006 6.71914 0.794006C3.44675 0.794006 0.793945 3.44681 0.793945 6.7192C0.793945 9.99159 3.44675 12.6444 6.71914 12.6444C7.83402 12.6464 8.92611 12.3288 9.86608 11.7293L13.0328 14.8894C13.5851 15.3624 14.4163 15.2981 14.8893 14.7457C15.3115 14.2528 15.3115 13.5258 14.8893 13.0328V13.0328ZM6.71914 10.011C4.90115 10.011 3.42737 8.53719 3.42737 6.7192C3.42737 4.9012 4.90115 3.42742 6.71914 3.42742C8.53714 3.42742 10.0109 4.9012 10.0109 6.7192C10.0109 8.53719 8.53714 10.011 6.71914 10.011Z" fill="%23A7B1B9"/%3E%3C/svg%3E')
          no-repeat scroll 13px 12px;
        padding-left: 40px;
      }
      &-input:focus {
        background: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M14.8893 13.0328L11.7292 9.86613L11.7292 9.86613C12.3287 8.92616 12.6463 7.83407 12.6443 6.7192C12.6443 3.44681 9.99154 0.794006 6.71914 0.794006C3.44675 0.794006 0.793945 3.44681 0.793945 6.7192C0.793945 9.99159 3.44675 12.6444 6.71914 12.6444C7.83402 12.6464 8.92611 12.3288 9.86608 11.7293L13.0328 14.8894C13.5851 15.3624 14.4163 15.2981 14.8893 14.7457C15.3115 14.2528 15.3115 13.5258 14.8893 13.0328V13.0328ZM6.71914 10.011C4.90115 10.011 3.42737 8.53719 3.42737 6.7192C3.42737 4.9012 4.90115 3.42742 6.71914 3.42742C8.53714 3.42742 10.0109 4.9012 10.0109 6.7192C10.0109 8.53719 8.53714 10.011 6.71914 10.011Z" fill="%23A7B1B9"/%3E%3C/svg%3E')
          no-repeat scroll 13px 12px;
      }
      &-container {
        margin-top: 5px;
        padding-top: 15px;
        background-color: #f4f5f6;
        height: calc(100vh - 150px);
        overflow-y: scroll;
        padding: 15px 16px 0px 16px;
      }
    }
    &-preview-messenger {
      margin: 0;
      height: 85px;
      padding: 0px 16px;
      box-shadow: none;
      border-bottom: 1px solid #a7b1b9;
      &--active {
        border: none;
        border-radius: 4px;
      }
      &--left {
        width: 52px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .str-chat__avatar {
          height: 52px !important;
          width: 52px !important;
          display: flex;
          flex-basis: 52px !important;
          justify-content: center;
          alignitems: center;
          margin: 0;
          img {
            flex-basis: 52px !important;
            height: 52px !important;
            width: 52px !important;
          }
          &-fallback {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      &--right {
        margin-left: 21px;
      }
      &--name span {
        font-family: Trade Gothic Next W01;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: #223c50;
      }
      &--last-message p {
        font-family: Trade Gothic Next W01;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #223c50;
      }
      &-preview-messenger--left {
        .str-chat__avatar str-chat__avatar--circle {
          width: 52px !important;
          height: 52px !important;
        }
      }
      &--unread {
        .str-chat__channel-preview-messenger--right
          .str-chat__channel-preview-messenger--last-message
          p {
          font-family: Trade Gothic Next W01;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #1c7488;
        }
        background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='6' fill='%2381E1DA'/%3E%3C/svg%3E%0A")
          no-repeat 240px 36px;
      }
    }
  }
  .str-chat__audio__image {
    z-index: 1;
  }
  .str-chat__container {
    .str-chat__input-flat {
      box-shadow: 0 -1px 3px 0 rgb(0 0 0 / 5%), 0 -1px 0 0 rgb(0 0 0 / 7%);
    }
    .str-chat__tooltip {
      left: auto;
      right: 0;
    }
    textarea {
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px 100px 20px 20px;
    }
    textarea:focus {
      box-shadow: 0 0 0 1px var(--primary-color);
    }
    .str-chat__input-flat-emojiselect {
      svg {
        display: none;
      }
      top: calc(100% - 44px);
      left: auto;
      right: 52px;
      width: 24px;
      height: 24px;
      background: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="12" cy="12" r="10" stroke="%23223C50" stroke-width="2"/%3E%3Cpath d="M17.5436 13.2961C17.2421 14.0241 16.8001 14.6855 16.2429 15.2427C15.6858 15.7998 15.0244 16.2418 14.2964 16.5433C13.5685 16.8448 12.7882 17 12.0003 17C11.2124 17 10.4322 16.8448 9.70421 16.5433C8.97625 16.2418 8.31482 15.7998 7.75767 15.2427C7.20052 14.6855 6.75856 14.0241 6.45703 13.2961" stroke="%23223C50" stroke-width="2" stroke-linecap="round"/%3E%3Cpath d="M9.03046 9.62763C8.62859 9.22576 8.08355 9 7.51523 9C6.94691 9 6.40186 9.22576 6 9.62763" stroke="%23223C50" stroke-width="2" stroke-linecap="round"/%3E%3Cpath d="M18.0305 9.62763C17.6286 9.22576 17.0835 9 16.5152 9C15.9469 9 15.4019 9.22576 15 9.62763" stroke="%23223C50" stroke-width="2" stroke-linecap="round"/%3E%3C/svg%3E%0A')
        no-repeat;
    }
    .str-chat__input-flat-fileupload {
      svg {
        display: none;
      }
      width: 24px;
      height: 24px;
      display: block;
      background: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="12" cy="12" r="10" stroke="%23223C50" stroke-width="2"/%3E%3Cline x1="7" y1="12" x2="17" y2="12" stroke="%23223C50" stroke-width="2" stroke-linecap="round"/%3E%3Cline x1="12" y1="7" x2="12" y2="17" stroke="%23223C50" stroke-width="2" stroke-linecap="round"/%3E%3C/svg%3E%0A')
        no-repeat;
    }
    .str-chat__input--emojipicker {
      left: auto;
      right: 0;
    }
    .str-chat__input-flat .rfu-file-upload-button {
      top: calc(100% - 44px);
    }
  }
`;

Wrapper.displayName = 'Wrapper';

export { Wrapper };
