import styled from 'styled-components';

const StyledContentSection = styled.div`
  p,
  a {
    margin-top: 32px;
  }

  padding: 48px 20px 0;

  h5 {
    margin-top: 40px;
  }

  a {
    margin-bottom: 40px;
  }

  @media (min-width: 767px) {
    .button__section {
      text-align: left;
    }
  }

  @media (max-width: 768px) {
    padding: 0px 20px 0;
    .button__section {
      text-align: center;
    }
  }
`;

export default StyledContentSection;
