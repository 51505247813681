import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    margin-top: 0.5rem;
    text-align: center;
  }

  @media (max-width: 1022px) {
    padding: 0 1.8rem;
  }

  @media (max-width: 479px) {
    padding: 0 1rem;
  }
`;

export { Wrapper };
