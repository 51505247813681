import styled from 'styled-components';

const StyledParagraphWithLink = styled.p`
  margin: 24px 0 0 5px;
  color: var(--ds-color__indigo);
  font-size: 12px !important;

  a {
    font-size: var(--ds-typography__small) !important;
    display: inline !important;
  }
`;

export default StyledParagraphWithLink;
