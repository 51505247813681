import styled from 'styled-components';

const StyledInputHeader = styled.div`
  p {
    font-size: 15px !important;
    margin-bottom: 8px;
  }

  ${({ isView }) =>
    isView &&
    `
    font-size: 14px !important;
    margin-bottom: 8px;
    font-weight: 600;
  `}
`;

StyledInputHeader.displayName = 'StyledInputHeader';

export default StyledInputHeader;
