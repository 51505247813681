import styled from 'styled-components';

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 64px;
  min-width: 232px;
  z-index: 9999;

  li {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 8px;

    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
`;

StyledList.displayName = 'StyledList';

export default StyledList;
