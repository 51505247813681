export const getInstructorCapacity = (role) =>
  role.includes('career_specialist') ? 75 : 40;

export const validateInput = (values, role) => {
  const errors = {};
  if (!values.capacity || values.capacity > getInstructorCapacity(role)) {
    errors.capacity = `Please enter a number from 0 to ${getInstructorCapacity(
      role
    )}`;
  }
  return errors;
};
