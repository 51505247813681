import Table from '../../components/Table';
import { connect } from 'react-redux';
import { handleDeleteConfirmation } from '../../actions';

export const mapStateToProps = ({ timeOff: { requests, role } }) => ({
  requests,
  role,
});

export const mapDispatchToProps = (dispatch) => ({
  handleDeleteConfirmation: (id) => dispatch(handleDeleteConfirmation(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
