module.exports = (() => {
  const init = () => {
    bindFunctions();
  };

  const bindFunctions = function () {
    $(document).on('change', '#admin_upstream_step_course_id', loadSteps);
  };

  const loadSteps = function () {
    $.ajax({
      data: {
        course_id: $('#admin_upstream_step_course_id').val(),
      },
      dataType: 'script',
      method: 'POST',
      url: `/en/admin/submissions/load_steps/`,
    });
  };

  return {
    init,
  };
})();
