import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;

  label,
  p {
    margin: 0;
  }
`;

CheckboxWrapper.displayName = 'CheckboxWrapper';

export {
  CheckboxWrapper,
};
