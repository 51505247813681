import styled from 'styled-components';

const StyledRemoteWork = styled.div`
  background-color: #fff;
  margin-left: 20px;
  margin-bottom: 48px;
  @media (min-width:801px)  { 
    margin-right: 20px
  }
  @media (min-width:1025px) {
    margin-right: 116px;
  }
  li, strong {
    font-size: 18px;
    color: var(--ds-color__indigo) !important;
  }
`;

StyledRemoteWork.displayName = 'StyledRemoteWork';

export default StyledRemoteWork;
