import List from './List';
import PropTypes from 'prop-types';
import React from 'react';
import StudentListWrapper from './styles';
import Tabs from './Tabs';

const Students = ({
  activeStudents,
  assigningStudentId,
  assigningStudentInfo,
  unsetAssignmentIds,
  saveInstructorAssignment,
  setAssigningStudentId,
  setStudentsTab,
  studentsCount,
  studentList,
}) => (
  <StudentListWrapper className="ds-grid__col-xs-4">
    <h4 className="ds-typography__h4">Starting Students:</h4>
    <Tabs
      activeStudents={activeStudents}
      setStudentsTab={setStudentsTab}
      studentsCount={studentsCount}
    />
    <List
      assigningStudentId={assigningStudentId}
      assigningStudentInfo={assigningStudentInfo}
      saveInstructorAssignment={saveInstructorAssignment}
      setAssigningStudentId={setAssigningStudentId}
      studentList={studentList}
      unsetAssignmentIds={unsetAssignmentIds}
    />
  </StudentListWrapper>
);

Students.propTypes = {
  activeStudents: PropTypes.string,
  assigningStudentId: PropTypes.number,
  assigningStudentInfo: PropTypes.object,
  saveInstructorAssignment: PropTypes.func.isRequired,
  setAssigningStudentId: PropTypes.func.isRequired,
  setStudentsTab: PropTypes.func.isRequired,
  studentList: PropTypes.array,
  studentsCount: PropTypes.object,
  unsetAssignmentIds: PropTypes.func.isRequired,
};

export default Students;
