import { Route, Switch, useLocation } from 'react-router-dom';
import AdminEmployerConnectorModalContainer from '../../shared/containers/EmployerConnectorModal/index';
import AppWrapper from '../../shared/components/AppWrapper';
import JobProfileFilters from '../containers/JobProfileFilters';
import PropTypes from 'prop-types';
import React from 'react';
import StudentTableContainer from '../containers/StudentTable';

const App = ({ modalIsOpen }) => {
  const location = useLocation();
  return (
    <>
      <Switch location={location}>
        <Route exact path="/:lang/admin/employer_connector/students">
          <AppWrapper header='Employer Connector - Student list'>
            <JobProfileFilters />
            <StudentTableContainer />
          </AppWrapper>
        </Route>
      </Switch>
      {modalIsOpen && <AdminEmployerConnectorModalContainer /> }
    </>
  );
};

App.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
};

export default App;
