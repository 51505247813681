import styled from 'styled-components';

const PlayButton = styled.i`
  color: var(--ds-color__indigo);
  font-size: 20px;
`;

const PlayerStyleWrapper = styled.div`
  padding: 16px 0.5em;
  position: absolute;
  width: 244px;
  height: 92px;
  bottom: 0px;
  background: white;
  opacity: 0.8;

  .rhap_container {
    align-items: center;
    box-shadow: none;
    padding: 0;
  }

  .rhap_progress-section {
    flex: 25 1 auto;
  }

  .rhap_controls-section {
    display: flex;
    align-items: center;
    height: 100%;

    p {
      margin: 0px;
    }
  }

  .rhap_additional-controls,
  .rhap_volume-controls {
    display: none;
  }

  .rhap_main-controls-button {
    align-items: center;
    display: flex;
  }

  .rhap_time {
    color: var(--ds-color__indigo);
    font-size: 12px;
  }

  .rhap_progress-filled {
    background-color: var(--ds-bg-color__int-progress_ind-active);
  }

  .rhap_progress-indicator {
    color: var(--ds-color__indigo);
    transform: scale(0.7);
  }

  .rhap_play-pause-button {
    justify-content: center;
  }
`;

PlayButton.displayName = 'PlayButton';
PlayerStyleWrapper.displayName = 'PlayerStyleWrapper';

export { PlayButton, PlayerStyleWrapper };
