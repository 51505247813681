import {
  RatingWrapper,
  StarWrapper,
  Question,
  QuestionWrapper,
} from './styles';
import React from 'react';

interface Rating {
  rating: number;
  survey_question_id: number;
  survey_question_body: string;
}

interface ExerciseRatingProps {
  ratings: Rating[];
  submitRating: (exerciseRating: number, surveyQuestionId: number) => void;
  showTimeSurvey: boolean;
}

const ExerciseRating: React.FC<ExerciseRatingProps> = ({
  ratings,
  submitRating,
  showTimeSurvey,
}) => {
  return (
    <RatingWrapper>
      <p className='ds-text__body--medium ds-text--bold heading'>
        {`${
          showTimeSurvey ? '2' : '1'
        }. Please answer the following questions with a star rating`}
      </p>
      <p className='ds-text__body--small'>
        (1 - below average, 5 - above average)
      </p>
      {ratings &&
        ratings.map((rate) => (
          <QuestionWrapper key={rate.survey_question_id}>
            <Question className='ds-text__body--medium'>
              {rate.survey_question_body}
            </Question>
            <StarWrapper>
              {Array.from({ length: 5 }).map((_, index) => (
                <i
                  key={index}
                  className={`fa-solid fa-star ${
                    index < rate.rating ? 'filled' : ''
                  }`}
                  onClick={() =>
                    submitRating(index + 1, rate.survey_question_id)
                  }
                ></i>
              ))}
            </StarWrapper>
          </QuestionWrapper>
        ))}
    </RatingWrapper>
  );
};

export default ExerciseRating;
