import React from 'react';
import StyledFeatureDescription from './styles/StyledFeatureDescription';

const FeatureDescription = () => {
  const bulletPoints = [
    'interview preparation',
    'portfolio development',
    'remote work',
    'daily life in your new role ...and more!',
  ];

  return (
    <StyledFeatureDescription>
      <h5 className="ds-typography__h5 text-left">
        Check out videos, webinars, and articles about:
      </h5>
      <div className="ds-grid__row bullet-points">
        {bulletPoints.map((bulletPoint, index) => {
          return (
            <li
              key={index}
              className="ds-typography__body ds-grid__col-sm-12 ds-grid__col-md-4"
            >
              {bulletPoint}
            </li>
          );
        })}
      </div>
      <a
        className="ds-button__primary"
        href="/en/media-library"
        rel="noreferrer"
        target="_blank"
      >
        See all resources
      </a>
    </StyledFeatureDescription>
  );
};

export default FeatureDescription;
