import StyledStaticStarRating from './StyledStaticStarRating';
import styled from 'styled-components';

const StyledStarRating = styled(StyledStaticStarRating)`
  cursor: pointer;

  ${({ isModal }) => !isModal && `
    margin-top: 16px;
  `}
`;

StyledStarRating.displayName = 'StyledStarRating';

export default StyledStarRating;
