import React from 'react';

const NextButton = () => {
  return (
    <span className="ds-button__tertiary tourNextButton">
      Next
    </span>
  );
};
export default NextButton;
