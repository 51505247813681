import styled from 'styled-components';

const Wrapper = styled.div`
  .loading_spinner_wrapper {
    margin-top: 0;
    background: transparent;
  }
`;

export default Wrapper;
