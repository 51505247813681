import ApiService from '../../../../../services/ApiService';
import { getAuthToken } from '../../../../../utils/getAuthToken';

export const markAsCompleted = (id, param) => {
  const apiService = new ApiService(`/api/v1/instructors/candidates/${id}`);
  const params = {
    authenticity_token: getAuthToken(),
    resources_surveys_params: {
      [param]: true,
    },
  };

  apiService.makePutCall(params);
};

export const updateCandidate = (formId, responseId, candidateId) => {
  const apiService = new ApiService(
    `/api/v1/instructors/candidates/${candidateId}`
  );
  const params = {
    authenticity_token: getAuthToken(),
    resources_surveys_params: {
      survey_form_id: formId,
      survey_response_id: responseId,
    },
  };

  apiService.makePutCall(params);
};
