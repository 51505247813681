import { IconProgressCircle } from './icons';
import MenuWrapper from './styles/MenuWrapper';
import NavMenu from './NavMenu';
import PropTypes from 'prop-types';
import React from 'react';
import StyledNavProgress from './styles/StyledNavProgress';
import StyledNavWrapper from './styles/StyledNavWrapper';
import StyledProgressWrapper from './styles/StyledProgressWrapper';

const NavbarWrapper = ({ header, menus, onClick, activeNav, isReducedMenu }) => {
  return (
    <StyledNavWrapper>
      <StyledProgressWrapper>
        <IconProgressCircle />
        <StyledNavProgress isReducedMenu={isReducedMenu} />
      </StyledProgressWrapper>
      <MenuWrapper>
        <h4 className="ds-typography__body">{header}</h4>
        {menus.map((menu) => (
          <NavMenu
            key={menu.label}
            label={menu.label}
            icon={menu.icon}
            onClick={() => onClick(menu.id, menu.label)}
            isActive={activeNav === menu.id}
          />
        ))}
      </MenuWrapper>
    </StyledNavWrapper>
  );
};

NavbarWrapper.propTypes = {
  activeNav: PropTypes.string,
  header: PropTypes.string.isRequired,
  isReducedMenu: PropTypes.bool,
  menus: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NavbarWrapper;
