import React, { Component } from 'react';

class Sidebar extends Component {
  constructor(props){
    super(props);
  };

  showDiscountType = () => this.props.discount_type == 'referral' ? '(Referral)' : '';

  renderCoursePrice = () => {
    let price = this.props.price;

    if (price.total < price.total_undiscounted) {
      return(
        <div className="price__wrapper">
          <p className="discounted__price">{price.total_undiscounted.replace(' USD', '')}</p>
          <p className="price">{price.total} {this.showDiscountType()}</p>
        </div>
      );
    } else if (price.total > price.total_undiscounted) {
      return(<p className="price">{price.total.replace(' USD', '')}</p>);
    } else {
      return(<p className="price">{price.total}</p>);
    };
  };

  renderRating = () => {
    let content = this.props.courseParams ? this.props.courseParams.content : {};
    return(
      <div className="ratings__wrapper">
        <i className="fa fa-star" aria-hidden="true"></i>
        <i className="fa fa-star" aria-hidden="true"></i>
        <i className="fa fa-star" aria-hidden="true"></i>
        <i className="fa fa-star" aria-hidden="true"></i>
        <i className="fa fa-star-half-o" aria-hidden="true"></i>
        <span className="cf-small cf-bold"> {content.rating}</span>
        <p className="cf-label-tiny--gray">{content.avg_rating}</p>
      </div>
    );
  };

  renderUSPs = () => <div className="details__wrapper" dangerouslySetInnerHTML={{ __html: this.props.courseParams.details_list }} />

  renderFooter = () => {
    let content = this.props.courseParams ? this.props.courseParams.content : {};
    return(
      <footer className="cf-text-center">
        <p className="cf-small">{content.students}</p>
        {content.headshots.map((img_url, i) => {
          return(<img key={i} src={img_url} className="img-circle" />)
        })}
        <p className="cf-label--gray">{content.companies}</p>
        <img src={content.company_logos} />
      </footer>
    );
  };

  courseEnrollSidebar = () => {
    return (
      <div>
        <p className="cf-bold">{this.props.courseParams.course_name}</p>
        {this.props.courseParams.custom_enroll.payer_name ? "" : this.renderCoursePrice()}
        {this.renderRating()}
        {this.renderUSPs()}
        {this.renderFooter()}
      </div>
    );
  };

  extensionsPageSidebar = () => {
    let { title, numberOfDays, list } = this.props.metadata;
    let price = this.props.price;
    return (
      <div>
        <p className="cf-bold">{title}<br/>{numberOfDays}-Day Extension</p>
        <p className="price">{price}</p>
        <div className="details__wrapper">
          <ul>
            {list.map((item, i) => {
              return <li key={i} className="cf-small">{item}</li>
            })}
          </ul>
        </div>
      </div>
    );
  };

  render() {
    return(
      <div className={`SideBar ${this.props.slideIndex == 3 ? "hidden" : ""}`}>
        {this.props.extensionsPage ? this.extensionsPageSidebar() : this.courseEnrollSidebar()}
      </div>
    );
  };
};

export default Sidebar;
