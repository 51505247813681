import styled from 'styled-components';

const Header = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px 8px 16px;
  font-family: 'Trade Gothic Next W01';
  font-weight: 300;
  font-size: 18px;
  span {
    color: #223c50;
  }
`;

const Wrapper = styled.div`
  max-height: 80vh;
  overflow: auto;
  position: absolute;
  right: 0px;
  top: 30px;
  background: #f4f5f6;
  box-shadow: 0 8px 12px 0 #223c5028;
  // border: 1px solid #eee;
  .str-chat.str-chat-channel-list.messaging.light {
    background-color: white;
  }
  .str-chat,
  .str-chat__channel-list-messenger,
  .str-chat__channel-list-messenger__main {
    height: auto;
    background-color: #f4f5f6;
  }

  .str-chat__channel {
    &-search {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 16px 24px 16px;
      background-color: #ffffff;
      &-input {
        height: 40px;
        border-radius: unset;
        border: 1px solid #a7b1b9;
        border-radius: 8px;
        background-color: #ffffff;

        font-family: 'Trade Gothic Next W01';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        color: rgba(34, 60, 80, 0.75);
        background: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M14.8893 13.0328L11.7292 9.86613L11.7292 9.86613C12.3287 8.92616 12.6463 7.83407 12.6443 6.7192C12.6443 3.44681 9.99154 0.794006 6.71914 0.794006C3.44675 0.794006 0.793945 3.44681 0.793945 6.7192C0.793945 9.99159 3.44675 12.6444 6.71914 12.6444C7.83402 12.6464 8.92611 12.3288 9.86608 11.7293L13.0328 14.8894C13.5851 15.3624 14.4163 15.2981 14.8893 14.7457C15.3115 14.2528 15.3115 13.5258 14.8893 13.0328V13.0328ZM6.71914 10.011C4.90115 10.011 3.42737 8.53719 3.42737 6.7192C3.42737 4.9012 4.90115 3.42742 6.71914 3.42742C8.53714 3.42742 10.0109 4.9012 10.0109 6.7192C10.0109 8.53719 8.53714 10.011 6.71914 10.011Z" fill="%23A7B1B9"/%3E%3C/svg%3E')
          no-repeat scroll 13px 12px;
        padding-left: 40px;
      }
      &-input:focus {
        background: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M14.8893 13.0328L11.7292 9.86613L11.7292 9.86613C12.3287 8.92616 12.6463 7.83407 12.6443 6.7192C12.6443 3.44681 9.99154 0.794006 6.71914 0.794006C3.44675 0.794006 0.793945 3.44681 0.793945 6.7192C0.793945 9.99159 3.44675 12.6444 6.71914 12.6444C7.83402 12.6464 8.92611 12.3288 9.86608 11.7293L13.0328 14.8894C13.5851 15.3624 14.4163 15.2981 14.8893 14.7457C15.3115 14.2528 15.3115 13.5258 14.8893 13.0328V13.0328ZM6.71914 10.011C4.90115 10.011 3.42737 8.53719 3.42737 6.7192C3.42737 4.9012 4.90115 3.42742 6.71914 3.42742C8.53714 3.42742 10.0109 4.9012 10.0109 6.7192C10.0109 8.53719 8.53714 10.011 6.71914 10.011Z" fill="%23A7B1B9"/%3E%3C/svg%3E')
          no-repeat scroll 13px 12px;
      }
      &-container {
        margin-top: 16px;
        background-color: #f4f5f6;
        padding: 0px 16px;
      }
    }
    &-preview-messenger {
      padding: 22px 0px 17px 0px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: none;
      background: #f4f5f6;
      box-shadow: none;
      border-bottom: 1px solid #a7b1b9;
      margin: 0;
      &--left {
        width: 52px;
        height: 52px;
        .str-chat__avatar.str-chat__avatar--circle {
          width: 52px;
          height: 52px;
          justify-content: center;
          align-items: center;
          img {
            flex-basis: 52px;
            width: 52px;
            height: 52px;
          }
        }
      }
      &--right {
        height: 52px;
        margin-left: 21px;
      }
      &--name {
        font-family: Trade Gothic Next W01;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #223c50;
      }
      &--last-message p {
        font-family: Trade Gothic Next W01;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #223c50;
      }
    }
  }

  .str-chat-channel-list .str-chat__channel-list-messenger__main {
    padding-top: 0px;
    background-color: #f4f5f6;
    padding: 0px 16px;
  }

  .str-chat {
    &__channel-preview {
      background-color: inherit;
      padding: 16px 0px 16px 0px;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      padding: 0;
      padding: 22px 0px 17px 0px;
      button {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: none;
        background: none;
        padding: 0;
      }
      &-info {
        margin-left: 21px;
        height: 52px;
        max-height: 52px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      &-title {
        font-family: Trade Gothic Next W01;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #223c50;
      }
      &-last-message {
        font-family: Trade Gothic Next W01;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #223c50;
        overflow: hidden;
      }
      &__unread {
        .str-chat__channel-preview-last-message {
          font-family: Trade Gothic Next W01;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #1c7488;
        }
        background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='6' fill='%2381E1DA'/%3E%3C/svg%3E%0A")
          no-repeat 240px 36px;
      }
    }
  }

  .str-chat__avatar.str-chat__avatar--circle {
    flex-basis: auto;
    height: 52px;
    margin: 0;
    width: 52px;
  }

  .str-chat__channel-preview-title {
    font-weight: bold;
  }

  @media screen and (max-width: 960px) {
    .str-chat-channel-list.messaging,
    .str-chat-channel-list.team,
    .str-chat-channel-list.commerce {
      box-shadow: inherit;
      left: inherit;
      min-height: inherit;
      overflow-y: inherit;
      position: inherit;
      top: inherit;
      transition: inherit;
      z-index: inherit;
    }
  }
`;

const MessagingPageLink = styled.a`
  display: block;
  box-shadow: inherit;
  left: inherit;
  min-height: inherit;
  overflow-y: inherit;
  position: inherit;
  top: inherit;
  -webkit-transition: inherit;
  transition: inherit;
  z-index: inherit;
  float: left;
  z-index: 1;
  min-width: 130px;
  margin: 0;
  padding: 0;
  text-align: right;
`;
Header.displayName = 'Header';
Wrapper.displayName = 'Wrapper';
MessagingPageLink.displayName = 'MessagingPageLink';

export { Header, MessagingPageLink, Wrapper };
