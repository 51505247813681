import React from 'react';
import { StyledHeader } from './styles';

const Header = () => (
  <StyledHeader>
    <h2 className="ds-typography__h2 text-left">Student calls for invoicing</h2>
    <p className="ds-typography__lead">
      Please check the calls and update their status regularly, as these will be
      used to generate invoices. Your deadline for updating calls from the
      previous calendar month is by the 6th day of the current month. After the
      6th, you will no longer be able to update call statuses from the previous
      calendar month.
    </p>
  </StyledHeader>
);

export default Header;
