import styled from 'styled-components';

const SectionWrapper = styled.div`
  width: 664px;
  margin-bottom: 18px;

  header p {
    margin-bottom: 32px;
  }

  @media (max-width: 1278px) {
    width: 100%;
  }
`;

SectionWrapper.displayName = 'SectionWrapper';

export default SectionWrapper;
