import styled from 'styled-components';

const Label = styled.div`
  display: inline-block;
  background-color: #eef0f1;
  padding: 4px 18px 5px;
`;

Label.displayName = 'Label';

export default Label;
