import MessagingPreview from '../../components/MessagingPreview';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { openMessagingWindow } from '../ChannelButton/actions';
import { queriedChannelsSuccess } from '../../../actions';
import { toggleMsgPreview } from '../../actions';
import { useChannels } from '../../../hooks';

export const MessagingPreviewContainer = ({
  channelsHaveLoaded,
  isMessagingPreviewOpen,
  queriedChannelsSuccess,
  sort,
  toggleMsgPreview,
  openMessagingWindow,
  uuid,
}) => {
  const filters = {
    members: { $in: [uuid] },
    type: 'messaging',
  };

  useChannels(!channelsHaveLoaded, filters, sort, queriedChannelsSuccess);

  return (
    channelsHaveLoaded && (
      <MessagingPreview
        filters={filters}
        isMessagingPreviewOpen={isMessagingPreviewOpen}
        onToggleMsgPreview={toggleMsgPreview}
        onOpenMessagingWindow={openMessagingWindow}
        sort={sort}
      />
    )
  );
};

MessagingPreviewContainer.propTypes = {
  channelsHaveLoaded: PropTypes.bool,
  isMessagingPreviewOpen: PropTypes.bool,
  openMessagingWindow: PropTypes.func,
  queriedChannelsSuccess: PropTypes.func,
  sort: PropTypes.object,
  toggleMsgPreview: PropTypes.func,
  uuid: PropTypes.string,
};

export const mapStateToProps = ({
  messaging: {
    channelsHaveLoaded,
    isMessagingPreviewOpen,
    sort,
    me: { uuid },
  },
}) => ({
  channelsHaveLoaded,
  isMessagingPreviewOpen,
  sort,
  uuid,
});

export const mapDispatchToProps = (dispatch) => ({
  openMessagingWindow: (channelId) => dispatch(openMessagingWindow(channelId)),
  queriedChannelsSuccess: () => dispatch(queriedChannelsSuccess()),
  toggleMsgPreview: () => dispatch(toggleMsgPreview()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagingPreviewContainer);
