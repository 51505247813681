import Button from '../shared/Button';
import FAQ from '../../components/FAQ';
import PropTypes from 'prop-types';
import React from 'react';
import StyledFAQWrapper from '../../styles/StyledFAQWrapper';

const OptinFAQ = ({ handleCloseModal }) => {
  return (
    <StyledFAQWrapper>
      <hr/>
      <div className="scroll-body">
        <h4 className="ds-typography__h4 text-left">You’re all set up!</h4>
        <p className="ds-typography__body">Your proﬁle has been added to the Employer Connector. </p>
        <p className="ds-typography__body mb-52">In the following section, you’ll find answers to some common questions.</p>
        <FAQ />
      </div>
      <hr/>
      <div className="button-section">
        <Button
          className="ds-button__primary"
          label="Close"
          onClick={handleCloseModal}
        />
      </div>
    </StyledFAQWrapper>
  );
};

OptinFAQ.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

export default OptinFAQ;
