import ApiService from '../../services/ApiService';
import { constants } from './constants';
import { getAuthToken } from '../../utils/getAuthToken';
import { transformParamsForPost } from './helpers';

export const setLoadingState = (state) => ({
  state,
  type: constants.SET_LOADING,
});
export const setFormStatus = (data) => ({
  data,
  type: constants.SET_FORM_STATUS,
});
export const setPersonalData = (data) => ({
  data,
  type: constants.SET_PERSONAL_DATA,
});

export const handleFormSubmit = (data) => async (dispatch) => {
  const postParams = transformParamsForPost(data);

  delete postParams.uuid;

  dispatch(setLoadingState(true));

  try {
    let response = null;

    if (data.uuid) {
      const apiService = new ApiService(
        `/api/v1/finance/payout_information/${data.uuid}`
      );

      response = await apiService.makePatchCall({
        authenticity_token: getAuthToken(),
        payout_information: { ...postParams },
      });
    } else {
      const apiService = new ApiService('/api/v1/finance/payout_information/');

      response = await apiService.makePostCall({
        authenticity_token: getAuthToken(),
        payout_information: { ...postParams },
      });
    }

    if (response) {
      dispatch(
        setFormStatus([
          { code: 'success', message: 'Successfully saved your information' },
        ])
      );
    } else {
      throw new Error();
    }
  } catch (error) {
    dispatch(
      setFormStatus([{ code: 'error', message: 'Something went wrong' }])
    );
  }

  dispatch(setLoadingState(false));
};

export const getUserPayoutInformation = () => async (dispatch) => {
  const apiService = new ApiService('/api/v1/finance/payout_information');

  try {
    const result = await apiService.makeGetCall();
    dispatch(setPersonalData(result.data));
  } catch (error) {
    dispatch(setPersonalData({}));
  }
};

export const handleFormChange = (event) => (dispatch) => {
  const { name, value } = event.target;
  let dispatchProps = {};

  dispatch(setFormStatus([])); // clear any success or error messages shown

  switch (name) {
    case 'taxRate':
      dispatchProps = {
        data: { [name]: value },
        type: constants.SET_TAX_RATE,
      };
      break;

    case 'smallCompany':
      dispatchProps = {
        type: constants.SET_SMALL_COMPANY,
      };
      break;

    case 'country':
      dispatchProps = {
        data: { [name]: value },
        type: constants.SET_COUNTRY,
      };
      break;

    default:
      dispatchProps = {
        data: { [name]: value },
        type: constants.SET_FORM_DATA,
      };
      break;
  }

  dispatch(dispatchProps);
};
