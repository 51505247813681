import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ isUnderProgram }) => (isUnderProgram ? '0.5rem' : '1rem')};

  p {
    margin: 0;
    color: ${({ isAccessible, isSelected }) => {
      if (!isAccessible) {
        return 'rgba(34, 60, 80, 0.4)';
      } else if (isSelected) {
        return 'var(--ds-color__white)';
      } else {
        return 'var(--ds-color__petrol)';
      }
    }};
  }

  @media (max-width: 1279px) {
    flex-direction: column;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${({ isAccessible, isSelected }) => {
      if (!isAccessible) {
        return 'rgba(34, 60, 80, 0.4)';
      } else if (isSelected) {
        return 'var(--ds-color__white)';
      } else {
        return 'var(--ds-color__indigo)';
      }
    }};
  }

  @media (max-width: 1279px) {
    margin-top: 0.5rem;
  }
`;

export { Wrapper, IconWrapper, DateWrapper };
