import styled from 'styled-components';

const UploadWrapper = styled.div`
  p {
    display: inline;
  }

  i {
    margin-left: 8px;
  }
`;

export { UploadWrapper };
