import {
  AccordionContent,
  HeaderWrapper,
  StyledAccordionItemButton,
  StatusWrapper,
  StyledAccordionItem,
  StyledTooltipContent,
} from '../styles';
import { Body, Cap, Header } from '@careerfoundry/bubble';
import { AccordionItemHeading } from 'react-accessible-accordion';
import Message from '../../../Message';
import React from 'react';
import { ITabData, IUpdateAdmissionParams } from '../../../../interfaces';
import { IFile } from '../interfaces';
import Tooltip from '../../../../../shared/components/Tooltip';
import { TooltipContentInProgress, TooltipContent1 } from '../helpers';
import { renderStatus } from '../../../../helpers';
import Upload from '../../../../../admin/AfaAdmissions/components/Upload';

interface IProps {
  selectedSubTab: string;
  tabData: ITabData;
  updateAdmission: (params: IUpdateAdmissionParams, tab: string) => void;
}

const FirstTab: React.FC<IProps> = ({
  selectedSubTab,
  tabData,
  updateAdmission,
}) => {
  const handleFormSubmit = (
    file: IFile,
    ev: React.MouseEvent<HTMLButtonElement>
  ) => {
    ev.preventDefault();
    updateAdmission(
      {
        afa_voucher: {
          data: file.data,
          file_name: file.fileName,
          file_size: file.fileSize,
        },
      },
      'afaVoucher'
    );
  };

  const fileData = {
    data: tabData.data || '',
    fileName: tabData.fileName || '',
    fileSize: 0,
  };

  return (
    <StyledAccordionItem
      uuid='1'
      dangerouslySetExpanded={selectedSubTab === '1'}
    >
      <AccordionItemHeading>
        <StyledAccordionItemButton locked={'false'}>
          <Header variant='h3' tag='h3'>
            Bildungsgutschein (Step 1 of 2)
          </Header>
          <div>
            {tabData.state === 'pending' ? (
              <Tooltip
                content={<TooltipContentInProgress />}
                place='bottom'
                customStyledComponentWrapper={StyledTooltipContent}
              >
                <StatusWrapper>
                  <Cap tag='p' variant='bold'>
                    status:{' '}
                  </Cap>
                  {renderStatus('pending', true)}
                </StatusWrapper>
              </Tooltip>
            ) : (
              <StatusWrapper state={tabData.state}>
                <Cap tag='p' variant='bold'>
                  status:{' '}
                </Cap>
                {renderStatus(tabData.state, true)}
              </StatusWrapper>
            )}
          </div>
        </StyledAccordionItemButton>
      </AccordionItemHeading>
      <AccordionContent>
        <Body tag='p' variant='large-bold'>
          Upload your blank “Bildungsgutschein” training voucher
        </Body>
        <Body tag='p' variant='large'>
          Please leave all fields blank. We’ll review and let you know if any
          changes are needed!
        </Body>
        {tabData.reason && tabData.state === 'action_required' && (
          <Message message={tabData.reason} messageType='error' />
        )}
        {tabData.type === 'digital' && (
          <Message
            message='Since you have a digital Bildungsgutschein, you can skip step 2.'
            messageType='success'
          />
        )}
        <HeaderWrapper>
          <Header tag='h5' variant='h5'>
            Bildungsgutschein
          </Header>
          <Tooltip
            content={<TooltipContent1 />}
            customStyledComponentWrapper={StyledTooltipContent}
          >
            <i className='ds-icon__help-line'></i>
          </Tooltip>
        </HeaderWrapper>
        <Upload handleFormSubmit={handleFormSubmit} fileData={fileData} />
      </AccordionContent>
    </StyledAccordionItem>
  );
};

export default FirstTab;
