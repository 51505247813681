import Card from '../../../InstructorOnboarding/InstructorProfile/styles/Card';
import React from 'react';
import Section from './section';
import { StyledSidebar, StyledCard } from './styles';
import { IProgress } from '../../interfaces';
import { Body, Header, Link } from '@careerfoundry/bubble';

export interface SidebarProps {
  selectedTab: string;
  selectedSubTab: string;
  progress: IProgress;
  setSelectedTab: (tab: string) => void;
  setSelectedSubTab: (tab: string) => void;
  firstName: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  selectedTab,
  selectedSubTab,
  progress,
  setSelectedTab,
  setSelectedSubTab,
  firstName,
}) => {
  return (
    <StyledSidebar>
      <StyledCard>
        <Header tag='h4' variant='h4'>
          Hi {firstName},
        </Header>
        <Body tag='p' variant='medium'>
          Welcome to CareerFoundry—we’re excited to have you on board, and ready
          to help you get your documentation in order. (We’re nearly there!)
          <br />
          <br />
          Here you’ll be able to track your enrollment status as you work
          through each step. Don’t hesitate to reach out by{' '}
          <Link
            href='mailto:bildungsgutschein@careerfoundry.com'
            variant='medium'
          >
            email
          </Link>{' '}
          if you have questions!
        </Body>
        <br />
        <>
          <Header tag='h4' variant='h4'>
            Progress
          </Header>
          {Object.entries(progress.sidebar).map((tab) => {
            const tabKey = tab[0];
            const tabValue = tab[1];
            return (
              <div key={tabKey}>
                <Section
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  selectedSubTab={selectedSubTab}
                  setSelectedSubTab={setSelectedSubTab}
                  tabKey={tabKey}
                  tabValue={tabValue}
                  isUnLocked={tabValue.active}
                />
              </div>
            );
          })}
        </>
      </StyledCard>
    </StyledSidebar>
  );
};

export default Sidebar;
