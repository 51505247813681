import React from 'react';

const Introduction = () => {
  return (
    <>
      <p className='ds-typography__body break'>
        Welcome to your resource for getting started as a Freelancer. This
        collection of resources is meant to provide you with a{' '}
        <strong>foundation of information</strong> to get you started in this
        part of your career development journey. We are not endorsing one way of
        doing things but simply providing you with a basis of thought and
        structure so that you can get up and running in a thoughtful way.
      </p>
      <p className='ds-typography__body break'>
        That said, we are looking to put top of mind that{' '}
        <strong>freelancing is a business</strong> which comes with its own set
        of responsibilities including regulations, taxes, and financing. It is
        ultimately up to you to research and keep up with the changes and
        requirements in your area. To say this another way: We provide the
        inspiration and you provide the perspiration.
      </p>
    </>
  );
};

export default Introduction;
