import { handleCloseModal, setCurrentAnswer } from '../../actions';
import InterviewEvaluationModal from '../../components/InterviewEvaluationModal';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  userProfile: {
    modalAction,
    modalIsOpen,
    answersForReview,
    currentAnswer,
    isMentorPage,
    selectedInsight,
  },
}) => ({
  answersForReview,
  currentAnswer,
  isMentorPage,
  modalAction,
  modalIsOpen,
  selectedInsight,
});
export const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => dispatch(handleCloseModal()),
  setCurrentAnswer: (answer) => dispatch(setCurrentAnswer(answer)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewEvaluationModal);
