import PropTypes from 'prop-types';
import React from 'react';
import StyledModalButton from './styles/StyledModalButton';

const InteractiveModalButton = ({ onCloseModal }) => (
  <StyledModalButton>
    <button
      type="submit"
      className="ds-button__primary"
      target="_blank"
      onClick={onCloseModal}
    >
      Close
    </button>
  </StyledModalButton>
);

InteractiveModalButton.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

export default InteractiveModalButton;
