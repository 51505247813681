import styled from 'styled-components';

const Progress = styled.div`
  margin-top: 1.5rem;

  .accordion__panel {
    margin-top: 20px;
  }

  .accordion__button {
    div {
      display: flex;
      align-items: center;
    }

    h5 {
      display: inline;
    }

    img {
      width: 3.5rem;
      margin-right: 1rem;
    }
  }

  > div {
    margin-bottom: 1.5rem;
  }

  > div:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

Progress.displayName = 'Progress';

export default Progress;
