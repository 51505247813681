import { TaxRateInputDE, TaxRateInputDefault } from './index';
import PropTypes from 'prop-types';
import React from 'react';

const TaxRateInput = ({ country }) => {
  switch (country) {
    case 'DE':
      return <TaxRateInputDE />;
    default:
      return <TaxRateInputDefault />;
  }
};

TaxRateInput.propTypes = {
  country: PropTypes.string,
};

export default TaxRateInput;
