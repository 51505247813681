import { State } from './interfaces';

export const constants = {
  GET_INITIAL_DATA_SUCCESS: 'afaAdmissions.GET_INITIAL_DATA_SUCCESS',
  SET_SELECTED_SUB_TAB: 'afaAdmissions.SET_SELECTED_SUB_TAB',
  SET_SELECTED_TAB: 'afaAdmissions.SET_SELECTED_TAB',
  UPDATE_ADMISSION_SUCCESS: 'afaAdmissions.UPDATE_ADMISSION_SUCCESS',
  SET_TAB_IS_LOADING: 'afaAdmissions.SET_TAB_IS_LOADING',
};

export const admission = {
  admission: {
    afaVoucher: {
      fileName: '',
      data: '',
      type: '',
      state: '',
      reason: '',
      physicalVoucherState: '',
      physicalVoucherReason: '',
      physicalVoucherSentAt: '',
    },
    englishTest: {
      fileName: '',
      data: '',
      state: '',
      reason: '',
    },
    profileSurvey: {
      typeformId: '',
      state: '',
      reason: '',
    },
    admissionTest: {
      typeformId: '',
      score: null,
      state: '',
      reason: '',
    },
    termsOfParticipation: {
      state: '',
      reason: '',
    },
    contract: {
      state: '',
      reason: '',
    },
    firstName: '',
    uuid: '',
    courseId: null,
    productId: null,
  },
};

export const tabData = {
  tabData: {
    fileName: '',
    data: '',
    type: '',
    state: '',
    reason: '',
    physicalVoucherState: '',
    physicalVoucherReason: '',
    physicalVoucherSentAt: '',
    typeformId: '',
    score: null,
    name: '',
    uuid: '',
    surveyLink: '',
    admissionTestLink: '',
    termsLink: '',
  },
};

export const initialState: State = {
  ...admission,
  selectedTab: '',
  selectedSubTab: '',
  loading: true,
  tabLoading: false,
  progress: {
    state: '',
    sidebar: {
      afaVoucher: {
        name: '',
        status: '',
        active: false,
        steps: null,
      },
      termsOfParticipation: {
        name: 'Terms of Participation',
        status: '',
        active: false,
        steps: null,
      },
    },
  },
  ...tabData,
};

export const testState = {
  afaAdmissions: {
    selectedSubTab: '1',
    admission: {
      afaVoucher: {
        fileName: 'name_on_file.pdf',
        data: 'https://file.com',
        type: 'physical',
        state: 'done',
        reason: '',
        physicalVoucherState: 'done',
        physicalVoucherReason: '',
        physicalVoucherSentAt: '',
      },
      englishTest: {
        fileName: 'test-result.pdf',
        data: 'https://...',
        state: 'approved',
        reason: 'too bad',
      },
      profileSurvey: {
        typeformId: 'typeform_id',
        state: 'todo',
        reason: 'Wrong file',
      },
      admissionTest: {
        typeformId: 'pending',
        score: 85,
        state: 'approved',
        reason: 'Wrong file',
      },
      termsOfParticipation: {
        state: 'pending',
        reason: 'Wrong file',
      },
      contract: {
        state: 'todo',
        reason: 'Wrong file',
      },
      firstName: 'Ada',
      uuid: '1394834792874293847',
      courseId: 59,
      productId: 248,
    },
    ...tabData,
  },
};

export const testAdmission = {
  admission: {
    afaVoucher: {
      fileName: 'name_on_file.pdf',
      data: 'https://file.com',
      type: 'physical',
      state: 'done',
      reason: '',
      physicalVoucherState: 'todo',
      physicalVoucherReason: '',
      physicalVoucherSentAt: '',
    },
    englishTest: {
      fileName: 'test-result.pdf',
      data: 'https://...',
      state: 'approved',
      reason: 'too bad',
    },
    profileSurvey: {
      typeformId: 'typeform_id',
      state: 'todo',
      reason: 'Wrong file',
    },
    admissionTest: {
      typeformId: 'pending',
      score: 85,
      state: 'approved',
      reason: 'Wrong file',
    },
    termsOfParticipation: {
      state: 'pending',
      reason: 'Wrong file',
    },
    contract: {
      state: 'todo',
      reason: 'Wrong file',
    },
    firstName: 'Ada',
    uuid: '1394834792874293847',
    courseId: 59,
    productId: 248,
  },
  tabLoading: false,
  tabData: {
    state: 'todo',
    fileName: 'file',
    data: '',
    type: '',
    reason: '',
    physicalVoucherState: '',
    physicalVoucherReason: '',
    physicalVoucherSentAt: '',
    surveyLink: '',
    admissionTestLink: '',
    termsLink: '',
  },
};
