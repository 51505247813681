import { StyledHeader, VerticalBorderContainer } from './styles';
import { AfaAdmissionProps } from '../../interfaces';
import Card from '../../../../shared/styles/Card';
import { Content } from '../../styles';
import React from 'react';

const Header: React.FC<AfaAdmissionProps> = ({
  firstName = '',
  lastName = '',
  userId = '',
  slug = '',
  status = '',
  stateDate = '',
  email = '',
  createdAt = '',
  productName = '',
  id = '',
}) => (
  <Card>
    <div className='ds-grid__row'>
      <div className='ds-grid__col-xs-12'>
        <StyledHeader>
          <Content>
            <div className='name-wrapper'>
              <h3 className='ds-text__h3'>
                {firstName} {lastName}
              </h3>
              {!userId && (
                <a
                  href={`/en/admin/afa_admissions/${slug}/edit`}
                  className='ds-icon__edit edit-icon'
                />
              )}
            </div>
            <a
              href={`/en/admin/afa_admissions/${slug}/enrollments/new`}
              className={`ds-btn__primary pull-right ${!userId && 'disabled'}`}
            >
              Enroll candidate
            </a>
          </Content>
        </StyledHeader>
      </div>
    </div>
    <div className='ds-grid__row'>
      <div className='ds-grid__col-xs-4'>
        <Content>
          <p className='ds-text__body--medium'>
            <span className='ds-text--bold'>Candidate status</span>: {status}
          </p>
          <p className='ds-text__body--medium'>
            <span className='ds-text--bold'>Status date</span>: {stateDate}
          </p>
          <p className='ds-text__body--medium'>
            <span className='ds-text--bold'>Creation date</span>: {createdAt}
          </p>
          <p className='ds-text__body--medium'>
            <span className='ds-text--bold'>Email</span>: {email}
          </p>
          <a
            data-confirm='Are you sure? This will delete the admission record, but not the user (if any).'
            data-method='delete'
            href={`/en/admin/afa_admissions/${id}`}
            rel='nofollow'
            className='ds-btn__secondary-error'
          >
            Delete
          </a>
        </Content>
      </div>
      <VerticalBorderContainer className='ds-grid__col-xs-4'>
        <Content>
          <p className='ds-text__body--medium'>
            <span className='ds-text--bold'>Course</span>: {productName}
          </p>
        </Content>
      </VerticalBorderContainer>
      <div className='ds-grid__col-xs-4'></div>
    </div>
  </Card>
);

export default Header;
