import styled from 'styled-components';

const StyledMenuLabel = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  margin-bottom: 0;
  color: #515559;

  ${({ isActive }) => isActive && `
    color: #fff;
  `}
`;

StyledMenuLabel.displayName = 'StyledMenuLabel';
export default StyledMenuLabel;
