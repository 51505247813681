import axios from 'axios';

export const fetchPaymentData = async () => {
  const userData = await axios.get('/api/v1/me');
  const paymentData = await axios.get(`${location.href}/payment_details`);

  return {
    email: userData.data.data.email,
    nameOnCard: '',
    paymentData: paymentData.data.payment_data,
  };
};
