var CareerFoundyCoach = (function () { // eslint-disable-line
  const thisState = {};
  const init = function () {
    initPlugins();
    communicationIndex.init();
    initializeToolTips();
    adminSection();
    initializeBarRating();
    navDropdownHover();
    showTabOnReload();
    // GLOBAL Initializers
    userNotifications.init();
    initializeLoginForm();
    initPopover();
  };

  // Initialize Methods

  var showTabOnReload = function () {
    const queryParams = (new URL(document.location)).searchParams;
    const tabParam = queryParams.get('tab');
    const fragment = document.location.hash.slice(1);
    const tab = tabParam || fragment;

    if (tab) {
      $('.nav-tabs a[href="#' + tab + '"]').tab('show');
    }
  };

  var initPlugins = function () {
    showdown.extension('linebreak', showdownExtensions.lineBreak());
  };

  var initializeBarRating = function () {
    $('.star-rating').barrating({
      onSelect: function (value, text, event) {
        if (typeof (event) !== 'undefined' && value > 0) {
          // rating was selected by a user, submit
          $(event.target).closest('.new_rating');
        }
      },
      theme: 'bootstrap-stars',
    });
  };

  var initializeToolTips = function () {
    $('body').tooltip({ selector: '[data-toggle="tooltip"]' });
  };

  var adminSection = function () {
    $('#quote').on('keyup', function () {
      const charCount = $('#quote').val().length;
      $('#char-count').html(charCount);
      if (charCount > 249) {
        $('#char-count').css('color', '#FF0000');
      } else {
        $('#char-count').css('color', '#3E51A3');
      }
    });
  };

  var navDropdownHover = function () {
    if ($(window).width() > 991) {
      $('.dropdown_nav--js').hover(
        function () {
          $('.dropdown-menu', this).stop(true, true).fadeIn('fast');
          $(this).toggleClass('open');
          $('.notifications_dropdown').removeClass('open');
        },
        function () {
          $('.dropdown-menu', this).stop(true, true).fadeOut('fast');
          $(this).toggleClass('open');
        });
    }
  };

  var initializeLoginForm = function () {
    const userLogin = $('#user-login');
    if (userLogin.length) {
      thisState.form = new CF.Form('#user-login');

      if (window.chrome && $('input:-webkit-autofill')) {
        $(window).load(function () {
          $('input:-webkit-autofill').each(function () {
            $(this).parent().addClass('cf-form__group--frozen');
          });
        });
      }
    }
  };

  // Public Methods

  const htmlConverter = function () {
    return new showdown.Converter({ extensions: ['linebreak'] });
  };

  const initializeScrollSpy = function (target, offset) {
    $('body').scrollspy({ offset: offset, target: target });
  };

  const showWarningNotice = function (e) {
    // this adds click notices to specified elements
    // to add an element add the given class to the leading
    // if block and add a data-toggle='notice' attribute
    // as well as a data-noticetext attribute with the
    // text you'd like to be displayed in the warning div
    if ($(this).is('.disabled, .visible')) {
      const text = '<p>' + $(this).data('noticetext') + '</p>';
      const warningDiv = getWarningDiv();

      $('body').append(warningDiv);

      warningDiv.html(text)
        .css({
          left: e.pageX - 20,
          top: e.pageY - 20,
        })
        .show()
        .delay(800).fadeOut('slow');
    }
  };
  var getWarningDiv = function () {
    if ($('.warningDiv').length) {
      return $('.warningDiv');
    } else {
      return $("<div class='warningDiv'></div>");
    }
  };

  const flashMessage = function (message, type) {
    $('body').append(
      "<div class='error_msg' style='margin-top:0px;margin-bottom:0px;'>" +
        "<div class='alert alert-" + type + "'>" +
          '<p>' +
            '<small>' + message + '</small>' +
          '</p>' +
        '</div>' +
      '</div>',
    );
    $('.error_msg').fadeOut(7000);
  };

  var initPopover = function () {
    $('[data-toggle="popover"]').popover({
      html: 'true',
      placement: 'left',
      trigger: 'hover',
    });
  };

  return {
    flashMessage: flashMessage,
    getWarningDiv: getWarningDiv,
    htmlConverter: htmlConverter,
    init: init,
    initializeScrollSpy: initializeScrollSpy,
    showWarningNotice: showWarningNotice,
  };
})();
