import { MOBILE_VIEW_MAX_WIDTH } from '../../constants';
import styled from 'styled-components';

const commonStyles = [
  (props) =>
    props.isSelected ? 'var(--ds-color__indigo)' : 'var(--ds-color__white)',
];

const Wrapper = styled.div`
  height: 100%;
  position: fixed;
  width: 5rem;
  z-index: 13;
  background-color: var(--ds-color__indigo);
  display: flex;
  flex-direction: column;

  @media (max-width: ${MOBILE_VIEW_MAX_WIDTH}px) {
    width: 18.5rem;
    display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${MOBILE_VIEW_MAX_WIDTH}px) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
`;

const TopItems = styled(Items)``;
const BottomItems = styled(Items)``;

const ItemsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem 0;
  overflow-y: auto;

  @media (max-width: ${MOBILE_VIEW_MAX_WIDTH}px) {
    justify-content: start;
  }
`;

const CFAndCancelIconWrapper = styled.div`
  position: relative;
  align-items: center;
  background-color: var(--ds-color__petrol);
  display: flex;
  height: 5rem;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${MOBILE_VIEW_MAX_WIDTH}px) {
    justify-content: start;
    height: 3rem;
  }
`;

const CFIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${MOBILE_VIEW_MAX_WIDTH}px) {
    svg {
      margin-left: 1rem;
      width: 2rem;
    }
  }
`;

const CancelIconWrapper = styled.div`
  position: absolute;
  right: -1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--ds-color__white);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: var(--ds-color__petrol);
    width: 1rem;
    height: 1rem;
  }

  @media (min-width: ${MOBILE_VIEW_MAX_WIDTH + 1}px) {
    display: none;
  }
`;

const Courses = styled.div`
  width: 100%;
  margin-left: 1rem;

  @media (min-width: ${MOBILE_VIEW_MAX_WIDTH + 1}px) {
    display: none;
  }
`;

const BackgroundBlur = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(34, 60, 80, 0.4);
  z-index: 12;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};

  @media (min-width: ${MOBILE_VIEW_MAX_WIDTH + 1}px) {
    display: none;
  }
`;

const DashboardIconMobileViewWrapper = styled.div`
  width: 100%;

  @media (max-width: ${MOBILE_VIEW_MAX_WIDTH}px) {
    > div > div:nth-child(1) {
      background-color: ${(props) =>
        props.isSelected ? 'var(--ds-color__white)' : 'inherit'};

      > div:nth-child(2) {
        color: ${commonStyles[0]};
      }

      svg {
        fill: ${commonStyles[0]};
      }
    }
  }
`;

export {
  Wrapper,
  Items,
  TopItems,
  CFAndCancelIconWrapper,
  CancelIconWrapper,
  CFIconWrapper,
  BottomItems,
  ItemsWrapper,
  Courses,
  BackgroundBlur,
  DashboardIconMobileViewWrapper,
};
