import React from 'react';
import StyledBackgroundImage from './styles/StyledBackgroundImage';
import StyledContentWrapper from './styles/StyledContentWrapper';

const EmptyState = () => {
  return (
    <StyledContentWrapper>
      <StyledBackgroundImage />
      <h3 className="ds-typography__h3">No applications yet</h3>
      <p>Start applying to jobs and tracking your applications</p>
    </StyledContentWrapper>
  );
};

export default EmptyState;
