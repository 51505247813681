import styled from 'styled-components';

const Button = styled.a`
  align-self: flex-end;
  margin-bottom: 0;
  margin-top: auto;
  text-decoration: underline;
  cursor: pointer;
  color: var(--ds-color__petrol);

  @media (max-width: 767px) {
    display: none;
  }
`;

Button.displayName = 'Button';

export default Button;
