module.exports = (() => {
  const init = () => bindFunctions();

  const bindFunctions = () => {
    $('#feedback-modal').modal('toggle');
    removeFeedBack();
  };

  const removeFeedBack = function () {
    const urlParams = new URLSearchParams(window.location.search);
    const feedback = urlParams.get('feedback');
    $('#feedback-modal h3').text(setHeading(feedback));
    $('#feedback-modal .' + feedback).removeClass('hide');
    if (feedback === 'deletion') {
      $('#feedback-modal .modal-title').text('Cancelled');
    }
    removeFeedbackFromURI();
  };

  const setHeading = (feedback) =>
    feedback === 'deletion' ? 'Cancelled' : 'Leave created';

  const removeFeedbackFromURI = function () {
    const uri = window.location.toString();
    if (uri.indexOf('?') > 0) {
      const cleanUri = uri.substring(0, uri.indexOf('?'));
      window.history.replaceState({}, document.title, cleanUri);
    }
  };

  return {
    init,
  };
})();
