import { constants } from './constants';
import { mapJobSeekingProfileResponse } from '../admin/EmployerConnector/Students/helpers';

export const initialState = {
  activePage: 1,
  employer: {},
  fetchingProfiles: false,
  jobSeekingProfile: {},
  jobSeekingProfiles: [],
  modalAction: '',
  modalIsOpen: false,
  onboardingStatus: {},
  perPage: 20,
  profileFilterParams: { city: '', country: '', program: '', specialization: '' },
  totalEntries: 0,
  totalPages: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
  case constants.HANDLE_CLOSE_MODAL: {
    return {
      ...state,
      messagingLoading: null,
      modalAction: '',
      modalIsOpen: false,
    };
  }

  case constants.SHOW_PASSWORD_CHANGE_MODAL: {
    return {
      ...state,
      modalAction: 'changePassword',
      modalIsOpen: true,
    };
  }

  case constants.FETCH_ONBOARDING_STATUS_SUCCESS: {
    const { data } = action;
    return {
      ...state,
      onboardingStatus: data,
    };
  }

  case constants.SET_CURRENT_USER: {
    const { currentUser } = action;
    return {
      ...state,
      employer: currentUser,
    };
  }

  case constants.PASSWORD_UPDATE_SUCCESS: {
    const { data } = action;
    return {
      ...state,
      onboardingStatus: {
        ...state.onboardingStatus,
        passwordUpdatedAt: data.passwordUpdatedAt,
      },
    };
  }

  case constants.POLICY_UPDATE_SUCCESS: {
    const { data } = action;
    return {
      ...state,
      onboardingStatus: {
        ...state.onboardingStatus,
        ...data,
      },
    };
  }

  case constants.PASSWORD_UPDATE_FAILURE: {
    const { error } = action;
    return {
      ...state,
      passwordError: error,
    };
  }

  case constants.SET_PROFILE_LOADING: {
    return {
      ...state,
      fetchingProfiles: true,
    };
  }

  case constants.SET_PROFILE_FILTER: {
    const { name, value } = action.data;
    return {
      ...state,
      profileFilterParams: {
        ...state.profileFilterParams,
        [name]: value,
      },
    };
  }

  case constants.FETCH_JOB_SEEKING_PROFILES_SUCCESS: {
    const { data } = action;
    return {
      ...state,
      activePage: data.currentPage,
      fetchingProfiles: false,
      jobSeekingProfiles: mapJobSeekingProfileResponse(data.data),
      perPage: data.perPage,
      totalEntries: data.totalEntries,
      totalPages: data.totalPages,
    };
  }

  case constants.SHOW_JOB_SEEKING_PROFILE_SUCCESS: {
    const { data } = action;
    return {
      ...state,
      jobSeekingProfile: data,
      modalAction: 'showProfileModal',
      modalIsOpen: true,
    };
  }

  case constants.SET_MESSAGING_LOADING: {
    const { data } = action;
    return {
      ...state,
      messagingLoading: data,
    };
  }

  case constants.SET_CREATING_CHANNEL: {
    const { data } = action;
    return {
      ...state,
      creatingChannel: data,
    };
  }

  default:
    return state;
  };
};
