import styled from 'styled-components';

const StyledHeaderImage = styled.div`
  height: 300px;
  flex-basis: 45%;
  background-repeat: no-repeat;
  background-image: url('https://coach-courses-us.s3.amazonaws.com/public/images/Group+629.png');
  background-position: center;

  ${({ isPreview }) =>
    !isPreview &&
    `
      height: 168px;
      flex-basis: 25%;
      background-position: unset;
      background-size: contain;
    `}

  @media (min-width: 1480px) {
    ${({ isPreview }) =>
      isPreview &&
      `
      flex-basis: 410px;
    `}
  }

  @media (max-width: 767px) {
    background-size: contain;
    height: 168px;
    margin-bottom: 24px;
    flex-basis: unset;

    ${({ isPreview }) =>
      !isPreview &&
      `
        background-position: center;
      `}
  }
`;
StyledHeaderImage.displayName = 'StyledHeaderImage';
export default StyledHeaderImage;
