import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 375px;
  text-align: center;
`;

Wrapper.displayName = 'Wrapper';

export { Wrapper };
