import BannerWrapper from './styles/BannerWrapper';
import ButtonWithIcon from '../../../shared/components/ButtonWithIcon';
import ConfidenceSurveyBanner from '../../../shared/components/ConfidenceSurveyBanner';
import JobApplicationsContainer from '../../containers/JobApplicationsContainer';
import PropTypes from 'prop-types';
import React from 'react';
import StyledJobApplicationTracker from './styles/StyledJobApplicationTracker';

const App = ({ attendeeId }) => {
  return (
    <StyledJobApplicationTracker>
      <div className="ds-grid__container">
        <ButtonWithIcon href="/en/career-support-center" icon="arrow-left" type="secondary" text="Back to career support center" />
        <BannerWrapper>
          <ConfidenceSurveyBanner attendeeId={attendeeId} />
        </BannerWrapper>
        <JobApplicationsContainer attendeeId={attendeeId} />
      </div>
    </StyledJobApplicationTracker>
  );
};

App.propTypes = {
  attendeeId: PropTypes.number.isRequired,
};

export default App;
