import styled from 'styled-components';

const StyledInterviewPrep = styled.div`
  background: #F1F1F1;
  padding: 0px 0 40px 0;

  @media (max-width: 767px) {
    background: #fff;
    padding: 16px 0;
  }
`;

StyledInterviewPrep.displayName = 'StyledInterviewPrep';

export default StyledInterviewPrep;
