import React, { useEffect, useState } from 'react';
import { getAdmission, updateAdmission } from '../../actions';
import Card from '../../../../shared/styles/Card';
import Documents from '../Documents';
import Header from '../Header';
import { IAdmission, IModalAction } from '../../interfaces';
import LoadingSpinner from '../../../../shared/loadingSpinner';
import Modal from '../Modal';
import Surveys from '../Surveys';
import { initialState } from '../../constants';
import { StyledApp } from './styles';

interface AppProps {
  id: string;
}

const App: React.FC<AppProps> = ({ id }) => {
  const [admission, setAdmission] = useState<IAdmission>({ ...initialState });
  const [loading, setLoading] = useState<boolean>(true);
  const [modalAction, setModalAction] = useState<IModalAction>({
    action: '',
    attribute: '',
  });

  const handleModalClose = () => setModalAction({ action: '', attribute: '' });
  const handleModalAction = (action: string, attribute: string) =>
    setModalAction({ action, attribute });

  useEffect(() => {
    getAdmission(id).then((admission) => {
      setAdmission(admission);
      setLoading(false);
    });
  }, []);

  const handleUpdateAdmission = (params: any) => {
    updateAdmission(id, params).then((newAdmission) => {
      setAdmission(newAdmission);
      setModalAction({ action: '', attribute: '' });
    });
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <StyledApp className='ds-grid__container-fluid'>
      <Header
        firstName={admission.firstName}
        lastName={admission.lastName}
        userId={admission.userId}
        slug={admission.slug}
        status={admission.status}
        stateDate={admission.stateDate}
        email={admission.email}
        createdAt={admission.createdAt}
        productName={admission.productName}
        id={admission.id}
      />
      <Card>
        <div className='ds-grid__row'>
          <div className='ds-grid__col-xs-6'>
            <Documents
              admission={admission}
              handleModalAction={handleModalAction}
              handleUpdateAdmission={handleUpdateAdmission}
            />
          </div>
          <div className='ds-grid__col-xs-6'>
            <Surveys admission={admission} />
          </div>
        </div>
      </Card>
      <Modal
        modalAction={modalAction}
        handleModalClose={handleModalClose}
        handleUpdateAdmission={handleUpdateAdmission}
      />
    </StyledApp>
  );
};

export default App;
