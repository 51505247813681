import React from 'react';

export const BookIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6735 4.47369C17.6735 3.92141 18.1212 3.47369 18.6735 3.47369H20.8638C21.8691 3.47369 22.684 4.28861 22.684 5.29387V22.1798C22.684 23.1851 21.8691 24 20.8638 24H7.45152C6.44626 24 5.63135 23.1851 5.63135 22.1798V19.9123C5.63135 19.36 6.07906 18.9123 6.63135 18.9123C7.18363 18.9123 7.63135 19.36 7.63135 19.9123V22H20.684V5.47369H18.6735C18.1212 5.47369 17.6735 5.02598 17.6735 4.47369Z"
    />
    <path d="M2 1.82018C2 1.36721 2.36721 1 2.82018 1H18.5482C19.0012 1 19.3684 1.36721 19.3684 1.82018V19.864C19.3684 20.317 19.0012 20.6842 18.5482 20.6842H2.82018C2.36721 20.6842 2 20.317 2 19.864V1.82018Z" />
    <path
      d="M4.46045 7.94737V1H7.93413V7.94737L6.19729 5.96241L4.46045 7.94737Z"
      fill="#1C7488"
    />
  </svg>
);
