import PropTypes from 'prop-types';
import React from 'react';
import Select from '../Select';

const CountrySelect = ({
  name,
  value,
  onChange,
  placeholder = 'Country',
  required,
}) => {
  const countryList = Object.entries(CF.countries.listWithCodes).slice(1);

  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      required={required}
    >
      <option>{placeholder}{required ? '*' : ''}</option>
      {countryList.map(country => (
        <option key={country[0]} value={country[0]}>{country[1]}</option>
      ))}
    </Select>
  );
};

CountrySelect.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
};

export default CountrySelect;
