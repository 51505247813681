import {
  Channel,
  Chat,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from 'stream-chat-react';
import { CustomHeaderWrapper, Wrapper } from './styles';
import React, { useEffect } from 'react';
import CustomDateSeparator from '../../../components/CustomDateSeparator';
import CustomMessageInput from '../../../components/CustomMessageInput';
import PropTypes from 'prop-types';
import { chatClient } from '../../../index';
import { uploadFile } from '../../../helpers';
import useOutsideClick from '../../../../shared/hooks/useOutsideClick';

const CustomHeader = ({
  closeMessagingWindow,
  channelMembers,
  channelName,
  userId,
}) => {
  const handleClick = () => closeMessagingWindow();
  let title = channelName;
  const members = Object.values(channelMembers);

  if (!title && members.length === 2) {
    const otherMember = members.find((m) => m.user.id !== userId);
    title = otherMember.user.name;
  }

  return (
    <CustomHeaderWrapper className='str-chat__header-livestream'>
      <button onClick={handleClick}>
        <i className='ds-icon__close-thin ds-typography__text' />
      </button>
      <h6>{title}</h6>
    </CustomHeaderWrapper>
  );
};

CustomHeader.propTypes = {
  channelMembers: PropTypes.object,
  channelName: PropTypes.string,
  closeMessagingWindow: PropTypes.func,
  userId: PropTypes.string,
};

const App = ({
  activeChannel,
  closeMessagingWindow,
  isMessagingWindowOpen,
  ...rest
}) => {
  const { ref, isOutsideClick } = useOutsideClick();
  useEffect(() => {
    isOutsideClick && closeMessagingWindow();
  }, [isOutsideClick]);

  return (
    isMessagingWindowOpen && (
      <Wrapper ref={ref}>
        <Chat client={chatClient} theme={'messaging light'}>
          <Channel channel={activeChannel} DateSeparator={CustomDateSeparator}>
            <Window>
              <CustomHeader
                channelMembers={activeChannel.state.members}
                channelName={activeChannel.data.name}
                closeMessagingWindow={closeMessagingWindow}
                userId={chatClient.user.id}
                {...rest}
              />
              <MessageList
                messageActions={['edit', 'delete']}
                onlySenderCanEdit={true}
              />
              <MessageInput
                doFileUploadRequest={uploadFile}
                grow={true}
                Input={CustomMessageInput}
              />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      </Wrapper>
    )
  );
};

App.propTypes = {
  activeChannel: PropTypes.object,
  closeMessagingWindow: PropTypes.func,
  isMessagingWindowOpen: PropTypes.bool,
};

export default App;
