import LinkEdit from '../../shared/LinkEdit';
import styled from 'styled-components';

const LinkEditView = styled(LinkEdit)`
  margin-left: 48px;
  width: 50%;
  
  .icon-wrap {
    display: none;
  }
  @media (max-width: 768px) {
    width: 50%;
  }
`;

LinkEditView.displayName = 'LinkEditView';

export default LinkEditView;
