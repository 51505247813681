import { constants } from '../../shared/StripePayments/constants';

interface State {
  transactionId: number;
  clientSecret: string;
  productSku: string;
  error: string;
  userLocation: string;
}

const initialState: State = {
  transactionId: 0,
  productSku: '',
  clientSecret: '',
  error: '',
  userLocation: '',
};

export default function (state = initialState, action: any): State {
  switch (action.type) {
    case constants.GET_INITIAL_DATA_SUCCESS: {
      return {
        ...state,
        clientSecret: action.payload.clientSecret,
        transactionId: action.payload.transactionId,
        productSku: action.payload.productSku,
      };
    }
    case constants.GET_INITIAL_DATA_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case constants.GET_USER_LOCATION: {
      return {
        ...state,
        userLocation: action.payload.country_code,
      };
    }
    default:
      return state;
  }
}
