const careerfoundryCoach = require('../../../../../assets/javascripts/site/careerfoundry_coach.js');

module.exports = (() => {
  const init = () => {
    bindFunctions();
    calendarEntriesTable();
  };

  const bindFunctions = () => {
    $(".calendar_entries_data_table").on('change', '.status-select', updateCalendarEntry);
  };

  const calendarEntriesTable = () => {
    $('.calendar_entries_data_table').DataTable({
      searching: false,
      paging: false,
      info: false
    });
  };

  // UPDATE Calendar entry
  const updateCalendarEntry = function () {
    var bookingId = $(this).data("id");
    var bookingStatus = $(this).val();
    var initialValue = $(this).data("initial-value");

    if(confirm(_bookingMessage(bookingId, bookingStatus))){
      $.ajax({
        url: "/admin/calendar_entries/" + bookingId,
        type: "PUT",
        data: { status: bookingStatus }
      }).fail(function(r) {
        let response = $.parseJSON(r.responseText)
        CareerFoundyCoach.flashMessage(response.message, response.type)
      });
    }else{
      $(this).val(initialValue);
    }
  };

  const _bookingMessage = (bookingId, bookingStatus) => {
    return(
      `Are you sure you want to change the status of the Booking ${bookingId} to ${bookingStatus}?`
    )
  };

  return {
    init: init
  };
})();
