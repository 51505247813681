export const tourSteps = {
  ep_rubric: {
    arrowPosition: 'right',
    position: 'left',
    selector: '.step_criteria_row',
    style: {
      backgroundColor: 'var(--ds-color__indigo)',
      color: '#fff',
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '0px',
      maxWidth: '344px',
    },
  },
  ep_submission: {
    arrowPosition: 'left',
    position: 'right',
    selector: '#step_submission_history_tab',
    style: {
      backgroundColor: 'var(--ds-color__indigo)',
      color: '#fff',
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '0px',
      maxWidth: '344px',
    },
  },
  ep_task: {
    arrowPosition: 'left',
    position: 'right',
    selector: '.task-heading',
    style: {
      backgroundColor: 'var(--ds-color__indigo)',
      color: '#fff',
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '0px',
      maxWidth: '344px',
    },
  },
};
