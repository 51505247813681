import Border from './styles/Border';
import ConfidenceSurveys from '../../components/ConfidenceSurveys';
import InfoWrapper from './styles/InfoWrapper';
import ProfileTabStyles from './styles/ProfileTab';
import PropTypes from 'prop-types';
import React from 'react';

const ProfileTab = ({ answers = [], user = {} }) => {
  const { survey = {} } = user;

  return (
    <ProfileTabStyles>
      <p className='ds-typography__lead--bold'>Contact Details</p>
      <div className='ds-grid__row'>
        <div className='ds-grid__col-xs-12'>
          <InfoWrapper>
            <p className='ds-typography__body--bold'>E-mail</p>
            <p className='ds-typography__body'>{user.email}</p>
          </InfoWrapper>
          <InfoWrapper>
            <p className='ds-typography__body--bold'>Skype</p>
            <p className='ds-typography__body'>{user.skype}</p>
          </InfoWrapper>
        </div>
      </div>
      {!user.hideSurveyAnswers && answers.length && (
        <>
          <Border />
          <div className='ds-grid__row'>
            <div className='ds-grid__col-xs-12'>
              <p className='ds-typography__lead--bold'>
                Onboarding Background Survey
              </p>
              {answers.map((answer) => (
                <InfoWrapper key={answer.surveyQuestionId}>
                  <p className='ds-typography__body--bold'>
                    {answer.questionTitle}
                  </p>
                  <p className='ds-typography__body'>
                    {answer.summarizedAnswer}
                  </p>
                </InfoWrapper>
              ))}
            </div>
          </div>
        </>
      )}
      {survey.optedInToJobPrep && (
        <>
          <Border />
          <p className='ds-typography__lead--bold'>Job Preparation Survey</p>
          <div className='ds-grid__row'>
            <div className='ds-grid__col-xs-12'>
              <InfoWrapper>
                <p className='ds-typography__body--bold'>
                  Willing to relocate?
                </p>
                <p className='ds-typography__body'>
                  {survey.willingToRelocate}
                </p>
              </InfoWrapper>
              <InfoWrapper>
                <p className='ds-typography__body--bold'>
                  Places, willing to relocate to
                </p>
                <p className='ds-typography__body'>
                  {survey.relocateLocations !== ''
                    ? survey.relocateLocations
                    : 'None'}
                </p>
              </InfoWrapper>
              <InfoWrapper>
                <p className='ds-typography__body--bold'>Career situation</p>
                <p className='ds-typography__body'>{survey.signupMotivation}</p>
              </InfoWrapper>
              <InfoWrapper>
                <p className='ds-typography__body--bold'>Recent work history</p>
                <p className='ds-typography__body'>{survey.bio}</p>
              </InfoWrapper>
            </div>
          </div>
        </>
      )}
      {!!user?.confidenceSurveys?.length && (
        <ConfidenceSurveys confidenceSurveys={user.confidenceSurveys} />
      )}
    </ProfileTabStyles>
  );
};

ProfileTab.propTypes = {
  answers: PropTypes.array,
  user: PropTypes.shape({
    confidenceSurveys: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    email: PropTypes.string.isRequired,
    hideSurveyAnswers: PropTypes.bool,
    skype: PropTypes.string.isRequired,
    survey: PropTypes.shape({
      bio: PropTypes.string,
      optedInToJobPrep: PropTypes.bool,
      relocateLocations: PropTypes.string,
      signupMotivation: PropTypes.string,
      willingToRelocate: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ProfileTab;
