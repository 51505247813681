export const trackEvent = function(event, data = {}){
  $.ajax({
    url: '/api/v1/analytics/track',
    method: 'POST',
    data: {
      event: event,
      data: data
    }
  })
};
