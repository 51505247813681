import PropTypes from 'prop-types';
import React from 'react';

const GraduationCap = ({
  isHighlight = false,
  isInaccessible = false,
}) => {
  let color = '#223C50';

  if (isInaccessible) {
    color = '#A7B1B9';
  } else if (isHighlight) {
    color = '#FFFFFF';
  }

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.59349 6.11255L7.96963 3.05175L14.4058 6.20663L8.02897 8.95762L1.59349 6.11255ZM7.84308 9.03782C7.84325 9.03775 7.84342 9.03767 7.84359 9.0376L7.84309 9.03782L7.84308 9.03782Z" fill={color} stroke={color} strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.05566 10.7977V7.33331H4.05566V10.7977C4.05566 10.8324 4.07425 10.856 4.09079 10.8651L8.07686 13.0501L7.83652 13.4885L8.07686 13.0501C8.08427 13.0541 8.09128 13.0556 8.09768 13.0555C8.10409 13.0555 8.11115 13.0538 8.1186 13.0496L11.9103 10.8721C11.9267 10.8628 11.9446 10.8392 11.9446 10.8052V7.33331H12.9446V10.8052C12.9446 11.1869 12.7441 11.5465 12.4083 11.7393L8.61659 13.9167C8.30088 14.098 7.91553 14.102 7.59618 13.927L3.61011 11.742C3.26383 11.5521 3.05566 11.1866 3.05566 10.7977Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.1111 5.94446C15.3872 5.94446 15.6111 6.16832 15.6111 6.44446V10.8889C15.6111 11.165 15.3872 11.3889 15.1111 11.3889C14.8349 11.3889 14.6111 11.165 14.6111 10.8889V6.44446C14.6111 6.16832 14.8349 5.94446 15.1111 5.94446Z" fill={color}/>
    </svg>
  );
};

GraduationCap.propTypes = {
  isHighlight: PropTypes.bool,
  isInaccessible: PropTypes.bool,
};

export { GraduationCap };
