const parseDate = (date) => {
  return new Date(date).toLocaleString({
    month: 'long',
    weekday: 'long',
    year: 'numeric',
  });
};

const parseDateTime = (datetimeStr) => {
  return new Date(datetimeStr).toLocaleString({
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'long',
    weekday: 'long',
    year: 'numeric',
  });
};

const formattedValue = (value, type) => {
  let fm = null;
  switch (type) {
    case 'date':
      fm = parseDate(value);
      break;
    case 'datetime':
      fm = parseDateTime(value);
      break;
    case 'user':
      if (value) {
        fm = value.name;
      } else {
        fm = 'N/A';
      }
      break;
    default:
      fm = value;
      break;
  }
  return fm;
};

const toSnakeCase = (str) => {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((s) => s.toLowerCase())
      .join('_')
  );
};

export { parseDate, parseDateTime, toSnakeCase, formattedValue };
