import PropTypes from 'prop-types';
import React from 'react';

const DeletionFailure = ({ errorMessage, handleClose }) => (
  <>
    <i className="ds-icon__check-circle-line"></i>
    <h3 className="ds-typography__h3">Cannot delete</h3>
    <p className="ds-typography__body">{errorMessage?.errors}</p>
    <button className="ds-button__primary" onClick={handleClose}>
      Close
    </button>
  </>
);

DeletionFailure.propTypes = {
  errorMessage: PropTypes.shape({
    errors: PropTypes.string,
    message: PropTypes.string,
  }),
  handleClose: PropTypes.func,
};

export default DeletionFailure;
