import styled from 'styled-components';

const NavButtonsWrapper = styled.div`
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 24px;
`;

const NavButton = styled.div`
  width: 121px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  ${(props) =>
    props.disabled &&
    `
    background: rgba(34, 60, 80, 0.4);
    border: none;
    color: white;
  `}
`;
NavButtonsWrapper.displayName = 'NavButtonsWrapper';
NavButton.displayName = 'NavButton';

export { NavButton, NavButtonsWrapper };
