import Markdown from 'react-markdown';
import React from 'react';
import { Wrapper } from './styles';

interface Props {
  text?: string;
}

const CustomMarkdownRender: React.FC<Props> = ({ text = '' }) => (
  <Wrapper>
    <Markdown
      components={{
        p(props) {
          const { node, ...rest } = props;
          return <p className='ds-text__body--small' {...rest} />;
        },
        strong(props) {
          const { node, ...rest } = props;
          return <span className='ds-text--bold' {...rest} />;
        },
        li(props) {
          const { node, ...rest } = props;
          return <li className='ds-text__body--small' {...rest} />;
        },
      }}
    >
      {text}
    </Markdown>
  </Wrapper>
);

export default CustomMarkdownRender;
