import { MOBILE_VIEW_MAX_WIDTH } from '../../../constants';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  @media (max-width: ${MOBILE_VIEW_MAX_WIDTH}px) {
    display: none;
  }
`;

export { Wrapper };
