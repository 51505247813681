import React from 'react';

const UXResearch = () => (
  <>
    <p className="ds-typography__body">The previous stages were aimed at laying the groundwork and scoping out any preliminary questions. Now it’s time to get specific! In this phase, you need to identify the user pain point that you will be trying to solve, based on the problem that you’ve chosen to address.</p>
    <p className="ds-typography__body break">After this, you will be able to formulate your research questions. These will help you to understand which direction to take and give you a better understanding of how you can help your target users. Finally, you will formulate the problem statement, which will give clear direction to your design solution.</p>
    <p className="ds-typography__body--bold">Differences for UI designers and UX designers</p>
    <p className="ds-typography__body break">If you are aiming for a more UI-focused project, this phase could be inspired by some existing UX research and problem statement; you don’t need to focus too heavily on this part, as your main priority will be showcasing your UI design skills.. If your project is more UX-heavy, we strongly recommend  conducting your own research and explaining the reasoning behind your decisions.</p>
    <p className="ds-typography__body--bold">Research phase</p>

    <ul className="break">
      <li>What research do I need to do, and how will I conduct it?</li>
      <li>What are the skills and tools I need to learn and how will I learn them?</li>
      <li>What are the research questions that I want to answer?</li>
      <li>What pain point do my users have?</li>
      <li>What is the problem that I’m trying to solve?</li>
      <li>How does it have a UX/UI solution?</li>
    </ul>
    <p className="ds-typography__body--bold">What you could be doing during this stage</p>
    <p className="ds-typography__body">Deliverables of this stage could include:</p>
    <ul>
      <li>Surveys</li>
      <li>Interviews</li>
      <li>Field studies</li>
      <li>Diary studies</li>
      <li>Affinity mapping</li>
    </ul>
    <p className="ds-typography__body break">So basically any artifacts that summarize the research that you’ve done so far!</p>
    <p className="ds-typography__body--bold">Deliverables</p>
    <p className="ds-typography__body">Deliverables of this stage could include:</p>
    <ul>
      <li>Personas</li>
      <li>User Journey maps</li>
      <li>Storyboards etc.</li>
    </ul>
    <p className="ds-typography__body break">So basically any artifacts that summarize the research that you’ve done so far!</p>
    <p className="ds-typography__body--bold">Finding focus: Defining your problem statement</p>
    <p className="ds-typography__body">After all the research has been collected, analyzed, and synthesized, it is time to formulate a problem statement. This will set out the core challenge that you will be trying to solve during the next stages. It is based on all the research that you’ve done so far, and represents the problem that you believe would be most valuable to be solved.</p>
    <p className="ds-typography__body">After the problem statement has been defined, it might be also handy to create a list of “How Might We”s (HMWs)—initial problem reframed as “opportunity” statements. If you are not familiar with this technique, learn more in <a href="https://www.nngroup.com/articles/how-might-we-questions/" target="_blank" rel="noreferrer">this guide to “How Might We” questions put together by the Nielsen Norman Group</a>. A set of well-framed “HMW” questions will help you later on when it comes to brainstorming solutions.</p>
  </>
);

export default UXResearch;
