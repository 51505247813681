import PropTypes from 'prop-types';
import React from 'react';
import Stepper from '../../../shared/components/Stepper';
import StepperSection from './styles/InterviewReview__StepperSection';

const determineStepState = (index, answersForReview, currentAnswer) => {
  if (answersForReview[index].rating !== 0) {
    return 'isComplete';
  } else if (index > answersForReview.indexOf(currentAnswer)) {
    return 'isNext';
  } else {
    return 'isActive';
  }
};

const EvaluationStepper = ({ answersForReview, currentAnswer, isModal, currentNavIndex, onToggle }) => {
  const handleClickStepper = (index) => {
    const direction = index - currentNavIndex;
    onToggle(direction);
  };

  const constructStepsArray = () => {
    return answersForReview.map((answer, index) => {
      return {
        stepCount: index + 1,
        stepState: determineStepState(index, answersForReview, currentAnswer),
      };
    });
  };

  return (
    <StepperSection isModal={isModal}>
      <Stepper key={currentAnswer.id} steps={constructStepsArray()} onClick={handleClickStepper} />
    </StepperSection>
  );
};

EvaluationStepper.propTypes = {
  answersForReview: PropTypes.array.isRequired,
  currentAnswer: PropTypes.object,
  currentNavIndex: PropTypes.number,
  isModal: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default EvaluationStepper;
