import { Group } from '@visx/group';
import Pie from '@visx/shape/lib/shapes/Pie';
import PropTypes from 'prop-types';
import React from 'react';

const SubmissionProgressChart = ({
  goal = 1,
  radius = 50,
  stroke = 10,
  submissions = 0,
}) => {
  const normalizedRadius = radius - stroke;
  const data = Array.from({ length: goal }, (_, i) => submissions <= i);

  return (
    <svg
      viewBox='0 0 100 100'
      width='100%'
      height='100%'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Group top={50} left={50}>
        <Pie
          data={data}
          pieValue={(d) => 1}
          outerRadius={normalizedRadius}
          innerRadius={normalizedRadius - 10}
          padAngle={0.01}
          padRadius={500}
          fill={({ data }) => (data ? '#f1f1f1' : '#5ca2b2')}
        />
      </Group>
      <text
        className='ds-typography__small ds-typography__small--bold'
        x='50%'
        y='40%'
        textAnchor='middle'
        dominantBaseline='middle'
      >
        {submissions} / {goal}
      </text>
      <text
        className='ds-typography__tiny'
        x='50%'
        y='55%'
        textAnchor='middle'
        dominantBaseline='middle'
      >
        Tasks
      </text>
      <text
        className='ds-typography__tiny'
        x='50%'
        y='65%'
        textAnchor='middle'
        dominantBaseline='middle'
      >
        Submitted
      </text>
    </svg>
  );
};

SubmissionProgressChart.propTypes = {
  goal: PropTypes.number,
  radius: PropTypes.number,
  stroke: PropTypes.number,
  submissions: PropTypes.number,
};

export default SubmissionProgressChart;
