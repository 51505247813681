import { Marker, Tick, TickWrapper, Title, Wrapper } from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const ProgressLine = ({ bluePrint }) => (
  <Wrapper>
    {bluePrint.map((t, i) => t.includes('wsg') ? (
      <TickWrapper key={i}>
        <Marker
          aria-hidden="true"
          className="fa fa-flag-checkered"
          hasMetWsg={t === 'wsgMet'}
        />
        <Title className="ds-typography__small ds-color__indigo">This week's goal</Title>
      </TickWrapper>
    ) : <Tick key={i} isComplete={t === 'complete'} />,
    )}
  </Wrapper>
);

ProgressLine.propTypes = {
  bluePrint: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProgressLine;
