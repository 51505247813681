import React, { useEffect } from 'react';
import { finishTour, getCurrentUserdData, trackEvent } from '../../actions';
import OnboardingTour from '../../../shared/components/OnboardingTour';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useDesktopOnly from '../../../shared/hooks/useDesktopOnly';

const OnboardingTourContainer = ({
  active,
  onFinishTour,
  onGetUserData,
  steps,
  userData,
}) => {
  useEffect(() => {
    onGetUserData();
  }, []);

  const onTrackEvent = async (event, data) => {
    await trackEvent(event, data);
  };

  const checkToDisplay = (toursCompleted) => {
    return toursCompleted.length
      ? !toursCompleted.find((tour) => tour === 'pre_course_start')
      : true;
  };

  const shouldShowTour =
    active ||
    (userData &&
      userData.onboardingStatus &&
      userData.completedGuidedTours &&
      checkToDisplay(userData.completedGuidedTours));

  return (
    useDesktopOnly() &&
    shouldShowTour && (
      <OnboardingTour
        onFinishTour={onFinishTour}
        steps={steps}
        onTrackEvent={onTrackEvent}
      />
    )
  );
};

const mapStateToProps = (state) => {
  return {
    active: state.guidedTour.active,
    steps: state.guidedTour.steps,
    userData: state.guidedTour.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFinishTour: (stepName) => dispatch(finishTour(stepName)),
    onGetUserData: () => dispatch(getCurrentUserdData()),
  };
};

OnboardingTourContainer.propTypes = {
  active: PropTypes.bool,
  onFinishTour: PropTypes.func,
  onGetUserData: PropTypes.func,
  steps: PropTypes.array,
  userData: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingTourContainer);
