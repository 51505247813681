import ActiveButtonsWrapper from '../styles/ActiveButtonsWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import WarningWrapper from '../styles/WarningWrapper';
import Wrapper from '../styles/Wrapper';

const InactiveContent = ({
  showECModal,
  updateJobSeekingProfile,
  jobSeekingProfile,
}) => (
  <Wrapper>
    <p className='ds-typography__body text-left'>
      Employer connector connects applicants with CareerFoundry partners
      interested in hiring our graduates.&nbsp;
      <span
        onClick={() => showECModal('faq')}
        className='ds-typography__body ds-typography__anchor'
      >
        See Employer connector FAQs
      </span>
    </p>
    <WarningWrapper>
      <p className='ds-typography__body--bold text-left sub-header'>
        Your profile is deactivated
      </p>
    </WarningWrapper>
    <ActiveButtonsWrapper>
      <a
        className='ds-button__primary action-button'
        onClick={() =>
          updateJobSeekingProfile({ ...jobSeekingProfile, status: 'active' })
        }
      >
        Reactivate my Profile
      </a>
    </ActiveButtonsWrapper>
  </Wrapper>
);

InactiveContent.propTypes = {
  jobSeekingProfile: PropTypes.shape({
    attachment: PropTypes.shape({
      attachableId: PropTypes.number,
      attachableType: PropTypes.string,
      contentType: PropTypes.string,
      fileIcon: PropTypes.string,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      id: PropTypes.number,
      s3Url: PropTypes.string,
      subtype: PropTypes.string,
    }),
    country: PropTypes.string,
    graduated: PropTypes.bool,
    graduation_date: PropTypes.string,
    id: PropTypes.number.isRequired,
    motivation_statement: PropTypes.string,
    portfolio: PropTypes.string,
    program: PropTypes.string,
    progress: PropTypes.number,
    socials: PropTypes.array,
    specialization: PropTypes.string,
    state: PropTypes.string,
    status: PropTypes.string.isRequired,
    student_name: PropTypes.string,
    willing_to_work_remote: PropTypes.bool,
  }),
  showECModal: PropTypes.func.isRequired,
  updateJobSeekingProfile: PropTypes.func.isRequired,
};

export default InactiveContent;
