import ApiService from './ApiService';
import { keysToCamel } from '../utils/keysConverter';

export const ALL_LINKS_API = '/api/v1/admin/enrollment_links.json';

class EnrollmentConfigurationService {
  createLink(
    productId,
    startDate,
    payerId,
    discount,
    bankTransfer,
    notes,
    authToken,
    planType,
    targetCompletionDate,
    trialEndDate,
    createUnpaidInvoice,
    preliminaryUsername,
    payerIdentifier
  ) {
    let params = {
      authenticity_token: authToken,
      product_id: productId,
      start_date: startDate,
      target_completion: targetCompletionDate,
      trial_end_date: trialEndDate,
      payer_id: payerId,
      discount_percentage: discount,
      provider: bankTransfer ? 'bank_transfer' : null,
      notes: notes,
      payment_plan_type: planType,
      create_unpaid_invoice: createUnpaidInvoice,
      preliminary_username: preliminaryUsername,
      payer_identifier: payerIdentifier,
    };

    const apiService = new ApiService(
      '/api/v1/admin/enrollment_configurations.json'
    );

    return apiService
      .makePostCall(params)
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  fetchAllRecords = (params) => {
    const apiService = new ApiService(ALL_LINKS_API);
    return apiService
      .makeGetCall(params)
      .then((res) => {
        const resData = keysToCamel(res.data);
        return resData.data;
      })
      .catch((err) => {
        return keysToCamel(err);
      });
  };
}

export default EnrollmentConfigurationService;
