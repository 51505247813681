import React, { useState } from 'react';
import { createAttachment, deleteAttachment, handleUpload } from '../../../Uploader/actions';
import InputRow from './styles/InputRow';
import JobApplicationFormStyles from './styles/JobApplicationFormStyles';
import Line from './styles/Line';
import PropTypes from 'prop-types';
import StyledButtonSection from './styles/StyleButtonSection';
import StyledLabel from './styles/StyledLabel';
import Uploader from '../../../Uploader/components/Uploader';
import { getCurrentUserId } from '../../../UserProfile/actions';

const JobApplicationForm = ({
  formAction,
  formData,
  submitting,
  handleAttachmentUpdate,
  handleDeleteJobApplication,
  handleCancel,
  handleCreate,
  handleUpdate,
  setFormData,
}) => {
  const [uploadError, setUploadError] = useState({});

  const statuses = ['Applied', 'Phone screen', 'Assignment', '1st face-to-face interview', '2nd face-to-face interview', 'No response', 'Rejected', 'Offer accepted'];
  const requiredFields = [
    { label: 'Date Applied*', max: new Date().toISOString().split('T')[0], name: 'date', required: true, type: 'date' },
    { label: 'Role*', name: 'role', required: true, type: 'text' },
    { label: 'Company*', name: 'company', required: true, type: 'text' },
    { label: 'Source*', name: 'source', required: true, sublabel: 'Where did you find the job ad? LinkedId, Glassdoor, etc.', type: 'text' },
  ];

  const optionalFields = [
    { label: 'Link', name: 'link', required: false, type: 'text' },
    { label: 'Location', name: 'location', required: false, type: 'text' },
    { label: 'Notes', name: 'notes', required: false, textArea: true, type: 'text' },
    { label: 'Contact', name: 'contact', required: false, type: 'text' },
    { label: 'Follow up date', name: 'followUpDate', required: false, sublabel: 'When do you plan to follow up or did you already follow up?', type: 'date' },
    { label: 'Salary', name: 'salary', required: false, type: 'text' },
  ];

  const screenshotAttachments = formData.attachments.filter(attachment => attachment.subtype === 'screenshot');
  const coverLetterAttachments = formData.attachments.filter(attachment => attachment.subtype === 'cover_letter');

  const handleFormData = ({ target: { name, value } }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const jobApplicationFormSubmit = e => {
    e.preventDefault();
    formData.status = formData.status ? formData.status : statuses[0];

    formAction === 'Update' ? handleUpdate(formData) : handleCreate(formData);
  };

  const handleAttachment = (file, subtype) => {
    getCurrentUserId().then(result => {
      handleUpload(file, 'job_applications', result).then(response => {
        createAttachment(response, 'JobApplication', subtype, formData.id).then(response => {
          if (response.type === 'danger') {
            setUploadError({ message: Object.entries(response.message)[0].flat().join(''), status: 'danger', subtype: subtype });
          } else {
            const updatedFormData = { ...formData, attachments: [...formData.attachments, response.data] };
            setFormData(updatedFormData);
            handleAttachmentUpdate(updatedFormData);
            dismissError();
          }
        });
      });
    });
  };

  const handleDelete = (id) => {
    deleteAttachment(id).then(response => {
      const updatedFormData = { ...formData, attachments: formData.attachments.filter(attachment => attachment.id !== response.data.id) };
      setFormData(updatedFormData);
      handleAttachmentUpdate(updatedFormData);
    });
  };

  const dismissError = () => {
    setUploadError({});
  };

  const formatInputs = (field) => {
    return (<InputRow key={field.name}>
      <StyledLabel>
        <label
          className="ds-typography__body--bold">
          {field.label}
        </label>
        {field.sublabel && <p className="ds-typography__tiny">{field.sublabel}</p>}
      </StyledLabel>
      {field.textArea
        ? <textarea
          required={field.required}
          value={formData[field.name] || ''}
          placeholder="Type here"
          onChange={handleFormData}
          name={field.name}
        />
        : <input
          type={field.type}
          required={field.required}
          value={formData[field.name] || ''}
          placeholder="Type here"
          onChange={handleFormData}
          name={field.name}
          max={field.max}
        />
      }
    </InputRow>);
  };

  return (
    <JobApplicationFormStyles onSubmit={jobApplicationFormSubmit}>
      <h3 className="ds-typography__h3">{formAction} Job Application</h3>
      {requiredFields.map((field) => formatInputs(field))}
      <InputRow>
        <label
          className="ds-typography__body--bold">
          Status*
        </label>
        <select
          onChange={handleFormData}
          name="status"
          id="status"
          value={formData.status}
        >
          {statuses.map((status, i) => (
            <option value={status} key={i}>{status}</option>
          ))}
        </select>
      </InputRow>
      <Line />
      <h5 className="ds-typography__lead--bold">Optional information</h5>
      {optionalFields.map((field) => formatInputs(field))}
      <Uploader subtype="screenshot"
        label="Job Ad Screenshot"
        handleAttachment={handleAttachment}
        deleteAttachment={handleDelete}
        dismissError={dismissError}
        sublabel="2 MB limit, File types allowed: .jpg, .png, .bmp"
        attachments={screenshotAttachments}
        error={uploadError.subtype === 'screenshot' ? uploadError : {}} />
      <Uploader subtype="cover_letter"
        label="Cover Letter"
        handleAttachment={handleAttachment}
        deleteAttachment={handleDelete}
        dismissError={dismissError}
        sublabel="2 MB limit, File types allowed: .pdf, .doc, .docx"
        attachments={coverLetterAttachments}
        error={uploadError.subtype === 'cover_letter' ? uploadError : {}} />
      <StyledButtonSection className="text-right">
        {formAction === 'Update'
          ? <button className="ds-button ds-button__secondary" onClick={() => handleDeleteJobApplication(formData.id)}>Delete Application</button>
          : <button className="ds-button ds-button__secondary" onClick={() => handleCancel(formData)}>Cancel</button>
        }
        <button className="ds-button__primary" disabled={submitting} type="submit">{formAction === 'Create' ? 'Submit' : 'Submit Changes'}</button>
      </StyledButtonSection>
    </JobApplicationFormStyles>
  );
};

JobApplicationForm.propTypes = {
  formAction: PropTypes.string,
  formData: PropTypes.shape({
    attachments: PropTypes.array,
    company: PropTypes.string,
    contact: PropTypes.string,
    date: PropTypes.any,
    followUpDate: PropTypes.any,
    id: PropTypes.number,
    link: PropTypes.string,
    location: PropTypes.string,
    notes: PropTypes.string,
    role: PropTypes.string,
    salary: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
  }),
  handleAttachmentUpdate: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleDeleteJobApplication: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default JobApplicationForm;
