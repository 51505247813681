import PropTypes from 'prop-types';
import React from 'react';
import StyledFormField from '../../styles/StyledFormField';
import StyledLabelLarge from '../../styles/StyledLabel__Large';

const FormField = ({
  labelText,
  children,
  tooltip,
  sublabel,
  className = '',
}) => (
  <StyledFormField className={`${className} ${sublabel ? 'sublabel' : ''}`}>
    <StyledLabelLarge className='large-label'>
      <span>
        <label className='ds-typography__body--bold'>{labelText}</label>
        {tooltip && tooltip}
      </span>
      {sublabel && <p className='ds-typography__tiny'>{sublabel}</p>}
    </StyledLabelLarge>
    {children}
  </StyledFormField>
);

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  sublabel: PropTypes.string,
  tooltip: PropTypes.object,
};

export default FormField;
