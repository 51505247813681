import styled from 'styled-components';

const TabsWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 32px;
  width: 300px;

  .locked {
    &:hover {
      background-color: transparent;
      cursor: not-allowed;
    }
  }
`;

export { TabsWrapper };
