import AnswerHistoryCard from '../../components/AnswerHistoryCard';
import { connect } from 'react-redux';
import { openQuestionInsights } from './actions';

export const mapStateToProps = ({
  interviewPrep: {
    interviewAnswers,
    selectedCategory,
  },
}) => ({
  interviewAnswers,
  selectedCategory,
});

export const mapDispatchToProps = dispatch => ({
  openQuestionInsights: (question, insights) => dispatch(openQuestionInsights(question, insights)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnswerHistoryCard);
