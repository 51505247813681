import styled from 'styled-components';

const StyledAnswer = styled.div`
  cursor: pointer;
  height: 72px;
  margin: 3px 0 3px 16px;
  padding: 8px 10px 20px 14px;
  border: solid 1px var(--ds-color__grey--light);
  background-color: var(--ds-color__grey--light_300);
  display: inline-block;
  width: 192px;

  .h-6 {
    height: 15px;
    margin-right: 8px;
  }

  .w-6 {
    width: 15px;
  }

  p {
    font-size: 9px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  i {
    margin-left: 14px;
    font-size: 14px;
  }

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

StyledAnswer.displayName = 'Answer';

export default StyledAnswer;
