import OnboardingPanel from './OnboardingPanel';
import PropTypes from 'prop-types';
import React from 'react';
import StyledDashboardHeader from '../../../../CareerSupportCenter/components/DashboardWrapper/styles/StyledDashboardHeader';
import StyledHeaderWrapper from '../../../../CareerSupportCenter/components/DashboardWrapper/styles/StyledHeaderWrapper';
import StyledOnboardingWrapper from '../../styles/StyledOnboardingWrapper';

const OnboardingWrapper = ({ header, children, panelHeader, buttonLabel, onClick }) => {
  return (
    <StyledOnboardingWrapper>
      <StyledHeaderWrapper>
        <StyledDashboardHeader className="ds-typography__h2">
          {header}
        </StyledDashboardHeader>
      </StyledHeaderWrapper>
      <OnboardingPanel header={panelHeader} buttonLabel={buttonLabel} onClick={onClick}>
        {children}
      </OnboardingPanel>
    </StyledOnboardingWrapper>
  );
};

OnboardingWrapper.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  children: PropTypes.node,
  header: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  panelHeader: PropTypes.string,
};

export default OnboardingWrapper;
