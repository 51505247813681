import styled from 'styled-components';

const StyledSelect = styled.div`
  position: relative;

  select {
    padding: 14px 16px 12px;
    border: 1px solid
      ${(props) => (props.error ? 'var(--ds-color_error)' : '#A7B1B9')};
    border-radius: 4px;
    font-family: TradeGothic;
    font-size: 16px;
    color: var(--ds-color__indigo);
    appearance: none;
    outline: none;

    &:focus {
      border: 1px solid
        ${(props) =>
          props.error ? 'var(--ds-color_error)' : 'var(--ds-color__indigo)'};
    }

    &:hover {
      border: 1px solid
        ${(props) =>
          props.error ? 'var(--ds-color_error)' : 'var(--ds-color__petrol)'};
    }
  }

  & > span {
    &:after {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      top: 14px;
      right: 16px;
      border-right: 2px solid var(--ds-color__indigo);
      border-bottom: 2px solid var(--ds-color__indigo);
      transform: rotate(45deg);
      pointer-events: none;
    }
  }
`;

StyledSelect.displayName = 'StyledSelect';

export default StyledSelect;
