import { Body } from '@careerfoundry/bubble';
import React from 'react';

export const transformParamsForPost = (params) => {
  const postParams = {};
  postParams.payout_method = getPayoutMethod(params);
  postParams.address_line = params.addressLine;
  postParams.address_second_line = params.addressSecondLine;
  postParams.city = params.city;
  postParams.postal_code = params.postalCode;
  postParams.country = params.country;
  postParams.business_name = params.businessName;
  postParams.tax_rate = params.taxRate;
  postParams.tax_number = params.taxNumber;
  postParams.small_company = params.smallCompany;
  postParams.contact_name = params.contactName;
  postParams.uuid = params.uuid;
  postParams.payee_email = params.payeeEmail;
  postParams.payout_type = params.payoutType;
  postParams.phone_number = params.phoneNumber;
  postParams.state = params.state;

  return postParams;
};

export const getStateParams = ({
  accountHolder,
  addressLine,
  addressSecondLine,
  bankAddress,
  bankCity,
  bankCountry,
  bankName,
  bicNumber,
  branch,
  businessName,
  city,
  contactName,
  country,
  ibanNumber,
  methodType,
  payeeEmail,
  payoutType,
  paypalEmail,
  phoneNumber,
  postalCode,
  smallCompany,
  state,
  taxNumber,
  taxRate,
  uuid,
}) => {
  return {
    accountHolder: accountHolder || '',
    addressLine: addressLine || '',
    addressSecondLine: addressSecondLine || '',
    bankAddress: bankAddress || '',
    bankCity: bankCity || '',
    bankCountry: bankCountry || '',
    bankName: bankName || '',
    bicNumber: bicNumber || '',
    branch: branch || '',
    businessName: businessName || '',
    city: city || '',
    contactName: contactName || '',
    country: country || '',
    ibanNumber: ibanNumber || '',
    methodType: methodType || '',
    payeeEmail: payeeEmail || '',
    payoutType: payoutType || '',
    paypalEmail: paypalEmail || '',
    phoneNumber: phoneNumber || '',
    postalCode: postalCode || '',
    smallCompany: !!smallCompany || false,
    state: state || '',
    taxNumber: taxNumber || '',
    taxRate: taxRate || '0',
    uuid: uuid || '',
  };
};

const getPayoutMethod = (params) => {
  if (params.methodType === 'bank_transfer') {
    return {
      account_holder: params.accountHolder,
      bank_address: params.bankAddress,
      bank_city: params.bankCity,
      bank_country: params.bankCountry,
      bank_name: params.bankName,
      bic_number: params.bicNumber,
      branch: params.branch,
      iban_number: params.ibanNumber,
      method_type: params.methodType,
    };
  }
  if (params.methodType === 'paypal') {
    return {
      method_type: params.methodType,
      paypal_email: params.paypalEmail,
    };
  }
};

export const renderMessages = (formMessages = []) => {
  return (
    <ul className='form-messages'>
      {formMessages.map((formMessage, index) => (
        // code can be either success or error for now
        <li key={`${formMessage.code}_${index}`}>
          <Body
            tag='span'
            variant='medium'
            className={`${formMessage.code}-message`}
          >
            {formMessage.message}
          </Body>
        </li>
      ))}
    </ul>
  );
};
