import React, { useEffect } from 'react';
import App from '../../components/App';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import { getInitialData } from '../../actions';
import { IProgress } from '../../interfaces';

interface AppContainerProps {
  loading: boolean;
  selectedTab: string;
  getInitialData: () => void;
  progress: IProgress;
}

export const AppContainer: React.FC<AppContainerProps> = ({
  loading,
  selectedTab,
  getInitialData,
  progress,
}) => {
  useEffect(() => {
    getInitialData();
  }, []);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <App selectedTab={selectedTab} progress={progress} />
  );
};

interface IState {
  afaAdmissions: {
    loading: boolean;
    selectedTab: string;
    progress: IProgress;
  };
}

export const mapStateToProps = ({
  afaAdmissions: { loading, selectedTab, progress },
}: IState) => ({
  loading,
  selectedTab,
  progress,
});

export const mapDispatchToProps = (dispatch: any) => ({
  getInitialData: () => dispatch(getInitialData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
