import React from 'react';
import User from '../../components/User';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { name, avatar } = state.sideNavList.meData;

  return {
    name,
    profilePhotoUrl: avatar,
  };
};

const UserContainer = (props) => {
  return <User {...props} />;
};

UserContainer.propTypes = {
  ...User.propTypes,
};

export default connect(mapStateToProps)(UserContainer);
