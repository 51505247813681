import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;
`;

Layout.displayName = 'Layout';

export default Layout;
