import AnswerCarousel from '../AnswerCarousel';
import PropTypes from 'prop-types';
import React from 'react';
import StyledQuestion from './styles/StyledQuestion';
import { interviewQuestionTracking } from '../../../../shared/helpers/tracking';

const Question = ({
  openQuestionInsights,
  question,
}) => {
  const handleOpenQuestionInsights = () => {
    interviewQuestionTracking(question, 'ip_open_question_insights');
    openQuestionInsights(question.question, question.questionInsights);
  };
  return (
    <StyledQuestion className="ds-grid__row question" key={question.question}>
      <div className="ds-grid__col-sm-12 ds-grid__col-md-3 question">
        <p className="ds-typography__body--bold">{question.question}</p>
        <a onClick={() => handleOpenQuestionInsights()} className="ds-typography__body ds-typography__anchor">Tips for answering the question</a>
      </div>
      <div className="ds-grid__col-sm-12 ds-grid__col-md-9 answers">
        <AnswerCarousel answers={question.answers} />
      </div>
    </StyledQuestion>
  );
};

Question.propTypes = {
  openQuestionInsights: PropTypes.func,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.shape({
          attachment: PropTypes.shape({
            fileIcon: PropTypes.string,
            fileName: PropTypes.string,
            id: PropTypes.number,
            s3Url: PropTypes.string,
          }),
          category: PropTypes.string,
          date: PropTypes.string,
          id: PropTypes.number,
          industry: PropTypes.string,
          notes: PropTypes.string,
          question: PropTypes.string,
          questionInsights: PropTypes.string,
          rating: PropTypes.number,
          shared: PropTypes.bool,
        }),
      }),
    ),
    question: PropTypes.string,
    questionInsights: PropTypes.string,
  }),
};

export default Question;
