export const formatDropdown = (result, inAttributes, key1, key2) => {
  let arr = result.map(item => {
    return {
      text: item.attributes[key1],
      value: inAttributes ? item.attributes[key2] : item[key2]
    }
  });
  return arr;
};

export const formatDropdownFromParams = (result, key1, key2) => {
  let arr = result.map(item => {
    return {
      text: item[key1],
      value: item[key2]
    }
  });
  return arr;
};
