import ConfidenceReviewModal from '../../../CareerSupportCenter/components/MainDashboard/components/ConfidenceReviewModal';
import PropTypes from 'prop-types';
import React from 'react';
import SurveyStyleWrapper from './styles';
import TheModal from '../../../shared/components/TheModal';
import Typeform from '../../../shared/components/Typeform';

const ConfidenceSurvey = ({
  attendeeId,
  formId,
  handleClick,
  handleClose,
  handleSubmit,
  isModalOpen,
  linkText,
  renderSurvey,
  modalType,
  handleCloseReviewModal,
}) => {
  return (
    renderSurvey && (
      <SurveyStyleWrapper>
        <ul>
          <li>
            <a onClick={handleClick}>{linkText}</a>
          </li>
        </ul>
        <TheModal handleClose={handleClose} isModalOpen={isModalOpen}>
          {
            {
              form: (
                <Typeform
                  formId={formId}
                  handleSubmit={handleSubmit}
                  params={{
                    attendee_id: attendeeId,
                    form_type: 'step',
                  }}
                />
              ),
              review: (
                <ConfidenceReviewModal onCloseModal={handleCloseReviewModal} />
              ),
            }[modalType]
          }
        </TheModal>
      </SurveyStyleWrapper>
    )
  );
};

ConfidenceSurvey.propTypes = {
  attendeeId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCloseReviewModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  linkText: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  renderSurvey: PropTypes.bool.isRequired,
};

export default ConfidenceSurvey;
