import styled from 'styled-components';

const Card = styled.div`
  padding: 36px 25px;
  background: var(--ds-bg-color__static-primary);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.298039);
  border-radius: 2px;
  position: relative;
  margin-bottom: 1em;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  h4 {
    text-align: left;
  }

  & > div {
    display: flex;

    button:first-of-type {
      margin-right: 8px;
    }
  }
`;

export { Card };
