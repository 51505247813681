import styled from 'styled-components';

const TextBox = styled.div`
  overflow-y: auto;
  height: 118px;
  margin-bottom: 32px;
  .text-right {
    padding: 10px;
    text-align: left;
  }

  p {
    line-height: 30px;
    margin: 0 !important;
  }

  a {
    display: inline !important;
  }

  @media (max-width: 1024px) {
    height: 170px;
  }

  @media (max-width: 768px) {
    height: 107px;

    p {
      line-height: 27px !important;
    }

    a {
      height: fit-content;
      width: fit-content;
      text-align: center;
    }
  }
`;

TextBox.displayName = 'TextBox';

export default TextBox;
