import PropTypes from 'prop-types';
import React from 'react';
import StyledTile from './styles/StyledTile';
import { userEventTracking } from '../../../shared/helpers/tracking';

const Tile = ({
  description,
  icon,
  title,
  secondaryButton,
  links,
  className = '',
}) => {
  const handleOnClick = (link) => {
    if (link.event) {
      userEventTracking(link.link, link.event);
    }
  };

  return (
    <StyledTile className={className}>
      <h5 className='ds-typography__h5 text-center'>{title}</h5>
      <div className='tile-icon'>{icon}</div>
      <p className='ds-typography__body description'>{description}</p>
      {secondaryButton && (
        <a
          href={secondaryButton.link}
          onClick={() => handleOnClick(secondaryButton)}
          className='ds-button__secondary link-row row-button'
          rel='noreferrer'
          target={secondaryButton.target || '_blank'}
        >
          {secondaryButton.text}
        </a>
      )}
      {links &&
        links.map((link) => (
          <a
            className='link-row'
            key={link.text}
            onClick={() => handleOnClick(link)}
            href={link.link}
            rel='noreferrer'
            target='_blank'
          >
            {link.text}
          </a>
        ))}
    </StyledTile>
  );
};

Tile.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  links: PropTypes.array,
  secondaryButton: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default Tile;
