import ApiService from '../../services/ApiService';
import { Dispatch } from 'redux';
import { getAuthToken } from '../../utils/getAuthToken';
import { keysToSnake } from '../../utils/keysConverter';

export const updateBillingProfile =
  () => async (dispatch: Dispatch, getState: () => any) => {
    const apiService = new ApiService(
      '/api/v1/instructors/personal_billing_information/save_record'
    );

    const params = getState().instructorProfile.personalData;

    const data = {
      authenticity_token: getAuthToken(),
      personal_billing_information: keysToSnake(params),
    };

    try {
      const result = await apiService.makePostCall(data);
      dispatch({
        data: result.data,
        type: 'InstructorProfile.UPDATE_PERSONAL_INFORMATION_SUCCESS',
      });
    } catch (error) {
      dispatch({
        errors: error.errors,
        type: 'InstructorProfile.SAVE_PERSONAL_DATA_FAILURE',
      });
    }
  };

export const handleClose = () => ({
  type: 'InstructorProfile.HANDLE_MODAL_CLOSE_AFTER_UPDATE',
});
