export const constants = {
  ADD_NOTES: 'studentActivity.ADD_NOTES',
  GET_REQUIRED_DATA: 'studentActivity.GET_REQUIRED_DATA',
  INACTIVE_USER_TYPES: ['frozen', 'canceled', 'lead'],
  NOTES_AFTER_COURSE_START:
    'You’ll see your notes here after the start of your course/program.',
  NOTES_TAB: 'My notes',
  NO_NOTES: 'You haven’t written any notes yet. Try writing one now!',
  NO_NOTES_FOUND:
    'We couldn’t find any matches for your search. Try entering another keyword.',
  NO_SUBMISSIONS_MESSAGE:
    'You haven’t submitted any tasks yet. Try submitting one soon!',
  PASSED_COURSE_END_DATE_STATE: 'out of time',
  SET_NOTES_DATA: 'studentActivity.SET_NOTES_DATA',
  SET_NOTES_LOADER: 'studentActivity.SET_NOTES_LOADER',
  SET_REQUIRED_DATA: 'studentActivity.SET_REQUIRED_DATA',
  SET_SELECTED_COURSE_ID: 'studentActivity.SET_SELECTED_COURSE_ID',
  SET_USER_ID: 'studentActivity.SET_USER_ID',
  SUBMISSIONS_AFTER_COURSE_START:
    'You’ll see your submissions here after the start of your course/program.',
  SUBMISSIONS_TAB: 'My task submissions',
  TITLE: 'My activity',
};
