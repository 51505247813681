import React, { useEffect, useState } from 'react';
import CallFeedbackContainer from './components/CallFeedbackContainer';
import PropTypes from 'prop-types';
import { getData } from './actions';

const CallFeedback = ({ attendeeId, callId }) => {
  const [loading, setLoading] = useState(true);
  const [calls, setCalls] = useState([]);
  const [selectedCall, setSelectedCall] = useState({});

  useEffect(() => {
    getData(attendeeId).then((res) => {
      if (callId) {
        const call = res.callsForReview.find(
          (call) => call.callId === parseInt(callId)
        );
        call ? setSelectedCall(call) : setSelectedCall({});
      }
      setCalls(res.callsForReview.filter((call) => call.state === 'open'));
      setLoading(false);
    });
  }, []);

  return (
    <CallFeedbackContainer
      attendeeId={attendeeId}
      calls={calls}
      setCalls={setCalls}
      selectedCall={selectedCall}
      setSelectedCall={setSelectedCall}
      loading={loading}
      modal={!!callId}
    />
  );
};

CallFeedback.propTypes = {
  attendeeId: PropTypes.number.isRequired,
  callId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default CallFeedback;
