import React from 'react';
import { Body } from '@careerfoundry/bubble';
import { MessageWrapper } from './styles';

interface IProps {
  message: string;
  messageType: string;
}

const Message: React.FC<IProps> = ({ message, messageType }) => (
  <MessageWrapper messageType={messageType} className='MessageWrapper'>
    <Body tag='p' variant='small'>
      {message}
    </Body>
  </MessageWrapper>
);

export default Message;
