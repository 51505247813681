import styled from 'styled-components';

const StyledMediaResourcePreview = styled.div`
  box-shadow: 0px 4px 6px 0px #223c5029;
  border-radius: 4px;

  padding: 32px;
  margin-top: 25px;
  margin-bottom: 25px;

  h4 {
    display: inline-block;
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 400 !important;
  }

  a {
    margin: auto;
    font-size: 16px;
  }

  .sub-header {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 30px;
  }
`;

StyledMediaResourcePreview.displayName = 'StyledMediaResourcePreview';

export default StyledMediaResourcePreview;
