import styled from 'styled-components';

const StyledFloatingInput = styled.div`
  width: 269px;
  height: 84px;
  padding: 13px 12px 13px 4px;
  border-radius: 4px;
  box-shadow: 0 8px 12px 0 rgba(48, 49, 51, 0.1);
  border: solid 1px rgba(167, 177, 185, 0.4);
  background-color: #fff;
  position: absolute;
  top: 24px;
  padding: 13px 12px;
  right: -10px;
  z-index: 99;

  input {
    width: 245px;
    height: 32px;
    margin: 0 0 6px;
    padding: 3px 20px 5px 9px;
    border-radius: 4px;
    border: solid 2px var(--ds-color__grey--400);
    font-size: 16px;
    font-weight: 300;
    color: var(--ds-color__indigo);
    overflow: auto;
    text-overflow: ellipsis;
  }
  p {
    font-size: 11px;
    color: var(--ds-color__grey--400);
    text-align: right;
    font-style: italic;
    font-weight: 300;
  }
  i {
    margin: 4px 0;
    transform: rotate(80deg);
    font-size: 16px !important;
    position: absolute;
    right: 12px;
    top: 18px;
    font-weight: 500;
  }
  .wrapper {
    display: flex;
    align-items: initial;
  }
  ${({ isError }) =>
    isError &&
    `
    input {
      border: solid 2px var(--ds-color_error);
      outline: none;
    }
    i, p {
      color: var(--ds-color_error) !important;
    }
    `
}
@media (max-width: 768px) {
  right: 0%;
  width: 200px;
}

`;

StyledFloatingInput.displayName = 'StyledFloatingInput';

export default StyledFloatingInput;
