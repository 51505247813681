import axios from 'axios';

const ENROLL_CHECKOUT_BACKEND_URL = '/en/enroll/checkout.json';
const PAYPAL_ORDER_CREATE_URL = '/en/enroll/paypal_order.json';
const PAYPAL_SUBSCRIPTION_CREATE_URL = '/en/enroll/paypal_subscription.json';

let COURSE_EXTENSIONS_CHECKOUT_BACKEND_PAYPAL_URL = null;
let COURSE_EXTENSIONS_CHECKOUT_BACKEND_STRIPE_URL = null;
let SEPA_ONE_TIME_PAYMENT_URL = null;

let courseExtensionsSlug = null;
let oneTimePaymentSlug = null;

if (window.location.href.match(/course_extensions\/([^/]+)/)) {
  courseExtensionsSlug = window.location.href.match(/course_extensions\/([^/]+)/)[1];
  COURSE_EXTENSIONS_CHECKOUT_BACKEND_STRIPE_URL = `/en/course_extensions/${courseExtensionsSlug}/checkout.json`;
  COURSE_EXTENSIONS_CHECKOUT_BACKEND_PAYPAL_URL = `/en/course_extensions/${courseExtensionsSlug}/paypal_order.json`;
} else {
  COURSE_EXTENSIONS_CHECKOUT_BACKEND_STRIPE_URL = '/en/course_extensions/WzEsbnVsbF0=/checkout.json';
  COURSE_EXTENSIONS_CHECKOUT_BACKEND_PAYPAL_URL = '/en/course_extensions/WzEsbnVsbF0=/paypal_order.json';
}

if (window.location.href.match(/sepa\/([^/]+)/)) {
  oneTimePaymentSlug = window.location.href.match(/sepa\/([^/]+)/)[1];
  SEPA_ONE_TIME_PAYMENT_URL = `/en/one_time_payments/sepa/${oneTimePaymentSlug}/checkout`;
}

class CheckoutService {
  constructor (authToken, checkoutForComponent) {
    this.authToken = authToken;
    this.formData = {};

    this.thirdPartyCheckout = this.thirdPartyCheckout.bind(this);
    this.stripeCheckout = this.stripeCheckout.bind(this);
    this.stripeResponseHandler = this.stripeResponseHandler.bind(this);
    this.callStripe = this.callStripe.bind(this);
    this.setFormData = this.setFormData.bind(this);
    this.existingBillingProfileCheckoutHandler = this.existingBillingProfileCheckoutHandler.bind(this);
    this.checkoutBackendDriver = this.checkoutBackendDriver.bind(this);
    this.checkoutForComponent = this.checkoutUrlForComponentHandler.bind(this);
    this.checkout = this.checkout.bind(this);
    this.checkoutForComponent = checkoutForComponent;
  }

  checkoutUrlForComponentHandler (checkoutForComponent) {
    switch (checkoutForComponent) {
    case 'enroll':
      if (this.formData.provider === 'paypal') {
        return this.formData.isSubscription ? PAYPAL_SUBSCRIPTION_CREATE_URL : PAYPAL_ORDER_CREATE_URL;
      } else {
        return ENROLL_CHECKOUT_BACKEND_URL;
      }
    case 'course_extensions':
      if (this.formData.provider === 'paypal') { return COURSE_EXTENSIONS_CHECKOUT_BACKEND_PAYPAL_URL; } else { return COURSE_EXTENSIONS_CHECKOUT_BACKEND_STRIPE_URL; }
    case 'oneTimePayment':
      return SEPA_ONE_TIME_PAYMENT_URL;
    default:
      throw new Error('Must pass the checkout component');
    }
  }

  checkout (params, start, discountCode, offerCode, plan, provider, b2b, productSku, paymentMethod) {
    if (provider === 'stripe') {
      return this.stripeCheckout(params, start, discountCode, offerCode, plan, provider, b2b, productSku, paymentMethod);
    } else {
      return this.thirdPartyCheckout(params, start, discountCode, offerCode, plan, provider, b2b, productSku);
    }
  };

  thirdPartyCheckout (params, start, discountCode, offerCode, plan, provider, b2b, productSku) {
    this.setFormData(params, start, discountCode, offerCode, plan, provider, b2b, productSku);
    return new Promise((resolve, reject) => {
      this.checkoutBackendDriver(resolve, reject);
    });
  }

  stripeCheckout (params, start, discountCode, offerCode, plan, provider, b2b, productSku, paymentMethod) {
    this.setFormData(params, start, discountCode, offerCode, plan, provider, b2b, productSku);
    const me = this;

    return new Promise((resolve, reject) => {
      this.callStripe(params, paymentMethod)
        .then(response => {
          this.stripeResponseHandler(response, paymentMethod)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              const errorObject = error.error.message;
              if (errorObject && errorObject.requires_action) {
                params.stripe.handleCardAction(
                  errorObject.payment_intent_client_secret,
                ).then(function (result) {
                  me.stripeResponseHandler(result)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
                }).catch(error => reject(error));
              } else {
                reject(error);
              }
            });
        })
        .catch(error => reject(error));
    });
  }

  callStripe (params, paymentMethod) {
    const { stripe, cardElement, nameOnCard, email } = params;
    const formattedPaymentMethod = paymentMethod === 'sepa' ? 'sepa_debit' : 'card';
    return new Promise((resolve, reject) => {
      stripe.createPaymentMethod(formattedPaymentMethod, cardElement, {
        billing_details: {
          email: email,
          name: nameOnCard,
        },
      }).then(function (result) {
        if (result.error) {
          reject({ data: null, error: result.error.message, errorType: result.error.param });
        } else {
          resolve(result);
        }
      }).catch(error => reject(error));
    });
  }

  stripeResponseHandler (result, paymentMethod) {
    let additionalParams;
    if (result.paymentMethod) {
      const paymentMethodId = result.paymentMethod.id;
      additionalParams = {
        payment_method: paymentMethod,
        payment_method_id: paymentMethodId,
      };
    }

    if (result.paymentIntent) {
      const paymentIntentId = result.paymentIntent.id;
      additionalParams = {
        payment_intent_id: paymentIntentId,
        payment_method: paymentMethod,
      };
    }

    return new Promise((resolve, reject) => {
      this.checkoutBackendDriver(resolve, reject, additionalParams);
    });
  }

  existingBillingProfileCheckoutHandler (data, start, discountCode, offerCode, plan, provider, isB2B, productSku) {
    this.setFormData(data, null, null, null, null, provider, isB2B, productSku);
    return new Promise((resolve, reject) => {
      if (provider === 'stripe' || provider === 'paypal') {
        this.checkoutBackendDriver(resolve, reject);
      } else {
        throw new Error('Invalid payment provider');
      }
    });
  }

  getPostDataObject (extraParamsObj) {
    return {
      authenticity_token: this.authToken,
      name: this.formData.name,
      business_name: this.formData.businessName,
      address_line1: this.formData.address_line1,
      address_city: this.formData.address_city,
      address_zip: this.formData.address_zip,
      address_country: this.formData.address_country,
      vat: this.formData.vat,
      provider: this.formData.provider,
      discount_code: this.formData.discount_code,
      offer_code: this.formData.offer_code,
      start_date: this.formData.start,
      plan: this.formData.plan,
      b2b: this.formData.b2b,
      product_sku: this.formData.productSku,
      paypalOrderData: this.formData.paypalOrderData,
      ...extraParamsObj,
    };
  }

  checkoutBackendDriver (resolve, reject, extraParamsObj) {
    const data = this.getPostDataObject(extraParamsObj);

    axios.post(this.checkoutUrlForComponentHandler(this.checkoutForComponent), data).then((response) => {
      resolve({ data: response.data, error: null });
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        reject({ data: null, error: error.response.data.errors, errorType: error.response.data.errors.key });
      } else {
        reject({ data: null, error: 'Unknown error occurred', errorType: 'Unknown' });
      }
    });
  }

  setFormData (params, start, discountCode, offerCode, plan, provider, b2b, productSku) {
    this.formData = {
      name: params.billingName,
      businessName: params.businessName,
      nameOnCard: params.nameOnCard,
      number: params.cardNumber,
      cvc: params.cvc,
      exp_month: params.expMonth,
      exp_year: params.expYear,
      address_line1: params.street,
      address_city: params.city,
      address_zip: params.zip || params.zipCode,
      address_country: params.countryCode || params.country,
      vat: params.vat,
      discount_code: discountCode,
      offer_code: offerCode,
      start: start,
      plan: plan,
      provider: provider,
      b2b: b2b.toString(),
      productSku: productSku,
      paypalOrderData: params.paypalOrderData,
      isSubscription: params.isSubscription,
    };
  }
}

export default CheckoutService;
