import { Button, TableWrapper } from './styles';
import EmptyState from '../../../../shared/components/EmptyTableState';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../../../../shared/components/Tooltip';
import { emails } from '../../constants';
import { formatOptionForSelect } from '../../../../../utils/formatOptionForSelect';

const Table = ({ handleDeleteConfirmation, requests, role }) => {
  const tooltipText = `To cancel please email ${emails[role]}`;

  return (
    <>
      <Link to="/en/time-off/new">
        <Button className="ds-button__primary">Submit time off</Button>
      </Link>
      <TableWrapper>
        <table className="ds-table">
          <thead>
            <tr className="ds-table__tr">
              <th className="ds-table__th">Status</th>
              <th className="ds-table__th">Notice date</th>
              <th className="ds-table__th">From</th>
              <th className="ds-table__th">To</th>
              <th className="ds-table__th">Time off type</th>
              <th className="ds-table__th">Action</th>
            </tr>
          </thead>
          <tbody>
            {requests.map((request) => {
              return (
                <tr className="ds-table__tr" key={request.slug}>
                  <td className="ds-table__td">
                    {formatOptionForSelect(request.status)}
                  </td>
                  <td className="ds-table__td">{request.createdAt}</td>
                  <td className="ds-table__td">{request.startDate}</td>
                  <td className="ds-table__td">{request.endDate}</td>
                  <td className="ds-table__td">
                    {formatOptionForSelect(request.reason)}
                  </td>
                  {request.canCancel ? (
                    <td
                      onClick={() => handleDeleteConfirmation(request.slug)}
                      className="ds-table__td cancelable"
                    >
                      Cancel
                    </td>
                  ) : (
                    <td className="ds-table__td">
                      <Tooltip content={tooltipText} place="left">
                        <span className="not-cancelable">Cancel</span>
                      </Tooltip>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        {!requests.length && (
          <EmptyState buttonStyle="secondary" heading="No time off yet" />
        )}
      </TableWrapper>
    </>
  );
};

Table.propTypes = {
  handleDeleteConfirmation: PropTypes.func,
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      canCancel: PropTypes.bool,
      course: PropTypes.string,
      createdAt: PropTypes.string,
      endDate: PropTypes.string,
      reason: PropTypes.string,
      slug: PropTypes.string,
      startDate: PropTypes.string,
      status: PropTypes.string,
    })
  ),
  role: PropTypes.string,
};

export default Table;
