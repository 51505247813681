import React from 'react';

export const GlobeIcon = () => (
  <svg
    width='23'
    height='22'
    viewBox='0 0 23 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.5 0.0817871C5.4795 0.0817871 0.581787 4.9795 0.581787 11C0.581787 17.0204 5.4795 21.9182 11.5 21.9182C17.5204 21.9182 22.4182 17.0204 22.4182 11C22.4182 4.9795 17.5204 0.0817871 11.5 0.0817871ZM11.5 20.0985C9.22535 20.0985 7.14816 19.2532 5.55138 17.8684C5.55866 17.8566 5.56867 17.8466 5.57504 17.8348C7.07811 15.3163 6.13277 13.7777 5.43492 13.0844C5.25113 12.9016 5.04096 12.7351 4.82441 12.5676C4.25849 12.1282 3.88363 11.8379 3.88363 11C3.88363 10.6051 4.10381 10.4504 5.19108 10.0792C5.93898 9.82354 6.78605 9.5342 7.33286 8.82725C8.6021 7.18588 7.2064 4.65469 6.91342 4.16337C6.7633 3.91134 6.60044 3.67478 6.4312 3.44823C7.79871 2.52746 9.43097 1.97791 11.1852 1.91695C11.469 2.39826 11.9376 2.80405 12.398 3.19984C12.791 3.5383 13.4152 3.68024 13.7364 4.08057C13.9202 4.30985 13.831 4.75204 13.8374 5.04137C13.8501 5.61549 14.1977 6.448 15.3696 6.448C15.4214 6.448 15.4751 6.44618 15.5306 6.44254C15.9837 6.41069 17.3976 6.08679 18.8188 5.61731C19.9324 7.12766 20.5985 8.98557 20.5985 11C20.5985 16.0169 16.5169 20.0985 11.5 20.0985ZM14.6972 8.18217C13.2633 8.18217 11.7993 8.86365 11.0551 9.87722C10.5555 10.5578 10.41 11.3448 10.6447 12.0936C11.1133 13.5876 11.1588 14.4683 10.8658 15.4555C10.6056 16.3326 10.5801 17.6046 12.0659 18.2105C12.3461 18.3252 12.6537 18.3834 12.9776 18.3834C14.0821 18.3834 15.3896 17.7001 16.566 16.5073C17.9799 15.0743 18.8115 13.3183 18.7915 11.8079C18.7642 9.77531 16.9654 8.18217 14.6972 8.18217Z'
      fill='#1C7488'
    />
  </svg>
);

export const SearchIcon = () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.834 19.5493L18.0938 14.7992L18.0938 14.7992C18.9931 13.3893 19.4695 11.7511 19.4665 10.0788V10.0788C19.4665 5.17024 15.4873 1.19104 10.5787 1.19104C5.67012 1.19104 1.69092 5.17024 1.69092 10.0788C1.69092 14.9874 5.67012 18.9666 10.5787 18.9666V18.9666V18.9666C12.251 18.9696 13.8892 18.4932 15.2991 17.5939L20.0492 22.3341V22.3341C20.8777 23.0436 22.1245 22.9471 22.834 22.1186C23.4672 21.3792 23.4672 20.2887 22.834 19.5492V19.5493ZM10.5787 15.0165H10.5787C7.85172 15.0165 5.64105 12.8058 5.64105 10.0788C5.64105 7.35184 7.85172 5.14117 10.5787 5.14117C13.3057 5.14117 15.5164 7.35184 15.5164 10.0788V10.0788C15.5164 12.8058 13.3057 15.0165 10.5787 15.0165H10.5787Z'
      fill='#1C7488'
    />
  </svg>
);

export const CopyIcon = () => (
  <svg
    width='23'
    height='22'
    viewBox='0 0 23 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.09554 15.5038H4.29404C3.79657 15.5038 3.39329 15.1005 3.39329 14.603C3.39329 14.1056 3.79657 13.7023 4.29404 13.7023H6.09554V11H4.29404C3.79657 11 3.39329 10.5968 3.39329 10.0993C3.39329 9.60182 3.79657 9.19854 4.29404 9.19854H6.09554V5.59554H0.69104V21.809H14.2023V18.206H6.09554V15.5038ZM7.89704 0.19104V16.4045H22.309V0.19104H7.89704ZM18.706 10.0993H11.5C11.0026 10.0993 10.5993 9.69601 10.5993 9.19854C10.5993 8.70107 11.0026 8.29779 11.5 8.29779H18.706C19.2035 8.29779 19.6068 8.70107 19.6068 9.19854C19.6068 9.69601 19.2035 10.0993 18.706 10.0993ZM18.706 5.59554H11.5C11.0026 5.59554 10.5993 5.19226 10.5993 4.69479C10.5993 4.19732 11.0026 3.79404 11.5 3.79404H18.706C19.2035 3.79404 19.6068 4.19732 19.6068 4.69479C19.6068 5.19226 19.2035 5.59554 18.706 5.59554Z'
      fill='#1C7488'
    />
  </svg>
);

export const InfoIcon = () => (
  <svg
    width='23'
    height='22'
    viewBox='0 0 23 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.5 0.0817871C5.47002 0.0817871 0.581787 4.97002 0.581787 11C0.581787 17.0299 5.47002 21.9182 11.5 21.9182C17.5299 21.9182 22.4182 17.0299 22.4182 11C22.4182 4.97002 17.5299 0.0817871 11.5 0.0817871ZM12.4098 16.4591C12.4098 16.9616 12.0025 17.3689 11.5 17.3689C10.9975 17.3689 10.5901 16.9616 10.5901 16.4591V10.0901C10.5901 9.58763 10.9975 9.18027 11.5 9.18027C12.0025 9.18027 12.4098 9.58763 12.4098 10.0901V16.4591ZM11.5 7.36058C10.7462 7.36058 10.1352 6.74955 10.1352 5.9958C10.1352 5.24206 10.7462 4.63103 11.5 4.63103C12.2537 4.63103 12.8647 5.24206 12.8647 5.9958C12.8647 6.74955 12.2537 7.36058 11.5 7.36058Z'
      fill='#1C7488'
    />
  </svg>
);

export const HomeIcon = () => (
  <svg
    width='23'
    height='22'
    viewBox='0 0 23 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.5884 8.44191L11.5 0.19104L1.41164 8.44191H1.41164C0.956011 8.81614 0.69167 9.37456 0.69104 9.96418V19.8724V19.8724C0.710608 20.9443 1.58263 21.8043 2.65467 21.809H7.89704V16.4045C7.89704 14.4147 9.51016 12.8015 11.5 12.8015C13.4899 12.8015 15.103 14.4147 15.103 16.4045V21.809H20.3454H20.3454C21.4278 21.8041 22.3041 20.9278 22.309 19.8454V9.93716V9.93716C22.3004 9.35703 22.0368 8.81011 21.5884 8.44192L21.5884 8.44191Z'
      fill='#1C7488'
    />
  </svg>
);
