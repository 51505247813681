import CelebrationModal, { Page } from '../shared/components/CelebrationModal';
import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { debounce } from 'lodash';
import { getQueryVariable } from '../../utils/getUrlQueryParams';
import store from '../shared/reduxStore';

const weeklySubmissionGoalContainer = document.querySelector(
  '.WeeklySubmissionGoal'
);
const progressSubmissionsContainer = document.getElementById(
  'ProgressSubmissions'
);
const courseOnboardingTourContainer = document.getElementById(
  'courseOnboardingTour'
);
const wsgCardContainer = document.getElementById('WSG');
const celebrationModalCourseDashboardContainer = document.getElementById(
  'celebration-modal-course-dashboard'
);

if (weeklySubmissionGoalContainer) {
  const WeeklySubmissionGoal = lazy(() => import('./WeeklySubmissionGoal'));
  const weeklySubmissionGoalContainerRoot = createRoot(
    weeklySubmissionGoalContainer
  );

  weeklySubmissionGoalContainerRoot.render(
    <Provider store={store}>
      <Suspense fallback={<div></div>}>
        <WeeklySubmissionGoal
          attendeeId={+weeklySubmissionGoalContainer.dataset.attendee_id}
        />
      </Suspense>
    </Provider>
  );
}

if (progressSubmissionsContainer) {
  const ProgressLine = lazy(() => import('./ProgressLine/containers/App'));
  const progressSubmissionsContainerRoot = createRoot(
    progressSubmissionsContainer
  );

  progressSubmissionsContainerRoot.render(
    <Provider store={store}>
      <Suspense fallback={<div></div>}>
        <ProgressLine
          complete={+progressSubmissionsContainer.dataset.exercises_completed}
          total={+progressSubmissionsContainer.dataset.exercises_count}
          isTestVariant={progressSubmissionsContainer.dataset.istestvariant}
        />
      </Suspense>
    </Provider>
  );
}

if (courseOnboardingTourContainer) {
  const OnboardingTour = lazy(() => import('./OnboardingTour/containers/Tour'));
  const courseOnboardingTourContainerRoot = createRoot(
    courseOnboardingTourContainer
  );

  courseOnboardingTourContainerRoot.render(
    <Provider store={store}>
      <Suspense fallback={<div></div>}>
        <OnboardingTour
          isVariant={
            courseOnboardingTourContainer.dataset.is_variant === 'true'
          }
        />
      </Suspense>
    </Provider>
  );
}

if (wsgCardContainer) {
  const WsgCard = lazy(() => import('./WSG/containers/WsgCard'));
  const wsgCardContainerRoot = createRoot(wsgCardContainer);

  wsgCardContainerRoot.render(
    <Provider store={store}>
      <Suspense fallback={<div></div>}>
        <WsgCard
          attendeeId={+wsgCardContainer.dataset.attendee_id}
          attendeeTargetCompletion={wsgCardContainer.dataset.target_completion}
        />
      </Suspense>
    </Provider>
  );
}

if (celebrationModalCourseDashboardContainer) {
  const celebrationModalCourseDashboardContainerRoot = createRoot(
    celebrationModalCourseDashboardContainer
  );

  celebrationModalCourseDashboardContainerRoot.render(
    <CelebrationModal page={Page.courseDashboard} />
  );
}

const loadFeedbackCard = () => {
  const getDiv =
    window.innerWidth >= 1024 ? '.CallFeedback' : '.CallFeedbackMobile';
  const callFeedback = document.querySelector(getDiv);

  if (callFeedback) {
    const callId = getQueryVariable('id');
    const CallFeedback = lazy(() => import('./CallFeedback'));
    const callFeedbackContainerRoot = createRoot(callFeedback);

    callFeedbackContainerRoot.render(
      <Suspense fallback={<div></div>}>
        <CallFeedback
          attendeeId={+callFeedback.dataset.attendee_id}
          callId={callId}
        />
      </Suspense>
    );
  }
};

window.addEventListener('resize', debounce(loadFeedbackCard, 500));
window.addEventListener('load', loadFeedbackCard);
