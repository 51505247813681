export const imagePreview = () => {
  $('#mediaResourceImage').on('change', function (event) {
    const files = event.target.files;
    const image = files[0];
    const reader = new FileReader();
    reader.onload = function (file) {
      const img = new Image();
      img.src = file.target.result;
      img.width = 447;
      img.height = 268;
      $('#previewTarget').html(img);
    };
    reader.readAsDataURL(image);
  });
};
