var coursePrepCarousel = require('./course_prep_carousel')

module.exports = (() => {
  var init = function(){
    coursePrepCarousel.init();
  };

  return {
    init: init
  };
})();
