import ApiService from '../../services/ApiService';
import { LOAD_RESOURCES_SUCCESS } from './constants';

export const getMediaResources = (industry) => async (dispatch) => {
  const apiService = new ApiService('/api/v1/media_resources');
  try {
    const result = await apiService.makeGetCall({ industry });
    dispatch({
      resources: result.data.data,
      type: LOAD_RESOURCES_SUCCESS,
    });
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};
