import { FormSection, InputWrapper } from '../styles';
import PropTypes from 'prop-types';
import React from 'react';
import SectionHeader from '../../SectionHeader';
import Select from '../../../../../shared/components/Select';
import { paymentFields } from './helpers';

const paymentOptions = ['Payoneer', 'Bank Transfer', 'PayPal'];

const PaymentInputs = ({
  formErrors,
  handleCountryChange,
  handlePaymentMethodChange,
  handleSave,
  personalData,
}) => (
  <FormSection>
    <SectionHeader
      headline="Payment Method"
      tooltipText="Please choose one of the payment methods. Regular transfer fees may apply. Please check with the financial institution/company before you select! If you'd rather be paid via Payoneer, you can request a token and link from talent.instructors@careerfoundry.com"
    />
    <InputWrapper>
      <Select
        name="paymentMethod"
        value={personalData.paymentMethod}
        onChange={(event) => handlePaymentMethodChange(event.target.value)}
        required={true}
      >
        <option value="">Payment method*</option>
        {paymentOptions.map((paymentOption) => (
          <option key={paymentOption} value={paymentOption}>
            {paymentOption}
          </option>
        ))}
      </Select>
    </InputWrapper>
    {paymentFields(personalData, handleSave, handleCountryChange, formErrors)}
  </FormSection>
);

PaymentInputs.propTypes = {
  formErrors: PropTypes.object,
  handleCountryChange: PropTypes.func,
  handlePaymentMethodChange: PropTypes.func,
  handleSave: PropTypes.func,
  personalData: PropTypes.object,
};

export default PaymentInputs;
