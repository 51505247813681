import styled from 'styled-components';

const StyledShowModal = styled.div`
  height: 100%;
  overflow: hidden;

  .row-md {
    flex-basis: 42%;
    max-width: 42%;
    overflow: auto;
    height: 560px;

    .large-label {
      width: 32%;
    }
  }

  .row-lg {
    flex-basis: 55%;
    max-width: 55%;
    padding: 0 77px 0 44px;
    overflow: auto;
    height: 560px;
  }

  @media (max-width: 768px) {
    .row-md {
      flex-basis: 100%;
      max-width: 100%;
      padding: 0 16px;
      overflow: hidden;
      height: 100%;

      .large-label {
        width: 25%;
      }
    }

    .row-lg {
      flex-basis: 100%;
      width: 100%;
      max-width: 100%;
      padding: 0 16px;
      overflow: hidden;
      height: 100%;
    }

    hr {
      margin: 12px 0 14px;
      opacity: 0.5;
      border: solid 1px #c5c8c9;
    }

    .scroll {
      overflow: auto;
      padding: 10px;
      height: 100vh;
    }
  }
`;

StyledShowModal.displayName = 'StyledShowModal';

export default StyledShowModal;
