import Header from '../../components/Header';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  assignmentsPage: {
    studentsListLoaded,
    course,
  },
}) => ({
  startDate: studentsListLoaded ? course.start : 'xxx-xx-xx',
  title: studentsListLoaded ? course.name : 'Data is loading',
});

export default connect(mapStateToProps)(Header);
