import styled from 'styled-components';

const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  a {
    display: inline-block;
    width: fit-content;
    margin-bottom: 6px;
    cursor: pointer;
  }

  p {
    display: inline-block;
    width: fit-content;
    margin-bottom: 6px;
  }

  .red-text {
    color: #ef5353 !important;
  }
`;

StyledTextWrapper.displayName = 'StyledTextWrapper';

export default StyledTextWrapper;
