module.exports = (() => {
  var init = function() {
    _initDataTable();
  }

  var _initDataTable = function() {
    $('.adminIndexRefundsTable--js').DataTable({
      searching: false,
      paging: false,
      info: false
    });
  }

  return {
    init: init
  }
})();
