module.exports = (() => {
  const init = function () {
    const checkboxes = document.querySelectorAll('input[type=checkbox]');
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].addEventListener('change', function () {
        const startDateId = this.id.replace('course_start_date_', '');

        $.ajax({
          data: { enabled: this.checked },
          type: 'PATCH',
          url: '/admin/course_start_dates/' + startDateId,
        }).fail(function (r) {
          CareerFoundyCoach.flashMessage(r.message, r.type);
        }).done(function (r) {
          CareerFoundyCoach.flashMessage(r.message, r.type);
        });
      });
    }
  };

  return {
    init: init,
  };
})();
