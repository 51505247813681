module.exports = (() => {
  const init = () => {
    bindFunctions();
    mobileToggleFilters();
    preloadJobDetails();
  };

  const bindFunctions = () => {
    $('.search_filter--js').on('click', buildSearchParams);
    $('.search_filter_dropdown--js').on('change', buildSearchParams);
    $(document).on('click', '.job_description--js:not(.active)', showJobDetail);
    $('.filter-toggle--js').on('click', toggleFilters);
    $('.job_section').on('click', '.close-job-details--js', closeJobDetail);
    $(document).on('click', '.back_to_main--js', backToMain);
  };

  let search_params = {};

  const buildSearchParams = function () {
    $('.search_filter--js').each(function () {
      const key = $(this).data('name');
      checkOrInitializeKey(key);
      if (this.checked) { search_params[key].push($(this).val()); }
    });
    search_params.city = $('.search_filter_dropdown--js').val();
    search();
  };

  const showJobDetail = function () {
    $(this).append(Template.loading_spinner);
    jobDetail($(this).data('id'));
  };

  const jobDetail = function (job_id) {
    $.ajax({
      url: `/en/careerhub/${job_id}/job_detail/`,
      method: 'post',
      dataType: 'script',
      success: function (data) {
        history.pushState(null, null, '?job_id=' + job_id);
      },
    });
  };

  const preloadJobDetails = function () {
    getUrlParameter('job_id') && jobDetail(getUrlParameter('job_id'));
  };

  const backToMain = function () {
    history.pushState(null, null, '?');
    $('.jobs_offer').show();
    $('.search-jobs-filter ').show();
    $('.job_offer_details').hide();
    $('.jobs').addClass('col-md-9');
  };

  const checkOrInitializeKey = function (key) {
    if (!(key in search_params)) { search_params[key] = []; }
  };

  const search = function () {
    $.ajax({
      url: '/en/careerhub/search' + '?' + $.param({
        search: search_params,
      }),
      method: 'get',
      dataType: 'script',
      beforeSend: function () {
      },
    });
    search_params = {};
  };

  const toggleFilters = function () {
    $(this).siblings('.filter-select').slideToggle();
    $('i', this).toggleClass('fa-angle-down fa-angle-up');
  };

  const mobileToggleFilters = function () {
    if (window.innerWidth <= 768) {
      $('.filter-select').hide();
      $('i.fa.fa-angle-down').removeClass('hidden');
      $('i.fa.fa-angle-up').hide();
    };
    $('.search_filter_dropdown--js').select2();
  };

  const closeJobDetail = function (e) {
    e.stopPropagation();
    $('#job_details_card').slideUp(400, function () { $('#job_details_card').remove(); });
    $('.job_description--js').removeClass('active');
    history.pushState(null, null, '?');
  };

  return {
    init: init,
  };
})();
