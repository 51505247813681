import React from 'react';
import { Body } from '@careerfoundry/bubble';
import { StyledTooltipContent } from './styles';

export const TooltipContent1 = () => (
  <StyledTooltipContent>
    <Body tag='p' variant='medium-bold'>
      What does my voucher look like?
      <br />
    </Body>
    <ul>
      <li>
        <Body tag='p' variant='medium-bold'>
          <u>Coming from the Agentur für Arbeit</u>: it’s one page and reads
          “Ausfertigung des Maßnahmeträgers zur Vorlage” at the top
        </Body>
      </li>
      <li>
        <Body tag='p' variant='medium-bold'>
          <u>Coming from the Jobcenter</u>: it’s two pages and reads “BGS
          Trägerausfertigung” in the top-left corner
        </Body>
      </li>
    </ul>
  </StyledTooltipContent>
);

export const TooltipContentInProgress = () => (
  <StyledTooltipContent>
    <Body tag='p' variant='medium-bold'>
      We’re reviewing your upload. We’ll be in touch if anything else is needed.
      (Thanks for your patience!)
    </Body>
  </StyledTooltipContent>
);
