import './styles.scss';
import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const onBoardingPageContainer = document.getElementById('onBoarding');

if (onBoardingPageContainer) {
  const LandingPage = lazy(() => import('./containers/LandingPage'));
  const onBoardingPageContainerRoot = createRoot(onBoardingPageContainer);

  onBoardingPageContainerRoot.render(
    <Provider store={store}>
      <Suspense
        fallback={
          <i className='ds-icon__mail-fill ds-typography__h5 ds-color__grey' />
        }
      >
        <LandingPage />
      </Suspense>
    </Provider>
  );
}
