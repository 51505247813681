import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const checkInitialParams = (initialValue, initialText) => {
  if ((initialValue && !initialText) || (!initialValue && initialText)) {
    throw 'You need to set both Text and Value OR nothing.';
  };
};

const Dropdown = props => {
  const initialValue = props.initialValue ? props.initialValue : '';
  const initialText = props.initialText ? props.initialText : '';
  checkInitialParams(initialValue, initialText);
  const [selected, setValue] = useState({ value: initialValue, text: initialText });
  const [inputWrapperClass, setInputWrapperClass] = useState('');

  useEffect(() => {
    setValue({ value: initialValue, text: initialText });
  }, [props.initialValue, props.initialText]);

  useEffect(() => {
    if (selected.value !== '') {
      props.onChange(selected.value, props.name);
      setFrozen();
    };
  }, [selected]);

  const setFrozen = isFrozen => {
    if (selected.value.length > 0) {
      setInputWrapperClass('cf-form__group--frozen');
    } else {
      setInputWrapperClass('');
    };
  };

  const handleInputChange = e => {
    e.persist();
    if (e.target.value === '') {
      props.resetDropdown ? props.resetDropdown() : '';
    } else {
      setValue({ value: e.target.value, text: e.target.options[e.target.selectedIndex].innerHTML });
    };
  };

  const getDropdownCountries = () => {
    return Object
      // get all the keys
      .keys(CF.countries.listWithCodes)
      // put them in our desired dropdown format
      .map(code => ({ text: CF.countries.listWithCodes[code], value: code }))
      // remove the empty entry as we're adding it in our html
      .filter(item => item.value !== '');
  };

  const options = props.countrySelector ? getDropdownCountries() : props.options;

  return (
    <div className={`cf-form__group--floating ${inputWrapperClass} ${props.isValid ? 'cf-form--validated' : ''}`}>
      <select
        name={props.name}
        className="cf-form__input"
        onChange={e => handleInputChange(e)}
        disabled={props.disabled}
        required={props.required}
        value={selected.value || ''}
      >
        {props.noBlankOption ? null : <option value={null}/>}
        {options.map((item, i) => (
          <option defaultValue={props.initialValue === item.value} value={item.value} key={i}>{item.text}</option>
        ))}
      </select>
      <i className="fa fa-angle-down"/>
      <label className="cf-form__label--floating">{props.labelText}</label>
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  initialText: PropTypes.string,
  resetDropdown: PropTypes.func,
  onChange: PropTypes.func,
  countrySelector: PropTypes.bool,
};

export default Dropdown;
