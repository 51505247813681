import styled from 'styled-components';

const StyledModalBody = styled.div`
  width: 683px;
  height: 457px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 0 20px;
  padding: 16px 32px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);
  background-color: var(--ds-color__white);

  h4 {
    font-size: 28px;
    font-weight: 500;
    line-height: 2;
    margin-bottom: 24px;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    border-radius: 0;
    border-bottom: solid 2px #c5c8c9;
    border-top: solid 2px #c5c8c9;
    box-shadow: none;
    padding: 16px 18px;
  }
`;

StyledModalBody.displayName = 'StyledModalBody';

export default StyledModalBody;
