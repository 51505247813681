import { hasRevisions, isInRevision, totalTaskTime } from '../../helpers';

export const getWeekEstimate = (exercises, order) => {
  const totalReadTime = totalTaskTime('read_time', exercises, order);
  const min = totalReadTime + totalTaskTime('min_task_time', exercises, order);
  const max = totalReadTime + totalTaskTime('max_task_time', exercises, order);
  const additionalTime = hasRevisions(order, exercises, isInRevision)
    ? '+'
    : '';

  return `${min} - ${max} ${additionalTime}hours`;
};
