import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from '../../shared/form';
import Dropdown from '../../shared/dropdown';

const PreselectedPlanView = props => {
  const [ startDate, setStartDate ] = useState('');

  const renderDates = datesArray => {
    switch(datesArray.length) {
      case 0:
        throw "At least one date has to be provided";
      case 1:
        return (
          <span className="startdate-text">{datesArray[0].text}</span>
        );
      default:
        return (
          <Dropdown options={props.datesArray}
                    name="select-start-date"
                    initialText={startDate}
                    initialValue={startDate}
                    onChange={props.onStartChange}
                    noBlankOption
          />
        )
    }
  };

  const renderSubmitButton = () => {
    return (
      <button className="button--large button--primary-petrol"
              id="user-profile-submit">
        Next
      </button>
    );
  };

  const renderSubParagraphText = text => {
    if(text) {
      return <p className="cf-small--bold">{text}</p>;
    }
    return '';
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.onSubmit();
  };

  return (
    <Form onSubmit={handleSubmit}
          className="enroll__slide PreselectedPlanView">
      <h2>Your Plan</h2>
      <p className="sub-heading">{props.mainText}</p>
      {renderSubParagraphText(props.subText)}
      <span className="startdate-heading">{`Your ${props.productType} will start on:`}</span>
      {renderDates(props.datesArray)}
      <footer>
        {renderSubmitButton()}
      </footer>
    </Form>
  )
};

PreselectedPlanView.propTypes = {
  datesArray: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onStartChange: PropTypes.func,
  mainText: PropTypes.string.isRequired,
  subText: PropTypes.string,
  enrollType: PropTypes.string.isRequired
};

export default PreselectedPlanView;
