import { constants } from '../CareerSupportCenter/constants';

export const showECModal = (status = 'optin') => ({
  status,
  type: constants.SHOW_EMPLOYER_CONNECTOR_MODAL,
});

export const cancelECModal = () => ({
  type: constants.CLOSE_EMPLOYER_CONNECTOR_MODAL,
});

export const updateECProfile = (data) => ({
  data,
  type: constants.UPDATE_EC_PROFILE,
});

export const setOptinModalStep = (step) => ({
  step,
  type: constants.SET_OPTIN_MODAL_STEP,
});

export const reverseProfileEdit = () => ({
  type: constants.REVERSE_PROFILE_UPDATE,
});
