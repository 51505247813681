import { heading, text } from './helpers';
import PropTypes from 'prop-types';
import React from 'react';

const ModalContent = ({ handleClose, callStatus }) => (
  <main className='cf-text-center'>
    <h3 className='ds-typography__h3'>{heading(callStatus)}</h3>
    <p className='ds-typography__body'>{text(callStatus)}</p>
    <button className='ds-button__primary' onClick={() => handleClose()}>
      Close
    </button>
  </main>
);

ModalContent.propTypes = {
  callStatus: PropTypes.string,
  handleClose: PropTypes.func,
};

export default ModalContent;
