import JobApplicationsDetails from '../../../../CareerSupportCenter/components/CareerSupportCenterModal/JobApplicationDetails';
import PropTypes from 'prop-types';
import React from 'react';
import TheModal from '../../../../shared/components/TheModal';

const JobApplicationModal = ({
  handleCloseModal,
  modalAction,
  modalIsOpen,
  isMentor,
  jobApplication,
}) =>
  modalIsOpen && (
    <>
      {
        {
          viewApplication: (
            <TheModal handleClose={handleCloseModal} isModalOpen={modalIsOpen}>
              <JobApplicationsDetails
                jobApplication={jobApplication}
                isMentor={isMentor}
              />
            </TheModal>
          ),
        }[modalAction]
      }
    </>
  );

JobApplicationModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  isMentor: PropTypes.bool.isRequired,
  jobApplication: PropTypes.object.isRequired,
  modalAction: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

export default JobApplicationModal;
