import styled from 'styled-components';

const StyledSidebar = styled.div`
  position: sticky;
  top: 78px;

  & > div:first-of-type {
    height: 87vh;
    display: flex;
    flex-direction: column;
  }

  footer {
    margin-top: auto;

    p {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1022px) {
    & > div:first-of-type {
      height: auto;
    }
  }
`;

StyledSidebar.displayName = 'Sidebar';

const Progress = styled.div`
  margin-top: 90px;
  margin-bottom: 200px;

  @media (max-width: 1022px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

Progress.displayName = 'Progress';

const SectionLink = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8ebed;
  margin: 0 -32px;
  padding: 0 30px;
  height: 57px;
  border-left: ${(props) =>
    props.active && '8px solid var(--ds-color__petrol--medium)'};
  padding-left: ${(props) => props.active && '22px'};
  cursor: pointer;

  p {
    margin-bottom: 0;
    display: inline-block;
    line-height: 1;
  }

  .warning {
    text-transform: uppercase;
    margin-left: 12px;
    font-size: 10px;
  }

  .pending {
    color: var(--ds-color__petrol--medium);
  }

  .changes-requested {
    color: var(--ds-color_error);
  }

  .completed-section {
    font-size: 14px;
    color: var(--ds-color__petrol);
  }
`;

SectionLink.displayName = 'SectionLink';

const TabName = styled.p`
  color: ${(props) =>
    props.active
      ? 'var(--ds-color__indigo)'
      : 'rgba(34, 60, 80, 0.75)'} !important;
`;

TabName.displayName = 'TabName';

export { StyledSidebar, Progress, SectionLink, TabName };
