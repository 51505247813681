import ProfessionalStatement from '../../../../../EmployerConnector/modals/showModal/ProfessionalStatement';
import React from 'react';
import { connect } from 'react-redux';
import { updateECProfile } from '../../../../../EmployerConnector/actions';

const AdminProfessionalStatementViewContainer = (props) => {
  return (
    <ProfessionalStatement {...props} />
  );
};

export const mapStateToProps = ({
  adminEmployerConnector: { jobSeekingProfile },
}) => ({
  jobSeekingProfile,
});

export const mapDispatchToProps = (dispatch) => ({
  updateECProfile: (data) => dispatch(updateECProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfessionalStatementViewContainer);
