import PropTypes from 'prop-types';
import React from 'react';
import StyledQuestionGuidelines from './styles/StyledQuestionInsights';

const QuestionInsights = ({
  buttonAction,
  insights,
  question,
}) => (
  <StyledQuestionGuidelines>
    <h3 className="ds-typography__h3 text-left">{question}</h3>
    <p className="ds-typography__body">{insights}</p>
    <div className="button-section">
      <a onClick={() => buttonAction()} className="ds-button__secondary text-center">Okay</a>
    </div>
  </StyledQuestionGuidelines>
);

QuestionInsights.propTypes = {
  buttonAction: PropTypes.func.isRequired,
  insights: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
};

export default QuestionInsights;
