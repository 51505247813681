import DoubleParagraphModal from '../shared/DoubleParagraphModal';
import PropTypes from 'prop-types';
import React from 'react';

const WelcomeModal = ({ onClick }) => {
  return (
    <DoubleParagraphModal
      onClick={onClick}
      firstParagraph="Employer Connector connects applicants with CareerFoundry partners
        interested in hiring our graduates. You provide your application
        package for companies to view exclusively on our platform. If an
        employer decides that you could be a good fit for a role, they will
        start a conversation with you via the CF platform."
      secondParagraph="To add your profile to our applicant pool, you’ll need to check that your personal data is up to date and provide an application package for employers to view. The more complete your profile is, the higher your chance of being noticed by potential employers.
        Click “Start” to create your Employer Connector profile."
      header="Welcome to Employer Connector!"
      buttonLabel="Start"
    />

  );
};

WelcomeModal.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default WelcomeModal;
