import {
  getCallNotes,
  openNewNoteModal,
  openOffPlatformCallModal,
} from '../../action';
import CallNotesLists from '../../components/CallNotesLists';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  mentorCallNotes: {
    currentCallId,
    calendarEntries,
    calendarEntriesPagination,
    flashMessage,
    showFlash,
    reloadCalendarEntries,
  },
  currentExerciseCta: { attendeesData },
}) => ({
  activeCourse: attendeesData?.activeCourse,
  calendarEntries,
  calendarEntriesPagination,
  currentCallId,
  flashMessage,
  reloadCalendarEntries,
  showFlash,
});

export const mapDispatchToProps = (dispatch) => ({
  getCallNotes: (currentPage) => dispatch(getCallNotes(currentPage)),
  openNewNoteModal: (callId, canSubmitNote) =>
    dispatch(openNewNoteModal(callId, canSubmitNote)),
  openOffPlatformCallModal: () => dispatch(openOffPlatformCallModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallNotesLists);
