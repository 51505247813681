import React, { useEffect, useState } from 'react';
import InputHeader from './InputHeader';
import NumberCheckInput from './NumberCheckInput';
import PropTypes from 'prop-types';
import StyledGroupedNumberCheckInput from '../styles/StyledGroupedNumberCheckInput';

const GroupedNumberCheckInput = ({
  label,
  onInputChange,
  id,
  prevSelected,
  viewType,
}) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(prevSelected);
  }, []);

  useEffect(() => {
    if (typeof onInputChange === 'function') {
      onInputChange({ data: selected, id });
    }
  }, [selected]);

  const handleClick = (num) => {
    if (selected === num) {
      setSelected(null);
    } else {
      setSelected(num);
    }
  };

  return (
    <>
      <InputHeader isView={viewType === 'view'} label={label} />
      <StyledGroupedNumberCheckInput>
        {Array(10)
          .fill(0)
          .map((num, i) => {
            const current = i + 1;
            return (
              <NumberCheckInput
                isSelected={selected === current}
                key={current}
                label={current}
                onClick={handleClick}
                viewType={viewType}
              />
            );
          })}
      </StyledGroupedNumberCheckInput>
    </>
  );
};

GroupedNumberCheckInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  prevSelected: PropTypes.number,
  viewType: PropTypes.string,
};

export default GroupedNumberCheckInput;
