import {
  createJobApplication,
  handleAttachmentUpdate,
  handleDeleteJobApplication,
  setFormData,
  updateJobApplication,
} from '../../actions';
import JobApplicationForm from '../../components/CareerSupportCenterModal/JobApplicationForm';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  careerSupportCenter: {
    modalAction,
    modalIsOpen,
    formAction,
    formData,
    submitting,
  },
}) => ({
  formAction,
  formData,
  modalAction,
  modalIsOpen,
  submitting,
});

export const mapDispatchToProps = dispatch => ({
  handleAttachmentUpdate: data => dispatch(handleAttachmentUpdate(data)),
  handleCreate: data => dispatch(createJobApplication(data)),
  handleDeleteJobApplication: id => dispatch(handleDeleteJobApplication(id)),
  handleUpdate: data => dispatch(updateJobApplication(data)),
  setFormData: tempData => dispatch(setFormData(tempData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobApplicationForm);
