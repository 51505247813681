// temporary helper function to keep names short until backend does this for me

export const programNameShortner = fullname => {
    if (!fullname) return '';
    const uxCertified = 'Certified UX';
    const webdev = 'Web Dev';
    const uxOld = 'UX Design';
    const programArray = [uxCertified, webdev, uxOld];
    const shortName = programArray.filter(item => fullname.includes(item))[0];
    return shortName ? shortName : fullname;
};

export const courseNameShortner = fullname => {
    if(!fullname) return '';
    const uxDesign = 'UX Design';
    const webdev = 'Web Dev';
    const uxFundamentals = 'Fundamentals';
    const uiDesign = 'UI Design';
    const coursesArray = [uxDesign, webdev, uxFundamentals, uiDesign];
    const shortName = coursesArray.filter(item => fullname.includes(item))[0];
    if(shortName === uxDesign) return 'Immersion';
    return shortName ? shortName : fullname;
};
