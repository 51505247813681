import React, { Component } from 'react';
import TermsCheckbox from '../../shared/termsCheckbox';
import { getPasswordScore } from '../../../utils/insertZXCVBNScript';

class SignupForm extends Component {
  constructor(props){
    super(props)

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordStrengthScore: {
        score: 0,
        textLabel: 'At least 8 characters',
      },
    }
  };

  componentDidMount(){
    const form = new CF.Form('#enroll__user_register');
    this.setCheckbox();
    window.insertZXCVBNScript();
  };

  setCheckbox = () => this.props.courseParams.terms_required ? document.getElementById('terms__checkbox').checked = false : '';

  handleSubmit = event => {
    event.preventDefault();
    let productSku = this.props.courseParams.product_sku;
    let agreedToTerms = this.props.agreedToTerms;
    let { firstName, lastName, email, password } = this.state;
    let utms = {source_page: 'Enroll Page',
                form_id: document.getElementsByName('form_id')[0].value,
                en_form_utm_campaign: document.getElementsByName('form_utm_campaign')[0].value,
                en_form_utm_source: document.getElementsByName('form_utm_source')[0].value,
                en_form_utm_medium: document.getElementsByName('form_utm_medium')[0].value,
                en_form_utm_content: document.getElementsByName('form_utm_content')[0].value,
                en_form_utm_term: document.getElementsByName('form_utm_term')[0].value,
                popup_tracking: document.getElementsByName('popup_tracking')[0].value}
    this.props.userSignup(firstName, lastName, email, password, productSku, agreedToTerms, utms);
  }

  onChange = event => {
    if(event.target.name == "terms__checkbox") {
      this.props.onTermsClick(event.target.checked);
    }else{
      const state = this.state;
      state[event.target.name] = event.target.value;
      if(event.target.name === 'password') {
        const { score, textLabel } = getPasswordScore(event.target.value);
        state.passwordStrengthScore = {
          score: score,
          textLabel: textLabel,
        }
      }
      this.setState(state);
    }
  };

  togglePasswordVisibility = _ => {
    const passwordField = document.querySelector('#new-password');
    if (passwordField.type === 'text') {
      passwordField.type = 'password';
    } else {
      passwordField.type = 'text';
    }
  }

  render(){
    let toggleButtonClass = this.props.agreedToTerms ? "button--primary-petrol" : "button--primary-disabled";

    const renderButton = () => {
      if(this.props.loadingState) {
        return(<button disabled={true} className="button--large button--primary-petrol" dangerouslySetInnerHTML={{__html: CF.html.loadingSpinner}}></button>);
      }else{
        return(<button type="submit" disabled={!this.props.agreedToTerms} className={toggleButtonClass}>Create account</button>);
      }
    };

    return(
      <div className="enroll__signup__form enroll__form enroll__slide">
        <h2>Create a student account</h2>
        <form className="cf-form" id="enroll__user_register" onSubmit={this.handleSubmit}>
          <input type="hidden" name="form_utm_campaign" />
          <input type="hidden" name="form_utm_source" />
          <input type="hidden" name="form_utm_medium" />
          <input type="hidden" name="form_utm_content" />
          <input type="hidden" name="form_utm_term" />
          <input type="hidden" name="popup_tracking" />
          <input type="hidden" name="form_id" value="99ec119f-a878-4054-a657-9b156b4fd20d" />
          <div className="cf-form__group--floating">
            <input onChange={this.onChange} type="text" name="firstName" required="required" className="cf-form__input" />
            <label className="cf-form__label--floating">First Name (Official)</label>
            <div className="cf-form__validation"></div>
          </div>
          <div className="cf-form__group--floating">
            <input onChange={this.onChange} type="text" name="lastName" required="required" className="cf-form__input" />
            <label className="cf-form__label--floating">Last Name (Official)</label>
            <div className="cf-form__validation"></div>
          </div>
          <div className={`cf-form__group--floating ${(this.state.email.length > 0) ? 'cf-form__group--frozen' : ''} ${(this.props.errorType == "email") ? "with__errors" : ""}`}>
            <input onChange={this.onChange} type="email" name="email" required="required" className="cf-form__input" />
            <label className="cf-form__label--floating">Email Address</label>
            <div className="cf-form__validation"></div>
          </div>
          <div className={`cf-form__group--floating ${(this.state.password.length > 0) ? 'cf-form__group--frozen' : ''} ${(this.props.errorType == "password") ? "with__errors" : ""}`}>
            <input onChange={this.onChange} type="password" name="password" required="required" className="cf-form__input" autoComplete="new-password" id="new-password" />
            <label className="cf-form__label--floating">Password</label>
            <div className="cf-form__validation"></div>
            <div className="password-strength-indicator__wrapper">
              <progress
                className="strength-meter"
                max="4"
                value={this.state.passwordStrengthScore.score}
              ></progress>
              <div className="static-label">Password<br/>Strength</div>
              <span className="label-text">{this.state.passwordStrengthScore.textLabel}</span>
            </div>
            <i
              className="ds-icon__eye toggle-password"
              id="toggle-password"
              onClick={this.togglePasswordVisibility}></i>
            <div className="error__container">
              <p className="cf-small cf-text-right" dangerouslySetInnerHTML={{__html: this.props.displayErrorMessage}}></p>
            </div>
          </div>
          <TermsCheckbox name="terms__checkbox"
                         id="terms__checkbox"
                         onChange={this.onChange}
                         termsRequired={this.props.courseParams.terms_required} />
          {renderButton()}
        </form>
        <p className="cf-small cf-text-center">Already registered? <a onClick={this.props.onLoginClick}>Login</a></p>
      </div>
    )
  }
};

export default SignupForm;
