import { ButtonWrapper, Section, Valid } from './styles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const estimationOptions = [
  '<1 hour',
  '1-3 hours',
  '4-6 hours',
  '7-9 hours',
  '10+ hours',
];

const TimeTaken = ({ estimationOptionSelected, taskTimeEstimationSaved }) => {
  const [timeEstimationButtonSelected, setTimeEstimationButtonSelected] =
    useState(estimationOptions.map(() => false));

  const handleClick = (option, index) => {
    estimationOptionSelected(option);
    setTimeEstimationButtonSelected(
      estimationOptions.map((b, i) => index === i)
    );
  };

  return (
    <Section>
      <p className='ds-text__body--medium ds-text--bold'>
        1. How much time did you spend on this task?
      </p>
      {taskTimeEstimationSaved && (
        <Valid
          className='ds-icon__check'
          taskTimeEstimationSaved={taskTimeEstimationSaved}
        />
      )}
      <ButtonWrapper>
        {estimationOptions.map((option, index) => (
          <button
            key={option}
            className='ds-button__secondary task_estimation_modal__btn'
            disabled={timeEstimationButtonSelected[index]}
            onClick={() => handleClick(option, index)}
          >
            {option}
          </button>
        ))}
      </ButtonWrapper>
    </Section>
  );
};

TimeTaken.propTypes = {
  estimationOptionSelected: PropTypes.func,
  taskTimeEstimationSaved: PropTypes.bool,
};

export default TimeTaken;
