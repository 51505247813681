import App from '../../components/App.js';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const AppContainer = ({ modalIsOpen }) => {
  return (
    <App modalIsOpen={modalIsOpen} />
  );
};

AppContainer.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
};

export const mapStateToProps = ({
  adminEmployerConnector: {
    modalIsOpen,
  },
}) => ({
  modalIsOpen,
});

export default connect(mapStateToProps, null)(AppContainer);
