import styled from 'styled-components';

const StyledLink = styled.a`
  display: inline-block;
  margin: 40px 0 50px;
  padding: 9px 12px 7px;
  height: fit-content;
  max-width: fit-content;
  text-decoration: none !important;

  i {
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    max-width: fit-content;
  }

  @media (min-width: 768px) {
    max-width: none;
  }
  
  @media (max-width: 479px) {
    margin: 24px 0 20px;
  }
`;

StyledLink.displayName = 'StyledLink';

export default StyledLink;
