import AddressFields from './AddressFields';
import { CategoryHeading } from '../styles';
import LoadingSpinner from '../../../../../shared/loadingSpinner.jsx';
import NameFields from './NameFields';
import PaymentFields from './PaymentFields';
import PropTypes from 'prop-types';
import React from 'react';
import TaxFields from './TaxFields';

const PersonalInformation = ({ data }) =>
  !data ? (
    <LoadingSpinner />
  ) : (
    <>
      <CategoryHeading className="ds-typography__h4">
        Personal Information
      </CategoryHeading>
      <NameFields
        firstName={data.personal_legal_first_name}
        middleName={data.personal_legal_middle_name}
        lastName={data.personal_legal_last_name}
        businessName={data.business_name}
      />
      <AddressFields
        heading="Official Address"
        addressLine1={data.personal_address_line_1}
        addressLine2={data.personal_address_line_2}
        city={data.personal_city}
        zip={data.personal_post_code}
        country={data.personal_country}
      />
      <PaymentFields
        paymentMethod={data.payment_method}
        city={data.bank_city}
        country={data.bank_country}
        bankName={data.bank_name}
        street={data.bank_street}
        zip={data.bank_zip}
        bic={data.bic}
        branch={data.branch}
        businessName={data.business_name}
        iban={data.iban}
        payee={data.payee}
      />
      <AddressFields
        heading="Billing Address"
        addressLine1={data.address_line_1}
        addressLine2={data.address_line_2}
        city={data.city}
        zip={data.post_code}
        country={data.country}
      />
      <TaxFields
        taxId={data.tax_id}
        taxDoc={data.tax_document}
        vatId={data.vat_id}
        vatDoc={data.vat_document}
      />
    </>
  );

PersonalInformation.propTypes = {
  data: PropTypes.shape({
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    bank_city: PropTypes.string,
    bank_country: PropTypes.string,
    bank_name: PropTypes.string,
    bank_street: PropTypes.string,
    bank_zip: PropTypes.string,
    bic: PropTypes.string,
    branch: PropTypes.string,
    business_name: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    iban: PropTypes.string,
    payee: PropTypes.string,
    payment_method: PropTypes.string,
    personal_address_line_1: PropTypes.string,
    personal_address_line_2: PropTypes.string,
    personal_city: PropTypes.string,
    personal_country: PropTypes.string,
    personal_legal_first_name: PropTypes.string,
    personal_legal_last_name: PropTypes.string,
    personal_legal_middle_name: PropTypes.string,
    personal_post_code: PropTypes.string,
    post_code: PropTypes.string,
    tax_document: PropTypes.shape({
      data: PropTypes.string,
      file_name: PropTypes.string,
    }),
    tax_id: PropTypes.string,
    vat_document: PropTypes.shape({
      data: PropTypes.string,
      file_name: PropTypes.string,
    }),
    vat_id: PropTypes.string,
  }),
};

export default PersonalInformation;
