export const customBuilder = (exercises, goal, beginOfWeek) => {
  const { past, sortedPresent } = builder(exercises, beginOfWeek);

  return {
    past,
    present: sortedPresent.slice(0, goal),
  };
};

export const defaultBuilder = (exercises, numberOfWeeks, beginOfWeek) => {
  const { past, sortedPresent } = builder(exercises, beginOfWeek);
  const numberOfExercises = sortedPresent.length;

  const speedLow = Math.floor(numberOfExercises / numberOfWeeks);
  const remDist = numberOfExercises % numberOfWeeks;
  const goal = remDist ? speedLow + 1 : speedLow;

  return {
    past,
    present: sortedPresent.slice(0, goal),
  };
};

export const builder = (exercises, beginOfWeek) => {
  const past = [];
  const present = [];

  Object.keys(exercises).forEach((order) => {
    const ex = exercises[order];

    const notApproved = ex.exercise_state && ex.exercise_state !== 'approved';

    if (notApproved && submittedInPastWeek(ex.submission_date, beginOfWeek)) {
      past.push(order);
    } else {
      present.push(order);
    }
  });

  return {
    past: sortExercises(past),
    sortedPresent: sortExercises(present),
  };
};

export const sortExercises = (exercises) =>
  [...exercises].sort((a, b) => {
    const splitA = a.split('.');
    const splitB = b.split('.');

    return +splitA[0] > +splitB[0]
      ? 1
      : +splitA[0] < +splitB[0]
      ? -1
      : +splitA[1] > +splitB[1]
      ? 1
      : +splitA[1] < +splitB[1]
      ? -1
      : 0;
  });

export const submittedInPastWeek = (date, beginOfWeek) =>
  date && new Date(date) < new Date(beginOfWeek);

export const totalTaskTime = (timeType, exercises, order) => {
  const total = order.reduce(
    (acc, key, i) =>
      acc + (isInRevision(exercises[key]) ? 0 : exercises[key][timeType] / 60),
    0
  );

  return Math.ceil(total);
};

export const hasRevisions = (order, exercises, isInRevision) =>
  order.map((key) => exercises[key]).some((ex) => isInRevision(ex));

export const isInRevision = (ex) =>
  ex.exercise_state === 'not_yet' ||
  ex.exercise_state === 'almost_there' ||
  ex.exercise_state === 'a_little_more';
