import PropTypes from 'prop-types';
import React from 'react';
import StyledStartReview from './styles/StyledStartReview';

const StartReview = ({ buttonAction }) => (
  <StyledStartReview>
    <div></div>
    <div>
      <h3 className="ds-typography__h3">You have answered all the questions in this interview training!</h3>
      <p className="ds-typography__body">Now you can review and evaluate your answers</p>
    </div>
    <div>
      <button className="ds-button__primary" onClick={() => buttonAction()}>Evaluate answers</button>
    </div>
  </StyledStartReview>
);

StartReview.propTypes = {
  buttonAction: PropTypes.func.isRequired,
};

export default StartReview;
