import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 40px 0;

  h2 {
    text-align: left;
  }
`;

Wrapper.displayName = 'Wrapper';

export {
  Wrapper,
};
