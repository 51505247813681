import '@typeform/embed/build/css/widget.css';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TypeformContainer from './styles';
import { createWidget } from '@typeform/embed';

const Typeform = ({ formId, handleSubmit, params = {} }) => {
  const refContainer = useRef(null);

  useEffect(() => {
    createWidget(formId, {
      container: refContainer.current,
      hidden: params,
      hideFooter: true,
      hideHeaders: true,
      onSubmit: handleSubmit ? () => handleSubmit(event) : null,
    });
  }, []);

  return <TypeformContainer ref={refContainer} />;
};

Typeform.propTypes = {
  formId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  params: PropTypes.object,
};

export default Typeform;
