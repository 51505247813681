import React from 'react';

const AdditionalResources = () => (
  <>
    <ul>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://www.skuvault.com/blog/how-to-use-the-retail-price-formula-to-calculate-pricing/'
            )
          }
        >
          How to Use Retail Price Formula to Calculate Product Pricing
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://www.settle-in-berlin.com/how-to-become-a-freelancer-in-germany-self-employed/'
            )
          }
        >
          How to Become a Freelancer in Germany
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/career-change/how-to-become-a-digital-nomad'
            )
          }
        >
          How to Become a Digital Nomad (CareerFoundry)
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/data-analytics/freelance-data-analyst/'
            )
          }
        >
          How to Become a Freelance Data Analyst (CareerFoundry)
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/digital-marketing/freelance-digital-marketing/'
            )
          }
        >
          How to Get Started in Freelance Digital Marketing (CareerFoundry)
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/web-development/freelance-web-developer/'
            )
          }
        >
          How to Become a Freelance Web Developer (CareerFoundry)
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://careerfoundry.com/en/blog/ux-design/ux-agency-vs-in-house-vs-freelance/'
            )
          }
        >
          Agency vs InHouse vs Freelance (CareerFoundry)
        </a>
      </li>
    </ul>
  </>
);

export default AdditionalResources;
