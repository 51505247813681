import PropTypes from 'prop-types';
import React from 'react';
import { TabsWrapper } from './styles';
import Tooltip from '../../../shared/components/Tooltip';

const Tabs = ({ activeTab, tabs, setActiveTab }) => (
  <TabsWrapper className="ds-tabs">
    {tabs.map((tab) => {
      const tabName = Object.keys(tab).toString();
      const isLocked = tab[tabName].open === false;

      return (
        <Tooltip
          key={tabName}
          content={
            isLocked &&
            'Invoicing period has closed. Calls can no longer be edited.'
          }
        >
          <h5
            className={`ds-typography__h5 ds-tabs__item ${
              isLocked ? 'locked' : ''
            } ${activeTab === tabName ? 'active' : ''}`}
            onClick={() => !isLocked && setActiveTab(tabName)}
          >
            {tabName}
          </h5>
        </Tooltip>
      );
    })}
  </TabsWrapper>
);

Tabs.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  tabs: PropTypes.array,
};

export default Tabs;
