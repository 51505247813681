import {
  ItemBottom,
  ItemBulletpoints,
  ItemContainer,
  ItemDescription,
  ItemImage,
  ItemTitle,
  TeamsItem,
  TeamsWrapper,
  Wrapper,
} from './styles';
import Head from '../../components/Head';
import NavButtons from '../../components/NavButtons';
import PropTypes from 'prop-types';
import React from 'react';
import careerSpec from '../../assets/images/career_spec.svg';
import mentor from '../../assets/images/mentor.svg';
import { noop } from 'lodash';
import studentAdvisor from '../../assets/images/student_advisor.svg';
import tutor from '../../assets/images/tutor.svg';

const TeamPage = ({
  head = {},
  members = [],
  navigateTo = noop,
  shouldHideMember = noop,
}) => {
  const images = { careerSpec, mentor, studentAdvisor, tutor };

  return (
    <Wrapper>
      <Head data={head} />
      <TeamsWrapper>
        {members.map(
          (member) =>
            !shouldHideMember(member.title) && (
              <TeamsItem key={member.title} role='teamItem'>
                <ItemContainer>
                  <ItemImage role='itemImage'>
                    <img src={images[member.image]} className='teamItemImage' />
                  </ItemImage>
                  <ItemTitle className='ds-typography__body' role='itemTitle'>
                    {member.title}
                  </ItemTitle>
                  <ItemDescription
                    className='ds-typography__body'
                    role='itemDescription'
                  >
                    {member.description}
                  </ItemDescription>
                  <ItemBulletpoints
                    className='ds-typography__body'
                    role='itemBullets'
                  >
                    <ul>
                      {member.bulletpoints.map((bullet) => (
                        <li key={bullet}>{bullet}</li>
                      ))}
                    </ul>
                  </ItemBulletpoints>
                </ItemContainer>
                <ItemBottom
                  backgroundColor={member.color}
                  role='itemBottom'
                ></ItemBottom>
              </TeamsItem>
            )
        )}
      </TeamsWrapper>
      <NavButtons
        onClickNext={() => navigateTo('#profile', 'next')}
        onClickBack={() => navigateTo('back')}
        nextButtonText='Got it'
      />
    </Wrapper>
  );
};

TeamPage.propTypes = {
  head: PropTypes.shape({
    description: PropTypes.string,
    main: PropTypes.string,
    pageIndex: PropTypes.number,
    title: PropTypes.string,
  }),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      bulletpoints: PropTypes.arrayOf(PropTypes.string),
      color: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  navigateTo: PropTypes.func,
  shouldHideMember: PropTypes.func,
};

export default TeamPage;
