import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import StyledAudioSection from './styles/StyledAudioSection';

const AudioSection = ({ audio, trackPlayButton }) => {
  const audioRef = useRef();

  useEffect(() => {
    audioRef.current.addEventListener('play', () => {
      trackPlayButton();
    });
  }, []);

  return (
    <StyledAudioSection>
      <audio controls src={audio} ref={audioRef} />
    </StyledAudioSection>
  );
};

AudioSection.propTypes = {
  audio: PropTypes.string.isRequired,
  trackPlayButton: PropTypes.func,
};

export default AudioSection;
