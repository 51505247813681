import styled from 'styled-components';

const StyledApp = styled.div`
  margin-top: 24px;
  margin-bottom: 50px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: var(--ds-color__indigo);
  }

  .TheModal {
    width: 440px;
  }
`;

StyledApp.displayName = 'App';

export default StyledApp;
