import PersonalInformationInputs from '../../../components/PersonalInformation/PersonalInformationInputs';
import { connect } from 'react-redux';
import { handleSave } from '../actions';

export const mapStateToProps = ({
  instructorProfile: { formErrors, personalData },
}) => ({
  formErrors,
  personalData,
});

export const mapDispatchToProps = (dispatch) => ({
  handleSave: (name, value) => dispatch(handleSave(name, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInformationInputs);
