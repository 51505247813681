import ApiService from '../../../../../services/ApiService';

const POST_INSTRUCTOR_SIGNUP_URL = `${window.location.href}`;
const GET_INSTRUCTOR_SIGNUP_DETAILS_URL = `${window.location.href}.json`;

export const submitInstructorSignupForm = async ({ ...instructorDetails }) => {
  const apiService = new ApiService(POST_INSTRUCTOR_SIGNUP_URL);
  return await apiService.makePostCall({ ...instructorDetails });
};

export const getInstructorSignupDetails = async () => {
  const apiService = new ApiService(GET_INSTRUCTOR_SIGNUP_DETAILS_URL);
  return await apiService.makeGetCall();
};
