import styled from 'styled-components';

const HelpfulSection = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  p {
    margin: 0px;
    font-weight: 700;
    margin-top: 10px !important;
  }
  ${({ userHasLiked }) =>
    userHasLiked &&
    `
    #helpfulIcon, #helpfulInTransitionIcon, #helpfulInTransition2Icon {
      display: none;
    }
  `}

  ${({ userHasLiked }) =>
    !userHasLiked &&
    `
    #helpfulIcon, #helpfulInTransitionIcon, #helpfulInTransition2Icon {
      transition: all .35s ease-in-out;
    }

    #helpfulIcon {
      display: block !important;
    }

    #helpfulInTransitionIcon, #helpfulInTransition2Icon {
      display: none;
    }
  `}

  ${({ ratingLoading, userHasLiked }) =>
    !userHasLiked &&
    ratingLoading &&
    `
    #helpfulIcon {
      transform: rotate( 90deg ) scale( 0 );
      opacity: 0;
    }
    #helpfulInTransitionIcon {
      display: block !important;
    }
  `}

  ${({ ratingTransition, userHasLiked }) =>
    !userHasLiked &&
    ratingTransition &&
    `
    #helpfulInTransitionIcon {
      transform: rotate( 90deg ) scale( 0 );
      opacity: 0;
    }

    #helpfulInTransition2Icon {
      display: block !important;
    }
  `}

  @media (max-width: 768px) {
    p {
      margin: 10px 0 0 !important;
    }
  }
`;

HelpfulSection.displayName = 'HelpfulSection';

export default HelpfulSection;
