import styled from 'styled-components';

const StyledTextArea = styled.div`
  margin-bottom: 24px;
  .tox-tinymce {
    height: 250px !important;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed !important;
    pointer-events: none;
  `};

  ${({ loading }) =>
    loading &&
    `
    input {
      display: none;
    }
    .loading_spinner_wrapper {
      display: block;
    }
  `};

  ${({ loading }) =>
    !loading &&
    `
    input {
      display: block;
    }
    .loading_spinner_wrapper {
      display: none;
    }
  `};
`;

StyledTextArea.displayName = 'StyledTextArea';

export default StyledTextArea;
