import styled from 'styled-components';

const StyledSpinner = styled.div`
&.cf-spinner div {
    background-color: var(--white) !important;
}
`;

StyledSpinner.displayName = 'StyledSpinner';

export default StyledSpinner;
