const careerfoundryCoach = require('../../../../../assets/javascripts/site/careerfoundry_coach.js');

module.exports = (() => {
  const init = () => {
    bindFunctions();
    initializeValidation();
  };

  const bindFunctions = () => {
    // this taps into the rails-autocomplete event autocompleteselect for autocomplete
    $('.paypalAutocomplete--js').on('autocompleteselect', getAttendees);
    $('.createUrlForm--js').on('submit', createUrl);
  };

  const getAttendees = function(e, ui) {
    $.ajax({
      url: '/en/admin/paypal/get_attendees/',
      data: _buildData(ui),
      method: 'POST'
    }).done(function(r) {
      _addOptions(r)
    }).fail(function(r) {
      let response = $.parseJSON(r.responseText)
      CareerFoundyCoach.flashMessage(response.message, response.type)
    })
  };

  const createUrl = function(e) {
    e.preventDefault();
    let $form = $(this);

    if ($form.valid()) {
      let $data = $(this).serialize();

      $.ajax({
        url: '/en/admin/paypal/create_url/',
        data: $data,
        method: 'POST'
      }).done(function(r) {
        _renderUrl(r.url);
      }).fail(function(r) {
        let response = $.parseJSON(r.responseText);
        CareerFoundyCoach.flashMessage(response.message, response.type);
      })
    }
  };

  const _buildData = ui => {
    return {
      user_id: ui.item.id
    }
  };

  const _addOptions = function(res) {
    let $dropdown = $('#attendeesDropdown--js')

    $dropdown.empty();
    $dropdown.append(new Option("Select Attendee", ''));
    _generateOptions($dropdown, res)
  };

  const _generateOptions = function(element, collection) {
    if(collection.length){
      $.each(collection, function() {
        element.append(new Option(this.course.topic, this.id))
      })
    }else{
      element.append(new Option("User has no Attendees", ''));
    }
  }

  const initializeValidation = function() {
    $.validator.addMethod("futureDate", function(value, element) {
      return this.optional(element) || !(Date.parse(value) < Date.now())
    }, "Please enter only future dates");

    $('form.createUrlForm--js').validate({
      rules: {
        "generator[user]": "required",
        attendee_id: "required",
        "generator[amount]": "required",
        "generator[cycles]": "required",
        "generator[currency]": "required",
        "generator[start_date]": {
          required: function() {
            return $('#generator_cycles').val() > 1
          },
          futureDate: true
        },
        "generator[amount_monthly]": {
          required: function() {
            return $('#generator_cycles').val() > 1
          }
        }
      },
      messages: {
        "generator[user]": "Please enter a user",
        attendee_id: "Please enter an attendee",
        "generator[amount]": "Please enter an amount",
        "generator[amount_monthly]": "Please enter a monthly amount",
        "generator[cycles]": "Please enter installments",
        "generator[currency]": "Please enter a currency",
        "generator[start_date]": {
          required: "Please enter a start date"
        }
      }
    })
  }

  const _renderUrl = function(url) {
    $('.generatedUrl').show();
    $('.generatedUrl--js').text(url);
  };

  return {
    init: init
  };
})();
