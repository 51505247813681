import React, { useEffect } from 'react';
import {
  getApplicationConsent,
  getJobApplications,
  handleNewJobApplication,
  handleUpdateJobApplication,
  handleViewJobApplication,
  updateShareApplication,
} from '../../actions';
import FlashMessageContainer from '../../../shared/flashMessageContainer.jsx';
import JobApplicationPreview from '../../components/JobApplicationPreview';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const JobApplicationsPreviewContainer = ({
  attendeeId,
  jobApplicationAction,
  loading,
  reload,
  getJobApplications,
  updateShareApplication,
  ...rest
}) => {
  useEffect(() => {
    if (reload) {
      getJobApplications(null, 3);
    }
  }, [reload]);

  useEffect(() => {
    const consent = localStorage.getItem('jobApplicationConsent') ? JSON.parse(localStorage.getItem('jobApplicationConsent')) : null;
    const isShared = consent && consent.userId === attendeeId && !!consent.shared === true;

    if (isShared) {
      updateShareApplication(isShared);
    }
  }, []);

  return (
    <>
      {loading
        ? <LoadingSpinner />
        : <JobApplicationPreview userId={attendeeId} {...rest}/>
      }
      {jobApplicationAction && <FlashMessageContainer status="success" message={`Job Application ${jobApplicationAction}`} />}
    </>
  );
};

JobApplicationsPreviewContainer.propTypes = {
  attendeeId: PropTypes.number.isRequired,
  getJobApplications: PropTypes.func.isRequired,
  handleNewJobApplication: PropTypes.func.isRequired,
  handleUpdateJobApplication: PropTypes.func.isRequired,
  handleViewJobApplication: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  jobApplicationAction: PropTypes.string,
  jobApplications: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  reload: PropTypes.bool.isRequired,
  updateShareApplication: PropTypes.func.isRequired,
};

export const mapStateToProps = ({
  careerSupportCenter: {
    jobApplicationAction,
    jobApplications,
    loading,
    reload,
    shareJobApplication,
    retrievingConsent,
  },
}) => ({
  jobApplicationAction,
  jobApplications,
  loading,
  reload,
  retrievingConsent,
  shareJobApplication,
});

export const mapDispatchToProps = dispatch => ({
  getApplicationConsent: (userId, hasApplications) => dispatch(getApplicationConsent(userId, hasApplications)),
  getJobApplications: (page, limit) => dispatch(getJobApplications(page, limit)),
  handleNewJobApplication: () => dispatch(handleNewJobApplication()),
  handleUpdateJobApplication: id => dispatch(handleUpdateJobApplication(id)),
  handleViewJobApplication: id => dispatch(handleViewJobApplication(id)),
  updateShareApplication: shared => dispatch(updateShareApplication(shared)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobApplicationsPreviewContainer);
