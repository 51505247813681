import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import React from 'react';
import { accordionClickTracking } from '../../helpers/tracking';

interface AccordionSectionProps {
  content: React.ReactElement;
  heading: string;
  eventType: string;
}

const AccordionSection: React.FC<AccordionSectionProps> = ({
  content,
  heading,
  eventType,
}) => (
  <AccordionItem uuid={heading.replace(/ /g, '')}>
    <AccordionItemHeading
      onClick={() =>
        eventType ? accordionClickTracking(heading, eventType) : {}
      }
    >
      <AccordionItemButton>
        <h5 className='ds-typography__h5'>{heading}</h5>
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel className='accordion__panel ds-implicit'>
      {content}
    </AccordionItemPanel>
  </AccordionItem>
);

export default AccordionSection;
