import { Body, Button, Header } from '@careerfoundry/bubble';
import { ApprovalModalContent } from './styles';
import React from 'react';
import { formatApprovalParams } from './helpers';

interface ApprovalModalProps {
  modalAction: {
    action: string;
    attribute: string;
  };
  handleUpdateAdmission: (params: any) => void;
}

const ApprovalModal: React.FC<ApprovalModalProps> = ({
  modalAction,
  handleUpdateAdmission,
}) => {
  return (
    <ApprovalModalContent>
      <Header tag='h4' variant='h4'>
        Are you sure?
      </Header>
      <Body tag='p' variant='medium'>
        Are you sure you want to approve this step of the admissions process?
        Once approved, it cannot be undone.
      </Body>
      <Button
        label='Confirm'
        onClick={() => handleUpdateAdmission(formatApprovalParams(modalAction))}
        size='medium'
        variant='primary'
      />
    </ApprovalModalContent>
  );
};

export default ApprovalModal;
