import {
  IconMicrophone,
  IconProfile,
  IconStudy,
  IconSubtract,
  IconUnion,
  IconVector,
} from './components/Navbar/icons';
import React, { useEffect, useState } from 'react';
import NavbarWrapper from './components/Navbar/NavbarWrapper';
import PropTypes from 'prop-types';
import StyledSideNav from './styles/StyledSideNav';
import { cscNavTracking } from '../../../shared/helpers/tracking';
import { sections } from '../../constants';

const defaultMenus = [
  {
    header: 'Apply to jobs',
    menus: [
      {
        icon: <IconVector />,
        id: 'application-tracker',
        label: 'Track your applications',
      },
      {
        icon: <IconSubtract />,
        id: 'job-search',
        label: 'Search jobs',
      },
    ],
  },
  {
    header: 'Strengthen your job search',
    menus: [
      {
        icon: <IconMicrophone />,
        id: 'interview-prep',
        label: 'Practice interviewing',
      },
      {
        icon: <IconUnion />,
        id: 'portfolio-project',
        label: 'Create new portfolio project',
      },
    ],
  },
  {
    header: 'Extend your knowledge ',
    menus: [
      {
        icon: <IconStudy />,
        id: 'explore-job',
        label: 'Explore job-related topics',
      },
      {
        icon: <IconProfile />,
        id: 'support-community',
        label: 'Get support from the CF community',
      },
    ],
  },
];

const SideNav = ({ onClickNav, activeNav, user, showProjectMenu }) => {
  const handleClick = (id, label) => {
    const itemOrder = sections.indexOf(id) + 1;
    cscNavTracking('csc_menu_item_clicked', user.id, label, itemOrder);

    onClickNav(id);
  };

  const [menuSections, setSection] = useState(defaultMenus);

  useEffect(() => {
    const menus = menuSections;
    if (showProjectMenu === false && menuSections[1].menus.length === 2) {
      menus[1].menus = [menus[1].menus[0]];
      setSection(menus);
    } else if (showProjectMenu && menuSections[1].menus.length < 2) {
      menus[1].menus.push({
        icon: <IconUnion />,
        id: 'portfolio-project',
        label: 'Create new portfolio project',
      });
      setSection(menus);
    }
  }, [showProjectMenu]);

  return (
    <StyledSideNav>
      {menuSections.map((section) => (
        <NavbarWrapper
          key={section.header}
          header={section.header}
          menus={section.menus}
          onClick={handleClick}
          activeNav={activeNav}
          isReducedMenu={section.menus.length < 2}
        />
      ))}
    </StyledSideNav>
  );
};

SideNav.propTypes = {
  activeNav: PropTypes.string,
  onClickNav: PropTypes.func.isRequired,
  showProjectMenu: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default SideNav;
