import PropTypes from 'prop-types';
import React from 'react';
import StyledMenu from './styles/StyledMenu';
import StyledMenuLabel from './styles/StyledMenuLabel';

const NavMenu = ({ label, icon, isActive, onClick }) => {
  return (
    <StyledMenu isActive={isActive} onClick={onClick}>
      { icon }
      <StyledMenuLabel className='ds-typography__body' isActive={isActive}>
        { label }
      </StyledMenuLabel>
    </StyledMenu>
  );
};

NavMenu.propTypes = {
  icon: PropTypes.element.isRequired,
  isActive: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NavMenu;
