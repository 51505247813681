import React, { useEffect } from 'react';
import PaymentPlan from '../../../types/PaymentPlan';
import { connect } from 'react-redux';
import StripePayments from '../../../../shared/StripePayments';
import { createCheckoutTransaction } from '../../../../shared/StripePayments/actions/index';
import { Dispatch } from 'redux';
import { getUserLocation } from '../../actions';

interface Props {
  clientSecret: string;
  startDate: string;
  paymentPlan: PaymentPlan;
  transactionId: number;
  productSku: string;
  discountCode: string;
  createCheckoutTransaction: (
    paymentPlan: PaymentPlan,
    discountCode: string
  ) => void;
  getUserLocation: () => void;
  userLocation: string;
}

interface IState {
  klarnaCheckout: {
    clientSecret: string;
    transactionId: number;
    productSku: string;
    userLocation: string;
  };
}

const AppContainer: React.FC<Props> = ({
  clientSecret,
  startDate,
  paymentPlan,
  transactionId,
  productSku,
  discountCode,
  createCheckoutTransaction,
  getUserLocation,
  userLocation,
}) => {
  useEffect(() => {
    createCheckoutTransaction(paymentPlan, discountCode);
    getUserLocation();
  }, []);

  return (
    <StripePayments
      clientSecret={clientSecret}
      transactionId={transactionId}
      startDate={startDate}
      productSku={productSku}
      userLocation={userLocation}
    />
  );
};

export const mapStateToProps = ({
  klarnaCheckout: { clientSecret, transactionId, productSku, userLocation },
}: IState) => ({
  clientSecret,
  transactionId,
  productSku,
  userLocation,
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  createCheckoutTransaction: (paymentPlan: PaymentPlan, discountCode: string) =>
    createCheckoutTransaction(paymentPlan, discountCode)(dispatch),
  getUserLocation: () => getUserLocation()(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
