import {
  createOffPlatformCall,
  handleCancelModal,
  handleCloseModal,
  setModalAction,
  setModalType,
  updateCalendarEntry,
} from '../../action';
import CallQuestionModal from '../../components/Modals/CallQuestionModal';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  mentorCallNotes: {
    currentCallId,
    currentCalendarEntry,
    modalType,
    isOffPlatformCall,
  },
}) => ({
  currentCalendarEntry,
  currentCallId,
  isOffPlatformCall,
  modalType,
});

export const mapDispatchToProps = (dispatch) => ({
  createOffPlatformCall: (callInformation, noteData) =>
    dispatch(createOffPlatformCall(callInformation, noteData)),
  handleCancelModal: () => dispatch(handleCancelModal()),
  handleCloseModal: () => dispatch(handleCloseModal()),
  setModalAction: (modalAction) => dispatch(setModalAction(modalAction)),
  setModalType: (modalType) => dispatch(setModalType(modalType)),
  updateCalendarEntry: (id, data) => dispatch(updateCalendarEntry(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallQuestionModal);
