import PropTypes from 'prop-types';
import React from 'react';

const ProgressLineLabel = ({ complete, label, total }) => (
  <p className='ds-typography__small'>
    {complete} / {total} {label}
  </p>
);

ProgressLineLabel.propTypes = {
  complete: PropTypes.number,
  label: PropTypes.string,
  total: PropTypes.number,
};

export default ProgressLineLabel;
