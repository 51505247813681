import HandoffDisclaimer from './HandoffDisclaimer';
import PropTypes from 'prop-types';
import React from 'react';
import Table from './styles/StyledStudentHandoffTable';

const StudentHandoffTab = ({ responses }) => {
  const headers = ['Question', 'Tutor Response', 'Mentor Response'];
  const hasTutorResponses =
    [...new Set(responses.map((response) => response.tutor))].length > 1;
  const hasMentorResponses =
    [...new Set(responses.map((response) => response.tutor))].length > 1;
  return (
    <Table className='table table-bordered'>
      <thead>
        <tr>
          {headers.map((column, i) => (
            <th key={i}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {responses.map((response, i) => (
          <tr key={`${i}`}>
            <td>{response.question}</td>
            <td>
              {response.tutor}
              {response.mentorSpecific && hasTutorResponses && (
                <HandoffDisclaimer disclaimerType='Mentor' />
              )}
            </td>
            <td>
              {response.mentor}
              {response.tutorSpecific && hasMentorResponses && (
                <HandoffDisclaimer disclaimerType='Tutor' />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

StudentHandoffTab.propTypes = {
  responses: PropTypes.array.isRequired,
};

export default StudentHandoffTab;
