import InstructorProfile from './InstructorProfile/containers/App';
import InstructorSignup from './InstructorSignup/containers/InstructorSignupForm/index';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const instructorSignupContainer = document.getElementById(
  'InstructorEnrollmentLinks'
);
const instructorProfileContainer = document.getElementById('InstructorProfile');

if (instructorSignupContainer) {
  const instructorSignupContainerRoot = createRoot(instructorSignupContainer);

  instructorSignupContainerRoot.render(<InstructorSignup />);
}

if (instructorProfileContainer) {
  const instructorProfileContainerRoot = createRoot(instructorProfileContainer);

  instructorProfileContainerRoot.render(
    <Provider store={store}>
      <InstructorProfile />
    </Provider>
  );
}
