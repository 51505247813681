import styled from 'styled-components';

const StyledHeaderInfographic = styled.div`
  display: flex;
  flex-grow: 1;

  ${({ isPreview }) =>
    isPreview &&
    `
      margin-bottom: 60px;
    `}

  @media (min-width: 1480px) {
    ${({ isPreview }) =>
      isPreview &&
      `
      align-items: center;
      justify-content: center;
    `}
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

StyledHeaderInfographic.displayName = 'StyledHeaderInfographic';
export default StyledHeaderInfographic;
