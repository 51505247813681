import App from './containers/App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const callsEditContainer = document.getElementById('callsEdit');

if (callsEditContainer) {
  const callsEditContainerRoot = createRoot(callsEditContainer);

  callsEditContainerRoot.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}
