import styled from 'styled-components';

const StyledSuccessImage = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 300px;
  width: 100%;
  position: relative;
  left: 3%;
  background-image: url('http://images.careerfoundry.com/public/images/eventsSection.png');
`;

StyledSuccessImage.displayName = 'StyledSuccessImage';
export default StyledSuccessImage;
