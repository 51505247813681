export default (() => {
  const init = () => {
    $(document).on('click', '.delete-postscript', deletePostscript);
    $(document).on('change', '#admin_submissions_course_id', loadSteps);
    $('[data-toggle="popover"]').tooltip();
    loadSteps();
  };

  const deletePostscript = function(e){
    e.preventDefault();
    if (confirm('Do you want to remove this postscript ?')){
      $.ajax({
        url: `/en/admin/postscripts/${$(this).data('id')}`,
        method: 'DELETE',
        dataType: 'script'
      });
      $(this).closest('tr').remove();
    }
  };
  const loadSteps = function() {
    $.ajax({
      url: `/en/admin/submissions/load_steps/`,
      data: {
        course_id: $('#admin_submissions_course_id').val(),
      },
      method: 'POST',
      dataType: 'script'
    });
  };
  return {
    init: init
  };
})();
