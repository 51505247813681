import { HomeIcon } from './icons';
import JobSearchTile from '../../UsefulLinks/JobSearchTile';
import React from 'react';

const RemoteWorkTile = () => (
  <JobSearchTile
    description='Pros and cons, salary expectations, and much more.'
    icon={<HomeIcon />}
    secondaryButton={{
      event: 'rework_work_click',
      link: '/en/remote-work',
      text: 'Learn about remote work',
    }}
    title='Working remotely'
  />
);

export default RemoteWorkTile;
