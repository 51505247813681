import styled from 'styled-components';

const Table = styled.table`
  margin-bottom: 25px;

  thead {
    background-color: var(--ds-color__indigo);
    color: white;
    th {
      text-align: center;
    }
  }

  tbody > tr > td {
    text-align: center;
  }

  tbody > tr:nth-child(even) > td,
  tbody > tr:nth-child(even) > th {
    background-color: #eef0f1;
  }
  table {
    table-layout: fixed;
    width: 100%;
  }
  td.title {
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
  }
`;

Table.displayName = 'Table';

export default Table;
