module.exports = (() => {
  var thisState = {}

  var init = function(){
    bindFunctions()
    thisState.form = new CF.Form('.new__pwd__form')
  }

  var bindFunctions = function(){
    $('.navigate_back--js').on('click', _navigateBack)
  }

  var _navigateBack = function(){
    window.history.go(-1)
  }

  return {
    init: init
  }
})()
