import ConsentForm from '../../components/ConsentForm';
import { connect } from 'react-redux';
import { handleShareAnswer } from '../../actions';

export const mapDispatchToProps = (dispatch) => ({
  onShareAnswer: (shared, interviewId) => dispatch(handleShareAnswer(shared, interviewId)),
});

export const mapStateToProps = ({
  interviewPrep: {
    interviewId,
    shareWithMentor,
  },
}) => ({
  interviewId,
  shareWithMentor,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentForm);
