const style = {
  backgroundColor: 'var(--ds-color__indigo)',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: '22,5px',
  marginTop: '0px',
  maxWidth: '325px',
};

export const chWsgSubmission = {
  arrowPosition: 'left',
  content:
    'Setting weekly submission goals will help you to stay on track. This tool calculates when you’ll finish your course based on the goal you set. You can adjust your goal at any time.',
  name: 'ch_wsg__submission',
  position: 'right',
  selector: '#wsgCardTitle',
  style,
};

export default [
  {
    arrowPosition: 'left',
    content:
      'Pick up where you left off at any time, from anywhere on the platform.',
    name: 'ch_current_exercise',
    position: 'right',
    selector: '.current_exercise',
    style,
  },
  {
    arrowPosition: 'right',
    content: 'Schedule one-hour calls with your mentor here',
    name: 'ch_mentor_calls',
    position: 'left',
    selector: '.prototype_course_home__sidebar .prototype_course_home__calls',
    style: {
      ...style,
      marginLeft: '-20px',
    },
  },
  {
    arrowPosition: 'topRight',
    content:
      'Click the envelope icon to exchange messages with your mentor or tutor.',
    name: 'ch_messaging',
    position: 'bottom',
    selector: '#MessagingNotifications',
    style: {
      ...style,
      marginLeft: '10px',
    },
  },
  {
    arrowPosition: 'left',
    content:
      'The overall course progress section will help you visualize your progress to stay on track throughout the course.',
    name: 'ch_wsg',
    position: 'right',
    selector: '.prototype_course_home__progress .ds-typography__h4',
    style,
  },
];
