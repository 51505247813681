import ApiService from '../../services/ApiService';
import { constants } from '../Employer/constants';
import { getAuthToken } from '../../utils/getAuthToken';
import { getUserData } from '../../services/getUserData';

export const getOnboardingStatus = (employerId) => async dispatch => {
  const apiService = new ApiService(`/api/v1/employers/${employerId}/registration`);
  try {
    const result = await apiService.makeGetCall();
    dispatch({
      data: result.data.data,
      type: constants.FETCH_ONBOARDING_STATUS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: error,
      type: constants.FETCH_ONBOARDING_STATUS_FAILURE,
    });
  };
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    const { data } = await getUserData();
    dispatch({
      currentUser: data.data,
      type: constants.SET_CURRENT_USER,
    });
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const updatePassword = (data) => async dispatch => {
  const params = {
    authenticity_token: getAuthToken(),
    user: {
      ...data,
    },
  };
  const apiService = new ApiService('/api/v1/users/update_password');

  try {
    const result = await apiService.makePostCall(params);
    dispatch({
      data: result.data.data,
      type: constants.PASSWORD_UPDATE_SUCCESS,
    });
    window.location.href = '/employers/dashboard';
  } catch (error) {
    dispatch({
      error: error.message,
      type: constants.PASSWORD_UPDATE_FAILURE,
    });
  };
};

export const updatePolicy = (employerId, data) => async dispatch => {
  const params = {
    authenticity_token: getAuthToken(),
    ...data,
  };
  const apiService = new ApiService(`/api/v1/employers/${employerId}/update_agreements`);

  try {
    const result = await apiService.makePatchCall(params);
    dispatch({
      data: result.data.data,
      type: constants.POLICY_UPDATE_SUCCESS,
    });
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  };
};

export const handleCloseModal = () => ({ type: constants.HANDLE_CLOSE_MODAL });

export const showPasswordChangeModal = () => ({ type: constants.SHOW_PASSWORD_CHANGE_MODAL });
