import { currencySign } from '../../../utils/currencySign'

module.exports = (() => {
  const init = function () {
    $(document).on('change', '#payment_contract_form_product_id', loadProduct)
    loadProduct();
  }

  const formatProductInformation = function(product) {
      const prices = product.prices.map((price) => `${currencySign(price.currency)}${price.amount}`)
      const productDescription = `Current product setup: ${product.name}, Prices: ${prices.join('; ')}, Calls: ${product.calls}, CareerChange: ${product.career_change}, Length: ${product.length}, BufferTime: ${product.buffer_time}, Active: ${product.active}`

      return(productDescription)
  }

  const loadProduct = function() {
    const productId = $('#payment_contract_form_product_id').val()

    $.ajax({
      url: `/api/v1/admin/products/${productId}`
    }).done((data) => {
      const record = data.data
      const formattedProductInformation = formatProductInformation(record)
      $('.payment_contract_form_product_information').text(formattedProductInformation)
    })
  };

  return {
    init: init
  };
})();
