import React, { useState } from 'react';
import FlashMessageContainer from '../../../../shared/flashMessageContainer.jsx';
import PropTypes from 'prop-types';
import { copyLink } from '../../../../../utils/copyToClipboard';
import { parseDate } from '../../utils';
import { useHistory } from 'react-router-dom';

const ListRow = ({ candidate, sendWelcomeEmail }) => {
  const [flash, setFlash] = useState(false);
  const history = useHistory();
  const navigateToCandidate = (id) =>
    history.push(`/en/admin/instructors/candidates/${id}`);
  const candidateLink = `${location.origin}/instructors/enrollments/${candidate.uuid}`;

  const handleCopyToClipboard = () => {
    copyLink(candidateLink);
    setFlash(true);
  };

  return (
    <tr>
      <td>{candidate.name}</td>
      <td>{candidate.email}</td>
      <td>{candidate.candidate_role}</td>
      <td>{candidate.state}</td>
      <td className="actions">
        <a onClick={() => handleCopyToClipboard()}>Copy link</a>
        {flash && (
          <FlashMessageContainer
            status="success"
            message="Link copied to clipboard"
          />
        )}
      </td>
      <td className="actions">
        {candidate.invitation_sent_at ? (
          parseDate(candidate.invitation_sent_at)
        ) : candidate.state === 'Sign-up pending' ? (
          <a
            onClick={() =>
              window.confirm('Are you sure you want to send the welcome email?')
                ? sendWelcomeEmail(candidate.id)
                : null
            }
          >
            Send welcome email
          </a>
        ) : null}
      </td>
      <td className="actions">
        <a onClick={() => navigateToCandidate(candidate.id)}>Details</a>
      </td>
    </tr>
  );
};

ListRow.propTypes = {
  candidate: PropTypes.object.isRequired,
  sendWelcomeEmail: PropTypes.func.isRequired,
};

export default ListRow;
