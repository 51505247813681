export const handleTaxChange = (event) => ({
  type: 'InstructorProfile.HANDLE_TAX_CHANGE',
  value: event.target.value,
});

export const removeTaxInput = (key) => ({
  key,
  type: 'InstructorProfile.REMOVE_TAX_INPUT',
});

export const removeFile = (key) => ({
  key,
  type: 'InstructorProfile.REMOVE_FILE',
});

export const handleFileUpload = (data, key) => (dispatch) => {
  data.then((res) => {
    dispatch({
      key,
      res,
      type: 'InstructorProfile.HANDLE_FILE_UPLOAD',
    });
  });
};
