import { InfoIcon } from './icons';
import JobSearchTile from '../../UsefulLinks/JobSearchTile';
import React from 'react';

const JobSearchTipsTile = () => (
  <JobSearchTile
    description='Job search tips from our specialists.'
    icon={<InfoIcon />}
    secondaryButton={{
      link: '/en/job-search-tips',
      target: '_parent',
      text: 'Explore job search tips ',
    }}
    title='Job search tips'
  />
);

export default JobSearchTipsTile;
