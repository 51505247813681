module.exports = (() => {
  const thisState = {};

  const init = () => {
    thisState.modal = new CF.Modal('#admin_invoices__modal');
    bindFunctions();
  };

  const bindFunctions = () => {
    $('.downloads').on('click', showDownloadBox);
    $('#admin_invoices__modal a').on('click', toggleLoadingState);
  };

  const showDownloadBox = function() {
    $('.downloadBox').slideToggle("slow");
    $(this).children()
      .children('.fa-caret-up, .fa-caret-down')
      .toggleClass('fa-caret-up fa-caret-down');
  };

  const toggleLoadingState = function() {
    $(this).html(CF.html.loadingSpinner);
  };

  return {
    init: init
  };
})();
