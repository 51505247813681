import {
  MessageInput,
  MessageList,
  useChannelStateContext,
} from 'stream-chat-react';
import React, { useState } from 'react';
import Chatbot from '../Chatbot';
import CustomChannelHeader from '../CustomChannelHeader';
import CustomMessageInput from '../../../components/CustomMessageInput';
import { uploadFile } from '../../../helpers';

interface Props {
  filters?: any;
}

const CustomChannel: React.FC<Props> = ({ filters }) => {
  const [shouldShowChannelList, setShouldShowChannelList] = useState(true);
  const { channel } = useChannelStateContext();

  return channel?.data?.chatbot ? (
    <Chatbot filters={filters} />
  ) : (
    <>
      <CustomChannelHeader
        userId={filters?.members?.$in}
        shouldShowChannelList={shouldShowChannelList}
        setShouldShowChannelList={setShouldShowChannelList}
      />
      <MessageList
        messageActions={['flag', 'react', 'edit', 'delete']}
        onlySenderCanEdit={true}
      />
      <MessageInput
        doFileUploadRequest={uploadFile}
        grow={true}
        Input={CustomMessageInput}
      />
    </>
  );
};

export default CustomChannel;
