import { IconAndNameWrapper, Wrapper } from './styles';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../../../shared/components/Tooltip';
import { noop } from 'lodash';

const Icon = ({
  id,
  isSelected = false,
  onClick = noop,
  isDisabled = false,
  isVisible = false,
  nameForMobileView = '',
  tooltipContent = '',
  onMouseEnter = noop,
  renderSVGIcon = noop,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Tooltip content={tooltipContent} disable={!tooltipContent}>
      <Wrapper>
        <IconAndNameWrapper
          id={id}
          isSelected={isSelected}
          isDisabled={isDisabled}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
        >
          <div>{renderSVGIcon()}</div>
          <div className='ds-typography__body--bold'>{nameForMobileView}</div>
        </IconAndNameWrapper>
      </Wrapper>
    </Tooltip>
  );
};

Icon.propTypes = {
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isVisible: PropTypes.bool,
  nameForMobileView: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  renderSVGIcon: PropTypes.func,
  tooltipContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default Icon;
