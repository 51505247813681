import React from 'react';

const UXDesign = () => (
  <>
    <p className="ds-typography__body break">Next up is ideation, prototyping, and testing. These stages typically occur in a cycle: you start by brainstorming for a solution, then you prototype it, get feedback on the prototype, and then, based on that feedback, adjust your idea, create a new prototype and repeat the process all over again. Typically the prototypes will become more and more complex at each stage, so you might start with rough sketches and end up with high fidelity prototypes.</p>
    <p className="ds-typography__body--bold">Differences for UI designers and UX designers</p>
    <p className="ds-typography__body break">This stage tends to be more UX-heavy, so if you’re working on a project to showcase your UX skills, it’s worth investing more time on this step.  For UI designers, this is the stage where you’d usually start working together with UX designers, so you can also take more of an active role in this stage.</p>
    <p className="ds-typography__body--bold">What you could be doing during this stage:</p>
    <p className="ds-typography__body">Here are some examples of activities that you might complete during this cycle:</p>
    <ul className="break">
      <li>Brainstorming</li>
      <li>Mapping out the flow for the tasks that your user will be performing</li>
      <li>Creating wireframes and prototypes of various fidelities for your solution</li>
      <li>Conducting user testing sessions</li>
      <li>Analyzing feedback and improving solutions accordingly</li>
    </ul>
    <p className="ds-typography__body--bold">Deliverables</p>
    <p className="ds-typography__body">Deliverables of this stage could include:</p>
    <ul>
      <li>User flows</li>
      <li>Task flows</li>
      <li>Sketches</li>
      <li>Wireframes</li>
      <li>Prototypes (low to high fidelity, varying level of interactivity) etc.</li>
    </ul>
  </>
);

export default UXDesign;
