import MyMentorCallsIcon from '../../../components/icons/MyMentorCallsIcon';
import React from 'react';
import { connect } from 'react-redux';
import { getExpiredCourses } from '../../../../shared/helpers/courseHelpers';

const mapStateToProps = (state) => {
  const { common, meData, attendeesData } = state.sideNavList;
  const { isUserActive, isStudent } = common;
  const { roles } = meData;
  const isUserOnlyTutor =
    roles && Array.isArray(roles) && roles.length === 1 && roles[0] === 'tutor';
  const isVisible = isUserActive && !(isUserOnlyTutor ?? true);
  const courses = attendeesData.attendees || [];
  const isCallAllowed = !!courses.find((course) => course.callAllowed);
  const isDisabled = isStudent && !isCallAllowed;
  const areAllCoursesExpired =
    getExpiredCourses({ courses }).length === courses.length;

  return {
    areAllCoursesExpired,
    isDisabled,
    isStudent,
    isVisible,
  };
};

const MyMentorCallsIconContainer = (props) => {
  return <MyMentorCallsIcon {...props} />;
};

MyMentorCallsIconContainer.propTypes = {
  ...MyMentorCallsIcon.propTypes,
};

export default connect(mapStateToProps, null)(MyMentorCallsIconContainer);
