import React, { Component } from 'react';
import App from '../../components/App';
import LoadingSpinner from '../../../../shared/loadingSpinner';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getData } from './actions';
import { getWeekEstimate } from './helpers';

export class AppContainer extends Component {
  componentDidMount() {
    this.props.getData(this.props.attendeeId);
  }

  render() {
    const { dataLoaded, ...restOfProps } = this.props;

    return dataLoaded ? <App {...restOfProps} /> : <LoadingSpinner />;
  }
}

AppContainer.propTypes = {
  attendeeId: PropTypes.number,
  dataLoaded: PropTypes.bool,
  getData: PropTypes.func.isRequired,
};

export const mapStateToProps = ({
  weeklySubmissionGoal: { dataLoaded, exercises, goal, order },
}) => ({
  dataLoaded,
  goal,
  weekEstimate: dataLoaded ? getWeekEstimate(exercises, order) : '',
});

export const mapDispatchToProps = (dispatch) => ({
  getData: (attendeeId) => dispatch(getData(attendeeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
