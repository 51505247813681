import styled from 'styled-components';

const InfoWrapper = styled.div`
  display: flex;

  p {
    margin-bottom: 8px;
  }
  p:first-of-type {
    color: #617381;
    min-width: 250px;
  }

  @media (max-width: 768px) {
    display: block;
    p:first-of-type {
      width: 100%;
    }
  }
`;

InfoWrapper.displayName = 'InfoWrapper';

export default InfoWrapper;
