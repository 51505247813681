import confetti from 'canvas-confetti';

export const celebrationAnimation = () => {
  // animation copied from here: https://www.kirilv.com/canvas-confetti/
  const count = 200;
  const defaults = {
    origin: { y: 0.5 },
  };

  const fire = (particleRatio, opts) => {
    confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      })
    );
  };

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    decay: 0.91,
    spread: 100,
  });
  fire(0.1, {
    decay: 0.92,
    spread: 120,
    startVelocity: 25,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};
