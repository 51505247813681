import React from 'react';

export const headerColumns = [
  {
    elem: (
      <div>
        student
        <br />
        name
      </div>
    ),
    name: 'user_name',
  },
  {
    elem: (
      <div>
        active program/
        <br />
        course
      </div>
    ),
    name: 'course_name',
  },
  {
    elem: (
      <div>
        program/course
        <br />
        start
      </div>
    ),
    name: 'start',
  },
  {
    elem: (
      <div>
        program/course
        <br />
        target end
      </div>
    ),
    name: 'end_date',
  },
  {
    elem: (
      <div>
        program/course
        <br />
        progress
      </div>
    ),
    name: 'target_completion',
  },
  {
    elem: (
      <div>
        job prep
        <br />
        progress
      </div>
    ),
    name: 'job_prep_progress',
  },
  {
    elem: (
      <div>
        last submission
        <br />
        approval date
      </div>
    ),
    name: 'last_approved_submission_at',
  },
  {
    elem: (
      <div>
        last activity
        <br />
        date
      </div>
    ),
    name: 'last_activity_at',
  },
  {
    elem: <div>WSG</div>,
    name: 'weekly_submission_goal',
  },
  {
    elem: 'mentor',
    name: 'mentor_name',
  },
  {
    elem: 'tutor',
    name: 'tutor_name',
  },
];
