import styled from 'styled-components';

const AppStyles = styled.div`
  background: #f1f1f1;
  padding: 40px 60px 40px 0;
  min-height: 600px;

  .loading_spinner_wrapper {
    background: transparent;
  }

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  @media (max-width: 479px) {
    background: none;

    span.ds-icon__close {
      top: 8px;
      right: 8px;
    }
  }
`;

AppStyles.displayName = 'AppStyles';

export default AppStyles;
