import AppStyles from './styles/AppStyles';
import Header from '../../containers/Header';
import React from 'react';
import TabSection from '../../containers/TabSection';

const App = () => (
  <AppStyles>
    <div className='ds-grid__container'>
      <Header />
      <TabSection />
    </div>
  </AppStyles>
);

export default App;
