import PropTypes from 'prop-types';
import React from 'react';
import StarRating from '../../../../shared/components/StarRating';
import StyledAnswer from './styles/StyledAnswer';
import SubText from './styles/SubText';
import { interviewAnswerTracking } from '../../../../shared/helpers/tracking';

const Answer = ({ answer, evaluatePreviousResponses, isMentorPage, showStudentResponse }) => {
  const handlePreviousEvaluation = (answer) => {
    interviewAnswerTracking(answer, 'ip_click_to_evaluate_answer');
    evaluatePreviousResponses(answer);
  };
  const answerTitle = isMentorPage ? `Student's answer  on ${answer.date}` : `Your answer on ${answer.date}`;

  return (
    <StyledAnswer onClick={() => isMentorPage ? showStudentResponse(answer) : handlePreviousEvaluation(answer)}>
      <div className="content">
        <div className="answer-section">
          <SubText className="ds-typography__small">{answerTitle}</SubText>
          {!isMentorPage
            ? answer.rating
              ? <StarRating key={answer.id} rating={answer.rating} modelId={answer.id} />
              : <p className="ds-typography__body">NOT YET REVIEWED</p>
            : ''
          }
        </div>
        <i className="fa fa-chevron-right tabs-arrow"></i>
      </div>
    </StyledAnswer>
  );
};

Answer.propTypes = {
  answer: PropTypes.shape({
    attachment: PropTypes.shape({
      fileIcon: PropTypes.string,
      fileName: PropTypes.string,
      id: PropTypes.number,
      s3Url: PropTypes.string,
    }),
    category: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.number,
    industry: PropTypes.string,
    notes: PropTypes.string,
    question: PropTypes.string,
    questionInsights: PropTypes.string,
    rating: PropTypes.number,
    shared: PropTypes.bool,
  }),
  evaluatePreviousResponses: PropTypes.func.isRequired,
  isMentorPage: PropTypes.bool,
  showStudentResponse: PropTypes.func,
};

export default Answer;
