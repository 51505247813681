import PropTypes from 'prop-types';
import React from 'react';
import StyledFormField from '../../../Registration/components/styles/StyledFormField';

const FilterWrapper = ({ label, children, isVerticalLabel = false }) => (
  <StyledFormField labelText={ label } isFilter={ true } isVerticalLabel={isVerticalLabel} >
    { children }
  </StyledFormField>
);

FilterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isVerticalLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

export default FilterWrapper;
