import React, { useEffect, useState } from 'react';
import { MessageList, useChannelStateContext } from 'stream-chat-react';
import { getOptionsForChatbot } from './services';
import ResponseMessage from './ResponseMessage';
import CustomMarkdownRender from './CustomMarkdownRender';

interface Props {
  messageActions: string[];
  onlySenderCanEdit: boolean;
}

const ChatbotMessageList: React.FC<Props> = ({
  messageActions,
  onlySenderCanEdit,
}) => {
  const [message, setMessage] = useState<any>({});
  const [option, setOption] = useState<string>('');
  const { channel } = useChannelStateContext();

  useEffect(() => {
    getOptionsForChatbot(channel?.data?.id);

    channel.on('message.new', (event: any) => {
      setMessage(event?.message);
      if (event?.message?.option) {
        setOption(event.message.option);
      }
    });
  }, []);

  const customRenderText = (text?: string) => {
    switch (true) {
      case 'llm_request_id' in message:
        return responseMessage(message, text);
      default:
        return defaultMessage(text);
    }
  };

  const responseMessage = (message: any, text?: string) => {
    if (option === text) {
      return defaultMessage(text);
    } else {
      return (
        <ResponseMessage
          text={text}
          messageId={message.id}
          requestId={message.llm_request_id}
        />
      );
    }
  };

  const defaultMessage = (text?: string) => (
    <CustomMarkdownRender text={text} />
  );

  return (
    <MessageList
      messageActions={messageActions}
      onlySenderCanEdit={onlySenderCanEdit}
      renderText={customRenderText}
    />
  );
};

export default ChatbotMessageList;
