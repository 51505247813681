import styled from 'styled-components';

const StyledIntroduction = styled.div`
  a {
    border-bottom: 1px solid;
  }
`;

StyledIntroduction.displayName = 'StyledIntroduction';

export default StyledIntroduction;
