import ProfilePage from '../../components/ProfilePage';
import { connect } from 'react-redux';
import { submitProfileData } from '../../actions';

export const mapStateToProps = (state) => {
  return {
    userData: state.onBoarding.userData,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitProfileData: (file, desc) =>
      dispatch(submitProfileData(file, desc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
