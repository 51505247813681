import React, { useEffect } from 'react';
import {
  getSurveyQuestions,
  getUserData,
  navigateTo,
  setCurrentPage,
} from '../../actions';
import Header from '../Header';
import Page from '../Page';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const LandingPage = ({ onGetQuestions, onGetUserData, onSetCurrentPage }) => {
  useEffect(() => {
    const subscribeToListener = () => {
      window.addEventListener(
        'hashchange',
        () => onSetCurrentPage(location.hash),
        false
      );
    };
    subscribeToListener();
    onSetCurrentPage(location.hash);
    return () => {
      window.removeEventListener(
        'hashchange',
        () => onSetCurrentPage(location.hash),
        false
      );
    };
  }, []);

  useEffect(() => {
    onGetQuestions();
    onGetUserData();
  }, []);
  return (
    <>
      <Header />
      <Page navigateTo={navigateTo} />
    </>
  );
};

LandingPage.propTypes = {
  onGetQuestions: PropTypes.func,
  onGetUserData: PropTypes.func,
  onSetCurrentPage: PropTypes.func,
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onGetQuestions: () => dispatch(getSurveyQuestions()),
    onGetUserData: () => dispatch(getUserData()),
    onSetCurrentPage: (page) => dispatch(setCurrentPage(page)),
  };
};

export default connect(null, mapDispatchToProps)(LandingPage);
