module.exports = (() => {
  var thisState = {}

  var init = function(){
    thisState.form = new CF.Form('#task-time-data-form')
  }

  return {
    init: init
  }
})()
