import {
  getMentees,
  handleCloseModal,
  storeOffPlatformCall,
} from '../../action';
import OffPlatformRegistrationModal from '../../components/Modals/OffPlatformRegistrationModal/index';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  mentorCallNotes: { modalType, mentees },
}) => ({
  mentees,
  modalType,
});

export const mapDispatchToProps = (dispatch) => ({
  getMentees: (input) => dispatch(getMentees(input)),
  handleCloseModal: () => dispatch(handleCloseModal()),
  storeOffPlatformCall: (attendeeId, callInformation) =>
    dispatch(storeOffPlatformCall(attendeeId, callInformation)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OffPlatformRegistrationModal);
