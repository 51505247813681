import React, { useEffect, useState } from 'react';
import CSCMainDashboard from '../../components/MainDashboard';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { newProjectAccess } from '../../actions';

const CSCMainDashboardContainer = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showCard, setShowCard] = useState(true);

  useEffect(() => {
    newProjectAccess().then((res) => {
      setShowCard(res);
      setShowMenu(res);
    });
  }, [props.userIndustry]);
  return (
    <CSCMainDashboard
      {...props}
      showProjectCard={showCard}
      showProjectMenu={showMenu}
    />
  );
};

CSCMainDashboardContainer.propTypes = {
  userIndustry: PropTypes.string,
};

export const mapStateToProps = ({ careerSupportCenter: { userIndustry } }) => ({
  userIndustry,
});

export default connect(mapStateToProps, null)(CSCMainDashboardContainer);
