import axios from 'axios';
import { getAuthToken } from '../../../../../../utils/getAuthToken';

export interface RateResponse {
  requestId: string;
  messageId: string;
  helpful: boolean;
}

export const getOptionsForChatbot = async (channelId: any) => {
  try {
    const response = await axios.get(
      `/api/v1/ai/initialize_chatbot?channel_id=${channelId}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const rateResponse = async ({
  requestId,
  messageId,
  helpful,
}: RateResponse) => {
  try {
    const response = await axios.post('/api/v1/ai/rate_llm_response', {
      authenticity_token: getAuthToken(),
      request_id: requestId,
      message_id: messageId,
      helpful,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
