import styled from 'styled-components';

const StyledButtonSection = styled.div`
  a {
    margin: 10px;
    width: 22%;
  }

  .ds-button__secondary, .ds-button__primary {
    font-size: 16px !important;
  }

  padding: 60px 0px 20px;
  display: flex;
  justify-content: center;

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    padding: 40px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a.ds-button__secondary, a.ds-button__primary {
      width: 50%;
    }
  }

  @media (max-width: 479px) {
    a.ds-button__secondary, a.ds-button__primary {
      width: 100%;
    }
  }
  .delete-button {
    background-color: var(--ds-color_error);
    border-color: var(--ds-color_error);
  }
`;

StyledButtonSection.displayName = 'StyledButtonSection';

export default StyledButtonSection;
