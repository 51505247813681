import styled from 'styled-components';

const Header = styled.div`
  background-color: #f4f5f6;
  display: flex;
  padding: 0px 30px;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
  }

  i {
    margin-right: 5px;
    position: relative;
    top: 2px;
  }
`;

Header.displayName = 'Header';

export default Header;
