import styled from 'styled-components';

const StyledStudentHandoffCard = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ds-color__grey--light_300);
  img {
    width: 64px;
    height: 64px;
  }
  div {
    margin: 10px;
  }
`;

StyledStudentHandoffCard.displayName = 'StyledStudentHandoffCard';

export default StyledStudentHandoffCard;
