import '@stream-io/stream-chat-css/dist/css/index.css';
import './styles.scss';
import React, { Suspense } from 'react';
import { LoadingChannels } from 'stream-chat-react';
import MessagingNotification from '../Messaging/MessagingNotification';
import MessagingPage from '../Messaging/MessagingPage';
import MessagingWindow from '../Messaging/MessagingWindow';
import { Provider } from 'react-redux';
import { StreamChat } from 'stream-chat';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const notificationsContainer = document.getElementById(
  'MessagingNotifications'
);
const pageContainer = document.getElementById('MessagingPage');
const messagingWindowContainer = document.getElementById('MessagingWindow');

export let chatClient = null;

if (notificationsContainer) {
  const notificationsContainerRoot = createRoot(notificationsContainer);

  chatClient = new StreamChat(notificationsContainer.dataset.stream_chat_key);

  notificationsContainerRoot.render(
    <Provider store={store}>
      <Suspense
        fallback={
          <i className='ds-icon__mail-fill ds-typography__h5 ds-color__grey' />
        }
      >
        <MessagingNotification
          isExistingUser={
            notificationsContainer.dataset.is_existing_user === 'true'
          }
        />
      </Suspense>
    </Provider>
  );
}

if (pageContainer) {
  const pageContainerRoot = createRoot(pageContainer);

  pageContainerRoot.render(
    <Provider store={store}>
      <Suspense fallback={<LoadingChannels />}>
        <MessagingPage />
      </Suspense>
    </Provider>
  );
}

if (messagingWindowContainer) {
  const messagingWindowContainerRoot = createRoot(messagingWindowContainer);

  messagingWindowContainerRoot.render(
    <Provider store={store}>
      <Suspense fallback={<span />}>
        <MessagingWindow />
      </Suspense>
    </Provider>
  );
}
