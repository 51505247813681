import React, { useState } from 'react';
import Label from './styles/Label';
import MentorNoteButtons from '../../containers/MentorNotes/MentorNotesButtons';
import MentorNoteCategories from './MentorNoteCategories';
import MentorNoteStyles from './styles/MentorNote';
import PropTypes from 'prop-types';

const MentorNote = ({ edited, note, owner, shouldTruncate }) => {
  const MAX_LENGTH = 750;
  const [truncated, setTruncated] = useState(shouldTruncate);
  const truncate = (text) =>
    truncated ? `${text.substring(0, MAX_LENGTH - 3)}...` : text;
  const toggleShow = () => setTruncated(!truncated);

  return (
    <MentorNoteStyles owner={owner}>
      <div>
        <img src={note.avatar} className='img-circle' />
        <div>
          <p className='ds-typography__body--bold'>
            {owner ? 'You' : note.name}
          </p>
          <Label>{note.label}</Label>
        </div>
      </div>
      <div>
        {owner && !note.shared && (
          <span className='ds-typography__small--bold visible-label'>
            Visible only to you
          </span>
        )}
        <p className='ds-typography__body--bold'>{note.title}</p>
        <MentorNoteCategories categories={note.categories} />
        <p className='ds-typography__body'>
          {truncate(note.content)}
          {edited && (
            <span className='ds-typography__small edited-label'>(edited)</span>
          )}
          {shouldTruncate && (
            <a className='ds-typography__small' onClick={toggleShow}>
              {truncated ? 'Show more' : 'Show less'}
            </a>
          )}
        </p>
        <div>
          <p className='ds-typography__small--bold'>added on {note.created}</p>
          {owner && <MentorNoteButtons id={note.id} />}
        </div>
      </div>
    </MentorNoteStyles>
  );
};

MentorNote.propTypes = {
  edited: PropTypes.bool.isRequired,
  note: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired,
    content: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shared: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    truncated: PropTypes.bool,
    updated: PropTypes.string.isRequired,
  }),
  owner: PropTypes.bool.isRequired,
  shouldTruncate: PropTypes.bool,
};

export default MentorNote;
