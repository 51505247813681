import React from 'react';

const HowToWorkFromHome = () => (
  <>
    <p className='ds-typography__body break'>
      While you can work from home by negotiating it, applying to companies that
      offer the option, and by freelancing, there are some things to know in
      order to be effective while working as part of a distributed team. Some{' '}
      <a
        onClick={() =>
          window.open(
            'https://www.forbes.com/sites/harmoncullinan/2019/05/16/to-meet-or-email-that-is-the-question/'
          )
        }
      >
        best practices
      </a>{' '}
      include recognizing that written communication increases the chance of
      misinterpretation and identifying when a message should be a call,
      meeting, or email.
    </p>
    <p className='ds-typography__body break'>
      It is also key to not assume everyone is available at any given moment, or
      that they know who you are without an introduction. Additionally, you can
      batch your messages and questions so that every thought is not an alert
      for someone to respond, especially when you work across time zones. Here
      are tips on{' '}
      <a
        onClick={() =>
          window.open(
            'https://www.virtualvocations.com/blog/telecommuting-survival/virtual-office-etiquette-common-sense-tips/'
          )
        }
      >
        Remote Work / Virtual Office Etiquette.
      </a>
    </p>

    <h5 className='ds-typography__h5'>Staying motivated</h5>
    <p className='ds-typography__body break'>
      One of the challenges of working remotely are the environmental cues that
      let us know when work is done. Here are some ways to create your own
      structure throughout the day:
    </p>
    <ul>
      <li>
        Schedule regular breaks, especially lunch, so that you can remain
        focused at key times.
      </li>
      <li>
        Create a list of priorities at the beginning of your day (or the night
        before) to keep you on track of key project deadlines.
      </li>
      <li>
        Make a conscious, intentional effort to get to know your team so that
        when work gets tough you can lean on each other for help.
      </li>
      <li>
        At the end of the day, shut work off if possible and even leave your
        designated workspace so that when you return you can be more refreshed.
      </li>
    </ul>
    <p className='ds-typography__body break'>
      You can also build a routine to signal to your body that it is time to
      start or stop work. Additionally, here are{' '}
      <a
        onClick={() =>
          window.open(
            'https://careerstonegroup.com/blog/147/7-Tips-to-Stay-Engaged-as-a-Remote-Worker'
          )
        }
      >
        7 tips to Stay Engaged as a Remote Worker.
      </a>
    </p>
    <h5 className='ds-typography__h5'>Remote work skills</h5>
    <p className='ds-typography__body break'>
      Key skills that increase success in remote work can be developed or
      improved. Here are some examples of skills and resources to help:
    </p>
    <ul>
      <li>
        Organization and Time Management:{' '}
        <a
          onClick={() =>
            window.open(
              'https://skillandcare.com/how-to-improve-organizational-skills/'
            )
          }
        >
          How To Improve Your Organizational Skills – 11 Practical Tips
        </a>
      </li>
      <li>
        Collaboration:{' '}
        <a
          onClick={() =>
            window.open(
              'https://www.indeed.com/career-advice/career-development/collaboration-skills'
            )
          }
        >
          Collaboration Skills: Definition and Examples
        </a>
      </li>
      <li>Project Management</li>
      <li>Remote Work Tools (See section Remote collaboration tools)</li>
    </ul>
    <p className='ds-typography__body break'>
      PRO TIP: Create your own schedule, routine, or checklist to add structure
      to your day.
    </p>
    <h5 className='ds-typography__h5'>Self-care resources</h5>
    <p className='ds-typography__body break'>
      Another key to setting yourself up for success in remote work is
      practicing self-care. Top tips include creating boundaries like
      maintaining a dedicated work and living space, connecting with your
      colleagues or having a balanced amount of time for deep thinking, and
      acknowledging your successes at the same time you identify opportunities
      to improve. Here are some targeted resources to help get you started:
    </p>
    <ul>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://hbr.org/2020/03/a-guide-for-working-from-home-parents'
            )
          }
        >
          A Guide for Working (From Home) Parents
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://blogs.sas.com/content/efs/2020/06/11/tips-and-tricks-for-working-remote-neurodivergent-edition/'
            )
          }
        >
          Tips and Tricks for Working Remote: Neurodivergent Edition
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            window.open(
              'https://www.indeed.com/career-advice/career-development/extroverts-working-from-home'
            )
          }
        >
          A Definitive Guide for Extroverts Working From Home
        </a>
      </li>
    </ul>
  </>
);

export default HowToWorkFromHome;
