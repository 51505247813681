import styled from 'styled-components';

const StyledStatusText = styled.p`
  margin: 0 0 40px 0;
  min-height: 30px;
  text-align: center;
  i {
    color: var(--ds-color_error);
    margin-right: 9px;
    font-size: var(--md-font);
  }

  span {
    font-size: 18px !important;
  }
`;

StyledStatusText.displayName = 'StyledStatusText';

export default StyledStatusText;
