import PropTypes from 'prop-types';
import React from 'react';
import StyledButton from '../../Students/components/styles/StyledButton';
import StyledDashboardHeader from '../../../../CareerSupportCenter/components/DashboardWrapper/styles/StyledDashboardHeader';
import StyledDashboardWrapper from '../../../../CareerSupportCenter/components/DashboardWrapper/styles/StyledDashboardWrapper';
import StyledGrid from '../../../../CareerSupportCenter/components/DashboardWrapper/styles/StyledGrid';
import StyledHeaderWrapper from '../../../../CareerSupportCenter/components/DashboardWrapper/styles/StyledHeaderWrapper';

const AppWrapper = ({
  children,
  header,
}) => {
  const handleBackClick = () => {
    window.location.href = '/en/admin';
  };

  return (
    <StyledDashboardWrapper nonStudentView={true}>
      <StyledGrid
        className="ds-grid__container grid-wrapper"
      >
        <StyledButton
          className='ds-button__secondary'
          onClick={handleBackClick}
        >
          <i className='fa fa-arrow-left'></i>
          <span>Back to Admin view</span>
        </StyledButton>
        <StyledHeaderWrapper>
          <StyledDashboardHeader className="ds-typography__h2" nonStudentView={true}>
            {header}
          </StyledDashboardHeader>
          <hr />
        </StyledHeaderWrapper>
        {children}
      </StyledGrid>
    </StyledDashboardWrapper>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
};

export default AppWrapper;
