import { Tab, TabOption } from './styles';
import PropTypes from 'prop-types';
import React from 'react';
import { noop } from 'lodash';

const TabMenu = ({ options = [], selectedOption = '', onClick = noop }) => {
  return (
    <Tab>
      {options.map((option, index) => (
        <TabOption
          key={`${option}-${index}`}
          className="ds-typography__body"
          isSelected={selectedOption === option}
          onClick={() => onClick(option)}
        >
          {option}
        </TabOption>
      ))}
    </Tab>
  );
};

TabMenu.propTypes = {
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  selectedOption: PropTypes.string,
};

export default TabMenu;
