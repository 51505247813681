import styled from 'styled-components';

const StyledMediaCategory = styled.div`
  .mobile-border {
    display: none;
  }

  & > div:first-of-type {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      cursor: pointer;

      i {
        margin-left: 8px;
        color: var(--ds-color__indigo);
        font-size: 22px;
      }
    }
  }

  .resource-row {
    margin-bottom: 40px;

    &.hidden {
      display: none;
    }
  }

  @media (max-width: 767px) {
    & > div > p {
      margin: 17px 0;
    }

    .mobile-border {
      display: block;
    }

    .resource-row {
      margin-top: 32px;
      margin-bottom: 10px;
    }
  }
`;

StyledMediaCategory.displayName = 'StyledMediaCategory';

export default StyledMediaCategory;
