import React, { useEffect, useState } from 'react';
import CallCard from './CallCard';
import FlashMessageContainer from '../../../shared/flashMessageContainer.jsx';
import Loading from '../../../shared/components/Loading';
import PaginationControls from '../../../shared/components/Pagination';
import PropTypes from 'prop-types';
import StyledAnchorLink from './styles/StyledAnchorLink';
import StyledButton from './styles/StyledButton';
import StyledCallNotesLists from './styles/StyledCallNotesLists';

const CallNotesLists = ({
  activeCourse,
  openNewNoteModal,
  calendarEntries,
  flashMessage,
  showFlash,
  getCallNotes,
  calendarEntriesPagination,
  openOffPlatformCallModal,
  reloadCalendarEntries,
}) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getCalendarEntries(calendarEntriesPagination.currentPage);
  }, []);

  useEffect(() => {
    if (reloadCalendarEntries) {
      getCalendarEntries(calendarEntriesPagination.currentPage);
    }
  }, [reloadCalendarEntries]);

  const getCalendarEntries = async (page) => {
    await getCallNotes(page);
    setLoading(false);
  };

  return (
    <>
      <StyledCallNotesLists>
        <StyledButton
          className='ds-button__secondary'
          disabled={false}
          loading={isLoading}
          onClick={openOffPlatformCallModal}
        >
          Add off-platform call
        </StyledButton>

        <Loading isLoading={isLoading}>
          {calendarEntries.length < 1 && (
            <p className='ds-typography__body'>
              Congrats! Keep your notes nice and tidy. Come back when you’ll
              have your next call with a student.
            </p>
          )}
          <StyledAnchorLink>
            <a
              className='ds-typography__body ds-typography__anchor'
              href={`/en/course/${activeCourse}/call_notes`}
            >
              Notes history
            </a>
          </StyledAnchorLink>
          {calendarEntries.length > 0 && (
            <>
              {calendarEntries.map((calendarEntry) => (
                <CallCard
                  key={calendarEntry.id}
                  onClick={openNewNoteModal}
                  calendarEntry={calendarEntry}
                />
              ))}
              <PaginationControls
                activePage={calendarEntriesPagination.currentPage}
                itemsCountPerPage={calendarEntriesPagination.perPage}
                totalItemsCount={calendarEntriesPagination.totalEntries}
                pageRangeDisplayed={calendarEntriesPagination.perPage}
                onChange={getCalendarEntries}
              />
            </>
          )}
        </Loading>
      </StyledCallNotesLists>
      {showFlash && (
        <FlashMessageContainer status='success' message={flashMessage} />
      )}
    </>
  );
};

CallNotesLists.propTypes = {
  activeCourse: PropTypes.number,
  calendarEntries: PropTypes.array,
  calendarEntriesPagination: PropTypes.shape({
    currentPage: PropTypes.number,
    perPage: PropTypes.number,
    totalEntries: PropTypes.number,
  }),
  flashMessage: PropTypes.string,
  getCallNotes: PropTypes.func.isRequired,
  openNewNoteModal: PropTypes.func.isRequired,
  openOffPlatformCallModal: PropTypes.func.isRequired,
  reloadCalendarEntries: PropTypes.bool.isRequired,
  showFlash: PropTypes.bool,
};

export default CallNotesLists;
