import React, { useEffect } from 'react';
import { getCandidates, getInitialData, sendWelcomeEmail } from '../../actions';
import App from '../../components/App';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const AppContainer = ({
  candidatesList,
  candidatesListLoaded,
  candidateStates,
  getCandidates,
  getInitialData,
  interviewers,
  sendWelcomeEmail,
}) => {
  useEffect(() => {
    getInitialData();
    getCandidates();
  }, []);

  return (
    <App
      candidatesList={candidatesList}
      candidatesListLoaded={candidatesListLoaded}
      candidateStates={candidateStates}
      interviewers={interviewers}
      sendWelcomeEmail={sendWelcomeEmail}
    />
  );
};

AppContainer.propTypes = {
  candidateStates: PropTypes.array.isRequired,
  candidatesList: PropTypes.array.isRequired,
  candidatesListLoaded: PropTypes.bool.isRequired,
  getCandidates: PropTypes.func.isRequired,
  getInitialData: PropTypes.func.isRequired,
  interviewers: PropTypes.array.isRequired,
  sendWelcomeEmail: PropTypes.func.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  getCandidates: () => dispatch(getCandidates()),
  getInitialData: () => dispatch(getInitialData()),
  sendWelcomeEmail: (id) => dispatch(sendWelcomeEmail(id)),
});

export const mapStateToProps = ({ instructorsCandidatesPage }) => ({
  candidateStates: instructorsCandidatesPage.candidateStates,
  candidatesList: instructorsCandidatesPage.candidatesList,
  candidatesListLoaded: instructorsCandidatesPage.candidatesListLoaded,
  interviewers: instructorsCandidatesPage.interviewers,
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
