import styled from 'styled-components';

const StyledNavProgress = styled.div`
  height: 160px;
  width: 1px;
  background-color: #54D0C6;

  ${({ isReducedMenu }) => isReducedMenu && `
    height: 106px;
  `}

`;

StyledNavProgress.displayName = 'StyledNavProgress';
export default StyledNavProgress;
