import {
  CandidateInfo,
  CandidatePicture,
  CheckboxWrapper,
  StyledHeader,
} from './styles';
import { documentsRequired, isCertified } from '../../../helpers';
import Checkbox from '../../../../../shared/components/Checkbox';
import { InfoWrapper } from '../styles';
import PropTypes from 'prop-types';
import React from 'react';
import { formattedValue } from '../../../utils';
import { useHistory } from 'react-router-dom';

const Header = ({
  candidate,
  handleContractCheckbox,
  handleOpen,
  updateCandidate,
}) => {
  const history = useHistory();
  const editCandidate = (id) =>
    history.push(`/en/admin/instructors/candidates/${id}/edit`);

  const bulletPoints = [
    {
      label: 'Creation date',
      value: formattedValue(candidate.created_at, 'date'),
    },
    { label: 'Role', value: candidate.candidate_role },
    {
      label: 'Working with EU students',
      value: candidate.are_documents_required ? 'yes' : 'no',
    },
    { label: 'Email', value: candidate.email },
    { label: 'Interviewer', value: candidate?.interviewer?.first_name },
  ];

  const hrCheckboxes = [
    {
      isVisible: isCertified(candidate),
      key: 'protocol_created',
      name: 'Protocol created',
      value: candidate.protocol_created,
    },
    {
      isVisible: candidate.is_specialization_mentor,
      key: 'spec_mentor_created',
      name: 'Spec. mentor created',
      value: candidate.spec_mentor_created,
    },
  ];

  const contractCheckboxes = [
    {
      disabled: candidate.contract_sent,
      key: 'contract_sent',
      name: 'Contract sent',
      value: candidate.contract_sent,
    },
    {
      disabled: candidate.hello_sign,
      key: 'hello_sign',
      name: 'Contract signed',
      value: candidate.hello_sign,
    },
  ];

  const checkboxes = [
    {
      disabled: true,
      isVisible: true,
      key: 'billing_profile_approved',
      name: 'Billing and tax approved ',
      value: candidate.billing_profile_approved,
    },
    {
      disabled: false,
      isVisible: documentsRequired(candidate),
      key: 'documents_checked',
      name: 'Documents checked',
      value: candidate.documents_checked,
    },
    {
      disabled: false,
      isVisible: true,
      key: 'onboarding_tasks',
      name: 'Onboarding task done',
      value: candidate.onboarding_tasks,
    },
    {
      disabled: false,
      isVisible: true,
      key: 'slack_setup',
      name: 'Slack created ',
      value: candidate.slack_setup,
    },
  ];

  const billingProfileRejected =
    candidate.personal_billing_information?.reason?.join(', ');

  return (
    <StyledHeader>
      <div className='ds-grid__row'>
        <InfoWrapper borderRight className='ds-grid__col-xs-5'>
          <div className='ds-grid__row'>
            <CandidateInfo className='ds-grid__col-xs-12'>
              <div>
                <CandidatePicture src='https://images.careerfoundry.com/public/framework/head_v2.svg' />
              </div>
              <div>
                <h3 className='ds-typography__h3 text-left'>
                  {candidate.name}
                </h3>
                <p className='ds-typography__body'>
                  <b>Candidate status</b>: {candidate.state}
                </p>
                {billingProfileRejected && (
                  <p className='ds-typography__body'>
                    <b>Change request cause</b>: {billingProfileRejected}
                  </p>
                )}
                <p className='ds-typography__body'>
                  <b>Status date</b>:{' '}
                  {candidate.state_updated_at &&
                    formattedValue(candidate.state_updated_at, 'date')}
                </p>
                <p className='ds-typography__body'>
                  <b>Last update date</b>:{' '}
                  {formattedValue(candidate.updated_at, 'date')}
                </p>
                <button
                  className='ds-button__secondary delete-button'
                  onClick={() => handleOpen('confirmDeletion')}
                >
                  Delete
                </button>
                <button
                  className='ds-button__primary'
                  onClick={() => handleOpen('confirmActivation')}
                  disabled={!candidate['ready_for_approval?']}
                >
                  Activate candidate
                </button>
              </div>
            </CandidateInfo>
          </div>
        </InfoWrapper>
        <div className='ds-grid__col-xs-7'>
          <div className='ds-grid__row'>
            <InfoWrapper className='ds-grid__col-xs-6' borderRight paddingLeft>
              {bulletPoints.map((point) => (
                <p className='ds-typography__body' key={point.label}>
                  <b>{point.label}</b>: {point.value}
                </p>
              ))}
              <p className='ds-typography__body--bold'>
                Course(s):{' '}
                {candidate.state === 'Sign-up pending' && (
                  <i
                    onClick={() => editCandidate(candidate.id)}
                    className='ds-icon__edit'
                  ></i>
                )}
              </p>
              {candidate.courses &&
                candidate.courses.map((course) => (
                  <p className='ds-typography__body' key={course.slug}>
                    {course.title}
                  </p>
                ))}
            </InfoWrapper>
            <InfoWrapper className='ds-grid__col-xs-6' paddingLeft>
              {hrCheckboxes.map(
                (checkbox) =>
                  checkbox.isVisible && (
                    <CheckboxWrapper key={checkbox.name}>
                      <p className='ds-typography__body--bold'>
                        {checkbox.name}
                      </p>
                      <Checkbox
                        onClick={(ev) =>
                          updateCandidate(candidate.id, {
                            [checkbox.key]: ev.target.checked,
                          })
                        }
                        defaultChecked={checkbox.value}
                      />
                    </CheckboxWrapper>
                  )
              )}
              {contractCheckboxes.map((checkbox) => (
                <CheckboxWrapper
                  key={checkbox.name}
                  onClick={
                    !checkbox.disabled
                      ? () => handleContractCheckbox(checkbox.key)
                      : null
                  }
                  className={`${
                    checkbox.disabled ? 'disabled-checkbox' : ''
                  } checkbox-overlay`}
                >
                  <p className='ds-typography__body--bold'>{checkbox.name}</p>
                  <Checkbox
                    defaultChecked={checkbox.value}
                    disabled={checkbox.disabled}
                    className={checkbox.disabled ? 'disabled-checkbox' : ''}
                  />
                </CheckboxWrapper>
              ))}
              {checkboxes.map(
                (checkbox) =>
                  checkbox.isVisible && (
                    <CheckboxWrapper key={checkbox.name}>
                      <p className='ds-typography__body--bold'>
                        {checkbox.name}
                      </p>
                      <Checkbox
                        onClick={(ev) =>
                          updateCandidate(candidate.id, {
                            [checkbox.key]: ev.target.checked,
                          })
                        }
                        defaultChecked={checkbox.value}
                        disabled={checkbox.disabled}
                        className={checkbox.disabled ? 'disabled-checkbox' : ''}
                      />
                    </CheckboxWrapper>
                  )
              )}
            </InfoWrapper>
          </div>
        </div>
      </div>
    </StyledHeader>
  );
};

Header.propTypes = {
  candidate: PropTypes.object,
  handleContractCheckbox: PropTypes.func,
  handleOpen: PropTypes.func,
  updateCandidate: PropTypes.func,
};

export default Header;
