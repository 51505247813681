import React from 'react';
import StyledLargeTile from '../../UsefulLinks/styles/StyledLargeTile';

const CommunityTile = () => {
  const communityLinks = [
    {
      event: 'online_networking_slack_link_clicked',
      link: 'https://careerfoundry.slack.com/archives/#online_networking',
      text: '#Online Networking',
    },
    {
      event: 'careers_slack_link_clicked',
      link: 'https://careerfoundry.slack.com/archives/#careers',
      text: '#Careers',
    },
    {
      event: 'job_leads_slack_link_clicked',
      link: 'https://careerfoundry.slack.com/archives/#job_leads',
      text: '#Job Leads',
    },
    {
      event: 'portfolio_slack_link_clicked',
      link: 'https://careerfoundry.slack.com/archives/#portfolio_feedback',
      text: '#Portfolio Feedback',
    },
  ];

  return (
    <StyledLargeTile
      description='Here you can find links to Slack groups for students who are searching for a job'
      icon={
        <img
          src='https://coach-courses-us.s3.amazonaws.com/public/icons/csc-chat.png'
          alt='icon'
        />
      }
      links={communityLinks}
      title='Community'
    />
  );
};

export default CommunityTile;
