import AdminEnrollmentLinksIndex from '../../../react/admin_enrollment_links_index';
import React from 'react';
import { createRoot } from 'react-dom/client';

const adminEnrollmentLinksIndexContainer = document.querySelector(
  '.admin__enrollment_links_index'
);

if (adminEnrollmentLinksIndexContainer) {
  const adminEnrollmentLinksIndexContainerRoot = createRoot(
    adminEnrollmentLinksIndexContainer
  );

  adminEnrollmentLinksIndexContainerRoot.render(<AdminEnrollmentLinksIndex />);
}
