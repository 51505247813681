window.loadingZXCVBN = false;

export const insertZXCVBNScript = () => {
  if (window.loadingZXCVBN || typeof window.zxcvbn === 'function') return;
  const scriptElem = document.createElement('script');
  scriptElem.src = 'https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js';
  scriptElem.async = true;
  document.body.appendChild(scriptElem);
  window.loadingZXCVBN = true;
  scriptElem.addEventListener('load', () => {
    window.loadingZXCVBN = undefined;
  });
};

export const setPasswordStrength = e => {
  if (!e) return;
  const { score, textLabel } = getPasswordScore(e.target.value);
  document.querySelector('progress.strength-meter').value = score;
  document.querySelector('span.label-text').innerHTML = textLabel;
};

export const getPasswordScore = password => {
  if (!password || password.length < 8) {
    return {
      score: 0,
      textLabel: 'At least 8 characters',
    };
  }
  if (typeof window.zxcvbn !== 'function') {
    return {
      score: 0,
      textLabel: 'Password strength not available',
    };
  }
  const resultObject = window.zxcvbn(password);
  let textLabel;
  switch (resultObject.score) {
  case 1:
  case 2:
    textLabel = 'Weak';
    break;
  case 3:
    textLabel = 'Medium';
    break;
  case 4:
    textLabel = 'Strong';
    break;
  default:
    textLabel = 'Weak';
  }
  return {
    score: resultObject.score || 1,
    textLabel: textLabel || 'Weak',
  };
};

window.insertZXCVBNScript = insertZXCVBNScript;
