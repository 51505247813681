import React, { useState } from 'react';
import ModalWrapper from '../../shared/ModalWrapper';
import NameSearchForm from './NameSearchForm';
import PropTypes from 'prop-types';
import RegistrationForm from './RegistrationForm';
import StyledOffPlatformForm from './styles/StyledOffPlatformForm';

const CCA_COURSE_ID = '55';

const OffPlatformRegistrationModal = ({
  storeOffPlatformCall,
  getMentees,
  handleCloseModal,
  mentees,
  modalType,
}) => {
  const [nameInput, setNameInput] = useState('');
  const [attendee, setAttendee] = useState(null);
  const [callInformation, setCallInformation] = useState({});
  const submitDisabled =
    !attendee ||
    !callInformation.callRequestScreenshot ||
    !callInformation.startTime;

  const handleNameSearch = async (input) => {
    getMentees(input, CCA_COURSE_ID);
    setNameInput(input);
  };

  const handleNextClick = async () => {
    if (!submitDisabled) {
      storeOffPlatformCall(attendee, callInformation);
    }
  };

  return (
    <ModalWrapper
      currentState={modalType}
      onNextClick={handleNextClick}
      onPrevClick={() => handleCloseModal()}
      submitDisabled={submitDisabled}
      leftButtons={true}
    >
      <StyledOffPlatformForm>
        <h4 className='ds-typography__h4'>Add off-platform call</h4>
        <p className='ds-typography__body'>
          Fill out all required fields and the call notes to add an off-platform
          call.
        </p>
        <span className='ds-typography__body ds-typography__small cf-text-right'>
          *required field
        </span>
        <NameSearchForm
          attendee={attendee}
          nameInput={nameInput}
          handleNameSearch={handleNameSearch}
          mentees={mentees}
          setAttendee={setAttendee}
        />
        <RegistrationForm
          callInformation={callInformation}
          setCallInformation={setCallInformation}
        />
      </StyledOffPlatformForm>
    </ModalWrapper>
  );
};

OffPlatformRegistrationModal.propTypes = {
  getMentees: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  mentees: PropTypes.array.isRequired,
  modalType: PropTypes.string.isRequired,
  storeOffPlatformCall: PropTypes.func.isRequired,
};

export default OffPlatformRegistrationModal;
