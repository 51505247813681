import React from 'react';
import { StyledBanner } from './styles';

interface BannerProps {
  hasBillingChangeRequest?: boolean;
}

const Banner: React.FC<BannerProps> = ({ hasBillingChangeRequest }) => {
  return hasBillingChangeRequest ? (
    <StyledBanner>
      <p className='ds-text__body--medium ds-text--bold'>Update pending</p>
      <i className='ds-icon__help-line'></i>
    </StyledBanner>
  ) : null;
};

export default Banner;
