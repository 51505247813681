import PropTypes from 'prop-types';
import React from 'react';
import StyledMediaResourceImage from '../styles/StyledMediaResourceImage';

const MediaResourceImage = ({ resource }) => {
  return (
    <StyledMediaResourceImage>
      {resource.duration && (
        <p className='ds-typography__small--bold'>{resource.duration}</p>
      )}
      <img src={resource.image} alt={resource.title} />
    </StyledMediaResourceImage>
  );
};

MediaResourceImage.propTypes = {
  resource: PropTypes.shape({
    categories: PropTypes.array.isRequired,
    description: PropTypes.string,
    duration: PropTypes.string,
    image: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

export default MediaResourceImage;
