import { loadStripeLib } from '../../services/StripeHelpers';
import ApiService from '../../services/ApiService';
import { getAuthToken } from '../../utils/getAuthToken';
import { EU_WITHOUT_DE } from '../../globals/constants';

module.exports = (() => {
  var thisState = {};
  var stripe = null;
  var cardElement = null;

  const init = function () {
    bindFunctions();
    showPaymentMethod();
    displayVatField();
    showTaxRate();
    showTaxStatus();
    thisState.form = new CF.Form('.update-billing-profile-form');

    loadStripeLib($).then((stripeInstance) => {
      stripe = stripeInstance;
      const elements = stripe.elements();
      cardElement = elements.create('card', { hidePostalCode: true, style: style });
      cardElement.mount('#card-element');
    });
  };

  const style = {
    base: {
      fontFamily: 'TradeGothic,Helvetica,Arial,sans-serif',
      fontWeight: 400,
      color: 'rgb(34, 60, 80)',
      iconColor: '#a7b1b9',
      fontSize: '18px',
      '::placeholder': {
        color: '#a7b1b9',
        fontWeight: 300,
        fontFamily: 'TradeGothic,Helvetica,Arial,sans-serif',
      },
    },
  };

  var bindFunctions = function () {
    $('[data-toggle="tooltip"]').tooltip();
    $(document).on('change', '#country', function () { clearValues('#full_taxation'); showTaxRate(); showTaxStatus(); });
    $(document).on('change', '#paymentMethod', function () { clearValues('#payment'); showPaymentMethod(); });
    $(document).on('change', '#taxStatus', function () { clearValues('#taxation'); showTaxRate(); });
    $(document).on('change', '#billing-country', displayVatField);
    $('.navigate_back--js').on('click', _navigateBack);
    $('#cc-b2b__checkbox').on('change', _toggleB2BFields);
    $('#cc-terms__checkbox').on('change', _toggleSubmitButton);
    $('#change_card').on('change', _showCardFields);
    $('.form-submit--js').on('click', _validateAndSubmit);
    $('#contract-billing-profile-b2b__checkbox').on('change', toggleContractBillingProfileBusinessFields);
  };

  const toggleContractBillingProfileBusinessFields = value => {
    const busienssFields = document.querySelector('.contract-billing-profile-b2b--js');
    const businessNameField = document.getElementById('contract-billing-profile-business_name');

    if (value.currentTarget.checked) {
      busienssFields.classList.remove('hidden');
      businessNameField.setAttribute('required', '');
    } else {
      busienssFields.classList.add('hidden');
      businessNameField.removeAttribute('required');
    }
    displayVatField();
  };

  const displayVatField = () => {
    const checkbox = document.getElementById('contract-billing-profile-b2b__checkbox');
    const vatField = document.querySelector('.contract-billing-profile-vat--js');
    const country = document.getElementById('billing-country');

    if (EU_WITHOUT_DE.indexOf(country.value) > -1 && checkbox.checked) {
      vatField.classList.remove('hidden');
    } else {
      vatField.classList.add('hidden');
    }
  };

  var _clearBusinessDetails = function (form) {
    $('#contract-billing-profile-vat_id').val('');
    $('#contract-billing-profile-business_name').val('');
  };

  var _validateAndSubmit = function (e) {
    e.preventDefault();
    const btn = document.querySelector('.form-submit--js');
    _setLoadingState(btn);

    if (thisState.form.validate()) {
      if (!$('#contract-billing-profile-b2b__checkbox').is(':checked')) {
        _clearBusinessDetails(thisState.form);
      }

      if ($('#change_card').is(':checked')) {
        _updateCard(btn);
      } else {
        $('#edit_billing_profile').submit();
      }
    } else {
      _resetLoadingState(btn);
    }
  };

  const _setLoadingState = btn => {
    btn.innerHTML = CF.html.loadingSpinner;
    btn.setAttribute('disabled', '');
  };

  const _resetLoadingState = btn => {
    btn.innerHTML = 'Save & Exit';
    btn.removeAttribute('disabled');
  };

  var _updateCard = function (btn) {
    const apiService = new ApiService('/api/v1/credit_cards.json');
    const authToken = getAuthToken();

    apiService.makePostCall({
      authenticity_token: authToken,
      credit_card: {
        payment_method_id: null,
      },
    }).then(result => {
      const clientSecret = result.data.clientSecret;
      const nameOnCard = $('#nameOnCard').val();

      stripe.handleCardSetup(clientSecret, cardElement, {
        payment_method_data: { billing_details: { name: nameOnCard } },
      }).then(result => {
        if (result.error) {
          _handleStripeErrors(result);
          _resetLoadingState(btn);
          Rollbar.error('Stripe credit card update error', result.error);
        } else {
          apiService.makePostCall({
            authenticity_token: authToken,
            billing_profile_id: $('#billing_profile_id').val(),
            credit_card: {
              payment_method_id: result.setupIntent.payment_method,
            },
          }).then(result => {
            _clearAllErrors();
            $('.stripe_result__container').text('Credit Card successfully updated!');
            $('#edit_billing_profile').submit();
          }).catch(error => {
            _handleStripeErrors({ error: error });
            _resetLoadingState(btn);
            Rollbar.error('Billing profile credit card update error', error);
          });
        }
      });
    });
  };

  var _handleStripeErrors = function (response) {
    _clearAllErrors();
    $('.stripe_error__container').text(response.error.message);
    var error = response.error.code;
  };

  var _clearAllErrors = function () {
    var fields = ['cc-number', 'cc-cvc', 'cc-exp'];
    $('.stripe_error__container').text('');
    $('.stripe_result__container').text('');
    fields.map(x => $(`#${x}`).removeClass('cf-form__input-error'));
  };

  var _showCardFields = function () {
    $('.enroll__card__options').toggleClass('hidden');
    $('.card-info--js').toggleClass('hidden')
      .find('input').prop('required', (i, v) => !v);
  };

  var _navigateBack = function () {
    var elm = document.getElementById('edit_billing_profile');
    window.location.href = `/en/users/${elm.dataset.slug}/settings#user_payment`;
  };

  var _validateForm = function () {
    thisState.form.validate();
  };

  var _toggleB2BFields = function () {
    $('.cc-b2b--js').toggleClass('hidden')
      .find('input').prop('required', (i, v) => !v);
  };

  var _toggleSubmitButton = function () {
    var btn = $('.form-submit--js');
    if ($(this).is(':checked')) {
      btn.removeClass('button--primary-disabled');
      btn.prop('disabled', false);
      btn.addClass('button--primary-petrol');
    } else {
      btn.addClass('button--primary-disabled');
      btn.prop('disabled', true);
    }
  };

  var EUROZONE = ['AT', 'BE', 'CY',
    'EE', 'FI', 'FR',
    'GR', 'IE', 'IT',
    'LV', 'LT', 'LU',
    'MT', 'NL', 'PT',
    'SK', 'SI', 'ES'];

  var showTaxStatus = function () {
    var country = $('#country');

    if (country.val() === 'DE') {
      $('#tax_status').removeClass('hidden');
      $('#tax_id').removeClass('hidden');
      $('#vat_id').removeClass('hidden');
      if ($('#taxStatus').val() === 'Kleinunternehmer') {
        $('#vat_id').addClass('hidden');
      };
    } else if (EUROZONE.indexOf(country.val()) != -1) {
      $('#tax_rate').addClass('hidden');
      $('#tax_status').addClass('hidden');
      $('#tax_id').removeClass('hidden');
      $('#vat_id').removeClass('hidden');
      $('.tax-help-block').removeClass('hidden');
    } else {
      $('#tax_rate').addClass('hidden');
      $('#tax_status').addClass('hidden');
      $('#tax_id').removeClass('hidden');
      $('#vat_id').removeClass('hidden');
      $('.tax-help-block').removeClass('hidden');
    };
  };

  var showPaymentMethod = function () {
    var tag = $('#paymentMethod');

    if (tag.val() === 'Bank Transfer') {
      $('#payee').addClass('hidden');
      $('#iban').removeClass('hidden');
    } else if (tag.val() === 'Payoneer' || tag.val() === 'PayPal') {
      var text = tag.val() === 'Payoneer' ? 'Payoneer Token*' : 'Email*';
      $('label[for="billing_profile_payee"]').text(text);

      $('#payee').removeClass('hidden');
      $('#iban').addClass('hidden');
    } else {
      $('#payee').addClass('hidden');
      $('#iban').addClass('hidden');
    }
  };

  var showTaxRate = function () {
    var status = '#taxStatus';

    if ($(status).val() === 'Mehrwertsteuerpflichtig') {
      $('#tax_rate').removeClass('hidden');
      $('#vat_id').removeClass('hidden');
      $('#tax_id').removeClass('hidden');
      $('.tax-help-block').removeClass('hidden');
    } else if ($(status).val() === 'Kleinunternehmer') {
      $('#tax_rate').addClass('hidden');
      $('#vat_id').addClass('hidden');
      $('#tax_id').removeClass('hidden');
      $('.tax-help-block').addClass('hidden');
    } else {
      $('#tax_rate').addClass('hidden');
      $('#vat_id').removeClass('hidden');
      $('#tax_id').removeClass('hidden');
      $('.tax-help-block').removeClass('hidden');
    };
  };

  var clearValues = function (selector) {
    $(selector)
      .find('input').not($('.form-submit--js'))
      .each(function (index, element) {
        $(this).val('');
      });

    if (selector === '#taxation' || selector === '#full_taxation') {
      $(selector)
        .find('select')
        .each(function (index, element) {
          $(this).prop('selectedIndex', 0);
        });
    }
  };

  return {
    init: init,
  };
})();
