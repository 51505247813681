import PropTypes from 'prop-types';
import React from 'react';
import StyledDashboardHeader from '../../../CareerSupportCenter/components/DashboardWrapper/styles/StyledDashboardHeader';
import StyledDashboardWrapper from '../../../CareerSupportCenter/components/DashboardWrapper/styles/StyledDashboardWrapper';
import StyledGrid from '../../../CareerSupportCenter/components/DashboardWrapper/styles/StyledGrid';
import StyledHeaderWrapper from '../../../CareerSupportCenter/components/DashboardWrapper/styles/StyledHeaderWrapper';

const AppWrapper = ({
  children,
  header,
}) => {
  return (
    <StyledDashboardWrapper nonStudentView={true}>
      <StyledGrid
        className="ds-grid__container grid-wrapper"
      >
        <StyledHeaderWrapper>
          <StyledDashboardHeader className="ds-typography__h2" nonStudentView={true}>
            {header}
          </StyledDashboardHeader>
          <hr />
        </StyledHeaderWrapper>
        {children}
      </StyledGrid>
    </StyledDashboardWrapper>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
};

export default AppWrapper;
