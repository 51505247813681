var userNotes = require('../users/note');

module.exports = (() => {
  var init = function(){
    userNotes.init();
    //global functions from site.js
    tabNavUrl('.nav-tabs a');
  }

  return {
    init: init
  }
})();
