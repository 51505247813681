import { NavButton, NavButtonsWrapper } from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const NavButtons = ({
  nextButtonDisabled,
  nextButtonText = 'Next',
  onClickBack,
  onClickNext,
}) => (
  <NavButtonsWrapper>
    <NavButton
      className='ds-button__secondary'
      role='backButton'
      onClick={onClickBack}
    >
      Back
    </NavButton>
    <NavButton
      className='ds-button__primary'
      role='nextButton'
      disabled={nextButtonDisabled}
      onClick={onClickNext}
    >
      {nextButtonText}
    </NavButton>
  </NavButtonsWrapper>
);

NavButtons.propTypes = {
  nextButtonDisabled: PropTypes.bool,
  nextButtonText: PropTypes.string,
  onClickBack: PropTypes.func,
  onClickNext: PropTypes.func,
};

export default NavButtons;
