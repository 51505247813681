module.exports = (() => {
  var init = function() {
    bindFunctions();
  };

  var bindFunctions = function () {
    $('#subscription-form').submit(trimWhitespace);
  };

  // trim whitespace to reduce human error
  var trimWhitespace = function () {
    $(this).find('input:text').each(function(){
      var inputValue = $(this).val();
      $(this).val($.trim(inputValue));
   });
  };

  return {
    init: init
  };
})();
