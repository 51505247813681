import React, { useEffect, useState } from 'react';
import BillingForm from '../BillingForm';
import CheckoutService from '../../../../services/CheckoutService';
import Header from '../Header';
import Loading from '../../../shared/components/Loading';
import Sidebar from '../Sidebar';
import StripeHelpers from '../../../../services/StripeHelpers';
import { fetchPaymentData } from '../../actions';
import { formatErrorMessage } from '../../../../utils/formatErrorMessages';
import { getAuthToken } from '../../../../utils/getAuthToken';

const App = () => {
  const defaultCheckboxes = {
    agreedToSepa: false,
    agreedToTerms: false,
  };
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [email, setEmail] = useState('');
  const [stripe, setStripe] = useState({});
  const [cardElement, setCardElement] = useState(null);
  const [checkboxes, setCheckboxes] = useState(defaultCheckboxes);
  const [formError, setFormError] = useState('');
  const [nameOnCard, setNameOncard] = useState('');

  useEffect(() => {
    fetchPaymentData().then(res => {
      setPaymentData(res.paymentData);
      setEmail(res.email);
    });
    StripeHelpers.loadStripeLib(window.$).then(stripe => {
      setStripe(stripe);
      setLoading(false);
    });
  }, []);

  const handleCheckboxClick = e => {
    setCheckboxes({
      ...checkboxes,
      [e.target.name]: e.target.checked,
    });
  };

  const handleCardElement = el => setCardElement(el);

  const handleSubmit = e => {
    e.preventDefault();
    setButtonLoading(true);
    const checkoutService = new CheckoutService(getAuthToken(), 'oneTimePayment');
    const formattedParams = {
      ...paymentData,
      cardElement,
      email,
      nameOnCard,
      stripe,
    };
    checkoutService.stripeCheckout(formattedParams, null, null, null, null, null, false, null, 'sepa').then(res => {
      setButtonLoading(false);
      window.location.replace(res.data.url);
    }).catch(error => {
      setFormError(formatErrorMessage(error));
      setButtonLoading(false);
    });
  };

  return (
    <Loading isLoading={loading}>
      <div className="EnrollWrapper cf-content ExtensionPage">
        <div className="extensions-page__header-wrapper">
          <p className="cf-bold cf-text-center">One-time payment via SEPA Direct Debit</p>
        </div>
        <main>
          <div>
            <div className="PaymentDetailsSlide enroll__slide">
              <Header amount={paymentData.amount}/>
              <BillingForm
                setNameOncard={setNameOncard}
                nameOnCard={nameOnCard}
                handleCheckboxClick={handleCheckboxClick}
                handleSubmit={handleSubmit}
                checkboxes={checkboxes}
                stripe={stripe}
                handleCardElement={handleCardElement}
                formError={formError}
                buttonLoading={buttonLoading}
              />
            </div>
          </div>
          <Sidebar amount={paymentData.amount}/>
        </main>
      </div>
    </Loading>
  );
};

export default App;
