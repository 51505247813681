import React, { useEffect } from 'react';
import {
  getUserPayoutInformation,
  handleFormChange,
  handleFormSubmit,
  setLoadingState,
} from '../../actions';
import App from '../../components/App';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from 'lodash';

const AppWrapper = ({ getUserPayoutInformation = noop, ...rest }) => {
  useEffect(() => {
    getUserPayoutInformation();
  }, []);

  return <App {...rest} />;
};

export const mapStateToProps = ({
  referralBillingProfile: { formStatus, isLoading, personalData },
}) => ({
  formStatus,
  isLoading,
  personalData,
});

AppWrapper.propTypes = {
  getUserPayoutInformation: PropTypes.func,
};

export const mapDispatchToProps = (dispatch) => ({
  getUserPayoutInformation: () => dispatch(getUserPayoutInformation()),
  handleFormChange: (event) => dispatch(handleFormChange(event)),
  handleFormSubmit: (values) => dispatch(handleFormSubmit(values)),
  setLoadingState: () => dispatch(setLoadingState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
