import React, { useEffect } from 'react';
import ButtonWithIcon from '../../../shared/components/ButtonWithIcon';
import JobsMansoryTiles from '../JobsMansoryTiles';
import { OTHER_JOBS_CATEGORIES } from './constants/otherJobs';
import RecommendedJobAccordions from './accordions';
import StyledBoardContainer from './styles/StyledBoardContainer';
import StyledRecommendedJobsBoard from './styles/StyledJobBoard';
import { getCategorizedData } from '../../../shared/helpers/jobSearch';
import { userEventTracking } from '../../../shared/helpers/tracking';

const RecommendedJobsBoard = () => {
  const categories = getCategorizedData(OTHER_JOBS_CATEGORIES, 3);

  useEffect(() => {
    userEventTracking(window.location.href, 'recommended_job_page_open');
  }, []);

  return (
    <StyledRecommendedJobsBoard>
      <div className="ds-grid__container">
        <ButtonWithIcon
          href="/en/career-support-center"
          icon="arrow-left"
          type="secondary"
          text="Back to career support center"
        />
        <h2 className="ds-typography__h2 text-left">Recommended Job Boards</h2>
        <StyledBoardContainer>
          <JobsMansoryTiles
            tilesCategories={categories}
            jobType="recommended_jobs"
            linkEventName="recommended_job_link_clicked"
          />
          <RecommendedJobAccordions />
        </StyledBoardContainer>
      </div>
    </StyledRecommendedJobsBoard>
  );
};

export default RecommendedJobsBoard;
