import React, { useEffect } from 'react';
import LoadingSpinner from '../../../shared/loadingSpinner';
import PropTypes from 'prop-types';
import StudentNotesTab from '../../components/StudentNotesTab';
import { connect } from 'react-redux';
import { getCourseData } from '../../actions';
import { setActivePage } from './actions';

export const mapStateToProps = ({
  userProfile: {
    studentNotesActivePage,
    currentUserId,
    courses,
    coursesLoading,
    selectedCourse,
    userId,
  },
}) => ({
  activePage: studentNotesActivePage,
  attendees: courses?.attendees?.attendees,
  coursesLoading,
  currentUserId,
  hasAttendees: !!courses?.attendees?.attendees,
  selectedCourse,
  userId,
});

export const mapDispatchToProps = (dispatch) => ({
  getCourseData: (userId) => dispatch(getCourseData(userId)),
  setActivePage: (activePage) => dispatch(setActivePage(activePage)),
});

const StudentNotesTabContainer = ({
  attendees,
  getCourseData,
  coursesLoading,
  userId,
  ...rest
}) => {
  useEffect(() => {
    if (!attendees) {
      getCourseData(userId);
    }
  }, []);

  return coursesLoading ? (
    <LoadingSpinner />
  ) : (
    <StudentNotesTab attendees={attendees} {...rest} />
  );
};

StudentNotesTabContainer.propTypes = {
  attendees: PropTypes.array,
  coursesLoading: PropTypes.bool.isRequired,
  getCourseData: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentNotesTabContainer);
