import './styles.scss';
import React, { useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { getSkipTourAction, getSkipTourText, isFirstOrLastStep, isStepPositive, toggleButtons, trackEvent } from './helpers';
import LastStepButton from './Buttons/LastStepButton';
import NextButton from './Buttons/NextButton';
import PrevButton from './Buttons/PrevButton';
import PropTypes from 'prop-types';
import Tour from 'reactour';

const OnboardingTour = ({ showBackButtonAtLastStep, lastStepButtonTitle, onFinishTour, onSkipClose, onTrackEvent, steps }) => {
  const prevButtonRef = useRef(null);
  const tourRef = useRef(null);
  const currentRef = useRef(0);
  const skipTourRef = useRef(null);

  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  const updateCurrentStep = (curr) => {
    tourRef.current.helper.current && tourRef.current.helper.current.setAttribute('id', `maskWrapper__${steps[curr].arrowPosition}`);

    isStepPositive(curr, steps.length, showBackButtonAtLastStep) ? toggleButtons(true, [prevButtonRef]) : toggleButtons(false, [prevButtonRef]);
    !isFirstOrLastStep(curr, steps, currentRef) && trackEvent(curr, currentRef, steps, onTrackEvent);
    currentRef.current = curr;
  };

  const onBeforeClose = () => {
    tourRef.current.gotoStep(0);
  };

  const onClose = (event) => {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
    enableBody();
    // Close complete_tour on button click only
    const stepName = event?.currentTarget && event.currentTarget.tagName !== 'BUTTON' && steps[currentRef.current].name;
    trackEvent(currentRef.current + 1, currentRef, steps, onTrackEvent);
    onFinishTour(stepName);
  };

  const skipTour = () => {
    onTrackEvent('onboarding_tooltip', {
      event_action: 'skip',
      tooltip: steps[currentRef.current].name,
    });
    currentRef.current = steps.length - 1;
    tourRef.current.gotoStep(steps.length - 1);
  };

  const badgeContent = (curr, tot) => (
    <span
      id="badgeContent"
      className="ds-typography__body"
      onClick={getSkipTourAction(onSkipClose, onClose, skipTour)}
      ref={skipTourRef}>
      {getSkipTourText(steps, curr, tot)}
    </span>
  );

  const afterOpen = () => {
    disableBody();
    tourRef.current.helper.current.setAttribute('id', `maskWrapper__${steps[0].arrowPosition}`);
  };

  return (
    !!steps.length && <Tour
      className="maskWrapper"
      highlightedMaskClassName="highlighted"
      badgeContent={badgeContent}
      steps={steps}
      isOpen={true}
      onRequestClose={onClose}
      showNavigation={false}
      showNumber={true}
      nextButton={<NextButton />}
      prevButton={<PrevButton buttonRef={prevButtonRef} hidden={(currentRef.current === 0)}/>}
      lastStepNextButton={<LastStepButton title={lastStepButtonTitle}/>}
      maskClassName="mask"
      onBeforeClose={onBeforeClose}
      getCurrentStep={curr => updateCurrentStep(curr)}
      rounded={4}
      onAfterOpen={afterOpen}
      disableInteraction={true}
      maskSpace={2}
      showCloseButton={false}
      ref={tourRef}
    />
  );
};

OnboardingTour.propTypes = {
  lastStepButtonTitle: PropTypes.string,
  onFinishTour: PropTypes.func,
  onSkipClose: PropTypes.bool,
  onTrackEvent: PropTypes.func,
  showBackButtonAtLastStep: PropTypes.bool,
  steps: PropTypes.array,
};

export default OnboardingTour;
