import CareerSupportCenterModal from '../../../CareerSupportCenter/containers/CareerSupportCenterModal';
import JobApplicationTracker from '../JobApplicationTracker/index';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const JobApplicationsContainer = ({ loading, modalIsOpen, attendeeId }) => {
  return (
    <>
      <JobApplicationTracker attendeeId={attendeeId} />
      {modalIsOpen && <CareerSupportCenterModal
        modalIsOpen={modalIsOpen}
        loading={loading}
        userId={attendeeId}
      />}
    </>
  );
};

JobApplicationsContainer.propTypes = {
  attendeeId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

export const mapStateToProps = ({
  careerSupportCenter: {
    jobApplications,
    loading,
    modalIsOpen,
  },
}) => ({
  jobApplications,
  loading,
  modalIsOpen,
});

export default connect(mapStateToProps, null)(JobApplicationsContainer);
