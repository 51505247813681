import styled from 'styled-components';

const StudentListWrapper = styled.div`
  padding-top: 40px;

  h4 {
    margin-bottom: 40px;
  }
`;

StudentListWrapper.displayName = 'StudentListWrapper';

export default StudentListWrapper;
