import styled from 'styled-components';

const StyledParagraphWithLabel = styled.div`
  display: flex;
  align-items: flex-start;
  h5 {
    margin: 0;
    flex-basis: 27%;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 3px;
  }

  p {
    margin: 0;
    color: var(--ds-color__indigo);
  }

  @media (max-width: 768px) {
    h5 {
      flex-basis: 40%;
    }
  }
`;

StyledParagraphWithLabel.displayName = 'StyledParagraphWithLabel';

export default StyledParagraphWithLabel;
