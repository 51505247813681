module.exports = (() => {
  const init = function () {
    const positionSelectors = document.querySelectorAll('.position-select');

    for (let i = 0; i < positionSelectors.length; i++) {
      positionSelectors[i].addEventListener('change', function () {
        this.submit();
      });
    }

    const filterForm = document.querySelector('#filter-form');
    const selectFilters = document.querySelectorAll('.filter-select');

    const setFilters = function () {
      selectFilters.forEach(selector => {
        if (selector.value === '') {
          selector.value = null;
        }
      });
    };

    filterForm.addEventListener('submit', setFilters);
  };

  return {
    init: init,
  };
})();
