import CourseLabel from '../../shared/components/CourseLabel';
import PropTypes from 'prop-types';
import React from 'react';
import StyledMediaResourceDescription from '../styles/StyledMediaResourceDescription';

const MediaResourceDescription = ({ resource, preview }) => {
  return (
    <StyledMediaResourceDescription preview={preview}>
      <div>
        <CourseLabel course={resource.industry} />
        <p className="type ds-typography__small">{resource.type}</p>
      </div>
      <h5 className="ds-typography__h5 text-left">{resource.title}</h5>
      {!preview && (
        <p className="ds-typography__small--bold">{resource.description}</p>
      )}
    </StyledMediaResourceDescription>
  );
};

MediaResourceDescription.propTypes = {
  preview: PropTypes.bool.isRequired,
  resource: PropTypes.shape({
    categories: PropTypes.array.isRequired,
    description: PropTypes.string,
    duration: PropTypes.string,
    image: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

export default MediaResourceDescription;
