import React, { useEffect } from 'react';
import { getRequiredData, setSelectedCourseId } from '../../actions';
import PropTypes from 'prop-types';
import StudentActivity from '../../components/StudentActivity';
import { connect } from 'react-redux';
import { noop } from 'lodash';

const StudentActivityContainer = ({
  getRequiredDataAction = noop,
  ...rest
}) => {
  useEffect(() => {
    getRequiredDataAction();
  }, []);

  return <StudentActivity {...rest} />;
};

StudentActivityContainer.propTypes = {
  getRequiredDataAction: PropTypes.func,
};

const mapStateToProps = ({
  studentActivity: { attendees, courseCards, isLoading, selectedCourseId },
}) => ({
  courseCards,
  isLoading,
  isReadOnlyStudent: attendees.length === 0,
  selectedCourseId,
});

const mapDispatchToProps = (dispatch) => ({
  getRequiredDataAction: () => dispatch(getRequiredData()),
  setSelectedCourseIdAction: ({ courseId }) =>
    dispatch(setSelectedCourseId({ courseId })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentActivityContainer);
