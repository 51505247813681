module.exports = (() => {
  var init = function() {
    bindFunctions();
  }

  var bindFunctions = function() {
    $('.stripe-id--js').on('click', showInput);
    $('#user_drop_down').on('change', setUserFlags);
  }

  var showInput = function(e) {
    e.preventDefault();
    $(this).siblings('div').removeClass('hidden');
    $(this).hide();
  }

  // Public Methods
  var setUserFlags = function() {
    $('.hidden_field').val(false);
    var drop_down =  $('#user_drop_down').val();
    if (drop_down == 'user') {
      $('.hidden_field').val(false);
    } else if (drop_down == 'rtm_mentor') {
      $('#user_mentor').val(true);
      $('#user_senior_mentor').val(true);
    } else if (drop_down == 'classic_mentor') {
      $('#user_mentor').val(true);
      $('#user_senior_mentor').val(true);
    } else if (drop_down == 'tutor') {
      $('#user_mentor').val(true);
      $('#user_tutor').val(true);
    }
  }

  return {
    init: init,
    setUserFlags: setUserFlags
  }
})();
