import { IUpdateAdmissionParams, ITabData } from '../../../interfaces';
import React from 'react';
import { renderStatus } from '../../../helpers';
import {
  TabContentContainer,
  HeaderWrapper,
  StatusWrapper,
  TextWrapper,
  StyledHeader,
} from '../styles';
import { Body, Cap, Link } from '@careerfoundry/bubble';
import Upload from '../../../../admin/AfaAdmissions/components/Upload';
import { IFile } from '../../../../admin/AfaAdmissions/components/Upload/interfaces';
import Message from '../../Message';

interface IProps {
  tabData: ITabData;
  updateAdmission: (params: IUpdateAdmissionParams, tab: string) => void;
}

const EnglishTest: React.FC<IProps> = ({ tabData, updateAdmission }) => {
  const handleFormSubmit = (
    file: IFile,
    ev: React.MouseEvent<HTMLButtonElement>
  ) => {
    ev.preventDefault();
    updateAdmission(
      {
        english_test: {
          data: file.data,
          file_name: file.fileName,
          file_size: file.fileSize,
        },
      },
      'englishTest'
    );
  };

  const fileData = {
    data: tabData.data || '',
    fileName: tabData.fileName || '',
    fileSize: 0,
  };

  return (
    <TabContentContainer>
      <HeaderWrapper>
        <StyledHeader variant='h3' tag='h3'>
          English Test
        </StyledHeader>
        <StatusWrapper>
          <Cap tag='p' variant='bold'>
            status:{' '}
          </Cap>
          {renderStatus(tabData.state, true)}
        </StatusWrapper>
      </HeaderWrapper>
      <TextWrapper>
        <Body tag='p' variant='large'>
          Our courses are taught in English and require a minimum comprehension
          level of B1.2 for acceptance into the course. Please tell us whether
          you’re a native or non-native English speaker.
        </Body>
        {tabData.reason && (
          <Message message={tabData.reason} messageType='error' />
        )}
        <Body tag='p' variant='large-bold'>
          If you are not a native English speaker:
        </Body>
        <Body tag='p' variant='large' id='middle-paragraph'>
          Please take the{' '}
          <Link href='https://test.berlitz.com/placement/' variant='large'>
            English test.
          </Link>{' '}
          Once you have completed the test, please upload a PDF with your
          results. We’ll accept a PDF copy of the email that Berlitz will send
          with your results, or a screenshot of the result with your name
          visible.
        </Body>
        <Body tag='p' variant='small'>
          Please note that for compliance reasons we are only able to accept the
          results of the Berlitz test linked above. We are, therefore, unable to
          accept results from other test providers, degree certificates, or any
          other proof of English language beyond those mentioned above.
        </Body>
        <Body tag='p' variant='large-bold' className='heading'>
          If you are a native English speaker:
        </Body>
        <Body tag='p' variant='large'>
          Please upload a copy of the photo page of your passport.
        </Body>
      </TextWrapper>
      <Upload handleFormSubmit={handleFormSubmit} fileData={fileData} />
    </TabContentContainer>
  );
};

export default EnglishTest;
