import PropTypes from 'prop-types';
import React from 'react';
import StyledProgressCircle from '../../styles/StyledProgressCircle';

const ProgressCircle = ({ isActive = false }) => (
  <StyledProgressCircle isActive={isActive} />
);

ProgressCircle.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default ProgressCircle;
