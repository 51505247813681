export const filteredList = (interviewer, state, searchText, list) => {
  const byInterviewer = interviewer
    ? list.filter((c) => c.interviewer === interviewer)
    : list;
  const byState = state
    ? byInterviewer.filter((c) => c.state === state)
    : byInterviewer;
  const bySearchText = searchText
    ? byState.filter((c) =>
        c.name.toLowerCase().includes(searchText.toLowerCase())
      )
    : byState;
  return bySearchText;
};

const isCS = (candidate) =>
  candidate.candidate_role?.toLowerCase().includes('career specialist');

export const isCertified = (candidate) =>
  candidate.candidate_role?.toLowerCase().includes('certified');

export const documentsRequired = (candidate) => {
  return (
    candidate.are_documents_required ||
    isCertified(candidate) ||
    isCS(candidate)
  );
};
