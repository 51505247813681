import FullScreenModal from '../../../shared/components/FullScreenModal';
import InterviewReview from '../../../InterviewPrep/components/InterviewReview';
import PropTypes from 'prop-types';
import QuestionInsights from '../../../InterviewPrep/components/QuestionInsights';
import React from 'react';
import TheModal from '../../../shared/components/TheModal';

const InterviewEvaluationModal = ({
  answersForReview,
  currentAnswer,
  handleCloseModal,
  modalAction,
  modalIsOpen,
  selectedInsight,
  setCurrentAnswer,
  isMentorPage,
}) =>
  modalIsOpen && (
    <>
      {
        {
          evaluation: (
            <FullScreenModal
              handleClose={handleCloseModal}
              isModalOpen={modalIsOpen}
              modalClassName='interview-modal'
            >
              <InterviewReview
                isModal={true}
                answersForReview={answersForReview}
                closeEvaluation={handleCloseModal}
                finishInterview={handleCloseModal}
                currentAnswer={currentAnswer}
                setCurrentAnswer={setCurrentAnswer}
                isMentorPage={isMentorPage}
                shareWithMentor={false}
              />
            </FullScreenModal>
          ),
          questionInsights: (
            <TheModal handleClose={handleCloseModal} isModalOpen={modalIsOpen}>
              <QuestionInsights
                buttonAction={handleCloseModal}
                insights={selectedInsight.insights}
                question={selectedInsight.question}
              />
            </TheModal>
          ),
        }[modalAction]
      }
    </>
  );

InterviewEvaluationModal.propTypes = {
  answersForReview: PropTypes.array,
  currentAnswer: PropTypes.object,
  handleCloseModal: PropTypes.func.isRequired,
  isMentorPage: PropTypes.bool.isRequired,
  modalAction: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  selectedInsight: PropTypes.shape({
    insights: PropTypes.string,
    question: PropTypes.string,
  }),
  setCurrentAnswer: PropTypes.func,
};

export default InterviewEvaluationModal;
