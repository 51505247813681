import { CardWrapper, TypeformModalWrapper } from './styles';
import React, { useState } from 'react';
import { markAsCompleted, updateCandidate } from './helpers';
import Card from './Card';
import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import SectionWrapper from '../../styles/SectionWrapper';
import { SubmitButton } from '../PersonalInformation/styles';
import TheModal from '../../../../shared/components/TheModal';
import Typeform from '../../../../shared/components/Typeform';

const TYPEFORM_IDS = {
  career_specialist: 'PLegF5WF',
  certified_career_specialist: 'PLegF5WF',
  certified_mentor: 'JX3IVqR9',
  certified_tutor: 'JX3IVqR9',
  mentor: 'mIYAqEZw',
  tutor: 'mIYAqEZw',
};

const Resources = ({ handleSubmit, id, loadingButton, role }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const isCareerSpecialist =
    role === 'career_specialist' || role === 'certified_career_specialist';
  const resourceLink = isCareerSpecialist
    ? 'trello.com/c/ozx1arzA/131-welcome-videos-getting-started'
    : 'careerfoundrystudentsuccess.zendesk.com/hc';
  const videoLink = isCareerSpecialist
    ? 'careerfoundry.wistia.com/projects/58xqn24ni6'
    : 'careerfoundry.wistia.com/projects/dg9tmaho74';
  const videoPassword = !isCareerSpecialist && `Video password: mentoring2021!`;
  const typeformId = TYPEFORM_IDS[role];

  const toggleSurvey = (isOpen) => setModalIsOpen(isOpen);

  const handleSubmitSurvey = (e) =>
    updateCandidate(typeformId, e.data.responseId, id);

  return (
    <SectionWrapper>
      <header>
        <h3 className='ds-typography__h3 text-left'>Resources</h3>
        <p className='ds-typography__body'>
          We want to make sure you’re prepared to succeed in your new role.
          Please take some time to go through these resources, and then test
          your knowledge of important instructor policies and procedures by
          completing the survey at the end.
        </p>
      </header>
      <CardWrapper>
        <Card
          heading='Reading materials'
          imageUrl='https://images.careerfoundry.com/public/instructor_candidates/reading_resource.svg'
          subHeading='Get to know your role and how things work—payments, time off, student calls, and more.'
          actionLink={`https://${resourceLink}`}
          actionText='Read materials'
          actionFn={() => markAsCompleted(id, 'reading_material')}
          type='resource'
        />
        <Card
          heading='Onboarding videos'
          imageUrl='https://images.careerfoundry.com/public/instructor_candidates/video_resource.svg'
          subHeading="Meet the teams you'll work with and get familiar with our role expectations, policies, and procedures."
          actionLink={`https://${videoLink}`}
          actionText='Watch videos'
          warning={videoPassword}
          type='resource'
        />
        <Card
          heading='Let’s check your knowledge!'
          imageUrl='https://images.careerfoundry.com/public/instructor_candidates/survey_resource.svg'
          subHeading='Let’s make sure you’re well-equipped with the information you need to succeed in your new role. Take this survey to find out.'
          actionText='Start survey'
          actionFn={() => toggleSurvey(true)}
          type='survey'
        />
      </CardWrapper>
      <SubmitButton onClick={handleSubmit} className='ds-button__primary'>
        {loadingButton ? <LoadingSpinner /> : 'Save and continue'}
        {!loadingButton && <i className='ds-icon__arrow-thin-right'></i>}
      </SubmitButton>
      <TypeformModalWrapper>
        <TheModal
          isModalOpen={modalIsOpen}
          handleClose={() => toggleSurvey(false)}
        >
          <Typeform formId={typeformId} handleSubmit={handleSubmitSurvey} />
        </TheModal>
      </TypeformModalWrapper>
    </SectionWrapper>
  );
};

Resources.propTypes = {
  handleSubmit: PropTypes.func,
  id: PropTypes.number,
  loadingButton: PropTypes.bool,
  role: PropTypes.string.isRequired,
};

export default Resources;
