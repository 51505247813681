import { Button, Cap, Header } from '@careerfoundry/bubble';
import { ButtonWrapper, Section, SectionStep, Step } from '../styles';
import { IFile } from '../../Upload/interfaces';
import React from 'react';
import { UpdateParams } from '../../../interfaces';
import Upload from '../../Upload';
import { renderStatus } from '../../../../../AfaAdmissions/helpers';

interface BSGProps {
  data: {
    fileName?: string;
    data?: string;
    type?: string;
    state: string;
    reason?: string;
    physicalVoucherState: string;
    physicalVoucherReason?: string;
    physicalVoucherSentAt?: string;
  };
  handleModalAction: (action: string, attribute: string) => void;
  handleUpdateAdmission: (params: UpdateParams) => void;
}

const BSG: React.FC<BSGProps> = ({
  data,
  handleModalAction,
  handleUpdateAdmission,
}) => {
  const handleFormSubmit = (
    file: IFile,
    ev: React.MouseEvent<HTMLButtonElement>
  ) => {
    ev.preventDefault();
    handleUpdateAdmission({
      afa_voucher: {
        data: file.data,
        file_name: file.fileName,
        file_size: file.fileSize,
      },
    });
  };

  const fileData = {
    data: '',
    fileName: '',
    fileSize: 0,
  };

  return (
    <Section>
      <div>
        <Header tag='h5' variant='h5'>
          Bildungsgutschein
        </Header>
      </div>
      <SectionStep>
        <div>
          <Step tag='p' variant='medium-bold' locked='false'>
            Step 1 of 2
          </Step>
          {renderStatus(data.state, true)}
        </div>
        <div>
          {data.data && (
            <a
              href={data.data}
              target='_blank'
              className='ds-text__link'
              rel='noreferrer'
            >
              {data.fileName}
            </a>
          )}
          {data.data && data.state === 'pending' && (
            <ButtonWrapper>
              <Button
                label='Reject'
                onClick={() => handleModalAction('reject', 'afa_voucher')}
                size='small'
                variant='secondary'
              />
              <Button
                label='Approve'
                onClick={() => handleModalAction('approveBSG', 'afa_voucher')}
                size='small'
                variant='primary'
              />
            </ButtonWrapper>
          )}
        </div>
        <Upload handleFormSubmit={handleFormSubmit} fileData={fileData} />
      </SectionStep>
      <SectionStep>
        <div>
          <div>
            <Step
              tag='p'
              variant='medium-bold'
              locked={String(data.state !== 'done')}
            >
              Step 2 of 2
            </Step>
          </div>
          {renderStatus(data.physicalVoucherState, data.state === 'done')}
        </div>
        {data.physicalVoucherState === 'pending' && (
          <div>
            {data.physicalVoucherSentAt && (
              <Cap tag='p' variant='bold'>
                sent {data.physicalVoucherSentAt}
              </Cap>
            )}
            <ButtonWrapper>
              <Button
                label='Reject'
                onClick={() => handleModalAction('reject', 'physicalVoucher')}
                size='small'
                variant='secondary'
              />
              <Button
                label='Approve'
                onClick={() => handleModalAction('approve', 'physicalVoucher')}
                size='small'
                variant='primary'
              />
            </ButtonWrapper>
          </div>
        )}
      </SectionStep>
    </Section>
  );
};

export default BSG;
