import ButtonWithIcon from '../../../shared/components/ButtonWithIcon';
import PropTypes from 'prop-types';
import React from 'react';
import StudentHandoffCard from '../StudentHandoffCard/index';
import StyledStudentHandoff from './styles/StyledStudentHandoff';

const OverdueRequests = ({ handleClick, overdueHandoffs }) => (
  <StyledStudentHandoff>
    <div className="ds-grid__container">
      <ButtonWithIcon href="/en/" text="Back to dashboard" icon="arrow-left" />
      <div className="flex header">
        <h4 className="intro_graf bold">Overdue handoff requests</h4>
      </div>
      {overdueHandoffs && overdueHandoffs.length > 0
        ? overdueHandoffs.map(handoff => <StudentHandoffCard handleClick={handleClick} key={handoff.id} handoff={handoff} />)
        : <p className="bold center">No handoff requests at the moment</p>
      }
    </div>
  </StyledStudentHandoff>
);
;

OverdueRequests.propTypes = {
  handleClick: PropTypes.func.isRequired,
  overdueHandoffs: PropTypes.array.isRequired,
};

export default OverdueRequests;
