import JobApplicationDetails from '../../components/CareerSupportCenterModal/JobApplicationDetails';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  careerSupportCenter: {
    formData,
  },
}) => ({
  jobApplication: formData,
});

export default connect(mapStateToProps, null)(JobApplicationDetails);
