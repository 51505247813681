import React from 'react';

const UIDesign = () => (
  <>
    <p className="ds-typography__body break">During this stage, you’ll focus on the look or style of your solution based on your findings and outcomes from the UX phase. The most important aspect to consider here is the overall feel of the interface.</p>
    <p className="ds-typography__body--bold">Differences for UI designers and UX designers</p>
    <p className="ds-typography__body break">Typically, this stage is executed by a UI designer, so if you’re looking to showcase your UI design skills, this is your moment to shine! If you are a UX designer, you won’t need to be so involved in this part part. Depending on how advanced your UI skills are, you can choose the level of fidelity for the screens that you will showcase as a final deliverable in your project.</p>
    <p className="ds-typography__body--bold">What you could be doing during this stage:</p>
    <p className="ds-typography__body">Here are some examples of activities that you might carry out during this cycle:</p>
    <ul className="break">
      <li>Creating mood boards and visual directions</li>
      <li>Work on branding</li>
      <li>Defining a style guide</li>
      <li>Working on the visuals of the various product screens</li>
    </ul>
    <p className="ds-typography__body--bold">Deliverables</p>
    <p className="ds-typography__body">Typically, deliverables for this phase would be hand-off-ready screens together with the relevant specs. These would be shared with the developers who would then implement your designs in the way that you envision them.</p>
  </>
);

export default UIDesign;
