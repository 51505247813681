import React, { useEffect } from 'react';
import AiTaskSubmissionStatus, {
  AiTaskSubmissionStatusProps,
} from '../../components/AiTaskSubmissionStatus';
import { connect } from 'react-redux';
import { setAiEvaluationState } from '../../actions';
import { Dispatch } from 'redux';

interface AiTaskSubmissionStatusContainerProps
  extends AiTaskSubmissionStatusProps {
  aiTutorInfo: {
    evaluationId: number | null;
    isEvaluationUnderReview: boolean;
  };
}

export interface SetAiEvaliationStatusActionParams {
  inProgress: boolean;
  status: string | null;
  isError: boolean;
  id?: number | null;
}

interface State {
  taskSubmission: {
    aiEvaluationState: {
      id: number | null;
      inProgress: boolean;
      status: string | null;
    };
    errors: {
      aiEvaluation: boolean | null;
    };
  };
}

const AiTaskSubmissionStatusContainer = ({
  aiTutorInfo,
  aiEvaluationId,
  aiEvaluationStatus,
  isAiEvaluationUnderProgress,
  ...rest
}: AiTaskSubmissionStatusContainerProps) => (
  <AiTaskSubmissionStatus
    aiEvaluationId={aiEvaluationId || aiTutorInfo.evaluationId}
    aiEvaluationStatus={
      aiEvaluationStatus || aiTutorInfo.isEvaluationUnderReview
        ? 'waiting_for_review'
        : null
    }
    isAiEvaluationUnderProgress={
      isAiEvaluationUnderProgress || aiTutorInfo.isEvaluationUnderReview
    }
    {...rest}
  />
);

export const mapStateToProps = ({
  taskSubmission: {
    aiEvaluationState: { id, inProgress, status },
    errors: { aiEvaluation },
  },
}: State) => ({
  aiEvaluationId: id,
  aiEvaluationStatus: status,
  isAiEvaluationUnderProgress: inProgress,
  aiEvaluationError: aiEvaluation,
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  setAiEvaluationStateAction: ({
    inProgress,
    status,
    isError,
  }: SetAiEvaliationStatusActionParams) =>
    dispatch(setAiEvaluationState({ inProgress, status, isError, id: null })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AiTaskSubmissionStatusContainer);
