import { constants } from './constants';
import { getStateParams } from './helpers';

export const initialState = {
  formStatus: [],
  isLoading: false,
  personalData: getStateParams({
    smallCompany: false,
  }),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.SET_COUNTRY: {
      const country = action.data.country;
      return {
        ...state,
        personalData: {
          ...state.personalData,
          country,
          smallCompany: false,
          taxRate: '',
        },
      };
    }
    case constants.SET_TAX_RATE: {
      const taxRate = action.data.taxRate;
      const country = state.personalData.country;
      const smallCompany = !!(country === 'DE' && taxRate === '0');

      if (country !== 'DE') {
        return {
          ...state,
          personalData: {
            ...state.personalData,
            taxRate,
          },
        };
      }
      return {
        ...state,
        personalData: {
          ...state.personalData,
          smallCompany,
          taxRate,
        },
      };
    }
    case constants.SET_LOADING: {
      return {
        ...state,
        isLoading: action.state,
      };
    }
    case constants.SET_FORM_DATA: {
      return {
        ...state,
        personalData: {
          ...state.personalData,
          ...action.data,
        },
      };
    }
    case constants.SET_PERSONAL_DATA: {
      const {
        payoutMethod: { ...payoutMethod },
        ...personalInformation
      } = action.data;
      return {
        ...state,
        personalData: {
          ...personalInformation,
          ...payoutMethod,
        },
      };
    }
    case constants.SET_FORM_STATUS: {
      return {
        ...state,
        formStatus: action.data,
      };
    }
    case constants.SET_SMALL_COMPANY: {
      const smallCompany = !state.personalData.smallCompany;
      const taxRate = smallCompany ? '0' : '19';
      return {
        ...state,
        personalData: {
          ...state.personalData,
          smallCompany,
          taxRate,
        },
      };
    }
    default:
      return { ...state };
  }
}
