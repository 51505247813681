import styled from 'styled-components';

const StyledJobApplicationsPreviewHeader = styled.div`
  a {
    height: 48px;
    display: flex !important;
    width: 182px !important;
    justify-content: center;
    align-items: center;
    line-height: unset;
    margin-bottom: 32px;
  }

  p {
    line-height: 32px;
    font-size: 20px;
    margin-bottom: 8px;
  }
`;

StyledJobApplicationsPreviewHeader.displayName = 'StyledJobApplicationsPreviewHeader';

export default StyledJobApplicationsPreviewHeader;
