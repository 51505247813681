import React, { useEffect } from 'react';
import {
  getCandidate,
  handleContractCheckbox,
  handleOpen,
  updateCandidate,
} from '../../actions';
import Candidate from '../../components/Candidate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

export const CandidateContainer = ({
  getCandidate,
  candidate,
  updateCandidate,
  handleContractCheckbox,
  handleOpen,
}) => {
  const { candidateId } = useParams();

  useEffect(() => {
    getCandidate(candidateId);
  }, []);

  return (
    <Candidate
      candidate={candidate}
      updateCandidate={updateCandidate}
      handleContractCheckbox={handleContractCheckbox}
      handleOpen={handleOpen}
    />
  );
};

CandidateContainer.propTypes = {
  candidate: PropTypes.object.isRequired,
  getCandidate: PropTypes.func.isRequired,
  handleContractCheckbox: PropTypes.func,
  handleOpen: PropTypes.func,
  updateCandidate: PropTypes.func.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  getCandidate: (id) => dispatch(getCandidate({ candidateId: id })),
  handleContractCheckbox: (modalAction) =>
    dispatch(handleContractCheckbox(modalAction)),
  handleOpen: (modalAction) => dispatch(handleOpen(modalAction)),
  updateCandidate: (id, payload) =>
    dispatch(updateCandidate({ candidateId: id, payload: payload })),
});

export const mapStateToProps = ({
  instructorsCandidatesPage: { candidate },
}) => ({
  candidate,
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateContainer);
