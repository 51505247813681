export const retry = (fn: any, retriesLeft = 5) => {
  return new Promise((resolve, reject) => {
    fn()
      .then((response: any) => {
        response.data.data.length > 0
          ? resolve(response)
          : callTimeout(retriesLeft, resolve, reject, fn);
      })
      .catch((error: any) => {
        console.log('error ', error);
      });
  });
};

const callTimeout = (
  retriesLeft: number,
  resolve: any,
  reject: any,
  fn: () => void
) => {
  setTimeout(() => {
    if (retriesLeft === 1) {
      return;
    }
    retry(fn, retriesLeft - 1).then(resolve, reject);
  }, 1000);
};
