import React, { useEffect } from 'react';
import {
  createNote,
  deleteNote,
  getCandidateNotes,
  getCurrentUserId,
} from './actions';
import CandidateNotes from '../../components/Candidate/CandidateNotes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const CandidateNotesContainer = ({
  candidateNotes,
  getCandidateNotes,
  getCurrentUserId,
  candidateNotesLoading,
  currentUserId,
  createNote,
  deleteNote,
}) => {
  const { candidateId } = useParams();

  useEffect(() => {
    getCurrentUserId();
    getCandidateNotes(candidateId);
  }, []);

  return (
    <CandidateNotes
      candidateNotes={candidateNotes}
      createNote={createNote}
      currentUserId={currentUserId}
      deleteNote={deleteNote}
      loading={candidateNotesLoading}
    />
  );
};

CandidateNotesContainer.propTypes = {
  candidateNotes: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      authorId: PropTypes.number,
      date: PropTypes.string,
      id: PropTypes.number,
      note: PropTypes.string,
    })
  ),
  candidateNotesLoading: PropTypes.bool,
  createNote: PropTypes.func,
  currentUserId: PropTypes.number,
  deleteNote: PropTypes.func,
  getCandidateNotes: PropTypes.func,
  getCurrentUserId: PropTypes.func,
};

export const mapDispatchToProps = (dispatch) => ({
  createNote: (authorId, note, id) => dispatch(createNote(authorId, note, id)),
  deleteNote: (id) => dispatch(deleteNote(id)),
  getCandidateNotes: (id) => dispatch(getCandidateNotes(id)),
  getCurrentUserId: () => dispatch(getCurrentUserId()),
});

export const mapStateToProps = ({
  instructorsCandidatesPage: {
    candidateNotes,
    candidateNotesLoading,
    currentUserId,
  },
}) => ({
  candidateNotes,
  candidateNotesLoading,
  currentUserId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateNotesContainer);
