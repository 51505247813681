import StyledLabel from '../../Uploader/components/styles/StyledLabel';
import styled from 'styled-components';

const StyledLabelLarge = styled(StyledLabel)`
  width: 25%;
  span {
    display: inline-flex;
  }

  i {
    margin-left: 4px;
  }
`;

StyledLabelLarge.displayName = 'StyledLabelLarge';

export default StyledLabelLarge;
