import ExerciseTypeImageWrapper from './styles/ExerciseTypeImageWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import ReviewerImage from './styles/ReviewerImage';
import TitleWrapper from './styles/TitleWrapper';

const Notice = ({
  exerciseState,
  next,
  reviewerImg,
  reviewerName,
  studentAvatar,
  postscript,
}) => {
  const rubric = {
    a_little_more: {
      class: 'ds-color__grey--light ds-icon__rubric-2-alittlemore',
      message:
        'The task was reviewed as "a little more" and requires revision.',
    },
    almost_there: {
      class: 'ds-color__grey--light ds-icon__rubric-3-almostthere',
      message: 'The task was reviewed as "almost there" and requires revision',
    },
    approved: {
      class: 'ds-color__aqua ds-icon__rubric-4-approved',
      message:
        "The task has been approved. Congratulation! You're one step closer to your goal.",
    },
    not_yet: {
      class: 'ds-color__grey--light ds-icon__rubric-1-notyet',
      message: 'The task was reviewed as "not yet" and requires revision.',
    },
  }[exerciseState];

  const hasFutureExerciseHeadsUp = postscript;

  return (
    <TitleWrapper style={{ justifyContent: 'center' }}>
      {hasFutureExerciseHeadsUp && (
        <ExerciseTypeImageWrapper>
          <img alt={postscript.title} src={postscript.icon_url} />
          <span className='cf-tooltip ds-typography__body ds-color__petrol'>
            {postscript.content}
          </span>
        </ExerciseTypeImageWrapper>
      )}
      {exerciseState ? (
        exerciseState === 'waiting_for_review' ? (
          <ExerciseTypeImageWrapper>
            <ReviewerImage alt={`Image of ${reviewerName}`} src={reviewerImg} />
            <span className='cf-tooltip ds-typography__body ds-color__petrol'>
              The task is currently waiting for review by {reviewerName}.
            </span>
          </ExerciseTypeImageWrapper>
        ) : (
          <ExerciseTypeImageWrapper>
            <i className={`ds-typography__h3 no-margin ${rubric.class}`} />
            <span className='cf-tooltip ds-typography__body ds-color__petrol'>
              {rubric.message}
            </span>
          </ExerciseTypeImageWrapper>
        )
      ) : next ? (
        <ExerciseTypeImageWrapper>
          <ReviewerImage alt={'Your avatar'} src={studentAvatar} />
          <span className='cf-tooltip ds-typography__body ds-color__petrol'>
            You're currently working on this exercise.
          </span>
        </ExerciseTypeImageWrapper>
      ) : null}
    </TitleWrapper>
  );
};

Notice.propTypes = {
  exerciseState: PropTypes.string,
  next: PropTypes.bool,
  postscript: PropTypes.shape({
    content: PropTypes.string,
    icon_url: PropTypes.string,
    step_display_position: PropTypes.string,
    title: PropTypes.string,
  }),
  reviewerImg: PropTypes.string,
  reviewerName: PropTypes.string,
  studentAvatar: PropTypes.string,
};

export default Notice;
