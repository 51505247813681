import React, { useState } from 'react';
import ButtonSection from './styles/InterviewReview__ButtonSection';
import PropTypes from 'prop-types';

const EvaluationEditButton = ({ isModal, reviewRating, reviewNote, updateReview, setReviewNavigable, closeEvaluation, isMentorPage }) => {
  const [isSaving, setSaving] = useState(false);

  const getReviewRating = () => {
    let data = {};
    let answerId;

    if (reviewRating) {
      answerId = reviewRating.answerId;
      data = { rating: reviewRating.rating };
    }

    return { answerId, data };
  };

  const getReviewNote = () => {
    let data = {};
    let answerId;

    if (reviewNote) {
      answerId = reviewNote.answerId;
      data = { notes: reviewNote.notes };
    }

    return { answerId, data };
  };

  const handleInterviewEdit = () => {
    setReviewNavigable(false);
    setSaving(true);

    const rating = getReviewRating();
    const note = getReviewNote();

    const data = { ...rating.data, ...note.data };
    const answerId = rating.answerId || note.answerId;

    updateReview(answerId, data);
  };

  return (
    <ButtonSection className="review-button" isModal={isModal}>
      <a onClick={() => closeEvaluation()} className="ds-button__secondary">
        {isMentorPage ? 'Close' : 'Cancel'}
      </a>
      { !isMentorPage &&
        <a
          onClick={handleInterviewEdit}
          className={`ds-button__primary ${isSaving ? 'disabled' : ''}`}
        >
          Save Changes
        </a>
      }
    </ButtonSection>
  );
};

EvaluationEditButton.propTypes = {
  closeEvaluation: PropTypes.func.isRequired,
  isMentorPage: PropTypes.bool,
  isModal: PropTypes.bool.isRequired,
  reviewNote: PropTypes.object,
  reviewRating: PropTypes.object,
  setReviewNavigable: PropTypes.func,
  updateReview: PropTypes.func.isRequired,
};

export default EvaluationEditButton;
