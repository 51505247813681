import React, { useEffect, useState } from 'react';
import OverdueRequests from '../Requests/OverdueRequests';
import PendingRequests from '../Requests/PendingRequests';
import PropTypes from 'prop-types';

const App = ({ handoffs, handleClick, overdue }) => {
  const [pendingHandoffs, setPendingHandoffs] = useState([]);
  const [overdueHandoffs, setOverdueHandoffs] = useState([]);

  useEffect(() => {
    setPendingHandoffs(handoffs.filter((handoff) => handoff.status === 'pending'));
    setOverdueHandoffs(handoffs.filter((handoff) => handoff.status === 'overdue'));
  }, [handoffs]);

  return (
    <>
      {overdue
        ? <OverdueRequests
          handleClick={handleClick}
          overdueHandoffs={overdueHandoffs} />
        : <PendingRequests
          handleClick={handleClick}
          pendingHandoffs={pendingHandoffs}
          overdueHandoffs={overdueHandoffs} />
      }
    </>
  );
};

App.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handoffs: PropTypes.array.isRequired,
  overdue: PropTypes.bool.isRequired,
};

export default App;
