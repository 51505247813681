import styled from 'styled-components';

const Footer = styled.div`
  display: table;
  margin: 0 auto;
  padding-top: 32px;

  @media (max-width: 479px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: inherit;
  }

  a {
    color: var(--ds-color__int-content-primary) !important;
  }
`;

Footer.displayName = 'Footer';

export { Footer };
