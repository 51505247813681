import React, { Component } from 'react';
import QuizModal from '../../react/quiz/QuizModal';
import StepQuizEntry from '../../react/quiz/StepQuizEntry';
import { createRoot } from 'react-dom/client';

const quizContainer = document.querySelector('.step__quiz--app');

class QuizWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attempt: null,
      detailsProgress: 0,
      modal: null,
      modalLoading: false,
      questionsLoaded: false,
      questions_status_list: [],
      tempAttempt: null,
    };

    this.initializeState();

    this.handleStartNewQuiz = this.handleStartNewQuiz.bind(this);
    this.initializeModal = this.initializeModal.bind(this);
    this.handleQuestionPrevClick = this.handleQuestionPrevClick.bind(this);
    this.handleStartOver = this.handleStartOver.bind(this);
    this.handleSeeDetailsOnEntry = this.handleSeeDetailsOnEntry.bind(this);
    this.handleDetailsNextClick = this.handleDetailsNextClick.bind(this);
    this.handleDetailsPrevClick = this.handleDetailsPrevClick.bind(this);
    this.handleResultNextClick = this.handleResultNextClick.bind(this);
    this.handleAnswerClick = this.handleAnswerClick.bind(this);
    this.handleQuestionNextClick = this.handleQuestionNextClick.bind(this);
    this.getQuestionIdFromState = this.getQuestionIdFromState.bind(this);
    this.getQuizQuestions = this.getQuizQuestions.bind(this);
  }

  initializeState() {
    $.get(
      '/api/v1/quizzes/get_progress/',
      {
        quizzable_id: gon.step_id,
        quizzable_type: 'Step',
        user_id: gon.current_user.id,
      },
      (response) => {
        if (response == null) return;

        if (quizContainer) {
          quizContainer.classList.remove('hidden');
        }

        this.setState(response);
      }
    );
  }

  getQuizQuestions(newQuiz) {
    $.get(
      '/api/v1/quizzes/start_quiz/',
      {
        quizzable_id: gon.step_id,
        quizzable_type: 'Step',
        user_id: gon.current_user.id,
      },
      (response) => {
        const newQuestionsStatusList = Object.values(response.questions).map(
          (question) => question.chosen_answer_status
        );

        const newAttemptObject = response;
        newAttemptObject.questions = Object.entries(
          newAttemptObject.questions
        ).reduce((newObject, questionArray) => {
          // Build a new questions object with shuffled answers:

          const newQuestionObject = {
            ...questionArray[1],
            answers: [...questionArray[1].answers].sort(
              () => Math.random() - 0.5
            ),
          };

          newObject[questionArray[0]] = newQuestionObject;
          return newObject;
        }, {});

        if (newQuiz) {
          this.setState({
            attempt: newAttemptObject,
            questionsLoaded: true,
            questions_status_list: newQuestionsStatusList,
          });
        } else {
          this.setState({
            questionsLoaded: true,
            tempAttempt: newAttemptObject,
          });
        }
      }
    ).fail((error) => {
      alert(
        'Oh. Something went wrong. Please try reloading the page. If the error continues, please contact your advisor.'
      );
      console.log(`Error: ${error}`);
    });
  }

  handleStartNewQuiz() {
    if (this.state.questionsLoaded) {
      this.state.modal.toggleModal();
    } else {
      this.getQuizQuestions(true);
    }
    // 1. show loading spinner on button (by passing a prop to StepQuizEntry)
    // 2. make a API request
    // 3. in the API callback update this.state.attemptQuestions object
    // 4. open CF modal (by updating the state before, it should already be set to the correct props)
  }

  handleStartOver() {
    this.setState({
      detailsProgress: 0,
      questionsLoaded: false,
    });

    this.getQuizQuestions(false);

    if (this.state.modal == null) this.initializeModal();
    if (!this.state.modal.isModalOpen()) this.state.modal.toggleModal();
  }

  handleSeeDetailsOnEntry() {
    this.setState({ detailsProgress: 1 });
    if (this.state.modal == null) this.initializeModal();
    this.state.modal.toggleModal();
  }

  initializeModal() {
    this.setState({ modal: new CF.Modal('#quiz__modal') }, () => {
      if (
        this.state.attempt.progress_index <
        this.state.questions_status_list.length
      ) {
        this.state.modal.toggleModal();
      } // open modal only if quiz is not completed yet
    });
  }

  getQuestionIdFromState() {
    const attempt = this.state.tempAttempt
      ? this.state.tempAttempt
      : this.state.attempt;
    return Object.keys(attempt.questions)[attempt.progress_index];
  }

  handleAnswerClick(answerId) {
    const newAttempt = this.state.tempAttempt
      ? this.state.tempAttempt
      : this.state.attempt;
    newAttempt.questions[this.getQuestionIdFromState()].chosen_answer_id =
      answerId;
    this.setState({
      attempt: newAttempt,
    });
  }

  handleQuestionNextClick() {
    const attempt = this.state.tempAttempt
      ? this.state.tempAttempt
      : this.state.attempt;

    this.setState({ modalLoading: true });
    const chosenAnswerId =
      attempt.questions[this.getQuestionIdFromState()].chosen_answer_id;
    $.post('/api/v1/quizzes/answer_question', {
      quiz_answer_id: chosenAnswerId,
      quiz_attempt_question_id: this.getQuestionIdFromState(),
    })
      .success((data) => {
        const answerStatus = attempt.questions[
          this.getQuestionIdFromState()
        ].answers.find((answer) => {
          return answer.id === chosenAnswerId;
        }).value
          ? 'correct'
          : 'wrong';

        const newAttempt = attempt;
        newAttempt.questions[
          this.getQuestionIdFromState()
        ].chosen_answer_status = answerStatus;
        newAttempt.progress_index = attempt.progress_index + 1;

        const newQuestionsStatusList = Object.values(newAttempt.questions).map(
          (question) => question.chosen_answer_status
        );

        this.setState({
          attempt: newAttempt,
          detailsProgress: 0,
          modalLoading: false,
          questions_status_list: newQuestionsStatusList,
          tempAttempt: null,
        });
      })
      .fail((error) => {
        alert(
          'Oh. Something went wrong. Please try reloading the page. If the error continues, please contact your advisor.'
        );
        console.log(`Error: ${error}`);
      });
  }

  handleQuestionPrevClick() {
    const newProgress = this.state.attempt;
    newProgress.progress_index = this.state.attempt.progress_index - 1;
    this.setState({ attempt: newProgress });
  }

  handleDetailsPrevClick() {
    this.setState({ detailsProgress: this.state.detailsProgress - 1 });
  }

  handleDetailsNextClick() {
    this.setState({ detailsProgress: this.state.detailsProgress + 1 });
  }

  handleResultNextClick(isFinalSlide) {
    if (isFinalSlide) {
      this.setState({
        detailsProgress: 0,
      });
      this.state.modal.toggleModal();
    } else {
      this.setState({ detailsProgress: 1 });
    }
  }

  render() {
    const renderQuizModal = () => {
      if (this.state.attempt != null) {
        return (
          <QuizModal
            onQuizLoaded={this.initializeModal}
            onQuestionPrevClick={this.handleQuestionPrevClick}
            onQuestionNextClick={this.handleQuestionNextClick}
            detailsProgress={this.state.detailsProgress}
            questions_status_list={this.state.questions_status_list}
            onAnswerClick={this.handleAnswerClick}
            onTakeAgainClick={this.handleStartOver}
            onResultNextClick={this.handleResultNextClick}
            onDetailsNextClick={this.handleDetailsNextClick}
            onDetailsPrevClick={this.handleDetailsPrevClick}
            modalLoading={this.state.modalLoading}
            tempAttempt={this.state.tempAttempt}
            attempt={this.state.attempt}
          />
        );
      }
    };

    return (
      <div className='cf-content'>
        <StepQuizEntry
          questions_status_list={this.state.questions_status_list}
          onTakeAgainClick={this.handleStartOver}
          onHandleSeeDetails={this.handleSeeDetailsOnEntry}
          onStartNewQuiz={this.handleStartNewQuiz}
        />
        {renderQuizModal()}
      </div>
    );
  }
}

if (quizContainer) {
  const quizContainerRoot = createRoot(quizContainer);

  quizContainerRoot.render(<QuizWrapper />);
}
