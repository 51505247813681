import { CheckboxWrapper, ModalContent } from './styles';
import React, { useState } from 'react';
import Checkbox from '../../components/Checkbox';
import PropTypes from 'prop-types';
import TheModal from '../../components/TheModal';

const Label = ({ handleClick }) => (
  <>
    I have read and agree to the <a href="#!" onClick={handleClick}>SEPA Direct Debit Mandate</a>
  </>
);

Label.propTypes = {
  handleClick: PropTypes.func,
};

const SepaCheckbox = ({ agreedToSepa, onChange }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);
  const handleCloseButton = e => {
    e.preventDefault();
    toggleModal();
  };

  return (
    <CheckboxWrapper>
      <Checkbox
        onClick={onChange}
        name="agreedToSepa"
        labelText={<Label handleClick={toggleModal} />}
        required={true}
        defaultChecked={agreedToSepa}
      />
      <TheModal
        isModalOpen={modalIsOpen}
        handleClose={toggleModal}
      >
        <ModalContent>
          <i className="fa fa-university"></i>
          <h3 className="ds-typography__h3_small_caps">SEPA Direct Debit Mandate</h3>
          <p className="ds-typography__body">By providing your payment information and confirming this payment, you authorise (A) CareerFoundry GmbH and Stripe, our payment service provider and/or PPRO, its local service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.</p>
          <button
            className="ds-button__primary"
            onClick={e => handleCloseButton(e)}
          >Close</button>
        </ModalContent>
      </TheModal>
    </CheckboxWrapper>
  );
};

SepaCheckbox.propTypes = {
  agreedToSepa: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SepaCheckbox;
