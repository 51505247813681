import React from 'react';
import StyledImageSection from '../styles/StyledImageSection';
import StyledErrorImage from '../styles/StyledErrorImage';

const ErrorPage = () => {
  const goToDashboard = () => window.location.replace('/');
  // @ts-ignore
  const url = new URL(window.location);
  const productSku = url.searchParams.get('product_sku');

  return (
    <>
      <h3 className='ds-text__h3'>There was an issue with your payment.</h3>
      <StyledImageSection>
        <StyledErrorImage />
      </StyledImageSection>
      <div className='message__body'>
        <p className='ds-text__body--medium'>
          There seemed to be an issue with your payment. Please{' '}
          <a
            href={`/en/enroll?product_sku=${productSku}`}
            className='ds-text--bold'
          >
            try again
          </a>{' '}
          or contact support.
        </p>
        <button className='ds-button__primary' onClick={goToDashboard}>
          Contact support
        </button>
      </div>
    </>
  );
};

export default ErrorPage;
