import { Field } from 'formik';
import React from 'react';
import Select from '../../../shared/components/Select';
import { StyledInput } from '../App/styles';

const TaxRateInputDE = () => (
  <StyledInput>
    <Field
      as={Select}
      type='text'
      name='taxRate'
      placeholder='Tax rate'
      required={true}
    >
      <option value=''>Select a tax rate</option>
      <option value='0'>0%</option>
      <option value='19'>19%</option>
    </Field>
  </StyledInput>
);

export default TaxRateInputDE;
