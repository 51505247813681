import { CheckboxWrapper, FormSection, InputWrapper } from '../styles';
import Checkbox from '../../../../../shared/components/Checkbox';
import CountrySelect from '../../../../../shared/components/CountrySelect';
import Input from '../../../../../shared/components/Input';
import PropTypes from 'prop-types';
import React from 'react';
import SectionHeader from '../../SectionHeader';
import { errorMessage } from '../helpers';

const BillingInputs = ({
  copyAddress,
  formErrors,
  handleCopyAddress,
  handleCountryChange,
  handleSave,
  personalData,
}) => {
  return (
    <FormSection>
      <SectionHeader
        headline="Billing Address"
        tooltipText="Please write your billing address, if it differs from your official address."
      >
        <CheckboxWrapper>
          <Checkbox
            onClick={(ev) => handleCopyAddress(ev.target.checked)}
            labelText="Identical to 'Official Address'"
            defaultChecked={copyAddress}
          />
        </CheckboxWrapper>
      </SectionHeader>
      {!copyAddress && (
        <>
          <InputWrapper>
            <Input
              type="text"
              name="addressLine1"
              placeholder="Address"
              required={true}
              value={personalData.addressLine1}
              onChange={(value) => handleSave('addressLine1', value)}
              error={errorMessage(formErrors, 'addressLine1')}
            />
            <Input
              type="text"
              name="addressLine2"
              placeholder="Apt., bldg., etc."
              value={personalData.addressLine2}
              onChange={(value) => handleSave('addressLine2', value)}
              error={errorMessage(formErrors, 'addressLine2')}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="text"
              name="city"
              placeholder="City"
              required={true}
              value={personalData.city}
              onChange={(value) => handleSave('city', value)}
              error={errorMessage(formErrors, 'city')}
            />
            <Input
              type="text"
              name="postCode"
              placeholder="Postcode"
              required={true}
              value={personalData.postCode}
              onChange={(value) => handleSave('postCode', value)}
              error={errorMessage(formErrors, 'postCode')}
            />
          </InputWrapper>
          <InputWrapper>
            <CountrySelect
              name="country"
              value={personalData.country}
              onChange={(event) => handleCountryChange(event)}
              required={true}
            />
          </InputWrapper>
        </>
      )}
    </FormSection>
  );
};

BillingInputs.propTypes = {
  copyAddress: PropTypes.bool,
  formErrors: PropTypes.object,
  handleCopyAddress: PropTypes.func,
  handleCountryChange: PropTypes.func,
  handleSave: PropTypes.func,
  personalData: PropTypes.object,
};

export default BillingInputs;
