import React from 'react';
import AccordionSection from '../../../../shared/components/AccordionSection';
import JobsMansoryTiles from '../../JobsMansoryTiles';
import { WORLD_WIDE_JOBS_CATEGORIES } from '../constants/worldwideJobs';

const SECTION_NAME = "Worldwide Locations";

const AllLocationsBoard = () => {
  return (
    <AccordionSection
      heading={SECTION_NAME}
      content={
        <JobsMansoryTiles
          tilesCategories={WORLD_WIDE_JOBS_CATEGORIES}
          jobType="all_locations_jobs"
          sectionName={SECTION_NAME}
          linkEventName="recommended_job_link_clicked"
        />
      }
    />
  );
};

export default AllLocationsBoard;
