import App from '../Registration/components/App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../shared/reduxStore';

const employerRegistrationContainer = document.getElementById(
  'employerRegistration'
);

if (employerRegistrationContainer) {
  const employerRegistrationContainerRoot = createRoot(
    employerRegistrationContainer
  );

  employerRegistrationContainerRoot.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}
