import Button from '../shared/Button';
import ImageText from '../shared/ImageText';
import PropTypes from 'prop-types';
import React from 'react';
import ReversedImageText from '../shared/ReversedImageText';
import ScrollView from '../../styles/ScrollView';
import StyledWelcomeModal from '../../styles/StyledWelcomeModal';

const DoubleParagraphModal = ({ onClick, firstParagraph, secondParagraph, header, buttonLabel }) => {
  return (
    <StyledWelcomeModal>
      <hr/>
      <ScrollView>
        <h4 className="ds-typography__h4 text-left"> { header } </h4>
        <ImageText
          imageUrl="https://coach-courses-us.s3.amazonaws.com/public/images/Frame+533.png"
          alt="welcome"
          paragraph={firstParagraph}
        />
        <ReversedImageText
          imageUrl="https://coach-courses-us.s3.amazonaws.com/public/images/Frame+534.png"
          alt="welcome"
          paragraph={secondParagraph}
        />
      </ScrollView>
      <div className="button-section">
        <Button
          className="ds-button__primary"
          label={buttonLabel}
          onClick={() => onClick()}
        />
      </div>
    </StyledWelcomeModal>
  );
};

DoubleParagraphModal.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  firstParagraph: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  secondParagraph: PropTypes.string.isRequired,
};

export default DoubleParagraphModal;
