import PasswordChangeContainer from '../../containers/PasswordChangeContainer';
import PropTypes from 'prop-types';
import React from 'react';
import StyledModalWrapper from '../styles/StyledModalWrapper';
import TheModal from '../../../shared/components/TheModal';

const EmployerModal = ({
  handleCloseModal,
  modalIsOpen,
  modalAction,
}) => {
  const handleClose = () => {
    if (modalAction === 'changePassword') return;
    handleCloseModal();
  };

  return (
    modalIsOpen
      ? <StyledModalWrapper isPasswordChange={true} >
        <TheModal
          handleClose={handleClose}
          isModalOpen={modalIsOpen}
        >
          <>
            {
              {
                changePassword: <PasswordChangeContainer />,
              }[modalAction]
            }
          </>
        </TheModal>
      </StyledModalWrapper>
      : null
  );
};

EmployerModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  modalAction: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

export default EmployerModal;
