import styled from 'styled-components';

const CardInner = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(34, 60, 80, 0.16);
  display: flex;
  justify-content: space-between;
`;

CardInner.displayName = 'CardInner';

export default CardInner;
