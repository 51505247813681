import React, { Component } from 'react';
import CFModal from '../shared/modal';
import { getAuthToken } from '../../utils/getAuthToken';
import EnrollmentLinkService from '../../services/EnrollmentLinkService';

class BilledAction extends Component {
  constructor(props) {
    super(props);
    this.enrollmentLinkService = new EnrollmentLinkService();
  }

  state = {
    billedModalopen: false,
  };

  handleModalClose = () => {
    this.setState({ billedModalopen: false });
  };

  openBilledModal = () => {
    this.setState({ billedModalopen: true });
  };

  markAsBilled = () => {
    this.enrollmentLinkService
      .updateLink(this.props.link.id, 'waiting_for_payment', getAuthToken())
      .then((res) => {
        this.handleModalClose();
        location.reload();
      });
  };

  render() {
    return (
      <div>
        <a onClick={() => this.openBilledModal()} href="#">
          Billed
        </a>
        <CFModal
          appElement=".admin__enrollment_links_index"
          isOpen={this.state.billedModalopen}
          onRequestClose={this.handleModalClose}
          className="admin__enrollment_links__modal"
        >
          <div className="cf-text-center">
            <h4 className="cf-text-center">
              Are you sure this user has been billed already?
            </h4>
            <a
              onClick={() => this.handleModalClose()}
              className="button--medium button--primary-darkblue"
            >
              No
            </a>
            <a
              onClick={() => this.markAsBilled()}
              className="button--medium button--primary-petrol"
            >
              Yes
            </a>
          </div>
        </CFModal>
      </div>
    );
  }
}

export default BilledAction;
