import styled from 'styled-components';

const StyledToggleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0 8px 0 !important;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    align-items: flex-start;

    p {
      text-align: start;
      font-size: 14px;
    }
  }
`;

StyledToggleSection.displayName = 'StyledToggleSection';
export default StyledToggleSection;
