import PropTypes from 'prop-types';
import React from 'react';
import StyledFormField from './styles/StyledFormField';

const FilterWrapper = ({
  label,
  children,
  isVerticalLabel = false,
  tooltip,
}) => (
  <StyledFormField
    labelText={label}
    isFilter={true}
    isVerticalLabel={isVerticalLabel}
    tooltip={tooltip}
  >
    {children}
  </StyledFormField>
);

FilterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isVerticalLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.object,
};

export default FilterWrapper;
