import React, { useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { Body, Header, Link } from '@careerfoundry/bubble';
import BulbSVGPath from '../../../../../../assets/images/shared/bulb.svg';
import { getUserData } from '../../../../services/getUserData';
import { get } from 'lodash';
import { trackEvent } from '../../../../shared/apis/trackEvent';
import { getCardData } from '../../helpers';

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */
interface CardContent {
  title: string;
  message: string;
  typeOfMessage: 'link' | 'text' | 'anchor_link';
  linkInfo: {
    link?: string;
    text?: string;
  };
}

/**
 * ==================================================
 * Constants
 * ==================================================
 */
const initialCardContent: CardContent = {
  title: '',
  message: '',
  typeOfMessage: 'text',
  linkInfo: {},
};

/**
 * ==================================================
 * <Card />
 * ==================================================
 */
const Card: React.FC = () => {
  const [userId, setUserId] = useState<string>('');
  const [cardContent, setCardContent] =
    useState<CardContent>(initialCardContent);
  const courseId = (window.location.pathname.match(
    /dashboards\/(.*)\/course-home/
  ) || [])[1];

  useEffect(() => {
    const fetchData = async () => {
      const userData = await getUserData();
      const cardData = await getCardData(courseId);

      setUserId(get(userData, 'data.data.id', ''));
      setCardContent(get(cardData, 'data.data', initialCardContent));
    };

    try {
      fetchData();
    } catch (error) {
      // TODO: Report to Rollbar
    }
  }, []);

  const { title, message, typeOfMessage, linkInfo } = cardContent;
  const isAnchorLink = typeOfMessage === 'anchor_link';
  const isLinkVisible = typeOfMessage !== 'text';

  if (!message) {
    return null;
  }

  return (
    <Wrapper>
      <img src={BulbSVGPath} />
      <div className='content'>
        <Header tag='h4' variant='h4'>
          {title}
        </Header>
        <Body tag='p' variant='medium'>
          {message}
        </Body>
        {isLinkVisible && (
          <div className='link-container'>
            <Link
              href={isAnchorLink ? '#your-team' : linkInfo.link!}
              variant='medium'
              target={isAnchorLink ? '_self' : '_blank'}
              onClick={() =>
                trackEvent({
                  data: { userId },
                  event: 'student_support_card_cta_click',
                })
              }
            >
              {linkInfo.text || ''}
            </Link>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

/**
 * ==================================================
 * Exports
 * ==================================================
 */
export default Card;
