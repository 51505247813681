import styled from 'styled-components';

const WarningWrapper = styled.div`
  margin: 20px 17px 0px 0.5px;
  padding: 16px 98px 19px 16px;
  border-radius: 4px;
  background-color: rgba(250, 99, 87, 0.2);
`;

WarningWrapper.displayName = 'WarningWrapper';

export default WarningWrapper;
