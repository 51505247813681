import JobApplicationModal from '../../containers/JobApplicationModal';
import JobApplicationStyles from './styles/JobApplicationsStyles';
import PaginatedTable from '../../../shared/components/PaginatedTable';
import PropTypes from 'prop-types';
import React from 'react';

const JobApplicationsTab = ({
  applications,
  applicationsLength,
  activePage,
  setActivePage,
  showApplicationModal,
}) => {
  const columns = [
    'Date',
    { name: 'Role', type: 'link', url: 'Link' },
    'Status',
    'Company',
    'Source',
  ];
  return (
    <JobApplicationStyles>
      {applicationsLength ? (
        <PaginatedTable
          activePage={activePage}
          collection={applications}
          collectionLength={applicationsLength}
          columns={columns}
          itemsCountPerPage={10}
          setActivePage={setActivePage}
          showApplicationModal={showApplicationModal}
        />
      ) : (
        <p className='ds-typography__body text-center'>
          There are no job applications here yet.
        </p>
      )}

      <JobApplicationModal />
    </JobApplicationStyles>
  );
};

JobApplicationsTab.propTypes = {
  activePage: PropTypes.number.isRequired,
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.string,
      date: PropTypes.string,
      role: PropTypes.string,
      status: PropTypes.string,
    })
  ).isRequired,
  applicationsLength: PropTypes.number.isRequired,
  setActivePage: PropTypes.func.isRequired,
  showApplicationModal: PropTypes.func.isRequired,
};

export default JobApplicationsTab;
