import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 52px;

  a {
    margin: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;

    ${({ isReview }) => isReview && `
      padding: 0 24px;
      margin-bottom: 16px;
    `}
  }
  @media (max-width: 479px) {
    .training-label {
      display: none;
    }
  }
`;

Header.displayName = 'Header';

export default Header;
