import AppWrapper from '../shared/AppWrapper';
import JobProfileFiltersContainer from '../../containers/JobProfileFiltersContainer';
import React from 'react';
import StudentTableContainer from '../../containers/StudentTableContainer';

const StudentPage = () => {
  return (
    <AppWrapper header='Employer Connector - Student list'>
      <JobProfileFiltersContainer />
      <StudentTableContainer />
    </AppWrapper>
  );
};

export default StudentPage;
