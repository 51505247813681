export const defaultState = {
  are_documents_required: '',
  candidate_role: '',
  cf_email_password: '',
  course_ids: [],
  email: '',
  first_name: '',
  interviewer_id: '',
  is_specialization_mentor: '',
  last_name: '',
  personal_email: '',
  user_id: '',
};

export const populatedState = (candidate) => ({
  are_documents_required: candidate.are_documents_required,
  candidate_role: candidate.candidate_role,
  cf_email_password: candidate.cf_email_password,
  course_ids: candidate.course_ids,
  email: candidate.email,
  first_name: candidate.first_name,
  interviewer_id: candidate.interviewer?.id,
  is_specialization_mentor: candidate.is_specialization_mentor,
  last_name: candidate.last_name,
  personal_email: candidate.personal_email,
  user_id: candidate.user_id,
});
