import MediaResourceDescription from './MediaResourceDescription';
import MediaResourceImage from './MediaResourceImage';
import PropTypes from 'prop-types';
import React from 'react';
import StyledMediaResource from '../styles/StyledMediaResource';
import StyledMediaResourceContainer from '../styles/StyledMediaResourceContainer';
import { resourceTracking } from '../../shared/helpers/tracking';

const MediaResource = ({ resource, preview, isDragging = false }) => {
  const sizing = preview
    ? 'ds-grid__col-xs-6 ds-grid__col-sm-6 ds-grid__col-md-4'
    : 'ds-grid__col-xs-12 ds-grid__col-md-6 ds-grid__col-lg-4 ds-grid__col-xl-3';

  return (
    <StyledMediaResourceContainer className={sizing}>
      <StyledMediaResource>
        <a
          href={!isDragging ? resource.link : false}
          onClick={() => {
            if (!isDragging) {
              resourceTracking(
                resource.categories[0],
                resource.link,
                'media_library_item_clicked'
              )
            }
          }
          }
          target="_blank"
          rel="noopener noreferrer"
        ></a>
        <MediaResourceImage resource={resource} />
        <MediaResourceDescription resource={resource} preview={preview} />
      </StyledMediaResource>
    </StyledMediaResourceContainer>
  );
};

MediaResource.propTypes = {
  isDragging: PropTypes.bool,
  preview: PropTypes.bool.isRequired,
  resource: PropTypes.shape({
    categories: PropTypes.array.isRequired,
    description: PropTypes.string,
    duration: PropTypes.string,
    image: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

export default MediaResource;
