import React, { useEffect } from 'react';
import { finishTour, getCurrentUserdData, trackEvent } from '../../actions';
import OnboardingTour from '../../../../shared/components/OnboardingTour';
import PropTypes from 'prop-types';
import { chWsgSubmission } from '../../tourStepsFull';
import { connect } from 'react-redux';
import useDesktopOnly from '../../../../shared/hooks/useDesktopOnly';

export const OnboardingTourContainer = ({
  isVariant,
  onFinishTour,
  onGetUserData,
  steps,
}) => {
  useEffect(() => {
    onGetUserData();
  }, []);

  const onTrackEvent = async (event, data) => {
    await trackEvent(event, data);
  };

  return (
    useDesktopOnly() &&
    !!steps.length && (
      <OnboardingTour
        onFinishTour={onFinishTour}
        steps={steps}
        onTrackEvent={onTrackEvent}
        lastStepButtonTitle={isVariant ? 'Set my goal' : 'Finish'}
        onSkipClose={true}
        showBackButtonAtLastStep={true}
      />
    )
  );
};

export const mapStateToProps = (state, { isVariant }) => ({
  isVariant,
  steps:
    !!state.guidedTour.steps.length && isVariant
      ? [...state.guidedTour.steps, chWsgSubmission]
      : state.guidedTour.steps,
});

export const mapDispatchToProps = (dispatch) => {
  return {
    onFinishTour: (stepName) => dispatch(finishTour(stepName)),
    onGetUserData: () => dispatch(getCurrentUserdData()),
  };
};

OnboardingTourContainer.propTypes = {
  isVariant: PropTypes.bool,
  onFinishTour: PropTypes.func,
  onGetUserData: PropTypes.func,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      arrowPosition: PropTypes.string,
      content: PropTypes.string,
      name: PropTypes.string,
      position: PropTypes.string,
      selector: PropTypes.string,
      style: PropTypes.shape({
        backgroundColor: PropTypes.string,
        color: PropTypes.string,
        fontSize: PropTypes.string,
        fontWeight: PropTypes.string,
        lineHeight: PropTypes.string,
        marginTop: PropTypes.string,
        maxWidth: PropTypes.string,
      }),
    })
  ),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingTourContainer);
