import Button from '../../EmployerConnector/modals/shared/Button';
import PropTypes from 'prop-types';
import React from 'react';
import StyledButtonWrapper from '../../EmployerConnector/modals/showModal/styles/StyledButtonWrapper';

const MessagingButton = ({
  createMessageChannel,
  jobSeekingProfile,
  loading,
}) => {
  const handleClick = async () => {
    createMessageChannel(jobSeekingProfile.userId);
  };

  return (
    <StyledButtonWrapper>
      <Button
        className="ds-button__primary"
        label='Send a message to student'
        onClick={ handleClick }
        loading={loading}
      />

    </StyledButtonWrapper>
  );
};

MessagingButton.propTypes = {
  createMessageChannel: PropTypes.func.isRequired,
  jobSeekingProfile: PropTypes.shape({
    userId: PropTypes.number,
  }),
  loading: PropTypes.bool,
};

export default MessagingButton;
