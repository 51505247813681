import ExploreJobsMobileView from './mobileViews/ExploreJobsMobileView';
import MediaResourcePreviewContainer from '../../containers/MediaResourcePreviewContainer';
import MotivationalMessagesContainer from '../../containers/MotivationalMessagesContainer';
import PropTypes from 'prop-types';
import React from 'react';
import StyledExploreJobs from './styles/StyledExploreJobs';
import WebinarEventsSection from './components/WebinarEventsSection';

const ExploreJobsSection = ({ isMobile }) => (
  <StyledExploreJobs>
    {isMobile ? (
      <ExploreJobsMobileView isMobile={isMobile} />
    ) : (
      <>
        <MotivationalMessagesContainer />
        <MediaResourcePreviewContainer />
        <WebinarEventsSection isMobile={false} />
      </>
    )}
  </StyledExploreJobs>
);

ExploreJobsSection.propTypes = {
  isMobile: PropTypes.bool,
};

export default ExploreJobsSection;
