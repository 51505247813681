import { BookSearch as BookSearchIcon } from '../../../shared/icons/BookSearch';
import PropTypes from 'prop-types';
import React from 'react';
import { Wrapper } from './styles';

const EmptySection = ({ message }) => (
  <Wrapper>
    <BookSearchIcon />
    <h4 className="ds-typography__h4">{message}</h4>
  </Wrapper>
);

EmptySection.propTypes = {
  message: PropTypes.string,
};

export default EmptySection;
