import { CopyIcon } from './icons';
import JobSearchTile from '../../UsefulLinks/JobSearchTile';
import React from 'react';

const RecommendedJobTile = () => (
  <JobSearchTile
    description='List of job boards recommended by our specialists.'
    icon={<CopyIcon />}
    secondaryButton={{
      event: 'recommended_jobs_link_clicked',
      link: '/en/recommended-jobs',
      target: '_parent',
      text: 'View list',
    }}
    title='Recommended job boards'
  />
);

export default RecommendedJobTile;
