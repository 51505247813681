import styled from 'styled-components';

const StyledPagination = styled.div`
  text-align: center;

  .pagination > li > a {
    border: none;
    color: var(--ds-color__indigo);
    padding: 0 6px;
  }

  .pagination > .active > a {
    background-color: #e3e3e3;
    color: var(--ds-color__indigo);
  }
`;

StyledPagination.displayName = 'StyledPagination';

export default StyledPagination;
