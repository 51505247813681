import { fetchNotes, setNotesData } from '../../actions';
import Notes from '../../components/Notes';
import { connect } from 'react-redux';

const mapStateToProps = ({
  studentActivity: {
    allNotesCount,
    isFetchingNotes,
    isSelectedCourseStarted,
    notes,
    selectedCourseId,
    totalNotesToLoadCount,
    userId,
  },
}) => ({
  allNotesCount,
  isFetchingNotes,
  isSelectedCourseStarted,
  notes,
  selectedCourseId,
  totalNotesToLoadCount,
  userId,
});

const mapDispatchToProps = (dispatch) => ({
  clearNotesAction: ({ shouldResetAllNotesCount = false }) =>
    dispatch(
      setNotesData({
        isFetchingMoreNotes: false,
        notes: [],
        shouldResetAllNotesCount,
        totalNotesToLoadCount: 0,
      })
    ),
  fetchNotesAction: ({
    courseId,
    isFetchingAllNotes,
    isFetchingMoreNotes,
    notesPerPage,
    pageNumber,
    searchQuery,
    userId,
  }) =>
    dispatch(
      fetchNotes({
        courseId,
        isFetchingAllNotes,
        isFetchingMoreNotes,
        notesPerPage,
        pageNumber,
        searchQuery,
        userId,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
