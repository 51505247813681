import styled from 'styled-components';

export const CurriculumWrapper = styled.div`
  width: 600px;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0px 16px;
  }
`;

export const CurriculumCourse = styled.div``;
export const CurriculumCourseTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: var(--ds-color__indigo);
  margin-bottom: 8px;
`;

export const CurriculumCourseContent = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--ds-color__indigo);
  margin-bottom: 24px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 8px;
`;
