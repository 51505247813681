import PropTypes from 'prop-types';
import React from 'react';
import { StyledHeader } from './styles';

const Header = ({ heading, iconPath, text }) => (
  <StyledHeader>
    <header>
      <img src={iconPath} alt="icon" />
      <h2 className="ds-typography__h2 text-left">{heading}</h2>
    </header>
    <p className="ds-typography__lead">{text}</p>
  </StyledHeader>
);

Header.propTypes = {
  heading: PropTypes.string,
  iconPath: PropTypes.string,
  text: PropTypes.node,
};

export default Header;
