import styled from 'styled-components';

const StyledBackground = styled.div`
  height: 157px;
  overflow-y: hidden;
  background-color: var(--ds-color__petrol);
  margin-bottom: -117px;
  border-radius: 4px;
  width: 100%;

  @media (max-width: 768px) {
    display: none;
  }
`;

StyledBackground.displayName = 'StyledBackground';

export default StyledBackground;
