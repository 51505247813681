import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledInput from './styles';

const Input = props => {
  const { name, value, placeholder, type, required, onChange, error, pattern } = props;
  const [tempValue, setTempValue] = useState(value);
  const handleInputChange = value => setTempValue(value);

  return (
    <StyledInput error={error}>
      <input
        name={name}
        value={tempValue}
        placeholder={`${placeholder}${required ? '*' : ''}`}
        type={type}
        required={required}
        onChange={event => handleInputChange(event.target.value)}
        pattern={pattern}
        onBlur={() => onChange(tempValue, name)}
      />
      {error && <p className="ds-typography__small--bold error-text">{placeholder} {error}</p>}
    </StyledInput>
  );
};

Input.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default Input;
