import styled from 'styled-components';

const StyledIllustration = styled.div`
  width: 180px;
  height: 180px;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: center;
  }
`;

StyledIllustration.displayName = 'StyledIllustration';

export default StyledIllustration;
