module.exports = (() => {
  const thisState = {};

  const init = function() {
    _bindFunctions();
    initShowUserNotesModal();
    cohortDataTable();
  };

  const _bindFunctions = () => {
    $('.show-user-notes-students_notes--js').on('click', toggleShowUserNotesModal);
    $('.show-user-notes-afa_students_notes--js').on('click', toggleShowUserNotesModal);
    $('.show-user-notes-unpaid_student_notes--js').on('click', toggleShowUserNotesModal);
    $('.show-user-notes-mentor_notes--js').on('click', toggleShowUserNotesModal);
    $('.show-user-notes-tutor_notes--js').on('click', toggleShowUserNotesModal);
    $('.show-user-notes-afa_mentor_notes--js').on('click', toggleShowUserNotesModal);
    $('.show-user-notes-afa_tutor_notes--js').on('click', toggleShowUserNotesModal);
  };

  const initShowUserNotesModal = () => {
    let userNotes = document.querySelectorAll('.show_user_notes_students_notes_modal');
    let afaNotes = document.querySelectorAll('.show_user_notes_afa_students_notes_modal');
    let unpaidNotes = document.querySelectorAll('.show_user_notes_unpaid_students_notes_modal');
    let mentorNotes = document.querySelectorAll('.show_user_notes_mentor_notes_modal');
    let tutorNotes = document.querySelectorAll('.show_user_notes_tutor_notes_modal');
    let afaMentorNotes = document.querySelectorAll('.show_user_notes_afa_mentor_notes_modal');
    let afaTutorNotes = document.querySelectorAll('.show_user_notes_afa_tutor_notes_modal');

    userNotes.forEach(function(note, index){
      thisState[`showUserNotesModal_students_notes_${index}`] = new CF.Modal(`#students_notes_modal_${index}`);
    })

    afaNotes.forEach(function(note, index){
      thisState[`showUserNotesModal_afa_students_notes_${index}`] = new CF.Modal(`#afa_students_notes_modal_${index}`);
    })

    unpaidNotes.forEach(function(note, index){
      thisState[`showUserNotesModal_unpaid_student_notes_${index}`] = new CF.Modal(`#unpaid_student_notes_modal_${index}`);
    })

    mentorNotes.forEach(function(note, index){
      thisState[`showUserNotesModal_mentor_notes_${index}`] = new CF.Modal(`#mentor_notes_modal_${index}`);
    })

    tutorNotes.forEach(function(note, index){
      thisState[`showUserNotesModal_tutor_notes_${index}`] = new CF.Modal(`#tutor_notes_modal_${index}`);
    })

    afaMentorNotes.forEach(function(note, index){
      thisState[`showUserNotesModal_afa_mentor_notes_${index}`] = new CF.Modal(`#afa_mentor_notes_modal_${index}`);
    })

    afaTutorNotes.forEach(function(note, index){
      thisState[`showUserNotesModal_afa_tutor_notes_${index}`] = new CF.Modal(`#afa_tutor_notes_modal_${index}`);
    })
  };

  const toggleShowUserNotesModal = e => {
    e.preventDefault();
    e.stopPropagation();

    thisState[e.target.dataset.target].toggleModal();
  };

  var cohortDataTable = function() {
    $('.cohort_data_table').DataTable({
      "paging": false,
      "info": false,
      "searching": false
    });
  };

  return {
    init: init
  };
})();
