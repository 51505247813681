import styled from 'styled-components';

const StyledMediaResourceCarousel = styled.div`
    overflow: hidden;
    text-align: center;
    cursor: ${(props) => `${props.isDragging ? 'grabbing' : 'default'} !important`};

    .media-resources {
        position: relative;
        display: flex;
        width: 130%;
        transition: all 0.5s ease-in;
    }

    .media-resources:first-child{
      margin-left: ${(props) => `${props.offSet} !important`};
    }

    .dots {
      margin-top: 20px;
    }

    i {
      transition: all 0.5s ease-in;
      cursor: pointer;
      padding: 10px;
    }

    .inactive {
      color: var(--ds-color__grey--light);
    }
`;

StyledMediaResourceCarousel.displayName = 'StyledMediaResourceCarousel';

export default StyledMediaResourceCarousel;
