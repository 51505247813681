import {
  handleActivate,
  handleClose,
  handleDelete,
  updateContractStatus,
} from '../../actions';
import ConfirmationModal from '../../components/ConfirmationModal';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  instructorsCandidatesPage: {
    errorMessage,
    loadingButton,
    modalAction,
    modalIsOpen,
  },
}) => ({
  errorMessage,
  loadingButton,
  modalAction,
  modalIsOpen,
});

export const mapDispatchToProps = (dispatch) => ({
  handleActivate: (id) => dispatch(handleActivate(id)),
  handleClose: () => dispatch(handleClose()),
  handleDelete: (id) => dispatch(handleDelete(id)),
  updateContractStatus: (id, payload) =>
    dispatch(updateContractStatus(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
