import React, { useEffect, useState } from 'react';
import MediaResource from '../../../MediaLibrary/components/MediaResource';
import PropTypes from 'prop-types';
import StyledMediaResourceCarousel from './styles/StyledMediaResourceCarousel';
import { last } from 'lodash';
import useDebounce from '../../../shared/hooks/useDebounce';

const MediaResourceCarousel = ({ mediaResources, isMobile }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [offSet, setOffset] = useState('0%');
  const [storedPageX, setStoredPageX] = useState(null);
  const [direction, setDirection] = useState('+');
  const [resourceIndexes] = useState(
    Array(mediaResources.length)
      .fill(null)
      .map((_, i) => i)
  );
  const [activeResourceCount, setActiveResourceCount] = useState(
    isMobile ? 1 : 2
  );
  const [activeResources, setActiveResources] = useState(
    resourceIndexes.slice(0, activeResourceCount)
  );
  const debouncedActiveResources = useDebounce(activeResources, 100);

  useEffect(() => {
    setActiveResourceCount(isMobile ? 1 : 2);
  }, [isMobile]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mouseup', () => setIsDragging(false));
      window.addEventListener('ondragend', () => setIsDragging(false));
      window.addEventListener('ontouchend', () => setIsDragging(false));
    }
  }, [isDragging]);

  useEffect(() => {
    setOffset(`-${multiply(debouncedActiveResources) * (isMobile ? 45 : 15)}%`);
  }, [debouncedActiveResources]);

  const multiply = (array) => {
    let sum = 1;
    for (let i = 0; i < array.length; i++) {
      sum = sum * array[i];
    }
    return sum;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    determineDirection(e);
    checkBoundary();
  };

  const handleDrag = () => {
    setIsDragging(true);
  };

  const determineDirection = (e) => {
    const eventX = e.pageX || e.touches[0].pageX;
    if (eventX < storedPageX) {
      setDirection('+');
    } else if (eventX > storedPageX) {
      setDirection('-');
    }
    setStoredPageX(eventX);
  };

  const checkBoundary = () => {
    if (
      direction === '+' &&
      last(debouncedActiveResources) < last(resourceIndexes)
    ) {
      setActiveResources(debouncedActiveResources.map((value) => value + 1));
    } else if (direction === '-' && debouncedActiveResources[0] > 0) {
      setActiveResources(debouncedActiveResources.map((value) => value - 1));
    }
  };

  return (
    <StyledMediaResourceCarousel
      isDragging={isDragging}
      offSet={offSet}
      onDragStart={(e) => handleDrag(e)}
      onMouseDown={(e) => handleDrag(e)}
      onMouseMove={(e) => handleMouseMove(e)}
      onTouchStart={(e) => handleDrag(e)}
      onTouchMove={(e) => handleMouseMove(e)}
    >
      <div className="media-resources">
        {mediaResources.map((mediaResource) => {
          return (
            <MediaResource
              resource={mediaResource}
              key={`${mediaResource.title}`}
              preview={true}
              isDragging={isDragging}
            />
          );
        })}
      </div>
      <div className="dots">
        {resourceIndexes.map((index) => (
          <i
            key={index}
            onClick={() =>
              setActiveResources(
                resourceIndexes.slice(index, index + activeResourceCount)
              )
            }
            className={`fa fa-circle ${
              !activeResources.includes(index) && 'inactive'
            }`}
          />
        ))}
      </div>
    </StyledMediaResourceCarousel>
  );
};

MediaResourceCarousel.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  mediaResources: PropTypes.array.isRequired,
};

export default MediaResourceCarousel;
