import React, { useEffect } from 'react';
import App from '../../components/App';
import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSections } from '../../actions';

export const AppContainer = ({ loading, getSections, selectedTab }) => {
  useEffect(() => {
    document.documentElement.style.scrollPaddingTop = '130px';
    getSections();
  }, []);

  return loading ? <LoadingSpinner /> : <App selectedTab={selectedTab} />;
};

AppContainer.propTypes = {
  getSections: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedTab: PropTypes.string.isRequired,
};

export const mapStateToProps = ({
  instructorProfile: { loading, selectedTab },
}) => ({
  loading,
  selectedTab,
});

export const mapDispatchToProps = (dispatch) => ({
  getSections: () => dispatch(getSections()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
