import styled from 'styled-components';

const StyledIconsWrapper = styled.div`
  display: flex;
  margin-left: 48px;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 46%;
  min-width: inherit;
  margin-right: 24px;

  ${({ hasMargin }) => !hasMargin && `
    margin-left: 0;
    width: unset;
  `}
  @media (max-width: 768px) {
    flex-wrap: wrap;
    min-width: 55%;
    margin-right: 0;
  }
`;

StyledIconsWrapper.displayName = 'StyledIconsWrapper';

export default StyledIconsWrapper;
