import { handleDeleteNote, handleEditNote } from './actions';
import MentorNoteButtons from '../../../components/MentorNotesTab/MentorNoteButtons';
import { connect } from 'react-redux';

export const mapDispatchToProps = (dispatch) => ({
  handleDeleteNote: (id) => dispatch(handleDeleteNote(id)),
  handleEditNote: (id) => dispatch(handleEditNote(id)),
});

export default connect(null, mapDispatchToProps)(MentorNoteButtons);
