import axios, { AxiosResponse } from 'axios';
import PaymentPlan from '../../../enroll/types/PaymentPlan';
import { getAuthToken } from '../../../../utils/getAuthToken';
import { BillingData } from '../types/BillingForm';
import { Dispatch } from 'redux';
import { constants } from '../constants';

export interface ApiCheckoutTransaction {
  checkout_transaction: {
    id: number;
    uuid: string;
    payment_contract_id: number | null;
    user_id: number;
    billing_profile_id: number | null;
    payment_plan_id: number;
    provider: string;
    identifier: string;
    payment_method: string | null;
    status: string;
    enrolment_status: string;
    created_at: string;
    updated_at: string;
    api_version: string;
    product_sku: string;
  };
  client_secret: string;
}

export interface ErrorResponse {
  message: string;
}

export const createCheckoutTransaction =
  (paymentPlan: PaymentPlan, discountCode: String) =>
  async (dispatch: Dispatch): Promise<any | ErrorResponse> => {
    try {
      const response: AxiosResponse<ApiCheckoutTransaction> = await axios.post(
        '/api/v3/checkout_transactions',
        {
          payment_plan_id: paymentPlan.planId,
          discount_code: discountCode,
          authenticity_token: getAuthToken(),
        }
      );

      dispatch({
        type: constants.GET_INITIAL_DATA_SUCCESS,
        payload: {
          transactionId: response.data.checkout_transaction.id,
          clientSecret: response.data.client_secret,
          productSku: response.data.checkout_transaction.product_sku,
        },
      });
    } catch (error) {
      dispatch({
        type: constants.GET_INITIAL_DATA_ERROR,
        payload: {
          error: error?.response?.data?.message,
        },
      });
    }
  };

export async function updateCheckoutTransaction(
  transactionId: number
): Promise<any | ErrorResponse> {
  try {
    const response: AxiosResponse<any> = await axios.post(
      `/api/v3/checkout_transactions/${transactionId}/retrieve_and_update`,
      {
        authenticity_token: getAuthToken(),
      }
    );
    return response.data;
  } catch (error) {
    return { message: error?.response?.data?.message };
  }
}

export async function updateBillingDetails(
  transactionId: number,
  startDate: string,
  params: BillingData
): Promise<any | ErrorResponse> {
  try {
    const response: AxiosResponse<any> = await axios.post(
      `/api/v3/checkout_transactions/${transactionId}/submit`,
      {
        authenticity_token: getAuthToken(),
        address_line1: params.address,
        address_city: params.city,
        address_zip: params.zip,
        start_date: startDate,
      }
    );
    return response.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}
