import styled from 'styled-components';

const Line = styled.div`
  margin: 40px 0px;
  border: solid 2px #e8ebed;
`;

Line.displayName = 'Line';

export default Line;
