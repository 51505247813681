import React, { useEffect, useState } from 'react';
import MediaResource from '../components/MediaResource';
import PropTypes from 'prop-types';
import StyledMediaCategory from '../styles/StyledMediaCategory';

const MediaCategory = ({ category, resources }) => {
  const [itemsPerRow, setItemsPerRow] = useState(4);
  const [expanded, setExpanded] = useState(false);
  const resourcesLength = resources.length;

  useEffect(() => {
    getWindowSize();
    setExpanded(resourcesLength > itemsPerRow);
    window.addEventListener('resize', getWindowSize);
  }, []);

  const getWindowSize = () => {
    if (window.innerWidth < 768) {
      setItemsPerRow(1);
    } else if (window.innerWidth > 767 && window.innerWidth < 1023) {
      setItemsPerRow(2);
    } else if (window.innerWidth > 1022 && window.innerWidth < 1278) {
      setItemsPerRow(3);
    } else {
      setItemsPerRow(4);
    }
  };

  const toggleShow = () => setExpanded(!expanded);
  const hiddenClass = i => itemsPerRow === 1 || i !== 0 ? 'hidden' : '';
  const showToggleButton = itemsPerRow > 1 && resourcesLength > itemsPerRow;

  const splitCollection = (collection, itemsPerRow) => {
    const rows = Array.from(Array(Math.ceil(collection.length / itemsPerRow)), () => []);
    rows.forEach((_, i) => {
      const number = i * itemsPerRow;
      rows[i] = (collection.slice(number, number + itemsPerRow));
    });
    return rows;
  };

  return (
    <>
      <hr />
      <StyledMediaCategory>
        <div onClick={toggleShow}>
          <p className="ds-typography__lead--bold">{category}</p>
          <div>
            {showToggleButton && (
              <>
                <p className="ds-typography__body">Show {expanded ? 'more' : 'less'}</p>
                <i className={`fa fa-chevron-circle-${expanded ? 'down' : 'up'}`}></i>
              </>
            )}
          </div>
        </div>
        {splitCollection(resources, itemsPerRow).map((row, i) => (
          <div key={`row_${i}`}>
            {!expanded && <hr className="mobile-border" />}
            <div className={`ds-grid__row resource-row ${expanded && hiddenClass(i)}`}>
              {row.map(resource => <MediaResource resource={resource} key={`${resource.title}-${i}`} preview={false}/>)}
            </div>
          </div>
        ))}
      </StyledMediaCategory>
    </>
  );
};

MediaCategory.propTypes = {
  category: PropTypes.string,
  resources: PropTypes.array,
};

export default MediaCategory;
