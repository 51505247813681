// file can be presigned url or base64 string
export const isUrl = (file) => {
  if (file == null) {
    return;
  }

  return file.substring(0, 5) === 'https';
};

export const formatPayload = (payload) => {
  const filterOutLinks = payload.filter((obj) => !isUrl(obj.data.data));
  const formattedPayload = filterOutLinks.map((obj) => ({
    ...obj,
    ...obj.data,
  }));
  return formattedPayload;
};
