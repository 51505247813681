import ApiService from '../../services/ApiService';
import { getUserData as getUserApiData } from '../../services/getUserData';

export const getData = (userId) => (dispatch) => {
  return Promise.all([
    getUserData(userId),
    getCurrentUserId(),
    getOnboardingAnswers(userId),
  ]).then(([user, currentUserId, answers]) => {
    dispatch({
      answers: answers,
      currentUserId,
      type: 'UserProfile.LOAD_DATA_SUCCESS',
      user,
    });
  });
};

export const getCallReviewData = (userId) => async (dispatch) => {
  const apiService = new ApiService(`/api/v1/users/${userId}/call_reviews`);

  try {
    const result = await apiService.makeGetCall();
    dispatch({
      callReviews: result.data.callReviews,
      type: 'UserProfile.CALL_REVIEWS_LOADED',
    });
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

const getUserData = async (userId) => {
  const apiService = new ApiService(`/api/v1/users/${userId}.json`);

  try {
    const result = await apiService.makeGetCall();
    return result.data.data;
  } catch (error) {
    return error;
  }
};

export const getCourseData = (userId) => async (dispatch) => {
  const apiService = new ApiService(`/api/v1/user_attendees/${userId}.json`);

  dispatch({
    type: 'UserProfile.GET_COURSE_DATA_REQUEST',
  });
  try {
    const result = await apiService.makeGetCall();
    dispatch({
      data: result.data.data,
      type: 'UserProfile.GET_COURSE_DATA_SUCCESS',
    });
  } catch (error) {
    dispatch({
      type: 'UserProfile.GET_COURSE_DATA_FAILURE',
    });
    return error;
  }
};

const getOnboardingAnswers = async (userId) => {
  const apiService = new ApiService(
    `/api/v1/surveys/survey_answers.json?user_id=${userId}&question_type=onboarding`
  );
  try {
    const result = await apiService.makeGetCall();
    return result.data.data.answers;
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentUserId = async () => {
  try {
    const result = await getUserApiData();
    return result.data.data.id;
  } catch (error) {
    return error;
  }
};

export const getMentorNotes = (userId) => async (dispatch) => {
  const apiService = new ApiService(
    `/api/v1/users/${userId}/mentor_notes.json`
  );

  try {
    const result = await apiService.makeGetCall();
    dispatch({
      mentorNotes: result.data.data,
      type: 'UserProfile.MENTOR_NOTES_LOADED',
    });
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const setSelectedCourse = (courseId, cardState) => {
  return {
    cardState,
    courseId,
    type: 'UserProfile.SET_SELECTED_COURSE',
  };
};

export const getInterviewAnswers = (studentId) => async (dispatch) => {
  const apiService = new ApiService('/api/v1/interview_answers');
  try {
    dispatch(setMentorPage());
    dispatch(setLoadingInterview());
    const result = await apiService.makeGetCall({ student_id: studentId });
    dispatch({
      data: result.data.data,
      type: 'UserProfile.GET_INTERVIEW_ANSWERS_SUCCESS',
    });
  } catch (error) {
    dispatch({
      error: error,
      type: 'UserProfile.GET_INTERVIEW_ANSWERS_FAILURE',
    });
  }
};

export const showStudentResponse = (answer) => ({
  data: answer,
  type: 'UserProfile.EVALUATE_PREVIOUS_RESPONSES',
});

export const handleCloseModal = () => (dispatch) => {
  dispatch({ type: 'UserProfile.CLOSE_EVALUATION' });
};

export const setMentorPage = () => (dispatch) => {
  dispatch({ type: 'UserProfile.SET_MENTOR_PAGE' });
};

export const setCurrentAnswer = (currentAnswer) => ({
  currentAnswer: { ...currentAnswer },
  type: 'UserProfile.SET_CURRENT_ANSWER',
});

export const setLoadingInterview = () => (dispatch) => {
  dispatch({ type: 'UserProfile.SET_LOADING_INTERVIEWS' });
};

export const openQuestionInsights = (question, insights) => ({
  insights,
  question,
  type: 'UserProfile.OPEN_QUESTION_INSIGHTS',
});
