import styled from 'styled-components';

const StyledConsentForm = styled.div`
  .checkbox-wrapper {
    margin-left: 20%;
    display: flex;

    input {
      margin-top: 0;
    }

    label {
      margin-left: 6px;
      max-width: 400px;
      line-height: 1.67;
      font-size: 16px;
      font-weight: normal;

      strong {
        font-weight: bold !important;
      }
    }

    span {
      display: block;
      margin-top: 0;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  input[type='checkbox'] {
    border: 2px solid #a7b1b9;
  }

  @media (max-width: 768px) {
    .checkbox-wrapper {
      margin-left: 0%;
      label {
        width: 90%;
      }
    }
  }
`;

StyledConsentForm.displayName = 'StyledConsentForm';

export default StyledConsentForm;
