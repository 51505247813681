import styled from 'styled-components';

const CourseTileWrapper = styled.div`
  padding: ${(props) => (props.isCourseUnderProgram ? '1rem' : '1.5rem')} 1rem;
  border-bottom: solid 1px #c0c6cb;
  background-color: ${({
    isAccessible,
    isCourseSelected,
    isCourseUnderProgram,
  }) => {
    if (isAccessible && isCourseSelected) {
      return 'var(--ds-color__petrol)';
    } else if (isAccessible && isCourseUnderProgram) {
      return 'var(--ds-color__grey--light_300)';
    } else {
      return 'var(--ds-color__white)';
    }
  }};

  h4 {
    word-break: break-word;
    color: ${({ isAccessible, isCourseSelected }) => {
      if (!isAccessible) {
        return 'rgba(34, 60, 80, 0.4)';
      } else if (isCourseSelected) {
        return 'var(--ds-color__white)';
      } else {
        return 'var(--ds-color__indigo)';
      }
    }};
  }

  cursor: ${({ isAccessible }) => (isAccessible ? 'pointer' : 'not-allowed')};
`;

CourseTileWrapper.displayName = 'CourseTileWrapper';

export default CourseTileWrapper;
