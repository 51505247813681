import ConfirmationModal from '../../containers/ConfirmationModal';
import Header from '../Header';
import React from 'react';
import { StyledApp } from './styles';
import Table from '../../containers/Table';
import Tabs from '../../containers/Tabs';

const App = () => (
  <div className="ds-grid__row">
    <div className="ds-grid__col-xs-12">
      <StyledApp>
        <Header />
        <Tabs />
        <Table />
        <ConfirmationModal />
      </StyledApp>
    </div>
  </div>
);

export default App;
