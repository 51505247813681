import axios from 'axios';
import { getAuthToken } from '../../../../../utils/getAuthToken';
import { getInitialData } from '../App/action';

export const SAVING_INSTRUCTOR_ASSIGNMENT = 'AssignmentsPage.SAVING_INSTRUCTOR_ASSIGNMENT';
export const SAVE_INSTRUCTOR_ASSIGNMENT_SUCCESS = 'AssignmentsPage.SAVE_INSTRUCTOR_ASSIGNMENT_SUCCESS';
export const STUDENTS_TABS_CHANGE = 'AssignmentsPage.STUDENTS_TABS_CHANGE';
export const SET_ASSIGNING_STUDENT_ID = 'AssignmentsPage.SET_ASSIGNING_STUDENT_ID';
export const UNSET_ASSIGNMENT_IDS = 'AssignmentsPage.UNSET_ASSIGNMENT_IDS';

export const setStudentsTab = tabTitle => ({
  tabTitle,
  type: STUDENTS_TABS_CHANGE,
});

export const setAssigningStudentId = id => ({
  id,
  type: SET_ASSIGNING_STUDENT_ID,
});

export const unsetAssignmentIds = () => ({
  type: UNSET_ASSIGNMENT_IDS,
});

export const saveInstructorAssignment = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SAVING_INSTRUCTOR_ASSIGNMENT });

    const { assigningStudentInfo } = getState().assignmentsPage;

    await axios.post('/api/v1/admin/assignments', {
      assignment: {
        attendee_id: assigningStudentInfo.id,
        mentor_id: assigningStudentInfo.mentor?.user_id,
        tutor_id: assigningStudentInfo.tutor?.user_id,
      },
      authenticity_token: getAuthToken(),
    });

    dispatch({ type: SAVE_INSTRUCTOR_ASSIGNMENT_SUCCESS });
    getInitialData()(dispatch);
  } catch (e) {
    console.log(e);
  }
};
