import styled from 'styled-components';

const Wrapper = styled.button`
  .str-chat__avatar.str-chat__avatar--circle {
    flex-basis: 40px;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    width: 40px;

    .str-chat__avatar-image {
      flex-basis: 40px;
      height: 40px;
      object-fit: cover;
      width: 40px;
    }
  }
`;

Wrapper.displayName = 'Wrapper';

export { Wrapper };
