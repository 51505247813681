import ApiService from '../../../services/ApiService';
import { Action } from './types';
import { Actions } from './constants';
import { Dispatch } from 'redux';
import { ICreateService, IUpdateService } from './interfaces';
import { getAuthToken } from '../../../utils/getAuthToken';
import { keysToSnake } from '../../../utils/keysConverter';

export const setModalAction = (modalAction: string) => ({
  payload: modalAction,
  type: Actions.SET_MODAL_ACTION,
});

export const handleModalClose = () => ({
  type: Actions.HANDLE_MODAL_CLOSE,
});

export const handleEdit = (id: string) => ({
  payload: id,
  type: Actions.HANDLE_EDIT,
});

export const getStudentsAndServices =
  () => async (dispatch: Dispatch<Action>) => {
    const service = new ApiService('/api/v1/off_platform_services/new');

    try {
      const response = await service.makeGetCall();

      dispatch({
        type: Actions.GET_STUDENTS_AND_SERVICES_SUCCESS,
        payload: {
          students: response.data.students,
          serviceTypes: keysToSnake(response.data.serviceTypes),
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const handleSubmit =
  (params: ICreateService) => async (dispatch: Dispatch<Action>) => {
    const service = new ApiService('/api/v1/off_platform_services');
    const formattedParams = {
      authenticity_token: getAuthToken(),
      off_platform_service: {
        ...params,
        serviceDate: params.serviceDate.toString(),
      },
    };

    try {
      const response = await service.makePostCall(keysToSnake(formattedParams));

      dispatch({
        type: Actions.HANDLE_SUBMIT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: Actions.HANDLE_SUBMIT_ERROR,
        payload: error,
      });
      console.log(error);
    }
  };

export const getServices = () => async (dispatch: Dispatch<Action>) => {
  const service = new ApiService('/api/v1/off_platform_services');

  try {
    const response = await service.makeGetCall();

    dispatch({
      type: Actions.GET_SERVICES_SUCCESS,
      payload: response.data.services,
    });
  } catch (error) {
    console.log(error);
  }
};

export const handleDelete =
  (id: string) => async (dispatch: Dispatch<Action>) => {
    const deleteService = new ApiService(`/api/v1/off_platform_services/${id}`);
    const params = {
      data: {
        authenticity_token: getAuthToken(),
      },
    };

    try {
      const response = await deleteService.makeDeleteCall(params);

      if (!response.error) {
        dispatch({
          type: Actions.HANDLE_DELETE_SUCCESS,
          payload: id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const handleUpdate =
  (params: IUpdateService, id: string) =>
  async (dispatch: Dispatch<Action>) => {
    const service = new ApiService(`/api/v1/off_platform_services/${id}`);
    const formattedParams = {
      authenticity_token: getAuthToken(),
      off_platform_service: {
        ...params,
        serviceDate: params?.serviceDate?.toString(),
      },
    };

    try {
      const response = await service.makePutCall(keysToSnake(formattedParams));

      dispatch({
        type: Actions.HANDLE_UPDATE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: Actions.HANDLE_UPDATE_ERROR,
        payload: error,
      });
    }
  };
