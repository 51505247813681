import React, { useEffect, useState } from 'react';
import NotesForm from './styles/NotesForm';
import PropTypes from 'prop-types';
import useDebounce from '../../../shared/hooks/useDebounce';

const NotesSection = ({ answer, handleEditNote, isModal }) => {
  const [tempNotes, setTempNotes] = useState(answer.notes);
  const [notesSaved, setNotesSaved] = useState(false);

  const debouncedTempNotes = useDebounce(tempNotes, 300);

  useEffect(() => {
    if (answer.notes !== tempNotes) {
      handleEditNote(answer.id, tempNotes);
      setNotesSaved(true);
    }
  }, [debouncedTempNotes]);

  const handleTempNotes = ({ target: { value } }) => {
    setNotesSaved(false);
    setTempNotes(value);
  };

  return (
    <NotesForm onSubmit={handleEditNote}>
      <textarea
        type="text"
        value={tempNotes}
        placeholder="Add note"
        onChange={handleTempNotes}
        name="notes"
      />
      {(!isModal && notesSaved) && <p className="ds-typography__body">Your response has been saved automatically.</p>}
    </NotesForm>
  );
};

NotesSection.propTypes = {
  answer: PropTypes.object.isRequired,
  handleEditNote: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
};

export default NotesSection;
