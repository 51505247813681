import styled from 'styled-components';

const StyledInterviewTool = styled.div`
  background: #F1F1F1;
  margin: 0;
    padding: 0;

    .wrapper {
      padding: 0;
      width: 100%
    }

  @media (max-width: 768px) {
    background: #fff;
    padding: 0;
  }
`;

StyledInterviewTool.displayName = 'StyledInterviewTool';

export default StyledInterviewTool;
