import Exercise from '../Exercise';
import PropTypes from 'prop-types';
import React from 'react';
import RevisionTitle from './styles/RevisionTitle';

const List = ({ next, past, week }) => (
  <>
    {week.map((ex) => (
      <Exercise key={ex.order} ex={ex} next={next === ex.order} />
    ))}
    {past && (
      <>
        <RevisionTitle className='ds-typography__small no-margin'>
          These tasks are in need of revisions:
        </RevisionTitle>
        {past.map((ex) => (
          <Exercise key={ex.order} ex={ex} />
        ))}
      </>
    )}
  </>
);

List.propTypes = {
  next: PropTypes.string,
  past: PropTypes.arrayOf(PropTypes.object),
  week: PropTypes.array.isRequired,
};

export default List;
