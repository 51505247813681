import styled from 'styled-components';

const StyledInstruction = styled.div`
  display: flex;
  margin-left: 20%;

  svg {
    margin-right: 15px;
  }
`;

StyledInstruction.displayName = 'StyledInstruction';

export default StyledInstruction;
