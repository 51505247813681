import styled from 'styled-components';

const StyledProfileSection = styled.div`
  .section-header {
    font-size: 24px;
    font-weight: 500;
    line-height: 2.33;
  }

  .mr-33 {
    padding: 0 33px 0 0;
  }

  .mb-25 {
    margin-bottom: 25px;
  }


  .small {
    margin: 19px 0 8px 0 !important;
  }
  i {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    .section-header {
      line-height: 1.5;
      margin-bottom: 13px;
    }
  }
`;

StyledProfileSection.displayName = 'StyledProfileSection';

export default StyledProfileSection;
