import EmployerModal from '../../components/modal';
import React from 'react';
import { connect } from 'react-redux';
import { handleCloseModal } from '../../actions';
const EmployerModalContainer = (props) => {
  return (
    <EmployerModal {...props} />
  );
};

export const mapStateToProps = ({
  employerDashboard: {
    modalIsOpen,
    modalAction,
  },
}) => ({
  modalAction,
  modalIsOpen,
});

export const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => dispatch(handleCloseModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployerModalContainer);
