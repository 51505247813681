import React, { useEffect } from 'react';
import CurrentExerciseCta from '../../components/CurrentExerciseCta';
import { Locale } from '../../../../shared/enums';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRequiredData } from '../../actions';
import { getCourse } from '../../../shared/helpers/courseHelpers';
import { shouldShowCta } from '../../helpers';

const CurrentExerciseCtaContainer = ({ fetchRequiredData, ...rest }) => {
  useEffect(() => {
    fetchRequiredData();
  }, []);

  return <CurrentExerciseCta {...rest} />;
};

CurrentExerciseCtaContainer.propTypes = {
  fetchRequiredData: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const { attendeesData, meData } = state.currentExerciseCta;
  const isAnyCourseActive = !!getCourse({
    courses: attendeesData.attendees || [],
    state: 'active',
  });
  const isStudent = meData.userType === 'student';

  return {
    isVisible:
      isAnyCourseActive &&
      isStudent &&
      shouldShowCta({ locale: Locale.en, pathname: window.location.pathname }),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchRequiredData: () => dispatch(fetchRequiredData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentExerciseCtaContainer);
