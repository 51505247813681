import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

const ContractConfirm = ({
  handleClose,
  loadingButton,
  modalAction,
  updateContractStatus,
}) => {
  const { candidateId } = useParams();
  const contractSent = modalAction === 'contract_sent';
  const heading = contractSent
    ? 'Did you send the contract?'
    : 'Is the contract signed?';
  const subHeading = `Please confirm that the contract ${
    contractSent
      ? 'was sent to the candidate via HelloSign'
      : 'has been signed by both parties'
  }.`;
  const buttonText = `It's ${contractSent ? 'sent' : 'signed'}`;

  return (
    <>
      <i className="ds-icon__info-line"></i>
      <h3 className="ds-typography__h3">{heading}</h3>
      <p className="ds-typography__lead">{subHeading}</p>
      <button className="ds-button__secondary" onClick={handleClose}>
        Not yet
      </button>
      <button
        className="ds-button__primary"
        onClick={() =>
          updateContractStatus(candidateId, { [modalAction]: true })
        }
      >
        {loadingButton ? <LoadingSpinner /> : buttonText}
      </button>
    </>
  );
};

ContractConfirm.propTypes = {
  handleClose: PropTypes.func,
  loadingButton: PropTypes.bool,
  modalAction: PropTypes.string,
  updateContractStatus: PropTypes.func,
};

export default ContractConfirm;
