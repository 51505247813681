module.exports = (() => {
  var init = function(){
    _initializeDatePicker();
  };

  var _initializeDatePicker = function(){
    $(".datepicker").datepicker({
      dateFormat: 'yy-mm-dd'
    });
  };

  return {
    init: init
  };
})();
