import { SET_GUIDED_STEPS, SET_USER_DATA, TOGGLE_TOUR } from './constants';

export const initialState = {
  active: false,
  steps: [],
  userData: null,
};

export default function (state = initialState, action) {
  return { ...state, ...reducer(state, action) };
}

const reducer = (state, action) =>
  ({
    [SET_GUIDED_STEPS]: {
      steps: action.steps,
    },
    [SET_USER_DATA]: {
      userData: action.userData,
    },
    [TOGGLE_TOUR]: {
      active: action.active,
    },
  }[action.type]);
