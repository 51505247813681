import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2rem 1.5rem;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const LoadMoreCta = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ds-color__petrol);
  cursor: pointer;
  margin-top: 2rem;

  i {
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }
`;
const SearchInputWrapper = styled.div`
  margin-bottom: 2rem;

  i {
    position: absolute;
    font-size: 1.25rem;
    margin: calc(0.75rem / 2);
  }
`;

const SearchInput = styled.input`
  height: 2rem;
  width: 16rem;
  padding-left: 2rem;
  border: 1px solid var(--ds-color__grey--400);
  border-radius: 0.25rem;
`;

export { Wrapper, LoadMoreCta, SearchInput, SearchInputWrapper };
