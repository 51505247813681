import React, { useEffect } from 'react';
import { Accordion } from 'react-accessible-accordion';
import Card from '../Dashboard/styles/Card';
import Category from './Category';
import PropTypes from 'prop-types';
import StyledAccordion from './styles/StyledAccordion';
import StyledCardParagraph from '../Dashboard/styles/StyledCardParagraph';
import StyledCardSubheader from '../Dashboard/styles/StyledCardSubheader';

const AnswerHistoryCard = ({
  selectedCategory,
  interviewAnswers,
  openQuestionInsights,
  isMentorPage,
}) => {
  const ACCORDIAN_HEADER_HEIGHT = 150;
  useEffect(() => {
    if (selectedCategory) {
      const categoryEl = document.getElementById(`accordion__panel-${selectedCategory.replace(/ /g, '')}`);
      window.scrollTo({ behavior: 'smooth', top: categoryEl.offsetHeight - ACCORDIAN_HEADER_HEIGHT });
    }
  }, []);

  const headerTitle = isMentorPage ? 'Interview Answers' : 'History';
  const emptyMessageText = isMentorPage ? 'No shared interview answers yet' : 'You haven\'t answered any interview questions yet';
  return (
    <Card>
      <StyledCardSubheader className="ds-typography__h4">{ headerTitle }</StyledCardSubheader>
      {!isMentorPage && <StyledCardParagraph className="ds-typography__body">Here you’ll find a list of questions that you answered during previous practices, grouped by respective categories. You’ll also be able to access the recordings of your past answers, notes, and self-evaluation ratings.</StyledCardParagraph> }
      {interviewAnswers.length
        ? <StyledAccordion>
          <Accordion
            allowZeroExpanded
            allowMultipleExpanded
            preExpanded={!isMentorPage ? [`${interviewAnswers[0].category.replace(/ /g, '')}`] : []}>
            {interviewAnswers.map((category, i) => (
              <Category
                key={category.category}
                category={category}
                openQuestionInsights={openQuestionInsights}
              />
            ))}
          </Accordion>
        </StyledAccordion>
        : <p className="ds-typography__body--bold">{emptyMessageText}</p>}
    </Card>
  );
};

AnswerHistoryCard.propTypes = {
  interviewAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          answers: PropTypes.arrayOf(
            PropTypes.shape({
              attachment: PropTypes.shape({
                fileIcon: PropTypes.string,
                fileName: PropTypes.string,
                id: PropTypes.number,
                s3Url: PropTypes.string,
              }),
              category: PropTypes.string,
              date: PropTypes.string,
              id: PropTypes.number,
              industry: PropTypes.string,
              notes: PropTypes.string,
              question: PropTypes.string,
              questionInsights: PropTypes.string,
              rating: PropTypes.number,
              shared: PropTypes.bool,
            }),
          ),
          question: PropTypes.string,
          questionInsights: PropTypes.string,
        }),
      ),
    }),
  ),
  isMentorPage: PropTypes.bool.isRequired,
  openQuestionInsights: PropTypes.func,
  selectedCategory: PropTypes.string,
};

export default AnswerHistoryCard;
