import ApiService from '../../services/ApiService';
import { constants } from './constants';
import { Dispatch } from 'redux';
import { Action } from './types';
import { IUpdateAdmissionParams } from './interfaces';
import { getAuthToken } from '../../utils/getAuthToken';

const progress = new ApiService('/api/v1/afa_admissions/status');
const admission = new ApiService('/api/v1/afa_admissions');

export const getInitialData = () => async (dispatch: Dispatch<Action>) => {
  try {
    const admissionResponse = await admission.makeGetCall();
    const progressResponse = await progress.makeGetCall();

    dispatch({
      type: constants.GET_INITIAL_DATA_SUCCESS,
      payload: {
        admission: admissionResponse.data,
        progress: progressResponse.data,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const setSelectedTab = (tab: string) => ({
  type: constants.SET_SELECTED_TAB,
  payload: tab,
});

export const setSelectedSubTab = (tab: string) => ({
  type: constants.SET_SELECTED_SUB_TAB,
  payload: tab,
});

export const setTabIsLoading = (isLoading: boolean) => ({
  type: constants.SET_TAB_IS_LOADING,
  payload: isLoading,
});

export const updateAdmission =
  (params: IUpdateAdmissionParams, tab: string) =>
  async (dispatch: Dispatch<Action>) => {
    const formattedParams = {
      authenticity_token: getAuthToken(),
      ...params,
    };

    try {
      const admissionResponse = await admission.makePutCall(formattedParams);
      const progressResponse = await progress.makeGetCall();

      dispatch({
        type: constants.UPDATE_ADMISSION_SUCCESS,
        payload: {
          admission: admissionResponse.data,
          progress: progressResponse.data,
          tab,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
