import { CategoryHeading, InfoSection, InfoWrapper } from './styles';
import CandidateNotes from '../../containers/CandidateNotes';
import ConfirmationModal from '../../containers/ConfirmationModal';
import Documents from './Documents';
import Header from './Header';
import PersonalInformation from './PersonalInformation';
import PropTypes from 'prop-types';
import React from 'react';
import Survey from '../../containers/Survey';
import { documentsRequired } from '../../helpers';

const Candidate = ({
  candidate,
  handleContractCheckbox,
  handleOpen,
  updateCandidate,
}) => (
  <div className='ds-grid__container'>
    <Header
      candidate={candidate}
      updateCandidate={updateCandidate}
      handleContractCheckbox={handleContractCheckbox}
      handleOpen={handleOpen}
    />
    <CandidateNotes />
    <InfoSection>
      <div className='ds-grid__row'>
        <InfoWrapper className='ds-grid__col-xs-5' borderRight>
          <PersonalInformation data={candidate.personal_billing_information} />
        </InfoWrapper>
        <InfoWrapper className='ds-grid__col-xs-7' paddingLeft>
          <InfoWrapper borderBottom>
            <CategoryHeading className='ds-typography__h4'>
              Resources
            </CategoryHeading>
            <Survey />
          </InfoWrapper>
          <InfoWrapper>
            {documentsRequired(candidate) && (
              <Documents documents={candidate.documents} />
            )}
          </InfoWrapper>
        </InfoWrapper>
      </div>
    </InfoSection>
    <ConfirmationModal />
  </div>
);

Candidate.propTypes = {
  candidate: PropTypes.object,
  handleContractCheckbox: PropTypes.func,
  handleOpen: PropTypes.func,
  updateCandidate: PropTypes.func,
};

export default Candidate;
