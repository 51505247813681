import Evaluation from '../../containers/Evaluation';
import EvaluationStepper from './EvaluationStepper';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import MentorEvaluation from '../../../UserProfile/containers/MentorEvaluation';
import PropTypes from 'prop-types';
import React from 'react';

const EvaluationPage = ({ isMentorPage, isModal, answersForReview, currentAnswer, toggleNav, currentNavIndex }) => {
  const showStepper = !isModal && !isMentorPage;

  return (
    currentAnswer
      ? (
        <>
          {isMentorPage ? <MentorEvaluation isModal={isModal} onToggleNav={toggleNav}/> : <Evaluation isModal={isModal} onToggleNav={toggleNav}/>}
          {showStepper && (
            <EvaluationStepper
              answersForReview={answersForReview}
              currentAnswer={currentAnswer}
              isModal={isModal}
              onToggle={toggleNav}
              currentNavIndex={currentNavIndex}
            />
          )}
        </>
      )
      : (
        <LoadingSpinner />
      )
  );
};

EvaluationPage.propTypes = {
  answersForReview: PropTypes.array.isRequired,
  currentAnswer: PropTypes.shape({
    attachment: PropTypes.shape({
      fileIcon: PropTypes.string,
      fileName: PropTypes.string,
      id: PropTypes.number,
      s3Url: PropTypes.string,
    }),
    categories: PropTypes.array,
    date: PropTypes.string,
    id: PropTypes.number,
    industry: PropTypes.string,
    notes: PropTypes.string,
    question: PropTypes.string,
    questionInsights: PropTypes.string,
    rating: PropTypes.number,
    shared: PropTypes.bool,
  }),
  currentNavIndex: PropTypes.number,
  isMentorPage: PropTypes.bool,
  isModal: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

export default EvaluationPage;
