module.exports = (() => {
  var init = function(){
    getAllReviews();
    bindFunctions();
  };

  var bindFunctions = function(){
    $('.evaluation-explanation').on('click', showEvaluationExplanation);
  };

  var showEvaluationExplanation = function() {
    $('.evaluation-box').slideToggle("slow");
    if ($('i').hasClass('open')){
      $('i').removeClass('open');
    } else {
      $('i').addClass('open');
    };
  }

  var getAllReviews = function() {
    $.ajax({
      url: '/reviews/' + gon.review_user_id + '/all_reviews',
      method: 'GET'
    })
  };

  return {
    init: init
  };
})();
