import styled from 'styled-components';

const Label = styled.p`
    display: inline-block;
    padding: 2px 5px 4px 5px;
    margin-bottom: 0;
    font-size: 16px;
    background-color: #E3E3E3;
    margin-right: 10px;
`;

Label.displayName = 'Label';

export default Label;
