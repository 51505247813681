import styled from 'styled-components';

export const PWrapper = styled.div`
  width: 590px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0px 16px;
  }
`;

export const PProfileTextarea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  textarea {
    padding: 10px 16px;
    line-height: 1.93;
    font-size: 16px;
    outline: none;
    width: 515px;
    resize: none;
    min-height: 115px;
    background: white;
    border: 1px solid #a7b1b9;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;
