import CareerSupportCenterModal from '../CareerSupportCenterModal';
import JobApplicationsPreviewContainer from '../JobApplicationsPreviewContainer';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const JobApplicationsContainer = ({
  loading,
  isMobile,
  modalIsOpen,
  attendeeId,
}) => {
  return (
    <>
      <JobApplicationsPreviewContainer
        isMobile={isMobile}
        attendeeId={attendeeId}
      />
      {modalIsOpen && (
        <CareerSupportCenterModal
          modalIsOpen={modalIsOpen}
          loading={loading}
          userId={attendeeId}
        />
      )}
    </>
  );
};

JobApplicationsContainer.propTypes = {
  attendeeId: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

export const mapStateToProps = ({
  careerSupportCenter: { jobApplications, loading, modalIsOpen },
}) => ({
  jobApplications,
  loading,
  modalIsOpen,
});

export default connect(mapStateToProps, null)(JobApplicationsContainer);
