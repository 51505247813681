import styled from 'styled-components';

const ModalContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RejectionModalContent = styled(ModalContent)`
  textarea {
    width: 100%;
    height: 80px;
    padding: 16px 16px 40px;
    border: 1px solid var(--ds-color__int-border-secondary);
    border-radius: 4px;
    font-family: TradeGothic-Light;
    font-size: 16px;
    line-height: 24px;

    margin-top: 16px;
    margin-bottom: 32px;
  }
`;

RejectionModalContent.displayName = 'RejectionModalContent';

const ApprovalModalContent = styled(ModalContent)`
  .ds-radio {
    text-align: left;
    margin-top: 16px;
  }

  .ds-radio input[type='radio'] + .radio-label {
    margin-bottom: 16px;
  }
`;

ApprovalModalContent.displayName = 'ApprovalModalContent';

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;

  button:first-of-type {
    margin-right: 8px;
  }
`;

ButtonWrapper.displayName = 'ButtonWrapper';

export {
  ModalContent,
  RejectionModalContent,
  ButtonWrapper,
  ApprovalModalContent,
};
