import React, { useState } from 'react';
import ChatbotMessageList from './ChatbotMessageList';
import CustomChannelHeader from '../CustomChannelHeader';
import CustomMessageInput from '../../../components/CustomMessageInput';
import { MessageInput } from 'stream-chat-react';
import { uploadFile } from '../../../helpers';

interface Props {
  filters?: any;
}

const Chatbot: React.FC<Props> = ({ filters }) => {
  const [shouldShowChannelList, setShouldShowChannelList] = useState(true);

  return (
    <>
      <CustomChannelHeader
        userId={filters?.members?.$in}
        shouldShowChannelList={shouldShowChannelList}
        setShouldShowChannelList={setShouldShowChannelList}
      />
      <ChatbotMessageList
        messageActions={['flag', 'react', 'edit', 'delete']}
        onlySenderCanEdit={true}
      />
      <MessageInput
        doFileUploadRequest={uploadFile}
        grow={true}
        Input={CustomMessageInput}
      />
    </>
  );
};

export default Chatbot;
