import NotesSection from './NotesSection';
import PropTypes from 'prop-types';
import React from 'react';

const EvaluationNotes = ({ currentAnswer, saveReviewData, updateReview, isModal }) => {
  const handleEditNote = (answerId, notes) => {
    if (isModal) {
      saveReviewData({ answerId, notes }, 'notes');
      return;
    }
    updateReview(answerId, { notes: notes });
  };

  return (
    <div className="section text-left evaluation-note">
      <span className="ds-typography__body--bold tour__interview_prep_notes">
        Notes
      </span>
      <NotesSection
        key={currentAnswer.id}
        answer={currentAnswer}
        handleEditNote={handleEditNote}
        isModal={isModal}
      />
    </div>
  );
};

EvaluationNotes.propTypes = {
  currentAnswer: PropTypes.object,
  isModal: PropTypes.bool.isRequired,
  saveReviewData: PropTypes.func.isRequired,
  updateReview: PropTypes.func.isRequired,
};

export default EvaluationNotes;
