import TimeTaken from '../../components/TimeTaken';
import { connect } from 'react-redux';
import { estimationOptionSelected } from '../../actions';

export default connect(
  ({ taskSubmission: { estimationOptions, taskTimeEstimationSaved } }) => ({
    estimationOptions,
    taskTimeEstimationSaved,
  }),
  (dispatch) => ({
    estimationOptionSelected: (option) =>
      dispatch(estimationOptionSelected(option)),
  })
)(TimeTaken);
