import PropTypes from 'prop-types';
import React from 'react';

const SubmittedMessage = ({ name }) => (
  <header>
    <h1 className='ds-typography__h1 ds-color__indigo ds-icon__thumb-up'></h1>
    <h3 className='ds-typography__h3 ds-color__indigo'>Good job, {name}!</h3>
    <p className='ds-typography__body' style={{ marginBottom: '32px' }}>
      You'll get feedback on your submission soon! In the meantime, let us know
      your thoughts about this Exercise. Your feedback will be used to improve
      our curriculum for other students. Thank you!
    </p>
  </header>
);

SubmittedMessage.propTypes = {
  name: PropTypes.string,
};

export default SubmittedMessage;
