import ApiService from './ApiService';

export class SortOrders {
  static getSortOrders() {
    return Object.freeze({ ASC: 'ASC', DSC: 'DESC' });
  }
}

/**
 * API Params
 *
 * page=<page_number>
 * order_by=<column_name>
 * order_direction=<DESC/ASC>
 * per_page=<number_of_entries>
 * q=<filter_query>
 */

class AdminListViewService {
  constructor(dataUrl, perPage) {
    if (!dataUrl) throw new Error('Data URL needs to be supplied');
    this.dataUrl = dataUrl;
    this.SortOrder = SortOrders.getSortOrders();
    this.params = {
      limit: perPage ? perPage : 25,
      search: '',
      course: '',
      startDates: { start: null, end: null },
      endDates: { start: null, end: null },
      offset: 1,
      sortByColumn: '',
      sortOrder: '',
      trialDate: false,
    };
  }

  _makeApiCall = () => {
    const formateDates = (dates) => {
      return {
        start: dates.start ? dates.start.toDateString() : null,
        end: dates.end ? dates.end.toDateString() : null,
      };
    };
    const params = {
      ...(this.params.offset && { page: this.params.offset }),
      ...(this.params.sortByColumn !== '' && {
        order_by: this.params.sortByColumn,
      }),
      ...(this.params.sortOrder !== '' && {
        order_direction: this.params.sortOrder,
      }),
      ...(this.params.search !== '' && { q: this.params.search }),
      ...(this.params.course !== '' && { course: this.params.course }),
      ...(this.params.startDates && {
        start_dates: formateDates(this.params.startDates),
      }),
      ...(this.params.endDates && {
        end_dates: formateDates(this.params.endDates),
      }),
      ...{ trial_date: this.params.trialDate },
      per_page: this.params.limit,
      hide_completed_courses: this.params.hideCompletedCourses,
      hide_job_prep: this.params.hideJobPrep,
    };
    const apiService = new ApiService(this.dataUrl);
    return apiService.makeGetCall(params);
  };

  // Getters

  getLatestResults = () => {
    return this._makeApiCall();
  };

  getParamsObject = () => {
    // return copy of the object
    return Object.assign({}, this.params);
  };

  // Setters

  setTrialDate = (trialDate) => {
    this.params.trialDate = trialDate;
  };

  setSearchQuery = (query) => {
    this.params.search = query;
  };

  setOffset = (offset) => {
    this.params.offset = offset || 0;
  };

  setSortOrder = (order) => {
    this.params.sortOrder = order;
  };

  setSortByCourse = (course) => {
    this.params.course = course;
  };

  setStartDates = (startDates) => {
    this.params.startDates = startDates;
  };

  setEndDates = (endDates) => {
    this.params.endDates = endDates;
  };

  setSortByColumn = (sortByColumn) => {
    this.params.sortByColumn = sortByColumn;
  };

  setLimit = (newLimit) => {
    this.params.limit = newLimit;
  };

  setHideCompletedCourses = (removedCourses) => {
    this.params.hideCompletedCourses = removedCourses;
  };

  setHideJobPrep = (jobPrepCourses) => {
    this.params.hideJobPrep = jobPrepCourses;
  };
}

export default AdminListViewService;
