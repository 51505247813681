export const constants = {
  CREATE_TIME_OFF_FAAILURE: 'timeOff.CREATE_TIME_OFF_FAAILURE',
  CREATE_TIME_OFF_SUCCESS: 'timeOff.CREATE_TIME_OFF_SUCCESS',
  DELETE_TIME_OFF_REQUEST: 'timeOff.DELETE_TIME_OFF_REQUEST',
  FORM_ERROR_END_BEFORE_START:
    'Your selected end date cannot be earlier than the start date',
  FORM_ERROR_TOO_EARLY:
    'Please select a start date more than two days in the future',
  HANDLE_DELETE_CONFIRMATION: 'timeOff.HANDLE_DELETE_CONFIRMATION',
  HANDLE_MODAL_CLOSE: 'timeOff.HANDLE_MODAL_CLOSE',
  LOAD_INITIAL_DATA_SUCCESS: 'timeOff.LOAD_INITIAL_DATA_SUCCESS',
};

export const emails = {
  career_specialist: 'careerservices@careerfoundry.com',
  instructor: 'mentors@careerfoundry.com',
};
