import styled from 'styled-components';

const StyledStatementParagraph = styled.p`
  a {
    display: inline-block !important;
    font-size: inherit !important;
  }
`;

StyledStatementParagraph.displayName = 'StyledStatementParagraph';

export default StyledStatementParagraph;
