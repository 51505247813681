module.exports = (() => {
  const init = function () {
    const selectors = document.querySelectorAll('select');

    for (let i = 0; i < selectors.length; i++) {
      selectors[i].addEventListener('change', function () {
        this.form.submit();
      });
    }
  };

  return {
    init: init,
  };
})();
