export const constants = {
  CLOSE_EMPLOYER_CONNECTOR_MODAL:
    'CareerSupportCenter.CLOSE_EMPLOYER_CONNECTOR_MODAL',
  CLOSE_INTERACTIVE_MESSAGES: 'CareerSupportCenter.CLOSE_INTERACTIVE_MESSAGES',
  CREATE_JOB_APPLICATION_FAILURE:
    'CareerSupportCenter.CREATE_JOB_APPLICATION_FAILURE',
  CREATE_JOB_APPLICATION_REQUEST:
    'CareerSupportCenter.CREATE_JOB_APPLICATION_REQUEST',
  CREATE_JOB_APPLICATION_SUCCESS:
    'CareerSupportCenter.CREATE_JOB_APPLICATION_SUCCESS',
  DELETE_JOB_APPLICATION_FAILURE:
    'CareerSupportCenter.DELETE_JOB_APPLICATION_FAILURE',
  DELETE_JOB_APPLICATION_SUCCESS:
    'CareerSupportCenter.DELETE_JOB_APPLICATION_SUCCESS',
  GET_CURRENT_USER_SUCCESS: 'CareerSupportCenter.GET_CURRENT_USER_SUCCESS',
  GET_JOB_APPLICATIONS_FAILURE:
    'CareerSupportCenter.GET_JOB_APPLICATIONS_FAILURE',
  GET_JOB_APPLICATIONS_SUCCESS:
    'CareerSupportCenter.GET_JOB_APPLICATIONS_SUCCESS',
  GET_JOB_APPLICATION_FAILURE:
    'CareerSupportCenter.GET_JOB_APPLICATION_FAILURE',
  GET_JOB_SEEKING_PROFILE_FAILURE:
    'CareerSupportCenter.GET_JOB_SEEKING_PROFILE_FAILURE',
  GET_JOB_SEEKING_PROFILE_SUCCESS:
    'CareerSupportCenter.GET_JOB_SEEKING_PROFILE_SUCCESS',
  HANDLE_ATTACHMENT_UPDATE: 'CareerSupportCenter.HANDLE_ATTACHMENT_UPDATE',
  HANDLE_CANCEL_MODAL: 'CareerSupportCenter.HANDLE_CANCEL_MODAL',
  HANDLE_CLOSE_MODAL: 'CareerSupportCenter.HANDLE_CLOSE_MODAL',
  HANDLE_DELETE_JOB_APPLICATION:
    'CareerSupportCenter.HANDLE_DELETE_JOB_APPLICATION',
  HANDLE_NEW_JOB_APPLICATION: 'CareerSupportCenter.HANDLE_NEW_JOB_APPLICATION',
  HANDLE_UPDATE_JOB_APPLICATION:
    'CareerSupportCenter.HANDLE_UPDATE_JOB_APPLICATION',
  HANDLE_VIEW_JOB_APPLICATION:
    'CareerSupportCenter.HANDLE_VIEW_JOB_APPLICATION',
  OPEN_INTERVIEW_GUIDELINES: 'CareerSupportCenter.OPEN_INTERVIEW_GUIDELINES',
  REVERSE_PROFILE_UPDATE: 'CareerSupportCenter.REVERSE_PROFILE_UPDATE',
  SET_ALL_INDUSTRIES: 'CareerSupportCenter.SET_ALL_INDUSTRIES',
  SET_FORM_DATA: 'CareerSupportCenter.SET_FORM_DATA',
  SET_OPTIN_MODAL_STEP: 'CareerSupportCenter.SET_OPTIN_MODAL_STEP',
  SET_USER_INDUSTRY: 'CareerSupportCenter.SET_USER_INDUSTRY',
  SHOW_APPLICATION_CONSENT: 'CareerSupportCenter.SHOW_APPLICATION_CONSENT',
  SHOW_EMPLOYER_CONNECTOR_MODAL:
    'CareerSupportCenter.SHOW_EMPLOYER_CONNECTOR_MODAL',
  SHOW_INTERACTIVE_MESSAGES: 'CareerSupportCenter.SHOW_INTERACTIVE_MESSAGES',
  SHOW_JOB_SEEKING_PROFILE: 'CareerSupportCenter.SHOW_JOB_SEEKING_PROFILE',
  UPDATE_APPLICATION_CONSENT: 'CareerSupportCenter.UPDATE_APPLICATION_CONSENT',
  UPDATE_APPLICATION_CONSENT_FAILURE:
    'CareerSupportCenter.UPDATE_APPLICATION_CONSENT_FAILURE',
  UPDATE_EC_PROFILE: 'CareerSupportCenter.UPDATE_EC_PROFILE',
  UPDATE_JOB_APPLICATION_FAILURE:
    'CareerSupportCenter.UPDATE_JOB_APPLICATION_FAILURE',
  UPDATE_JOB_APPLICATION_REQUEST:
    'CareerSupportCenter.UPDATE_JOB_APPLICATION_REQUEST',
  UPDATE_JOB_APPLICATION_SUCCESS:
    'CareerSupportCenter.UPDATE_JOB_APPLICATION_SUCCESS',
  UPDATE_JOB_SEEKING_PROFILE_FAILURE:
    'CareerSupportCenter.UPDATE_JOB_SEEKING_PROFILE_FAILURE',
  UPDATE_JOB_SEEKING_PROFILE_SUCCESS:
    'CareerSupportCenter.UPDATE_JOB_SEEKING_PROFILE_SUCCESS',
  UPDATE_LOADING_CONSENT: 'CareerSupportCenter.UPDATE_LOADING_CONSENT',
  UPDATE_SHARE_JOB_APPLICATION:
    'CareerSupportCenter.UPDATE_SHARE_JOB_APPLICATION',
};

export const programHeaders = {
  data_analytics: 'Data Analytics Program',
  digital_marketing: 'Digital Marketing',
  general: 'General',
  product_management: 'Product Management',
  ui_design: 'UI Design Program',
  ux_design: 'UX Design Program',
  web_development: 'Web Development Program',
};

export const sections = [
  'application-tracker',
  'job-search',
  'interview-prep',
  'portfolio-project',
  'explore-job',
  'support-community',
];
