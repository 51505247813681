import React from 'react';
import StyledStatementParagraph from '../../styles/StyledStatementParagraph';

const StatementParagraph = () => (
  <StyledStatementParagraph className="ds-typography__body ds-typography__small">
    (This is the same as elevator pitch, that you’ve created in the &nbsp;
    <a
      href="/en/course/job-preparation/exercise/defining-elevator-pitch#task"
      className="ds-typography__anchor"
      target="_blank"
      rel="noreferrer"
    >
      exercise 1.3 &nbsp;
    </a>
    of Job Preparation course It should be about 100 words, but no more than
    200)
  </StyledStatementParagraph>
);

export default StatementParagraph;
