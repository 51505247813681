import styled from 'styled-components';

const FormSection = styled.div`
  border-bottom: ${(props) => !props.last && '1px solid #E8EBED'};
  margin-bottom: ${(props) => props.last && '8px'};
  margin-top: 32px;
  padding-bottom: 8px;
`;

FormSection.displayName = 'FormSection';

const Header = styled.div`
  width: 664px;
  margin-bottom: 18px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    margin-bottom: 32px;
  }

  @media (max-width: 1278px) {
    width: 100%;
  }
`;

Header.displayName = 'Header';

const SectionWrapper = styled.div`
  margin-bottom: 18px;

  header {
    width: 518px;
  }
`;

SectionWrapper.displayName = 'SectionWrapper';

const Title = styled.p`
  margin-bottom: 8px;
`;

Title.displayName = 'Title';

export { FormSection, Header, SectionWrapper, Title };
