import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  cursor: ${(props) => (props.isAccessible ? 'pointer' : 'not-allowed')};
  background-color: ${(props) =>
    props.isSelected ? 'var(--ds-color__white)' : 'inherit'};
  color: ${(props) => {
    if (props.isSelected) {
      return 'var(--ds-color__indigo)';
    } else if (!props.isAccessible) {
      return 'rgba(255, 255, 255, 0.40)';
    } else {
      return 'var(--ds-color__white)';
    }
  }};

  :hover {
    background-color: ${(props) => {
      if (props.isSelected) {
        return 'var(--ds-color__white)';
      } else if (props.isAccessible) {
        return 'rgba(255, 255, 255, 0.10)';
      } else {
        return 'inherit';
      }
    }};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;

  svg {
    width: 1rem;
    height: 1rem;
    fill: ${(props) => {
      if (props.isSelected) {
        return 'var(--ds-color__indigo)';
      } else {
        return 'var(--ds-color__white)';
      }
    }};
  }
`;

export { Wrapper, IconWrapper };
