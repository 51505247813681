import styled from 'styled-components';

const ExerciseTypeImageWrapper = styled.span`
  display: inline-block;
  height: 35px;
  position: relative;
  width: 35px;

  & + span {
    margin-left: 10px;
  }

  img {
    width: 100%;
  }

  .cf-tooltip {
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(51, 51, 51, 0.1);
    color: #444;
    display: inline-block;
    min-width: 235px;
    padding: 10px;
    position: absolute;
    right: 0;
    text-align: left;
    top: 45px;
    transition: visibility 0.2s;
    visibility: hidden;
    z-index: 1;

    &::before {
      border-bottom: 8px solid transparent;
      border-right: 8px solid #f2f2f2;
      border-top: 8px solid transparent;
      content: '';
      display: block;
      height: 0;
      left: 91%;
      position: absolute;
      top: -12px;
      transform: rotate(90deg);
      width: 0;
    }
  }

  &:hover .cf-tooltip {
    visibility: visible;
  }
`;

ExerciseTypeImageWrapper.displayName = 'ExerciseTypeImageWrapper';

export default ExerciseTypeImageWrapper;
