module.exports = (() => {
  const init = () => {
    if (document.getElementById('ai-access')) {
      $(document).ready(() => bindFunctions());
    }
  };

  const INITIAL_ENDPOINT = '/api/v1/ai/simplify_text';
  const POLLING_ENDPOINT = '/api/v1/ai/fetch_llm_response';

  let selectedText;
  let selectedElement;
  let selectionRange;
  const dataForRetry = {};

  const bindFunctions = function () {
    document.addEventListener('selectionchange', handleHighlight);
    document
      .querySelector('.simplify-text')
      .addEventListener('click', handleSimplify);
  };

  const handleHighlight = () => debounce(() => onHighlight())();

  const onHighlight = () => {
    const selection = document.getSelection();
    document.querySelector('.simplify-text').style.display = 'flex';
    selectedText = selection.toString();
    selectedElement =
      selection.rangeCount > 0 && selection.getRangeAt(0)?.endContainer;
    selectionRange = selection.rangeCount > 0 && selection?.getRangeAt(0);

    if (selectedText === '') {
      selectedElement = null;
      document.querySelector('.action-menu').style.display = 'none';
    }
    if (
      selectedElement &&
      selectedElement.parentElement.parentElement.parentElement.className ===
        'simplified-english'
    ) {
      selectedText = null;
      selectedElement = null;
      selectionRange = null;
      document.querySelector('.simplify-text').style.display = 'none';
    }
  };

  const handleSimplify = (ev) => {
    const id = ev.pageX.toString();
    highlight(id);
    fetchSimplifiedText(selectedText, id);
  };

  const getSlug = () => document.getElementById('exercise_data').dataset.slug;

  const loadInsertTextBox = (id) => {
    const tempDiv = document.createElement('div');
    const parentNodeName = selectedElement.parentElement.nodeName;

    if (selectedElement.parentElement.parentElement.nodeName === 'BLOCKQUOTE') {
      selectedElement.parentElement.parentElement.insertAdjacentElement(
        'beforebegin',
        tempDiv
      );
      tempDiv.innerHTML += htmlTemplate(id);
    } else if (parentNodeName === 'UL') {
      selectedElement.parentElement.insertAdjacentElement('afterend', tempDiv);
      tempDiv.innerHTML += htmlTemplate(id);
    } else if (parentNodeName === 'LI') {
      selectedElement.parentElement.parentElement.insertAdjacentElement(
        'afterend',
        tempDiv
      );
      tempDiv.innerHTML += htmlTemplate(id);
    } else if (
      selectedElement.parentElement.parentElement.nodeName === 'CODE' ||
      parentNodeName === 'CODE'
    ) {
      selectedElement.parentElement.parentElement.parentElement.insertAdjacentElement(
        'afterend',
        tempDiv
      );
      tempDiv.innerHTML += htmlTemplate(id);
    } else {
      selectedElement.innerHTML += htmlTemplate(id);
    }

    document
      .querySelector(`.remove-content-${id}`)
      .addEventListener('click', removeContent);
  };

  const htmlTemplate = (id) => `
    <div class='simplified-english' id='simplified-english-${id}'>
      <header>
        <div>
          <p class='ds-typography_body--large ds-text--bold'>AI Explain</p>
        </div>
        <div>
          <i class="fa-solid fa-arrows-rotate re-generate re-generate-${id}" data-id='${id}'></i>
          <i class='ds-icon__close remove-content-${id}' data-id='${id}'></i>
        </div>
      </header>
      <main>
        <p class='ds-typography_body--medium' id="${id}">${loadingSpinner(
          id
        )}</p>
        <div class='rating-container rating-container-${id}'>
          <i class="fa-regular fa-thumbs-up rate-response" data-id='${id}' data-helpful=true></i>
          <i class="fa-regular fa-thumbs-down rate-response" data-id='${id}' data-helpful=false></i>
        </div>
      </main>
      <footer>
        <p class='ds-typography_body--small'>Don’t like this explanation? Use the retry button to generate another!</p>
      </footer>
    </div>
`;
  const loadingSpinner = (id) =>
    `<div id="loading-spinner-${id}" class="loading text-center" style="color:#ccc;padding-top: 10px;"><i style="color:#ccc; font-size: 3em" class="fa fa-spinner fa-spin fa-3x fa-fw"></i></div>`;

  const insertLoadingSpinner = (id) =>
    (document.getElementById(id).innerHTML = loadingSpinner(id));

  const removeContent = (ev) => {
    const id = ev.target.dataset.id;
    document.getElementById(`simplified-english-${id}`).remove();
    Array.from(document.querySelectorAll(`.highlighted-${id}`)).forEach((el) =>
      el.classList.remove('highlighted')
    );
  };

  const getAuthToken = () =>
    document.querySelector('meta[name="csrf-token"]').content;

  const pollForCompletion = (requestId, text, id) => {
    dataForRetry[id] = { requestId, text };

    fetch(`${POLLING_ENDPOINT}?request_id=${requestId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'completed') {
          handleAPIResponse(data, id);
        } else if (data.status === 'failed') {
          handleAPIResponse(data, id);
        } else {
          setTimeout(() => {
            pollForCompletion(requestId, text, id);
          }, 5000);
        }
      })
      .catch((error) => handleAPIResponse({ error }, id));
  };

  const fetchInitialResponse = (text, id, requestId = null) => {
    fetch(INITIAL_ENDPOINT, {
      body: JSON.stringify({
        request_id: requestId,
        step_id: getSlug(),
        text,
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getAuthToken(),
      },
      method: 'POST',
    })
      .then((response) => response.json())
      .then((data) =>
        data.request_id
          ? pollForCompletion(data.request_id, text, id)
          : handleAPIResponse(data, id)
      )
      .catch((error) => handleAPIResponse({ error }, id));
  };

  const fetchSimplifiedText = (text, id) => {
    fetchInitialResponse(text, id);
    loadInsertTextBox(id);
  };

  const reGenerateSimplifiedText = (ev) => {
    const id = ev.target.dataset.id;
    const element = document.getElementById(id);
    element.innerHTML = '';
    insertLoadingSpinner(id);

    fetchInitialResponse(dataForRetry[id].text, id, dataForRetry[id].requestId);
  };

  const handleAPIResponse = (data, id) => {
    document.getElementById(`loading-spinner-${id}`).remove();
    if (data.type === 'danger') {
      document.getElementById(id).innerHTML =
        'Something went wrong. Please try again later.';
    } else if (data.error) {
      document.getElementById(id).innerHTML = data.error;
    } else {
      document.getElementById(id).innerHTML = data.text;
      showRegenerateButton(id);
      showRatingButtons(id);
    }
  };

  const showRegenerateButton = (id) => {
    const reGenerateButton = document.querySelector(`.re-generate-${id}`);
    reGenerateButton.style.display = 'inline-block';
    reGenerateButton.addEventListener('click', reGenerateSimplifiedText);
  };

  const showRatingButtons = (id) => {
    const ratingContainer = document.querySelector(`.rating-container-${id}`);
    ratingContainer.style.display = 'block';
    const ratingButtons = document.querySelectorAll('.rate-response');
    ratingButtons.forEach((button) =>
      button.addEventListener('click', rateResponse)
    );
  };

  const rateResponse = (ev) => {
    const element = ev.target;
    const id = element.dataset.id;
    const helpful = element.dataset.helpful;
    const requestId = dataForRetry[id].requestId;

    if (element.classList.contains('fa-solid')) return;

    fetch('/api/v1/ai/rate_llm_response', {
      body: JSON.stringify({
        helpful,
        request_id: requestId,
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getAuthToken(),
      },
      method: 'POST',
    }).then((response) => handleRateAPIResponse(response, element));
  };

  const handleRateAPIResponse = (response, element) => {
    if (response.ok) {
      element.classList.remove('fa-regular');
      element.classList.add('fa-solid');
      const siblingElement =
        element.nextElementSibling || element.previousElementSibling;
      if (siblingElement.classList.contains('fa-solid')) {
        siblingElement.classList.remove('fa-solid');
        siblingElement.classList.add('fa-regular');
      }
    }
  };

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const highlight = (id) => {
    const node = getSelectionParentElement();
    const text = selectedText;
    markFunc(node, text, id);
  };

  const getSelectionParentElement = () => {
    return selectionRange.commonAncestorContainer.nodeType !== 1
      ? selectionRange.commonAncestorContainer.parentNode
      : selectionRange.commonAncestorContainer;
  };

  const markFunc = (node, text, id) => {
    const instance = new Mark(node);
    instance.mark(text, {
      accuracy: 'partially',
      acrossElements: true,
      diacritics: true,
      done: function () {
        window.getSelection().empty();
      },
      each: function (element) {
        element.setAttribute('class', `highlighted highlighted-${id}`);
      },
      element: 'span',
      filter: function (node, counter) {
        let res = false;
        if (counter === 0) {
          res = selectionRange.isPointInRange(node, selectionRange.startOffset);
        } else {
          res = selectionRange.isPointInRange(node, 1);
        }
        return res;
      },
      ignoreJoiners: true,
      separateWordSearch: false,
    });
  };

  return {
    init,
  };
})();
