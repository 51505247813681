import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PaginatedTable from '../shared/PaginatedTable';
import PropTypes from 'prop-types';
import React from 'react';
import { employerTracking } from '../../../shared/helpers/tracking.js';

const StudentTable = ({
  employer,
  fetchingProfiles,
  activePage,
  jobSeekingProfiles,
  perPage,
  totalEntries,
  totalPages,
  showJobSeekingProfile,
  getJobSeekingProfiles,
  profileFilterParams,
}) => {
  const columns = [
    { key: 'studentName', name: 'Student Name' },
    { key: 'location', name: 'Location' },
    { key: 'program', name: 'Program' },
    { key: 'specialization', name: 'Specialization' },
  ];

  const showApplicationModal = (studentId) => {
    const data = {
      date: new Date(),
      employer_id: employer.id,
      student_id: studentId,
    };
    employerTracking('ec_student_profile_visit', data);
    showJobSeekingProfile(studentId);
  };

  return (
    <>
      {fetchingProfiles
        ? (
          <LoadingSpinner />
        )
        : (
          <>
            <PaginatedTable
              columns={columns}
              activePage={activePage}
              collection={jobSeekingProfiles}
              collectionLength={totalEntries}
              itemsCountPerPage={perPage}
              showApplicationModal={showApplicationModal}
              getJobSeekingProfiles={getJobSeekingProfiles}
              profileFilterParams={profileFilterParams}
            />
          </>
        )}
    </>
  );
};

StudentTable.propTypes = {
  activePage: PropTypes.number.isRequired,
  employer: PropTypes.shape({
    id: PropTypes.number,
  }),
  fetchingProfiles: PropTypes.bool.isRequired,
  getJobSeekingProfiles: PropTypes.func.isRequired,
  jobSeekingProfiles: PropTypes.array.isRequired,
  perPage: PropTypes.number.isRequired,
  profileFilterParams: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    program: PropTypes.string,
    specialization: PropTypes.string,
  }),
  showJobSeekingProfile: PropTypes.func.isRequired,
  totalEntries: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default StudentTable;
