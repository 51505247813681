import { Services } from '../../interfaces';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import { handleEdit } from '../../actions';

interface IState {
  offPlatformServices: {
    services: Services;
  };
}

export const mapStateToProps = ({
  offPlatformServices: { services },
}: IState) => ({
  services,
});

export const mapDispatchToProps = (dispatch: any) => ({
  handleEdit: (id: string) => dispatch(handleEdit(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
