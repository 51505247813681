import styled, { createGlobalStyle } from 'styled-components';

const FrozenBody = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const ModalBackground = styled.div`
  align-items: center;
  backdrop-filter: blur(1px);
  background-color: rgba(34, 60, 80, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 13;
`;

const CloseButton = styled.span`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  opacity: 1;
  &:hover {
    opacity: 0.7;
  }
`;

const ModalBox = styled.div`
  background: #fff;
  box-shadow: 10px 10px 10px 0 rgba(34, 60, 80, 0.4);
  max-width: 770px;
  padding: 40px;
  position: relative;
  width: 770px;
  z-index: 51;

  @media (max-width: 479px) {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-around;
    margin: 0;
    max-width: 100%;
    padding: 16px;
    width: 100%;
  }
`;

ModalBackground.displayName = 'ModalBackground';
CloseButton.displayName = 'CloseButton';
FrozenBody.displayName = 'FrozenBody';
ModalBox.displayName = 'ModalBox';

export { ModalBackground, CloseButton, FrozenBody, ModalBox };
