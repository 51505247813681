import { getJobSeekingProfiles, showJobSeekingProfile } from '../../actions';
import StudentTable from '../../components/students/StudentTable';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  employerDashboard: {
    activePage,
    jobSeekingProfiles,
    perPage,
    totalEntries,
    totalPages,
    fetchingProfiles,
    profileFilterParams,
  },
  messaging: {
    me,
  },
}) => ({
  activePage,
  employer: me,
  fetchingProfiles,
  jobSeekingProfiles,
  perPage,
  profileFilterParams,
  totalEntries,
  totalPages,
});

export const mapDispatchToProps = dispatch => ({
  getJobSeekingProfiles: (page, params) => dispatch(getJobSeekingProfiles(page, params)),
  showJobSeekingProfile: (id) => dispatch(showJobSeekingProfile(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentTable);
