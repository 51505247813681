import {
  AudioReplayDisabledIcon,
  AudioReplayIcon,
  VolumeOffIcon,
  VolumeOnIcon,
} from './icon';
import React, { useCallback, useEffect, useState } from 'react';
import AudioRecorder from '../AudioRecorder/index';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import QuestionInsights from './QuestionInsight';
import StyledInterviewQuestion from './styles/StyledInterviewQuestion';

let audioTimerId;

const renderAudioIcon = (iconType) => {
  switch (iconType) {
    case 'paused':
      return <VolumeOffIcon />;
    case 'replay':
      return <AudioReplayIcon />;
    case 'replayDisabled':
      return <AudioReplayDisabledIcon />;
    default:
      return <VolumeOnIcon />;
  }
};

const InterviewQuestion = ({
  finished,
  handleAnswer,
  question,
  questions,
  errorsaving,
  onCancelErrorSaving,
}) => {
  const INTERVIEW_QUESTION_COUNT = 5;

  const [audio, setAudio] = useState(null);
  const [showFull, setShowFull] = useState(false);
  const [iconType, setIconType] = useState('play');

  useEffect(() => {
    setShowFull(false);
    setIconType('play');

    if (question) {
      setAudio(new Audio(question.audioLink));
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  }, [question]);

  useEffect(() => {
    if (audio) {
      audio.play();
      audio.addEventListener('ended', () => {
        setIconType('replay');
        showFullQuestion();
      });
      return () => {
        audio.removeEventListener('ended', () => setShowFull(false));
      };
    } else {
      setAudioTimeout();
    }
  }, [audio]);

  const setAudioTimeout = () => {
    audioTimerId = setTimeout(() => {
      setIconType('replay');
      showFullQuestion();
    }, 5000);
  };

  const clearAudioTimer = () => {
    if (audioTimerId) {
      clearTimeout(audioTimerId);
    }
  };

  const pauseAudio = () => {
    clearAudioTimer();

    audio.pause();
    setIconType('paused');
    setAudioTimeout();
  };

  const replayAudio = () => {
    clearAudioTimer();

    setIconType('play');
    setShowFull(false);
    setAudio(new Audio(question.audioLink));
  };

  const handleAudioIconClick = () => {
    switch (iconType) {
      case 'paused':
        return replayAudio();
      case 'replay':
        return replayAudio();
      case 'replayDisabled':
        return null;
      default:
        return pauseAudio();
    }
  };

  const showFullQuestion = () => {
    setShowFull(true);
  };

  const disableAudioIcon = useCallback(() => {
    setIconType('replayDisabled');
  }, []);

  const showReplayIcon = useCallback(() => {
    setIconType('replay');
  }, []);

  return finished || !question ? (
    <LoadingSpinner />
  ) : (
    <StyledInterviewQuestion>
      <div className='header-section'>
        <h4 className='ds-typography__body text-left'>
          Question {questions.indexOf(question) + 1} /{' '}
          {INTERVIEW_QUESTION_COUNT}
        </h4>
        <div className='parag-wrapper'>
          <div className='svg-wrapper' onClick={handleAudioIconClick}>
            {renderAudioIcon(iconType)}
          </div>
          <p className='ds-typography__h3 bold text-left'>
            {question.question}
          </p>
        </div>
      </div>
      {showFull && (
        <>
          <QuestionInsights insight={question.insight} />
          <div className='content'>
            <AudioRecorder
              handleAnswer={handleAnswer}
              question={question}
              errorsaving={errorsaving}
              onCancelErrorSaving={onCancelErrorSaving}
              onDisableAudioIcon={disableAudioIcon}
              onShowReplayIcon={showReplayIcon}
            />
          </div>
        </>
      )}
    </StyledInterviewQuestion>
  );
};

InterviewQuestion.propTypes = {
  errorsaving: PropTypes.bool,
  finished: PropTypes.bool.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  onCancelErrorSaving: PropTypes.func.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.shape({
          attachment: PropTypes.shape({
            fileIcon: PropTypes.string,
            fileName: PropTypes.string,
            id: PropTypes.number,
            s3Url: PropTypes.string,
          }),
          category: PropTypes.string,
          date: PropTypes.string,
          id: PropTypes.number,
          industry: PropTypes.string,
          notes: PropTypes.string,
          question: PropTypes.string,
          questionInsights: PropTypes.string,
          rating: PropTypes.number,
          shared: PropTypes.bool,
        }),
      })
    ),
    audioLink: PropTypes.string,
    insight: PropTypes.string,
    question: PropTypes.string,
  }),
  questions: PropTypes.array,
};

export default InterviewQuestion;
