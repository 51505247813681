import AccordionSection from '../../../../shared/components/AccordionSection';
import { EUROPE_JOBS_CATEGORIES } from '../constants/europeJobs';
import JobsMansoryTiles from '../../JobsMansoryTiles';
import React from 'react';
import { getCategorizedData } from '../../../../shared/helpers/jobSearch';

const SECTION_NAME = 'Europe';

const EuropeLocationBoard = () => {
  const categories = getCategorizedData(EUROPE_JOBS_CATEGORIES, 3);

  return (
    <AccordionSection
      heading={SECTION_NAME}
      content={
        <JobsMansoryTiles
          tilesCategories={categories}
          jobType="europe_jobs"
          sectionName={SECTION_NAME}
          linkEventName="recommended_job_link_clicked"
        />
      }
    />
  );
};

export default EuropeLocationBoard;
