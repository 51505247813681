import React, { useEffect } from 'react';
import {
  getJobApplications,
  setActivePage,
  showApplicationModal,
} from './actions';
import JobApplicationsTab from '../../components/JobApplicationsTab';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const JobApplicationsTabContainer = ({
  getJobApplications,
  loading,
  userId,
  ...rest
}) => {
  useEffect(() => {
    getJobApplications(userId);
  }, []);

  return loading ? <LoadingSpinner /> : <JobApplicationsTab {...rest} />;
};

JobApplicationsTabContainer.propTypes = {
  getJobApplications: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

export const mapStateToProps = ({
  userProfile: { jobApplicationsTab, userId },
}) => ({
  activePage: jobApplicationsTab.activePage,
  applications: jobApplicationsTab.applications,
  applicationsLength: jobApplicationsTab.applicationsLength,
  loading: jobApplicationsTab.loading,
  userId,
});

export const mapDispatchToProps = (dispatch) => ({
  getJobApplications: (userId) => dispatch(getJobApplications(userId)),
  setActivePage: (activePage) => dispatch(setActivePage(activePage)),
  showApplicationModal: (jobId) => dispatch(showApplicationModal(jobId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobApplicationsTabContainer);
