import styled from 'styled-components';

const StyledImageSection = styled.div`
  .guidelines {
    margin-right: 20%;
  }
  text-align: center;
`;

export default StyledImageSection;
