import React, { useEffect, useState } from 'react';
import { Body, Button, Header } from '@careerfoundry/bubble';
import { redirectTo } from '../../../../utils/redirectTo';
import { getEncryptedUserId, setSurveyCompleted } from '../../helpers';
import { get, noop } from 'lodash';
import { getUserData } from '../../../../services/getUserData';
import Typeform from '../../../shared/components/Typeform';
import TheModal from '../../../shared/components/TheModal';
import { Wrapper } from './styles';
import { sharedConstants } from '../../../shared/constants';

const DiversitySurvey = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [encryptedUserId, setEncryptedUserId] = useState<string | null>(null);
  const [shouldShowTypeformPopupModal, setShouldShowTypeformPopupModal] =
    useState<boolean>(false);
  const [shouldShowRedirectModal, setShouldShowRedirectModal] =
    useState<boolean>(false);
  const [shouldShowExitConfirmationModal, setShouldShowExitConfirmationModal] =
    useState<boolean>(false);
  const isDiversitySurveyFlowViaProfile = localStorage.getItem(
    sharedConstants.IS_DIVERSITY_SURVEY_FLOW_VIA_PROFILE
  );

  const removeIsDiversitySurveyFlowViaProfileItemFromLS = () =>
    localStorage.removeItem(
      sharedConstants.IS_DIVERSITY_SURVEY_FLOW_VIA_PROFILE
    );

  const onSubmitHandler = async () => {
    try {
      const surveyCompletedData = await setSurveyCompleted();

      if (surveyCompletedData.data.success) {
        setShouldShowTypeformPopupModal(false);
        setShouldShowRedirectModal(true);
      } else {
        Rollbar.error(
          'Diversity Survey: Failed to track completion for an user',
          { userId }
        );
      }
    } catch (error) {
      /**
       * TODO: Handle it in a better way (once we have a way to handle
       * API errors; like having platform level notification popups)
       */
    }
  };

  const fetchData = async () => {
    const encryptedUserIdData = await getEncryptedUserId();
    const userData = await getUserData();

    const encryptedUserId = get(
      encryptedUserIdData,
      'data.data.encryptedUserId',
      ''
    );
    const userId = get(userData, 'data.data.id', '');

    setUserId(userId);

    if (encryptedUserId) {
      setEncryptedUserId(encryptedUserId);
      setShouldShowTypeformPopupModal(true);
    } else {
      Rollbar.error(
        'Diversity Survey: Failed to get encrypted ID for an user',
        { userId }
      );
    }
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      /**
       * TODO: Handle it in a better way (once we have a way to handle
       * API errors; like having platform level notification popups)
       */
    }
  }, []);

  return (
    <Wrapper>
      <TheModal
        isModalOpen={shouldShowTypeformPopupModal}
        handleClose={() => setShouldShowExitConfirmationModal(true)}
        className='typeform-modal'
      >
        <Typeform
          formId='XHQBvTii' // Replace with a test form ID while development
          params={{ user_id: encryptedUserId || '' }}
          handleSubmit={onSubmitHandler}
        />
      </TheModal>
      <TheModal
        isModalOpen={shouldShowRedirectModal}
        handleClose={noop}
        closeButton={false}
        className='confirmation-modal'
      >
        <Header tag='h4' variant='h4'>
          Thanks for your answers!
        </Header>
        <Body tag='p' variant='medium'>
          {isDiversitySurveyFlowViaProfile
            ? "Now you'll be redirected to your student profile."
            : 'You’re ready to start your learning journey. Time for the Orientation Course!'}
        </Body>
        <div className='footer'>
          <Button
            label={
              isDiversitySurveyFlowViaProfile
                ? 'Ok'
                : 'Go to the Orientation course'
            }
            onClick={() => {
              removeIsDiversitySurveyFlowViaProfileItemFromLS();
              redirectTo({
                url: isDiversitySurveyFlowViaProfile
                  ? '/en/settings'
                  : '/en/dashboards/orientation/course-home/',
              });
            }}
          />
        </div>
      </TheModal>
      <TheModal
        isModalOpen={shouldShowExitConfirmationModal}
        handleClose={() => setShouldShowExitConfirmationModal(false)}
        className='confirmation-modal'
      >
        <Header tag='h4' variant='h4'>
          You’re about to leave the page
        </Header>
        <Body tag='p' variant='medium'>
          Are you sure you want to quit the survey? All your answers will be
          lost. If you want to fill in the survey again later, you can find it
          in your student profile.
          <span>
            {isDiversitySurveyFlowViaProfile
              ? 'If you quit, you’ll be redirected to your student profile.'
              : 'If you quit, you’ll be redirected to the Orientation Course.'}
          </span>
        </Body>
        <div className='footer'>
          <Button
            variant='secondary'
            label='Yes, quit'
            onClick={() => {
              removeIsDiversitySurveyFlowViaProfileItemFromLS();
              setShouldShowExitConfirmationModal(false);
              redirectTo({
                url: isDiversitySurveyFlowViaProfile
                  ? '/en/settings'
                  : '/en/dashboards/orientation/course-home/',
              });
            }}
          />
          <Button
            label='Cancel'
            onClick={() => setShouldShowExitConfirmationModal(false)}
          />
        </div>
      </TheModal>
    </Wrapper>
  );
};

export default DiversitySurvey;
