import Sidebar from '../../components/Sidebar';
import { connect } from 'react-redux';
import { setSelectedTab } from '../../actions';

export const mapStateToProps = ({
  instructorProfile: { data, selectedTab },
}) => ({
  candidateState: data.candidateState,
  name: data.firstName,
  selectedTab,
  status: data.status,
  tabs: data.tabs,
});

export const mapDispatchToProps = (dispatch) => ({
  setSelectedTab: (tab) => dispatch(setSelectedTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
