import { FormSection, InputWrapper } from '../styles';
import CountrySelect from '../../../../../shared/components/CountrySelect';
import Input from '../../../../../shared/components/Input';
import PropTypes from 'prop-types';
import React from 'react';
import SectionHeader from '../../SectionHeader';
import { errorMessage } from '../helpers';

const AddressInputs = ({
  formErrors,
  handleCountryChange,
  handleSave,
  personalData,
}) => (
  <FormSection>
    <SectionHeader
      headline="Official Address"
      tooltipText="Please write your registered private or business address. We'll use this to create your contract."
    />
    <InputWrapper>
      <Input
        type="text"
        name="personalAddressLine1"
        placeholder="Address"
        required={true}
        value={personalData.personalAddressLine1}
        onChange={(value) => handleSave('personalAddressLine1', value)}
        error={errorMessage(formErrors, 'personalAddressLine1')}
      />
      <Input
        type="text"
        name="personalAddressLine2"
        placeholder="Apt., bldg., etc."
        value={personalData.personalAddressLine2}
        onChange={(value) => handleSave('personalAddressLine2', value)}
        error={errorMessage(formErrors, 'personalAddressLine2')}
      />
    </InputWrapper>
    <InputWrapper>
      <Input
        type="text"
        name="personalCity"
        placeholder="City"
        required={true}
        value={personalData.personalCity}
        onChange={(value) => handleSave('personalCity', value)}
        error={errorMessage(formErrors, 'personalCity')}
      />
      <Input
        type="text"
        name="personalPostCode"
        placeholder="Postcode"
        required={true}
        value={personalData.personalPostCode}
        onChange={(value) => handleSave('personalPostCode', value)}
        error={errorMessage(formErrors, 'personalPostCode')}
      />
    </InputWrapper>
    <InputWrapper>
      <CountrySelect
        name="personalCountry"
        value={personalData.personalCountry}
        onChange={(event) => handleCountryChange(event)}
        required={true}
      />
    </InputWrapper>
  </FormSection>
);

AddressInputs.propTypes = {
  formErrors: PropTypes.object,
  handleCountryChange: PropTypes.func,
  handleSave: PropTypes.func,
  personalData: PropTypes.object,
};

export default AddressInputs;
