import DisabledTooltipContent from '../../DisabledTooltipContent';
import Icon from '../../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { MyCFPlusIcon as SVGIcon } from '../../SideNavList/icons';
import { trackAndRedirect } from '../../../helpers';

const MyCFPlusIcon = ({
  isDisabled = true,
  isVisible = false,
  currentPageName,
  ...rest
}) => (
  <Icon
    isDisabled={isDisabled}
    isSelected={currentPageName === 'MY_CF_PLUS'}
    isVisible={isVisible}
    onClick={() =>
      !isDisabled &&
      trackAndRedirect({
        redirect: {
          url: '/en/cf-plus',
        },
        track: {
          itemName: 'cfplus',
          shouldTrack: true,
        },
      })
    }
    renderSVGIcon={() => <SVGIcon />}
    nameForMobileView='My CF+'
    tooltipContent={
      isDisabled ? (
        <DisabledTooltipContent title='My CF+' description='Locked' />
      ) : (
        'My CF+'
      )
    }
    {...rest}
  />
);

MyCFPlusIcon.propTypes = {
  ...Icon.propTypes,
  isDisabled: PropTypes.bool,
  isVisible: PropTypes.bool,
};

export default MyCFPlusIcon;
