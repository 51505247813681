import FileUploadError from '../../components/FileUploadError';
import { connect } from 'react-redux';

interface State {
  taskSubmission: {
    errors: {
      fileUpload: string;
    };
  };
}

export const mapStateToProps = ({
  taskSubmission: {
    errors: { fileUpload },
  },
}: State) => ({ errorMessage: fileUpload });

export default connect(
  ({
    taskSubmission: {
      errors: { fileUpload },
    },
  }) => ({ errorMessage: fileUpload }),
  null
)(FileUploadError);
