import React, { useEffect, useState } from 'react';
import {
  getJobApplications, updateShareApplication,
} from '../../../CareerSupportCenter/actions';
import FlashMessageContainer from '../../../shared/flashMessageContainer';
import JobApplicationTracker from '../../components/index';
import LoadingSpinner from '../../../shared/loadingSpinner';
import PaginationControls from '../../../shared/components/ServerSidePaginator';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import handlePageControls from '../../../shared/helpers/handlePageControls';

export const JobApplicationTrackerContainer = ({
  attendeeId,
  jobApplicationAction,
  loading,
  getJobApplications,
  totalPages,
  reload,
  updateShareApplication,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [indexControls, setIndexControls] = useState({});

  useEffect(() => {
    if (reload) {
      getJobApplications(currentPage, null);
    }
    if (totalPages) {
      handlePageControls(currentPage, totalPages, setIndexControls);
      getJobApplications(currentPage, null);
    };
  }, [reload, totalPages, currentPage]);

  useEffect(() => {
    const consent = localStorage.getItem('jobApplicationConsent') ? JSON.parse(localStorage.getItem('jobApplicationConsent')) : null;
    const isShared = consent && consent.userId === attendeeId && !!consent.shared === true;

    if (isShared) {
      updateShareApplication(isShared);
    }
  }, []);

  return (
    <>
      {loading
        ? <LoadingSpinner />
        : <><JobApplicationTracker userId={attendeeId} />
          {totalPages > 1 && <PaginationControls currentPage={currentPage}
            indexControls={indexControls}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage} />}
        </>
      }
      {jobApplicationAction && <FlashMessageContainer status="success" message={`Job Application ${jobApplicationAction}`} />}
    </>
  );
};

JobApplicationTrackerContainer.propTypes = {
  attendeeId: PropTypes.number.isRequired,
  getJobApplications: PropTypes.func.isRequired,
  jobApplicationAction: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  reload: PropTypes.bool.isRequired,
  totalPages: PropTypes.number,
  updateShareApplication: PropTypes.func.isRequired,
};

export const mapStateToProps = ({
  careerSupportCenter: {
    jobApplicationAction,
    totalPages,
    loading,
    reload,
  },
}) => ({
  jobApplicationAction,
  loading,
  reload,
  totalPages,
});

export const mapDispatchToProps = dispatch => ({
  getJobApplications: (page, limit) => dispatch(getJobApplications(page, limit)),
  updateShareApplication: shared => dispatch(updateShareApplication(shared)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobApplicationTrackerContainer);
