import { Locale } from '../../shared/enums';

interface ShouldShowCtaParams {
  pathname: string;
  locale: Locale;
}

export const shouldShowCta = ({
  pathname = '',
  locale = Locale.en,
}: ShouldShowCtaParams) => {
  const restrictedPathnames = [`/${locale}/messaging`];

  return !restrictedPathnames.includes(pathname);
};
