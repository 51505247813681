import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

Flex.displayName = 'Flex';

export default Flex;
