import React, { useEffect, useState } from 'react';
import { getStudentHandoffs, postTypeformData } from '../../actions';
import App from '../../components/App';
import LoadingSpinner from '../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import StudentHandoffModal from '../../components/StudentHandoffModal/index';
import StyledStudentHandoff from '../../components/Requests/styles/StyledStudentHandoff';

const MENTOR_TYPEFORM_ID = 'qWejnjaR';
const TUTOR_TYPEFORM_ID = 'l7y2OoYl';

export const AppContainer = ({ isTutor, userId, overdue }) => {
  const formId = isTutor ? TUTOR_TYPEFORM_ID : MENTOR_TYPEFORM_ID;
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedHandoff, setSelectedHandoff] = useState(null);
  const [handoffs, setHandoffs] = useState([]);

  useEffect(() => {
    getStudentHandoffs().then((resp) => {
      setHandoffs(resp);
      setLoading(false);
    });
  }, []);

  const handleSubmit = (event) => {
    setLoading(true);
    postTypeformData({
      formId,
      handoff: selectedHandoff,
      responseId: event.data.responseId,
      userId,
    })
      .then(() => {
        setHandoffs(
          handoffs.filter((handoff) => handoff.id !== selectedHandoff.id)
        );
        handleClose();
        setLoading(false);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  const handleClick = (handoff) => {
    setSelectedHandoff(handoff);
    setModalIsOpen(true);
  };

  const handleClose = () => setModalIsOpen(false);

  return loading ? (
    <StyledStudentHandoff>
      <LoadingSpinner />
    </StyledStudentHandoff>
  ) : (
    <>
      <App
        userId={userId}
        overdue={overdue}
        handoffs={handoffs}
        handleClick={handleClick}
      />
      {modalIsOpen && (
        <StudentHandoffModal
          formId={formId}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          loading={false}
          modalIsOpen={true}
        />
      )}
    </>
  );
};

AppContainer.propTypes = {
  isTutor: PropTypes.bool.isRequired,
  overdue: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
};

export default AppContainer;
