import styled from 'styled-components';

const Wrapper = styled.div`
  p {
    margin-top: var(--ds-spacing__xs);
    margin-bottom: var(--ds-spacing__m);
    color: var(--ds-color__int-content-input-negative);
  }
`;

export { Wrapper };
