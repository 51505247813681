import React from 'react';
import PaymentDetailsSlide from '../../shared/PaymentDetailsSlide';
import { PaymentPlanWrapperProps } from '../types/PaymentPlanWrapperProps';
import KlarnaCheckout from '../Klarna/containers/App';
import { Provider } from 'react-redux';
import store from '../../shared/reduxStore';
import { keysToCamel } from '../../../utils/keysConverter';

const PaymentPlanWrapper = ({
  paymentPlan,
  ...props
}: PaymentPlanWrapperProps) => {
  const startDate = props.startDate;
  const courseParams = props.courseParams;

  switch (paymentPlan.plan) {
    case 'external':
      return (
        <Provider store={store}>
          <KlarnaCheckout
            startDate={startDate}
            paymentPlan={keysToCamel(paymentPlan)}
            discountCode={courseParams.discount_code}
          />
        </Provider>
      );
    default:
      return <PaymentDetailsSlide {...props} />;
  }
};

export default PaymentPlanWrapper;
