import { handleDelete, handleModalClose } from '../../actions';
import ConfirmationModal from '../../components/ConfirmationModal';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  timeOff: { modalAction, selectedRequest },
}) => ({
  isOpen: modalAction !== '',
  modalAction,
  selectedRequest,
});

export const mapDispatchToProps = (dispatch) => ({
  handleDelete: (slug) => dispatch(handleDelete(slug)),
  handleModalClose: () => dispatch(handleModalClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
