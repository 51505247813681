import PropTypes from 'prop-types';
import React from 'react';
import StyledErrorMessage from './styles/StyledErrorMessage';

const ErrorMessage = ({ errorMessage }) => {
  return (
    <StyledErrorMessage>
      <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
      <h1>{errorMessage}</h1>
    </StyledErrorMessage>
  );
};

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorMessage;
