import Evaluation from '../../components/InterviewEvaluationModal/Evaluation';
import { connect } from 'react-redux';

export const mapStateToProps = ({
  userProfile: { currentAnswer, isMentorPage },
}) => ({
  currentAnswer,
  isMentorPage,
});

export default connect(mapStateToProps, null)(Evaluation);
