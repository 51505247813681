import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { interviewQuestionTracking } from '../../../shared/helpers/tracking';

const EvaluationQuestionTips = ({ currentAnswer }) => {
  const MAX_LENGTH = 300;
  const shouldTruncate = currentAnswer.questionInsights.length > MAX_LENGTH;
  const [truncated, setTruncated] = useState(true);

  useEffect(() => {
    setTruncated(shouldTruncate);
  }, [shouldTruncate]);

  const truncate = (text) =>
    truncated ? `${text.substring(0, MAX_LENGTH - 3)}...` : text;
  const toggleShow = () => {
    interviewQuestionTracking(
      currentAnswer.question,
      'ip_expand_question_insights',
    );
    setTruncated(!truncated);
  };

  return (
    <div className="section text-left">
      <h5 className="ds-typography__h5 tour__interview_prep_tips">
        Tips for answering the question
      </h5>
      <div className="insight-body">
        <p className="ds-typography_body">
          {shouldTruncate
            ? truncate(currentAnswer.questionInsights)
            : currentAnswer.questionInsights}
          {shouldTruncate && (
            <a className="ds-typography__body" onClick={toggleShow}>
              {truncated ? ' Show more' : ' Show less'}
            </a>
          )}
        </p>
      </div>
    </div>
  );
};

EvaluationQuestionTips.propTypes = {
  currentAnswer: PropTypes.object,
};

export default EvaluationQuestionTips;
