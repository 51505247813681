export function formElementId(formId, elementName) {
  return(`${formId}_${elementName}`);
}

export function formLabel(formId, elementName) {
  return($(`label[for="${formElementId(formId, elementName)}"]`));
}

export function formElement(formId, elementName) {
  return($(`#${formElementId(formId, elementName)}`));
}

export function showElementAndLabel(formId, elementName) {
  formLabel(formId, elementName).removeClass("hidden");
  formElement(formId, elementName).removeClass("hidden");
}

export function showElementsAndLabels(formId, elementNames) {
  for(var elementName of elementNames) {
    showElementAndLabel(formId, elementName);
  }
}

export function hideElementAndLabel(formId, elementName) {
  formLabel(formId, elementName).addClass("hidden");
  formElement(formId, elementName).addClass("hidden");
  formElement(formId, elementName).val("");
}

export function hideElementsAndLabels(formId, elementNames) {
  for(var elementName of elementNames) {
    hideElementAndLabel(formId, elementName);
  }
}
