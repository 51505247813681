import styled from 'styled-components';
import { Body, Button } from '@careerfoundry/bubble';

const Banner = styled(Body)`
  text-align: left;
  max-width: 100%;
  display: flex;
  align-items: start;

  @media (max-width: 768px) {
    padding: var(--ds-spacing__m) 0;
    margin-top: var(--ds-spacing__m);
  }

  @media (max-width: 767px) {
    margin-top: var(--ds-spacing__xl);
  }

  @media (max-width: 480px) {
    font-size: 16px;

    .check-icon {
      margin-top: 0.1rem;
    }
  }

  .check-icon {
    margin-right: 0.5rem;
    margin-top: 0.2rem;
  }
`;

const DiversitySurveyContainer = styled.div`
  background-color: var(--ds-bg-color__static-primary);
  max-width: 1074px;
  padding: var(--ds-spacing__xl);
  margin: var(--ds-spacing__2xl) auto;
  text-align: center;

  @media (max-width: 1440px) {
    max-width: 962px;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
    padding: var(--ds-spacing__xl) var(--ds-spacing__m);
  }

  h2 {
    @media (max-width: 768px) {
      font-size: 40px;
    }

    @media (max-width: 480px) {
      font-size: 32px;
    }
  }

  img {
    width: 100%;

    &.DiversitySurveyImageMobile {
      display: none;

      @media (max-width: 768px) {
        display: block;
        max-width: 432px;
        margin: 0 auto;
      }
    }

    &.DiversitySurveyImageDesktop {
      display: block;
      max-width: 100%;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .DiversitySurveyButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .TheModal {
    max-width: 440px;

    h4 {
      text-align: center;
      margin-bottom: var(--ds-spacing__m);
      margin-top: 0;
    }

    & > p {
      text-align: center;
      margin-bottom: var(--ds-spacing__l);

      span {
        display: inline-block;
        margin-top: var(--ds-spacing__xs);
      }
    }

    & > .footer {
      display: flex;
      justify-content: center;

      button:nth-of-type(2) {
        margin-left: var(--ds-spacing__xs);
      }
    }
  }
`;

const DiversitySurveyBody = styled(Body)`
  margin: var(--ds-spacing__l) var(--ds-spacing__2xl);
`;

const DiversitySurveyTertiaryButton = styled(Button)`
  margin: var(--ds-spacing__l) var(--ds-spacing__s);
`;

const DiversitySurveyFootnote = styled(Body)`
  margin: var(--ds-spacing__l) var(--ds-spacing__2xl);
  max-width: 876px;
  line-height: 1.8em;
`;

Banner.displayName = 'Banner';
DiversitySurveyBody.displayName = 'DiversitySurveyBody';
DiversitySurveyContainer.displayName = 'DiversitySurveyContainer';
DiversitySurveyFootnote.displayName = 'DiversitySurveyFootnote';
DiversitySurveyTertiaryButton.displayName = 'DiversitySurveyTertiaryButton';

export {
  Banner,
  DiversitySurveyBody,
  DiversitySurveyContainer,
  DiversitySurveyFootnote,
  DiversitySurveyTertiaryButton,
};
