import { InputWrapper, StyledInput } from '../App/styles';
import { Field } from 'formik';
import React from 'react';

const Paypal = () => (
  <InputWrapper>
    <StyledInput>
      <Field
        type='email'
        name='paypalEmail'
        placeholder='Paypal email'
        required={false}
      />
    </StyledInput>
  </InputWrapper>
);

export default Paypal;
