import styled from 'styled-components';

const StyledInterviewInstructions = styled.form`
  h4 {
    margin-bottom: 24px;
    font-weight: bold;
  }

  .instruction-section {
    margin-top: 40px;
  }

  button {
    width: 313px;
    margin-top: 32px;
    text-align: center;
  }

  @media (max-width: 768px) {
    button {
      width: 100%;
    }
  }
`;

StyledInterviewInstructions.displayName = 'StyledInterviewInstructions';

export default StyledInterviewInstructions;
