module.exports = (() => {
  const tinymce = require('tinymce/tinymce');
  require('tinymce/icons/default');
  require('tinymce/themes/silver');
  require('tinymce/plugins/link');
  require('tinymce/plugins/preview');
  require('tinymce/plugins/code');
  require('tinymce/plugins/lists');

  const toolbar =
    'undo redo | formatselect | removeformat | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code preview';
  const plugins = ['link', 'code', 'preview', 'lists'];

  const init = (inputField, selector, onChange) => {
    initializeTextEditor(inputField, selector, onChange);
  };

  const initializeTextEditor = (inputField, selector, onChange) => {
    tinymce.init({
      body_class: 'ds-implicit',
      content_css: 'https://styleguide.careerfoundry.com/main.css',
      content_style: 'body { padding: 0 15px }',
      height: '500',
      menubar: false,
      plugins: plugins,
      selector: selector ? `#${selector}` : '#tinymce',
      setup: (editor) => {
        editor.on('init', () => {
          editor.setContent(inputField.value);
        });
        editor.on('change', () => {
          inputField.value = tinymce.activeEditor.getContent();
          if (typeof onChange === 'function') {
            onChange(inputField.value);
          }
        });
        editor.on('keyup', () => {
          inputField.value = tinymce.activeEditor.getContent();
          if (typeof onChange === 'function') {
            onChange(inputField.value);
          }
        });
      },
      skin: false,
      statusbar: false,
      toolbar: toolbar,
    });
  };

  const closeEditor = (selector) => {
    tinymce.execCommand('mceRemoveEditor', true, selector);
  };

  return {
    closeEditor,
    init: init,
  };
})();
