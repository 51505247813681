import styled from 'styled-components';

const StyledGroupedNumberCheckInput = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

StyledGroupedNumberCheckInput.displayName = 'StyledGroupedNumberCheckInput';

export default StyledGroupedNumberCheckInput;
