import { closeEvaluation, finishInterview } from '../../containers/InterviewModal/actions';
import { evaluateAnswer, setCurrentAnswer, setReviewNavigable } from '../../actions';
import InterviewReview from '../../components/InterviewReview/index';
import { connect } from 'react-redux';

export const mapDispatchToProps = dispatch => ({
  closeEvaluation: () => dispatch(closeEvaluation()),
  evaluateAnswer: (answerId, params) => dispatch(evaluateAnswer(answerId, params)),
  finishInterview: () => dispatch(finishInterview()),
  setCurrentAnswer: answer => dispatch(setCurrentAnswer(answer)),
  setReviewNavigable: isReviewNavigable => dispatch(setReviewNavigable(isReviewNavigable)),
});

export const mapStateToProps = ({
  interviewPrep: {
    answersForReview,
    currentAnswer,
    reviewNote,
    reviewRating,
    shareWithMentor,
  },
}) => ({
  answersForReview,
  currentAnswer,
  reviewNote,
  reviewRating,
  shareWithMentor,
});

export default connect(mapStateToProps, mapDispatchToProps)(InterviewReview);
