import styled from 'styled-components';

const StyledHeader = styled.header`
  border-bottom: 1px solid var(--ds-palette__grey-light);

  & > div {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    text-align: left;
    display: inline-block;
    margin: 0;
  }

  .name-wrapper {
    position: relative;

    .edit-icon {
      color: var(--ds-palette__grey-70);
      position: absolute;
      top: 5px;
      right: -22px;
    }
  }

  a:last-of-type {
    align-self: center;
  }

  .ds-btn__primary {
    color: var(--ds-color__int-content-primary);

    &.disabled {
      opacity: 0.2;
      pointer-events: none;
      cursor: default;
    }
  }
`;

StyledHeader.displayName = 'Header';

const VerticalBorderContainer = styled.div`
  border-left: 1px solid var(--ds-palette__grey-light);
  border-right: 1px solid var(--ds-palette__grey-light);
`;

VerticalBorderContainer.displayName = 'VerticalBorderContainer';

export { StyledHeader, VerticalBorderContainer };
