import PropTypes from 'prop-types';
import React from 'react';
import StyledInputHeader from '../styles/StyledInputHeader';

const InputHeader = ({ label, prefix, isView = false }) => (
  <StyledInputHeader isView={isView}>
    <p className="ds-typography__body">
      {prefix && <span className="ds-typography__small">{prefix}</span>} {label}
    </p>
  </StyledInputHeader>
);

InputHeader.propTypes = {
  isView: PropTypes.bool,
  label: PropTypes.string.isRequired,
  prefix: PropTypes.string,
};

export default InputHeader;
