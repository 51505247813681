import styled from 'styled-components';

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;

  input {
    padding: 14px 16px 12px;
    border-radius: 4px;
    font-family: TradeGothic;
    font-size: 16px;
    color: var(--ds-color__indigo);
    border: 1px solid ${props => (props.error ? 'var(--ds-color_error)' : '#A7B1B9')};
    outline: none;

    &::placeholder {
      color: var(--ds-color__indigo);
    }
    &:-ms-input-placeholder {
      color: var(--ds-color__indigo);
    }
    &::-ms-input-placeholder {
      color: var(--ds-color__indigo);
    }

    &:focus {
      border: 1px solid ${props => (props.error ? 'var(--ds-color_error)' : 'var(--ds-color__indigo)')};
    }

    &:hover {
      border: 1px solid ${props => (props.error ? 'var(--ds-color_error)' : 'var(--ds-color__petrol)')};
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  .error-text {
    margin-top: 5px;
    color: var(--ds-color_error);
  }
`;

StyledInput.displayName = 'Input';

export default StyledInput;
