import { Field, Form, Formik } from 'formik';
import {
  PROGRAMS,
  SPECIALIZATION,
  STATUSES,
} from '../../../../../Employer/constants';
import React, { useEffect } from 'react';
import FilterWrapper from '../../../../../Employer/components/shared/FilterWrapper';
import InputWrapper from '../../../../../Employer/styles/InputWrapper';
import PropTypes from 'prop-types';
import StyleInputWrapper from '../../../../../Employer/styles/StyleInputWrapper';

let timerId;

const JobProfileFilters = ({
  getJobSeekingProfiles,
  setFilterParams,
  profileFilterParams,
}) => {
  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFilterParams({
      name,
      value,
    });
  };

  const handleInputChange = (event) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    const { name, value } = event.target;
    timerId = setTimeout(() => setFilterParams({ name, value }), 1000);
  };

  useEffect(() => {
    getJobSeekingProfiles(1, profileFilterParams);
  }, [profileFilterParams]);

  useEffect(() => {
    const data = [];
    for (const filter in profileFilterParams) {
      if (profileFilterParams[filter]) {
        data.push({ filter: filter, value: profileFilterParams[filter] });
      }
    }
  }, [profileFilterParams]);

  return (
    <>
      <Formik
        initialValues={{
          enrolled_end_date: '',
          enrolled_start_date: '',
          graduated_status: '',
          location: '',
          program: '',
          specialization: '',
          status: '',
        }}
      >
        {() => (
          <Form>
            <div className="ds-grid__row">
              <FilterWrapper label="Program" isVerticalLabel={true}>
                <Field
                  as="select"
                  name="program"
                  placeholder="Select Program"
                  onChange={handleSelectChange}
                  value={profileFilterParams.program}
                >
                  <option value="">Select Program</option>
                  {PROGRAMS.map((program) => (
                    <option value={program.slug} key={program.slug}>
                      {program.name}
                    </option>
                  ))}
                </Field>
              </FilterWrapper>
              <FilterWrapper label="Specialization" isVerticalLabel={true}>
                <Field
                  as="select"
                  name="specialization"
                  placeholder="specialization"
                  onChange={handleSelectChange}
                  value={profileFilterParams.specialization}
                >
                  <option value="">Select specialization</option>
                  {SPECIALIZATION.map((specialization) => {
                    const [name, slug] = specialization;
                    return (
                      <option value={slug} key={slug}>
                        {name}
                      </option>
                    );
                  })}
                </Field>
              </FilterWrapper>
              <InputWrapper>
                <StyleInputWrapper>
                  <FilterWrapper label="Location" isVerticalLabel={true}>
                    <Field
                      type="text"
                      name="location"
                      placeholder="Type country, state or city"
                      onKeyUp={handleInputChange}
                    />
                  </FilterWrapper>
                </StyleInputWrapper>
              </InputWrapper>
            </div>
            <div className="ds-grid__row">
              <FilterWrapper label="Is graduated?" isVerticalLabel={true}>
                <Field
                  as="select"
                  name="graduation_status"
                  placeholder="Select graduation status"
                  onChange={handleSelectChange}
                  value={profileFilterParams.graduated}
                >
                  <option value="">Select graduated status</option>
                  {['graduated', 'ungraduated'].map((graduatedStatus) => {
                    return (
                      <option value={graduatedStatus} key={graduatedStatus}>
                        {graduatedStatus}
                      </option>
                    );
                  })}
                </Field>
              </FilterWrapper>
              <FilterWrapper label="Status" isVerticalLabel={true}>
                <Field
                  as="select"
                  name="status"
                  placeholder="Select status"
                  onChange={handleSelectChange}
                  value={profileFilterParams.status}
                >
                  <option value="">Select specialization</option>
                  {STATUSES.map((status) => {
                    return (
                      <option value={status} key={status}>
                        {status}
                      </option>
                    );
                  })}
                </Field>
              </FilterWrapper>
              <InputWrapper>
                <label className="ds-typography__body--bold">
                  Date Enrolled
                </label>
                <StyleInputWrapper>
                  <FilterWrapper label="from">
                    <Field
                      type="date"
                      name="enrolled_start_date"
                      placeholder="Select a date"
                      onChange={handleInputChange}
                    />
                  </FilterWrapper>
                  <FilterWrapper label="to">
                    <Field
                      type="date"
                      name="enrolled_end_date"
                      placeholder="Select a date"
                      onChange={handleInputChange}
                    />
                  </FilterWrapper>
                </StyleInputWrapper>
              </InputWrapper>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

JobProfileFilters.propTypes = {
  getJobSeekingProfiles: PropTypes.func.isRequired,
  profileFilterParams: PropTypes.shape({
    byEnrolledEndDate: PropTypes.string,
    byEnrolledStartDate: PropTypes.string,
    graduated: PropTypes.bool,
    location: PropTypes.string,
    program: PropTypes.string,
    specialization: PropTypes.string,
    status: PropTypes.string,
  }),
  setFilterParams: PropTypes.func.isRequired,
};

export default JobProfileFilters;
