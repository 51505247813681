import React, { useEffect } from 'react';
import LoadingSpinner from '../../../../shared/loadingSpinner';
import MentorNotesTab from '../../../components/MentorNotesTab';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMentorNotes } from '../../../actions';
import { handleModalClose } from './actions';

export const MentorNotesContainer = ({
  getMentorNotes,
  loading,
  userId,
  ...rest
}) => {
  useEffect(() => {
    getMentorNotes(userId);
  }, []);

  return loading ? <LoadingSpinner /> : <MentorNotesTab {...rest} />;
};

MentorNotesContainer.propTypes = {
  getMentorNotes: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

export const mapStateToProps = ({
  userProfile: { mentorNotesTab, userId },
}) => ({
  loading: mentorNotesTab.loading,
  modalIsOpen: mentorNotesTab.modalIsOpen,
  userId,
});

export const mapDispatchToProps = (dispatch) => ({
  getMentorNotes: (userId) => dispatch(getMentorNotes(userId)),
  handleModalClose: () => dispatch(handleModalClose()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MentorNotesContainer);
