import React, { useEffect, useState } from 'react';
import InputHeader from './InputHeader';
import PropTypes from 'prop-types';
import StyledDiscussedTopicsSection from '../styles/StyledDiscussedTopicsSection';
import StyledFlexWrapper from '../styles/StyledFlexWrapper';
import Tag from './Tag';
import { getTags } from '../../action';
import { splitString } from '../../helper';

const DiscussedTopicsSection = ({
  id,
  label,
  onChange,
  prevSelectedTopics,
  viewType,
}) => {
  const [selectedTopics, setSelectedTopics] = useState(new Set());
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    getTopics();
  }, []);

  const getTopics = async () => {
    const tags = await getTags();
    setTopics(tags);
  };

  useEffect(() => {
    if (prevSelectedTopics) {
      const prevSet = new Set(splitString(prevSelectedTopics));
      setSelectedTopics(prevSet);
    }
  }, []);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({ data: [...selectedTopics].join(','), id });
    }
  }, [selectedTopics]);

  const handleClick = (topic) => {
    const currentSelectedTopics = selectedTopics;
    if (currentSelectedTopics.has(topic)) {
      currentSelectedTopics.delete(topic);
    } else {
      currentSelectedTopics.add(topic);
    }
    setSelectedTopics(new Set([...currentSelectedTopics]));
  };

  const isView = viewType === 'view';

  return (
    <StyledDiscussedTopicsSection>
      <InputHeader isView={isView} label={label} />
      <StyledFlexWrapper isEdit={!isView}>
        {isView
          ? splitString(prevSelectedTopics).map((topic) => {
              const topicLC = topic.toLowerCase();
              return (
                <Tag
                  isActive={false}
                  label={topicLC}
                  key={topicLC}
                  viewType={viewType}
                />
              );
            })
          : topics.map((topic) => {
              const topicLC = topic.toLowerCase();
              return (
                <Tag
                  isActive={selectedTopics.has(topicLC)}
                  label={topicLC}
                  key={topicLC}
                  onClick={handleClick}
                  viewType={viewType}
                />
              );
            })}
      </StyledFlexWrapper>
    </StyledDiscussedTopicsSection>
  );
};

DiscussedTopicsSection.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  prevSelectedTopics: PropTypes.string,
  viewType: PropTypes.string,
};

export default DiscussedTopicsSection;
