import styled, { css } from 'styled-components';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clippath: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
`;

const checkedStyles = css`
  &:before {
    content: "";
    display: block;
    width: 7px;
    height: 11px;
    border: solid #223c50;
    border-width: 0 2px 2px 0;
    margin-left: 6px;
    margin-top: 2px;
    transform: rotate(45deg);
  }
`;

const disabledStyles = css`
  cursor: not-allowed
`;

const StyledCheckbox = styled.div`
  border: 1px solid var(--ds-color__indigo);
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  transition: all 150ms;
  cursor: pointer;

  ${props => props.checked && checkedStyles};
  ${props => props.disabled && disabledStyles};
`;

const LabelText = styled.p`
  font-size: 12.6px !important;
  font-weight: 700 !important;
  margin-left: 7px !important;
  letter-spacing: 0 !important;
  color: var(--ds-color__indigo) !important;
  line-height: 1.85;
`;

const CheckboxContainer = styled.label`
  display: flex;
`;

HiddenCheckbox.displayName = 'HiddenCheckbox';
StyledCheckbox.displayName = 'StyledCheckbox';
CheckboxContainer.displayName = 'CheckboxContainer';
LabelText.displayName = 'LabelText';

export {
  CheckboxContainer,
  HiddenCheckbox,
  LabelText,
  StyledCheckbox,
};
