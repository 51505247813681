import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StyledVisualizer from './styles/StyledVisualizer';
import { draw } from './audioHelper';

const Visualizer = ({ stream, drawVisuals }) => {
  const [visualState, setVisualizer] = useState({});
  const [hasSource, setSource] = useState(false);

  const visualize = () => {
    const canvas = document.querySelector('.visualizer');
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const canvasCtx = canvas.getContext('2d');

    const audioCtx = new AudioContext();
    const source = audioCtx.createMediaStreamSource(stream);

    const mainSection = document.querySelector('.main-controls');
    window.onresize = function () {
      canvas.width = mainSection.offsetWidth;
    };

    window.onresize();

    const analyser = audioCtx.createAnalyser();
    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    source.connect(analyser);
    setSource(true);
    setVisualizer({ analyser, bufferLength, canvas, canvasCtx, dataArray });
  };

  useEffect(visualize, []);

  useEffect(() => {
    if (hasSource) {
      draw(visualState.canvas, visualState.canvasCtx, visualState.analyser, visualState.dataArray, visualState.bufferLength, drawVisuals);
    }
  }, [hasSource, drawVisuals]);

  return (
    <StyledVisualizer >
      <canvas className="visualizer" height="60px"></canvas>
    </StyledVisualizer>
  );
};

Visualizer.propTypes = {
  drawVisuals: PropTypes.bool,
  stream: PropTypes.object.isRequired,
};

export default Visualizer;
