import ApiService from '../../services/ApiService';
import { constants } from './constants';
import { getAuthToken } from '../../utils/getAuthToken';
import { keysToSnake } from '../../utils/keysConverter';

export const openNewNoteModal = (callId, canSubmitNote) => async (dispatch) => {
  const status = await getCallStatus(callId);

  if (status === 'canceled') {
    dispatch({
      type: constants.SHOW_CALL_CANCELED_MODAL,
    });
  } else {
    dispatch({
      data: { callId, canSubmitNote },
      type: constants.SHOW_ADD_NOTES_MODAL,
    });
  }
};

export const openOffPlatformCallModal = () => ({
  type: constants.OPEN_OFF_PLATFORM_CALL_MODAL,
});

export const getCallStatus = async (id) => {
  const params = {
    authenticity_token: getAuthToken(),
  };
  const apiService = new ApiService(`/api/v1/calendar_entries/${id}`);

  try {
    const result = await apiService.makeGetCall(params);

    return result.data.status;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
  }
};

export const createOffPlatformCall =
  (callInformation, noteData) => async (dispatch) => {
    const {
      attendeeId,
      callDropped,
      callRequestScreenshot,
      mentorComment,
      startTime,
    } = callInformation;
    const params = {
      attendee_id: attendeeId,
      authenticity_token: getAuthToken(),
      ...noteData,
      call_dropped: callDropped,
      call_request_screenshot: callRequestScreenshot,
      mentor_comment: mentorComment,
      start_time: startTime,
    };

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    const apiService = new ApiService(`/api/v1/off_platform_call`);

    try {
      dispatch(setAddingNotes(true));
      const result = await apiService.makePostCall(params, config);
      dispatch({
        data: result.data,
        type: constants.CREATE_OFF_PLATFORM_CALL_SUCCESS,
      });
    } catch (error) {
      dispatch({
        error: error.message,
        type: constants.CREATE_OFF_PLATFORM_CALL_FAILURE,
      });
    }
  };

export const storeOffPlatformCall =
  (attendee, callInformation) => async (dispatch) => {
    const { startTime } = callInformation;
    const { id, value } = attendee;
    dispatch({
      data: { attendeeId: id, attendeeName: value, callInformation, startTime },
      type: constants.STORE_OFF_PLATFORM_CALL,
    });
  };

export const handleCloseModal = () => ({ type: constants.HANDLE_CLOSE_MODAL });

export const handleCancelModal = () => ({
  type: constants.HANDLE_CANCEL_MODAL,
});

export const setModalType = (modalType) => ({
  modalType,
  type: constants.SET_MODAL_TYPE,
});

export const setModalAction = (modalAction) => ({
  modalAction,
  type: constants.SET_MODAL_ACTION,
});

export const setFormFields = (data) => ({
  data,
  type: constants.SET_NOTE_FORM_FIELDS,
});

export const setAddingNotes = (loading) => ({
  loading,
  type: constants.ADD_NOTES_LOADING,
});

export const addCallNotes = (callId, data) => async (dispatch) => {
  const params = {
    calendar_entry_id: callId,
    ...data,
    authenticity_token: getAuthToken(),
  };

  const apiService = new ApiService('/api/v1/call_notes');
  try {
    dispatch(setAddingNotes(true));

    const result = await apiService.makePostCall(params);

    await dispatch(getCallNotes(1));
    dispatch(setAddingNotes(false));
    dispatch({
      type: constants.ADD_NOTES_SUCCESS,
    });

    return result.data;
  } catch (error) {
    dispatch(setAddingNotes(false));
    console.log(`Something went wrong... ${error}`);
  }
};

export const getCallNotes = (currentPage) => async (dispatch) => {
  const params = {
    page: currentPage,
  };

  const apiService = new ApiService('/api/v1/calendar_entries/pending_notes');
  try {
    const result = await apiService.makeGetCall(params);
    dispatch({
      data: result.data,
      type: constants.GET_CALENDAR_ENTRIES_SUCCESS,
    });
    return result.data;
  } catch (error) {
    dispatch({
      type: constants.GET_CALENDAR_ENTRIES_FAILURE,
    });
    console.log(`Something went wrong... ${error}`);
  }
};

export const getCallNotesHistory =
  (currentPage, filterParams) => async (dispatch) => {
    const params = {
      page: currentPage,
      ...filterParams,
    };

    const apiService = new ApiService('/api/v1/call_notes');
    try {
      const result = await apiService.makeGetCall(params);
      dispatch({
        data: result.data,
        type: constants.GET_NOTES_SUCCESS,
      });
      return result.data;
    } catch (error) {
      dispatch({
        type: constants.GET_NOTES_FAILURE,
      });
    }
  };

export const updateCalendarEntry = (id, data) => async (dispatch) => {
  const params = {
    authenticity_token: getAuthToken(),
    ...keysToSnake(data),
  };
  const apiService = new ApiService(`/api/v1/calendar_entries/${id}`);

  try {
    dispatch(setAddingNotes(true));
    const result = await apiService.makePatchCall(params);
    await dispatch(getCallNotes(1));
    dispatch(setAddingNotes(false));
    dispatch({
      data: result.data,
      type: constants.UPDATE_CALENDAR_ENTRY_SUCCESS,
    });
  } catch (error) {
    dispatch(setAddingNotes(false));
    console.log(`Something went wrong... ${error}`);
  }
};

export const fetchCallNote =
  (noteId, type = 'edit') =>
  async (dispatch) => {
    const apiService = new ApiService(`/api/v1/call_notes/${noteId}`);
    try {
      dispatch(setFetchingNote(true, noteId, type));
      const result = await apiService.makeGetCall();

      dispatch(setFetchingNote(false, noteId, type));
      if (type === 'view') {
        dispatch(handleViewNoteModal(noteId, result.data));
      } else {
        dispatch(openEditNoteModal(noteId, result.data));
      }
      return result.data;
    } catch (error) {
      console.log(`Something went wrong... ${error}`);
    }
  };

export const openEditNoteModal = (noteId, note) => ({
  data: { note, noteId },
  type: constants.SHOW_EDIT_NOTES_MODAL,
});

export const handleViewNoteModal = (noteId, note) => ({
  data: { note, noteId },
  type: constants.SHOW_VIEW_NOTES_MODAL,
});

export const setFetchingNote = (isLoading, noteId, type) => ({
  data: { isLoading, noteId, type },
  type: constants.FETCHING_NOTES,
});

export const editCallNotes = (callId, data) => async (dispatch) => {
  const params = {
    ...data,
    authenticity_token: getAuthToken(),
  };

  const apiService = new ApiService(`/api/v1/call_notes/${callId}`);
  try {
    dispatch(setAddingNotes(true));

    const result = await apiService.makePutCall(params);

    dispatch(setAddingNotes(false));
    dispatch({
      message: 'Note Updated Successfully',
      type: constants.EDIT_NOTES_SUCCESS,
    });

    return result.data;
  } catch (error) {
    dispatch(setAddingNotes(false));
    console.log(`Something went wrong... ${error}`);
  }
};

export const getTags = async () => {
  const apiService = new ApiService('/api/v1/call_note_tags');
  try {
    const result = await apiService.makeGetCall();

    return result.data.tags;
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
    return [];
  }
};

export const setFilterParams = (data) => ({
  data,
  type: constants.SET_NOTES_FILTER,
});

export const getMentees = (input, courseId) => async (dispatch) => {
  const params = {
    course_id: courseId,
    name: input,
  };
  const apiService = new ApiService('/api/v1/mentees/autocomplete');
  try {
    const result = await apiService.makeGetCall(params);
    dispatch({
      data: result.data,
      type: constants.GET_MENTEES_SUCCESS,
    });
  } catch (error) {
    console.log(`Something went wrong... ${error}`);
    return [];
  }
};
