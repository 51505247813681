module.exports = (() => {
  var init = function() {
    courseReviewsTable();
  };

  var courseReviewsTable = function() {
    $('.course_reviews_table').DataTable({
      "paging": false,
      "info": false,
      "searching": false
    });
  };

  return {
    init: init
  };
})();
