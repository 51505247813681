import ApiService from '../../../../services/ApiService';

export const getHandoffResponses = async (userId) => {
  const apiService = new ApiService(
    `/api/v1/users/${userId}/student_handoff_answers`
  );
  try {
    const result = await apiService.makeGetCall();
    return result.data.data;
  } catch (error) {
    console.log(error);
  }
};
