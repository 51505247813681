import styled from 'styled-components';

const StyledBackgroundImage = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: ${(props) => (props.mobile ? '214px' : '330px')};
  width: 100%;
  background-image: url('http://images.careerfoundry.com/public/images/practiceInterview.png');
`;
StyledBackgroundImage.displayName = 'StyledBackgroundImage';
export default StyledBackgroundImage;
