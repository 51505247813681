import styled from 'styled-components';

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0 rgba(51, 51, 51, 0.1);
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 40px 32px;
`;

const StyledHeader = styled(Card)`
  h3 {
    margin-bottom: 24px;
  }

  i {
    color: #a7b1b9;
  }

  p,
  h3 {
    color: var(--ds-color__indigo);
  }

  .cf-form__checkbox--open input[type='checkbox'] {
    border: 1px solid var(--ds-color__indigo);
  }

  button {
    height: 40px;
  }

  .checkbox-overlay {
    p {
      width: 100%;
      z-index: 2;
      cursor: pointer;
    }

    .cf-form__checkbox--open {
      position: absolute;
      right: 0;
    }
  }

  .disabled-checkbox {
    p {
      width: inherit;
      cursor: auto;
    }
  }

  .delete-button {
    color: var(--ds-color_error);
    border-color: var(--ds-color_error);
    margin-right: 8px;
  }
`;

const CandidatePicture = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 32px;
`;

CandidatePicture.displayName = 'CandidatePicture';

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  .disabled-checkbox {
    cursor: not-allowed !important;
  }
`;

CheckboxWrapper.displayName = 'CheckboxWrapper';

const CandidateInfo = styled.div`
  display: flex;
`;

CandidateInfo.displayName = 'CandidateInfo';

export { CandidatePicture, CheckboxWrapper, StyledHeader, CandidateInfo };
