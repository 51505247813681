import LoadingSpinner from '../../../../shared/loadingSpinner.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

const ActivationConfirm = ({ handleClose, handleActivate, loadingButton }) => {
  const { candidateId } = useParams();

  return (
    <>
      <i className='ds-icon__info-line'></i>
      <h3 className='ds-typography__h3'>Before you activate...</h3>
      <ol>
        <li className='ds-typography__lead'>
          Is the contract signed by both parties?
        </li>
        <li className='ds-typography__lead'>
          Are tax and billing details approved?
        </li>
        <li className='ds-typography__lead'>
          Have the correct documents been provided?
        </li>
        <li className='ds-typography__lead'>
          Has the candidate completed their onboarding tasks?
        </li>
      </ol>
      <button className='ds-button__secondary' onClick={handleClose}>
        Discard
      </button>
      <button
        className='ds-button__primary'
        onClick={() => handleActivate(candidateId)}
      >
        {loadingButton ? <LoadingSpinner /> : 'Activate'}
      </button>
    </>
  );
};

ActivationConfirm.propTypes = {
  handleActivate: PropTypes.func,
  handleClose: PropTypes.func,
  loadingButton: PropTypes.bool,
};

export default ActivationConfirm;
