import ProfessionalStatement from '../../modals/showModal/ProfessionalStatement';
import React from 'react';
import { connect } from 'react-redux';
import { updateECProfile } from '../../actions';

const ProfessionalStatementViewContainer = (props) => {
  return (
    <ProfessionalStatement {...props} />
  );
};

export const mapStateToProps = ({
  careerSupportCenter: { jobSeekingProfile },
}) => ({
  jobSeekingProfile,
});

export const mapDispatchToProps = (dispatch) => ({
  updateECProfile: (data) => dispatch(updateECProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalStatementViewContainer);
