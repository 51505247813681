import { Body, Header } from '@careerfoundry/bubble';
import { HeaderContainer, ToolTipWrapper, RejectionWrapper } from './styles';
import React from 'react';
import Tooltip from '../../../../shared/components/Tooltip';

interface HeadingProps {
  reason?: string;
}

const Heading: React.FC<HeadingProps> = ({ reason }) => (
  <HeaderContainer>
    <Header tag='h2' variant='h2'>
      Add new claim
    </Header>
    {reason && (
      <RejectionWrapper>
        <Body tag='p' variant='medium'>
          {reason}
        </Body>
      </RejectionWrapper>
    )}
    <ToolTipWrapper>
      <Body tag='p' variant='large'>
        Type a name to search for the student you want to add services for. If
        the service was requested by us, please select “Career Services Team”
      </Body>
      <Tooltip
        place='bottom'
        content='Can’t find the student? Please choose “Career Services Team” and leave our team a note below, we’d be happy to help!'
      >
        <i className='ds-icon__help-line'></i>
      </Tooltip>
    </ToolTipWrapper>
  </HeaderContainer>
);

export default Heading;
