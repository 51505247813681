import EmptyState from '../../../shared/components/EmptyTableState';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../../shared/components/Select';
import { TableWrapper } from './styles';
import { formatOptionForSelect } from '../../../../utils/formatOptionForSelect';

const Table = ({ calls, handleStatusChange, options }) => {
  return (
    <TableWrapper>
      <table className="ds-table">
        <thead>
          <tr className="ds-table__tr">
            <th className="ds-table__th">Date of call</th>
            <th className="ds-table__th">Time of call</th>
            <th className="ds-table__th">Student name</th>
            <th className="ds-table__th">Course</th>
            <th className="ds-table__th">Status</th>
          </tr>
        </thead>
        <tbody>
          {calls.map((call) => {
            return (
              <tr className="ds-table__tr" key={call.id}>
                <td className="ds-table__td">{call.callDate}</td>
                <td className="ds-table__td">
                  {call.callTime}
                  {call.timezone}
                </td>
                <td className="ds-table__td">{call.student}</td>
                <td className="ds-table__td">{call.course}</td>
                <td className="ds-table__td">
                  <Select
                    value={call.status}
                    onChange={(event) =>
                      handleStatusChange(call.id, event.target.value)
                    }
                  >
                    {options.map((option) => (
                      <option key={option} value={option}>
                        {formatOptionForSelect(option)}
                      </option>
                    ))}
                  </Select>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {!calls.length && <EmptyState heading="No student calls yet" />}
      <p className="ds-typography__body calls-count">
        <span className="ds-typography__body--bold">Total:</span> {calls.length}{' '}
        calls
      </p>
    </TableWrapper>
  );
};

Table.propTypes = {
  calls: PropTypes.arrayOf(
    PropTypes.shape({
      callDate: PropTypes.string,
      callTime: PropTypes.string,
      course: PropTypes.string,
      id: PropTypes.number,
      status: PropTypes.string,
      student: PropTypes.string,
      timezone: PropTypes.string,
    })
  ),
  handleStatusChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
};

export default Table;
