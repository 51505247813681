import PropTypes from 'prop-types';
import React from 'react';

const AdminWrapper = props => (
  <div className="admin_page cf-content">
    <header className="cf-page_header--petrol">
      <div className="cf-container--full">
        <h1 className="cf-text-left">{props.headerText}</h1>
      </div>
    </header>
    <section className="cf-section cf-container--full card--white">
      {props.children}
    </section>
  </div>
);

AdminWrapper.propTypes = {
  children: PropTypes.object,
  headerText: PropTypes.string,
};

export default AdminWrapper;
