import axios from 'axios';
import { getAuthToken } from '../../../utils/getAuthToken';
module.exports = require('../../users/settings');
const attendeesIndex = require('../attendees/index');
const careerfoundryCoach = require('../../../../../assets/javascripts/site/careerfoundry_coach.js');
require('./user_roles');
require('./user_specializations');

module.exports = (() => {
  const thisState = {};

  const init = () => {
    _bindFunctions();
    thisState.readOnlyModal = new CF.Modal('#read_only_attendee_modal');
    thisState.readOnlyForm = new CF.Form('#add-read-only-attendee-form');
    initEditProgramAttendees();
    attendeesIndex.init();
    careerfoundryCoach.init();
  };

  const _bindFunctions = () => {
    $('.add_read_only_attendee--js').on('click', toggleReadOnlyModal);
    $('.edit-program-attendee--js').on('click', toggleEditProgramAttendeeModal);
    $('.update-program-attendee--js').on('click', updateProgramAttendee);
    $('.admin_breadcrumb_nav__content i').on('click', showSearch);
    $('.setUserType--js').on('change', setUserType);
    $('.setEmploymentAgency--js').on('change', setEmploymentAgency);
    $('.delete-user--js').on('click', handleDelete);
    $('body').on('change', '.program-attendee-start-date--js', updateProgramDates);
  };

  const handleDelete = () => window.confirm('Are you sure you want to delete the user? This action cannot be undone and user data will be deleted or anonymized forever.') && deleteUser();

  const deleteUser = () => {
    const userId = document.getElementsByClassName('admin_user_profile__wrapper')[0].dataset.id;
    const params = {
      authenticity_token: getAuthToken(),
    };

    axios.post(`/admin/users/${userId}/anonymize`, params)
      .then(_ => {
        alert('Deleted!');
        window.location.href = '/en/admin';
      }).catch(error => {
        alert(`There was an error: ${error.response.data.errors}`);
      });
  };

  const initEditProgramAttendees = () => {
    const programAttendees = document.querySelectorAll('.edit_program_attendee');

    programAttendees.forEach(function (attendee, index) {
      thisState[`editProgramAttendeeModal_${index}`] = new CF.Modal(`#${attendee.classList[0]}_modal_${index}`);
      thisState[`editProgramAttendeeForm_${index}`] = new CF.Form(`#${attendee.classList[0]}_form_${index}`);
    });
  };

  const toggleReadOnlyModal = e => {
    e.preventDefault();
    thisState.readOnlyModal.toggleModal();
  };

  const toggleEditProgramAttendeeModal = e => {
    e.preventDefault();
    thisState[e.target.dataset.target].toggleModal();
  };

  const setUserType = e => {
    const $element = $(e.target);
    const data = {
      id: $element.data('user-id'),
      user_type: $element.context.value,
    };
    $.ajax({
      data,
      method: 'PATCH',
      url: '/en/admin/users/set_user_type/',
    }).done(function (r) {
      CareerFoundyCoach.flashMessage(r.message, r.type);
      if(data.user_type !== 3){
        $('.setTerminationStatus--js').removeClass('hidden');
      }
    }).fail(function (r) {
      const response = $.parseJSON(r.responseText);
      CareerFoundyCoach.flashMessage(response.message, response.type);
    });
  };

  const setEmploymentAgency = e => {
    const elm = e.target;
    const paymentContractId = elm.dataset.paymentContractId;
    const userId = elm.dataset.userId;
    const data = {
      authenticity_token: getAuthToken(),
      id: paymentContractId,
      payment_contract: {
        employment_agency: parseInt(elm.value),
      },
      user_id: userId,
    };

    axios.patch(`/en/admin/users/${userId}/payment_contracts/${paymentContractId}/employment_agency`, data)
      .then(res => {
        CareerFoundyCoach.flashMessage(res.data.message, res.data.status);
      });
  };

  const updateProgramAttendee = e => {
    e.preventDefault();

    $.ajax({
      data: $(`#${e.target.form.id}`).serialize(),
      method: 'POST',
      url: '/en/admin/users/update_program_attendee/',
    }).done(function (res) {
      alert('Program Attendee Updated!');
      window.location.reload();
    }).fail(function (error) {
      alert(`There was an error updating the program attendee: ${error.responseText}`);
    });
  };

  const showSearch = e => {
    $(e.target).siblings().toggleClass('hidden');
    $('#search').focus();
  };

  const updateProgramDates = e => {
    const startDateInput = e.target
    const programAttendeeForm = $(e.target).parents('.program-attendee-form')
    const newStartDate = new Date($(startDateInput).val());
    const previousStartDate = new Date($(startDateInput).data('start_date'));
    const days = Math.round((newStartDate - previousStartDate) / (1000 * 60 * 60 * 24));
    const targetCompletionDate = new Date($(programAttendeeForm).find('.program-attendee-target-completion--js').val());
    const endDate = new Date($(programAttendeeForm).find('.program-attendee-end-date--js').val());
    
    $('.program-attendee-target-completion--js').datepicker("setDate", addDays(targetCompletionDate, days));
    $('.program-attendee-end-date--js').datepicker("setDate", addDays(endDate, days));
  };

  function addDays(date, days) {
    return new Date(date.getTime() + days*24*60*60*1000);
  }

  return {
    init,
  };
})();
