import React from 'react';

const ProsAndCons = () => (
  <>
    <p className='ds-typography__body break'>
      Based on direct experience and key questions there are a few special cases
      we wanted to address:
    </p>
    <h5 className='ds-typography__h5'>Job searching while freelancing</h5>
    <p className='ds-typography__body break'>
      If your freelancing work comes up during an interview for a full-time role
      there are some key questions the prospective employer might have that you
      can prepare for, or proactively address:
    </p>
    <ul>
      <li>
        How do you see this role fitting in with your career plans?
        <ul>
          <li>
            <em>
              Example response: While I enjoy my work freelancing because it
              allows me to learn so many things, this role would afford me the
              opportunity to work as part of a dedicated team while being
              mentored.
            </em>
          </li>
        </ul>
      </li>
      <li>
        Will you have enough time to do this role?
        <ul>
          <li>
            <em>
              Example response: I would be fully committed to delivering quality
              work in this role. Since I use freelance for professional
              development purposes, I am able to scale my freelance projects as
              my schedule permits. My work as a freelancer perfectly positions
              me for this role because…{' '}
            </em>
          </li>
        </ul>
      </li>
      <li>
        Does your freelance work compete with this company?
        <ul>
          <li>
            <em>
              Example response: I do not currently have any conflicts of
              interest (if you truly don’t) in taking on this role, and if
              something arises that seems questionable I will bring it to your
              attention to be sure I handle it appropriately. My work as a
              freelancer perfectly positions me for this role because…{' '}
            </em>
          </li>
        </ul>
      </li>
      <li>
        What about intellectual property?
        <ul>
          <li>
            <em>
              Example response: I will do my due diligence to ensure that I keep
              my work with you separate from my freelance work by ensuring I do
              not use any company materials, ideas, or equipment to complete my
              freelance tasks. My work as a freelancer perfectly positions me
              for this role because…{' '}
            </em>
          </li>
        </ul>
      </li>
    </ul>
    <p className='ds-typography__body break'>
      Acknowledging and addressing potential employer concerns with specific
      examples and planned actions can go a long way toward earning the trust
      that you can handle the responsibility.
    </p>
    <h5 className='ds-typography__h5'>Freelancing while unemployed</h5>
    <p className='ds-typography__body break'>
      As with all other business requirements, it is up to you to understand
      what your legal obligations are when it comes to freelance work if you are
      in a government program such as unemployment assistance. For example, in
      some countries, you can only receive unemployment assistance if your
      income or work hours are under a specific threshold. If you are receiving
      benefits, be sure to review the work requirements in your specific
      country, state, province, or city.
    </p>
  </>
);

export default ProsAndCons;
