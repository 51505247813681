export const displayState = (state) => {
  switch (String(state).toLowerCase()) {
    case 'waiting':
      return 'Not started';
    case 'active':
      return 'In progress';
    case 'expired':
    case 'out of time':
      return 'Out of time';
    case 'graduated':
      return 'Completed';
    case 'read_only':
    case 'canceled':
    case 'blocked':
    case 'locked':
      return '';
    default:
      return state;
  }
};

export const isCourseAccessible = (state) => {
  const inaccessibleStates = ['blocked', 'locked'];

  return !inaccessibleStates.includes(state);
};

export const setClasses = (arr, i, status) => {
  if (arr.length - 1 === i) {
    return status ? 'last' : 'current';
  }
};
