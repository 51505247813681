import {
  QBody,
  QBodyItem,
  QBodyItemDescription,
  QBodyItemImage,
  QBodyItemTitle,
  QHead,
  QHeadTitle,
} from './styles';
import Answers from '../Answers';
import PropTypes from 'prop-types';
import React from 'react';

const Question = ({ data, onClickAnswer, userAnswerIndex }) => (
  <>
    <QHead>
      <QHeadTitle className='ds-typography__uber' role='QTitle'>
        {data.question}
      </QHeadTitle>
    </QHead>
    <QBody>
      {data.type === 'icon-with-text' ? (
        data.answers.map((answer, index) => (
          <QBodyItem
            className='ds-shadow__4'
            active={
              typeof userAnswerIndex === 'number'
                ? index === userAnswerIndex
                : answer.selected
            }
            key={answer.answer}
            role='QAnswer'
            onClick={() => onClickAnswer(index)}
          >
            <QBodyItemTitle className='ds-typography__body' role='QAnswerTitle'>
              {answer.answer}
            </QBodyItemTitle>
            <QBodyItemImage role='QAnswerImage'>
              <img src={answer.icon} />
            </QBodyItemImage>
            <QBodyItemDescription
              className='ds-typography__body'
              role='QAnswerDescription'
            >
              {answer.description}
            </QBodyItemDescription>
          </QBodyItem>
        ))
      ) : (
        <Answers
          answers={data.answers}
          onSelectAnswer={onClickAnswer}
          userAnswerIndex={userAnswerIndex}
        />
      )}
    </QBody>
  </>
);

Question.propTypes = {
  data: PropTypes.object,
  onClickAnswer: PropTypes.func,
  userAnswerIndex: PropTypes.number,
};

export default Question;
