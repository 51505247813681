import PropTypes from 'prop-types';
import React from 'react';
import StyledCheckbox from '../styles/StyledCheckbox';
import StyledLongSelectInput from '../styles/StyledLongSelectInput';

const LongSelectInput = ({ label, prefix, isSelected, onClick }) => {
  return (
    <StyledLongSelectInput isSelected={isSelected} onClick={() => onClick()}>
      <StyledCheckbox isSelected={isSelected}>
        <p className='ds-typography__small--bold'>{prefix}</p>
      </StyledCheckbox>
      <p className='ds-typography__body'>{label}</p>
    </StyledLongSelectInput>
  );
};

LongSelectInput.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
};

export default LongSelectInput;
