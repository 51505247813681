import {
  AnswerItem,
  AnswerItemLetter,
  AnswerItemText,
  AnswerList,
} from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const Answers = ({ answers = [], onSelectAnswer, userAnswerIndex }) => {
  const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'];
  return (
    <AnswerList className='ds-shadow__4'>
      {answers.map((answer, index) => {
        return (
          <AnswerItem
            key={answer.answer}
            isActive={
              typeof userAnswerIndex === 'number'
                ? index === userAnswerIndex
                : answer.selected
            }
            role='answerItem'
            className='ds-typography__body'
            onClick={() => onSelectAnswer(index)}
          >
            <AnswerItemLetter
              role='answerLetter'
              isActive={
                typeof userAnswerIndex === 'number'
                  ? index === userAnswerIndex
                  : answer.selected
              }
            >
              {letters[index]}
            </AnswerItemLetter>
            <AnswerItemText>{answer.answer}</AnswerItemText>
          </AnswerItem>
        );
      })}
    </AnswerList>
  );
};

Answers.propTypes = {
  answers: PropTypes.array,
  onSelectAnswer: PropTypes.func,
  userAnswerIndex: PropTypes.number,
};

export default Answers;
